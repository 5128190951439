import React, {type FC, memo} from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {type AppState} from 'store/interface';

import logoGray from '../../CoursebookContentsViewer/assets/logo-gray.svg';
import fltrArrow from '../../CoursebookLibrary/LibraryPageList/assets/fltr-arrow.png';
import fltrArrow2x from '../../CoursebookLibrary/LibraryPageList/assets/fltr-arrow@2x.png';
import './NoEntries.scss';

interface Props {
  dictionaryIsReadonly: boolean;
  userId: number;
  isCompact?: boolean;
  hasButton?: boolean;
  isList?: boolean;
}

export const NoEntries: FC<Props> = memo(
  ({dictionaryIsReadonly, isCompact, hasButton, userId, isList}) => {
    const isStudent = useSelector<AppState, boolean>(s => s.user.id === userId);
    const isEn = useSelector<AppState, boolean>(s => s.intl.locale === 'en');

    return (
      <div className={classNames('no-entries', {'is-compact': isCompact})}>
        <div className="logo">
          <img src={logoGray} alt="" />
        </div>
        {!dictionaryIsReadonly && (
          <div className={classNames('empty-list-hint', {en: isEn})}>
            {(isCompact || isStudent || hasButton) && (
              <img src={fltrArrow} srcSet={`${fltrArrow2x} 2x`} className="arrow" alt="" />
            )}
            {!isList ? (
              isStudent ? (
                <span className="empty-list-message case-1">
                  <FormattedMessage id="Dictionary.ClickHereToAddToYourDictionary" />
                </span>
              ) : (
                <span className="empty-list-message case-2">
                  <FormattedMessage id="Dictionary.ClickHereToAddToStudentsDictionary" />
                </span>
              )
            ) : hasButton ? (
              <span className="empty-list-message case-3">
                <FormattedMessage id="Dictionary.ClickHereToAddToList" />
              </span>
            ) : null}
          </div>
        )}
      </div>
    );
  }
);
