import {type Action} from 'redux';
import {type AxiosError, type AxiosRequestConfig, type AxiosResponse} from 'axios';
import {
  type AxiosAction,
  type MiddlewareRequestError,
  type MiddlewareRequestSuccess
} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';

export const AXIOS_SUCCESS_POSTFIX: string = '_SUCCESS';
export const AXIOS_ERROR_POSTFIX: string = '_FAIL';

export interface AxiosRequestAction extends AxiosAction {
  payload: {
    client?: string;
    request: AxiosRequestConfig;
    options?: {
      preventDispatch?: {
        onSuccess?(
          response?: MiddlewareRequestSuccess<AppState> | MiddlewareRequestError<AppState>
        ): boolean;
      };
    };
  };
}

export interface AxiosResponseAction<S> extends Action {
  meta?: {
    previousAction: AxiosRequestAction;
  };
  payload: AxiosResponse<S>;
}

export function isAxiosError(e: Error | AxiosRequestError): e is AxiosRequestError {
  return !!(e as AxiosRequestError).error;
}

export interface AxiosRequestError extends Action {
  error: AxiosError;
  meta: {
    previousAction: AxiosRequestAction;
  };
}

export type UploadProgressHandler = (event: ProgressEvent) => void;
