import {useMemo} from 'react';
import {type Color, ColorsPaint, SelectablePaint, usePaint} from '@englex/paint-react';
import {Paint} from '@englex/paint';

export function useColor() {
  const paint = usePaint<ColorsPaint>();

  const [selectedNode] = Paint.selectedEntry(paint);
  const isSelectable = SelectablePaint.isEnabled(paint);

  const isDisabled = isSelectable && selectedNode?.className === 'Image';

  const selectedNodeColor: Color | undefined = useMemo(() => {
    if (!selectedNode || !isSelectable) return;
    const [attr] = ColorsPaint.getColorAttrs(paint, selectedNode);
    return selectedNode.attrs[attr] || undefined;
  }, [isSelectable, paint, selectedNode]);

  const active = selectedNodeColor || paint.colors.active;

  return {active, isDisabled};
}
