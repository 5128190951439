import {type IntlShape} from 'react-intl';
import {type Block, Editor, Value, type ValueJSON} from '@englex/slate';
import {Map, List} from 'immutable';
import * as yup from 'yup';

import {
  type QuizJSON,
  type QuizOptions,
  type QuizResultBlockJSON
} from 'store/exercise/player/widgets/Quiz/interface';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {exerciseExcerptLength} from 'config/static';

import {
  type MediaMeta,
  WidgetMediaType,
  WidgetTitle,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../player/interface';
import {type XFormattedTextProperties} from '../XFormattedText/interface';
import {documentNotEmpty} from '../validation';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import XQuizBlockRecord from './XQuizBlockRecord';
import genKey from '../../../../../components/Slate/utils/genKey';
import {isBlockOfType, valueJSONFromText} from '../../../../../components/Slate/utils';
import {type ImageBlock, SlateBlock} from '../../../../../components/Slate/interface';
import {ValidationWidgetError} from '../customErrors';
import validationMessages from '../i18n';
import {isDocumentEmpty} from '../../../../../components/Slate/utils/documentNotEmpty';
import {type MCAnswerBlock} from '../../../../../components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/interface';
import {addDataToBlock} from '../../../../../components/Slate/SlateEditor/plugins/utils';
import {contentExcerpt} from '../excerpt';

export interface XQuizProperties extends XFormattedTextProperties {
  options: QuizOptions;
  resultBlocks: List<XQuizBlockRecord>;
  changeBlockTitle: (blockId: string, editor: Editor) => this;
  changeBlockText: (blockId: string, editor: Editor) => this;
}

class XQuizRecord extends XFormattedTextRecord<QuizOptions> implements XQuizProperties {
  static Questions = {Min: 2, Max: 99, Default: 2};
  static Answers = {Min: 2, Max: 99, Default: 4};

  public declare resultBlocks: List<XQuizBlockRecord>;

  static createBlock(block: Partial<QuizResultBlockJSON> = {}) {
    return new XQuizBlockRecord({
      id: block.id || genKey(),
      title: block.title || valueJSONFromText(''),
      text: block.text || valueJSONFromText('')
    });
  }

  static createBlocks(count: number) {
    return List(Array.from(Array(count).keys()).map(() => XQuizRecord.createBlock()));
  }

  static prepareAnswers(
    change: Editor,
    resultBlocks: List<XQuizBlockRecord>,
    options: WidgetToJSONOptions
  ): Editor {
    return change.value.document
      .filterDescendants(
        node =>
          isBlockOfType(node, SlateBlock.QUESTION_ANSWER) ||
          isBlockOfType(node, SlateBlock.QUESTION_ITEM)
      )
      .reduce((ch: Editor, questionOrAnswerBlock: MCAnswerBlock): Editor => {
        const {generateIdentifiers} = options;

        if (generateIdentifiers) {
          addDataToBlock(ch, questionOrAnswerBlock, 'id', genKey());
        }

        if (isBlockOfType(questionOrAnswerBlock, SlateBlock.QUESTION_ITEM)) {
          questionOrAnswerBlock
            .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
            .forEach((node: Block, index: number) => {
              addDataToBlock(ch, node, 'resultId', resultBlocks.getIn([index, 'id']));
            });
        }

        return ch;
      }, change);
  }

  static contentToJSON(
    content: Value,
    resultBlocks: List<XQuizBlockRecord>,
    options: WidgetToJSONOptions = {}
  ): ValueJSON {
    const change = new Editor({value: content});
    change.moveToRangeOfDocument().command(XQuizRecord.prepareAnswers, resultBlocks, options);

    return change.value.toJSON();
  }

  static contentFromJSON(content: ValueJSON): Value {
    const value = Value.fromJSON(content);
    const change = new Editor({value});
    return change.value;
  }

  constructor(raw: QuizJSON) {
    super(raw);

    this.initValues({
      resultBlocks: List.isList(raw.resultBlocks)
        ? raw.resultBlocks
        : List((raw.resultBlocks as QuizResultBlockJSON[]).map(XQuizRecord.createBlock)),
      content: XQuizRecord.contentFromJSON(raw.content),
      options: raw.options
    });
  }

  public get type() {
    return WidgetType.Quiz;
  }

  public get title(): string {
    return WidgetTitle.Quiz;
  }

  public toJSON(options?: WidgetToJSONOptions): QuizJSON {
    return {
      id: this.id,
      type: this.type,
      content: XQuizRecord.contentToJSON(this.content, this.resultBlocks, options),
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      resultBlocks: this.resultBlocks.map((b: XQuizBlockRecord) => b.toJSON()).toArray(),
      options: {...this.options, questionsNumber: this.questionsNumber}
    };
  }

  private get questionsNumber() {
    const change = new Editor({value: this.content});

    return change.value.document.filterDescendants(node =>
      isBlockOfType(node, SlateBlock.QUESTION_ITEM)
    ).size;
  }

  public get excerpt(): string {
    const task = this.task.document.text;

    if (task.length >= exerciseExcerptLength) {
      return task;
    }

    const questions = this.content.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
      .map((qi: Block, i: number) => {
        const question = qi.getBlocksByType(SlateBlock.DEFAULT).first().text;
        const answers = qi
          .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
          .map((n: Block) => n.text)
          .join(' / ');
        return `${i + 1}. ${question} [${answers}]`;
      })
      .join(' ');

    const result = this.resultBlocks
      .map((b: XQuizBlockRecord, i: number) => {
        const title = contentExcerpt(b.title, task.length + questions.length);
        const text = contentExcerpt(b.text, task.length + questions.length + title.length);

        return `${i + 1}. ${title} ${text}`;
      })
      .join(' ');

    return `${task} ${questions} ${result}`.trim();
  }

  public schema(intl: IntlShape) {
    return yup.object({resultBlocks: this.resultBlocksSchema(intl)});
  }

  private resultBlocksSchema(intl: IntlShape): yup.AnySchema {
    return yup
      .mixed()
      .test({
        test: async () => {
          const withEmptyTitle = this.resultBlocks.filter(block =>
            Boolean(block && isDocumentEmpty(block.title.document))
          );

          if (withEmptyTitle.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.ResultTitleNotEmpty),
              this,
              withEmptyTitle.reduce(
                (props, block: XQuizBlockRecord) => ({
                  ...props,
                  [block.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      })
      .test({
        test: async () => {
          const withEmptyText = this.resultBlocks.filter(block =>
            Boolean(block && isDocumentEmpty(block.text.document))
          );

          if (withEmptyText.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.ResultTextNotEmpty),
              this,
              withEmptyText.reduce(
                (props, block: XQuizBlockRecord) => ({
                  ...props,
                  [block.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      });
  }

  public changeBlockTitle(id: string, change: Editor) {
    return this.update('resultBlocks', blocks => {
      const blockIndex = blocks.findIndex((block: XQuizBlockRecord) => block.id === id);

      return blocks.setIn([blockIndex, 'title'], change.value);
    });
  }

  public changeBlockText(id: string, change: Editor) {
    return this.update('resultBlocks', blocks => {
      const blockIndex = blocks.findIndex((block: XQuizBlockRecord) => block.id === id);

      return blocks.setIn([blockIndex, 'text'], change.value);
    });
  }

  public get media(): MediaMeta | undefined {
    const images = this.resultBlocks.reduce((list: List<Block>, block: XQuizBlockRecord) => {
      return list.concat(block.text.document.getBlocksByType(SlateBlock.IMAGE));
    }, List<Block>());

    if (images.size) {
      const ids = images.map((ib: ImageBlock) => ib.data.get('id')).toSet();
      return Map({[WidgetMediaType.IMAGES]: ids}) as MediaMeta;
    }
    return undefined;
  }
}

decorate(XQuizRecord, {
  resultBlocks: property(List())
});
record()(XQuizRecord);
export default XQuizRecord;
