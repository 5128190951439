import './dotsAnimation.scss';

const Dot = () => {
  return <span className="dot"></span>;
};

export const DotsAnimation = () => {
  const dotCount = 3;

  const renderDots = () => {
    const dots = [];
    for (let i = 0; i < dotCount; i++) {
      dots.push(<Dot key={i} />);
    }
    return dots;
  };

  return <span className="dots-container">{renderDots()}</span>;
};
