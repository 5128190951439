import React, {
  type FC,
  type PropsWithChildren,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState
} from 'react';
import {Portal} from 'react-portal';
import {
  createPaint,
  createStage,
  Paint,
  type PaintPlugin,
  type POrientation,
  type PStage,
  withPlugins
} from '@englex/paint';
import {
  DrawLayerProvider,
  Focusable,
  MobilePaint,
  PaintReact,
  PaintTool,
  withColors,
  withFontSize,
  withReact,
  withSelectable,
  withThickness,
  withTools
} from '@englex/paint-react';
import {withHistory} from '@englex/paint-history';
import classNames from 'classnames';

import {PaintEditor} from './PaintEditor';
import {Toolbar} from '../Toolbar';
import {withImage, withStoredState, withThicknessPreset} from './plugins';

import './Painter.scss';

type ConditionalProps =
  | {stage: PStage; onChange: (stage: PStage) => void; width?: never; height?: never} // Controlled component, onChange should change 'stage' prop
  | {stage: PStage; onChange?: never; width?: never; height?: never} // Uncontrolled component with default stage
  | {stage?: never; onChange?: never; width?: never; height?: never} // Uncontrolled component with no props (default width and height)
  | {stage?: never; onChange?: never; width: number; height: number}; // Uncontrolled component with width and height props

export type PainterProps = PropsWithChildren<
  ConditionalProps & {
    paint?: Paint;
    plugins?: PaintPlugin[];
    scale?: number;
    orientation?: POrientation;
    toolbarPortalNode?: HTMLDivElement | null;
  }
>;

const defaultPlugins: PaintPlugin[] = [
  withReact(),
  withSelectable(),
  withHistory,
  withTools({active: PaintTool.Pencil}),
  withColors(),
  withThickness(),
  withThicknessPreset(),
  withFontSize(),
  withImage,
  withStoredState
];

export const Painter: FC<PainterProps> = ({
  stage: stageProp,
  children,
  toolbarPortalNode,
  scale,
  orientation,
  paint: paintProp,
  onChange: onChangeProp,
  plugins = defaultPlugins,
  width = 800,
  height = 600
}) => {
  const initialStage = useMemo(() => createStage(width, height), [height, width]);

  const paint = useMemo(() => {
    return paintProp || withPlugins(createPaint(initialStage), ...plugins);
  }, [initialStage, paintProp, plugins]);

  const [localStage, setLocalStage] = useState(onChangeProp ? null : stageProp || initialStage);
  const stage = localStage || stageProp || initialStage;

  const onChange = useCallback(
    (stage: PStage) => (onChangeProp ? onChangeProp(stage) : setLocalStage(stage)),
    [onChangeProp]
  );

  const toolbar = children ? children : <Toolbar />;

  useLayoutEffect(() => {
    if (scale && paint.scale !== scale) {
      Paint.setScale(paint, scale);
    }
  }, [paint, scale]);

  useLayoutEffect(() => {
    if (orientation && paint.orientation !== orientation) {
      Paint.setOrientation(paint, orientation);
    }
  }, [orientation, paint]);

  useLayoutEffect(() => {
    // uncontrolled component could receive the new width and height props,
    // so we should use the new initial stage in this case
    if (
      !stageProp &&
      localStage &&
      (initialStage.attrs.width !== localStage.attrs.width ||
        initialStage.attrs.height !== localStage.attrs.height)
    ) {
      setLocalStage(initialStage);
    }
  }, [initialStage, localStage, paint, stageProp]);

  return (
    <PaintReact stage={stage} paint={paint} onChange={onChange || setLocalStage}>
      <Focusable className={classNames('painter', {'painter-mobile': MobilePaint.is(paint)})}>
        <DrawLayerProvider>
          <PaintEditor />
          {toolbarPortalNode ? <Portal node={toolbarPortalNode}>{toolbar}</Portal> : toolbar}
        </DrawLayerProvider>
      </Focusable>
    </PaintReact>
  );
};
