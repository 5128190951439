import {List} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetTitle, WidgetType} from '../../interface';
import {type CardJSON, type CardsJSON, type CardsProperties, CardsType} from './interface';
import WidgetRecord from '../../WidgetRecord';
import CardRecord from './CardRecord';

class CardsRecord extends WidgetRecord<string, string, unknown> implements CardsProperties {
  private static createCard(type: CardsType, card: Partial<CardJSON> = {}) {
    const cardProps =
      type === CardsType.TEXT
        ? {
            type,
            id: card.id,
            text: card.text,
            title: card.title
          }
        : {
            type,
            id: card.id,
            text: card.text,
            image: card.image
          };

    return new CardRecord(cardProps);
  }

  private static createCards(raw: CardsJSON) {
    if (List.isList(raw.cardsList)) {
      return raw.cardsList;
    }

    return List(
      (raw.cardsList as CardJSON[]).map((card: CardJSON) =>
        CardsRecord.createCard(raw.cardsType, card)
      )
    );
  }

  private static getDefaultValue(raw: CardsJSON) {
    return raw.values || raw.cardsList.find(Boolean)?.id;
  }

  public declare readonly cardsType: CardsType;

  public declare readonly cardsList: List<CardRecord>;

  public get type() {
    return WidgetType.CARDS;
  }

  public get title(): string {
    return `${WidgetTitle.Cards} (${this.cardsType})`;
  }

  constructor(raw: CardsJSON) {
    super(raw);

    this.initValues({
      cardsType: raw.cardsType,
      cardsList: CardsRecord.createCards(raw),
      values: CardsRecord.getDefaultValue(raw)
    });
  }

  public toJSON(): CardsJSON {
    return {
      ...super.toJSON(),
      cardsType: this.cardsType,
      cardsList: this.cardsList.map((card: CardRecord) => card.toJSON(this.cardsType)).toArray()
    };
  }

  public setValuesFromJSON(values: string): this {
    return this.withMutations(record => {
      record.set('values', values);
    });
  }

  public get isAvailableSelection() {
    return true;
  }
}

decorate(CardsRecord, {
  cardsType: property(''),
  cardsList: property(List())
});
record()(CardsRecord);
export default CardsRecord;
