import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';

class SpeakingRecord extends FormattedTextRecord {
  public get type() {
    return WidgetType.SPEAKING;
  }
}

record()(SpeakingRecord);
export default SpeakingRecord;
