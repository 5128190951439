import {defineMessages} from 'react-intl';

export const chatMessages = defineMessages({
  EditMessageDropdownMessage: {
    id: 'Message.EditDropdownMessage'
  },
  DeleteMessageDropdownMessage: {
    id: 'Message.DeleteDropdownMessage'
  },
  SelectAction: {
    id: 'Message.SelectAction'
  },
  DeleteMessageWampErrorText: {
    id: 'Message.DeleteMessageWampErrorText'
  },
  DeleteMessageWampErrorHeader: {
    id: 'Message.DeleteMessageWampErrorHeader'
  },
  DeletedMessageText: {
    id: 'Message.DeletedMessageText'
  },
  UpdatedMessageTitle: {
    id: 'Message.UpdatedPencilTitle'
  },
  ImageUnavailable: {
    id: 'Message.ImageUnavailable'
  }
});
