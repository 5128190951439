import {type Action, type Dispatch} from 'redux';
import {type ThunkAction} from 'redux-thunk';
import {captureException, withScope} from '@sentry/react';
import {createSearchParams} from 'react-router-dom';

import {PersistState, purgePersistState} from 'store/persist';
import {wampConnectionClose} from 'services/wamp/actions/action';
import {type AppState, CLOSED, NOT_CONNECTED} from 'store/interface';
import {replace} from 'store/router';

import {dashboardUrl} from '../../helpers/url';
import {
  type LogoutCreator,
  type RequestLoginAction,
  type RequestLoginCreator,
  type RequestProfileCreator,
  type RequestProfileSettings,
  type SetTokenAction,
  type UserAction
} from './interface';
import {
  GOT_TOKEN,
  LOGOUT,
  REQUEST_LOGIN,
  REQUEST_PROFILE,
  TOKEN_EXPIRED,
  TOKEN_RENEWED
} from './actionTypes';
import {isLoginAtDashboardWeb} from '../helpers';

export const requestLogin: RequestLoginCreator = (token: string): RequestLoginAction => ({
  type: REQUEST_LOGIN,
  payload: {
    request: {
      method: 'post',
      url: '/v1/user/login',
      data: {
        token
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }
});

export const requestProfile: RequestProfileCreator = (settings: RequestProfileSettings) => {
  const {id, ...params} = settings;

  return {
    type: REQUEST_PROFILE,
    payload: {
      request: {
        method: 'get',
        url: `/v1/user/${id}`,
        params,
        validateStatus: status => status === 200
      }
    }
  };
};

const logoutAction: LogoutCreator = () => ({
  type: LOGOUT
});

const userLogout: () => ThunkAction<Promise<void>, AppState, void, Action> =
  () => (dispatch: Dispatch<Action>) => {
    dispatch(logoutAction());
    return purgePersistState(PersistState.user);
  };

export const logout =
  (dashboardUri = '/logout'): ThunkAction<Promise<void>, AppState, never, Action> =>
  async dispatch => {
    try {
      await dispatch(userLogout());
    } catch (e) {
      withScope(scope => {
        scope.setExtra(
          'oldMsg',
          'Something went wrong while purging persist state in logout action'
        );
        captureException(e);
      });
    } finally {
      const dashUrl = dashboardUrl(dashboardUri);
      window.location.replace(
        isLoginAtDashboardWeb() && dashUrl ? dashUrl : window.location.origin
      );
    }
  };

export const loginRequired =
  (refresh = false): ThunkAction<void, AppState, never, Action> =>
  dispatch => {
    const returnBack = window.location.pathname;
    if (isLoginAtDashboardWeb()) {
      dispatch(goDashboardWebLogin(returnBack));
    } else if (refresh) {
      window.location.reload();
    } else {
      dispatch(replace('/login', {state: {returnBack}}));
    }
  };

const goDashboardWebLogin = (returnBack: string) => {
  const params = createSearchParams({vc: returnBack});

  window.location.href = dashboardUrl(`/login?${params}`) || window.location.origin;

  return userLogout();
};

const tokenExpired: UserAction = {
  type: TOKEN_EXPIRED
};

export const tokenRenewed = (token: string): SetTokenAction => ({
  type: TOKEN_RENEWED,
  token
});

export const userSessionExpired: ThunkAction<void, AppState, void, Action> = (
  dispatch,
  getState
) => {
  dispatch(tokenExpired);
  dispatch(userLogout());
  const wampStatus = getState().wamp.status;
  if (wampStatus !== CLOSED && wampStatus !== NOT_CONNECTED) {
    dispatch(wampConnectionClose());
  }
};

export const gotToken = (token: string): SetTokenAction => ({
  type: GOT_TOKEN,
  token
});
