import React from 'react';
import {type ReducersMapObject} from 'redux';
import {ReactReduxContext} from 'react-redux';

import {type ReduxContext} from './index';

/**
 * High Order Component for inject async reducer for async redux route
 *
 * @usage export default injectReducer({home: homeReducer})(connect(mapStateToProps, mapDispatchToProps)(HomePageContainer))
 * @param asyncReducers
 * @returns {(WrappedComponent:React.ComponentClass<{}>)=>Component}
 */

type ReducersInjector = (
  asyncReducers: ReducersMapObject
) => <P extends {} = {}>(WrappedComponent: React.ComponentType<P>) => React.ComponentType<P>;

interface State {
  injected: boolean;
}

const injectReducer: ReducersInjector = asyncReducers => {
  return <P extends {} = {}>(WrappedComponent: React.ComponentType<P>): React.ComponentType<P> => {
    class Component extends React.Component<P, State> {
      public static displayName = `injectReducer(${
        WrappedComponent.displayName || WrappedComponent.name
      })`;
      public static contextType = ReactReduxContext;

      public declare context: ReduxContext;
      public state: State = {injected: false};

      componentDidMount(): void {
        if (this.state.injected) {
          return;
        }
        if (this.context.store.injectReducers) {
          this.context.store.injectReducers(asyncReducers);
        } else if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.trace('store.injectReducer function does not exist on store object');
        }
        this.setState({injected: true});
      }

      public render() {
        return this.state.injected ? React.createElement(WrappedComponent, this.props) : null;
      }
    }
    return Component;
  };
};
export default injectReducer;
