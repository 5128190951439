/**** authorizer related errors ****/

// the error below might occur if the authorization worker is offline
export const AUTHORIZATION_FAILED = 'wamp.error.authorization_failed';

// the error below might occur if authorization worker is online but user has no access to call/publish/subscribe to URI
export const NOT_AUTHORIZED = 'wamp.error.not_authorized';

/**** callee worker related errors ****/

// the error below might occur if the worker which is responsible to handle the procedure (callee) is offline
export const NO_SUCH_PROCEDURE = 'wamp.error.no_such_procedure';
