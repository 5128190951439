import React from 'react';

import {FontSize} from './FontSize';

import './FontSizeList.scss';

interface Props {
  items: number[];
  active?: number;
  onSelect: (size: number) => void;
}

export const FontSizeList: React.FC<Props> = ({items, active, onSelect}) => {
  return (
    <div className="font-size-list">
      {items.map(value => (
        <FontSize key={value} value={value} active={value === active} onSelect={onSelect} />
      ))}
    </div>
  );
};
