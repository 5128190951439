import {type MessageDescriptor} from 'react-intl';

import * as toastr from '../components/toastr';
import {RTCMessages} from './components/RTCmessages';
import {rtcErrors} from './rtcclient';
import {recipientOfflineCallErrorMessage, unsupportedBrowserCallErrorMessage} from './types';

type FormatMessageFunc = (
  messageDescriptor: MessageDescriptor,
  values?: {[key: string]: string | number | boolean | Date}
) => string;

export const handleError = (
  error: Error,
  formatMessage: FormatMessageFunc,
  isVideoCall?: boolean
) => {
  switch (error.name) {
    case 'TrackStartError':
    case 'NotReadableError': {
      toastr.error(
        formatMessage(RTCMessages.MediaErrorHeader),
        formatMessage(RTCMessages.MediaErrorText)
      );
      break;
    }
    case rtcErrors.UncriticalError:
    case rtcErrors.ReconnectAborted:
      break;
    case rtcErrors.GetStreamTimeoutError:
      toastr.error(
        formatMessage(RTCMessages.CaptureStreamErrorHeader),
        formatMessage(RTCMessages.CaptureStreamErrorMessage)
      );
      break;
    case 'NotFoundError':
    case 'OverconstrainedError':
      if (isVideoCall) {
        toastr.error(
          formatMessage(RTCMessages.MediaNotFoundErrorHeader),
          formatMessage(RTCMessages.MediaNotFoundErrorMessage)
        );
      } else {
        toastr.error(
          formatMessage(RTCMessages.MicNotFoundErrorHeader),
          formatMessage(RTCMessages.MicNotFoundErrorMessage)
        );
      }
      break;
    default:
      switch (error.message) {
        case unsupportedBrowserCallErrorMessage:
          toastr.error('', formatMessage(RTCMessages.CallToUnsupportedBrowserError));
          break;
        case recipientOfflineCallErrorMessage:
          toastr.error(
            formatMessage(RTCMessages.CallInterrupted),
            formatMessage(RTCMessages.UserGoneOffline)
          );
          break;
        default:
          toastr.error('', formatMessage(RTCMessages.CallStartedUnknownErr));
      }
  }
};
