export const multipleChoiceQuestionsNumberDefault = 3;
export const multipleChoiceQuestionsNumberMin = 1;
export const multipleChoiceQuestionsNumberMax = 99;

export const multipleChoiceAnswersNumberDefault = 4;
export const multipleChoiceAnswersNumberMin = 2;
export const multipleChoiceAnswersNumberMax = 99;

export const pictureChoiceQuestionsNumberDefault = 3;
export const pictureChoiceQuestionsNumberMin = 1;
export const pictureChoiceQuestionsNumberMax = 99;

export const pictureChoiceAnswersNumberDefault = 3;
export const pictureChoiceAnswersNumberMin = 2;
export const pictureChoiceAnswersNumberMax = 99;

export const matchingDefaultAnswersNumber = 2;
export const matchingInitialQuestionsNumber = 3;

export const oddWordAnswersNumberMin = 2;

export const freeChoiceAnswersNumberMin = 1;
