import React, {type FC, memo, useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useDictionarySubscribe} from 'store/dictionary/useDictionarySubscribe';
import {useDictionaryStore} from 'store/dictionary/useDictionaryStore';
import {setStudentTeachers} from 'store/studentTeachers/actions';
import {setIsReadonly, setSearch, setStudentId} from 'store/dictionary/actions';
import {type AppState, type StudentTeacher, type StudentTeachers} from 'store/interface';
import BreadcrumbsPanel from 'components/Breadcrumbs/BreadcrumbsPanel';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';

import {StudentSelector} from '../shared/StudentSelector';
import {DictionaryEntries} from './DictionaryEntries';
import {ContextProviders} from '../shared/contexts/list/ContextProviders';
import {useStudentTeachers} from '../hooks/useStudentTeachers';
import {DeleteEntryModal} from './DeleteEntryModal';
import {useSidebarDictionaryInitializer} from './useSidebarDictionaryInitializer';
import {EditListController} from '../shared/CreateList/EditListController';
import {useSearchInOD} from './DictionaryArticle/hooks/useSearchInOD';

import './SidebarDictionary.scss';

interface Props {
  studentId: number;
  studentTeachers: StudentTeachers;
}

export const Dictionary: FC<{selectedStudentTeacher?: StudentTeacher}> = memo(
  ({selectedStudentTeacher}) => {
    useDictionaryStore();
    const dispatch = useDispatch();
    const userId = useSelector<AppState, number>(s => s.user.id!);
    const studentId = useSelector<AppState, number | undefined>(s => s.dictionary?.studentId);
    const studentTeachers = useSelector<AppState, StudentTeachers | undefined>(
      s => s.studentTeachers?.studentTeachers
    );

    const {isLoading} = useStudentTeachers(
      studentTeachers => {
        const studentTeachersObject: StudentTeachers = {};
        for (const st of studentTeachers) {
          studentTeachersObject[st.id] = {...st, recipient: st.student!};
        }
        dispatch(setStudentTeachers(studentTeachersObject));
      },
      true,
      false,
      studentTeachers
    );

    useEffect(() => {
      if (selectedStudentTeacher && !studentId) {
        dispatch(setStudentId(+selectedStudentTeacher.student_id));
      }
      const isReadonly = !studentTeachers
        ? true
        : studentId === userId
          ? !Object.values(studentTeachers).find(st => !st.deleted_at)
          : !!Object.values(studentTeachers).find(st => Number(st.student_id) === studentId)
              ?.deleted_at;
      dispatch(setIsReadonly(isReadonly));
    }, [dispatch, selectedStudentTeacher, studentId, studentTeachers, userId]);

    return (
      <div className="sidebar-dictionary">
        {isLoading || !studentTeachers || !studentId ? (
          <Loader />
        ) : (
          <DictionaryInner studentId={studentId} studentTeachers={studentTeachers} />
        )}
      </div>
    );
  }
);

const DictionaryInner: FC<Props> = memo(({studentId, studentTeachers}) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState<number>(0);
  const [hasError, setHasError] = useState(false);

  const dictionaryIsReadonly = useSelector<AppState, boolean>(s => !!s.dictionary?.isReadonly);
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');
  const hasLists = useSelector<AppState, boolean>(s => {
    if (isStudent) return !!s.dictionary?.lists?.length;
    return !!s.dictionary?.lists?.filter(l => l.createdById === s.user.id || l.exerciseInstanceId)
      .length;
  });

  const {error, isSubscribing, retry} = useDictionarySubscribe();

  const {
    isError: bootstrapperError,
    isLoading: bootstrapperLoading,
    reload: reloadBootstrapper
  } = useSidebarDictionaryInitializer(studentId);

  const searchInODData = useSearchInOD(true);

  const changeActiveStudentTeacher = useCallback(
    (id: number) => {
      const newStudentId = studentTeachers?.[id].student_id;
      if (newStudentId) {
        dispatch(setStudentId(+newStudentId));
        searchInODData.closeResultOfSearchingInOD();
        dispatch(setSearch());
      }
    },
    [dispatch, searchInODData, studentTeachers]
  );

  if (isSubscribing || bootstrapperLoading) return <Loader />;

  if (error || bootstrapperError)
    return (
      <WampErrorMask
        reload={() => {
          retry();
          reloadBootstrapper();
        }}
      />
    );

  return (
    <ContextProviders {...searchInODData}>
      {!isStudent && (
        <BreadcrumbsPanel>
          <StudentSelector
            changeActiveStudentTeacher={changeActiveStudentTeacher}
            studentTeachers={studentTeachers}
            popoverHeight={height}
            popoverLeftPosition="0"
            popoverTopPosition="37px"
            popoverContainerClassName="chat-popover-wide"
            isSidebar={true}
          />
        </BreadcrumbsPanel>
      )}
      <DictionaryEntries
        hasError={hasError}
        hasLists={hasLists}
        resizeHandler={setHeight}
        setHasError={setHasError}
      />
      {!dictionaryIsReadonly && (
        <>
          <EditListController dictionaryOwnerId={studentId} dictionaryOwnerRole="student" />
          <DeleteEntryModal />
        </>
      )}
    </ContextProviders>
  );
});
