import {useCallback} from 'react';

import {deleteEntryFromDictionaryRequest} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';

import {type DictionaryOwnerRole, useDictionaryContext} from '../shared/contexts';

export const useDeleteEntryFromDictionary = (
  successHandler: () => void,
  errorHandler: () => void,
  entryId?: string
): [boolean, () => void] => {
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {isLoading, apiRequest} = useApiRequest2<[number, DictionaryOwnerRole, string]>(
    deleteEntryFromDictionaryRequest,
    successHandler,
    errorHandler
  );

  const deleteFromDictionary = useCallback(() => {
    if (entryId) apiRequest(dictionaryOwnerId, dictionaryOwnerRole, entryId);
  }, [apiRequest, entryId, dictionaryOwnerRole, dictionaryOwnerId]);

  return [isLoading, deleteFromDictionary];
};
