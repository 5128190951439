import React, {type FC, memo} from 'react';
import classNames from 'classnames';
import {useFocused, useSlateStatic} from 'slate-react';
import {type Editor} from 'slate';

type BsSizeShort = 'sm' | 'lg';
type WrapperTag = 'div' | 'span';

interface Props {
  className?: string | ((editor: Editor, isFocused: boolean) => string);
  bsSize?: BsSizeShort;
  tag?: WrapperTag;
}

export const SlateContainer: FC<Props> = memo(
  ({bsSize, className: classNameProp, tag, children}) => {
    const editor = useSlateStatic();
    const isFocused = useFocused();

    const className = classNames(
      typeof classNameProp === 'function' ? classNameProp?.(editor, isFocused) : classNameProp
    );

    const Tag = tag === 'span' ? tag : 'div';

    return (
      <Tag
        id={editor.getEditorId()}
        className={classNames(className, {
          focus: isFocused,
          [bsSize as string]: !!bsSize
        })}
      >
        {children}
      </Tag>
    );
  }
);

SlateContainer.displayName = 'SlateContainer';
