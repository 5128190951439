import {type FC} from 'react';
import type React from 'react';

import {useLocalState} from '../hooks/useLocalState';
import {type ProviderProps} from './types';

interface Props {
  children: (props: ProviderProps) => React.ReactElement;
}

export const StateProvider: FC<Props> = ({children}) => {
  const stateProps = useLocalState();

  return children(stateProps);
};
