import React from 'react';

interface Props {
  localVideoEnabled?: boolean;
  smallVideoRatio: number;
  resizing: boolean;
  onMouseDown: (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => void;
  onLocalVideoPause: () => void;
  localVideoRef: (el: HTMLVideoElement) => void;
}

export default class LocalVideo extends React.PureComponent<Props, {}> {
  public render() {
    const {
      localVideoEnabled,
      smallVideoRatio,
      resizing,
      onMouseDown,
      localVideoRef,
      onLocalVideoPause
    } = this.props;
    if (localVideoEnabled) {
      const style = {
        width: `${smallVideoRatio * 100}%`,
        height: `${smallVideoRatio * 100}%`
      };
      return (
        <div className={`local-video ${resizing ? 'resizing' : ''}`} style={style}>
          <video
            width="100%"
            height="100%"
            ref={localVideoRef}
            onPause={onLocalVideoPause}
            autoPlay={true}
            playsInline={true}
          />
          <div onMouseDown={onMouseDown} onTouchStart={onMouseDown} className="resize-trigger">
            <div className="background" />
            <div className={`stripes ${resizing ? 'active' : ''}`}>
              <div className="stripe" />
              <div className="stripe" />
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}
