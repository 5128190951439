import {NoteType, type NoteDataByType} from 'store/exercise/player/widgets/Note/interface';

export const noteDataByType: NoteDataByType[] = [
  {
    type: NoteType.SPEAKING_FOCUS,
    iconName: 'bullhorn',
    title: 'Speaking Focus'
  },
  {
    type: NoteType.PRONUNCIATION_FOCUS,
    iconName: 'microphone',
    title: 'Pronunciation Focus'
  },
  {
    type: NoteType.WRITING_FOCUS,
    iconName: 'pencil',
    title: 'Writing Focus'
  },
  {
    type: NoteType.SPELLING_FOCUS,
    iconName: 'virc-note',
    title: 'Spelling Focus'
  },
  {
    type: NoteType.VOCABULARY_FOCUS,
    iconName: 'book',
    title: 'Vocabulary Focus'
  },
  {
    type: NoteType.HOW_TO_STRATEGIES,
    iconName: 'lightbulb-o',
    title: 'How To'
  },
  {
    type: NoteType.GRAMMAR_FOCUS,
    iconName: 'pc-course',
    title: 'Grammar Focus'
  },
  {
    type: NoteType.CULTURAL_FOCUS,
    iconName: 'virc-culture',
    title: 'Cultural Focus'
  }
];

export const noteIconSet = {
  bullhorn: '\uEA59',
  microphone: '\uEB9A',
  pencil: '\uEBF4',
  'virc-note': '\uED13',
  book: '\uEA51',
  'lightbulb-o': '\uEB73',
  'pc-course': '\uEBC7',
  'virc-culture': '\uED3E'
};
