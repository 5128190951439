import React from 'react';
import {components, type DropdownIndicatorProps} from 'react-select';

import Icon from '../Icon';

export default function DropdownIndicator<O, M extends boolean>(
  props: DropdownIndicatorProps<O, M>
) {
  const DefaultIndicator = components.DropdownIndicator;
  return (
    <DefaultIndicator {...props}>
      <Icon name="angle-down" />
    </DefaultIndicator>
  );
}
