import React, {type FC, memo, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import {CSSTransition} from 'react-transition-group';

import {type AppState} from 'store/interface';
import {messageSend} from 'components/Chat/actions/action';
import Icon from 'components/Icon';

import {useStartPhrases} from './useStartPhrases';

import './StartPhrases.scss';

const timeoutAnimationMs = 200;

interface Props {
  closed: boolean;
  toggleClosed: () => void;
}

export const StartPhrases: FC<Props> = memo(({closed, toggleClosed}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [animationProcess, setAnimationProcess] = useState(false);

  const selectedRoomId = useSelector<AppState, number | undefined>(
    state => state.chat!.selectedRoomId
  );

  const randomPhrases = useStartPhrases();

  const onClick = (message: string) => {
    toggleClosed();
    sendMessageAfterAnimation(message);
  };

  const onClose = () => {
    toggleClosed();
  };

  const onTransitionStart = useCallback(() => {
    setAnimationProcess(true);
  }, []);

  const onTransitionEnd = useCallback(() => {
    setAnimationProcess(false);
  }, []);

  const sendMessageAfterAnimation = (message: string) => {
    setTimeout(() => dispatch(messageSend(selectedRoomId, message)), timeoutAnimationMs);
  };

  const startPhrasesBtn = (
    <Button className={classNames('start-phrases-btn btn-circle')} onClick={onClose}>
      <Icon name="commenting" size="lg" />
    </Button>
  );

  const startPhrasesList = randomPhrases.map((phrase, index) => {
    return (
      <div className="phrase" key={index} onClick={() => onClick(phrase)}>
        <div className="phrase-message">
          <span>{phrase}</span>
        </div>

        <Icon className="phrase-icon" name={'send-o'} size="lg" />
      </div>
    );
  });

  const renderCloseBtn = !closed && (
    <div className="start-phrases-close" onClick={onClose}>
      {intl.formatMessage({id: 'Common.Close'})}{' '}
      <Icon className="icon-close" name={'pc-close'} size="lg" />
    </div>
  );

  const renderContent = closed ? startPhrasesBtn : startPhrasesList;

  return (
    <CSSTransition
      in={closed}
      onEnter={onTransitionStart}
      onEntered={onTransitionEnd}
      onExit={onTransitionStart}
      onExited={onTransitionEnd}
      timeout={timeoutAnimationMs}
      classNames={{
        exitActive: 'opened',
        exitDone: 'opened'
      }}
    >
      <div className="start-phrases">
        {animationProcess ? null : (
          <>
            {renderCloseBtn}
            {renderContent}
          </>
        )}
      </div>
    </CSSTransition>
  );
});
