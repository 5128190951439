import {type List, Map} from 'immutable';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';
import {type Block, type Editor} from '@englex/slate';

import {genKey, valueJSONFromText} from 'components/Slate/utils';
import {record} from 'immutable-record/decorator/record';
import {type MediaMeta, WidgetMediaType, WidgetType} from 'store/exercise/player/interface';
import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseCardJSON,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseValues
} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import {type XWordPictureSetProperties, type XWordPictureSetProps} from './interface';
import XWordPictureSetCardRecord from './XWordPictureSetCardRecord';
import {ValidationWidgetError} from '../../customErrors';
import validationMessages from '../../i18n';
import XWordPictureSetBaseRecord from '../XWordPictureSetBaseRecord';
import {type SchemaResult, WPSTheme} from '../baseInterface';

class XWordPictureSetRecord
  extends XWordPictureSetBaseRecord<
    WordPictureSetBaseCardId,
    WordPictureSetBaseValues<
      WordPictureSetBaseCardId,
      WordPictureSetBaseMetaDataProperties<WordPictureSetBaseCardId>
    >,
    XWordPictureSetCardRecord
  >
  implements XWordPictureSetProperties
{
  public static minCountCards = 2;

  public static fromCards = (
    cards: Map<string, XWordPictureSetCardRecord>
  ): XWordPictureSetRecord => {
    return new XWordPictureSetRecord({
      id: genKey(),
      type: WidgetType.WORD_PICTURE_SET,
      task: valueJSONFromText(''),
      cards,
      defaultValues: {
        isCollaborativeManagement: false,
        cardIds: cards ? cards.map(card => ({cardId: card!.get('id')})).toArray() : []
      },
      isDefaultHidden: false,
      isNotInteractive: false,
      widgetTheme: WPSTheme.ORANGE
    });
  };

  public schema(intl: IntlShape): SchemaResult {
    const parentSchema = super.schema(intl);
    const schema = yup.object({
      cards: yup
        .mixed()
        .test({
          test: (cards: Map<string, XWordPictureSetCardRecord>) => {
            const emptyCards = cards.filter((card: XWordPictureSetCardRecord) => !card.image);

            if (emptyCards.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.BlankImages),
                this,
                emptyCards.reduce(
                  (props, card: XWordPictureSetCardRecord) => ({
                    ...props,
                    [card.id]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test({
          test: (cards: Map<string, XWordPictureSetCardRecord>) => {
            const emptyCards = cards.filter(
              (card: XWordPictureSetCardRecord) => card.text.document.text.trim() === ''
            );

            if (emptyCards.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.BlankWords),
                this,
                emptyCards.reduce(
                  (props, card: XWordPictureSetCardRecord) => ({
                    ...props,
                    [card.id]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test({
          test: (cards: Map<string, XWordPictureSetCardRecord>) => {
            if (cards.size < XWordPictureSetRecord.minCountCards) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.CardsMinSize),
                this,
                {CardsMinSize: true}
              );
            }

            return true;
          }
        })
    });

    return parentSchema.concat(schema);
  }

  constructor(raw: XWordPictureSetProps) {
    super(raw);
    this.initValues({
      id: raw.id || genKey(),
      cards: raw ? this.createCards(raw) : Map(),
      isDefaultHidden: raw.isDefaultHidden || false,
      isNotInteractive: raw.isNotInteractive || false,
      defaultValues: raw.defaultValues
        ? this.createValues(raw.defaultValues)
        : this.createNewDefaultValue()
    });
  }

  public createCard(cardJSON: WordPictureSetBaseCardJSON) {
    return new XWordPictureSetCardRecord(cardJSON);
  }

  public get type() {
    return WidgetType.WORD_PICTURE_SET;
  }

  public get excerpt(): string {
    return `${this.task.document.text} ${(
      this.defaultValues.get('cardIds') as List<WordPictureSetBaseCardId>
    )
      .map(
        (value: WordPictureSetBaseCardId, key: number) =>
          `${key + 1}. [Image] ${this.cards
            .get(value.cardId)
            .text.document.getBlocks()
            .map((block: Block) => block.text)
            .join(' ')}`
      )
      .join(' ')}`.trim();
  }

  public get media(): MediaMeta | undefined {
    const images = this.cards.map((c: XWordPictureSetCardRecord) => c.image).toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }

  public changeCardImage(cardId: string, image: number) {
    return this.setIn(['cards', cardId, 'image'], image);
  }

  public changeCardText(cardId: string, editor: Editor) {
    return this.setIn(['cards', cardId, 'text'], editor.value);
  }
}

record()(XWordPictureSetRecord);
export default XWordPictureSetRecord;
