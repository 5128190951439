import type {
  Router,
  Location,
  RouterState as RemixRouterState,
  RouterNavigateOptions
} from '@remix-run/router';
import {type Action} from 'redux';

export const NAVIGATE = '@@router/NAVIGATE';
export const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

type NavigateMethods = 'push' | 'replace' | 'go';
type HistoryAction = RemixRouterState['historyAction'];
type RouterNavigateArgs = Parameters<Router['navigate']>;

type RouterState = {
  location?: Location | null;
  action?: HistoryAction | null;
  previousLocations?: {location?: Location | null; action?: HistoryAction | null}[];
};

interface LocationChangeAction extends Action {
  type: typeof LOCATION_CHANGE;
  payload: {location: Location; action: HistoryAction};
}

interface NavigateAction extends Action {
  type: typeof NAVIGATE;
  payload: {
    method: string;
    args: RouterNavigateArgs;
  };
}

export type {
  Router,
  Location,
  HistoryAction,
  NavigateMethods,
  RouterNavigateArgs,
  RouterNavigateOptions,
  RouterState,
  LocationChangeAction,
  NavigateAction
};
