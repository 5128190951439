import React, {type FC, type RefObject, useCallback, useRef} from 'react';
import classNames from 'classnames';
import {fixedScales, Paint} from '@englex/paint';
import {MobilePaint, type ReactPaint} from '@englex/paint-react';

import isShortcut from '../../../helpers/shortcut';
import {ToolbarButton} from './button/ToolbarButton';
import Icon from '../../Icon';
import {ScaleSlider} from './ScaleSlider';

interface Props {
  paint: ReactPaint;
  toggleRef: RefObject<HTMLButtonElement>;
  setTooltipVisible: (v: boolean) => void;
  fitToWidth?: boolean;
  setFitToWidth?: (f: boolean) => void;
  scale?: number;
  setScale?: (s: number) => void;
  tooltipVisible?: boolean;
  showSlider?: boolean;
}

export const ScalePicker: FC<Props> = ({
  toggleRef: buttonRef,
  showSlider,
  paint,
  tooltipVisible,
  setTooltipVisible,
  scale,
  setScale,
  setFitToWidth,
  fitToWidth
}) => {
  const firstButtonRef = useRef<HTMLButtonElement | null>(null);
  const lastButtonRef = useRef<HTMLButtonElement | null>(null);

  const onEdgeToolKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLButtonElement>) => {
      if (!tooltipVisible) {
        return;
      }
      if (isShortcut(e, 'tab') && lastButtonRef.current === e.target) {
        e.preventDefault();
        e.stopPropagation();
        firstButtonRef.current?.focus({preventScroll: true});
      }
      if (isShortcut(e, 'shift+tab') && firstButtonRef.current === e.target) {
        e.preventDefault();
        e.stopPropagation();
        lastButtonRef.current?.focus({preventScroll: true});
      }
    },
    [tooltipVisible]
  );

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {index} = e.currentTarget.dataset;
      if (index) {
        MobilePaint.is(paint) ? MobilePaint.setFitToSize(paint, false) : setFitToWidth?.(false);
        setScale ? setScale(fixedScales[index]) : Paint.setScale(paint, fixedScales[index]);
        setTooltipVisible(false);
        buttonRef.current?.focus();
      }
    },
    [buttonRef, paint, setFitToWidth, setScale, setTooltipVisible]
  );

  const onFitToWidthClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (!fitToWidth) {
        MobilePaint.is(paint) ? MobilePaint.setFitToSize(paint, true) : setFitToWidth?.(true);
        setTooltipVisible(false);
        buttonRef.current?.focus();
      }
    },
    [paint, buttonRef, fitToWidth, setFitToWidth, setTooltipVisible]
  );

  const showFitToWidthButton = setFitToWidth || MobilePaint.is(paint);

  const activeFitToWidth = fitToWidth || (paint as ReactPaint).fitToSize;

  return (
    <div className="painter-scale-button-overlay">
      {showFitToWidthButton && (
        <ToolbarButton
          onClick={onFitToWidthClick}
          className={classNames({active: activeFitToWidth})}
        >
          <Icon name="arrows-h" />
        </ToolbarButton>
      )}
      {fixedScales.map((s, i) => {
        const isFirst = i === 0;
        const isLast = i === fixedScales.length - 1;
        return (
          <ToolbarButton
            ref={isFirst ? firstButtonRef : isLast ? lastButtonRef : null}
            key={i}
            data-index={i}
            onClick={onClick}
            onKeyDown={isFirst || isLast ? onEdgeToolKeyDown : undefined}
            className={classNames({
              active: !activeFitToWidth && Math.abs((scale || paint.scale) - s) < 0.1
            })}
          >
            <span className="title">{`${s * 100}%`}</span>
          </ToolbarButton>
        );
      })}
      {showSlider && (
        <ScaleSlider scale={scale} setScale={setScale} setFitToWidth={setFitToWidth} />
      )}
    </div>
  );
};
