import React from 'react';
import {Outlet} from 'react-router-dom';
import {connect, type MapStateToProps} from 'react-redux';
import Spinner from 'react-spinkit';
import Bowser from 'bowser';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Action} from 'redux';
import {setTag, setUser} from '@sentry/react';

import * as toastr from 'components/toastr';
import {type Dispatch} from 'services/wamp/actions/interface';
import {wampConnectionOpen} from 'services/wamp/actions/action';
import {type AppState, CLOSED, CONNECTED, NOT_CONNECTED, type Role} from 'store/interface';
import {
  type GetActions,
  type OnSubscribe,
  withWampSubscription
} from 'services/wamp/withWampSubscription';
import {defaultAutobahnClientName} from 'services/wamp/actions/types';
import {requestTeacherSettings, setTeacherSettings} from 'routes/ClassRoom/actions/action';
import {IsAuthenticated, isLoginAtDashboardWeb} from 'authentication';
import {replace} from 'store/router';

import {
  type RequestProfileSuccess,
  type RequestTeacherSettingsSuccess
} from '../authentication/actions/interface';
import {loginRequired, requestProfile} from '../authentication/actions/action';
import {changeLocale} from '../i18n/actions/actions';
import WampDisconnectedModal from '../components/modals/WampDisconnected';
import {type SetIsMobileCreator} from './actions/interface';
import {setIsMobile} from './actions/action';
import TokenExpiredModal from '../components/modals/TokenExpired';
import {getAppVersion, pageBlurred, pageFocused, subscribeToAppUpdate} from '../common/action';
import {default as AppOfflineModal} from '../components/modals/AppOffline';
import ExerciseAudio from '../components/media/AudioPlayer/containers/Exercise/Audio';
import Icon from '../components/Icon';
import OutdatedAppToastButton from '../components/toastr/OutdatedAppToastButton';
import {dashboardUrl} from '../helpers/url';

interface AuthenticatedAreaStateProps {
  userId: number;
  role?: Role;
  isNotConnected: boolean;
  showLoader: boolean;
  showLayout: boolean;
  showDisconnectedModal: boolean;
  sessionExpired: boolean;
  isWampConnected: boolean;
  newAppVersion?: string;
  appVersion: string;
  appHashChanged?: boolean;
  isMobile?: boolean;
  showAppOfflineModal?: boolean;
  enforceDesktop?: true;
  shouldRenderPlayer: boolean;
}

interface DispatchAuthenticatedAreaProps {
  wampAutoConnect: () => void;
  setIsMobile: SetIsMobileCreator;
  requestProfile: (id: number) => Promise<boolean>;
  requestTeacherSettings: () => Promise<boolean>;
  renewSession: () => void;
  pageBlurred: () => void;
  pageFocused: () => void;
  noProfile: () => void;
}

const messages = defineMessages({
  OutdatedAppToastrHeader: {
    id: 'Toastr.OutdatedAppToastrHeader'
  },
  OutdatedAppToastrText: {
    id: 'Toastr.OutdatedAppToastrText'
  }
});

interface AuthenticatedAreaProps
  extends DispatchAuthenticatedAreaProps,
    AuthenticatedAreaStateProps {}

class AuthLayout extends React.Component<AuthenticatedAreaProps & WrappedComponentProps, {}> {
  public componentDidUpdate(prevProps: AuthenticatedAreaProps) {
    const {appHashChanged, newAppVersion} = this.props;
    if (newAppVersion && !prevProps.newAppVersion) {
      this.showOutdatedAppToast(newAppVersion);
      setTag('newRelease', newAppVersion);
    }
    if (appHashChanged && !prevProps.appHashChanged && !newAppVersion) {
      this.showOutdatedAppToast();
    }
  }

  public componentDidMount() {
    window.addEventListener('focus', this.resolvePageVisibility);
    window.addEventListener('blur', this.resolvePageVisibility);
    this.props.setIsMobile(this.isMobile);
    if (this.props.isNotConnected) {
      this.connect();
    }
    // perform check after page load.
    if (!document.hasFocus()) {
      this.resolvePageVisibility();
    }
  }

  public render() {
    return (
      <div style={{height: '100%'}}>
        <WampDisconnectedModal
          show={this.props.showDisconnectedModal && !this.props.showAppOfflineModal}
          connect={this.props.wampAutoConnect}
          isMobile={this.props.isMobile}
        />
        <AppOfflineModal show={!!this.props.showAppOfflineModal} isMobile={this.props.isMobile} />
        <TokenExpiredModal
          show={this.props.sessionExpired}
          renewSession={this.props.renewSession}
        />
        {this.props.shouldRenderPlayer && <ExerciseAudio />}
        {this.props.showLayout ? <Outlet /> : this.renderLoader()}
      </div>
    );
  }

  private get isMobile() {
    if (this.props.enforceDesktop !== undefined) {
      return false;
    }
    return Bowser.getParser(window.navigator.userAgent).getPlatformType() !== 'desktop';
  }

  private connect = async () => {
    const {
      requestProfile: getProfile,
      requestTeacherSettings,
      userId,
      isNotConnected,
      wampAutoConnect,
      noProfile,
      role
    } = this.props;
    setUser({
      id: String(this.props.userId)
    });
    const profileReceived = await getProfile(userId);
    if (!profileReceived) {
      noProfile();
    } else if (isNotConnected) {
      wampAutoConnect();
    }

    if (role === 'teacher') requestTeacherSettings();
  };

  private resolvePageVisibility = () => {
    if (document.hasFocus()) {
      this.props.pageFocused();
    } else {
      this.props.pageBlurred();
    }
  };

  private renderLoader() {
    return this.props.showLoader ? (
      <Spinner name="three-bounce" className="center-block brand-primary" fadeIn="none" />
    ) : null;
  }

  private showOutdatedAppToast(newAppVersion?: string) {
    const formatMessage = this.props.intl.formatMessage;
    const toastrHeader = formatMessage(messages.OutdatedAppToastrHeader, {
      version: newAppVersion || null
    });
    const toastrText = formatMessage(messages.OutdatedAppToastrText);
    toastr.warning(toastrHeader, toastrText, {
      removeOnHover: false,
      icon: <Icon name="warning" />,
      component: <OutdatedAppToastButton />,
      showCloseButton: true
    });
  }
}

const mapStateToProps: MapStateToProps<AuthenticatedAreaStateProps, {}, AppState> = state => ({
  userId: state.user.id!,
  role: state.user.role,
  isNotConnected: state.wamp.status === NOT_CONNECTED,
  showLayout: !!state.layout.show,
  showLoader: !state.layout.show && state.wamp.status !== CLOSED,
  showDisconnectedModal: state.wamp.status === CLOSED && !state.user.sessionExpired,
  sessionExpired: !!state.user.sessionExpired,
  isWampConnected: state.wamp.status === CONNECTED,
  newAppVersion: state.common.newVersion,
  appVersion: state.common.version,
  appHashChanged: !!state.common.newHash && state.common.hash !== state.common.newHash,
  isMobile: state.layout.isMobile,
  showAppOfflineModal: state.layout.showAppOfflineModal,
  enforceDesktop: state.layout.enforceDesktop,
  shouldRenderPlayer: Boolean(state.media.audio && state.media.audio.audioFile)
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>) => ({
  requestProfile: async (id: number) => {
    try {
      const profileResponse = await dispatch<RequestProfileSuccess>(
        requestProfile({
          id,
          expand: 'profile'
        })
      );
      const locale = profileResponse.payload.data.locale;
      await dispatch(changeLocale(locale));
      return true;
    } catch (error) {
      return false;
    }
  },
  requestTeacherSettings: async () => {
    try {
      const response = await dispatch<RequestTeacherSettingsSuccess>(requestTeacherSettings());

      dispatch(setTeacherSettings(response.payload.data));

      return true;
    } catch (error) {
      return false;
    }
  },
  setIsMobile: (isMobile: boolean) => {
    return dispatch(setIsMobile(isMobile));
  },
  wampAutoConnect: () => {
    return dispatch(wampConnectionOpen(defaultAutobahnClientName));
  },
  renewSession: () => dispatch(loginRequired(true)),
  pageBlurred: () => dispatch(pageBlurred()),
  pageFocused: () => dispatch(pageFocused()),
  noProfile: () =>
    isLoginAtDashboardWeb()
      ? (window.location.href = dashboardUrl() || window.location.origin)
      : dispatch(replace('/logout'))
});

const getActions: GetActions = () => [subscribeToAppUpdate()];

const onSubscribe: OnSubscribe = (actions, dispatch) => dispatch(getAppVersion());

const IntlLayout = injectIntl(AuthLayout);

const SubscribedLayout = withWampSubscription(getActions, onSubscribe)(IntlLayout);

const ConnectedLayout = connect(mapStateToProps, mapDispatchToProps)(SubscribedLayout);

export const AuthenticatedLayout = () => (
  <IsAuthenticated>
    <ConnectedLayout />
  </IsAuthenticated>
);
