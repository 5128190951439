import {type Block, type Document, type Node} from '@englex/slate';
import {List} from 'immutable';

import {isListBlock} from '../../../../../utils';
import {type ListBlock} from '../../../../../interface';

export function getClosestListAncestor(document: Document, b: Block): Block | null {
  return document.getClosest(b.key, ancestor => isListBlock(ancestor)) as Block;
}

export const getListIndentLevel = (document: Document, list: Block): number => {
  const ancestors = document.getAncestors(list.key);
  if (!ancestors) {
    return 0;
  }
  return ancestors.filter(isListBlock).size;
};

export const getAncestorListOfDepth = (
  document: Document,
  b: Block,
  level: number
): Block | null => {
  const currentIndentLevel = getListIndentLevel(document, b);
  if (isListBlock(b) && currentIndentLevel === level) {
    return b;
  }
  if (currentIndentLevel < level) {
    return null;
  }
  const ancestors = document.getAncestors(b.key) || List<Node>();
  const listAncestors = ancestors.filter(isListBlock);
  if (!listAncestors) {
    return null;
  }

  return listAncestors.get(level) as ListBlock;
};

export const getPrevSiblingsNumber = (document: Document, b: Node): number => {
  const prevSibling = document.getPreviousSibling(b.key);
  if (prevSibling) {
    return getPrevSiblingsNumber(document, prevSibling) + 1;
  }
  return 0;
};
