import moment from 'moment';

import {
  type ChatMessage,
  type ChatState,
  type ImageViewerImage,
  type MessageMeta,
  type Room,
  type Rooms,
  type TextMessageMeta
} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {type WampCallActionWithRoomId, type WampErrorAction} from 'services/wamp/actions/interface';
import uriIdResolver from 'services/wamp/uriIdResolver';
import {wampClientActionType} from 'services/wamp/actions/actionTypes';
import {onlineState2UserStatus} from 'helpers/user';
import {TextMessageTypes} from 'common/enums';

import {
  CHANGE_ACTIVE_TAB,
  CHANGE_ROOMS_FILTER,
  CHANGE_USER_SESSIONS,
  CLEAR_CHAT,
  CLEAR_CHAT_CLIPBOARD,
  CLEAR_MESSAGE_TO_UPDATE,
  CLEAR_ROOM_MESSAGES_EXEPT_SELECTED,
  DELETED_MESSAGE_RECEIVED,
  GET_PRIVATE_ROOMS_FAIL,
  GET_PRIVATE_ROOMS_LIST_FAIL,
  GET_PRIVATE_ROOMS_LIST_SUCCESS,
  GET_PRIVATE_ROOMS_SUCCESS,
  GET_ROOM_MESSAGES,
  GET_ROOM_MESSAGES_FAIL,
  GET_ROOM_MESSAGES_SUCCESS,
  HIDE_START_PHRASES,
  MESSAGE_DELETE,
  MESSAGE_DELETE_FAIL,
  MESSAGE_DELETE_SUCCESS,
  MESSAGE_READ,
  MESSAGE_READ_FAIL,
  MESSAGE_RECEIVED,
  MESSAGE_SEND,
  MESSAGE_SEND_FAIL,
  MESSAGE_SEND_SUCCESS,
  MESSAGE_UPDATE,
  MESSAGE_UPDATE_FAIL,
  MESSAGE_UPDATE_SUCCESS,
  PROMOTE_MESSAGE_TO_UPDATE,
  RECIPIENT_TYPING_STARTED,
  RECIPIENT_TYPING_STOPPED,
  SELECT_ROOM,
  SET_CHAT_CLIPBOARD,
  SET_NOTIFICATION_TO_FALSE,
  TOGGLE_START_PHRASES,
  SUBSCRIBE_TO_ROOM_FAIL,
  TOGGLE_ROOMS_POPOVER,
  TOGGLE_SCROLLED_TO_BOTTOM,
  TYPING_STARTED,
  TYPING_STOPPED,
  UPDATED_MESSAGE_RECEIVED
} from '../actions/actionTypes';
import {
  type ChangeFilterAction,
  type SetChatClipboardAction
} from '../../../routes/ClassRoom/actions/interface';
import {
  type ChangeActiveTabAction,
  type ChangeUserStatusAction,
  type ChatAction,
  type DeletedMessageReceivedAction,
  type GetPrivateRoomsSuccessAction,
  type GetRoomMessagesAction,
  type GetRoomMessagesSuccessAction,
  type MessageReadAction,
  type MessageReadFailAction,
  type MessageReceivedAction,
  type MessageSendAction,
  type MessageSendSuccessAction,
  type MessageUpdateAction,
  type MessageUpdateSuccessAction,
  type PromoteMessageToUpdateAction,
  type RecipientTypingStartedAction,
  type RecipientTypingStoppedAction,
  type SelectRoomAction,
  type StartPhrasesAction,
  type TypingStartedAction,
  type UpdatedMessageReceivedAction
} from '../actions/interface';
import {WAMP_BROWSER_CHECK_FAIL} from '../../../webRTC/action/actionTypes';
import {type ToggleElementAction} from '../../../common/interface';
import {roomsReducer} from './roomsReducer';
import {IMAGES_ACTION_HANDLERS} from './imageReducer';
import parseMediaFromMessageText from './parseMediaFromMessageText';
import {checkIfParsedImage} from '../utils';

export const getUnreadMessagesCount = (rooms?: Rooms) => {
  if (!rooms) {
    return 0;
  }
  let unreadMessagesCount = 0;

  for (const id in rooms) {
    unreadMessagesCount += rooms[id].newMessagesCount;
  }
  return unreadMessagesCount;
};

const messageSendResponse = (
  state: ChatState,
  action: MessageSendSuccessAction | WampErrorAction<{}, {}, MessageSendAction>
) => {
  const roomId = uriIdResolver(action.wamp!.meta.previousAction.wamp.uri).chatroomId;
  const newState: ChatState = {
    ...state,
    rooms: {
      ...state.rooms,
      [roomId]: {
        ...state.rooms![roomId],
        sendingMessageAwait: false
      }
    }
  };
  return newState;
};

const messageUpdateResponse = (
  state: ChatState,
  action: MessageUpdateSuccessAction | WampErrorAction<{}, {}, MessageUpdateAction>
) => {
  const roomId = action.wamp!.meta.previousAction.roomId;
  const newState: ChatState = {
    ...state,
    rooms: {
      ...state.rooms,
      [roomId]: {
        ...state.rooms![roomId],
        sendingMessageAwait: false
      }
    }
  };
  return newState;
};

const messageUpdate = (state: ChatState, action: WampCallActionWithRoomId<{}, {}>) => ({
  ...state,
  rooms: {
    ...state.rooms,
    [action.roomId]: {
      ...state.rooms![action.roomId],
      sendingMessageAwait: true
    }
  }
});

function getMessageMeta(message: ChatMessage) {
  if (message.thumbnail) {
    message.thumbnail.clientId = `${message.thumbnail.id}${message.id}_server`;
  }
  if (message.meta) {
    if (typeof message.meta === 'string') {
      return JSON.parse(message.meta);
    }
    return message.meta;
  }
  if (message.text) {
    return parseMediaFromMessageText(message);
  }
  return {type: TextMessageTypes.text};
}

function getImages(room: Room) {
  const imagesArray: ImageViewerImage[] = [];
  if (!room.messages) {
    return imagesArray;
  }
  room.messages.forEach(message => {
    if ((message.meta as TextMessageMeta).parsedMedia) {
      (message.meta as TextMessageMeta).parsedMedia!.forEach(
        media => checkIfParsedImage(media) && imagesArray.push({id: media.imageId, src: media.src})
      );
    }
    if (message.thumbnail && !message.deleted_at) {
      const image: ImageViewerImage = {
        id: message.thumbnail.clientId,
        src: message.thumbnail.image!.urls[0],
        retina: message.thumbnail.image!.urls[1]
      };
      imagesArray.push(image);
    }
  });
  return imagesArray;
}

export const initialChatState: ChatState = {
  popoverOpenTabId: '1',
  roomsLoaded: false,
  roomsFilter: '',
  shouldLoadPrivateRoomsList: true,
  images: [],
  scrollbarAtBottom: true,
  selectedYTVideoId: null
};

const ACTION_HANDLERS: ActionHandlersList<ChatState, ChatAction> = {
  [CHANGE_ROOMS_FILTER]: (state: ChatState, action: ChangeFilterAction) => ({
    ...state,
    roomsFilter: action.filter
  }),
  [GET_PRIVATE_ROOMS_LIST_SUCCESS]: (state: ChatState, action: GetPrivateRoomsSuccessAction) => {
    return {
      ...state,
      rooms: roomsReducer(state.rooms, action),
      roomsLoaded: state.roomsLoaded || !action.wamp.callResult.args[0],
      shouldLoadPrivateRoomsList: false
    };
  },
  [CLEAR_ROOM_MESSAGES_EXEPT_SELECTED]: (state: ChatState, action: ChatAction) => {
    const newState = {
      ...state,
      rooms: {...state.rooms}
    };
    for (const id in newState.rooms) {
      if (id !== String(state.selectedRoomId)) {
        newState.rooms[id] = {
          ...newState.rooms[id],
          allMessagesLoaded: false
        };
        newState.rooms[id].messages = [];
      }
    }
    return newState;
  },
  [GET_PRIVATE_ROOMS_SUCCESS]: (state: ChatState, action: GetPrivateRoomsSuccessAction) => {
    if (!action.wamp.callResult.args[0]) {
      return {
        ...state,
        roomsLoaded: true
      };
    }
    if (!state.roomsLoaded) {
      const activeRoom: Room | undefined = action.wamp.callResult.args[0].find(
        room => !room.deleted_at
      );
      return {
        ...state,
        rooms: roomsReducer(state.rooms, action),
        selectedRoomId: activeRoom
          ? activeRoom.id
          : action.wamp.callResult.args[0][0]
            ? action.wamp.callResult.args[0][0].id
            : undefined,
        roomsLoaded: true
      };
    }
    const newState: ChatState = {
      ...state,
      rooms: roomsReducer(state.rooms, action)
    };
    return newState;
  },
  [GET_PRIVATE_ROOMS_FAIL]: (state: ChatState) => ({
    ...state,
    wampError: true
  }),
  [GET_PRIVATE_ROOMS_LIST_FAIL]: (state: ChatState) => ({
    ...state,
    wampError: true
  }),
  [SELECT_ROOM]: (state: ChatState, action: SelectRoomAction) => ({
    ...state,
    selectedRoomId: action.id,
    images: state.rooms && state.rooms[action.id] ? getImages(state.rooms[action.id]) : [],
    messageToUpdateId: undefined,
    scrollbarAtBottom: true
  }),
  [CHANGE_ACTIVE_TAB]: (state: ChatState, action: ChangeActiveTabAction) => ({
    ...state,
    popoverOpenTabId: action.id
  }),
  [GET_ROOM_MESSAGES]: (state: ChatState, action: GetRoomMessagesAction) => {
    const newState: ChatState = {
      ...state,
      rooms: {...state.rooms}
    };
    if (!action.withoutLoader) {
      const roomId: number = uriIdResolver(action.wamp.uri).chatroomId;
      newState.rooms![roomId] = {
        ...newState.rooms![roomId],
        loadingNewMessages: true
      };
    }
    return newState;
  },
  [GET_ROOM_MESSAGES_SUCCESS]: (state: ChatState, action: GetRoomMessagesSuccessAction) => {
    if (!state.rooms) {
      return state;
    }
    const newState: ChatState = {
      ...state,
      rooms: {
        ...state.rooms
      }
    };
    const activeRoomId = uriIdResolver(action.wamp.meta.previousAction.wamp.uri).chatroomId;

    newState.rooms![activeRoomId] = {
      ...state.rooms[activeRoomId],
      loadingNewMessages: undefined
    };
    if (!state.rooms[activeRoomId].messages) {
      newState.rooms![activeRoomId].messages = [];
    } else {
      newState.rooms![activeRoomId].messages = [...state.rooms[activeRoomId].messages];
    }
    const receivedMessages = action.wamp.callResult.args[0].filter(
      (receivedMessage: ChatMessage) => {
        return !newState.rooms![activeRoomId].messages.find(
          storedMessage => storedMessage.id === receivedMessage.id
        );
      }
    );
    if (action.wamp.callResult.args[0].length < action.wamp.meta.previousAction.wamp.args![0]) {
      newState.rooms![activeRoomId] = {
        ...newState.rooms![activeRoomId],
        allMessagesLoaded: true
      };
    }
    receivedMessages.forEach((message: ChatMessage) => {
      const chatMessage: ChatMessage = {...message};
      const meta: MessageMeta = getMessageMeta(chatMessage);
      newState.rooms![activeRoomId].messages.unshift({
        ...chatMessage,
        meta
      });
    });
    if (newState.rooms![activeRoomId].messages) {
      newState.rooms![activeRoomId].messages.sort((message1, message2) =>
        new Date(message1.created_at).getTime() < new Date(message2.created_at).getTime() ? -1 : 1
      );
    }

    newState.images = getImages(newState.rooms![activeRoomId]);
    return newState;
  },
  [GET_ROOM_MESSAGES_FAIL]: (
    state: ChatState,
    action: WampErrorAction<{}, {}, GetRoomMessagesAction>
  ) => {
    const roomId = uriIdResolver(action.wamp!.meta.previousAction.wamp.uri).chatroomId;
    if (!state.rooms) {
      return state;
    }
    const newState: ChatState = {
      ...state,
      wampError: true,
      rooms: {
        ...state.rooms,
        [roomId]: {
          ...state.rooms[roomId],
          loadingNewMessages: false
        }
      }
    };
    return newState;
  },
  [MESSAGE_RECEIVED]: (state: ChatState, action: MessageReceivedAction) => {
    const newState: ChatState = {
      ...state,
      shouldPlayNotification: !action.message.own && !action.message.meta && action.shouldNotify,
      scrollbarAtBottom: action.message.own ? true : state.scrollbarAtBottom,
      rooms: {
        ...state.rooms,
        [action.roomId]: {...state.rooms![action.roomId]}
      }
    };
    const activeRoom: Room = newState.rooms![action.roomId];
    const createdAt: string = moment(action.message.created_at).format('YYYY-MM-DD[T]HH:mm:ssZ');
    if (action.message.new) {
      activeRoom.newMessagesCount++;
    }
    if (activeRoom.messages) {
      activeRoom.messages = [...activeRoom.messages];
      activeRoom.messages.push({
        ...action.message,
        created_at: createdAt,
        meta: getMessageMeta(action.message)
      });
      newState.images = getImages(activeRoom);
    }
    return newState;
  },
  [UPDATED_MESSAGE_RECEIVED]: (state: ChatState, action: UpdatedMessageReceivedAction) => {
    const {roomId, updatedMessage} = action;
    const newState: ChatState = {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {...state.rooms![roomId]}
      }
    };
    if (newState.rooms![roomId].messages) {
      newState.rooms![roomId].messages = newState.rooms![roomId].messages.map(message => {
        if (message.id === updatedMessage.id) {
          const alteredMessage = {
            ...updatedMessage,
            new: message.new
          };
          alteredMessage.meta = getMessageMeta(updatedMessage);
          return alteredMessage;
        }
        return message;
      });
    }
    newState.images = getImages(newState.rooms![roomId]);
    return newState;
  },
  [DELETED_MESSAGE_RECEIVED]: (state: ChatState, action: DeletedMessageReceivedAction) => {
    const {roomId, deletedMessage} = action;
    const newState: ChatState = {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {...state.rooms![roomId]}
      }
    };
    if (newState.rooms![roomId].messages) {
      newState.rooms![roomId].messages = newState.rooms![roomId].messages.map(message => {
        if (message.id === deletedMessage.id) {
          const alteredMessage = {
            ...deletedMessage,
            new: message.new
          };
          alteredMessage.meta = getMessageMeta(alteredMessage);
          return alteredMessage;
        }
        return message;
      });
    }
    if (deletedMessage.id === newState.messageToUpdateId) {
      delete newState.messageToUpdateId;
    }
    newState.images = getImages(newState.rooms![roomId]);
    return newState;
  },
  [MESSAGE_READ]: (state: ChatState, action: MessageReadAction) => {
    if (!state.rooms) {
      return state;
    }
    const activeRoom: Room = {...state.rooms[action.roomId]};
    const readMessagePos = activeRoom.messages.findIndex(
      message => message.id === uriIdResolver(action.wamp.uri).messageId
    );
    if (readMessagePos !== -1 && activeRoom.messages[readMessagePos].new) {
      const newMessage = {
        ...activeRoom.messages[readMessagePos],
        new: false
      };
      activeRoom.messages = [...activeRoom.messages];
      activeRoom.messages.splice(readMessagePos, 1, newMessage);
      activeRoom.newMessagesCount--;
    }
    return {
      ...state,
      rooms: {
        ...state.rooms,
        [action.roomId]: activeRoom
      }
    };
  },
  [MESSAGE_READ_FAIL]: (state: ChatState, action: MessageReadFailAction) => {
    const roomId = action.wamp!.meta.previousAction.roomId;
    const messageId = uriIdResolver(action.wamp!.meta.previousAction.wamp.uri).messageId;
    const newState: ChatState = {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {
          ...state.rooms![roomId],
          newMessagesCount: state.rooms![roomId].newMessagesCount + 1
        }
      }
    };
    const activeRoom: Room = newState.rooms![roomId];
    activeRoom.messages.forEach((message, index, array) => {
      if (message.id === messageId) {
        const newMessage = {
          ...message,
          new: true
        };
        activeRoom.messages = [...array];
        activeRoom.messages.splice(index, 1, newMessage);
      }
    });
    return newState;
  },
  [MESSAGE_SEND]: (state: ChatState, action: MessageSendAction) => {
    const roomId = uriIdResolver(action.wamp.uri).chatroomId;
    const newState: ChatState = {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {
          ...state.rooms![roomId],
          sendingMessageAwait: true
        }
      }
    };
    return newState;
  },
  [MESSAGE_SEND_SUCCESS]: messageSendResponse,
  [MESSAGE_SEND_FAIL]: messageSendResponse,
  [MESSAGE_UPDATE]: messageUpdate,
  [MESSAGE_UPDATE_SUCCESS]: messageUpdateResponse,
  [MESSAGE_UPDATE_FAIL]: messageUpdateResponse,
  [MESSAGE_DELETE]: messageUpdate,
  [MESSAGE_DELETE_SUCCESS]: messageUpdateResponse,
  [MESSAGE_DELETE_FAIL]: messageUpdateResponse,
  [CHANGE_USER_SESSIONS]: (state: ChatState, action: ChangeUserStatusAction) => {
    const newState: ChatState = {
      ...state,
      rooms: {...state.rooms}
    };
    for (const id in newState.rooms!) {
      if (newState.rooms[id].recipient && newState.rooms[id].recipient!.id === action.userId) {
        newState.rooms[id] = {
          ...newState.rooms[id],
          recipient: {
            ...newState.rooms[id].recipient!,
            online: action.onlineState,
            onCall: action.callsNumber
          }
        };
        const recipient = newState.rooms[id].recipient!;
        recipient.userStatus = onlineState2UserStatus(action.onlineState, action.callsNumber);
      }
    }
    return newState;
  },
  [CLEAR_CHAT]: (state: ChatState) => initialChatState,
  [SUBSCRIBE_TO_ROOM_FAIL]: (state: ChatState) => ({
    ...state,
    wampError: true
  }),
  [WAMP_BROWSER_CHECK_FAIL]: (state: ChatState) => ({
    ...state,
    wampError: true
  }),
  [TOGGLE_ROOMS_POPOVER]: (state: ChatState, action: ToggleElementAction) => ({
    ...state,
    roomsPopoverOpen: action.show
  }),
  [TYPING_STARTED]: (state: ChatState, action: TypingStartedAction) => ({
    ...state,
    typingTimerId: action.timerId
  }),
  [TYPING_STOPPED]: (state: ChatState) => {
    const {typingTimerId, ...rest} = state;
    return {...rest};
  },
  [RECIPIENT_TYPING_STARTED]: (state: ChatState, action: RecipientTypingStartedAction) => ({
    ...state,
    rooms: {
      ...state.rooms,
      [action.roomId]: {
        ...state.rooms![action.roomId],
        recipient: state.rooms![action.roomId].recipient
          ? {
              ...state.rooms![action.roomId].recipient,
              typingTimerId: action.timerId
            }
          : undefined
      }
    }
  }),
  [RECIPIENT_TYPING_STOPPED]: (state: ChatState, action: RecipientTypingStoppedAction) => ({
    ...state,
    rooms: state.rooms
      ? {
          ...state.rooms,
          [action.roomId]: {
            ...state.rooms[action.roomId],
            recipient: state.rooms[action.roomId].recipient
              ? {
                  ...state.rooms[action.roomId].recipient,
                  typingTimerId: undefined
                }
              : undefined
          }
        }
      : undefined
  }),
  [PROMOTE_MESSAGE_TO_UPDATE]: (state: ChatState, action: PromoteMessageToUpdateAction) => ({
    ...state,
    messageToUpdateId: action.id
  }),
  [CLEAR_MESSAGE_TO_UPDATE]: (state: ChatState) => ({
    ...state,
    messageToUpdateId: undefined
  }),
  [SET_NOTIFICATION_TO_FALSE]: (state: ChatState) => ({
    ...state,
    shouldPlayNotification: false
  }),
  [wampClientActionType('CONNECTION_RETRYING')]: (state: ChatState) => ({
    ...state,
    shouldLoadPrivateRoomsList: true
  }),
  [wampClientActionType('CONNECTION_CLOSED')]: (state: ChatState) => ({
    ...state,
    shouldLoadPrivateRoomsList: true
  }),
  [TOGGLE_SCROLLED_TO_BOTTOM]: (state: ChatState, action: ToggleElementAction) => ({
    ...state,
    scrollbarAtBottom: action.show
  }),
  [SET_CHAT_CLIPBOARD]: (state: ChatState, action: SetChatClipboardAction) => ({
    ...state,
    clipboard: action.clipboard
  }),
  [CLEAR_CHAT_CLIPBOARD]: (state: ChatState) => ({...state, clipboard: undefined}),
  [TOGGLE_START_PHRASES]: (state: ChatState, action: StartPhrasesAction) => {
    const roomId = action.roomId;

    return {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {
          ...state.rooms![roomId],
          showStartPhrases: !state.rooms?.[action.roomId]?.showStartPhrases
        }
      }
    };
  },
  [HIDE_START_PHRASES]: (state: ChatState, action: StartPhrasesAction) => {
    const roomId = action.roomId;

    return {
      ...state,
      rooms: {
        ...state.rooms,
        [roomId]: {
          ...state.rooms![roomId],
          showStartPhrases: false
        }
      }
    };
  }
};

export default function reducer(
  state: ChatState = initialChatState,
  action: ChatAction
): ChatState {
  const chatReducer = ACTION_HANDLERS[action.type] || IMAGES_ACTION_HANDLERS[action.type];
  return chatReducer ? chatReducer(state, action) : state;
}
