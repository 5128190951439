import React, {type HTMLAttributes} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import './ToggleControlsButton.scss';

type Props = Omit<React.DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, 'ref'>;

export const ToggleControlsButton: React.FC<Props> = ({className, ...rest}) => {
  return (
    <div className={classNames('toggle-tools', className)} {...rest}>
      <Icon name="angle-down" className="toggle-tools__icon" />
    </div>
  );
};
