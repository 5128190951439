/* eslint-disable @typescript-eslint/no-explicit-any */
const OBFUSCATED_ERROR =
  'An invariant failed, however the error is obfuscated because this is an production build.';

function isPlainObject(value: unknown) {
  if (value === null || typeof value !== 'object') return false;
  const proto = Object.getPrototypeOf(value);
  return proto === Object.prototype || proto === null;
}

function invariant(check: false, message?: string | boolean): never;
function invariant(check: true, message?: string | boolean): void;
function invariant(check: any, message?: string | boolean): void;
function invariant(check: boolean, message?: string | boolean) {
  if (!check) throw new Error('[decorate] ' + (message || OBFUSCATED_ERROR));
}

export function decorate<T>(
  clazz: new (...args: any[]) => T,
  decorators: {
    [P in keyof T]?:
      | MethodDecorator
      | PropertyDecorator
      | Array<MethodDecorator>
      | Array<PropertyDecorator>;
  }
): void;
export function decorate<T>(
  object: T,
  decorators: {
    [P in keyof T]?:
      | MethodDecorator
      | PropertyDecorator
      | Array<MethodDecorator>
      | Array<PropertyDecorator>;
  }
): T;
export function decorate(thing: any, decorators: any) {
  import.meta.env.MODE !== 'production' &&
    invariant(isPlainObject(decorators), 'Decorators should be a key value map');
  const target = typeof thing === 'function' ? thing.prototype : thing;
  for (const prop in decorators) {
    let propertyDecorators = decorators[prop];
    if (!Array.isArray(propertyDecorators)) {
      propertyDecorators = [propertyDecorators];
    }
    import.meta.env.MODE !== 'production' &&
      invariant(
        propertyDecorators.every((decorator: any) => typeof decorator === 'function'),
        `Decorate: expected a decorator function or array of decorator functions for '${prop}'`
      );
    const descriptor = Object.getOwnPropertyDescriptor(target, prop);
    const newDescriptor = propertyDecorators.reduce(
      (accDescriptor: any, decorator: any) => decorator(target, prop, accDescriptor),
      descriptor
    );
    if (newDescriptor) Object.defineProperty(target, prop, newDescriptor);
  }
  return thing;
}
