import React, {type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';

interface Props {
  disabled: boolean;
  onClick(): void;
}

export const PronunciationTrigger: FC<Props> = ({disabled, onClick}) => {
  return (
    <Button className="pronunciation-btn" disabled={disabled} onClick={onClick}>
      <Icon name="gear" />
    </Button>
  );
};
