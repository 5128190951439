import React, {type FC, useCallback, useContext, useMemo, useState} from 'react';
import {type Value} from '@englex/slate';

import {
  loadPronunciationsRequest,
  type LoadPronunciationsResponseAction
} from 'store/dictionary/requests';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type PronunciationOption} from 'store/exercise/player/interface';
import {valueFromText} from 'components/Slate/utils';

import {EditEntryActionsContext} from '../contexts/entry/EditEntryContext';
import {pronunciationPhoneticSpellingMaxLength} from '../static';
import {PronunciationModal} from '../PronunciationModal/PronunciationModal';
import {originalToRequestPayload} from '../utils';
import {submitPronunciation} from '../contexts/entry/actions';
import {closeModal as doCloseModal} from '../contexts/actions';
import {type PartOfSpeech} from '../interface';

interface Props {
  original: string;
  phoneticSpelling?: string;
}

export const DictionaryPronunciationModal: FC<Props> = ({original, phoneticSpelling}) => {
  const [pronunciationOptions, setPronunciationOptions] = useState<
    Array<PronunciationOption | null> | undefined
  >();
  const axiosDispatch = useAxiosDispatch();
  const {dispatch} = useContext(EditEntryActionsContext);
  const [phoneticSpellingValue, setPhoneticSpellingValue] = useState<Value>(
    valueFromText(phoneticSpelling)
  );
  const [selectedOptionIndex, setSelectedOptionIndex] = useState<number>(0);

  const validationStatus = useMemo(() => {
    const {length} = phoneticSpellingValue.document.text;
    return length > pronunciationPhoneticSpellingMaxLength ? 'error' : 'success';
  }, [phoneticSpellingValue]);

  const closeModal = useCallback(() => dispatch(doCloseModal()), [dispatch]);

  const loadPronunciations = useCallback(
    (word: string, partOfSpeech?: PartOfSpeech) =>
      axiosDispatch<LoadPronunciationsResponseAction>(
        loadPronunciationsRequest(word, partOfSpeech)
      ),
    [axiosDispatch]
  );

  const loadPronunciationsCallback = useCallback(() => {
    const {partOfSpeech, word} = originalToRequestPayload(original);
    return loadPronunciations(word, partOfSpeech);
  }, [loadPronunciations, original]);

  const submit = useCallback(() => {
    if (!pronunciationOptions) return;
    const selectedOption = pronunciationOptions[selectedOptionIndex];
    const {text: spellingString} = phoneticSpellingValue.document;
    const textIsEmpty = !spellingString.length || spellingString.match(/^\s*$/);
    dispatch(submitPronunciation(selectedOption?.id || null, textIsEmpty ? null : spellingString));
    closeModal();
  }, [
    closeModal,
    dispatch,
    phoneticSpellingValue.document,
    pronunciationOptions,
    selectedOptionIndex
  ]);

  const pronunciationsLoaded = useCallback((options: PronunciationOption[]) => {
    setPronunciationOptions([null, ...options]);
    if (options.length) setSelectedOptionIndex(1);
  }, []);

  return (
    <PronunciationModal
      loadPronunciations={loadPronunciationsCallback}
      pronunciationsLoaded={pronunciationsLoaded}
      pronunciationOptions={pronunciationOptions}
      selectPronunciationOption={setSelectedOptionIndex}
      selectedOptionIndex={selectedOptionIndex}
      phoneticSpelling={phoneticSpellingValue}
      changePhoneticSpelling={setPhoneticSpellingValue}
      validationStatus={validationStatus}
      close={closeModal}
      submit={submit}
    />
  );
};
