import React from 'react';
import {FormattedTime, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {toLocalDate} from 'services/common-methods/toLocalDate';
import {isIOS, isSafari} from 'helpers/browser';
import {TextMessageTypes} from 'common/enums';

import {type MessageType} from './TextMessage';
import Icon from '../../../../Icon';
import {chatMessages} from './chatMessages';

type MessageTextType = MessageType | 'additionalImageBubble';

const additionalImageBubble: MessageTextType = 'additionalImageBubble';

interface Props extends WrappedComponentProps {
  updated_at?: string;
  deleted_at?: string;
  created_at: string;
  own?: boolean;
  type: MessageTextType;
  updatingThisMessage: boolean;
  userTimezone: string;
  isMediaMessage?: true;
}

export default class MessageBubble extends React.PureComponent<Props, {}> {
  public render() {
    const {image, mediaSingleLink} = TextMessageTypes;

    const {updated_at, deleted_at, own, type, updatingThisMessage} = this.props;
    const textClasses = classNames('chat-message-text text', {
      'being-updated': updatingThisMessage,
      updated: !!updated_at,
      deleted: !!deleted_at,
      image: type === image || type === mediaSingleLink || !!this.props.isMediaMessage,
      apple: isIOS || isSafari(),
      own
    });
    if (type in TextMessageTypes || type === additionalImageBubble) {
      return (
        <div className={textClasses}>
          {this.props.children}
          {this.renderTime()}
        </div>
      );
    }
    return null;
  }

  private renderTime = () => {
    const {intl, created_at, userTimezone, updated_at, type} = this.props;
    if (type === additionalImageBubble) {
      return;
    }
    const localUpdatedDate = updated_at
      ? toLocalDate(new Date(updated_at), userTimezone)
      : undefined;
    const pencilIcon: JSX.Element | null =
      this.props.updated_at && !this.props.deleted_at ? (
        <Icon
          name="pc-write"
          className="updated-message-icon"
          title={`${intl.formatMessage(chatMessages.UpdatedMessageTitle)} ${intl.formatDate(
            localUpdatedDate!
          )} ${intl.formatTime(localUpdatedDate!)}`}
        />
      ) : null;
    return (
      <div className="chat-message-time">
        <FormattedTime value={toLocalDate(new Date(created_at), userTimezone)} />
        {pencilIcon}
      </div>
    );
  };
}
