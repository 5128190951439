import {type Action, type Reducer} from 'redux';

import {type RTCState} from 'store/interface';
import {type WampCallResponseAction} from 'services/wamp/actions/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  type CallAnsweredAction,
  type CallStartingAction,
  type ChangeCallDurationAction,
  type ChangeCallStatusAction,
  type ChangeNotificationAction,
  type IncomingCallAction,
  type MuteCallAction,
  type MuteStreamAction,
  type OtherSessionCallAction,
  type RTCAction,
  type SetQualityAction,
  type SetRemoteQualityAction,
  type WampCallStartedSuccessAction,
  type WampSendStateAction
} from '../action/interface';
import {
  WAMP_BROWSER_CHECK_SUCCESS,
  CALL_ANSWERED,
  CALL_END,
  CALL_STARTING,
  CALL_CHANGE_DURATION,
  CALL_CHANGE_MEDIA_DEVICE,
  CALL_CHANGE_STATUS,
  CHANGE_INCOMING_CALL_NOTIFICATION,
  WAMP_GET_RTC_SETTINGS_SUCCESS,
  WAMP_CALL_HANG_UP,
  INCOMING_CALL,
  CALL_MUTE,
  OTHER_SESSION_CALL,
  OUTGOING_CALL,
  PARTNER_SESSION_ID_UPGRADED,
  SET_LOCAL_CONNECTION_QUALITY,
  SET_REMOTE_CONNECTION_QUALITY,
  SHOW_LOW_SPEED_TOAST,
  START_SPEEDTEST,
  TOGGLE_RECALL,
  TOGGLE_REMOTE_VIDEO_DEVICE,
  TOGGLE_SPEEDTEST_NECESSITY,
  WAMP_CALL_ANSWERED_SUCCESS,
  WAMP_CALL_CREATE_SUCCESS,
  WAMP_MUTE_CALL_SUCCESS,
  WAMP_SEND_STATE,
  CALL_ACCEPT,
  CLOSE_BAD_BROWSER
} from '../action/actionTypes';
import {type ToggleElementAction} from '../../common/interface';
import {type PrivateCallRtcSettings} from '../rtcclient';
import {MUTE_REMOTE_STREAM} from '../../components/Chat/actions/actionTypes';
import {type ConnectedIceInfo} from '../types';
import {type RTCCall, type ServerSideBrowserInfo} from '../interface';
import {type UpgradePartnerSessionAction} from '../action/action';

function callEnded(state: RTCState): RTCState {
  return {
    badBrowser: state.badBrowser,
    browserInfo: state.browserInfo,
    performSpeedtest: state.performSpeedtest
  };
}

const initialRTCState: RTCState = {
  performSpeedtest: true
};

const ACTION_HANDLERS: ActionHandlersList<RTCState, RTCAction> = {
  [INCOMING_CALL]: (state: RTCState, action: IncomingCallAction): RTCState => {
    if (!state.call) {
      const newState: RTCState = {
        ...state,
        incomingCall: true,
        call: {
          ...action.call,
          created_at: new Date(action.call.created_at!),
          status: 'connecting'
        },
        remoteStream: action.options,
        partnerSessionId: action.call.caller_session
      };
      return newState;
    }
    return {...state};
  },
  [OUTGOING_CALL]: (state: RTCState): RTCState => {
    if (state.callStarting) {
      const {callStarting, ...newState} = state;
      return {
        ...newState,
        outgoingCall: true
      };
    }
    return {...state};
  },
  [CALL_STARTING]: (state: RTCState, action: CallStartingAction): RTCState => ({
    ...state,
    callStarting: true,
    call: {
      room_id: action.roomId
    },
    localStream: {
      audio: true,
      video: Boolean(action.video)
    }
  }),
  [WAMP_CALL_CREATE_SUCCESS]: (
    state: RTCState,
    action: WampCallStartedSuccessAction
  ): RTCState => ({
    ...state,
    call: state.call
      ? {
          ...state.call,
          ...action.wamp.callResult.args[0],
          status: 'connecting'
        }
      : state.call
  }),
  [CALL_END]: callEnded,
  [WAMP_CALL_HANG_UP]: callEnded,
  [CALL_ACCEPT]: (state: RTCState): RTCState => ({
    ...state,
    answeringAwait: true
  }),
  [WAMP_CALL_ANSWERED_SUCCESS]: (
    state: RTCState,
    action: WampCallResponseAction<RTCCall, {}, Action>
  ): RTCState => {
    const call = action.wamp.callResult.args[0];

    return {
      ...state,
      localStream: {audio: Boolean(call.recipient_audio), video: Boolean(call.recipient_video)}
    };
  },
  [CALL_ANSWERED]: (state: RTCState, action: CallAnsweredAction): RTCState => {
    const {answeringAwait, incomingCall, outgoingCall, ...newState} = state;
    return {
      ...newState,
      partnerSessionId: Number(action.answererUserId) || state.partnerSessionId,
      callInProgress: state.otherSessionCall ? undefined : true,
      remoteStream: action.options ? action.options : state.remoteStream
    };
  },
  [OTHER_SESSION_CALL]: (state: RTCState, action: OtherSessionCallAction): RTCState => ({
    ...state,
    otherSessionCall: true,
    call: action.call || state.call,
    incomingCall: undefined,
    outgoingCall: undefined,
    answeringAwait: undefined
  }),
  [WAMP_BROWSER_CHECK_SUCCESS]: (
    state: RTCState,
    action: WampCallResponseAction<boolean[], ServerSideBrowserInfo, {}>
  ): RTCState => ({
    ...state,
    badBrowser: !action.wamp.callResult.args[0],
    browserInfo: action.wamp.callResult.kwargs
  }),
  [CALL_MUTE]: (state: RTCState, action: MuteCallAction): RTCState => {
    const newState = {
      ...state,
      call: {...state.call!},
      localStream: {...(state.localStream as MediaStreamConstraints)}
    };
    if (action.component === 'video' && state.call) {
      newState.call.status = 'reconnecting';
    }
    if (action.component === 'audio' && state.call) {
      newState.localStream.audio = Boolean(action.micId);
    }
    return newState;
  },
  [WAMP_MUTE_CALL_SUCCESS]: (
    state: RTCState,
    action: WampCallResponseAction<boolean[], {}, {}>
  ): RTCState => ({
    ...state,
    localStream: {
      ...state.localStream,
      video: action.wamp.callResult.args[0]
    }
  }),
  [MUTE_REMOTE_STREAM]: (state: RTCState, action: MuteStreamAction): RTCState => {
    const newState = {
      ...state,
      remoteStream: {
        video: action.video,
        audio: action.audio
      },
      call: {...state.call!}
    };
    if (action.shouldReconnect && newState.call) {
      newState.call.status = 'reconnecting';
    }
    return newState;
  },
  [WAMP_SEND_STATE]: (state: RTCState, action: WampSendStateAction): RTCState => {
    const newState = {
      ...state,
      call: state.call ? {...state.call} : undefined
    };
    if (newState.call && action.wamp.args![1] === 'connected-candidates') {
      newState.iceConnectionInfo = {...state.iceConnectionInfo!};
      newState.iceConnectionInfo.connectedIceIp = (
        action.wamp.args![2] as ConnectedIceInfo
      ).localIp;
      newState.iceConnectionInfo.connectionType = (action.wamp.args![2] as ConnectedIceInfo).type;
    }
    return newState;
  },
  [CALL_CHANGE_STATUS]: (state: RTCState, action: ChangeCallStatusAction): RTCState => ({
    ...state,
    call: !state.call
      ? undefined
      : {
          ...state.call,
          status: action.status
        }
  }),
  [CALL_CHANGE_MEDIA_DEVICE]: (state: RTCState): RTCState => ({
    ...state,
    call: state.call
      ? {
          ...state.call,
          status: 'reconnecting'
        }
      : state.call
  }),
  [CALL_CHANGE_DURATION]: (state: RTCState, action: ChangeCallDurationAction): RTCState => ({
    ...state,
    call: state.call
      ? {
          ...state.call,
          duration: action.duration
        }
      : undefined
  }),
  [CHANGE_INCOMING_CALL_NOTIFICATION]: (
    state: RTCState,
    action: ChangeNotificationAction
  ): RTCState => ({
    ...state,
    callNotificationId: action.notificationId
  }),
  [SET_LOCAL_CONNECTION_QUALITY]: (state: RTCState, action: SetQualityAction): RTCState => ({
    ...state,
    localConnectionQuality: {
      ip: action.wamp.kwargs!.ip,
      jitter: action.wamp.kwargs!.j,
      uploadingSpeed: action.wamp.kwargs!.u,
      downloadingSpeed: action.wamp.kwargs!.d,
      ping: action.wamp.kwargs!.p
    },
    speedtestRunnung: false
  }),
  [START_SPEEDTEST]: (state: RTCState): RTCState => ({
    ...state,
    speedtestRunnung: true
  }),
  [TOGGLE_SPEEDTEST_NECESSITY]: (state: RTCState, action: ToggleElementAction): RTCState => ({
    ...state,
    performSpeedtest: action.show,
    localConnectionQuality: undefined
  }),
  [WAMP_GET_RTC_SETTINGS_SUCCESS]: (
    state: RTCState,
    action: WampCallResponseAction<PrivateCallRtcSettings[], {}, {}>
  ): RTCState => {
    const newState = {
      ...state,
      iceConnectionInfo: {
        availableCandidates: [...action.wamp.callResult.args[0].configuration.iceServers!]
      },
      speedtestGloballyDisabled: !action.wamp.callResult.args[0].configuration.speedTestEnabled
    };

    return newState;
  },
  [SET_REMOTE_CONNECTION_QUALITY]: (state: RTCState, action: SetRemoteQualityAction): RTCState => ({
    ...state,
    remoteConnectionQuality: action.quality,
    remoteSpeedtestTimeout: action.timeout
  }),
  [SHOW_LOW_SPEED_TOAST]: (state: RTCState): RTCState => ({
    ...state,
    lowSpeedToastWasShown: true
  }),
  [TOGGLE_RECALL]: (state: RTCState, action: ToggleElementAction): RTCState => ({
    ...state,
    call: !state.call
      ? undefined
      : {
          ...state.call,
          isRecalling: action.show,
          status: !state.call.isRecalling && action.show ? 'reconnecting' : state.call.status
        }
  }),
  [TOGGLE_REMOTE_VIDEO_DEVICE]: (state: RTCState, action: ToggleElementAction): RTCState => ({
    ...state,
    remoteStream: {
      ...state.remoteStream,
      video: action.show
    }
  }),
  [PARTNER_SESSION_ID_UPGRADED]: (
    state: RTCState,
    action: UpgradePartnerSessionAction
  ): RTCState => ({
    ...state,
    partnerSessionId: state.partnerSessionId && action.sessionId
  }),
  [CLOSE_BAD_BROWSER]: (state): RTCState => {
    return {...state, shouldHideBadBrowser: true};
  }
};

const rtcReducer: Reducer<RTCState, RTCAction> = (state = initialRTCState, action) => {
  const rtcreducer = ACTION_HANDLERS[action.type];
  return rtcreducer ? rtcreducer(state, action) : state;
};

export default rtcReducer;
