import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {toLocalDay} from 'services/common-methods';

export const useChunkIds = (timezone: string, updatedAt: string): string[] =>
  useSelector<AppState, string[]>(s => {
    const updatedAtDay = toLocalDay(new Date(updatedAt), timezone).toString();
    return (s.dictionary?.entries || [])
      .filter(e => {
        return updatedAtDay === toLocalDay(new Date(e.updatedAt), timezone).toString();
      })
      .map(e => e.id);
  });
