import {useState, useCallback, useRef} from 'react';
import {useIntl} from 'react-intl';

import {type EditEntryData} from 'components/Dictionary/shared/interface';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {createWordEntry, loadPronunciationsRequest} from 'store/dictionary/requests';
import * as toastr from 'components/toastr';
import {determineDirectionOfTranslation} from 'routes/Dictionary/utils';
import {
  type DictionaryOwnerRole,
  useDictionaryContext
} from 'components/Dictionary/shared/contexts';
import {DictionarySearchMode} from 'common/enums';

import {useSearchingInODContext} from '../contexts/searchingInODContext';
import {type CreateWordData, type PronunciationData} from '../interfaces';

type Callback = (entryId: string) => void;

export const useAddWordToDictionary = (isSidebar: boolean) => {
  const {formatMessage} = useIntl();

  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {includedTranslations, setIncludedTranslations} = useSearchingInODContext();
  const [currentOriginal, setCurrentOriginal] = useState<string | undefined>(undefined);
  const [currentTranslation, setCurrentTranslation] = useState<string | undefined>(undefined);
  const [isAddFromErrorModal, setIsAddFromErrorModal] = useState<boolean>(false);
  const [notChangedTranslation, setNotChangedTranslation] = useState<[string, string] | undefined>(
    undefined
  );

  const {
    currentScrambledWord,
    scrambledArticles,
    textArticlesStory,
    textArticlesCache,
    searchMode,
    textArticle: dictionaryArticle
  } = useSearchingInODContext();

  const callbackRef = useRef<Callback | undefined>();

  const textArticle = !isSidebar
    ? textArticlesCache.getTextArticleFromCache(
        textArticlesStory.getStoryItem(textArticlesStory.currencyLocationKey!)
      )
    : dictionaryArticle;

  const currentArticle = !!textArticle
    ? textArticle
    : currentScrambledWord
      ? scrambledArticles[currentScrambledWord]
      : null;

  const translationDirection = determineDirectionOfTranslation(currentArticle?.word);
  const isFromEnglish = translationDirection?.sourceLocale === 'en';

  const addWordSuccessHandler = (data: CreateWordData) => {
    const key =
      isAddFromErrorModal && notChangedTranslation
        ? isFromEnglish
          ? notChangedTranslation[1]
          : notChangedTranslation[0]
        : isFromEnglish
          ? data.dictionaryEntry.translation
          : data.dictionaryEntry.original;

    setIncludedTranslations({...includedTranslations, [key]: data.id});
    setIsAddFromErrorModal(false);
    setNotChangedTranslation(undefined);

    if (!callbackRef.current) {
      toastr.success('', formatMessage({id: 'Dictionary.Entry.CreationSuccess'}));
    }

    callbackRef.current?.(data.id);
    callbackRef.current = undefined;
  };

  const addWordErrorHandler = () => {
    if (!callbackRef.current) {
      toastr.error('', formatMessage({id: 'Dictionary.Entry.CreationError'}));
    }

    callbackRef.current = undefined;
  };

  const pronunciationSuccessHandler = (data: PronunciationData) => {
    if (currentArticle && currentOriginal && currentTranslation && dictionaryOwnerId) {
      addWordApiRequest(dictionaryOwnerId, dictionaryOwnerRole, {
        original: isFromEnglish ? currentOriginal : currentTranslation,
        translation: isFromEnglish ? currentTranslation : currentOriginal,
        pronunciationId: data?.audio?.length ? data.audio[0].id : undefined,
        phoneticSpelling: data?.phoneticSpelling
      });
    }
  };

  const pronunciationErrorHandler = () => {
    if (currentArticle && currentOriginal && currentTranslation && dictionaryOwnerId) {
      addWordApiRequest(dictionaryOwnerId, dictionaryOwnerRole, {
        original: isFromEnglish ? currentOriginal : currentTranslation,
        translation: isFromEnglish ? currentTranslation : currentOriginal
      });
    }
  };

  const {apiRequest: addWordApiRequest} = useApiRequest2<
    [number, DictionaryOwnerRole, EditEntryData]
  >(createWordEntry, addWordSuccessHandler, addWordErrorHandler);

  const {apiRequest: pronunciationApiRequest} = useApiRequest2<[string]>(
    loadPronunciationsRequest,
    pronunciationSuccessHandler,
    pronunciationErrorHandler
  );

  const addWord = useCallback(
    (translation: string, callback?: Callback) => {
      if (currentArticle) {
        setCurrentOriginal(currentArticle.word);
        setCurrentTranslation(translation);

        if (searchMode === DictionarySearchMode.EN) pronunciationApiRequest(currentArticle.word);
        else pronunciationApiRequest(isFromEnglish ? currentArticle.word : translation);

        callbackRef.current = callback;
      }
    },
    [currentArticle, isFromEnglish, pronunciationApiRequest, searchMode]
  );

  const addChangedWord = useCallback(
    (original: string, translation: string) => {
      if (currentArticle) {
        setCurrentOriginal(original);
        setCurrentTranslation(translation);
        pronunciationApiRequest(isFromEnglish ? original : translation);
      }
    },
    [currentArticle, isFromEnglish, pronunciationApiRequest]
  );

  return {
    includedTranslations,
    addWord,
    addChangedWord,
    setIncludedTranslations,
    addWordApiRequest,
    isAddFromErrorModal,
    setIsAddFromErrorModal,
    notChangedTranslation,
    setNotChangedTranslation
  };
};
