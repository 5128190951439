import {type ReducersMapObject} from 'redux';
import {useStore} from 'react-redux';
import {useRef, useEffect, useState} from 'react';

import {type AsyncStore} from 'store';
import {type AppState} from 'store/interface';

export const useInjectReducer = (asyncReducers: ReducersMapObject): boolean => {
  const asyncReducersRef = useRef<ReducersMapObject>(asyncReducers);
  const reducers = useRef<ReducersMapObject | null>(null);
  const store = useStore() as AsyncStore<AppState>;
  const [injected, setInjected] = useState<boolean>(false);

  useEffect(() => {
    if (injected) {
      return;
    }
    if (
      !reducers.current ||
      Object.values(reducers.current).find(
        r => !Object.values(asyncReducersRef.current).includes(r)
      )
    ) {
      reducers.current = asyncReducersRef.current;
      if (store.injectReducers) {
        store.injectReducers(asyncReducersRef.current);
      } else if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.trace(
          'useInjectReducer: store.injectReducer function does not exist on store object'
        );
      }
      setInjected(true);
    }
  }, [injected, store]);

  return injected;
};
