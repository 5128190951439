import {type Profile, type UserStatus} from 'store/interface';
import {OnlineState} from 'common/enums';

export function userFullName(profile: Profile): string {
  return `${profile.last_name} ${profile.first_name}`;
}

export function userCompactName(profile: Profile): string {
  return `${profile.last_name} ${profile.first_name[0]}.`;
}

function hasState(mask: number, bit: OnlineState): boolean {
  return (mask & bit) === bit;
}

export function isUserStatusSupportsRtc(status: UserStatus): boolean {
  const onlineStatuses: UserStatus[] = ['online', 'online-mobile'];
  return onlineStatuses.includes(status);
}

export function onlineState2UserStatus(state?: number, onCall?: number): UserStatus {
  if (!state || state === OnlineState.OFFLINE) {
    return 'offline';
  }
  if (onCall) {
    return 'oncall';
  }

  if (hasState(state, OnlineState.WEB)) {
    return 'online';
  } else if (hasState(state, OnlineState.WEB_MOBILE)) {
    return 'online-mobile';
  } else if (hasState(state, OnlineState.WEB_NO_RTC)) {
    return 'online-no-rtc';
  } else if (hasState(state, OnlineState.WEB_MOBILE_NO_RTC)) {
    return 'online-mobile-no-rtc';
  } else if (hasState(state, OnlineState.VC_MOBILE_APP)) {
    return 'app-vc-mobile';
  } else if (hasState(state, OnlineState.VC_MOBILE_APP_WEB_VIEW)) {
    return 'app-vc-mobile-webview';
  } else {
    return 'online-no-rtc'; // fallback to online w/o RTC calls supported
  }
}
