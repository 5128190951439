export default {
  'arrow-up': '\uEA23',
  'arrow-down': '\uEA20',
  'arrow-left': '\uEA21',
  'arrow-right': '\uEA22',
  copyright: '\uEAAC',
  registered: '\uEC1B',
  'smile-o': '\uEC50',
  'frown-o': '\uEB10',
  check: '\uEA82',
  close: '\uEA94',
  'plus-circle': '\uEC05',
  'minus-circle': '\uEB9B',
  'question-circle': '\uEC0F'
};
