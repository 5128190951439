import React from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';

import Icon from '../../Icon';

interface PopoverOverlayProps {
  shouldDisablePopover?: boolean;
  studentTeacherPopoverOpen?: boolean;
  toggleStudentTeachersPopover: (show: boolean) => void;
}

export default class StudentTeacherBreadcrumbPopoverOverlay extends React.Component<
  PopoverOverlayProps,
  {}
> {
  public render() {
    const {children, studentTeacherPopoverOpen} = this.props;
    return (
      <div className="popover-overlay">
        <div className="click-catcher">
          <Icon name="caret-down" />
        </div>
        <Overlay
          animation={false}
          container={this}
          placement="bottom"
          rootClose={true}
          show={studentTeacherPopoverOpen}
          onHide={this.overlayOnHideHandler}
        >
          {children}
        </Overlay>
      </div>
    );
  }

  private overlayOnHideHandler = () => {
    this.props.toggleStudentTeachersPopover(false);
  };
}
