import {
  type ChatMessage,
  type ParsedImage,
  type ParsedYTVideo,
  type TextMessageMeta
} from 'store/interface';
import {ParsedMediaType, TextMessageTypes} from 'common/enums';

import uniqueId from '../imageUniqueId';

// don't use /g flag on YT regexp so that we can later get video id with match method
const longYTURLPattern = /(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=([^\s&]{11})/m;
const shortenedYTURLPattern = /(https?:\/\/)?(www\.)?youtu\.be\/([^\s?]{11})/m;

const imagesURLPattern = /https:\/\/\S+\.\S+.(jpe?g|png|bmp)(\?\S+)?/gm;
const flags = (imagesURLPattern.flags + longYTURLPattern.flags + shortenedYTURLPattern.flags)
  .split('')
  .sort()
  .join('')
  .replace(/(.)(?=.*\1)/g, '');
const mediaURLPattern = new RegExp(
  '(' +
    imagesURLPattern.source +
    ')|(' +
    longYTURLPattern.source +
    ')|(' +
    shortenedYTURLPattern.source +
    ')',
  flags
);

export default function parseMediaFromMessageText(message: ChatMessage): TextMessageMeta {
  const mediaLinks = message.text.match(mediaURLPattern);
  if (!mediaLinks) {
    return {type: TextMessageTypes.text};
  }
  return {
    type:
      mediaLinks[0] === message.text
        ? TextMessageTypes.mediaSingleLink
        : TextMessageTypes.textWithAdditionalBubbles,
    parsedMedia: mediaLinks.map((src: string, position: number) => {
      const isImage = src.match(imagesURLPattern);
      if (isImage) {
        return {
          src,
          type: ParsedMediaType.image,
          imageId: uniqueId(message.id, position)
        } as ParsedImage;
      }
      const isShortenedYoutubeURL = src.match(shortenedYTURLPattern);
      const isLongYoutubeURL = src.match(longYTURLPattern);
      return {
        src,
        type: ParsedMediaType.youtubeVideo,
        videoId: isShortenedYoutubeURL ? isShortenedYoutubeURL[3] : isLongYoutubeURL![3]
      } as ParsedYTVideo;
    })
  };
}
