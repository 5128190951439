import {useEffect} from 'react';
import {init} from 'emoji-mart';
import {captureException, withScope} from '@sentry/react';

import {emojiData} from './emojiData';

export function useInitEmoji() {
  useEffect(() => {
    init({data: emojiData}).catch(error => {
      withScope(scope => {
        scope.setLevel('warning');
        captureException(error);
      });
    });
  }, []);
}
