import React from 'react';
import RetinaImage from '@englex/react-retina-image';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import HelpBlock from 'react-bootstrap/lib/HelpBlock';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';
import Tooltip from 'rc-tooltip';
import {FormattedMessage} from 'react-intl';
import Bowser from 'bowser';

import {browsersMediaInstructions, videoSizeConstraints} from 'config/static';
import Icon from 'components/Icon';

import Loader from '../../../../components/Loader';
import VolumeScale from './VolumeScale';
import Spinner from '../../../../components/Spinner';
import {RTCClient, rtcErrors} from '../../../../webRTC/rtcclient';
import {playMedia} from '../../../../helpers/playMedia';
import {type MediaSettingsProps as Props} from './MediaSettingsWizard';
import Cat1 from './cat.png';
import Cat2 from './cat@2x.png';
import Webcam1 from './webcam-placeholder.png';
import Webcam2 from './webcam-placeholder@2x.png';

const noDevicesImage = [Cat1, Cat2];
const webcamPlaceholder = [Webcam1, Webcam2];

enum MediaErrorType {
  TIMEOUT_ERROR = 'timeoutError',
  MEDIA_ERROR = 'mediaError',
  NONE = 'none'
}

interface State {
  devicesLoaded: boolean;
  camMediaError: MediaErrorType;
  micMediaError: MediaErrorType;
  audioPreviewStream: MediaStream | null;
  videoPreviewStream: MediaStream | null;
  loadingCam: boolean;
  loadingMic: boolean;
  modalSelectedMic?: string;
  modalSelectedCam?: string;
}

class SelectDevicesBody extends React.Component<Props, State> {
  public state: State = {
    devicesLoaded: false,
    camMediaError: MediaErrorType.NONE,
    micMediaError: MediaErrorType.NONE,
    audioPreviewStream: null,
    videoPreviewStream: null,
    loadingCam: false,
    loadingMic: false
  };

  private videoPreview?: HTMLVideoElement;

  public static getDerivedStateFromProps(props: Props, state: State) {
    const shouldResetCamError = props.modalSelectedCam !== state.modalSelectedCam;
    const shouldResetMicError = props.modalSelectedMic !== state.modalSelectedMic;
    return {
      ...state,
      modalSelectedCam: props.modalSelectedCam,
      modalSelectedMic: props.modalSelectedMic,
      micMediaError: shouldResetCamError ? MediaErrorType.NONE : state.micMediaError,
      camMediaError: shouldResetMicError ? MediaErrorType.NONE : state.camMediaError
    };
  }

  public componentDidMount(): void {
    this.prepareSelectDevices();
  }

  public componentWillUnmount() {
    if (this.state.videoPreviewStream) {
      this.state.videoPreviewStream.getTracks().forEach(track => track.stop());
    }
    if (this.state.audioPreviewStream) {
      this.state.audioPreviewStream.getTracks().forEach(track => track.stop());
    }
    this.removeEventListeners();
  }

  private addEventListeners() {
    navigator.mediaDevices.addEventListener('devicechange', this.saveDevices);
    navigator.mediaDevices.addEventListener('devicechange', this.getDevicesPresence);
  }

  private removeEventListeners() {
    navigator.mediaDevices.removeEventListener('devicechange', this.saveDevices);
    navigator.mediaDevices.removeEventListener('devicechange', this.getDevicesPresence);
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    const devicesWereLoaded = !prevState.devicesLoaded && this.state.devicesLoaded;

    if (devicesWereLoaded) {
      this.addEventListeners();
    }

    if (this.props.camAccess && this.props.modalSelectedCam) {
      const camWasSelected = prevProps.modalSelectedCam !== this.props.modalSelectedCam;
      if (devicesWereLoaded || camWasSelected) {
        this.getCamPreviewStream(this.props.modalSelectedCam);
      }
    }

    if (this.props.micAccess && this.props.modalSelectedMic) {
      const micWasSelected = prevProps.modalSelectedMic !== this.props.modalSelectedMic;
      if (devicesWereLoaded || micWasSelected) {
        this.getMicPreviewStream(this.props.modalSelectedMic);
      }
    }

    if (
      !this.props.modalSelectedCam &&
      prevProps.modalSelectedCam &&
      this.state.videoPreviewStream
    ) {
      this.state.videoPreviewStream.getTracks().forEach(track => track.stop());
      this.setState({videoPreviewStream: null});
    }

    if (
      !this.props.modalSelectedMic &&
      prevProps.modalSelectedMic &&
      this.state.audioPreviewStream
    ) {
      this.state.audioPreviewStream.getTracks().forEach(track => track.stop());
      this.setState({audioPreviewStream: null});
    }
  }

  public render() {
    if (!this.state.devicesLoaded) {
      return (
        <Modal.Body className="loading">
          <Loader />
        </Modal.Body>
      );
    }
    if (this.props.noMicsAvailable && this.props.noCamsAvailable) {
      return this.renderNoDevicesBody();
    }
    if (this.props.micAccess === false) {
      return this.renderAccessDeniedBody();
    }
    const camValidation = this.getCamValidation();
    const micValidation = this.getMicValidation();
    return (
      <Modal.Body className="select-devices">
        <div className="description text-center">
          <FormattedMessage id="MediaDevicesWizard.SelectDevicesDescription.First" tagName="span" />
          <FormattedMessage
            id="MediaDevicesWizard.SelectDevicesDescription.Second"
            tagName="span"
          />
        </div>
        <div className="devices">
          <form>
            <FormGroup
              className="mic-validation-block"
              validationState={micValidation ? 'error' : undefined}
            >
              <HelpBlock className="error">{micValidation}</HelpBlock>
            </FormGroup>
            <div className="columns">
              <FormGroup className="mic" validationState={micValidation ? 'error' : undefined}>
                <ControlLabel>
                  <FormattedMessage id="MediaDevicesWizard.SelectDevices.Label.Microphone" />
                  {this.state.loadingMic ? <Spinner size={15} height={10} /> : null}
                </ControlLabel>
                <Tooltip
                  placement="top"
                  trigger={['hover']}
                  overlay={this.getMicDropdownTitle()}
                  overlayClassName="black-tooltip"
                >
                  <div>
                    <DropdownButton
                      id="select-device-mic"
                      className="dropdown-toggle"
                      title={this.getMicDropdownTitle()}
                      onSelect={this.props.selectMic}
                      disabled={this.props.noMicsAvailable}
                    >
                      {this.props.audioDevices
                        ? this.props.audioDevices.map(this.renderDeviceOption)
                        : undefined}
                    </DropdownButton>
                  </div>
                </Tooltip>
                <VolumeScale stream={this.state.audioPreviewStream} />
              </FormGroup>

              <FormGroup className="cam" validationState={camValidation ? 'warning' : undefined}>
                <ControlLabel>
                  <FormattedMessage id="MediaDevicesWizard.SelectDevices.Label.Camera" />
                  {this.state.loadingCam ? <Spinner size={15} height={10} /> : null}
                </ControlLabel>
                <Tooltip
                  overlay={this.getCamDropdownTitle()}
                  placement="top"
                  trigger={['hover']}
                  overlayClassName="black-tooltip"
                >
                  <div>
                    <DropdownButton
                      id="select-device-cam"
                      className="dropdown-toggle"
                      title={this.getCamDropdownTitle()}
                      disabled={this.props.camAccess === false || this.props.noCamsAvailable}
                      onSelect={this.props.selectCam}
                    >
                      {this.props.audioDevices
                        ? this.props.videoDevices!.map(this.renderDeviceOption)
                        : undefined}
                    </DropdownButton>
                  </div>
                </Tooltip>
                <HelpBlock className="warning">
                  {camValidation ? <Icon name="warning" /> : null}
                  {camValidation}
                </HelpBlock>
              </FormGroup>

              {this.renderVideoPreview()}
            </div>
          </form>
        </div>
      </Modal.Body>
    );
  }

  private getCamPreviewStream = async (deviceId: string) => {
    try {
      this.setState({loadingCam: true, videoPreviewStream: null});
      if (this.state.videoPreviewStream) {
        this.state.videoPreviewStream.getTracks().forEach(track => track.stop());
      }
      const stream = await RTCClient.getUserMedia(
        {video: {...videoSizeConstraints}},
        deviceId,
        null
      );
      if (this.videoPreview && deviceId === this.props.modalSelectedCam) {
        this.videoPreview.srcObject = stream;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        playMedia(this.videoPreview);
        this.setState({
          videoPreviewStream: stream,
          camMediaError: MediaErrorType.NONE,
          loadingCam: false
        });
      } else {
        stream.getTracks().forEach(track => track.stop());
      }
    } catch (e) {
      if (this.props.modalSelectedCam === deviceId) {
        const errorType =
          e.name === rtcErrors.GetStreamTimeoutError
            ? MediaErrorType.TIMEOUT_ERROR
            : MediaErrorType.MEDIA_ERROR;
        this.setState({camMediaError: errorType, loadingCam: false});
      }
    }
  };

  private getMicPreviewStream = async (deviceId: string) => {
    try {
      this.setState({loadingMic: true, audioPreviewStream: null});
      if (this.state.audioPreviewStream) {
        this.state.audioPreviewStream.getTracks().forEach(track => track.stop());
      }

      const stream = await RTCClient.getUserMedia({}, null, deviceId);

      if (deviceId === this.props.modalSelectedMic) {
        this.setState({
          audioPreviewStream: stream,
          micMediaError: MediaErrorType.NONE,
          loadingMic: false
        });
      } else {
        stream.getTracks().forEach(track => track.stop());
      }
    } catch (e) {
      if (this.props.modalSelectedMic === deviceId) {
        const errorType =
          e.name === rtcErrors.GetStreamTimeoutError
            ? MediaErrorType.TIMEOUT_ERROR
            : MediaErrorType.MEDIA_ERROR;
        this.setState({micMediaError: errorType, loadingMic: false});
      }
    }
  };

  private getMicValidation = () => {
    if (this.props.noMicsAvailable) {
      return <FormattedMessage id="MediaDevicesWizard.NoMicsAvailable" />;
    }
    if (this.state.micMediaError !== MediaErrorType.NONE) {
      return (
        <React.Fragment>
          {this.getMicErrorMessage(this.state.micMediaError)}{' '}
          <a className="lnk" href="" onClick={this.tryCaptureAudioAgain}>
            <FormattedMessage id="MediaDevicesWizard.TryAgain" />
          </a>
        </React.Fragment>
      );
    }
    return null;
  };

  private getCamValidation = () => {
    if (this.props.noCamsAvailable) {
      return <FormattedMessage id="MediaDevicesWizard.NoCamsAvailable" />;
    }
    if (!this.props.camAccess) {
      return (
        <React.Fragment>
          <FormattedMessage id="MediaDevicesWizard.NoCamPermission1" />
          <a
            className="lnk"
            href={browsersMediaInstructions[this.props.locale][this.browserName!]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="MediaDevicesWizard.NoCamPermission2" />
          </a>
          .
        </React.Fragment>
      );
    }
    if (this.state.camMediaError !== MediaErrorType.NONE) {
      return (
        <React.Fragment>
          {this.getCamErrorMessage(this.state.camMediaError)}{' '}
          <a className="lnk" href="" onClick={this.tryCaptureVideoAgain}>
            <FormattedMessage id="MediaDevicesWizard.TryAgain" />
          </a>
        </React.Fragment>
      );
    }
    return null;
  };

  private getCamErrorMessage = (errorType: MediaErrorType) => {
    if (errorType === MediaErrorType.TIMEOUT_ERROR) {
      return <FormattedMessage id="MediaDevicesWizard.CamTimeoutError" />;
    }
    return <FormattedMessage id="MediaDevicesWizard.CamMediaError" />;
  };

  private getMicErrorMessage = (errorType: MediaErrorType) => {
    if (errorType === MediaErrorType.TIMEOUT_ERROR) {
      return <FormattedMessage id="MediaDevicesWizard.MicTimeoutError" />;
    }
    return <FormattedMessage id="MediaDevicesWizard.MicMediaError" />;
  };

  private tryCaptureVideoAgain = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (this.props.modalSelectedCam) {
      this.getCamPreviewStream(this.props.modalSelectedCam);
    }
  };

  private tryCaptureAudioAgain = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (this.props.modalSelectedMic) {
      this.getMicPreviewStream(this.props.modalSelectedMic);
    }
  };

  private getDevicesPresence = () => {
    return navigator.mediaDevices.enumerateDevices().then(
      (devices: MediaDeviceInfo[]) =>
        new Promise(resolve => {
          const micsNumber = devices.filter(device => device.kind === 'audioinput').length;
          const camsNumber = devices.filter(device => device.kind === 'videoinput').length;
          if (!micsNumber && !this.props.noMicsAvailable) {
            this.props.setNoMicsAvailable(true);
          }
          if (micsNumber && this.props.noMicsAvailable) {
            this.props.setNoMicsAvailable(false);
          }
          if (!camsNumber && !this.props.noCamsAvailable) {
            this.props.setNoCamsAvailable(true);
          }
          if (camsNumber && this.props.noCamsAvailable) {
            this.props.setNoCamsAvailable(false);
          }
          resolve({
            audio: !!micsNumber,
            video: !!camsNumber
          } as MediaStreamConstraints);
        })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getMicDropdownTitle: any = () => {
    if (this.props.noMicsAvailable) {
      return <FormattedMessage tagName="b" id="Common.NotAvailable" />;
    }
    if (this.props.modalSelectedMic && this.props.audioDevices) {
      const selectedDevice = this.props.audioDevices.find(
        device => device.deviceId === this.props.modalSelectedMic
      );
      if (selectedDevice) {
        return selectedDevice.label;
      }
    }
    return <FormattedMessage tagName="b" id="Common.NotSelected" />;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private getCamDropdownTitle: any = () => {
    if (this.props.noCamsAvailable || !this.props.camAccess) {
      return <FormattedMessage tagName="b" id="Common.NotAvailable" />;
    }
    if (this.props.modalSelectedCam && this.props.videoDevices) {
      const selectedDevice = this.props.videoDevices.find(
        device => device.deviceId === this.props.modalSelectedCam
      );
      if (selectedDevice) {
        return selectedDevice.label;
      }
    }
    return <FormattedMessage tagName="b" id="Common.NotSelected" />;
  };

  private getPermissions = (options: MediaStreamConstraints) => {
    if (!options.audio && !options.video) {
      return Promise.resolve();
    }
    return navigator.mediaDevices.getUserMedia({
      video: options.video,
      audio: options.audio
    });
  };

  private renderDeviceOption = (device: MediaDeviceInfo) => {
    const isActive =
      device.kind === 'audioinput'
        ? this.props.modalSelectedMic === device.deviceId
        : this.props.modalSelectedCam === device.deviceId;
    return (
      <MenuItem key={device.deviceId} eventKey={device} active={isActive}>
        {device.label}
      </MenuItem>
    );
  };

  private saveDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((allDevices: MediaDeviceInfo[]) => {
      const audioPermissions = this.checkPermissions(
        allDevices.filter(device => device.kind === 'audioinput')
      );

      const videoPermissions = this.checkPermissions(
        allDevices.filter(device => device.kind === 'videoinput')
      );

      this.props.changePermissions({
        video: videoPermissions,
        audio: audioPermissions
      });

      const devices = allDevices.filter(
        device =>
          (device.kind === 'audioinput' || device.kind === 'videoinput') && device.label !== ''
      );

      const selectedMic = devices.find(device => device.deviceId === this.props.modalSelectedMic);
      const selectedCam = devices.find(device => device.deviceId === this.props.modalSelectedCam);

      this.props.saveDevices(devices, selectedMic, selectedCam);

      this.setState({devicesLoaded: true});
    });
  };

  private checkPermissions = (devices: MediaDeviceInfo[] | undefined) => {
    if (devices) {
      return devices.some(device => device.label !== '');
    }
    return true;
  };

  private renderAccessDeniedBody() {
    return (
      <Modal.Body className="media-access-denied">
        <div className="title text-danger text-large text-center">
          <FormattedMessage id="MediaDevicesWizard.AccessDeniedTitle" tagName="p" />
        </div>
        <div className="description text-center">
          <FormattedMessage id="MediaDevicesWizard.AccessDeniedDescription" />
        </div>
        <div className="guide">{this.renderBrowserGuideButton()}</div>
      </Modal.Body>
    );
  }

  private renderNoDevicesBody() {
    return (
      <Modal.Body className="no-devices">
        <div className="no-devices-image">
          <RetinaImage src={noDevicesImage} />
        </div>
        <div className="title text-danger text-large text-center">
          <FormattedMessage id="MediaDevicesWizard.NoDevicesTitle" tagName="p" />
        </div>
        <div className="description text-center">
          <FormattedMessage id="MediaDevicesWizard.NoDevicesDescription" />
        </div>
      </Modal.Body>
    );
  }

  private getBrowserIcon = () => {
    switch (this.browserName) {
      case 'Chrome':
        return 'chrome';
      case 'Safari':
        return 'safari';
      case 'Opera':
        return 'opera';
      case 'Yandex Browser':
        return 'yandex_browser';
      case 'Firefox':
        return 'firefox';
      default:
        return '';
    }
  };

  private renderBrowserGuideButton() {
    return (
      <a
        href={browsersMediaInstructions[this.props.locale][this.browserName!]}
        className="btn btn-transparent btn-lg"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FormattedMessage
          id="MediaDevicesWizard.AccessDenied.Button.Chrome"
          values={{
            browserName: this.browserName,
            icon: <Icon name={this.getBrowserIcon()} />
          }}
        />
      </a>
    );
  }

  private prepareSelectDevices() {
    setTimeout(async () => {
      if (this.props.showModal) {
        try {
          const options = await this.getDevicesPresence();
          const stream: MediaStream | void = await this.getPermissions(
            options as MediaStreamConstraints
          );
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
          this.saveDevices();
        } catch (e) {
          this.saveDevices();
        }
      }
    }, 700); // crutch for smooth transition of modal
  }

  private renderVideoPreview = () => {
    return (
      <div className="video-preview">
        <div className="poster">
          <RetinaImage src={webcamPlaceholder} />
        </div>
        <video ref={this.videoPreviewRef} autoPlay={true} playsInline={true} />
      </div>
    );
  };

  private videoPreviewRef = (el: HTMLVideoElement) => (this.videoPreview = el);

  private get browserName() {
    return Bowser.getParser(window.navigator.userAgent).getBrowser().name;
  }
}
export default SelectDevicesBody;
