import {useCallback, useEffect, useRef, useState} from 'react';

import {
  type AxiosRequestAction,
  type AxiosRequestError,
  type AxiosResponseAction
} from 'services/axios/interface';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';

interface HookResult<A extends unknown[]> {
  isLoading: boolean;
  isError: boolean;
  apiRequest(...args: A): void;
}

export const useApiRequest2 = <A extends unknown[], D = unknown>(
  actionCreator: (...args: A) => AxiosRequestAction,
  successHandler: (data: D) => void,
  errorHandler?: (e: Error | AxiosRequestError) => void,
  finallyHandler?: () => void
): HookResult<A> => {
  const dispatch = useAxiosDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const activeRequest = useRef<CancellablePromise | null>(null);

  const successHandlerRef = useRef<typeof successHandler>();
  const errorHandlerRef = useRef<typeof errorHandler>();
  const finallyHandlerRef = useRef<typeof finallyHandler>();

  successHandlerRef.current = successHandler;
  errorHandlerRef.current = errorHandler;
  finallyHandlerRef.current = finallyHandler;

  const apiRequest = useCallback(
    (...args) => {
      if (activeRequest.current) {
        activeRequest.current.cancel();
      }
      setIsError(false);
      setIsLoading(true);

      activeRequest.current = makeCancellable(
        dispatch<AxiosResponseAction<D>>(actionCreator(...(args as A))),
        response => {
          setIsLoading(false);
          activeRequest.current = null;
          successHandlerRef.current?.(response.payload.data);
        },
        e => {
          if (import.meta.env.MODE === 'development')
            console.error('Hook useApiRequest2 fetch error:', e); // eslint-disable-line no-console
          setIsError(true);
          setIsLoading(false);
          activeRequest.current = null;
          errorHandlerRef.current?.(e);
        },
        undefined,
        finallyHandlerRef.current
      );
    },
    [actionCreator, dispatch]
  );

  useEffect(
    () => () => {
      activeRequest.current?.cancel();
    },
    []
  );

  return {
    isLoading,
    isError,
    apiRequest
  };
};
