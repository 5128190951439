import {type FC, memo, useCallback, useContext} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import * as toastr from 'components/toastr';
import {
  EditEntryActionsContext,
  EditEntryStateContext
} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {setSelectionAction} from 'components/Dictionary/shared/contexts/entry/actions';
import {maxListEntries} from 'components/Dictionary/shared/static';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';

import './AddSelectedEntriesToListDropdown.scss';

interface Props {
  hasLists: boolean;
}

export const AddSelectedEntriesToListDropdown: FC<Props> = memo(({hasLists}) => {
  const {formatMessage} = useIntl();

  const {dispatch} = useContext(EditEntryActionsContext);
  const {selectionMode} = useContext(EditEntryStateContext);

  const selectedEntryIds = selectionMode?.entryIds || [];

  const onDropdownChange = useCallback(
    (key: string) => {
      if (key === 'add_to_existing_list') {
        dispatch(setSelectionAction('add', true));
      }
      if (key === 'add_to_new_list') {
        if (selectedEntryIds.length > maxListEntries) {
          return toastr.error('', formatMessage({id: 'Dictionary.Toastr.TooManySelectedEntries'}));
        } else {
          dispatch(setSelectionAction('add', false));
        }
      }
    },
    [dispatch, selectedEntryIds.length, formatMessage]
  );

  return (
    <DropDown
      toggleClassName="add-selected-entries-to-list-dropdown add btn"
      items={{
        add_to_new_list: formatMessage({id: 'Dictionary.AddToNewList'}),
        add_to_existing_list: {
          value: formatMessage({id: 'Dictionary.AddToExistingList'}),
          disabled: !hasLists
        }
      }}
      onChange={onDropdownChange}
      value={
        <span title={formatMessage({id: 'Dictionary.AddToList'})}>
          <Icon name="virc-note" />
          <FormattedMessage id="Dictionary.AddToList" />
        </span>
      }
      placement="top"
      trigger={['click']}
      disabled={!selectedEntryIds.length}
      noCaret={true}
    />
  );
});
