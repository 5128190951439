import {createTransform} from 'redux-persist';
import {Map} from 'immutable';

import {
  type DraftExercisesJSON,
  type XDraftExercisesMap
} from './exercise/editor/widgets/interface';
import XExerciseRecord from './exercise/editor/widgets/XExerciseRecord';

function inbound(value: XDraftExercisesMap, key: string) {
  if (key !== 'draftExercises' || value.size === 0) {
    return value;
  }

  return value.map(e => e?.toJSON()).toJS();
}

function outbound(value: DraftExercisesJSON, key: string) {
  if (key !== 'draftExercises') {
    return value;
  }

  const exerciseRecords = Object.keys(value).reduce(
    (o, key) => ({...o, [key]: new XExerciseRecord(value[key])}),
    {}
  );

  return Map(exerciseRecords);
}

const transformXDraftExercises = createTransform<
  DraftExercisesJSON | unknown,
  XDraftExercisesMap | unknown
>(inbound, outbound);

export default transformXDraftExercises;
