import SparkMD5 from 'spark-md5';

const DEFAULT_CHUNK_SIZE: number = 2097152; // 2MB

export function md5Chunk(
  file: File,
  chunkSize: number = DEFAULT_CHUNK_SIZE,
  blobSlice: (start?: number, end?: number, contentType?: string) => Blob = File.prototype.slice
): Promise<string> {
  return new Promise((resolve, reject) => {
    const chunks: number = Math.ceil(file.size / chunkSize);
    const spark: SparkMD5.ArrayBuffer = new SparkMD5.ArrayBuffer();
    const fileReader: FileReader = new FileReader();

    let currentChunk: number = 0;

    const loadNext: () => void = () => {
      const start: number = currentChunk * chunkSize;
      const end: number = start + chunkSize >= file.size ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };

    fileReader.onload = function (this: FileReader, e: ProgressEvent) {
      spark.append(fileReader.result as ArrayBuffer);
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        const hash: string = spark.end();
        resolve(hash);
      }
    };

    fileReader.onerror = () => {
      reject(false);
    };

    loadNext();
  });
}

export default md5Chunk;
