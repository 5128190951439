import {type FC} from 'react';
import {Outlet} from 'react-router-dom';

import {isLoginAtDashboardWeb} from 'authentication';
import {useUserId} from 'hooks/user/useUserId';

import {Header} from './Header';
import {Footer} from './Footer';
import styles from './LoginLayout.module.scss';

export const LoginLayout: FC = () => {
  const isDisabled = isLoginAtDashboardWeb();
  const userId = useUserId();

  if (isDisabled || userId) {
    return <Outlet />;
  }

  return (
    <div className={styles.layout}>
      <div className={styles.container}>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};
