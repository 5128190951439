import {type AnyAction, type Reducer} from 'redux';

import {LOCATION_CHANGE, type LocationChangeAction, type RouterState} from './interface';

export const createRouterReducer = ({
  savePreviousLocations = 0
}: {
  savePreviousLocations?: number;
}): Reducer<RouterState> => {
  const initialState: RouterState = {
    location: null,
    action: null
  };

  const numLocationToTrack = isNaN(savePreviousLocations) ? 0 : savePreviousLocations;
  if (numLocationToTrack) initialState.previousLocations = [];

  return (state = initialState, a: AnyAction) => {
    if (a.type === LOCATION_CHANGE) {
      const {payload} = a as LocationChangeAction;
      const {location, action} = payload || {};
      const previousLocations =
        numLocationToTrack && state.previousLocations
          ? [{location, action}, ...state.previousLocations.slice(0, numLocationToTrack)]
          : undefined;
      return {...state, location, action, previousLocations};
    }
    return state;
  };
};
