import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';
import {
  changeAudio,
  changePlayStatus,
  changeTimestamp,
  changeVolume,
  clearForceRestart,
  setDuration
} from 'store/media/audio/actions';

import {type AudioFile, type MediaContext, type PlayStatusV2} from '../../../interface';
import AudioPlayer from '../../AudioPlayer';

interface AudioPlayerStateProps {
  activeFile?: AudioFile;
  forceRestart?: true;
  isMobile?: boolean;
  playStatus?: PlayStatusV2;
  playbackRate?: number;
  startedAt?: number;
  timestamp: number;
  volume: number;
  context?: MediaContext;
}

interface AudioPlayerDispatchProps {
  changeAudio: (audioFile: AudioFile, context: MediaContext) => void;
  changePlayStatus: (playStatus?: PlayStatusV2) => void;
  changeTimestamp: (timestamp: number) => void;
  changeVolume: (volume: number) => void;
  clearForceRestart: () => void;
  setDuration: (duration: number) => void;
}

export interface AudioPlayerProps extends AudioPlayerStateProps, AudioPlayerDispatchProps {}

type AudioPlayerStateToProps = (state: AppState) => AudioPlayerStateProps;
type AudioPlayerDispatchToProps = (dispatch: Dispatch<Action>) => AudioPlayerDispatchProps;

const mapStateToProps: AudioPlayerStateToProps = ({media, layout}) => {
  const {audioFile, forceRestart, playStatus, startedAt, timestamp, volume, playbackRate, context} =
    media.audio!;
  return {
    activeFile: audioFile,
    forceRestart,
    isMobile: layout.isMobile,
    playStatus,
    playbackRate,
    startedAt,
    timestamp,
    volume,
    context
  };
};

const mapDispatchToProps: AudioPlayerDispatchToProps = dispatch => ({
  changeAudio: (audioFile: AudioFile, context?: MediaContext) =>
    dispatch(changeAudio(audioFile, context)),
  changePlayStatus: (status?: PlayStatusV2) => dispatch(changePlayStatus(status)),
  changeTimestamp: (timestamp: number) => dispatch(changeTimestamp(timestamp)),
  changeVolume: (volume: number) => dispatch(changeVolume(volume)),
  clearForceRestart: () => dispatch(clearForceRestart()),
  setDuration: (duration: number) => dispatch(setDuration(duration))
});

export default connect<AudioPlayerStateProps, AudioPlayerDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(AudioPlayer);
