import React, {PureComponent, type SyntheticEvent} from 'react';
import RetinaImage from '@englex/react-retina-image';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import classNames from 'classnames';
import {captureMessage, withScope} from '@sentry/react';

import {
  type AxiosRequestAction,
  type AxiosRequestError,
  type AxiosResponseAction
} from 'services/axios/interface';
import {type AppState, type ImageV2} from 'store/interface';
import {
  ImageCacheContext,
  type ImageCacheContextShape
} from 'components/XPlayer/contexts/imageCacheContext';

import {ReactComponent as BrokenSVG} from './broken.svg';
import {ReactComponent as ReloadSVG} from './reload.svg';
import {ReactComponent as LoadingSVG} from './img-normal.svg';
import Spinner from '../Spinner';
import {type CancellablePromise, makeCancellable} from '../../helpers/cancellablePromise';

import './LoadableImage.scss';

const REQUEST_IMAGE = 'IMAGE/REQUEST_IMAGE';
export const requestImage = (id: number): AxiosRequestAction => ({
  type: REQUEST_IMAGE,
  payload: {
    client: 'v2',
    request: {
      url: `v2/image/${id}`,
      method: 'get'
    }
  }
});

interface OwnProps {
  imageId: number;
  img?: ImageV2;
  width: string;
  height: string;
  draggable?: boolean;
  onClick?: () => void;
  onImageLoaded?: (image: ImageV2, imageId?: number) => void;
  clickable?: boolean;
}

interface DispatchProps {
  requestImage: (id: number) => Promise<Action>;
}

interface Props extends OwnProps, DispatchProps {}

interface State {
  img?: ImageV2;
  error?: boolean;
  imageLoaded?: boolean;
}

class LoadableImage extends PureComponent<Props, State> {
  public state: State = {img: this.props.img};

  static contextType = ImageCacheContext;
  public declare context: ImageCacheContextShape;

  private imageRequest: CancellablePromise;

  public componentDidMount() {
    if (!this.state.img) {
      this.sendRequest();
    } else {
      this.props.onImageLoaded?.(this.state.img);
    }
  }

  public componentWillUnmount(): void {
    if (this.imageRequest) {
      this.imageRequest.cancel();
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (this.props.imageId && this.props.imageId !== prevProps.imageId) {
      this.setState({imageLoaded: false});
      this.sendRequest();
    }
  }

  public render() {
    const {onClick, width, height, clickable} = this.props;
    return (
      <div
        className={classNames('loadable-image', {clickable: clickable ?? !!onClick})}
        style={{width, height}}
        onClick={this.onClick}
      >
        {this.renderImage()}
      </div>
    );
  }

  private onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!this.state.error && this.props.onClick) {
      e.stopPropagation();
      e.preventDefault();
      this.props.onClick();
    }
  };

  private renderImage = () => {
    const {draggable = true} = this.props;

    if (this.state.error) {
      return (
        <div className="load-error" onClick={this.sendRequest}>
          <BrokenSVG className="broken" />
          <ReloadSVG className="reload" />
        </div>
      );
    }
    if (!this.state.img || !this.state.imageLoaded) {
      return (
        <div className="loading-image">
          <LoadingSVG />
          <Spinner size={20} />
          {this.state.img && (
            <RetinaImage
              src={this.state.img.urls[0]}
              onError={this.onError}
              onLoad={this.onLoaded}
            />
          )}
        </div>
      );
    }
    return (
      <RetinaImage
        src={this.state.img.urls[0]}
        onError={this.onError}
        style={{width: '100%', height: '100%'}}
        draggable={draggable}
      />
    );
  };

  private onLoaded = () => {
    this.setState({imageLoaded: true});
  };

  private onError = (event: SyntheticEvent<HTMLImageElement> | AxiosRequestError) => {
    const imageId = this.props.imageId;
    this.setState({error: true, imageLoaded: false});
    withScope(scope => {
      scope.setExtras({
        imageId: imageId || null,
        url: this.state.img && this.state.img.urls[0],
        event
      });
      captureMessage('IMG onerror.', 'warning');
    });
  };

  private sendRequest = () => {
    const {imageCache} = this.context;
    if (this.state.error) {
      this.setState({error: undefined});
    }
    if (imageCache[this.props.imageId]) {
      this.handleGetFromCache(imageCache[this.props.imageId]);
    } else {
      this.imageRequest = makeCancellable(
        this.props.requestImage(this.props.imageId),
        this.handleImageLoaded,
        this.onError as (e: AxiosRequestError) => void
      );
    }
  };

  private handleImageLoaded = (action: AxiosResponseAction<ImageV2>) => {
    const {addToImageCache} = this.context;

    if (this.props.onImageLoaded) {
      this.props.onImageLoaded(action.payload.data);
    }
    this.setState({img: action.payload.data});
    addToImageCache(this.props.imageId, action.payload.data);
  };

  private handleGetFromCache = (img: ImageV2) => {
    this.setState({img}, () => {
      this.props.onImageLoaded?.(img);
    });
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  requestImage: (id: number) => dispatch(requestImage(id))
});

export default connect(null, mapDispatchToProps)(LoadableImage);
