import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';
const PATH: string = 'UPLOADS/';
export const FILE_MD5_CHECK: string = PATH + 'DOCUMENT_MD5_CHECK';
export const ADD_FILE_TO_UPLOADS_LIST: string = PATH + 'ADD_FILE_TO_UPLOADS_LIST';
export const BLOCK_UPLOAD_PANEL: string = PATH + 'BLOCK_UPLOAD_PANEL';
export const REMOVE_FILE_FROM_UPLOADS_LIST: string = PATH + 'REMOVE_FILE_FROM_UPLOADS_LIST';
export const CLONE_FILE: string = PATH + 'CLONE_FILE';
export const UPLOAD_FILE: string = PATH + 'UPLOAD_FILE';
export const UPLOADING_PROGRESS: string = PATH + 'UPLOADING_PROGRESS';
export const UPLOADING_FAILED: string = PATH + 'UPLOADING_FAILED';
export const GET_RECENT_DOCUMENTS: string = PATH + 'GET_RECENT_DOCUMENTS';
export const GET_RECENT_SOUNDS: string = PATH + 'GET_RECENT_SOUNDS';
export const GET_RECENT_DOCUMENTS_SUCCESS: string = GET_RECENT_DOCUMENTS + AXIOS_SUCCESS_POSTFIX;
export const GET_RECENT_DOCUMENTS_FAIL: string = GET_RECENT_DOCUMENTS + AXIOS_ERROR_POSTFIX;
export const GET_RECENT_SOUNDS_SUCCESS: string = GET_RECENT_SOUNDS + AXIOS_SUCCESS_POSTFIX;
export const GET_RECENT_SOUNDS_FAIL: string = GET_RECENT_SOUNDS + AXIOS_ERROR_POSTFIX;
export const FILE_UPLOADED: string = PATH + 'FILE_UPLOADED';
export const UPLOADS_RESET: string = PATH + 'UPLOADS_RESET';
