export interface ContainerProps {
  currentPage?: number | null;
  pageCount?: number | null;
  selectPage(page: number): void;
}

interface CommonOptions {
  unitId: number;
}

interface OriginalOptions extends CommonOptions {
  type: 'original';
  coursebookId: string;
  forStudent?: boolean;
}

interface InstanceOptions extends CommonOptions {
  type: 'instance';
}

export type Options = OriginalOptions | InstanceOptions;

export enum Status {
  loading = 'loading',
  error = 'error',
  default = 'default'
}

export interface UnitInstancePageContent {
  exercisesCount: number | null;
  title: string | null;
  task: string | null;
  extraAvailable?: number;
  exercisesCompleted?: number;
}

export interface UnitInstanceContents {
  unitInstanceId: number;
  coursebookInstanceId: string;
  contents: Record<number, UnitInstancePageContent>;
}

export interface UnitContents {
  unitId: number;
  coursebookId: string;
  contents: Record<number, Omit<UnitInstancePageContent, 'exercisesCompleted'>>;
}
