import {type Action} from 'redux';
import {type ThunkAction, type ThunkDispatch} from 'redux-thunk';

import {push} from 'store/router';
import {type AppState} from 'store/interface';
import {MaterialsTab} from 'common/enums';

import {type ChangeFilterCreator} from '../../actions/interface';
import {type ToggleElementCreator} from '../../../../common/interface';
import {materialsTabParamName} from './components/interface';

const PATH = 'FILES_WORK_SPACE/';
export const CHANGE_FILES_FILTER: string = PATH + 'CHANGE_FILES_FILTER';
export const TOGGLE_MATERIALS_SEARCHBAR: string = PATH + 'TOGGLE_MATERIALS_SEARCHBAR';
export const FILES_WORKSPACE_RESET: string = PATH + 'RESET';

export const changeFilesFilter: ChangeFilterCreator = (filter: string) => ({
  type: CHANGE_FILES_FILTER,
  filter
});

export const toggleMaterialsSearchBar: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_MATERIALS_SEARCHBAR,
  show
});

export const resetFilesWorkspace = (): Action => ({
  type: FILES_WORKSPACE_RESET
});

export const openMaterialsTab =
  (tab: MaterialsTab): ThunkAction<void, AppState, void, Action> =>
  (dispatch: ThunkDispatch<void, void, Action>, getState: () => AppState) => {
    const {router} = getState();
    const searchParams = new URLSearchParams(router.location!.search);
    if (searchParams.get(materialsTabParamName) && tab === MaterialsTab.DOCUMENTS) {
      searchParams.delete(materialsTabParamName);
    } else {
      searchParams.set(materialsTabParamName, tab);
    }
    dispatch(push({pathname: router.location!.pathname, search: searchParams.toString()}));
  };
