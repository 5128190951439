import {useIntl} from 'react-intl';

import {type DictionarySearchMode} from 'common/enums';

interface Props {
  searchMode: DictionarySearchMode;
  isTeacherDictionary: boolean;
  isTeacher: boolean;
}

export const useDictionarySearchPlaceholder = ({
  searchMode,
  isTeacherDictionary,
  isTeacher
}: Props) => {
  const {formatMessage} = useIntl();

  const placeholders = {
    myDictionary: formatMessage({id: 'Dictionary.MyDictionary'}).toLowerCase(),
    studentDictionary: formatMessage({id: 'Dictionary.StudentDictionary'}).toLowerCase(),
    enToRus: formatMessage({id: 'Dictionary.Search.Placeholder.EnToRus'}).toLowerCase(),
    en: formatMessage({id: 'Dictionary.Search.Placeholder.En'}).toLowerCase()
  };

  if (searchMode !== 'myDictionary')
    return `${formatMessage({id: 'Dictionary.Search'})}: ${placeholders[searchMode]}`;

  return `${formatMessage({id: 'Dictionary.Search'})}: ${
    isTeacher && !isTeacherDictionary ? placeholders.studentDictionary : placeholders.myDictionary
  }`;
};
