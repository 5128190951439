import {
  CLOSED,
  CONNECTED,
  CONNECTING,
  NOT_CONNECTED,
  RETRYING,
  type WampState
} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  type SubscriptionAction,
  type WampAction,
  type WampOpenAction,
  type WampRetryAction
} from '../actions/interface';
import {
  ADD_SUBSCRIPTION,
  CONNECTION_CLOSED,
  CONNECTION_OPENED,
  CONNECTION_OPENING,
  CONNECTION_RETRYING,
  REMOVE_SUBSCRIPTION,
  wampClientActionType
} from '../actions/actionTypes';
import {type AutobahnClientName, defaultAutobahnClientName} from '../actions/types';

export const initialWampState: WampState = {
  status: NOT_CONNECTED,
  subscriptions: []
};

export default function (client: AutobahnClientName = defaultAutobahnClientName) {
  const ACTION_HANDLERS: ActionHandlersList<WampState, WampAction> = {
    [wampClientActionType(CONNECTION_OPENING, client)]: (state: WampState) => {
      const {retryCount, ...newState} = state;
      return {
        ...newState,
        status: CONNECTING
      };
    },
    [wampClientActionType(CONNECTION_OPENED, client)]: (
      state: WampState,
      action: WampOpenAction
    ) => {
      const {retryCount, ...newState} = state;
      return {
        ...newState,
        ...action.session,
        status: CONNECTED
      };
    },
    [wampClientActionType(CONNECTION_RETRYING, client)]: (
      state: WampState,
      action: WampRetryAction
    ) => ({
      ...state,
      retryCount: action.retryCount,
      status: RETRYING,
      subscriptions: []
    }),
    [wampClientActionType(CONNECTION_CLOSED, client)]: (state: WampState) => {
      const {retryCount, ...newState} = state;
      return {
        ...newState,
        status: CLOSED,
        subscriptions: []
      };
    },
    [wampClientActionType(ADD_SUBSCRIPTION, client)]: (
      state: WampState,
      action: SubscriptionAction
    ) => ({
      ...state,
      subscriptions: [...state.subscriptions, action.topic]
    }),
    [wampClientActionType(REMOVE_SUBSCRIPTION, client)]: (
      state: WampState,
      action: SubscriptionAction
    ) => {
      const newState: WampState = {
        ...state,
        subscriptions: [...state.subscriptions]
      };
      newState.subscriptions.splice(newState.subscriptions.indexOf(action.topic), 1);
      return newState;
    }
  };

  return (state: WampState = initialWampState, action: WampAction): WampState => {
    const wampReducer = ACTION_HANDLERS[action.type];
    return wampReducer ? wampReducer(state, action) : state;
  };
}
