import React, {type FC, useContext, useMemo} from 'react';
import {type IntlShape, useIntl} from 'react-intl';

import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import {addEntriesToList} from '../../../../shared/contexts/entry/actions';
import {showModal} from '../../../../shared/contexts/actions';
import {EditEntryActionsContext} from '../../../../shared/contexts/entry/EditEntryContext';
import {EditListActionsContext} from '../../../../shared/contexts/list/EditListContext';

import './TranslationItemControls.scss';

type Key = 'add_to_dictionary' | 'add_to_new_list' | 'add_to_existing_list';

type Items = Record<Key, string>;

type Callback = (entryId: string) => void;

const getItems = (intl: IntlShape): Items => {
  return {
    add_to_dictionary: intl.formatMessage({id: 'Dictionary.AddToDictionary'}),
    add_to_new_list: intl.formatMessage({id: 'Dictionary.AddToNewList'}),
    add_to_existing_list: intl.formatMessage({id: 'Dictionary.AddToExistingList'})
  };
};

interface Props {
  includedTranslation?: string;
  onInclude: (callback?: Callback) => void;
  onExclude: () => void;
  getTooltipContainer?: () => HTMLElement;
}

export const TranslationItemControls: FC<Props> = ({
  includedTranslation,
  onInclude,
  onExclude,
  getTooltipContainer
}) => {
  const intl = useIntl();

  const items = useMemo(() => getItems(intl), [intl]);

  const {dispatch} = useContext(EditEntryActionsContext);
  const editListDispatch = useContext(EditListActionsContext);

  const onChange = (key: Key) => {
    switch (key) {
      case 'add_to_dictionary':
        return onInclude();

      case 'add_to_existing_list':
        return dispatch(addEntriesToList([], true, onInclude));

      case 'add_to_new_list':
        dispatch(addEntriesToList([], false, onInclude));
        return editListDispatch(showModal());

      default:
        return;
    }
  };

  if (includedTranslation) {
    return (
      <button
        className="translation-item-controls__button minus"
        title={intl.formatMessage({id: 'Dictionary.DeleteWordFromDictionary'})}
        onClick={onExclude}
      >
        <Icon name="minus-circle" />
      </button>
    );
  }

  return (
    <div
      title={intl.formatMessage({id: 'Dictionary.AddToDictionary'})}
      className="translation-item-controls"
    >
      <DropDown
        items={items}
        onChange={onChange}
        value={<Icon name="plus-circle" />}
        noCaret={true}
        trigger={['click']}
        placement="bottomRight"
        getTooltipContainer={getTooltipContainer}
      />
    </div>
  );
};
