import React from 'react';
import NavItem from 'react-bootstrap/lib/NavItem';
import {connect, type MapDispatchToProps} from 'react-redux';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';

import {type AppState} from 'store/interface';

import Icon from '../../../../components/Icon';
import {type ToggleElementCreator} from '../../../../common/interface';
import {
  changePermissions,
  type ChangePermissionsCreator,
  selectedCamPresence,
  type SelectedDevicePresenceCreator,
  selectedMicPresence,
  toggleDevicesModal
} from './action';

interface SelectDevicesIconStateProps {
  browserHasRTC?: boolean;
  selectedCam?: string;
  selectedMic?: string;
  selectedCamPresent?: boolean;
  selectedMicPresent?: boolean;
  deviceNotSelected?: boolean;
  deviceNotAvailable?: boolean;
  micAccess?: boolean;
  camAccess?: boolean;
  isMobile?: boolean;
}

interface DispatchDevicesIconProps {
  toggleDevicesModal: ToggleElementCreator;
  selectedCamPresence: SelectedDevicePresenceCreator;
  selectedMicPresence: SelectedDevicePresenceCreator;
  changePermissions: ChangePermissionsCreator;
}

const messages = defineMessages({
  MediaDevicesSettingsDefault: {
    id: 'MediaDevicesWizard.MediaDevicesSettingsDefault'
  },
  MediaDevicesSettingsNotSelected: {
    id: 'MediaDevicesWizard.MediaDevicesSettingsNotSelected'
  },
  MediaDevicesSettingsUnavailable: {
    id: 'MediaDevicesWizard.MediaDevicesSettingsUnavailable'
  }
});

interface SelectDevicesIconProps
  extends SelectDevicesIconStateProps,
    DispatchDevicesIconProps,
    WrappedComponentProps {}

class SelectDevicesIcon extends React.Component<SelectDevicesIconProps, {}> {
  public componentDidMount() {
    if (this.props.browserHasRTC) {
      this.setDeviceChangeHandler();
    }
  }

  public componentDidUpdate(prevProps: SelectDevicesIconProps) {
    if (this.props.browserHasRTC && !prevProps.browserHasRTC) {
      this.setDeviceChangeHandler();
    }
  }

  public render() {
    const {browserHasRTC, deviceNotAvailable, deviceNotSelected, micAccess, camAccess} = this.props;
    if (browserHasRTC) {
      const redDot =
        deviceNotAvailable || deviceNotSelected || !micAccess || !camAccess ? (
          <span className="devices-changed-indicator" />
        ) : null;
      return (
        <NavItem onClick={this.showModal} title={this.getTitle()}>
          {redDot}
          <Icon name="cog" size="lg" />
        </NavItem>
      );
    }
    return null;
  }

  private showModal = () => this.props.toggleDevicesModal(true);

  private getTitle = () => {
    const formatMessage = this.props.intl.formatMessage;
    if (this.props.deviceNotSelected) {
      return formatMessage(messages.MediaDevicesSettingsNotSelected);
    }
    if (this.props.deviceNotAvailable) {
      return formatMessage(messages.MediaDevicesSettingsUnavailable);
    }
    return formatMessage(messages.MediaDevicesSettingsDefault);
  };

  private setDeviceChangeHandler = () => {
    this.checkDevicesStatus();
    navigator.mediaDevices.ondevicechange = this.checkDevicesStatus;
  };

  private checkDevicesStatus = () => {
    navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
      const audioPermissions = this.checkPermissions(
        devices.filter(device => device.kind === 'audioinput')
      );
      const videoPermissions = this.checkPermissions(
        devices.filter(device => device.kind === 'videoinput')
      );
      if (audioPermissions !== this.props.micAccess || videoPermissions !== this.props.camAccess) {
        this.props.changePermissions({
          video: videoPermissions,
          audio: audioPermissions
        });
      }
      const inputDevices = devices.filter(
        device => device.kind === 'videoinput' || device.kind === 'audioinput'
      );
      this.checkDevicesPresence(inputDevices);
    });
  };

  private checkPermissions = (devices: MediaDeviceInfo[] | undefined) => {
    if (devices) {
      return devices.some(device => device.label !== '');
    }
    return true;
  };

  private checkDevicesPresence = (devices: MediaDeviceInfo[]) => {
    if (this.props.selectedCam) {
      if (devices.find(device => device.deviceId === this.props.selectedCam)) {
        if (!this.props.selectedCamPresent) {
          this.props.selectedCamPresence(true);
        }
      } else {
        if (this.props.selectedCamPresent) {
          this.props.selectedCamPresence(false);
        }
      }
    }

    if (this.props.selectedMic) {
      if (devices.find(device => device.deviceId === this.props.selectedMic)) {
        if (!this.props.selectedMicPresent) {
          this.props.selectedMicPresence(true);
        }
      } else {
        if (this.props.selectedMicPresent) {
          this.props.selectedMicPresence(false);
        }
      }
    }
  };
}

const mapStateToProps = (state: AppState) =>
  ({
    browserHasRTC: state.rtc && state.rtc.badBrowser === false,
    selectedCam: state.mediaDevices.cam,
    selectedMic: state.mediaDevices.mic,
    selectedCamPresent: state.mediaDevices.selectedCamPresent,
    selectedMicPresent: state.mediaDevices.selectedMicPresent,
    deviceNotSelected: !state.mediaDevices.cam || !state.mediaDevices.mic,
    deviceNotAvailable:
      !state.mediaDevices.selectedMicPresent || !state.mediaDevices.selectedCamPresent,
    camAccess: state.mediaDevices.cameraAccess,
    micAccess: state.mediaDevices.micAccess,
    isMobile: state.layout.isMobile
  }) as SelectDevicesIconStateProps;

const mapDispatchToProps: MapDispatchToProps<DispatchDevicesIconProps, {}> = dispatch => ({
  toggleDevicesModal: (show: boolean) => dispatch(toggleDevicesModal(show)),
  selectedCamPresence: (present: boolean) => dispatch(selectedCamPresence(present)),
  selectedMicPresence: (present: boolean) => dispatch(selectedMicPresence(present)),
  changePermissions: (options: MediaStreamConstraints) => dispatch(changePermissions(options))
});

export default connect<SelectDevicesIconStateProps, DispatchDevicesIconProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(SelectDevicesIcon));
