import React, {type Dispatch, type FC, useCallback, useEffect, useRef, useState} from 'react';
import {useSelector} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import {type Action} from 'redux';
import {Navigation} from 'swiper';
import {Swiper, type SwiperRef, SwiperSlide} from 'swiper/react';
import {type Swiper as SwiperClass} from 'swiper/types';

import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import {isMobileWebView} from 'helpers/browser';
import {useWebViewMessage, WebViewMessageType} from 'hooks/webview/useWebViewMessage';

import {activateResource, deactivateResource} from './state/actions';
import {Type, type ViewerState} from './interface';
import Slide from './Slide';
import Arrow from './Arrow';
import {animationSpeed} from './utils';

import './Viewer.scss';

interface OwnProps extends ViewerState {
  dispatch: Dispatch<Action>;
}

interface Props extends OwnProps {}

const Viewer: FC<Props> = props => {
  if (!props.activeResourceId || !props.activeProviderId) {
    return null;
  }

  if (isMobileWebView()) {
    return <InternalWebviewViewer {...props} />;
  }

  return <InternalViewer {...props} />;
};

const InternalViewer: FC<Props> = ({
  activeProviderId,
  dispatch,
  activeResourceId,
  providers,
  resourceType
}) => {
  const [expanded, setExpanded] = useState(false);
  const swiper = useRef<SwiperRef>(null);
  const isMobile = useSelector<AppState, boolean>(s => !!s.layout.isMobile);

  const isResourcesValid = activeProviderId && activeResourceId && resourceType;
  const resources = isResourcesValid
    ? providers.find(p => p.id === activeProviderId)?.resources[resourceType]
    : null;
  const index = resources?.findIndex(item => item.id === activeResourceId) || 0;
  const arrowIsFirst = index === 0;
  const arrowIsLast = !!(resources && index === resources.length - 1);

  const onClose = useCallback(() => {
    dispatch(deactivateResource());
  }, [dispatch]);

  const onExpand = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  const onSlideChange = useCallback(
    (e: SwiperClass) => {
      if (!resources) return;

      setExpanded(false);
      dispatch(activateResource(activeProviderId!, resources[e.activeIndex].id, resourceType!));
    },
    [activeProviderId, dispatch, resourceType, resources]
  );

  useEffect(() => {
    const currentActiveIndex = swiper.current?.swiper?.activeIndex;

    if (index !== currentActiveIndex) {
      setExpanded(false);
      swiper.current?.swiper?.slideTo(index, animationSpeed);
    }
  }, [index]);

  if (!resources || !resources.length) return null;

  return (
    <div className={classNames('viewer-carousel', {'with-expand': resourceType === Type.IMAGE})}>
      <div className="viewer-carousel-header">
        {resourceType === Type.IMAGE && (
          <Button className="button" onClick={onExpand}>
            <Icon name={`search-${expanded ? 'minus' : 'plus'}`} />
          </Button>
        )}
        <Button className="button" onClick={onClose}>
          <Icon name="close" />
        </Button>
      </div>
      <Swiper
        ref={swiper}
        noSwiping={expanded}
        className="swiper-no-swiping"
        speed={animationSpeed}
        initialSlide={index}
        onSlideChange={onSlideChange}
        modules={[Navigation]}
        navigation={{
          prevEl: '.left',
          nextEl: '.right'
        }}
      >
        {resources.map(r => (
          <SwiperSlide key={r.id}>
            <Slide
              activeResourceId={activeResourceId!}
              expanded={expanded}
              isMobile={isMobile}
              resource={r}
              resourceType={resourceType!}
            />
          </SwiperSlide>
        ))}
        <Arrow direction="left" disabled={arrowIsFirst} />
        <Arrow direction="right" disabled={arrowIsLast} />
      </Swiper>
    </div>
  );
};

const InternalWebviewViewer: FC<Props> = ({
  activeProviderId,
  activeResourceId,
  providers,
  resourceType,
  dispatch
}) => {
  const isResourcesValid = activeProviderId && activeResourceId && resourceType;

  const resources = isResourcesValid
    ? providers.find(p => p.id === activeProviderId)?.resources[resourceType]
    : null;

  const index = resources?.findIndex(item => item.id === activeResourceId) ?? 0;

  const postMessage = useWebViewMessage();

  useEffect(() => {
    if (resources) {
      postMessage({type: WebViewMessageType.OpenResourceViewer, payload: {resources, index}});
      dispatch(deactivateResource());
    }
  }, [dispatch, postMessage, resources, index]);

  return null;
};

export default Viewer;
