import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

interface AppOfflineModalProps {
  show: boolean;
  isMobile?: boolean;
}

export default class AppOffline extends React.Component<AppOfflineModalProps, {}> {
  public render() {
    if (this.props.isMobile) {
      if (this.props.show) {
        return this.renderMobileView();
      }
      return null;
    }
    return (
      <Modal
        backdrop="static"
        className="error-modal modal-center app-offline"
        show={this.props.show}
        onHide={() => null}
        backdropClassName="app-offline"
      >
        <Modal.Body>
          <div className="title text text-danger text-center">
            <FormattedMessage id="WampDisconnected.AppOffline.Header" />
          </div>

          <div className="description text text-center">
            <FormattedMessage id="WampDisconnected.AppOffline.description" />
          </div>
        </Modal.Body>
      </Modal>
    );
  }

  private renderMobileView = () => (
    <div className="wamp-disconnected-mobile">
      <div>
        <FormattedMessage id="WampDisconnected.AppOffline.Header" />
      </div>
    </div>
  );
}
