import {type Action} from 'redux';
import {type IntlShape} from 'react-intl';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';
import {addEntriesToListRequest, createWordEntry, editWordEntry} from 'store/dictionary/requests';
import {type AxiosResponseAction} from 'services/axios/interface';
import * as toastr from 'components/toastr';

import {
  ADD_ENTRY_TO_LIST,
  CLEAR_DELETE_ENTRY,
  CLEAR_EDIT,
  CLEAR_PRONUNCIATION,
  CLOSE_FORM,
  DESELECT_ALL,
  DESELECT_ENTRIES,
  ENTER_SELECTION_MODE,
  EXIT_SELECTION_MODE,
  RESET,
  SELECT_ALL,
  SELECT_ENTRIES,
  SET_DICTIONARY_ENTRY_TO_DELETE,
  SET_ENTRY_TO_EDIT,
  SET_LIST_ENTRY_TO_DELETE,
  SET_SELECTION_ACTION,
  SUBMIT_PRONUNCIATION,
  TOGGLE_FORM
} from '../actionTypes';
import {type DictionaryEntryInstance} from '../../interface';
import {type SpellingObject, type SubmitCallback} from './interface';
import {type DictionaryOwnerRole} from '../index';

export interface SubmitPronunciationAction extends Action {
  soundId?: number | null;
  phoneticSpelling?: string | null;
}

export const submitPronunciation = (
  soundId?: number | null,
  phoneticSpelling?: string | null
): SubmitPronunciationAction => ({
  type: SUBMIT_PRONUNCIATION,
  soundId,
  phoneticSpelling
});

export const clearPronunciation = () => ({type: CLEAR_PRONUNCIATION});

export interface ToggleFormAction extends Action {
  listId?: string;
}
export const toggleForm = (listId?: string): ToggleFormAction => ({type: TOGGLE_FORM, listId});
export const closeForm = (): Action => ({type: CLOSE_FORM});
export const resetModal = () => ({type: RESET});
export const clearEdit = () => ({type: CLEAR_EDIT});
export const clearDeleteEntry = () => ({type: CLEAR_DELETE_ENTRY});

export interface SetListEntryToDeleteAction extends Action {
  listId: string;
  entryId: string;
}
export const setListEntryToDelete = (
  listId: string,
  entryId: string
): SetListEntryToDeleteAction => ({type: SET_LIST_ENTRY_TO_DELETE, listId, entryId});

export interface SetDictionaryEntryToDeleteAction extends Action {
  entryId: string;
  deletingFromListPage?: boolean;
}
export const setDictionaryEntryToDelete = (
  entryId: string,
  deletingFromListPage?: boolean
): SetDictionaryEntryToDeleteAction => ({
  type: SET_DICTIONARY_ENTRY_TO_DELETE,
  entryId,
  deletingFromListPage
});

export interface AddEntriesToListAction extends Action {
  entryIds: string[];
  existingList?: boolean;
  submit?: (callback: SubmitCallback) => void;
}

export const addEntriesToList = (
  entryIds: string[],
  existingList?: boolean,
  submit?: (callback: SubmitCallback) => void
): AddEntriesToListAction => ({type: ADD_ENTRY_TO_LIST, entryIds, existingList, submit});

export interface SetEntryToEditAction extends Action {
  entry: DictionaryEntryInstance;
}
export const setEntryToEdit = (entry: DictionaryEntryInstance) => ({
  type: SET_ENTRY_TO_EDIT,
  entry
});

interface CreateOrEditWordArgs {
  dictionaryOwnerId: number;
  dictionaryOwnerRole: DictionaryOwnerRole;
  editedEntry?: DictionaryEntryInstance;
  dataObj: {
    original: string;
    translation: string;
  } & SpellingObject;
  listId?: string;
  intl: IntlShape;
}
export const createOrEditWord =
  ({
    dictionaryOwnerId,
    editedEntry,
    dataObj,
    listId,
    intl,
    dictionaryOwnerRole
  }: CreateOrEditWordArgs) =>
  async (dispatch: Dispatch<Action, AppState>) => {
    if (!editedEntry) {
      let addToListError = false;
      try {
        const response = await dispatch<AxiosResponseAction<DictionaryEntryInstance>>(
          createWordEntry(dictionaryOwnerId, dictionaryOwnerRole, dataObj)
        );
        if (response.payload.data && listId) {
          try {
            await dispatch(
              addEntriesToListRequest(
                dictionaryOwnerId,
                dictionaryOwnerRole,
                [response.payload.data.id],
                listId
              )
            );
            toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListSuccess'}));
          } catch (e) {
            addToListError = true;
            throw e;
          }
        } else {
          toastr.success('', intl.formatMessage({id: 'Dictionary.Entry.CreationSuccess'}));
        }
      } catch (e) {
        toastr.error(
          '',
          addToListError
            ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListError'})
            : intl.formatMessage({id: 'Dictionary.Entry.CreationError'})
        );
        throw e;
      }
    } else {
      try {
        await dispatch<AxiosResponseAction<DictionaryEntryInstance>>(
          editWordEntry(dictionaryOwnerId, dictionaryOwnerRole, editedEntry.id, dataObj)
        );
        toastr.success('', intl.formatMessage({id: 'Dictionary.Entry.EditingSuccess'}));
      } catch (e) {
        if (e.error.response?.status !== 409)
          toastr.error('', intl.formatMessage({id: 'Dictionary.Entry.EditingError'}));
        throw e;
      }
    }
  };

export interface EnterSelectionModeAction extends Action {
  listId?: string;
}
export const enterSelectionMode = (listId?: string) => ({type: ENTER_SELECTION_MODE, listId});

export const exitSelectionMode = () => ({type: EXIT_SELECTION_MODE});

export interface SelectEntriesAction extends Action {
  entryIds: string[];
}
export const selectEntries = (entryIds: string[]): SelectEntriesAction => ({
  type: SELECT_ENTRIES,
  entryIds
});
export const deselectEntries = (entryIds: string[]): SelectEntriesAction => ({
  type: DESELECT_ENTRIES,
  entryIds
});

export const deselectAll = () => ({type: DESELECT_ALL});

export interface SelectAllEntriesAction extends Action {
  entryIds: string[];
}
export const selectAll = (entryIds: string[]): SelectAllEntriesAction => ({
  type: SELECT_ALL,
  entryIds
});

export interface SetSelectionActionAction extends Action {
  action?: 'add' | 'delete';
  existingList?: boolean;
}
export const setSelectionAction = (
  action?: 'add' | 'delete',
  existingList?: boolean
): SetSelectionActionAction => ({
  type: SET_SELECTION_ACTION,
  action,
  existingList
});
