import React, {type KeyboardEvent, PureComponent, type SyntheticEvent} from 'react';
import {default as FormControl, type FormControlProps} from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import classNames from 'classnames';

import {maxSearchStringLength, searchTimeout} from 'config/static';
import {type Role} from 'store/interface';

import Icon from './Icon';
import './searchbar.scss';

const messages = defineMessages({
  ClearSearchField: {
    id: 'Search.ClearSearchField'
  },
  FindAStudent: {
    id: 'Search.FindAStudent'
  },
  FindATeacher: {
    id: 'Search.FindATeacher'
  }
});

export interface SearchBarStateProps {
  filter: string;
  getSize?: (height: number, width: number) => void;
  isSmall?: boolean;
  autoFocus?: boolean;
  userRole?: Role;
  isMobile?: boolean;
  placeholder?: string;
  disabled?: boolean;
  clearOnUnmount?: boolean;
}

export interface DispatchSearchBarProps {
  changeFilter: (filter: string) => void;
  collapseSearchbar?: () => void;
}

type Props = SearchBarStateProps & DispatchSearchBarProps & WrappedComponentProps;

interface SearchBarState {
  textInput: string;
}

class SearchBar extends PureComponent<Props, SearchBarState> {
  public static defaultProps: Partial<Props> = {
    clearOnUnmount: true
  };

  private searchBarDiv: HTMLDivElement;
  private filterChangeTimeout: NodeJS.Timeout;

  public constructor(props: Props, context: {}) {
    super(props, context);
    this.state = {textInput: props.filter};
  }

  public componentDidMount() {
    if (this.props.getSize) {
      this.props.getSize(this.searchBarDiv.clientHeight, this.searchBarDiv.clientWidth);
    }
  }

  public componentWillUnmount() {
    if (this.props.clearOnUnmount && this.props.filter !== '') {
      this.props.changeFilter('');
    }
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<SearchBarState>) {
    if (prevProps.filter !== this.props.filter && this.props.filter !== this.state.textInput) {
      this.setState({textInput: this.props.filter});
    }
  }

  public render() {
    let searchIconHint;
    if (this.props.userRole) {
      searchIconHint =
        this.props.userRole === 'student'
          ? this.props.intl.formatMessage(messages.FindATeacher)
          : this.props.intl.formatMessage(messages.FindAStudent);
    }
    const closeButton =
      this.props.filter || this.props.collapseSearchbar ? (
        <Icon
          name="pc-close"
          size="lg"
          onClick={this.onCloseIconClick}
          title={this.props.intl.formatMessage(messages.ClearSearchField)}
        />
      ) : (
        <Icon name="virc-search" size="lg" title={searchIconHint} />
      );
    const inputClass = classNames({
      small: this.props.isSmall,
      large: !this.props.isSmall,
      mobile: this.props.isMobile
    });
    return (
      <div className={'search-bar ' + inputClass} ref={this.getRef}>
        <FormGroup className={this.props.isMobile ? 'mobile' : ''}>
          <FormControl
            type="text"
            onChange={this.changeHandler}
            onKeyUp={this.keyUpHandler}
            onBlur={this.blurHandler}
            className={inputClass}
            autoFocus={this.props.autoFocus}
            value={this.state.textInput}
            bsSize={inputClass === 'large' ? 'lg' : undefined}
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
          />
          {closeButton}
        </FormGroup>
      </div>
    );
  }

  private getRef = (el: HTMLDivElement | null) => (this.searchBarDiv = el!);

  private onCloseIconClick = () => {
    this.props.changeFilter('');
    if (this.props.collapseSearchbar) {
      this.props.collapseSearchbar();
    }
  };

  private blurHandler = () => {
    this.props.changeFilter(this.state.textInput);
  };

  private changeHandler = (event: SyntheticEvent<FormControlProps>) => {
    this.setState({textInput: event.currentTarget.value as string});
    const value: string = event.currentTarget.value as string;
    clearTimeout(this.filterChangeTimeout);
    if (value.length > maxSearchStringLength) {
      const newValue = (event.currentTarget.value as string).substr(0, maxSearchStringLength);
      this.setState({textInput: newValue});
      this.props.changeFilter(newValue);
    }
    this.filterChangeTimeout = setTimeout(() => {
      this.props.changeFilter(this.state.textInput);
    }, searchTimeout);
  };

  private keyUpHandler = (event: KeyboardEvent<FormControl>) => {
    const escapeKeyCode: number = 27;
    if (event.keyCode === escapeKeyCode) {
      clearTimeout(this.filterChangeTimeout);
      this.setState({textInput: ''});
      this.props.changeFilter('');
      if (this.props.collapseSearchbar) {
        this.props.collapseSearchbar();
      }
    }
  };
}

export default injectIntl(SearchBar);
