import {createTransform} from 'redux-persist';

import {type VideoState} from './interface';

const transformVideo = createTransform<VideoState | undefined, VideoState | undefined>(
  filter,
  filter
);

export default transformVideo;

function filter(state: VideoState, key: string): VideoState {
  if (key !== 'video') {
    return state;
  }
  const {volume} = state;
  return {players: [], volume};
}
