import {type MatchingJSON, MatchingType} from 'store/exercise/player/widgets/Matching/interface';

import XMatchingFreeChoiceRecord from './XMatchingFreeChoiceRecord';
import XMatchingNoCategoriesRecord from './XMatchingNoCategoriesRecord';
import XMatchingRecord from './XMatchingRecord';

export const xmatchingFactory = (raw: MatchingJSON) => {
  switch (raw.matchingType) {
    case MatchingType.FREE_CHOICE: {
      return new XMatchingFreeChoiceRecord(raw);
    }
    case MatchingType.NO_CATEGORIES: {
      return new XMatchingNoCategoriesRecord(raw);
    }
    default:
      return new XMatchingRecord(raw);
  }
};
