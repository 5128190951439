import {connect, type MapDispatchToProps} from 'react-redux';
import {type Action} from 'redux';
import {type AxiosAction, type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';

import {requestMessageSend, requestSubjects, toggleContactsModal} from '../actions/action';
import MessageModal, {type MessageModalProps} from '../components/MessageModal';
import {type ToggleElementCreator} from '../../common/interface';

export interface DispatchMessageModalProps {
  toggleContactsModal: ToggleElementCreator;
  requestSubjects: () => Promise<Action | null>;
  requestMessageSend: (topic: string, message: string, copy?: boolean) => Promise<Action | null>;
}

type MessageModalStateToProps = (state: AppState) => MessageModalProps;

const mapStateToProps: MessageModalStateToProps = state => ({
  isShowModal: state.layout.showContactsModal,
  sendingMessageAwait: state.layout.sendingMessageAwait,
  subjects: state.layout.subjects,
  networkError: state.layout.networkError,
  isMobile: state.layout.isMobile
});

const mapDispatchToProps: MapDispatchToProps<DispatchMessageModalProps, {}> = (
  dispatch: Dispatch<Action, AppState>
) => ({
  toggleContactsModal: (show: boolean) => dispatch(toggleContactsModal(show)),
  requestSubjects: () => dispatch(requestSubjects() as AxiosAction),
  requestMessageSend: (topic: string, message: string, copy?: boolean) =>
    dispatch(requestMessageSend(topic, message, copy) as AxiosAction)
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageModal);
