import {type AxiosRequestAction} from 'services/axios/interface';

import {LOAD_DOCUMENT_INSTANCE} from './actionTypes';

export const loadDocumentInstance = (documentInstanceId: number): AxiosRequestAction => ({
  type: LOAD_DOCUMENT_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/document-instance/${documentInstanceId}`
    }
  }
});
