import {type Editor, type Block, type Mark, type Node} from '@englex/slate';
import {type Set} from 'immutable';

import {SlateMark, getMarkClassNames} from '../interface';
import {enumValues} from '../../../helpers/enum';

export const getActiveMarksClassNamesForNode = (editor: Editor, node: Node, parent: Node) => {
  const rangeOfNode = editor.value.document.createRange({}).moveToRangeOfNode(node);
  const activeMarks = (parent as Block).getActiveMarksAtRange(rangeOfNode);

  if (!activeMarks) {
    return [];
  }

  return getMarkClassNamesForNode(editor, activeMarks, enumValues(SlateMark));
};

/**
 * Returns mark class names for node
 * @param editor
 * @param marks
 * @param allowedMarkTypes
 * @return string[]
 */
export function getMarkClassNamesForNode(
  editor: Editor,
  marks: Set<Mark>,
  allowedMarkTypes: SlateMark[]
): string[] {
  const classNames: string[] = [];
  marks.forEach((mark: Mark) => {
    if (!allowedMarkTypes.includes(mark.type as SlateMark)) {
      return;
    }
    const markClassNames = editor.query<string, [Mark]>(getMarkClassNames, mark);
    classNames.push(markClassNames);
  });
  return classNames;
}
