import {useCallback, useState} from 'react';
import type Scrollbars from 'react-custom-scrollbars';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

export const useScrollSaver = (scrollbar: Scrollbars | null) => {
  const [position, setPosition] = useState(0);
  const isMounted = useIsMounted();
  const saveScrollPosition = useCallback(
    isReverse =>
      isReverse && isMounted.current
        ? setPosition(
            Math.round(scrollbar?.getScrollHeight() || 0) - (scrollbar?.getScrollTop() || 0)
          )
        : setPosition(scrollbar?.getScrollTop() || 0),
    [isMounted, scrollbar]
  );

  const scrollToSavedPosition = useCallback(
    isReverse => {
      scrollbar?.scrollTop(isReverse ? scrollbar?.getScrollHeight() - position : position);
    },
    [scrollbar, position]
  );

  return {
    saveScrollPosition,
    scrollToSavedPosition
  };
};
