import {
  type Editor,
  type CommandFunc,
  type Inline,
  type InlineProperties,
  type InlineJSON
} from '@englex/slate';

import {type SlateMark, SlateObject, isVoidInlineSelectedOnly} from '../../interface';

/**
 * Fixes applying commands like 'toggleMark' when the only
 * void inline is selected (selection is collapsed and in the text node inside the void inline node)
 * Same as crutchFixOnInsertInline, it just temporarily (w/o saving) extends the end of selection
 * to the next text, applies the target command and revert selection back
 *
 * @param editor
 * @param command
 */
const crutchFixSelectionForTheOnlyVoidInlineSelected = (editor: Editor, command: CommandFunc) => {
  let voidInlineSelectionPatched = false;
  if (!editor.hasQuery(isVoidInlineSelectedOnly)) {
    editor.command(command);
    return;
  }

  if (editor.query(isVoidInlineSelectedOnly)) {
    editor.withoutSaving(() => {
      editor.moveEndToStartOfNextText();
      voidInlineSelectionPatched = true;
    });
  }

  editor.command(command);

  if (voidInlineSelectionPatched) {
    editor.withoutSaving(() => {
      editor.moveToStart();
    });
  }
};

/**
 * Fixes insert inline for expanded selection with text node contains marks
 * Should be used before 'insertInline' command everywhere
 * @see https://redmine.englex.ru/issues/5334
 */
const crutchFixOnInsertInline = (editor: Editor) => {
  const selection = editor.value.selection;
  if (!selection?.isSet || !selection.isExpanded) {
    return;
  }
  const startText = editor.value.startText;
  const endText = editor.value.endText;

  if (!startText) return;

  if (
    startText !== endText ||
    selection.start.offset !== 0 ||
    selection.end.offset !== startText.text.length
  ) {
    // do nothing unless the only one text node is fully selected
    return;
  }

  const parent = editor.value.document.getParent(startText.key);
  if (parent?.object !== SlateObject.BLOCK) {
    // do nothing unless the parent of the selected text is block object
    return;
  }

  if (parent?.getFirstText() !== startText) {
    // do nothing unless the selected text is the first text node inside parent block
    return;
  }

  if (!editor.value.endText) return;

  const mayBeNextSiblingText = editor.value.document.getNextSibling(editor.value.endText.key);
  if (mayBeNextSiblingText?.object !== SlateObject.TEXT) {
    // do nothing unless the next sibling node is text
    return;
  }

  editor.withoutSaving(() => {
    if (editor.value.selection?.isBackward) {
      editor.flip();
    }
    // Finally, this is the crutch fix for the selection.
    editor.moveEndToStartOfNextText();
  });
};

/**
 * Custom toggleMark implementation
 * @param editor
 * @param mark
 */
export const toggleMark = (editor: Editor, mark: SlateMark): void => {
  crutchFixSelectionForTheOnlyVoidInlineSelected(editor, e => e.toggleMark(mark));
};

/**
 * Custom addMark implementation
 * @param editor
 * @param mark
 */
export const addMark = (editor: Editor, mark: SlateMark): void => {
  crutchFixSelectionForTheOnlyVoidInlineSelected(editor, e => e.addMark(mark));
};

/**
 * Custom removeMark implementation
 * @param editor
 * @param mark
 */
export const removeMark = (editor: Editor, mark: SlateMark): void => {
  crutchFixSelectionForTheOnlyVoidInlineSelected(editor, e => e.removeMark(mark));
};

/**
 * Custom insertInline implementation
 * @param editor
 * @param inline
 */
export const insertInline = (
  editor: Editor,
  inline: string | Inline | InlineProperties | InlineJSON
) => {
  editor.command(crutchFixOnInsertInline).insertInline(inline);
};
