import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {type ModalProps} from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {type Color, ColorsPaint, usePaint} from '@englex/paint-react';

import messages from '../../messages';
import {MobileModal} from '../modal';
import {ColorPalette} from './ColorPalette';
import {MobileColorPicker} from './MobileColorPicker';

const defaultPalette: Color[] = [
  '#414141',
  '#8f5721',
  '#b14b26',
  '#f36f20',
  '#ffb400',
  '#6e8532',
  '#33a6b3',
  '#7167b4'
];

interface Props extends ModalProps {
  active?: string;
}

export const MobileColorModal: React.FC<Props> = ({active, onHide, ...rest}) => {
  const paint = usePaint<ColorsPaint>();

  const intl = useIntl();

  const [showPicker, setShowPicker] = useState(false);
  const [pickerColor, setPickerColor] = useState<Color | undefined>(active);

  const title = intl.formatMessage(messages.selectColor);

  const colorPaletteTitle = intl.formatMessage(messages.default);

  const myColorPaletteTitle = intl.formatMessage(messages.myPalette);

  const buttonTitle = showPicker
    ? intl.formatMessage(messages.done)
    : intl.formatMessage(messages.customColor);

  const myPalette = paint.colors.recent || [];

  const closeModal = () => {
    onHide();
    setShowPicker(false);
  };

  const selectColor = (color: Color) => {
    ColorsPaint.setColor(paint, color);
    closeModal();
  };

  const selectMyColor = (color?: Color) => {
    if (color) {
      ColorsPaint.setColor(paint, color);
      ColorsPaint.addRecent(paint, color, {
        exclude: defaultPalette,
        maxRecent: 2 * defaultPalette.length
      });
    }

    closeModal();
  };

  const handleClick = () => {
    if (showPicker) return selectMyColor(pickerColor);

    setShowPicker(true);
  };

  const renderFooter = () => {
    return (
      <Button bsStyle="primary" className="modal-footer__button" onClick={handleClick}>
        <span className="title">{buttonTitle}</span>
      </Button>
    );
  };

  return (
    <MobileModal title={title} renderFooter={renderFooter} onHide={closeModal} {...rest}>
      {showPicker ? (
        <MobileColorPicker color={pickerColor} onChange={setPickerColor} />
      ) : (
        <>
          <ColorPalette
            active={active}
            palette={defaultPalette}
            title={colorPaletteTitle}
            onSelect={selectColor}
          />

          {!!myPalette.length && (
            <ColorPalette
              active={active}
              palette={myPalette}
              title={myColorPaletteTitle}
              onSelect={selectColor}
            />
          )}
        </>
      )}
    </MobileModal>
  );
};
