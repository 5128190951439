import React, {useCallback, useState} from 'react';

import {type AudioFile} from '../../components/media/interface';
import {
  useWebViewMessage,
  WebViewMessageType,
  type PlayerPlayStatus
} from '../../hooks/webview/useWebViewMessage';

interface WebviewPlayerState {
  audio?: AudioFile;
  playStatus?: PlayerPlayStatus;
}

interface WebviewPlayerProps extends WebviewPlayerState {
  setAudioState: (audio?: AudioFile, playStatus?: PlayerPlayStatus) => void;
}

export const WebviewPlayerContext = React.createContext<WebviewPlayerProps>({
  setAudioState: () => {}
});

export const WebviewPlayerProvider: React.FC = ({children}) => {
  const [state, setState] = useState<WebviewPlayerState>({});

  const setAudioState = useCallback(
    (audio?: AudioFile, playStatus?: PlayerPlayStatus) =>
      setState(prevState => ({...prevState, audio, playStatus})),
    []
  );

  useWebViewMessage(message => {
    if (message.type === WebViewMessageType.Player) {
      setAudioState(message.payload.audio, message.payload.playStatus);
    }
  });

  const value = {...state, setAudioState};

  return <WebviewPlayerContext.Provider value={value}>{children}</WebviewPlayerContext.Provider>;
};
