import React from 'react';
import Modal, {type ModalProps} from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {isMobileWebView} from 'helpers/browser';

import './MobileModal.scss';

interface Props extends ModalProps {
  title: string;
  renderFooter?: () => React.ReactElement;
}

export const MobileModal: React.FC<Props> = ({title, renderFooter, onHide, children, ...rest}) => {
  const onClose = () => onHide();

  const webview = isMobileWebView();

  return (
    <Modal
      className={classNames('painter-mobile-modal', {webview})}
      restoreFocus={true}
      onHide={onHide}
      {...rest}
    >
      <Modal.Header>
        <span className="title">{title}</span>

        <Button className="btn-sm close-button" onClick={onClose}>
          <Icon name="pc-close" />
        </Button>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      {renderFooter && <Modal.Footer>{renderFooter()}</Modal.Footer>}
    </Modal>
  );
};
