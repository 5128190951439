/* eslint-disable @typescript-eslint/no-explicit-any */
import {META_KEY} from '../meta';
import {defaultValues} from '../helpers';

export function record(): ClassDecorator {
  return (target: Function): void => {
    const proto = target.prototype;

    if (proto[META_KEY]) {
      const meta = proto[META_KEY];
      const props = {};
      for (const prop in meta) {
        if (meta.hasOwnProperty(prop)) {
          props[prop] = meta[prop];
        }
      }

      delete proto[META_KEY];
      updateRecordProto(proto, props);
    }
  };
}

// immutable 3.8.1
function updateRecordProto(proto: any, props: object): void {
  const keys = Object.keys(props);

  keys.forEach(key => {
    setProp(proto, key);
  });

  proto._defaultValues = defaultValues.merge(proto, props);
}

function setProp(proto: any, name: string) {
  Object.defineProperty(proto, name, {
    get() {
      return this.get(name);
    },
    set(value: any) {
      // babel adds class instance properties and assign them with undefined in constructor,
      // but it is not allowed with immutable records
      // added hack here about skipping this behavior
      if (!this.__ownerID && value !== undefined) {
        throw new Error('Cannot set on an immutable record.');
      }
      if (value !== undefined) {
        this.set(name, value);
      }
    }
  });
}
