// This module disables React DevTools on production environment

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reactDevToolsGlobalHook = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;
if (import.meta.env.MODE === 'production' && typeof reactDevToolsGlobalHook === 'object') {
  reactDevToolsGlobalHook._renderers = {};

  reactDevToolsGlobalHook.inject = () => {};
}
export {};
