import {type Editor, type SchemaProperties, type SlateError} from '@englex/slate';

import {wordTemplateLiteral} from 'config/static';

import {SlateBlock} from '../../../interface';
import {logNormalizeError} from '../utils';

export const schema: SchemaProperties = {
  rules: [
    {
      match: {type: SlateBlock.DEFAULT},
      text: /^[^·]*$/,
      normalize: (change: Editor, error: SlateError) => {
        if (error.code === 'node_text_invalid') {
          const textNode = error.node.getFirstText();
          if (textNode) {
            const indices: number[] = [];
            textNode.text.split('').forEach((char: string, index: number) => {
              if (char === wordTemplateLiteral) {
                indices.unshift(index);
              }
            });
            change.withoutNormalizing(() => {
              indices.forEach(index => {
                change.removeTextByKey(textNode.key, index, 1);
              });
            });
            logNormalizeError(error, 'TextDecorator.schema.rules', true, '0');
            return;
          }
        }
        logNormalizeError(error, 'TextDecorator.schema.rules', false, '0');
      }
    }
  ]
};
