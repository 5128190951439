import React from 'react';
import classNames from 'classnames';
import {FormattedMessage, FormattedTime} from 'react-intl';

import {type CallEndedMeta, type ChatMessage} from 'store/interface';
import {toLocalDate} from 'services/common-methods/toLocalDate';
import {formatSecondsToString} from 'services/common-methods/secondsToString';
import {ServiceMessageTypes} from 'common/enums';

import ServiceMessageIcon from './ServiceMessageIcon';
import UnreadCircle from './UnreadCircle';
import {ClientMessageTypes} from '../../../actions/interface';

export interface AddedCallDurationProps {
  // is added only if this message needs current call timer
  callDuration?: number;
}

export interface ServiceMessageProps extends ChatMessage {
  getRef: (el: HTMLDivElement, message: ChatMessage) => void;
  type: ServiceMessageTypes | ClientMessageTypes;
  isMobile?: boolean;
  userTimezone: string;
  partnerName: string;
  isLastMessage: boolean;
}

export default class ServiceMessage extends React.PureComponent<
  ServiceMessageProps & AddedCallDurationProps,
  {}
> {
  public render() {
    const {own, isMobile, type, new: isNew, isLastMessage} = this.props;
    const className = classNames('chat-message service', {own}, {mobile: isMobile});
    return (
      <div className={classNames('message service-message', {isLastMessage})} ref={this.ref}>
        <div className={className}>
          <ServiceMessageIcon type={type} />
          <UnreadCircle shouldRender={isNew} />
          {this.renderServiceText()}
        </div>
      </div>
    );
  }

  private ref = (el: HTMLDivElement) => el && this.props.getRef(el, this.props);

  private renderServiceText = () => {
    const {own, type, created_at, userTimezone} = this.props;
    const missedOrDeclined =
      type === ServiceMessageTypes.missed || type === ClientMessageTypes.declined;
    const notAnsweredOrBusy =
      type === ClientMessageTypes.noAnswer || type === ServiceMessageTypes.busy;
    const textClasses = classNames(
      'chat-message-text service',
      {own},
      {'red-text': missedOrDeclined || notAnsweredOrBusy}
    );
    return (
      <div className={textClasses}>
        <FormattedTime value={toLocalDate(new Date(created_at), userTimezone)} />
        {this.renderMessage()}
      </div>
    );
  };

  private renderMessage = () => {
    switch (this.props.type) {
      case ServiceMessageTypes.missed:
        return <FormattedMessage id="Chat.MissedCall" />;
      case ClientMessageTypes.noAnswer:
        return (
          <FormattedMessage id="Chat.DoesntResponse" values={{name: this.props.partnerName}} />
        );
      case ServiceMessageTypes.busy:
        return <FormattedMessage id="Chat.IsBusy" values={{name: this.props.partnerName}} />;
      case ClientMessageTypes.declined:
        return <FormattedMessage id="Chat.CallDeclined" />;
      case ClientMessageTypes.incomingCall:
        return [
          <FormattedMessage id="Chat.IncomingCall" key="1" />,
          this.renderCurrentCallDuration()
        ];
      case ClientMessageTypes.outgoingCall:
        return [
          <FormattedMessage id="Chat.OutgoingCall" key="1" />,
          this.renderCurrentCallDuration()
        ];
      case ServiceMessageTypes.endCall:
        return [
          <FormattedMessage id="Chat.EndCall" key="1" />,
          <div key="2">
            <FormattedMessage id="Chat.Duration" />
            {formatSecondsToString((this.props.meta as CallEndedMeta).duration)}
          </div>
        ];
      default:
        return 'Unknown message type';
    }
  };

  private renderCurrentCallDuration = () => {
    if (this.props.callDuration !== undefined) {
      return (
        <div key="2">
          <FormattedMessage id="Chat.Duration" />
          {formatSecondsToString(this.props.callDuration)}
        </div>
      );
    }
    return null;
  };
}
