import {Map} from 'immutable';
import {type Inline, Value} from '@englex/slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {GapFillSize, GapFillType} from 'components/Slate/interface';

import FormattedTextRecord from '../FormattedText/FormattedTextRecord';
import {
  type Answer,
  type ChoicesMap,
  type DNDChoice,
  type DNDInputValue,
  WidgetType
} from '../../interface';
import {type GapFillJSON, type GapFillProperties} from './interface';

const initExampleChoices = (content: Value): ChoicesMap | undefined => {
  const examples = content.document.getInlines().filter(inline => inline?.data.get('example'));
  return !examples.size
    ? undefined
    : Map<string, DNDChoice>(
        examples.map((e: Inline) => [
          e.data.get('id'),
          {value: e.data.get('answer')[0], caseSensitive: e.data.get('caseSensitive')}
        ])
      );
};

class GapFillRecord
  extends FormattedTextRecord<
    Map<string, Answer[]> | undefined,
    Map<string, Answer> | Map<string, DNDInputValue> | undefined,
    ChoicesMap | undefined
  >
  implements GapFillProperties
{
  public declare readonly gap: GapFillType;
  public declare readonly gapSize?: GapFillSize;

  public declare readonly gapfillExamples?: ChoicesMap;
  public declare readonly preFillValues?: Map<string, string>;

  constructor(raw: GapFillJSON) {
    super(raw);
    const content = Value.fromJSON(raw.content);
    this.initValues({
      content,
      gap: raw.gap,
      gapSize: raw.gapSize,
      choices: raw.choices ? Map(raw.choices) : undefined,
      gapfillExamples: [GapFillType.DND, GapFillType.DND_INPUT].includes(raw.gap)
        ? initExampleChoices(content)
        : undefined,
      values: raw.values ? Map(raw.values) : undefined,
      preFillValues: raw.preFillValues ? Map(raw.preFillValues) : undefined,
      answers: raw.answers ? Map(raw.answers) : Map() // TODO: should be undefined by default
    });
  }

  public toJSON(): GapFillJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      content: this.content.toJSON(),
      gap: this.gap,
      gapSize: this.gapSize
    };
  }
  public get type() {
    return WidgetType.GAP_FILL;
  }

  public setAnswersFromJSON(json: {[id: string]: Answer}) {
    return this.set('answers', Map(json));
  }

  public createValues() {
    return this.set('values', Map());
  }

  public setValuesFromJSON(json?: {[id: string]: Answer}) {
    return json ? this.set('values', Map(json)) : this;
  }

  public get throttleSendValues(): number | undefined {
    return [GapFillType.INPUT, GapFillType.DND_INPUT].includes(this.gap) ? 500 : undefined;
  }

  public get isAutoChecked() {
    return true;
  }

  public get orphanIds(): string[] | undefined {
    if (this.gap !== GapFillType.DND) return undefined;
    if (!this.choices || !this.answers) return undefined;
    const choiceIdsWithAnswers = this.answers.valueSeq().map((a: [string]) => a[0]);
    const orphanIds = this.choices
      .keySeq()
      .filter((key: string) => !choiceIdsWithAnswers.includes(key))
      .toArray();
    return orphanIds.length ? orphanIds : undefined;
  }
}
decorate(GapFillRecord, {
  gap: property(GapFillType.INPUT),
  gapSize: property(GapFillSize.LARGE),
  gapfillExamples: property(),
  preFillValues: property()
});
record()(GapFillRecord);
export default GapFillRecord;
