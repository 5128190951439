import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import './warningToastr.scss';

export interface WarningToastStateProps {
  buttonText: string | JSX.Element;
}

export interface WarningToastDispatchProps {
  handleClick: () => void;
}

export interface WarningToastOwnProps {
  remove: () => void;
}

export class WarningToastrWithButton extends React.Component<
  WarningToastStateProps & WarningToastDispatchProps,
  {}
> {
  public render() {
    return (
      <div className="warning-toastr-with-button">
        <Button className="warning-toastr-btn" onClick={this.props.handleClick}>
          {this.props.buttonText}
        </Button>
      </div>
    );
  }
}
