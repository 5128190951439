import {Block, type Document, type Value} from '@englex/slate';

import {isBlockOfType} from '../../../../utils';
import {SlateBlock} from '../../../../interface';

export function blockIsQuestionLabel(value: Value, block: Block) {
  const parent = value.document.getParent(block.key);

  const parentBlockIsQuestion =
    Block.isBlock(parent) && isBlockOfType(parent, SlateBlock.QUESTION_ITEM);
  return parentBlockIsQuestion && isBlockOfType(block, SlateBlock.DEFAULT);
}

export function blockIsQuestionAnswer(value: Value, block: Block) {
  const parent = value.document.getParent(block.key);

  const parentBlockIsAnswer =
    Block.isBlock(parent) && isBlockOfType(parent, SlateBlock.QUESTION_ANSWER);
  return parentBlockIsAnswer && isBlockOfType(block, SlateBlock.DEFAULT);
}

export function getAncestorQuestionOfBlock(document: Document, block: Block) {
  const parent = document.getParent(block.key) as Block;
  if (parent.type === SlateBlock.QUESTION_ITEM) {
    return parent;
  }
  return document.getParent(parent.key) as Block;
}

export function getAllSelectedQuestions({document, blocks}: Value) {
  return blocks.reduce<Block[]>((reduction: Block[], el: Block) => {
    const questionOfCurrentEl = getAncestorQuestionOfBlock(document, el);
    if (!reduction.find(question => question.key === questionOfCurrentEl.key)) {
      reduction.push(questionOfCurrentEl);
    }
    return reduction;
  }, []);
}
