import {Iterable, Map, OrderedMap} from 'immutable';
import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import supplementaryExercisesModalReducer, {
  initialState as supplementaryExercisesModalInitialState
} from 'store/exercise/player/Exercise/supplementary/reducer';
import {type AxiosResponseAction} from 'services/axios/interface';
import {LOAD_HOMEWORK_EXERCISE_SUCCESS} from 'routes/ClassRoom/pages/HomeworkPlayerPage/actionTypes';

import {
  type ClearDraftCommentAction,
  type ClientHeightAction,
  type CommentAction,
  type ExtraExerciseAction,
  type LoadHomeworkExerciseSuccess,
  type TopPositionAction
} from './actions';
import {
  CLEAR_DRAFT_COMMENT,
  CLEAR_XPLAYER_EXERCISE,
  CREATE_DRAFT_COMMENT,
  EXTRA_EXERCISES_ADDED_REQUEST_SUCCESS,
  HIDE_SAVING_BADGE,
  INIT_PLAYER_TOP,
  REMOVE_EXTRA_EXERCISE_FROM_XPLAYER,
  REQUEST_UNIT_INSTANCE_PAGE_SUCCESS,
  SET_COMMENT_VALUE,
  SET_MODAL_EXERCISE,
  SHOW_SAVING_BADGE,
  STORE_CLIENT_HEIGHT,
  STORE_SCROLL_TOP
} from './actionTypes';
import ExerciseRecord from './Exercise/ExerciseRecord';
import {
  type ExerciseJSON,
  type SetModalExerciseAction,
  type WidgetInstanceSavedAction,
  type XPlayerState,
  type WidgetProperties
} from './interface';
import {
  REHYDRATE_USER_WIDGET_VALUES,
  REHYDRATE_WIDGET_VALUES,
  SEND_WIDGET_VALUES_SUCCESS,
  SET_USER_ID,
  WIDGET_VALUES_VERSION_UPDATED,
  WIDGET_VALUES_VERSION_UPDATED_EVENT
} from './persistence/actionTypes';
import {
  type RehydrateUserWidgetValuesAction,
  type RehydrateWidgetValuesAction,
  type SetUserIdAction,
  type WidgetValuesUpdatedAction
} from './persistence/interface';
import processingWidgetValuesReducer from './persistence/processingWidgetValuesReducer';
import widgetValuesReducer from './persistence/widgetValuesReducer';
import {type ExerciseProperties} from './Exercise/interface';
import {xplayerExercisesReducer} from './exercisesReducer';
import DraftCommentRecord from './DraftCommentRecord';
import {slateMigrateDown} from '../../../components/SlateJS/utils';

const widgetValuesUpdatedReducer = (
  state: XPlayerState,
  {exerciseId, widgetId, values, version}: WidgetValuesUpdatedAction
): XPlayerState => {
  const exercise = state.exercises.get(exerciseId);
  if (!exercise) {
    return state;
  }
  const widgetIndex = exercise.widgets.findIndex(
    (w: WidgetProperties) => w.id === String(widgetId)
  );
  if (widgetIndex === -1) {
    return state;
  }
  return {
    ...state,
    exercises: state.exercises.setIn(
      [exerciseId, 'widgets', widgetIndex],
      exercise.widgets.get(widgetIndex).setValuesFromJSON(values).set('version', version)
    )
  };
};
const REDUCERS: ReducersMapObject = {
  [INIT_PLAYER_TOP]: (state: XPlayerState, {top}: TopPositionAction): XPlayerState => ({
    ...state,
    layout: {
      ...state.layout,
      playerTop: top
    }
  }),
  [STORE_SCROLL_TOP]: (state: XPlayerState, {top}: TopPositionAction): XPlayerState => ({
    ...state,
    layout: {
      ...state.layout,
      scrollTop: top
    }
  }),
  [STORE_CLIENT_HEIGHT]: (
    state: XPlayerState,
    {clientHeight}: ClientHeightAction
  ): XPlayerState => ({...state, layout: {...state.layout, clientHeight}}),
  [REQUEST_UNIT_INSTANCE_PAGE_SUCCESS]: (
    state: XPlayerState,
    action: AxiosResponseAction<ExerciseJSON[]>
  ) => ({
    ...state,
    exercises: OrderedMap(
      action.payload.data.reduce((a: Array<[string | null, ExerciseRecord]>, e: ExerciseJSON) => {
        a.push([e.id, ExerciseRecord.fromJSON(e)]);
        if (e.additional) {
          e.additional.forEach(ae => {
            a.push([ae.id, ExerciseRecord.fromJSON(ae)]);
          });
        }
        return a;
      }, [])
    )
  }),
  [EXTRA_EXERCISES_ADDED_REQUEST_SUCCESS]: (
    state: XPlayerState,
    action: AxiosResponseAction<ExerciseJSON[]>
  ) => ({
    ...state,
    exercises: OrderedMap(
      action.payload.data.reduce(
        (a: Array<[string | null, ExerciseProperties]>, e: ExerciseJSON) => {
          const exercise = state.exercises.get(e.id!, ExerciseRecord.fromJSON(e));
          a.push([
            e.id,
            exercise.additionalAvailable !== e.additionalAvailable
              ? exercise.set('additionalAvailable', e.additionalAvailable)
              : exercise
          ]);
          if (e.additional) {
            e.additional.forEach(ae => {
              a.push([ae.id, state.exercises.get(ae.id!, ExerciseRecord.fromJSON(ae))]);
            });
          }
          return a;
        },
        []
      )
    )
  }),
  [LOAD_HOMEWORK_EXERCISE_SUCCESS]: (
    state: XPlayerState,
    {
      payload: {
        data: {exerciseInstance}
      }
    }: LoadHomeworkExerciseSuccess
  ): XPlayerState => ({
    ...state,
    exercises: OrderedMap([[exerciseInstance.id, new ExerciseRecord(exerciseInstance)]])
  }),
  [CLEAR_XPLAYER_EXERCISE]: (state: XPlayerState): XPlayerState => ({
    ...state,
    exercises: OrderedMap<string, ExerciseRecord>()
  }),
  [WIDGET_VALUES_VERSION_UPDATED_EVENT]: widgetValuesUpdatedReducer,
  [WIDGET_VALUES_VERSION_UPDATED]: widgetValuesUpdatedReducer,
  [SEND_WIDGET_VALUES_SUCCESS]: (
    state: XPlayerState,
    {
      wamp: {
        callResult: {
          args: [{version}],
          kwargs: {exerciseId, widgetId}
        }
      }
    }: WidgetInstanceSavedAction
  ): XPlayerState => {
    const exercise = state.exercises.get(exerciseId);
    if (!exercise) {
      return state;
    }
    const widgetIndex = exercise.widgets.findIndex(
      (w: WidgetProperties) => w.id === String(widgetId)
    );
    if (widgetIndex === -1) {
      return state;
    }
    return {
      ...state,
      exercises: state.exercises.setIn(
        [exerciseId, 'widgets', widgetIndex],
        exercise.widgets.get(widgetIndex).set('version', version)
      )
    };
  },
  [REHYDRATE_WIDGET_VALUES]: (
    state: XPlayerState,
    {exerciseId, widgetId, values, version}: RehydrateWidgetValuesAction
  ): XPlayerState => {
    const exercise = state.exercises.get(exerciseId);
    if (!exercise) {
      return state;
    }
    const widgetIndex = exercise.widgets.findIndex(
      (w: WidgetProperties) => w.id === String(widgetId)
    );
    if (widgetIndex === -1) {
      return state;
    }
    const widget = exercise.widgets.get(widgetIndex);
    if (
      (version === null && widget.version !== null) ||
      (version !== null && widget.version !== null && widget.version > version)
    ) {
      return state;
    }
    return {
      ...state,
      exercises: state.exercises.setIn(
        [exerciseId, 'widgets', widgetIndex],
        Iterable.isIterable(values)
          ? widget.set('values', values)
          : widget.setValuesFromJSON(values)
      )
    };
  },
  [SHOW_SAVING_BADGE]: (state: XPlayerState) => ({
    ...state,
    layout: {
      ...state.layout,
      showSavingBadge: true
    }
  }),
  [HIDE_SAVING_BADGE]: (state: XPlayerState) => {
    const {showSavingBadge, ...layout} = state.layout || {showSavingBadge: false};
    return {
      ...state,
      layout
    };
  },
  [SET_MODAL_EXERCISE]: (state: XPlayerState, {id}: SetModalExerciseAction): XPlayerState => ({
    ...state,
    modalExercise: id,
    supplementaryExercisesModal: id ? supplementaryExercisesModalInitialState : undefined
  }),
  [SET_USER_ID]: (state: XPlayerState, {userId}: SetUserIdAction): XPlayerState => ({
    ...state,
    sync: {
      ...state.sync,
      userId
    }
  }),
  [REHYDRATE_USER_WIDGET_VALUES]: (
    state: XPlayerState,
    {userId, widgetValues}: RehydrateUserWidgetValuesAction
  ): XPlayerState => {
    return {
      ...state,
      sync: {
        ...state.sync,
        userId,
        widgetValues: state.sync.widgetValues.merge(widgetValues),
        otherUserWidgetValues: state.sync.otherUserWidgetValues.delete(userId)
      }
    };
  },
  [REMOVE_EXTRA_EXERCISE_FROM_XPLAYER]: (
    state: XPlayerState,
    action: ExtraExerciseAction
  ): XPlayerState => ({
    ...state,
    exercises: state.exercises && state.exercises.delete(action.exerciseId)
  }),
  [SET_COMMENT_VALUE]: (
    state: XPlayerState,
    {exerciseId, draftCommentId, value}: CommentAction
  ): XPlayerState => {
    if (!state.draftComments) return state;
    const comment = state.draftComments.get(exerciseId);
    if (!comment) return state;
    return {
      ...state,
      draftComments: state.draftComments.set(
        exerciseId,
        comment.contentChange(value, draftCommentId)
      )
    };
  },
  [CREATE_DRAFT_COMMENT]: (
    state: XPlayerState,
    {exerciseId, draftCommentId, value}: CommentAction
  ): XPlayerState => {
    const draftComments = state.draftComments || Map();
    return {
      ...state,
      draftComments: draftComments.set(
        exerciseId,
        new DraftCommentRecord({
          id: draftCommentId,
          comment: slateMigrateDown(value),
          autoFocus: true
        })
      )
    };
  },
  [CLEAR_DRAFT_COMMENT]: (
    state: XPlayerState,
    {exerciseId}: ClearDraftCommentAction
  ): XPlayerState => {
    if (!state.draftComments) return state;
    const draftComments = state.draftComments.delete(exerciseId);
    if (!draftComments.size) {
      const {draftComments, ...rest} = state;
      return rest;
    }
    return {...state, draftComments};
  }
};

const initialState: XPlayerState = {
  exercises: OrderedMap<string, ExerciseRecord>(),
  sync: {
    widgetValues: Map(),
    processingWidgetValues: Map(),
    otherUserWidgetValues: Map()
  }
};

function childReducers(state: XPlayerState, action: Action) {
  const exercises = xplayerExercisesReducer(state.exercises, action);
  const widgetValues = widgetValuesReducer(state.sync.widgetValues, action);
  const processingWidgetValues = processingWidgetValuesReducer(
    state.sync.processingWidgetValues,
    action
  );
  const supplementaryExercisesModal = supplementaryExercisesModalReducer(
    state.supplementaryExercisesModal,
    action
  );
  return {exercises, widgetValues, processingWidgetValues, supplementaryExercisesModal};
}

function xplayerReducer(state: XPlayerState = initialState, action: Action): XPlayerState {
  const reducer: Reducer<XPlayerState> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;

  const {exercises, widgetValues, processingWidgetValues, supplementaryExercisesModal} =
    childReducers(state, action);

  if (
    exercises === state.exercises &&
    widgetValues === state.sync.widgetValues &&
    processingWidgetValues === state.sync.processingWidgetValues &&
    supplementaryExercisesModal === state.supplementaryExercisesModal
  ) {
    return state;
  }

  return {
    ...state,
    exercises,
    sync: {
      ...state.sync,
      widgetValues,
      processingWidgetValues
    },
    supplementaryExercisesModal
  };
}

export default xplayerReducer;
