/* eslint-disable @typescript-eslint/no-explicit-any */
import {type Iterable, type Map} from 'immutable';

export interface ImmutableRecord {
  new (): ImmutableRecord & BaseRecord;

  has(key: string): boolean;
  getIn(searchKeyPath: Array<any>, notSetValue?: any): any;
  getIn(searchKeyPath: Iterable<any, any>, notSetValue?: any): any;
  get(key: string, notSetValue?: any): any;
  set(key: string, value: any): this;
  delete(key: string): this;
  remove(key: string): this;
  clear(): this;
  update(updater: (value: any) => this): this;
  update(key: string, updater: (value: any) => any): this;
  update(key: string, notSetValue: any, updater: (value: any) => any): this;
  merge(...iterables: Array<Iterable<string, any>>): this;
  merge(...iterables: Array<{[key: string]: any}>): this;
  mergeWith(
    merger: (previous?: any, next?: any, key?: string) => any,
    ...iterables: Array<Iterable<string, any>>
  ): this;
  mergeWith(
    merger: (previous?: any, next?: any, key?: string) => any,
    ...iterables: Array<{[key: string]: any}>
  ): this;
  mergeDeep(...iterables: Array<Iterable<string, any>>): this;
  mergeDeep(...iterables: Array<{[key: string]: any}>): this;
  mergeDeepWith(
    merger: (previous?: any, next?: any, key?: string) => any,
    ...iterables: Array<Iterable<string, any>>
  ): this;
  mergeDeepWith(
    merger: (previous?: any, next?: any, key?: string) => any,
    ...iterables: Array<{[key: string]: any}>
  ): this;
  setIn(keyPath: Iterable<any, any> | any[], value: any): this;
  deleteIn(keyPath: Iterable<any, any> | any[]): this;
  removeIn(keyPath: Iterable<any, any> | any[]): this;
  updateIn(keyPath: Iterable<any, any> | any[], updater: (value: any) => any): this;
  updateIn(
    keyPath: Iterable<any, any> | any[],
    notSetValue: any,
    updater: (value: any) => any
  ): this;
  mergeIn(keyPath: Iterable<any, any> | any[], ...iterables: Array<Iterable<string, any>>): this;
  mergeIn(keyPath: any[], ...iterables: Array<{[key: string]: any}>): this;
  mergeDeepIn(
    keyPath: Iterable<any, any> | any[],
    ...iterables: Array<Iterable<string, any>>
  ): this;
  mergeDeepIn(keyPath: any[], ...iterables: Array<{[key: string]: any}>): this;
  withMutations(mutator: (mutable: this) => any): this;
  asMutable(): this;
  asImmutable(): this;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

export abstract class BaseRecord {
  public readonly size: number;

  private readonly _name: string | null;
  private readonly _keys: string[];
  private readonly _defaultValues: object;
  private _map: Map<string, unknown>;

  protected initValues(values: Record<string, unknown>): void {
    this._map = this._map.withMutations(map => {
      for (const prop in values) {
        if (values.hasOwnProperty(prop)) {
          if (!this['_keys'].some((x: unknown) => x === prop)) {
            throw new Error(`Value with name ${prop} is not part of this record.`);
          }
          map.set(prop, values[prop]);
        }
      }
    });
  }
}
