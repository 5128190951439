import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

import Icon from '../../Icon';

interface Props {
  show: boolean;
  connect: () => void;
  isMobile?: boolean;
}

class WampDisconnected extends React.Component<Props, {}> {
  public render() {
    if (this.props.isMobile && this.props.show) {
      return this.renderForMobile();
    }
    return this.renderForDesktop();
  }

  private renderForMobile = () => (
    <div className="wamp-disconnected-mobile">
      <div>
        <FormattedMessage id="WampDisconnected.ConnectionLost" />
        <Button className="btn-transparent" onClick={this.props.connect}>
          <FormattedMessage id="WampDisconnected.reconnect" />
        </Button>
      </div>
    </div>
  );

  private renderForDesktop = () => {
    return (
      <Modal
        backdrop="static"
        className="error-modal modal-center wamp-disconnected-desktop"
        show={this.props.show}
        onHide={() => null}
        backdropClassName="app-offline"
      >
        <Modal.Body>
          <Icon size="lg" name="refresh" className="ico text text-danger text-center" />

          <div className="title text text-danger text-center">
            <FormattedMessage id="WampDisconnected.title" />
          </div>

          <div className="description text text-center">
            <FormattedMessage id="WampDisconnected.description" />
          </div>

          <Button bsStyle="primary" bsSize="sm" className="connect" onClick={this.props.connect}>
            <FormattedMessage id="WampDisconnected.reconnect" />
          </Button>
        </Modal.Body>
      </Modal>
    );
  };
}
export default WampDisconnected;
