import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {ReactPaint, withPaint} from '@englex/paint-react';
import {KONVA_NODE_MIME_TYPE, PaintNode} from '@englex/paint';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';

export const PasteButton = withPaint(() => {
  const isEnabled = true; // TODO: implement query permissions for supported browsers

  const intl = useIntl();

  const onClick = useCallback(async () => {
    // clipboard API (incl. async) is in "Working Draft" state for now,
    // so unfortunately we can't read (clipboard.read()) custom mime-types.
    // As a fallback, we could readText and if text is a node data url,
    // then we could dispatch synthetic paste event
    // that will be handled by onPaste handler in usePaintClipboard hook
    try {
      const mayBeNodeDataUrl = await navigator.clipboard.readText();
      if (PaintNode.isDataUrl(mayBeNodeDataUrl)) {
        const pasteEvent = new ClipboardEvent('paste', {clipboardData: new DataTransfer()});
        pasteEvent.clipboardData?.items.add(mayBeNodeDataUrl, KONVA_NODE_MIME_TYPE);
        document.dispatchEvent(pasteEvent);
      }
    } catch (e) {
      // async clipboard API is restricted by permission,
      // so if user agent didn't allowed pasting from clipboard, we can't do it
      // eslint-disable-next-line no-console
      import.meta.env.MODE === 'development' && console.error(e);
    }
  }, []);

  return (
    <ToolbarButton
      onClick={onClick}
      disabled={!isEnabled}
      shortcut="mod+v"
      title={intl.formatMessage(buttonTitles.paste)}
    >
      <Icon name="paste" />
    </ToolbarButton>
  );
}, ReactPaint.isClipboardEnabled);
