import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';
const PATH: string = 'DOCUMENTS/';
export const EDIT_DOCUMENT: string = PATH + 'EDIT_DOCUMENT';
export const EDIT_DOCUMENT_SUCCESS: string = EDIT_DOCUMENT + AXIOS_SUCCESS_POSTFIX;
export const EDIT_DOCUMENT_ERROR: string = EDIT_DOCUMENT + AXIOS_ERROR_POSTFIX;
export const DELETE_DOCUMENT: string = PATH + 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS: string = DELETE_DOCUMENT + AXIOS_SUCCESS_POSTFIX;
export const OPEN_EDITOR: string = PATH + 'OPEN_EDITOR';
export const REQUEST_DOCUMENTS: string = PATH + 'REQUEST_DOCUMENTS';
export const REQUEST_DOCUMENTS_SUCCESS: string = REQUEST_DOCUMENTS + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_DOCUMENTS_FAIL: string = REQUEST_DOCUMENTS + AXIOS_ERROR_POSTFIX;
export const DOCUMENT_VIEWED: string = PATH + 'DOCUMENT_VIEWED';
export const DOCUMENT_VIEWED_SUCCESS: string = DOCUMENT_VIEWED + AXIOS_SUCCESS_POSTFIX;
export const DOCUMENTS_RESET: string = PATH + 'DOCUMENTS_RESET';
export const CLEAR_OPENED_TOGETHER: string = PATH + 'CLEAR_OPENED_TOGETHER';
export const PIN_DOCUMENT: string = PATH + 'PIN_DOCUMENT';
export const PIN_DOCUMENT_SUCCESS: string = PIN_DOCUMENT + AXIOS_SUCCESS_POSTFIX;
export const PIN_DOCUMENT_ERROR: string = PIN_DOCUMENT + AXIOS_ERROR_POSTFIX;
export const SET_DOCUMENT_DELETING: string = PATH + 'SET_DOCUMENT_DELETING';
export const EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT: string =
  PATH + 'EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT';
export const EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE: string =
  PATH + 'EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE';
export const CLOSE_EDIT_HOMEWORK_COMMENT_MODAL: string = PATH + 'CLOSE_EDIT_HOMEWORK_COMMENT_MODAL';
export const REQUEST_ENSURE_PDF_PAGE = PATH + 'REQUEST_ENSURE_PDF_PAGE';
export const SET_LAST_DOC_PAGE_NUMBER = PATH + 'SET_LAST_DOC_PAGE_NUMBER';
