import {useEffect} from 'react';

import {isMobileWebView} from 'helpers/browser';

const ignoredMessages = ['JSON Parse error:'];

export function useDebugWebview() {
  useEffect(() => {
    if (import.meta.env.MODE === 'development' && isMobileWebView()) {
      const windowAlert = window.alert;

      window.alert = function (message: string) {
        if (ignoredMessages.some(ignoredMessage => message.includes(ignoredMessage))) return;

        windowAlert.call(window, message);
      };
    }
  }, []);
}
