import React, {useCallback, useEffect} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import {
  clearXPlayerExercise,
  requestUnitInstancePage,
  toggleSupplementaryExercisesModal
} from 'store/exercise/player/actions';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';
import {type AppState, type SelectionData, type UserWithRole} from 'store/interface';
import {type ExerciseRecordMap} from 'store/exercise/player/interface';
import XPlayer from 'components/XPlayer';
import {SelectionProvider} from 'components/Pointer/selection/SelectionContext';
import {DictionaryContextProvider} from 'components/Dictionary/shared/contexts';
import useCanPlayTogether from 'components/media/_common/useCanPlayTogether';
import xplayerReducer from 'store/exercise/player/reducer';
import {useInjectReducer} from 'hooks/redux/useInjectReducer';
import {useApiRequestStateless} from 'hooks/rest/useApiRequestStateless';
import {useWampSubscription} from 'hooks/wamp/useWampSubscription';
import {uuid4RegExp} from 'config/static';

import {subscribeUnitPage} from '../UnitInstancePage/actions';
import {SavingBadge} from './SavingBadge';
import SupplementaryExercisesModal from './SupplementaryExercisesModal';

import './XPlayerPage.scss';

interface StateProps {
  exercises: ExerciseRecordMap | null;
  modalExercise?: string;
  scrollToExerciseId?: string;
}

type Props = OwnProps & StateProps;

interface OwnProps {
  unitInstanceId: number;
  pageNumber: number;
  showSupplementaryModalSwitcher?: boolean;
  selfCheckEnabled?: boolean;
}

const XPlayerPage: React.FC<Props> = props => {
  const {
    unitInstanceId,
    pageNumber,
    exercises,
    scrollToExerciseId,
    showSupplementaryModalSwitcher,
    selfCheckEnabled
  } = props;

  const isMounted = useIsMounted();
  const resourcesNode = useNode('.page-left');
  const isInjected = useInjectReducer({xplayer: xplayerReducer});
  const dispatch = useDispatch();
  const {studentTeacherId} = useParams<{studentTeacherId: string}>();

  useEffect(
    () => () => {
      dispatch(clearXPlayerExercise());
    },
    [dispatch, pageNumber]
  );

  const user = useSelector((state: AppState) => state.user as Required<UserWithRole>);

  const selectionData = useSelector<AppState, SelectionData | undefined>(
    state => state.classroom?.selection
  );

  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  const {
    error: subError,
    isSubscribing,
    retry
  } = useWampSubscription(subscribeUnitPage, unitInstanceId, pageNumber);

  const {isLoading, isError, reload} = useApiRequestStateless(
    false,
    requestUnitInstancePage,
    unitInstanceId,
    pageNumber
  );

  const closeModal = useCallback(() => dispatch(toggleSupplementaryExercisesModal()), [dispatch]);

  const noExercises = !exercises || exercises.size === 0;

  const shouldRenderLoader = isLoading || (!isMounted.current && isSubscribing) || noExercises;
  const shouldRenderErrorMask = isError || subError;

  if (!isInjected) {
    return null;
  }

  if (shouldRenderErrorMask) {
    return (
      <WampErrorMask
        reload={() => {
          if (subError) {
            retry();
          } else if (isError) {
            reload();
          }
        }}
      />
    );
  }

  if (shouldRenderLoader) {
    return <Loader />;
  }

  const isTeacher = user.role === 'teacher';
  const modalIsOpened = isTeacher && props.modalExercise !== undefined;

  const selectionEnabled =
    canOpenTogether || Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED);

  return (
    <div className="x-player-page">
      {modalIsOpened && (
        <SupplementaryExercisesModal
          close={closeModal}
          exerciseId={props.modalExercise!}
          showRoleSwitcher={showSupplementaryModalSwitcher}
        />
      )}
      <Resources id="x-player-page" node={resourcesNode}>
        <SelectionProvider role={user.role} active={selectionEnabled} selectionData={selectionData}>
          <DictionaryContextProvider isTeacherDictionary={isTeacher} dictionaryOwnerId={user.id}>
            <XPlayer
              exercises={exercises!}
              role={user.role}
              suppressToolbarPortal={modalIsOpened}
              scrollToExerciseId={scrollToExerciseId}
              page={pageNumber}
              hasPointer={true}
              selfCheckEnabled={selfCheckEnabled}
            />
          </DictionaryContextProvider>
        </SelectionProvider>
      </Resources>
      <SavingBadge />
    </div>
  );
};

const mapStateToProps = (state: AppState): StateProps => {
  const hash = state.router.location!.hash.substring(1);
  const hashExerciseId = hash.length && uuid4RegExp.test(hash) ? hash : undefined;
  return {
    exercises: state.xplayer ? state.xplayer.exercises : null,
    modalExercise: state.xplayer ? state.xplayer.modalExercise : undefined,
    scrollToExerciseId: hashExerciseId
  };
};

export default connect(mapStateToProps)(XPlayerPage);
