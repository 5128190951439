import {SUCCESS_POSTFIX} from 'services/wamp/actions/types';

const xplayerPersistancePrefix = '@@xplayer/';
export const SET_USER_ID = xplayerPersistancePrefix + 'SET_USER_ID';
export const REHYDRATE_USER_WIDGET_VALUES =
  xplayerPersistancePrefix + 'REHYDRATE_USER_WIDGET_VALUES';
export const PERSIST_WIDGET_VALUES = xplayerPersistancePrefix + 'PERSIST_WIDGET_VALUES';
export const REHYDRATE_WIDGET_VALUES = xplayerPersistancePrefix + 'REHYDRATE_WIDGET_VALUES';
export const PROCESS_WIDGET_VALUES = xplayerPersistancePrefix + 'PROCESS_WIDGET_VALUES';
export const WIDGET_VALUES_VERSION_UPDATED =
  xplayerPersistancePrefix + 'WIDGET_VALUES_VERSION_UPDATED';
export const WIDGET_VALUES_VERSION_UPDATED_EVENT =
  xplayerPersistancePrefix + 'WIDGET_VALUES_VERSION_UPDATED_EVENT';
export const EXERCISE_COMPLETED_EVENT = xplayerPersistancePrefix + 'EXERCISE_COMPLETED_EVENT';
export const EXERCISE_UNCOMPLETED_EVENT = xplayerPersistancePrefix + 'EXERCISE_UNCOMPLETED_EVENT';
export const EXERCISE_ALREADY_COMPLETED = xplayerPersistancePrefix + 'EXERCISE_ALREADY_COMPLETED';
export const DELETE_PROCESSING_WIDGET_VALUES =
  xplayerPersistancePrefix + 'DELETE_PROCESSING_WIDGET_VALUES';
export const PERSIST_WIDGET_VALUES_UNPROCESSED =
  xplayerPersistancePrefix + 'PERSIST_WIDGET_VALUES_UNPROCESSED';
export const SEND_WIDGET_VALUES = xplayerPersistancePrefix + 'SEND_WIDGET_VALUES';
export const SEND_WIDGET_VALUES_SUCCESS =
  xplayerPersistancePrefix + 'SEND_WIDGET_VALUES' + SUCCESS_POSTFIX;
