import React, {type FC} from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import {dashboardUrl} from '../../helpers/url';
import Icon from '../../components/Icon';
import EnglexDropdown from '../containers/EnglexDropdown';
import {
  Classroom,
  Dashboard,
  DictionaryLink,
  FeedbackLink,
  HomeworkLink,
  Intensive,
  Skills
} from './DropdownItemsLibrary';
import {useIsSampleLicenseStudent} from '../../hooks/user/useIsSampleLicenseStudent';

const getResponsiveTitle = (pathname: string, breakpoint: boolean) => {
  if (!breakpoint) return <FormattedMessage id="Common.Class" />;

  if (pathname.startsWith('/dictionary')) {
    return <FormattedMessage id="Vocabulary" />;
  }

  if (pathname.startsWith('/homework')) {
    return <FormattedMessage id="LessonPage.Homework.Short" />;
  }

  return <FormattedMessage id="Common.Class" />;
};

const Brand: FC<{
  deviceIsMobile?: boolean;
  toggle: () => void;
  close: () => void;
  dropdownOpen: boolean;
  isMethodist: boolean;
  isStudent: boolean;
  pathname: string;
}> = ({deviceIsMobile, isMethodist, isStudent, toggle, close, dropdownOpen, pathname}) => {
  const isSampleLicenseStudent = useIsSampleLicenseStudent();
  const matches = useMediaQuery(1200);
  const responsiveTitle = getResponsiveTitle(pathname, matches);

  const title = (
    <div style={{display: 'inline-block'}}>
      <Navbar.Brand />
      <div>
        {responsiveTitle}
        <Icon name="angle-down" />
      </div>
    </div>
  );

  const classes = classNames('main-logo', {mobile: deviceIsMobile});

  const href = dashboardUrl();

  return (
    <div className={classes}>
      <EnglexDropdown
        title={title}
        toggle={toggle}
        dropdownOpen={dropdownOpen}
        id="englex-dropdown"
      >
        <Dashboard />
        <Classroom
          active={
            pathname.startsWith('/room') ||
            (pathname.startsWith('/library') && !pathname.endsWith('/feedback'))
          }
          callback={close}
        />
        <DictionaryLink
          isMethodist={isMethodist}
          active={pathname.startsWith('/dictionary')}
          callback={close}
        />
        <FeedbackLink
          callback={close}
          isMethodist={isMethodist}
          active={pathname.startsWith('/library/feedback')}
        />
        <HomeworkLink isMethodist={isMethodist} pathname={pathname} callback={close} />
        {isStudent && !isSampleLicenseStudent && <Intensive pathname={pathname} callback={close} />}
        {isStudent && <Skills />}
      </EnglexDropdown>
      {href ? (
        <a href={href}>
          <Navbar.Brand />
        </a>
      ) : (
        <Navbar.Brand />
      )}
    </div>
  );
};

export default Brand;
