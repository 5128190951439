import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
import {persistReducer} from 'redux-persist';

import {type AppState, type ExerciseInstance} from 'store/interface';
import {clearXPlayerExercise} from 'store/exercise/player/actions';
import injectReducer from 'store/injectReducer';
import {persistXPlayerConfig} from 'store/persist';
import xplayerReducer from 'store/exercise/player/reducer';
import {type ExerciseRecordMap, type XPlayerState} from 'store/exercise/player/interface';

import {type HomeworkPlayerUrlParams} from '../HomeworkPage/interface';
import {useApiRequest} from '../../../../hooks/rest/useApiRequest';
import {loadHomeworkExercise} from './actions';
import Loader from '../../../../components/Loader';
import WampErrorMask from '../../../../components/WampErrorMask';
import HomeworkExerciseSubscription from './HomeworkExerciseSubscription';

interface StateProps {
  playerExercises: ExerciseRecordMap;
}

interface OwnProps {
  exerciseId: string;
}

type Props = OwnProps & StateProps;

const HomeworkExercise: React.FC<Props> = (props: Props) => {
  const {courseId, homeworkId} = useParams<HomeworkPlayerUrlParams>();
  const {exerciseId, playerExercises} = props;
  const dispatch = useDispatch();
  const action = useMemo(
    () => loadHomeworkExercise(courseId!, homeworkId!, exerciseId),
    [courseId, homeworkId, exerciseId]
  );
  const [unitInstanceId, setUnitInstanceId] = useState<number | undefined>();
  const [unitPage, setUnitPage] = useState<number | undefined>();
  const [selfCheckEnabled, setSelfCheckEnabled] = useState<boolean>(false);

  const successHandler = useCallback((resp: {exerciseInstance: ExerciseInstance}) => {
    setUnitPage(resp.exerciseInstance.pageNumber);
    setUnitInstanceId(resp.exerciseInstance.unitInstanceId);
    setSelfCheckEnabled(!!resp.exerciseInstance.unitInstance?.coursebookInstance?.selfCheckEnabled);
  }, []);
  useEffect(
    () => () => {
      dispatch(clearXPlayerExercise());
    },
    [dispatch, exerciseId]
  );
  const {isError, reload} = useApiRequest(action, successHandler);
  if (isError) {
    return <WampErrorMask reload={reload} />;
  }
  if (!playerExercises.size || unitInstanceId === undefined || unitPage === undefined) {
    return <Loader />;
  }
  return (
    <HomeworkExerciseSubscription
      playerExercises={playerExercises}
      unitInstanceId={unitInstanceId}
      unitPage={unitPage}
      selfCheckEnabled={selfCheckEnabled}
    />
  );
};

const mapStateToProps = (state: AppState): StateProps => ({
  playerExercises: state.xplayer!.exercises
});

export default injectReducer({
  xplayer: persistReducer<XPlayerState>(persistXPlayerConfig, xplayerReducer)
})(connect(mapStateToProps)(HomeworkExercise));
