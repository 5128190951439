const prefix = 'VIDEO/';

export const REGISTER_PLAYER = prefix + 'REGISTER_PLAYER';
export const UNREGISTER_PLAYER = prefix + 'UNREGISTER_PLAYER';
export const REQUEST_VIDEO_PROPS = prefix + 'REQUEST_VIDEO_PROPS';
export const ACTIVATE_PLAYER = prefix + 'ACTIVATE_PLAYER';
export const DEACTIVATE_PLAYER = prefix + 'DEACTIVATE_PLAYER';
export const CHANGE_VOLUME = prefix + 'CHANGE_VOLUME';
export const SET_DURATION = prefix + 'SET_DURATION';
export const CHANGE_PLAYBACK_RATE = prefix + 'CHANGE_PLAYBACK_RATE';
