import React, {type FC} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';
import ButtonGroup from 'react-bootstrap/lib/ButtonGroup';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';

import {type ContainerProps} from './interface';
import {usePager} from './usePager';

import './Pager.scss';

interface Props extends ContainerProps {
  batchListToggle(): void;
  hasNext: boolean;
  hasPrev: boolean;
  listIsOpened: boolean;
  loading?: boolean;
  toggleList(): void;
  disableButtons?: boolean;
}

export const Pager: FC<Props> = ({
  batchListToggle,
  currentPage,
  hasNext,
  hasPrev,
  listIsOpened,
  loading,
  selectPage,
  pageCount,
  toggleList,
  disableButtons
}) => {
  const intl = useIntl();

  const {pageNumber, handlerPrev, handlerNext} = usePager({
    init: currentPage ?? 1,
    min: 1,
    max: pageCount ?? Number.MAX_SAFE_INTEGER,
    onChange: page => {
      if (listIsOpened) toggleList();

      selectPage(page);
    }
  });

  if (typeof pageCount !== 'number') return null;

  return (
    <div className="x-player-pager">
      <div className="controls">
        <span
          className={classNames('list-trigger', {disabled: loading})}
          onClick={loading ? undefined : !listIsOpened ? toggleList : batchListToggle}
          title={
            listIsOpened
              ? intl.formatMessage({id: 'UnitContents.CloseContents'})
              : intl.formatMessage({id: 'UnitContents.OpenContents'})
          }
        >
          <Icon name={loading ? 'refresh' : listIsOpened ? 'angle-left' : 'list'} spin={loading} />
          <span className="count">
            {pageNumber}/{pageCount}
          </span>
        </span>

        <ButtonGroup className="incremental-triggers">
          <Button
            className="trigger"
            onClick={handlerPrev}
            disabled={disableButtons || !hasPrev}
            title={intl.formatMessage({id: 'UnitContents.PrevPage'})}
          >
            <Icon name="pc-left-arrow" />
          </Button>
          <Button
            className="trigger"
            onClick={handlerNext}
            disabled={disableButtons || !hasNext}
            title={intl.formatMessage({id: 'UnitContents.NextPage'})}
          >
            <Icon name="pc-right-arrow" />
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
};
