import {type Action, type ReducersMapObject} from 'redux';

import {type DictionaryState} from 'components/Dictionary/shared/interface';

import {
  CLEAR_ALL_ENTRIES,
  DELETE_LIST_FROM_STORE,
  REPLACE_LIST,
  UPDATE_ALL_ENTRIES,
  SET_STUDENT_LISTS,
  UNSHIFT_LIST,
  RESET_DICTIONARY,
  SET_DICTIONARY_OVERVIEW,
  SET_LIST_ENTRIES,
  SET_SEARCH,
  SET_STUDENT_ID,
  DELETE_FROM_LIST,
  DELETE_ENTRY_FROM_STORE,
  SET_IS_REVERSE,
  REVERSE_ENTRIES,
  ACTIVATE_DICTIONARY,
  UPDATE_LIST_MEMBER_OF,
  SORT_LIST,
  SET_IS_READONLY,
  DELETE_ENTRIES_FROM_STORE,
  DELETE_ENTRIES_FROM_LIST
} from './actionTypes';
import {
  type DeleteListFromStoreAction,
  type EditDictionaryListAction,
  type UpdateAllEntriesAction,
  type SetStudentListsAction,
  type SetDictionaryOverviewAction,
  type SetListEntriesAction,
  type SetSearchAction,
  type SetStudentIdAction,
  type DeleteEntryAction,
  type SetIsReverseAction,
  type UpdateListMemberOfAction,
  type SetListSortAction,
  type SetReadonlyAction,
  type DictionaryEnriesIds
} from './actions';

const initialState: DictionaryState = {
  status: 'active',
  entries: [],
  listSort: 'alphabetic',
  listEntries: [],
  isReverse: true
};

const actionHandlers: ReducersMapObject = {
  [UPDATE_ALL_ENTRIES]: (
    state: DictionaryState,
    {entries, page, isCallback}: UpdateAllEntriesAction
  ): DictionaryState => {
    const isReverse = state.studentId ? true : state.isReverse;
    if (isCallback) {
      const entryIds = entries.map(e => e.id);
      const oldEntries = state.entries.filter(e => !entryIds.includes(e.id));
      return {
        ...state,
        entries: isReverse ? oldEntries.concat(entries.reverse()) : entries.concat(oldEntries)
      };
    }
    if (page === 1) {
      const newEntries = isReverse ? [...entries].reverse() : entries;
      return {...state, entries: newEntries};
    }
    return {
      ...state,
      entries: isReverse ? entries.reverse().concat(state.entries) : state.entries.concat(entries)
    };
  },

  [REVERSE_ENTRIES]: (state: DictionaryState): DictionaryState => ({
    ...state,
    entries: state.entries.reverse()
  }),

  [DELETE_ENTRY_FROM_STORE]: (state: DictionaryState, {entryId}: DeleteEntryAction) => ({
    ...state,
    entries: state.entries.filter(e => e.id !== entryId)
  }),

  [DELETE_ENTRIES_FROM_STORE]: (state: DictionaryState, {entryIds}: DictionaryEnriesIds) => ({
    ...state,
    entries: state.entries.filter(item => !entryIds.includes(item.id))
  }),

  [CLEAR_ALL_ENTRIES]: (state: DictionaryState): DictionaryState => ({...state, entries: []}),

  [SET_LIST_ENTRIES]: (state: DictionaryState, {entries}: SetListEntriesAction) => ({
    ...state,
    listEntries: entries
  }),

  [SET_STUDENT_LISTS]: (
    state: DictionaryState,
    {lists}: SetStudentListsAction
  ): DictionaryState => ({...state, lists}),

  [SET_DICTIONARY_OVERVIEW]: (
    state: DictionaryState,
    {overview}: SetDictionaryOverviewAction
  ): DictionaryState => ({...state, overview}),

  [UNSHIFT_LIST]: (state: DictionaryState, {list}: EditDictionaryListAction): DictionaryState => ({
    ...state,
    lists: state.lists ? [list, ...state.lists] : [list]
  }),

  [REPLACE_LIST]: (state: DictionaryState, {list}: EditDictionaryListAction): DictionaryState => ({
    ...state,
    lists: state.lists?.map(l => (l.id === list.id ? {...l, ...list} : l))
  }),

  [DELETE_FROM_LIST]: (state: DictionaryState, {entryId}: DeleteEntryAction): DictionaryState => ({
    ...state,
    listEntries: state.listEntries.filter(le => le.id !== entryId)
  }),

  [DELETE_ENTRIES_FROM_LIST]: (
    state: DictionaryState,
    {entryIds}: DictionaryEnriesIds
  ): DictionaryState => ({
    ...state,
    listEntries: state.listEntries.filter(item => !entryIds.includes(item.id))
  }),

  [DELETE_LIST_FROM_STORE]: (
    state: DictionaryState,
    {listId}: DeleteListFromStoreAction
  ): DictionaryState => ({
    ...state,
    lists: (state.lists || []).filter(l => l.id !== listId)
  }),

  [SET_IS_REVERSE]: (state: DictionaryState, {isReverse}: SetIsReverseAction): DictionaryState => ({
    ...state,
    entries: [...state.entries.reverse()],
    isReverse
  }),

  [SORT_LIST]: (state: DictionaryState, {listSort}: SetListSortAction): DictionaryState => ({
    ...state,
    listSort
  }),

  [UPDATE_LIST_MEMBER_OF]: (
    state: DictionaryState,
    {entryIds, isDeleted}: UpdateListMemberOfAction
  ): DictionaryState => {
    const entries = [];
    for (const entry of state.entries) {
      if (entryIds.includes(entry.id)) {
        entries.push({
          ...entry,
          listsMember: isDeleted ? (entry.listsMember || 0) - 1 : (entry.listsMember || 0) + 1
        });
      } else {
        entries.push(entry);
      }
    }
    return {...state, entries};
  },

  [ACTIVATE_DICTIONARY]: (state: DictionaryState): DictionaryState => ({
    ...state,
    status: 'active'
  }),

  [RESET_DICTIONARY]: (state: DictionaryState) => ({
    ...initialState,
    status: 'idle',
    isReverse: state.isReverse,
    listSort: state.listSort
  }),

  [SET_IS_READONLY]: (
    state: DictionaryState,
    {isReadonly}: SetReadonlyAction
  ): DictionaryState => ({
    ...state,
    isReadonly
  }),

  // Sidebar dictionary specific state
  [SET_SEARCH]: (state: DictionaryState, {search}: SetSearchAction): DictionaryState => ({
    ...state,
    search
  }),

  [SET_STUDENT_ID]: (state: DictionaryState, {studentId}: SetStudentIdAction): DictionaryState => ({
    ...state,
    studentId
  })
};

export const reducer = (state: DictionaryState = initialState, action: Action) => {
  const handler = actionHandlers[action.type];
  if (handler) return handler(state, action);
  return state;
};
