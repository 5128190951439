import {Value, type ValueJSON} from '@englex/slate';

import {genKey, valueJSONFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type WordPictureSetCardJSON} from 'store/exercise/player/widgets/WordPictureSet/WordPictureSet/interface';

import XWordPictureSetBaseCardRecord from '../XWordPictureSetBaseCardRecord';
import {type XWordPictureSetCardProperties, type XWordPictureSetCardProps} from './interface';

class XWordPictureSetCardRecord
  extends XWordPictureSetBaseCardRecord
  implements XWordPictureSetCardProperties
{
  public declare readonly image: number;

  public declare readonly text: Value;

  constructor(raw: XWordPictureSetCardProps) {
    super(raw);

    const text: ValueJSON =
      raw.text ||
      valueJSONFromText('', [], {
        style: {
          textAlign: 'center',
          position: 'relative'
        }
      });

    this.initValues({
      image: raw.image || '',
      text: Value.fromJSON(text)
    });
  }

  public toJSON(): WordPictureSetCardJSON {
    return {
      ...super.toJSON(),
      image: this.image,
      text: this.text.toJSON()
    };
  }
}

decorate(XWordPictureSetCardRecord, {
  id: property(genKey()),
  image: property(''),
  text: property()
});
record()(XWordPictureSetCardRecord);
export default XWordPictureSetCardRecord;
