import {List, Map, OrderedMap} from 'immutable';

import {wordTemplateLiteral} from 'config/static';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetType} from '../../interface';
import {
  type ScrambledAnswers,
  type ScrambledChoices,
  type ScrambledExample,
  type ScrambledJSON,
  type ScrambledProperties,
  type ScrambledSentencesJSON
} from './interface';
import WidgetRecord from '../../WidgetRecord';
import {getFullSentence} from './helpers';

const initChoices = (scrambledSentences: ScrambledSentencesJSON): ScrambledChoices => {
  const containsExample = !!scrambledSentences[0].answers;

  return OrderedMap(
    (containsExample ? scrambledSentences.slice(1) : scrambledSentences).map(s => {
      return [
        s.id,
        OrderedMap(
          Array.isArray(s.choices)
            ? s.choices.map(({id, ...choice}) => [id, choice])
            : Object.keys(s.choices).map(k => [k, s.choices[k]])
        )
      ];
    })
  );
};

const initValues = (valuesJson: {[id: string]: string[]}): ScrambledAnswers =>
  Map(Object.keys(valuesJson).map(k => [k, List(valuesJson[k])]));

class ScrambledRecord
  extends WidgetRecord<ScrambledAnswers | undefined, ScrambledAnswers>
  implements ScrambledProperties
{
  public declare readonly sentences: ScrambledSentencesJSON;
  public declare readonly scrambledChoices: ScrambledChoices;

  constructor(raw: ScrambledJSON & {type?: WidgetType}) {
    super(raw);
    this.initValues({
      answers: raw.answers ? initValues(raw.answers) : undefined,
      sentences: raw.sentences,
      scrambledChoices: initChoices(raw.sentences),
      values: raw.values ? initValues(raw.values) : undefined
    });
  }

  public get containsExample(): boolean {
    return !!this.sentences[0].answers;
  }

  public get example(): ScrambledExample | undefined {
    const {answers, choices, template} = this.sentences[0];

    if (!this.containsExample || !answers) return undefined;

    const templateArray = template
      .split(new RegExp(`(${wordTemplateLiteral})`))
      .filter(token => token.length);

    const fullSentence = getFullSentence(templateArray, answers, choices);

    return {fullSentence} as ScrambledExample;
  }

  public get type() {
    return WidgetType.SCRAMBLED_SENTENCES;
  }

  public setAnswersFromJSON(values: {[id: string]: string[]}): this {
    return this.set('answers', initValues(values));
  }

  public createValues() {
    return this.set('values', Map());
  }

  public get isAutoChecked() {
    return true;
  }

  public setValuesFromJSON(values?: {[id: string]: string[]}): this {
    return values ? this.set('values', initValues(values)) : this;
  }

  public get isAvailableSelection() {
    return true;
  }
}
decorate(ScrambledRecord, {
  sentences: property(undefined),
  scrambledChoices: property(undefined)
});
record()(ScrambledRecord);
export default ScrambledRecord;
