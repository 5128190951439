import {type Action, type MiddlewareAPI} from 'redux';
import {defineMessages} from 'react-intl';

import {type AppState} from 'store/interface';

import {
  type MessageReceivedAction,
  type SelectRoomAction
} from '../../../../components/Chat/actions/interface';
import {SELECT_ROOM} from '../../../../components/Chat/actions/actionTypes';
import {type Dispatch, type WampAction} from '../../../wamp/actions/interface';

const messages = defineMessages({
  title: {
    id: 'Notification.IncomingMessageTitle'
  }
});

export default (
  api: MiddlewareAPI<Dispatch<Action, WampAction>, AppState>,
  action: MessageReceivedAction
) => {
  const messageText = action.message.text;

  if (!messageText) {
    return;
  }

  const appState = api.getState();
  const userId = appState.user.id;

  if (action.message.sender_id === userId) {
    return;
  }

  const sender =
    appState.chat && appState.chat.rooms && appState.chat.rooms[action.roomId].recipient
      ? appState.chat.rooms[action.roomId].recipient!.profile
      : null;
  const senderName = sender ? sender.first_name : null;

  const body = senderName ? `${senderName}: ${messageText}` : null;
  const icon = sender ? sender.avatars.lg['@2x'] : null;
  const title = appState.intl.formatMessage(messages.title);

  if (title && body && icon) {
    return {
      body,
      title,
      icon,
      hasId: false,
      showOnInactiveTabOnly: true,
      onClick() {
        window.focus();
        if (appState.chat && appState.chat.selectedRoomId !== action.roomId) {
          const roomId = action.roomId;
          api.dispatch({
            type: SELECT_ROOM,
            id: roomId
          } as SelectRoomAction);
        }
      }
    };
  }
  return;
};
