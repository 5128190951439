import React, {type FC, memo, useReducer} from 'react';

import {
  type Articles,
  type IncludedTranslations,
  type SearchData
} from 'components/Dictionary/Sidebar/DictionaryArticle/interfaces';
import {SearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {type TextArticlesStory} from 'components/Dictionary/Sidebar/DictionaryArticle/hooks/useTextArticlesStory';
import {type TextArticlesCache} from 'components/Dictionary/Sidebar/DictionaryArticle/hooks/useTextArticlesCash';
import {type DictionarySearchMode} from 'common/enums';

import {EditEntryContextProvider} from '../entry/EditEntryContext';
import {initialState, reducer} from './reducer';
import {useDictionaryContext} from '../index';
import {EditListController} from '../../CreateList/EditListController';
import {EditListActionsContext, EditListStateContext} from './EditListContext';

export const ContextProviders: FC<{
  isSearchingInOD: boolean;
  textArticle?: SearchData;
  textArticlesStory: TextArticlesStory;
  textArticlesCache: TextArticlesCache;
  scrambledArticles: Articles;
  isLoading: boolean;
  currentSearchWord: string;
  isScrambledWordLoading: boolean;
  currentScrambledWord?: string;
  includedTranslations: Record<string, string>;
  currentWord: string;
  searchMode: DictionarySearchMode;
  isSearching: boolean;
  searchFieldValue: string;
  searchOpen: boolean;
  setSearchFieldValue: (str: string) => void;
  openResultOfSearchingInOD: () => void;
  closeResultOfSearchingInOD: () => void;
  changeSearchValue: (text: string) => void;
  sendApiRequestEnToRus: () => void;
  sendApiRequestEn: () => void;
  sendScrambledWordApiRequest: (word: string) => void;
  sendCrossLinkApiRequestEnToRus: (id: string, word: string) => void;
  sendCrossLinkApiRequestEn: (id: string, word: string) => void;
  setIncludedTranslations: (translations: IncludedTranslations) => void;
  setCurrentSearchWord: (word: string) => void;
  setSearchMode: (mode: DictionarySearchMode) => void;
  setCurrentWord: (str: string) => void;
  setSearchOpen: (searchOpen: boolean) => void;
}> = memo(({children, ...searchInOdData}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  return (
    <EditEntryContextProvider>
      <EditListActionsContext.Provider value={dispatch}>
        <EditListStateContext.Provider value={state}>
          <SearchingInODContext.Provider value={searchInOdData}>
            {children}
          </SearchingInODContext.Provider>
          <EditListController
            dictionaryOwnerId={dictionaryOwnerId}
            dictionaryOwnerRole={dictionaryOwnerRole}
          />
        </EditListStateContext.Provider>
      </EditListActionsContext.Provider>
    </EditEntryContextProvider>
  );
});
