import {defineMessages} from 'react-intl';

export const titleMessages = defineMessages({
  AppTitle: {
    id: 'Head.AppTitle'
  },
  NewMessagesCountTitle: {
    id: 'Head.NewMessagesCount'
  }
});
