import React from 'react';

import {type HeaderDispatchProps, type HeaderStateProps} from '../containers/interface';
import Navbar from './Navbar';

import './header.scss';

interface Props extends HeaderStateProps, HeaderDispatchProps {}

export default class Header extends React.Component<Props> {
  public render() {
    const {collapsed, deviceIsMobile, notifications} = this.props;
    const className = `main-header ${collapsed ? 'collapsed' : ''} ${
      deviceIsMobile ? 'mobile' : ''
    }`;
    return (
      <header className={className}>
        <Navbar
          deviceIsMobile={deviceIsMobile}
          notifications={notifications}
          collapsed={collapsed}
          collapseLayout={this.collapseLayout}
        />
      </header>
    );
  }

  private collapseLayout = () => {
    const {collapsed, collapseLayout} = this.props;
    collapseLayout(!collapsed);
  };
}
