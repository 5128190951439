import React, {type FC, useEffect, useRef} from 'react';
import {type DropTargetMonitor, useDrop} from 'react-dnd';
import {FormattedMessage, type IntlShape} from 'react-intl';
import {NativeTypes} from 'react-dnd-html5-backend';

import {imageMaxResolution} from 'config/static';
import Icon from 'components/Icon';
import './ImageUpload.scss';

interface Props {
  dispatchFile(file: File): void;
  dispatchImageDataUrl(imageDataUrl: string): void;
  intl: IntlShape;
  minWidth: number;
  minHeight: number;
  maxWidth?: number;
  maxHeight?: number;
}

const ImageFileInput: FC<Props> = ({
  minWidth,
  minHeight,
  maxWidth = imageMaxResolution,
  maxHeight = imageMaxResolution,
  dispatchImageDataUrl,
  dispatchFile
}) => {
  const fileReader = useRef(new FileReader());

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    fileReader.current.onloadend = () => {
      const res = fileReader.current.result;
      if (res) {
        dispatchImageDataUrl(res as string);
      }
    };
  }, [dispatchImageDataUrl]);

  const [{isOver}, collectDropTarget] = useDrop({
    accept: NativeTypes.FILE,
    drop: ({files}: {files: File[]}) => {
      handleFileDrop(files);
    },
    collect: (monitor: DropTargetMonitor) => ({
      isOver: monitor.isOver()
    })
  });

  const handleFileDrop = (files: File[] | FileList) => {
    if (files && files[0]) {
      fileReader.current.readAsDataURL(files[0]);
      dispatchFile(files[0]);
    }
  };

  const handleFileInput = () => {
    const files = inputRef.current?.files;
    if (files && files[0]) {
      fileReader.current.readAsDataURL(files[0]);
      dispatchFile(files[0]);
    }
  };

  return collectDropTarget(
    <div className={`dnd-image-upload${isOver ? ' is-over' : ''}`}>
      <Icon name="virc-upload" />
      <input
        accept=".jpg,.jpeg,.png,.bmp"
        id="dnd-image-upload-file-input"
        multiple={false}
        onChange={handleFileInput}
        ref={inputRef}
        type="file"
      />
      <FormattedMessage id="Slate.Modal.Image.UploadImageTitle" />
      <FormattedMessage
        id="Slate.Modal.Image.UploadImageSizes"
        values={{minWidth, minHeight, maxWidth, maxHeight}}
      />
      <FormattedMessage id="Slate.Modal.Image.UploadImageMessage" />
      <label htmlFor="dnd-image-upload-file-input" />
    </div>
  );
};

export default ImageFileInput;
