import React, {Component} from 'react';
import Select, {
  type ActionMeta,
  type GroupBase,
  type SelectComponentsConfig,
  type Props as SelectProps
} from 'react-select';
import classNames from 'classnames';
import {type WrappedComponentProps, injectIntl, type MessageDescriptor} from 'react-intl';

import DropdownIndicator from './DropdownIndicator';
import ClearIndicator from './ClearIndicator';
import {reactSelectMessages} from './i18n';
import {type OptionsType, type OptionTypeBase, type ValueType} from './interface';

interface ReactSelectProps<O extends OptionTypeBase = {label: string; value: string}>
  extends Omit<SelectProps<O | O[], boolean, GroupBase<O>>, 'options' | 'onChange'> {
  // make options properties required
  options: /*GroupedOptionsType<O> | */ OptionsType<O>; // todo: grouped options temporary disabled
  onChange: (value: ValueType<O, boolean>, action: ActionMeta<O>) => void;
  // add custom props
  bsSize?: 'sm' | 'lg';
  isValueSelected?: (option: O, options: OptionsType<O>) => boolean;
  noOptionsMessageDescriptor?: MessageDescriptor;
  placeholderMessageDescriptor?: MessageDescriptor;
}

type Props<O extends OptionTypeBase> = ReactSelectProps<O> & WrappedComponentProps;

class ReactSelect<O extends OptionTypeBase = {label: string; value: string}> extends Component<
  Props<O>
> {
  public render() {
    const {
      intl,
      noOptionsMessageDescriptor,
      placeholderMessageDescriptor,
      bsSize,
      className,
      isValueSelected,
      ...selectProps
    } = this.props;
    const {options, components} = selectProps;

    const value = isValueSelected ? options.filter(o => isValueSelected(o, options)) : undefined;

    return (
      <Select
        isMulti={true}
        isClearable={true}
        menuPlacement="auto"
        value={value}
        noOptionsMessage={() =>
          intl.formatMessage(noOptionsMessageDescriptor || reactSelectMessages.noOptionsMessage)
        }
        placeholder={intl.formatMessage(
          placeholderMessageDescriptor || reactSelectMessages.placeholder
        )}
        {...selectProps}
        components={
          {DropdownIndicator, ClearIndicator, ...components} as SelectComponentsConfig<
            O | O[],
            boolean,
            GroupBase<O>
          >
        }
        onChange={this.onChange}
        className={classNames('react-select', className, bsSize, {
          'single-value': !this.props.isMulti
        })}
        classNamePrefix="rs"
      />
    );
  }

  private onChange = (selectedOptions: OptionsType<O> | null, actionMeta: ActionMeta<O>) => {
    const {isDisabled, onChange} = this.props;
    if (!isDisabled && onChange) {
      onChange(selectedOptions, actionMeta);
    }
  };
}

export default injectIntl(ReactSelect);
