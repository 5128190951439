import {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import {englexUrl} from 'helpers/url';

import styles from './Footer.module.scss';

export const Footer: FC = () => {
  return (
    <footer className={styles.footer}>
      <ul className={classNames(styles.links, 'list-inline')}>
        <li>
          <a
            href={englexUrl('/contract-englex/')}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            <FormattedMessage id="Layout.Login.Footer.PublicOffer" />
          </a>
        </li>
        <li>
          <a
            href={englexUrl('/agreement-englex/')}
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            <FormattedMessage id="Layout.Login.Footer.TermsOfUse" />
          </a>
        </li>
        <li>
          <a href={englexUrl('/privacy-englex/')} target="_blank" className="link" rel="noreferrer">
            <FormattedMessage id="Layout.Login.Footer.PrivacyPolicy" />
          </a>
        </li>
      </ul>
      <p className={styles.copyright}>
        © 2011–{new Date().getFullYear()}{' '}
        <a href={englexUrl('/')} target="_blank" className="link" rel="noreferrer">
          <FormattedMessage id="Layout.Login.Footer.EnglexLLC" />
        </a>
        , <FormattedMessage id="Layout.Login.Footer.OnlineSchoolOfEnglish" />
      </p>
    </footer>
  );
};
