import React, {type SyntheticEvent} from 'react';
import {type WrappedComponentProps, FormattedMessage} from 'react-intl';
import sparkMD5 from 'spark-md5';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import MenuItem from 'react-bootstrap/lib/MenuItem';

import Icon from '../../../../Icon';
import {chatMessages} from './chatMessages';

interface Props extends WrappedComponentProps {
  editMessage: (e?: SyntheticEvent<{}>) => void;
  deleteMessage: (e?: SyntheticEvent<{}>) => void;
  messageId: number;
  isDropup: boolean;
  isOpen: boolean;
  isMobile?: boolean;
  toggleDropdown: () => void;
  dropdownDropupCheck: (e: SyntheticEvent<{}>) => void;
  canBeEdited?: boolean;
}

interface State {
  value: string;
}

export default class EditDeleteDropdown extends React.PureComponent<Props, State> {
  public state: State = {value: ''};

  public componentDidUpdate() {
    switch (this.state.value) {
      case 'edit':
        this.props.editMessage();
        break;
      case 'delete':
        this.props.deleteMessage();
        break;
      default:
        break;
    }
    this.setState({value: ''});
  }

  public render() {
    const {intl, messageId, isDropup, isOpen, isMobile} = this.props;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const editMessageIcon: any = (
      <Icon
        name="ellipsis-h"
        className="edit-message-icon"
        title={intl.formatMessage(chatMessages.EditMessageDropdownMessage)}
      />
    );
    if (!isMobile) {
      return (
        <DropdownButton
          dropup={isDropup}
          className="edit-message-dropdown"
          id={sparkMD5.hash(String(messageId))}
          title={editMessageIcon}
          open={isOpen}
          onClick={this.props.dropdownDropupCheck}
          onToggle={this.props.toggleDropdown}
        >
          {this.renderEditMenuItem()}
          <MenuItem onClick={this.props.deleteMessage}>
            <FormattedMessage id="Message.Delete" />
          </MenuItem>
        </DropdownButton>
      );
    } else {
      return (
        <div className={`edit-message-mobile`}>
          {editMessageIcon}
          <select
            autoFocus={true}
            className="edit-message-dropdown-select"
            id="edit-message-dropdown-select"
            onChange={this.handleSelect}
            defaultValue=""
          >
            <option hidden={true} value="" disabled={true}>
              {intl.formatMessage(chatMessages.SelectAction)}
            </option>
            {this.renderEditOption()}
            <option value="delete">
              {intl.formatMessage(chatMessages.DeleteMessageDropdownMessage)}
            </option>
          </select>
        </div>
      );
    }
  }

  private renderEditMenuItem = () => {
    if (this.props.canBeEdited) {
      return (
        <MenuItem onClick={this.props.editMessage}>
          <FormattedMessage id="Message.Edit" />
        </MenuItem>
      );
    }
    return null;
  };

  private renderEditOption = () => {
    if (this.props.canBeEdited) {
      return (
        <option value="edit">
          {this.props.intl.formatMessage(chatMessages.EditMessageDropdownMessage)}
        </option>
      );
    }
    return null;
  };

  private handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.stopPropagation();
    this.props.toggleDropdown();
    const value = e.target.value;
    this.setState({value});
  };
}
