import React, {type FC} from 'react';
import classNames from 'classnames';
import {
  DrawLayer,
  Paintable,
  PainterNode,
  PortalRefProvider,
  type RenderNodeProp,
  ToolsPaint,
  usePaint
} from '@englex/paint-react';

import {PaintContainer} from './PaintContainer';
import {type ImagePaint, useImageDrop} from './plugins/image';

import './PaintEditor.scss';

interface Props {
  renderNode?: RenderNodeProp;
}

export const PaintEditor: FC<Props> = ({renderNode = PainterNode}) => {
  const paint = usePaint<ImagePaint>();
  const tool = ToolsPaint.getTool(paint);

  const [, drop] = useImageDrop(paint);

  return (
    <div className={classNames('paint-editor', `tool-${tool}`)} ref={drop}>
      <PaintContainer>
        <PortalRefProvider>
          <Paintable renderNode={renderNode}>
            <DrawLayer />
          </Paintable>
        </PortalRefProvider>
      </PaintContainer>
    </div>
  );
};
