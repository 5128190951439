import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

import {useCloseSidebarPanels} from '../hooks/useCloseSidebarDictionaryPanels';
import {useSearchingInODContext} from './DictionaryArticle/contexts/searchingInODContext';

export const SearchButton: FC<{open: boolean}> = ({open}) => {
  const intl = useIntl();
  const {closeSidebarPanels} = useCloseSidebarPanels();
  const {setSearchOpen} = useSearchingInODContext();

  const onClick = useCallback(() => {
    closeSidebarPanels();
    setSearchOpen(!open);
  }, [closeSidebarPanels, open, setSearchOpen]);

  return (
    <Button
      className={classNames('search-button')}
      bsStyle="success"
      onClick={onClick}
      title={intl.formatMessage({id: 'Dictionary.Search'})}
    >
      <Icon name="search" />
    </Button>
  );
};
