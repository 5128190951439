import {type FC, type PropsWithChildren, useLayoutEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useRole} from 'hooks/user/useRole';
import {type Role} from 'store/interface';

type Props = PropsWithChildren<{
  roles: Role | Role[];
  redirectTo?: string;
}>;

export const AuthRolesGuard: FC<Props> = ({roles, children, redirectTo = '/'}) => {
  const currentRole = useRole();
  const navigate = useNavigate();
  const allowedRoles = Array.isArray(roles) ? roles : [roles];

  const isAllowed = currentRole && allowedRoles.includes(currentRole);

  useLayoutEffect(() => {
    if (!isAllowed) {
      navigate(redirectTo, {replace: true});
    }
  }, [navigate, isAllowed, redirectTo]);

  if (!isAllowed) {
    return null;
  }

  return <>{children}</>;
};
