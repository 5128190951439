import React, {type FC} from 'react';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import {FormattedMessage} from 'react-intl';

import {type Role} from 'store/interface';
import './RoleSwitcher.scss';

interface Props {
  role: Role;
  changeRole: (role: Role) => void;
  disabled?: boolean;
}

const RoleSwitcher: FC<Props> = ({role, changeRole, disabled}) => (
  <div className="role-switcher">
    <ToggleButtonGroup
      type="radio"
      bsSize="sm"
      name="xpreviewChangeRole"
      value={role}
      onChange={changeRole}
    >
      <ToggleButton value="student" disabled={disabled}>
        <FormattedMessage id="Common.Role.Student" />
      </ToggleButton>
      <ToggleButton value="teacher" disabled={disabled}>
        <FormattedMessage id="Common.Role.Teacher" />
      </ToggleButton>
    </ToggleButtonGroup>
  </div>
);

export default React.memo(RoleSwitcher);
