import {type FC, memo, useCallback} from 'react';
import Tooltip from 'rc-tooltip';
import {FormattedMessage} from 'react-intl';

import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {useDictionaryDropdownItems} from 'components/Dictionary/hooks/useDictionaryDropdownItems';
import DropDown from 'components/DropDown';
import Icon from 'components/Icon';
import {type DictionarySearchMode} from 'common/enums';

import './SwitchDictionaryDropdown.scss';

interface Props {
  isTeacher: boolean;
  isTeacherDictionary: boolean;
}

export const SwitchDictionaryDropdown: FC<Props> = memo(({isTeacher, isTeacherDictionary}) => {
  const {searchMode, setSearchMode} = useSearchingInODContext();

  const items = useDictionaryDropdownItems({isTeacher, isTeacherDictionary});

  const onChangeDropdown = useCallback(
    (mode: DictionarySearchMode) => {
      if (mode === searchMode) return;

      setSearchMode(mode);
    },
    [searchMode, setSearchMode]
  );

  return (
    <Tooltip
      overlayClassName="online-dictionary-temporarily-unavailable"
      overlay={<FormattedMessage id="Dictionary.Search.Online.TemporarilyUnavailable" />}
      placement="topLeft"
    >
      <span>
        <DropDown
          activeKey={searchMode}
          className="rc-dropdown switch-dictionary-dropdown"
          items={items}
          onChange={onChangeDropdown}
          value={
            <span className="content">
              <span className="text">{items[searchMode].value}</span>
              <Icon name="angle-down" className="arrow" />
            </span>
          }
          noCaret={true}
          placement="bottomRight"
          trigger={['click']}
          disabled={true}
        />
      </span>
    </Tooltip>
  );
});
