import {type IntlShape} from 'react-intl';
import * as yup from 'yup';
import {List, Map, type Set} from 'immutable';
import {type Dispatch} from 'redux';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../player/interface';
import {type VocabularyJSON} from '../../../player/widgets/Vocabulary/interface';
import XWidgetRecord from '../XWidgetRecord';
import VocabularyWordRecord from '../../../player/widgets/Vocabulary/VocabularyWordRecord';
import VocabularyCategoryRecord from '../../../player/widgets/Vocabulary/VocabularyCategoryRecord';
import validationMessages from '../i18n';
import {stripSpaces} from './actions';
import {
  isVocabularyWordJSON,
  isVocabularyWordRecord
} from '../../../player/widgets/Vocabulary/utils';
import {taskExcerpt} from '../excerpt';
import {
  type XVocabularyProperties,
  type VocabularyWordProperties,
  type VocabularyCategoryProperties,
  type XPronunciationModalProperties
} from './interface';
import {ValidationWidgetError} from '../customErrors';
import {documentNotEmpty} from '../validation';
import {getObjectWithNewIds} from '../getObjectWithNewIds';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

class XVocabularyRecord extends XWidgetRecord<VocabularyJSON> implements XVocabularyProperties {
  public static wordMaxLength = 255;
  public static categoryMaxLength = 255;

  public declare readonly vocabulary: List<VocabularyWordProperties | VocabularyCategoryProperties>;

  public declare readonly pronunciationModal: null | XPronunciationModalProperties;

  public declare readonly quizletURL: string;

  public declare readonly listTitle?: string;

  constructor(raw: VocabularyJSON) {
    super(raw);
    this.initValues({
      vocabulary: List(
        raw.vocabulary.map(entry => {
          if (isVocabularyWordJSON(entry)) {
            return new VocabularyWordRecord(entry);
          }
          return new VocabularyCategoryRecord(entry);
        })
      ),
      quizletURL: raw.quizletURL || '',
      listTitle: raw.listTitle || ''
    });
  }

  public toJSON(options?: WidgetToJSONOptions): VocabularyJSON {
    return {
      ...super.toJSON(options),
      vocabulary: this.getVocabulary(options),
      quizletURL: this.quizletURL === '' ? undefined : this.quizletURL,
      listTitle: this.listTitle?.trim() || undefined
    };
  }

  private getVocabulary(options?: WidgetToJSONOptions) {
    const vocabulary = this.vocabulary
      .map((entry: VocabularyCategoryRecord | VocabularyWordRecord) => entry.toJSON())
      .toJS();

    if (options?.generateIdentifiers) return getObjectWithNewIds(vocabulary, 'wordId');

    return vocabulary;
  }

  public get type() {
    return WidgetType.VOCABULARY;
  }

  public schema(intl: IntlShape): yup.AnyObjectSchema {
    return yup.object({
      vocabulary: yup
        .mixed()
        .test(
          'Has at least one word',
          intl.formatMessage(validationMessages.ShouldContainAtLeastOneWord),
          (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) =>
            !!vocabulary.find(entry => !!entry && isVocabularyWordJSON(entry))
        )
        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const wordsWithCyrillic = vocabulary.filter(entry =>
              Boolean(entry && isVocabularyWordJSON(entry) && entry.original.match(/[А-я]/g))
            );

            if (wordsWithCyrillic.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.WordsNotContainCyrillic),
                this,
                wordsWithCyrillic.reduce(
                  (props, word: VocabularyWordRecord) => ({
                    ...props,
                    [word.wordId]: {withCyrillic: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const emptyWords = vocabulary.filter(entry =>
              Boolean(
                entry && isVocabularyWordJSON(entry) && (!entry.original || !entry.translation)
              )
            );

            if (emptyWords.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.WordsAndTranslationNonEmpty),
                this,
                emptyWords.reduce(
                  (props, word: VocabularyWordRecord) => ({
                    ...props,
                    [word.wordId]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const emptyCategories = vocabulary.filter(entry =>
              Boolean(entry && !isVocabularyWordJSON(entry) && !entry.name)
            );

            if (emptyCategories.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.CategoryNameNonEmpty),
                this,
                emptyCategories.reduce(
                  (props, word: VocabularyCategoryRecord) => ({
                    ...props,
                    [word.categoryId]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })

        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const categoriesWithoutWords = vocabulary.filter((entry, i: number) =>
              Boolean(entry && vocabulary.size - 1 === i && !isVocabularyWordRecord(entry))
            );

            if (categoriesWithoutWords.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.CategoryContainsWords),
                this,
                categoriesWithoutWords.reduce(
                  (props, word: VocabularyCategoryRecord) => ({
                    ...props,
                    [word.categoryId]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })

        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const words = vocabulary.filter((entry, i: number) =>
              Boolean(
                entry &&
                  isVocabularyWordJSON(entry) &&
                  (entry.translation.length > XVocabularyRecord.wordMaxLength ||
                    entry.original.length > XVocabularyRecord.wordMaxLength)
              )
            );

            if (words.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.WordsAndTranslationsNotTooLong, {
                  maxCharCount: XVocabularyRecord.wordMaxLength
                }),
                this,
                words.reduce(
                  (props, word: VocabularyWordRecord) => ({
                    ...props,
                    [word.wordId]: {maxLength: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })

        .test({
          test: (vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>) => {
            const categories = vocabulary.filter((entry, i: number) =>
              Boolean(
                entry &&
                  !isVocabularyWordJSON(entry) &&
                  entry.name.length > XVocabularyRecord.categoryMaxLength
              )
            );

            if (categories.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.CategoriesNotTooLong, {
                  maxCharCount: XVocabularyRecord.categoryMaxLength
                }),
                this,
                categories.reduce(
                  (props, word: VocabularyCategoryRecord) => ({
                    ...props,
                    [word.categoryId]: {maxLength: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        }),
      listTitle: yup
        .string()
        .test(
          'List title not too long',
          intl.formatMessage(validationMessages.ListTitleTooLong),
          (listTitle?: string) => {
            return (listTitle?.trim().length || 0) <= 255;
          }
        ),
      quizletURL: yup
        .string()
        .test(
          'Valid Quizlet URL',
          intl.formatMessage(validationMessages.ValidQuizletURL),
          (url: string) => {
            if (url === '') {
              return true;
            }
            return !!url.match(/^https:\/\/quizlet\.com\/.+/);
          }
        ),

      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }

  public onBeforeValidation(dispatch: Dispatch) {
    dispatch(stripSpaces(this.id));
    return;
  }

  public get excerpt(): string {
    const task = taskExcerpt(this.task);
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const vocabularyExcerpt = this.vocabulary
      .filter((v: VocabularyWordRecord) => !!v.original)
      .map((v: VocabularyWordRecord) => `${v.original} - ${v.translation}`)
      .join('; ');
    const excerpt = `${task} ${vocabularyExcerpt}`;
    return excerpt.length > exerciseExcerptLength
      ? excerpt.trim().substring(0, exerciseExcerptLength)
      : excerpt;
  }

  public get media(): MediaMeta | undefined {
    const pronunciations = this.vocabulary
      .map((v: VocabularyWordRecord) => v.soundId)
      .filter(id => !!id)
      .toSet() as Set<number>;
    return pronunciations.size
      ? (Map({[WidgetMediaType.PRONUNCIATIONS]: pronunciations}) as MediaMeta)
      : undefined;
  }
}

decorate(XVocabularyRecord, {
  vocabulary: property(List()),
  pronunciationModal: property(null),
  quizletURL: property(),
  listTitle: property()
});
record()(XVocabularyRecord);
export default XVocabularyRecord;
