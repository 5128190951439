import classNames from 'classnames';
import React, {useState, memo} from 'react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import {DictionarySearchMode} from 'common/enums';

import {type EnEntry, type IEntry, type LexicalEntryType, type Sense} from '../../interfaces';
import {useSearchingInODContext} from '../../contexts/searchingInODContext';
import {Entry} from '../Entry/Entry';
import {EnglishEntries} from '../EnglishEntries/EnglishEntries';
import {getLexicalCategoryText} from '../../utils/helpers';

import './LexicalEntry.scss';

type Callback = (entryId: string) => void;

const createGrammaticalFeaturesText = (lexicalEntry: LexicalEntryType): string => {
  const entries = lexicalEntry.entries;
  if (entries && entries[0].grammaticalFeatures) {
    const grammaticalFeatureIds = entries[0].grammaticalFeatures;
    return grammaticalFeatureIds.map(item => item.id).join(', ');
  }

  return '';
};

interface Props {
  lexicalEntry: LexicalEntryType;
  includedTranslations: Record<string, string>;
  isDefaultCollapse?: boolean;
  dictionaryIsReadonly: boolean;
  isSidebar: boolean;
  addWord: (translation: string, callback?: Callback) => void;
  deleteWord: (translation: string) => void;
}

export const LexicalEntry: React.FC<Props> = memo(
  ({
    isSidebar,
    lexicalEntry,
    includedTranslations,
    isDefaultCollapse = false,
    dictionaryIsReadonly,
    addWord,
    deleteWord
  }) => {
    const {formatMessage} = useIntl();
    const [isCollapsed, setIsisDefaultCollapsed] = useState(isDefaultCollapse);

    const {searchMode} = useSearchingInODContext();

    const partOfSpeech = {
      noun: formatMessage({id: 'Dictionary.Noun'}),
      adjective: formatMessage({id: 'Dictionary.Adjective'}),
      verb: formatMessage({id: 'Dictionary.Verb'}),
      adverb: formatMessage({id: 'Dictionary.Adverb'}),
      article: formatMessage({id: 'Dictionary.Article'}),
      pronoun: formatMessage({id: 'Dictionary.Pronoun'}),
      numeral: formatMessage({id: 'Dictionary.Numeral'}),
      conjunction: formatMessage({id: 'Dictionary.Conjunction'}),
      preposition: formatMessage({id: 'Dictionary.Preposition'}),
      interjection: formatMessage({id: 'Dictionary.Interjection'}),
      particle: formatMessage({id: 'Dictionary.Particle'}),
      prefix: formatMessage({id: 'Dictionary.Prefix'}),
      determiner: formatMessage({id: 'Dictionary.Determiner'}),
      idiomatic: formatMessage({id: 'Dictionary.Idiomatic'}),
      suffix: formatMessage({id: 'Dictionary.Suffix'}),
      other: formatMessage({id: 'Dictionary.Other'})
    };

    const switchIsCollapsed = () => {
      setIsisDefaultCollapsed(isCollapsed => !isCollapsed);
    };

    const lexicalCategory = lexicalEntry?.lexicalCategory?.text;
    const grammaticalFeaturesText = createGrammaticalFeaturesText(lexicalEntry);

    const lexicalCategoryText = getLexicalCategoryText(partOfSpeech, searchMode, lexicalCategory);

    const isLexicalEntryEmpty =
      lexicalEntry?.entries?.length === 0 || searchMode === DictionarySearchMode.EN_TO_RUS
        ? lexicalEntry?.entries?.every((entry: IEntry) => !entry?.translations?.length)
        : lexicalEntry?.entries?.every((entry: EnEntry) => !entry?.senses?.length) ||
          lexicalEntry?.entries?.every((entry: EnEntry) =>
            entry?.senses?.every((sense: Sense) => !sense.definitions?.length)
          );

    if (isLexicalEntryEmpty) return null;

    return (
      <div className={classNames('lexical-entry', {collapsed: isCollapsed})}>
        <div
          className={classNames('lexical-entry__header', {
            'english-dictionary': searchMode === DictionarySearchMode.EN
          })}
          onClick={switchIsCollapsed}
        >
          <div className="lexical-entry__title">
            <span className="lexical-entry__lexical-category">{lexicalCategoryText}</span>
            {isCollapsed ? <Icon name="angle-down" /> : <Icon name="angle-up" />}
          </div>
          {grammaticalFeaturesText && (
            <div className="lexical-entry__grammatical-features">{grammaticalFeaturesText}</div>
          )}
        </div>
        <div className="lexical-entry__entries">
          {searchMode === DictionarySearchMode.EN_TO_RUS ? (
            <Entry
              lexicalEntry={lexicalEntry}
              includedTranslations={includedTranslations}
              dictionaryIsReadonly={dictionaryIsReadonly}
              addWord={addWord}
              deleteWord={deleteWord}
            />
          ) : (
            <EnglishEntries
              isSidebar={isSidebar}
              lexicalEntry={lexicalEntry}
              includedTranslations={includedTranslations}
              dictionaryIsReadonly={dictionaryIsReadonly}
              addWord={addWord}
              deleteWord={deleteWord}
            />
          )}
        </div>
      </div>
    );
  }
);
