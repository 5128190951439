import React from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';

import {ServiceMessageTypes, TextMessageTypes} from 'common/enums';
import {messageUpdateIsAllowedTime} from 'config/static';
import {
  type CallEndedMeta,
  type ChatMessage,
  type MessageMeta,
  type ServiceMessageMeta
} from 'store/interface';
import {toLocalDate} from 'services/common-methods/toLocalDate';

import {type MessagesProps} from './interface';
import Message, {type MessageType} from './Message/TextMessage';
import MessageWithCallTimer from './Message/MessageWithCallTimer';
import NoMessages from '../../NoMessages';
import {ClientMessageTypes} from '../../actions/interface';
import ServiceMessage from './Message/ServiceMessage';
import {enumContainsValue} from '../../../../helpers/enum';

export default class Messages extends React.PureComponent<MessagesProps, {}> {
  private static shouldRenderDivider(
    index: number,
    allMessages: ChatMessage[],
    userTimezone: string
  ): boolean {
    return (
      index === 0 ||
      toLocalDate(new Date(allMessages[index - 1].created_at), userTimezone).toDateString() !==
        toLocalDate(new Date(allMessages[index].created_at), userTimezone).toDateString()
    );
  }

  private static renderDivider(date: Date) {
    let dateElement: JSX.Element;
    if (date.toDateString() === new Date().toDateString()) {
      dateElement = <FormattedMessage id="Common.Today" />;
    } else {
      dateElement = <FormattedDate value={date} day="numeric" month="long" year="numeric" />;
    }
    return (
      <div className="message divider">
        <div className="divider-date">{dateElement}</div>
        <hr />
      </div>
    );
  }

  public render() {
    const {allMessagesLoaded, messages} = this.props;
    if (allMessagesLoaded && messages.length === 0) {
      return <NoMessages />;
    }
    return messages.map(this.renderMessage);
  }

  private renderMessage = (message: ChatMessage, index: number, allMessages: ChatMessage[]) => {
    const type: MessageType | 'unknown' = this.checkMessageType(message);
    if (enumContainsValue(TextMessageTypes, type)) {
      return this.renderTextMessage(message, type as TextMessageTypes, index, allMessages);
    }
    if (
      enumContainsValue(ServiceMessageTypes, type) ||
      enumContainsValue(ClientMessageTypes, type)
    ) {
      return this.renderServiceMessage(
        message,
        type as ServiceMessageTypes | ClientMessageTypes,
        index,
        allMessages
      );
    }
    return null;
  };

  private renderServiceMessage = (
    message: ChatMessage,
    type: ServiceMessageTypes | ClientMessageTypes,
    index: number,
    allMessages: ChatMessage[]
  ) => {
    const {currentCallId, partnerName, isMobile, userTimezone} = this.props;
    const isCallInProgressMessage =
      type === ClientMessageTypes.incomingCall || type === ClientMessageTypes.outgoingCall;
    const shouldRenderDuration: boolean =
      isCallInProgressMessage &&
      !!currentCallId &&
      (message.meta as CallEndedMeta).callId === currentCallId;
    const MessageComponent = shouldRenderDuration ? MessageWithCallTimer : ServiceMessage;
    const isLastMessage = index === allMessages.length - 1;
    return (
      <React.Fragment key={message.id}>
        {Messages.shouldRenderDivider(index, allMessages, userTimezone) &&
          Messages.renderDivider(toLocalDate(new Date(message.created_at), userTimezone))}
        <MessageComponent
          {...message}
          getRef={this.props.getRef}
          partnerName={partnerName}
          userTimezone={userTimezone}
          isMobile={isMobile}
          type={type}
          isLastMessage={isLastMessage}
        />
      </React.Fragment>
    );
  };

  private renderTextMessage = (
    message: ChatMessage,
    type: TextMessageTypes,
    index: number,
    allMessages: ChatMessage[]
  ) => {
    const {
      partnerName,
      userTimezone,
      promoteMessageToUpdate,
      selectedRoomId,
      deleteMessage,
      messageToUpdateId,
      isMobile
    } = this.props;
    const messageIsNotOldEnough: boolean =
      new Date().getTime() - new Date(message.created_at).getTime() < messageUpdateIsAllowedTime;
    const updatingThisMessage = message.id === messageToUpdateId;
    const renderUpdateDeleteDropdown =
      (!message.deleted_at && message.own && messageIsNotOldEnough && !messageToUpdateId) || false;
    const isLastMessage = index === allMessages.length - 1;
    return (
      <React.Fragment key={message.id}>
        {Messages.shouldRenderDivider(index, allMessages, userTimezone) &&
          Messages.renderDivider(toLocalDate(new Date(message.created_at), userTimezone))}
        <Message
          {...message}
          getRef={this.props.getRef}
          type={type}
          partnerName={partnerName}
          promoteMessageToUpdate={promoteMessageToUpdate}
          selectedRoomId={selectedRoomId}
          deleteMessage={deleteMessage}
          updatingThisMessage={updatingThisMessage}
          renderUpdateDeleteDropdown={renderUpdateDeleteDropdown}
          userTimezone={userTimezone}
          isMobile={isMobile}
          imageLoadingFailed={this.props.imageLoadingFailed}
          isLastMessage={isLastMessage}
        />
      </React.Fragment>
    );
  };

  private checkMessageType = (message: ChatMessage) => {
    switch ((message.meta as MessageMeta).type) {
      case ServiceMessageTypes.missed:
        if ((message.meta as ServiceMessageMeta).callerId === this.props.userId) {
          return ClientMessageTypes.noAnswer;
        }
        return ServiceMessageTypes.missed;
      case ServiceMessageTypes.busy:
        if ((message.meta as ServiceMessageMeta).callerId === this.props.userId) {
          return ServiceMessageTypes.busy;
        }
        return ClientMessageTypes.declined;
      case ServiceMessageTypes.answer:
        if ((message.meta as ServiceMessageMeta).callerId === this.props.userId) {
          return ClientMessageTypes.outgoingCall;
        }
        return ClientMessageTypes.incomingCall;
      case ServiceMessageTypes.endCall:
      case TextMessageTypes.mediaSingleLink:
      case TextMessageTypes.textWithAdditionalBubbles:
      case TextMessageTypes.text:
      case TextMessageTypes.image:
        return (message.meta as MessageMeta).type;
      default:
        return 'unknown';
    }
  };
}
