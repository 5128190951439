import {type Paint} from '@englex/paint';

import {type PaintImage} from './interface';

export const withImage = <P extends Paint>(paint: P): P & PaintImage => {
  const paintWithImage = paint as P & PaintImage;

  paintWithImage.showImageModal = false;

  paintWithImage.setShowImageModal = nextShow => {
    if (paintWithImage.showImageModal !== nextShow) {
      paintWithImage.showImageModal = nextShow;
      paintWithImage.flush();
    }
  };

  paintWithImage.setImageDropInitials = afterDropInitials => {
    paintWithImage.afterDropInitials = afterDropInitials;
    if (afterDropInitials) {
      paintWithImage.showImageModal = true;
    }
    paintWithImage.flush();
  };

  return paintWithImage;
};
