import {type Action, applyMiddleware, createStore, type ReducersMapObject, type Store} from 'redux';
import {persistStore} from 'redux-persist';
import type React from 'react';

import {type Dispatch} from 'services/wamp/actions/interface';
import {parseToken} from 'config/jwt';

import {PERSIST_PREFIX, PersistState} from './persist';
import {makeRootReducer} from './reducers';
import {type AppState} from './interface';
import middleware from './middleware';
import enhancers from './enhancers';
import {changeLocale} from '../i18n/actions/actions';
import composeWithDevTools from './devTools';

export type ReduxContext = React.ContextType<React.Context<ReduxAsyncStoreContextValue>>;

interface ReduxAsyncStoreContextValue<S = AppState> {
  store: AsyncStore<S>;
  storeState: S;
}
export interface AsyncStore<S> extends Store<S> {
  asyncReducers?: ReducersMapObject;
  injectReducers?: (reducers: ReducersMapObject) => void;
  dispatch: Dispatch<Action, unknown>;
}

function getInitialState(): Partial<AppState> {
  if (window.webViewData) {
    const {token} = window.webViewData;
    const parsedToken = parseToken(token);

    const key = PERSIST_PREFIX + PersistState.user;
    const currentUser = localStorage.getItem(key);

    if (currentUser) {
      const user = JSON.parse(currentUser);

      if (user.token !== token) {
        localStorage.removeItem(key);
      }
    }

    return {
      user: {
        token,
        id: parsedToken.authid,
        role: parsedToken.authroles[0]
      }
    };
  }

  return {};
}

export function createStoreAsync(
  initialState: Partial<AppState> = getInitialState()
): Promise<Store<AppState>> {
  return new Promise(resolve => {
    const store: AsyncStore<Partial<AppState>> = createStore(
      makeRootReducer(),
      initialState,
      composeWithDevTools(applyMiddleware(...middleware), ...enhancers)
    );
    store.asyncReducers = {};

    store.injectReducers = (asyncReducers: ReducersMapObject) => {
      Object.keys(asyncReducers).forEach(key => {
        store.asyncReducers![key] = asyncReducers[key];
      });
      store.replaceReducer(makeRootReducer(store.asyncReducers));
      persistStore(store);
    };

    if (import.meta.hot) {
      import.meta.hot.accept('./reducers', () => {
        store.replaceReducer(makeRootReducer(store.asyncReducers));
        persistStore(store);
      });
    }

    persistStore(store, {}, async () => {
      await store.dispatch(changeLocale(store.getState().intl!.locale));
      resolve(store as Store<AppState>);
    });
  });
}
