import {type Reducer, type ReducersMapObject} from 'redux';
import {type List} from 'immutable';

import widgetReducer from './widgetReducer';
import {type WidgetAction, type WidgetProperties} from '../interface';

const REDUCERS: ReducersMapObject = {};

function widgetsReducer(
  state: List<WidgetProperties>,
  action: WidgetAction
): List<WidgetProperties> {
  const reducer: Reducer<List<WidgetProperties>> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;

  return widgetReducer(state, action);
}

export default widgetsReducer;
