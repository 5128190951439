import {List, Map} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetType} from '../../interface';
import WidgetRecord from '../../WidgetRecord';
import {
  type ImageMatchingCardJSON,
  type ImageMatchingJSON,
  type ImageMatchingProperties
} from './interface';

class ImageMatchingRecord
  extends WidgetRecord<Map<string, string[]> | undefined, Map<string, string> | undefined>
  implements ImageMatchingProperties
{
  public declare readonly cards: List<ImageMatchingCardJSON>;
  public declare readonly preFillValues: Map<string, string>;

  constructor(raw: ImageMatchingJSON) {
    super(raw);
    this.initValues({
      cards: List(raw.cards),
      choices: Map(raw.choices),
      answers: raw.answers ? Map(raw.answers) : Map(),
      values: raw.values ? Map(raw.values) : undefined,
      preFillValues: raw.preFillValues ? Map(raw.preFillValues) : undefined
    });
  }

  public get type() {
    return WidgetType.IMAGE_MATCHING;
  }

  public setAnswersFromJSON(values: {[key: string]: string}): this {
    return this.set('answers', Map(values));
  }

  public createValues() {
    return this.set('values', Map());
  }

  public get isAutoChecked() {
    return true;
  }

  public setValuesFromJSON(values?: {[key: string]: string}): this {
    return values ? this.set('values', Map(values)) : this;
  }

  public get isAvailableSelection() {
    return true;
  }
}
decorate(ImageMatchingRecord, {
  cards: property(List<ImageMatchingCardJSON>()),
  preFillValues: property(undefined)
});
record()(ImageMatchingRecord);
export default ImageMatchingRecord;
