import {
  type VocabularyCategoryJSON,
  type VocabularyWithGroupedWords,
  type VocabularyWordJSON
} from './interface';
import {
  type VocabularyWordProperties,
  type VocabularyCategoryProperties
} from '../../../editor/widgets/XVocabulary/interface';
import type VocabularyWordRecord from './VocabularyWordRecord';

export function isVocabularyWordJSON(
  item: VocabularyWordJSON | VocabularyCategoryJSON
): item is VocabularyWordJSON {
  return !!(item as VocabularyWordJSON).wordId;
}

export function isVocabularyWordRecord(
  item: VocabularyWordProperties | VocabularyCategoryProperties
): item is VocabularyWordProperties {
  return !!(item as VocabularyWordProperties).wordId;
}

export const mapEntryIdsToCategories = (
  vocabularyGroupedWords: VocabularyWithGroupedWords
): {[categoryId: string]: string[]} => {
  const map: {[categoryId: string]: string[]} = {};
  let lastCategoryId: string | undefined;
  vocabularyGroupedWords.forEach(vgw => {
    if ((vgw as VocabularyCategoryProperties).categoryId) {
      map[(vgw as VocabularyCategoryProperties).categoryId] = [];
      lastCategoryId = (vgw as VocabularyCategoryProperties).categoryId;
    } else if ((vgw as VocabularyWordRecord[]).length && lastCategoryId) {
      map[lastCategoryId] = (vgw as VocabularyWordRecord[])
        .filter(v => !v.dictionaryEntryInstance)
        .map(v => v.wordId);
    }
  });
  return map;
};
