import {type ValueJSON} from '@englex/slate';
import {Map} from 'immutable';
import {type Descendant} from 'slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {valueJSONFromText} from 'components/Slate/utils';
import {slateMigrateDown, slateMigrateUp} from 'components/SlateJS/utils';

import WidgetRecord from '../../WidgetRecord';
import {WidgetType} from '../../interface';
import {type EssayJSON, type EssayProperties} from './interface';

const WEAK_VALUES = new WeakMap<ValueJSON, Descendant[]>();

class EssayRecord
  extends WidgetRecord<undefined, Map<'onReview' | 'value', unknown>>
  implements EssayProperties
{
  public declare readonly shouldCountWords: boolean | undefined;

  constructor(raw: EssayJSON & {type?: WidgetType}) {
    super(raw);
    this.initValues({
      shouldCountWords: raw.options && raw.options.countWords,
      values: Map({
        onReview: !!(raw.values && raw.values.onReview) || false,
        value: raw.values?.value ? raw.values.value : valueJSONFromText('')
      })
    });
  }

  public toJSON(): EssayJSON {
    const value = this.values.get('value') as ValueJSON | undefined;
    const onReview = this.values.get('onReview', undefined);
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      skipSync: this.skipSync,
      values: value ? {onReview: !!onReview || undefined, value} : undefined
    };
  }

  public get type() {
    return WidgetType.ESSAY;
  }

  public get onReview(): boolean {
    return this.values.get('onReview', false) as boolean;
  }

  public get value(): Descendant[] {
    const val = this.values.get('value') as ValueJSON;
    if (!WEAK_VALUES.has(val)) {
      WEAK_VALUES.set(val, slateMigrateUp(val));
    }
    return WEAK_VALUES.get(val)!;
  }

  public get throttleSendValues(): number | undefined {
    return 500;
  }

  public get isAvailableSelection() {
    return true;
  }

  public setValuesFromJSON(values?: {value: ValueJSON; onReview?: boolean}): this {
    return values
      ? this.withMutations(v => {
          v.setIn(['values', 'value'], values.value);
          v.setIn(['values', 'onReview'], !!values.onReview);
        })
      : this;
  }

  public contentChange(value: Descendant[]): this {
    const val = slateMigrateDown(value);
    WEAK_VALUES.set(val, value);
    return this.setIn(['values', 'value'], val);
  }
}
decorate(EssayRecord, {
  shouldCountWords: property(false)
});
record()(EssayRecord);
export default EssayRecord;
