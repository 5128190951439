import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {type WrappedComponentProps} from 'react-intl';

import Icon from 'components/Icon';

import {getMuteCamBtnTitle, RTCMessages} from './RTCmessages';

interface Props extends WrappedComponentProps {
  camId?: string;
  localAudioEnabled?: boolean;
  localVideoEnabled?: boolean;
  hangUp: () => void;
  toggleAudioState: () => void;
  toggleVideoState: () => void;
  selectCurrentChatRoom: () => void;
  renderSwitchChatRoomBtn: boolean;
  speedtestGloballyDisabled?: boolean;
  shouldDisableVideoBtn: boolean;
}

export default class CallControlButtons extends React.PureComponent<Props, {}> {
  public render() {
    const formatMessage = this.props.intl.formatMessage;
    const {
      camId,
      localVideoEnabled,
      localAudioEnabled,
      shouldDisableVideoBtn,
      hangUp,
      toggleAudioState,
      toggleVideoState
    } = this.props;
    const muteCameraClassname = this.props.localVideoEnabled ? 'turn-video-off' : 'turn-video-on';
    const muteMicClassname = this.props.localAudioEnabled ? 'turn-mic-off' : 'turn-mic-on';
    return (
      <div className="bottom-buttons">
        {this.renderSpeedtest()}
        <div className="call-control-buttons">
          <Button
            className={`btn-circle mute-button ${muteCameraClassname}`}
            bsSize="sm"
            onClick={toggleVideoState}
            title={formatMessage(getMuteCamBtnTitle(camId!, Boolean(localVideoEnabled)))}
            disabled={shouldDisableVideoBtn}
          >
            <Icon
              name={this.props.localVideoEnabled ? 'video-camera' : 'virc-video_slash'}
              className={muteCameraClassname}
            />
          </Button>
          <Button
            className={`btn-circle mute-button ${muteMicClassname}`}
            bsSize="sm"
            onClick={toggleAudioState}
            title={formatMessage(
              localAudioEnabled ? RTCMessages.DisableMic : RTCMessages.EnableMic
            )}
          >
            <Icon
              name={localAudioEnabled ? 'microphone' : 'microphone-slash'}
              className={muteMicClassname}
            />
          </Button>
          {this.renderSwitchChatRoomBtn()}
          <Button
            bsStyle="danger"
            className="btn-circle hang-up-button hangup-answered-call"
            title={this.props.intl.formatMessage(RTCMessages.PartnerHangUp)}
            onClick={hangUp}
          >
            <Icon name="phone" size="lg" className="hangup-answered-call" />
          </Button>
        </div>
      </div>
    );
  }

  private renderSpeedtest = () => {
    if (!this.props.speedtestGloballyDisabled) {
      // return <Speedtest />;
      // TODO: speedtest disabled in CRA 2 vite migration
      return null;
    }
    return null;
  };

  private renderSwitchChatRoomBtn = () => {
    const {renderSwitchChatRoomBtn, selectCurrentChatRoom} = this.props;
    if (renderSwitchChatRoomBtn) {
      return (
        <Button
          className="btn-circle change-chat-room"
          bsSize="sm"
          onClick={selectCurrentChatRoom}
          title={this.props.intl.formatMessage(RTCMessages.OpenChatWithPartner)}
        >
          <Icon name="comments" />
        </Button>
      );
    }
    return null;
  };
}
