import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import {DictionarySearchMode} from 'common/enums';

import {useSearchingInODContext} from '../../contexts/searchingInODContext';
import {type CrossReference} from '../../interfaces';
import {CrossLink} from '../CrossLink/CrossLink';

import './CrossLinks.scss';

interface Props {
  crossReferences: CrossReference[];
}

export const CrossLinks: React.FC<Props> = memo(({crossReferences}) => {
  const {formatMessage} = useIntl();
  const {sendCrossLinkApiRequestEnToRus, searchMode, sendCrossLinkApiRequestEn} =
    useSearchingInODContext();

  return (
    <div className="cross-links">
      <div className="cross-links__title">{formatMessage({id: 'Dictionary.SeeAlso'})}</div>
      <div className="cross-links__links">
        {crossReferences.map(crossReference => (
          <CrossLink
            key={crossReference.id}
            {...crossReference}
            onLinkClick={
              searchMode === DictionarySearchMode.EN_TO_RUS
                ? sendCrossLinkApiRequestEnToRus
                : sendCrossLinkApiRequestEn
            }
          />
        ))}
      </div>
    </div>
  );
});
