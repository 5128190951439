import React, {type FC, type PropsWithChildren} from 'react';
import {
  type BackendDeclaration,
  type Backends,
  DndProvider,
  MouseTransition,
  TouchTransition
} from 'react-dnd-multi-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import {HTML5Backend} from 'react-dnd-html5-backend';

import {isMobileWebView, isTouchMobileDevice} from '../helpers/browser';
import {isTouchEmulatorEnabled} from '../components/TouchEmulator';

const html5 = {
  backend: HTML5Backend,
  transition: MouseTransition
} as BackendDeclaration;

const touch = {
  backend: TouchBackend,
  options: {enableMouseEvents: true},
  preview: true,
  transition: TouchTransition
} as BackendDeclaration;

// try fixing duplicate events in Slate toolbar:
// dnd multi-backend does not work well on touch devices (even on iOS) and leads to duplicate events be fired,
// so disabling html5 backend for mobile web view for now
const isTouchOnly = isMobileWebView() || isTouchMobileDevice;
const isHtmlOnly = isTouchEmulatorEnabled();

const HTML5toTouch: Backends = {
  backends: isTouchOnly ? [touch] : isHtmlOnly ? [html5] : [html5, touch]
};

export const DNDProvider: FC<PropsWithChildren<{}>> = ({children}) => {
  return <DndProvider options={HTML5toTouch}>{children}</DndProvider>;
};
