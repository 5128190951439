import React from 'react';

interface Props {
  shouldRender: boolean;
}

export default class UnreadCircle extends React.PureComponent<Props, {}> {
  public render() {
    if (!this.props.shouldRender) {
      return null;
    }
    return <div className="unread-message-circle" />;
  }
}
