import {type Action, type Dispatch} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type AppState} from 'store/interface';

import {
  CHANGE_ACTIVE,
  CHANGE_PLAY_STATUS,
  CHANGE_PLAYBACK_RATE,
  CHANGE_TIMESTAMP,
  CHANGE_VOLUME,
  CLEAR_OPENED_TOGETHER,
  DELETE_SOUND,
  EDIT_LENGTH,
  EDIT_SOUND,
  OPEN_EDITOR,
  REQUEST_SOUNDS,
  SET_SOUND_DELETING,
  SET_UNIQUE_PLAYBACK_ID,
  SOUND_VIEWED,
  SOUNDS_RESET,
  TOGGLE_VOLUME_POPOVER
} from './actionTypes';
import {
  type ChangePlaybackRateAction,
  type ChangePlayStatusAction,
  type ChangePlayStatusActionCreator,
  type ChangeTimestampActionCreator,
  type ChangeVolumeActionCreator,
  type LengthEditCreator,
  MULTI_PLAY,
  PLAYING,
  type PlayMode,
  type PlayStatus,
  type SetUniquePlaybackIdAction
} from './interface';
import {
  type ChangeFileAction,
  type FileAction,
  type LoadFileAction,
  type OpenEditorAction,
  type SetIsDeleteRequestCreator
} from '../../../../actions/interface';
import {type ToggleElementCreator} from '../../../../../../common/interface';

export const changeActiveSound = (id: number | null): ChangeFileAction => ({
  type: CHANGE_ACTIVE,
  id
});

export const lengthEdit: LengthEditCreator = (id: number, length: number) => ({
  type: EDIT_LENGTH,
  id,
  length
});

export const soundOpenEditor = (id: number | null): OpenEditorAction => ({
  type: OPEN_EDITOR,
  id
});

export const changePlayStatus: ChangePlayStatusActionCreator = (
  playStatus: PlayStatus,
  playMode: PlayMode
) => {
  let startedAt: number = undefined!;
  switch (playStatus) {
    case PLAYING:
      startedAt = new Date().getTime();
      break;
    default:
      break;
  }
  const resObject: ChangePlayStatusAction = {
    type: CHANGE_PLAY_STATUS,
    playStatus,
    startedAt
  };
  if (playMode) {
    resObject.playMode = playMode;
  }
  return resObject;
};

export const setUniquePlaybackId = (id: string): SetUniquePlaybackIdAction => ({
  type: SET_UNIQUE_PLAYBACK_ID,
  id
});

export const changeTimestamp: ChangeTimestampActionCreator = (timestamp: number) => {
  return {
    type: CHANGE_TIMESTAMP,
    startedAt: new Date().getTime(),
    timestamp
  };
};

export const soundEdit = (id: number, title: string): AxiosRequestAction => ({
  type: EDIT_SOUND,
  payload: {
    request: {
      url: `/v1/classroom/audio-instance/${id}`,
      method: 'put',
      data: {
        title
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }
});

export const soundDelete = (id: number): AxiosRequestAction => ({
  type: DELETE_SOUND,
  payload: {
    request: {
      url: `/v1/classroom/audio-instance/${id}`,
      method: 'delete'
    }
  }
});

export const loadSounds = (courseId: number): LoadFileAction => ({
  type: REQUEST_SOUNDS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/classroom/course-instance/${courseId}/audio`,
      params: {
        expand: 'views'
      }
    }
  }
});

const soundViewed = (id: number): AxiosRequestAction => ({
  type: SOUND_VIEWED,
  payload: {
    request: {
      method: 'post',
      url: `/v1/classroom/audio-instance/${id}/read`
    }
  }
});

export const resetSounds: () => FileAction = () => ({
  type: SOUNDS_RESET
});

export const clearOpenedTogetherSound: () => FileAction = () => ({
  type: CLEAR_OPENED_TOGETHER
});

export const changePlaybackRate = (playbackRate: number): ChangePlaybackRateAction => ({
  type: CHANGE_PLAYBACK_RATE,
  playbackRate
});

export const changeVolume: ChangeVolumeActionCreator = (volume: number) => ({
  type: CHANGE_VOLUME,
  volume
});

export const toggleVolumePopover: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_VOLUME_POPOVER,
  show
});

export const setSoundDeleting: SetIsDeleteRequestCreator = (
  fileId: string,
  componentId: string,
  isBeingDeleted: boolean
) => ({
  type: SET_SOUND_DELETING,
  fileId,
  componentId,
  isBeingDeleted
});

export const playSound =
  (uniquePlaybackId: string, multiPlay?: boolean) =>
  (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const state = getState();
    const fileId = Number(uniquePlaybackId.replace(/[^\d]/g, ''));
    dispatch(changeActiveSound(fileId));
    dispatch(setUniquePlaybackId(uniquePlaybackId));
    dispatch(changePlayStatus(PLAYING, multiPlay && MULTI_PLAY));
    if (state.sounds!.sounds && state.sounds!.sounds[fileId].isNew) {
      dispatch(soundViewed(fileId));
    }
  };
