import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {type UnitPreviewState} from 'store/interface';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';
import xpreviewReducer from 'store/exercise/player/preview/previewReducer';
import PreviewRecord from 'store/exercise/player/preview/PreviewRecord';

import {
  type InitUnitPreviewAction,
  type UnitPreviewSetMainIdAction,
  type InitUnitPreviewAdditionalAction,
  type UnitPreviewSetGrammarPreviewOpenAction
} from './actions';
import {
  INIT_UNIT_PREVIEW,
  CLEAN_UNIT_PREVIEW,
  SET_MAIN_EXERCISE_ID,
  INIT_UNIT_PREVIEW_ADDITIONAL,
  CLEAR_MAIN_EXERCISE_ID,
  SET_GRAMMAR_PREVIEW_OPEN,
  CLEAR_GRAMMAR_PREVIEW_OPEN
} from './actionTypes';

const REDUCERS: ReducersMapObject = {
  [INIT_UNIT_PREVIEW]: (
    state: UnitPreviewState,
    {exercises, coursebookId, unitId, addExerciseNumber, pageNumber}: InitUnitPreviewAction
  ): UnitPreviewState => {
    const config = {
      role: state?.xpreview?.role,
      addExerciseNumber,
      pageNumber
    };

    return {
      ...state,
      coursebookId,
      unitId,
      xpreview: new PreviewRecord(exercises, config)
    };
  },
  [INIT_UNIT_PREVIEW_ADDITIONAL]: (
    state: UnitPreviewState,
    {exercise}: InitUnitPreviewAdditionalAction
  ): UnitPreviewState => {
    const preview = state && (state.extraPreview || state.xpreview);
    const role = preview ? preview.role : undefined;
    return {
      ...state,
      extraPreview: new PreviewRecord(ExerciseRecord.fromJSON(exercise), {role})
    };
  },
  [SET_MAIN_EXERCISE_ID]: (state, {mainId}: UnitPreviewSetMainIdAction): UnitPreviewState => ({
    ...state,
    mainId
  }),
  [CLEAR_MAIN_EXERCISE_ID]: (state: UnitPreviewState): UnitPreviewState => {
    const {mainId, extraPreview, ...rest} = state!;
    const role = extraPreview ? extraPreview.role : undefined;
    return {
      ...rest,
      xpreview: role && rest.xpreview ? rest.xpreview.set('role', role) : rest.xpreview
    };
  },
  [CLEAN_UNIT_PREVIEW]: (): UnitPreviewState => null,
  [SET_GRAMMAR_PREVIEW_OPEN]: (
    state: UnitPreviewState,
    {exerciseId}: UnitPreviewSetGrammarPreviewOpenAction
  ): UnitPreviewState => {
    return {...state, grammarPreview: exerciseId};
  },
  [CLEAR_GRAMMAR_PREVIEW_OPEN]: (state: UnitPreviewState): UnitPreviewState => {
    return {...state, grammarPreview: undefined};
  }
};

const initialState = null;

function unitPreviewReducer(
  state: UnitPreviewState = initialState,
  action: Action
): UnitPreviewState {
  const reducer: Reducer<UnitPreviewState> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;

  return state
    ? {
        ...state,
        xpreview: !state.mainId ? xpreviewReducer(state.xpreview, action) : state.xpreview,
        extraPreview: state.mainId
          ? xpreviewReducer(state.extraPreview, action)
          : state.extraPreview
      }
    : state;
}

export default unitPreviewReducer;
