import {
  type Documents,
  type Locale,
  type MaterialFile,
  type Role,
  type Sounds,
  type StudentTeachers,
  type UploadingFile,
  type UploadingFiles
} from 'store/interface';
import {MaterialsTab} from 'common/enums';

import {type ToggleElementCreator} from '../../../../../common/interface';
import {type FileType, type PublishMaterialsOpenCreator} from '../../../actions/interface';
import {type DocumentFile, type DocumentFileV2} from '../documentsTab/actions/interface';
import {type RemoveFileFromUploadsCreator} from '../uploadingFiles/actions/interface';

export const materialsTabParamName = 'tab';
export const filesPdfPainterPagerPortalClassName = 'files-pdf-painter-pager-portal';

export function isMaterialsTab(arg: string): arg is MaterialsTab {
  return Object.values<string>(MaterialsTab).includes(arg);
}

export interface WorkSpaceStateProps {
  soundsLoaded: boolean;
  documents?: Documents;
  uploadsNumber: number;
  userId: number;
  uploadsSearchResultsNum?: number;
  expanded?: boolean;
  callInProgress?: boolean;
  videoUndocked?: boolean;
  searchBarExpanded?: boolean;
  role: Role;
  appOnline?: boolean;
  searchParam?: string;
}

export interface DispatchWorkSpaceProps {
  loadSounds: (courseId: number) => Promise<unknown>;
  loadDocuments: (courseId: number, shouldRenderLoader?: boolean) => Promise<unknown>;
  toggleVideoUndocked: ToggleElementCreator;
  openMaterialsSearchBar: () => void;
  getRecentSounds: () => void;
  getRecentDocuments: () => void;
  resetFiles: () => void;
  playSound: (uniquePlaybackId: string) => void;
  documentViewed: (id: number) => void;
  openTab: (tab: MaterialsTab) => void;
}

export interface WorkSpaceOwnProps {
  studentTeacherId: number;
  courseId: number;
  readonly: boolean;
  studentTeachers?: StudentTeachers;
  shouldRenderUploadsTab?: boolean;
  homeworkDoc?: DocumentFile | DocumentFileV2;
  selectedPageNumber?: number;
  wampSubscribed?: boolean;
  selectedDocId?: number;
  selectDoc?: (id: number) => void;
  unselectDoc?: () => void;
  openWhenLoadedSoundId?: string;
  clearOpenWhenLoadedSound?: () => void;
}

export interface WorkSpaceProps
  extends WorkSpaceStateProps,
    DispatchWorkSpaceProps,
    WorkSpaceOwnProps {}

export interface FilesListStateProps {
  files: Sounds | Documents;
  filter: string;
  fileType: FileType;
  role: Role;
  userId: number;
  userTimezone: string;
  uploadingFiles: UploadingFiles;
  listIsEmpty: boolean;
  networkError: boolean;
  locale: Locale;
}

export type PublishMaterialsOpen = (fileId: number, uniquePlaybackId?: string) => void;

export interface DispatchFilesListProps {
  changeActiveFile: (id: number | null) => void;
  removeFileFromUploads: RemoveFileFromUploadsCreator;
  publishMaterialsOpen: PublishMaterialsOpenCreator | PublishMaterialsOpen;
  publishMaterialsUpdated: (courseInstanceId?: number) => void;
  reloadFiles: () => void;
}

export interface FilesListOwnProps {
  readonly: boolean;
  courseId: string;
  studentTeacherId: number;
  selectDocument: (id: number) => void;
}

export interface FilesListProps
  extends FilesListStateProps,
    DispatchFilesListProps,
    FilesListOwnProps {}

export interface FilesByDate {
  [key: string]: Array<MaterialFile | UploadingFile>;
}
