import {type IntlShape} from 'react-intl';
import {type Value} from '@englex/slate';
import * as yup from 'yup';

import {type MultipleChoiceJSON} from 'store/exercise/player/widgets/List/interface';
import {record} from 'immutable-record/decorator/record';

import {WidgetTitle, type WidgetToJSONOptions, WidgetType} from '../../../player/interface';
import XMultipleChoiceRecord from '../XMultipleChoice/XMultipleChoiceRecord';
import validationMessages from '../i18n';
import {answerBlocksNotEmpty, minRequiredAnswers, questionLabelsNotEmpty} from '../validation';
import {freeChoiceAnswersNumberMin} from '../constants';

class XFreeChoiceRecord extends XMultipleChoiceRecord {
  public get type() {
    return WidgetType.FREE_CHOICE;
  }

  public get title(): string {
    return WidgetTitle.FreeChoice;
  }

  public toJSON(options: WidgetToJSONOptions): MultipleChoiceJSON {
    const widgetJSON = super.toJSON(options);

    delete widgetJSON.answers;

    return widgetJSON;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Questions not empty',
          intl.formatMessage(validationMessages.QuestionsNonEmpty),
          (v: Value) =>
            !this.options.get('withoutQuestions') ? questionLabelsNotEmpty(v.document) : true
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => answerBlocksNotEmpty(v.document)
        )
        .test(
          'Min required answers',
          intl.formatMessage(validationMessages.MinRequiredAnswers),
          (v: Value) => minRequiredAnswers(v.document, freeChoiceAnswersNumberMin)
        )
    });
  }
}

record()(XFreeChoiceRecord);
export default XFreeChoiceRecord;
