import {type List} from 'immutable';

import {type VocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/interface';
import {isVocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/utils';
import {PartOfSpeech, XVocabularyDefaultCategories} from 'components/Dictionary/shared/interface';

import {type VocabularyWordProperties, type VocabularyCategoryProperties} from './interface';

const CategoryToPartOfSpeech = {
  [XVocabularyDefaultCategories.NOUNS]: PartOfSpeech.NOUN,
  [XVocabularyDefaultCategories.ADJECTIVES]: PartOfSpeech.ADJECTIVE,
  [XVocabularyDefaultCategories.VERBS]: PartOfSpeech.VERB,
  [XVocabularyDefaultCategories.ADVERBS]: PartOfSpeech.ADVERB
};

interface Result {
  partOfSpeech?: PartOfSpeech;
  transformedWord: string;
}

// this function is used to transform word original to suitable for pronunciation request form and
// also to get part of speech that should be included in request, if one can be found
export function transformForRequestAndGetPOS(
  wordIndex: number,
  vocabulary: List<VocabularyWordProperties | VocabularyCategoryProperties>
): Result {
  const word = (vocabulary.get(wordIndex) as VocabularyWordProperties).splitByBraces
    .textBeforeBraces;

  const wordWithoutArticle = word.replace(/^(a|(the)|(an))\s(.+)/, '$4');
  const articleWasCut = wordWithoutArticle !== word;
  if (articleWasCut) {
    return {transformedWord: wordWithoutArticle, partOfSpeech: PartOfSpeech.NOUN};
  }

  const wordWithoutVerbParticle = word.replace(/^to\s(.+)/, '$1');
  const verbParticleWasCut = wordWithoutVerbParticle !== word;
  if (verbParticleWasCut) {
    return {transformedWord: wordWithoutVerbParticle, partOfSpeech: PartOfSpeech.VERB};
  }

  const result: Result = {transformedWord: word};
  for (let i = wordIndex; i >= 0; i--) {
    const entry = vocabulary.get(i);
    if (!isVocabularyWordJSON(entry)) {
      Object.keys(CategoryToPartOfSpeech).forEach(selectedCategory => {
        if (selectedCategory.toLowerCase() === entry.name.toLowerCase()) {
          result.partOfSpeech = CategoryToPartOfSpeech[selectedCategory];
        }
      });
      break;
    }
  }
  return result;
}

export function isOriginalNotEmpty(word: VocabularyWordJSON | VocabularyWordProperties) {
  return !word.original.match(/^\s*$/);
}
