import React, {Component, type FC} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type CompleteCrop, type Crop} from 'react-image-crop';
import classNames from 'classnames';

import {type EnglexImage} from 'store/interface';
import md5Chunk from 'services/common-methods/md5chunk';
import {getStatusText, validateImageFile} from 'common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import PictureUploadingStatus from 'common/ImageUpload/PictureUploadingStatus';
import {AspectRatio} from 'components/Crop/static';
import CropComponent from 'components/Crop';
import * as toastr from 'components/toastr';
import Icon from 'components/Icon';

import ImageFileInput from './ImageFileInput';
import ImageUploader from './ImageUploader';
import CropControls from './CropButtons';
import {type ToolBoxChildrenProps} from '../../../interface';
import messages from '../i18n';
import {IMAGE_MAX_WIDTH, IMAGE_MIN_HEIGHT, IMAGE_MIN_WIDTH, IMAGE_RESIZE_GRID} from '../static';
import CropResult from './CropResult';
import {type AfterDropInitials} from '../interface';

const DndImage: FC = ({children}) => <div className="dnd-image">{children}</div>;

enum ImageBlockPhase {
  NO_IMAGE = 'NO IMAGE',
  IMAGE = 'IMAGE',
  CROP = 'CROP',
  CROP_RESULT = 'CROP RESULT',
  UPLOADING = 'UPLOADING'
}

interface Defaults {
  minHeight: number;
  minWidth: number;
  maxHeight?: number;
  maxWidth?: number;
}

interface P extends ToolBoxChildrenProps, AfterDropInitials, Defaults {
  insertImage: (
    id: number,
    w: number,
    h: number,
    urls?: string[],
    originalWidth?: number,
    originalHeight?: number
  ) => void;
  openedFromInlineToolbar: boolean;
}

type Props = P & WrappedComponentProps;

interface State extends AfterDropInitials {
  allowCrop: boolean;
  aspectRatio: AspectRatio;
  cropping: boolean;
  imageBlockPhase: ImageBlockPhase;
  // initialCrop is needed to figure out if we enter crop phase from image phase (no initialCrop)
  // or from stepping back from crop result phase
  initialCrop?: Crop;
  lockedAspectRatios: AspectRatio[];
  cropResult?: CompleteCrop;
}

const initialState: State = {
  allowCrop: false,
  aspectRatio: AspectRatio.ORIGINAL,
  cropping: false,
  file: null,
  imageBlockPhase: ImageBlockPhase.NO_IMAGE,
  imageDataUrl: undefined,
  initialCrop: undefined,
  lockedAspectRatios: [],
  cropResult: undefined,
  status: undefined,
  validatedFile: undefined
};

class ImageModal extends Component<Props, State> {
  public static defaultProps: Defaults = {
    minHeight: IMAGE_MIN_HEIGHT,
    minWidth: IMAGE_MIN_WIDTH
  };

  public state: State = initialState;
  private imageNaturalHeight: number;
  private imageNaturalWidth: number;

  constructor(props: Props) {
    super(props);
    if (props.status) {
      toastr.error('', getStatusText(props.intl, props.status, [props.minHeight, props.minWidth]));
      this.close();
    } else if (props.imageDataUrl && props.file && props.validatedFile) {
      this.state = {
        ...initialState,
        file: props.file,
        validatedFile: props.validatedFile,
        imageDataUrl: props.imageDataUrl,
        allowCrop: true,
        imageBlockPhase: ImageBlockPhase.IMAGE
      };
    }
  }

  public render() {
    const {openedFromInlineToolbar} = this.props;
    const {ApplyButton, CancelButton} = this;
    return (
      <>
        <Modal.Header className="dnd-image-modal-header">
          {openedFromInlineToolbar ? (
            <FormattedMessage id="Slate.Toolbar.Button.ImageReplace" />
          ) : (
            <FormattedMessage id="Slate.Modal.Image.Header.Title" />
          )}
          <Button className="btn-xs" onClick={this.close}>
            <Icon name="pc-close" />
          </Button>
        </Modal.Header>
        <Modal.Body className="dnd-image-modal-body">{this.renderImageBlock()}</Modal.Body>
        <Modal.Footer className="dnd-image-modal-footer">
          <div className="image-upload-controls">
            <CancelButton />
            {this.renderFooterBlock()}
            <ApplyButton />
          </div>
        </Modal.Footer>
      </>
    );
  }

  private apply = () => {
    const [{file}, {freeze}] = [this.state, this.props];
    if (this.state.imageBlockPhase === ImageBlockPhase.CROP) {
      this.setState({imageBlockPhase: ImageBlockPhase.CROP_RESULT});
      return;
    }
    if (file) {
      this.setState({imageBlockPhase: ImageBlockPhase.UPLOADING});
      freeze(true);
    }
  };

  private close = () => this.props.close();

  private crop = () =>
    this.setState({
      cropping: true,
      imageBlockPhase: ImageBlockPhase.CROP,
      initialCrop: this.state.cropResult
    });

  private getCrop = (cropResult: CompleteCrop) => this.setState({cropResult});

  private handleCancel = () => {
    if (this.state.cropping) {
      this.setState({
        cropping: false,
        imageBlockPhase: ImageBlockPhase.IMAGE,
        initialCrop: undefined,
        cropResult: undefined,
        status: undefined
      });
    } else {
      this.props.close();
    }
  };

  private handleFileSelected = (imageDataUrl: string) => {
    this.validateFile()
      .then(() => {
        this.setState({
          imageDataUrl,
          allowCrop: true,
          imageBlockPhase: ImageBlockPhase.IMAGE,
          status: undefined
        });
      })
      .catch(e => {
        this.setState({status: e.message});
      });
  };

  private handleResponse = (response: AxiosResponseAction<EnglexImage>) => {
    const {insertImage} = this.props;
    const {id, width, height, urls} = response.payload.data;
    // Response contains image dimensions. Server is fine with images up to 5000px for each dimension, at the same
    // time slate player supports width up to IMAGE_MAX_WIDTH, so if response from server contains image below IMAGE_MAX_WIDTH wide
    // - do nothing, accept. If width > IMAGE_MAX_WIDTH, squeeze it to IMAGE_MAX_WIDTH, and, to keep image's aspect ratio
    // height must be squeezed accordingly.
    const [w, h] =
      width > IMAGE_MAX_WIDTH
        ? [IMAGE_MAX_WIDTH, (height * IMAGE_MAX_WIDTH) / width]
        : [width, height];
    // Slate redactor allows to resize images. Step of resize is IMAGE_RESIZE_GRID pixels. Next two lines of code ensure
    // that initially loaded image width is multiple of IMAGE_RESIZE_GRID. I.e. w, calculated on previous line is 345px.
    // It's fine with IMAGE_MAX_WIDTH constrain, but not with IMAGE_RESIZE_GRID. Next line makes it 340px. To keep aspect ratio
    // height is changed accordingly.
    const finalWidth = w - (w % IMAGE_RESIZE_GRID);
    const finalHeight = finalWidth !== w ? Math.round((h * finalWidth) / w) : h;
    insertImage(id, finalWidth, finalHeight, urls, width, height);
  };

  private imageOnLoad = (image: React.SyntheticEvent<HTMLImageElement>) => {
    const {minHeight, minWidth} = this.props;
    const {naturalHeight, naturalWidth} = image.currentTarget;
    if (naturalWidth < minWidth || naturalHeight < minHeight) {
      this.setState({
        allowCrop: false,
        cropping: false,
        file: null,
        imageBlockPhase: ImageBlockPhase.NO_IMAGE,
        imageDataUrl: undefined,
        status: UploadingPictureValidationError.IMAGE_SIZE_TOO_SMALL
      });
    }
    this.imageNaturalHeight = naturalHeight;
    this.imageNaturalWidth = naturalWidth;
  };

  private isErrorStatus = (): boolean =>
    !!this.state.status &&
    (this.state.status! in UploadingPictureValidationError ||
      this.state.status === UploadingPictureStatus.UPLOADING_ERROR);

  private lockAspectRatios = (lockedAspectRatios: AspectRatio[]) =>
    this.setState({lockedAspectRatios});

  private renderFooterBlock = () => {
    const {
      allowCrop,
      aspectRatio,
      imageBlockPhase,
      lockedAspectRatios,
      cropResult,
      status,
      validatedFile
    } = this.state;
    const {freeze, intl, minHeight} = this.props;
    switch (imageBlockPhase) {
      case ImageBlockPhase.IMAGE:
        return (
          <Button bsSize="small" onClick={this.crop} disabled={!allowCrop}>
            {intl.formatMessage(messages.cropButton)}
          </Button>
        );
      case ImageBlockPhase.CROP:
        return (
          <CropControls
            aspectRatio={aspectRatio}
            intl={intl}
            lockedAspectRatios={lockedAspectRatios}
            setAspectRatio={this.setAspectRatio}
          />
        );
      case ImageBlockPhase.CROP_RESULT:
        return (
          <Button bsSize="small" onClick={this.crop} disabled={!allowCrop}>
            {intl.formatMessage(messages.changeCropButton)}
          </Button>
        );
      case ImageBlockPhase.UPLOADING:
        const crop = cropResult
          ? cropResult
          : {x: 0, y: 0, width: this.imageNaturalWidth, height: this.imageNaturalHeight};
        return (
          <ImageUploader
            file={validatedFile!}
            freeze={freeze}
            handleResponse={this.handleResponse}
            hideModal={this.close}
            isErrorStatus={this.isErrorStatus()}
            pixelCrop={crop as CompleteCrop}
            setStatus={this.setStatus}
            status={status}
          />
        );
      case ImageBlockPhase.NO_IMAGE:
      default:
        return this.isErrorStatus() ? (
          <PictureUploadingStatus isError={true} status={status!} minResolution={minHeight} />
        ) : null;
    }
  };

  private renderImageBlock = () => {
    const {aspectRatio, cropping, imageDataUrl, imageBlockPhase, initialCrop, cropResult} =
      this.state;
    const {intl, minHeight, minWidth, maxWidth, maxHeight} = this.props;
    switch (imageBlockPhase) {
      case ImageBlockPhase.IMAGE:
        return (
          <DndImage>
            <img src={imageDataUrl} onLoad={this.imageOnLoad} alt="" />
          </DndImage>
        );
      case ImageBlockPhase.CROP_RESULT:
        return (
          <CropResult
            originalHeight={this.imageNaturalHeight}
            imageDataUrl={imageDataUrl!}
            cropResult={cropResult!}
            originalWidth={this.imageNaturalWidth}
            containerWidth={maxWidth}
            containerHeight={maxHeight}
          />
        );
      case ImageBlockPhase.UPLOADING:
        return cropping ? (
          <CropResult
            originalHeight={this.imageNaturalHeight}
            imageDataUrl={imageDataUrl!}
            cropResult={cropResult!}
            originalWidth={this.imageNaturalWidth}
          />
        ) : (
          <DndImage>
            <img src={imageDataUrl} onLoad={this.imageOnLoad} alt="" />
          </DndImage>
        );
      case ImageBlockPhase.CROP:
        return cropping ? (
          <CropComponent
            aspectRatio={aspectRatio}
            getCrop={this.getCrop}
            imageDataUrl={imageDataUrl!}
            initialCrop={initialCrop as CompleteCrop}
            lockAspectRatios={this.lockAspectRatios}
            minHeight={minHeight}
            minWidth={minWidth}
          />
        ) : (
          <DndImage>
            <img src={imageDataUrl} onLoad={this.imageOnLoad} alt="" />
          </DndImage>
        );
      case ImageBlockPhase.NO_IMAGE:
      default:
        return (
          <ImageFileInput
            dispatchFile={this.storeFile}
            dispatchImageDataUrl={this.handleFileSelected}
            intl={intl}
            minHeight={minHeight}
            minWidth={minWidth}
          />
        );
    }
  };

  private setAspectRatio = (aspectRatio: AspectRatio) => this.setState({aspectRatio});

  private storeFile = (file: File) => this.setState({file});

  private setStatus = (status: UploadingPictureStatus) => this.setState({status});

  private validateFile = async () => {
    const {file} = this.state;
    const {minHeight} = this.props;
    if (file) {
      const md5 = await md5Chunk(file);
      const status = await validateImageFile(file, md5, undefined, undefined, minHeight);
      if (status in UploadingPictureValidationError) {
        throw new Error(status);
      }
      this.setState({validatedFile: {md5, file}});
    }
  };

  private ApplyButton: FC = () => (
    <Button
      bsSize="small"
      bsStyle="primary"
      className={classNames('apply', {
        crop:
          this.state.imageBlockPhase === ImageBlockPhase.CROP ||
          this.state.imageBlockPhase === ImageBlockPhase.CROP_RESULT
      })}
      disabled={
        this.state.imageBlockPhase === ImageBlockPhase.NO_IMAGE ||
        this.state.imageBlockPhase === ImageBlockPhase.UPLOADING
      }
      onClick={this.apply}
    >
      <span className="title">
        {this.props.intl.formatMessage(
          this.state.imageBlockPhase === ImageBlockPhase.CROP
            ? messages.applyButton
            : messages.insertButton
        )}
      </span>
    </Button>
  );

  private CancelButton: FC = () => (
    <Button
      bsSize="small"
      onClick={this.handleCancel}
      className={classNames('cancel', {
        crop:
          this.state.imageBlockPhase === ImageBlockPhase.CROP ||
          this.state.imageBlockPhase === ImageBlockPhase.CROP_RESULT
      })}
      disabled={
        this.state.status === UploadingPictureStatus.CLONING ||
        this.state.status === UploadingPictureStatus.UPLOADING
      }
    >
      <span className="title">
        {this.props.intl.formatMessage(
          this.state.imageBlockPhase === ImageBlockPhase.CROP_RESULT ||
            this.state.imageBlockPhase === ImageBlockPhase.CROP
            ? messages.undoCropButton
            : messages.cancelButton
        )}
      </span>
    </Button>
  );
}

export default injectIntl<'intl', Omit<Props, 'minHeight' | 'minWidth'> & Partial<Defaults>>(
  ImageModal
);
