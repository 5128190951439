import {useCallback, useEffect, useState} from 'react';
import {type PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {pdfjs} from 'config/react-pdf';

export function usePDFDocumentProxy(docUrl?: string) {
  const [pdfDocumentProxy, setPDFDocumentProxy] = useState<PDFDocumentProxy>();

  const [isError, setIsError] = useState(false);

  const getPage = useCallback(() => {
    if (!docUrl) return;

    setIsError(false);

    pdfjs
      .getDocument(docUrl)
      .promise.then(pdf => setPDFDocumentProxy(pdf))
      .catch(error => {
        setIsError(true);

        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.warn('error', error);
        }
      });
  }, [docUrl]);

  useEffect(() => {
    getPage();

    return () => setPDFDocumentProxy(undefined);
  }, [getPage]);

  return {pdfDocumentProxy, isLoading: !pdfDocumentProxy, isError, reload: getPage};
}
