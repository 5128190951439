import {
  type Plugin,
  type Editor,
  type SlateError,
  type Text,
  type SchemaProperties
} from '@englex/slate';
import {Set} from 'immutable';
import {hiddenChars} from '@englex/utils';

import {SlateObject} from '../interface';
import {logNormalizeError} from '../SlateEditor/plugins/utils';
import {type TextNormalizerOptions} from './interface';

export class TextNormalizer implements Plugin {
  private readonly stripChars: string = hiddenChars();

  constructor(options: TextNormalizerOptions = {}) {
    this.stripChars = options.override
      ? options.stripChars || ''
      : this.stripChars.concat(options.stripChars || '');
  }

  public schema: SchemaProperties = {
    rules: [
      {
        match: [{object: SlateObject.TEXT}],
        text: (text: string) => new RegExp('^[^' + this.stripChars + ']*$').test(text),
        normalize: (editor: Editor, error: SlateError) => {
          if (error.code === 'node_text_invalid') {
            const text = error.node as Text;
            editor.withoutSaving(() => {
              const selection = editor.value.selection;
              editor.setTextByKey(
                error.node.key,
                text.text.replace(new RegExp('[' + this.stripChars + ']', 'g'), ''),
                text.marks || Set()
              );
              if (selection) {
                editor.select(selection);
              }
            });
            logNormalizeError(error, 'TextNormalizer.schema.rules', true, '0');
            return;
          }
        }
      }
    ]
  };
}
