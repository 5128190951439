import {type FC, memo, useState} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import {Translations} from '../Translations/Translations';
import {type LexicalEntryType} from '../../interfaces';
import {ExampleItem} from '../Example/ExampleItem';

import './Entry.scss';

interface Props {
  lexicalEntry: LexicalEntryType;
  includedTranslations: Record<string, string>;
  isDefaultCollapse?: boolean;
  dictionaryIsReadonly: boolean;
  addWord: (translation: string) => void;
  deleteWord: (translation: string) => void;
}

export const Entry: FC<Props> = memo(
  ({lexicalEntry, includedTranslations, dictionaryIsReadonly, addWord, deleteWord}) => {
    const {formatMessage} = useIntl();

    const [isExamplesHidden, setIsExamplesHidden] = useState(true);

    const switchIsExamplesHidden = () => {
      setIsExamplesHidden(isExamplesHidden => !isExamplesHidden);
    };

    return (
      <>
        <div className="entry">
          {lexicalEntry?.entries?.map((entry, index) => {
            if (entry?.translations)
              return (
                <Translations
                  key={index}
                  translations={entry.translations}
                  includedTranslations={includedTranslations}
                  dictionaryIsReadonly={dictionaryIsReadonly}
                  addWord={addWord}
                  deleteWord={deleteWord}
                />
              );

            return null;
          })}
        </div>
        {lexicalEntry?.entries?.some(entry => entry?.examples && entry?.examples?.length > 0) && (
          <>
            <div className={classNames('entry__examples-wrapper', {hidden: isExamplesHidden})}>
              {lexicalEntry?.entries?.map(entry =>
                entry?.examples?.map(example => (
                  <ExampleItem
                    key={example?.text}
                    example={example?.text}
                    translations={example.translations}
                  />
                ))
              )}
            </div>
            {
              <div className="entry__examples-switcher" onClick={switchIsExamplesHidden}>
                {isExamplesHidden
                  ? formatMessage({id: 'Dictionary.ShowExamples'})
                  : formatMessage({id: 'Dictionary.HideExamples'})}
              </div>
            }
          </>
        )}
      </>
    );
  }
);
