import {fromJS, List, Map} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {
  type PictureChoiceAnswers,
  PictureChoiceAnswerType,
  PictureChoiceMode,
  PictureChoiceQuestionsMode
} from 'store/exercise/editor/widgets/XPictureChoice/interface';

import {WidgetType} from '../../interface';
import {PictureChoiceQuestionRecord} from './PictureChoiceQuestionRecord';
import WidgetRecord from '../../WidgetRecord';
import {
  type PictureChoiceJSON,
  type PictureChoiceProperties,
  type PictureChoiceQuestionProperties
} from './interface';

type PictureChoiceValues = Map<string, List<string>>;

class PictureChoiceRecord
  extends WidgetRecord<PictureChoiceAnswers, PictureChoiceValues>
  implements PictureChoiceProperties
{
  public declare mode: PictureChoiceMode;
  public declare questionsMode: PictureChoiceQuestionsMode;
  public declare answerType: PictureChoiceAnswerType;
  public declare questions: List<PictureChoiceQuestionProperties>;

  constructor(raw: PictureChoiceJSON) {
    super(raw);
    this.initValues({
      mode: raw.mode,
      questionsMode: raw.questionsMode ? raw.questionsMode : PictureChoiceQuestionsMode.Default,
      answerType: raw.answerType,
      questions: List(raw.questions.map(question => new PictureChoiceQuestionRecord(question))),
      answers: raw.answers ? fromJS(raw.answers) : undefined,
      values: raw.values ? fromJS(raw.values) : undefined
    });
  }

  public toJSON() {
    return {
      ...super.toJSON(),
      mode: this.mode,
      questionsMode: this.questionsMode,
      answerType: this.answerType,
      questions: this.questions
        .map((question: PictureChoiceQuestionProperties) => question.toJSON())
        .toArray(),
      answers: this.answers.toJS(),
      values: this.values.toJS()
    };
  }

  public get type() {
    return WidgetType.PICTURE_CHOICE;
  }

  public get isAvailableSelection() {
    return true;
  }

  public setAnswersFromJSON(values: PictureChoiceJSON['values']): this {
    return this.set('answers', fromJS(values));
  }

  public setValuesFromJSON(values?: PictureChoiceJSON['values']): this {
    return values ? this.set('values', fromJS(values)) : this;
  }

  public toggleValue(questionId: string, cardId: string) {
    const hasAnswer = this.checkAnswer(questionId, cardId);

    return hasAnswer ? this.deleteValue(questionId, cardId) : this.addValue(questionId, cardId);
  }

  private addValue(questionId: string, cardId: string) {
    const thisWithValues = this.set('values', this.values ?? Map());

    if (this.answerType === PictureChoiceAnswerType.One) {
      const answers = thisWithValues.get('values').set(questionId, List([cardId]));

      return thisWithValues.set('values', answers);
    }

    const answersList = thisWithValues.getIn(['values', questionId], List()).push(cardId);

    return thisWithValues.setIn(['values', questionId], answersList);
  }

  private deleteValue(questionId: string, cardId: string) {
    const answersList: List<string> = this.getIn(['values', questionId], List()).filter(
      (card: string) => card !== cardId
    );
    const hasEmptyList = !answersList.size;

    if (hasEmptyList) {
      const answers = this.get('values').delete(questionId);

      return this.set('values', answers);
    }

    return this.setIn(['values', questionId], answersList);
  }

  private checkAnswer(questionId: string, cardId: string) {
    const answers: boolean = !!this.getIn(['values', questionId], List()).find(
      (card: string) => card === cardId
    );

    return answers;
  }
}

decorate(PictureChoiceRecord, {
  mode: property(PictureChoiceMode.MULTIPLE_CHOICE),
  questionsMode: property(PictureChoiceQuestionsMode.Default),
  answerType: property(PictureChoiceAnswerType.One),
  questions: property(List())
});
record()(PictureChoiceRecord);

export default PictureChoiceRecord;
