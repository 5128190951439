import React, {type FC} from 'react';
import classNames from 'classnames';

import {SelectButton} from '../../../button';
import {YSyncHwStatus} from '../../../YSyncHwStatus';
import {ToggleControlsButton} from './ToggleControlsButton';
import {LastToolButton} from '../last';

import './MobileToolbarHeader.scss';

interface Props {
  controlsIsOpen: boolean;
  toggleIsOpen: () => void;
}

export const MobileToolbarHeader: FC<Props> = ({controlsIsOpen, toggleIsOpen}) => {
  return (
    <div className="painter-mobile-toolbar__header">
      <div className="left">
        <SelectButton />
        <LastToolButton />
      </div>

      <div className="center">
        <ToggleControlsButton
          className={classNames({rotated: controlsIsOpen})}
          onClick={toggleIsOpen}
        />
      </div>

      <div className="right">
        <YSyncHwStatus />
      </div>
    </div>
  );
};
