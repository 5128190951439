import React from 'react';
import {default as Tooltip} from 'rc-tooltip';
import classNames from 'classnames';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import Icon from 'components/Icon';
import './FormControlTooltip.scss';

type ValidationState = 'success' | 'warning' | 'error';
type ToggleIcon = {[key in keyof ValidationState]: string | null};

interface Props {
  state: ValidationState | null;
  content?: React.ReactNode;
  tooltip?: Partial<TooltipProps>;
  icon?: ToggleIcon;
}

interface State {}

class FormControlTooltip extends React.Component<Props, State> {
  public static defaultIcon = {
    null: null,
    success: 'check-circle',
    error: 'exclamation-triangle'
  };
  public render() {
    const inputGroupClassName = classNames('form-control-tooltip', {
      [this.props.state as string]: !!this.props.state
    });

    return (
      <div className={inputGroupClassName}>
        {this.props.children}
        {this.renderErrorTooltip()}
      </div>
    );
  }

  private renderErrorTooltip = () => {
    const props = this.props.tooltip ? this.props.tooltip : {};
    return this.props.content ? (
      <Tooltip
        trigger={['hover']}
        placement="top"
        destroyTooltipOnHide={true}
        overlayClassName="rc-form-control-tooltip"
        {...props}
        overlay={this.props.content}
      >
        {this.renderFeedbackIcon()}
      </Tooltip>
    ) : (
      this.renderFeedbackIcon()
    );
  };

  private renderFeedbackIcon = () => {
    let icon = null;

    let toggleIconName = null;
    if (this.props.state) {
      toggleIconName =
        this.props.icon && this.props.icon[this.props.state]
          ? this.props.icon[this.props.state]
          : FormControlTooltip.defaultIcon[this.props.state];
    }

    if (toggleIconName) {
      icon = <Icon name={`${toggleIconName}`} />;
    }

    return <span className="form-control-tooltip-toggle">{icon}</span>;
  };
}

export default FormControlTooltip;
