import {type AxiosRequestAction} from 'services/axios/interface';

import {
  REQUEST_SEARCH_WORD_IN_OD_EN_TO_RUS,
  REQUEST_SEARCH_WORD_IN_OD_BY_ID_EN_TO_RUS,
  REQUEST_SEARCH_WORD_IN_OD_EN,
  REQUEST_SEARCH_WORD_IN_OD_BY_ID_EN
} from './actionTypes';

export const requestSearchInODEnToRus = (
  word: string,
  sourceLang: 'en' | 'ru',
  targetLang: 'en' | 'ru'
): AxiosRequestAction => {
  return {
    type: REQUEST_SEARCH_WORD_IN_OD_EN_TO_RUS,
    payload: {
      client: 'v2',
      request: {
        method: 'GET',
        url: `v2/translation/${sourceLang}/${targetLang}/search/${word}`
      }
    }
  };
};

export const requestSearchInODEn = (word: string): AxiosRequestAction => {
  return {
    type: REQUEST_SEARCH_WORD_IN_OD_EN,
    payload: {
      client: 'v2',
      request: {
        method: 'GET',
        url: `v2/translation/en-gb/search/${word}`
      }
    }
  };
};

export const requestSearchInODByIdEnToRus = (
  id: string,
  sourceLang: 'en' | 'ru',
  targetLang: 'en' | 'ru'
): AxiosRequestAction => {
  return {
    type: REQUEST_SEARCH_WORD_IN_OD_BY_ID_EN_TO_RUS,
    payload: {
      client: 'v2',
      request: {
        method: 'GET',
        url: `v2/translation/${sourceLang}/${targetLang}/word/${id}`
      }
    }
  };
};

export const requestSearchInODByIdEn = (id: string): AxiosRequestAction => {
  return {
    type: REQUEST_SEARCH_WORD_IN_OD_BY_ID_EN,
    payload: {
      client: 'v2',
      request: {
        method: 'GET',
        url: `v2/translation/en-gb/word/${id}`
      }
    }
  };
};
