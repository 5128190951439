import classNames from 'classnames';
import {type FC, memo, useCallback, useContext} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';

import {deselectAll} from 'components/Dictionary/shared/contexts/entry/actions';
import Icon from 'components/Icon';
import {EditEntryActionsContext} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';

import './UnselectButton.scss';

interface Props {
  numberOfSelectedEntries: number;
}

export const UnselectButton: FC<Props> = memo(({numberOfSelectedEntries}) => {
  const {formatMessage} = useIntl();
  const {dispatch} = useContext(EditEntryActionsContext);

  const deselectAllCallback = useCallback(() => {
    dispatch(deselectAll());
  }, [dispatch]);

  return (
    <Button
      className={classNames('unselect-button')}
      onClick={deselectAllCallback}
      disabled={!numberOfSelectedEntries}
      title={formatMessage({id: 'Dictionary.UncheckAll'})}
    >
      <Icon name="square-o" />
    </Button>
  );
});
