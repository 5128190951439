import React from 'react';
import {type Editor as ReactEditor, type RenderMarkProps} from '@englex/slate-react';
import {type Editor, type Next, type Mark as CoreMark, type GenericQuery} from '@englex/slate';

import {type RendererPlugin} from '../interface';
import {type SlateMark, getMarkClassNames} from '../../interface';
import {type MarkClassNamesPlugin} from '../../SlateEditor/plugins/interface';

abstract class Mark implements RendererPlugin, MarkClassNamesPlugin {
  public onQuery = (query: GenericQuery<[CoreMark]>, editor: Editor, next: Next) => {
    if (query.type === getMarkClassNames) {
      const [mark] = query.args;
      if (mark.type === this.mark) {
        return this.getMarkClassNames();
      }
    }
    return next();
  };
  public abstract mark: SlateMark;
  public abstract wrapper: React.ElementType | string;

  public getMarkClassNames = (): string => {
    return this.mark;
  };

  public renderMark = (
    {attributes, mark, children}: RenderMarkProps,
    editor: ReactEditor,
    next: Next
  ) => {
    if (mark.type !== this.mark) {
      return next();
    }

    const Wrapper = this.wrapper;
    return <Wrapper {...attributes}>{children}</Wrapper>;
  };
}

export default Mark;
