import {type XScrambledJSON, type XScrambledProperties} from '../XScrambled/interface';
import {type SpellingJSON} from '../../../player/widgets/Selling/interface';
import {type CheckerFn} from '../../../../../components/Slate/plugins/DecorateText/interface';

export enum SpellingType {
  Phrases = 'phrases',
  Words = 'words'
}

export interface XSpellingProperties extends XScrambledProperties {
  spellingType?: SpellingType;
  ordinal?: string;
  setOrdinal: (newOrdinal?: string) => XSpellingProperties;
  letterChecker: CheckerFn;
}

export type XSpellingJSON = XScrambledJSON & Omit<SpellingJSON, 'sentences'>;
