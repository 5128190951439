import {type FC, memo} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import './SearchOffer.scss';

export enum OfferType {
  COULD_NOT_BE_FOUND = 'COULD_NOT_BE_FOUND',
  ADDITIONAL_SEARCH = 'ADDITIONAL_SEARCH'
}

interface Props {
  offerType: OfferType;
  className?: string;
}

export const SearchOffer: FC<Props> = memo(({className, offerType}) => {
  const {formatMessage} = useIntl();

  const titles = {
    [OfferType.COULD_NOT_BE_FOUND]: formatMessage({id: 'Dictionary.Entry.NothingCoincidences'}),
    [OfferType.ADDITIONAL_SEARCH]: formatMessage({id: 'Dictionary.Entry.NothingFound'})
  };

  return (
    <div className={classNames('search-offer', className)}>
      <div className="search-offer__text">
        <p className="first-line">{titles[offerType]}</p>
      </div>
    </div>
  );
});
