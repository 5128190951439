import React, {useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {SelectablePaint, useFocused, usePaint, withPaint, ToolsPaint} from '@englex/paint-react';
import {PSelection} from '@englex/paint';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import isShortcut from '../../../../helpers/shortcut';
import {useIsDrawing} from '../../hooks/useIsDrawing';
import {buttonTitles} from '../titles';

interface Props {
  shortcut?: string;
}

export const SelectButton = withPaint(({shortcut = 'mod+opt+s'}: Props) => {
  const intl = useIntl();
  const paint = usePaint<SelectablePaint>();
  const isFocused = useFocused();
  const isDrawing = useIsDrawing();

  const onClick = useCallback(
    e => {
      if (isDrawing()) return;

      const isSelectable = paint.isSelectable;
      if (SelectablePaint.is(paint) && !isSelectable) {
        paint.setSelectable();
        ToolsPaint.selectTool(paint);
      }
    },
    [paint, isDrawing]
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isFocused || isDrawing()) return;

      if (isShortcut(e, shortcut)) {
        e.preventDefault();
        e.stopPropagation();
        if (SelectablePaint.is(paint) && PSelection.isTextSelection(paint.selection)) {
          paint.editText();
        }
        SelectablePaint.enable(paint);
        ToolsPaint.selectTool(paint);
      }
    },
    [isFocused, isDrawing, paint, shortcut]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <ToolbarButton
      onClick={onClick}
      className={classNames({
        active: paint.isSelectable
      })}
      shortcut={shortcut}
      title={intl.formatMessage(buttonTitles.select)}
    >
      <Icon name="hand-pointer-o" />
    </ToolbarButton>
  );
}, SelectablePaint.is);
