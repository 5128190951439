import React, {type FC, type PropsWithChildren, useEffect, useMemo} from 'react';
import {
  withReact,
  withTools,
  withColors,
  withThickness,
  withSelectable,
  withFontSize
} from '@englex/paint-react';
import {type PaintPlugin, type POrientation} from '@englex/paint';

import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import {ErrorMask} from 'components/ErrorMask';

import {useYPainter} from './useYPainter';
import {Painter} from '../Painter/Painter';
import {withImage, withStoredState, withThicknessPreset} from '../Painter/plugins';

interface Props {
  docId: string;
  topic?: string;
  scale?: number;
  orientation?: POrientation;
  plugins?: PaintPlugin[];
  toolbarPortalNode?: HTMLDivElement | null;
  renderError?: (reload: () => void) => JSX.Element | null;
  renderLoader?: () => JSX.Element | null;
  onError?: (reload: () => void) => void;
  onLoading?: (loading: boolean) => void;
}

const defaultPlugins = [
  withReact(),
  withSelectable({isSelectable: true}),
  withTools(),
  withColors(),
  withThickness(),
  withThicknessPreset(),
  withFontSize(),
  withImage,
  withStoredState
];

export const YPainter: FC<Props> = ({
  topic = 'ydoc:doc',
  docId,
  scale,
  orientation,
  plugins: additionalPlugins,
  children,
  toolbarPortalNode,
  renderLoader,
  renderError,
  onError,
  onLoading
}: PropsWithChildren<Props>) => {
  const plugins = useMemo(
    () => (additionalPlugins ? [...defaultPlugins, ...additionalPlugins] : defaultPlugins),
    [additionalPlugins]
  );

  const {reload, paint, isLoaded, isError, stage, onChange} = useYPainter(docId, {
    plugins,
    topic,
    onError,
    onLoading
  });

  useEffect(() => {
    if (isLoaded && !isError) {
      // trigger onLoading for parent wrapper components
      onLoading?.(false);
    }
  }, [isError, isLoaded, onLoading]);

  if (isError) {
    return renderError ? renderError(reload) : <ErrorMask reload={reload} />;
  }

  if (!isLoaded || !stage) {
    return renderLoader ? renderLoader() : <SimpleLoadingOverlay />;
  }

  return (
    <Painter
      paint={paint}
      scale={scale}
      orientation={orientation}
      toolbarPortalNode={toolbarPortalNode}
      stage={stage}
      onChange={onChange}
    >
      {children}
    </Painter>
  );
};
