import {type PDFDocumentProxy, type RefProxy} from 'pdfjs-dist/types/src/display/api';

import {type Item} from './interface';

const getDestination = async (
  pdf: PDFDocumentProxy,
  item: Item
): Promise<[RefProxy, ...unknown[]]> => {
  const dest: [RefProxy] = item.dest as [RefProxy];
  if (typeof item.dest === 'string') {
    return (await pdf.getDestination(item.dest)) as [RefProxy];
  }

  return dest;
};
export const getOutlinePageIndex = async (pdf: PDFDocumentProxy, item: Item) => {
  let pageIndex = -1;
  const destination = await getDestination(pdf, item);
  if (destination) {
    const [ref] = destination;
    pageIndex = await pdf.getPageIndex(ref);
  }

  return pageIndex;
};
