import React from 'react';
import classNames from 'classnames';
import {type Color} from '@englex/paint-react';

interface Props {
  color: Color;
  onSelect: (color: Color) => void;
  active?: boolean;
}

export const ColorElement: React.FC<Props> = ({color, active, onSelect}) => {
  const handleClick = () => onSelect(color);

  const style = {backgroundColor: color};

  return (
    <div className={classNames('color-element', {active})} onClick={handleClick}>
      <div className="color" style={style} />
    </div>
  );
};
