import {type Action, type MiddlewareAPI} from 'redux';

import {type Dispatch} from 'services/wamp/actions/interface';

import {
  type BrowserNotificationConfig,
  type BrowserNotificationConfigFn,
  type MiddlewareFn,
  type NotificationMiddleware
} from './interface';
import {type AppState} from '../../interface';
import {changeIncomingCallNotification} from '../../../webRTC/action/action';

const notificationsList: {[key: string]: Notification} = {};

function shouldShowNotification(config?: BrowserNotificationConfig, badBrowser?: boolean) {
  let shouldShow = !!config && config.showOnInactiveTabOnly;
  if (shouldShow && config!.hasId) {
    shouldShow = !badBrowser;
  }
  return shouldShow;
}

function createNotification(
  config: BrowserNotificationConfig,
  api: MiddlewareAPI<Dispatch<Action, Action>, AppState>
) {
  try {
    const notification = new Notification(config.title, {
      body: config.body,
      icon: config.icon
    });
    if (config.onClick) {
      notification.onclick = e => {
        config.onClick!(e);
        notification.close();
      };
    }
    if (config.hasId) {
      const id = Math.round(Math.random() * 10000);
      notificationsList[id] = notification;
      api.dispatch(changeIncomingCallNotification(id));
    }
  } catch {
    return;
  }
}

type API = MiddlewareAPI<Dispatch<Action, Action>, AppState>;

const browserNotificationsMiddleware: MiddlewareFn = middlewareConfig => {
  const middleware: NotificationMiddleware =
    (api: API) =>
    (next: Dispatch<Action, Action>) =>
    <A extends Action>(action: A) => {
      const state = api.getState();

      if (middlewareConfig.notification[action.type]) {
        const actionHandler: BrowserNotificationConfigFn =
          middlewareConfig.notification[action.type];
        const config = actionHandler(api, action, notificationsList);

        if (!config) {
          return next(action);
        }
        if (state.layout.isMobile === false) {
          if (
            !state.layout.pageHasFocus &&
            shouldShowNotification(
              config as BrowserNotificationConfig,
              state.rtc ? state.rtc.badBrowser : undefined
            )
          ) {
            createNotification(config as BrowserNotificationConfig, api);
          }
        }
      }

      return next(action);
    };

  return middleware;
};

export default browserNotificationsMiddleware;
