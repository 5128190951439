import {type ChatMessage, type TextMessageMeta} from 'store/interface';
import {TextMessageTypes} from 'common/enums';

import {type ChatAction, type ChatImageAction} from '../actions/interface';
import {IMAGE_LOADING_FAILED} from '../actions/imageActionTypes';
import {checkIfParsedImage} from '../utils';

export const messagesReducer = (messages: ChatMessage[], action: ChatAction) => {
  switch (action.type) {
    case IMAGE_LOADING_FAILED: {
      const {messageId, imageId} = action as ChatImageAction;
      const newState = [...messages];
      const messageIndex = newState.findIndex(m => m.id === messageId);
      const message: ChatMessage = {...newState[messageIndex]} as ChatMessage;
      const meta = {...(message.meta as TextMessageMeta)};
      if (
        meta.type === TextMessageTypes.textWithAdditionalBubbles ||
        meta.type === TextMessageTypes.mediaSingleLink
      ) {
        if (meta.type === TextMessageTypes.mediaSingleLink) {
          meta.type = TextMessageTypes.text;
        }
        const images = [...meta.parsedMedia!];
        const index = images.findIndex(
          image => checkIfParsedImage(image) && image.imageId === imageId
        );
        if (index !== -1) {
          images.splice(index, 1);
        }
        meta.parsedMedia = images;
      }
      if (meta.type === TextMessageTypes.image) {
        meta.imageCorrupted = true;
      }
      message.meta = meta;
      newState[messageIndex] = message;
      return newState;
    }

    default:
      return messages;
  }
};
