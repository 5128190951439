import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import ServiceMessage, {
  type AddedCallDurationProps,
  type ServiceMessageProps
} from './ServiceMessage';

const mapStateToProps = (state: AppState) =>
  ({
    callDuration: state.rtc.call ? state.rtc.call.duration : 0
  }) as AddedCallDurationProps;

const MessageWithCallTimer = connect<AddedCallDurationProps, {}, ServiceMessageProps, {}>(
  mapStateToProps
)(ServiceMessage);

export default MessageWithCallTimer;
