import {Map, Record} from 'immutable';

import {defaultValues} from '../helpers';
import {type BaseRecord, type ImmutableRecord} from '../Record';

export function recordBase() {
  return <T extends typeof BaseRecord>(target: T): ImmutableRecord => {
    const recordBaseProto = target.prototype;
    const recordProto = Record({}).prototype;

    const recordBaseProps = Object.getOwnPropertyNames(recordBaseProto).filter(
      x => x !== 'constructor'
    );
    for (const prop of recordBaseProps) {
      recordProto[prop] = recordBaseProto[prop];
    }

    recordProto._name = '';

    Object.defineProperty(recordProto, 'size', {
      get() {
        return this._keys.length;
      }
    });

    const MRecord = function (): void {
      // @ts-ignore
      if (!(this instanceof MRecord)) {
        // @ts-ignore
        return new MRecord();
      }

      // @ts-ignore
      const name = this.constructor.name;

      Object.defineProperty(recordProto, '_defaultValues', {
        configurable: true,
        get() {
          return defaultValues.get(name);
        },
        set(value: object) {
          defaultValues.set(name, value);
        }
      });

      Object.defineProperty(recordProto, '_keys', {
        configurable: true,
        get() {
          return Object.keys(this._defaultValues);
        }
      });

      // @ts-ignore
      this._map = Map();
    };

    MRecord.prototype = recordProto;
    MRecord.prototype.constructor = MRecord;
    return MRecord as unknown as ImmutableRecord;
  };
}
