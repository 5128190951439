import {type Value, type ValueJSON} from '@englex/slate';
import {type List} from 'immutable';

import {type WidgetJSON, type WidgetProperties} from '../../interface';

export enum CardsType {
  TEXT = 'Text',
  IMAGES = 'Images'
}

export interface CardJSON {
  id: string;
  text: ValueJSON;
  title?: ValueJSON;
  image?: number;
}

export interface CardsJSON extends WidgetJSON {
  cardsType: CardsType;
  cardsList: CardJSON[];
  values?: string;
}

export interface CardsProperties extends WidgetProperties {
  cardsType: CardsType;
  cardsList: List<CardProperties>;
  values?: string;
  setValuesFromJSON: (values: string) => this;
}

export interface CardProperties {
  id: string;
  text: Value;
  image: number;
  title: Value;
}
