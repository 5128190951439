import React, {useCallback, useState} from 'react';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import classNames from 'classnames';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState, type Locale} from 'store/interface';

import flagOfRussia from '../assets/Flag_of_Russia.svg';
import flagOfBritain from '../assets/Flag_of_Great_Britain.svg';
import {changeLocale} from '../../i18n/actions/actions';
import './LanguageSelector.scss';
import Icon from '../../components/Icon';

const enabled = import.meta.env.REACT_APP_SWITCH_LOCALE_ENABLED === 'true';

interface Props {
  showCloseBtn?: boolean;
}

const LanguageSelector: React.FC<Props> = ({showCloseBtn = false}) => {
  const [open, setOpen] = useState(false);
  const {locale} = useSelector((state: AppState) => state.intl);
  const dispatch = useDispatch();
  const onSelect = useCallback(
    (eventKey: {}) => dispatch(changeLocale(eventKey as Locale)),
    [dispatch]
  );

  const toggle = useCallback((open: boolean) => setOpen(open), []);

  if (!enabled) {
    return null;
  }

  return (
    <NavDropdown
      open={open}
      className={classNames('nav-dropdown', 'language-selector-dropdown')}
      id={'language-selector-dropdown'}
      title={
        <img
          src={locale === 'ru' ? flagOfRussia : flagOfBritain}
          width={20}
          height={20}
          alt={locale}
        />
      }
      onSelect={onSelect}
      value={locale}
      onToggle={toggle}
    >
      {showCloseBtn && (
        <button
          className="language-dropdown-close-button dropdown-close-button"
          onClick={() => toggle(false)}
        >
          <Icon name="pc-close" />
        </button>
      )}
      <MenuItem eventKey="ru" active={locale === 'ru'} className="language-selector-menu-item">
        Русский
      </MenuItem>
      <MenuItem eventKey="en" active={locale === 'en'} className="language-selector-menu-item">
        English
      </MenuItem>
    </NavDropdown>
  );
};

export default LanguageSelector;
