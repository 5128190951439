import {type Editor} from '@englex/slate-react';
import {
  type Annotation,
  type Document,
  type Range,
  type RangeType,
  type Text,
  Value
} from '@englex/slate';
import {type List} from 'immutable';

import {
  type PointerSelectionManager,
  type StopSelectionEventDetails,
  type Subscriber as BaseSubscriber
} from 'components/Pointer/selection/interface';
import {type SelectionData as BaseSelectionData} from 'store/interface';
import {type WidgetComponentProps, WidgetType} from 'store/exercise/player/interface';

import {getWidgetProps, SlateAnnotation} from '../../../interface';
import {SelectionPointerEditor} from './SelectionPointerEditor';

type Subscriber = BaseSubscriber<Editor, Range>;
type SelectionData = BaseSelectionData<Range>;

export class PointerManager implements PointerSelectionManager<Editor, Range> {
  public isManagerFor(editor: unknown): editor is Editor {
    return Value.isValue((editor as Editor)?.value);
  }

  public createForTeacher(subscriber: Subscriber) {
    if (subscriber) {
      const {editor, range} = subscriber;

      editor.command(this.removeCreatePointer);
      editor.command(SelectionPointerEditor.createAnnotation, {
        type: SlateAnnotation.POINTER,
        range
      });
      editor.command(SelectionPointerEditor.resetRangeBySelection, document.getSelection());
    }
  }

  public createForStudent(subscriber: Subscriber, data: SelectionData): void {
    if (subscriber) {
      const {editor} = subscriber;
      const {elementId, range} = data;

      editor.command(SelectionPointerEditor.createAnnotation, {
        key: elementId,
        type: SlateAnnotation.POINTER,
        callback: SelectionPointerEditor.scrollToAnnotation,
        range
      });
    }
  }

  public removeCreatePointer(editor: Editor): void {
    SelectionPointerEditor.deleteAnnotation(editor, [SlateAnnotation.CREATE_POINTER]);
  }

  public getWidgetProps(editor: Editor) {
    return editor.query<WidgetComponentProps | undefined>(getWidgetProps);
  }

  public editorToString(editor: Editor) {
    const widgetProps = this.getWidgetProps(editor);

    if (!widgetProps) return '';

    switch (widgetProps.widget.type) {
      case WidgetType.COMMENT:
        return JSON.stringify(editor.value.document.text);

      default:
        return JSON.stringify(editor.value.document.toJSON());
    }
  }

  public startSelection(event: MouseEvent, editor: Editor, selection: Selection | null) {
    editor
      .command(SelectionPointerEditor.resetRangeBySelection, selection)
      .command(this.removeCreatePointer)
      .command(SelectionPointerEditor.blurEditor, event);
  }

  public getRange(
    selection: Selection,
    subscriber: Subscriber,
    details: StopSelectionEventDetails
  ): Range | null {
    return null;
  }

  public stopSelection(
    selection: Selection,
    subscriber: Subscriber,
    details: StopSelectionEventDetails
  ): Subscriber | null {
    const tripleClickedElement = details.isTripleClick && details.target;

    let range =
      // try to find slate range by DOM selection
      (subscriber.editor.findRange(selection) as Range) ||
      // or when triple click try to find range of clicked block node
      (!!tripleClickedElement &&
        subscriber.editor.query(
          SelectionPointerEditor.findRangeOfDOMElement,
          tripleClickedElement
        )) ||
      null;

    if (!range) return null;

    const {
      editor: {
        value: {document, annotations}
      }
    } = subscriber;

    range = this.normalizePointerRange(document, range);

    const pointerAnimatedAnnotations = annotations
      .filter((a: Annotation) => a.type === SlateAnnotation.POINTER)
      .toList();

    if (
      range.isSet &&
      range.isExpanded &&
      subscriber.isAvailable() &&
      this.shouldAddPointerAnnotation(pointerAnimatedAnnotations, range)
    ) {
      const {editor} = subscriber;

      editor.command(SelectionPointerEditor.createAnnotation, {
        key: SlateAnnotation.CREATE_POINTER,
        type: SlateAnnotation.CREATE_POINTER,
        callback: SelectionPointerEditor.selectRangeByAnnotation,
        range
      });

      return {...subscriber, range};
    }

    return null;
  }

  private isRangesIntersect(source: RangeType, target: RangeType): boolean {
    if (target.isUnset || source.isUnset) {
      return false;
    }
    return source.end.isAfterPoint(target.start) && source.start.isBeforePoint(target.end);
  }

  private shouldAddPointerAnnotation(annotations: List<Annotation>, range: Range) {
    return !annotations.some((a: Annotation) => this.isRangesIntersect(range, a));
  }

  /**
   * `editor.findRange(selection)` could return range, that ends exactly at start of some block
   * We prefer not to annotate this start leaf block, so trying to move end point th the end of previous text then
   */
  private normalizePointerRange(document: Document, range: Range): Range {
    range = this.normalizeEdgePointOfRange(document, range);
    return this.normalizeEdgePointOfRange(document, range, false);
  }

  private normalizeEdgePointOfRange(document: Document, range: Range, isEnd = true): Range {
    const leafBlocks = document.getLeafBlocksAtRange(range);
    const leafBlock = isEnd ? leafBlocks.last() : leafBlocks.first();
    const leafBlockPath = !!leafBlock && document.getPath(leafBlock.key);
    const point = isEnd ? range.end : range.start;
    const shouldTryMove =
      !!leafBlock && isEnd
        ? point.isAtStartOfNode(leafBlock)
        : point.isAtEndOfNode(leafBlock) && leafBlock.text.length;

    const edgeText: Text | null =
      (shouldTryMove &&
        !!leafBlockPath &&
        ((isEnd
          ? document.getPreviousBlock(leafBlockPath)?.getLastText()
          : document.getNextBlock(leafBlockPath)?.getFirstText()) as Text)) ||
      null;

    return this.tryMoveEdgePointToEdgeOfText(document, range, edgeText, isEnd);
  }

  private tryMoveEdgePointToEdgeOfText(
    document: Document,
    range: Range,
    edgeText: Text | null = null,
    isEnd = true
  ): Range {
    const edgeTextPath = edgeText && document.getPath(edgeText);
    if (edgeTextPath && edgeText) {
      const point = document.createPoint({
        path: edgeTextPath,
        offset: isEnd ? edgeText.text.length : 0
      });
      range = isEnd ? (range.setEnd(point) as Range) : (range.setStart(point) as Range);
    }
    return range;
  }
}
