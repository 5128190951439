import React, {type FC} from 'react';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';

import {useLocale} from 'hooks/user/useLocale';

import LanguageSelector from '../components/LanguageSelector';
import styles from './Header.module.scss';

export const Header: FC = () => {
  const locale = useLocale();
  return (
    <Navbar className={styles.navbar}>
      <Navbar.Header>
        <Navbar.Brand className={locale} />
      </Navbar.Header>
      <Nav pullRight={true} className="hidden-xs">
        <LanguageSelector />
      </Nav>
    </Navbar>
  );
};
