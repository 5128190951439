import React, {type FC} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

import {type Role} from 'store/interface';
import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import ModalError from 'components/ModalError';
import {type ExerciseJSON} from 'store/exercise/player/interface';
import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import XPlayer from 'components/XPlayer';
import {type XPreviewProperties} from 'store/exercise/player/preview/interface';
import {BootstrapBreakpoint} from 'config/static';

import ExtraExercisesList from './ExtraExercisesList';
import {CollapsedSidebarButton} from '../../../../../components/FloatSidebar/CollapsedSidebarButton';
import {
  FloatSidebar,
  FloatSidebarPosition,
  FloatSidebarProvider
} from '../../../../../components/FloatSidebar/FloatSidebar';

interface Props {
  activeExercise?: ExerciseJSON;
  exercises: ExerciseJSON[];
  hideControls: boolean;
  isError: boolean;
  isLoading: boolean;
  selectExercise: (id: string) => void;
  submitting: boolean;
  reload: () => undefined | (() => void);
  role: Role;
  xpreview?: XPreviewProperties;
}

const ModalBody: FC<Props> = ({
  activeExercise,
  exercises,
  hideControls,
  isError,
  isLoading,
  reload,
  role,
  selectExercise,
  submitting,
  xpreview
}) => {
  const resourcesNode = useNode('.supplementary-exercises-modal .modal-body');

  if (isError) {
    return (
      <Modal.Body>
        <ModalError
          errorTitle={
            <FormattedMessage id="SupplementaryExercisesModal.RequestExercisesErrorTitle" />
          }
          errorText={<FormattedMessage id="LibraryError.Description" />}
          reload={reload}
        />
      </Modal.Body>
    );
  }
  return (
    <Modal.Body>
      <Resources node={resourcesNode} id="modal-xplayer">
        {isLoading || !activeExercise ? (
          <SimpleLoadingOverlay />
        ) : (
          <>
            {submitting && <SimpleLoadingOverlay />}
            <FloatSidebar
              provider={FloatSidebarProvider.State}
              breakpoint={BootstrapBreakpoint.LG}
              position={FloatSidebarPosition.Left}
              collapsedButton={(clickHandler, sidebarCollapsed) => (
                <CollapsedSidebarButton
                  onClick={clickHandler}
                  shouldBeRendered={sidebarCollapsed || false}
                />
              )}
              layoutCollapsed={false}
            >
              <ExtraExercisesList
                exercises={exercises}
                selectedId={activeExercise.id!}
                selectExercise={selectExercise}
                showFormControls={true}
              />
            </FloatSidebar>
            {xpreview && (
              <XPlayer
                exercises={xpreview.exercises}
                hideCompleteButtons={hideControls}
                preview={true}
                role={role}
                isModal={true}
              />
            )}
          </>
        )}
      </Resources>
    </Modal.Body>
  );
};

export default ModalBody;
