import React, {type FC, useCallback, useState} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import {useIntl} from 'react-intl';

import {requestStudentLists} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import * as toastr from 'components/toastr';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import {type DictionaryList} from '../interface';
import {type DictionaryOwnerRole, useDictionaryContext} from '../contexts';

import './WordEntryLists.scss';

interface Props {
  id: string;
  alone?: boolean;
  listsMember?: number;
}

export const WordEntryLists: FC<Props> = React.memo(({id, alone, listsMember}) => {
  const intl = useIntl();
  const [showTooltip, setShowTooltip] = useState(false);
  const [lists, setLists] = useState<
    undefined | {id: string; title: string; deletedAt: string | null}[]
  >(undefined);
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const {isLoading, apiRequest: requestLists} = useApiRequest2<
    [number, DictionaryOwnerRole, string],
    DictionaryList[]
  >(
    requestStudentLists,
    data => {
      setLists(
        data
          .map(dl => ({id: dl.id, title: dl.title, deletedAt: dl.deletedAt}))
          .sort((a, b) => {
            const aWeight = a.deletedAt ? 0 : 1;
            const bWeight = b.deletedAt ? 0 : 1;
            return bWeight - aWeight;
          })
      );
      setShowTooltip(true);
    },
    () => {
      toastr.error('', intl.formatMessage({id: 'Dictionary.RetrievingListOfWordsError'}));
    }
  );

  const getTooltipContainer = useCallback<() => HTMLElement>(
    () => document.querySelector('.word-entry') || document.body,
    []
  );

  const toggleTooltip = useCallback(() => {
    setShowTooltip(!showTooltip);
  }, [showTooltip]);

  const onVisibleChange = useCallback(visible => {
    setShowTooltip(visible);
    if (!visible) {
      setLists(undefined);
    }
  }, []);

  const onClick = useCallback(() => {
    requestLists(dictionaryOwnerId, dictionaryOwnerRole, id);
  }, [dictionaryOwnerId, dictionaryOwnerRole, id, requestLists]);

  if (listsMember === 0) return null;

  if (lists)
    return (
      <Tooltip
        overlayClassName="word-entry-list-tooltip-overlay"
        getTooltipContainer={getTooltipContainer}
        onVisibleChange={onVisibleChange}
        overlay={
          <ul>
            {lists.map(l => (
              <li key={l.id} className={classNames('tooltip-list-item', {archive: l.deletedAt})}>
                {l.title}
              </li>
            ))}
          </ul>
        }
        placement="left"
        trigger={['click']}
        visible={showTooltip}
      >
        <Button
          className={classNames('word-entry-lists-btn btn-ico', {alone, open: showTooltip})}
          onClick={toggleTooltip}
        >
          <Icon name="virc-note" />
        </Button>
      </Tooltip>
    );

  return (
    <Button className={classNames('word-entry-lists-btn btn-ico', {alone})} onClick={onClick}>
      {isLoading ? <Spinner size={14} /> : <Icon name="virc-note" />}
    </Button>
  );
});
