import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Helmet from 'react-helmet';

import {push} from 'store/router';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import {type DocumentFileV2} from 'routes/ClassRoom/components/FilesWorkSpace/documentsTab/actions/interface';
import {isMobileWebView} from 'helpers/browser';
import {WebviewPlayerProvider} from 'contexts/Webview/WebviewPlayerContext';
import {ImageCacheProvider} from 'components/XPlayer/contexts/imageCacheContext';
import {useDocumentInstance, useDocumentInstancePage, usePDFDocumentProxy} from 'containers/hooks';
import {
  useWebViewMessage,
  type WebViewMessageData,
  WebViewMessageType
} from 'hooks/webview/useWebViewMessage';
import {useMobilePainterAttrs} from 'components/Paint/hooks/useMobilePainterAttrs';
import {getMobileOutline} from 'containers/utils';

import {MobileDocumentInstancePage} from './MobileDocumentInstancePage';

const getUrl = (docInstanceId: number, pageNumber: number) => {
  return `/xplayer/document/${docInstanceId}/page/${pageNumber}`;
};

const prev = (docInstanceId: number, pageNumber: number) => {
  return getUrl(docInstanceId, Math.max(pageNumber - 1, 1));
};

const next = (docInstanceId: number, pageNumber: number, max: number = Number.MAX_SAFE_INTEGER) => {
  return getUrl(docInstanceId, Math.min(pageNumber + 1, max));
};

type RouteParams = {
  docInstanceId: string;
  page?: string;
};

export const StandalonePDFDocumentPlayer: React.FC = () => {
  const {docInstanceId, page = 1} = useParams<RouteParams>();
  const documentInstanceId = Number(docInstanceId);
  const pageNumber = Number(page);

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  const {
    documentInstance,
    isLoading: isDocumentLoading,
    isError: isDocumentError,
    reload: reloadDocument
  } = useDocumentInstance(documentInstanceId);

  const {
    pdfDocumentProxy,
    isLoading: isPDFDocumentProxyLoading,
    isError: isPDFDocumentProxyError,
    reload: reloadPDFDocumentProxy
  } = usePDFDocumentProxy(documentInstance?.url);

  const reload = useCallback(() => {
    reloadDocument();
    reloadPDFDocumentProxy();
  }, [reloadDocument, reloadPDFDocumentProxy]);

  const onMessage = useCallback(
    (message: WebViewMessageData) => {
      const {type} = message;

      switch (type) {
        case WebViewMessageType.Prev:
          return dispatch(push(prev(documentInstanceId, pageNumber)));

        case WebViewMessageType.Next:
          return dispatch(push(next(documentInstanceId, pageNumber)));

        case WebViewMessageType.SetPage:
          const {payload} = message;

          return dispatch(push(getUrl(documentInstanceId, payload.pageNumber)));

        case WebViewMessageType.Refetch:
          return reload();

        default:
          return;
      }
    },
    [dispatch, reload, documentInstanceId, pageNumber]
  );

  const onLoading = useCallback((loading: boolean) => {
    if (!loading) {
      setLoaded(true);
    }
  }, []);

  const postMessage = useWebViewMessage(onMessage);

  useEffect(() => {
    if (loaded && documentInstance && pdfDocumentProxy) {
      const url = documentInstance.url!;
      const pageCount = pdfDocumentProxy.numPages;

      getMobileOutline(pdfDocumentProxy).then(outline => {
        postMessage({type: WebViewMessageType.Ready, payload: {url, pageCount, outline}});
      });
    }
  }, [postMessage, loaded, documentInstance, pdfDocumentProxy]);

  const loading = isDocumentLoading || isPDFDocumentProxyLoading;
  const isError = isDocumentError || isPDFDocumentProxyError;

  return (
    <div className="standalone-player">
      {isMobileWebView() && (
        <Helmet
          bodyAttributes={{class: 'x-player-mobile-webview'}}
          meta={[{name: 'viewport', content: 'initial-scale=1.0, maximum-scale=1.0'}]}
        />
      )}
      {isError ? (
        <WampErrorMask reload={reload} />
      ) : loading ? (
        <Loader />
      ) : (
        <WebviewPlayerProvider>
          <ImageCacheProvider>
            <DocumentInstancePage
              documentInstance={documentInstance!}
              pageNumber={pageNumber}
              onLoading={onLoading}
            />
          </ImageCacheProvider>
        </WebviewPlayerProvider>
      )}
    </div>
  );
};

interface HomeworkDocumentPageProps {
  documentInstance: DocumentFileV2;
  pageNumber: number;
  onLoading: (loading: boolean) => void;
}

const DocumentInstancePage: React.FC<HomeworkDocumentPageProps> = React.memo(
  ({documentInstance, pageNumber, onLoading}) => {
    const {id, url} = documentInstance;

    const {
      attrs,
      isError: isAttrsError,
      reload: reloadAttrs
    } = useMobilePainterAttrs(url, pageNumber);

    const {
      docInstancePage,
      isError: isPageError,
      reload: reloadPage
    } = useDocumentInstancePage(id, pageNumber, attrs?.size);

    const isError = isAttrsError || isPageError;

    const reload = useCallback(() => {
      reloadPage();
      reloadAttrs();
    }, [reloadPage, reloadAttrs]);

    if (isError) {
      return <WampErrorMask reload={reload} />;
    }

    if (!attrs || !docInstancePage) {
      return <Loader />;
    }

    return (
      <MobileDocumentInstancePage
        attrs={attrs}
        docInstancePage={docInstancePage}
        onLoading={onLoading}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.documentInstance === nextProps.documentInstance &&
      prevProps.pageNumber === nextProps.pageNumber
    );
  }
);
