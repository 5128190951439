import React, {type FC} from 'react';
import classNames from 'classnames';

import {OfferType, SearchOffer} from 'components/Dictionary/components/Search-Offer/SearchOffer';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';

import './WordsList.scss';

interface Props {
  shouldHide?: boolean;
  isSidebar?: boolean;
  numberOfWords: number;
  isLoading: boolean;
}

export const WordsList: FC<Props> = React.memo(
  ({children, shouldHide, isSidebar, numberOfWords, isLoading}) => {
    const {isSearching} = useSearchingInODContext();
    const isEmpty = numberOfWords === 0;

    return (
      <div
        className={classNames('words-list', {
          'should-hide': shouldHide,
          searching: isSearching && !isLoading
        })}
      >
        {children}
        {isEmpty && isSearching && !isLoading && (
          <SearchOffer
            className={classNames({sidebar: isSidebar, 'is-empty': isEmpty})}
            offerType={isEmpty ? OfferType.COULD_NOT_BE_FOUND : OfferType.ADDITIONAL_SEARCH}
          />
        )}
      </div>
    );
  }
);
