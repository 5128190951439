import genKey from 'components/Slate/utils/genKey';
import {type ImageMatchingCardJSON} from 'store/exercise/player/widgets/ImageMatching/interface';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';

import {type XImageMatchingCardProperties, type XImageMatchingCardProps} from './interface';
import {CardSizeType} from '../../XWordPictureSet/XPictureSet/interface';

const Record = recordBase()(BaseRecord);

class XImageMatchingCardRecord extends Record implements XImageMatchingCardProperties {
  public declare readonly id: string;

  public declare readonly choiceId: string;

  public declare readonly phrase: string;

  public declare readonly imageId: number;

  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: Partial<XImageMatchingCardProps> = {}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      choiceId: raw.choiceId || genKey(),
      phrase: raw.phrase || '',
      imageId: raw.imageId || '',
      cardSizeType: raw.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toCardJSON(): ImageMatchingCardJSON {
    return {
      id: this.id,
      imageId: this.imageId,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(XImageMatchingCardRecord, {
  id: property(genKey()),
  choiceId: property(genKey()),
  phrase: property(''),
  imageId: property(''),
  cardSizeType: property('')
});
record()(XImageMatchingCardRecord);
export default XImageMatchingCardRecord;
