import {FileExtension} from 'common/enums';

export function fileIcon(fileExt: FileExtension | string): string {
  switch (fileExt) {
    case FileExtension.mp3:
      return 'virc-audio';
    case FileExtension.pdf:
      return 'file-pdf-o';
    default:
      return 'file-o';
  }
}

export function getImageSize(file: File): Promise<{height: number; width: number}> {
  const fileDataURL = window.URL.createObjectURL(file);

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      });
    };

    img.onerror = error => {
      reject(error);
    };

    img.src = fileDataURL;
  });
}
