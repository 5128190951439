import {Map, List, fromJS} from 'immutable';
import {type Editor} from '@englex/slate';
import {type IntlShape} from 'react-intl';
import * as yup from 'yup';

import {genKey, valueFromText} from 'components/Slate/utils';
import {type DataMap} from 'components/Slate/interface';
import {
  type MediaMeta,
  WidgetMediaType,
  WidgetTitle,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {
  type PictureChoiceCardJSON,
  type PictureChoiceJSON,
  type PictureChoiceQuestionJSON,
  type PictureChoiceQuestionProperties
} from 'store/exercise/player/widgets/PictureChoice/interface';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {PictureChoiceCardRecord} from 'store/exercise/player/widgets/PictureChoice/PictureChoiceCardRecord';
import {isDocumentEmpty} from 'components/Slate/utils/documentNotEmpty';

import {PictureChoiceQuestionRecord} from '../../../player/widgets/PictureChoice/PictureChoiceQuestionRecord';
import {pictureChoiceAnswersNumberDefault, pictureChoiceAnswersNumberMin} from '../constants';
import {ValidationWidgetError} from '../customErrors';
import {documentNotEmpty} from '../validation';
import validationMessages from '../i18n';
import XWidgetRecord from '../XWidgetRecord';
import {
  type PictureChoiceAnswers,
  PictureChoiceAnswerType,
  PictureChoiceMode,
  PictureChoiceQuestionsMode,
  type XPictureChoiceOptions,
  type XPictureChoiceProperties,
  type XPictureChoiceProps
} from './interface';
import {CardSizeType} from '../XWordPictureSet/XPictureSet/interface';
import {getObjectWithNewIds} from '../getObjectWithNewIds';

export class XPictureChoiceRecord
  extends XWidgetRecord<XPictureChoiceProps, DataMap<XPictureChoiceOptions>>
  implements XPictureChoiceProperties
{
  public declare readonly mode: PictureChoiceMode;
  public declare readonly questionsMode: PictureChoiceQuestionsMode;
  public declare readonly answerType: PictureChoiceAnswerType;
  public declare readonly questions: List<PictureChoiceQuestionProperties>;
  public declare readonly answers: PictureChoiceAnswers;

  private static createQuestion(answersNumber: number, questionsMode: PictureChoiceQuestionsMode) {
    const data = {
      id: genKey(),
      content: questionsMode === PictureChoiceQuestionsMode.Default ? valueFromText('') : undefined,
      cards: [...Array(answersNumber)].map(() => {
        return {
          id: genKey(),
          imageId: null,
          cardSizeType: CardSizeType.SQUARE
        };
      })
    };

    return new PictureChoiceQuestionRecord(data);
  }

  public static createQuestions(
    questionsNumber: number,
    answersNumber: number,
    questionsMode: PictureChoiceQuestionsMode
  ) {
    return List(
      [...Array(questionsNumber)].map(() =>
        XPictureChoiceRecord.createQuestion(answersNumber, questionsMode)
      )
    );
  }

  constructor(raw: XPictureChoiceProps) {
    super(raw);
    const {options: {defaultAnswersNumber = pictureChoiceAnswersNumberDefault} = {}} = raw;

    this.initValues({
      options: Map({defaultAnswersNumber}),
      mode: raw.mode,
      questionsMode: raw.questionsMode ? raw.questionsMode : PictureChoiceQuestionsMode.Default,
      answerType: this.getAnswerType(raw.answerType, raw.mode, raw.questionsMode),
      questions: this.checkQuestions(raw),
      answers: fromJS(raw.answers)
    });
  }

  private checkQuestions(raw: XPictureChoiceProps) {
    if (List.isList(raw.questions)) {
      return raw.questions;
    }
    return List(
      (raw.questions as PictureChoiceQuestionJSON[]).map((question: PictureChoiceQuestionJSON) => {
        return new PictureChoiceQuestionRecord({
          id: question.id,
          content: question.content,
          cards: List(
            question.cards.map((card: PictureChoiceCardJSON) => new PictureChoiceCardRecord(card))
          )
        });
      })
    );
  }

  public get type() {
    return WidgetType.PICTURE_CHOICE;
  }

  public get title(): string {
    return WidgetTitle[this.type];
  }

  public toJSON(options?: WidgetToJSONOptions): PictureChoiceJSON {
    const {questions, answers} = this.getQuestionsAndAnswers(options);

    return {
      ...super.toJSON(options),
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      options: this.options.toJS(),
      mode: this.mode,
      answerType: this.answerType,
      questions,
      answers,
      questionsMode: this.questionsMode
    };
  }

  private getQuestionsAndAnswers(options?: WidgetToJSONOptions) {
    const questions = this.questions
      .map((question: PictureChoiceQuestionProperties) => question.toJSON())
      .toArray();
    const answers = this.answers.toJS();

    if (options?.generateIdentifiers && this.mode === PictureChoiceMode.MULTIPLE_CHOICE) {
      const objWithNewIds = getObjectWithNewIds({questions, answers});

      return {questions: objWithNewIds.questions, answers: objWithNewIds.answers};
    }

    return {questions, answers};
  }

  private getAnswerType(
    answerType: PictureChoiceAnswerType,
    mode: PictureChoiceMode,
    questionsMode?: PictureChoiceQuestionsMode
  ) {
    if (mode === PictureChoiceMode.FREE_CHOICE) return PictureChoiceAnswerType.Multiple;
    if (questionsMode === PictureChoiceQuestionsMode.WithoutQuestionsTitle)
      return PictureChoiceAnswerType.One;

    return answerType;
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    const answers = this.questions.map(
      (question: PictureChoiceQuestionProperties, index: number) => {
        const images = [...Array(question.cards.size)].map(item => 'Image').join(' / ');

        if (this.questionsMode === PictureChoiceQuestionsMode.WithoutQuestionsTitle)
          return `${index + 1}. [${images}]`;

        const title = question.content ? question.content.document.text : '';

        return `${index + 1}. ${title} [${images}]`;
      }
    );

    return `${task} ${answers.join(' ')}`;
  }

  public get media(): MediaMeta | undefined {
    const images = this.questions
      .reduce((list: List<number | null>, question: PictureChoiceQuestionProperties) => {
        return list.concat(question.cards.map((card: PictureChoiceCardRecord) => card?.imageId));
      }, List<number | null>())
      .toSet();

    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }

  public schema(intl: IntlShape): yup.AnyObjectSchema {
    return yup.object({
      questions: yup
        .mixed()
        .test({
          name: 'Each header must be filled in',
          test: (questions: List<PictureChoiceQuestionProperties>) => {
            if (this.questionsMode === PictureChoiceQuestionsMode.WithoutQuestionsTitle)
              return true;

            const withError = questions.filter((question: PictureChoiceQuestionRecord) =>
              isDocumentEmpty(question.content!.document)
            );

            if (withError.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.QuestionsNonEmpty),
                this,
                withError.reduce(
                  (props, question: PictureChoiceQuestionProperties) => ({
                    ...props,
                    [question.id]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test({
          name: 'Each picture must be uploaded',
          test: (questions: List<PictureChoiceQuestionProperties>) => {
            const withError: PictureChoiceCardRecord[] = [];

            questions.forEach((question: PictureChoiceQuestionProperties) => {
              question.cards.forEach((card: PictureChoiceCardRecord) => {
                if (!card.imageId) withError.push(card);
              });
            });

            const immutableWithError = List(withError);

            if (immutableWithError.size) {
              return new ValidationWidgetError(
                intl.formatMessage(validationMessages.BlankImages),
                this,
                immutableWithError.reduce(
                  (props, card: PictureChoiceCardRecord) => ({
                    ...props,
                    [card.id]: {empty: true}
                  }),
                  {}
                )
              );
            }

            return true;
          }
        })
        .test(
          'Cards count should be valid',
          intl.formatMessage(validationMessages.Min2ImagesCount),
          (questions: List<PictureChoiceQuestionProperties>) =>
            questions.every(
              (question: PictureChoiceQuestionProperties) =>
                question.cards.size >= pictureChoiceAnswersNumberMin
            )
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.CorrectAnswersSelected),
          (questions: List<PictureChoiceQuestionProperties>) => {
            if (this.mode === PictureChoiceMode.FREE_CHOICE) return true;

            return this.answers.size === questions.size;
          }
        )
    });
  }

  // Actions

  public deleteQuestion(questionId: string) {
    const questionIndex = this.questions.findIndex(question => question!.id === questionId);
    const questions = this.questions.delete(questionIndex);

    return this.deleteAnswer(questionId).set('questions', questions);
  }

  public moveQuestion(from: number, to: number) {
    const source = this.questions.get(from);
    const destination = this.questions.get(to);
    const questions = this.questions.set(to, source).set(from, destination);

    return this.set('questions', questions);
  }

  public addEmptyQuestion() {
    const defaultAnswersNumber = this.options.get('defaultAnswersNumber');
    const question = XPictureChoiceRecord.createQuestion(defaultAnswersNumber, this.questionsMode);
    const questions = this.questions.push(question);

    return this.set('questions', questions);
  }

  public changeQuestionContent(questionIndex: number, change: Editor) {
    return this.setIn(['questions', questionIndex, 'content'], change.value);
  }

  public addEmptyCard(questionIndex: number) {
    const cards = this.getIn(['questions', questionIndex, 'cards']).push(
      new PictureChoiceCardRecord({} as PictureChoiceCardJSON)
    );

    return this.setIn(['questions', questionIndex, 'cards'], cards);
  }

  public setCardImage(questionIndex: number, cardIndex: number, imageId: number) {
    return this.setIn(['questions', questionIndex, 'cards', cardIndex, 'imageId'], imageId);
  }

  public setCardSizeType(questionIndex: number, cardIndex: number, cardSizeType: CardSizeType) {
    return this.setIn(
      ['questions', questionIndex, 'cards', cardIndex, 'cardSizeType'],
      cardSizeType
    );
  }

  public deleteCard(questionId: string, cardId: string) {
    const questionIndex = this.questions.findIndex(question => question!.id === questionId);
    const cardIndex = this.questions
      .getIn([questionIndex, 'cards'])
      .findIndex((card: PictureChoiceCardRecord) => card.id === cardId);
    const cards = this.getIn(['questions', questionIndex, 'cards']).delete(cardIndex);

    return this.filterAnswer(questionId, cardId).setIn(
      ['questions', questionIndex, 'cards'],
      cards
    );
  }

  public toggleAnswer(questionId: string, cardId: string) {
    const hasAnswer = this.checkAnswer(questionId, cardId);

    return hasAnswer ? this.filterAnswer(questionId, cardId) : this.addAnswer(questionId, cardId);
  }

  private addAnswer(questionId: string, cardId: string) {
    if (this.answerType === PictureChoiceAnswerType.One) {
      const answers: PictureChoiceAnswers = this.get('answers').set(questionId, List([cardId]));

      return this.set('answers', answers);
    }

    const answersList = this.getIn(['answers', questionId], List()).push(cardId);

    return this.setIn(['answers', questionId], answersList);
  }

  private filterAnswer(questionId: string, cardId: string) {
    const answersList: List<string> = this.getIn(['answers', questionId], List()).filter(
      (card: string) => card !== cardId
    );
    const hasEmptyList = !answersList.size;

    if (hasEmptyList) {
      const answers = this.get('answers').delete(questionId);

      return this.set('answers', answers);
    }

    return this.setIn(['answers', questionId], answersList);
  }

  private deleteAnswer(questionId: string) {
    const answers = this.get('answers').delete(questionId);

    return this.set('answers', answers);
  }

  public moveCard(questionId: string, moveCardIndex: number, targetCardIndex: number) {
    const questionIndex = this.questions.findIndex(
      (question: PictureChoiceQuestionProperties) => question.id === questionId
    );
    const movedCard = this.questions.get(questionIndex).cards.get(moveCardIndex);

    const newCards = this.questions
      .get(questionIndex)
      .cards.delete(moveCardIndex)
      .insert(targetCardIndex, movedCard);

    return this.setIn(['questions', questionIndex, 'cards'], newCards);
  }

  public rollBackCards(questions: List<PictureChoiceQuestionProperties>) {
    return this.set('questions', questions);
  }

  private checkAnswer(questionId: string, cardId: string) {
    const answers: boolean = !!this.getIn(['answers', questionId], List()).find(
      (card: string) => card === cardId
    );

    return answers;
  }
}

decorate(XPictureChoiceRecord, {
  mode: property(PictureChoiceMode.MULTIPLE_CHOICE),
  questionsMode: property(PictureChoiceQuestionsMode.Default),
  answerType: property(PictureChoiceAnswerType.One),
  questions: property(undefined),
  answers: property(undefined)
});
record()(XPictureChoiceRecord);
