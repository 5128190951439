import React, {type FC, useCallback, memo} from 'react';
import {useIntl} from 'react-intl';

import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import EditTitleModal from 'components/modals/EditTitleModal/EditTitleModal';
import * as toastr from 'components/toastr';
import {
  addEntriesToListRequest,
  createWordsListRequest,
  renameWordsListRequest
} from 'store/dictionary/requests';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import {type Role} from 'store/interface';

import {listTitleMaxLength} from '../static';
import {type DictionaryOwnerRole} from '../contexts';

type Callback = (entryId: string) => void;

interface Props {
  addEntriesErrorMessage: string;
  addEntriesSuccessMessage: string;
  editedList?: DictionaryList;
  entryIds?: string[];
  listToDelete?: DictionaryList;
  show: boolean;
  dictionaryOwnerId: number;
  dictionaryOwnerRole: DictionaryOwnerRole;
  hide(success?: boolean): void;
  submit?(callback: Callback): void;
}

export const EditListModal: FC<Props> = memo(
  ({
    addEntriesErrorMessage,
    addEntriesSuccessMessage,
    editedList,
    entryIds,
    listToDelete,
    show,
    dictionaryOwnerId,
    dictionaryOwnerRole,
    hide,
    submit
  }) => {
    const intl = useIntl();

    const {isLoading: addingEntriesToList, apiRequest: addEntriesToList} = useApiRequest2<
      [number, DictionaryOwnerRole, string[], string]
    >(
      addEntriesToListRequest,
      () => {
        toastr.success('', addEntriesSuccessMessage);
        hide(true);
      },
      () => {
        toastr.error('', addEntriesErrorMessage);
        hide();
      }
    );

    const {isLoading: isRenaming, apiRequest: renameWordsList} = useApiRequest2<
      [string, string],
      DictionaryList
    >(
      renameWordsListRequest,
      () => toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.EditListSuccess'})),
      () => toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.EditListError'})),
      () => hide(true)
    );

    const {isLoading: isCreating, apiRequest: createWordsList} = useApiRequest2<
      [number, Role, string],
      DictionaryList
    >(
      createWordsListRequest,
      list => {
        if (submit) {
          submit(entryId =>
            addEntriesToList(dictionaryOwnerId, dictionaryOwnerRole, [entryId], list.id)
          );
        } else if (entryIds) {
          addEntriesToList(dictionaryOwnerId, dictionaryOwnerRole, entryIds, list.id);
        } else {
          toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.CreateListSuccess'}));
        }

        if (!entryIds) {
          hide(true);
        }
      },
      () => {
        toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.CreateListError'}));
        hide();
      }
    );

    const handleSubmit = useCallback(
      (title: string) =>
        editedList
          ? renameWordsList(title, editedList.id)
          : createWordsList(dictionaryOwnerId, dictionaryOwnerRole, title),
      [createWordsList, dictionaryOwnerId, dictionaryOwnerRole, editedList, renameWordsList]
    );

    const submitting = isCreating || isRenaming || addingEntriesToList;

    return (
      <EditTitleModal
        onHide={hide}
        show={show && !listToDelete}
        submitting={submitting}
        handleSubmit={handleSubmit}
        label={intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameModal.Label'})}
        placeholder={intl.formatMessage({id: 'ServerCoursebook.UnitsList.RenameModal.Placeholder'})}
        maxLength={listTitleMaxLength}
        maxLengthErrorMessage={intl.formatMessage(
          {
            id: 'ServerCoursebook.UnitsList.EditModal.Validation.Max'
          },
          {count: listTitleMaxLength}
        )}
        requiredErrorMessage={intl.formatMessage({
          id: 'ServerCoursebook.UnitsList.EditModal.Validation.Required'
        })}
        editedTitle={editedList ? editedList.title : undefined}
        submitTitle={editedList ? undefined : intl.formatMessage({id: 'Common.Create'})}
        title={
          editedList
            ? intl.formatMessage({id: 'Dictionary.RenameWordList'})
            : intl.formatMessage({id: 'Dictionary.CreateWordList'})
        }
      />
    );
  }
);
