import 'focus-options-polyfill';
import 'fastestsmallesttextencoderdecoder-encodeinto';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/ru';
import 'intl-pluralrules';
import 'config/debug';
import 'config/sentry';
import 'config/disableReactDevTools';

import ReactDOM from 'react-dom';

import {App} from 'containers/App';

import {createStoreAsync} from './store';

import 'styles/main.scss';

const root = document.getElementById('root');

createStoreAsync().then(store => ReactDOM.render(<App store={store} />, root));
