import {type Action} from 'redux';

import {type StudentTeachers} from '../interface';
import {SET_SELECTED_PARTNER, SET_STUDENT_TEACHERS} from './actionTypes';

export interface SetStudentTeachersAction extends Action {
  studentTeachers: StudentTeachers;
}
export const setStudentTeachers = (studentTeachers: StudentTeachers): SetStudentTeachersAction => ({
  type: SET_STUDENT_TEACHERS,
  studentTeachers
});

export interface SetSelectedStudentTeacherAction extends Action {
  id: number;
  isStudent: boolean;
}
export const setSelectedStudentTeacher = (
  id: number,
  isStudent: boolean
): SetSelectedStudentTeacherAction => ({
  type: SET_SELECTED_PARTNER,
  id,
  isStudent
});
