import {type FC, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {logout} from 'authentication/actions/action';

export const LogoutRoute: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  }, [dispatch]);
  return null;
};
