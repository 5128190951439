import {type UploadingFiles, type UploadsState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  type AddFileToUploadsAction,
  type BlockUploadPanelAction,
  type FileUploadedAction,
  type GetRecentFilesSuccessAction,
  type RemoveFileFromUploadsAction,
  type UploadingAction,
  type UploadingFailedAction,
  type UploadingProgressAction
} from '../actions/interface';
import {
  ADD_FILE_TO_UPLOADS_LIST,
  BLOCK_UPLOAD_PANEL,
  FILE_UPLOADED,
  GET_RECENT_DOCUMENTS,
  GET_RECENT_DOCUMENTS_FAIL,
  GET_RECENT_DOCUMENTS_SUCCESS,
  GET_RECENT_SOUNDS,
  GET_RECENT_SOUNDS_FAIL,
  GET_RECENT_SOUNDS_SUCCESS,
  REMOVE_FILE_FROM_UPLOADS_LIST,
  UPLOADING_FAILED,
  UPLOADING_PROGRESS,
  UPLOADS_RESET
} from '../actions/actionTypes';
import {type Sound} from '../../soundsTab/actions/interface';
import {type DocumentFile} from '../../documentsTab/actions/interface';
import {DELETE_SOUND_SUCCESS, REQUEST_SOUNDS_SUCCESS} from '../../soundsTab/actions/actionTypes';
import {type FileDeleteSuccessAction} from '../../../../actions/interface';
import {
  DELETE_DOCUMENT_SUCCESS,
  REQUEST_DOCUMENTS_SUCCESS
} from '../../documentsTab/actions/actionTypes';

function getFilesObjNameByFileId(
  fileId: string,
  uploadingSounds: UploadingFiles,
  uploadingDocuments: UploadingFiles
) {
  if (Object.keys(uploadingSounds).find(key => key === String(fileId))) {
    return 'uploadingSounds';
  }
  if (Object.keys(uploadingDocuments).find(key => key === String(fileId))) {
    return 'uploadingDocuments';
  }
  return null;
}

const initialUploadsState: UploadsState = {
  uploadingSounds: {},
  uploadingDocuments: {},
  recentDocuments: {},
  recentSounds: {}
};

const ACTION_HANDLERS: ActionHandlersList<UploadsState, UploadingAction> = {
  [ADD_FILE_TO_UPLOADS_LIST]: (state: UploadsState, action: AddFileToUploadsAction) => {
    const newState: UploadsState = {...state};
    if (action.file.uploadingStatus === 'uploading') {
      action.file.percentsUploaded = 0;
    }
    const filesObj = action.file.fileType === 'audio' ? 'uploadingSounds' : 'uploadingDocuments';
    newState[filesObj] = {
      ...state[filesObj],
      [action.file.id]: action.file
    };
    return newState;
  },
  [BLOCK_UPLOAD_PANEL]: (state: UploadsState, action: BlockUploadPanelAction) => {
    const newState: UploadsState = {...state};
    if (action.fileType === 'audio') {
      newState.blockSoundsUploading = action.blocked ? true : undefined;
    } else {
      newState.blockDocsUploading = action.blocked ? true : undefined;
    }
    return newState;
  },
  [REMOVE_FILE_FROM_UPLOADS_LIST]: (state: UploadsState, action: RemoveFileFromUploadsAction) => {
    const newState: UploadsState = {...state};
    const uploadingFiles = Object.assign({}, state.uploadingDocuments, state.uploadingSounds);
    for (const id in uploadingFiles) {
      if (uploadingFiles[id].md5 === action.md5 && (!action.id || id === String(action.id))) {
        const filesObj =
          uploadingFiles[id].fileType === 'audio' ? 'uploadingSounds' : 'uploadingDocuments';
        newState[filesObj] = {...state[filesObj]};
        delete newState[filesObj][id];
      }
    }
    return newState;
  },
  [UPLOADING_PROGRESS]: (state: UploadsState, action: UploadingProgressAction) => {
    const filesObj = getFilesObjNameByFileId(
      action.fileId,
      state.uploadingSounds,
      state.uploadingDocuments
    );
    if (!filesObj || state[filesObj][action.fileId].uploadingStatus !== 'uploading') {
      return state;
    }
    const newState: UploadsState = {
      ...state,
      [filesObj]: {
        ...state[filesObj],
        [action.fileId]: {
          ...state[filesObj][action.fileId]
        }
      }
    };
    if (action.percents === 100) {
      newState[filesObj][action.fileId].uploadingStatus = 'converting';
      delete newState[filesObj][action.fileId].percentsUploaded;
    } else {
      newState[filesObj][action.fileId].percentsUploaded = action.percents;
    }
    return newState;
  },
  [UPLOADING_FAILED]: (state: UploadsState, action: UploadingFailedAction) => {
    const filesObj = getFilesObjNameByFileId(
      action.id,
      state.uploadingSounds,
      state.uploadingDocuments
    );
    if (!filesObj) {
      return state;
    }
    const newState: UploadsState = {
      ...state,
      [filesObj]: {...state[filesObj]}
    };
    newState[filesObj][action.id] = {
      ...state[filesObj][action.id],
      uploadingStatus: 'fail',
      errorMessage: action.errorText
    };
    return newState;
  },
  [GET_RECENT_SOUNDS]: (state: UploadsState) => ({
    ...state,
    loadingRecentSounds: true
  }),
  [GET_RECENT_DOCUMENTS]: (state: UploadsState) => ({
    ...state,
    loadingRecentDocuments: true
  }),
  [GET_RECENT_SOUNDS_SUCCESS]: (state: UploadsState, action: GetRecentFilesSuccessAction) => {
    const {loadingRecentSounds, ...newState} = state;
    newState.recentSounds = state.recentSounds ? {...state.recentSounds} : {};
    newState.recentSoundsUploaded = true;
    action.payload.data.forEach((sound: Sound) => {
      newState.recentSounds[sound.id] = sound;
      newState.recentSounds[sound.id].fileType = 'audio';
    });
    return newState;
  },
  [GET_RECENT_DOCUMENTS_SUCCESS]: (state: UploadsState, action: GetRecentFilesSuccessAction) => {
    const {loadingRecentDocuments, ...newState} = state;
    newState.recentDocuments = state.recentDocuments ? {...state.recentDocuments} : {};
    newState.recentDocumentsUploaded = true;
    action.payload.data.forEach((document: DocumentFile) => {
      newState.recentDocuments[document.id] = document;
      newState.recentDocuments[document.id].fileType = 'document';
    });
    return newState;
  },
  [GET_RECENT_SOUNDS_FAIL]: (state: UploadsState) => {
    const {loadingRecentSounds, ...newState} = state;
    newState.recentSoundsUploaded = true;
    newState.networkError = true;
    return newState;
  },
  [GET_RECENT_DOCUMENTS_FAIL]: (state: UploadsState) => {
    const {loadingRecentDocuments, ...newState} = state;
    newState.recentDocumentsUploaded = true;
    newState.networkError = true;
    return newState;
  },
  [UPLOADS_RESET]: () => ({
    ...initialUploadsState
  }),
  [DELETE_SOUND_SUCCESS]: (state: UploadsState, action: FileDeleteSuccessAction) => {
    const newState: UploadsState = {
      ...state,
      recentSounds: {...state.recentSounds}
    };
    const id: string = action.meta.previousAction.payload.request.url!.match(/\d+$/)![0];
    if (newState.recentSounds[id]) {
      delete newState.recentSounds[id];
    }
    return newState;
  },
  [DELETE_DOCUMENT_SUCCESS]: (state: UploadsState, action: FileDeleteSuccessAction) => {
    const newState: UploadsState = {
      ...state,
      recentDocuments: {...state.recentDocuments}
    };
    const id: string = action.meta.previousAction.payload.request.url!.match(/\d+$/)![0];
    if (newState.recentDocuments[id]) {
      delete newState.recentDocuments[id];
    }
    return newState;
  },
  [FILE_UPLOADED]: (state: UploadsState, action: FileUploadedAction) => {
    const filesObj = getFilesObjNameByFileId(
      action.id,
      state.uploadingSounds,
      state.uploadingDocuments
    );
    if (!filesObj) {
      return state;
    }
    const newState: UploadsState = {
      ...state,
      [filesObj]: {...state[filesObj]}
    };
    newState[filesObj][action.id] = {
      ...newState[filesObj][action.id],
      uploadingStatus: 'uploaded'
    };
    return newState;
  },
  [REQUEST_DOCUMENTS_SUCCESS]: (state: UploadsState) => {
    const newState: UploadsState = {
      ...state
    };
    for (const id in newState.uploadingDocuments) {
      if (newState.uploadingDocuments[id].uploadingStatus === 'uploaded') {
        newState.uploadingDocuments = {...newState.uploadingDocuments};
        delete newState.uploadingDocuments[id];
      }
    }
    return newState;
  },
  [REQUEST_SOUNDS_SUCCESS]: (state: UploadsState) => {
    const newState: UploadsState = {
      ...state
    };
    for (const id in newState.uploadingSounds) {
      if (newState.uploadingSounds[id].uploadingStatus === 'uploaded') {
        newState.uploadingSounds = {...newState.uploadingSounds};
        delete newState.uploadingSounds[id];
      }
    }
    return newState;
  }
};

export default function (
  state: UploadsState = initialUploadsState,
  action: UploadingAction
): UploadsState {
  const uploadsReducer = ACTION_HANDLERS[action.type];
  return uploadsReducer ? uploadsReducer(state, action) : state;
}
