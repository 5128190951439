import {type Reducer} from 'redux';
import {OrderedMap} from 'immutable';

import {type ExerciseProperties} from './Exercise/interface';
import type ExerciseRecord from './Exercise/ExerciseRecord';
import {type ExerciseRecordMap} from './interface';
import {xplayerExerciseReducer} from './Exercise/reducer';

export const createExercisesReducer =
  (exerciseReducer: Reducer<ExerciseProperties>): Reducer<ExerciseRecordMap> =>
  (state, action) => {
    if (!state) {
      return OrderedMap<string, ExerciseProperties>();
    }
    let returnSame = true;
    const updatedExercises = state.map((e: ExerciseRecord) => {
      const updated = exerciseReducer(e, action);
      if (updated !== e) returnSame = false;
      return updated;
    });
    return returnSame ? state : updatedExercises.toOrderedMap();
  };

export const xplayerExercisesReducer = createExercisesReducer(xplayerExerciseReducer);
