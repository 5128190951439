import React from 'react';

const ReconnectingStatus = () => (
  <svg
    width="8px"
    height="8px"
    viewBox="0 0 8 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>reconnect</title>
    <desc>Created with Sketch.</desc>
    <g id="elements" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="status" transform="translate(-100.000000, -181.000000)" fill="#FFB401">
        <g id="Group-62" transform="translate(56.000000, 66.000000)">
          <path
            d="M45.3481766,116.006907 L46.1745423,117.438214 C45.8156811,117.85798 45.6,118.403584 45.6,119 C45.6,120.334724 46.6745166,121.4 48,121.4
           C48.1501236,121.4 48.2968385,121.386407 48.4390123,121.360392 L49.2670458,122.794588 C48.8686674,122.927828 48.4425809,123 48,123 C45.790861,123
            44,121.204644 44,119 C44,117.809055 44.5217807,116.739673 45.3481766,116.006907 Z M46.7329542,115.205412 C47.1313326,115.072172 47.5574191,115 
            48,115 C50.209139,115 52,116.795356 52,119 C52,120.190945 51.4782193,121.260327 50.6518234,121.993093 L49.8254577,120.561786 C50.1843189,120.14202 
            50.4,119.596416 50.4,119 C50.4,117.665276 49.3254834,116.6 48,116.6 C47.8498764,116.6 47.7031615,116.613593 47.5609877,116.639608 
            L46.7329542,115.205412 Z"
            id="reconnect"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default ReconnectingStatus;
