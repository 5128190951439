import {
  type Annotation,
  type Block,
  type Document,
  type Editor,
  type Path,
  Point,
  Range,
  type Value
} from '@englex/slate';
import {type List} from 'immutable';

import {wordSplitter} from 'config/static';
import {getSurroundOffsets, isSurroundedByChar} from 'components/Slate/plugins/DecorateText';

import {SlateAnnotation} from '../../../interface';

const getTextAtRange = (document: Document, r: Range) =>
  document.getTextsAtRange(r).first().text.substring(r.start.offset, r.end.offset);

const extractLi = (value: Value) => {
  const {document, selection} = value;

  if (!selection?.start.key) {
    return null;
  }

  return (document.nodes.first() as Block).nodes.find(
    (li: Block) => li.getFirstText()!.key === selection?.start.key
  ) as Block;
};

const extractLiAnnotations = (value: Value, li: Block | null) => {
  const {annotations, document} = value;

  if (!li) {
    return null;
  }

  const liPath = document.getPath(li.key);

  const liAnnotations = annotations.filter((a: Annotation) =>
    (a.anchor.path as List<number>).slice(0, 2).equals(liPath as List<number>)
  );

  if (!liAnnotations.size) {
    return null;
  }

  return liAnnotations;
};

export const addAnnotation = (
  editor: Editor,
  path: Path,
  start: number,
  end: number,
  keyGen: () => string
) => {
  const {document} = editor.value;
  const annotation = document.createAnnotation({
    type: SlateAnnotation.PARSED_WORD,
    key: keyGen(),
    anchor: Point.create({path: path as number[], offset: start}),
    focus: Point.create({path: path as number[], offset: end})
  });
  editor.addAnnotation(annotation);
};

export const reannotate = (editor: Editor, keyGen: () => string) => {
  const {document} = editor.value;
  const li = extractLi(editor.value);
  const liAnnotations = extractLiAnnotations(editor.value, li);

  if (!liAnnotations) {
    return editor;
  }

  const {text} = li!.getTexts().first();

  let prevAnnotationFocusOffset: number | undefined;
  editor.withoutSaving(() => {
    liAnnotations
      .sort((a1: Annotation, a2: Annotation) => a1.anchor.offset - a2.anchor.offset)
      .forEach((a: Annotation) => {
        const annotatedText = getTextAtRange(document, Range.fromJSON(a.toJSON()));
        const {
          anchor: {path, offset: anchorOffset},
          focus: {offset: focusOffset}
        } = a;

        // check if annotation lost all capital letters or received splitters -> remove annotation then
        const wsMatch = annotatedText.match(wordSplitter);
        if (wsMatch) {
          // annotation got broken by splitter. this branch tries to save annotation for 1st word.
          editor.removeAnnotation(a);
          const breakpoint = annotatedText.indexOf(wsMatch[0]);
          const head = annotatedText.slice(0, breakpoint);
          const tail = annotatedText.length - breakpoint;
          if (annotatedText.length > breakpoint && head !== head.toLowerCase()) {
            editor.command(addAnnotation, path!, anchorOffset, focusOffset - tail, () => a.key);
            prevAnnotationFocusOffset = anchorOffset + focusOffset - tail;
          }
        } else if (
          annotatedText === annotatedText.toLowerCase() ||
          (prevAnnotationFocusOffset && prevAnnotationFocusOffset > a.anchor.offset)
        ) {
          editor.removeAnnotation(a);
        } else {
          // check if annotation was merged with another word, i.e. lost splitters
          // between them -> extend boundaries then. Technique, used here is explained via comment in
          // FormatPainter plugin realization.
          const [before, after] = [text.slice(0, anchorOffset), text.slice(focusOffset)];
          const toRight = `${after}!`.search(wordSplitter);
          const toLeft = `!${before}`.split('').reverse().join('').search(wordSplitter);
          if ((toLeft !== 0 || toRight !== 0) && (toRight !== -1 || toLeft !== -1)) {
            editor.command(
              addAnnotation,
              path!,
              anchorOffset - toLeft,
              focusOffset + toRight,
              keyGen
            );
            editor.removeAnnotation(a);
          }
          prevAnnotationFocusOffset = anchorOffset + toRight;
        }
      });
  });

  return editor;
};

export const excludeSurroundingQuotes = (editor: Editor, keyGen: () => string) => {
  const {document} = editor.value;

  const li = extractLi(editor.value);
  const liAnnotations = extractLiAnnotations(editor.value, li);

  if (!liAnnotations) {
    return editor;
  }

  return editor.withoutSaving(() => {
    liAnnotations.forEach((a: Annotation) => {
      const annotatedText = getTextAtRange(document, Range.fromJSON(a.toJSON()));
      if (isSurroundedByChar(annotatedText)) {
        const [unshift, pop] = getSurroundOffsets(annotatedText);
        const {path} = a.anchor;
        editor.removeAnnotation(a);
        editor.addAnnotation(
          document.createAnnotation({
            type: SlateAnnotation.PARSED_WORD,
            key: a.key,
            anchor: Point.create({path, offset: a.anchor.offset + unshift}),
            focus: Point.create({path, offset: a.focus.offset - pop})
          })
        );
      }
    });
  });
};

export const resolveLiIds = (editor: Editor, keyGen: () => string) =>
  editor.withoutSaving(() => {
    const listItems = (editor.value.document.nodes.first() as Block).nodes;
    const ids: string[] = [];
    listItems.forEach((li: Block) => {
      let id = li.data.get('id');
      if (!id || ids.includes(id)) {
        id = keyGen();
        editor.setNodeByKey(li.key, {data: {id}});
      }
      ids.push(id);
    });
  });

export const toggleAnnotation = (
  editor: Editor,
  path: Path,
  offset: number,
  text: string,
  keyGen: () => string
) => {
  const {document} = editor.value;
  const li = document.getNode((path as List<number>).pop().pop()) as Block;
  const liPath = document.getPath(li.key);

  const {annotations} = editor.value;
  const annotation = annotations.find(
    (a: Annotation) =>
      a.anchor.offset === offset &&
      (a.anchor.path as List<number>).slice(0, 2).equals(liPath as List<number>)
  );

  return annotation
    ? editor.removeAnnotation(annotation)
    : editor.command(addAnnotation, path, offset, offset + text.length, keyGen);
};
