const audioPrefix = 'AUDIO/';

// todo: refactor names
export const ATTACH_AUDIO = audioPrefix + 'ATTACH_AUDIO';
export const ALLOW_AUDIO = audioPrefix + 'ALLOW_AUDIO';
export const CLEAR_AUDIO = audioPrefix + 'CLEAR_AUDIO';
export const CHANGE_ACTIVE_AUDIO = audioPrefix + 'CHANGE_ACTIVE_AUDIO';
export const CHANGE_PLAY_STATUS = audioPrefix + 'CHANGE_PLAY_STATUS';
export const CHANGE_PLAYBACK_RATE = audioPrefix + 'CHANGE_PLAYBACK_RATE';
export const CHANGE_TIMESTAMP = audioPrefix + 'CHANGE_TIMESTAMP';
export const CHANGE_VOLUME = audioPrefix + 'CHANGE_VOLUME';
export const EXISTENCE_CHECK = audioPrefix + 'EXISTENCE_CHECK';
export const SET_DURATION = audioPrefix + 'SET_DURATION';
export const UPLOAD = audioPrefix + 'UPLOAD';
export const REQUEST_AUDIO_FILE = audioPrefix + 'REQUEST_AUDIO_FILE';
export const CLEAR_FORCE_RESTART = audioPrefix + 'CLEAR_FORCE_RESTART';
