import {type Paint} from '@englex/paint';

import {type PaintLocalStorage} from './interface';

interface Options<S extends object> {
  storageKey?: string;
  hydrate?: (paint: Paint, data: S) => void;
  persist?: (paint: Paint) => S;
}

export const withLocalStorage =
  <S extends object>({
    storageKey = 'enlx-oc-paint:',
    hydrate = () => {},
    persist = () => ({}) as S
  }: Options<S> = {}) =>
  <P extends Paint = Paint>(paint: P): P & PaintLocalStorage => {
    const p = paint as P & PaintLocalStorage;
    const flush = p.flush;

    p.lsHydrate = () => {
      const item = window.localStorage.getItem(`${storageKey}`);
      if (!item) {
        return;
      }
      try {
        const data = JSON.parse(item);
        hydrate(p, data);
      } catch (e) {
        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.error('withLocalStorage.lsHydrate(): error', e);
        }
      }
    };

    p.lsPersist = () => {
      const data = persist(p);
      window.localStorage.setItem(`${storageKey}`, JSON.stringify(data));
    };

    p.lsHydrate();

    p.flush = () => {
      p.lsPersist();
      flush();
    };

    return p;
  };
