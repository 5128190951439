import {defineMessages} from 'react-intl';

export const unitInstanceMessages = defineMessages({
  ExtraExerciseAddedToast: {
    id: 'UnitInstancePage.ExtraExerciseAddedToast'
  },
  ExtraExercisesDeletedToast: {
    id: 'UnitInstancePage.ExtraExerciseDeletedToast'
  }
});
