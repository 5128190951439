import {connect} from 'react-redux';
import {type Action} from 'redux';

import {type AppState, type ChatMessage, type Room} from 'store/interface';
import {type Dispatch} from 'services/wamp/actions/interface';

import Messages from './Body';
import {type BodyDispatchProps, type BodyStateProps} from './interface';
import {
  deleteMessage,
  getRoomMessages,
  hideStartPhrases,
  messageRead,
  promoteMessageToUpdate,
  resetRecipientTyping,
  setNotificationToFalse,
  toggleStartPhrases,
  toggleScrolledToBottom
} from '../../actions/action';
import {imageLoadingFailed} from '../../actions/imageActions';

const mapStateToProps = (state: AppState): BodyStateProps => {
  const selectedRoom: Room | null =
    state.chat!.rooms && state.chat!.rooms[state.chat!.selectedRoomId!]
      ? state.chat!.rooms[state.chat!.selectedRoomId!]
      : null;
  const messages: ChatMessage[] =
    selectedRoom && selectedRoom.messages ? selectedRoom.messages : [];
  messages.forEach((message: ChatMessage) => {
    message.own = message.sender_id === state.user.id;
  });
  const selectedRoomId = state.chat!.selectedRoomId || undefined;
  const chatExpanded =
    state.floatSidebar?.collapsed || state.layout.collapsed
      ? state.floatSidebar?.sidebarPulledOut
      : true;

  return {
    messages,
    allMessagesLoaded: selectedRoom ? selectedRoom.allMessagesLoaded : true,
    loadingNewMessages: selectedRoom ? selectedRoom.loadingNewMessages : undefined,
    newMessagesCount: selectedRoom ? selectedRoom.newMessagesCount : 0,
    partnerName:
      selectedRoom && selectedRoom.recipient ? selectedRoom.recipient.profile!.first_name : '',
    chatCollapsed: !chatExpanded,
    selectedRoomId,
    userId: state.user.id!,
    currentCallId: state.rtc.call ? state.rtc.call.id : undefined,
    userTimezone: state.user.profile!.timeZone,
    messageToUpdateId: state.chat!.messageToUpdateId,
    chatPopoverOpen: state.layout.chatPopoverOpen,
    isMobile: state.layout.isMobile,
    shouldPlayNotification: state.chat!.shouldPlayNotification,
    wampStatus: state.wamp.status,
    pageHasFocus: state.layout.pageHasFocus,
    scrollbarAtBottom: state.chat!.scrollbarAtBottom,
    dictionaryTabOpened: !!state.dictionary?.studentId,
    role: state.user.role,
    showStartPhrases: selectedRoomId
      ? !!state.chat?.rooms?.[selectedRoomId]?.showStartPhrases &&
        !state.chat?.rooms?.[selectedRoomId]?.deleted_at
      : false
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): BodyDispatchProps => ({
  getRoomMessages: (id: number, withoutLoader?: boolean, lastMessageId?: number) =>
    dispatch(getRoomMessages(id, withoutLoader, lastMessageId)),
  deleteMessage: (roomId: number, messageId: number) => dispatch(deleteMessage(roomId, messageId)),
  promoteMessageToUpdate: (roomId: number, id: number) =>
    dispatch(promoteMessageToUpdate(roomId, id)),
  messageRead: (roomId: number, messageId: number) => dispatch(messageRead(roomId, messageId)),
  resetRecipientTyping: (roomId: number) => dispatch(resetRecipientTyping(roomId)),
  imageLoadingFailed: (roomId: number, messageId: number, imageId: string) => {
    return dispatch(imageLoadingFailed(roomId, messageId, imageId));
  },
  setNotificationToFalse: () => dispatch(setNotificationToFalse()),
  toggleScrolledToBottom: (atBottom: boolean) => dispatch(toggleScrolledToBottom(atBottom)),
  hidePhrases: (roomId: number) => dispatch(hideStartPhrases(roomId)),
  togglePhrases: (roomId: number) => dispatch(toggleStartPhrases(roomId))
});

const MessagesContainer = connect(mapStateToProps, mapDispatchToProps)(Messages);

export default MessagesContainer;
