import {defaultSmallVideoRatio, videoBaseHeight, videoBaseWidth} from 'config/static';
import {type ActionHandlersList} from 'store/reducers';
import {type VideoPositionState} from 'store/interface';

import {
  type ChangeRatioAction,
  type ChangeVideoPositionAction,
  type RTCAction
} from '../action/interface';
import {
  CALL_END,
  CHANGE_SMALL_VIDEO_RATIO,
  CHANGE_VIDEO_POSITION,
  WAMP_CALL_HANG_UP,
  RESIZE_VIDEO,
  TOGGLE_FULLSCREEN_MODE,
  TOGGLE_TRANSFORMING_MODE,
  TOGGLE_UNDOCKED_MODE
} from '../action/actionTypes';
import {type ToggleElementAction} from '../../common/interface';

const initialViewingState: VideoPositionState = {
  positionX: 0,
  positionY: 0,
  height: videoBaseHeight,
  width: videoBaseWidth,
  smallVideoRatio: defaultSmallVideoRatio
};

const ACTION_HANDLERS: ActionHandlersList<VideoPositionState, RTCAction> = {
  [TOGGLE_FULLSCREEN_MODE]: (state: VideoPositionState, action: ToggleElementAction) => ({
    ...state,
    fullScreen: action.show
  }),
  [WAMP_CALL_HANG_UP]: () => ({
    ...initialViewingState
  }),
  [CALL_END]: () => ({
    ...initialViewingState
  }),
  [TOGGLE_UNDOCKED_MODE]: (state: VideoPositionState, action: ToggleElementAction) => ({
    ...state,
    undocked: action.show,
    positionX: 0,
    positionY: 0,
    height: videoBaseHeight,
    width: videoBaseWidth
  }),
  [CHANGE_VIDEO_POSITION]: (state: VideoPositionState, action: ChangeVideoPositionAction) => ({
    ...state,
    positionY: action.y,
    positionX: action.x
  }),
  [RESIZE_VIDEO]: (state: VideoPositionState, action: ChangeVideoPositionAction) => ({
    ...state,
    width: action.x,
    height: action.y
  }),
  [TOGGLE_TRANSFORMING_MODE]: (state: VideoPositionState, action: ToggleElementAction) => ({
    ...state,
    transformingMode: action.show
  }),
  [CHANGE_SMALL_VIDEO_RATIO]: (state: VideoPositionState, action: ChangeRatioAction) => ({
    ...state,
    smallVideoRatio: action.ratio
  })
};

export default function (
  state: VideoPositionState = initialViewingState,
  action: RTCAction
): VideoPositionState {
  const videoreducer = ACTION_HANDLERS[action.type];
  return videoreducer ? videoreducer(state, action) : state;
}
