import React from 'react';
import {type Editor, type EditorProps, type Plugin} from '@englex/slate-react';
import classNames from 'classnames';
import {type Next} from '@englex/slate';

import genKey from '../utils/genKey';
import {getEditorId} from '../interface';

type BsSizeShort = 'sm' | 'lg';
type WrapperTag = 'div' | 'span';

interface WrapperOptions {
  className?: string | ((editor: Editor) => string);
  bsSize?: BsSizeShort;
  tag?: WrapperTag; // TODO: think about change to ElementType
}

class Wrapper implements Plugin {
  public readonly slateEditorId: string;
  private readonly tag: WrapperTag = 'div';
  private readonly bsSize?: BsSizeShort;
  private readonly className?: string | ((editor: Editor) => string);

  public queries = {
    [getEditorId]: () => this.slateEditorId
  };

  constructor(options: WrapperOptions = {}) {
    this.tag = options.tag ? options.tag : this.tag;
    this.bsSize = options.bsSize ? options.bsSize : undefined;
    this.slateEditorId = genKey();
    this.className = options.className || 'slate-editor';
  }

  public renderEditor = (props: EditorProps, editor: Editor, next: Next) => {
    const children = next();
    const className = classNames(
      typeof this.className === 'function' ? this.className(editor) : this.className,
      {
        focus: editor.value.selection?.isFocused,
        [this.bsSize as string]: !!this.bsSize
      }
    );

    return React.createElement(this.tag, {
      id: this.slateEditorId,
      className,
      children
    });
  };
}

export default Wrapper;
