import React, {
  type CSSProperties,
  type FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import {useCloseSidebarPanels} from 'components/Dictionary/hooks/useCloseSidebarDictionaryPanels';

import {EditEntryActionsContext} from '../contexts/entry/EditEntryContext';
import {toggleForm} from '../contexts/entry/actions';
import {maxListEntries} from '../static';
import './CreateEntryButton.scss';

interface Props {
  isCompact?: boolean;
  listEntryCount?: number;
  listId?: string;
}

export const CreateEntryButton: FC<Props> = ({listEntryCount, listId, isCompact = false}) => {
  const intl = useIntl();
  const locale = useSelector<AppState, 'en' | 'ru'>(s => s.intl.locale);
  const [titleWidth, setTitleWidth] = useState(0);
  const titleRef = useRef<HTMLSpanElement>(null);

  const {closeSidebarPanels} = useCloseSidebarPanels();

  const {dispatch} = useContext(EditEntryActionsContext);

  if (!titleWidth && titleRef.current) {
    setTitleWidth(titleRef.current.getBoundingClientRect().width + 5);
  }

  useEffect(() => {
    if (titleRef.current) setTitleWidth(titleRef.current.getBoundingClientRect().width + 5);
  }, [locale]);

  const disabled = !!(listEntryCount && listEntryCount >= maxListEntries);

  const onClick = useCallback(() => {
    closeSidebarPanels();
    dispatch(toggleForm(listId));
  }, [closeSidebarPanels, dispatch, listId]);

  return (
    <Button
      className={classNames('create-dictionary-entry-button', {
        'is-compact': isCompact,
        invisible: !titleWidth
      })}
      bsStyle="success"
      onClick={onClick}
      style={{'--titleWidth': `${-titleWidth}px`} as CSSProperties}
      disabled={disabled}
      title={disabled ? intl.formatMessage({id: 'Dictionary.List.AlreadyFull'}) : undefined}
    >
      <span className="icon-container">
        <Icon name="plus-circle" />
      </span>
      <span className="title" ref={titleRef}>
        <FormattedMessage id="Dictionary.CreateEntry" />
      </span>
    </Button>
  );
};
