import React, {type FC} from 'react';
import Tooltip from 'rc-tooltip';
import Highlighter from 'react-highlight-words';
import {splitWordByBraces, stripWordCurlyBrackets} from '@englex/trainer';

import {type PronunciationPlayerStatus} from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

import {type Pronunciation} from '../../interface';

interface SpellingTooltipProps {
  text: string;
}

const SpellingTooltip: FC<SpellingTooltipProps> = ({children, text}) => {
  return (
    <Tooltip
      destroyTooltipOnHide={true}
      overlay={`[${text}]`}
      trigger={['hover']}
      placement="top"
      align={{overflow: {adjustX: false, adjustY: false}}}
    >
      <span>{children}</span>
    </Tooltip>
  );
};

interface SpellingIconProps {
  phoneticSpelling: string | null;
  playStatus: PronunciationPlayerStatus;
  pronunciation: Pronunciation | null;
}

const SpellingIcon: FC<SpellingIconProps> = ({phoneticSpelling, playStatus, pronunciation}) => {
  if (playStatus === 'loading') return <Spinner size={14} />;
  if (pronunciation) return <Icon name="volume-up" />;
  if (phoneticSpelling) return <Icon name="commenting" />;
  return null;
};

interface OriginalViewProps extends SpellingIconProps {
  original: string;
  search?: string[];
}

export const OriginalView = React.forwardRef<HTMLSpanElement, OriginalViewProps>(
  ({original, search, ...rest}, ref) => {
    const {textBeforeBraces, textInBraces} = splitWordByBraces(stripWordCurlyBrackets(original));

    return (
      <div>
        <span className="value" ref={ref}>
          {search ? (
            <Highlighter
              highlightClassName="highlight"
              searchWords={[search[0]]}
              textToHighlight={textBeforeBraces}
              autoEscape={true}
            />
          ) : (
            textBeforeBraces
          )}
        </span>
        {rest.phoneticSpelling ? (
          <SpellingTooltip text={rest.phoneticSpelling}>
            <SpellingIcon {...rest} />
          </SpellingTooltip>
        ) : (
          <SpellingIcon {...rest} />
        )}
        {textInBraces && (
          <span className="grammar-commentary">
            {search ? (
              <Highlighter
                highlightClassName="highlight"
                searchWords={search?.[1] ? [search[1]] : search}
                textToHighlight={textInBraces}
                autoEscape={true}
              />
            ) : (
              textInBraces
            )}
          </span>
        )}
      </div>
    );
  }
);
