import {
  Connection,
  type IPublication,
  type IResult,
  type ISubscription,
  Result,
  type Session,
  type Subscription
} from 'autobahn';
import {type Action, type Dispatch, type MiddlewareAPI} from 'redux';
import {type ThunkDispatch} from 'redux-thunk';
import {withScope, captureException} from '@sentry/react';

import {type AppState} from 'store/interface';

import {
  CONNECTION_CLOSE,
  CONNECTION_CLOSED,
  CONNECTION_OPENED,
  CONNECTION_OPENING,
  CONNECTION_RETRYING,
  INITIALIZE_CLIENT,
  wampClientActionType
} from './actions/actionTypes';
import {
  type CloseDetails,
  type SessionInfo,
  type WampAction,
  type WampCallAction,
  type WampCallResponseAction,
  type WampClosedCreator,
  type WampError,
  type WampErrorAction,
  type WampOpenAction,
  type WampOpenCreator,
  type WampPublishAction,
  type WampPublishResponseAction,
  type WampRetryCreator,
  type WampSubscribeAction,
  type WampSubscribeResponseAction,
  type WampUnsubscribeAction,
  type WampUnsubscribeResponseAction
} from './actions/interface';
import {
  type AutobahnClient,
  type AutobahnClientName,
  type AutobahnClients,
  type ConnectionOptions,
  type CurrentCall,
  defaultAutobahnClientName,
  FAIL_POSTFIX,
  SUCCESS_POSTFIX,
  type WampClients
} from './actions/types';
import {addSubscription, removeSubscription} from './actions/action';
import {tokenRenewed, userSessionExpired} from '../../authentication/actions/action';
import {type InitializeClientAction} from '../../routes/Sandbox/AutobahnTester/actions/actions';
import {getAutobahnConfig} from '../autobahnTester/config';

const actionBeforeConnectionOpenMessage =
  'Cannot perform WAMP actions before session was established.';

const wampActionBeforeConnectionOpenError: WampError<{}, {}> = {
  error: 'WAMP premature action',
  args: [actionBeforeConnectionOpenMessage],
  kwargs: {errors: [{name: '', message: actionBeforeConnectionOpenMessage}]}
};

const alreadySubscribedError = (topic: string): WampError<{}, {topic: string}> => ({
  error: 'app.error.already_subscribed',
  args: ['Wamp is already subscribed to this topic.'],
  kwargs: {
    topic
  }
});

const FORCE_RECONNECT = 'WAMP/FORCE_RECONNECT';

const unsubscribeFromUnexistentError: WampError<{}, {}> = {
  error: 'WAMP unsubscribe from unexistent error',
  args: ['Wamp is not subscribed to this topic, so unsubscription is not possible.'],
  kwargs: {}
};

const disconnectBeforeAnsweredMessage = 'WAMP disconnected before call answered';

const disconnectedBeforeAnsweredError = {
  error: 'WAMP disconnected before call answered',
  args: ['WAMP client has disconnected before the call was answered'],
  kwargs: {errors: [{name: '', message: disconnectBeforeAnsweredMessage}]}
};

const wampConnectionOpened: WampOpenCreator = (
  session: SessionInfo,
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(CONNECTION_OPENED, client),
  session,
  client
});

const wampConnectionClosed: WampClosedCreator = (
  reason: string,
  details: CloseDetails,
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(CONNECTION_CLOSED, client),
  reason,
  details,
  client
});

const wampConnectionRetrying: WampRetryCreator = (
  retryCount: number,
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(CONNECTION_RETRYING, client),
  retryCount,
  client
});

type OneOfWampActions =
  | WampUnsubscribeAction
  | WampSubscribeAction<{}, {}>
  | WampPublishAction
  | WampCallAction<{}, {}>;

function logError(error: WampError<string[], string[]>, method: string) {
  if (import.meta.env.MODE === 'development') {
    // eslint-disable-next-line no-console
    console.error(`Wamp error in ${method}`, error);
  }
}

function checkIfAlreadySubscribed(
  client: Connection,
  action: WampSubscribeAction<{}, {}>
): boolean {
  return client.session.subscriptions.some(subscription => {
    return subscription[0] && action.wamp.uri === subscription[0].topic;
  });
}

function handleRepeatedTopicSubscription(
  action: WampSubscribeAction<{}, {}>,
  next: Dispatch<Action>,
  resolve: (action: WampErrorAction<{}, {}, {}>) => void,
  reject?: (error: WampErrorAction<{}, {}, {}>) => void
): void {
  const error = alreadySubscribedError(action.wamp.uri);
  // eslint-disable-next-line no-console
  console.error(error);
  const errorAction: WampErrorAction<{}, {}, {}> = {
    type: action.type + FAIL_POSTFIX,
    error,
    wamp: {
      meta: {
        previousAction: action
      }
    }
  };
  next(errorAction);
  reject ? reject(errorAction) : resolve(errorAction);
}

async function subscribeToTopic(
  client: Connection,
  action: WampSubscribeAction<{}, {}>,
  api: MiddlewareAPI<Dispatch<Action>, AppState>,
  resolve: (action: WampSubscribeResponseAction<{}> | WampErrorAction<{}, {}, {}>) => void,
  reject?: (error: WampErrorAction<{}, {}, {}>) => void
): Promise<void> {
  try {
    const subscription: ISubscription = await client.session.subscribe(
      action.wamp.uri,
      (args, kwargs, details) => {
        try {
          action.callback(args!, kwargs, details!, api, action.wamp.uri);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
          withScope(scope => {
            scope.setExtras({uri: action.wamp.uri, args, kwargs});
            captureException(e);
          });
        }
      },
      action.wamp.options
    );
    const successAction: WampSubscribeResponseAction<{}> = {
      type: action.type + SUCCESS_POSTFIX,
      wamp: {subscription, meta: {previousAction: action}}
    };
    resolve(successAction);
    api.dispatch(addSubscription(action.wamp.uri, getClientName(action)));
    api.dispatch(successAction);
  } catch (error) {
    logError(error, action.wamp.uri);
    const errorAction: WampErrorAction<{}, {}, {}> = {
      type: action.type + FAIL_POSTFIX,
      error
    };
    api.dispatch(errorAction);
    reject ? reject(errorAction) : resolve(errorAction);
  }
}

function handleWampActionBeforeSessionOpen(
  action: OneOfWampActions,
  next: Dispatch<Action>,
  resolve: (action: WampErrorAction<{}, {}, {}>) => void,
  reject?: (error: WampErrorAction<{}, {}, {}>) => void
): void {
  if (import.meta.env.MODE === 'development') {
    // eslint-disable-next-line no-console
    console.error(wampActionBeforeConnectionOpenError);
  }
  const errorAction: WampErrorAction<{}, {}, {}> = {
    type: action.type + FAIL_POSTFIX,
    error: wampActionBeforeConnectionOpenError
  };
  if (action.wamp.method === 'call') {
    errorAction.wamp = {
      meta: {
        previousAction: action
      }
    };
  }
  next(errorAction);
  reject ? reject(errorAction) : resolve(errorAction);
}

function handleUnsubscribeFromUnexistent(
  action: WampUnsubscribeAction,
  next: Dispatch<Action>,
  resolve: (action: WampErrorAction<{}, {}, {}>) => void
): void {
  if (import.meta.env.MODE === 'development') {
    // eslint-disable-next-line no-console
    console.error(unsubscribeFromUnexistentError);
  }
  const errorAction: WampErrorAction<{}, {}, {}> = {
    type: action.type + FAIL_POSTFIX,
    error: unsubscribeFromUnexistentError
  };
  next(errorAction);
  resolve(errorAction);
}

async function unsubscribeFromTopic(
  client: Connection,
  subscriptionToRemove: Subscription,
  action: WampUnsubscribeAction,
  dispatch: Dispatch<Action>,
  resolve: (action: WampErrorAction<{}, {}, {}> | WampUnsubscribeResponseAction) => void
): Promise<void> {
  try {
    await client.session.unsubscribe(subscriptionToRemove);
    const successAction: WampUnsubscribeResponseAction = {
      method: 'unsubscribe',
      type: action.type + SUCCESS_POSTFIX,
      wamp: {uri: action.wamp.uri}
    };
    dispatch(removeSubscription(action.wamp.uri, getClientName(action)));
    dispatch(successAction);
    resolve(successAction);
  } catch (error) {
    logError(error, action.wamp.uri);
    const errorAction: WampErrorAction<{}, {}, {}> = {
      type: action.type + FAIL_POSTFIX,
      error
    };
    dispatch(errorAction);
    resolve(errorAction);
  }
}

async function publish(
  client: Connection,
  action: WampPublishAction<Array<{}>, {}>,
  dispatch: Dispatch<Action>,
  resolve: (action: WampErrorAction<{}, {}, {}> | WampPublishResponseAction) => void,
  reject?: (error: WampErrorAction<{}, {}, {}>) => void
): Promise<void> {
  try {
    const publication: IPublication = await client.session.publish(
      action.wamp.uri,
      action.wamp.args,
      action.wamp.kwargs,
      action.wamp.options
    );
    if (publication) {
      const successAction: WampPublishResponseAction = {
        type: action.type + SUCCESS_POSTFIX,
        wamp: {publication}
      };
      resolve(successAction);
      dispatch(successAction);
    } else {
      const successAction: WampPublishResponseAction = {
        type: action.type + SUCCESS_POSTFIX,
        wamp: {
          publication: {id: null}
        }
      };
      dispatch(successAction);
      resolve(successAction);
    }
  } catch (error) {
    logError(error, action.wamp.uri);
    const errorAction: WampErrorAction<{}, {}, {}> = {
      type: action.type + FAIL_POSTFIX,
      error
    };
    dispatch(errorAction);
    reject ? reject(errorAction) : resolve(errorAction);
  }
}

type ResponseAction = WampCallResponseAction<{}, {}, {}> | WampErrorAction<{}, {}, {}>;
type Resolve = (action: ResponseAction) => void;

function resolveAndDispatchAnswerAction(
  currentCalls: CurrentCall[],
  currentCall: CurrentCall,
  api: MiddlewareAPI<Dispatch<Action>, AppState>,
  resolve: Resolve,
  action: ResponseAction
) {
  currentCalls.splice(currentCalls.indexOf(currentCall), 1);
  api.dispatch(action);
  resolve(action);
}

function getErrorAction(action: Action, error: WampError<{}, {}>): WampErrorAction<{}, {}, {}> {
  return {
    type: action.type + FAIL_POSTFIX,
    wamp: {
      meta: {
        previousAction: action as {}
      }
    },
    error
  };
}

async function callMethod(
  client: Connection,
  action: WampCallAction<[{}], {}>,
  resolve: Resolve,
  reject: ((action: Action) => void) | undefined,
  api: MiddlewareAPI<Dispatch<Action>, AppState>,
  currentCalls: CurrentCall[]
): Promise<void> {
  const {uri, args, kwargs, options} = action.wamp;
  const currentCall = {
    action,
    cancel() {
      const cancelAction = getErrorAction(action, disconnectedBeforeAnsweredError);
      resolveAndDispatchAnswerAction(
        currentCalls,
        currentCall,
        api,
        reject || resolve,
        cancelAction
      );
    }
  };
  let resolveAction: WampCallResponseAction<{}, {}, {}> | WampErrorAction<{}, {}, {}>;
  let isError = false;
  try {
    currentCalls.push(currentCall);
    let callResult: IResult = await client.session.call<IResult>(uri, args, kwargs, options);
    // call can return not instance of Result, so create it manually to unify API
    if (!(callResult instanceof Result)) {
      callResult = new Result([callResult], {});
    }
    resolveAction = {
      type: action.type + SUCCESS_POSTFIX,
      wamp: {
        callResult,
        meta: {
          previousAction: action
        }
      }
    };
  } catch (error) {
    isError = true;
    logError(error, uri);
    resolveAction = getErrorAction(action, error);
  }
  resolveAndDispatchAnswerAction(
    currentCalls,
    currentCall,
    api,
    isError && reject ? reject : resolve,
    resolveAction
  );
}

function setUriPrefix(session: Session, config: ConnectionOptions<Dispatch<Action>, AppState>) {
  if (config.uriMap) {
    for (const prefix of Object.keys(config.uriMap)) {
      session.prefix(prefix, `${config.uriPrefix}.${config.uriMap[prefix]}`);
    }
  }
}

function rejectAllCalls(currentCalls: CurrentCall[]) {
  [...currentCalls].forEach(call => {
    call.cancel();
  });
}

function openSession(
  connection: Connection,
  api: MiddlewareAPI<ThunkDispatch<AppState, void, Action>, AppState>,
  config: ConnectionOptions<Dispatch<Action>, AppState>,
  resolve: (action: WampOpenAction) => void,
  currentCalls: CurrentCall[]
): void {
  connection.onopen = (session, details) => {
    setUriPrefix(session, config);
    if (import.meta.env.REACT_APP_DEV_AUTOBAHN_DEBUG === 'true') {
      // eslint-disable-next-line no-console
      console.log('Wamp connection was opened, details: ', details);
    }
    const sessionInfo: SessionInfo = {
      authid: details.authid,
      authrole: details.authrole,
      sessionId: session.id
    };

    if (
      config.renewToken &&
      details.authextra &&
      details.authextra.renew &&
      import.meta.env.REACT_APP_RENEW_TOKEN === 'true'
    ) {
      api.dispatch(tokenRenewed(details.authextra.renew));
    }

    const wampOpenedAction = wampConnectionOpened(sessionInfo, config.client);

    api.dispatch(wampOpenedAction);
    resolve(wampOpenedAction);
  };
  connection.onclose = (reason, details: CloseDetails) => {
    if (import.meta.env.MODE === 'development') {
      // eslint-disable-next-line no-console
      console.log('Wamp connection was closed, reason: ', reason, ', details: ', details);
    }
    const sessionExpired = Boolean(api.getState().user.sessionExpired!);
    rejectAllCalls(currentCalls);
    if (details.will_retry) {
      api.dispatch(wampConnectionRetrying(details.retry_count, config.client));
    } else {
      api.dispatch(wampConnectionClosed(reason, details, config.client));
      if (config.client === defaultAutobahnClientName) {
        const tokenExpiredReason = `${config.uriPrefix}.authenticate.validation.error`;
        if (details.reason === tokenExpiredReason) {
          api.dispatch(userSessionExpired);
        }
      }
    }
    return sessionExpired;
  };
  config.authid = config.stateAuthId(api);

  const clientVersionExtra = {clientVersion: api.getState().common.version};
  config.authextra = {
    ...config.authextra,
    ...clientVersionExtra
  } as object;

  config.onchallenge = () => {
    return new Promise(resolveToken => {
      resolveToken(config.stateToken(api));
    });
  };

  connection.open();
}

function getClientName(action: WampAction): string {
  return action.client ? action.client : defaultAutobahnClientName;
}

function getClient(
  action: WampAction,
  clients: WampClients<AutobahnClients<Dispatch<Action>, AppState>>
): AutobahnClient<Dispatch<Action>, AppState> {
  const clientName = getClientName(action);
  if (clients.hasOwnProperty(clientName)) {
    return clients[clientName];
  }
  throw new Error(`WAMP client ${clientName} is not configured`);
}

export type WampMiddleware<S> = (
  clients: WampClients<Dispatch<Action>>
) => (
  api: MiddlewareAPI<Dispatch<Action>, S>
) => (next: Dispatch<Action>) => (action: OneOfWampActions | WampAction) => Promise<{}> | Action;

const wampMiddleware: WampMiddleware<AppState> = clients => api => next => action => {
  if (action.type === wampClientActionType(INITIALIZE_CLIENT, action.client)) {
    const autobahnConfig = getAutobahnConfig(action as InitializeClientAction);
    clients[getClientName(action)] = {
      connection: new Connection(autobahnConfig),
      config: autobahnConfig,
      currentCalls: []
    };
    return next(action);
  }
  const {connection, config, currentCalls} = getClient(action, clients);

  if (action.type === wampClientActionType(CONNECTION_OPENING, action.client)) {
    if (connection.isConnected) {
      // eslint-disable-next-line no-console
      console.warn(`WAMP connection client named '${config.client}' is already connected`);
      return next(action);
    }
    return new Promise(resolve => {
      openSession(connection, api, config, resolve, currentCalls);
      next(action);
    });
  }

  if (action.type === FORCE_RECONNECT) {
    connection.transport.close();
    return next(action);
  }

  if (action.type === wampClientActionType(CONNECTION_CLOSE, action.client)) {
    connection.close();
    return next(action);
  }

  if ((action as OneOfWampActions).wamp) {
    switch ((action as OneOfWampActions).wamp.method) {
      case 'subscribe': {
        const subAction = action as WampSubscribeAction<{}, {}>;
        return new Promise((resolve, reject) => {
          const onErrorReject = subAction.wamp.rejectOnError ? reject : undefined;
          if (!connection.session || !connection.session.isOpen) {
            handleWampActionBeforeSessionOpen(subAction, next, resolve, onErrorReject);
          } else {
            if (checkIfAlreadySubscribed(connection, subAction)) {
              next(subAction);
              handleRepeatedTopicSubscription(subAction, next, resolve, onErrorReject);
            } else {
              next(subAction);
              subscribeToTopic(connection, subAction, api, resolve, onErrorReject);
            }
          }
        });
      }

      case 'unsubscribe':
        return new Promise(resolve => {
          if (!connection.session || !connection.session.isOpen) {
            handleWampActionBeforeSessionOpen(action as WampUnsubscribeAction, next, resolve);
          } else {
            const subscriptionToRemove: Subscription[] = connection.session.subscriptions.find(
              subscription => {
                return (
                  subscription[0] &&
                  subscription[0].topic === (action as WampUnsubscribeAction).wamp.uri
                );
              }
            ) as Subscription[];
            if (!subscriptionToRemove) {
              handleUnsubscribeFromUnexistent(action as WampUnsubscribeAction, next, resolve);
            } else {
              next(action);
              unsubscribeFromTopic(
                connection,
                subscriptionToRemove[0],
                action as WampUnsubscribeAction,
                api.dispatch,
                resolve
              );
            }
          }
        });

      case 'publish': {
        return new Promise((resolve, reject) => {
          const publishAction = action as WampPublishAction<Array<{}>, {}>;
          const onErrorReject = publishAction.wamp.rejectOnError ? reject : undefined;
          if (!connection.session || !connection.session.isOpen) {
            handleWampActionBeforeSessionOpen(
              action as WampPublishAction,
              next,
              resolve,
              onErrorReject
            );
          } else {
            next(publishAction);
            publish(connection, publishAction, api.dispatch, resolve, onErrorReject);
          }
        });
      }

      case 'call':
        return new Promise((resolve, reject) => {
          const callAction = action as WampCallAction<[{}], {}>;
          const onErrorReject = callAction.wamp.rejectOnError ? reject : undefined;
          if (!connection.session || !connection.session.isOpen) {
            next(action);
            handleWampActionBeforeSessionOpen(
              action as WampCallAction<{}, {}>,
              next,
              resolve,
              onErrorReject
            );
          } else {
            next(action);
            callMethod(connection, callAction, resolve, onErrorReject, api, currentCalls);
          }
        });

      default:
        return next(action);
    }
  } else {
    return next(action);
  }
};

export default wampMiddleware;
