import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {OrderedMap} from 'immutable';

import {type ExerciseLocation, type ExerciseRecordMap} from 'store/exercise/player/interface';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';
import {type AxiosRequestError} from 'services/axios/interface';
import {type AppState, type ExerciseInstance, type Role} from 'store/interface';
import {useNode} from 'contexts/Viewer/utils';
import {Resources} from 'contexts/Viewer';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type CoursebookInstanceUrlParams} from 'common/paths';
import {useXPlayer} from 'hooks/player/useXPlayer';

import {loadGrammarInstance, loadGrammarOriginal, loadGrammarPreview} from './actions';
import {type Grammar} from './interface';
import {PageNotFound} from '../../routes/PageNotFound/components/PageNotFound';

interface Props {
  selectedGrammar: Grammar;
  setLocation: (location?: ExerciseLocation) => void;
  cbi?: string;
  hasPointer?: boolean;
  setLoaded?: (loaded: boolean) => void;
  isModal?: boolean;
}

const GrammarExercise: React.FC<Props> = ({
  selectedGrammar,
  setLocation,
  cbi,
  hasPointer,
  setLoaded,
  isModal
}) => {
  const {coursebookInstanceId} = useParams<CoursebookInstanceUrlParams>();
  const role = useSelector<AppState, Role>(state => state.user.role!);

  const [grammarExercise, setGrammarExercise] = useState<ExerciseRecordMap>();
  const [notFound, setNotFound] = useState<boolean>(false);

  const resourcesNode = useNode('.page-left');
  const action = useMemo(() => {
    const {grammarId, unitInstanceId, grammarInstanceId, id: unitExerciseId} = selectedGrammar;
    if (cbi && unitExerciseId) {
      return loadGrammarPreview(cbi, unitExerciseId);
    }
    if (grammarInstanceId) {
      return loadGrammarInstance(grammarInstanceId);
    } else {
      return loadGrammarOriginal(grammarId, unitInstanceId, cbi || coursebookInstanceId!);
    }
  }, [cbi, coursebookInstanceId, selectedGrammar]);

  const {isError, reload, isLoading} = useApiRequest(
    action,
    (resp: ExerciseInstance) => {
      setNotFound(false);
      setLocation(resp.location);
      setGrammarExercise(OrderedMap([[resp.id, new ExerciseRecord(resp)]]));
      setLoaded && setLoaded(true);
    },
    (e: AxiosRequestError) => {
      if (e.error.response && e.error.response.status === 404) {
        setNotFound(true);
      }
    }
  );

  const XPlayer = useXPlayer();

  useEffect(
    () => () => {
      setGrammarExercise(undefined);
    },
    [selectedGrammar.grammarId]
  );

  if (notFound) {
    return <PageNotFound />;
  }

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (!grammarExercise?.size || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Resources id="grammar-player-page" node={resourcesNode}>
        <XPlayer
          exercises={grammarExercise}
          role={role}
          hideCompleteButtons={true}
          preview={true}
          grammar={true}
          hasPointer={hasPointer}
          isModal={isModal}
        />
      </Resources>
    </>
  );
};

export default GrammarExercise;
