import {useCallback, useEffect, useMemo, useState} from 'react';

import {requestEnsurePDFPage} from 'routes/ClassRoom/components/FilesWorkSpace/documentsTab/actions/action';
import {type DocumentInstancePage} from 'routes/ClassRoom/components/FilesWorkSpace/documentsTab/actions/interface';
import {useApiRequest} from 'hooks/rest/useApiRequest';

interface PageSize {
  width: number;
  height: number;
}

export function useDocumentInstancePage(
  documentInstanceId: number,
  pageNumber: number,
  size?: PageSize
) {
  const [docInstancePage, setDocInstancePage] = useState<DocumentInstancePage | null>(null);

  const action = useMemo(() => {
    if (size) {
      const {width, height} = size;

      return requestEnsurePDFPage(documentInstanceId, pageNumber, width, height);
    }

    return null;
  }, [documentInstanceId, pageNumber, size]);

  const successHandler = useCallback((pageInstance: DocumentInstancePage) => {
    setDocInstancePage(pageInstance);
  }, []);

  useEffect(
    () => () => {
      setDocInstancePage(null);
    },
    [documentInstanceId, pageNumber]
  );

  const {isLoading, isError, reload} = useApiRequest(action, successHandler);

  return {docInstancePage, isLoading, isError, reload};
}
