import React, {Component} from 'react';
import ReactAudioPlayer from 'react-audio-player';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';
import {captureMessage, withScope} from '@sentry/react';

import * as toastr from 'components/toastr';
import {emitter, xAudioBufferChanged, xAudioCurrentTimeChanged} from 'services/event-emitter';
import Throttle from 'common/Throttle';
import {isSafari} from 'helpers/browser';
import {playMedia} from 'helpers/playMedia';

import {LOADING, MediaContextAction, PAUSE, PLAYING} from '../interface';
import {type AudioPlayerProps as Props} from './containers/Exercise/Audio';

import './AudioPlayer.scss';

const messages = defineMessages({
  PlaybackError: {
    id: 'Media.Audio.PlaybackError'
  }
});

interface State {
  awaitingPlayPromise: boolean;
}

class AudioPlayer extends Component<Props & WrappedComponentProps, State> {
  public static connectionLostPlayError = 'MEDIA_ELEMENT_ERROR: Format error';
  private static readonly emitFrequency: number = 400;
  private static readonly timeBeforeStopped: number = 300;
  // sometimes max buffering length and max play length of a track are different for some reason
  private static readonly diffError: number = 0.8;

  public state: State = {awaitingPlayPromise: false};

  private playerRef: ReactAudioPlayer | null;
  private bufferInterval: NodeJS.Timeout;
  private throttle: Throttle;
  private hasAutoPlayPermission: boolean;

  private get isAutoplay() {
    return this.props.context?.action !== MediaContextAction.Pause;
  }

  private get player(): HTMLMediaElement | null {
    return this.playerRef?.audioEl.current ?? null;
  }

  public constructor(props: Props & WrappedComponentProps) {
    super(props);
    this.throttle = new Throttle();
    this.hasAutoPlayPermission = true;
  }

  public componentWillUnmount() {
    this.clearBufferInterval();
  }

  public componentDidUpdate(prevProps: Props) {
    this.shouldLoad(prevProps);
    this.shouldPlay(prevProps);
    this.shouldPause(prevProps);
    this.shouldEmit();
    this.shouldChangeCurrentTime(prevProps);
    this.shouldChangeVolume();
    this.shouldClearBufferInterval(prevProps);
    this.shouldChangePlaybackRate(prevProps);
  }

  public componentDidMount() {
    const {isMobile, volume} = this.props;
    if (this.player) {
      this.player.volume = isMobile ? 1 : volume;
      this.load();
    }
  }

  public render() {
    const {activeFile, isMobile, volume} = this.props;
    return activeFile ? (
      <ReactAudioPlayer
        src={activeFile.url}
        ref={this.getPlayerRef}
        onEnded={this.end}
        onPause={this.onPause}
        preload="auto"
        controls={false}
        volume={isMobile ? 1 : volume}
        onError={this.onError}
      />
    ) : null;
  }

  private onPlaySuccess = () => {
    this.hasAutoPlayPermission = true;
  };

  private onPlayError = (error: Error) => {
    // eslint-disable-next-line no-console
    console.warn(error);
    if (error.name === 'NotAllowedError') {
      this.hasAutoPlayPermission = false;
    }
  };

  private playMedia = () => {
    if (this.player) {
      this.setState({awaitingPlayPromise: true});
      playMedia(this.player, false)
        .then(this.onPlaySuccess)
        .catch(this.onPlayError)
        .finally(() => {
          this.setState({awaitingPlayPromise: false});
          this.clearBufferInterval();
          this.setBufferInterval();
        });
    }
  };

  private onError = (e: Event) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const error: Error = (e.target as any).error;
    const isConnectionLostError = error.message === AudioPlayer.connectionLostPlayError;
    if (!isConnectionLostError) {
      withScope(scope => {
        scope.setExtras({
          src: this.props.activeFile && this.props.activeFile.url,
          nativeError: error
        });
        captureMessage('Audio playback error', 'warning');
      });
    }
    toastr.error('', this.props.intl.formatMessage(messages.PlaybackError));
    this.props.changePlayStatus(undefined);
  };

  private onPause = () => {
    const {changePlayStatus, playStatus} = this.props;
    if (playStatus && playStatus !== PAUSE) changePlayStatus(PAUSE);
  };

  private getPlayerRef = (el: ReactAudioPlayer | null) => {
    this.playerRef = el;
    if (el) {
      el.clearListenTrack();
    }
  };

  private end = () => {
    if (this.player) {
      emitter.emit(xAudioCurrentTimeChanged, this.player.duration);
      setTimeout(this.props.changePlayStatus, AudioPlayer.timeBeforeStopped);
    }
  };

  private load() {
    if (this.player) {
      this.player.load();
      this.player.currentTime = 0;
      this.player.playbackRate = this.props.playbackRate || 1;
      this.player.onloadedmetadata = () => {
        if (this.player) {
          if (
            this.props.activeFile &&
            this.props.activeFile.length !== Math.round(this.player.duration)
          ) {
            this.props.setDuration(this.player.duration);
          }
          this.isAutoplay && this.playMedia();
        }
      };
      this.player.onwaiting = () => {
        if (this.props.playStatus !== LOADING && this.props.playStatus !== undefined) {
          this.props.changePlayStatus(LOADING);
        }
      };
      this.player.onplaying = () => {
        if (this.hasAutoPlayPermission && this.props.playStatus !== PLAYING) {
          this.props.changePlayStatus(PLAYING);
        }
      };
      this.player.ontimeupdate = this.emitHandler;
      this.player.onseeking = this.emitHandler;
      this.player.onseeked = this.emitHandler;
      // this event listener exists simply because safari on some circumstances can skip
      // firing onPlaying after loading provides enough data to play, which caused infinite
      // LOADING state in reducer -> infinite loading indicator.
      if (isSafari()) {
        this.player.oncanplay = () => {
          if (this.props.playStatus === LOADING && this.player) {
            this.props.changePlayStatus(PLAYING);
            this.playMedia();
          }
        };
      }
    }
  }

  private emitHandler = (e: Event) => {
    const currentTime = (e.currentTarget as HTMLAudioElement).currentTime;
    this.throttle.throttleAction(() => {
      emitter.emit(xAudioCurrentTimeChanged, currentTime);
    });
  };

  private setBufferInterval = () => {
    if (this.player)
      this.bufferInterval = setInterval(this.emitBufferChange, AudioPlayer.emitFrequency);
  };

  private emitBufferChange = () => {
    if (this.player) {
      const {buffered, duration} = this.player;
      if (buffered.length) {
        const bufferedEnd = buffered.end(buffered.length - 1);
        const diff: number = Math.abs(duration - bufferedEnd);
        if (diff > AudioPlayer.diffError) {
          emitter.emit(xAudioBufferChanged, buffered.end(buffered.length - 1) / duration);
        } else {
          emitter.emit(xAudioBufferChanged, 1);
          this.clearBufferInterval();
        }
      } else {
        emitter.emit(xAudioBufferChanged, 0);
      }
    } else {
      this.clearBufferInterval();
    }
  };

  private clearBufferInterval() {
    clearInterval(this.bufferInterval);
  }

  private shouldClearBufferInterval(prevProps: Props) {
    if (prevProps.playStatus === PLAYING && this.props.playStatus !== PLAYING) {
      this.clearBufferInterval();
    }
  }

  private shouldChangePlaybackRate(prevProps: Props) {
    const {playbackRate} = this.props;
    if (this.player && prevProps.playbackRate !== playbackRate) {
      this.player.playbackRate = playbackRate || 1;
    }
  }

  private shouldChangeVolume() {
    const {isMobile, volume} = this.props;
    if (isMobile) {
      return;
    }
    if (this.player && volume !== this.player.volume) {
      this.player.volume = volume;
    }
  }

  private shouldLoad(prevProps: Props) {
    const {activeFile, playStatus} = this.props;
    if (playStatus === PLAYING && !prevProps.playStatus) {
      this.load();
      return;
    }
    if (!prevProps.activeFile && activeFile) {
      this.load();
      return;
    }
    if (prevProps.activeFile && activeFile) {
      if (prevProps.activeFile.id !== activeFile.id) {
        this.load();
        return;
      }
    }
  }

  private shouldPlay(prevProps: Props) {
    if (
      this.player &&
      this.isAutoplay &&
      prevProps.playStatus &&
      prevProps.playStatus !== PLAYING &&
      this.props.playStatus === PLAYING
    ) {
      this.playMedia();
    }
  }

  private shouldPause(prevProps: Props) {
    if (this.player && prevProps.playStatus !== PAUSE && this.props.playStatus === PAUSE) {
      if (!this.state.awaitingPlayPromise) this.player.pause();
    }
  }

  private shouldEmit() {
    const {playStatus} = this.props;
    if (this.player && (playStatus === PAUSE || playStatus === LOADING)) this.emitBufferChange();
  }

  private shouldChangeCurrentTime({
    playStatus: prevPlayStatus,
    timestamp: prevTimestamp,
    startedAt: prevStartedAt
  }: Props) {
    const {clearForceRestart, forceRestart, playStatus, startedAt, timestamp} = this.props;
    if (this.player) {
      if (
        prevStartedAt !== startedAt &&
        (timestamp !== prevTimestamp || prevPlayStatus === playStatus || forceRestart)
      ) {
        this.player.currentTime = timestamp;
        if (forceRestart) {
          clearForceRestart();
        }
      }
    }
  }
}

export default injectIntl(AudioPlayer);
