interface TranslationDirection {
  sourceLocale: 'en' | 'ru';
  targetLocale: 'en' | 'ru';
}

export const determineDirectionOfTranslation = (str?: string): TranslationDirection | null => {
  if (!str) return null;

  const isContainsEnglishCharacters = /(?![\s_-])[A-Z, a-z, Ā-ſ]/i.test(str);
  const isContainsRussianCharacters = /(?![\s_-])[А-ЯЁ, а-яё]/i.test(str);

  if (isContainsEnglishCharacters && isContainsRussianCharacters) return null;
  if (!isContainsEnglishCharacters && !isContainsRussianCharacters) return null;

  return {
    sourceLocale: isContainsEnglishCharacters ? 'en' : 'ru',
    targetLocale: !isContainsEnglishCharacters ? 'en' : 'ru'
  };
};
