import {connect} from 'react-redux';

import {type AppState} from 'store/interface';

import EnglexDropdown, {type EnglexDropdownStateProps} from '../components/EnglexDropdown';

const mapStateToProps = (state: AppState): EnglexDropdownStateProps => ({
  isMobile: state.layout.isMobile
});

export default connect(mapStateToProps)(EnglexDropdown);
