import React, {Component, type FC, type PropsWithChildren} from 'react';
import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';

import {type AppState} from 'store/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import {playMedia} from 'helpers/playMedia';
import {type Pronunciation} from 'components/Dictionary/shared/interface';
import * as toastr from 'components/toastr';
import {type PronunciationOption} from 'store/exercise/player/interface';
import {
  loadPronunciationSound,
  type LoadPronunciationSoundResponse
} from 'store/exercise/player/widgets/Vocabulary/action';

const messages = defineMessages({
  RequestError: {
    id: 'XPlayerXWidget.Vocabulary.LoadPronunciationSoundError'
  },
  PlayError: {
    id: 'XPlayerXWidget.Vocabulary.PlayPronunciationSoundError'
  }
});

export type PronunciationPlayerStatus = 'loading' | 'playing' | null;

interface State {
  soundUrl?: string;
  status: PronunciationPlayerStatus;
}

interface DispatchProps {
  loadSound(soundId: number): Promise<LoadPronunciationSoundResponse>;
}

interface OwnProps {
  soundUrl?: string;
  soundId?: number;
  pronunciation?: PronunciationOption | Pronunciation;
  renderContent?: FC<{
    playSound(): void;
    status: PronunciationPlayerStatus;
  }>;
  onStatusChange?(status: PronunciationPlayerStatus): void;
}

type Props = PropsWithChildren<OwnProps> & DispatchProps & WrappedComponentProps;

class PronunciationSoundPlayer extends Component<Props, State> {
  public state: State = {soundUrl: this.props.soundUrl, status: null};

  private audioEl: HTMLAudioElement | null;
  private request: CancellablePromise;

  public componentWillUnmount() {
    this.request?.cancel();
  }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.onStatusChange && prevState.status !== this.state.status) {
      this.props.onStatusChange(this.state.status);
    }
    if (prevProps.soundId !== this.props.soundId) {
      if (this.request) {
        this.request.cancel();
      }
      this.setState({soundUrl: this.props.soundUrl, status: null});
    }
    if (prevProps.pronunciation?.id !== this.props.pronunciation?.id) {
      this.setState({soundUrl: this.props.pronunciation?.url, status: null});
    }
  }

  public render() {
    const {soundUrl, status} = this.state;
    const {renderContent, children} = this.props;
    return (
      <>
        {renderContent ? renderContent({playSound: this.playSound, status}) : children}
        <audio
          controls={false}
          src={soundUrl}
          ref={this.getRef}
          key="1"
          onEnded={this.onEnded}
          onError={this.handleAudioTagError}
        />
      </>
    );
  }

  public playSound = () => {
    const {pronunciation, soundId} = this.props;
    const {soundUrl, status} = this.state;

    if (!this.audioEl) return;

    if (soundUrl) {
      if (status !== 'playing') {
        this.setState({status: 'playing'});
      }
      playMedia(this.audioEl);
      return;
    }

    if (pronunciation) {
      this.setState(
        {status: 'playing', soundUrl: pronunciation.url},
        () => this.audioEl && playMedia(this.audioEl)
      );
      return;
    }

    if (soundId) {
      this.setState({status: 'loading'});
      this.request = makeCancellable(
        this.props.loadSound(soundId),
        this.handleResponse,
        this.handleRequestError
      );
    }
  };

  private getRef = (el: HTMLAudioElement | null) => (this.audioEl = el);

  private onEnded = () => {
    this.setState({status: null});
  };

  private handleResponse = (action: LoadPronunciationSoundResponse) => {
    this.setState({soundUrl: action.payload.data.url, status: 'playing'}, () => this.playSound());
  };

  private handleRequestError = () => {
    const {intl} = this.props;
    this.setState({status: null});
    toastr.error('', intl.formatMessage(messages.RequestError));
  };

  private handleAudioTagError = () => {
    const {intl} = this.props;
    this.setState({soundUrl: undefined, status: null});
    toastr.error('', intl.formatMessage(messages.PlayError));
  };
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchProps => ({
  loadSound: (soundId: number) =>
    dispatch<LoadPronunciationSoundResponse>(loadPronunciationSound(soundId))
});

export default connect(null, mapDispatchToProps, null, {forwardRef: true})(
  injectIntl(PronunciationSoundPlayer, {forwardRef: true})
);

export type IntlPronunciationSoundPlayer = PronunciationSoundPlayer;
