import React, {forwardRef, type PropsWithChildren, useCallback, useEffect} from 'react';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {
  PaintTool,
  useFocused,
  usePaint,
  withPaint,
  ToolsPaint,
  SelectablePaint
} from '@englex/paint-react';

import {enumValues} from 'helpers/enum';
import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import {toolButtonTitles} from '../titles';
import isShortcut from '../../../../helpers/shortcut';
import {useIsDrawing} from '../../hooks/useIsDrawing';

interface Props {
  className?: string;
  tool: PaintTool;
  toolKey?: string;
  shortcut?: string;
  iconName?: string;
  shouldDisableSelectable?: boolean;
}

export const ToolButton = withPaint(
  forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
    (
      {children, className, tool, toolKey: tk, shortcut, iconName, shouldDisableSelectable = true},
      ref
    ) => {
      const intl = useIntl();
      const paint = usePaint<ToolsPaint>();
      const isFocused = useFocused();
      const isDrawing = useIsDrawing();
      const {active} = paint.tools;

      const onClick = useCallback(() => {
        if (isDrawing()) return;

        if (shouldDisableSelectable) SelectablePaint.disable(paint);

        ToolsPaint.selectTool(paint, tool);
      }, [paint, tool, isDrawing, shouldDisableSelectable]);

      const toolKey = tk || enumValues(PaintTool).indexOf(tool) + 1;

      const sc = shortcut || `mod+opt+${toolKey}`;

      const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
          if (!isFocused || isDrawing()) return;

          if (isShortcut(e, sc)) {
            e.preventDefault();
            e.stopPropagation();
            SelectablePaint.disable(paint);
            ToolsPaint.selectTool(paint, tool);
          }
        },
        [isFocused, isDrawing, paint, sc, tool]
      );

      useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        return () => {
          window.removeEventListener('keydown', onKeyDown);
        };
      });

      return (
        <ToolbarButton
          ref={ref}
          className={classNames(className, {active: active === tool})}
          title={intl.formatMessage(toolButtonTitles[tool])}
          shortcut={sc}
          onClick={onClick}
        >
          {iconName ? <Icon name={iconName} /> : null}
          {children}
        </ToolbarButton>
      );
    }
  ),
  ToolsPaint.is
);
