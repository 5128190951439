import React, {type FC, useContext} from 'react';

import {useChunkIds} from '../hooks/useChunkIds';
import {EditEntryStateContext} from '../shared/contexts/entry/EditEntryContext';
import {DateChunkCheckbox} from '../shared/DateChunkCheckbox';

interface Props {
  updatedAt: string;
  timezone: string;
}

export const DateSeparatorController: FC<Props> = React.memo(({timezone, updatedAt}) => {
  const chunkIds = useChunkIds(timezone, updatedAt);
  const {selectionMode} = useContext(EditEntryStateContext);

  if (!selectionMode) return null;

  return <DateChunkCheckbox chunkIds={chunkIds} selectionMode={selectionMode} />;
});
