import {type Editor} from '@englex/slate-react';
import {
  type Annotation,
  Editor as EditorController,
  type Range,
  type RangeType
} from '@englex/slate';

import {getEditorId, type SlateAnnotation} from '../../../interface';
import {genKey} from '../../../utils';
import {type Role} from '../../../../../store/interface';
import {scrollToElement} from '../../../../Pointer/selection/helpers';

interface CreateOptions {
  role: Role;
  key?: string;
  type: SlateAnnotation;
  range?: Range;
  callback?: (change: Editor, annotation: Annotation) => void;
}

export const SelectionPointerEditor = {
  scrollToAnnotation(change: Editor, annotation: Annotation) {
    scrollToElement(annotation.key);
  },
  findRangeOfDOMElement(editor: Editor, element: Element): Range | null {
    const {
      value,
      value: {document}
    } = editor;
    try {
      // silently try to find triple clicked text node in slate and when found get and return range of this node
      const clickedNode = editor.findNode(element);
      const nodePath = clickedNode && document.getPath(clickedNode);
      const closestBlock = nodePath && document.getClosestBlock(nodePath);
      const closestBlockPath = closestBlock && document.getPath(closestBlock);

      if (closestBlockPath) {
        const e = new EditorController({value});
        const block = e.value.document.getNode(closestBlockPath);
        if (block) {
          e.moveToRangeOfNode(block);
          const nodeRange = e.value.selection?.toRange() as Range;
          if (nodeRange?.isSet && nodeRange.isExpanded) {
            return nodeRange;
          }
        }
      }
    } catch {}
    return null;
  },
  blurEditor(change: Editor, event: MouseEvent) {
    const target = event.target as Node;

    if (target) {
      const id = change.query<string>(getEditorId);
      const node = document.getElementById(id);

      if (node && !node.contains(target)) {
        change.blur();
        SelectionPointerEditor.clearSelection();
      }
    }
  },
  clearSelection() {
    const selection = window.getSelection();

    if (selection?.empty) return selection.empty();

    if (selection?.removeAllRanges) return selection.removeAllRanges();
  },
  createAnnotation(change: Editor, {key, type, range, callback}: CreateOptions) {
    if (!range) return;

    const {document} = change.value;
    const {anchor, focus} = range;

    const annotation = document.createAnnotation({
      key: key || genKey(),
      type,
      anchor,
      focus,
      data: {timestamp: new Date().getTime()}
    });

    change.withoutSaving(() => change.addAnnotation(annotation));

    if (callback) {
      callback(change, annotation);
    }
  },
  deleteAnnotation(change: Editor, types: SlateAnnotation | SlateAnnotation[]) {
    const annotations = change.value.annotations.filter((annotation: Annotation) =>
      Array.isArray(types)
        ? types.some((type: SlateAnnotation) => type === annotation.type)
        : annotation.type === types
    );

    change.withoutSaving(() =>
      annotations.forEach((annotation: Annotation) => change.removeAnnotation(annotation))
    );
  },
  selectRange(change: Editor, range: RangeType) {
    change.withoutSaving(() => {
      change.select(range);
      change.focus();
    });
  },
  selectRangeByAnnotation(change: Editor, annotation: Annotation) {
    const annotationRange = change.value.document.createRange({
      anchor: annotation.anchor,
      focus: annotation.focus
    });

    SelectionPointerEditor.selectRange(change, annotationRange);
  },
  resetRangeBySelection(
    change: Editor,
    selection: Selection | null | undefined,
    collapse: boolean = true
  ) {
    if (!selection) return;

    try {
      const range = change.findRange(selection);

      if (range) {
        SelectionPointerEditor.selectRange(change, collapse ? range.moveToAnchor() : range);
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn("Couldn't find the range");
    }
  }
};
