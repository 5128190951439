import {useMemo} from 'react';
import {useIntl, type IntlShape} from 'react-intl';
import * as yup from 'yup';

export const loginSchema = (intl: IntlShape) =>
  yup.object().shape({
    email: yup
      .string()
      .email(intl.formatMessage({id: 'Login.Form.Email.InvalidEmail'}))
      .required(intl.formatMessage({id: 'Login.Form.Email.Required'})),
    password: yup.string().required(intl.formatMessage({id: 'Login.Form.Password.Required'}))
  });

export const useLoginSchema = () => {
  const intl = useIntl();
  return useMemo(() => loginSchema(intl), [intl]);
};
