import * as yup from 'yup';
import {type IntlShape} from 'react-intl';
import {Map, Set} from 'immutable';

import {type WidgetFormattedTextJSON} from 'store/exercise/player/widgets/FormattedText/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../player/interface';
import {type AudioWidgetJSON} from '../../../player/widgets/Audio/interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import validationMessages from '../i18n';
import {audioExists, documentNotEmpty} from '../validation';
import {type AudioFile} from '../../../../../components/media/interface';
import {taskExcerpt, contentExcerpt} from '../excerpt';
import {type XAudioProperties} from './interface';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

class XAudioRecord extends XFormattedTextRecord implements XAudioProperties {
  private static isNotNewlyConstructedWidget(
    json: AudioWidgetJSON | WidgetFormattedTextJSON
  ): json is AudioWidgetJSON {
    return Boolean((json as AudioWidgetJSON).audio);
  }

  public declare readonly audioId?: number;

  public declare readonly audio?: AudioFile;

  constructor(raw: AudioWidgetJSON | WidgetFormattedTextJSON) {
    super(raw);
    this.initValues({
      audioId: XAudioRecord.isNotNewlyConstructedWidget(raw) ? raw.audio.id : undefined
    });
  }

  public toJSON(options?: WidgetToJSONOptions): AudioWidgetJSON | WidgetFormattedTextJSON {
    return {
      ...super.toJSON(options),
      audio: this.audioId ? {id: this.audioId} : undefined
    };
  }

  public get type() {
    return WidgetType.AUDIO;
  }

  public get excerpt(): string {
    const task = this.task ? taskExcerpt(this.task) : '';
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const label = '[Audio]';
    return `${task} ${label} ${contentExcerpt(this.content, task.length + label.length)}`;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      audio: yup
        .mixed()
        .test(
          'Should contain audio',
          intl.formatMessage(validationMessages.AudioExists),
          (v: AudioFile) => audioExists(v)
        ),
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }

  public get media(): MediaMeta | undefined {
    if (!this.audioId) {
      return undefined;
    }
    return Map({[WidgetMediaType.AUDIOS]: Set([this.audioId])}) as MediaMeta;
  }
}

decorate(XAudioRecord, {
  audio: property(undefined),
  audioId: property(undefined)
});
record()(XAudioRecord);
export default XAudioRecord;
