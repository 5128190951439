import {type Map, type List} from 'immutable';
import {type ValueJSON} from '@englex/slate';

import {type DataMap} from 'components/Slate/interface';

import {type ChoicesMap} from '../../interface';
import {
  type FormattedTextProperties,
  type WidgetFormattedTextJSON
} from '../FormattedText/interface';

export enum MatchingType {
  DEFAULT = 'Default',
  FREE_CHOICE = 'FreeChoice',
  NO_CATEGORIES = 'NoCategories'
}

interface MatchingChoice {
  value: string;
}

export interface WidgetMatchingAnswersJSON {
  [id: string]: string[];
}

export interface WidgetMatchingChoicesJSON {
  [id: string]: MatchingChoice;
}

export interface QuestionBlockJSON {
  id: string;
  category: string;
  answers: string[];
}

export type QuestionBlockMap = DataMap<{
  id: string;
  category: string;
  answers: List<string>;
}>;

interface MatchingAdditionalProperties {
  dropAnswer(questionId: string, choiceId: string): this;
  returnAnswer(choiceId: string): this;
  getMatchingValue(questionId: string): List<string> | undefined;
  choiceIsUsed(choiceId?: string): boolean;
  receivedChoicesWithThisValue(questionId: string, value: string): List<string>;
}

export interface MatchingBaseJSON extends WidgetFormattedTextJSON {
  answers?: WidgetMatchingAnswersJSON;
  choices?: WidgetMatchingChoicesJSON;
  matchingType?: MatchingType;
}

export interface MatchingBaseProperties<
  A extends Map<string, List<string>> | undefined,
  V extends Map<string, List<string>> | List<QuestionBlockMap> | undefined,
  C extends ChoicesMap | undefined
> extends FormattedTextProperties {
  readonly answers?: A;
  readonly values?: V;
  readonly choices?: C;
  readonly matchingExamples?: ChoicesMap;
  readonly matchingType?: MatchingType;
}

export interface MatchingJSON extends MatchingBaseJSON {
  values?: WidgetMatchingAnswersJSON;
}

export interface MatchingProperties
  extends MatchingBaseProperties<
      Map<string, List<string>> | undefined,
      Map<string, List<string>> | undefined,
      ChoicesMap | undefined
    >,
    MatchingAdditionalProperties {}

export interface MatchingNoCategoriesJSON extends Omit<MatchingBaseJSON, 'values'> {
  values?: QuestionBlockJSON[];
}

export interface MatchingNoCategoriesProperties
  extends MatchingBaseProperties<
      undefined,
      List<QuestionBlockMap> | undefined,
      ChoicesMap | undefined
    >,
    MatchingAdditionalProperties {
  changeCategory(id: string, category: string): this;
  addCategory(categoryId: string): this;
  deleteCategory(categoryId: string): this;
  getQuestionBlock(questionId: string): QuestionBlockMap | undefined;
}

export interface MatchingFreeChoiceJSON extends MatchingJSON {
  extraContent?: ValueJSON;
}
