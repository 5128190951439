import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type RoomUser} from 'store/interface';

import Icon from '../../components/Icon';
import PrivateUser from './PrivateUser';
import {userFullName} from '../../helpers/user';
import {RTCMessages} from '../../webRTC/components/RTCmessages';

interface SelectedRoomProps {
  showRTCControls: boolean;
  startVideoCall: () => void;
  startAudioCall: () => void;
  isAwaitingAnswer?: boolean;
  compact?: boolean;
  isRoomDeleted: boolean;
  recipient?: RoomUser;
  isRoomPrivate: boolean;
  partnerIsTyping: boolean;

  camId?: string;
  micId?: string;
  shouldDisableCallButtons: boolean;
}

class SelectedRoom extends React.Component<SelectedRoomProps & WrappedComponentProps, {}> {
  public render() {
    const {recipient, isRoomDeleted} = this.props;
    if (!recipient) {
      return <div className="selected-room" />;
    }
    const isShowCallButtons = this.isShowCallButtons();
    if (this.props.isRoomPrivate) {
      const avatarSize = this.props.compact ? 'xs' : 'sm';
      return (
        <div className="selected-room">
          <PrivateUser
            status={isRoomDeleted ? undefined : recipient.userStatus}
            name={userFullName(this.props.recipient!.profile!)}
            avatar={recipient.profile!.avatars[avatarSize]}
            isCompact={this.props.compact}
            showCallButtons={isShowCallButtons}
            onlineState={this.props.recipient?.online}
            className="selected-user"
            {...this.props}
          />
          {isShowCallButtons && this.renderRTCControls()}
        </div>
      );
    } else {
      return <div>Public rooms are not yet implemented.</div>;
    }
  }

  private renderRTCControls = () => {
    return (
      <div className="header-selected-room">
        <Button
          className="btn-circle start-audio-call"
          bsStyle="success"
          disabled={this.props.shouldDisableCallButtons}
          title={this.props.intl.formatMessage(RTCMessages.PartnerVoiceCall)}
          onClick={this.props.startAudioCall}
        >
          <Icon name="phone" size="lg" className="start-audio-call" />
        </Button>
        <Button
          className="btn-circle start-video-call"
          bsStyle="success"
          disabled={this.props.shouldDisableCallButtons || !this.props.camId}
          title={
            this.props.camId
              ? this.props.intl.formatMessage(RTCMessages.PartnerVideoCall)
              : this.props.intl.formatMessage(RTCMessages.NoWebcam)
          }
          onClick={this.props.startVideoCall}
        >
          <Icon name="video-camera" size="lg" className="start-video-call" />
        </Button>
      </div>
    );
  };

  private isShowCallButtons() {
    const {showRTCControls, compact} = this.props;
    return showRTCControls && !compact;
  }
}
export default injectIntl(SelectedRoom);
