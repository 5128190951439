import {useCallback, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';

export interface TextArticlesStory {
  currencyLocationKey?: string;
  set: (key: string, word: string) => void;
  getStoryItem: (key: string) => string;
}

export const useTextArticlesStory = (currentSearchWord: string): TextArticlesStory => {
  const {key} = useLocation();

  const [textArticlesStory, setTextArticlesStory] = useState<Record<string, string>>({});
  const [currencyLocationKey, setCurrencyLocationKey] = useState(key);

  const set = useCallback((key: string, word: string): void => {
    setTextArticlesStory(textArticlesStory => ({...textArticlesStory, [key]: word}));
  }, []);

  const getStoryItem = useCallback(
    (key: string) => {
      return textArticlesStory[key];
    },
    [textArticlesStory]
  );

  useEffect(() => {
    if (key && currentSearchWord) {
      set(key, currentSearchWord);
    }
    setCurrencyLocationKey(key);
  }, [set, key, currentSearchWord]);

  return {
    currencyLocationKey,
    set,
    getStoryItem
  };
};
