import {type AxiosRequestAction} from 'services/axios/interface';
import {type Role} from 'store/interface';

const prefix = 'login/';

export const LOGIN_REQUEST = prefix + 'REQUEST';

export interface LoginResponse {
  token: string;
  payload?: {authid: number; authroles: Role[]};
}

export const loginRequest = (email: string, password: string): AxiosRequestAction => ({
  type: LOGIN_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: '/v2/dashboard/login',
      data: {email, password}
    }
  }
});
