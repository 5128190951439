import type TextAlign from './TextAlign';
import TextAlignJustify from './TextAlignJustify';
import TextAlignLeft from './TextAlignLeft';
import TextAlignCenter from './TextAlignCenter';
import TextAlignRight from './TextAlignRight';
import {type RendererPlugin} from '../../interface';

class TextAlignment implements RendererPlugin {
  public plugins: TextAlign[] = [
    new TextAlignLeft(),
    new TextAlignCenter(),
    new TextAlignRight(),
    new TextAlignJustify()
  ];
}

export default TextAlignment;
