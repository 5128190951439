import React, {Component, type SyntheticEvent} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';

import Icon from '../../Icon';
import './ConfirmModal.scss';

interface Defaults {
  keyboard: boolean;
  headerText: string | React.ReactElement;
  onAccept: (() => void) | ((e: SyntheticEvent<Button>) => void);
  onDecline: () => void;
}

interface Props extends Defaults {
  show?: boolean;
  bodyText?: string | React.ReactElement;
  disableButtons: boolean;
  className?: string;
  acceptButtonText?: string;
  declineButtonText?: string;
  hideCloseButton?: boolean;
  headerIcon?: React.ReactElement;
  inverted?: boolean;
}

export default class Confirm extends Component<Props> {
  public static defaultProps: Defaults = {
    keyboard: true,
    headerText: '',
    onAccept: () => null,
    onDecline: () => null
  };

  public render() {
    const {
      headerIcon,
      onDecline,
      headerText,
      bodyText,
      acceptButtonText,
      declineButtonText,
      keyboard,
      show,
      inverted,
      onAccept,
      className,
      hideCloseButton,
      disableButtons
    } = this.props;
    return (
      <Modal
        backdrop="static"
        show={show}
        onHide={onDecline}
        className={classNames('confirm-modal', {inverted: inverted}, className)}
        keyboard={keyboard}
      >
        <Modal.Header onClick={this.stopPropagation}>
          <Modal.Title>
            {!hideCloseButton && (
              <a
                onClick={
                  disableButtons
                    ? this.emptyFunction
                    : e => {
                        e.preventDefault();
                        e.stopPropagation();
                        onDecline();
                      }
                }
                className="close-button"
              >
                <Icon name="pc-multiply" tag="i" />
              </a>
            )}
            {headerIcon} {headerText}{' '}
          </Modal.Title>
        </Modal.Header>
        {bodyText ? <Modal.Body onClick={this.stopPropagation}>{bodyText}</Modal.Body> : null}
        <Modal.Footer onClick={this.stopPropagation}>
          <Button
            bsSize="sm"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onDecline();
            }}
            disabled={disableButtons}
          >
            {declineButtonText || <FormattedMessage id="Common.No" />}
          </Button>
          <Button
            bsSize="sm"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              onAccept(e);
            }}
            bsStyle="primary"
            disabled={disableButtons}
          >
            {acceptButtonText || <FormattedMessage id="Common.Yes" />}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  private stopPropagation = (e: SyntheticEvent<{}, MouseEvent>) => e.stopPropagation();

  private emptyFunction = () => null;
}
