import {List, Map} from 'immutable';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';

import {genKey} from 'components/Slate/utils';
import {type WidgetToJSONOptions} from 'store/exercise/player/interface';
import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseCardJSON,
  type WordPictureSetBaseJSON,
  type WordPictureSetBaseMetaDataJSON,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseProps,
  type WordPictureSetBaseValues
} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';

import XWidgetRecord from '../XWidgetRecord';
import type XWordPictureSetBaseCardRecord from './XWordPictureSetBaseCardRecord';
import {type CardSizeType} from './XPictureSet/interface';
import validationMessages from '../i18n';
import {documentNotEmpty} from '../validation';
import {WPSTheme} from './baseInterface';
import {getObjectWithNewIds} from '../getObjectWithNewIds';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

interface Props
  extends WordPictureSetBaseProps<
    WordPictureSetBaseCardId,
    WordPictureSetBaseMetaDataJSON<WordPictureSetBaseCardId>,
    WordPictureSetBaseCardJSON
  > {}

abstract class XWordPictureSetBaseRecord<
  I extends WordPictureSetBaseCardId,
  V extends WordPictureSetBaseValues<I, WordPictureSetBaseMetaDataProperties<I>>,
  C extends XWordPictureSetBaseCardRecord
> extends XWidgetRecord {
  public declare readonly cards: Map<string, C>;
  public declare readonly isDefaultHidden: boolean;
  public declare readonly isNotInteractive: boolean;
  public declare readonly defaultValues: V;
  public declare readonly widgetTheme: WPSTheme;

  abstract createCard(cardJSON: WordPictureSetBaseCardJSON): C;

  constructor(raw: Props) {
    super(raw);
    this.initValues({
      widgetTheme: raw.widgetTheme || WPSTheme.ORANGE
    });
  }

  public toJSON(
    options?: WidgetToJSONOptions
  ): WordPictureSetBaseJSON<I, WordPictureSetBaseMetaDataJSON<I>, WordPictureSetBaseCardJSON> {
    const {cards, defaultValues} = this.getCardsAndValues(options);

    return {
      ...super.toJSON(options),
      cards,
      isDefaultHidden: this.isDefaultHidden,
      isNotInteractive: this.isNotInteractive,
      defaultValues,
      widgetTheme: this.widgetTheme
    };
  }

  private getCardsAndValues(options?: WidgetToJSONOptions) {
    const cards = this.cards.map((card: C) => card.toJSON()).toObject();
    const cardIds = (this.defaultValues.get('cardIds') as List<I>).toArray();
    const isCollaborativeManagement = this.defaultValues.get(
      'isCollaborativeManagement'
    ) as boolean;
    const defaultValues = {cardIds, isCollaborativeManagement};

    if (options?.generateIdentifiers) {
      const objWithNewIds = getObjectWithNewIds({cards, defaultValues});

      return {cards: objWithNewIds.cards, defaultValues: objWithNewIds.defaultValues};
    }

    return {cards, defaultValues};
  }

  public changeIsDefaultHidden() {
    return this.set('isDefaultHidden', !this.isDefaultHidden);
  }

  public switchIsNotInteractive() {
    return this.set('isNotInteractive', !this.isNotInteractive).set('displayButton', undefined);
  }

  public removeCard(cardId: string) {
    const cardIds = this.defaultValues.get('cardIds') as List<I>;
    const cardIndex = cardIds.findIndex(card => card?.cardId === cardId);
    const newCardIds = cardIds.delete(cardIndex);

    return this.set('cards', this.cards.delete(cardId)).set(
      'defaultValues',
      this.defaultValues.set('cardIds', newCardIds)
    );
  }

  public addCard() {
    const cardId = genKey();

    const newCardIds = (this.defaultValues.get('cardIds') as List<I>).push({cardId} as I);

    return this.set('cards', this.cards.set(cardId, this.createCard({id: cardId}))).set(
      'defaultValues',
      this.defaultValues.set('cardIds', newCardIds)
    );
  }

  public changeCardSizeType(cardId: string, newCardSizeType: CardSizeType) {
    return this.setIn(['cards', cardId, 'cardSizeType'], newCardSizeType);
  }

  public moveCard(moveCardIndex: number, targetCardIndex: number) {
    const moveCardId = (this.defaultValues.get('cardIds') as List<I>).get(moveCardIndex);
    const newCardIds = (this.defaultValues.get('cardIds') as List<I>)
      .delete(moveCardIndex)
      .insert(targetCardIndex, moveCardId);
    return this.set('defaultValues', this.defaultValues.set('cardIds', newCardIds));
  }

  public rollBackCards(cardIds: List<I>) {
    return this.set('defaultValues', this.defaultValues.set('cardIds', cardIds));
  }

  public changeWidgetTheme(widgetTheme: WPSTheme) {
    return this.set('widgetTheme', widgetTheme);
  }

  public createCards = (
    raw: WordPictureSetBaseProps<I, WordPictureSetBaseMetaDataJSON<I>, WordPictureSetBaseCardJSON>
  ): Map<string, C> => {
    if (Map.isMap(raw.cards)) return raw.cards as Map<string, C>;

    const cards = Object.keys(raw.cards).reduce((cards, key) => {
      return {...cards, [key]: this.createCard(raw.cards[key])};
    }, {});

    return Map(cards);
  };

  public createValues = (defaultValues: WordPictureSetBaseMetaDataJSON<I>) => {
    if (Map.isMap(defaultValues)) return defaultValues as unknown as V;
    const newCardIds = List.isList(defaultValues)
      ? (defaultValues as unknown as List<I>)
      : Array.isArray(defaultValues)
        ? (List(defaultValues) as List<I>)
        : (List(defaultValues.cardIds) as List<I>);

    return Map([
      ['isCollaborativeManagement', !!defaultValues?.isCollaborativeManagement],
      ['cardIds', newCardIds]
    ]);
  };

  public createNewDefaultValue = () => {
    return Map([
      ['isCollaborativeManagement', false],
      ['cardIds', List()]
    ]);
  };

  public schema(intl: IntlShape): yup.AnyObjectSchema {
    return yup.object({
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }
}

decorate(XWordPictureSetBaseRecord, {
  cards: property(Map()),
  isDefaultHidden: property(false),
  isNotInteractive: property(false),
  defaultValues: property(''),
  widgetTheme: property(WPSTheme.ORANGE)
} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
record()(XWordPictureSetBaseRecord);
export default XWordPictureSetBaseRecord;
