import {IMAGE_LOADING_FAILED} from './imageActionTypes';
import {type ChatImageActionCreator} from './interface';

export const imageLoadingFailed: ChatImageActionCreator = (
  roomId: number,
  messageId: number,
  imageId: string
) => ({
  type: IMAGE_LOADING_FAILED,
  roomId,
  messageId,
  imageId
});
