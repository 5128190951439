import {type IntlShape} from 'react-intl';
import {List, Map} from 'immutable';
import * as yup from 'yup';

import {
  type ImageMatchingCardJSON,
  type ImageMatchingJSON
} from 'store/exercise/player/widgets/ImageMatching/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {prefillValues} from 'helpers/prefillValues/prefillValues';

import {
  type ChoicesMap,
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../../player/interface';
import {type XImageMatchingProperties, type XImageMatchingProps} from './interface';
import XWidgetRecord from '../../XWidgetRecord';
import validationMessages from '../../i18n';
import genKey from '../../../../../../components/Slate/utils/genKey';
import {valueJSONFromText} from '../../../../../../components/Slate/utils';
import XImageMatchingCardRecord from './XImageMatchingCardRecord';
import {type CardSizeType} from '../../XWordPictureSet/XPictureSet/interface';
import {getObjectWithNewIds} from '../../getObjectWithNewIds';

const minCountIncorrectAnswers = 6;

class XImageMatchingRecord extends XWidgetRecord implements XImageMatchingProperties {
  public static minImagesCount: number = 2;

  public declare readonly cards: List<XImageMatchingCardRecord>;
  public declare readonly preFillValues: {[key: string]: string[]};
  public declare readonly hasPreFillValues: boolean;

  public static fromCards(cards: List<XImageMatchingCardRecord>): XImageMatchingRecord {
    return new XImageMatchingRecord({
      id: genKey(),
      type: WidgetType.IMAGE_MATCHING,
      task: valueJSONFromText(''),
      cards,
      answers: Map([cards.map((c: XImageMatchingCardRecord) => [c.id, c.choiceId])]),
      choices: Map([cards.map((c: XImageMatchingCardRecord) => [c.choiceId, {value: c.phrase}])])
    });
  }

  public schema(intl: IntlShape) {
    return yup.object({
      cards: yup
        .mixed()
        .test(
          'Image should not be blank',
          intl.formatMessage(validationMessages.BlankImages),
          (cards: List<XImageMatchingCardRecord>) =>
            cards.every((c: XImageMatchingCardRecord) => !!c.imageId)
        )
        .test(
          'Images count should be valid',
          intl.formatMessage(validationMessages.Min2ImagesCount, {
            count: XImageMatchingRecord.minImagesCount
          }),
          (cards: List<XImageMatchingCardRecord>) =>
            cards.size >= XImageMatchingRecord.minImagesCount
        )
        .test(
          'Phrase should not be blank',
          intl.formatMessage(validationMessages.BlankWords),
          (cards: List<XImageMatchingCardRecord>) =>
            cards.every((c: XImageMatchingCardRecord) => c.phrase.trim().length > 0)
        )
        .test(
          'Validation of incorrect answers',
          intl.formatMessage(validationMessages.Min6ImagesCount),
          (cards: List<XImageMatchingCardRecord>) =>
            !this.hasPreFillValues ||
            (this.hasPreFillValues && cards.size >= minCountIncorrectAnswers)
        )
    });
  }

  constructor(raw: XImageMatchingProps) {
    super(raw);
    this.initValues({
      id: raw.id || genKey(),
      cards: this.createCards(raw),
      hasPreFillValues: raw.hasPreFillValues || false
    });
  }

  public toJSON(options?: WidgetToJSONOptions): ImageMatchingJSON {
    const {cards, answers, preFillValues} = this.getCardsAndAnswers(options);

    return {
      ...super.toJSON(options),
      cards: cards,
      answers: answers,
      choices: this.choices.toJS(),
      hasPreFillValues: this.hasPreFillValues,
      preFillValues: preFillValues
    };
  }

  private getCardsAndAnswers(options?: WidgetToJSONOptions) {
    const cards = this.cards.map((c: XImageMatchingCardRecord) => c.toCardJSON()).toArray();
    const answers = this.answers.toJS();
    const preFillValues = this.hasPreFillValues ? prefillValues(this.answers.toJS()) : undefined;

    if (options?.generateIdentifiers) {
      const objWithNewIds = getObjectWithNewIds({answers, cards, preFillValues});

      return {
        cards: objWithNewIds.cards,
        answers: objWithNewIds.answers,
        preFillValues: objWithNewIds.preFillValues
      };
    }

    return {cards, answers, preFillValues};
  }

  public get answers(): Map<string, string[]> {
    return Map(this.cards.map((card: XImageMatchingCardRecord) => [card.id, [card.choiceId]]));
  }

  public get choices(): ChoicesMap {
    return Map(
      this.cards
        .sort((c1, c2) => c1.id.localeCompare(c2.id))
        .map((card: XImageMatchingCardRecord) => [card.choiceId, {value: card.phrase}])
    );
  }

  public get type() {
    return WidgetType.IMAGE_MATCHING;
  }

  public get excerpt(): string {
    return `${this.task.document.text} ${this.cards
      .map((c: XImageMatchingCardRecord) => `[Image] ${c.phrase}`)
      .join('; ')}.`.trim();
  }

  public moveCard(moveCardIndex: number, targetIndex: number): XImageMatchingRecord {
    const card = this.cards.get(moveCardIndex);
    const cards = this.cards.delete(moveCardIndex).insert(targetIndex, card);
    return this.set('cards', cards);
  }

  public rollBackCards(cards: List<XImageMatchingCardRecord>): XImageMatchingRecord {
    return this.set('cards', cards);
  }

  public changeCardSizeType(cardId: string, newCardSizeType: CardSizeType) {
    const index = this.cards.findIndex(item => item?.id === cardId);
    return this.setIn(['cards', index, 'cardSizeType'], newCardSizeType);
  }

  private createCards(raw: XImageMatchingProps) {
    if (List.isList(raw.cards)) {
      return raw.cards;
    }
    return List(
      (raw.cards as ImageMatchingCardJSON[]).map((card: ImageMatchingCardJSON) => {
        const choiceId = raw.answers[card.id][0];
        const {imageId, cardSizeType} = card;
        return new XImageMatchingCardRecord({
          id: card.id,
          choiceId,
          imageId,
          phrase: raw.choices[choiceId].value,
          cardSizeType
        });
      })
    );
  }

  public get media(): MediaMeta | undefined {
    const images = this.cards.map((c: XImageMatchingCardRecord) => c.imageId).toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }

  public createNewCard() {
    return this.withMutations(d => {
      d.set('cards', d.cards.push(new XImageMatchingCardRecord({})));
    });
  }

  public togglePreFillValues() {
    return this.set('hasPreFillValues', !this.hasPreFillValues);
  }
}

decorate(XImageMatchingRecord, {
  cards: property(List<XImageMatchingCardRecord>()),
  preFillValues: property(undefined),
  hasPreFillValues: property(undefined)
});
record()(XImageMatchingRecord);
export default XImageMatchingRecord;
