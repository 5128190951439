import {defineMessages} from 'react-intl';
import {type Action, type MiddlewareAPI} from 'redux';

import {type AppState} from 'store/interface';

import {type IncomingCallAction} from '../../../../webRTC/action/interface';
import {userFullName} from '../../../../helpers/user';
import {type Dispatch, type WampAction} from '../../../wamp/actions/interface';

const messages = defineMessages({
  IncomingCallTitle: {
    id: 'Notification.IncomingCallTitle'
  },
  IncomingCallBody: {
    id: 'Notification.IncomingCallBody'
  }
});

export default (
  api: MiddlewareAPI<Dispatch<Action, WampAction>, AppState>,
  action: IncomingCallAction
) => {
  const appState = api.getState();
  if (
    !appState.chat ||
    !appState.chat.rooms ||
    !appState.chat.rooms[action.call.room_id].recipient ||
    !appState.chat.rooms[action.call.room_id].recipient!.profile
  ) {
    return;
  }
  const recipientName = userFullName(appState.chat.rooms[action.call.room_id].recipient!.profile!);
  const title = appState.intl.formatMessage(messages.IncomingCallTitle)
    ? appState.intl.formatMessage(messages.IncomingCallTitle)
    : null;
  const icon = appState.chat.rooms[action.call.room_id].recipient!.profile!.avatars.lg['@2x'];
  const body = appState.intl.formatMessage(messages.IncomingCallBody)
    ? `${appState.intl.formatMessage(messages.IncomingCallBody)} ${recipientName}`
    : null;
  if (title && body && icon) {
    return {
      body,
      title,
      icon,
      hasId: true,
      showOnInactiveTabOnly: true,
      onClick() {
        window.focus();
      }
    };
  }
  return;
};
