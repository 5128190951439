import React, {memo} from 'react';
import classNames from 'classnames';

import {TranslationItemControls} from './TranslationItemControls';
import {useTranslationItemControls} from '../../hooks/useTranslationItemControls';

import './TranslationItem.scss';

const getTextInBrackets = (array: {id: string}[]) => `(${array.map(item => item.id).join(', ')})`;

export type Callback = (entryId: string) => void;

interface Props {
  translation: string;
  includedTranslation?: string;
  domains?: {id: string}[];
  grammaticalFeatures?: {id: string}[];
  dictionaryIsReadonly: boolean;
  addWord: (translation: string, callback?: Callback) => void;
  deleteWord: (translation: string) => void;
}

export const TranslationItem: React.FC<Props> = memo(
  ({
    translation,
    includedTranslation,
    domains,
    grammaticalFeatures,
    dictionaryIsReadonly,
    addWord,
    deleteWord
  }) => {
    const domainsText = domains ? `${getTextInBrackets(domains)}  ` : '';
    const grammarText = grammaticalFeatures ? `${getTextInBrackets(grammaticalFeatures)}` : '';

    const {ref, onInclude, onExclude, getContainer} = useTranslationItemControls({
      translation,
      addWord,
      deleteWord
    });

    return (
      <tr ref={ref} className={classNames('translation-item', {included: includedTranslation})}>
        <td className="translation-item__cell cell-first">
          <div>
            {domainsText && <span className="translation-item__domains">{domainsText}</span>}{' '}
            <span className="translation-item__translation">{translation}</span>{' '}
            {grammarText && <span className="translation-item__grammar">{grammarText}</span>}
          </div>
        </td>
        <td className="translation-item__cell cell-second">
          {!dictionaryIsReadonly && (
            <div>
              <TranslationItemControls
                includedTranslation={includedTranslation}
                onInclude={onInclude}
                onExclude={onExclude}
                getTooltipContainer={getContainer}
              />
            </div>
          )}
        </td>
      </tr>
    );
  }
);
