import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {Map} from 'immutable';

import {
  WIDGET_VALUES_VERSION_UPDATED,
  PERSIST_WIDGET_VALUES,
  PERSIST_WIDGET_VALUES_UNPROCESSED,
  PROCESS_WIDGET_VALUES,
  SEND_WIDGET_VALUES_SUCCESS
} from './actionTypes';
import {
  type WidgetValuesVersionChangedAction,
  type PersistUnprocessedWidgetValuesAction,
  type PersistWidgetValuesAction,
  type ProcessWidgetValuesAction,
  type WidgetInstanceValuesMap
} from './interface';
import {type WidgetInstanceSavedAction} from '../interface';

const REDUCERS: ReducersMapObject = {
  [PERSIST_WIDGET_VALUES]: (
    state: WidgetInstanceValuesMap,
    {widgetValues: {exerciseId, widgetId, values, version, throttle}}: PersistWidgetValuesAction
  ): WidgetInstanceValuesMap => {
    return state.set(`${exerciseId}:${widgetId}`, {values, throttle, version});
  },
  [PROCESS_WIDGET_VALUES]: (
    state: WidgetInstanceValuesMap,
    {widgetKey}: ProcessWidgetValuesAction
  ): WidgetInstanceValuesMap => {
    return state.delete(widgetKey);
  },
  [WIDGET_VALUES_VERSION_UPDATED]: (
    state: WidgetInstanceValuesMap,
    {exerciseId, widgetId}: WidgetValuesVersionChangedAction
  ): WidgetInstanceValuesMap => {
    return state.delete(`${exerciseId}:${widgetId}`);
  },
  [SEND_WIDGET_VALUES_SUCCESS]: (
    state: WidgetInstanceValuesMap,
    {
      wamp: {
        callResult: {
          args: [{version}],
          kwargs: {exerciseId, widgetId}
        }
      }
    }: WidgetInstanceSavedAction
  ): WidgetInstanceValuesMap => {
    const widgetKey = `${exerciseId}:${widgetId}`;
    const widgetValues = state.get(widgetKey);
    if (!widgetValues) {
      return state;
    }
    return state.set(widgetKey, {...widgetValues, version});
  },
  [PERSIST_WIDGET_VALUES_UNPROCESSED]: (
    state: WidgetInstanceValuesMap,
    {widgetKey, widgetValues}: PersistUnprocessedWidgetValuesAction
  ): WidgetInstanceValuesMap => {
    const values = state.get(widgetKey);
    if (values) {
      return state;
    }
    return state.set(widgetKey, widgetValues);
  }
};

function widgetValuesReducer(
  state: WidgetInstanceValuesMap = Map(),
  action: Action
): WidgetInstanceValuesMap {
  const reducer: Reducer<WidgetInstanceValuesMap> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default widgetValuesReducer;
