import {useEffect, useMemo, useState} from 'react';
import {useIntl} from 'react-intl';

import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type AxiosResponseAction} from 'services/axios/interface';
import * as toastr from 'components/toastr';

import {requestContents} from './requestContents';
import {type Options, Status, type UnitContents, type UnitInstanceContents} from './interface';

export const useContentLoader = (listIsOpened: boolean, options?: Options) => {
  const [contents, setContents] = useState<UnitInstanceContents | UnitContents | undefined>(
    undefined
  );
  const [status, setStatus] = useState<Status>(Status.default);

  const intl = useIntl();
  const axiosDispatch = useAxiosDispatch();

  const request = useMemo(() => {
    if (!options) return null;
    const address =
      options.type === 'instance'
        ? `/v2/unit-instance/${options.unitId}/contents`
        : `/v2/coursebook/${options.coursebookId}/unit/${options.unitId}/contents`;
    const params = options.type === 'original' ? {forStudent: options.forStudent} : {};
    return {address, params};
  }, [options]);

  useEffect(() => {
    let cancel = false;
    if (listIsOpened && request?.address) {
      (async () => {
        setStatus(Status.loading);
        try {
          const response: AxiosResponseAction<UnitInstanceContents | UnitContents> =
            await axiosDispatch(requestContents(request.address, request.params));
          if (!cancel) setContents(response.payload.data);
          setStatus(Status.default);
        } catch (e) {
          setStatus(Status.error);
          toastr.error('', intl.formatMessage({id: 'UnitContents.ErrorOpening'}));
        }
      })();
    }
    if (!listIsOpened) {
      setStatus(Status.default);
      setContents(undefined);
    }
    return () => {
      cancel = true;
    };
  }, [axiosDispatch, intl, listIsOpened, request]);

  return {contents, status};
};
