import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {WidgetType} from 'store/exercise/player/interface';

import StyledListRecord from './StyledListRecord';
import {type VerticalStyledListJSON} from './interface';

class VerticalStyledListRecord extends StyledListRecord {
  public declare readonly twoColumns: boolean;

  constructor(raw: VerticalStyledListJSON) {
    super(raw);
    this.initValues({
      twoColumns: !!raw.twoColumns
    });
  }

  public get type() {
    return WidgetType.VERTICAL_STYLED_LIST;
  }
}

decorate(VerticalStyledListRecord, {
  twoColumns: property()
});
record()(VerticalStyledListRecord);
export default VerticalStyledListRecord;
