import React from 'react';
import './volume-scale.scss';

interface Props {
  stream?: MediaStream | null;
}

interface State {
  volume: number;
}

export default class VolumeScale extends React.Component<Props, State> {
  public state: State = {volume: 0};

  private source: MediaStreamAudioSourceNode;
  private script: ScriptProcessorNode;
  private audioContext: AudioContext;

  public componentWillUnmount() {
    this.disconnect();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.stream && (!this.props.stream || prevProps.stream.id !== this.props.stream.id)) {
      this.disconnect();
    }
    if (this.props.stream && (!prevProps.stream || prevProps.stream.id !== this.props.stream.id)) {
      this.audioContext = new AudioContext();
      this.source = this.audioContext.createMediaStreamSource(this.props.stream);
      this.script = this.audioContext.createScriptProcessor(256, 1, 1);
      this.source.connect(this.script);
      this.script.connect(this.audioContext.destination);
      this.script.addEventListener('audioprocess', this.getInstantVolume);
    }
  }

  public render() {
    return (
      <div className="volume-scale">
        <div className="progress" style={{width: `${this.state.volume}%`}} />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
        <span className="scale-divider" />
      </div>
    );
  }

  private disconnect = () => {
    if (this.source && this.script) {
      this.script.removeEventListener('audioprocess', this.getInstantVolume);
      this.source.disconnect();
      this.script.disconnect();
      if (this.audioContext.state === ('running' as AudioContextState)) {
        this.audioContext.close();
      }
    }
  };

  private getInstantVolume = (e: AudioProcessingEvent) => {
    const input = e.inputBuffer.getChannelData(0);
    const sum = input.reduce((currentSum, vol) => currentSum + vol * vol, 0);
    const instantVolume = Math.sqrt(sum / input.length) * 100;
    // parabolic dependency so that scale is more sensible towards changes at low volume level and
    // less sensible towards changes at high volume level
    const volume = Math.pow(instantVolume, 0.75) * 10;
    if (volume > this.state.volume) {
      // if volume level increases, just set it so even the short leaps are indicated
      this.setState({volume});
    } else {
      // if volume level decreases, slow down the bar
      this.setState({volume: (this.state.volume * 12 + volume) / 13});
    }
  };
}
