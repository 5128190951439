import rc from 'jsrsasign';

import {type Role} from 'store/interface';

const publicKey = import.meta.env.REACT_APP_JWT_PUBLIC_KEY;

export function verifyToken(token: string, gracePeriod: number = 7200): boolean {
  try {
    if (import.meta.env.MODE === 'test') {
      gracePeriod = Number.POSITIVE_INFINITY;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return rc.KJUR.jws.JWS.verifyJWT(token, publicKey, {
      alg: ['RS256'],
      gracePeriod
    });
  } catch (e) {
    return false;
  }
}

export function parseToken(token: string): ParsedToken {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (rc as any).KJUR.jws.JWS.parse(token).payloadObj;
}

export interface ParsedToken {
  authid: number;
  authroles: Role[];
  exp: number;
  iat: number;
}

export default verifyToken;
