import {type Action} from 'redux';

import {type Role} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {
  REQUEST_AVAILABLE,
  CHANGE_ROLE,
  SELECT_EXERCISE,
  CLEAR_STATE,
  ATTACH_ADDITIONAL
} from './actionTypes';

export interface ModalAction extends Action {}

export interface SwitchRoleAction extends ModalAction {
  role: Role;
}

export interface SelectExerciseAction extends ModalAction {
  id: string;
}

export const requestAvailableAdditionalExercises = (exerciseId: string): AxiosRequestAction => ({
  type: REQUEST_AVAILABLE,
  payload: {
    client: 'v2',
    request: {
      url: `/v2/exercise-instance/${exerciseId}/additional`,
      params: {
        expand: 'grammar'
      }
    }
  }
});

export const attachAdditionalExercises = (
  exerciseId: string,
  exercises: Array<{
    exerciseId: string;
    homework?: true;
  }>
): AxiosRequestAction => ({
  type: ATTACH_ADDITIONAL,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/exercise-instance/${exerciseId}/additional`,
      data: {exercises}
    }
  }
});

export const changeRole = (role: Role): SwitchRoleAction => ({type: CHANGE_ROLE, role});

export const selectExercise = (id: string): SelectExerciseAction => ({
  type: SELECT_EXERCISE,
  id
});

export const clearState = (): ModalAction => ({
  type: CLEAR_STATE
});
