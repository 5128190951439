import StyledNode from '../StyledNode';
import {
  type JustifyContentValue,
  type SlateBlock,
  type StyledBlock,
  type TextAlignValue
} from '../../../interface';

abstract class TextAlign extends StyledNode<SlateBlock, StyledBlock> {
  public wrapper = 'div';
  public abstract align: TextAlignValue;
  public abstract justify: JustifyContentValue;
}

export default TextAlign;
