import {type FC, useEffect} from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import {FormattedMessage, useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useFormik} from 'formik';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {useLoginSchema} from './login.schema';
import styles from './LoginForm.module.scss';

export interface LoginFormValues {
  email: string;
  password: string;
}

interface Props {
  onSubmit: (values: LoginFormValues) => void;
  loginError?: string;
  isSubmitting?: boolean;
}

const initialValues: LoginFormValues = {
  email: '',
  password: ''
};

export const LoginForm: FC<Props> = ({onSubmit, loginError, isSubmitting}) => {
  const validationSchema = useLoginSchema();
  const intl = useIntl();

  const {handleSubmit, values, errors, handleChange, handleBlur, touched, setFieldError} =
    useFormik<LoginFormValues>({
      validationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      onSubmit,
      initialValues
    });

  const hasErrors = !!Object.entries(errors).filter(([k, v]) => touched[k] && !!v).length;

  useEffect(() => {
    if (loginError) {
      setFieldError('password', loginError);
    }
  }, [intl, loginError, setFieldError]);

  return (
    <div className={styles.block}>
      <div className={classNames(styles.left, 'hidden-xs')} />
      <div className={styles.right}>
        <Icon name="pc-login" className="hidden-xs" />
        <div className={classNames(styles.lock, 'visible-xs')}></div>
        <p className={styles.formTitle}>
          <FormattedMessage id="Login.Form.Title" />
        </p>
        <form onSubmit={handleSubmit} className={styles.loginForm}>
          {hasErrors && (
            <div className={styles.summary}>
              <ul className={styles.ul}>
                {touched.email && errors.email && <li className={styles.li}>{errors.email}</li>}
                {touched.password && errors.password && (
                  <li className={styles.li}>{errors.password}</li>
                )}
              </ul>
            </div>
          )}
          <FormGroup validationState={errors.email ? 'error' : null}>
            <InputGroup>
              <InputGroup.Addon>
                <Icon name="pc-email" size="lg" />
              </InputGroup.Addon>
              <FormControl
                id="email"
                name="email"
                autoComplete="username"
                autoFocus={true}
                bsSize="lg"
                placeholder={intl.formatMessage({id: 'Login.Form.Email.Placeholder'})}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </FormGroup>

          <FormGroup validationState={errors.password ? 'error' : null}>
            <InputGroup>
              <InputGroup.Addon>
                <Icon name="pc-password" size="lg" />
              </InputGroup.Addon>
              <FormControl
                id="password"
                name="password"
                type="password"
                bsSize="lg"
                autoComplete="current-password"
                placeholder={intl.formatMessage({id: 'Login.Form.Password.Placeholder'})}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </InputGroup>
          </FormGroup>

          <div className={styles.actions}>
            <Button bsStyle="primary" type="submit" disabled={isSubmitting}>
              <FormattedMessage id="Login.Form.Submit" />
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};
