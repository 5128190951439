import React, {type FC, useCallback, useEffect, useMemo, useRef} from 'react';
import classNames from 'classnames';

import {type FloatSidebarPosition} from '../FloatSidebar';

import './Sidebar.scss';

interface Props {
  position: FloatSidebarPosition;
  sidebarCollapsed: boolean;
  sidebarPulledOut: boolean;
  layoutCollapsed: boolean;
  breakpoint?: number;
  pullSidebarCb: () => void;
  toggleCollapseCb: (collapse: boolean) => void;
  clickHandler: () => void;
  collapsedButton: (clickHandler: () => void, sidebarCollapsed: boolean) => JSX.Element;
  className?: string;
}

type MQListener = (this: MediaQueryList) => void;

export const SwitchSidebarContext = React.createContext<() => void>(() => {});

export const Sidebar: FC<Props> = ({
  position,
  breakpoint,
  layoutCollapsed,
  sidebarCollapsed,
  collapsedButton,
  className,
  toggleCollapseCb,
  pullSidebarCb,
  sidebarPulledOut,
  clickHandler,
  children
}) => {
  const prevLayoutCollapsed = useRef<boolean>(layoutCollapsed);

  const mq: MediaQueryList = useMemo(
    () => window.matchMedia(`screen and (max-width: ${breakpoint ?? 991}px)`),
    [breakpoint]
  );

  const mqListener = useCallback(
    (mq: MediaQueryList) => {
      toggleCollapseCb(mq.matches);
      if (mq.matches && !layoutCollapsed) {
        pullSidebarCb();
      }
      if (!mq.matches && !sidebarPulledOut && !layoutCollapsed) {
        pullSidebarCb();
      }
    },
    [layoutCollapsed, pullSidebarCb, sidebarPulledOut, toggleCollapseCb]
  );

  useEffect(() => {
    if (mq.addEventListener) {
      mq.addEventListener('change', mqListener as MQListener);
    }

    return () => {
      if (mq.removeEventListener) {
        mq.removeEventListener('change', mqListener as MQListener);
      }
    };
  }, [mq, mqListener]);

  useEffect(() => {
    if (mq.matches) {
      toggleCollapseCb(true);
      pullSidebarCb();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevLayoutCollapsed.current && !layoutCollapsed && !sidebarPulledOut && !mq?.matches) {
      pullSidebarCb();
    }
    if (!prevLayoutCollapsed.current && layoutCollapsed) {
      pullSidebarCb();
    }
  }, [layoutCollapsed, mq, pullSidebarCb, sidebarPulledOut]);

  useEffect(() => {
    prevLayoutCollapsed.current = layoutCollapsed;
  }, [layoutCollapsed]);

  return (
    <div
      className={classNames('float-sidebar', position, className, {
        'sidebar-collapsed': sidebarCollapsed,
        'sidebar-pulled-out': sidebarCollapsed && sidebarPulledOut
      })}
    >
      <SwitchSidebarContext.Provider value={clickHandler}>
        {collapsedButton(clickHandler, sidebarCollapsed)}
        {children}
      </SwitchSidebarContext.Provider>
    </div>
  );
};
