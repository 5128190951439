import React, {type PropsWithChildren} from 'react';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';
import {visibleOnlyTeacher} from 'authentication';

import {dashboardUrl} from '../../helpers/url';
import Icon from '../../components/Icon';
import {type ToggleElementCreator} from '../../common/interface';
import {toggleReportDropdown} from '../actions/action';

interface StateProps {
  isMobile?: boolean;
  isOpen?: boolean;
  className: string;
}

interface DispatchProps {
  toggle: ToggleElementCreator;
}

type Props = PropsWithChildren<StateProps & DispatchProps>;

class ReportDropdown extends React.Component<Props, {}> {
  public render() {
    const href = dashboardUrl();
    if (!href || this.props.isMobile) {
      return null;
    }
    const title = (
      <Button bsStyle="primary" bsSize="sm">
        <FormattedMessage id="Common.Report" />
        <Icon name="virc-note" size="lg" />
        <Icon name="angle-down" />
      </Button>
    );
    return (
      <NavDropdown
        title={title}
        id="report-dropdown"
        className={this.props.className}
        open={this.props.isOpen}
        onToggle={this.toggle}
      >
        <button
          className="report-dropdown-close-button dropdown-close-button"
          onClick={this.toggle}
        >
          <Icon name="pc-close" />
        </button>
        <MenuItem
          href={`${dashboardUrl()}/lesson/report/create`}
          target="_blank"
          className="report-dropdown-item"
        >
          <FormattedMessage id="Header.Lesson" />
        </MenuItem>
        <MenuItem
          href={`${dashboardUrl()}/lesson/lessonIntro/create`}
          target="_blank"
          className="report-dropdown-item"
        >
          <FormattedMessage id="Header.IntroLesson" />
        </MenuItem>
      </NavDropdown>
    );
  }

  private toggle = () => {
    this.props.toggle(!this.props.isOpen);
  };
}

const mapStateToProps = (state: AppState): StateProps => {
  return {
    isMobile: state.layout.isMobile,
    isOpen: state.layout.reportDropdownOpen,
    className: classNames('report-dropdown', 'rounded', {
      'hidden-xs': state.layout.isMobile
    })
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): DispatchProps => ({
  toggle: (show: boolean) => dispatch(toggleReportDropdown(show))
});

const connected = connect(mapStateToProps, mapDispatchToProps)(ReportDropdown);

export default visibleOnlyTeacher(connected);
