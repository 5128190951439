import React, {memo} from 'react';

import {ScrambledItem} from '../ScrambledItem/ScrambledItem';

import './ScrambledList.scss';

interface Props {
  scrambledWord: string[];
  currentScrambleWord: string;
}

export const ScrambledList: React.FC<Props> = memo(({scrambledWord, currentScrambleWord}) => {
  return (
    <div className="scrambled-list">
      {scrambledWord.map(word => (
        <ScrambledItem key={word} word={word} isCurrent={word === currentScrambleWord} />
      ))}
    </div>
  );
});
