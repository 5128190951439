import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import ReactYoutube from 'react-youtube';
import classNames from 'classnames';
import RetinaImage from '@englex/react-retina-image';
import {useDragScroll} from '@englex/react-hooks/lib/useDragScroll';

import {type AppState} from 'store/interface';
import Loader from 'components/Loader';
import IFrameWrapper from 'components/IFrameWrapper';

import {type ImageSlide, type SlideProps, Type, type YTSlide} from './interface';
import {ReactComponent as BrokenSVG} from '../../components/LoadableImage/broken.svg';

const Slide: FC<SlideProps> = ({
  activeResourceId,
  expanded,
  isMobile,
  resource: {id, urls},
  resourceType
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const ref = useDragScroll<HTMLDivElement>(expanded && !isMobile);

  useEffect(() => {
    setLoaded(false);
  }, [id]);

  useEffect(() => {
    if (activeResourceId === id && !initialized) {
      setInitialized(true);
    }
  }, [activeResourceId, id, initialized]);

  if (!initialized) {
    return null;
  }

  return (
    <div className={classNames('viewer-slide', {expanded})}>
      <div className="figure-container" ref={ref}>
        {error ? <BrokenSVG className="broken" /> : !loaded && <Loader />}
        {resourceType === Type.IMAGE ? (
          <Image id={id} loaded={loaded} setLoaded={setLoaded} setError={setError} urls={urls!} />
        ) : (
          <YT
            activeResourceId={activeResourceId}
            id={id}
            loaded={loaded}
            setLoaded={setLoaded}
            setError={setError}
          />
        )}
      </div>
    </div>
  );
};

const Image: FC<ImageSlide> = ({loaded, setLoaded, setError, urls}) => {
  const onLoad = useCallback(() => {
    setLoaded(true);
    setError(false);
  }, [setLoaded, setError]);

  const onError = useCallback(() => setError(true), [setError]);

  return (
    <RetinaImage
      draggable={false}
      src={urls}
      onLoad={onLoad}
      onError={onError}
      className={classNames({loading: !loaded, loaded})}
    />
  );
};

const YT: FC<YTSlide> = ({activeResourceId, id, loaded, setLoaded, setError}) => {
  const renderMask = useSelector<AppState, boolean>(s => !!s.video.transformingMode);

  const isMobile = useSelector<AppState, boolean>(s => !!s.layout.isMobile);

  const opts = useMemo(() => {
    if (isMobile) {
      return {width: '320', height: '180', playerVars: {autoplay: 1}};
    }

    return {width: '640', height: '360', playerVars: {autoplay: 1}};
  }, [isMobile]);

  const onReady = useCallback(() => {
    setLoaded(true);
    setError(false);
  }, [setLoaded, setError]);

  const onError = useCallback(() => setError(true), [setError]);

  useEffect(() => {
    if (activeResourceId !== id && loaded) {
      setLoaded(false);
    }
  });

  return activeResourceId !== id ? null : (
    <IFrameWrapper>
      <div className="YT">
        <ReactYoutube
          videoId={id}
          className={classNames('video-player', {loading: !loaded, loaded})}
          opts={opts}
          onReady={onReady}
          onError={onError}
        />
        {renderMask && <div className="mask" />}
      </div>
    </IFrameWrapper>
  );
};

export default Slide;
