import {useDispatch} from 'react-redux';
import {useEffect} from 'react';

import {togglePagerPopover} from '../../routes/ClassRoom/pages/CourseInstancePage/actions';

export const usePagesPopoverSync = (popoverOpened: boolean) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(togglePagerPopover(popoverOpened));
  }, [dispatch, popoverOpened]);
  useEffect(
    () => () => {
      dispatch(togglePagerPopover(false));
    },
    [dispatch]
  );
};
