import React from 'react';

import {ServiceMessageTypes} from 'common/enums';

import {type MessageType} from './TextMessage';
import Icon from '../../../../Icon';
import {ClientMessageTypes} from '../../../actions/interface';

interface Props {
  type: MessageType;
}

export default class ServiceMessageIcon extends React.PureComponent<Props, {}> {
  public render() {
    switch (this.props.type) {
      case ClientMessageTypes.declined:
      case ServiceMessageTypes.missed:
        return <Icon name="virc-incoming_call" className="red-text" />;
      case ServiceMessageTypes.busy:
      case ClientMessageTypes.noAnswer:
        return <Icon name="virc-outgoing_call" className="red-text" />;
      case ServiceMessageTypes.endCall:
        return <Icon name="virc-call_over" />;
      case ClientMessageTypes.incomingCall:
        return <Icon name="virc-incoming_call" />;
      case ClientMessageTypes.outgoingCall:
        return <Icon name="virc-outgoing_call" />;
      default:
        return null;
    }
  }
}
