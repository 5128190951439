import {record} from 'immutable-record/decorator/record';
import XPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/XPictureSetCardRecord';
import {WidgetType} from 'store/exercise/player/interface';

import {type WordPictureSetBaseCardJSON} from '../baseInterface';
import WordPictureSetBaseRecord from '../WordPictureSetBaseRecord';
import {
  type PictureSetCardMetaDataCardJSON,
  type PictureSetCardMetaDataJSON,
  type PictureSetProperties,
  type PictureSetProps,
  type PictureSetValues
} from './interface';

class PictureSetRecord
  extends WordPictureSetBaseRecord<
    PictureSetCardMetaDataCardJSON,
    PictureSetValues,
    PictureSetCardMetaDataJSON,
    XPictureSetCardRecord
  >
  implements PictureSetProperties
{
  constructor(raw: PictureSetProps) {
    super(raw);

    this.initValues({
      cards: this.createCards(raw),
      values: this.createValue(raw),
      isDefaultHidden: raw.isDefaultHidden,
      isNotInteractive: raw.isNotInteractive,
      defaultValues: this.makeDefaultValues(raw.defaultValues)
    });
  }

  public createCard(cardJSON: WordPictureSetBaseCardJSON) {
    return new XPictureSetCardRecord(cardJSON);
  }

  public switchIsCardHiddenInValue(
    cardMetaData: PictureSetCardMetaDataCardJSON,
    setField?: boolean
  ): PictureSetCardMetaDataCardJSON {
    return {
      ...cardMetaData,
      isImageHidden: setField !== undefined ? setField : !cardMetaData.isImageHidden
    };
  }

  public get type() {
    return WidgetType.PICTURE_SET;
  }
}

record()(PictureSetRecord);
export default PictureSetRecord;
