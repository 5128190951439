import {MaterialsTab} from '../enums';

export type ClassroomUrlParams = {
  studentTeacherId?: string;
  courseId?: string;
};

export type CoursebookInstanceUrlParams = ClassroomUrlParams & {
  coursebookInstanceId: string;
  page?: string;
};

export type UnitInstancePreviewUrlParams = CoursebookInstanceUrlParams & {
  unitInstanceId: string;
  page?: string;
};

export type FilesListSelectedDocPageUrlParams = ClassroomUrlParams & {
  documentId?: string;
  pageNumber?: string;
};

export type UnitInstanceUrlParams = CoursebookInstanceUrlParams & {
  unitInstanceId: string;
  page?: string;
};

export const roomPath = () => '/room/';

export const studentTeacherPattern = `${roomPath()}:studentTeacherId`;
export const studentTeacherPath = (studentTeacherId: number | string) =>
  `${roomPath()}${studentTeacherId}`;

export const studentTeacherAndCoursePath = (
  studentTeacherId: number | string,
  courseId: number | string
) => `${studentTeacherPath(studentTeacherId)}/course/${courseId}`;
export const coursePattern = `${studentTeacherPattern}/course/:courseId`;

export const coursebookInstancePattern = `${coursePattern}/coursebook/:coursebookInstanceId`;
export const coursebookInstancePath = (
  {studentTeacherId, courseId}: ClassroomUrlParams,
  coursebookId: string
) =>
  `${studentTeacherAndCoursePath(
    Number(studentTeacherId),
    Number(courseId)
  )}/coursebook/${coursebookId}`;

export const unitInstancePattern = `${coursebookInstancePattern}/unit/:unitInstanceId/page?/:page?`;
export const unitInstancePatternStandalone = `/xplayer/coursebook/:coursebookInstanceId/unit/:unitInstanceId/page?/:page?`;
export const unitInstancePath = (
  params: CoursebookInstanceUrlParams,
  unitInstanceId: number | string
) => `${coursebookInstancePath(params, params.coursebookInstanceId)}/unit/${unitInstanceId}`;

const pageExercise = (page?: number, exerciseId?: string): string => {
  if (!page) return '';

  return `/page/${page}${exerciseId ? `#${exerciseId}` : ''}`;
};
export const unitInstancePagePath = (
  params: CoursebookInstanceUrlParams,
  unitInstanceId: number | string,
  page: number,
  exerciseId?: string
) => `${unitInstancePath(params, unitInstanceId)}${pageExercise(page, exerciseId)}`;

export const unitInstancePreviewPattern = `${coursebookInstancePattern}/preview/:unitInstanceId`;
export const unitInstancePreviewPath = (
  params: CoursebookInstanceUrlParams,
  unitInstanceId: number,
  page?: number,
  exerciseId?: string
) =>
  `${coursebookInstancePath(
    params,
    params.coursebookInstanceId
  )}/preview/${unitInstanceId}${pageExercise(page, exerciseId)}`;

export const grammarPattern = `${coursebookInstancePattern}/grammar`;
export const grammarPath = (params: CoursebookInstanceUrlParams) =>
  `${coursebookInstancePath(params, params.coursebookInstanceId)}/grammar`;

export const grammarPlayerPattern = `${grammarPattern}/:grammarId?`;
export const grammarPlayerPath = (params: CoursebookInstanceUrlParams, grammarId: string) =>
  `${grammarPath(params)}/${grammarId}`;
export const grammarStandalonePlayerPath = (coursebookInstanceId: string, grammarId: string) =>
  `/xplayer/coursebook/${coursebookInstanceId}/grammar/${grammarId}`;

export const filesListPattern = `${coursePattern}/files`;
export const filesListPath = (
  studentTeacherId: number | string,
  courseId: number | string,
  openedTab?: MaterialsTab
) =>
  `${studentTeacherAndCoursePath(String(studentTeacherId), courseId)}/files${
    openedTab && openedTab !== MaterialsTab.DOCUMENTS ? '?tab=' + openedTab : ''
  }`;

export const filesListSelectedDocPattern = `${filesListPattern}/:documentId/:page?/:pageNumber?`;
export const filesListSelectedDocPath = (
  studentTeacherId: number | string,
  courseId: number | string,
  documentId: number | string,
  pageNumber?: number
) =>
  `${filesListPath(studentTeacherId, courseId)}/${documentId}${
    pageNumber ? `/page/${pageNumber}` : ''
  }`;

export const courseInstanceHomeworkPattern = `${coursePattern}/homework`;
export const homeworkPath = (studentTeacherId: number | string, courseId: number | string) =>
  `${studentTeacherAndCoursePath(studentTeacherId, courseId)}/homework`;

export const homeworkPlayerPattern = `${courseInstanceHomeworkPattern}/:homeworkId`;
export const homeworkPlayerPath = (
  {studentTeacherId, courseId}: ClassroomUrlParams,
  hwId: string,
  page?: number
) =>
  `${studentTeacherAndCoursePath(Number(studentTeacherId), Number(courseId))}/homework/${hwId}${
    page ? '/page/' + page : ''
  }`;
