import React, {type PropsWithChildren} from 'react';
import {RawIntlProvider} from 'react-intl';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

const IntlProvider: React.FC<PropsWithChildren<{}>> = ({children}) => {
  const intl = useSelector(
    (state: AppState) => state.intl,
    (left, right) => left.locale === right.locale && left.hotUpdated === right.hotUpdated
  );
  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
};

export default IntlProvider;
