import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import Helmet from 'react-helmet';

import {push} from 'store/router';
import {isMobileWebView} from 'helpers/browser';
import {WebviewPlayerProvider} from 'contexts/Webview/WebviewPlayerContext';
import {useNode} from 'contexts/Viewer/utils';
import {Resources, ViewerProvider} from 'contexts/Viewer';
import {ImageCacheProvider} from 'components/XPlayer/contexts/imageCacheContext';
import {type HomeworkWithContents} from 'store/interface';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';
import {loadHomework} from 'routes/ClassRoom/pages/HomeworkPlayerPage/actions';
import HomeworkExercise from 'routes/ClassRoom/pages/HomeworkPlayerPage/HomeworkExercise';
import {
  useWebViewMessage,
  type WebViewMessageData,
  WebViewMessageType
} from 'hooks/webview/useWebViewMessage';
import {useApiRequest} from 'hooks/rest/useApiRequest';

import {MobileHomeworkDocumentPage} from './MobileHomeworkDocumentPage';

import './StandaloneUnitPlayer.scss';

const getUrl = (courseId: string, homeworkId: string, page: number) => {
  return `/xplayer/course/${courseId}/homework/${homeworkId}/page/${page}`;
};

const getMax = (exercisesCount = 0, documentPagesCount = 0) => {
  return Math.max(exercisesCount + documentPagesCount, 1);
};

const prev = (courseId: string, homeworkId: string, page: string | number) => {
  return getUrl(courseId, homeworkId, Math.max(Number(page) - 1, 1));
};

const next = (courseId: string, homeworkId: string, page: string | number, max: number) => {
  return getUrl(courseId, homeworkId, Math.min(Number(page) + 1, max));
};

type RouteParams = {
  courseId: string;
  homeworkId: string;
  page?: string;
};
export const StandaloneHomeworkPlayer: FC = () => {
  const dispatch = useDispatch();

  const {courseId, homeworkId, page = 1} = useParams<RouteParams>();

  const resourcesNode = useNode('.standalone-player');

  const [homework, setHomework] = useState<HomeworkWithContents>();

  const max = getMax(homework?.exercises.length, homework?.documentPages.length);

  const action = useMemo(() => loadHomework(courseId!, homeworkId!), [courseId, homeworkId]);

  const {isLoading, isError, reload} = useApiRequest(action, (respData: HomeworkWithContents) =>
    setHomework(respData)
  );

  const onMessage = useCallback(
    (message: WebViewMessageData) => {
      const {type} = message;

      switch (type) {
        case WebViewMessageType.Prev:
          return dispatch(push(prev(courseId!, homeworkId!, page)));

        case WebViewMessageType.Next:
          return dispatch(push(next(courseId!, homeworkId!, page, max)));

        case WebViewMessageType.SetPage:
          const {
            payload: {pageNumber}
          } = message;

          return dispatch(push(getUrl(courseId!, homeworkId!, pageNumber)));

        case WebViewMessageType.Refetch:
          return reload();

        default:
          return;
      }
    },
    [dispatch, reload, courseId, homeworkId, page, max]
  );

  const postMessage = useWebViewMessage(onMessage);

  useEffect(() => {
    postMessage({type: WebViewMessageType.Ready});
  }, [postMessage]);

  const loading = isLoading && !homework;

  return (
    <div className="standalone-player">
      {isMobileWebView() && (
        <Helmet
          bodyAttributes={{class: 'x-player-mobile-webview'}}
          meta={[{name: 'viewport', content: 'initial-scale=1.0, maximum-scale=1.0'}]}
        />
      )}
      {isError ? (
        <WampErrorMask reload={reload} />
      ) : loading || !homework ? (
        <Loader />
      ) : (
        <WebviewPlayerProvider>
          <ImageCacheProvider>
            <ViewerProvider>
              <Resources id="homework-player-page" node={resourcesNode}>
                <HomeworkContent
                  courseId={Number(courseId)}
                  page={Number(page)}
                  homework={homework}
                />
              </Resources>
            </ViewerProvider>
          </ImageCacheProvider>
        </WebviewPlayerProvider>
      )}
    </div>
  );
};

interface HomeworkContentProps {
  page: number;
  courseId: number;
  homework: HomeworkWithContents;
}

const HomeworkContent: React.FC<HomeworkContentProps> = ({courseId, page, homework}) => {
  const {exercises, documentPages} = homework;

  const pageIsDocumentPage = page > exercises.length;

  const selectedDocPage = pageIsDocumentPage
    ? documentPages[page - exercises.length - 1]
    : undefined;

  return selectedDocPage ? (
    <MobileHomeworkDocumentPage
      key={selectedDocPage.documentInstancePageId}
      courseId={courseId}
      homeworkId={homework.id}
      documentInstancePageId={selectedDocPage.documentInstancePageId}
      pageNumber={selectedDocPage.documentInstancePage.pageNumber}
    />
  ) : (
    <HomeworkExercise exerciseId={exercises[Number(page) - 1].exerciseInstanceId} />
  );
};
