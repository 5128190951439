import {type Action, type ActionCreator, type Dispatch} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {appOfflineModalTimeout, visibilityInterval} from 'config/static';
import {type AppState} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';

import {
  APP_OFFLINE,
  APP_ONLINE,
  CLEAR_VISIBILITY_INTERVAL,
  COLLAPSE_LAYOUT,
  REQUEST_MESSAGE_SEND,
  REQUEST_MESSAGES_SUBJECTS,
  REQUEST_NOTIFICATIONS,
  SET_APP_OFFLINE_TIMEOUT,
  SET_IS_MOBILE,
  SET_LAYOUT_NETWORK_ERROR,
  SET_NOTIFICATIONS_NUMBER,
  SET_PAGE_VISIBILITY,
  SET_VISIBILITY_INTERVAL,
  SHOW_APP_OFFLINE_MODAL,
  TOGGLE_CHAT_POPOVER,
  TOGGLE_CONTACTS_MODAL,
  TOGGLE_CONTACTS_POPOVER,
  TOGGLE_NAV_DROPDOWN,
  TOGGLE_REPORT_DROPDOWN,
  TOGGLE_USER_DROPDOWN,
  UPDATE_FRAMES_COUNT,
  CLOSE_NAV_DROPDOWN
} from './actionTypes';
import {type ToggleElementCreator} from '../../common/interface';
import {
  type CollapseLayoutAction,
  type LayoutAction,
  type SetAppOfflineTimeoutCreator,
  type SetIsMobileCreator,
  type SetPageVisibilityActionCreator,
  type SetVisibilityIntervalAction
} from './interface';
import {toggleVideoUndocked} from '../../webRTC/action/action';

export const toggleContactsModal: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_CONTACTS_MODAL,
  show
});

export const requestNotifications = () => ({
  type: REQUEST_NOTIFICATIONS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/user/notifications`
    }
  }
});

export type RequestNotificationsResponseAction = AxiosResponseAction<{count: number}>;

export interface SetNotificationsNumberAction extends Action {
  notificationsNumber: number;
}

export const setNotificationsNumber = (
  notificationsNumber: number
): SetNotificationsNumberAction => ({
  type: SET_NOTIFICATIONS_NUMBER,
  notificationsNumber
});

export const setLayoutNetworkError = (): Action => ({
  type: SET_LAYOUT_NETWORK_ERROR
});

export const requestSubjects = () => ({
  type: REQUEST_MESSAGES_SUBJECTS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/user/contact-manager-subjects',
      headers: {
        'content-type': 'application/json'
      }
    }
  }
});

export const requestMessageSend = (subject: string, message: string, copy?: boolean) => ({
  type: REQUEST_MESSAGE_SEND,
  payload: {
    request: {
      method: 'post',
      url: '/v1/user/contact-manager',
      headers: {
        'content-type': 'application/json'
      },
      data: {
        subject,
        message,
        cc: copy
      }
    }
  }
});

export const toggleContactsPopover: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_CONTACTS_POPOVER,
  show
});

export const toggleUserDropdown: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_USER_DROPDOWN,
  show
});

export const toggleNavDropdown: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_NAV_DROPDOWN,
  show
});

export const closeNavDropdown = () => ({
  type: CLOSE_NAV_DROPDOWN
});

export const setIsMobile: SetIsMobileCreator = (isMobile: boolean) => ({
  type: SET_IS_MOBILE,
  isMobile
});

const appOnline: ActionCreator<LayoutAction> = () => ({
  type: APP_ONLINE
});

const appOffline: ActionCreator<LayoutAction> = () => ({
  type: APP_OFFLINE
});

export const toggleChatPopover: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_CHAT_POPOVER,
  show
});

export const toggleReportDropdown: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_REPORT_DROPDOWN,
  show
});

export const setPageVisibility: SetPageVisibilityActionCreator = (pageHasFocus: boolean) => ({
  type: SET_PAGE_VISIBILITY,
  pageHasFocus
});

export const setVisibilityInterval =
  () => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const {framesCount} = getState().layout;
    if (!framesCount) {
      const action: SetVisibilityIntervalAction = {
        type: SET_VISIBILITY_INTERVAL,
        intervalId: setInterval(() => {
          const focus = document.hasFocus();
          if (focus !== getState().layout.pageHasFocus) {
            dispatch(setPageVisibility(focus));
          }
        }, visibilityInterval)
      };
      dispatch(action);
    } else {
      dispatch({
        type: UPDATE_FRAMES_COUNT,
        add: true
      });
    }
  };

export const clearVisibilityInterval =
  () => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const {framesCount, intervalId} = getState().layout;
    if (intervalId && framesCount === 1) {
      clearInterval(intervalId);
      dispatch({type: CLEAR_VISIBILITY_INTERVAL});
    } else {
      dispatch({
        type: UPDATE_FRAMES_COUNT,
        add: false
      });
    }
  };

const showAppOfflineModal = () => ({
  type: SHOW_APP_OFFLINE_MODAL
});

const setAppOfflineTimeout: SetAppOfflineTimeoutCreator = timeoutId => ({
  type: SET_APP_OFFLINE_TIMEOUT,
  timeoutId
});

export const setAppOfflineAndTimeout: ThunkAction<void, AppState, void, Action> = dispatch => {
  dispatch(appOffline());
  const timeout = setTimeout(() => dispatch(showAppOfflineModal()), appOfflineModalTimeout);
  dispatch(setAppOfflineTimeout(timeout));
};

export const setAppOnlineAndClearTimeout: ThunkAction<void, AppState, void, Action> = (
  dispatch,
  getState
) => {
  dispatch(appOnline());
  const state = getState();
  clearTimeout(state.layout.appOfflineTimeout!);
};

export const collapseLayout =
  (collapse: boolean): ThunkAction<void, AppState, void, Action> =>
  (dispatch, getState) => {
    const action: CollapseLayoutAction = {
      type: COLLAPSE_LAYOUT,
      collapse
    };
    dispatch(action);
    const {
      rtc: {call, badBrowser},
      video: {undocked}
    } = getState();
    if (!call || badBrowser) {
      return;
    }
    if (collapse && !undocked) {
      dispatch(toggleVideoUndocked(true));
    }
    if (!collapse && undocked) {
      dispatch(toggleVideoUndocked(false));
    }
  };
