import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import Tab from 'react-bootstrap/lib/Tab';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';
import {FormattedMessage} from 'react-intl';
import {matchPath} from 'react-router-dom';

import {type AppState, type StudentTeachers} from 'store/interface';
import {studentTeacherPattern, dictionaryPath} from 'common/paths';
import RTCComponent from 'webRTC/containers/RTCComponent';
import IndockButton from 'webRTC/components/IndockButton';
import Chat from 'components/Chat';
import Icon from 'components/Icon';
import {Dictionary} from 'components/Dictionary/Sidebar/SidebarDictionary';
import {getUnreadMessagesCount} from 'components/Chat/reducer/reducer';
import {DictionaryContextProvider} from 'components/Dictionary/shared/contexts';

import {type SelectCallback} from '../interface';
import {FloatSidebar} from '../FloatSidebar/FloatSidebar';
import LessonSidebarCollapsed from './LessonSidebarCollapsed';
import {LessonSidebarTab} from './interfaces';

import './LessonSidebar.scss';

interface StateProps {
  shouldRenderIndockVideoBtn?: boolean;
  sidebarCollapsed?: boolean;
  studentTeachers?: StudentTeachers;
  chatUnreadMessagesCount: number;
  pathname: string;
  studentId?: number;
}

interface State {
  selectedTab: LessonSidebarTab;
}

interface Props extends StateProps {}

class LessonSidebar extends React.Component<Props, State> {
  private get shouldHideSwitcher() {
    return matchPath({path: dictionaryPath, end: false}, this.props.pathname);
  }
  public state: State = {selectedTab: LessonSidebarTab.CHAT};

  private get selectedStudentTeacher() {
    const {studentTeachers, pathname} = this.props;
    const studentTeacherMatch = matchPath({path: studentTeacherPattern, end: false}, pathname);
    const matchHomework = matchPath('/homework', pathname);

    if (matchHomework && studentTeachers) return studentTeachers[Object.keys(studentTeachers)[0]];

    if (!studentTeacherMatch) return undefined;
    const {params} = studentTeacherMatch;
    if (!studentTeachers || !params.studentTeacherId || !studentTeachers[params.studentTeacherId])
      return undefined;
    return studentTeachers[params.studentTeacherId];
  }

  public componentDidUpdate(prevProps: Props) {
    if (this.shouldHideSwitcher && this.state.selectedTab === LessonSidebarTab.DICTIONARY) {
      this.setState({selectedTab: LessonSidebarTab.CHAT});
    }
  }

  public render() {
    const {shouldRenderIndockVideoBtn, sidebarCollapsed, chatUnreadMessagesCount, studentId} =
      this.props;
    const selectedTab = this.state.selectedTab;
    return (
      <FloatSidebar
        className="lesson-sidebar"
        collapsedButton={(clickHandler, sidebarCollapsed) => (
          <LessonSidebarCollapsed
            pullOutSidebar={clickHandler}
            shouldBeRendered={sidebarCollapsed || false}
            unreadMessagesCount={chatUnreadMessagesCount}
          />
        )}
      >
        <RTCComponent selectedTab={selectedTab} />
        {shouldRenderIndockVideoBtn && <IndockButton />}
        <Tab.Container
          id="lesson-sidebar-tabs"
          activeKey={selectedTab}
          onSelect={this.onTabSelect as SelectCallback}
        >
          <div className="tabs-container">
            <Tab.Content
              className={classNames('tab-content-lesson-sidebar', {collapsed: sidebarCollapsed})}
            >
              <Tab.Pane eventKey={LessonSidebarTab.CHAT}>
                <Chat unreadMessagesCount={chatUnreadMessagesCount} />
              </Tab.Pane>
              <Tab.Pane eventKey={LessonSidebarTab.DICTIONARY}>
                {selectedTab === LessonSidebarTab.DICTIONARY ? (
                  <DictionaryContextProvider
                    dictionaryOwnerId={studentId || Number(this.selectedStudentTeacher?.student_id)}
                    isTeacherDictionary={false}
                  >
                    <Dictionary selectedStudentTeacher={this.selectedStudentTeacher} />
                  </DictionaryContextProvider>
                ) : null}
              </Tab.Pane>
            </Tab.Content>
            <Nav
              bsStyle="tabs"
              className={classNames('lesson-sidebar-tab-switcher', {
                'is-hidden': this.shouldHideSwitcher
              })}
            >
              <NavItem eventKey={LessonSidebarTab.CHAT}>
                <Icon name="virc-chat-handler" />
                {chatUnreadMessagesCount > 0 && selectedTab !== LessonSidebarTab.CHAT && (
                  <span className="unread-messages-indicator" />
                )}
                <span className="tab-name">
                  <FormattedMessage id="Chat" />
                </span>
              </NavItem>
              <NavItem eventKey={LessonSidebarTab.DICTIONARY}>
                <Icon name="virc-vocabulary" />
                <span className="tab-name">
                  <FormattedMessage id="Vocabulary" />
                </span>
              </NavItem>
            </Nav>
          </div>
        </Tab.Container>
      </FloatSidebar>
    );
  }

  private onTabSelect = (tab: LessonSidebarTab) => this.setState({selectedTab: tab});
}

const mapStateToProps = (state: AppState): StateProps => ({
  shouldRenderIndockVideoBtn: state.video.undocked && state.rtc.callInProgress,
  studentTeachers: state.studentTeachers?.studentTeachers,
  chatUnreadMessagesCount: getUnreadMessagesCount(state.chat!.rooms),
  pathname: state.router.location!.pathname,
  sidebarCollapsed: !!state.floatSidebar?.collapsed || state.layout.collapsed,
  studentId: state.dictionary?.studentId
});

export default connect(mapStateToProps)(LessonSidebar);
