import React, {type FC, useContext} from 'react';
import classNames from 'classnames';

import {CreateEntryButton} from '../shared/CreateEntry/CreateEntryButton';
import {EditEntryForm} from '../shared/CreateEntry/EditEntryForm';
import {AddEntryToListModal} from '../shared/CreateEntry/AddEntryToListModal/AddEntryToListModal';
import {EditEntryStateContext} from '../shared/contexts/entry/EditEntryContext';
import PageControls from '../../PageControls';
import {SearchButton} from './SearchButton';
import {Search} from './Search';
import {type DictionaryEntryInstance} from '../shared/interface';
import {useSearchingInODContext} from './DictionaryArticle/contexts/searchingInODContext';
import {CloseButton} from '../components/CloseButton/CloseButton';
import {SelectEntriesPanel} from '../components/SelectEntriesPanel/SelectEntriesPanel';
import {EnterSelectionModeButton} from '../shared/SelectionControls/EnterSelectionModeButton';

interface Props {
  entries: DictionaryEntryInstance[];
  hasLists: boolean;
  studentId: number;
  reset(): void;
  dictionaryIsReadonly: boolean;
}

export const DictionaryActions: FC<Props> = React.memo(
  ({entries, hasLists, dictionaryIsReadonly, reset}) => {
    const {isSearchingInOD, searchOpen} = useSearchingInODContext();
    const {selectionMode, showForm} = useContext(EditEntryStateContext);

    return (
      <>
        <PageControls
          className={classNames({
            'selection-mode': selectionMode,
            'is-searching-in-od': isSearchingInOD,
            searching: searchOpen,
            'editing-entry': showForm
          })}
        >
          {!dictionaryIsReadonly &&
            !isSearchingInOD &&
            (!showForm ? <CreateEntryButton isCompact={true} /> : <CloseButton />)}
          {entries.length && !dictionaryIsReadonly && !isSearchingInOD ? (
            !selectionMode ? (
              <EnterSelectionModeButton />
            ) : (
              <CloseButton />
            )
          ) : null}
          {!searchOpen ? <SearchButton open={searchOpen} /> : <CloseButton />}
        </PageControls>
        {selectionMode && <SelectEntriesPanel hasLists={hasLists} reset={reset} />}
        {searchOpen && <Search reset={reset} searchOpen={searchOpen} />}
        {showForm && <EditEntryForm isCompact={true} isSidebar={true} reset={reset} />}
        {!dictionaryIsReadonly && <AddEntryToListModal />}
      </>
    );
  }
);
