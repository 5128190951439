import {
  ADD_SUBSCRIPTION,
  REMOVE_SUBSCRIPTION,
  UNSUBSCRIBE,
  wampClientActionType
} from 'services/wamp/actions/actionTypes';
import {SUCCESS_POSTFIX} from 'services/wamp/actions/types';

import {type SentryMiddlewareConfig} from './interface';
import {CALL_CHANGE_DURATION} from '../../../webRTC/action/actionTypes';
import {
  SUBSCRIBE_TO_ROOM,
  SUBSCRIBE_TO_ROOM_SUCCESS
} from '../../../components/Chat/actions/actionTypes';
import {STORE_SCROLL_TOP} from '../../exercise/player/actionTypes';

export const config: SentryMiddlewareConfig = {
  ignore: [
    CALL_CHANGE_DURATION,
    SUBSCRIBE_TO_ROOM,
    SUBSCRIBE_TO_ROOM_SUCCESS,
    STORE_SCROLL_TOP,
    wampClientActionType(ADD_SUBSCRIPTION),
    wampClientActionType(REMOVE_SUBSCRIPTION),
    wampClientActionType(UNSUBSCRIBE),
    wampClientActionType(UNSUBSCRIBE) + SUCCESS_POSTFIX
  ]
};
