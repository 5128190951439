import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';

import {
  ADD_ENTRIES_TO_DICTIONARY,
  CREATE_EXERCISE_DICTIONARY_LIST,
  CREATE_EXERCISE_DICTIONARY_LIST_FOR_TEACHER,
  LOAD_DICTIONARY_META_REQUEST,
  LOAD_PRONUNCIATION_SOUND
} from './actionTypes';
import {type PronunciationOption} from '../../interface';

export type LoadPronunciationSoundResponse = AxiosResponseAction<PronunciationOption>;

export const loadPronunciationSound = (id: number): AxiosRequestAction => ({
  type: LOAD_PRONUNCIATION_SOUND,
  payload: {
    client: 'v2',
    request: {
      url: `/v2/pronunciation/${id}`,
      method: 'get'
    }
  }
});

export const loadDictionaryMetaRequest = (
  widgetId: string,
  exerciseInstanceId: string
): AxiosRequestAction => ({
  type: LOAD_DICTIONARY_META_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/exercise-instance/${exerciseInstanceId}/widget-vocabulary/${widgetId}`
    }
  }
});

export const createExerciseDictionaryList = (exerciseInstanceId: string): AxiosRequestAction => ({
  type: CREATE_EXERCISE_DICTIONARY_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/exercise-instance/${exerciseInstanceId}/dictionary-list`
    }
  }
});

export const createExerciseDictionaryListForTeacher = (
  exerciseInstanceId: string
): AxiosRequestAction => ({
  type: CREATE_EXERCISE_DICTIONARY_LIST_FOR_TEACHER,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/exercise-instance/${exerciseInstanceId}/dictionary-list/teacher`
    }
  }
});

export const addWordsToDictionaryList = (
  widgetId: string,
  dictionaryListId: string,
  wordIds: string[]
): AxiosRequestAction => ({
  type: ADD_ENTRIES_TO_DICTIONARY,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/dictionary-list/${dictionaryListId}/widget/${widgetId}`,
      data: {wordIds}
    }
  }
});

export const addWordsToDictionary = (
  exerciseInstanceId: string,
  widgetId: string,
  wordIds: string[]
): AxiosRequestAction => ({
  type: ADD_ENTRIES_TO_DICTIONARY,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/exercise-instance/${exerciseInstanceId}/widget-vocabulary/${widgetId}`,
      data: {wordIds}
    }
  }
});
