import React, {type FC} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import {downloadBrowserLink} from 'config/static';
import Icon from 'components/Icon';
import {type AppState, type Locale} from 'store/interface';

import {type BrowserName} from '../interface';

interface Props {
  name: BrowserName;
  version: string | number;
  minVersion: string | number;
  onClose: () => void;
}

const OutdatedBrowser: FC<Props> = ({name, version, minVersion, onClose}) => {
  const locale = useSelector<AppState, Locale>(s => s.intl.locale);
  return (
    <div className="bad-browser outdated">
      <FormattedMessage id="Chat.BrowserOutdated.Title" values={{name, version}} />
      {name === 'Safari' ? (
        <FormattedMessage id="Chat.BrowserOutdated.SafariDescription" />
      ) : (
        <FormattedMessage id="Chat.BrowserOutdated.Description" values={{name, minVersion}} />
      )}
      <Icon name="warning" size="lg" />
      <a target="_blank" href={downloadBrowserLink(locale)(name)} rel="noopener noreferrer">
        {name === 'Safari' ? (
          <FormattedMessage id="Chat.BrowserOutdated.Help" />
        ) : (
          <FormattedMessage id="Chat.BrowserOutdated.Download" />
        )}
      </a>
      <div className="bad-browser__close" onClick={onClose} />
    </div>
  );
};

export default OutdatedBrowser;
