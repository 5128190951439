import SparkMD5 from 'spark-md5';

import {PointerManager as LegacyPointerManager} from 'components/Slate/plugins/renderers/Pointer/PointerManager';
import {PointerManager} from 'components/SlateJS/plugins/pointer/selection/PointerManager';

import {type SelectionData} from '../../../store/interface';
import {
  type PointerSelectionManager,
  type StopSelectionEventDetails,
  type Subscriber
} from './interface';

class PointerFacade {
  constructor(private readonly editorManagers: PointerSelectionManager[] = []) {}

  public createForTeacher(subscriber: Subscriber): void {
    const manager = this.findManager(subscriber.editor);
    manager?.createForTeacher(subscriber);
  }

  public createForStudent(subscriber: Subscriber, data: SelectionData): void {
    const manager = this.findManager(subscriber.editor);
    manager?.createForStudent(subscriber, data);
  }

  public startSelection(editor: unknown, event: MouseEvent, selection: Selection | null) {
    const manager = this.findManager(editor);

    return manager?.startSelection(event, editor, selection);
  }

  public stopSelection(
    selection: Selection,
    subscribers: Subscriber[],
    details: StopSelectionEventDetails
  ): Subscriber | null {
    for (const subscriber of subscribers) {
      const manager = this.findManager(subscriber.editor);
      const subscriberWithRange = manager?.stopSelection(selection, subscriber, details);
      if (subscriberWithRange) {
        return subscriberWithRange;
      }
    }

    return null;
  }

  public removeCreatePointer(editor: unknown) {
    const manager = this.findManager(editor);
    manager?.removeCreatePointer(editor);
  }

  public getWidgetProps(editor: unknown): ReturnType<PointerSelectionManager['getWidgetProps']> {
    const manager = this.findManager(editor);
    return manager?.getWidgetProps(editor);
  }

  public getContentHash(editor: unknown, id: string | number): string {
    const widgetProps = this.getWidgetProps(editor);

    if (widgetProps) {
      const {widget} = widgetProps;
      const manager = this.findManager(editor);
      const stringValue = manager?.editorToString(editor);

      return SparkMD5.hash(widget.id + id + stringValue);
    }

    return '';
  }

  private findManager(editor: unknown): PointerSelectionManager | null {
    return this.editorManagers.find(m => m.isManagerFor(editor)) || null;
  }
}

export const SlatePointerFacade: PointerFacade = new PointerFacade([
  new PointerManager(),
  new LegacyPointerManager()
]);
