import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {genKey} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

import {type PictureChoiceCardProperties, type XPictureChoiceCardJSON} from './interface';

const Record = recordBase()(BaseRecord);

export class PictureChoiceCardRecord extends Record implements PictureChoiceCardProperties {
  public declare readonly id: string;
  public declare readonly imageId: number;
  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: XPictureChoiceCardJSON) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      imageId: raw.imageId || null,
      cardSizeType: raw.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toJSON(): PictureChoiceCardProperties {
    return {
      id: this.id,
      imageId: this.imageId,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(PictureChoiceCardRecord, {
  id: property(genKey()),
  imageId: property(null),
  cardSizeType: property('')
});
record()(PictureChoiceCardRecord);
