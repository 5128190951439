import React, {type MouseEventHandler} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Popover from 'react-bootstrap/lib/Popover';
import {FormattedMessage} from 'react-intl';

import {contacts} from 'config/static';
import Icon from 'components/Icon';

import {type ToggleElementCreator} from '../../common/interface';

interface Links {
  label: string;
  href: string;
  icon: string;
}

interface Numbers extends Links {
  text: JSX.Element;
}

export const contactLinksTeacher: Links[] = [
  {
    label: contacts.email,
    href: `mailto:${contacts.email}`,
    icon: 'pc-email'
  },
  {
    label: contacts.englexManagers,
    href: `https://join.skype.com/invite/oLoI5bsk1V5p`,
    icon: 'pc-skype'
  },
  {
    label: contacts.telegram,
    href: 'https://t.me/englexschool',
    icon: 'pc-telegram'
  }
];

export const contactLinksStudent: Links[] = [
  {
    label: contacts.email,
    href: `mailto:${contacts.email}`,
    icon: 'pc-email'
  },
  {
    label: contacts.englexMail,
    href: `https://join.skype.com/invite/j08IU1MEIGS9`,
    icon: 'pc-skype'
  },
  {
    label: contacts.telegram,
    href: 'https://t.me/englexschool',
    icon: 'pc-telegram'
  },
  {
    label: contacts.whatsapp,
    href: 'https://wa.me/74951507740',
    icon: 'whatsapp'
  }
];

export const contactNumbers: Numbers[] = [
  {
    label: contacts.telephone1,
    href: `tel:${contacts.telephone1.replace(/\D/g, '')}`,
    icon: 'pc-phone',
    text: <FormattedMessage id="Header.Tel1Caption" />
  },
  {
    label: contacts.telephone2,
    href: `tel:${contacts.telephone2.replace(/\D/g, '')}`,
    icon: 'pc-phone',
    text: <FormattedMessage id="Header.Tel2Caption" />
  }
];

export interface SupportPopoverProps {
  isStudent: boolean;
  showModalFunc: ToggleElementCreator;
  hidePopover: () => void;
}

export default class SupportPopover extends React.Component<SupportPopoverProps, {}> {
  public render(): JSX.Element {
    return (
      <Popover id="support-popover" arrowOffsetLeft="214px" placement="bottom">
        {this.wrapContentWithStopPropagation(
          <>
            <div>
              {this.renderNumbers()}
              {this.renderLinks()}
            </div>
            {this.props.isStudent && (
              <div>
                <Button bsStyle="primary" onClick={this.handleButtonClick}>
                  <FormattedMessage id="Header.SendMessage" />
                </Button>
              </div>
            )}
          </>
        )}
      </Popover>
    );
  }

  private handleClick: MouseEventHandler<HTMLDivElement> = e => {
    e.stopPropagation();
  };

  private wrapContentWithStopPropagation(content: JSX.Element): JSX.Element {
    return <div onClick={this.handleClick}>{content}</div>;
  }

  private renderNumbers = () => {
    return contactNumbers.map(({label, icon, href, text}) => {
      return (
        <a className="call-item call-link" href={href} key={label}>
          <Icon name={icon} size="lg" />
          <span>{label}</span>
          {text}
        </a>
      );
    });
  };

  private renderLinks = () => {
    const contactLinks = this.props.isStudent ? contactLinksStudent : contactLinksTeacher;

    return contactLinks.map(({label, icon, href}) => (
      <a className="call-item link-item" href={href} key={label}>
        <Icon name={icon} size="lg" />
        <span>{label}</span>
      </a>
    ));
  };

  private handleButtonClick = () => {
    this.props.hidePopover();
    this.props.showModalFunc(true);
  };
}
