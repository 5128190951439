export function playMedia(
  element: HTMLMediaElement,
  catchPlayError: boolean = true
): Promise<void> {
  const promise = element.play() as Promise<void> | undefined;
  if (promise?.catch) {
    // eslint-disable-next-line no-console
    return catchPlayError ? promise.catch(console.warn) : promise;
  }
  return Promise.resolve(promise);
}
