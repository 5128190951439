import React from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from 'components/Icon';
import {type AvatarUrl} from 'store/interface';

import Avatar from '../../components/Avatar';
import {SoundNotification, soundUrl} from '../../helpers/sound';
import './OutgoingCall.scss';

interface OutgoingCallProps {
  partnerName: string;
  hangUp: () => void;
  avatar: AvatarUrl;
  callStarting?: boolean;
  callRequestSent?: boolean;
}

export default class OutgoingCall extends React.Component<OutgoingCallProps, {}> {
  private audioEl: HTMLMediaElement | null;

  public componentWillUnmount(): void {
    // hack to prevent user on MacOS Chrome push 'play' button on headphones or keyboard and hearing call notification after call was ended
    if (this.audioEl) {
      this.audioEl.volume = 0;
    }
  }

  public render() {
    return (
      <div className="outgoing-call">
        {this.renderAudioNotification()}
        <div className="header">
          {this.props.children}
          <FormattedMessage id="Chat.CallOutgoing" />
        </div>
        <div className={`avatar-and-name ${this.props.callStarting ? '' : 'animation'}`}>
          <div>
            <Avatar size={72} url={this.props.avatar} />
          </div>
          <span className="opponent-name">{this.props.partnerName}</span>
        </div>
        <Button
          bsStyle="danger"
          className="btn-circle hang-up-button hangup-outgoing-call"
          disabled={this.props.callStarting}
          onClick={this.props.hangUp}
        >
          <Icon name="phone" size="lg" className="hangup-outgoing-call" />
        </Button>
      </div>
    );
  }

  public renderAudioNotification = () => {
    if (this.props.callRequestSent) {
      return (
        <audio
          src={soundUrl(SoundNotification.callOutgoing)}
          autoPlay={true}
          loop={true}
          ref={el => (this.audioEl = el)}
        />
      );
    }
    return null;
  };
}
