import {List, Map} from 'immutable';
import {type Block, Editor, Value, type ValueJSON} from '@englex/slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {type QuizJSON, type QuizOptions, type QuizResultBlockJSON} from './interface';
import {WidgetType} from '../../interface';
import MultipleChoiceRecord from '../List/MultipleChoice/MultipleChoiceRecord';
import genKey from '../../../../../components/Slate/utils/genKey';
import {isBlockOfType, valueJSONFromText} from '../../../../../components/Slate/utils';
import {SlateBlock} from '../../../../../components/Slate/interface';
import QuizBlockRecord from './QuizBlockRecord';

class QuizRecord extends MultipleChoiceRecord {
  public declare resultBlocks: List<QuizBlockRecord>;
  public declare options: QuizOptions;

  private static shuffleAnswers(content: ValueJSON) {
    const change = new Editor({value: Value.fromJSON(content)});

    change.moveToRangeOfDocument();

    change.value.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
      .reduce((ch: Editor, block: Block): Editor => {
        const answers = block.filterDescendants(node =>
          isBlockOfType(node, SlateBlock.QUESTION_ANSWER)
        );

        const shuffledAnswers = answers.sort((a: Block, b: Block) =>
          a.data.get('id').localeCompare(b.data.get('id'))
        );

        answers.forEach((node: Block) => ch.removeNodeByKey(node.key));

        shuffledAnswers.forEach((node: Block, index: number) =>
          ch.insertNodeByKey(block.key, index + 1, node)
        );

        return ch;
      }, change);

    return change.value.toJSON();
  }

  private static getResultIds(map: Map<string, number>) {
    const {result} = map.reduce(
      (acc: {max: number; result: string[]}, count: number, id: string) => {
        if (count > acc.max) {
          return {max: count, result: [id]};
        } else if (count === acc.max) {
          return {max: count, result: acc.result.concat(id)};
        }

        return acc;
      },
      {max: 0, result: []}
    );

    return result;
  }

  private static createBlock(block: Partial<QuizResultBlockJSON> = {}) {
    return new QuizBlockRecord({
      id: block.id || genKey(),
      title: block.title || valueJSONFromText(''),
      text: block.text || valueJSONFromText('')
    });
  }

  constructor({
    content,
    resultBlocks,
    options: {element, answersNumber, questionsNumber},
    ...other
  }: QuizJSON) {
    super({content: QuizRecord.shuffleAnswers(content), element, ...other});

    this.initValues({
      resultBlocks: List((resultBlocks as QuizResultBlockJSON[]).map(QuizRecord.createBlock)),
      options: {answersNumber, questionsNumber}
    });
  }

  public get type() {
    return WidgetType.Quiz;
  }

  public get completed() {
    return this.content.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
      .every((node: Block) => Boolean(this.values?.has(node.data.get('id'))));
  }

  public get results(): List<QuizBlockRecord> {
    if (this.completed) {
      const valueIds = this.values!.valueSeq().flatten();

      const mapResults: Map<string, number> = this.content.document
        .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
        .filter((node: Block) => valueIds.includes(node.data.get('id')))
        .map((node: Block) => node.data.get('resultId'))
        .reduce(
          (res: Map<string, number>, id) =>
            res.has(id) ? res.update(id, count => count + 1) : res.set(id, 1),
          Map()
        );

      const resultIds = QuizRecord.getResultIds(mapResults);

      return this.resultBlocks.filter((block: QuizBlockRecord) =>
        resultIds.includes(block.id)
      ) as List<QuizBlockRecord>;
    }

    return List();
  }

  public get otherResults() {
    if (this.completed) {
      const resultIds = this.results.map((result: QuizBlockRecord) => result.id);
      return this.resultBlocks.filter((block: QuizBlockRecord) => !resultIds.includes(block.id));
    }

    return List();
  }
}

decorate(QuizRecord, {
  resultBlocks: property(),
  options: property()
});
record()(QuizRecord);
export default QuizRecord;
