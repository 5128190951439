import {record} from 'immutable-record/decorator/record';
import XWordPictureSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSet/XWordPictureSetCardRecord';
import {WidgetType} from 'store/exercise/player/interface';

import WordPictureSetBaseRecord from '../WordPictureSetBaseRecord';
import {
  type WordPictureSetCardMetaDataJSON,
  type WordPictureSetCardProps,
  type WordPictureSetMetaDataJSON,
  type WordPictureSetProperties,
  type WordPictureSetProps,
  type WordPictureSetValues
} from './interface';

class WordPictureSetRecord
  extends WordPictureSetBaseRecord<
    WordPictureSetCardMetaDataJSON,
    WordPictureSetValues,
    WordPictureSetMetaDataJSON,
    XWordPictureSetCardRecord
  >
  implements WordPictureSetProperties
{
  constructor(raw: WordPictureSetProps) {
    super(raw);

    this.initValues({
      cards: this.createCards(raw),
      values: this.createValue(raw),
      isDefaultHidden: raw.isDefaultHidden,
      isNotInteractive: raw.isNotInteractive,
      defaultValues: this.makeDefaultValues(raw.defaultValues)
    });
  }

  public createCard(cardJSON: WordPictureSetCardProps) {
    return new XWordPictureSetCardRecord(cardJSON);
  }

  public switchIsCardHiddenInValue(
    cardMetaData: WordPictureSetCardMetaDataJSON,
    setField?: boolean
  ): WordPictureSetCardMetaDataJSON {
    return {
      ...cardMetaData,
      isImageHidden: setField !== undefined ? setField : !cardMetaData.isImageHidden,
      isTextHidden: setField !== undefined ? setField : !cardMetaData.isTextHidden
    };
  }

  public switchIsImageHiddenInValue(
    value: WordPictureSetCardMetaDataJSON,
    setField?: boolean
  ): WordPictureSetCardMetaDataJSON {
    return {
      ...value,
      isImageHidden: setField !== undefined ? setField : !value.isImageHidden
    };
  }

  public switchIsTextHiddenInValue(
    value: WordPictureSetCardMetaDataJSON,
    setField?: boolean
  ): WordPictureSetCardMetaDataJSON {
    return {
      ...value,
      isTextHidden: setField !== undefined ? setField : !value.isTextHidden
    };
  }

  public switchIsImageHidden(cardId: string) {
    return this.switchIsHiddenByCardId(cardId, this.switchIsImageHiddenInValue);
  }

  public switchIsTextHidden(cardId: string) {
    return this.switchIsHiddenByCardId(cardId, this.switchIsTextHiddenInValue);
  }

  public get type() {
    return WidgetType.WORD_PICTURE_SET;
  }
}

record()(WordPictureSetRecord);
export default WordPictureSetRecord;
