import {useMemo} from 'react';
import momentTimezone, {type Moment} from 'moment-timezone';

import {type DictionaryEntryInstance} from 'components/Dictionary/shared/interface';

type DateRecord = {[id: string]: Date};

export const useLocaleDate = (entries: DictionaryEntryInstance[], timezone?: string): DateRecord =>
  useMemo(() => {
    if (!timezone) return {};
    const dateRecord: DateRecord = {};
    let prevMoment: Moment | undefined = undefined;
    for (const entry of entries) {
      const dateString = entry.updatedAt;
      const moment = momentTimezone.tz(dateString, timezone);
      if (!prevMoment || !moment.isSame(prevMoment, 'day')) {
        dateRecord[entry.id] = new Date(moment.year(), moment.month(), moment.date());
      }
      prevMoment = moment;
    }
    return dateRecord;
  }, [entries, timezone]);
