import {Value, type ValueProperties} from '@englex/slate';

import {genKey, valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type WordSetCardJSON} from 'store/exercise/player/widgets/WordPictureSet/WordSet/interface';

import XWordPictureSetBaseCardRecord from '../XWordPictureSetBaseCardRecord';
import {type XWordSetCardProperties, type XWordSetCardProps} from './interface';

class XWordSetCardRecord extends XWordPictureSetBaseCardRecord implements XWordSetCardProperties {
  public declare readonly id: string;

  public declare readonly text: Value;

  constructor(raw: XWordSetCardProps) {
    super(raw);
    this.initValues({
      text: raw.text ? Value.fromJSON(raw.text as ValueProperties) : valueFromText('')
    });
  }

  public toJSON(): WordSetCardJSON {
    return {
      ...super.toJSON(),
      text: this.text.toJSON()
    };
  }
}

decorate(XWordSetCardRecord, {
  id: property(genKey()),
  text: property()
});
record()(XWordSetCardRecord);
export default XWordSetCardRecord;
