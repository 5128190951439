import React, {useCallback, useMemo} from 'react';
import {
  withBackgroundImage,
  withChildren,
  withKonvaFlags,
  withMobileViewport,
  withScrollBars
} from '@englex/paint-react';

import {type MobilePageAttrs} from 'components/Paint/hooks/useMobilePainterAttrs';
import {isTouchEmulatorEnabled} from 'components/TouchEmulator';
import Loader from 'components/Loader';
import {YPainter} from 'components/Paint/YPainter';

import {type DocumentInstancePage} from '../../ClassRoom/components/FilesWorkSpace/documentsTab/actions/interface';

interface Props {
  attrs: MobilePageAttrs;
  docInstancePage: DocumentInstancePage;
  onLoading?: (loading: boolean) => void;
}

export const MobileDocumentInstancePage: React.FC<Props> = ({
  attrs,
  docInstancePage,
  onLoading
}) => {
  const {url, orientation, size} = attrs;

  const plugins = useMemo(() => {
    return [
      withMobileViewport({...size, enabled: true}),
      withChildren({enabled: true}),
      withBackgroundImage({url}),
      withScrollBars(),
      withKonvaFlags({
        flags: {hitOnDragEnabled: true, capturePointerEventsEnabled: true},
        when: isTouchEmulatorEnabled
      })
    ];
  }, [url, size]);

  const renderLoader = useCallback(() => <Loader />, []);

  return (
    <YPainter
      docId={docInstancePage.yDocId}
      orientation={orientation}
      plugins={plugins}
      renderLoader={renderLoader}
      onLoading={onLoading}
    />
  );
};
