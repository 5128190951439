export const dictionaryPath = '/dictionary';

export const studentDictionaryPattern = `${dictionaryPath}/student?/:studentId?`;
export const teacherDictionaryPattern = `${dictionaryPath}/teacher?/:teacherId?`;

export const studentDictionaryAllEntriesPattern = `${studentDictionaryPattern}/all`;
export const teacherDictionaryEntriesInListPattern = `${teacherDictionaryPattern}/all`;

export const dictionaryListPattern = `${studentDictionaryPattern}/list/:listId`;
export const teacherDictionaryListPattern = `${teacherDictionaryPattern}/list/:listId`;

export const studentDictionaryPath = (studentId: number | string) =>
  `${dictionaryPath}/student/${studentId}`;
export const teacherDictionaryPath = (teacherId: number | string) =>
  `${dictionaryPath}/teacher/${teacherId}`;

export const dictionaryListPath = (studentId: number | string, listId: number | string) =>
  `${studentDictionaryPath(studentId)}/list/${listId}`;
export const teacherDictionaryListPath = (teacherId: number | string, listId: number | string) =>
  `${teacherDictionaryPath(teacherId)}/list/${listId}`;

export const dictionaryAllEntriesPath = (studentId: number | string) =>
  `${studentDictionaryPath(studentId)}/all`;
export const teacherDictionaryAllEntriesPath = (teacherId: number | string) =>
  `${teacherDictionaryPath(teacherId)}/all`;
