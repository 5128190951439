const PATH: string = 'MEDIA_DEVICES/';
export const TOGGLE_DEVICES_MODAL = PATH + 'TOGGLE_MODAL';
export const SKIP_GREETING = PATH + 'SKIP_GREETING';
export const CHANGE_PERMISSIONS = PATH + 'CHANGE_PERMISSIONS';
export const SAVE_DEVICES = PATH + 'SAVE_DEVICES';
export const SELECT_CAM = PATH + 'SELECT_CAM';
export const SELECT_MIC = PATH + 'SELECT_MIC';
export const SAVE_MODAL_SELECTION = PATH + 'SAVE_MODAL_SELECTION';
export const SELECTED_MIC_PRESENCE = PATH + 'SELECTED_MIC_PRESENCE';
export const SELECTED_CAM_PRESENCE = PATH + 'SELECTED_CAM_PRESENCE';
export const NO_CAMS_AVAILABLE = PATH + 'NO_CAMS_AVAILABLE';
export const NO_MICS_AVAILABLE = PATH + 'NO_MICS_AVAILABLE';
export const SET_DEVICES = PATH + 'SET_DEVICES';
