import {Editor, Value} from '@englex/slate';

import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';

import {WidgetType} from '../../interface';
import {type GrammarRulesJSON} from './interface';
import {type FormattedTextProperties} from '../FormattedText/interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';
import {insertIcon} from '../../../../../components/Slate/SlateEditor/plugins/button/Icon/changes';

type Props = FormattedTextProperties;

class GrammarRulesRecord extends FormattedTextRecord implements Props {
  public declare readonly grammarTitle?: Value;

  constructor(raw: GrammarRulesJSON & {type?: WidgetType}) {
    super(raw);
    this.initValues({
      grammarTitle: raw.grammarTitle
        ? new Editor({value: Value.fromJSON(raw.grammarTitle)})
            .insertText(' ')
            .moveToStartOfDocument()
            .command(insertIcon, 'pc-course', 'not-bold').value
        : undefined
    });
  }
  public get type() {
    return WidgetType.GRAMMAR_RULES;
  }

  public toJSON(): GrammarRulesJSON {
    return {
      ...super.toJSON(),
      grammarTitle: this.grammarTitle ? this.grammarTitle.toJSON() : undefined
    };
  }

  public get isAvailableSelection() {
    return true;
  }
}
decorate(GrammarRulesRecord, {
  grammarTitle: property()
});
record()(GrammarRulesRecord);
export default GrammarRulesRecord;
