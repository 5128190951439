import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import Header from '../components/Header';
import {collapseLayout} from '../actions/action';
import {type HeaderDispatchProps, type HeaderStateProps} from './interface';

const mapStateToProps = (state: AppState): HeaderStateProps => ({
  collapsed: state.layout.collapsed,
  deviceIsMobile: state.layout.isMobile,
  notifications: state.layout.notifications
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): HeaderDispatchProps => ({
  collapseLayout: (collapse: boolean) => dispatch(collapseLayout(collapse))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
