import React from 'react';

import {videoBaseHeight, videoBaseWidth} from 'config/static';

interface Props {
  videoFullScreen?: boolean;
  isUndocked?: boolean;
  width: number | string;
  height: number | string;
  remoteVideoEnabled?: boolean;
  remoteVideoRef: (el: HTMLVideoElement) => void;
  onRemoteVideoPause: () => void;
}

export default class RemoteVideo extends React.PureComponent<Props, {}> {
  getValue(v: string | number) {
    return typeof v === 'number' ? v + 'px' : v;
  }

  getSize() {
    const {videoFullScreen, isUndocked, width, height} = this.props;

    const w = videoFullScreen ? '100%' : isUndocked ? width : videoBaseWidth;
    const h = videoFullScreen ? '100%' : isUndocked ? height : videoBaseHeight;

    return {
      width: this.getValue(w),
      height: this.getValue(h)
    };
  }

  public render() {
    const {remoteVideoEnabled, remoteVideoRef, onRemoteVideoPause} = this.props;

    const {width, height} = this.getSize();

    return (
      <div className="remote-video" style={{width, height}}>
        <video
          className={remoteVideoEnabled ? '' : 'invisible'}
          ref={remoteVideoRef}
          onPause={onRemoteVideoPause}
          autoPlay={true}
          playsInline={true}
        />
      </div>
    );
  }
}
