import {type Action} from 'redux';

import {
  type AddEntriesToListAction,
  type EnterSelectionModeAction,
  type SelectAllEntriesAction,
  type SelectEntriesAction,
  type SetDictionaryEntryToDeleteAction,
  type SetEntryToEditAction,
  type SetListEntryToDeleteAction,
  type SetSelectionActionAction,
  type SubmitPronunciationAction,
  type ToggleFormAction
} from './actions';
import {
  ADD_ENTRY_TO_LIST,
  CLEAR_DELETE_ENTRY,
  CLEAR_EDIT,
  CLEAR_EDITED_ENTRY,
  CLEAR_PRONUNCIATION,
  CLOSE_FORM,
  CLOSE_MODAL,
  DESELECT_ALL,
  DESELECT_ENTRIES,
  ENTER_SELECTION_MODE,
  EXIT_SELECTION_MODE,
  RESET,
  SELECT_ALL,
  SELECT_ENTRIES,
  SET_DICTIONARY_ENTRY_TO_DELETE,
  SET_ENTRY_TO_EDIT,
  SET_LIST_ENTRY_TO_DELETE,
  SET_SELECTION_ACTION,
  SHOW_MODAL,
  SUBMIT_PRONUNCIATION,
  TOGGLE_FORM
} from '../actionTypes';
import {type EditEntryState, type EntrySelectionMode} from './interface';

export const initialState: EditEntryState = {
  showForm: false,
  showPronunciationModal: false
};

const handlers = {
  [SUBMIT_PRONUNCIATION]: (
    state: EditEntryState,
    {soundId: pronunciationId, phoneticSpelling}: SubmitPronunciationAction
  ): EditEntryState => ({...state, pronunciationId, phoneticSpelling}),

  [CLEAR_PRONUNCIATION]: ({
    pronunciationId,
    phoneticSpelling,
    ...state
  }: EditEntryState): EditEntryState => state,

  [TOGGLE_FORM]: (state: EditEntryState, {listId}: ToggleFormAction): EditEntryState => ({
    ...state,
    showForm: !state.showForm,
    formListId: listId
  }),

  [CLOSE_FORM]: (state: EditEntryState): EditEntryState => ({
    ...state,
    showForm: false
  }),

  [SHOW_MODAL]: (state: EditEntryState): EditEntryState => ({
    ...state,
    showPronunciationModal: true
  }),

  [CLOSE_MODAL]: (state: EditEntryState): EditEntryState => ({
    ...state,
    showPronunciationModal: false
  }),

  [ADD_ENTRY_TO_LIST]: (
    state: EditEntryState,
    {entryIds, existingList, submit}: AddEntriesToListAction
  ): EditEntryState => ({...state, addToListObject: {entryIds, existingList, submit}}),

  [CLEAR_EDIT]: ({
    addToListObject,
    phoneticSpelling,
    pronunciationId,
    editedEntry,
    ...state
  }: EditEntryState): EditEntryState => ({...state, showForm: false}),

  [SET_LIST_ENTRY_TO_DELETE]: (
    state: EditEntryState,
    {listId, entryId}: SetListEntryToDeleteAction
  ): EditEntryState => ({...state, listToDeleteFromId: listId, entryToDeleteId: entryId}),

  [SET_DICTIONARY_ENTRY_TO_DELETE]: (
    {editedEntry, ...state}: EditEntryState,
    {entryId, deletingFromListPage}: SetDictionaryEntryToDeleteAction
  ): EditEntryState => ({
    ...state,
    showForm: false,
    entryToDeleteId: entryId,
    deletingFromListPage
  }),

  [CLEAR_DELETE_ENTRY]: ({
    listToDeleteFromId,
    entryToDeleteId,
    deletingFromListPage,
    ...state
  }: EditEntryState): EditEntryState => state,

  [SET_ENTRY_TO_EDIT]: (state: EditEntryState, {entry}: SetEntryToEditAction): EditEntryState => ({
    ...state,
    showForm: true,
    editedEntry: entry
  }),

  [CLEAR_EDITED_ENTRY]: ({editedEntry, ...state}: EditEntryState): EditEntryState => state,

  [ENTER_SELECTION_MODE]: (
    state: EditEntryState,
    {listId}: EnterSelectionModeAction
  ): EditEntryState => ({...state, selectionMode: {entryIds: [], listId}}),

  [EXIT_SELECTION_MODE]: ({selectionMode, ...state}: EditEntryState): EditEntryState => state,

  [SELECT_ENTRIES]: (state: EditEntryState, {entryIds}: SelectEntriesAction): EditEntryState => ({
    ...state,
    selectionMode: {
      ...state.selectionMode,
      entryIds: [
        ...state.selectionMode!.entryIds.filter(eid => !entryIds.includes(eid)),
        ...entryIds
      ]
    }
  }),

  [DESELECT_ENTRIES]: (state: EditEntryState, {entryIds}: SelectEntriesAction): EditEntryState => ({
    ...state,
    selectionMode: {
      ...state.selectionMode,
      entryIds: state.selectionMode!.entryIds.filter(eid => !entryIds.includes(eid))
    }
  }),

  [DESELECT_ALL]: (state: EditEntryState): EditEntryState => ({
    ...state,
    selectionMode: {...state.selectionMode, entryIds: []}
  }),

  [SELECT_ALL]: (state: EditEntryState, {entryIds}: SelectAllEntriesAction): EditEntryState => ({
    ...state,
    selectionMode: {...state.selectionMode, entryIds}
  }),

  [SET_SELECTION_ACTION]: (
    state: EditEntryState,
    {action, existingList}: SetSelectionActionAction
  ): EditEntryState => {
    if (!state.selectionMode) return state;
    return {
      ...state,
      selectionMode: {...(state.selectionMode as EntrySelectionMode), action, existingList}
    };
  },

  [RESET]: () => initialState
};

export const reducer = (state: EditEntryState, action: Action): EditEntryState => {
  return handlers[action.type]?.(state, action) || state;
};
