import React from 'react';
import classNames from 'classnames';

import {type StudentTeacher, type StudentTeachers} from 'store/interface';

import StudentTeacherBreadcrumbPopoverOverlay from './StudentTeacherBreadcrumbPopoverOverlay';
import {userCompactName, userFullName} from '../../../helpers/user';
import StudentTeacherPopover from './StudentTeacherPopover';
import {Breadcrumb} from '../Breadcrumb';

interface Props {
  activeStudentTeacher?: StudentTeacher;
  studentTeacherPopoverOpen?: boolean;
  toggleStudentTeachersPopover: (show: boolean) => void;
  studentTeachers: StudentTeachers;
  changeActiveStudentTeacher: (id: number) => void;
  popoverId: string;
  popoverHeight?: number;
  popoverTopPosition?: string;
  popoverLeftPosition?: string;
  popoverContainerClassName?: string;
  studentTeachersFilter: string;
  popoverOpenTabId: string;
  changeFilter: (filter: string) => void;
  changeActiveTab: (tabId: string) => void;
  currentStudent?: number;
  isSidebar: boolean;
}

export default class StudentTeacherBreadcrumb extends React.PureComponent<Props, {}> {
  public render() {
    const {
      activeStudentTeacher,
      studentTeacherPopoverOpen,
      studentTeachers,
      currentStudent,
      isSidebar
    } = this.props;
    if (!activeStudentTeacher) {
      return null;
    }

    const className: string = classNames(
      {pointer: this.shouldRenderPopover},
      {active: studentTeacherPopoverOpen}
    );

    const currentStudentTeachersId = Object.keys(studentTeachers).find(
      key => Number(studentTeachers[key].student_id) === currentStudent
    );

    const title = userCompactName(
      isSidebar && currentStudentTeachersId
        ? studentTeachers[currentStudentTeachersId].recipient.profile!
        : activeStudentTeacher.recipient.profile!
    );

    const accessibilityTitle = userFullName(
      isSidebar && !!currentStudentTeachersId
        ? studentTeachers[currentStudentTeachersId].recipient.profile!
        : activeStudentTeacher.recipient.profile!
    );

    return (
      <Breadcrumb
        icon="user"
        title={title}
        className={className}
        accessibilityTitle={accessibilityTitle}
        onClick={this.partnersTabClickHandler}
      >
        {this.renderPopover(currentStudentTeachersId)}
      </Breadcrumb>
    );
  }

  private partnersTabClickHandler = () => {
    const {studentTeacherPopoverOpen, toggleStudentTeachersPopover} = this.props;
    if (!studentTeacherPopoverOpen && this.shouldRenderPopover) {
      toggleStudentTeachersPopover(true);
    }
  };

  private renderPopover = (currentStudentTeachersId?: string) => {
    const {
      studentTeacherPopoverOpen,
      toggleStudentTeachersPopover,
      changeActiveStudentTeacher,
      studentTeachers,
      popoverId,
      studentTeachersFilter,
      changeFilter,
      changeActiveTab,
      popoverHeight,
      popoverLeftPosition,
      popoverTopPosition,
      popoverContainerClassName,
      popoverOpenTabId,
      activeStudentTeacher
    } = this.props;
    return this.shouldRenderPopover ? (
      <StudentTeacherBreadcrumbPopoverOverlay
        toggleStudentTeachersPopover={toggleStudentTeachersPopover}
        studentTeacherPopoverOpen={studentTeacherPopoverOpen}
      >
        <StudentTeacherPopover
          partnerSelectedHandler={changeActiveStudentTeacher}
          hidePopover={this.hidePopover}
          elements={studentTeachers}
          popoverId={popoverId}
          filter={studentTeachersFilter}
          changeFilter={changeFilter}
          changeActiveTab={changeActiveTab}
          openTabId={popoverOpenTabId}
          popoverHeight={popoverHeight}
          topPosition={popoverTopPosition}
          leftPosition={popoverLeftPosition}
          hideArrow={true}
          selectedElementId={activeStudentTeacher && activeStudentTeacher.id}
          containerClassName={popoverContainerClassName}
          currentStudentTeachersId={currentStudentTeachersId}
        />
      </StudentTeacherBreadcrumbPopoverOverlay>
    ) : null;
  };

  private get shouldRenderPopover() {
    return Object.keys(this.props.studentTeachers).length > 1;
  }

  private hidePopover = () => this.props.toggleStudentTeachersPopover(false);
}
