import {type ActionCreator} from 'redux';

import {type MaterialFile} from 'store/interface';

import {type FileAction} from '../../../../actions/interface';

export interface Sound extends MaterialFile {
  document_instance_id?: number | null;
  length: number;
  url: string;
}

type Playing = 'PLAYING';
type Stopped = 'STOPPED';
type Paused = 'PAUSE';
type Loading = 'LOADING';
type singlePlay = 'singlePlay';
type multiPlay = 'multiPlay';
export const PLAYING: Playing = 'PLAYING';
export const STOPPED: Stopped = 'STOPPED';
export const PAUSE: Paused = 'PAUSE';
export const LOADING: Loading = 'LOADING';
export const MULTI_PLAY: multiPlay = 'multiPlay';

export type PlayStatus = Playing | Stopped | Paused | Loading;
export type PlayMode = singlePlay | multiPlay;

export interface ChangePlayStatusAction extends FileAction {
  playStatus: PlayStatus;
  playMode?: PlayMode;
  startedAt?: number;
}

export interface SetUniquePlaybackIdAction extends FileAction {
  id: string;
}

export interface ChangeTimestampAction extends FileAction {
  startedAt: number;
  timestamp: number;
}

export interface LengthEditAction extends FileAction {
  id: number;
  length: number;
}

export interface ChangeVolumeAction extends FileAction {
  volume: number;
}

export interface ChangePlaybackRateAction extends FileAction {
  playbackRate: number;
}

export interface ChangePlayStatusActionCreator extends ActionCreator<ChangePlayStatusAction> {
  (playStatus: PlayStatus, playMode: PlayMode): ChangePlayStatusAction;
}

export interface ChangeTimestampActionCreator extends ActionCreator<ChangeTimestampAction> {
  (timestamp: number): ChangeTimestampAction;
}

export interface LengthEditCreator extends ActionCreator<LengthEditAction> {
  (id: number, duration: number): LengthEditAction;
}

export interface ChangeVolumeActionCreator extends ActionCreator<ChangeVolumeAction> {
  (volume: number): ChangeVolumeAction;
}
