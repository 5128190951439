import React from 'react';
import {FormattedMessage} from 'react-intl';

export default class NoMessages extends React.Component<{}, {}> {
  public render() {
    return (
      <div className="message no-messages">
        <FormattedMessage id="Chat.NoMessages" />
      </div>
    );
  }
}
