import {createTransform} from 'redux-persist';

import {type XAudio} from '../interface';

const transformAudio = createTransform<XAudio | undefined, XAudio | undefined>(filter, filter);

export default transformAudio;

function filter(state: XAudio, key: string): XAudio | undefined {
  if (!state) {
    return undefined;
  }
  if (key !== 'audio') {
    return state;
  }
  const {volume} = state;
  return {volume, timestamp: 0};
}
