import React, {type PropsWithChildren} from 'react';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import {clearVisibilityInterval, setVisibilityInterval} from '../layouts/actions/action';

interface DispatchProps {
  setVisibilityInterval: () => void;
  clearVisibilityInterval: () => void;
}

class IFrameWrapper extends React.Component<PropsWithChildren<DispatchProps>> {
  public componentDidMount() {
    this.props.setVisibilityInterval();
  }

  public componentWillUnmount() {
    this.props.clearVisibilityInterval();
  }

  public render() {
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>) => ({
  clearVisibilityInterval: () => dispatch(clearVisibilityInterval()),
  setVisibilityInterval: () => dispatch(setVisibilityInterval())
});

export default connect(null, mapDispatchToProps)(IFrameWrapper);
