import {type Action, type MiddlewareAPI, type Store} from 'redux';

import {type AppState} from 'store/interface';

import {type ConnectionOptions, defaultAutobahnClientName} from './actions/types';
import uriMap from './uriMap';
import {type Dispatch, type WampAction} from './actions/interface';
const url: string = import.meta.env.REACT_APP_WAMP_URL;
const realm: string = import.meta.env.REACT_APP_WAMP_REALM;
const maxRetries: number = import.meta.env.REACT_APP_WAMP_MAX_RETRIES;

const config: ConnectionOptions<Dispatch<Action, WampAction>, AppState> = {
  client: defaultAutobahnClientName,
  url,
  realm,
  uriPrefix: import.meta.env.REACT_APP_WAMP_URI_PREFIX,
  uriMap,
  max_retries: maxRetries,
  use_es6_promises: true,
  authmethods: ['ticket'],
  stateAuthId(store: MiddlewareAPI<Dispatch<Action, WampAction>, AppState>) {
    return String(store.getState().user.id!);
  },
  stateToken(store: Store<AppState>) {
    return store.getState().user.token!;
  },
  renewToken: true
};

export default config;
