import {
  Block,
  Editor,
  type SlateError,
  Document,
  type Text,
  Value,
  type Inline
} from '@englex/slate';
import {List} from 'immutable';
import {cloneFragment} from '@englex/slate-react';
import type React from 'react';

import {getLineSeparator} from '../../../../helpers/getLineSeparator';
import {isDefaultBlock} from '../../utils';
import {SlateBlock} from '../../interface';

/**
 * Parse css style value from style tag (useful only for parsing html from macOS editors line Pages and TextEdit)
 * @param {HTMLElement} el
 * @param {string} selector
 * @param {keyof CSSStyleDeclaration} cssProperty
 * @returns {string | null}
 */
export function getElementCssStyleValue(
  el: HTMLElement,
  selector: string,
  cssProperty: string
): string | null {
  if (el.ownerDocument) {
    const inlineStyles = el.ownerDocument.getElementsByTagName('style');
    for (const style in inlineStyles) {
      if (inlineStyles.hasOwnProperty(style)) {
        const sheet = new CSSStyleSheet();

        const rules = inlineStyles[style].innerHTML.split('\n').filter(Boolean);

        rules.forEach((rule, i) => {
          sheet.insertRule(rule, i);
        });

        if (sheet.cssRules.length) {
          const length = sheet.cssRules.length;
          for (let i = 0; i < length; i++) {
            if (sheet.cssRules[i]) {
              const r = sheet.cssRules[i] as CSSStyleRule;
              if (r && r.selectorText && r.selectorText.toLowerCase() === selector) {
                return r.style[cssProperty];
              }
            }
          }
        }
      }
    }
  }

  return null;
}

export function isPastingFromMSWord(html: string): boolean {
  const parser = new DOMParser();
  const document = parser.parseFromString(html, 'text/html');
  return !!document.querySelector('meta[name="ProgId"][content="Word.Document"]');
}

export function editHTMLFromMSWord(html: string): string {
  const lineSeparator = getLineSeparator();
  const startFragmentNewlineRegex = new RegExp(`${lineSeparator}<!--StartFragment-->`, 'gm');
  const betweenParagraphsNewlineRegex = new RegExp(`${lineSeparator}${lineSeparator}<p`, 'gm');
  const otherNewlineRegex = new RegExp(`${lineSeparator}`, 'gm');
  return (
    html
      // first, remove line break in the beginning of the document
      .replace(startFragmentNewlineRegex, '<!--StartFragment-->')
      // then, remove all line breaks between paragraphs
      .replace(betweenParagraphsNewlineRegex, '<p')
      // turn line-breaks between each line of text into regular spaces
      .replace(otherNewlineRegex, ' ')
  );
}

export const logNormalizeError = (
  error: SlateError,
  type: string,
  handled?: boolean,
  key?: string
) => {
  if (import.meta.env.MODE !== 'test') {
    const group = handled ? 'groupCollapsed' : 'group';
    const method = handled ? 'debug' : 'error';
    const message = handled
      ? `normalizing Slate schema key='${key}' in "${type}"`
      : `unhandled Slate schema error in "${type}"`;
    /* eslint-disable no-console */
    console[group](`"${error.code}" - ${message} normalizer`);
    console[method]('node:', error.node.toJSON());
    console[method]('child:', error.child && error.child.toJSON());
    console[method]('parent:', error.parent && error.parent.toJSON());
    console[method]('previous:', error.previous && error.previous.toJSON());
    console[method]('next:', error.next && error.next.toJSON());
    console[method]('mark:', error.mark && error.mark.toJSON());
    console.groupEnd();
    /* eslint-enable no-console */
  } else if (!handled) {
    throw new Error('Unhandled Slate schema error: ' + JSON.stringify(error, null, 2));
  }
};

export function getTextsInOneBlockFragment(ch: Editor) {
  // extract plain text from some blocks
  const {document} = ch.value;
  const textsAndInlinesList = document
    .getBlocks()
    .reduce<List<Text | Inline>>((reduction: List<Text | Inline>, block: Block) => {
      return reduction.concat(block.nodes) as List<Text | Inline>;
    }, List<Text | Inline>());

  return Document.create([Block.create({type: SlateBlock.DEFAULT, nodes: textsAndInlinesList})]);
}

export const getDefaultLeafBlocksFragment = (
  change: Editor,
  useSelection: boolean = true
): Document => {
  const {document, selection} = change.value;
  const range = document.createRange({anchor: selection?.anchor, focus: selection?.focus});
  const fragmentWithAllLevels = useSelection ? document.getFragmentAtRange(range) : document;
  const defaultBlocks = fragmentWithAllLevels
    .getBlocks()
    .filter((b: Block) => isDefaultBlock(b))
    .toList();
  return Document.create(defaultBlocks);
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function addDataToBlock(ch: Editor, block: Block, dataKey: string, dataValue: any) {
  if (dataValue === undefined) {
    const newData = block.data.toJS();
    delete newData[dataKey];
    ch.setNodeByKey(block.key, {data: newData});
  } else {
    ch.setNodeByKey(block.key, {data: {...block.data.toJS(), [dataKey]: dataValue}});
  }
}

export function copyFragment(event: React.ClipboardEvent, fragment: Document): void {
  const fragmentEditor = new Editor({value: Value.create({document: fragment})});
  cloneFragment(event, fragmentEditor.moveToRangeOfDocument());
}
