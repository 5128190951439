import {type Action, type ActionCreator, type Dispatch} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {type AppState, type ChatMessage, type Room} from 'store/interface';
import {
  type WampCallAction,
  type WampCallActionWithRoomId,
  type WampCallResponseAction,
  type WampErrorAction,
  type WampSubscribeAction
} from 'services/wamp/actions/interface';

import {type RTCCall} from '../../../webRTC/interface';

export const messagesLoadingLimit: number = 100;

export enum ClientMessageTypes { // types that are generated on client
  noAnswer = 'noAnswer',
  declined = 'declined',
  incomingCall = 'incomingCall',
  outgoingCall = 'outgoingCall'
}

export interface ChatAction extends Action {}

export interface SelectRoomAction extends ChatAction {
  id: number;
}

export interface ChangeActiveTabAction extends ChatAction {
  id: string;
}

export interface MessageReceivedAction extends ChatAction {
  roomId: number;
  message: ChatMessage;
  shouldNotify?: boolean;
}

export interface TypingStartedAction extends ChatAction {
  timerId: NodeJS.Timeout;
}

export interface RecipientTypingStartedAction extends ChatAction {
  roomId: number;
  timerId: NodeJS.Timeout;
}

export interface RecipientTypingStoppedAction extends ChatAction {
  roomId: number;
}

export interface UpdatedMessageReceivedAction extends ChatAction {
  roomId: number;
  updatedMessage: ChatMessage;
}

export interface DeletedMessageReceivedAction extends ChatAction {
  roomId: number;
  deletedMessage: ChatMessage;
}

export interface MessageReadAction extends WampCallAction<{}, {}> {
  roomId: number;
}

export type MessageReadFailAction = WampErrorAction<{}, {}, MessageReadAction>;

export type GetPrivateRoomsAction = WampCallAction<{}, {expand?: string; fields?: string}>;

export type GetPrivateRoomsSuccessAction = WampCallResponseAction<
  [Room[]],
  {},
  GetPrivateRoomsAction
>;

export interface GetRoomMessagesAction extends WampCallAction<number[], {}> {
  withoutLoader?: boolean;
}

export type GetRoomMessagesSuccessAction = WampCallResponseAction<
  [ChatMessage[]],
  {},
  GetRoomMessagesAction
>;

export interface MessageSendAction extends WampCallAction<string[], {}> {}

export type MessageSendSuccessAction = WampCallResponseAction<{}, {}, MessageSendAction>;

export type SubscribeToRoomAction = WampSubscribeAction<
  Array<number | ChatMessage | RTCCall | boolean | string>,
  {}
>;

export interface ChangeUserStatusAction extends ChatAction {
  userId: number;
  onlineState: number;
  callsNumber: number;
}

export interface PromoteMessageToUpdateAction extends ChatAction {
  roomId: number;
  id: number;
}

interface ClearMessageToUpdateAction extends ChatAction {
  roomId: number;
}

export interface MessageUpdateAction extends WampCallActionWithRoomId<string[], {}> {}

export interface MessageUpdateSuccessAction
  extends WampCallResponseAction<{}, {}, MessageUpdateAction> {}

interface MessageDeleteAction extends WampCallActionWithRoomId<string[], {}> {}

export interface ChatImageAction extends ChatAction {
  roomId: number;
  messageId: number;
  imageId: string;
}

export interface SelectRoomCreator extends ActionCreator<SelectRoomAction> {
  (id: number): SelectRoomAction;
}

export interface ChangeActiveTabCreator extends ActionCreator<ChangeActiveTabAction> {
  (id: string): ChangeActiveTabAction;
}

export interface MessageReceivedCreator extends ActionCreator<MessageReceivedAction> {
  (roomId: number, message: ChatMessage, shouldNotify?: boolean): MessageReceivedAction;
}

export interface MessageReadCreator extends ActionCreator<MessageReadAction> {
  (roomId: number, messageId: number): MessageReadAction;
}

export interface GetPrivateRoomsCreator extends ActionCreator<GetPrivateRoomsAction> {
  (): GetPrivateRoomsAction;
}

export interface GetRoomMessagesCreator extends ActionCreator<GetRoomMessagesAction> {
  (roomId: number, withoutLoader?: boolean, lastMessageId?: number): GetRoomMessagesAction;
}

export interface MessageSendCreator extends ActionCreator<MessageSendAction> {
  (roomId: number, message: string): MessageSendAction;
}

export interface ChangeUserStatusCreator extends ActionCreator<ChangeUserStatusAction> {
  (userId: number, sessionsNumber: number, callsNumber: number): ChangeUserStatusAction;
}

export interface ClearChatCreator extends ActionCreator<ChatAction> {
  (): ChatAction;
}

export interface ChatTypingCreator
  extends ActionCreator<ThunkAction<void, AppState, void, Action>> {
  (roomId: number): (dispatch: Dispatch<Action>, getState: () => AppState) => void;
}

export interface ResetChatTypingCreator extends ActionCreator<{}> {
  (): (dispatch: Dispatch<Action>, getState: () => AppState) => void;
}

export interface RecipientTypingActionCreator
  extends ActionCreator<ThunkAction<void, AppState, void, Action>> {
  (roomId: number): ThunkAction<void, AppState, void, Action>;
}

export interface PromoteMessageToUpdateActionCreator
  extends ActionCreator<PromoteMessageToUpdateAction> {
  (roomId: number, id: number): PromoteMessageToUpdateAction;
}

export interface MessageUpdateActionCreator extends ActionCreator<MessageUpdateAction> {
  (roomId: number, messageId: number, messageText: string): MessageUpdateAction;
}

export interface MessageDeleteActionCreator extends ActionCreator<MessageDeleteAction> {
  (roomId: number, messageId: number): MessageDeleteAction;
}

export interface ClearMessageToUpdateActionCreator
  extends ActionCreator<ClearMessageToUpdateAction> {
  (roomId: number): ClearMessageToUpdateAction;
}

export interface UpdatedMessageReceivedActionCreator
  extends ActionCreator<UpdatedMessageReceivedAction> {
  (roomId: number, updatedMessage: ChatMessage): UpdatedMessageReceivedAction;
}

export interface DeletedMessageReceivedActionCreator
  extends ActionCreator<DeletedMessageReceivedAction> {
  (roomId: number, deletedMessage: ChatMessage): DeletedMessageReceivedAction;
}

export interface ChatImageActionCreator extends ActionCreator<ChatImageAction> {
  (roomId: number, messageId: number, imageId: string): ChatImageAction;
}

export interface StartPhrasesAction extends ChatAction {
  roomId: number;
}
