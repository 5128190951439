import {useEffect} from 'react';

import {isTouchEmulatorEnabled, loadTouchEmulator} from './utils';

export function useTouchEmulator() {
  useEffect(() => {
    if (isTouchEmulatorEnabled()) {
      loadTouchEmulator();
    }
  }, []);
}
