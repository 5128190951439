import React from 'react';
import classNames from 'classnames';

import {CONNECTED} from 'store/interface';
import {Resources} from 'contexts/Viewer';
import ChatImageAdapter from 'contexts/Viewer/adapters/ChatImageAdapter';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';

import Header from './components/Header';
import {ChatImageUpload} from './components/Body/UploadingImages/ChatImageUpload';
import {type ChatProps as Props} from './interface';
import './chat.scss';

class Chat extends React.Component<Props> {
  public static displayName: string = 'Chat';

  private resourcesNode: Element | null;

  private get resourcesNodeGetter() {
    if (this.props.isInPopover) {
      return null;
    }
    if (this.resourcesNode) {
      return this.resourcesNode;
    }
    this.resourcesNode = document.querySelector('.page-left');
    return this.resourcesNode;
  }

  public componentDidMount() {
    if (this.props.wampStatus === CONNECTED && !this.props.roomsLoaded) {
      this.getRooms();
    }
    if (this.props.isInPopover) {
      window.addEventListener('keydown', this.chatKeyDown);
    }
  }

  public componentWillUnmount() {
    if (this.props.isInPopover) {
      window.removeEventListener('keydown', this.chatKeyDown);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    const {wampStatus} = this.props;
    if (wampStatus === CONNECTED && prevProps.wampStatus !== CONNECTED) {
      this.getRooms();
    }
  }

  public render() {
    const {roomsLoaded, wampError, selectedRoomId} = this.props;
    const className = classNames(
      'chat',
      {'popover-chat': this.props.isInPopover},
      {compact: this.props.isChatCompact}
    );
    return (
      <Resources node={this.resourcesNodeGetter} id={`chat-${selectedRoomId}`}>
        <ChatImageAdapter />
        <div className={className}>
          {roomsLoaded || wampError ? this.renderContent() : <Loader />}
        </div>
      </Resources>
    );
  }

  private renderContent = () => {
    const {chatIsActive} = this.props;
    return (
      <>
        {this.renderWampErrorMask()}
        {this.renderHeader()}
        <ChatImageUpload
          isChatCompact={this.props.isChatCompact}
          editLastOwnMessage={this.editLastOwnMessage}
          chatIsActive={chatIsActive}
        />
      </>
    );
  };

  private renderHeader = () => {
    if (this.props.shouldRenderHeader) {
      return (
        <Header
          unreadMessagesCount={this.props.unreadMessagesCount}
          isChatCompact={this.props.isChatCompact}
          selectRoom={this.props.selectRoom}
        />
      );
    }
    return null;
  };

  private editLastOwnMessage = () => {
    const {promoteMessageToUpdate, selectedRoomId, lastOwnMessageId} = this.props;
    if (lastOwnMessageId) {
      promoteMessageToUpdate(selectedRoomId!, lastOwnMessageId);
    }
  };

  private renderWampErrorMask = () => {
    if (this.props.wampError) {
      return (
        <WampErrorMask
          reload={() => {
            this.props.clearChat();
            this.getRooms();
          }}
        />
      );
    }
    return null;
  };

  private chatKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.props.closeChatPopover();
    }
  };

  private getRooms = () => {
    this.props.getPrivateRoomsList();
    this.props.browserCheckRequest();
  };
}

export default Chat;
