import React, {type FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import {
  StandaloneGrammarPlayer,
  StandaloneHomeworkPlayer,
  StandalonePDFDocumentPlayer,
  StandaloneUnitPlayer
} from './components';

export const XPlayerRoute: FC = () => {
  return (
    <Routes>
      <Route path="coursebook/:coursebookInstanceId">
        <Route path="unit/:unitInstanceId/page?/:page?" element={<StandaloneUnitPlayer />} />
        <Route path="grammar/:grammarId?" element={<StandaloneGrammarPlayer />} />
      </Route>
      <Route
        path="course/:courseId/homework/:homeworkId/page?/:page?"
        element={<StandaloneHomeworkPlayer />}
      />
      <Route
        path="document/:docInstanceId/page?/:page?"
        element={<StandalonePDFDocumentPlayer />}
      />
    </Routes>
  );
};
