import {type Map} from 'immutable';
import {type MixedSchema} from 'yup/lib/mixed';
import {type OptionalObjectSchema, type TypeOfShape} from 'yup/lib/object';
import {type AnyObject} from 'yup/lib/types';

import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseValues
} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import {type XWidgetProperties} from '../interface';
import type XWordPictureSetBaseCardRecord from './XWordPictureSetBaseCardRecord';
import type XWordPictureSetBaseRecord from './XWordPictureSetBaseRecord';
import {type CardSizeType} from './XPictureSet/interface';

export interface XWordPictureSetBaseCardProps {
  id: string;
  cardSizeType?: CardSizeType;
  widgetTheme?: WPSTheme;
}

export interface XWordPictureSetBaseCardProperties {
  id: string;
  cardSizeType: CardSizeType;
}

export interface XWordPictureSetBaseProperties<
  I extends WordPictureSetBaseCardId,
  V extends WordPictureSetBaseValues<I, WordPictureSetBaseMetaDataProperties<I>>,
  C extends XWordPictureSetBaseCardRecord,
  W extends XWordPictureSetBaseRecord<I, V, C>
> extends XWidgetProperties {
  readonly cards: Map<string, C>;
  readonly isDefaultHidden: boolean;
  readonly isNotInteractive: boolean;
  readonly defaultValues: V;
  readonly widgetTheme: WPSTheme;
  addCard(cardSizeType?: CardSizeType): W;
  removeCard(cardId: string): W;
  changeIsDefaultHidden(): W;
  switchIsNotInteractive(): W;
  changeCardSizeType(cardId: string, newCardSizeType: CardSizeType): W;
  changeWidgetTheme(widgetTheme: WPSTheme): W;
}

export type SchemaResult = OptionalObjectSchema<
  {cards: MixedSchema},
  AnyObject,
  TypeOfShape<{cards: MixedSchema}>
>;

export enum WPSTheme {
  ORANGE = 'orange',
  GREEN = 'green',
  BLUE = 'blue',
  BLACK = 'black',
  WHITE = 'white'
}
