import {useCallback, useEffect, useRef, useState} from 'react';

import {useDebounceCallback} from 'helpers/debounce';
import {clamp} from 'helpers/clamp';

interface Options {
  init: number;
  min: number;
  max: number;
  onChange: (page: number) => void;
}

export function usePager({init = 1, min = 1, max = Number.MAX_SAFE_INTEGER, onChange}: Options) {
  const [pageNumber, setPageNumber] = useState(init);

  const onChangeRef = useRef(onChange);

  const initRef = useRef(init);

  const handlerPrev = useCallback(
    () => setPageNumber(prevState => clamp(prevState - 1, min, max)),
    [max, min]
  );

  const handlerNext = useCallback(
    () => setPageNumber(prevState => clamp(prevState + 1, min, max)),
    [max, min]
  );

  const debouncedOnChange = useDebounceCallback(
    (page: number) => {
      const onChange = onChangeRef.current;
      onChange(page);
    },
    [],
    400,
    {leading: true}
  );

  useEffect(() => {
    const {current: init} = initRef;

    if (init !== pageNumber) {
      debouncedOnChange(pageNumber);
    }
  }, [debouncedOnChange, pageNumber]);

  useEffect(() => {
    initRef.current = init;
    setPageNumber(init);
  }, [init]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  return {pageNumber, handlerPrev, handlerNext};
}
