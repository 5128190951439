import {List, Map} from 'immutable';

import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import type XWordPictureSetBaseCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordPictureSetBaseCardRecord';
import {WPSTheme} from 'store/exercise/editor/widgets/XWordPictureSet/baseInterface';

import WidgetRecord from '../../WidgetRecord';
import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseCardJSON,
  type WordPictureSetBaseJSON,
  type WordPictureSetBaseMetaDataJSON,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseProperties,
  type WordPictureSetBaseProps,
  type WordPictureSetBaseValues
} from './baseInterface';

interface Props
  extends WordPictureSetBaseProps<
    WordPictureSetBaseCardId,
    WordPictureSetBaseMetaDataJSON<WordPictureSetBaseCardId>,
    WordPictureSetBaseCardJSON
  > {}

abstract class WordPictureSetBaseRecord<
    I extends WordPictureSetBaseCardId,
    V extends WordPictureSetBaseValues<I, WordPictureSetBaseMetaDataProperties<I>>,
    VJson extends WordPictureSetBaseMetaDataJSON<I>,
    C extends XWordPictureSetBaseCardRecord
  >
  extends WidgetRecord<string, V, unknown>
  implements WordPictureSetBaseProperties<I, V, VJson, C, WordPictureSetBaseRecord<I, V, VJson, C>>
{
  public declare readonly cards: Map<string, C>;

  public declare readonly isDefaultHidden: boolean;

  public declare readonly isNotInteractive: boolean;

  public declare readonly defaultValues: V;

  public declare readonly widgetTheme: WPSTheme;

  abstract switchIsCardHiddenInValue(cardMetaData: I, setField?: boolean): I;

  abstract createCard(cardJSON: WordPictureSetBaseCardJSON): C;

  constructor(raw: Props) {
    super(raw);
    this.initValues({
      widgetTheme: raw.widgetTheme || WPSTheme.ORANGE
    });
  }

  public toJSON(): WordPictureSetBaseJSON<I, VJson, WordPictureSetBaseCardJSON> {
    return {
      ...super.toJSON(),
      cards: this.cards.map((card: C) => card.toJSON()).toObject(),
      defaultValues: this.defaultValues.toJS(),
      isDefaultHidden: this.isDefaultHidden,
      isNotInteractive: this.isNotInteractive,
      widgetTheme: this.widgetTheme
    };
  }

  public setValuesFromJSON(values?: VJson): this {
    return this.withMutations(record => {
      const newCardIds =
        values?.cardIds && !Array.isArray(values)
          ? (List(values.cardIds) as List<I>)
          : (this.remakeCardIds() as List<I>);

      record.set(
        'values',
        Map([
          ['isCollaborativeManagement', !!values?.isCollaborativeManagement],
          ['cardIds', newCardIds]
        ])
      );
    });
  }

  public switchIsHiddenByCardId(
    cardId: string,
    customSwitchMethod?: (cardId: I, setField?: boolean) => I
  ) {
    const cardIndex = (this.values.get('cardIds') as List<I>).findIndex(
      CardId => CardId?.cardId === cardId
    );
    const card = (this.values.get('cardIds') as List<I>).get(cardIndex);

    const newCardIds = customSwitchMethod
      ? (this.values.get('cardIds') as List<I>).set(cardIndex, customSwitchMethod(card))
      : (this.values.get('cardIds') as List<I>).set(
          cardIndex,
          this.switchIsCardHiddenInValue(card)
        );

    const newValues = this.values.set('cardIds', newCardIds);

    return this.set('values', newValues);
  }

  public hideAll() {
    const newCardIds = (this.values.get('cardIds') as List<I>)
      .map((cardId: I) => this.switchIsCardHiddenInValue(cardId, true))
      .toList();

    const newValues = this.values.set('cardIds', newCardIds);
    return this.set('values', newValues);
  }

  public showAll() {
    const newCardIds = (this.values.get('cardIds') as List<I>)
      .map((cardId: I) => this.switchIsCardHiddenInValue(cardId, false))
      .toList();

    const newValues = this.values.set('cardIds', newCardIds);
    return this.set('values', newValues);
  }

  public hidePart() {
    let hiddenCardsIndex = new Set<number>();
    const cardsSize = this.cards.size;

    const numberHiddenCards = Math.floor(cardsSize / 3) >= 1 ? Math.floor(cardsSize / 3) : 1;

    while (hiddenCardsIndex.size < numberHiddenCards) {
      hiddenCardsIndex = hiddenCardsIndex.add(Math.floor(Math.random() * cardsSize));
    }

    const newCardIds = Array.from(hiddenCardsIndex).reduce(
      (cardIds: List<I>, item: number) =>
        cardIds.set(item, this.switchIsCardHiddenInValue(cardIds.get(item), true)),
      this.values.get('cardIds') as List<I>
    );

    const newValues = this.values.set('cardIds', newCardIds);
    return this.set('values', newValues);
  }

  public shuffleCards() {
    const newCardIds = (this.values.get('cardIds') as List<I>).reduce(
      (cardIds: List<I>, cardId: I, key: number) => {
        const newIndex = Math.floor(Math.random() * cardIds?.size);
        const oldValueFromIndex = cardIds.get(newIndex);

        return cardIds.set(newIndex, cardIds.get(key)).set(key, oldValueFromIndex);
      },
      this.values.get('cardIds') as List<I>
    );

    const newValues = this.values.set('cardIds', newCardIds);
    return this.set('values', newValues);
  }

  public switchIsCollaborativeManagement() {
    const newValues = this.values.set(
      'isCollaborativeManagement',
      !this.values.get('isCollaborativeManagement')
    );
    return this.set('values', newValues);
  }

  get isAvailableSelection() {
    return true;
  }

  protected createCards(raw: WordPictureSetBaseProps<I, VJson, WordPictureSetBaseCardJSON>) {
    if (Map.isMap(raw.cards)) return raw.cards;

    return Map(
      Array.isArray(raw.defaultValues)
        ? raw.defaultValues.map(item => [item.cardId, this.createCard(raw.cards[item.cardId])])
        : raw.defaultValues.cardIds.map(item => [
            item.cardId,
            this.createCard(raw.cards[item.cardId])
          ])
    );
  }

  protected createValue(raw: WordPictureSetBaseProps<I, VJson, WordPictureSetBaseCardJSON>) {
    const {values, defaultValues, isDefaultHidden} = raw;

    if (Map.isMap(values)) return values as unknown as V;

    const newCardIds = List.isList(values)
      ? (values as unknown as List<I>)
      : this.createNewCardIds(defaultValues, isDefaultHidden, values);

    return Map([
      ['isCollaborativeManagement', !!raw.values?.isCollaborativeManagement],
      ['cardIds', newCardIds]
    ]);
  }

  protected remakeCardIds() {
    return List(
      (this.defaultValues.get('cardIds') as List<I>).map((cardId: I) =>
        this.switchIsCardHiddenInValue(cardId, this.isDefaultHidden)
      ) as List<I>
    );
  }

  public makeDefaultValues(defaultValues: VJson) {
    if (Map.isMap(defaultValues)) return defaultValues as unknown as V;

    const newCardIds = List.isList(defaultValues)
      ? (defaultValues as unknown as List<I>)
      : Array.isArray(defaultValues)
        ? (List(defaultValues) as List<I>)
        : (List(defaultValues.cardIds) as List<I>);

    return Map([
      ['isCollaborativeManagement', !!defaultValues?.isCollaborativeManagement],
      ['cardIds', newCardIds]
    ]);
  }

  public checkValues(values: VJson, defaultValues: VJson) {
    const isValueHaveCardsFromDefaultValues = !Array.isArray(defaultValues)
      ? defaultValues.cardIds.every(defaultItem =>
          values.cardIds.some(item => item?.cardId === defaultItem?.cardId)
        )
      : defaultValues.every(defaultItem =>
          values.cardIds.some(item => item?.cardId === defaultItem?.cardId)
        );

    const isSameLength = !Array.isArray(defaultValues)
      ? defaultValues.cardIds.length === values?.cardIds.length
      : defaultValues.length === values?.cardIds.length;

    return isValueHaveCardsFromDefaultValues && isSameLength;
  }

  public createNewCardIds(defaultValues: VJson, isDefaultHidden: boolean, values?: VJson) {
    return !Array.isArray(values) && values?.cardIds && this.checkValues(values, defaultValues)
      ? List(values.cardIds)
      : this.makeNewCardIdsFromDefaultValues(defaultValues, isDefaultHidden);
  }

  public makeNewCardIdsFromDefaultValues(defaultValues: VJson, isDefaultHidden: boolean) {
    return List(
      Array.isArray(defaultValues)
        ? defaultValues.map((cardId: I) => this.switchIsCardHiddenInValue(cardId, isDefaultHidden))
        : defaultValues.cardIds.map((cardId: I) =>
            this.switchIsCardHiddenInValue(cardId, isDefaultHidden)
          )
    );
  }
}

decorate(WordPictureSetBaseRecord, {
  cards: property(Map()),
  isDefaultHidden: property(false),
  isNotInteractive: property(false),
  defaultValues: property(List()),
  widgetTheme: property(WPSTheme.ORANGE)
} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
record()(WordPictureSetBaseRecord);
export default WordPictureSetBaseRecord;
