export const xWidgetPrefix = 'XWIDGET/';

export const TASK_CHANGE = xWidgetPrefix + 'TASK_CHANGE';
export const CONTENT_CHANGE = xWidgetPrefix + 'CONTENT_CHANGE';
export const TOGGLE_DISPLAY_AS_BUTTON = xWidgetPrefix + 'TOGGLE_DISPLAY_AS_BUTTON';
export const DISPLAY_BUTTON_TITLE_CHANGE = xWidgetPrefix + 'DISPLAY_BUTTON_TITLE_CHANGE';
export const DISPLAY_BUTTON_ICON_CHANGE = xWidgetPrefix + 'DISPLAY_BUTTON_ICON_CHANGE';
export const GRAMMAR_TITLE_CHANGE = xWidgetPrefix + 'GRAMMAR_TITLE_CHANGE';
export const QUESTIONS_CHANGE = xWidgetPrefix + 'QUESTIONS_CHANGE';
export const QUESTIONS_EXAMPLE_ANSWER_CHANGE = xWidgetPrefix + 'QUESTIONS_EXAMPLE_ANSWER_CHANGE';
export const TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE =
  xWidgetPrefix + 'TOGGLE_FIRST_QUESTION_ITEM_EXAMPLE';
export const SCRAMBLED_SENTENCES_CHANGE = xWidgetPrefix + 'SCRAMBLED_SENTENCES_CHANGE';
export const TOGGLE_CONTAINS_EXAMPLE = xWidgetPrefix + 'TOGGLE_CONTAINS_EXAMPLE';
export const ADD_EXTRA_CHOICE = xWidgetPrefix + 'ADD_EXTRA_CHOICE';
export const TOGGLE_PRE_FILL_VALUES = xWidgetPrefix + 'TOGGLE_PRE_FILL_VALUES';
export const SET_EXTRA_CHOICE_VALUE = xWidgetPrefix + 'SET_EXTRA_CHOICE_VALUE';
export const DELETE_EXTRA_CHOICE = xWidgetPrefix + 'DELETE_EXTRA_CHOICE';
