import {type Editor} from '@englex/slate';

import {SlateInline} from '../../../../interface';
import {insertInline} from '../../changes';

export const insertIcon = (ch: Editor, icon: string, additionalClass?: string) =>
  ch
    .command(insertInline, {
      type: SlateInline.ICON,
      data: {icon, ...(additionalClass ? {additionalClass} : {})}
    })
    .moveToStartOfNextText();
