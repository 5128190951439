import {useEffect, useRef} from 'react';

export function usePrevious<T>(props: T | undefined) {
  const ref = useRef<T | undefined>();

  useEffect(() => {
    ref.current = props;
  }, [props]);

  return ref.current;
}
