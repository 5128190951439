import {type FC} from 'react';
import {useIntl} from 'react-intl';

import {DotsAnimation} from '../DotsAnimation/DotsAnimation';

import './userTyping.scss';

export const UserTyping: FC = () => {
  const intl = useIntl();

  const text = intl.formatMessage({id: 'Chat.RecipientIsTyping'});

  return (
    <div className="user-typing">
      {text} <DotsAnimation />
    </div>
  );
};
