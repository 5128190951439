import {type Plugin} from '@englex/slate-react';

export function getPluginsRecursive(result: Plugin[], plugins?: Plugin[]) {
  if (!plugins) {
    return;
  }
  for (const plugin of plugins) {
    result.push(plugin);
    if (plugin.plugins) {
      getPluginsRecursive(result, plugin.plugins);
    }
  }
}
