import React, {type SyntheticEvent} from 'react';
import ruI18n from '@emoji-mart/data/i18n/ru.json';
import enI18n from '@emoji-mart/data/i18n/en.json';
import Picker from '@emoji-mart/react';
import Button from 'react-bootstrap/lib/Button';
import Overlay from 'react-bootstrap/lib/Overlay';
import Popover from 'react-bootstrap/lib/Popover';
import {injectIntl, type WrappedComponentProps} from 'react-intl';

import {type EmojiData} from './interface';
import Icon from '../../../Icon';
import {emojiData} from './emojiData';

import './EmojiButton.scss';

interface OwnProps {
  focusTextarea: () => void;
  selectEmoji: (e: EmojiData) => void;
  disabled?: boolean;
}

interface Props extends OwnProps, WrappedComponentProps {}

interface State {
  show: boolean;
}

class EmojiButton extends React.Component<Props, State> {
  public state: State = {show: false};
  private button: Button | null;

  public static getDerivedStateFromProps = (props: Props) => {
    if (props.disabled) {
      return {show: false};
    }
    return null;
  };

  public render() {
    const {show} = this.state;
    const {disabled, intl} = this.props;

    const i18n = intl.locale === 'ru' ? ruI18n : enI18n;

    return (
      <div className="emoji">
        <Button
          className={`btn-circle btn-transparent ${disabled && 'disabled'}`}
          onClick={this.togglePopover}
          ref={this.getRef}
          disabled={disabled}
        >
          <Icon name={show ? 'pc-close' : 'smile-o'} size={show ? 'lg' : 'xlg'} />
        </Button>
        <Overlay
          container={this}
          placement="top"
          rootClose={true}
          show={show}
          target={this.button!}
          onHide={this.hide}
        >
          <Popover
            id="emoji-mart-popover"
            className="emoji-popover"
            placement="top"
            onClick={this.stopPropagation}
            onKeyDown={this.stopPropagation}
          >
            <div className="emoji-picker-wrapper">
              <Picker
                data={emojiData}
                theme="light"
                i18n={i18n}
                set="native"
                dynamicWidth={true}
                previewPosition="none"
                skinTonePosition="none"
                noCountryFlags={true}
                onEmojiSelect={this.selectEmoji}
              />
            </div>
          </Popover>
        </Overlay>
      </div>
    );
  }

  private getRef = (el: Button) => (this.button = el);

  private hide = () => {
    this.setState({show: false});
    this.props.focusTextarea();
  };

  private selectEmoji = (e: EmojiData) => {
    this.props.selectEmoji(e);
  };

  private stopPropagation = (e: SyntheticEvent<Popover>) => e.stopPropagation();

  private togglePopover = () => {
    this.setState({show: !this.state.show});
    this.props.focusTextarea();
  };
}

export default injectIntl(EmojiButton);
