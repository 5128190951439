import {type Action} from 'redux';
import {type AxiosAction} from 'redux-axios-middleware';

import {type UploadProgressHandler} from 'services/axios/interface';

import {
  type AudioFile,
  type MediaContext,
  PLAYING,
  type PlayStatusV2
} from '../../../components/media/interface';
import {
  type ChangePlayStatusAction,
  type ChangeTimestampAction,
  type ChangeVolumeAction,
  type SetDurationAction
} from '../interface';
import {
  ALLOW_AUDIO,
  ATTACH_AUDIO,
  CHANGE_ACTIVE_AUDIO,
  CHANGE_PLAY_STATUS,
  CHANGE_PLAYBACK_RATE,
  CHANGE_TIMESTAMP,
  CHANGE_VOLUME,
  CLEAR_AUDIO,
  CLEAR_FORCE_RESTART,
  EXISTENCE_CHECK,
  REQUEST_AUDIO_FILE,
  SET_DURATION,
  UPLOAD
} from './actionTypes';
import {type XWidgetAction} from '../../exercise/editor/actions/interface';

export interface AttachAudioAction extends XWidgetAction {
  audio: AudioFile;
}

export interface ChangeAudioAction extends Action {
  audioFile?: AudioFile;
  context?: MediaContext;
}

export const allowAudio = (): Action => ({type: ALLOW_AUDIO});

export const attachAudio = (xwidgetId: string, audio: AudioFile): AttachAudioAction => ({
  type: ATTACH_AUDIO,
  xwidgetId,
  audio
});

export const clearAudio = (xwidgetId: string): XWidgetAction => ({
  type: CLEAR_AUDIO,
  xwidgetId
});

export const changeAudio = (audioFile?: AudioFile, context?: MediaContext): ChangeAudioAction => ({
  type: CHANGE_ACTIVE_AUDIO,
  audioFile,
  context
});

export const changeTimestamp = (timestamp: number): ChangeTimestampAction => ({
  type: CHANGE_TIMESTAMP,
  startedAt: new Date().getTime(),
  timestamp
});

export const changePlayStatus = (
  playStatus?: PlayStatusV2,
  forceRestart?: true
): ChangePlayStatusAction => ({
  type: CHANGE_PLAY_STATUS,
  playStatus: playStatus || undefined,
  startedAt: playStatus === PLAYING ? new Date().getTime() : undefined,
  forceRestart
});

export interface ChangePlaybackRateAction extends Action {
  playbackRate: number;
}
export const changePlaybackRate = (playbackRate: number): ChangePlaybackRateAction => ({
  type: CHANGE_PLAYBACK_RATE,
  playbackRate
});

export const clearForceRestart = () => ({type: CLEAR_FORCE_RESTART});

export const changeVolume = (volume: number): ChangeVolumeAction => ({
  type: CHANGE_VOLUME,
  volume
});

export const setDuration = (length: number): SetDurationAction => ({
  type: SET_DURATION,
  length
});

export const performExistenceCheck = (md5: string): AxiosAction => ({
  type: EXISTENCE_CHECK,
  payload: {
    request: {
      url: `/v1/classroom/audio/exist`,
      method: 'get',
      params: {md5}
    }
  }
});

export const uploadAudio = (
  data: FormData,
  onUploadProgress: UploadProgressHandler
): AxiosAction => ({
  type: UPLOAD,
  payload: {
    request: {
      url: `/v1/classroom/audio`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data,
      onUploadProgress
    }
  }
});

export const requestAudioFile = (id: number): AxiosAction => ({
  type: REQUEST_AUDIO_FILE,
  payload: {
    request: {
      method: 'get',
      url: `/v1/classroom/audio/${id}`
    }
  }
});
