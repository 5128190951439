import React, {type FC, useCallback} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';

import Icon from 'components/Icon';
import {downloadBrowserLink} from 'config/static';
import {type AppState, type Locale} from 'store/interface';

import {BrowserName} from '../interface';

interface Props {
  onClose: () => void;
}

const BadBrowser: FC<Props> = ({onClose}) => {
  const locale = useSelector<AppState, Locale>(s => s.intl.locale);
  const browserLink = useCallback(browser => downloadBrowserLink(locale)(browser), [locale]);
  return (
    <div className="bad-browser">
      <div className="bad-browser__close" onClick={onClose} />
      <FormattedMessage id="Chat.BrowserHasNoWebRTCSupport" />
      <FormattedMessage id="Chat.UseBrowserWithWebRTCSupport" />
      <Icon name="warning" size="lg" />
      <a target="_blank" href={browserLink(BrowserName.CHROME)} rel="noopener noreferrer">
        <Icon name="chrome" size="2x" />
      </a>
      <a target="_blank" href={browserLink(BrowserName.YANDEX)} rel="noopener noreferrer">
        <Icon name="yandex_browser" size="2x" />
      </a>
      <a target="_blank" href={browserLink(BrowserName.OPERA)} rel="noopener noreferrer">
        <Icon name="opera" size="2x" />
      </a>
    </div>
  );
};

export default BadBrowser;
