import {List} from 'immutable';

import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../interface';
import WidgetRecord from '../../WidgetRecord';
import {type DialogueJSON, type DialogueProperties} from './interface';
import MessageRecord from './MessageRecord';
import SpeakerRecord from './SpeakerRecord';

class DialogueRecord extends WidgetRecord implements DialogueProperties {
  public declare readonly speakers: List<SpeakerRecord>;

  public declare readonly messages: List<MessageRecord>;

  public declare readonly styleName: string;

  constructor(raw: DialogueJSON) {
    super(raw);
    this.initValues({
      speakers: List(raw.speakers.map(speaker => new SpeakerRecord(speaker))),
      messages: List(raw.messages.map(message => new MessageRecord(message))),
      styleName: raw.styleName
    });
  }

  public toJSON(): DialogueJSON {
    return {
      ...super.toJSON(),
      speakers: this.speakers.map((speaker: SpeakerRecord) => speaker.toJSON()).toArray(),
      messages: this.messages.map((message: MessageRecord) => message.toJSON()).toArray(),
      styleName: this.styleName
    };
  }

  public get type() {
    return WidgetType.DIALOGUE;
  }

  public get isAvailableSelection() {
    return true;
  }
}

decorate(DialogueRecord, {
  speakers: property(List()),
  messages: property(List()),
  styleName: property('')
});
record()(DialogueRecord);
export default DialogueRecord;
