import React from 'react';
import Spinner from 'react-spinkit';
import './loader.scss';

interface Props {
  className?: string;
  spinnerClassName?: string;
  shouldRender?: boolean;
}

export default class Loader extends React.PureComponent<Props, {}> {
  public render() {
    if (this.props.shouldRender === false) {
      return null;
    }
    const {className = 'loader', spinnerClassName = 'brand-primary'} = this.props;
    return (
      <div className={className}>
        <Spinner name="three-bounce" className={spinnerClassName} fadeIn="none" />
      </div>
    );
  }
}
