import React from 'react';
import {type Action} from 'redux';
import Button from 'react-bootstrap/lib/Button';
import Scrollbars, {type positionValues as PositionValues} from 'react-custom-scrollbars';
import TextareaAutosize from 'react-autosize-textarea';
import {defineMessages, type WrappedComponentProps, injectIntl} from 'react-intl';
import classNames from 'classnames';

import * as toastr from 'components/toastr';
import {maxChatMessageLength} from 'config/static';

import Icon from '../../../Icon';
import {MESSAGE_SEND_SUCCESS, MESSAGE_UPDATE_SUCCESS} from '../../actions/actionTypes';
import {type FooterProps} from './interface';
import SendButton from './components/SendButton';
import {SoundNotification, soundUrl} from '../../../../helpers/sound';
import {EmojiButton, type EmojiData} from '../EmojiButton';

import './Footer.scss';

const footerMessages = defineMessages({
  EnterMessage: {
    id: 'Chat.EnterMessage'
  },
  MessageSendErrorHeader: {
    id: 'Chat.MessageSendErrorHeader'
  },
  MessageSendErrorText: {
    id: 'Chat.MessageSendErrorText'
  },
  SendMessage: {
    id: 'Chat.SendMessage'
  },
  UpdateMessage: {
    id: 'Chat.UpdateMessage'
  },
  CancelUpdate: {
    id: 'Chat.CancelUpdate'
  },
  MessageUpdateErrorHeader: {
    id: 'Chat.MessageUpdateErrorHeader'
  },
  MessageUpdateErrorText: {
    id: 'Chat.MessageUpdateErrorText'
  }
});

interface ChatFooterState {
  audioNotification: boolean;
  message: string;
  selectedEmoji?: EmojiData;
  textareaCollapseHeight?: number;
}

interface Props extends FooterProps, WrappedComponentProps {}

class Footer extends React.PureComponent<Props, ChatFooterState> {
  public state: ChatFooterState = {
    audioNotification: false,
    message: this.props.defaultText,
    selectedEmoji: undefined,
    textareaCollapseHeight: undefined
  };
  private scrollbar: Scrollbars;
  private textareaRef = React.createRef<HTMLTextAreaElement>();

  private moveCaretToEnd(): void {
    const messageLength = this.state.message.length;
    this.textareaRef.current?.setSelectionRange(messageLength, messageLength);
  }

  public componentDidMount() {
    this.textareaRef.current?.focus();
    this.moveCaretToEnd();
    this.scrollbar?.scrollToBottom();
  }

  public render() {
    const {messageToUpdate, intl, sendingMessageAwait, isMobile} = this.props;
    const chatFooterClassName = classNames('chat-footer', {
      update: !!messageToUpdate,
      compact: this.props.isChatCompact,
      expanded: !!this.state.textareaCollapseHeight
    });
    const placeHolder: string = intl.formatMessage(footerMessages.EnterMessage);
    const sendButtonTitle = intl.formatMessage(
      messageToUpdate ? footerMessages.UpdateMessage : footerMessages.SendMessage
    );

    return (
      <div className={chatFooterClassName} onClick={this.focusTextarea}>
        {this.renderAudioNotification()}
        <EmojiButton
          selectEmoji={this.selectEmoji}
          focusTextarea={this.focusTextarea}
          disabled={this.shouldDisableEmojiButton()}
        />
        <Scrollbars
          autoHide={true}
          onUpdate={this.handleScrollbarUpdate}
          hideTracksWhenNotNeeded={true}
          ref={(el: Scrollbars) => {
            this.scrollbar = el;
          }}
          thumbSize={this.props.isChatCompact ? 20 : undefined}
        >
          <TextareaAutosize
            placeholder={placeHolder}
            className={`text-message ${isMobile ? 'mobile' : ''}`}
            onChange={this.setText}
            value={this.state.message}
            onKeyDown={this.handleKeyDown}
            disabled={!!sendingMessageAwait}
            ref={this.textareaRef}
            onPaste={this.onPaste}
          />
        </Scrollbars>
        {this.renderCancelUpdateButton()}
        <div className="controls">
          {this.renderUploadImageIcon()}
          <SendButton
            messageToUpdate={this.props.messageToUpdate}
            sendMessage={this.sendMessage}
            updateMessage={this.updateMessage}
            shouldDisableSendButton={this.shouldDisableSendButton()}
            shouldDisableUpdateButton={this.shouldDisableUpdateButton()}
            messageTitle={sendButtonTitle}
            isCompact={this.props.isChatCompact}
          />
        </div>
      </div>
    );
  }

  private onPaste = (event: React.ClipboardEvent) => {
    if (this.state.message) return;

    const [file] = event.clipboardData.files;

    const isImage = file?.type.includes('image/');

    if (isImage) {
      event.preventDefault();
      this.props.setChatClipboard({image: file});
    }
  };

  private handleScrollbarUpdate = (values: PositionValues) => {
    const {textareaCollapseHeight} = this.state;
    if (values.scrollHeight > values.clientHeight && !textareaCollapseHeight) {
      this.setState({textareaCollapseHeight: this.textareaRef.current!.clientHeight});
    } else if (
      textareaCollapseHeight &&
      this.textareaRef.current!.clientHeight < textareaCollapseHeight!
    ) {
      this.setState({textareaCollapseHeight: undefined});
    }
  };

  private selectEmoji = (e: EmojiData) => {
    const [m, end, start] = [
      this.state.message,
      this.textareaRef.current!.selectionEnd,
      this.textareaRef.current!.selectionStart
    ];
    const message = m.slice(0, start) + e.shortcodes + m.slice(end, m.length);
    this.setState({message}, () => {
      this.textareaRef.current!.selectionStart = this.textareaRef.current!.selectionEnd =
        end + e.shortcodes.length;
    });
    this.textareaRef.current?.focus();
  };

  private renderCancelUpdateButton = () => {
    const {messageToUpdate, selectedRoomId, intl} = this.props;
    return messageToUpdate ? (
      <Button
        className="chat-cancel-update-btn btn-circle btn-transparent"
        onClick={this.clearMessageToUpdate.bind(this, selectedRoomId)}
      >
        <Icon name="pc-close" title={intl.formatMessage(footerMessages.CancelUpdate)} />
      </Button>
    ) : null;
  };

  private renderUploadImageIcon = () => {
    const {messageToUpdate} = this.props;
    return messageToUpdate || this.state.message.length > 0 ? null : (
      <Button className="btn-circle btn-transparent upload-image" onClick={this.choseImage}>
        <Icon name="image" size="xlg" />
      </Button>
    );
  };

  private choseImage = (e: React.MouseEvent<Button>) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.startChoosingImage();
  };

  private renderAudioNotification = () =>
    this.state.audioNotification ? (
      <audio
        src={soundUrl(SoundNotification.chatMessageSent)}
        loop={false}
        autoPlay={true}
        onEnded={this.setNotificationToFalse}
      />
    ) : null;

  private setNotificationToFalse = () => {
    this.setState({audioNotification: false});
  };

  private shouldDisableEmojiButton = () => {
    const {message} = this.state;
    const {sendingMessageAwait} = this.props;
    const isTooLong: boolean = message.length >= maxChatMessageLength;
    return sendingMessageAwait || isTooLong;
  };

  private shouldDisableSendButton = () => {
    const {message} = this.state;
    return this.shouldDisableEmojiButton() || !!message.match(/^[\s]*$/);
  };

  private shouldDisableUpdateButton = () => {
    const {messageToUpdate} = this.props;
    return this.shouldDisableSendButton() || !messageToUpdate;
  };

  private setText = (event: React.SyntheticEvent<HTMLTextAreaElement>) => {
    const {chatTyping, selectedRoomId} = this.props;
    chatTyping(selectedRoomId!);
    this.setState({message: event.currentTarget.value});
  };

  private focusTextarea = () =>
    !this.props.isMobile && this.textareaRef.current && this.textareaRef.current.focus();

  private sendMessage = () => {
    const {resetChatTyping, sendMessage, selectedRoomId, intl} = this.props;
    if (!this.shouldDisableSendButton()) {
      sendMessage(selectedRoomId!, this.state.message).then((action: Action) => {
        if (action.type === MESSAGE_SEND_SUCCESS) {
          this.setState({audioNotification: false}); // temporarily disabled
          resetChatTyping();
          this.setState({message: ''});
          this.focusTextarea();
        } else {
          const formatMessage = intl.formatMessage;
          toastr.error(
            formatMessage(footerMessages.MessageSendErrorHeader),
            formatMessage(footerMessages.MessageSendErrorText)
          );
        }
      });
    }
  };

  private updateMessage = () => {
    const {updateMessage, intl, messageToUpdate, selectedRoomId, resetChatTyping} = this.props;
    const {message} = this.state;
    if (!this.shouldDisableUpdateButton() && selectedRoomId) {
      updateMessage(selectedRoomId, messageToUpdate!, message).then((action: Action) => {
        if (action.type === MESSAGE_UPDATE_SUCCESS) {
          resetChatTyping();
          this.clearMessageToUpdate(selectedRoomId);
          this.focusTextarea();
        } else {
          const formatMessage = intl.formatMessage;
          toastr.error(
            formatMessage(footerMessages.MessageUpdateErrorHeader),
            formatMessage(footerMessages.MessageUpdateErrorText)
          );
        }
      });
    }
  };

  private clearMessageToUpdate = (roomId: number) => {
    this.props.clearMessageToUpdate(roomId);
  };

  private handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    const {messageToUpdate, selectedRoomId, isMobile} = this.props;
    switch (e.keyCode) {
      case 27:
        messageToUpdate ? this.clearMessageToUpdate(selectedRoomId!) : this.setState({message: ''});
        break;
      case 13:
        if (!isMobile && !e.shiftKey && !e.ctrlKey) {
          e.preventDefault();
          messageToUpdate ? this.updateMessage() : this.sendMessage();
        }
        if (e.ctrlKey) {
          const stringArr = this.state.message.split('');
          const selectionRange = e.currentTarget.selectionEnd - e.currentTarget.selectionStart;
          stringArr.splice(e.currentTarget.selectionStart, selectionRange, '\n');
          const caretPosition = e.currentTarget.selectionStart + 1;
          this.setState({message: stringArr.join('')}, () => {
            if (this.textareaRef.current) {
              this.textareaRef.current.setSelectionRange(caretPosition, caretPosition);
              this.scrollbar.scrollTop(this.scrollbar.getScrollTop() + 20); // 20 is a lineHeight
            }
          });
        }
        break;
      case 38:
        if (this.state.message.length === 0) {
          e.preventDefault();
          this.props.editLastOwnMessage();
        }
        break;
      default:
        break;
    }
  };
}

export default injectIntl(Footer);
