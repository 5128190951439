import React from 'react';

import {type ThicknessName} from 'components/Paint/Painter/plugins';

import {PresetEntry} from './PresetEntry';

import './PresetEntries.scss';

interface Props {
  entries: ThicknessName[];
  active?: ThicknessName;
  onSelect: (name: ThicknessName) => void;
}

export const PresetEntries: React.FC<Props> = ({entries, active, onSelect}) => {
  return (
    <div className="preset-entries">
      {entries.map((name: ThicknessName) => {
        return <PresetEntry key={name} name={name} active={name === active} onSelect={onSelect} />;
      })}
    </div>
  );
};
