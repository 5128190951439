import React, {type FC, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import Tooltip from 'rc-tooltip';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {type AppState} from 'store/interface';
import Spinner from 'components/Spinner';

import './SavingBadge.scss';

interface Props {
  delayMs?: number;
}

export const SavingBadge: FC<Props> = ({delayMs = 5000}) => {
  const isSaving = useSelector(
    (state: AppState) =>
      state.xplayer && state.xplayer.layout && state.xplayer.layout.showSavingBadge
  );
  const [isShow, setIsShow] = useState(isSaving);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (isSaving) {
      const timeout = setTimeout(
        () => {
          isMounted.current && setIsShow(() => isSaving);
        },
        Math.max(delayMs, 0)
      );
      return () => clearTimeout(timeout);
    } else {
      setIsShow(isSaving);
      return;
    }
  }, [delayMs, isMounted, isSaving]);

  if (!isShow) {
    return null;
  }
  const tooltipOverlay = (
    <div className="saving-badge-tooltip">
      <FormattedMessage id="XPlayer.SavingDetailer" />
    </div>
  );

  return (
    <Tooltip overlay={tooltipOverlay} placement="top">
      <div className="x-player-saving-badge">
        <Spinner size={18} />
        <FormattedMessage id="XPlayer.Saving" />
      </div>
    </Tooltip>
  );
};
