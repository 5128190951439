import moment from 'moment';

export function formatSecondsToString(time: number, alwaysShowHours?: boolean): string {
  const roundedTime: number = Math.round(time);
  const hours: number = moment.duration(roundedTime, 'seconds').hours();
  const minutes: number = moment.duration(roundedTime, 'seconds').minutes();
  const seconds: number = moment.duration(roundedTime, 'seconds').seconds();
  const stringHours: string = hours ? String(hours) + ':' : alwaysShowHours ? '00:' : '';
  const stringMinutes: string =
    (minutes ? (minutes > 9 ? String(minutes) : '0' + String(minutes)) : '00') + ':';
  const stringSeconds: string = seconds
    ? seconds > 9
      ? String(seconds)
      : '0' + String(seconds)
    : '00';
  return stringHours + stringMinutes + stringSeconds;
}
