import {type Value} from '@englex/slate';
import {type ThunkAction} from 'redux-thunk';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';
import {
  loadPronunciationsRequest,
  type LoadPronunciationsResponseAction
} from 'store/dictionary/requests';
import {type PronunciationOption} from 'store/exercise/player/interface';
import {type VocabularyRow} from 'store/exercise/player/widgets/Vocabulary/interface';
import {isVocabularyWordJSON} from 'store/exercise/player/widgets/Vocabulary/utils';

import {
  XVOCABULARY_ADD_CATEGORY,
  XVOCABULARY_ADD_ROWS,
  XVOCABULARY_ADD_WORD,
  XVOCABULARY_CHANGE_LIST_TITLE,
  XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD,
  XVOCABULARY_CHANGE_QUIZLET_URL,
  XVOCABULARY_CLOSE_PRONUNCIATION_MODAL,
  XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING,
  XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED,
  XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION,
  XVOCABULARY_MOVE_ENTRY,
  XVOCABULARY_OPEN_PRONUNCIATION_MODAL,
  XVOCABULARY_REMOVE_ENTRY,
  XVOCABULARY_RESET_PRONUNCIATION,
  XVOCABULARY_SET_CATEGORY,
  XVOCABULARY_SET_ORIGINAL,
  XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD,
  XVOCABULARY_SET_TRANSLATION,
  XVOCABULARY_STRIP_SPACES
} from './actionTypes';
import {type XWidgetProperties} from '../interface';
import {type VocabularyWordProperties, type XVocabularyProperties} from './interface';
import {isOriginalNotEmpty, transformForRequestAndGetPOS} from './utils';
import {type XWidgetAction} from '../../actions/interface';

export interface XVocabularyWordAction extends XWidgetAction {
  wordId: string;
}

export interface XVocabularyChangeWordAction extends XVocabularyWordAction {
  value: string;
}

export const xVocabularySetOriginal = (
  xwidgetId: string,
  wordId: string,
  value: string
): XVocabularyChangeWordAction => ({
  type: XVOCABULARY_SET_ORIGINAL,
  xwidgetId,
  wordId,
  value
});

export interface XVocabularyChangeQuizletURLAction extends XWidgetAction {
  value: string;
}

export const xVocabularySetQuizletUrl = (
  xwidgetId: string,
  value: string
): XVocabularyChangeQuizletURLAction => ({
  type: XVOCABULARY_CHANGE_QUIZLET_URL,
  xwidgetId,
  value
});

export const xVocabularySetListTitle = (
  xwidgetId: string,
  value: string
): XVocabularyChangeQuizletURLAction => ({
  type: XVOCABULARY_CHANGE_LIST_TITLE,
  xwidgetId,
  value
});

export const xVocabularySetTranslation = (
  xwidgetId: string,
  wordId: string,
  value: string
): XVocabularyChangeWordAction => ({
  type: XVOCABULARY_SET_TRANSLATION,
  xwidgetId,
  wordId,
  value
});

export interface XVocabularyCategoryAction extends XWidgetAction {
  positionInVocabulary: number;
}

export interface XVocabularyAddRowsAction extends XWidgetAction {
  wordId: string;
  rows: VocabularyRow[];
}

export interface XVocabularyChangeCategoryAction extends XVocabularyCategoryAction {
  value: string;
}

export const xVocabularySetCategory = (
  xwidgetId: string,
  positionInVocabulary: number,
  value: string
): XVocabularyChangeCategoryAction => ({
  type: XVOCABULARY_SET_CATEGORY,
  xwidgetId,
  positionInVocabulary,
  value
});

export const xVocabularyAddWord = (xwidgetId: string): XWidgetAction => ({
  type: XVOCABULARY_ADD_WORD,
  xwidgetId
});

export const xVocabularyAddRows = (
  xwidgetId: string,
  wordId: string,
  rows: VocabularyRow[]
): XVocabularyAddRowsAction => ({
  type: XVOCABULARY_ADD_ROWS,
  xwidgetId,
  wordId,
  rows
});

export const xVocabularyAddCategory = (xwidgetId: string): XWidgetAction => ({
  type: XVOCABULARY_ADD_CATEGORY,
  xwidgetId
});

export const xVocabularyRemoveEntry = (
  xwidgetId: string,
  positionInVocabulary: number
): XVocabularyCategoryAction => ({
  type: XVOCABULARY_REMOVE_ENTRY,
  xwidgetId,
  positionInVocabulary
});

export interface XVocabularyMoveEntryAction extends XWidgetAction {
  sourcePos: number;
  targetPos: number;
}

export const xVocabularyMoveEntry = (
  xwidgetId: string,
  sourcePos: number,
  targetPos: number
): XVocabularyMoveEntryAction => ({
  type: XVOCABULARY_MOVE_ENTRY,
  xwidgetId,
  sourcePos,
  targetPos
});

export const xVocabularyOpenPronunciationModal = (
  xwidgetId: string,
  wordId: string
): XVocabularyWordAction => ({
  type: XVOCABULARY_OPEN_PRONUNCIATION_MODAL,
  xwidgetId,
  wordId
});

export const xVocabularyClosePronunciationModal = (xwidgetId: string): XWidgetAction => ({
  type: XVOCABULARY_CLOSE_PRONUNCIATION_MODAL,
  xwidgetId
});

export interface PronunciationsLoadedAction extends XWidgetAction {
  options: PronunciationOption[];
}

export const modalPronunciationsLoaded = (
  xwidgetId: string,
  options: PronunciationOption[]
): PronunciationsLoadedAction => ({
  type: XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED,
  xwidgetId,
  options
});

export interface SelectPronunciationOptionAction extends XWidgetAction {
  index: number;
}

export const selectPronunciationOption = (
  xwidgetId: string,
  index: number
): SelectPronunciationOptionAction => ({
  type: XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION,
  xwidgetId,
  index
});

export interface ChangePhoneticSpellingAction extends XWidgetAction {
  value: Value;
}

export const modalChangePhoneticSpelling = (
  xwidgetId: string,
  value: Value
): ChangePhoneticSpellingAction => ({
  type: XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING,
  xwidgetId,
  value
});

export interface ChangePronunciationAction extends XWidgetAction {
  wordIndex: number;
  soundId: number | null;
  phoneticSpelling?: string;
}

export const changePronunciationsForWord = (
  xwidgetId: string,
  wordIndex: number,
  soundId: number | null,
  phoneticSpelling?: string
): ChangePronunciationAction => ({
  type: XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD,
  xwidgetId,
  wordIndex,
  soundId,
  phoneticSpelling
});

export const resetPronunciation = (xwidgetId: string, wordId: string): XVocabularyWordAction => ({
  type: XVOCABULARY_RESET_PRONUNCIATION,
  xwidgetId,
  wordId
});

export const stripSpaces = (xwidgetId: string): XWidgetAction => ({
  type: XVOCABULARY_STRIP_SPACES,
  xwidgetId
});

export interface SetPronunciationsLoadingAction extends XVocabularyWordAction {
  isLoading: boolean;
}

const setPronunciationsLoadingForWord = (
  xwidgetId: string,
  wordId: string,
  isLoading: boolean
): SetPronunciationsLoadingAction => ({
  type: XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD,
  xwidgetId,
  wordId,
  isLoading
});

function getWordPosition(state: AppState, xwidgetId: string, wordId: string) {
  const xeditorAfterRequest = state.xeditor;
  if (xeditorAfterRequest) {
    const xwidgetAfterRequest = xeditorAfterRequest.xexercise.widgets.find(
      (x: XWidgetProperties) => x.id === xwidgetId
    ) as XVocabularyProperties | undefined;
    if (xwidgetAfterRequest) {
      return xwidgetAfterRequest.vocabulary.findIndex(
        entry => !!entry && isVocabularyWordJSON(entry) && entry.wordId === wordId
      );
    }
  }
  return -1;
}

async function loadPronunciationsForWord(
  word: VocabularyWordProperties,
  xwidget: XVocabularyProperties,
  getState: () => AppState,
  dispatch: Dispatch<Action, AppState>
) {
  dispatch(setPronunciationsLoadingForWord(xwidget.id, word.wordId, true));
  const indexInVocabulary = xwidget.vocabulary.findIndex(entry => entry === word);
  const {transformedWord, partOfSpeech} = transformForRequestAndGetPOS(
    indexInVocabulary,
    xwidget.vocabulary
  );
  let result: LoadPronunciationsResponseAction;
  try {
    result = await dispatch<LoadPronunciationsResponseAction>(
      loadPronunciationsRequest(transformedWord, partOfSpeech)
    );
  } catch {
    dispatch(setPronunciationsLoadingForWord(xwidget.id, word.wordId, false));
    return;
  }

  const wordPositionAfterRequest = getWordPosition(getState(), xwidget.id, word.wordId);
  if (wordPositionAfterRequest === -1) {
    // this means that editor, widget or word is no longer present
    return;
  }
  const wordAfterRequest = (
    getState().xeditor!.xexercise.widgets.find(
      (x: XWidgetProperties) => x.id === xwidget.id
    ) as XVocabularyProperties
  ).vocabulary.get(wordPositionAfterRequest) as VocabularyWordProperties;

  const soundFromRequest = result.payload.data.audio[0];
  const soundId = wordAfterRequest.soundId || (soundFromRequest && soundFromRequest.id) || null;
  const phoneticSpelling =
    wordAfterRequest.phoneticSpelling || result.payload.data.phoneticSpelling;
  dispatch(
    changePronunciationsForWord(xwidget.id, wordPositionAfterRequest, soundId, phoneticSpelling)
  );
  dispatch(setPronunciationsLoadingForWord(xwidget.id, word.wordId, false));
}

export const loadPronunciationsForAll =
  (xwidgetId: string): ThunkAction<void, AppState, void, Action> =>
  (dispatch, getState) => {
    dispatch(stripSpaces(xwidgetId));
    const xwidget = getState().xeditor!.xexercise.widgets.find(
      (x: XWidgetProperties) => x.id === xwidgetId
    ) as XVocabularyProperties;

    const wordsToLoadPronunciationsFor = xwidget.vocabulary.filter(
      entry =>
        !!entry &&
        isVocabularyWordJSON(entry) &&
        isOriginalNotEmpty(entry) &&
        (!entry.phoneticSpelling || !entry.soundId)
    );
    if (wordsToLoadPronunciationsFor.size === 0) {
      return;
    }
    wordsToLoadPronunciationsFor.forEach((word: VocabularyWordProperties) =>
      loadPronunciationsForWord(word, xwidget, getState, dispatch)
    );
  };
