import React, {useMemo} from 'react';
import {type IntlShape} from 'react-intl';

import {AspectRatio} from 'components/Crop/static';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import messages from '../i18n';

import './CropDropdown.scss';

const {freeAspect, originalAspect} = messages;

const trigger = ['click'];

const placement = 'top';

function getAspectRatioText(aspectRatio: keyof AspectRatio, intl: IntlShape) {
  switch (AspectRatio[aspectRatio]) {
    case AspectRatio.ORIGINAL:
      return intl.formatMessage(originalAspect);
    case AspectRatio.SQUARE:
      return '1:1';
    case AspectRatio.HORIZONTAL_RECT:
      return '4:3';
    case AspectRatio.VERTICAL_RECT:
      return '3:4';
    case AspectRatio.FREE:
      return intl.formatMessage(freeAspect);
    default:
      return undefined;
  }
}

export const CropDropdown: React.FC<{
  aspectRatio: AspectRatio;
  intl: IntlShape;
  lockedAspectRatios: AspectRatio[];
  setAspectRatio: (aspectRatio: AspectRatio) => void;
}> = ({aspectRatio, lockedAspectRatios, intl, setAspectRatio}) => {
  const items = useMemo(
    () =>
      [
        AspectRatio.ORIGINAL,
        AspectRatio.SQUARE,
        AspectRatio.HORIZONTAL_RECT,
        AspectRatio.VERTICAL_RECT,
        AspectRatio.FREE
      ]
        .filter(value => !lockedAspectRatios.includes(value))
        .reduce((res, value) => {
          const key = Object.keys(AspectRatio).find(key => AspectRatio[key] === value);

          return key ? {...res, [key]: value} : res;
        }, {}),
    [lockedAspectRatios]
  );

  const selectedText = getAspectRatioText(aspectRatio as unknown as keyof AspectRatio, intl);

  return (
    <DropDown
      activeKey={aspectRatio}
      items={items}
      value={
        <span>
          <span className="text">{selectedText}</span>
          <Icon name="angle-down" />
        </span>
      }
      trigger={trigger}
      placement={placement}
      onChange={setAspectRatio}
      noCaret={true}
    />
  );
};
