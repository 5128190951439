import {Editor, Range} from 'slate';
import {ReactEditor} from 'slate-react';

import {
  type PointerSelectionManager,
  type StopSelectionEventDetails,
  type Subscriber as BaseSubscriber
} from 'components/Pointer/selection/interface';
import {type SelectionData as BaseSelectionData} from 'store/interface';
import {scrollToElement} from 'components/Pointer/selection/helpers';
import {WidgetType, type WidgetTypeComponentProps} from 'store/exercise/player/interface';
import {
  type MatchingProperties,
  MatchingType
} from 'store/exercise/player/widgets/Matching/interface';

import {SelectionPointerEditor} from './SelectionPointerEditor';

type Subscriber = BaseSubscriber<Editor, Range>;
type SelectionData = BaseSelectionData<Range>;

export class PointerManager implements PointerSelectionManager<Editor, Range> {
  isManagerFor(editor: unknown): editor is Editor {
    return Editor.isEditor(editor);
  }

  public getRange(
    selection: Selection,
    subscriber: Subscriber,
    details: StopSelectionEventDetails
  ): Range | null {
    const {editor} = subscriber;

    let range: Range | null;

    try {
      range = SelectionPointerEditor.hasDomSelection(editor, selection)
        ? ReactEditor.toSlateRange(editor, selection, {
            exactMatch: true,
            suppressThrow: true
          })
        : null;
    } catch {
      range = null;
    }

    if (!range && editor.selection && details.isTripleClick) {
      return {...editor.selection};
    }

    return range && ReactEditor.hasRange(editor, range) ? range : null;
  }

  public startSelection(event: MouseEvent, editor: Editor, selection: Selection | null) {
    try {
      const shouldChange = !ReactEditor.hasDOMNode(editor, event.target as Node);
      SelectionPointerEditor.deleteCreatePointerDecoration(editor, shouldChange);
    } catch {
      return;
    }
  }

  public stopSelection(
    selection: Selection,
    subscriber: Subscriber,
    details: StopSelectionEventDetails
  ) {
    const {editor} = subscriber;

    const range = this.getRange(selection, subscriber, details);
    if (range && subscriber.isAvailable()) {
      SelectionPointerEditor.createPointerSelection(editor, range);

      const sub: Subscriber = {...subscriber, range};
      return sub;
    }

    return null;
  }

  public createForTeacher(subscriber: Subscriber) {
    if (subscriber) {
      const {editor, range} = subscriber;

      if (range) {
        SelectionPointerEditor.deleteCreatePointerDecoration(editor);
        SelectionPointerEditor.addPointerDecoration(editor, range);
      }
    }
  }

  public createForStudent(subscriber: Subscriber, data: SelectionData) {
    if (subscriber) {
      const {editor} = subscriber;
      const {elementId, range} = data;

      if (Range.isRange(range) && ReactEditor.hasRange(editor, range)) {
        if (elementId) {
          SelectionPointerEditor.addPointerDecoration(editor, range, {elementId});
          scrollToElement(elementId);
        }
      }
    }
  }

  public removeCreatePointer(editor: Editor): void {
    SelectionPointerEditor.deleteCreatePointerDecoration(editor);
  }

  public getWidgetProps(editor: Editor) {
    return editor.getWidgetProps?.();
  }

  public editorToString(editor: Editor) {
    const widgetProps = this.getWidgetProps(editor);

    if (!widgetProps) return '';

    switch (widgetProps.widget.type) {
      case WidgetType.ESSAY:
      case WidgetType.QUESTS:
      case WidgetType.QUESTIONS:
        return '';

      case WidgetType.COMMENT:
        return Editor.string(editor, []);

      case WidgetType.MATCHING:
        const {
          widget: {matchingType}
        } = widgetProps as WidgetTypeComponentProps<MatchingProperties>;

        if (matchingType === MatchingType.NO_CATEGORIES) return '';

        return Editor.string(editor, []);

      default:
        return JSON.stringify(editor.children);
    }
  }
}
