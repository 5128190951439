import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {PaintTool, useFocused} from '@englex/paint-react';

import {ToolButton} from '../../../button';
import {MobileFontSizeModal} from './MobileFontSizeModal';
import {useFontSize} from '../../../hooks';

import './MobileFontSizeButton.scss';

export function MobileFontSizeButton() {
  const isFocused = useFocused();
  const {fontSize, isActive, isSelectedText} = useFontSize();

  const [shoModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);

  const closeModal = useCallback(() => setShowModal(false), []);

  const handleClick = () => isActive && openModal();

  const shouldDisableSelectable = !isSelectedText;

  return (
    <>
      <div className="font-size-button" onClick={handleClick}>
        <ToolButton
          tool={PaintTool.Text}
          iconName="font"
          className={classNames({active: isActive, 'not-clickable': isSelectedText})}
          shouldDisableSelectable={shouldDisableSelectable}
        >
          {isActive && (
            <span className={classNames('value', {disabled: !isFocused})}>{fontSize}</span>
          )}
        </ToolButton>
      </div>
      <MobileFontSizeModal show={shoModal} active={fontSize} onHide={closeModal} />
    </>
  );
}
