import {useCallback} from 'react';
import {useIntl} from 'react-intl';

import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {deleteEntryFromDictionaryRequest} from 'store/dictionary/requests';
import * as toastr from 'components/toastr';
import {
  type DictionaryOwnerRole,
  useDictionaryContext
} from 'components/Dictionary/shared/contexts';

import {type DeleteWordData, type IncludedTranslations} from '../interfaces';

interface Props {
  includedTranslations: Record<string, string>;
  isSidebar: boolean;
  setIncludedTranslations: (translations: IncludedTranslations) => void;
}

export const useDeleteWordFromDictionary = ({
  includedTranslations,
  isSidebar,
  setIncludedTranslations
}: Props) => {
  const {formatMessage} = useIntl();

  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const deleteWordSuccessHandler = (data: DeleteWordData) => {
    const key = Object.keys(includedTranslations).find(key => {
      return includedTranslations[key] === data.id;
    });

    if (key) {
      const {[key]: _, ...newValue} = includedTranslations;
      setIncludedTranslations(newValue);
      toastr.success(
        '',
        formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionarySuccess'})
      );
    }
  };

  const deleteWordErrorHandler = () => {
    toastr.error('', formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionaryError'}));
  };

  const {apiRequest: deleteWordApiRequest} = useApiRequest2<[number, DictionaryOwnerRole, string]>(
    deleteEntryFromDictionaryRequest,
    deleteWordSuccessHandler,
    deleteWordErrorHandler
  );

  const deleteWord = useCallback(
    (translation: string) => {
      deleteWordApiRequest(
        dictionaryOwnerId,
        dictionaryOwnerRole,
        includedTranslations[translation]
      );
    },
    [deleteWordApiRequest, dictionaryOwnerId, dictionaryOwnerRole, includedTranslations]
  );

  return {deleteWord};
};
