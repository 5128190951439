enum CheckerType {
  Receiver = 'receiver',
  Sender = 'sender'
}

enum CheckerBytes {
  Received = 'bytesReceived',
  Sent = 'bytesSent',
  Written = 'bytesWritten'
}

interface Options {
  checkInterval?: number;
  checkTimeout?: number;
}

export class RTCConnectionChecker {
  private readonly checkInterval: number;

  private readonly checkTimeout: number;

  private bytes = 0;

  private timeout?: NodeJS.Timeout;

  private interval?: NodeJS.Timeout;

  private wasStartedTimerAgain: boolean = false;

  public constructor({checkInterval = 500, checkTimeout = 5000}: Options = {}) {
    this.checkInterval = checkInterval;
    this.checkTimeout = checkTimeout;
  }

  private async getBytes(connection: RTCPeerConnection, type: CheckerType, bytes: CheckerBytes) {
    const transceivers = connection.getTransceivers();
    const receivers = transceivers.filter(t => t[type].track).map(t => t[type]);

    let totalBytes = 0;

    for (const receiver of receivers) {
      const stats = await receiver.getStats();

      for (const stat of stats.values()) {
        totalBytes += stat[bytes] || 0;
      }
    }

    return totalBytes;
  }

  public reset() {
    this.bytes = 0;
    this.wasStartedTimerAgain = false;

    this.resetInterval();
    this.resetTimeout();
  }

  private resetInterval() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  private resetTimeout() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }
  }

  private startTimer(cb: Function) {
    this.resetTimeout();

    this.timeout = setTimeout(() => {
      this.reset();
      cb(true);
    }, this.checkTimeout);
  }

  public checkConnection(
    connection: RTCPeerConnection | null,
    type: CheckerType = CheckerType.Receiver,
    bytes: CheckerBytes = CheckerBytes.Received
  ): Promise<boolean> {
    this.reset();

    return new Promise(resolve => {
      if (!connection) return resolve(false);

      this.startTimer(resolve);

      this.interval = setInterval(async () => {
        const total = await this.getBytes(connection, type, bytes);

        if (this.bytes) {
          const diff = Math.abs(total - this.bytes);

          if (diff && this.wasStartedTimerAgain) {
            this.reset();
            return resolve(false);
          }

          if (diff && connection.iceConnectionState === 'disconnected') {
            this.wasStartedTimerAgain = true;
            this.startTimer(resolve);
          }
        }

        this.bytes = total;
      }, this.checkInterval);
    });
  }
}
