import {defineMessages} from 'react-intl';

const messages = defineMessages({
  replaceImage: {
    id: 'Slate.Toolbar.Button.ImageReplace'
  },
  cancelButton: {
    id: 'Slate.Modal.Image.CancelButton'
  },
  cropButton: {
    id: 'Slate.Modal.Image.CropButton'
  },
  changeCropButton: {
    id: 'Slate.Modal.Image.ChangeCrop'
  },
  undoCropButton: {
    id: 'Slate.Modal.Image.UndoCropButton'
  },
  applyButton: {
    id: 'Slate.Modal.Image.ApplyButton'
  },
  insertButton: {
    id: 'Slate.Modal.Image.InsertButton'
  },
  originalAspect: {
    id: 'Slate.Modal.Image.OriginalAspectRatio'
  },
  freeAspect: {
    id: 'Slate.Modal.Image.FreeAspectRatio'
  },
  aspectTitle: {
    id: 'Slate.Modal.Image.FixedAspectRatio'
  },
  originalSize: {
    id: 'Slate.Toolbar.ImageResize.OriginalSize'
  },
  half: {
    id: 'Slate.Toolbar.ImageResize.Half'
  },
  third: {
    id: 'Slate.Toolbar.ImageResize.Third'
  },
  quarter: {
    id: 'Slate.Toolbar.ImageResize.Quarter'
  }
});

export default messages;
