import {useCallback, useRef} from 'react';

import {useErrorModalContext} from '../contexts/errorModalContext';
import {useSearchingInODContext} from '../contexts/searchingInODContext';
import {ENTRY_MAX_LENGTH} from '../components/ErrorModal/ErrorModal';
import {type Callback} from '../components/TranslationItem/TranslationItem';

interface Props {
  translation: string;
  addWord: (translation: string, callback?: Callback) => void;
  deleteWord: (translation: string) => void;
}

export const useTranslationItemControls = ({translation, addWord, deleteWord}: Props) => {
  const {open, setCurrentOriginal, setCurrentTranslate} = useErrorModalContext();
  const {currentWord} = useSearchingInODContext();

  const ref = useRef<HTMLTableRowElement>(null);

  const onInclude = useCallback(
    (callback: Callback) => {
      if (currentWord.length <= ENTRY_MAX_LENGTH && translation.length <= ENTRY_MAX_LENGTH) {
        addWord(translation, callback);
      } else {
        setCurrentOriginal(currentWord);
        setCurrentTranslate(translation);
        open();
      }
    },
    [addWord, currentWord, open, setCurrentOriginal, setCurrentTranslate, translation]
  );

  const onExclude = useCallback(() => deleteWord(translation), [deleteWord, translation]);

  const getContainer = useCallback(() => ref.current || document.documentElement, []);

  return {
    ref,
    onInclude,
    onExclude,
    getContainer
  };
};
