import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import {type DispatchMessageModalProps} from '../containers/MessageModal';
import MessageModalContent from './MessageModalContent';

export interface MessageModalProps {
  isShowModal: boolean;
  sendingMessageAwait: boolean;
  subjects?: {[key: string]: string};
  networkError?: boolean;
  isMobile?: boolean;
}

interface Props extends MessageModalProps, DispatchMessageModalProps, WrappedComponentProps {}

export interface IntlProps extends Props {}

class MessageModal extends React.Component<IntlProps, {}> {
  public render(): JSX.Element {
    return (
      <Modal
        show={this.props.isShowModal}
        onHide={this.onModalHide}
        id="sendmessage-modal"
        className="sendmessage-modal"
      >
        <MessageModalContent {...this.props} />
      </Modal>
    );
  }

  private onModalHide = () => {
    this.props.toggleContactsModal(false);
  };
}

export default injectIntl(MessageModal);
