import {type Editor as ReactEditor, type RenderInlineProps} from '@englex/slate-react';
import classNames from 'classnames';
import React from 'react';
import {type Editor, type Next} from '@englex/slate';

import {SlateMark} from '../../../interface';
import {isIconInline} from '../../../utils';
import {getMarkClassNamesForNode} from '../../utils';
import {type RendererPlugin} from '../../interface';
import defaultIconSet from './iconset/default';
import './Icon.scss';

interface IconSet {
  [key: string]: string;
}

interface Options {
  iconset?: IconSet;
  override?: boolean;
}

export default class Icon implements RendererPlugin {
  protected markTypes = [SlateMark.FONT_SIZE, SlateMark.COLOR, SlateMark.HIGHLIGHT];

  private readonly icons: IconSet;

  constructor(options: Options = {}) {
    const {iconset = {}, override} = options;
    this.icons = override ? iconset : {...defaultIconSet, ...iconset};
  }

  public renderInline = (props: RenderInlineProps, editor: ReactEditor & Editor, next: Next) => {
    const {node, attributes, isFocused} = props;
    if (!isIconInline(node) || !Object.keys(this.icons).includes(node.data.get('icon'))) {
      return next();
    }
    const className = classNames(
      'slate-icon',
      {
        selected: isFocused && editor.value.selection?.isCollapsed
      },
      node.data.get('additionalClass'),
      getMarkClassNamesForNode(editor, node.getMarks(), this.markTypes)
    );
    const icon = node.data.get('icon');
    return (
      <span className={className} {...attributes}>
        {this.icons[icon]}
      </span>
    );
  };
}
