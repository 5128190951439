import {Value} from '@englex/slate';
import {type IntlShape, type MessageDescriptor} from 'react-intl';
import {type AnyObjectSchema} from 'yup';
import {Map} from 'immutable';
import {type Dispatch} from 'redux';

import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';

import {
  type MediaMeta,
  type WidgetJSON,
  WidgetTitle,
  type WidgetToJSONOptions,
  WidgetType
} from '../../player/interface';
import genKey from '../../../../components/Slate/utils/genKey';
import {valueFromText} from '../../../../components/Slate/utils';
import {type XWidgetProperties} from './interface';
import {documentNotEmpty} from './validation';
import {type DisplayButtonProperties, DisplayButtonRecord} from '../../player/DisplayButtonRecord';

const Record = recordBase()(BaseRecord);

abstract class XWidgetRecord<W extends WidgetJSON = WidgetJSON, O = unknown>
  extends Record
  implements XWidgetProperties
{
  public abstract get type(): WidgetType;

  public get title(): string {
    return WidgetTitle[this.type];
  }

  public abstract get excerpt(): string;

  public get customBodyClass(): string | undefined {
    return undefined;
  }

  public get taskPlaceholder(): MessageDescriptor | undefined {
    return undefined;
  }

  public declare readonly id: string;

  public declare readonly options: O;

  public declare readonly task: Value;

  public declare readonly displayButton?: DisplayButtonProperties;

  public get titleIconName(): string | undefined {
    return undefined;
  }

  public onBeforeValidation(dispatch: Dispatch) {
    return;
  }

  public get media(): MediaMeta | undefined {
    return undefined;
  }

  public get hasDisplayButton() {
    return (
      this.type === WidgetType.FORMATTED_TEXT ||
      this.type === WidgetType.SPEAKING ||
      this.type === WidgetType.NOTE ||
      this.type === WidgetType.VOCABULARY ||
      this.type === WidgetType.DIALOGUE ||
      this.type === WidgetType.WORD_SET ||
      this.type === WidgetType.PICTURE_SET ||
      this.type === WidgetType.WORD_PICTURE_SET ||
      this.type === WidgetType.AUDIO ||
      this.type === WidgetType.VIDEO
    );
  }

  public abstract schema(intl: IntlShape): AnyObjectSchema;

  constructor(raw: W) {
    super();
    this.initValues({
      id: raw.id,
      task: raw.task ? Value.fromJSON(raw.task) : valueFromText(),
      displayButton: raw.displayButton && DisplayButtonRecord.create(raw.displayButton)
    });
  }

  public toJSON(options?: WidgetToJSONOptions): W {
    return {
      id: this.id,
      type: this.type,
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      media: options && options.withMedia && this.media ? this.media.toJS() : undefined,
      displayButton: this.displayButton?.toJSON()
    } as W;
  }
}

decorate(XWidgetRecord, {
  id: property(genKey()),
  options: property(Map()),
  task: property(valueFromText()),
  displayButton: property()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any);
record()(XWidgetRecord);
export default XWidgetRecord;
