import {
  type BaseEditor,
  type BaseRange,
  type BaseOperation,
  type RangeRef,
  type BaseText
} from 'slate';
import {type Editable, type ReactEditor} from 'slate-react';
import {type HistoryEditor} from 'slate-history';
import {type ComponentProps, type CSSProperties} from 'react';

import {
  type ContainerEditor,
  type EditablePropsEditor,
  type FormatEditor,
  type IconsEditor,
  type LinkEditor,
  type SelectionPointerEditor,
  type SlateEditor,
  type WidgetPropsEditor
} from './plugins';
import {type ListStyleType, type ListType} from '../Slate/interface';

export type EditableProps = ComponentProps<typeof Editable>;

export type Mutable<T> = {
  -readonly [key in keyof T]: T[key];
};
// TODO: review naming and content of this module

type ParagraphElement = {
  type: 'paragraph';
  style?: CSSProperties;
  children: (CustomText | CustomInline)[];
};

type DefaultElement = {
  type?: undefined;
  children: (CustomText | CustomInline)[] | CustomBlock[];
};

export interface IconData {
  icon: string;
  additionalClass?: string;
}
export interface IconInline extends IconData {
  type: 'icon';
  children: CustomText[];
}

export interface SlateLinkData {
  href: string;
}

export interface LinkInline extends SlateLinkData {
  type: 'link';
  children: CustomText[];
}

interface SlateListItemData {}

export interface SlateListData extends Record<string, unknown> {
  list: ListType;
  listStyleType?: ListStyleType;
}

export interface ListItemElement extends SlateListItemData {
  type: 'listItem';
  children: CustomElement[];
}

export interface ListElement extends SlateListData {
  type: 'list';
  children: ListItemElement[];
}

export interface ExampleElement {
  type: 'example';
  children: CustomBlock[];
}

export type CustomBlock =
  | DefaultElement
  | ParagraphElement
  | ListElement
  | ListItemElement
  | ExampleElement;
type CustomInline = IconInline | LinkInline;
export type CustomElement = CustomBlock | CustomInline;

export interface FormatMarks {
  bold?: true;
  italic?: true;
  underline?: true;
  strikeThrough?: true;
  color?: string;
  highlight?: string;
  fontSize?: string;
}

type FormattedText = FormatMarks & BaseText;

interface CreateLinkProps extends BaseRange {
  createLink?: true;
}

export enum PointerDecorationType {
  CreatePointer = 'create-pointer',
  Pointer = 'pointer'
}

export interface PointerSelectionProps {
  id: string;
  type: PointerDecorationType;
  rangeRef: RangeRef;
}

export type PointerSelectionDecoration = PointerSelectionProps & BaseText;

export interface PointerDecoration extends PointerSelectionDecoration {
  type: PointerDecorationType.Pointer;
  elementId?: string;
}

export interface CreatePointerDecoration extends PointerSelectionDecoration {
  type: PointerDecorationType.CreatePointer;
}

export type CreateLinkDecoration = CreateLinkProps & BaseText;

type DecoratedText = CreateLinkDecoration | PointerDecoration | CreatePointerDecoration;

export type CustomText = FormattedText | DecoratedText;

export type CustomRange = BaseRange & (CreateLinkProps | PointerSelectionProps);

export type CustomEditor = BaseEditor &
  SlateEditor &
  ReactEditor &
  HistoryEditor &
  WidgetPropsEditor &
  EditablePropsEditor &
  ContainerEditor &
  FormatEditor &
  IconsEditor &
  LinkEditor &
  SelectionPointerEditor;

type ReactRerenderOperation = {type: 'react_request_rerender'};

export type CustomOperation = BaseOperation | ReactRerenderOperation;
