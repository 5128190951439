import React, {useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import {type DispatchMediaSettingsProps, type MediaSettingsStateProps} from './MediaSettingsWizard';

interface GreetingFooterProps extends MediaSettingsStateProps, DispatchMediaSettingsProps {}

const GreetingFooter: React.FC<GreetingFooterProps> = ({
  appVersion,
  autoShowModal,
  skipAutoShowModal,
  toggleModal,
  skipGreeting
}) => {
  const matches = useMediaQuery(480);

  const handleSetupLaterClick = useCallback(() => {
    if (autoShowModal) {
      skipAutoShowModal();
    }
    toggleModal(false);
  }, [autoShowModal, skipAutoShowModal, toggleModal]);

  const handleSetupClick = useCallback(() => {
    toggleModal(true);
    skipGreeting();
  }, [skipGreeting, toggleModal]);

  return (
    <Modal.Footer>
      <div className="modal-footer-text">
        {matches ? (
          appVersion
        ) : (
          <FormattedMessage id="Common.AppNameAndVersion" values={{version: appVersion}} />
        )}
      </div>
      <div className="buttons">
        <Button
          bsStyle="default"
          className="btn-transparent"
          bsSize="sm"
          onClick={handleSetupLaterClick}
        >
          <FormattedMessage id="MediaDevicesWizard.ButtonSetupLater" />
        </Button>
        <Button bsStyle="primary" bsSize="sm" onClick={handleSetupClick}>
          <FormattedMessage id="MediaDevicesWizard.ButtonSetupNow" />
        </Button>
      </div>
    </Modal.Footer>
  );
};

export default GreetingFooter;
