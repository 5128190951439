import {Value} from '@englex/slate';

import {valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';

import {type DialogueSpeakerJSON, type SpeakerProperties} from './interface';

const Record = recordBase()(BaseRecord);

class SpeakerRecord extends Record implements SpeakerProperties {
  public declare readonly id: string;

  public declare readonly name: Value;

  public declare readonly avatar: number;

  constructor(raw: Partial<DialogueSpeakerJSON>) {
    super();
    this.initValues({
      id: raw.id,
      name: raw.name ? Value.fromJSON(raw.name) : valueFromText(''),
      avatar: raw.avatar
    });
  }

  public toJSON(): DialogueSpeakerJSON {
    const {id, name, avatar} = this;
    return {id, name: name.toJSON(), avatar};
  }
}

decorate(SpeakerRecord, {
  id: property(),
  name: property(),
  avatar: property()
});
record()(SpeakerRecord);

export default SpeakerRecord;
