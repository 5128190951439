import React from 'react';
import {useIntl} from 'react-intl';
import {type ModalProps} from 'react-bootstrap/lib/Modal';
import {FontSizePaint, type MobilePaint, usePaint} from '@englex/paint-react';

import messages from '../../messages';
import {MobileModal} from '../modal';
import {FontSizeList} from './FontSizeList';

const preset = [4, 6, 8, 10, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72];

interface Props extends ModalProps {
  active?: number;
}

export const MobileFontSizeModal: React.FC<Props> = ({active, onHide, ...rest}) => {
  const paint = usePaint<MobilePaint>();

  const intl = useIntl();

  const title = intl.formatMessage(messages.selectFontSize);

  const selectFontSize = (size: number) => {
    FontSizePaint.setFontSize(paint, size);
    onHide();
  };

  return (
    <MobileModal title={title} onHide={onHide} {...rest}>
      <FontSizeList items={preset} active={active} onSelect={selectFontSize} />
    </MobileModal>
  );
};
