import {type Reducer} from 'redux';

import {type AxiosResponseAction} from 'services/axios/interface';
import ExerciseRecord from 'store/exercise/player/Exercise/ExerciseRecord';
import {type ExerciseJSON, type GrammarExerciseIds} from 'store/exercise/player/interface';
import PreviewRecord from 'store/exercise/player/preview/PreviewRecord';
import xpreviewReducer from 'store/exercise/player/preview/previewReducer';

import {type ModalAction, type SelectExerciseAction, type SwitchRoleAction} from './actions';
import {CHANGE_ROLE, SELECT_EXERCISE, CLEAR_STATE, REQUEST_AVAILABLE_SUCCESS} from './actionTypes';
import {type SupplementaryExercisesModalState} from './interface';

export const initialState: SupplementaryExercisesModalState = {
  exercises: [],
  role: 'teacher'
};

const handlers = {
  [CHANGE_ROLE]: (
    state: SupplementaryExercisesModalState,
    {role}: SwitchRoleAction
  ): SupplementaryExercisesModalState => ({...state, role}),
  [REQUEST_AVAILABLE_SUCCESS]: (
    state: SupplementaryExercisesModalState,
    action: AxiosResponseAction<{exercise: ExerciseJSON; grammar: GrammarExerciseIds}[]>
  ): SupplementaryExercisesModalState => {
    const exercises = action.payload.data.map(ue => ({...ue.exercise, grammar: ue.grammar}));
    const activeExercise = exercises[0];
    const xpreview = new PreviewRecord(ExerciseRecord.fromJSON(activeExercise));
    return {...state, exercises, activeExercise, xpreview};
  },
  [SELECT_EXERCISE]: (
    state: SupplementaryExercisesModalState,
    {id}: SelectExerciseAction
  ): SupplementaryExercisesModalState => {
    const activeExercise = state.exercises.find(e => e.id === id)!;
    const xpreview = new PreviewRecord(ExerciseRecord.fromJSON(activeExercise));
    return {...state, activeExercise, xpreview};
  },
  [CLEAR_STATE]: (): SupplementaryExercisesModalState => initialState
};

const reducer = (
  state: SupplementaryExercisesModalState | undefined = undefined,
  action: ModalAction
) => {
  if (state === undefined) {
    return undefined;
  }
  const reducer: Reducer<SupplementaryExercisesModalState> = handlers[action.type];
  const result: SupplementaryExercisesModalState = reducer ? reducer(state, action) : state;
  return {
    ...result,
    xpreview: xpreviewReducer(result.xpreview, action)
  };
};

export default reducer;
