import React, {type FC, memo, useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';

import {EditListModal} from './EditListModal';
import {EditEntryActionsContext, EditEntryStateContext} from '../contexts/entry/EditEntryContext';
import {EditListActionsContext, EditListStateContext} from '../contexts/list/EditListContext';
import {clearEdit, exitSelectionMode, setSelectionAction} from '../contexts/entry/actions';
import {closeModal} from '../contexts/actions';
import {type DictionaryOwnerRole} from '../contexts';

interface Props {
  dictionaryOwnerId: number;
  dictionaryOwnerRole: DictionaryOwnerRole;
}

export const EditListController: FC<Props> = memo(({dictionaryOwnerId, dictionaryOwnerRole}) => {
  const intl = useIntl();
  const {addToListObject, selectionMode} = useContext(EditEntryStateContext);
  const {dispatch: editEntryDispatch} = useContext(EditEntryActionsContext);

  const {show, editedList, listToDelete} = useContext(EditListStateContext);
  const editListDispatch = useContext(EditListActionsContext);

  const entryIds = selectionMode
    ? selectionMode.entryIds
    : addToListObject
      ? addToListObject.entryIds
      : undefined;

  const hide = useCallback(
    (success?: boolean) => {
      editEntryDispatch(clearEdit());
      editListDispatch(closeModal());
      if (selectionMode) {
        success ? editEntryDispatch(exitSelectionMode()) : editEntryDispatch(setSelectionAction());
      }
    },
    [editEntryDispatch, editListDispatch, selectionMode]
  );

  if (selectionMode) {
    return (
      <EditListModal
        dictionaryOwnerId={dictionaryOwnerId}
        dictionaryOwnerRole={dictionaryOwnerRole}
        addEntriesSuccessMessage={intl.formatMessage({
          id: 'Dictionary.Toastr.AddEntriesToNewListSuccess'
        })}
        addEntriesErrorMessage={intl.formatMessage({
          id: 'Dictionary.Toastr.AddEntriesToNewListError'
        })}
        entryIds={entryIds}
        hide={hide}
        show={selectionMode.action === 'add' && !selectionMode.existingList}
      />
    );
  }

  const addEntriesSuccessMessage =
    entryIds && entryIds.length > 1
      ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntriesToNewListSuccess'})
      : intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToNewListSuccess'});

  const addEntriesErrorMessage = intl.formatMessage({
    id: 'Dictionary.Toastr.AddEntryToNewListError'
  });

  return (
    <EditListModal
      show={show}
      dictionaryOwnerId={dictionaryOwnerId}
      dictionaryOwnerRole={dictionaryOwnerRole}
      addEntriesSuccessMessage={addEntriesSuccessMessage}
      addEntriesErrorMessage={addEntriesErrorMessage}
      editedList={editedList}
      entryIds={entryIds}
      listToDelete={listToDelete}
      hide={hide}
      submit={addToListObject?.submit}
    />
  );
});
