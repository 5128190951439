import {matchingFactory} from 'components/XPlayer/widgets/Matching/matchingFactory';

import {WidgetType, type WidgetProperties} from './interface';
import FormattedTextRecord from './widgets/FormattedText/FormattedTextRecord';
import GapFillRecord from './widgets/GapFill/GapFillRecord';
import NoteRecord from './widgets/Note/NoteRecord';
import CommentRecord from './widgets/Comment/CommentRecord';
import EssayRecord from './widgets/Essay/EssayRecord';
import QuestsRecord from './widgets/Writing/Quests/QuestsRecord';
import ImageMatchingRecord from './widgets/ImageMatching/ImageMatchingRecord';
import ImageLabelingRecord from './widgets/ImageLabeling/ImageLabelingRecord';
import MultipleChoiceRecord from './widgets/List/MultipleChoice/MultipleChoiceRecord';
import FreeChoiceRecord from './widgets/List/FreeChoice/FreeChoiceRecord';
import OddWordRecord from './widgets/List/OddWord/OddWordRecord';
import AudioRecord from './widgets/Audio/AudioRecord';
import ScrambledRecord from './widgets/ScrambledSentences/ScrambledRecord';
import VocabularyRecord from './widgets/Vocabulary/VocabularyRecord';
import VideoRecord from './widgets/Video/VideoRecord';
import GrammarRulesRecord from './widgets/GrammarRules/GrammarRulesRecord';
import SpeakingRecord from './widgets/Speaking/SpeakingRecord';
import QuestionsRecord from './widgets/Writing/Questions/QuestionsRecord';
import CardsRecord from './widgets/Cards/CardsRecord';
import QuizRecord from './widgets/Quiz/QuizRecord';
import PictureChoiceRecord from './widgets/PictureChoice/PictureChoiceRecord';
import DialogueRecord from './widgets/Dialogue/DialogueRecord';
import UnknownRecord from './widgets/Unknown/UnknownRecord';
import WordSetRecord from './widgets/WordPictureSet/WordSet/WordSetRecord';
import PictureSetRecord from './widgets/WordPictureSet/PictureSet/PictureSetRecord';
import WordPictureSetRecord from './widgets/WordPictureSet/WordPictureSet/WordPictureSetRecord';
import FlipCardsRecord from './widgets/FlipCards/FlipCardsRecord';
import HorizontalStyledListRecord from './widgets/List/StyledList/HorizontalStyledListRecord';
import VerticalStyledListRecord from './widgets/List/StyledList/VerticalStyledListRecord';
import SpellingRecord from './widgets/Selling/SpellingRecord';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function widgetFactory(raw: any): WidgetProperties {
  switch (raw.type) {
    case WidgetType.FORMATTED_TEXT: {
      return new FormattedTextRecord(raw);
    }
    case WidgetType.ESSAY: {
      return new EssayRecord(raw);
    }
    case WidgetType.GAP_FILL: {
      return new GapFillRecord(raw);
    }
    case WidgetType.VOCABULARY: {
      return new VocabularyRecord(raw);
    }
    case WidgetType.NOTE: {
      return new NoteRecord(raw);
    }
    case WidgetType.COMMENT: {
      return new CommentRecord(raw);
    }
    case WidgetType.QUESTS: {
      return new QuestsRecord(raw);
    }
    case WidgetType.QUESTIONS: {
      return new QuestionsRecord(raw);
    }
    case WidgetType.IMAGE_MATCHING: {
      return new ImageMatchingRecord(raw);
    }
    case WidgetType.IMAGE_LABELING: {
      return new ImageLabelingRecord(raw);
    }
    case WidgetType.MULTIPLE_CHOICE: {
      return new MultipleChoiceRecord(raw);
    }
    case WidgetType.FREE_CHOICE: {
      return new FreeChoiceRecord(raw);
    }
    case WidgetType.ODD_WORD: {
      return new OddWordRecord(raw);
    }
    case WidgetType.MATCHING: {
      return matchingFactory(raw);
    }
    case WidgetType.SCRAMBLED_SENTENCES: {
      return new ScrambledRecord(raw);
    }
    case WidgetType.AUDIO: {
      return new AudioRecord(raw);
    }
    case WidgetType.VIDEO: {
      return new VideoRecord(raw);
    }
    case WidgetType.GRAMMAR_RULES: {
      return new GrammarRulesRecord(raw);
    }
    case WidgetType.SPEAKING: {
      return new SpeakingRecord(raw);
    }
    case WidgetType.CARDS: {
      return new CardsRecord(raw);
    }
    case WidgetType.Quiz: {
      return new QuizRecord(raw);
    }
    case WidgetType.PICTURE_CHOICE: {
      return new PictureChoiceRecord(raw);
    }
    case WidgetType.DIALOGUE: {
      return new DialogueRecord(raw);
    }
    case WidgetType.WORD_SET: {
      return new WordSetRecord(raw);
    }
    case WidgetType.PICTURE_SET: {
      return new PictureSetRecord(raw);
    }
    case WidgetType.WORD_PICTURE_SET: {
      return new WordPictureSetRecord(raw);
    }
    case WidgetType.FLIP_CARDS: {
      return new FlipCardsRecord(raw);
    }
    case WidgetType.HORIZONTAL_STYLED_LIST: {
      return new HorizontalStyledListRecord(raw);
    }
    case WidgetType.VERTICAL_STYLED_LIST: {
      return new VerticalStyledListRecord(raw);
    }
    case WidgetType.SPELLING: {
      return new SpellingRecord(raw);
    }
    default:
      return new UnknownRecord(raw);
  }
}
