import {Map} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {type VideoData, type VideoOptions} from 'components/media/interface';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetType} from '../../interface';
import {type VideoProperties, type VideoWidgetJSON} from './interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';

class VideoRecord
  extends FormattedTextRecord<undefined, Map<'showCollapsible', boolean> | undefined>
  implements VideoProperties
{
  public declare readonly video: VideoData;
  public declare readonly posterId?: number;
  public declare readonly videoOptions?: VideoOptions;

  constructor(raw: VideoWidgetJSON) {
    super(raw);
    this.initValues({
      video: raw.video,
      posterId: raw.posterId,
      videoOptions: raw.videoOptions,
      values: raw.values ? Map(raw.values) : undefined
    });
  }

  public toJSON(): VideoWidgetJSON {
    return {
      ...super.toJSON(),
      video: {id: this.video.id, source: this.video.source},
      videoOptions: this.videoOptions,
      posterId: this.posterId
    };
  }

  public get type() {
    return WidgetType.VIDEO;
  }

  public createValues() {
    return this.set('values', Map({showCollapsible: false}));
  }

  public setValuesFromJSON(values: {showCollapsible?: boolean}) {
    return values
      ? this.withMutations(v => {
          if (!v.values) v = v.set('values', Map());
          if (values.showCollapsible !== undefined)
            v.setIn(['values', 'showCollapsible'], values.showCollapsible);
          else v.delete('values');
        })
      : this;
  }
}

decorate(VideoRecord, {
  video: property(),
  posterId: property(),
  videoOptions: property()
});
record()(VideoRecord);
export default VideoRecord;
