import {stripWordCurlyBrackets} from '@englex/trainer';

import {record} from 'immutable-record/decorator/record';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';

import {type VocabularyWordProperties} from '../../../editor/widgets/XVocabulary/interface';
import {type VocabularyWordJSON} from './interface';

const Record = recordBase()(BaseRecord);

class VocabularyWordRecord extends Record implements VocabularyWordProperties {
  public declare readonly wordId: string;

  public declare readonly dictionaryEntryInstance:
    | {dictionaryEntryInstanceId: string; otherExercise?: true}
    | undefined;

  public declare readonly original: string;

  public declare readonly translation: string;

  public declare readonly phoneticSpelling: string | undefined;

  public declare readonly soundId: number | undefined;

  public declare readonly loadingPronunciations?: true;

  constructor(raw: VocabularyWordJSON) {
    super();
    this.initValues({
      wordId: raw.wordId,
      original: raw.original,
      translation: raw.translation,
      phoneticSpelling: raw.phoneticSpelling,
      soundId: raw.soundId
    });
  }

  public toJSON(): VocabularyWordJSON {
    return {
      wordId: this.wordId,
      original: this.original,
      translation: this.translation,
      phoneticSpelling: this.phoneticSpelling,
      soundId: this.soundId
    } as VocabularyWordJSON;
  }

  public stripSpaces() {
    return this.set('original', this.original.trim()).set('translation', this.translation.trim());
  }

  public get splitByBraces() {
    const [, textBeforeBraces = this.original, textInBraces = ''] =
      this.original.match(/(.+?)(\s*\[.+\])$/) || [];

    return {textBeforeBraces: stripWordCurlyBrackets(textBeforeBraces), textInBraces};
  }
}

decorate(VocabularyWordRecord, {
  wordId: property(''),
  dictionaryEntryInstance: property(),
  original: property(''),
  translation: property(''),
  phoneticSpelling: property(undefined),
  soundId: property(undefined),
  loadingPronunciations: property(undefined)
});
record()(VocabularyWordRecord);
export default VocabularyWordRecord;
