import {AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

const prefix = 'ADDITIONAL_EXERCISES/';

export const CHANGE_ROLE = prefix + 'SWITCH_ROLE';
export const REQUEST_AVAILABLE = prefix + 'REQUEST_AVAILABLE';
export const REQUEST_AVAILABLE_SUCCESS = REQUEST_AVAILABLE + AXIOS_SUCCESS_POSTFIX;
export const ATTACH_ADDITIONAL = prefix + 'ATTACH_ADDITIONAL';
export const SELECT_EXERCISE = prefix + 'SELECT_EXERCISE';
export const CLEAR_STATE = prefix + 'CLEAR_STATE';
