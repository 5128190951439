import {genKey} from 'components/Slate/utils';

const replaceIds = (obj: Object, ids: {[key: string]: string}) => {
  let objJSON = JSON.stringify(obj);

  Object.keys(ids).forEach(oldId => {
    const newId = ids[oldId];

    objJSON = objJSON.replaceAll(oldId, newId);
  });

  return JSON.parse(objJSON);
};

export const getObjectWithNewIds = <T extends Object>(
  object: T,
  idProperty = 'id',
  excludedProperty?: string
): T => {
  const oldNewIds = {};

  const iterateObj = (obj: Object) => {
    Object.keys(obj).forEach(key => {
      if (key === idProperty) {
        const newId = genKey();
        oldNewIds[obj[key]] = newId;
      } else if (typeof obj[key] === 'object' && key !== excludedProperty) {
        iterateObj(obj[key]);
      }
    });
  };

  iterateObj(object);

  return replaceIds(object, oldNewIds);
};
