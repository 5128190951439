import React from 'react';
import {FormattedMessage} from 'react-intl';

import Icon from 'components/Icon';
import InViewer from 'contexts/Viewer/adapters/InViewer';
import {Type} from 'contexts/Viewer/interface';

interface YoutubeVideoPreviewOwnProps {
  videoId: string;
}

interface Props extends YoutubeVideoPreviewOwnProps {}

export default class YoutubeVideoPreview extends React.Component<Props, {}> {
  public render() {
    const {videoId} = this.props;
    return (
      <InViewer id={videoId} type={Type.YT}>
        {activate => (
          <div
            className="youtube-video-preview"
            style={{
              backgroundImage: `url(https://img.youtube.com/vi/${videoId}/hqdefault.jpg)`
            }}
            onClick={activate}
          >
            <div className="mask">
              <div className="mask-content">
                <Icon name="play" size="lg" />
                <FormattedMessage id="Common.Play" />
              </div>
            </div>
          </div>
        )}
      </InViewer>
    );
  }
}
