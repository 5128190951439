import React from 'react';
import {Editor as SlateReactEditor, type Plugin} from '@englex/slate-react';
import {type Value, type ValueJSON} from '@englex/slate';
import classNames from 'classnames';
import withDevTools from '@englex/slate-dev-tools/lib/withDevTools';

import {type WidgetTypeComponentProps} from 'store/exercise/player/interface';

import {type SlateReactChange} from '../interface';
import Wrapper from '../plugins/Wrapper';
import {getPluginsRecursive} from '../utils';
import GetWidgetProps from '../plugins/GetWidgetProps';
import hyperscript from '../hyperscript';
import {slateDevToolsEnabled} from '../utils/slateDevTools';
import {TextNormalizer} from '../plugins/TextNormalizer';

import './SlatePlayer.scss';

interface ToolbarOptions {
  hide?: boolean;
}

interface Props {
  value: Value;
  valueJSON?: ValueJSON;
  placeholder?: string;
  className?: string;
  toolbar?: ToolbarOptions;
  plugins?: Plugin[];
  getWidgetProps?: () => WidgetTypeComponentProps;
  trimEmptyTrailingParagraphs?: boolean;
}

interface State {
  value: Value | null;
}

class SlatePlayer extends React.PureComponent<Props, State> {
  public readonly plugins: Plugin[] = [];

  constructor(props: Props) {
    super(props);

    const {className, plugins = [], getWidgetProps, trimEmptyTrailingParagraphs} = props;

    getPluginsRecursive(this.plugins, [
      new TextNormalizer(),
      new Wrapper({
        className: classNames('slate-player', {
          [`${className}`]: !!className,
          'trim-empty-trailing-p': trimEmptyTrailingParagraphs
        })
      }),
      ...plugins
    ]);

    if (getWidgetProps) {
      this.plugins.push(new GetWidgetProps({getWidgetProps}));
    }

    this.state = {
      value: null
    };
  }

  public get value(): Value {
    return this.state.value || this.props.value;
  }

  public render() {
    return (
      <Editor
        className={'x-slate'}
        placeholder={this.props.placeholder}
        value={this.value}
        onChange={this.onChange}
        plugins={this.plugins}
        readOnly={true}
      />
    );
  }

  private onChange = (change: SlateReactChange) => {
    this.setState({value: change.value});
  };
}

const Editor = withDevTools({
  hyperprintOptions: {
    hyperscript
  },
  enabled: slateDevToolsEnabled()
})(SlateReactEditor);

export default SlatePlayer;
