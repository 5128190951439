import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {type ChangeVolumeAction, type RegisteredPlayer, type VideoState} from '../interface';
import {
  type ChangePlaybackRateAction,
  type RegisterVideoPlayerAction,
  type SetVideoDurationAction,
  type VideoAction
} from './actions';
import {
  ACTIVATE_PLAYER,
  CHANGE_PLAYBACK_RATE,
  CHANGE_VOLUME,
  DEACTIVATE_PLAYER,
  REGISTER_PLAYER,
  SET_DURATION,
  UNREGISTER_PLAYER
} from './actionTypes';

const filterOnePlayer = (players: RegisteredPlayer[], playerId: string): RegisteredPlayer[] => {
  let filtered = false;
  return players.filter(({id}) => {
    if (id === playerId && !filtered) {
      filtered = true;
      return false;
    }
    return true;
  });
};

const REDUCERS: ReducersMapObject = {
  [REGISTER_PLAYER]: (
    state: VideoState,
    {playerId, sourceId, url, posterUrls}: RegisterVideoPlayerAction
  ): VideoState => ({
    ...state,
    players: [...state.players, {id: playerId, sourceId, url, posterUrls}]
  }),
  [UNREGISTER_PLAYER]: (state: VideoState, {playerId}: VideoAction): VideoState => ({
    ...state,
    players: filterOnePlayer(state.players, playerId)
  }),
  [ACTIVATE_PLAYER]: (state: VideoState, {playerId}: VideoAction): VideoState =>
    playerId !== state.activePlayerId ? {...state, activePlayerId: playerId} : state,
  [DEACTIVATE_PLAYER]: (state: VideoState, {playerId}: VideoAction): VideoState =>
    playerId === state.activePlayerId ? {...state, activePlayerId: undefined} : state,
  [CHANGE_VOLUME]: (state: VideoState, {volume}: ChangeVolumeAction): VideoState => ({
    ...state,
    volume
  }),
  [CHANGE_PLAYBACK_RATE]: (
    state: VideoState,
    {playbackRate}: ChangePlaybackRateAction
  ): VideoState => ({
    ...state,
    playbackRate
  }),
  [SET_DURATION]: (state: VideoState, {playerId, length}: SetVideoDurationAction): VideoState => ({
    ...state,
    players: state.players.map(player =>
      player.id === playerId ? {...player, duration: length} : player
    )
  })
};

const initialState: VideoState = {players: [], volume: 1};

function videoReducer(state: VideoState = initialState, action: Action): VideoState {
  const reducer: Reducer<VideoState> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default videoReducer;
