import {type Action, type Dispatch} from 'redux';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import {type WampCallResponseAction, type WampErrorAction} from 'services/wamp/actions/interface';
import {
  CONNECTION_CLOSED,
  CONNECTION_OPENED,
  wampClientActionType
} from 'services/wamp/actions/actionTypes';

import {
  callEndedWhileReconnectingMessage,
  callIsNotConnectedServerError,
  RTCClient,
  rtcErrors,
  unknownRTCErrorMessage
} from './rtcclient';
import {
  CALL_ACCEPT,
  CALL_ACCEPTED_EVENT,
  CALL_ANSWERED,
  CALL_ANSWERED_EVENT,
  CALL_CHANGE_MEDIA_DEVICE,
  CALL_CREATE,
  CALL_END,
  CALL_MUTE,
  CALL_OFFERED_EVENT,
  CALL_SIGNALING_STABLE_EVENT,
  CALL_UPGRADED_EVENT,
  GET_LOCAL_STREAM,
  GET_REMOTE_STREAM,
  ICE_CANDIDATE_EVENT,
  INCOMING_CALL,
  OTHER_SESSION_CALL,
  OUTGOING_CALL_INTERRUPTED,
  PARTNER_DISCONNECTED,
  TOGGLE_RECALL,
  WAMP_CALL_HANG_UP,
  WAMP_MUTE_CALL_SUCCESS,
  WAMP_SEND_ICE_CANDIDATE_FAIL,
  WAMP_UPGRADE_CALL_FAIL
} from './action/actionTypes';
import {
  type AddIceCandidateAction,
  type CallAcceptedAction,
  type CallAnswerAction,
  type CallAnsweredAction,
  type CallStartedAction,
  type CallUpgradedAction,
  type ConnectionComponent,
  type IncomingCallAction,
  type MuteCallAction,
  type RTCAction,
  type SdpAction
} from './action/interface';
import {
  callEnd,
  changeCallMediaDevice,
  changeCallStatus,
  toggleRecall,
  toggleRemoteVideoDevice,
  toggleVideoUndocked,
  upgradeCall,
  wampAnswered,
  wampCallAccept,
  wampCreateCall,
  wampMuteCall
} from './action/action';
import {type RTCErrorAction} from './types';
import {type ToggleElementAction} from '../common/interface';
import uniqueId from '../helpers/uniqueId';
import {SAVE_MODAL_SELECTION} from '../routes/ClassRoom/components/MediaSettingsWizard/actionTypes';
import {RTCMessages} from './components/RTCmessages';
import {
  LOADING,
  PAUSE,
  PLAYING
} from '../routes/ClassRoom/components/FilesWorkSpace/soundsTab/actions/interface';
import {changePlayStatus} from '../routes/ClassRoom/components/FilesWorkSpace/soundsTab/actions/action';
import {type ServerSideCallInfo} from './interface';

export interface MiddlewareAPI {
  getState(): AppState;

  dispatch(action: Action): Promise<Action>;
}

export type RTCMiddleware = (
  client: RTCClient
) => (
  api: MiddlewareAPI
) => (next: Dispatch<Action>) => (action: Action) => Promise<unknown> | Action | void;

function checkIfCallEnded(client: RTCClient, connectionId: string) {
  if (!client.connection || client.connectionId !== connectionId) {
    throw RTCClient.createError(rtcErrors.ReconnectAborted, callEndedWhileReconnectingMessage);
  }
}

function handleCallOfferedEvent(action: SdpAction, client: RTCClient, api: MiddlewareAPI) {
  return new Promise(async (resolve, reject) => {
    try {
      client.callFailedCallback = reject;
      client.callSuccessCallback = resolve;
      const offer = action.sdp;
      const sdp = new RTCSessionDescription({
        type: 'offer',
        sdp: offer
      });
      await client.setRemoteDescription(sdp, client.connectionId!);
      await client.applyLocalStream(client.connectionId!);

      client.setCodecPreferences();

      const answerSdp = await client.createAnswer();
      await client.setLocalDescription(answerSdp, client.connectionId!);

      const rct = api.getState().rtc;
      await api.dispatch(wampAnswered(rct.call?.room_id!, rct.call!.id!, answerSdp));
    } catch (e) {
      client.handleError(e, client.connectionId!);
    }
  });
}

function handleCallAnsweredEvent(action: SdpAction, client: RTCClient, api: MiddlewareAPI) {
  return new Promise(async (resolve, reject) => {
    try {
      client.callFailedCallback = reject;
      client.callSuccessCallback = resolve;
      const answer = action.sdp;
      const sdp = new RTCSessionDescription({
        type: 'answer',
        sdp: answer
      });
      await client.setRemoteDescription(sdp, client.connectionId!);
    } catch (e) {
      client.handleError(e, client.connectionId!);
    }
  });
}
function handleCallCreate(action: CallStartedAction, client: RTCClient, api: MiddlewareAPI) {
  return new Promise(async (resolve, reject) => {
    const connectionId = uniqueId();
    try {
      client.callFailedCallback = reject;
      await client.openConnection(connectionId);
      const stream = await client.getUMAndCheckConnection(connectionId, action.camId, action.micId);
      client.callSuccessCallback = resolve;
      await client.applyStream(stream, connectionId);
      const camLabel = action.camId ? stream.getVideoTracks()[0].label : null;
      const micLabel = stream.getAudioTracks()[0].label;
      await api.dispatch(wampCreateCall(action.roomId, camLabel, micLabel));
    } catch (e) {
      client.handleError(e, connectionId);
    }
  });
}

function handleCallAccept(action: CallAnswerAction, client: RTCClient, api: MiddlewareAPI) {
  return new Promise(async (resolve, reject) => {
    const connectionId = uniqueId();
    try {
      client.callFailedCallback = reject;
      await client.openConnection(connectionId);
      const stream = await client.getUMAndCheckConnection(connectionId, action.camId, action.micId);
      client.callSuccessCallback = resolve;
      client.setLocalStream(stream);
      const camLabel = action.camId ? stream.getVideoTracks()[0].label : null;
      const micLabel = stream.getAudioTracks()[0].label;
      await api.dispatch(wampCallAccept(action.roomId, action.callId, camLabel, micLabel));
      client.setCallDurationInterval();
      client.setSendStatInterval();
    } catch (e) {
      client.handleError(e, connectionId);
    }
  });
}

function handleIncomingCall(action: IncomingCallAction, client: RTCClient, api: MiddlewareAPI) {
  const {sounds: soundsState, layout, rtc, floatSidebar} = api.getState();
  const description: RTCSessionDescriptionInit = {
    type: 'offer',
    sdp: action.call.offer
  };
  client.remoteDescription = description;

  if (rtc.badBrowser) {
    return;
  }
  if (layout.collapsed || floatSidebar?.collapsed) {
    api.dispatch(toggleVideoUndocked(true));
  }
  if (soundsState && (soundsState.playStatus === PLAYING || soundsState.playStatus === LOADING)) {
    api.dispatch(changePlayStatus(PAUSE));
  }
}

async function handleCallAnswered(
  action: CallAnsweredAction,
  client: RTCClient,
  api: MiddlewareAPI
) {
  const rtcState = api.getState().rtc;
  const connectionId = client.connectionId as string;
  if (action.answerSDP && !rtcState.otherSessionCall && rtcState.call && client.localDescription) {
    try {
      client.setCallDurationInterval();
      client.setSendStatInterval();
    } catch (e) {
      client.handleError(e, connectionId);
    }
  }
}

async function handleCallAcceptedEvent(
  action: CallAcceptedAction,
  client: RTCClient,
  api: MiddlewareAPI
) {
  const rtcState = api.getState().rtc;
  const connectionId = client.connectionId;
  if (!rtcState.otherSessionCall && rtcState.call && rtcState.outgoingCall) {
    try {
      await client.negotiation();
    } catch (e) {
      client.handleError(e, connectionId!);
    }
  }
}

function handleAddIceCandidateEvent(
  action: AddIceCandidateAction,
  client: RTCClient,
  api: MiddlewareAPI
) {
  const rtcState = api.getState().rtc;
  if (!rtcState.otherSessionCall && rtcState.call) {
    client.addIceCandidate(action.candidate);
  }
}

async function handleGetLocalStream(client: RTCClient) {
  try {
    const track = client.getLocalTrack('video');

    if (track) {
      return new MediaStream([track]);
    }

    return new MediaStream([]);
  } catch (e) {
    client.handleError(e, client.connectionId!);
    throw e;
  }
}

async function handleAudioMute(
  action: MuteCallAction,
  client: RTCClient,
  api: MiddlewareAPI,
  connectionId: string
) {
  try {
    const rtc = api.getState().rtc;
    checkIfCallEnded(client, connectionId);
    const cam = rtc.localStream!.video ? client.getLocalTrack('video').label : null;
    const mic = action.micId ? client.getLocalTrack('audio').label : null;
    client.toggleAudioTrack(Boolean(action.micId));
    await api.dispatch(wampMuteCall(rtc.call!.room_id, rtc.call!.id, cam, mic, false));
  } catch (e) {
    client.handleError(e, connectionId);
  }
}

function sendVideoMuteRequest(
  cam: string | null,
  api: MiddlewareAPI,
  client: RTCClient,
  connectionId: string,
  reconnect = true
) {
  const rtc = api.getState().rtc;
  checkIfCallEnded(client, connectionId);
  const localAudioTrackLabel = client.getLocalTrack('audio').label;
  const mic = rtc.localStream!.audio ? localAudioTrackLabel : null;
  return api.dispatch(wampMuteCall(rtc.call!.room_id, rtc.call!.id, cam, mic, reconnect));
}

function checkIfMuteFailed(responseAction: WampErrorAction<{}, {}, Action>) {
  if (responseAction.type !== WAMP_MUTE_CALL_SUCCESS) {
    const error = responseAction.error;
    throw RTCClient.createError(
      rtcErrors.UncriticalError,
      (error && error.args[0]) || unknownRTCErrorMessage
    );
  }
}

async function turnOnVideo(
  client: RTCClient,
  api: MiddlewareAPI,
  camId: string,
  connectionId: string
) {
  const newStream = await client.getUMAndCheckConnection(connectionId, camId, null);
  try {
    checkIfCallEnded(client, connectionId);
    const result = await sendVideoMuteRequest(
      newStream.getVideoTracks()[0].label,
      api,
      client,
      connectionId
    );

    checkIfMuteFailed(result as WampErrorAction<{}, {}, Action>);
    checkIfCallEnded(client, connectionId);
    const [videoTrack] = newStream.getVideoTracks();
    await client.replaceLocalTrack(videoTrack);
  } catch (e) {
    newStream.getTracks().forEach(track => track.stop());
    throw e;
  }
}

async function turnOffVideo(client: RTCClient, api: MiddlewareAPI, connectionId: string) {
  client.stopLocalVideoTrack();

  checkIfCallEnded(client, connectionId);

  const result = await sendVideoMuteRequest(null, api, client, connectionId);

  checkIfMuteFailed(result as WampErrorAction<string, {}, Action>);
  checkIfCallEnded(client, connectionId);
  await client.replaceLocalTrack(null);
}

async function changeVideoDevice(
  client: RTCClient,
  api: MiddlewareAPI,
  camId: string,
  connectionId: string
) {
  const newStream = await client.getUMAndCheckConnection(connectionId, camId, null);
  try {
    checkIfCallEnded(client, connectionId);
    const result = await sendVideoMuteRequest(
      newStream.getVideoTracks()[0].label,
      api,
      client,
      connectionId,
      false
    );
    checkIfMuteFailed(result as WampErrorAction<{}, {}, Action>);
    checkIfCallEnded(client, connectionId);
    client.stopLocalVideoTrack();
    const [videoTrack] = newStream.getVideoTracks();
    await client.replaceLocalTrack(videoTrack, false);
    await api.dispatch(changeCallStatus('connected'));
  } catch (e) {
    newStream.getTracks().forEach(track => track.stop());
    throw e;
  }
}

function sendChangeDeviceMuteRequest(
  api: MiddlewareAPI,
  stream: MediaStream,
  client: RTCClient,
  connectionId: string,
  shouldChangeCam?: boolean
) {
  function getCam() {
    if (!api.getState().rtc.localStream!.video) {
      return null;
    }
    if (shouldChangeCam) {
      return stream.getVideoTracks()[0].label;
    }
    const localVideoTrackLabel = client.getLocalTrack('video').label;
    checkIfCallEnded(client, connectionId);
    return localVideoTrackLabel;
  }

  const rtc = api.getState().rtc;
  const audioTrack = stream.getAudioTracks()[0];
  const mic = rtc.localStream && rtc.localStream.audio && audioTrack ? audioTrack.label : null;
  const cam = getCam();
  return api.dispatch(wampMuteCall(rtc.call!.room_id, rtc.call!.id, cam, mic, false));
}

async function changeAudioDevice(
  client: RTCClient,
  api: MiddlewareAPI,
  micId: string,
  connectionId: string
) {
  const localStreamConstraints = api.getState().rtc.localStream;
  if (!localStreamConstraints) {
    throw RTCClient.createError(
      rtcErrors.NoLocalStreamConstraintsError,
      'No local stream constraints found in store'
    );
  }
  const stream = await client.getUMAndCheckConnection(connectionId, null, micId);
  try {
    checkIfCallEnded(client, connectionId);
    const result = await sendChangeDeviceMuteRequest(api, stream, client, connectionId);
    checkIfMuteFailed(result as WampErrorAction<{}, {}, Action>);
    checkIfCallEnded(client, connectionId);
    client.stopLocalAudioTrack();
    const [audioTrack] = stream.getAudioTracks();
    audioTrack.enabled = Boolean(localStreamConstraints.audio);
    await client.replaceLocalTrack(audioTrack, false);
    await api.dispatch(changeCallStatus('connected'));
  } catch (e) {
    stream.getTracks().forEach(track => track.stop());
    throw e;
  }
}

async function changeBothDevices(
  client: RTCClient,
  api: MiddlewareAPI,
  camId: string,
  micId: string,
  connectionId: string
) {
  const newStream = await client.getUMAndCheckConnection(connectionId, camId, micId);
  try {
    const result = await sendChangeDeviceMuteRequest(api, newStream, client, connectionId, true);
    checkIfMuteFailed(result as WampErrorAction<{}, {}, Action>);
    checkIfCallEnded(client, connectionId);
    client.stopLocalAudioTrack();
    client.stopLocalVideoTrack();
    const rtc = api.getState().rtc;
    const isAudioEnabled = !!rtc.localStream?.audio;
    if (api.getState().rtc.localStream) {
      const [audioTrack] = newStream.getAudioTracks();
      audioTrack.enabled = isAudioEnabled;
    }
    await client.applyStream(newStream, connectionId);
    await api.dispatch(changeCallStatus('connected'));
  } catch (e) {
    newStream.getTracks().forEach(track => track.stop());
    throw e;
  }
}

async function recall(api: MiddlewareAPI, client: RTCClient, connectionId: string) {
  const rtc = api.getState().rtc;
  if (rtc.call && rtc.localStream) {
    const cam = rtc.localStream.video ? client.getLocalTrack('video').label : null;
    const mic = rtc.localStream.audio ? client.getLocalTrack('audio').label : null;
    const result = await api.dispatch(wampMuteCall(rtc.call.room_id, rtc.call.id, cam, mic, true));
    checkIfMuteFailed(result as WampErrorAction<{}, {}, Action>);
    checkIfCallEnded(client, connectionId);
    await client.renegotiation(true);
  }
}

type StartReconnectingActionType =
  | 'turnoffVideo'
  | 'turnonVideo'
  | 'changeCamera'
  | 'changeMic'
  | 'changeBoth'
  | 'recall';

function startReconnecting(
  action: MuteCallAction | RTCAction,
  client: RTCClient,
  api: MiddlewareAPI,
  type: StartReconnectingActionType
) {
  return new Promise(async (resolve, reject) => {
    const connectionId = client.connectionId as string;
    try {
      client.callFailedCallback = reject;
      client.callSuccessCallback = resolve;
      switch (type) {
        case 'turnonVideo':
          await turnOnVideo(client, api, (action as MuteCallAction).camId!, connectionId);
          break;
        case 'turnoffVideo':
          await turnOffVideo(client, api, connectionId);
          break;
        case 'changeCamera':
          await changeVideoDevice(client, api, (action as MuteCallAction).camId!, connectionId);
          break;
        case 'changeMic':
          await changeAudioDevice(client, api, (action as MuteCallAction).micId!, connectionId);
          break;
        case 'changeBoth':
          await changeBothDevices(
            client,
            api,
            (action as MuteCallAction).camId!,
            (action as MuteCallAction).micId!,
            connectionId
          );
          break;
        case 'recall':
          await recall(api, client, connectionId);
          break;
        default:
          return;
      }
    } catch (e) {
      switch (e.name) {
        case 'NotReadableError':
        case 'TrackStartError':
          api.dispatch(changeCallStatus('connected'));
          reject(e);
          break;
        case rtcErrors.UncriticalError:
          // if reason of this error is invalid status on server, which means recipient has already started renegotiation,
          // leave everything as is and wait for upgraded event, else roll back to 'connected' call status
          if (e.message !== callIsNotConnectedServerError) {
            api.dispatch(changeCallStatus('connected'));
          }
          reject(e);
          break;
        case rtcErrors.ReconnectAborted:
          reject(e);
          break;
        default:
          client.handleError(e, connectionId);
      }
    }
  });
}

async function handleReconnectAnswer(
  action: CallUpgradedAction,
  client: RTCClient,
  api: MiddlewareAPI
) {
  const rtcState = api.getState().rtc;
  const connectionId = client.connectionId as string;
  if (!rtcState.call || rtcState.call.status === 'connected') {
    return;
  }
  try {
    if (rtcState.call.isRecalling) {
      api.dispatch(toggleRecall(false));
    }
    const desc: RTCSessionDescriptionInit = {
      type: 'answer',
      sdp: action.sdp
    };
    checkIfCallEnded(client, connectionId);
    await client.setRemoteDescription(desc, connectionId);
  } catch (e) {
    client.handleError(e, connectionId);
  }
}

async function handleReconnectOffer(sdp: string, client: RTCClient, api: MiddlewareAPI) {
  const connectionId = client.connectionId as string;
  try {
    const desc: RTCSessionDescriptionInit = {
      type: 'offer',
      sdp
    };
    await client.setRemoteDescription(desc, connectionId);
    const answerSdp = await client.createAnswer();
    const rtc = api.getState().rtc;
    const responseAction = await api.dispatch(
      upgradeCall(rtc.call!.id, rtc.call!.room_id, {answer: answerSdp})
    );
    if (responseAction.type !== WAMP_UPGRADE_CALL_FAIL) {
      if (!client.connection) {
        return;
      }
      await client.setLocalDescription(answerSdp, connectionId);
    } else {
      throw RTCClient.createError(
        rtcErrors.WAMPError,
        (responseAction as WampErrorAction<{}, {}, Action>).error.error
      );
    }
  } catch (e) {
    client.handleError(e, connectionId);
  }
}

function getReconnectAction(component: ConnectionComponent) {
  if (component === 'audio') {
    return 'changeMic';
  }
  if (component === 'video') {
    return 'changeCamera';
  }
  return 'changeBoth';
}

function getFailWampActionMessage(action: RTCErrorAction) {
  if (!action.error) {
    return unknownRTCErrorMessage;
  }
  if (action.error.kwargs && action.error.kwargs.errors && action.error.kwargs.errors[0]) {
    return action.error.kwargs.errors[0].message;
  }
  if (action.error.args && action.error.args[0]) {
    return action.error.args[0];
  }
  return unknownRTCErrorMessage;
}

function handleUserWAMPReconnect(api: MiddlewareAPI, client: RTCClient) {
  const appState = api.getState();
  const call = appState.rtc.call;
  if (
    call &&
    call.id &&
    !appState.rtc.otherSessionCall &&
    appState.chat!.rooms &&
    appState.chat!.rooms[call.room_id]
  ) {
    api
      .dispatch(upgradeCall(call.id, call.room_id))
      .then((answer: WampCallResponseAction<ServerSideCallInfo[], {}, {}>) => {
        if (answer.type === WAMP_UPGRADE_CALL_FAIL) {
          api.dispatch(callEnd());
          return;
        }

        // check if recipient has started reconnecting while this user was offline
        // check local connection status to make sure reconnect was initiated from recipient, not this user
        const callOnServer = answer.wamp.callResult.args[0];
        if (callOnServer.status === 'reconnect-offered' && call.status === 'connected') {
          handleReconnectOffer(callOnServer.offer!, client, api);
          api.dispatch(changeCallStatus('reconnecting'));
          const userIsCaller = callOnServer.caller_id === String(api.getState().user.id);
          if (userIsCaller) {
            api.dispatch(toggleRemoteVideoDevice(callOnServer.recipient_video));
          } else {
            api.dispatch(toggleRemoteVideoDevice(callOnServer.caller_video));
          }
          return;
        }
      });
  }
}

const wampMiddleware: RTCMiddleware = client => api => next => {
  client.setApi(api);
  return action => {
    const appState = api.getState();
    switch (action.type) {
      case CALL_CREATE:
        next(action);
        return handleCallCreate(action as CallStartedAction, client, api);
      case CALL_ACCEPT:
        next(action);
        return handleCallAccept(action as CallAnswerAction, client, api);
      case CALL_OFFERED_EVENT:
        next(action);
        return handleCallOfferedEvent(action as SdpAction, client, api);
      case CALL_ANSWERED_EVENT:
        next(action);
        return handleCallAnsweredEvent(action as SdpAction, client, api);
      case INCOMING_CALL:
        handleIncomingCall(action as IncomingCallAction, client, api);
        return next(action);
      case CALL_ANSWERED:
        handleCallAnswered(action, client, api);
        return next(action);
      case CALL_ACCEPTED_EVENT:
        handleCallAcceptedEvent(action, client, api);
        return next(action);
      case ICE_CANDIDATE_EVENT:
        handleAddIceCandidateEvent(action as AddIceCandidateAction, client, api);
        return next(action);
      case GET_REMOTE_STREAM:
        next(action);
        return client.getRemoteStream();
      case GET_LOCAL_STREAM:
        next(action);
        return handleGetLocalStream(client);
      case CALL_END: {
        if (client.rejectRTCSettings) {
          client.rejectRTCSettings();
        }
        const nextAction = next(action);
        client.closeConnection();
        return nextAction;
      }
      case OTHER_SESSION_CALL: {
        const nextAction = next(action);
        client.closeConnection();
        return nextAction;
      }
      case WAMP_CALL_HANG_UP: {
        const nextAction = next(action);
        client.closeConnection();
        return nextAction;
      }
      case WAMP_SEND_ICE_CANDIDATE_FAIL:
        const errorMessage = getFailWampActionMessage(action as RTCErrorAction);
        client.handleError(
          RTCClient.createError(rtcErrors.WAMPError, errorMessage),
          client.connectionId!,
          {failedAction: action}
        );
        return next(action);
      case CALL_MUTE:
        if ((action as MuteCallAction).component === 'audio') {
          handleAudioMute(action as MuteCallAction, client, api, client.connectionId!);
          return next(action);
        } else {
          next(action);
          return startReconnecting(
            action as MuteCallAction,
            client,
            api,
            (action as MuteCallAction).camId ? 'turnonVideo' : 'turnoffVideo'
          );
        }
      case CALL_SIGNALING_STABLE_EVENT:
        client.logCodecInfo();
        return next(action);
      case CALL_UPGRADED_EVENT:
        if ((action as CallUpgradedAction).sdpType === 'answer') {
          handleReconnectAnswer(action as CallUpgradedAction, client, api);
        } else {
          handleReconnectOffer((action as CallUpgradedAction).sdp, client, api);
        }
        return next(action);
      case OUTGOING_CALL_INTERRUPTED:
        if (client.rejectRTCSettings) {
          client.rejectRTCSettings();
        }
        return next(action);
      case TOGGLE_RECALL:
        if (!(action as ToggleElementAction).show) {
          return next(action);
        }
        next(action);
        return startReconnecting(action, client, api, 'recall');
      case wampClientActionType(CONNECTION_CLOSED):
        api.dispatch(callEnd());
        return next(action);
      case wampClientActionType(CONNECTION_OPENED):
        next(action);
        handleUserWAMPReconnect(api, client);
        return;
      case SAVE_MODAL_SELECTION:
        const newCam =
          appState.mediaDevices.modalSelectedCam !== appState.mediaDevices.cam &&
          Boolean(appState.rtc && appState.rtc.localStream && appState.rtc.localStream.video)
            ? appState.mediaDevices.modalSelectedCam
            : null;
        const newMic =
          appState.mediaDevices.modalSelectedMic !== appState.mediaDevices.mic
            ? appState.mediaDevices.modalSelectedMic
            : null;
        if (appState.rtc.call && !appState.rtc.incomingCall && (newMic || newCam)) {
          const component = (newCam && newMic && 'both') || (newCam && 'video') || 'audio';
          api.dispatch(changeCallMediaDevice(component, newCam, newMic));
        }
        return next(action);
      case CALL_CHANGE_MEDIA_DEVICE:
        next(action);
        if (!appState.rtc.call) {
          return;
        }
        const comp = (action as MuteCallAction).component;
        if (appState.rtc.call.status === 'connected') {
          startReconnecting(action as MuteCallAction, client, api, getReconnectAction(comp)).catch(
            e => {
              if (e.name === rtcErrors.UncriticalError) {
                client.setChangeDeviceTimeout(comp);
              }
            }
          );
        } else {
          client.setChangeDeviceTimeout(comp);
        }
        return;
      case PARTNER_DISCONNECTED:
        const rtc = api.getState().rtc;
        const showPartnerDisconnected = !!rtc.call?.id && !rtc.otherSessionCall;
        api.dispatch(callEnd());
        if (showPartnerDisconnected) {
          const intl = api.getState().intl;
          toastr.error(
            intl.formatMessage(RTCMessages.PartnerDisconnectedHeader),
            intl.formatMessage(RTCMessages.PartnerDisconnectedText)
          );
        }
        return;
      default:
        return next(action);
    }
  };
};

export default wampMiddleware;
