import React, {type FC, type PropsWithChildren} from 'react';
import classNames from 'classnames';

import './PageControls.scss';

const PageControls: FC<PropsWithChildren<{className?: string; pullRight?: boolean}>> = ({
  children,
  className,
  pullRight
}) => (
  <div className={classNames('page-controls', className, {'pull-right': pullRight})}>
    {children}
  </div>
);

export default React.memo(PageControls);
