import {defineMessages} from 'react-intl';

export const introMessages = defineMessages({
  IntroPage: {
    id: 'XIntro.IntroPage'
  },
  ThemeBasic: {
    id: 'XIntro.Theme.Basic'
  },
  VisualPageTheme: {
    id: 'XIntro.Editor.VisualPageTheme'
  },
  EditorHeader: {
    id: 'XIntro.Editor.Header'
  },
  ContentPlaceholder: {
    id: 'XIntro.Editor.ContentPlaceholder'
  },
  BackgroundImageEmpty: {
    id: 'XIntro.Editor.Validation.BackgroundImageEmpty'
  },
  ContentEmpty: {
    id: 'XIntro.Editor.Validation.ContentEmpty'
  }
});
