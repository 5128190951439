import * as yup from 'yup';
import {type Block, type Value} from '@englex/slate';
import {type IntlShape} from 'react-intl';

import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {WidgetTitle, type WidgetToJSONOptions, WidgetType} from 'store/exercise/player/interface';
import {SlateBlock} from 'components/Slate/interface';
import {genKey, isBlockOfType, valueJSONFromText} from 'components/Slate/utils';
import {spellingLetterMatcher, wordTemplateLiteral} from 'config/static';
import {property} from 'immutable-record/decorator/property';
import {type SpellingJSON} from 'store/exercise/player/widgets/Selling/interface';

import XScrambledRecord from '../XScrambled/XScrambledRecord';
import {SpellingType, type XSpellingJSON, type XSpellingProperties} from './interface';
import {
  documentNotEmpty,
  sentencesContainDraggablesAmount,
  spellingSentencesContainWordsAmount
} from '../validation';
import validationMessages from '../i18n';
import {jsonContentFromSentences} from '../XScrambled/initials';
import {type GetPartsAndWordsResult, type Token} from '../XScrambled/interface';

export const ORDINAL_MAX_SIZE = 3;

class XSpellingRecord extends XScrambledRecord implements XSpellingProperties {
  public declare readonly ordinal?: string;
  public declare readonly spellingType: SpellingType;

  public static create(sentences: string[] = [''], manualSplitting?: boolean): XSpellingRecord {
    return new this({
      id: genKey(),
      type: WidgetType.SPELLING,
      spellingType: SpellingType.Phrases,
      task: valueJSONFromText(''),
      sentencesValueJSON: jsonContentFromSentences(sentences),
      ...(manualSplitting ? {options: {manualSplitting}} : {})
    });
  }

  public constructor(raw: XSpellingJSON) {
    super(raw);
    this.initValues({
      spellingType: raw.spellingType || SpellingType.Phrases,
      ordinal: raw?.ordinal
    });
    // letterChecker could be used outside, so bind record context here
    this.letterChecker = this.letterChecker.bind(this);
  }

  public get type() {
    return WidgetType.SPELLING;
  }

  public get isOneSentence() {
    return (
      this.sentencesValue && this.sentencesValue.document.getIn(['nodes', '0', 'nodes']).size === 1
    );
  }

  public get excerpt(): string {
    const task = this.task.document.text;

    const text = this.sentencesValue.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.LIST_ITEM))
      .map((b: Block, i: number) => {
        if (!this.isOneSentence) return `${i + 1}. ${b.text}`;
        if (this.ordinal) return `${this.ordinal}. ${b.text}`;
        return `${b.text}`;
      })
      .join(' ');

    return `${task} ${text}`.trim();
  }

  public get title(): string {
    const title = WidgetTitle[this.type];
    return `${title} (phrases)`;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      sentencesValue: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage({id: 'XEditor.XWidget.Spelling.WordsCannotBeBlank'}),
          ({document}: Value) => documentNotEmpty(document)
        )
        .test(
          'Sentence should contain at least two words',
          intl.formatMessage(validationMessages.SpellingSentenceIsTooShort),
          (v: Value) => {
            if (this.options?.get('manualSplitting')) return true;
            return spellingSentencesContainWordsAmount(v);
          }
        )
        .test(
          'Sentence should contain at least two draggable blocks',
          intl.formatMessage({id: 'XEditor.Validation.NotEnoughDraggables'}),
          (v: Value) => {
            if (!this.options?.get('manualSplitting')) return true;
            return sentencesContainDraggablesAmount(v);
          }
        ),

      ordinal: yup
        .string()
        .test(
          'Task number cannot be longer than 3 characters',
          intl.formatMessage({id: 'XEditor.XWidget.Spelling.TaskNumber'}),
          (ordinal?: string) => {
            if (!ordinal) return true;
            return ordinal.length <= ORDINAL_MAX_SIZE;
          }
        )
        .test(
          'Only digits can be in task number',
          intl.formatMessage({id: 'XEditor.XWidget.Spelling.OnlyNumber'}),
          (ordinal?: string) => {
            if (!ordinal) return true;
            return /^\d+$/.test(ordinal);
          }
        )
    });
  }

  public setOrdinal(newOrdinal?: string) {
    return this.set('ordinal', newOrdinal ? newOrdinal : undefined);
  }

  public letterChecker(chars: string[], index: number, sentenceLength: number) {
    if (chars[index] === '-') return this.isBetweenAllowedChars(chars, index);
    return this.isCharAllowed(chars[index]);
  }

  public toJSON(options?: WidgetToJSONOptions): SpellingJSON {
    return {
      ...super.toJSON(options),
      spellingType: this.spellingType,
      ordinal: this.ordinal
    };
  }

  protected isBetweenAllowedChars(chars: string[], index: number) {
    const {previous, next} = this.getPreviousAndNextChars(chars, index);

    return !!previous && !!next && this.isCharAllowed(previous) && this.isCharAllowed(next);
  }

  protected getPreviousAndNextChars(chars: string[], index: number) {
    return {
      previous: chars[index - 1],
      next: chars[index + 1]
    };
  }

  protected splitSentences(text: string) {
    return text.split('');
  }

  protected filterSentences(texts: string[]) {
    return texts.filter((p, i) => {
      return this.letterChecker(texts, i, texts.length);
    });
  }

  protected getPartsAndWords(text: string, tokenIds: string[]): GetPartsAndWordsResult {
    const parts = this.splitSentences(text);
    const words = this.filterSentences(parts);

    return {parts, words};
  }

  protected liTemplate(text: string, words: string[]) {
    let template = text;
    words.forEach(w => {
      if (w !== '-') template = template.replace(w, wordTemplateLiteral);
      else {
        const templateArray = template.split('');
        const index = templateArray.findIndex(
          (item, i) =>
            item === '-' &&
            (this.isCharAllowed(templateArray[i - 1]) ||
              templateArray[i - 1] === wordTemplateLiteral) &&
            (this.isCharAllowed(templateArray[i + 1]) ||
              templateArray[i + 1] === wordTemplateLiteral)
        );

        templateArray[index] = wordTemplateLiteral;
        template = templateArray.join('');
      }
    });

    return template;
  }

  protected liTokens(tokenIds: string[], parts: string[], words: string[], shuffledIds: string[]) {
    const tokens: Token[] = [];
    let offset = 0;
    let i = 0;
    parts.forEach((part, k) => {
      if (words.includes(part)) {
        const choiceId = shuffledIds ? shuffledIds[i] : tokenIds[i];

        if (part !== '-' || this.isBetweenAllowedChars(parts, k)) {
          tokens.push({
            id: `${choiceId}`,
            value: part,
            offset
          });
          i++;
        }
      }
      offset += part.length;
    });

    return tokens;
  }

  private isCharAllowed(char: string) {
    return spellingLetterMatcher.test(char);
  }
}

decorate(XSpellingRecord, {
  ordinal: property(),
  spellingType: property(SpellingType.Phrases)
});
record()(XSpellingRecord);
export default XSpellingRecord;
