import React, {
  createContext,
  type Dispatch,
  type FC,
  type ReactNode,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react';
import ReactDOM from 'react-dom';
import {type Action} from 'redux';

import {
  type ResourcesContextShape,
  type ResourcesProps,
  type ViewerContextShape
} from './interface';
import {registerProvider, unregisterProvider} from './state/actions';
import reducer, {initialState} from './state/reducer';
import Viewer from './Viewer';

const ViewerContext = createContext<ViewerContextShape | undefined>(undefined);
const ResourcesContext = createContext<ResourcesContextShape | undefined>(undefined);

export const ViewerProvider: FC<{children: ReactNode}> = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const node = useMemo(() => {
    let node: Element | null = null;
    if (state.activeProviderId) {
      const provider = state.providers.find(p => p.id === state.activeProviderId);
      if (provider) {
        node = provider.node;
      }
    }
    return node ? node : document.querySelector('body')!;
  }, [state.activeProviderId, state.providers]);

  return (
    <ViewerContext.Provider value={{state, dispatch}}>
      {ReactDOM.createPortal(
        <Viewer
          activeProviderId={state.activeProviderId}
          activeResourceId={state.activeResourceId}
          resourceType={state.resourceType}
          providers={state.providers}
          dispatch={dispatch}
        />,
        node
      )}
      {children}
    </ViewerContext.Provider>
  );
};

export const Resources: FC<ResourcesProps> = React.memo(({children, id, node}) => {
  const viewerContext = useViewerContext();

  if (!viewerContext) {
    return <>{children}</>;
  }

  return (
    <ResourcesIfViewer id={id} node={node} dispatch={viewerContext.dispatch}>
      {children}
    </ResourcesIfViewer>
  );
});

const ResourcesIfViewer: FC<
  {
    dispatch: Dispatch<Action>;
  } & ResourcesProps
> = ({children, dispatch, id, node = null}) => {
  const registered = useRef(false);

  useEffect(() => {
    if (!registered.current && node) {
      dispatch(registerProvider(id, node));
      registered.current = true;
    }
  }, [dispatch, id, node]);

  useEffect(() => {
    return () => {
      dispatch(unregisterProvider(id));
      registered.current = false;
    };
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return <ResourcesContext.Provider value={{id}}>{children}</ResourcesContext.Provider>;
};

export const useViewerContext = () => {
  return useContext(ViewerContext);
};

export const useResourcesContext = () => {
  return useContext(ResourcesContext);
};
