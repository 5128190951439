/// <reference path="./hyperscript.d.ts" />
import type {HyperscriptOptions} from '@englex/slate-hyperscript';

import {SlateAnnotation, SlateBlock, SlateInline, SlateMark} from './interface';

const hyperscript: HyperscriptOptions = {
  blocks: {
    paragraph: SlateBlock.DEFAULT,
    im: SlateBlock.IMAGE,
    list: SlateBlock.LIST,
    listItem: SlateBlock.LIST_ITEM,
    dialogTable: SlateBlock.DIALOG_TABLE,
    dialogRow: SlateBlock.DIALOG_ROW,
    dialogCell: SlateBlock.DIALOG_CELL,
    ql: SlateBlock.QUESTION_LIST,
    qi: SlateBlock.QUESTION_ITEM,
    qa: SlateBlock.QUESTION_ANSWER,
    example: SlateBlock.EXAMPLE
  },
  inlines: {
    gap: SlateInline.GAP,
    link: SlateInline.LINK,
    icon: SlateInline.ICON
  },
  marks: {
    bold: SlateMark.BOLD,
    italic: SlateMark.ITALIC,
    underline: SlateMark.UNDERLINE,
    strike: SlateMark.STRIKE_THROUGH,
    fontSize: SlateMark.FONT_SIZE,
    color: SlateMark.COLOR,
    highlight: SlateMark.HIGHLIGHT
  },
  annotations: {
    createLink: SlateAnnotation.CREATE_LINK,
    parsedWord: SlateAnnotation.PARSED_WORD,
    createPointer: SlateAnnotation.CREATE_POINTER,
    pointer: SlateAnnotation.POINTER
  }
};

export default hyperscript;
