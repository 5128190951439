import {useDrop} from 'react-dnd';
import {NativeTypes} from 'react-dnd-html5-backend';
import {type Paint} from '@englex/paint';

import {md5Chunk} from 'services/common-methods';
import {validateImageFile} from 'common/ImageUpload/functions';
import {IMAGE_MIN_HEIGHT} from 'components/Slate/SlateEditor/plugins/button/Image/static';
import {UploadingPictureStatus} from 'common/ImageUpload/interface';

import {type PaintImage} from './interface';

export const useImageDrop = (paint: Paint & PaintImage) => {
  return useDrop({
    accept: NativeTypes.FILE,
    drop: async (item, monitor) => {
      const droppedFiles = monitor.getItem<{files: File[]}>()['files'];
      if (droppedFiles.length !== 1) return;
      const file = droppedFiles[0];
      const md5 = await md5Chunk(file);
      const status = await validateImageFile(file, md5, undefined, undefined, IMAGE_MIN_HEIGHT);
      if (status && status !== UploadingPictureStatus.PREPARING) {
        paint.setImageDropInitials({status});
      } else {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
          const imageDataUrl = fileReader.result as string;
          if (imageDataUrl) {
            paint.setImageDropInitials({
              imageDataUrl,
              file,
              validatedFile: {file, md5}
            });
          }
        };
        fileReader.readAsDataURL(file);
      }
    },
    collect: monitor => ({isOver: monitor.isOver({shallow: true})})
  });
};
