import {record} from 'immutable-record/decorator/record';
import {WidgetType} from 'store/exercise/player/interface';

import XQuestsRecord from '../XQuests/XQuestsRecord';
import {type XQuestsProperties} from '../XQuests/interface';

class XQuestionsRecord extends XQuestsRecord implements XQuestsProperties {
  public get type() {
    return WidgetType.QUESTIONS;
  }
}

record()(XQuestionsRecord);

export default XQuestionsRecord;
