import {
  type SubscriptionActionCreator,
  type WampCloseCreator,
  type WampOpeningCreator
} from './interface';
import {
  ADD_SUBSCRIPTION,
  CONNECTION_CLOSE,
  CONNECTION_OPENING,
  REMOVE_SUBSCRIPTION,
  wampClientActionType
} from './actionTypes';
import {type AutobahnClientName, defaultAutobahnClientName} from './types';

export const wampConnectionOpen: WampOpeningCreator = (
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(CONNECTION_OPENING, client),
  client
});

export const wampConnectionClose: WampCloseCreator = (
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(CONNECTION_CLOSE, client),
  client
});

export const addSubscription: SubscriptionActionCreator = (
  topic: string,
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(ADD_SUBSCRIPTION, client),
  topic
});

export const removeSubscription: SubscriptionActionCreator = (
  topic: string,
  client: AutobahnClientName = defaultAutobahnClientName
) => ({
  type: wampClientActionType(REMOVE_SUBSCRIPTION, client),
  topic
});
