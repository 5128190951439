import React, {type FC} from 'react';

import Icon from '../Icon';
import './CollapsedSidebarButton.scss';

interface Props {
  onClick: () => void;
  shouldBeRendered: boolean;
}

export const CollapsedSidebarButton: FC<Props> = props => {
  if (!props.shouldBeRendered) {
    return null;
  }
  return (
    <div className="float-sidebar-collapsed" onClick={props.onClick}>
      <div className="left-arrow">
        <Icon name="pc-thin-left-arrow" />
      </div>
    </div>
  );
};
