import {type Action, type ReducersMapObject} from 'redux';

import {type StudentTeachersState} from './interface';
import {SET_SELECTED_PARTNER, SET_STUDENT_TEACHERS} from './actionTypes';
import {type SetSelectedStudentTeacherAction, type SetStudentTeachersAction} from './actions';

const actionHandlers: ReducersMapObject = {
  [SET_STUDENT_TEACHERS]: (
    state: StudentTeachersState,
    {studentTeachers}: SetStudentTeachersAction
  ): StudentTeachersState => ({
    ...state,
    studentTeachers
  }),
  [SET_SELECTED_PARTNER]: (
    state: StudentTeachersState,
    {id, isStudent}: SetSelectedStudentTeacherAction
  ): StudentTeachersState => {
    const {studentTeachers} = state;

    const isInactive = !studentTeachers
      ? true
      : isStudent
        ? !Object.values(studentTeachers).find(st => !st.deleted_at)
        : !!studentTeachers[id]?.deleted_at;
    return {...state, selectedStudentTeacher: id, isInactive};
  }
};

const initialState = {
  isInactive: true
};

export const studentTeachersReducer = (
  state: StudentTeachersState = initialState,
  action: Action
) => {
  const handler = actionHandlers[action.type];
  if (handler) return handler(state, action);
  return state;
};
