import {useCallback, useMemo} from 'react';
import {useDispatch} from 'react-redux';

import {getDictionaryOverviewRequest, requestStudentLists} from 'store/dictionary/requests';
import {setDictionaryOverview, setStudentLists} from 'store/dictionary/actions';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type DictionaryList, type DictionaryOverview} from 'components/Dictionary/shared/interface';

export const useSidebarDictionaryInitializer = (studentId: number) => {
  const {
    isLoading: loadingOverview,
    reload: reloadOverview,
    isError: isErrorOverview
  } = useOverviewLoader(studentId);
  const {
    isLoading: loadingLists,
    reload: reloadLists,
    isError: isErrorLists
  } = useListsLoader(studentId);
  return {
    isError: isErrorOverview || isErrorLists,
    isLoading: loadingOverview || loadingLists,
    reload: () => {
      reloadOverview();
      reloadLists();
    }
  };
};

const useListsLoader = (studentId: number) => {
  const dispatch = useDispatch();

  const requestAction = useMemo(() => requestStudentLists(studentId, 'student'), [studentId]);
  const successHandler = useCallback(
    (dictionaryLists: DictionaryList[]) => dispatch(setStudentLists(dictionaryLists)),
    [dispatch]
  );
  return useApiRequest(requestAction, successHandler);
};

const useOverviewLoader = (studentId: number) => {
  const dispatch = useDispatch();
  const requestAction = useMemo(
    () => getDictionaryOverviewRequest(studentId, 'student'),
    [studentId]
  );
  const successHandler = useCallback(
    (overview: DictionaryOverview) => dispatch(setDictionaryOverview(overview)),
    [dispatch]
  );
  return useApiRequest(requestAction, successHandler);
};
