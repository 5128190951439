import {type AxiosRequestError} from 'services/axios/interface';

export function makeCancellable<T>(
  promise: Promise<T>,
  successHandler: (val: T) => void,
  failHandler: (error: Error | AxiosRequestError) => void,
  canceledHandler?: () => void,
  finallyHandler?: () => void
) {
  let hasCanceled = false;

  promise
    .then(
      val => {
        if (!hasCanceled) {
          successHandler(val);
        }
      },
      error => {
        if (!hasCanceled) {
          failHandler(error);
        }
      }
    )
    .finally(() => {
      if (!hasCanceled) finallyHandler?.();
    });

  return {
    cancel() {
      hasCanceled = true;
      canceledHandler && canceledHandler();
    }
  } as CancellablePromise;
}

export interface CancellablePromise {
  cancel: () => void;
}
