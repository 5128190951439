import React, {
  type CSSProperties,
  type FC,
  type RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import {type Color, ColorsPaint, usePaint} from '@englex/paint-react';
import {HexColorPicker} from 'react-colorful';
import {useDebouncyEffect} from 'use-debouncy';
import classNames from 'classnames';

import Icon from 'components/Icon';

import {ToolbarButton} from './button/ToolbarButton';
import {Separator} from './Separator';
import isShortcut from '../../../helpers/shortcut';

import './ColorPicker.scss';

interface Props {
  active?: Color;
  firstButtonRef?: RefObject<HTMLButtonElement>;
  lastButtonRef?: RefObject<HTMLButtonElement>;
  onChange?: (c: Color) => void;
  palette?: Color[];
  paletteLength?: number;
  hideTooltip?: () => void;
  showColorfulToggle?: boolean;
}

export const ColorPicker: FC<Props> = ({
  onChange,
  active,
  palette = [],
  paletteLength = palette?.length || 0,
  showColorfulToggle,
  hideTooltip
}) => {
  const paint = usePaint<ColorsPaint>();
  const [pickerColor, setPickerColor] = useState<Color | undefined>(active);
  const [showPicker, setShowPicker] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const selectColor = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const {color} = e.currentTarget.dataset;

      if (color && color !== active) {
        ColorsPaint.setColor(paint, color);
        onChange?.(color);
      }
    },
    [active, onChange, paint]
  );

  const removeRecent = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e.button !== 0 || !e.shiftKey) return;

      const {recent} = e.currentTarget.dataset;
      const index = Number(recent);

      if (paint.colors.recent?.[index]) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        ColorsPaint.deleteRecentIndex(paint, index!);
      }
    },
    [paint]
  );

  const onTogglePickerClick = useCallback(() => {
    setShowPicker(c => !c);
  }, []);

  const recent = paint.colors.recent || [];

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      e.stopPropagation();
      if (isShortcut(e, 'enter')) {
        e.preventDefault();
        hideTooltip?.();
      }
    },
    [hideTooltip]
  );

  const renderPalette = useCallback(
    (p: Color[], recent?: boolean) => {
      return p?.map((color, i) => {
        return (
          <React.Fragment key={i}>
            <ToolbarButton
              data-color={color}
              data-recent={recent ? i : undefined}
              className={classNames('color', {active: color === active})}
              key={color}
              style={{color}}
              onClick={selectColor}
              onMouseDown={recent ? removeRecent : undefined}
            >
              <span className="palette-color" style={{backgroundColor: color}} />
            </ToolbarButton>
          </React.Fragment>
        );
      });
    },
    [active, selectColor, removeRecent]
  );

  const onBlur = useCallback(
    (e: React.FocusEvent) => {
      if (e.relatedTarget && !ref.current?.contains(e.relatedTarget as Node)) {
        hideTooltip?.();
      }
    },
    [hideTooltip]
  );

  useDebouncyEffect(
    () => {
      if (pickerColor) {
        ColorsPaint.setColor(paint, pickerColor);
      }
    },
    200,
    [pickerColor]
  );

  useEffect(
    () => () => {
      // add to recent on component unmount
      const active = ColorsPaint.getColor(paint);
      active &&
        ColorsPaint.addRecent(paint, active, {exclude: palette, maxRecent: palette?.length * 2});
    },
    [paint, palette]
  );
  return (
    <div ref={ref} className="select-color" onBlur={onBlur}>
      <div className="picker">
        {showColorfulToggle && (
          <div className="picker-toggle">
            <Separator />
            <ToolbarButton onClick={onTogglePickerClick}>
              <Icon name="angle-down" rotate={showPicker ? 180 : 0} />
            </ToolbarButton>
          </div>
        )}
        <div className="palette" style={{'--paletteLength': paletteLength} as CSSProperties}>
          {renderPalette(palette)}
          {renderPalette(recent, true)}
        </div>
      </div>

      {showPicker && (
        <HexColorPicker color={active} onChange={setPickerColor} onKeyDown={onKeyDown} />
      )}
    </div>
  );
};
