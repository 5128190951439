import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {useWampSubscription} from 'hooks/wamp/useWampSubscription';
import {type AppState, type SelectionData} from 'store/interface';
import {Resources} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import XPlayer from 'components/XPlayer';
import {type ExerciseRecordMap} from 'store/exercise/player/interface';
import Loader from 'components/Loader';
import WampErrorMask from 'components/WampErrorMask';

import {subscribeUnitPage} from '../UnitInstancePage/actions';
import {SavingBadge} from '../XPlayerPage/SavingBadge';
import {SelectionProvider} from '../../../../components/Pointer/selection/SelectionContext';
import useCanPlayTogether from '../../../../components/media/_common/useCanPlayTogether';

interface Props {
  playerExercises: ExerciseRecordMap;
  unitInstanceId: number;
  unitPage: number;
  selfCheckEnabled?: boolean;
}

const HomeworkExerciseSubscription: React.FC<Props> = (props: Props) => {
  const isMounted = useIsMounted();
  const {studentTeacherId} = useParams<{studentTeacherId: string}>();
  const role = useSelector((state: AppState) => state.user.role)!;
  const selectionData = useSelector<AppState, SelectionData | undefined>(
    state => state.classroom?.selection
  );
  const resourcesNode = useNode('.page-left');
  const {unitInstanceId, unitPage, selfCheckEnabled} = props;
  const {error, isSubscribing, retry} = useWampSubscription(
    subscribeUnitPage,
    unitInstanceId,
    unitPage
  );
  const canOpenTogether = useCanPlayTogether({studentTeacherId});

  if (!isMounted.current && isSubscribing) {
    return <Loader />;
  }

  if (error) {
    return <WampErrorMask reload={retry} />;
  }

  const selectionEnabled =
    canOpenTogether || Boolean(import.meta.env.REACT_APP_POINTER_DEV_MODE_ENABLED);

  return (
    <React.Fragment>
      <Resources id="homework-player-page" node={resourcesNode}>
        <SelectionProvider role={role} active={selectionEnabled} selectionData={selectionData}>
          <XPlayer
            exercises={props.playerExercises}
            role={role}
            homework={true}
            hasPointer={true}
            selfCheckEnabled={selfCheckEnabled}
          />
        </SelectionProvider>
      </Resources>
      <SavingBadge />
    </React.Fragment>
  );
};

export default HomeworkExerciseSubscription;
