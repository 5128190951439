import {type ValueJSON} from '@englex/slate';
import {type Descendant} from 'slate';

import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';

import {type DraftCommentJSON, type DraftCommentProperties} from './interface';
import {valueJSONFromText} from '../../../components/Slate/utils';
import {slateMigrateDown, slateMigrateUp} from '../../../components/SlateJS/utils';

const WEAK_VALUES = new WeakMap<ValueJSON, Descendant[]>();

const Record = recordBase()(BaseRecord);
class DraftCommentRecord extends Record implements DraftCommentProperties {
  public declare readonly id: string | null;
  public declare readonly autoFocus: boolean;
  public declare readonly comment: ValueJSON;
  public declare readonly updatedAt: number;

  public constructor(raw: DraftCommentJSON) {
    super();
    this.initValues({
      id: raw.id,
      updatedAt: raw.updatedAt || new Date().getTime(),
      comment: raw.comment,
      autoFocus: !!raw.autoFocus
    });
  }

  public toJSON(): DraftCommentJSON {
    return {
      id: this.id,
      updatedAt: this.updatedAt,
      comment: this.comment
    };
  }

  public get value(): Descendant[] {
    if (!WEAK_VALUES.has(this.comment)) {
      const val = slateMigrateUp(this.comment);
      WEAK_VALUES.set(this.comment, val);
    }
    return WEAK_VALUES.get(this.comment)!;
  }

  public contentChange(value: Descendant[], id: string | null): this {
    const val = slateMigrateDown(value);
    WEAK_VALUES.set(val, value);
    return this.withMutations(v => {
      v.set('comment', val);
      v.set('autoFocus', undefined);
      v.set('id', id);
      v.set('updatedAt', new Date().getTime());
    });
  }
}

decorate(DraftCommentRecord, {
  id: property(null),
  comment: property(valueJSONFromText()),
  updatedAt: property(0),
  autoFocus: property(false)
});

record()(DraftCommentRecord);

export default DraftCommentRecord;
