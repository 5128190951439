import {Value} from '@englex/slate';

import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';

import {type DialogueMessageJSON, type MessageProperties} from './interface';

const Record = recordBase()(BaseRecord);

class MessageRecord extends Record implements MessageProperties {
  public declare readonly id: string;

  public declare readonly speakerId: string;

  public declare readonly message: Value;

  constructor(raw: Partial<DialogueMessageJSON>) {
    super();
    this.initValues({
      id: raw.id,
      speakerId: raw.speakerId,
      message: raw.message ? Value.fromJSON(raw.message) : undefined
    });
  }

  public toJSON(): DialogueMessageJSON {
    return {
      id: this.id,
      speakerId: this.speakerId,
      message: this.message.toJSON()
    };
  }
}

decorate(MessageRecord, {
  id: property(),
  speakerId: property(),
  message: property()
});

record()(MessageRecord);
export default MessageRecord;
