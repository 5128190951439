import {type RendererPlugin} from '../../interface';
import Alpha from './Alpha';
import Decimal from './Decimal';
import UnorderedList from './UnorderedList';
import ListItem from './ListItem';

class Lists implements RendererPlugin {
  public plugins: RendererPlugin[] = [
    new Decimal(),
    new Alpha(),
    new UnorderedList(),
    new ListItem()
  ];
}

export default Lists;
