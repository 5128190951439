import {AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

const PATH = 'USER/';
export const LOGOUT = PATH + 'LOGOUT';
export const REQUEST_LOGIN = PATH + 'REQUEST_LOGIN';
export const REQUEST_LOGIN_SUCCESS = REQUEST_LOGIN + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_PROFILE = PATH + 'REQUEST_PROFILE';
export const REQUEST_PROFILE_SUCCESS = REQUEST_PROFILE + AXIOS_SUCCESS_POSTFIX;
export const TOKEN_EXPIRED = PATH + 'TOKEN_EXPIRED';
export const TOKEN_RENEWED = PATH + 'TOKEN_RENEWED';
export const GOT_TOKEN = PATH + 'GOT_TOKEN';
