const prefix = 'dictionary/';

export const REQUEST_STUDENT_LISTS = prefix + 'REQUEST_STUDENT_LISTS';
export const SET_STUDENT_LISTS = prefix + 'SET_STUDENT_LISTS';
export const SET_DICTIONARY_OVERVIEW = prefix + 'SET_DICTIONARY_OVERVIEW';
export const UNSHIFT_LIST = prefix + 'UNSHIFT_LIST';
export const REPLACE_LIST = prefix + 'REPLACE_LIST';
export const ACTIVATE_DICTIONARY = prefix + 'ACTIVATE_DICTIONARY';
export const RESET_DICTIONARY = prefix + 'RESET_DICTIONARY';
export const DELETE_LIST_FROM_STORE = prefix + 'DELETE_LIST_FROM_STORE';
export const REQUEST_DICTIONARY_INSTANCE_CHUNK = prefix + 'REQUEST_DICTIONARY_INSTANCE_CHUNK';
export const REQUEST_WORDS_LIST = prefix + 'REQUEST_WORDS_LIST';
export const CREATE_WORDS_LIST = prefix + 'CREATE_WORDS_LIST';
export const ARCHIVE_WORDS_LIST = prefix + 'ARCHIVE_WORDS_LIST';
export const RENAME_WORDS_LIST = prefix + 'RENAME_WORDS_LIST';
export const DELETE_WORDS_LIST = prefix + 'DELETE_WORDS_LIST';
export const ADD_ENTRIES_TO_LIST_REQUEST = prefix + 'ADD_ENTRIES_TO_LIST_REQUEST';
export const DELETE_ENTRIES_FROM_LIST_REQUEST = prefix + 'DELETE_ENTRIES_FROM_LIST_REQUEST';
export const DELETE_ENTRIES_FROM_DICTIONARY_REQUEST =
  prefix + 'DELETE_ENTRIES_FROM_DICTIONARY_REQUEST';
export const DELETE_ENTRY_FROM_DICTIONARY_REQUEST = prefix + 'DELETE_ENTRY_FROM_DICTIONARY_REQUEST';
export const CREATE_WORD_ENTRY_REQUEST = prefix + 'CREATE_WORD_ENTRY_REQUEST';
export const EDIT_WORD_ENTRY_REQUEST = prefix + 'EDIT_WORD_ENTRY_REQUEST';
export const LOAD_PRONUNCIATIONS_REQUEST = prefix + 'LOAD_PRONUNCIATIONS_REQUEST';
export const UPDATE_ALL_ENTRIES = prefix + 'UPDATE_ALL_ENTRIES';
export const REVERSE_ENTRIES = prefix + 'REVERSE_ENTRIES';
export const CLEAR_ALL_ENTRIES = prefix + 'CLEAR_ALL_ENTRIES';
export const SET_LIST_ENTRIES = prefix + 'SET_LIST_ENTRIES';
export const GET_DICTIONARY_OVERVIEW_REQUEST = prefix + 'GET_DICTIONARY_OVERVIEW_REQUEST';
export const SUBSCRIBE_DICTIONARY = prefix + 'SUBSCRIBE_DICTIONARY';
export const DELETE_FROM_LIST = prefix + 'DELETE_FROM_LIST';
export const DELETE_ENTRIES_FROM_LIST = prefix + 'DELETE_ENTRIES_FROM_LIST';
export const DELETE_ENTRY_FROM_STORE = prefix + 'DELETE_ENTRY_FROM_STORE';
export const DELETE_ENTRIES_FROM_STORE = prefix + 'DELETE_ENTRIES_FROM_STORE';
export const SET_IS_REVERSE = prefix + 'SET_IS_REVERSE';
export const UPDATE_LIST_MEMBER_OF = prefix + 'UPDATE_LIST_MEMBER_OF';
export const SORT_LIST = prefix + 'SORT_LIST';
export const SET_IS_READONLY = prefix + 'SET_IS_READONLY';
export const TRAINER_ANSWERS_PUBLISH_EVENT = prefix + 'TRAINER_ANSWERS_PUBLISH_EVENT';
export const COPY_WORD_LIST_REQUEST = prefix + 'COPY_WORD_LIST_REQUEST';

export const SET_SEARCH = prefix + 'SET_SEARCH';
export const SET_STUDENT_ID = prefix + 'SET_STUDENT_ID';
