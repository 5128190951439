import {type Reducer} from 'redux';
import {type AxiosResponse} from 'axios';

import {type LocationChangeAction, LOCATION_CHANGE} from 'store/router';
import {type LayoutState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {CONNECTION_OPENED, wampClientActionType} from 'services/wamp/actions/actionTypes';

import {
  type CollapseLayoutAction,
  type LayoutAction,
  type SetAppOfflineTimeoutAction,
  type SetIsMobileAction,
  type SetPageVisibilityAction,
  type SetVisibilityIntervalAction,
  type UpdateFramesCountAction
} from '../actions/interface';
import {
  APP_OFFLINE,
  APP_ONLINE,
  CLEAR_VISIBILITY_INTERVAL,
  COLLAPSE_LAYOUT,
  REQUEST_MESSAGE_SEND,
  REQUEST_MESSAGE_SEND_ERROR,
  REQUEST_MESSAGE_SEND_SUCCESS,
  REQUEST_MESSAGES_SUBJECTS,
  REQUEST_MESSAGES_SUBJECTS_ERROR,
  REQUEST_MESSAGES_SUBJECTS_SUCCESS,
  SET_APP_OFFLINE_TIMEOUT,
  SET_IS_MOBILE,
  SET_LAYOUT_NETWORK_ERROR,
  SET_NOTIFICATIONS_NUMBER,
  SET_PAGE_VISIBILITY,
  SET_VISIBILITY_INTERVAL,
  SHOW_APP_OFFLINE_MODAL,
  TOGGLE_CHAT_POPOVER,
  TOGGLE_CONTACTS_MODAL,
  TOGGLE_CONTACTS_POPOVER,
  TOGGLE_NAV_DROPDOWN,
  TOGGLE_REPORT_DROPDOWN,
  TOGGLE_USER_DROPDOWN,
  UPDATE_FRAMES_COUNT,
  CLOSE_NAV_DROPDOWN
} from '../actions/actionTypes';
import {type ToggleElementAction} from '../../common/interface';
import {type SetNotificationsNumberAction} from '../actions/action';

const ACTION_HANDLERS: ActionHandlersList<LayoutState, LayoutAction | {payload: AxiosResponse}> = {
  [TOGGLE_CONTACTS_MODAL]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    showContactsModal: action.show
  }),
  [SET_NOTIFICATIONS_NUMBER]: (
    state: LayoutState,
    {notificationsNumber}: SetNotificationsNumberAction
  ) => ({
    ...state,
    notifications: notificationsNumber
  }),
  [SET_LAYOUT_NETWORK_ERROR]: (state: LayoutState) => ({
    ...state,
    networkError: true
  }),
  [wampClientActionType(CONNECTION_OPENED)]: (state: LayoutState) => ({
    ...state,
    show: true
  }),
  [REQUEST_MESSAGES_SUBJECTS]: (state: LayoutState) => ({
    ...state,
    networkError: false,
    subjects: undefined
  }),
  [REQUEST_MESSAGES_SUBJECTS_SUCCESS]: (state: LayoutState, action: {payload: AxiosResponse}) => ({
    ...state,
    subjects: action.payload.data
  }),
  [REQUEST_MESSAGES_SUBJECTS_ERROR]: (state: LayoutState) => ({
    ...state,
    networkError: true
  }),
  [REQUEST_MESSAGE_SEND]: (state: LayoutState) => ({
    ...state,
    sendingMessageAwait: true
  }),
  [REQUEST_MESSAGE_SEND_SUCCESS]: (state: LayoutState) => ({
    ...state,
    sendingMessageAwait: false
  }),
  [REQUEST_MESSAGE_SEND_ERROR]: (state: LayoutState) => ({
    ...state,
    sendingMessageAwait: false
  }),
  [TOGGLE_CONTACTS_POPOVER]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    contactsPopoverOpen: action.show
  }),
  [TOGGLE_USER_DROPDOWN]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    userDropdownOpen: action.show
  }),
  [TOGGLE_NAV_DROPDOWN]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    navDropdownOpen: action.show
  }),
  [CLOSE_NAV_DROPDOWN]: (state: LayoutState) => ({
    ...state,
    navDropdownOpen: false
  }),
  [SET_IS_MOBILE]: (state: LayoutState, action: SetIsMobileAction) => ({
    ...state,
    isMobile: action.isMobile
  }),
  [APP_ONLINE]: (state: LayoutState) => {
    const {showAppOfflineModal, ...newState} = state;
    newState.appOnline = true;
    return newState;
  },
  [SET_APP_OFFLINE_TIMEOUT]: (state: LayoutState, action: SetAppOfflineTimeoutAction) => ({
    ...state,
    appOfflineTimeout: action.timeoutId
  }),
  [SHOW_APP_OFFLINE_MODAL]: (state: LayoutState) => ({
    ...state,
    showAppOfflineModal: true
  }),
  [APP_OFFLINE]: (state: LayoutState) => {
    const {appOnline, appOfflineTimeout, ...newState} = state;
    return newState;
  },
  [TOGGLE_CHAT_POPOVER]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    chatPopoverOpen: action.show
  }),
  [TOGGLE_REPORT_DROPDOWN]: (state: LayoutState, action: ToggleElementAction) => ({
    ...state,
    reportDropdownOpen: action.show
  }),
  [SET_PAGE_VISIBILITY]: (state: LayoutState, action: SetPageVisibilityAction) => {
    return {
      ...state,
      pageHasFocus: action.pageHasFocus
    };
  },
  [SET_VISIBILITY_INTERVAL]: (state: LayoutState, {intervalId}: SetVisibilityIntervalAction) => ({
    ...state,
    framesCount: 1,
    intervalId
  }),
  [UPDATE_FRAMES_COUNT]: (state: LayoutState, {add}: UpdateFramesCountAction) => {
    const {framesCount, ...newState} = state;
    return {
      ...newState,
      framesCount: add ? framesCount! + 1 : framesCount! - 1
    };
  },
  [CLEAR_VISIBILITY_INTERVAL]: (state: LayoutState) => {
    const {framesCount, intervalId, ...newState} = state;
    return newState;
  },
  [COLLAPSE_LAYOUT]: (state: LayoutState, {collapse}: CollapseLayoutAction): LayoutState => ({
    ...state,
    collapsed: collapse
  }),
  [LOCATION_CHANGE]: (state: LayoutState, action: LocationChangeAction): LayoutState => {
    if (action.payload.action !== 'PUSH') {
      return state;
    }
    return {
      ...state,
      isFirstLoadedPage: undefined
    };
  }
};
// ------------------------------------
// Reducer
// ------------------------------------
export const initialLayoutState: LayoutState = {
  showContactsModal: false,
  sendingMessageAwait: false,
  pageHasFocus: true,
  appOnline: true,
  collapsed: false,
  isFirstLoadedPage: true
};

const layoutReducer: Reducer<LayoutState, LayoutAction> = (state = initialLayoutState, action) => {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
};

export default layoutReducer;
