import {useCallback, useContext} from 'react';

import {EditEntryActionsContext} from '../shared/contexts/entry/EditEntryContext';
import {closeForm, exitSelectionMode} from '../shared/contexts/entry/actions';
import {useSearchingInODContext} from '../Sidebar/DictionaryArticle/contexts/searchingInODContext';

export const useCloseSidebarPanels = () => {
  const {setSearchOpen} = useSearchingInODContext();
  const {dispatch: sidebarDispatch} = useContext(EditEntryActionsContext);

  const closeSidebarPanels = useCallback(() => {
    if (setSearchOpen) {
      setSearchOpen(false);
      sidebarDispatch(closeForm());
      sidebarDispatch(exitSelectionMode());
    }
  }, [setSearchOpen, sidebarDispatch]);

  return {closeSidebarPanels};
};
