import React from 'react';
import ReactSpinner from 'react-spinner';
import './react-spinner.scss';

interface SpinnerProps {
  size: number;
  height?: number;
}

export default class Spinner extends React.Component<SpinnerProps, {}> {
  public render(): JSX.Element {
    return (
      <div
        className="spinner-container"
        style={{
          width: this.props.size + 'px',
          height: (this.props.height || this.props.size) + 'px'
        }}
      >
        <ReactSpinner />
      </div>
    );
  }
}
