import React, {memo} from 'react';
import classNames from 'classnames';

import SimpleLoadingOverlay from 'components/loaders/overlay/SimpleLoadingOverlay';
import {letterMatcher, wordFilter, wordSplitter} from 'config/static';

import {ArticleHeader} from './components/ArticleHeader/ArticleHeader';
import {LexicalEntry} from './components/LexicalEntry/LexicalEntry';
import {CrossLinks} from './components/CrossLinks/CrossLinks';
import {WordNotFound} from './components/WordNotFound/WordNotFound';
import {ScrambledList} from './components/ScrambledList/ScrambledList';
import {useAddWordToDictionary} from './hooks/useAddWordToDictionary';
import {useDeleteWordFromDictionary} from './hooks/useDeleteWordFromDictionary';
import {type SearchData} from './interfaces';
import {useSearchingInODContext} from './contexts/searchingInODContext';
import {ErrorModalContextProvider} from './contexts/errorModalContext';

import './DictionaryArticle.scss';

interface Props {
  isSidebar?: boolean;
  dictionaryIsReadonly: boolean;
}

export const DictionaryArticle: React.FC<Props> = memo(
  ({isSidebar = false, dictionaryIsReadonly}) => {
    const {
      isLoading,
      textArticle: dictionaryArticle,
      isScrambledWordLoading,
      currentScrambledWord,
      scrambledArticles,
      textArticlesStory,
      textArticlesCache,
      includedTranslations,
      currentWord,
      setIncludedTranslations
    } = useSearchingInODContext();

    const isGetArticleFromStory =
      textArticlesStory.currencyLocationKey &&
      !isSidebar &&
      textArticlesCache.getTextArticleFromCache(
        textArticlesStory.getStoryItem(textArticlesStory.currencyLocationKey)
      );

    const textArticle =
      textArticlesStory.currencyLocationKey && isGetArticleFromStory
        ? textArticlesCache.getTextArticleFromCache(
            textArticlesStory.getStoryItem(textArticlesStory.currencyLocationKey)
          )
        : dictionaryArticle;

    const {
      addWord,
      addChangedWord,
      isAddFromErrorModal,
      setIsAddFromErrorModal,
      notChangedTranslation,
      setNotChangedTranslation
    } = useAddWordToDictionary(isSidebar);

    const {deleteWord} = useDeleteWordFromDictionary({
      includedTranslations,
      isSidebar,
      setIncludedTranslations
    });

    const isMainArticleNotFound = !textArticle && (!scrambledArticles || !currentScrambledWord);
    const isScrambledArticleNotFound =
      !isMainArticleNotFound && !!currentScrambledWord && !scrambledArticles[currentScrambledWord];

    const scrambledWords = currentWord
      .split(wordSplitter)
      .filter(part => wordFilter.test(part) && letterMatcher.test(part));

    const hasFuzzyResult = !!textArticle?.fuzzy;

    if (isLoading || isScrambledWordLoading) return <SimpleLoadingOverlay />;

    if (isMainArticleNotFound || isScrambledArticleNotFound || hasFuzzyResult)
      return (
        <WordNotFound
          word={!isScrambledArticleNotFound ? currentWord : currentScrambledWord}
          scrambledWord={!textArticle ? scrambledWords : []}
          currentScrambledWord={currentScrambledWord}
          fuzzy={textArticle?.fuzzy}
          isSidebar={isSidebar}
          isScrambledArticleNotFound={isScrambledArticleNotFound}
        />
      );

    const isScrambled = !textArticle;

    const usingSearchData = !isScrambled
      ? textArticle
      : (scrambledArticles[currentScrambledWord as string] as SearchData);

    return (
      <ErrorModalContextProvider
        submit={addChangedWord}
        isAddFromErrorModal={isAddFromErrorModal}
        setIsAddFromErrorModal={setIsAddFromErrorModal}
        notChangedTranslation={notChangedTranslation}
        setNotChangedTranslation={setNotChangedTranslation}
      >
        <div className={classNames('dictionary-article', {'is-sidebar': isSidebar})}>
          {!!currentScrambledWord && !textArticle && (
            <ScrambledList
              scrambledWord={!textArticle ? scrambledWords : []}
              currentScrambleWord={currentScrambledWord}
            />
          )}
          <ArticleHeader
            word={usingSearchData.word}
            pronunciation={usingSearchData.pronunciation}
            isSidebar={isSidebar}
          />
          <div className="dictionary-article__body">
            {usingSearchData?.lexicalEntries?.map((lexicalEntry, index) => (
              <LexicalEntry
                key={index}
                lexicalEntry={lexicalEntry}
                includedTranslations={includedTranslations}
                isDefaultCollapse={index > 0}
                dictionaryIsReadonly={dictionaryIsReadonly}
                addWord={addWord}
                deleteWord={deleteWord}
                isSidebar={isSidebar}
              />
            ))}
          </div>

          {usingSearchData.crossReferences && (
            <CrossLinks crossReferences={usingSearchData.crossReferences} />
          )}
        </div>
      </ErrorModalContextProvider>
    );
  }
);
