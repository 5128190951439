import React, {type FC, useMemo} from 'react';
import {type Store} from 'redux';
import {createBrowserRouter, createRoutesFromElements, RouterProvider} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

import {useInitEmoji} from '../components/Chat/components/EmojiButton';
import {useTouchEmulator} from '../components/TouchEmulator';
import {useDebugWebview} from '../hooks/webview/useDebugWebview';
import {type AppState} from '../store/interface';
import {DNDProvider} from './DNDProvider';
import {slateDevToolsEnabled, SlateDevToolsLoadable} from '../components/Slate/utils/slateDevTools';
import Provider from '../i18n/redux-intl-provider';
import AppRoutes from '../routes';
import {connectRouter} from '../store/redux-router';
import '@englex/slate-dev-tools/lib/SlateDevTools.css';

interface Props {
  store: Store<AppState>;
}

export const App: FC<Props> = ({store}) => {
  useInitEmoji();
  useTouchEmulator();
  useDebugWebview();

  const browserRouter = useMemo(() => {
    const router = createBrowserRouter(createRoutesFromElements(AppRoutes()));
    return connectRouter(store, router);
  }, [store]);

  return (
    <Provider store={store}>
      <SlateDevToolsLoadable>
        {({default: SlateDevToolsProvider}) => (
          <SlateDevToolsProvider enabled={slateDevToolsEnabled()}>
            <div className="app">
              <DNDProvider>
                <RouterProvider router={browserRouter} />
              </DNDProvider>
              <ReduxToastr timeOut={0} newestOnTop={false} position="top-right" />
            </div>
          </SlateDevToolsProvider>
        )}
      </SlateDevToolsLoadable>
    </Provider>
  );
};
