import React, {Component} from 'react';
import {Form, type FormikBag, type FormikProps, withFormik} from 'formik';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import Button from 'react-bootstrap/lib/Button';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Modal from 'react-bootstrap/lib/Modal';
import * as Yup from 'yup';

import Icon from 'components/Icon';
import Loader from 'components/Loader';

import './EditTitleModal.scss';

interface Values {
  title: string;
}

interface Props {
  editedTitle?: string;
  label: string;
  maxLength?: number;
  maxLengthErrorMessage: string;
  placeholder: string;
  show: boolean;
  submitTitle?: string;
  submitting: boolean;
  title: string;
  requiredErrorMessage: string;
  handleSubmit(title: string): void;
  onHide(): void;
}

type AllProps = Props & FormikProps<Values>;

class EditTitleModal extends Component<AllProps> {
  public static maxTitleLength = 256;

  private get submitButtonClasses() {
    const {show, submitting} = this.props;
    return classNames({submitting, 'hidden-text': !show});
  }

  public componentDidUpdate(prevProps: AllProps) {
    if (!prevProps.show && this.props.show) {
      this.props.resetForm();
    }
  }

  public render() {
    const {
      dirty,
      errors,
      handleChange,
      label,
      onHide,
      placeholder,
      show,
      submitTitle,
      submitting,
      title,
      touched,
      values
    } = this.props;
    return (
      <Modal backdrop="static" className="edit-title-modal" onHide={onHide} show={show}>
        <Modal.Header>
          <Modal.Title>
            <span>{title}</span>
          </Modal.Title>
          <Button onClick={this.onHide} className="btn-xs">
            <Icon name="pc-multiply" />
          </Button>
        </Modal.Header>
        <Form>
          <Modal.Body>
            <FormGroup
              validationState={errors.title && (touched.title || dirty) ? 'error' : undefined}
            >
              <ControlLabel>
                {label}
                {': '}
              </ControlLabel>
              <FormControl
                autoComplete="off"
                autoFocus={true}
                name="title"
                onChange={handleChange}
                placeholder={placeholder}
                value={values.title}
              />
              <div className="help-block error">{errors.title}</div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button
              bsStyle="default"
              bsSize="sm"
              className="btn-transparent"
              onClick={this.onHide}
              disabled={submitting}
            >
              <FormattedMessage id="Common.Cancel" />
            </Button>
            <Button
              bsStyle="primary"
              bsSize="sm"
              disabled={submitting}
              type="submit"
              className={this.submitButtonClasses}
            >
              {submitting && (
                <div className="loader-button-positioning-helper">
                  <Loader shouldRender={true} />
                </div>
              )}
              {submitTitle ?? <FormattedMessage id="Common.Save" />}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    );
  }

  private onHide = () => this.props.onHide();
}

const handleSubmit = (values: Values, {props}: FormikBag<Props, Values>) => {
  props.handleSubmit(values.title);
};

const validationSchema = ({maxLength, maxLengthErrorMessage, requiredErrorMessage}: AllProps) =>
  Yup.object().shape({
    title: Yup.string()
      .trim()
      .required(requiredErrorMessage)
      .max(maxLength || EditTitleModal.maxTitleLength, maxLengthErrorMessage)
  });

const mapPropsToValues = ({editedTitle}: Props): Values => ({title: editedTitle || ''});

export default withFormik({
  handleSubmit,
  mapPropsToValues,
  validationSchema,
  enableReinitialize: true,
  validateOnChange: true
})(EditTitleModal);
