import {PaintTool, ThicknessPaint, type ToolsPaint} from '@englex/paint-react';
import produce from 'immer';
import {type LineAttrs, type ShapeAttrs} from '@englex/paint';

import {type PaintThicknessPreset, type ThicknessName, type ThicknessPreset} from './interface';
import {ThicknessPresetPaint} from './ThicknessPresetPaint';

interface Options {
  defaultName?: ThicknessName;
  preset?: ThicknessPreset;
  highlightPreset?: ThicknessPreset;
  toolAttrs?: (paint: ToolsPaint & ThicknessPresetPaint, t?: PaintTool) => ShapeAttrs;
}

const baseThickness = 3;

const defaultMap: ThicknessPreset = {
  thin: 1,
  medium: baseThickness,
  thick: baseThickness * 2,
  xthick: baseThickness * 4
};

const highlightMap: ThicknessPreset = {
  thin: baseThickness * 3 - 1,
  medium: baseThickness * 5 + 1,
  thick: baseThickness * 8,
  xthick: baseThickness * 11 - 1
};

const presetAttrs = (paint: ToolsPaint & ThicknessPresetPaint, t?: PaintTool): ShapeAttrs => {
  const tool = t || paint.tools.active;
  const thicknessName = paint.thickness.preset.active;
  const isHighlight = tool === PaintTool.Highlighter;
  const thickness = ThicknessPresetPaint.toThickness(
    thicknessName,
    isHighlight ? paint.thickness.preset.highlightMap : paint.thickness.preset.defaultMap
  );

  if (!thickness) return {};

  switch (tool) {
    case PaintTool.Arrow: {
      return {
        strokeWidth: thickness,
        pointerLength: ThicknessPaint.getArrowAnchorLength(paint, thickness),
        pointerWidth: ThicknessPaint.getArrowAnchorWidth(paint, thickness)
      } as LineAttrs;
    }
    default:
      return {strokeWidth: thickness};
  }
};

export const withThicknessPreset =
  ({
    defaultName = 'medium',
    preset = defaultMap,
    highlightPreset = highlightMap,
    toolAttrs = presetAttrs
  }: Options = {}) =>
  <P extends ThicknessPaint & ToolsPaint>(paint: P): P & PaintThicknessPreset => {
    const p = paint as P & PaintThicknessPreset;

    const getToolAttrs = p.getToolAttrs;

    p.thickness = produce(p.thickness, thickness => {
      thickness.active = undefined;
      thickness.preset = {
        active: defaultName,
        defaultMap: preset,
        highlightMap: highlightPreset
      };
    });

    p.getToolAttrs = (t?: PaintTool) => {
      const attrs = getToolAttrs(t);
      const thicknessName = p.thickness.preset.active;
      if (!thicknessName) return attrs;

      return {...attrs, ...toolAttrs?.(p)};
    };

    return p;
  };
