import React, {useCallback, useEffect, useRef, useState} from 'react';
import FormControl from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import DropdownMenu from 'react-bootstrap/lib/DropdownMenu';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import isHotkey from 'is-hotkey';
import {FontSizePaint, usePaint, withPaint, ReactPaint} from '@englex/paint-react';

import {useFontSize} from './hooks';

import './FontSizePicker.scss';

interface Props {
  min?: number;
  max?: number;
  preset?: number[];
}
export const FontSizePicker = withPaint(
  ({preset = [4, 6, 8, 10, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72]}: Props) => {
    const inputRef = useRef<HTMLInputElement | null>(null);
    const paint = usePaint<FontSizePaint>();

    const {fontSize, min, max, isActive, isDisabled, selectedNode, isSelectedText} = useFontSize();

    const [val, setVal] = useState<string>(fontSize ? fontSize.toString() : '');
    const [showMenu, setShowMenu] = useState<boolean>(false);

    const ensureValue = useCallback(
      (value: number) => (Number(value) ? Math.min(Math.max(+value, min), max) : min),
      [max, min]
    );
    const tabIndex = isDisabled ? -1 : undefined;

    const noOp = useCallback(e => {
      e.stopPropagation();
      e.preventDefault();
    }, []);

    const onSelect = useCallback(
      (e: unknown) => {
        const fontSize = e as number;
        const value = ensureValue(fontSize).toString();
        setVal(value);
        if (inputRef.current) {
          inputRef.current.value = value;
          ReactPaint.focus(paint); // TODO: review
        }
      },
      [ensureValue, paint]
    );

    const saveValue = useCallback(
      (value: number) => {
        setVal(value.toString());
        FontSizePaint.setFontSize(paint, value);
      },
      [paint]
    );

    useEffect(() => {
      setVal(fontSize ? fontSize.toString() : '');
    }, [fontSize, selectedNode]);

    useEffect(() => {
      if (!isSelectedText) {
        setShowMenu(false);
      }
    }, [isSelectedText]);

    return (
      <InputGroup className={classNames('font-size-picker')}>
        <InputGroup.Button>
          <Button
            bsSize="xs"
            className={classNames({disabled: isDisabled})}
            tabIndex={tabIndex}
            onClick={useCallback(() => {
              if (isDisabled) return;
              const value = ensureValue(Number(val) - 1);
              saveValue(value);
            }, [ensureValue, isDisabled, saveValue, val])}
          >
            –
          </Button>
        </InputGroup.Button>
        <FormControl
          inputRef={(input: HTMLInputElement | null) => {
            inputRef.current = input;
          }}
          type="number"
          className={classNames({disabled: isDisabled})}
          tabIndex={tabIndex}
          readOnly={isDisabled}
          value={val}
          min={min}
          max={max}
          onKeyDown={useCallback(
            e => {
              e.stopPropagation();

              if (isHotkey('esc', e)) {
                e.preventDefault();
                ReactPaint.focus(paint);
              }

              if (isHotkey('enter', e)) {
                e.preventDefault();
                const input = inputRef.current;

                if (input) {
                  saveValue(ensureValue(Number(input.value)));
                  requestAnimationFrame(() => input.select());
                }
              }
            },
            [ensureValue, paint, saveValue]
          )}
          onClick={useCallback(() => {
            if (isDisabled) return;
            inputRef.current?.focus({preventScroll: true});
          }, [isDisabled])}
          onFocus={useCallback(() => {
            if (isDisabled) return;
            setShowMenu(true);
            requestAnimationFrame(() => inputRef.current?.select());
          }, [isDisabled])}
          onBlur={useCallback(
            e => {
              setShowMenu(false);
              const {
                target: {value}
              } = e;
              const newValue = ensureValue(value);
              saveValue(newValue);
            },
            [ensureValue, saveValue]
          )}
          onChange={useCallback(e => {
            setVal(e.target.value);
          }, [])}
        />
        {preset?.length && isActive && showMenu && (
          <DropdownMenu>
            {preset.map((fontSize: number, i: number) => (
              <MenuItem
                active={fontSize === Number(val)}
                key={i}
                eventKey={fontSize}
                onMouseDown={noOp}
                onSelect={onSelect}
              >
                {fontSize}
              </MenuItem>
            ))}
          </DropdownMenu>
        )}
        <InputGroup.Button>
          <Button
            bsSize="xs"
            className={classNames({disabled: isDisabled})}
            tabIndex={tabIndex}
            onClick={useCallback(() => {
              if (isDisabled) return;
              const value = ensureValue(Number(val) + 1);
              saveValue(value);
            }, [ensureValue, isDisabled, saveValue, val])}
          >
            +
          </Button>
        </InputGroup.Button>
      </InputGroup>
    );
  },
  FontSizePaint.is
);
