import {normalizeOrientation} from '@englex/paint';

import {pdfjs} from 'config/react-pdf';

function canvasToURL(canvas: HTMLCanvasElement): Promise<string> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(blob => {
      if (blob) {
        const imgUrl = URL.createObjectURL(blob);

        canvas.width = 0;
        canvas.height = 0;

        canvas.remove();

        return resolve(imgUrl);
      }

      reject('No canvas blob');
    });
  });
}

export async function renderPage(docUrl: string, pageNumber: number) {
  const document = await pdfjs.getDocument(docUrl).promise;

  const page = await document.getPage(pageNumber);

  const orientation = normalizeOrientation(page.rotate);

  const viewport = page.getViewport({scale: 1, rotation: 0});

  const size = {width: viewport.width, height: viewport.height};

  const deviceViewport = page.getViewport({scale: window.devicePixelRatio, rotation: 0});

  const canvas = window.document.createElement('canvas');

  canvas.width = window.devicePixelRatio * size.width;
  canvas.height = window.devicePixelRatio * size.height;

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;

  const renderContext = {
    canvasContext: context,
    viewport: deviceViewport
  };

  await page.render(renderContext).promise;

  const url = await canvasToURL(canvas);

  return {url, orientation, size};
}
