import {type Dispatch, type ReactNode} from 'react';
import {type Action} from 'redux';

import {type ImageV2} from 'store/interface';

export interface ViewerContextShape {
  state: ViewerState;
  dispatch: Dispatch<Action>;
}

export interface ResourcesContextShape {
  id: Id;
}

export type Id = string;

export enum Type {
  IMAGE = 'image',
  YT = 'YT'
}

type Resources = {
  [Type.IMAGE]?: Resource[];
  [Type.YT]?: Resource[];
};

export type Resource = {id: Id; urls?: string[]};

export interface ProviderShape {
  id: Id;
  node: Element | null;
  resources: Resources;
}

export interface ViewerState {
  activeResourceId?: Id;
  activeProviderId?: Id;
  providers: ProviderShape[];
  resourceType?: Type;
}

export interface ProviderAction extends Action {
  providerId: Id;
}

export interface ResourceAction extends ProviderAction {
  resourceId: Id;
  resourceType: Type;
}

export interface LoadResourceAction extends ProviderAction {
  data: Resource;
  resourceType: Type;
}

export interface LoadResourcesAction extends ProviderAction {
  data: Resource[];
  resourceType: Type;
}

export interface RegisterProviderAction extends ProviderAction {
  node: Element | null;
}

type InViewerChild = (onLoaded?: (data: ImageV2) => void, activate?: () => void) => ReactNode;

export interface ResourcesProps {
  id: Id;
  node: Element | null;
}

export interface InViewerProps {
  children: InViewerChild;
  id: Id;
  type: Type;
}

export interface SlideProps {
  activeResourceId: Id;
  expanded: boolean;
  isMobile: boolean;
  resource: Resource;
  resourceType: Type;
}

interface ResourceSlide {
  id: Id;
  loaded: boolean;
  setLoaded(loaded: boolean): void;
  setError(error: boolean): void;
  urls?: string[];
}

export type ImageSlide = Required<ResourceSlide>;
export type YTSlide = Exclude<ResourceSlide, string[]> & {activeResourceId: Id};
