import React from 'react';

interface Props {
  isResizing: boolean;
  shouldRender?: boolean;
  startResizing: (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent) => void;
}

export default class ResizeTrigger extends React.PureComponent<Props, {}> {
  public render() {
    const {isResizing, startResizing, shouldRender} = this.props;
    if (!shouldRender) {
      return null;
    }
    return (
      <div onMouseDown={startResizing} onTouchStart={startResizing} className="resize-trigger">
        <div className="background" />
        <div className={`stripes ${isResizing ? 'active' : ''}`}>
          <div className="stripe" />
          <div className="stripe" />
        </div>
      </div>
    );
  }
}
