import {BrowserName} from 'webRTC/interface';
import {type Locale} from 'store/interface';

export const contacts = {
  telephone1: '8 (800) 775-47-81',
  telephone2: '+7 (495) 150-77-40',
  email: 'study@englex.ru',
  telegram: '@englexschool',
  whatsapp: '+7 (495) 150-77-40',
  englexManagers: 'Менеджеры Инглекс',
  englexMail: 'englex.ru'
};

export const downloadBrowserLink = (locale: Locale) => (browserName: BrowserName) => {
  switch (browserName) {
    case BrowserName.CHROME:
      return locale === 'en'
        ? 'https://www.google.com/chrome/'
        : 'https://www.google.com/intl/ru/chrome/';
    case BrowserName.OPERA:
      return `http://www.opera.com/${locale}`;
    case BrowserName.YANDEX:
      return locale === 'ru' ? 'https://getyabrowser.com/ru/' : 'https://browser.yandex.com/';
    case BrowserName.SAFARI:
      return locale === 'ru'
        ? 'https://support.apple.com/ru-ru/HT204416'
        : 'https://support.apple.com/en-us/HT204416';
    default:
      return '#';
  }
};

export const browsersMediaInstructions = {
  ru: {
    Chrome: 'https://support.google.com/chrome/answer/2693767?hl=ru',
    Opera: 'https://help.opera.com/ru/latest/web-preferences/',
    'Yandex Browser': 'https://yandex.ru/support/browser/security/permissions.html',
    Safari: 'https://support.apple.com/ru-ru/guide/safari/ibrw7f78f7fe/mac'
  },
  en: {
    Chrome: 'https://support.google.com/chrome/answer/2693767?hl=en',
    Opera: 'https://help.opera.com/en/latest/web-preferences/',
    'Yandex Browser': 'https://yandex.ru/support/browser/security/permissions.html?lang=en',
    Safari: 'https://support.apple.com/en-gb/guide/safari/ibrw7f78f7fe/mac'
  }
};

export enum BootstrapBreakpoint {
  XS = 480,
  SM = 768,
  MD = 992,
  LG = 1200
}

export const messageReadingTime: number = 500;

export let openTogetherAwaitTime: number = 5000;

if (import.meta.env.MODE === 'test') {
  openTogetherAwaitTime = 300;
}

export const sessionExpiredReason = 'JWT expired';

export const maxChatMessageLength: number = 8000;

export const maxSearchStringLength: number = 100;

export const searchTimeout: number = 500;

export const chatTypingAdvanceTimeout = 500;

export const chatTypingAwaitTimeout: number = 3000;

export const megaByteMultiplier = 1048576;

export const maxDocSize: number = 20 * megaByteMultiplier;

export const maxSoundSize: number = 20 * megaByteMultiplier;

export const maxSoundSizeForMethodist: number = 100 * megaByteMultiplier;

export const maxImageSize: number = 10 * megaByteMultiplier;

export const imageMaxResolution: number = 5000;

export const videoBaseWidth = 326;

export const videoBaseHeight = videoBaseWidth * 0.5625;

export const defaultSmallVideoRatio = 0.26;

export const getCallStatsInterval: number = 0;

export const getUserMediaAllowedTime: number = 15000;

export const iceSyntheticFailedTimeout: number = 5000;

export const connectionCheckerInterval: number = 500;

export const rtcThrottleToggleVideoTimeout = 1000;

export const messageUpdateIsAllowedTime: number = 86400000; // 1 day in ms

export const notificationInTitleInterval: number = 1000;

export const maxDocsPinCount: number = 10;

export const xPlayerPortal = 'x-player-audio-player-portal';

export const xPlayerPortalWidgetPreview = 'x-player-audio-player-portal-widget-preview';

const speedtestConfig = {
  url_dl: '/speedtest/20MB.file',
  url_ul: '/speedtest/empty.file',
  url_ping: '/speedtest/empty.file',
  time_dl: '2',
  time_ul: '1',
  count_ping: '20'
};

export function getSpeedtestConfig(host: string) {
  return {
    ...speedtestConfig,
    url_dl: host + speedtestConfig.url_dl,
    url_ul: host + speedtestConfig.url_ul,
    url_ping: host + speedtestConfig.url_ping
  };
}

export const intervalBetweenSpeedTests = 10000;

export const speedTestLength = 11000;

export const checkDurationInterval = 30;

export const visibilityInterval = 3000;

// these tables are used to calculate overall connection quality
// position of interval in array which consist current value indicates connection quality as number from 0 to 4

export type QualityTable = Array<{min?: number; max?: number}>;

export const speedTable: QualityTable = [
  {
    max: 0.5
  },
  {
    min: 0.5,
    max: 1
  },
  {
    min: 1,
    max: 3
  },
  {
    min: 3
  }
];

export const pingTable: QualityTable = [
  {
    min: 300
  },
  {
    min: 200,
    max: 300
  },
  {
    min: 100,
    max: 200
  },
  {
    max: 100
  }
];

export const jitterTable: QualityTable = [
  {
    min: 60
  },
  {
    min: 40,
    max: 60
  },
  {
    min: 20,
    max: 40
  },
  {
    max: 20
  }
];

export const videoSizeConstraints = {
  width: {
    ideal: 640
  },
  height: {
    ideal: 360
  }
};

const uuid4Pattern = '[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}';

export const uuid4RegExp = new RegExp(uuid4Pattern);

export const appOfflineModalTimeout = 10000;

export const exerciseTitleMaxLength = 250;

export const widgetTaskMaxLength = 256;

export const exerciseExcerptLength = widgetTaskMaxLength;

export const wordTemplateLiteral = '·';

export const coursebookLibraryPageSize = 10;

export const wordSplitter = /([‹›«»"“„‟”…_[\](){},:;.?!\s]+)/gi; // splits text by sequences of wordSplitter chars
export const wordFilter = /([^\W_]+|[&'’\-/а-яА-ЯЁё]+)+/; // filters out wordSplitter sequences
export const letterMatcher = /[\wа-яА-Я&]+/; // filters out parts w/o any letters or '&'

export const spellingLetterMatcher = /[\w`'´а-яёА-ЯЁ‘’′]+/;
export const spellingLetterMatcherForWordsType = /[\w`·'´а-яёА-ЯЁ‘’′-]+/;
export const spellingSeparators = /[\s\n/]/;

export const maxFilesSearchResultNumber = 99;

export const openingUrlForStudentTimeout = 5000;
export const openingUrlForTeacherTimeout = 15000;
export const publishingPointerTimeout = 5000;

export const pointerAnimationTimeout = 4000;

export const ACTIVE_UNITS_LIMIT = 5;
export const dictionaryPageSize = 20;
export const weekInMS = 6.048e8;

export const mediaSourceTitleMaxLength = 255;
export const requestBrowserNotificationPermissionsTimeout: number = 5000;

export const widgetDisplayAsButtonIconDefault = 'book';
