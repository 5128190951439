import React from 'react';
import {type Editor as ReactEditor, type RenderBlockProps} from '@englex/slate-react';
import {type Editor, type Block, type Next} from '@englex/slate';
import classNames from 'classnames';

import {isBlockOfType} from '../../../utils';
import {SlateBlock} from '../../../interface';
import {type RendererPlugin} from '../../interface';
import {getActiveMarksClassNamesForNode} from '../../utils';

class ListItem implements RendererPlugin {
  public block = SlateBlock.LIST_ITEM;

  public renderBlock = (
    {node, attributes, children, parent}: RenderBlockProps,
    editor: ReactEditor & Editor,
    next: Next
  ) => {
    if (!isBlockOfType(node, this.block)) {
      return next();
    }
    const firstBlockStyle: Block | null = node.nodes ? (node.nodes.first() as Block) : null;

    const {textAlign = undefined, justifyContent = undefined} =
      firstBlockStyle && firstBlockStyle.data && firstBlockStyle.data.has('style')
        ? firstBlockStyle.data.get('style')
        : {};

    // markClasses are applied so that bullet point or number for each item has some style if all text in this item has this style
    const markClasses = getActiveMarksClassNamesForNode(editor, node.nodes.first(), parent);
    const classes = classNames(
      {
        [`align-${textAlign}`]: !!textAlign,
        [`justify-${justifyContent}`]: !!justifyContent
      },
      markClasses
    );
    return (
      <li {...attributes} className={classes}>
        {children}
      </li>
    );
  };
}

export default ListItem;
