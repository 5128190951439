import {useCallback} from 'react';

interface Options {
  leading?: boolean;
  trailing?: boolean;
}

type TargetFunction = (...args: unknown[]) => void;

export const debounce = <F extends TargetFunction>(
  func: F,
  delay: number,
  {leading = true, trailing = true}: Options = {}
) => {
  let timerId: NodeJS.Timeout | null = null;
  let calls = 0;
  return (...args: Parameters<F>): void => {
    if (timerId !== null) {
      clearTimeout(timerId);
      timerId = null;
    }
    timerId = setTimeout(() => {
      if (timerId !== null) {
        clearTimeout(timerId);
        timerId = null;
        if ((calls > 1 && trailing) || (calls === 1 && !leading)) {
          func(...args);
        }
        calls = 0;
      }
    }, delay);

    calls === 0 && leading && func(...args);
    calls++;
  };
};

export function useDebounceCallback<F extends TargetFunction>(
  func: F,
  deps: unknown[], // TODO: works only w/o deps for now, should be fixed
  ms: number,
  options?: Options
) {
  // TODO: deps change will create the new debounced function for now on every call, so internal states 'calls' number and 'timerId' will lost
  //   and function returned by this hook will behave different that debounce function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(debounce(func, ms, options), deps);
}
