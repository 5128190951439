import React from 'react';

import './ActiveColor.scss';

interface Props {
  value?: string;
}

export const ActiveColor: React.FC<Props> = ({value = 'transparent'}) => {
  return (
    <div className="active-color i">
      <div className="active-color__value" style={{backgroundColor: value}} />
    </div>
  );
};
