import type React from 'react';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {push} from 'store/router';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {setSearch} from 'store/dictionary/actions';
import {DictionarySearchMode} from 'common/enums';

import {requestSearchInODByIdEn} from '../actions';
import {type EnglishLexicalEntryType, type EnglishSearchData, type SearchData} from '../interfaces';
import {type TextArticlesCache} from './useTextArticlesCash';
import {type TextArticlesStory} from './useTextArticlesStory';
import {groupLexicalEntries} from '../utils/groupLexicalEntries';

interface Props {
  isSidebar?: boolean;
  textArticlesCache: TextArticlesCache;
  textArticlesStory: TextArticlesStory;
  currentSearchWord: string;
  setCurrentSearchWord: React.Dispatch<React.SetStateAction<string>>;
  setTextArticle: (value: React.SetStateAction<SearchData | undefined>) => void;
  setSearchFieldValue: (str: string) => void;
  changeSearchValue: (text: string) => void;
}

export const useSearchInEnODByIdApiRequest = ({
  isSidebar,
  setTextArticle,
  textArticlesCache,
  textArticlesStory,
  currentSearchWord,
  setCurrentSearchWord,
  setSearchFieldValue,
  changeSearchValue
}: Props) => {
  const dispatch = useDispatch();
  const {key} = useLocation();

  const {set: setTextArticlesStory} = textArticlesStory;

  const searchInODByIdSuccessHandler = (data: EnglishSearchData) => {
    const groupedData = groupLexicalEntries(data, DictionarySearchMode.EN) as EnglishSearchData;
    const isDataEmpty =
      (!!groupedData?.crossReferences && groupedData.crossReferences?.length > 0) ||
      groupedData?.lexicalEntries?.some((lexicalEntry: EnglishLexicalEntryType) =>
        lexicalEntry.entries?.some(entry =>
          entry?.senses?.some(sense => !!sense.definitions && sense.definitions.length > 0)
        )
      );

    const article = isDataEmpty ? groupedData : undefined;

    if (article?.word) {
      setSearchFieldValue(article?.word);
      changeSearchValue(article?.word);
      dispatch(setSearch(article?.word));
      setCurrentSearchWord(article?.word);
    }

    setTextArticle(article);

    if (!!article && !isSidebar) {
      textArticlesCache.setTextArticleToCache(currentSearchWord, article);
      if (key) setTextArticlesStory(key, article.word);
    }
  };

  const {isLoading: isLoadingByIdEn, apiRequest: apiRequestSearchInODById} = useApiRequest2<
    [string]
  >(requestSearchInODByIdEn, searchInODByIdSuccessHandler);

  const sendCrossLinkApiRequestEn = useCallback(
    (id: string, word: string) => {
      if (!isSidebar) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('search', word);

        dispatch(push({search: searchParams.toString()}));

        setCurrentSearchWord(word);
      }
      apiRequestSearchInODById(id);
    },
    [apiRequestSearchInODById, dispatch, isSidebar, setCurrentSearchWord]
  );

  return {sendCrossLinkApiRequestEn, isLoadingByIdEn};
};
