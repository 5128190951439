import {type Connection, type IConnectionOptions} from 'autobahn';
import {type Dispatch, type MiddlewareAPI} from 'redux';

import {type AppState} from 'store/interface';

import {type WampCallAction} from './interface';
export const FAIL_POSTFIX: string = '_FAIL';
export const SUCCESS_POSTFIX: string = '_SUCCESS';
export const UNSUBSCRIBE_POSTFIX: string = '_UNSUBSCRIBE';

export const CALL: 'call' = 'call';
export const SUBSCRIBE: 'subscribe' = 'subscribe';
export const UNSUBSCRIBE: 'unsubscribe' = 'unsubscribe';

export const defaultAutobahnClientName: AutobahnClientName = 'wamp';

export type AutobahnClientName = keyof AutobahnClients<Dispatch, AppState>;

export interface CurrentCall {
  action: WampCallAction<{}, {}>;
  cancel: () => void;
}

export interface AutobahnClient<D extends Dispatch, S> {
  connection: Connection;
  config: ConnectionOptions<D, S>;
  currentCalls: CurrentCall[];
}

export interface AutobahnClients<D extends Dispatch, S> {
  wamp: AutobahnClient<D, S>;
  autobahn: AutobahnClient<D, S>;
}

export type WampClients<T> = {[P in keyof T]?: T[P]};

export interface ConnectionOptions<D extends Dispatch, S> extends IConnectionOptions {
  readonly client: AutobahnClientName;
  readonly uriPrefix: string;
  readonly stateAuthId: (api: MiddlewareAPI<D, S>) => string;
  readonly stateToken: (api: MiddlewareAPI<D, S>) => string;
  readonly stateUrl?: (api: MiddlewareAPI<D, S>) => string;
  readonly uriMap?: UriPrefixMap;
  readonly renewToken?: boolean;
}

export interface UriPrefixMap {
  [key: string]: string;
}
