import {List, Map} from 'immutable';
import {type Block, type Text, Value} from '@englex/slate';

import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';

import {
  type WidgetMatchingAnswersJSON,
  MatchingType,
  type MatchingBaseProperties,
  type MatchingBaseJSON,
  type QuestionBlockMap
} from './interface';
import {type ChoicesMap, WidgetType} from '../../interface';
import {genKey} from '../../../../../components/Slate/utils';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';

const initMatchingExamples = (content: Value): ChoicesMap | undefined => {
  const firstQuestionItem: Block = content.document.getNode([0, 0]) as Block;
  if (!firstQuestionItem?.data.get('example')) return undefined;
  return Map(
    firstQuestionItem
      .getTexts()
      .delete(0)
      .map((t: Text) => [genKey(), {value: t.text}])
  );
};

class MatchingBaseRecord<
    A extends Map<string, List<string>> | undefined,
    V extends Map<string, List<string>> | List<QuestionBlockMap> | undefined,
    C extends ChoicesMap | undefined
  >
  extends FormattedTextRecord<A, V, C>
  implements MatchingBaseProperties<A, V, C>
{
  public declare readonly answers: A;
  public declare readonly values: V;
  public declare readonly matchingExamples?: ChoicesMap;
  public declare readonly matchingType: MatchingType;

  constructor(raw: MatchingBaseJSON) {
    super(raw);
    const content = Value.fromJSON(raw.content);
    this.initValues({
      content,
      answers: raw.answers ? this.valuesFromJSON(raw.answers) : Map(),
      choices: raw.choices ? Map(raw.choices) : undefined,
      matchingExamples: initMatchingExamples(content),
      matchingType: raw.matchingType || MatchingType.DEFAULT
    });
  }

  public toJSON(): MatchingBaseJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      content: this.content.toJSON()
    };
  }

  public get type() {
    return WidgetType.MATCHING;
  }

  public setAnswersFromJSON(values: WidgetMatchingAnswersJSON): this {
    return this.set('answers', this.valuesFromJSON(values));
  }

  public valuesFromJSON(values: WidgetMatchingAnswersJSON) {
    return Map(values).mapEntries((entry: string[]) => [entry[0], List(entry[1])]);
  }
}
decorate(MatchingBaseRecord, {
  answers: property(Map()),
  matchingExamples: property(),
  values: property(undefined),
  matchingType: property('')
});
record()(MatchingBaseRecord);
export default MatchingBaseRecord;
