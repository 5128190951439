import {Map} from 'immutable';
import {Value} from '@englex/slate';

import {type ExerciseInstanceCommentMap, type ExerciseInstanceComment} from 'store/interface';
import {type DataMap} from 'components/Slate/interface';

export const commentFactory = (c: ExerciseInstanceComment): DataMap<ExerciseInstanceCommentMap> => {
  const data = Map<
    keyof ExerciseInstanceCommentMap,
    ExerciseInstanceCommentMap[keyof ExerciseInstanceCommentMap]
  >();
  return data.withMutations(d => {
    d = d.set('id', c.id);
    d = d.set('exerciseInstanceId', c.exerciseInstanceId);
    d = d.set('createdById', c.createdById);
    d = d.set('createdBy', c.createdBy!);
    d = d.set('createdAt', c.createdAt);
    d = d.set('updatedAt', c.updatedAt);
    d = d.set('comment', Value.fromJSON(c.comment));
  }) as DataMap<ExerciseInstanceCommentMap>;
};
