import {useCallback, useEffect} from 'react';
import {type POrientation} from '@englex/paint';
import {type Size} from '@englex/paint-react';
import {addBreadcrumb} from '@sentry/react';

import {isIOS, isMobileWebView} from 'helpers/browser';
import {type AudioFile} from 'components/media/interface';
import {type Resource} from 'contexts/Viewer/interface';

const target = isIOS ? window : document;

export interface MobileOutlineItem {
  title: string;
  pageIndex: number;
  items: MobileOutlineItem[];
}

export enum WebViewMessageType {
  Ready = 'ready',
  Prev = 'prev',
  Next = 'next',
  Player = 'player',
  Train = 'train',
  UnitContents = 'unit-contents',
  SetPage = 'set-page',
  ImageFileInfo = 'image-file-info',
  CreateImageFromPDFPage = 'create-image-from-pdf-page',
  FailedCreatingImage = 'failed-creating-image',
  OpenResourceViewer = 'open-resource-viewer',
  Refetch = 'refetch'
}

export enum PlayerPlayStatus {
  Idle = 'idle',
  Loading = 'loading',
  Paused = 'paused',
  Playing = 'playing'
}

type ReadyPayload = {
  url: string;
  pageCount: number;
  outline: MobileOutlineItem[] | null;
};

interface PlayerPayload {
  audio?: AudioFile;
  playStatus?: PlayerPlayStatus;
}

interface TrainPayload {
  listId: string;
}

interface UnitContentsPayload {
  coursebookInstanceId: string;
  unitInstanceId: number;
  pageNumber: number;
}

interface SetPagePayload {
  pageNumber: number;
}

interface ImageFileInfoPayload {
  url: string;
  orientation: POrientation;
  size: Size;
}

interface CreateImageFromPDFPagePayload {
  docUrl: string;
  pageNumber: number;
}

interface OpenResourceViewerPayload {
  index: number;
  resources: Resource[];
}

interface ReadyData {
  type: WebViewMessageType.Ready;
  payload?: ReadyPayload;
}

interface PrevData {
  type: WebViewMessageType.Prev;
}

interface NextData {
  type: WebViewMessageType.Next;
}

interface PlayerData {
  type: WebViewMessageType.Player;
  payload: PlayerPayload;
}

interface TrainData {
  type: WebViewMessageType.Train;
  payload: TrainPayload;
}

interface UnitContentsData {
  type: WebViewMessageType.UnitContents;
  payload: UnitContentsPayload;
}
interface SetPageData {
  type: WebViewMessageType.SetPage;
  payload: SetPagePayload;
}

interface ImageFileInfoData {
  type: WebViewMessageType.ImageFileInfo;
  payload: ImageFileInfoPayload;
}

interface CreateImageFromPDFPageData {
  type: WebViewMessageType.CreateImageFromPDFPage;
  payload: CreateImageFromPDFPagePayload;
}

interface FailedCreatingImageData {
  type: WebViewMessageType.FailedCreatingImage;
}

interface OpenResourceViewerData {
  type: WebViewMessageType.OpenResourceViewer;
  payload: OpenResourceViewerPayload;
}

interface RefetchData {
  type: WebViewMessageType.Refetch;
}

export type WebViewMessageData =
  | ReadyData
  | PrevData
  | NextData
  | PlayerData
  | TrainData
  | SetPageData
  | UnitContentsData
  | ImageFileInfoData
  | CreateImageFromPDFPageData
  | FailedCreatingImageData
  | OpenResourceViewerData
  | RefetchData;

type Callback = (message: WebViewMessageData) => void;

function prepareWebviewMessage(data: WebViewMessageData) {
  switch (data?.type) {
    case WebViewMessageType.ImageFileInfo:
      const {type, payload} = data;

      return JSON.stringify({type, payload: {...payload, url: '[long string]'}});

    default:
      return JSON.stringify(data);
  }
}

function addSentryBreadcrumbEvent(category: string, message: WebViewMessageData) {
  addBreadcrumb({category, message: prepareWebviewMessage(message), level: 'info'});
}

export function useWebViewMessage(callback?: Callback, available = isMobileWebView()) {
  const onMessage = useCallback(
    (message: MessageEvent) => {
      try {
        const data = JSON.parse(message.data) as WebViewMessageData;

        callback?.(data);
        addSentryBreadcrumbEvent('webview-event ↓', data);
      } catch (error) {
        if (import.meta.env.MODE === 'development') {
          window.alert(error.message);
        }
      }
    },
    [callback]
  );

  useEffect(() => {
    if (!available) return;

    target.addEventListener('message', onMessage);

    return () => {
      target.removeEventListener('message', onMessage);
    };
  }, [available, onMessage]);

  return useCallback((message: WebViewMessageData) => {
    if (isMobileWebView() && window.ReactNativeWebView?.postMessage) {
      const preparedMessage = JSON.stringify(message);

      window.ReactNativeWebView.postMessage(preparedMessage);
      addSentryBreadcrumbEvent('webview-event ↑', message);
    }
  }, []);
}
