import {useCallback, useEffect, useRef, useState} from 'react';

import {
  type AxiosRequestAction,
  type AxiosRequestError,
  type AxiosResponseAction
} from 'services/axios/interface';

import {useAxiosDispatch} from '../redux/useAxiosDispatch';
import {makeCancellable} from '../../helpers/cancellablePromise';

export const useApiRequest = <D>(
  action: AxiosRequestAction | null,
  successHandler: (data: D) => void,
  failHandler?: (e: AxiosRequestError) => void
) => {
  const [isLoading, setIsLoading] = useState(!!action);
  const [isError, setIsError] = useState(false);
  const successHandlerRef = useRef<(data: D) => void | undefined>();
  successHandlerRef.current = successHandler;
  const failHandlerRef = useRef<(e: AxiosRequestError) => void | undefined>();
  failHandlerRef.current = failHandler;

  const dispatch = useAxiosDispatch();

  const apiRequestEffect = useCallback(() => {
    setIsError(false);
    setIsLoading(!!action);
    if (!action) {
      return;
    }
    const cancellablePromise = makeCancellable(
      dispatch<AxiosResponseAction<D>>(action),
      res => {
        successHandlerRef.current!(res.payload.data);
        setIsLoading(false);
      },
      (e: AxiosRequestError) => {
        if (import.meta.env.MODE === 'development') {
          console.error('Hook useApiRequest fetch error:', e); // eslint-disable-line no-console
        }
        setIsError(true);
        setIsLoading(false);
        if (failHandlerRef.current) {
          failHandlerRef.current(e);
        }
      }
    );

    return cancellablePromise.cancel;
  }, [action, dispatch, successHandlerRef, failHandlerRef]);
  useEffect(apiRequestEffect, [apiRequestEffect]);

  return {
    isLoading,
    isError,
    reload: apiRequestEffect
  };
};
