import {FAIL_POSTFIX} from 'services/wamp/actions/types';

const PATH: string = 'CLASSROOM/';
export const OPEN_PARTNERS_TAB: string = PATH + 'OPEN_PARTNERS_TAB';
export const CHANGE_PARTNERS_FILTER: string = PATH + 'CHANGE_PARTNERS_FILTER';
export const SET_CLASSROOM_NETWORK_ERROR: string = PATH + 'SET_CLASSROOM_NETWORK_ERROR';
export const REQUEST_COURSES: string = PATH + 'REQUEST_COURSES';
export const PUBLISH_UPDATE_MATERIALS: string = PATH + 'PUBLISH_UPDATE_MATERIALS';
export const SUBSCRIBE_COURSE_EVENTS: string = PATH + 'SUBSCRIBE_COURSE_EVENTS';
export const SUBSCRIBE_COURSE_EVENTS_FAIL: string = SUBSCRIBE_COURSE_EVENTS + FAIL_POSTFIX;
export const CLEAR_CLASSROOM: string = PATH + 'CLEAR_CLASSROOM';
export const PUBLISH_OPEN_MATERIALS: string = PATH + 'PUBLISH_OPEN_MATERIALS';
export const PUBLISH_OPENED_MATERIALS: string = PATH + 'PUBLISH_OPENED_MATERIALS';
export const TOGGLE_STUDENT_TEACHER_POPOVER: string = PATH + 'TOGGLE_STUDENT_TEACHER_POPOVER';
export const TOGGLE_COURSES_DROPDOWN: string = PATH + 'TOGGLE_COURSES_DROPDOWN';
export const SET_COURSES: string = PATH + 'SET_COURSES';
export const CLEAR_COURSES: string = PATH + 'CLEAR_COURSES';
export const OPEN_SOUND_WHEN_LOADED: string = PATH + 'OPEN_SOUND_WHEN_LOADED';
export const CLEAR_OPEN_SOUND_WHEN_LOADED: string = PATH + 'CLEAR_OPEN_SOUND_WHEN_LOADED';
export const SET_HAS_GRAMMAR: string = PATH + 'SET_HAS_GRAMMAR';
export const REQUEST_TEACHER_SETTINGS = PATH + 'REQUEST_TEACHER_SETTINGS';
export const SET_TEACHER_SETTINGS = PATH + 'SET_TEACHER_SETTINGS';
