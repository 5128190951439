import type React from 'react';

export function isMouseEvent(
  e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent
): e is MouseEvent {
  return !isTouchEvent(e);
}

function isTouchEvent(e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent) {
  return e && 'changedTouches' in e;
}

export function getClientPosition(
  e: React.MouseEvent | React.TouchEvent | MouseEvent | TouchEvent
) {
  if (e && 'changedTouches' in e) {
    return {
      clientX: e.changedTouches[0].clientX,
      clientY: e.changedTouches[0].clientY,
      pageX: e.changedTouches[0].pageX,
      pageY: e.changedTouches[0].pageY
    };
  }

  return {clientX: e.clientX, clientY: e.clientY, pageX: e.pageX, pageY: e.pageY};
}
