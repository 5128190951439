import {type IntlShape} from 'react-intl';
import {Map} from 'immutable';
import {type Block, Value, type ValueJSON, Editor} from '@englex/slate';
import * as yup from 'yup';

import {
  MultipleChoiceDefaultAnswers,
  MultipleChoiceElement,
  type MultipleChoiceJSON,
  type MultipleChoiceOptions
} from 'store/exercise/player/widgets/List/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import {WidgetTitle, type WidgetToJSONOptions, WidgetType} from '../../../player/interface';
import {type XMultipleChoiceAnswers, type XMultipleChoiceProperties} from './interface';
import {type MCAnswerBlock} from '../../../../../components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/interface';
import {SlateBlock, type DataMap} from '../../../../../components/Slate/interface';
import {isBlockOfType} from '../../../../../components/Slate/utils';
import genKey from '../../../../../components/Slate/utils/genKey';
import {addDataToBlock} from '../../../../../components/Slate/SlateEditor/plugins/utils';
import validationMessages from '../i18n';
import {
  answerBlocksNotEmpty,
  correctAnswerSelectedForEveryQuestion,
  minRequiredAnswers,
  questionLabelsNotEmpty,
  documentNotEmpty
} from '../validation';
import {
  multipleChoiceAnswersNumberDefault,
  multipleChoiceQuestionsNumberDefault,
  multipleChoiceAnswersNumberMin
} from '../constants';

interface WidgetMultipleChoiceAnswersJSON {
  [id: string]: string[] | undefined;
}

const stripAnswers = (change: Editor, options: WidgetToJSONOptions): Editor =>
  change.value.document
    .filterDescendants(
      node =>
        isBlockOfType(node, SlateBlock.QUESTION_ANSWER) ||
        isBlockOfType(node, SlateBlock.QUESTION_ITEM)
    )
    .reduce((ch: Editor, questionOrAnswerBlock: MCAnswerBlock): Editor => {
      const {generateIdentifiers, preserveAnswers} = options;
      if (generateIdentifiers) {
        addDataToBlock(ch, questionOrAnswerBlock, 'id', genKey());
      }
      if (!preserveAnswers && isBlockOfType(questionOrAnswerBlock, SlateBlock.QUESTION_ANSWER)) {
        addDataToBlock(ch, questionOrAnswerBlock, 'checked', undefined);
      }
      return ch;
    }, change);

const fillAnswers = (change: Editor, answers: WidgetMultipleChoiceAnswersJSON) =>
  change.value.document
    .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
    .reduce((ch: Editor, questionBlock: Block): Editor => {
      const correctAnswersForThisQuestion = answers[questionBlock.data.get('id')];
      questionBlock.nodes.forEach((node: Block) => {
        if (
          isBlockOfType(node, SlateBlock.QUESTION_ANSWER) &&
          correctAnswersForThisQuestion &&
          correctAnswersForThisQuestion.includes(node.data.get('id'))
        ) {
          addDataToBlock(ch, node, 'checked', true);
        }
      });
      return ch;
    }, change);

class XMultipleChoiceRecord
  extends XFormattedTextRecord<DataMap<MultipleChoiceOptions>>
  implements XMultipleChoiceProperties
{
  public declare readonly element: MultipleChoiceElement;

  constructor(raw: MultipleChoiceJSON) {
    super(raw);
    const {
      options: {
        horizontal = undefined,
        defaultAnswersType = MultipleChoiceDefaultAnswers.EMPTY,
        defaultAnswersNumber = multipleChoiceAnswersNumberDefault,
        initialQuestionsNumber = multipleChoiceQuestionsNumberDefault,
        withoutQuestions = false
      } = {}
    } = raw;
    this.initValues({
      options: Map({
        horizontal,
        defaultAnswersType,
        defaultAnswersNumber,
        initialQuestionsNumber,
        withoutQuestions
      }),
      element: raw.element,
      content: this.contentFromJSON(raw)
    });
  }

  public toJSON(options: WidgetToJSONOptions): MultipleChoiceJSON {
    return {
      id: this.id,
      type: this.type,
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      content: this.contentToJSON(options),
      options: this.options.size
        ? {
            horizontal: this.options.get('horizontal'),
            defaultAnswersType: this.options.get('defaultAnswersType'),
            defaultAnswersNumber: this.options.get('defaultAnswersNumber'),
            withoutQuestions: this.options.get('withoutQuestions')
          }
        : undefined,
      element: this.element,
      answers: this.answers.toJS(),
      media: options && options.withMedia && this.media ? this.media.toJS() : undefined
    };
  }

  public get answers(): XMultipleChoiceAnswers {
    const value = this.content;
    const headBlock = value.document.nodes.get(0);
    if (!isBlockOfType(headBlock, SlateBlock.QUESTION_LIST)) {
      // questions list block wasn't created yet, return empty map
      return Map();
    }
    return Map(
      headBlock.nodes.map((question: Block) => [
        question.data.get('id'),
        question.nodes
          .filter((answer: MCAnswerBlock) => !!answer.data.get('checked'))
          .map((filteredAnswer: MCAnswerBlock) => filteredAnswer.data.get('id'))
      ])
    );
  }

  public get type() {
    return WidgetType.MULTIPLE_CHOICE;
  }

  public get title(): string {
    const title = WidgetTitle[this.type];
    switch (this.element) {
      case MultipleChoiceElement.CHECKBOX:
        return title + ' (Checkboxes)';
      case MultipleChoiceElement.RADIO:
        return title + ' (Radio Buttons)';
      default:
        return title;
    }
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = this.content.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
      .map((qi: Block, i: number) => {
        const question = qi.getBlocksByType(SlateBlock.DEFAULT).first().text;
        const answers = qi
          .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
          .map((n: Block) => n.text)
          .join(' / ');
        return `${i + 1}. ${question} [${answers}]`;
      })
      .join(' ');
    return `${task} ${text}`.trim();
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Questions not empty',
          intl.formatMessage(validationMessages.QuestionsNonEmpty),
          (v: Value) =>
            !this.options.get('withoutQuestions') ? questionLabelsNotEmpty(v.document) : true
        )
        .test(
          'Min required answers',
          intl.formatMessage(validationMessages.MinRequiredAnswers),
          (v: Value) => minRequiredAnswers(v.document, multipleChoiceAnswersNumberMin)
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => answerBlocksNotEmpty(v.document)
        )
        .test(
          'Correct answers selected',
          intl.formatMessage(validationMessages.CorrectAnswersSelected),
          (v: Value) => correctAnswerSelectedForEveryQuestion(v.document)
        )
    });
  }

  private contentToJSON(options: WidgetToJSONOptions = {}): ValueJSON {
    const change = new Editor({value: this.content});
    change.moveToRangeOfDocument().command(stripAnswers, options);
    return change.value.toJSON();
  }

  private contentFromJSON(contentJSON: MultipleChoiceJSON): Value {
    const {content, answers = {}} = contentJSON;
    const value = Value.fromJSON(content);
    const change = new Editor({value});
    change.withoutSaving(() => {
      change.moveToRangeOfDocument().command(fillAnswers, answers).moveToStartOfDocument();
    });
    return change.value;
  }

  public toggleAlignment(): this {
    const horizontal = this.options.get('horizontal');
    const options = horizontal
      ? this.options.delete('horizontal')
      : this.options.set('horizontal', true);
    return this.set('options', options);
  }
}

decorate(XMultipleChoiceRecord, {
  element: property(MultipleChoiceElement.RADIO)
});
record()(XMultipleChoiceRecord);
export default XMultipleChoiceRecord;
