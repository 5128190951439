import {createTransform} from 'redux-persist';
import {Map} from 'immutable';

import {
  type DraftCommentJSON,
  type DraftCommentProperties,
  type DraftComments
} from 'store/exercise/player/interface';
import {weekInMS} from 'config/static';

import DraftCommentRecord from './exercise/player/DraftCommentRecord';

interface DraftCommentsJSON {
  [exerciseId: string]: DraftCommentJSON;
}

const inbound = (state: DraftComments, key: string): DraftCommentsJSON | unknown => {
  if (key !== 'draftComments' || !state) return state;
  return state.map((v: DraftCommentProperties) => v.toJSON()).toObject();
};

const outbound = (state: DraftCommentsJSON, key: string): DraftComments | unknown => {
  if (key !== 'draftComments' || !state) return state;
  const now = new Date().getTime();
  const draftComments = Map<string, DraftCommentProperties>(
    Object.entries(state).reduce(
      (acc: {[exerciseId: string]: DraftCommentProperties}, [exerciseId, commentJSON]) => {
        const stale = commentJSON.updatedAt && now - commentJSON.updatedAt >= weekInMS;
        const comment = new DraftCommentRecord(commentJSON);
        return !stale
          ? {
              ...acc,
              [exerciseId]: comment
            }
          : acc;
      },
      {}
    )
  );
  return draftComments.size ? draftComments : undefined;
};

const transformXPlayerDraftComments = createTransform<
  DraftComments | unknown,
  DraftCommentsJSON | unknown
>(inbound, outbound);

export default transformXPlayerDraftComments;
