import {type BlockJSON, type ValueJSON} from '@englex/slate';

import {ListType, SlateBlock, SlateObject} from 'components/Slate/interface';

const initialStyledItem = (): BlockJSON[] =>
  [1, 2, 3].map(v => ({
    object: SlateObject.BLOCK as 'block',
    type: SlateBlock.LIST_ITEM,
    data: {},
    nodes: [
      {
        object: SlateObject.BLOCK,
        type: SlateBlock.DEFAULT,
        data: {},
        nodes: [
          {
            object: SlateObject.TEXT,
            text: `List item ${v}`
          }
        ]
      }
    ] as BlockJSON[]
  }));

export const styledListInitialValue = (): ValueJSON => ({
  document: {
    object: SlateObject.DOCUMENT,
    nodes: [
      {
        object: SlateObject.BLOCK,
        type: SlateBlock.LIST,
        data: {
          list: ListType.OL
        },
        nodes: initialStyledItem()
      }
    ]
  }
});
