import {type IntlShape} from 'react-intl';
import {Block, Editor, type Document, Text, Value, type ValueJSON} from '@englex/slate';
import {List, Map} from 'immutable';
import * as yup from 'yup';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import {type XMatchingAnswers, type XMatchingChoices, type XMatchingProperties} from './interface';
import {WidgetTitle, type WidgetToJSONOptions, WidgetType} from '../../../player/interface';
import {
  type MatchingJSON,
  MatchingType,
  type WidgetMatchingAnswersJSON,
  type WidgetMatchingChoicesJSON
} from '../../../player/widgets/Matching/interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import {isBlockOfType} from '../../../../../components/Slate/utils';
import {
  type QuestionItemBlock,
  type QuestionItemData,
  SlateBlock,
  type DataMap
} from '../../../../../components/Slate/interface';
import {type MCAnswerBlock} from '../../../../../components/Slate/SlateEditor/plugins/widget/QuestionsList/MultipleChoice/interface';
import {addDataToBlock} from '../../../../../components/Slate/SlateEditor/plugins/utils';
import genKey from '../../../../../components/Slate/utils/genKey';
import validationMessages from '../i18n';
import {
  answerBlocksNotEmpty,
  minQuestionsAmount,
  minRequiredAnswers,
  questionLabelsNotEmpty,
  questionsDoNotContainIdenticalAnswers,
  documentNotEmpty
} from '../validation';
import {addQuestion} from '../../../../../components/Slate/SlateEditor/plugins/widget/QuestionsList/changes';

function getAnswerBlocks(document: Document) {
  return document.filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER));
}

const stripAnswers = (change: Editor, options: WidgetToJSONOptions): Editor =>
  change.value.document
    .filterDescendants(
      node =>
        isBlockOfType(node, SlateBlock.QUESTION_ANSWER) ||
        isBlockOfType(node, SlateBlock.QUESTION_ITEM)
    )
    .reduce((ch: Editor, questionOrAnswerBlock: MCAnswerBlock): Editor => {
      const {generateIdentifiers, preserveAnswers} = options;
      const {
        value: {document}
      } = ch;
      if (generateIdentifiers) {
        addDataToBlock(ch, questionOrAnswerBlock, 'id', genKey());
      }
      if (
        (!preserveAnswers || !generateIdentifiers) &&
        isBlockOfType(questionOrAnswerBlock, SlateBlock.QUESTION_ANSWER)
      ) {
        const qi = document.getParent(questionOrAnswerBlock.key) as QuestionItemBlock;
        if (!qi.data.get('example')) {
          ch.removeNodeByKey(questionOrAnswerBlock.key);
        }
      }
      return ch;
    }, change);

const fillAnswers = (
  change: Editor,
  answers: WidgetMatchingAnswersJSON,
  choices: WidgetMatchingChoicesJSON
) =>
  change.value.document
    .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
    .reduce((ch: Editor, questionBlock: Block): Editor => {
      const correctAnswersForThisQuestion = answers[questionBlock.data.get('id')];
      if (correctAnswersForThisQuestion) {
        correctAnswersForThisQuestion.forEach((answerId, index) => {
          const text = Text.create(choices[answerId].value);
          const paragraph = Block.create({
            type: SlateBlock.DEFAULT,
            nodes: List([text])
          });
          const block = Block.create({
            type: SlateBlock.QUESTION_ANSWER,
            data: {id: answerId},
            nodes: List([paragraph])
          });
          ch.insertNodeByKey(questionBlock.key, index + 1, block);
        });
      }
      return ch;
    }, change);

function excludeAnswerBlocksFromFirstQuestion({document}: Value) {
  const firstQuestionKey = (document.nodes.get(0) as Block).nodes.get(0).key;
  const allAnswerBlocks = getAnswerBlocks(document);
  return allAnswerBlocks.filter(
    block => (document.getParent(block!.key) as Block).key !== firstQuestionKey
  );
}

class XMatchingRecord extends XFormattedTextRecord implements XMatchingProperties {
  public declare readonly matchingType: MatchingType;

  constructor(raw: MatchingJSON) {
    super(raw);
    this.initValues({
      content: this.contentFromJSON(raw),
      matchingType: raw.matchingType || MatchingType.DEFAULT
    });
  }

  public toJSON(options: WidgetToJSONOptions): MatchingJSON {
    return {
      id: this.id,
      type: this.type,
      matchingType: this.matchingType,
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      content: this.contentToJSON(options),
      answers: this.answers.toJS(),
      choices: this.choices.toJS(),
      media: options && options.withMedia && this.media ? this.media.toJS() : undefined
    };
  }

  public get type() {
    return WidgetType.MATCHING;
  }

  public get title(): string {
    const title = WidgetTitle[this.type];
    switch (this.matchingType) {
      case MatchingType.FREE_CHOICE:
        return title + ` (No Correct Answers)`;
      case MatchingType.NO_CATEGORIES:
        return title + ` (No categories)`;
      default:
        return title;
    }
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = this.content.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ITEM))
      .map((qi: Block, i: number) => {
        const question = qi.getBlocksByType(SlateBlock.DEFAULT).first().text;
        const answers = qi
          .filterDescendants(node => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
          .map((n: Block) => n.text)
          .join(' / ');
        return `${i + 1}. ${question} [${answers}]`;
      })
      .join(' ');
    return `${task} ${text}`.trim();
  }

  public get answers(): XMatchingAnswers {
    const value = this.content;
    const headBlock = value.document.nodes.get(0);
    if (!isBlockOfType(headBlock, SlateBlock.QUESTION_LIST)) {
      // questions list block wasn't created yet, return empty map
      return Map();
    }
    const questionNodes = this.firstQuestionIsExample ? headBlock.nodes.rest() : headBlock.nodes;
    return Map(
      questionNodes.map((question: Block) => [
        question.data.get('id'),
        question.nodes
          .filter((node: Block) => isBlockOfType(node, SlateBlock.QUESTION_ANSWER))
          .map((node: Block) => node.data.get('id'))
      ])
    );
  }

  public get choices(): XMatchingChoices {
    const value = this.content;
    const answers = this.firstQuestionIsExample
      ? excludeAnswerBlocksFromFirstQuestion(value)
      : getAnswerBlocks(value.document);
    return Map(
      answers
        .sort((b1: Block, b2: Block) => b1.data.get('id').localeCompare(b2.data.get('id')))
        .map((block: Block) => [block.data.get('id'), {value: block.text}])
    );
  }

  public get firstQuestionIsExample(): boolean {
    const qi = this.firstQuestionItem;
    return !!qi && isBlockOfType(qi, SlateBlock.QUESTION_ITEM) && !!qi.data.get('example');
  }

  public setFirstQuestionIsExample(isExample: boolean): XMatchingRecord {
    const qi = this.firstQuestionItem;
    if (!qi) {
      return this;
    }
    const change = new Editor({value: this.content});
    let data: DataMap<QuestionItemData> | undefined;
    if (isExample) {
      data = (qi.data as DataMap<QuestionItemData>).set('example', isExample);
      if (
        this.content.document.filterDescendants((b: Block) =>
          isBlockOfType(b, SlateBlock.QUESTION_ITEM)
        ).size === 1
      ) {
        addQuestion(change);
      }
    } else {
      data = (qi.data as DataMap<QuestionItemData>).delete('example');
    }
    change.setNodeByKey(qi.key, {data});
    return this.set('content', change.value);
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Categories not empty',
          intl.formatMessage(validationMessages.CategoriesNonEmpty),
          (v: Value) => questionLabelsNotEmpty(v.document)
        )
        .test(
          'One answer required',
          intl.formatMessage(validationMessages.MinRequiredAnswers),
          (v: Value) => minRequiredAnswers(v.document, 1)
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => answerBlocksNotEmpty(v.document)
        )
        .test(
          'No duplicate answers within single category',
          intl.formatMessage(validationMessages.NoDuplicateAnswers),
          (v: Value) => questionsDoNotContainIdenticalAnswers(v.document)
        )
        .test(
          'Should be at least two items if example',
          intl.formatMessage(validationMessages.NotOnlyExampleQuestion),
          (v: Value) => minQuestionsAmount(v.document)
        )
    });
  }

  private contentToJSON(options: WidgetToJSONOptions = {}): ValueJSON {
    const change = new Editor({value: this.content});
    change.moveToRangeOfDocument().command(stripAnswers, options);
    return change.value.toJSON();
  }

  private get firstQuestionItem() {
    return this.content.document.getNode(List([0, 0])) as QuestionItemBlock | undefined;
  }

  private contentFromJSON(contentJSON: MatchingJSON): Value {
    const {content, answers = {}, choices = {}} = contentJSON;
    const value = Value.fromJSON(content);
    const change = new Editor({value});
    change.withoutSaving(() => {
      change.moveToRangeOfDocument().command(fillAnswers, answers, choices).moveToStartOfDocument();
    });
    return change.value;
  }
}

decorate(XMatchingRecord, {
  matchingType: property(MatchingType.DEFAULT)
});
record()(XMatchingRecord);
export default XMatchingRecord;
