import {defineMessages} from 'react-intl';

const messages = defineMessages({
  selectColor: {
    id: 'Paint.Toolbar.SelectColor'
  },
  pickColor: {
    id: 'Paint.Toolbar.PickColor'
  },
  newColor: {
    id: 'Paint.Toolbar.NewColor'
  },
  done: {
    id: 'Paint.Toolbar.Done'
  },
  default: {
    id: 'Paint.Toolbar.Default'
  },
  myPalette: {
    id: 'Paint.Toolbar.MyPalette'
  },
  customColor: {
    id: 'Paint.Toolbar.CustomColor'
  },
  selectLineWeight: {
    id: 'Paint.Toolbar.SelectLineWeight'
  },
  selectScale: {
    id: 'Paint.Toolbar.SelectScale'
  },
  selectFontSize: {
    id: 'Paint.Toolbar.SelectFontSize'
  }
});

export default messages;
