import React, {type FC, useCallback, useContext, useEffect, useMemo} from 'react';
import {Transition} from 'react-transition-group';

import {bottomSlideOutTransition} from '../shared/utils';
import {EditEntryStateContext} from '../shared/contexts/entry/EditEntryContext';
import {DictionarySearchPanel} from '../components/DictionarySearchPanel/DictionarySearchPanel';
import {useSearchingInODContext} from './DictionaryArticle/contexts/searchingInODContext';

interface Props {
  searchOpen: boolean;
  reset(): void;
}

export const Search: FC<Props> = React.memo(({searchOpen, reset}) => {
  const {selectionMode} = useContext(EditEntryStateContext);
  const {setSearchOpen} = useSearchingInODContext();

  const transitionObj = useMemo(() => bottomSlideOutTransition(300, 50), []);
  const onExited = useCallback(() => {
    if (!selectionMode) {
      setSearchOpen(false);
    }
  }, [selectionMode, setSearchOpen]);

  useEffect(() => () => reset(), [reset]);

  return (
    <Transition timeout={transitionObj.timeout} in={searchOpen} onExited={onExited}>
      {state => (
        <div
          className="words-list-search-wrapper"
          style={{...transitionObj.defaultStyle, ...transitionObj.transitionStyles[state]}}
        >
          <DictionarySearchPanel onSidebarDictionaryReset={reset} isSidebar={true} />
        </div>
      )}
    </Transition>
  );
});
