import {connect} from 'react-redux';
import {type Action} from 'redux';

import {messageUpdateIsAllowedTime} from 'config/static';
import {type AppState, type ChatMessage, type MessageMeta, type Room} from 'store/interface';
import {
  type Dispatch,
  type WampErrorAction,
  type WampSubscribeResponseAction
} from 'services/wamp/actions/interface';
import {
  type GetActions,
  type OnSubscribe,
  withWampSubscription
} from 'services/wamp/withWampSubscription';
import resolveIdFromUri from 'services/wamp/uriIdResolver';
import {TextMessageTypes} from 'common/enums';

import {
  type ChatDispatchProps,
  type ChatDispatchToProps,
  type ChatStateProps,
  type ChatStateToProps
} from './interface';
import Chat from './Chat';
import {
  clearChat,
  clearRoomMessagesExeptSelected,
  getPrivateRooms,
  getPrivateRoomsList,
  getRoomMessages,
  promoteMessageToUpdate,
  selectChatRoomRelatedToSelectedStudentTeacher,
  selectRoom,
  subscribeToRoom,
  unsubscribeToRoom
} from './actions/action';
import {browserCheckRequest} from '../../webRTC/action/action';
import {type GetPrivateRoomsSuccessAction, type SubscribeToRoomAction} from './actions/interface';
import {SUBSCRIBE_TO_ROOM_FAIL, SUBSCRIBE_TO_ROOM_SUCCESS} from './actions/actionTypes';
import {toggleChatPopover} from '../../layouts/actions/action';
import {type WampCallAnswerAction} from '../../webRTC/action/interface';

const mapStateToProps: ChatStateToProps = (state: AppState) => {
  const selectedRoom: Room | null =
    state.chat!.rooms && state.chat!.rooms[state.chat!.selectedRoomId!]
      ? state.chat!.rooms[state.chat!.selectedRoomId!]
      : null;
  const messages: ChatMessage[] =
    selectedRoom && selectedRoom.messages ? selectedRoom.messages : [];
  messages.forEach((message: ChatMessage) => {
    message.own = message.sender_id === state.user.id;
  });
  const ownMessages = messages
    ? messages.filter(message => {
        return (
          message.own &&
          !message.deleted_at &&
          (message.meta as MessageMeta).type !== TextMessageTypes.image
        );
      })
    : [];
  const lastOwnMessage = ownMessages ? ownMessages[ownMessages.length - 1] : undefined;
  const now = new Date().getTime();
  const lastMessageEditable =
    lastOwnMessage &&
    now - new Date(lastOwnMessage.created_at).getTime() < messageUpdateIsAllowedTime;
  return {
    roomsLoaded: state.chat!.roomsLoaded,
    selectedRoomId: state.chat!.selectedRoomId || undefined,
    wampStatus: state.wamp.status,
    wampError: state.chat!.wampError,
    chatCollapsed: state.layout.collapsed,
    videoInChat: (!state.video.fullScreen && !state.video.undocked) || state.rtc.otherSessionCall,
    chatIsActive: selectedRoom && !selectedRoom.deleted_at,
    shouldRenderHeader: !!selectedRoom,
    lastOwnMessageId: lastMessageEditable && lastOwnMessage && lastOwnMessage.id
  } as ChatStateProps;
};

const mapDispatchToProps: ChatDispatchToProps = dispatch => ({
  getPrivateRoomsList: () => dispatch(getPrivateRoomsList),
  selectRoom: (roomId: number) => dispatch(selectRoom(roomId)),
  clearChat: () => dispatch(clearChat()),
  browserCheckRequest: () => dispatch(browserCheckRequest()),
  getRoomMessages: (id: number, withoutLoader?: boolean, lastMessageId?: number) =>
    dispatch(getRoomMessages(id, withoutLoader, lastMessageId)),
  promoteMessageToUpdate: (roomId: number, id: number) =>
    dispatch(promoteMessageToUpdate(roomId, id)),
  closeChatPopover: () => dispatch(toggleChatPopover(false))
});

const getActions: GetActions = getState => {
  const state: AppState = getState!();
  if (!state.chat!.rooms || state.chat!.shouldLoadPrivateRoomsList) {
    return null;
  }
  const toReturn: SubscribeToRoomAction[] = [];
  for (const id in state.chat!.rooms) {
    if (!state.chat!.rooms[id].deleted_at) {
      toReturn.push(subscribeToRoom(Number(id)));
    }
  }
  if (toReturn.length === 0) {
    return null;
  }
  return toReturn;
};

const unsubscribeFromRoom = (uri: string, dispatch: Dispatch<Action, WampCallAnswerAction>) => {
  const roomId: number = resolveIdFromUri(uri).chatroomId;
  if (roomId) {
    dispatch(unsubscribeToRoom(roomId));
  }
};

const onSubscribe: OnSubscribe = (actions, dispatch, allSubscriptions, getState) => {
  if (actions.find(action => action.type === SUBSCRIBE_TO_ROOM_FAIL)) {
    actions.forEach(
      (
        action: WampErrorAction<{}, {}, {}> | WampSubscribeResponseAction<SubscribeToRoomAction>
      ) => {
        if (action.type === SUBSCRIBE_TO_ROOM_SUCCESS) {
          unsubscribeFromRoom(
            (action as WampSubscribeResponseAction<SubscribeToRoomAction>).wamp.subscription.topic,
            dispatch as never
          );
        }
      }
    );
    allSubscriptions.forEach(subscription => {
      if (!actions.find(action => action.wamp!.meta.previousAction.wamp.uri === subscription)) {
        unsubscribeFromRoom(subscription, dispatch as never);
      }
    });
  } else {
    const state = getState();
    if (!state.chat!.roomsLoaded) {
      dispatch(getPrivateRooms()).then(() =>
        dispatch(selectChatRoomRelatedToSelectedStudentTeacher())
      );
    } else {
      dispatch(clearRoomMessagesExeptSelected());
      dispatch(getPrivateRooms()).then((action: GetPrivateRoomsSuccessAction) => {
        if (
          action.wamp.callResult &&
          action.wamp.callResult.args &&
          action.wamp.callResult.args[0].length
        ) {
          dispatch(getRoomMessages(state.chat!.selectedRoomId!, true));
        }
      });
    }
  }
};

const ReduxConnectedChat = connect<ChatStateProps, ChatDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Chat);

const SubscribedChat = withWampSubscription(getActions, onSubscribe)(ReduxConnectedChat);

export default SubscribedChat;
