import {type ThunkAction} from 'redux-thunk';
import {type AnswerData} from '@englex/trainer';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {type PronunciationOption} from 'store/exercise/player/interface';
import {type EditEntryData, type PartOfSpeech} from 'components/Dictionary/shared/interface';
import {dictionaryPageSize} from 'config/static';
import {type WampPublishAction} from 'services/wamp/actions/interface';
import {type DictionaryOwnerRole} from 'components/Dictionary/shared/contexts';

import {
  ADD_ENTRIES_TO_LIST_REQUEST,
  ARCHIVE_WORDS_LIST,
  CREATE_WORD_ENTRY_REQUEST,
  CREATE_WORDS_LIST,
  DELETE_ENTRY_FROM_DICTIONARY_REQUEST,
  DELETE_ENTRIES_FROM_LIST_REQUEST,
  DELETE_WORDS_LIST,
  EDIT_WORD_ENTRY_REQUEST,
  GET_DICTIONARY_OVERVIEW_REQUEST,
  LOAD_PRONUNCIATIONS_REQUEST,
  RENAME_WORDS_LIST,
  REQUEST_DICTIONARY_INSTANCE_CHUNK,
  REQUEST_STUDENT_LISTS,
  REQUEST_WORDS_LIST,
  TRAINER_ANSWERS_PUBLISH_EVENT,
  DELETE_ENTRIES_FROM_DICTIONARY_REQUEST,
  COPY_WORD_LIST_REQUEST
} from './actionTypes';
import {type AppState} from '../interface';
import {stringBooleanToBoolean} from './stringBooleanToBoolean';

export const requestStudentLists = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  dictionaryEntryInstanceId?: string
): AxiosRequestAction => ({
  type: REQUEST_STUDENT_LISTS,
  payload: {
    client: 'v2',
    request: {
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/list`,
      params: dictionaryEntryInstanceId
        ? {dictionaryEntryInstanceId}
        : {expand: ['overview', 'image']}
    }
  }
});

interface RequestDictionaryInstanceChunkParams {
  before?: string;
  beforeId?: string;
  fromCoursebook?: boolean;
  search?: string;
  inLists?: string;
}
export const requestDictionaryInstanceChunk = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  params: RequestDictionaryInstanceChunkParams = {}
): AxiosRequestAction => {
  const {search: searchParam = '', inLists, ...rest} = params;

  const search = searchParam.trim().replace(/([%_\\])/g, str => '\\' + str) || undefined;

  const withListsMember = inLists ? stringBooleanToBoolean(inLists) : true;

  return {
    type: REQUEST_DICTIONARY_INSTANCE_CHUNK,
    payload: {
      client: 'v2',
      request: {
        method: 'get',
        url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/entry`,
        params: {
          ...rest,
          search,
          withListsMember,
          take: dictionaryPageSize,
          inLists
        }
      }
    }
  };
};

export const createWordsListRequest = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  title: string
): AxiosRequestAction => ({
  type: CREATE_WORDS_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/list`,
      data: {title}
    }
  }
});

export const renameWordsListRequest = (title: string, listId: string): AxiosRequestAction => ({
  type: RENAME_WORDS_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/dictionary-list/${listId}`,
      data: {title}
    }
  }
});

export const createWordEntry = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  data: EditEntryData
): AxiosRequestAction => ({
  type: CREATE_WORD_ENTRY_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/entry`,
      data
    }
  }
});

export const editWordEntry = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  entryId: string,
  data: EditEntryData
): AxiosRequestAction => ({
  type: EDIT_WORD_ENTRY_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'put',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/entry/${entryId}`,
      data
    }
  }
});

export type LoadPronunciationsResponseAction = AxiosResponseAction<{
  audio: PronunciationOption[];
  phoneticSpelling: string;
}>;

export const loadPronunciationsRequest = (
  word: string,
  partOfSpeech?: PartOfSpeech
): AxiosRequestAction => ({
  type: LOAD_PRONUNCIATIONS_REQUEST,
  payload: {
    client: 'v2',
    request: {
      url: '/v2/pronunciation',
      method: 'post',
      data: {
        word,
        category: partOfSpeech
      }
    }
  }
});

export const requestDictionaryList = (
  listId: string,
  expand: string[] = ['entryInstances']
): AxiosRequestAction => ({
  type: REQUEST_WORDS_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/dictionary-list/${listId}`,
      params: {expand}
    }
  }
});

export const publishEntryAnswer: (
  data: AnswerData[]
) => ThunkAction<void, AppState, never, WampPublishAction<AnswerData[], {}>> =
  data => async (dispatch, getState) => {
    const studentId = getState().user?.id;
    if (studentId) {
      const action = publishEntryAnswerEvent(studentId, data);
      await dispatch(action);
    }
  };

const publishEntryAnswerEvent = (
  dictionaryOwnerId: number,
  args: AnswerData[]
): WampPublishAction<AnswerData[], {}> => {
  return {
    type: TRAINER_ANSWERS_PUBLISH_EVENT,
    wamp: {
      uri: `dictionary:instance._${dictionaryOwnerId}.stats.event.entry`,
      method: 'publish',
      args
    }
  };
};

export const archiveListToggle = (
  listId: string,
  method: 'archive' | 'restore'
): AxiosRequestAction => ({
  type: ARCHIVE_WORDS_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/dictionary-list/${listId}/${method}`
    }
  }
});

export const requestListDelete = (listId: string): AxiosRequestAction => ({
  type: DELETE_WORDS_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/dictionary-list/${listId}`
    }
  }
});

export const addEntriesToListRequest = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  entryInstanceIds: string[],
  listId: string
): AxiosRequestAction => ({
  type: ADD_ENTRIES_TO_LIST_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/list/${listId}/entry`,
      data: {entryInstanceIds}
    }
  }
});

export const deleteEntriesFromListRequest = (
  dictionaryOwnerId: number,
  entryInstanceIds: string[],
  listId: string,
  dictionaryOwnerRole: DictionaryOwnerRole
): AxiosRequestAction => ({
  type: DELETE_ENTRIES_FROM_LIST_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/list/${listId}/entry`,
      data: {entryInstanceIds}
    }
  }
});

export const deleteEntriesFromDictionaryRequest = (
  dictionaryOwnerId: number,
  entryInstanceIds: string[],
  dictionaryOwnerRole: DictionaryOwnerRole
): AxiosRequestAction => ({
  type: DELETE_ENTRIES_FROM_DICTIONARY_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/entry`,
      data: {entryInstanceIds}
    }
  }
});

export const deleteEntryFromDictionaryRequest = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole,
  entryInstanceId: string
): AxiosRequestAction => ({
  type: DELETE_ENTRY_FROM_DICTIONARY_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}/entry/${entryInstanceId}`
    }
  }
});

export const getDictionaryOverviewRequest = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole
): AxiosRequestAction => ({
  type: GET_DICTIONARY_OVERVIEW_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/dictionary-instance/${dictionaryOwnerRole}/${dictionaryOwnerId}`
    }
  }
});

export const copyWordListRequest = (
  dictionaryListId: string,
  dictionaryInstanceOwnerIds: number[]
): AxiosRequestAction => ({
  type: COPY_WORD_LIST_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/dictionary-list/${dictionaryListId}/copy`,
      data: {dictionaryInstanceOwnerIds}
    }
  }
});
