import {type RendererPlugin} from '../../interface';
import Orange from './Orange';
import Red from './Red';
import Brown from './Brown';
import Blue from './Blue';
import Green from './Green';
import Yellow from './Yellow';

class Highlight implements RendererPlugin {
  public plugins: RendererPlugin[] = [
    new Orange(),
    new Yellow(),
    new Red(),
    new Blue(),
    new Green(),
    new Brown()
  ];
}

export default Highlight;
