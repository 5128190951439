const prefix = 'XWIDGET/';

export const FLIP_CARDS_ADD_CARD = prefix + 'FLIP_CARDS_ADD_CARD';
export const FLIP_CARDS_DELETE_CARD = prefix + 'FLIP_CARDS_DELETE_CARD';
export const FLIP_CARDS_CHANGE_DEFAULT_FLIPPED = prefix + 'FLIP_CARDS_CHANGE_DEFAULT_FLIPPED';
export const FLIP_CARDS_CHANGE_IMAGE = prefix + 'FLIP_CARDS_CHANGE_IMAGE';
export const FLIP_CARDS_CHANGE_CARD_SIZE_TYPE = prefix + 'FLIP_CARDS_CHANGE_CARD_SIZE_TYPE';
export const FLIP_CARDS_CHANGE_TEXT = prefix + 'FLIP_CARDS_CHANGE_TEXT';
export const FLIP_CARDS_FLIP_CARD = 'FLIP_CARDS_FLIP_CARD';
export const FLIP_CARDS_MOVE_CARD = prefix + 'FLIP_CARDS_MOVE_CARD';
export const FLIP_CARDS_ROLL_BACK_CARDS = prefix + 'FLIP_CARDS_ROLL_BACK_CARS';
