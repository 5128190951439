import {WidgetTitle, WidgetType} from 'store/exercise/player/interface';
import {genKey, valueJSONFromText} from 'components/Slate/utils';
import {type ScrambledChoicesJSON} from 'store/exercise/player/widgets/ScrambledSentences/interface';
import {record} from 'immutable-record/decorator/record';

import XSpellingRecord from './XSpellingRecord';
import {jsonContentFromSentences} from '../XScrambled/initials';
import {
  spellingLetterMatcher,
  spellingLetterMatcherForWordsType,
  spellingSeparators,
  wordTemplateLiteral
} from '../../../../../config/static';
import {SpellingType} from './interface';
import {type Token} from '../XScrambled/interface';

class XSpellingWordsRecord extends XSpellingRecord {
  public static create(sentences: string[] = [''], manualSplitting?: boolean): XSpellingRecord {
    return new this({
      id: genKey(),
      type: WidgetType.SPELLING,
      spellingType: SpellingType.Words,
      task: valueJSONFromText(''),
      sentencesValueJSON: jsonContentFromSentences(sentences),
      ...(manualSplitting ? {options: {manualSplitting}} : {})
    });
  }

  public get title(): string {
    const title = WidgetTitle[this.type];
    return `${title} (words)`;
  }

  public letterChecker(chars: string[], index: number, sentenceLength: number) {
    const char = chars[index];

    if (char === '-') return this.isBetweenAllowedChars(chars, index);

    if (this.isWordConsistOnlyOfDashAndChar(chars, index, sentenceLength)) return false;

    if (
      this.isBetweenSeparators(chars, index, sentenceLength) ||
      this.isBetweenNotAllowedChars(chars, index, sentenceLength)
    ) {
      return false;
    }

    return this.isAllowedChar(chars[index]);
  }

  protected getPartsAndWords(text: string, tokenIds: string[]) {
    const wordParts = text
      .replaceAll(/[\s\n/]{2,}/g, ' ')
      .trim()
      .split(/[\s\n/]/);
    let currentIdsIndex = 0;
    const shuffledData = wordParts.map(word => {
      const {shuffledWord, wordIds, wordLength} = this.shuffleWord(word, tokenIds, currentIdsIndex);
      currentIdsIndex += wordLength;

      return {shuffledWord, wordIds};
    });

    const shuffledText = shuffledData.map(item => item.shuffledWord).join(' ');
    const shuffledIds = shuffledData.reduce((result: string[], item) => {
      return [...result, ...item.wordIds];
    }, []);

    const wordIds: string[] = [];
    wordParts.forEach(word => {
      if (word.length > 1) {
        const wordKey = genKey();
        const wordArray = word.trim().split('');
        wordArray.forEach((letter, i) => {
          if (this.letterChecker(wordArray, i, wordArray.length)) {
            wordIds.push(wordKey);
          }
        });
      }
    });

    const parts = this.splitSentences(shuffledText);
    const words = this.filterSentences(parts);

    return {parts, words, wordIds, shuffledIds};
  }

  protected filterSentences(texts: string[]) {
    return texts.filter((p, i) => {
      return this.letterChecker(texts, i, texts.length);
    });
  }

  protected liTemplate(text: string, words: string[]) {
    const template = text.trim().split('');

    words.forEach(w => {
      if (w !== '-') {
        const index = template.findIndex((item, i) => {
          if (item !== w) return false;
          return this.letterChecker(template, i, template.length);
        });

        template[index] = wordTemplateLiteral;
      } else {
        const index = template.findIndex((item, i) => {
          return (
            item === '-' &&
            (spellingLetterMatcher.test(template[i - 1]) ||
              template[i - 1] === wordTemplateLiteral) &&
            (spellingLetterMatcher.test(template[i + 1]) || template[i + 1] === wordTemplateLiteral)
          );
        });

        template[index] = wordTemplateLiteral;
      }
    });
    return template.join('');
  }

  protected liTokens(
    tokenIds: string[],
    parts: string[],
    words: string[],
    shuffledIds: string[],
    sentenceId?: string,
    wordIds?: string[]
  ) {
    const tokens: Token[] = [];
    let offset = 0;
    let i = 0;
    parts.forEach((part, k) => {
      if (words.includes(part)) {
        const choiceId = shuffledIds ? shuffledIds[i] : tokenIds[i];
        const sentenceIndex = this.cachedSentences?.findIndex(item => item.id === sentenceId);

        if (part !== '-' || this.isBetweenAllowedChars(parts, k)) {
          tokens.push({
            id: `${choiceId}`,
            value: part,
            tokenGroupId:
              !!this.cachedSentences &&
              sentenceIndex !== undefined &&
              this.cachedSentences[sentenceIndex]?.choices[choiceId]?.tokenGroupId
                ? this.cachedSentences[sentenceIndex].choices[choiceId].tokenGroupId
                : wordIds
                  ? wordIds[i]
                  : sentenceId,
            offset
          });
          i++;
        }
      }
      offset += part.length;
    });

    return tokens;
  }

  protected shuffleChoices(choices: ScrambledChoicesJSON, answers: string[]): ScrambledChoicesJSON {
    const tokenGroups = this.choicesToTokenGroups(choices);

    return Object.values(tokenGroups).reduce<ScrambledChoicesJSON>((c, choices) => {
      const tgAnswers = answers.filter(a => Object.keys(choices).includes(a));

      return {...c, ...super.shuffleChoices(choices, tgAnswers)};
    }, {});
  }

  protected choicesToTokenGroups(
    choices: ScrambledChoicesJSON
  ): Record<string, ScrambledChoicesJSON> {
    return Object.entries(choices).reduce<Record<string, ScrambledChoicesJSON>>(
      (result, [choiceId, choice]) => {
        const tokenGroupId = choice.tokenGroupId;
        if (!tokenGroupId) {
          return result;
        }

        const tg = result[tokenGroupId] || {};
        tg[choiceId] = choice;

        if (!result[tokenGroupId]) {
          result[tokenGroupId] = tg;
        }

        return result;
      },
      {}
    );
  }

  protected shuffleWord(
    word: string,
    Ids: string[],
    currentIdsIndex: number
  ): {shuffledWord: string; wordIds: string[]; wordLength: number} {
    if (word.length === 1) return {shuffledWord: '', wordIds: [], wordLength: 0};

    const wordArray = word.split('');
    const wordObj = {};

    let k = 0;

    wordArray.forEach((letter, i) => {
      if (this.letterChecker(wordArray, i, wordArray.length)) {
        wordObj[Ids[currentIdsIndex + k]] = letter;

        k += 1;
      }
    });

    const shuffledKeys = Object.keys(wordObj).sort((a, b) => b.localeCompare(a));
    const shuffledWord = shuffledKeys.map(key => wordObj[key]).join('');

    return shuffledWord[0] !== '-' || shuffledWord[shuffledWord.length - 1] !== '-'
      ? {shuffledWord, wordIds: shuffledKeys, wordLength: k}
      : this.shuffleWord(word, Ids, currentIdsIndex);
  }

  private isAllowedChar(char: string) {
    return spellingLetterMatcherForWordsType.test(char);
  }

  private getIsStartOfWordAndIsEndOfWord(index: number, charsLength: number) {
    return {
      isStartOfWord: index === 0,
      isEndOfWord: charsLength - index === 1
    };
  }

  private isSeparator(char: string) {
    return spellingSeparators.test(char);
  }

  private isBetweenSeparators(chars: string[], index: number, sentenceLength: number) {
    const {previous, next} = this.getPreviousAndNextChars(chars, index);
    const {isStartOfWord, isEndOfWord} = this.getIsStartOfWordAndIsEndOfWord(index, sentenceLength);

    const isPreviousSeparator = this.isSeparator(previous);
    const isNextSeparator = this.isSeparator(next);

    return (
      (isPreviousSeparator && isNextSeparator) ||
      (isStartOfWord && isNextSeparator) ||
      (isEndOfWord && isPreviousSeparator)
    );
  }

  private isBetweenNotAllowedChars(chars: string[], index: number, sentenceLength: number) {
    const {previous, next} = this.getPreviousAndNextChars(chars, index);
    const {isStartOfWord, isEndOfWord} = this.getIsStartOfWordAndIsEndOfWord(index, sentenceLength);

    const isPreviousAllowed = this.isAllowedChar(previous);
    const isNextAllowed = this.isAllowedChar(next);

    return (
      (!isPreviousAllowed && !isNextAllowed) ||
      (isStartOfWord && !isNextAllowed) ||
      (isEndOfWord && !isPreviousAllowed)
    );
  }

  private isWordConsistOnlyOfDashAndChar(chars: string[], index: number, sentenceLength: number) {
    const {isStartOfWord, isEndOfWord} = this.getIsStartOfWordAndIsEndOfWord(index, sentenceLength);
    const {previous, next} = this.getPreviousAndNextChars(chars, index);

    const previousIsDash = previous === '-';
    const nextIsDash = next === '-';

    if (!previousIsDash && !nextIsDash) return false;

    const previousIsDashBetweenAllowedChars =
      previous === '-' && this.isBetweenAllowedChars(chars, index - 1);
    const nextIsDashBetweenAllowedChars =
      next === '-' && this.isBetweenAllowedChars(chars, index + 1);

    if (
      !previousIsDashBetweenAllowedChars &&
      (!this.isAllowedChar(chars[index + 1]) || isEndOfWord)
    )
      return true;
    if (!nextIsDashBetweenAllowedChars && (!this.isAllowedChar(chars[index - 1]) || isStartOfWord))
      return true;

    return false;
  }
}

record()(XSpellingWordsRecord);
export default XSpellingWordsRecord;
