export type ListSort = 'alphabetic' | 'newest_at_the_bottom' | 'newest_at_the_top';

export enum XVocabularyDefaultCategories {
  NOUNS = 'Nouns / Существительные',
  ADJECTIVES = 'Adjectives / Прилагательные',
  VERBS = 'Verbs / Глаголы',
  ADVERBS = 'Adverbs / Наречия',
  PHRASES = 'Phrases / Фразы'
}

export enum PartOfSpeech {
  NOUN = 'Noun',
  ADJECTIVE = 'Adjective',
  VERB = 'Verb',
  ADVERB = 'Adverb'
}

export interface DictionaryState {
  status: 'active' | 'idle';
  entries: DictionaryEntryInstance[];
  listEntries: DictionaryEntryInstance[];
  listSort: ListSort;
  lists?: DictionaryList[];
  overview?: DictionaryOverview;
  isReadonly?: boolean;
  isReverse: boolean;
  // sidebar specific state
  search?: string;
  studentId?: number;
}

export type DictionaryRoute = {
  studentId?: string;
};

interface DictionaryEntry {
  id: string;
  original: string;
  translation: string;
}

export interface Pronunciation {
  id: number;
  importedAt: string | null;
  url: string;
}

export interface DictionaryEntryInstance {
  id: string;
  dictionaryEntryId: string;
  ownerId: number;
  pronunciationId: number | null;
  phoneticSpelling: string | null;
  exerciseInstanceId: string | null;
  createdById: number;
  updatedAt: string;
  dictionaryEntry: DictionaryEntry;
  pronunciation: Pronunciation | null;
  listsMember?: number;
}

export interface EditEntryData {
  original: string;
  translation: string;
  pronunciationId?: number | null;
  phoneticSpelling?: string | null;
}

export interface DictionaryOverview {
  ownerId: number;
  entryCount: number;
  listCount: number;
  tz: string;
}

interface DictionaryListCover {
  id: number;
  width: number;
  height: number;
  urls: string[];
}

export interface ExerciseInstance {
  id: string;
  unitInstance: {
    ordinal?: number;
    unit: {
      id: number;
      title: string;
      isRevision?: boolean;
      coursebook: {
        id: string;
        title: string;
      };
    };
  };
}

export interface DictionaryList {
  id: string;
  ownerId: number;
  title: string;
  imageId: number | null;
  exerciseInstanceId: string | null;
  createdById: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
  image: DictionaryListCover | null;
  entryCount?: number;
  exerciseInstance?: ExerciseInstance;
}

export interface DictionaryListWithEntries extends DictionaryList {
  entryInstances: DictionaryEntryInstance[];
}

export interface AddWordToDictionaryResponse {
  dictionaryListId: string;
  exerciseInstanceId: string;
  newEntries: DictionaryEntryInstance[];
  widgetId: string;
}
