import {type BlockJSON, type ValueJSON} from '@englex/slate';

import {genKey} from 'components/Slate/utils';

const initialBlocks = (sentences: string[]) => {
  return sentences.map(s => ({
    object: 'block' as 'block',
    type: 'ListItem',
    data: {
      id: genKey()
    },
    nodes: [
      {
        object: 'block',
        type: 'paragraph',
        data: {},
        nodes: [
          {
            object: 'text',
            text: s,
            marks: []
          }
        ]
      }
    ] as BlockJSON[]
  }));
};

export const jsonContentFromSentences = (sentences: string[] = []): ValueJSON => ({
  document: {
    object: 'document',
    nodes: [
      {
        object: 'block',
        type: 'List',
        data: {
          list: 'ol'
        },
        nodes: initialBlocks(sentences)
      }
    ]
  }
});
