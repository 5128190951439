export default class Throttle {
  private throttledAction: number | null = null;

  public throttleAction = (callback: () => void) => {
    if (this.throttledAction) {
      cancelAnimationFrame(this.throttledAction);
    }
    this.throttledAction = requestAnimationFrame(() => {
      this.throttledAction = null;
      callback();
    });
  };
}
