const touchEmulatorLSKey = 'vc:touch-emulator-enabled';

export function isTouchEmulatorEnabled() {
  if (import.meta.env.REACT_APP_DEV_TOUCH_EMULATOR === 'true') {
    return Boolean(localStorage.getItem(touchEmulatorLSKey));
  }

  return false;
}

export function toggleTouchEmulator() {
  if (isTouchEmulatorEnabled()) {
    removeTouchEmulator();
  } else {
    addTouchEmulator();
  }

  setTimeout(() => window.location.reload(), 300);
}

export function loadTouchEmulator() {
  const script = document.createElement('script');

  script.id = 'touch-emulator';

  script.setAttribute(
    'src',
    'https://cdn.rawgit.com/hammerjs/touchemulator/master/touch-emulator.js'
  );

  script.onload = () => window.TouchEmulator?.();

  document.head.appendChild(script);
}

function addTouchEmulator() {
  loadTouchEmulator();
  localStorage.setItem(touchEmulatorLSKey, 'true');
}

function removeTouchEmulator() {
  document.getElementById('touch-emulator')?.remove();
  localStorage.removeItem(touchEmulatorLSKey);
}
