/*
  autoSetLevel1:
    - hadn set 1 level of substate
    - skips substate if already modified
*/

import {type PersistConfig} from 'redux-persist/es/types';

function passWhitelistBlacklist<S>({whitelist, blacklist}: PersistConfig<S>, key: string) {
  if (Array.isArray(whitelist) && !whitelist.includes(key)) {
    return false;
  }
  return !(Array.isArray(blacklist) && blacklist.includes(key));
}

const autoSetLevel1 = <S extends object>(
  inboundState: S,
  originalState: S,
  reducedState: S,
  config: PersistConfig<S>
): S => {
  const newState = {...(reducedState as object)} as S;
  // only rehydrate if inboundState exists and is an object

  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach(key => {
      // ignore _persist data
      if (key === '_persist') {
        return;
      }
      // skip auto set if key does not pass whitelist/blacklist
      if (!passWhitelistBlacklist<S>(config, key)) {
        if (config.debug) {
          // eslint-disable-next-line no-console
          console.log(
            'stateReconciler/autoSetLevel1: key `%s` does not pass, whitelist/blacklist, skipping.',
            key
          );
        }
        return;
      }
      // otherwise hard set the new value
      newState[key] = inboundState[key];
    });
  }

  if (
    import.meta.env.MODE !== 'production' &&
    config.debug &&
    inboundState &&
    typeof inboundState === 'object'
  ) {
    // eslint-disable-next-line no-console
    console.log(
      `stateReconciler/autoSetLevel1: rehydrated keys '${Object.keys(inboundState).join(', ')}'`
    );
  }

  return newState;
};

export default autoSetLevel1;
