import {Block, Document, Value, type ValueJSON} from '@englex/slate';
import {List, OrderedMap} from 'immutable';

import {valueJSONFromText} from 'components/Slate/utils';
import {SlateBlock} from 'components/Slate/interface';

import QuestValueRecord from './QuestValueRecord';
import {type QuestsValues, type QuestsValuesJSON} from '../interface';

export const getQuestions = (value: ValueJSON): List<Value> =>
  List(
    (Value.fromJSON(value).document.nodes.get(0) as Block).nodes
      .filter(b => Block.isBlock(b))
      .map((b: Block, i: number) =>
        Value.create({
          document: Document.create([
            Block.create({
              type: SlateBlock.LIST_ITEM,
              data: b.data.set('index', i + 1),
              nodes: b.nodes
            })
          ])
        })
      )
  );

export const initValues = (
  questions: List<Value>,
  jsonValues?: QuestsValuesJSON,
  withOnReview?: boolean
): QuestsValues =>
  questions.reduce((values: QuestsValues, q: Value) => {
    const data = (q.document.nodes.first() as Block).data;
    const id = data.get('id');
    if (id && jsonValues && jsonValues[id]) {
      return values.set(
        id,
        new QuestValueRecord({
          value: jsonValues[id].value,
          onReview: withOnReview ? jsonValues[id].onReview : undefined
        })
      );
    }
    return id ? values.set(id, new QuestValueRecord({value: valueJSONFromText('')})) : values;
  }, OrderedMap());
