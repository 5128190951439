import {Value} from '@englex/slate';

import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {type DialogueMessageJSON} from 'store/exercise/player/widgets/Dialogue/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {genKey, valueFromText} from 'components/Slate/utils';

import {type DialogueMessageProperties, type DialogueMessageProps} from './interface';

const Record = recordBase()(BaseRecord);

class XDialogueMessageRecord extends Record implements DialogueMessageProperties {
  public declare readonly id: string;

  public declare readonly speakerId: string;

  public declare readonly message: Value;

  constructor(raw: Partial<DialogueMessageProps> = {}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      speakerId: raw.speakerId,
      message: raw.message ? Value.fromJSON(raw.message) : valueFromText()
    });
  }

  public toJSON(): DialogueMessageJSON {
    return {
      id: this.id,
      speakerId: this.speakerId,
      message: this.message.toJSON()
    };
  }
}

decorate(XDialogueMessageRecord, {
  id: property(genKey()),
  speakerId: property(''),
  message: property()
});
record()(XDialogueMessageRecord);
export default XDialogueMessageRecord;
