import {type FC} from 'react';
import classNames from 'classnames';

import {TranslationItemControls} from '../TranslationItem/TranslationItemControls';
import {useTranslationItemControls} from '../../hooks/useTranslationItemControls';
import {type Sense} from '../../interfaces';
import {getGrammaticalFeaturesAndDomains} from '../../utils/helpers';

import './EnglishEntryItem.scss';

interface Props {
  includedTranslation?: string;
  sense: Sense;
  isSidebar: boolean;
  dictionaryIsReadonly: boolean;
  addWord: (translation: string) => void;
  deleteWord: (translation: string) => void;
}

export const EnglishEntryItem: FC<Props> = ({
  includedTranslation,
  sense,
  isSidebar,
  dictionaryIsReadonly,
  addWord,
  deleteWord
}) => {
  const {ref, onInclude, onExclude, getContainer} = useTranslationItemControls({
    translation: sense.definitions![0],
    addWord,
    deleteWord
  });

  const grammaticalFeaturesAndDomains = getGrammaticalFeaturesAndDomains(sense);

  return (
    <div
      className={classNames('english-entry-item', {'is-sidebar': isSidebar})}
      key={sense.definitions![0]}
      ref={ref}
    >
      <div className="english-entry-item__header">
        <div className="english-entry-item__features">{grammaticalFeaturesAndDomains}</div>
        <div className="english-entry-item__button">
          {!dictionaryIsReadonly && (
            <TranslationItemControls
              includedTranslation={includedTranslation}
              onInclude={onInclude}
              onExclude={onExclude}
              getTooltipContainer={getContainer}
            />
          )}
        </div>
      </div>
      <div className="english-entry-item__definition">{sense.definitions![0]}</div>
      {!!sense.examples?.length && (
        <div className="english-entry-item__examples">
          <div className="english-entry-item__examples-title">Examples</div>
          {sense.examples?.map(example => (
            <div className="english-entry-item__example" key={example.text}>
              {`"${example.text}"`}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
