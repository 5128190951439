import React from 'react';
import {type Node, visit} from 'unist-util-visit';
import {type CodeComponent} from 'react-markdown/lib/ast-to-react';

interface VFile {
  value: string;
}

function hasEmoji(content: string) {
  const reg = /:\+1:|:-1:|:[\w-]+:/g;

  return reg.test(content);
}

function transformer(tree: Node, file: object) {
  visit(tree, 'text', function (node: Node) {
    const {value} = file as VFile;

    if (hasEmoji(value)) {
      node.type = 'code';
    }
  });
}

export function emoji() {
  return transformer;
}

export const EmojiRenderer: CodeComponent = ({children}) => {
  const rawString = String(children).replace(/\n$/, '');

  if (hasEmoji(rawString)) {
    const words = rawString.split(/(:\+1:|:-1:|:[\w-]+:)/g);

    return (
      <>
        {words.map((word, i) => {
          return hasEmoji(word) ? (
            <em-emoji key={i} shortcodes={word} set="native" size="17px"></em-emoji>
          ) : (
            word
          );
        })}
      </>
    );
  }

  return <code>{children}</code>;
};
