import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type CoursebookInstance, type UnitInstance} from 'store/interface';

import {
  ACTIVATE_UNIT_INSTANCE,
  ACTIVATE_UNIT_INSTANCE_EVENT,
  CLEAR_SELECTED_COURSEBOOK_INSTANCE,
  CLEAR_UNIT_INSTANCES,
  COMPLETE_UNIT_INSTANCE_EVENT,
  DEACTIVATE_UNIT_INSTANCE,
  DEACTIVATE_UNIT_INSTANCE_EVENT,
  REQUEST_COURSEBOOK_INSTANCE,
  SELECTED_CBI_INCREMENT_COMPLETED_UNITS,
  SET_SELECTED_COURSEBOOK_INSTANCE,
  SET_UNIT_INSTANCES,
  TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK,
  UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES,
  UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES
} from './actionTypes';

export interface SetUnitInstancesAction extends Action {
  instances: UnitInstance[];
}

export const setUnitInstances = (instances: UnitInstance[]): SetUnitInstancesAction => ({
  type: SET_UNIT_INSTANCES,
  instances
});

export const clearUnitInstances = () => ({
  type: CLEAR_UNIT_INSTANCES
});

export const activateUnit: (unitInstanceId: number) => AxiosRequestAction = unitInstanceId => ({
  type: ACTIVATE_UNIT_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/unit-instance/${unitInstanceId}/activate`
    }
  }
});

export const deactivateUnit: (unitInstanceId: number) => AxiosRequestAction = unitInstanceId => ({
  type: DEACTIVATE_UNIT_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/unit-instance/${unitInstanceId}/deactivate`
    }
  }
});

export interface UnitEventAction extends Action {
  unitId: number;
}

export interface CompletedUnitEventAction extends UnitEventAction {
  completedAt: string;
}

export const deactivateUnitInstanceEvent = (unitId: number): UnitEventAction => ({
  type: DEACTIVATE_UNIT_INSTANCE_EVENT,
  unitId
});

export const activateUnitInstanceEvent = (unitId: number): UnitEventAction => ({
  type: ACTIVATE_UNIT_INSTANCE_EVENT,
  unitId
});

export const completeUnitInstanceEvent = (
  unitId: number,
  completedAt: string
): CompletedUnitEventAction => ({
  type: COMPLETE_UNIT_INSTANCE_EVENT,
  unitId,
  completedAt
});

export interface UnitInstanceAction extends Action {
  unitInstanceId: number;
}

export const unitInstanceIncrementCompletedExercises = (
  unitInstanceId: number
): UnitInstanceAction => ({
  type: UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES,
  unitInstanceId
});

export const unitInstanceDecrementCompletedExercises = (
  unitInstanceId: number
): UnitInstanceAction => ({
  type: UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES,
  unitInstanceId
});

export const requestCoursebookInstance = (
  coursebookInstanceId: string,
  params: Record<string, unknown> = {
    expand: 'overview, coursebook.overview, hasGrammar'
  }
): AxiosRequestAction => ({
  type: REQUEST_COURSEBOOK_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/coursebook-instance/${coursebookInstanceId}`,
      params
    }
  }
});

export interface SetSelectedCoursebookInstanceAction extends Action {
  instance: CoursebookInstance;
}

export interface SetSelectedCoursebookInstanceSelfCheckEnabledAction extends Action {
  selfCheckEnabled: boolean;
}

export const setSelectedCoursebookInstance = (
  instance: CoursebookInstance
): SetSelectedCoursebookInstanceAction => ({
  type: SET_SELECTED_COURSEBOOK_INSTANCE,
  instance
});

export const toggleCoursebookInstanceSelfCheck = (
  selfCheckEnabled: boolean
): SetSelectedCoursebookInstanceSelfCheckEnabledAction => ({
  type: TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK,
  selfCheckEnabled
});

export interface IncrementCBICompletedUnitsAction extends Action {
  isTest?: boolean;
}

export const selectedCBIIncrementCompletedUnits = (
  isTest?: boolean
): IncrementCBICompletedUnitsAction => ({
  type: SELECTED_CBI_INCREMENT_COMPLETED_UNITS,
  isTest
});

export const clearSelectedCoursebookInstance = (): Action => ({
  type: CLEAR_SELECTED_COURSEBOOK_INSTANCE
});
