import {type IntlShape} from 'react-intl';
import * as yup from 'yup';

import {WidgetType} from 'store/exercise/player/interface';
import {record} from 'immutable-record/decorator/record';

import validationMessages from '../i18n';
import XWidgetRecord from '../XWidgetRecord';

class XUnknownRecord extends XWidgetRecord {
  public get type() {
    return WidgetType.UNKNOWN;
  }

  public get excerpt() {
    return 'Unknown';
  }

  public schema(intl: IntlShape) {
    return yup.object({
      task: yup
        .mixed()
        .test(
          'This widget can`t be saved',
          intl.formatMessage(validationMessages.UnknownWidgetInExercise),
          () => false
        )
    });
  }
}
record()(XUnknownRecord);
export default XUnknownRecord;
