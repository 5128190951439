import classNames from 'classnames';
import React, {memo} from 'react';
import {useIntl} from 'react-intl';

import {type Fuzzy} from '../../interfaces';
import {useSearchingInODContext} from '../../contexts/searchingInODContext';
import {CrossLink} from '../CrossLink/CrossLink';
import {ScrambledList} from '../ScrambledList/ScrambledList';
import {useFuzzyApiRequest} from '../../hooks/useFuzzyApiRequest';

import './WordNotFound.scss';

interface Props {
  word?: string;
  fuzzy?: Fuzzy[];
  scrambledWord: string[];
  currentScrambledWord: string | undefined;
  isSidebar: boolean;
  isScrambledArticleNotFound: boolean;
}

export const WordNotFound: React.FC<Props> = memo(
  ({word, fuzzy, scrambledWord, currentScrambledWord, isSidebar, isScrambledArticleNotFound}) => {
    const {formatMessage} = useIntl();
    const {sendScrambledWordApiRequest} = useSearchingInODContext();

    const sendFuzzyWordApiRequest = useFuzzyApiRequest(isSidebar);

    const hasFuzzyResult = !!fuzzy;

    return (
      <div className={classNames('word-not-found', {'is-sidebar': isSidebar})}>
        {!!currentScrambledWord && (
          <ScrambledList scrambledWord={scrambledWord} currentScrambleWord={currentScrambledWord} />
        )}
        <div className="word-not-found__word">{word}</div>
        <div className="word-not-found__text">{formatMessage({id: 'Dictionary.NotFound'})}</div>
        {!isScrambledArticleNotFound && hasFuzzyResult && (
          <FuzzyResult fuzzy={fuzzy} onLinkClick={sendFuzzyWordApiRequest} />
        )}
        {!isScrambledArticleNotFound && !hasFuzzyResult && scrambledWord.length > 1 && (
          <SeeMoreResult scrambledWord={scrambledWord} onLinkClick={sendScrambledWordApiRequest} />
        )}
      </div>
    );
  }
);

const SeeMoreResult: React.FC<{scrambledWord: string[]; onLinkClick: (word: string) => void}> = ({
  scrambledWord,
  onLinkClick
}) => {
  const {formatMessage} = useIntl();

  return (
    <div className="word-not-found__see-also">
      {scrambledWord.map(item => (
        <div key={item} className="word-not-found__see-also-item">
          <span className="word-not-found__see-also-label">
            {formatMessage({id: 'Dictionary.SeeAlso'})}
          </span>
          <CrossLink id={item} text={item} onLinkClick={onLinkClick} isInvertColor />
        </div>
      ))}
    </div>
  );
};

const FuzzyResult: React.FC<{fuzzy: Fuzzy[]; onLinkClick: (id: string, word: string) => void}> = ({
  fuzzy,
  onLinkClick
}) => {
  const {formatMessage} = useIntl();

  return (
    <div className="word-not-found__see-also">
      <span className="word-not-found__see-also-label">
        {formatMessage({id: 'Dictionary.PerhapsYouMeant'})}
      </span>
      {fuzzy.map(item => (
        <div key={item.id} className="word-not-found__see-also-item">
          <CrossLink id={item.id} text={item.label} onLinkClick={onLinkClick} isInvertColor />
        </div>
      ))}
    </div>
  );
};
