import {type FC, memo} from 'react';

import {type EnglishLexicalEntryType} from '../../interfaces';
import {EnglishEntryItem} from '../EnglishEntryItem/EnglishEntryItem';

interface Props {
  lexicalEntry: EnglishLexicalEntryType;
  includedTranslations: Record<string, string>;
  isSidebar: boolean;
  dictionaryIsReadonly: boolean;
  addWord: (translation: string) => void;
  deleteWord: (translation: string) => void;
}

export const EnglishEntries: FC<Props> = memo(
  ({lexicalEntry, includedTranslations, isSidebar, dictionaryIsReadonly, addWord, deleteWord}) => {
    return (
      <>
        {lexicalEntry.entries?.map(entry =>
          entry.senses?.map((sense, index) => {
            if (!sense?.definitions?.length) return null;

            return (
              <EnglishEntryItem
                key={index}
                includedTranslation={includedTranslations[sense.definitions[0]]}
                sense={sense}
                isSidebar={isSidebar}
                addWord={addWord}
                deleteWord={deleteWord}
                dictionaryIsReadonly={dictionaryIsReadonly}
              />
            );
          })
        )}
      </>
    );
  }
);
