import React, {type FC} from 'react';

import {ReduxProvider} from './providers/ReduxProvider';
import {StateProvider} from './providers/StateProvider';
import {Sidebar} from './components/Sidebar';
import {type ProviderProps} from './providers/types';

export enum FloatSidebarPosition {
  Left = 'left',
  Right = 'right'
}

export enum FloatSidebarProvider {
  Redux = 'redux',
  State = 'state'
}

interface Props {
  provider?: FloatSidebarProvider;
  position?: FloatSidebarPosition;
  breakpoint?: number;
  className?: string;
  layoutCollapsed?: boolean;
  sidebarPulledOut?: boolean;
  collapsedButton: (clickHandler: () => void, sidebarCollapsed: boolean) => JSX.Element;
}

export const FloatSidebar: FC<Props> = ({
  provider = FloatSidebarProvider.Redux,
  position = FloatSidebarPosition.Right,
  layoutCollapsed,
  sidebarPulledOut,
  ...restProps
}) => {
  const getCustomProps = (providerProps: ProviderProps) => {
    return layoutCollapsed !== undefined && providerProps.collapsed !== undefined
      ? {
          layoutCollapsed,
          sidebarCollapsed: layoutCollapsed || providerProps.collapsed
        }
      : sidebarPulledOut !== undefined
        ? {sidebarPulledOut}
        : {};
  };

  switch (provider) {
    case FloatSidebarProvider.Redux:
      return (
        <ReduxProvider>
          {providerProps => <Sidebar position={position} {...restProps} {...providerProps} />}
        </ReduxProvider>
      );

    case FloatSidebarProvider.State:
      return (
        <StateProvider>
          {providerProps => (
            <Sidebar
              position={position}
              {...restProps}
              {...providerProps}
              {...getCustomProps(providerProps)}
            />
          )}
        </StateProvider>
      );

    default:
      throw Error('Unknown data provider');
  }
};
