import React, {type FC, useCallback, useContext, useMemo} from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import classNames from 'classnames';

import {deselectEntries, selectEntries} from './contexts/entry/actions';
import {EditEntryActionsContext} from './contexts/entry/EditEntryContext';
import {type EntrySelectionMode} from './contexts/entry/interface';

interface Props {
  chunkIds: string[];
  selectionMode: EntrySelectionMode;
}

export const DateChunkCheckbox: FC<Props> = ({chunkIds, selectionMode}) => {
  const {dispatch} = useContext(EditEntryActionsContext);

  const checkedObject = useMemo<{any: boolean; all: boolean}>(() => {
    const result = {any: false, all: true};
    for (const id of chunkIds) {
      const includes = selectionMode.entryIds.includes(id);
      if (!includes) result.all = false;
      else result.any = true;
    }

    return result;
  }, [chunkIds, selectionMode]);

  const toggleMassSelection = useCallback(() => {
    dispatch(checkedObject.all ? deselectEntries(chunkIds) : selectEntries(chunkIds));
  }, [checkedObject.all, chunkIds, dispatch]);
  return (
    <Checkbox
      className={classNames({'partially-selected': checkedObject.any && !checkedObject.all})}
      onChange={toggleMassSelection}
      checked={checkedObject.all}
    >
      <span />
    </Checkbox>
  );
};
