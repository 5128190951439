import {type FC, type KeyboardEventHandler, memo, useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import classNames from 'classnames';

import {push} from 'store/router';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {useDictionarySearchPlaceholder} from 'components/Dictionary/hooks/useDictionarySearchPlaceholder';
import {searchTimeout} from 'config/static';
import isShortcut from 'helpers/shortcut';
import Icon from 'components/Icon';
import {setSearch} from 'store/dictionary/actions';
import {DictionarySearchMode} from 'common/enums';

import './DictionarySearchField.scss';

interface Props {
  isTeacher: boolean;
  isTeacherDictionary: boolean;
  value: string;
  isSidebar?: boolean;
  onSidebarDictionaryReset?: () => void;
  setValue: (value: string) => void;
  onEnter: () => void;
}

export const DictionarySearchField: FC<Props> = memo(
  ({
    isTeacher,
    isTeacherDictionary,
    value,
    isSidebar,
    onSidebarDictionaryReset,
    setValue,
    onEnter
  }) => {
    const {search} = useLocation();
    const dispatch = useDispatch();

    const timeout = useRef<NodeJS.Timeout>();
    const searchRef = useRef<string>();
    const selectedSearchModeRef = useRef<string>();

    const {
      searchMode,
      changeSearchValue,
      setCurrentSearchWord,
      setCurrentWord,
      closeResultOfSearchingInOD
    } = useSearchingInODContext();

    searchRef.current = search;
    selectedSearchModeRef.current = searchMode;
    const placeholder = useDictionarySearchPlaceholder({
      searchMode,
      isTeacher,
      isTeacherDictionary
    });

    const reset = useCallback(() => {
      if (timeout.current) {
        clearTimeout(timeout.current);
        timeout.current = undefined;
      }
      const searchParams = new URLSearchParams(searchRef.current);
      if (searchParams.get('search')) {
        searchParams.delete('search');
        dispatch(push({search: searchParams.toString()}));
      }
    }, [dispatch]);

    const setSearchValueForDictionaryPage = useCallback(
      (newValue: string) => {
        changeSearchValue(newValue);
        setValue(newValue);
        setCurrentSearchWord(newValue);
        setCurrentWord('');
        if (timeout.current) clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          const searchParams = new URLSearchParams(searchRef.current);
          newValue ? searchParams.set('search', newValue) : searchParams.delete('search');
          dispatch(push({search: searchParams.toString()}));
          timeout.current = undefined;
        }, searchTimeout);
      },
      [changeSearchValue, dispatch, setCurrentSearchWord, setCurrentWord, setValue]
    );

    const setSearchValueForSidebar = useCallback(
      (newValue: string) => {
        changeSearchValue(newValue);
        setValue(newValue);
        setCurrentSearchWord(newValue);

        if (newValue.trim() === value.trim()) return;

        if (timeout.current) clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
          dispatch(setSearch(newValue.trim() || undefined));
          if (onSidebarDictionaryReset) onSidebarDictionaryReset();
        }, searchTimeout);
      },
      [changeSearchValue, setValue, setCurrentSearchWord, value, dispatch, onSidebarDictionaryReset]
    );

    const onChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (isSidebar) {
          setSearchValueForSidebar(inputValue);
        } else {
          setSearchValueForDictionaryPage(inputValue);
        }
      },
      [isSidebar, setSearchValueForDictionaryPage, setSearchValueForSidebar]
    );

    const onKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
      event => {
        if (isShortcut(event, 'esc')) {
          isSidebar && onSidebarDictionaryReset ? onSidebarDictionaryReset() : reset();
        }
        if (isShortcut(event, 'enter')) onEnter();
      },
      [isSidebar, onEnter, onSidebarDictionaryReset, reset]
    );

    const onEnterRef = useRef(onEnter);

    useEffect(() => {
      onEnterRef.current = onEnter;
    }, [onEnter]);

    useEffect(() => {
      if (searchMode !== DictionarySearchMode.MY_DICTIONARY && isSidebar) {
        onEnterRef.current();
      }

      return () => setCurrentSearchWord('');
    }, [isSidebar, onEnterRef, searchMode, setCurrentSearchWord]);

    const clearSearchField = useCallback(() => {
      isSidebar ? setSearchValueForSidebar('') : setSearchValueForDictionaryPage('');

      changeSearchValue('');
      setCurrentSearchWord('');
      setCurrentWord('');

      if (searchMode === DictionarySearchMode.MY_DICTIONARY) {
        closeResultOfSearchingInOD();
      }
    }, [
      changeSearchValue,
      closeResultOfSearchingInOD,
      isSidebar,
      searchMode,
      setCurrentSearchWord,
      setCurrentWord,
      setSearchValueForDictionaryPage,
      setSearchValueForSidebar
    ]);

    return (
      <div className={classNames('dictionary-search-field', {empty: !value.length})}>
        <input
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          className="dictionary-search-field__field"
        />
        <div className="dictionary-search-field__icon-container">
          <Icon name="close" onClick={clearSearchField} />
        </div>
      </div>
    );
  }
);
