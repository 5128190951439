import {type Action} from 'redux';

import {type ResourceAction, type ProviderAction, type ViewerState} from '../interface';
import * as actionTypes from './actionTypes';
import providersReducer from './providersReducer';

const actionHandlers = {
  [actionTypes.ACTIVATE_RESOURCE]: (
    state: ViewerState,
    {providerId, resourceId, resourceType}: ResourceAction
  ): ViewerState => ({
    ...state,
    activeProviderId: providerId,
    activeResourceId: resourceId,
    resourceType
  }),
  [actionTypes.DEACTIVATE_RESOURCE]: ({
    activeResourceId,
    activeProviderId,
    resourceType,
    ...rest
  }: ViewerState) => rest,
  [actionTypes.UNREGISTER_RESOURCE]: (
    state: ViewerState,
    {providerId, resourceId, resourceType}: ResourceAction
  ): ViewerState => {
    const {activeResourceId, activeProviderId, providers} = state;
    if (providerId === activeProviderId && resourceId === activeResourceId) {
      const typeResources = providers.find(p => p.id === providerId)?.resources[resourceType];

      if (!typeResources || typeResources.length === 1) return state;

      const index = typeResources.map(tr => tr.id).indexOf(resourceId);

      if (index === -1) return state;

      return {...state, activeResourceId: typeResources[index ? index - 1 : index + 1]?.id};
    }
    return state;
  },
  [actionTypes.UNREGISTER_PROVIDER]: (
    state: ViewerState,
    {providerId}: ProviderAction
  ): ViewerState => (state.activeProviderId === providerId ? {providers: state.providers} : state)
};

export const initialState: ViewerState = {
  providers: []
};

const reducer = (state: ViewerState, action: Action): ViewerState => {
  if (Object.values(actionTypes).includes(action.type)) {
    return {
      ...(actionHandlers[action.type] ? actionHandlers[action.type](state, action) : state),
      providers: providersReducer(state.providers, action)
    };
  }
  return state;
};

export default reducer;
