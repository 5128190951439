import {Iterable, OrderedMap} from 'immutable';

import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {type Role} from 'store/interface';
import {decorate} from 'immutable-record/decorate.util';
import {type ExerciseJSON, type ExerciseRecordMap} from 'store/exercise/player/interface';

import {type XPreviewProperties} from './interface';
import ExerciseRecord from '../Exercise/ExerciseRecord';
import {type ExerciseProperties} from '../Exercise/interface';

const Record = recordBase()(BaseRecord);

interface Config {
  role?: Role;
  addExerciseNumber?: boolean;
  pageNumber?: number;
}

class PreviewRecord extends Record implements XPreviewProperties {
  public declare readonly role: Role;

  public declare readonly addExerciseNumber: boolean;

  public declare readonly exercises: ExerciseRecordMap;

  private static initExercises(exercise: ExerciseRecord | ExerciseJSON[]): ExerciseRecordMap {
    return Iterable.isIterable(exercise)
      ? OrderedMap([[(exercise as ExerciseRecord).id || 0, exercise]])
      : OrderedMap((exercise as ExerciseJSON[]).map(e => [e.id, ExerciseRecord.fromJSON(e)]));
  }

  private static setExerciseNumber(exercises: ExerciseRecordMap, pageNumber: number) {
    let exerciseNumber = 1;

    return exercises.map((exercise: ExerciseProperties) => {
      const widgets = exercise.widgets;

      return exercise.set(
        'widgets',
        widgets.set(
          exercise.headWidgetIndex,
          widgets.get(exercise.headWidgetIndex).setLabel(`${pageNumber}.${exerciseNumber++}`)
        )
      );
    });
  }

  constructor(exercise: ExerciseRecord | ExerciseJSON[], config: Config = {}) {
    super();

    const {role = 'student', addExerciseNumber = false, pageNumber = 1} = config;

    this.initValues({
      role,
      addExerciseNumber,
      exercises: addExerciseNumber
        ? PreviewRecord.setExerciseNumber(PreviewRecord.initExercises(exercise), pageNumber)
        : PreviewRecord.initExercises(exercise)
    });
  }
}

decorate(PreviewRecord, {
  role: property('student'),
  addExerciseNumber: property(false),
  exercises: property(undefined)
});
record()(PreviewRecord);
export default PreviewRecord;
