import {
  FontSizePaint,
  PaintTool,
  SelectablePaint,
  ToolsPaint,
  useFocused,
  usePaint
} from '@englex/paint-react';
import {Paint, type PaintText} from '@englex/paint';

export function useFontSize() {
  const paint = usePaint<FontSizePaint>();
  const isFocused = useFocused();

  const [min, max] = [FontSizePaint.getMinSize(paint), FontSizePaint.getMaxSize(paint)];

  const [selectedNode] = Paint.selectedEntry<PaintText>(paint);
  const isSelectedText = SelectablePaint.isEnabled(paint) && selectedNode?.className === 'Text';
  const fontSize = isSelectedText ? selectedNode?.attrs.fontSize : paint.fontSize.value;
  const isActive = isSelectedText || ToolsPaint.getTool(paint) === PaintTool.Text;
  const isDisabled = !isFocused || !isActive;

  return {fontSize, isActive, isDisabled, min, max, isSelectedText, selectedNode};
}
