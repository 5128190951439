import {defineMessages} from 'react-intl';

export default defineMessages({
  hidden: {
    id: 'SupplementaryExercisesModal.Hidden'
  },
  inClass: {
    id: 'SupplementaryExercisesModal.InClass'
  },
  atHome: {
    id: 'SupplementaryExercisesModal.AtHome'
  },
  savingStatesError: {
    id: 'SupplementaryExercisesModal.SavingStatesError'
  }
});
