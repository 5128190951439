export const CONTENT_CHANGE = 'CONTENT_CHANGE';

export const TURN_QUESTIONS_REVIEW_ON = 'TURN_QUESTIONS_REVIEW_ON';
export const TURN_QUESTIONS_REVIEW_OFF = 'TURN_QUESTIONS_REVIEW_OF';

export const QUEST_CONTENT_CHANGE = 'QUEST_CONTENT_CHANGE';
export const TURN_QUEST_REVIEW_ON = 'TURN_QUEST_REVIEW_ON';
export const TURN_QUEST_REVIEW_OFF = 'TURN_QUEST_REVIEW_OFF';

export const TURN_QUESTION_FIELDS_ON = 'TURN_QUESTION_FIELDS_ON';
export const TURN_QUESTION_FIELDS_OFF = 'TURN_QUESTION_FIELDS_OFF';

export const TURN_ESSAY_REVIEW_ON = 'TURN_ESSAY_REVIEW_ON';
export const TURN_ESSAY_REVIEW_OFF = 'TURN_ESSAY_REVIEW_OFF';

export const DROP_ANSWER = 'DROP_ANSWER';
export const CLEAR_ANSWER = 'CLEAR_ANSWER';
export const TOGGLE_COLLAPSIBLE = 'TOGGLE_COLLAPSIBLE';
export const SET_VOCABULARY_WIDGET_INFO = 'SET_VOCABULARY_WIDGET_INFO';

export const LABEL_FIRST_TASK = 'LABEL_FIRST_TASK';

export const SELECT_CARD = 'SELECT_CARD';

export const CHANGE_CARD_LABEL = 'CHANGE_CARD_LABEL';
