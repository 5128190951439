import {type AxiosRequestAction} from 'services/axios/interface';

import {
  LOAD_GRAMMAR,
  LOAD_GRAMMAR_EXERCISE,
  LOAD_GRAMMAR_PREVIEW,
  REQUEST_GRAMMAR_LIST
} from './actionTypes';

export const requestGrammarList = (
  coursebookInstanceId: string,
  original?: boolean,
  grammarFilter?: number[]
): AxiosRequestAction => ({
  type: REQUEST_GRAMMAR_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook${original ? '' : '-instance'}/${coursebookInstanceId}/grammar`,
      params: {
        withSlateTitle: false,
        grammar: grammarFilter
      }
    }
  }
});

export const loadGrammarPreview = (
  coursebookId: string,
  unitExerciseId?: string
): AxiosRequestAction => ({
  type: LOAD_GRAMMAR_PREVIEW,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook/${coursebookId}/grammar/${unitExerciseId}`
    }
  }
});

export const loadGrammarInstance = (exerciseInstanceId: string): AxiosRequestAction => ({
  type: LOAD_GRAMMAR,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/exercise-instance/${exerciseInstanceId}/grammar`
    }
  }
});

export const loadGrammarOriginal = (
  grammarId: string,
  unitInstanceId: number,
  coursebookInstanceId: string
): AxiosRequestAction => ({
  type: LOAD_GRAMMAR_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/coursebook-instance/${coursebookInstanceId}/unit/${unitInstanceId}/grammar/${grammarId}`
    }
  }
});
