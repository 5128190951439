import {type ComponentType, type FC, type PropsWithChildren} from 'react';

import {type Role} from 'store/interface';
import {useRole} from 'hooks/user/useRole';

export function visibleOnlyForRoles(role: Role | Role[], FallbackComponent?: ComponentType) {
  return <P extends PropsWithChildren<{}> = {}>(Component: ComponentType<P>): FC<P> =>
    (props: P) => {
      const currentRole = useRole();
      const allowedRoles = Array.isArray(role) ? role : [role];

      const isVisible = currentRole && allowedRoles.includes(currentRole);
      if (!isVisible) return FallbackComponent ? <FallbackComponent /> : null;

      return <Component {...props} />;
    };
}

export function visibleOnlyStudent<P extends PropsWithChildren<{}>>(
  Component: ComponentType<P>
): FC<P> {
  return visibleOnlyForRoles('student')(Component);
}

export function visibleOnlyTeacher<P extends PropsWithChildren<{}>>(
  Component: ComponentType<P>
): FC<P> {
  return visibleOnlyForRoles('teacher')(Component);
}

export function visibleOnlyAcademic<P extends PropsWithChildren<{}>>(
  Component: ComponentType<P>
): FC<P> {
  return visibleOnlyForRoles(['student', 'teacher'])(Component);
}

export function visibleOnlyMethodist<P extends PropsWithChildren<{}>>(
  Component: ComponentType<P>
): FC<P> {
  return visibleOnlyForRoles('methodist')(Component);
}
