import React from 'react';
import classNames from 'classnames';

import {TextMessageTypes} from 'common/enums';

import {type MessageType} from './TextMessage';

interface Props {
  type: MessageType;
  own?: boolean;
  updatingThisMessage: boolean;
}

export default class MessageArrow extends React.PureComponent<Props, {}> {
  public render() {
    const {type, own, updatingThisMessage} = this.props;
    if (type in TextMessageTypes) {
      const arrowClass = classNames(
        'chat-message-arrow',
        {own},
        {'being-updated': updatingThisMessage}
      );
      return <div className={arrowClass} />;
    } else {
      return null;
    }
  }
}
