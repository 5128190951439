import type {Middleware, Reducer, Store} from 'redux';

import {locationChangeAction} from './actions';
import {type Router, type Location, type RouterState} from './interface';
import {createRouterMiddleware} from './middleware';
import {createRouterReducer} from './reducer';

interface Options {
  routerReducerKey?: string;
  reduxTravelling?: boolean;
  passThroughNavigateAction?: boolean;
  selectRouterState?: <S>(state: S) => RouterState;
  savePreviousLocations?: number;
  batch?: (callback: () => void) => void;
}

interface CreateReduxRouterContext {
  connectRouter: (store: Store, router: Router) => Router;
  routerMiddleware: Middleware;
  routerReducer: Reducer<RouterState>;
}

export const createReduxRouterContext = ({
  routerReducerKey = 'router',
  reduxTravelling = false,
  passThroughNavigateAction = false,
  selectRouterState,
  savePreviousLocations = 0,
  batch
}: Options = {}): CreateReduxRouterContext => {
  let routerInstance: Router | undefined;

  const getRouter = (): Router => {
    if (!routerInstance) {
      throw new Error('router is not defined. Consider using connectRouter');
    }
    return routerInstance;
  };

  const handleState = typeof batch !== 'function' ? (fn: () => void) => fn() : batch;

  const getRouterState: <S>(state: S) => RouterState =
    typeof selectRouterState !== 'function'
      ? <S>(state: S) => state[routerReducerKey]
      : selectRouterState;

  const routerReducer = createRouterReducer({savePreviousLocations});
  const routerMiddleware = createRouterMiddleware({getRouter, passThroughNavigateAction});

  let isReduxTravelling = false;

  const handleReduxTravelling = (store: Store) => {
    const router = getRouter();
    const locationEqual = (loc1: Location, loc2: Location) =>
      loc1.pathname === loc2.pathname && loc1.search === loc2.search && loc1.hash === loc2.hash;

    return store.subscribe(() => {
      const sLoc = getRouterState(store.getState()).location;
      const hLoc = router.state.location;
      if (sLoc && hLoc && !locationEqual(sLoc, hLoc)) {
        isReduxTravelling = true;
        router.navigate({pathname: sLoc.pathname, search: sLoc.search, hash: sLoc.hash});
      }
    });
  };

  const connectRouter = (store: Store, router: Router): Router => {
    routerInstance = router;

    // init location store
    store.dispatch(locationChangeAction(router.state.location, router.state.historyAction));

    if (reduxTravelling) {
      handleReduxTravelling(store);
    }

    router.subscribe(({location, historyAction: action}) => {
      if (isReduxTravelling) {
        isReduxTravelling = false;
        return;
      }
      handleState(() => {
        store.dispatch(locationChangeAction(location, action));
      });
    });

    return router;
  };

  return {routerReducer, routerMiddleware, connectRouter};
};
