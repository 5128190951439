import React, {useCallback, useEffect, useMemo, useState, memo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import {type List} from 'immutable';
import {useIntl} from 'react-intl';

import {push, replace} from 'store/router';
import Loader from 'components/Loader';
import PageControls from 'components/PageControls';
import {Pager} from 'components/UnitContents/Pager';
import WampErrorMask from 'components/WampErrorMask';
import {type ExerciseLocation} from 'store/exercise/player/interface';
import {Contents} from 'components/UnitContents/Contents/Contents';
import {useContentsList} from 'components/UnitContents/useContenstList';
import {GrammarContentsItem} from 'components/UnitContents/Contents/GrammarContentsItem';
import {SharingButton} from 'components/ui/SharingButton/SharingButton';
import SearchBar from 'components/SearchBar';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {usePagesPopoverSync} from 'hooks/player/usePagesPopoverSync';
import {useSentChatMessage} from 'hooks/chat/useSentChatMessage';
import {
  useWebViewMessage,
  type WebViewMessageData,
  WebViewMessageType
} from 'hooks/webview/useWebViewMessage';
import {clipboardCopy} from 'helpers/navigator';
import {isMobileWebView} from 'helpers/browser';
import * as toastr from 'components/toastr';
import {type AppState, type Profile, type Role} from 'store/interface';
import {useDictionaryContext} from 'components/Dictionary/shared/contexts';
import {PageNotFound} from 'routes/PageNotFound/components/PageNotFound';
import {
  type CoursebookInstanceUrlParams,
  grammarPlayerPath,
  grammarStandalonePlayerPath
} from 'common/paths';

import {type Grammar, type GrammarList} from './interface';
import {requestGrammarList} from './actions';
import GrammarExercise from './GrammarExercise';
import ViewGrammarInCoursebook from './ViewGrammarInCoursebook';

import './GrammarPlayerPage.scss';

enum SharingButtonActions {
  Copy = 'copy',
  Send = 'send'
}

export type GrammarRouterParams = CoursebookInstanceUrlParams & {
  grammarId: string;
};

interface Props {
  coursebookId?: string;
  isInUnitPlayer?: boolean;
  isInHomeworkPlayer?: boolean;
  grammarFilter?: List<{id: number}>;
  hasPointer?: boolean;
  isModal?: boolean;
}

const GrammarPlayerPage: React.FC<Props> = memo(
  ({coursebookId, isInUnitPlayer, isInHomeworkPlayer, grammarFilter, hasPointer, isModal}) => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const {pathname} = useLocation();
    const params = useParams<GrammarRouterParams>();

    const {coursebookInstanceId, grammarId} = params;

    const shouldSelectFirstGrammar = !grammarId && (isInUnitPlayer || isInHomeworkPlayer);
    const isWebviewListenerActive = !isInUnitPlayer && !isInHomeworkPlayer;

    const {send} = useSentChatMessage();

    const role = useSelector<AppState, Role>(state => state.user.role!);

    const [isErrorOnPageRequest, setIsErrorOnPageRequest] = useState(false);
    const [grammarList, setGrammarList] = useState<GrammarList>([]);
    const [exerciseLocation, setExerciseLocation] = useState<ExerciseLocation>();
    const [grammarExist, setGrammarExist] = useState(true);
    const [loaded, setLoaded] = useState(false);

    const grammarFilterArray = useMemo<string | undefined>(
      () => grammarFilter?.map(grammar => grammar?.id).join(','),
      [grammarFilter]
    );

    const [search, setSearch] = useState('');
    const [previewPage, setPreviewPage] = useState<number | undefined>();
    const [previewGrammar, setPreviewGrammar] = useState<Grammar | undefined>();

    const {isTeacherDictionary} = useDictionaryContext();

    const searchWords = useMemo(() => search.split(' ').filter(Boolean), [search]);

    const sharingButtonItems = useMemo(() => {
      return {
        [SharingButtonActions.Copy]: {
          value: intl.formatMessage({id: 'XPlayer.Grammar.CopyLinkToClipboard'}),
          icon: 'paste'
        },
        [SharingButtonActions.Send]: {
          value: intl.formatMessage({id: 'XPlayer.Grammar.SendLinkToChat'}),
          icon: 'comment-o'
        }
      };
    }, [intl]);

    const action = useMemo(
      () =>
        requestGrammarList(
          coursebookId || coursebookInstanceId!,
          !isInUnitPlayer && !!coursebookId && !isInHomeworkPlayer,
          grammarFilterArray?.split(',').map(grammarId => Number(grammarId))
        ),
      [coursebookId, grammarFilterArray, isInHomeworkPlayer, isInUnitPlayer, coursebookInstanceId]
    );

    const onChangeSearch = useCallback((value: string) => setSearch(value.trim()), []);

    const grammarFindPredicate = useCallback(
      (grammar: Grammar) => grammar.grammarId === grammarId,
      [grammarId]
    );

    const currentPage = useMemo(() => {
      return grammarId ? grammarList.findIndex(grammarFindPredicate) + 1 : 1;
    }, [grammarFindPredicate, grammarId, grammarList]);

    const selectedGrammar = useCallback(
      (grammarList: GrammarList) => {
        if (shouldSelectFirstGrammar) {
          return grammarList.find(Boolean);
        }

        return grammarList.find(grammarFindPredicate);
      },
      [grammarFindPredicate, shouldSelectFirstGrammar]
    );

    const resolveGrammarIdByPage = useCallback(
      (pageNumber?: number, gl?: GrammarList) => {
        const currentGrammar = gl ? gl : grammarList;
        return pageNumber ? currentGrammar[pageNumber - 1].grammarId : currentGrammar[0].grammarId;
      },
      [grammarList]
    );

    const title = useMemo(() => {
      const grammar = previewGrammar || selectedGrammar(grammarList);
      return grammar ? grammar.title : 'Untitled';
    }, [selectedGrammar, previewGrammar, grammarList]);

    const selectPage = useCallback(
      (pageNumber?: number, gl?: GrammarList) => {
        if (coursebookId) {
          setPreviewPage(pageNumber);
          setPreviewGrammar(grammarList[pageNumber! - 1]);
        } else {
          dispatch(
            push(
              grammarPlayerPath(
                {
                  coursebookInstanceId: params.coursebookInstanceId!,
                  page: params.page,
                  studentTeacherId: params.studentTeacherId,
                  courseId: params.courseId
                },
                resolveGrammarIdByPage(pageNumber, gl)
              )
            )
          );
        }
      },
      [coursebookId, dispatch, grammarList, params, resolveGrammarIdByPage]
    );

    const reqSuccessHandler = useCallback(
      (gl: GrammarList) => {
        if (gl.length === 0) {
          setGrammarExist(false);
          return;
        }
        if (!selectedGrammar(gl)) {
          setGrammarExist(false);
        }
        setGrammarList(gl);
      },
      [selectedGrammar]
    );

    const reqFailHandler = useCallback(() => {
      setGrammarExist(false);
      toastr.error('', intl.formatMessage({id: 'XPlayer.Exercise.Grammar.Modal.ContentLoadFail'}));
    }, [intl]);

    const {isError, isLoading, reload} = useApiRequest(action, reqSuccessHandler, reqFailHandler);

    const pageCount = grammarList.length;

    useEffect(() => {
      if (!grammarId && pageCount !== 0 && !coursebookId) {
        setGrammarExist(true);
        dispatch(replace(`${pathname}/${resolveGrammarIdByPage(1, grammarList)}`));
      }
    }, [
      coursebookId,
      dispatch,
      grammarId,
      grammarList,
      pageCount,
      pathname,
      resolveGrammarIdByPage
    ]);

    const {
      hasNext,
      hasPrev,
      listIsOpened,
      toggleList,
      listToggleBatched,
      contents,
      batchListToggle
    } = useContentsList(pageCount, previewPage || currentPage, undefined, grammarList);

    const filteredContents = useMemo(() => {
      const preparedContent = (contents as string[])?.map((title: string, index: number) => ({
        title,
        index: index + 1
      }));

      if (preparedContent && search) {
        return preparedContent.filter(({title}) =>
          title.toLowerCase().includes(search.toLowerCase())
        );
      }

      return preparedContent || [];
    }, [search, contents]);

    usePagesPopoverSync(listIsOpened);

    const onMessage = useCallback(
      (message: WebViewMessageData) => {
        const {type} = message;

        switch (type) {
          case WebViewMessageType.Prev:
            return dispatch(
              replace(
                grammarStandalonePlayerPath(
                  coursebookInstanceId!,
                  resolveGrammarIdByPage(currentPage - 1)
                )
              )
            );

          case WebViewMessageType.Next:
            return dispatch(
              replace(
                grammarStandalonePlayerPath(
                  coursebookInstanceId!,
                  resolveGrammarIdByPage(currentPage + 1)
                )
              )
            );

          case WebViewMessageType.SetPage:
            const {
              payload: {pageNumber}
            } = message;

            return dispatch(
              replace(
                grammarStandalonePlayerPath(
                  coursebookInstanceId!,
                  resolveGrammarIdByPage(pageNumber)
                )
              )
            );

          default:
            return;
        }
      },
      [dispatch, resolveGrammarIdByPage, coursebookInstanceId, currentPage]
    );

    const postMessage = useWebViewMessage(onMessage, isWebviewListenerActive);

    useEffect(() => {
      postMessage({type: WebViewMessageType.Ready});
    }, [onMessage, postMessage]);

    const pager = useCallback(
      () => (
        <Pager
          batchListToggle={batchListToggle}
          currentPage={previewPage || currentPage}
          hasNext={hasNext}
          hasPrev={hasPrev}
          listIsOpened={listIsOpened}
          pageCount={pageCount}
          selectPage={selectPage}
          toggleList={toggleList}
        />
      ),
      [
        batchListToggle,
        currentPage,
        hasNext,
        hasPrev,
        listIsOpened,
        pageCount,
        previewPage,
        selectPage,
        toggleList
      ]
    );

    const onChangeSharingButton = useCallback(
      (key: SharingButtonActions) => {
        if (key === SharingButtonActions.Copy) {
          clipboardCopy(window.location.href)
            .then(() =>
              toastr.success('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextSuccess'}))
            )
            .catch(() =>
              toastr.error('', intl.formatMessage({id: 'Dictionary.Toastr.CopyTextError'}))
            );
        }

        if (key === SharingButtonActions.Send) {
          const message = `[${title}](${window.location.href})`;
          send(message)
            .then((profile: Profile) => {
              const {first_name: firstName, last_name: lastName} = profile;

              toastr.success(
                '',
                intl.formatMessage(
                  {id: 'XPlayer.Grammar.SendLinkToChat.SuccessToastr'},
                  {lastName, firstName}
                )
              );
            })
            .catch(() => {
              toastr.error(
                '',
                intl.formatMessage({id: 'XPlayer.Grammar.SendLinkToChat.ErrorToastr'})
              );
            });
        }
      },
      [intl, send, title]
    );

    const shouldShowPager = !isMobileWebView() && (coursebookId ? pageCount !== 1 : true);

    if (isError || isErrorOnPageRequest) {
      return (
        <WampErrorMask
          reload={() => {
            reload();
            setIsErrorOnPageRequest(false);
          }}
        />
      );
    }

    if (!grammarExist) {
      return <PageNotFound />;
    }

    if (!grammarList.length || isLoading) {
      return <Loader />;
    }

    return (
      <div className="grammar-player-page">
        {selectedGrammar(grammarList) ? (
          <GrammarExercise
            selectedGrammar={previewGrammar || selectedGrammar(grammarList)!}
            setLocation={setExerciseLocation}
            cbi={!isInUnitPlayer ? coursebookId : undefined}
            hasPointer={hasPointer}
            setLoaded={setLoaded}
            isModal={isModal}
          />
        ) : (
          <Loader />
        )}
        <Contents
          contents={contents}
          listIsOpened={listIsOpened}
          listToggleBatched={listToggleBatched}
          toggleList={toggleList}
          renderSearch={
            isInUnitPlayer
              ? undefined
              : () => (
                  <SearchBar
                    filter={search}
                    changeFilter={onChangeSearch}
                    isSmall={true}
                    isMobile={true}
                  />
                )
          }
          renderItems={closeSelf => (
            <>
              {filteredContents.map(({title, index}) => (
                <GrammarContentsItem
                  active={index === (previewPage || currentPage)}
                  closeParent={closeSelf}
                  title={title}
                  searchWords={searchWords}
                  index={index}
                  key={index}
                  selectPage={selectPage}
                />
              ))}
            </>
          )}
        />
        <PageControls>
          {shouldShowPager && pager()}
          {!coursebookId ? (
            <>
              <ViewGrammarInCoursebook
                exerciseLocation={exerciseLocation}
                selectedGrammar={previewGrammar || selectedGrammar(grammarList)}
                loaded={loaded}
              />
              <SharingButton
                items={sharingButtonItems}
                onChange={onChangeSharingButton}
                isHidden={role !== 'teacher' || isTeacherDictionary}
              />
            </>
          ) : null}
        </PageControls>
      </div>
    );
  }
);

export default GrammarPlayerPage;
