import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {persistReducer} from 'redux-persist';

import {useInjectReducer} from 'hooks/redux/useInjectReducer';

import {persistDictionaryConfig} from '../persist';
import {activateDictionary, resetDictionary} from './actions';
import {reducer} from './reducer';

// inject dictionary reducer. clear self when user of hook is unmounted
export const useDictionaryStore = () => {
  const dispatch = useDispatch();
  const isInjected = useInjectReducer({
    dictionary: persistReducer(persistDictionaryConfig, reducer)
  });

  useEffect(() => {
    if (isInjected) {
      dispatch(activateDictionary());
      return () => {
        dispatch(resetDictionary());
      };
    }
    return;
  }, [dispatch, isInjected]);
};
