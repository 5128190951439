import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import Icon from 'components/Icon';

import {type ToggleElementCreator} from '../../common/interface';
import {toggleVideoUndocked} from '../action/action';

interface DispatchIndockButtonProps {
  dockIn: ToggleElementCreator;
}

class IndockButton extends React.Component<DispatchIndockButtonProps, {}> {
  public render() {
    return (
      <div className="webrtc-indock" onClick={this.props.dockIn}>
        <div>
          <Icon name="virc-in_dock" />
          <FormattedMessage id="WebRTC.DockVideoCallWindow" />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  dockIn: () => dispatch(toggleVideoUndocked(false))
});

export default connect(null, mapDispatchToProps)(IndockButton);
