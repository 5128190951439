import React from 'react';
import {type WrappedComponentProps} from 'react-intl';

import Icon from 'components/Icon';

import {RTCMessages} from './RTCmessages';

interface Props extends WrappedComponentProps {
  videoFullScreen?: boolean;
  toggleFullScreen: () => void;
  isUndocked?: boolean;
  toggleUndocked: () => void;
}

export default class VideoControlPanel extends React.PureComponent<Props, {}> {
  public render() {
    const {toggleFullScreen, videoFullScreen} = this.props;
    const videoControlButtonHint = videoFullScreen
      ? this.props.intl.formatMessage(RTCMessages.ResetVideoCallWindow)
      : this.props.intl.formatMessage(RTCMessages.ExpandVideoCallWindow);
    return (
      <div className="video-controls" onMouseDown={this.stopEventPropagation}>
        {this.renderVideoControlUndockButton()}
        <Icon
          name={`virc-${videoFullScreen ? '' : 'no_'}clutter`}
          onClick={toggleFullScreen}
          title={videoControlButtonHint}
        />
      </div>
    );
  }

  private renderVideoControlUndockButton = () => {
    const {isUndocked, videoFullScreen, toggleUndocked} = this.props;
    const undockButtonHint = isUndocked
      ? this.props.intl.formatMessage(RTCMessages.DockVideoCallWindow)
      : this.props.intl.formatMessage(RTCMessages.UndockVideoCallWindow);
    if (!videoFullScreen) {
      return (
        <Icon
          name={`virc-${this.props.isUndocked ? 'in' : 'out'}_dock`}
          onClick={toggleUndocked}
          title={undockButtonHint}
        />
      );
    }
    return null;
  };

  private stopEventPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };
}
