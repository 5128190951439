import {useCallback, useEffect, useMemo, useState} from 'react';
import {type Action} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {type AppState} from 'store/interface';
import {makeCancellable} from 'helpers/cancellablePromise';
import {type AxiosRequestAction, type AxiosRequestError} from 'services/axios/interface';

import {useAxiosDispatch} from '../redux/useAxiosDispatch';

type SimpleArgsArray = Array<number | string | boolean>;

export const useApiRequestStateless = <A extends SimpleArgsArray>(
  delayed: boolean,
  actionCreator: (
    ...args: A
  ) => AxiosRequestAction | Action | ThunkAction<void, AppState, never, Action>,
  ...args: A
) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isDelayed, setIsDelayed] = useState(delayed);

  const dispatch = useAxiosDispatch();

  const action = useMemo(() => actionCreator(...args), [actionCreator, ...args]); // eslint-disable-line react-hooks/exhaustive-deps
  const apiRequestEffect = useCallback(() => {
    if (isDelayed) {
      return;
    }
    setIsError(false);
    setIsLoading(true);
    const cancellable = makeCancellable(
      dispatch(action as AxiosRequestAction),
      () => setIsLoading(false),
      (error: AxiosRequestError) => {
        if (import.meta.env.MODE === 'development') {
          console.error('Hook useApiRequestStateless fetch error:', error); // eslint-disable-line no-console
        }
        setIsError(true);
        setIsLoading(false);
      }
    );

    return () => {
      cancellable.cancel();
      setIsDelayed(delayed);
    };
  }, [action, delayed, dispatch, isDelayed, setIsDelayed]);
  useEffect(apiRequestEffect, [apiRequestEffect]);

  return {
    isLoading,
    isError,
    reload: apiRequestEffect,
    delay: setIsDelayed
  };
};
