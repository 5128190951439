import {type PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';

import {type MobileOutlineItem} from 'hooks/webview/useWebViewMessage';
import {type Item} from 'components/PDFViewer/contents/outline/interface';

import {getOutlinePageIndex} from '../components/PDFViewer/contents/outline/util';

async function transformToMobileOutline(
  pdfDocumentProxy: PDFDocumentProxy,
  item: Item
): Promise<MobileOutlineItem> {
  const {title, items} = item;

  const pageIndex = await getOutlinePageIndex(pdfDocumentProxy, item);

  return {
    title,
    pageIndex,
    items: await Promise.all(items.map(item => transformToMobileOutline(pdfDocumentProxy, item)))
  };
}

export async function getMobileOutline(
  pdfDocumentProxy: PDFDocumentProxy
): Promise<MobileOutlineItem[] | null> {
  try {
    const outline = await pdfDocumentProxy.getOutline();

    return await Promise.all(
      outline.map((item: Item) => transformToMobileOutline(pdfDocumentProxy, item))
    );
  } catch {
    return null;
  }
}
