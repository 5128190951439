import {type UriPrefixMap} from './actions/types';

const uriMap: UriPrefixMap = {
  chatroom: 'chat.room',
  classroom: 'classroom',
  chat: 'chat',
  xplayer: 'xplayer',
  system: 'system',
  dev: 'dev',
  dictionary: 'dictionary',
  ydoc: 'ydoc'
};

export default uriMap;
