import React from 'react';
import {FormattedMessage, type WrappedComponentProps} from 'react-intl';
import RetinaImage from '@englex/react-retina-image';
import classNames from 'classnames';

import Icon from '../../../../Icon';
import {chatMessages} from './chatMessages';

interface ChatPictureProps extends WrappedComponentProps {
  src: string;
  onError: (imageId: string) => void;
  onClick?: () => void;
  id: string;
  // true if image was uploaded to server, false if it was parsed from message text
  isUploaded: boolean;
  isCorrupted?: boolean;
  retinaAvailable?: boolean;
}

interface ChatPictureState {
  imageWidth: number;
  imageHeight: number;
  top: number;
  left: number;
  loadingImage: boolean;
}

export default class ChatPicture extends React.Component<ChatPictureProps, ChatPictureState> {
  private static imageSize = 188;

  public state: ChatPictureState = {
    imageHeight: ChatPicture.imageSize,
    imageWidth: ChatPicture.imageSize,
    top: 0,
    left: 0,
    loadingImage: true
  };

  private innerImg?: HTMLImageElement;

  public componentDidMount() {
    this.innerImg!.addEventListener('load', this.onLoad);
  }

  public componentWillUnmount() {
    this.innerImg!.removeEventListener('load', this.onLoad);
  }

  public render() {
    const {intl, isCorrupted, id, src, retinaAvailable} = this.props;
    // if src is array containing one string element, retina version of image won't be loaded
    const imageSrc = retinaAvailable ? src : [src];
    const className = classNames('chat-picture', {
      loading: this.state.loadingImage,
      corrupted: isCorrupted
    });
    return (
      <div
        className={className}
        title={`${isCorrupted ? intl.formatMessage(chatMessages.ImageUnavailable) : ''}`}
      >
        {this.renderImageIcon()}
        <RetinaImage
          src={imageSrc}
          style={{
            width: this.state.imageWidth,
            height: this.state.imageHeight,
            top: this.state.top,
            left: this.state.left
          }}
          width={this.state.imageWidth}
          height={this.state.imageHeight}
          onError={this.onError}
          forceOriginalDimensions={true}
          ref={this.getInnerRef}
          key={id}
        />
        <div className="mask" onClick={this.props.onClick}>
          <div className="mask-content">
            <Icon name="search-plus" size="lg" />
            <FormattedMessage id="Common.Open" />
          </div>
        </div>
      </div>
    );
  }

  private onError = () => this.props.onError(this.props.id);

  private renderImageIcon = () => {
    if (this.props.isCorrupted) {
      return <Icon name="broken-image" size="4x" />;
    }
    if (this.state.loadingImage) {
      return <Icon name="image" size="4x" />;
    }
    return null;
  };

  private onLoad = () => {
    this.setState({loadingImage: false});
    if (this.innerImg && !this.props.isUploaded) {
      if (this.innerImg.naturalWidth < this.innerImg.naturalHeight) {
        const imageHeight =
          this.innerImg.naturalHeight / (this.innerImg.naturalWidth / ChatPicture.imageSize);
        const top = (ChatPicture.imageSize - imageHeight) / 2;
        this.setState({imageHeight, top});
      } else {
        const imageWidth =
          this.innerImg.naturalWidth / (this.innerImg.naturalHeight / ChatPicture.imageSize);
        const left = (ChatPicture.imageSize - imageWidth) / 2;
        this.setState({imageWidth, left});
      }
    }
  };

  private getInnerRef = (el: HTMLImageElement) => {
    this.innerImg = el;
  };
}
