import {type Action} from 'redux';
import {captureException, withScope} from '@sentry/react';

import {
  checkDurationInterval,
  connectionCheckerInterval,
  getCallStatsInterval,
  getUserMediaAllowedTime,
  iceSyntheticFailedTimeout
} from 'config/static';
import {type WampCallResponseAction, type WampErrorAction} from 'services/wamp/actions/interface';

import {type MiddlewareAPI} from './middleware';
import {
  callEnd,
  changeCallDuration,
  changeCallMediaDevice,
  changeCallStatus,
  getRTCSettings,
  hangUp,
  sendIceCandidate,
  toggleRecall,
  upgradeCall,
  wampOffered,
  wampSendState
} from './action/action';
import {
  type ConnectionComponent,
  type StateType,
  type WampSendStateAction
} from './action/interface';
import {
  WAMP_GET_RTC_SETTINGS_FAIL,
  WAMP_GET_RTC_SETTINGS_SUCCESS,
  WAMP_UPGRADE_CALL_FAIL
} from './action/actionTypes';
import {
  type BitrateInfo,
  type CandidatePairStat,
  type CandidateStat,
  type ConnectedIceInfo,
  type RTCConfig
} from './types';
import {RTCConnectionChecker} from './RTCConnectionChecker';
import {isSafari} from '../helpers/browser';

const isLogEnabled: boolean = import.meta.env.REACT_APP_WEBRTC_DEBUG_LOG === 'true';
const isPreferVP8Codec: boolean = import.meta.env.REACT_APP_WEBRTC_PREFER_CODEC_VP8 === 'true';

interface MediaStreamBandwidth {
  audio?: number;
  video?: number;
}

export interface PrivateCallRtcSettings {
  configuration: RTCConfig;
  media: {
    constraints: MediaStreamConstraints;
    bandwidth: MediaStreamBandwidth;
  };
}

export const rtcErrors = {
  AddTrackWithoutConnection: 'AddTrackWithoutConnection',
  WAMPError: 'WampError',
  GetLocalStreamError: 'GetLocalStreamError',
  NoRemoteStreamRecieved: 'NoRemoteStreamRecieved',
  RemoveTrackError: 'RemoveTrackError',
  CreateOfferError: 'CreateOfferError',
  CreateAnswerError: 'CreateAnswerError',
  SetLocalDescError: 'SetLocalDescError',
  MuteAudioFail: 'MuteAudioFail',
  SetRemoteDescError: 'SetRemoteDescError',
  AddIceCandidateError: 'AddIceCandidateError',
  UncriticalError: 'UncriticalError',
  NoLocalStreamConstraintsError: 'NoLocalStreamConstraintsError',
  ReconnectAborted: 'ReconnectAborted',
  GetStreamTimeoutError: 'GetStreamTimeoutError',
  CallEndedWhileGettingStreamError: 'CallEndedWhileGettingStreamError',
  AddStreamError: 'AddStreamError'
};

export const unknownRTCErrorMessage = 'Unknown RTC WAMP error';
export const callEndedWhileReconnectingMessage = 'Call ended while reconnecting was in progress';
export const callIsNotConnectedServerError =
  'Unable mute Call with reconnect: Call is not is status "connected"';
const callIsNotReconnectingServerError =
  'Unable to mute call with reconnecting offer: call is not in status "reconnecting"';
const RTCSettingsRejectedMessage = 'RTC settings request was rejected because call was ended.';
const iceCandidateCalledWithoutCallMessage =
  "Ice candidate handler called when call object in store is not present or doesn't have id and/or roomId";

export class RTCClient {
  public connection: RTCPeerConnection | null;
  public callFailedCallback?: (e: Error) => void;
  public callSuccessCallback?: (value?: unknown) => void;
  public localDescription?: RTCSessionDescriptionInit;
  public remoteDescription?: RTCSessionDescriptionInit;
  public rejectRTCSettings?: () => void;
  public config: PrivateCallRtcSettings;
  public connectionId?: string;
  public componentToChange?: ConnectionComponent;
  private videoTransceiver?: RTCRtpTransceiver;
  private audioTransceiver?: RTCRtpTransceiver;
  private api: MiddlewareAPI;
  private iceCandidateQueue: RTCIceCandidateInit[] = [];
  private signalingStateSendingQueue: WampSendStateAction[] = [];
  private gatheringStateSendingQueue: WampSendStateAction[] = [];
  private callDurationInterval?: NodeJS.Timeout;
  private callDuration: number;
  private sendStatsInterval?: NodeJS.Timeout;
  private answeredAt?: number;
  private noStreamTimeout?: NodeJS.Timeout;
  private changeDeviceTimeout?: NodeJS.Timeout;
  private remoteStream?: MediaStream;
  private localStream: MediaStream;
  private wasIceConnectionFailed: boolean = false;
  private connectionChecker: RTCConnectionChecker;

  public static createError = (name: string, message: string) => {
    const e = new Error(message || 'Error');
    e.name = name;
    return e;
  };

  public static getUserMedia = (
    constraints: MediaStreamConstraints,
    camId: string | null,
    micId: string | null
  ) => {
    return new Promise<MediaStream>((resolve, reject) => {
      const finalConstraints: MediaStreamConstraints = {
        video: false,
        audio: false
      };

      if (camId) {
        finalConstraints.video = constraints.video || {};
        (finalConstraints.video as MediaTrackConstraints).deviceId = {
          exact: camId
        };
      }

      if (micId) {
        finalConstraints.audio = constraints.audio || {};
        (finalConstraints.audio as MediaTrackConstraints).deviceId = {
          exact: micId
        };
      }

      let timeoutExpired: boolean = false;

      const timeout = setTimeout(() => {
        timeoutExpired = true;
        reject(
          RTCClient.createError(rtcErrors.GetStreamTimeoutError, 'Get stream timeout expired')
        );
      }, getUserMediaAllowedTime);

      navigator.mediaDevices
        .getUserMedia(finalConstraints)
        .then(stream => {
          if (timeoutExpired) {
            stream.getTracks().forEach(track => track.stop());
          } else {
            clearTimeout(timeout);
            resolve(stream);
          }
        })
        .catch(reject);
    });
  };

  public setChangeDeviceTimeout = (component: ConnectionComponent) => {
    // if connection is not in status 'connected' or startReconnecting method threw UncriticalError (which means that
    // call has status 'reconnecting' on server), we should wait for renegotiation to finish, so we set timeout, which
    // will try to change call media device after some time. We should also consider changing one device and then changing
    // another before timeout has fired, so if current component to change is 'video', and this function is called with 'audio',
    // we should change both
    if (!this.componentToChange) {
      this.componentToChange = component;
    } else {
      const addVideoToAudio = this.componentToChange === 'audio' && component === 'video';
      const addAudioToVideo = this.componentToChange === 'video' && component === 'audio';
      if (component === 'both' || addAudioToVideo || addVideoToAudio) {
        this.componentToChange = 'both';
      }
    }
    if (this.changeDeviceTimeout) {
      clearTimeout(this.changeDeviceTimeout);
    }
    this.changeDeviceTimeout = setTimeout(this.changeDeviceCallback, 1000);
  };

  public setApi = (api: MiddlewareAPI) => {
    this.api = api;
  };

  public setCallDurationInterval = () => {
    if (this.callDurationInterval) {
      clearInterval(this.callDurationInterval);
    }
    this.callDuration = 0;
    this.answeredAt = Math.round(new Date().getTime() / 1000);
    this.callDurationInterval = setInterval(() => {
      if (!(this.callDuration && this.callDuration % checkDurationInterval)) {
        this.callDuration = Math.round(new Date().getTime() / 1000) - this.answeredAt!;
      } else {
        this.callDuration++;
      }
      this.api.dispatch(changeCallDuration(this.callDuration));
    }, 1000);
  };

  public createOffer = (connectionId: string, iceRestart?: boolean) => {
    if (!this.connection || connectionId !== this.connectionId) {
      throw RTCClient.createError(
        rtcErrors.CreateOfferError,
        "Offer created without connection established or id doesn't match"
      );
    }
    const offerOptions: RTCOfferOptions = {
      iceRestart
    };

    return this.connection.createOffer(offerOptions).then(sdp => {
      if (sdp.sdp) {
        sdp.sdp = this.removeUnsupportedServers(sdp.sdp);
      }
      return sdp;
    });
  };

  public createAnswer = () => {
    if (this.connection) {
      return this.connection.createAnswer().then(sdp => {
        if (sdp.sdp) {
          sdp.sdp = this.removeUnsupportedServers(sdp.sdp);
        }
        return sdp;
      });
    }
    throw RTCClient.createError(
      rtcErrors.CreateAnswerError,
      'Answer created without connection established'
    );
  };

  public setLocalDescription = (desc: RTCSessionDescriptionInit, connectionId: string) => {
    if (!this.connection || connectionId !== this.connectionId) {
      throw RTCClient.createError(
        rtcErrors.SetLocalDescError,
        "Cannot set local description, RTCPeerConnection object is undefined, or id doesn't match"
      );
    }
    return this.connection.setLocalDescription(desc).then(() => {
      this.logCollapsed('Local SDP set: ', desc.sdp);
      return this.ensureMaxBitrate();
    });
  };

  public toggleAudioTrack = (enabled: boolean) => {
    const audioTrackSender = this.audioTransceiver?.sender;
    if (!audioTrackSender || !audioTrackSender.track) {
      const message = `No audioTrackSender found or it doesn't send track`;
      throw RTCClient.createError(rtcErrors.MuteAudioFail, message);
    }
    this.logInfo(`Changing local audio track state to ${enabled ? 'enabled' : 'disabled'}`);
    audioTrackSender.track.enabled = enabled;
  };

  public setRemoteDescription = (desc: RTCSessionDescriptionInit, connectionId: string) => {
    if (!this.connection || this.connectionId !== connectionId) {
      throw RTCClient.createError(
        rtcErrors.SetRemoteDescError,
        "Cannot set remote description, RTCPeerConnection object is undefined or id doesn't match"
      );
    }
    return this.connection
      .setRemoteDescription(desc)
      .then(() => this.logCollapsed('Remote SDP set: ', desc.sdp));
  };

  public logInfo = (message: string, ...args: unknown[]) => {
    if (isLogEnabled) {
      // eslint-disable-next-line no-console
      console.log(message, ...args);
    }
  };

  public logCollapsed = (message: string, ...args: unknown[]) => {
    if (isLogEnabled) {
      /* eslint-disable no-console */
      console.groupCollapsed(message);
      console.log(...args);
      console.groupEnd();
      /* eslint-enable no-console */
    }
  };

  public applyStream = async (stream: MediaStream, connectionId: string) => {
    if (!this.connection || this.connectionId !== connectionId) {
      stream.getTracks().forEach(track => track.stop());
      throw RTCClient.createError(
        rtcErrors.AddTrackWithoutConnection,
        "Trying to apply stream when there is no connection object or connection ids don't match."
      );
    }
    const audioTransceiver = this.connection.getTransceivers().find(t => t.mid === '0');
    const videoTransceiver = this.connection.getTransceivers().find(t => t.mid === '1');

    const [audioTrack] = stream.getAudioTracks();
    const audioDirection = 'sendrecv';

    const {audio, video} = this.config.media.bandwidth;

    if (audioTransceiver) {
      this.audioTransceiver = audioTransceiver;
      await this.audioTransceiver.sender.replaceTrack(audioTrack);
      this.audioTransceiver.direction = audioDirection;
    } else {
      this.audioTransceiver = this.connection.addTransceiver(audioTrack, {
        sendEncodings: [{maxBitrate: audio! * 1000}],
        direction: audioDirection,
        streams: [stream]
      });
    }

    const [videoTrack] = stream.getVideoTracks();
    const videoDirection = videoTrack ? 'sendrecv' : 'recvonly';

    if (videoTransceiver) {
      this.videoTransceiver = videoTransceiver;
      await this.videoTransceiver.sender.replaceTrack(videoTrack || null);
      this.videoTransceiver.direction = videoDirection;
    } else {
      this.videoTransceiver = this.connection.addTransceiver(videoTrack || 'video', {
        sendEncodings: [{maxBitrate: video! * 1000}],
        direction: videoDirection,
        streams: [stream]
      });
    }
  };

  public replaceLocalTrack = async (
    track: MediaStreamTrack | null,
    changeDirection: boolean = true
  ) => {
    const transceiver = track?.kind === 'audio' ? this.audioTransceiver : this.videoTransceiver;
    const direction = track ? 'sendrecv' : 'recvonly';
    await transceiver!.sender.replaceTrack(track);
    if (changeDirection && transceiver!.direction !== direction) {
      transceiver!.direction = direction;
    }
  };

  public setSendStatInterval = () => {
    let bytesReceivedLastValue = 0;
    let bytesSentLastValue = 0;
    if (getCallStatsInterval > 0) {
      this.sendStatsInterval = setInterval(async () => {
        try {
          const stats: RTCStatsReport = await this.connection!.getStats();
          const activePair = this.getActiveCandidatePair(stats);
          if (activePair) {
            const receivingBitrate =
              ((activePair as CandidatePairStat).bytesReceived - bytesReceivedLastValue) /
              1024 /
              (getCallStatsInterval / 1000);
            const sendingBitrate =
              ((activePair as CandidatePairStat).bytesSent - bytesSentLastValue) /
              1024 /
              (getCallStatsInterval / 1000);
            bytesReceivedLastValue = (activePair as CandidatePairStat).bytesReceived;
            bytesSentLastValue = (activePair as CandidatePairStat).bytesSent;
            const bitrateInfo: BitrateInfo = {
              receiving: receivingBitrate,
              sending: sendingBitrate
            };
            this.sendState('bitrate', bitrateInfo);
          }
        } catch (e) {
          return;
        }
      }, getCallStatsInterval);
    }
  };

  public getActiveCandidatePair = (stats: RTCStatsReport) => {
    let activeCandidate: CandidatePairStat | null = null;
    let pairId: string | null = null;
    stats.forEach(transportStat => {
      if (transportStat.type === 'transport') {
        // for Chrome & Safari
        pairId = transportStat.selectedCandidatePairId;
      } else if (transportStat.type === 'candidate-pair' && transportStat.selected === true) {
        // for FF
        activeCandidate = transportStat;
      }
    });

    if (pairId) {
      stats.forEach(candidatePair => {
        if (candidatePair.id === pairId && candidatePair.type === 'candidate-pair') {
          activeCandidate = candidatePair;
        }
      });
    }

    return activeCandidate;
  };

  public getActiveCandidate = async () => {
    if (!this.connection) {
      return null;
    }
    const stats: RTCStatsReport = await this.connection.getStats();
    const activePair = this.getActiveCandidatePair(stats)!;
    if (!activePair) {
      return new Promise(resolve => {
        setTimeout(() => resolve(this.getActiveCandidate()), 1000);
      });
    }
    const activeCandidatePair: ConnectedIceInfo = {
      localIp: '',
      localPort: '',
      remoteIp: '',
      remotePort: '',
      type: 'srflx'
    };
    stats.forEach(candidate => {
      candidate.ip = candidate.ip || candidate.address;

      if (
        candidate.id === (activePair as CandidatePairStat).localCandidateId &&
        candidate.type === 'local-candidate'
      ) {
        activeCandidatePair.localIp = (candidate as CandidateStat).ip;
        activeCandidatePair.localPort = String((candidate as CandidateStat).port);
        if (candidate.candidateType === 'relay') {
          activeCandidatePair.type = 'relay';
          activeCandidatePair.turn = this.getDomainIfTurn(activeCandidatePair.localIp);
        }
      }
      if (
        candidate.id === (activePair as CandidatePairStat).remoteCandidateId &&
        candidate.type === 'remote-candidate'
      ) {
        activeCandidatePair.remoteIp = (candidate as CandidateStat).ip;
        activeCandidatePair.remotePort = String((candidate as CandidateStat).port);
        if (candidate.candidateType === 'relay') {
          activeCandidatePair.type = 'relay';
          activeCandidatePair.turn = this.getDomainIfTurn(activeCandidatePair.remoteIp);
        }
      }
    });
    return activeCandidatePair;
  };

  public stopLocalAudioTrack = () => {
    const track = this.getLocalTrack('audio');
    track.stop();
  };

  public stopLocalVideoTrack = () => {
    const track = this.getLocalTrack('video');
    track.stop();
  };

  public getLocalTrack = (kind: 'audio' | 'video'): MediaStreamTrack => {
    if (!this.connection) {
      throw RTCClient.createError(
        rtcErrors.GetLocalStreamError,
        'Trying to get local stream without connection established'
      );
    }
    const sender = kind === 'audio' ? this.audioTransceiver?.sender : this.videoTransceiver?.sender;
    if (!sender?.track) {
      const message = `No ${kind} track sender found or ${kind} transceiver.sender has no track in it`;
      throw RTCClient.createError(rtcErrors.GetLocalStreamError, message);
    }
    return sender.track;
  };

  public getRemoteStream = () =>
    new Promise<MediaStream>(resolve => {
      try {
        if (!this.connection) {
          throw RTCClient.createError(
            rtcErrors.NoRemoteStreamRecieved,
            'Trying to get remote stream without connection established'
          );
        }

        if (this.remoteStream) {
          return resolve(this.remoteStream);
        }

        if (this.noStreamTimeout) {
          clearTimeout(this.noStreamTimeout);
        }

        this.noStreamTimeout = setTimeout(() => {
          const rtcState = this.api.getState().rtc;
          if (rtcState.call && rtcState.call.status === 'connected') {
            const e = RTCClient.createError(
              rtcErrors.NoRemoteStreamRecieved,
              "Remote stream wasn't received."
            );
            captureException(e);
          }
        }, 15000);
      } catch (e) {
        this.handleError(e, this.connectionId!);
      }
    });

  public getUMAndCheckConnection = async (
    connectionId: string,
    camId: string | null,
    micId: string | null
  ) => {
    const stream = await RTCClient.getUserMedia(this.config.media.constraints, camId, micId);
    if (connectionId !== this.connectionId) {
      stream.getTracks().forEach(track => track.stop());
      throw RTCClient.createError(
        rtcErrors.CallEndedWhileGettingStreamError,
        'Call has ended while getUserMedia was executing'
      );
    }
    return stream;
  };

  public setCodecPreferences() {
    if (isPreferVP8Codec) {
      this.preferVideoCodec('video/VP8');
    }
  }

  private getPreferredCodecs(codecs: RTCRtpCodecCapability[], mimeType: string) {
    const otherCodecs: RTCRtpCodecCapability[] = [];
    const sortedCodecs: RTCRtpCodecCapability[] = [];

    codecs.forEach(codec => {
      if (codec.mimeType === mimeType) {
        sortedCodecs.push(codec);
      } else {
        otherCodecs.push(codec);
      }
    });

    return sortedCodecs.concat(otherCodecs);
  }

  private preferVideoCodec(mimeType: string) {
    if (!this.connection) {
      return;
    }

    const transceivers = this.connection.getTransceivers();

    for (const transceiver of transceivers) {
      const kind = transceiver.sender.track?.kind || transceiver.receiver.track?.kind;

      if (kind === 'video' && RTCRtpSender.getCapabilities && RTCRtpReceiver.getCapabilities) {
        const sendCodecs = RTCRtpSender.getCapabilities(kind)?.codecs || [];
        const recvCodecs = RTCRtpReceiver.getCapabilities(kind)?.codecs || [];

        const preferCodecs = this.getPreferredCodecs([...sendCodecs, ...recvCodecs], mimeType);

        // @ts-ignore
        transceiver.setCodecPreferences(preferCodecs);
      }
    }
  }

  public closeConnection = () => {
    this.iceCandidateQueue = [];
    delete this.remoteStream;
    delete this.callFailedCallback;
    delete this.callSuccessCallback;
    delete this.localDescription;
    delete this.remoteDescription;
    delete this.answeredAt;
    delete this.connectionId;
    delete this.componentToChange;
    clearInterval(this.callDurationInterval!);
    clearInterval(this.sendStatsInterval!);
    clearTimeout(this.noStreamTimeout!);
    clearTimeout(this.changeDeviceTimeout!);
    delete this.noStreamTimeout;
    delete this.callDurationInterval;
    delete this.sendStatsInterval;
    delete this.changeDeviceTimeout;
    this.connectionChecker?.reset();
    if (this.connection) {
      if (this.audioTransceiver) {
        this.audioTransceiver.sender.track?.stop();
        this.audioTransceiver.stop?.();
        delete this.audioTransceiver;
      }
      if (this.videoTransceiver) {
        this.videoTransceiver.sender.track?.stop();
        this.videoTransceiver.stop?.();
        delete this.videoTransceiver;
      }
      if (this.localStream) {
        this.localStream.getTracks().forEach(t => t.stop());
      }
      window.removeEventListener('beforeunload', this.beforeUnloadHandler);
      try {
        this.connection.close();
      } catch (e) {
        captureException(e);
      }
      this.connection = null;
    }
  };

  public openConnection = async (connectionId: string) => {
    this.connectionId = connectionId;
    const response = await this.getRTCSettings();
    if ((response as Action).type !== WAMP_GET_RTC_SETTINGS_SUCCESS) {
      if (
        (response as WampErrorAction<{}, {}, Action>).error &&
        (response as WampErrorAction<{}, {}, Action>).error.error
      ) {
        throw RTCClient.createError(
          rtcErrors.WAMPError,
          (response as WampErrorAction<{}, {}, Action>).error.error
        );
      }
      throw RTCClient.createError(rtcErrors.WAMPError, unknownRTCErrorMessage);
    }
    this.config = (
      response as WampCallResponseAction<PrivateCallRtcSettings[], {}, {}>
    ).wamp.callResult.args[0];

    delete this.config.configuration.sdpSemantics; // TODO: remove it later - server won't send it in next couple of updates

    this.connection = new RTCPeerConnection(this.config.configuration);
    window.addEventListener('beforeunload', this.beforeUnloadHandler);

    this.connectionChecker = new RTCConnectionChecker({
      checkTimeout: iceSyntheticFailedTimeout,
      checkInterval: connectionCheckerInterval
    });

    this.connection.onsignalingstatechange = e => {
      if (e.target) {
        const signalingState = (e.target as RTCPeerConnection).signalingState;
        this.logInfo('Signaling state changed: ', signalingState);
        this.sendState('signaling', signalingState);
        if (signalingState === 'stable') {
          this.iceCandidateQueue.forEach(candidate => this.addIceCandidate(candidate));
        }
      }
    };

    this.connection.onicegatheringstatechange = e => {
      if (e.target) {
        const iceGatheringState = (e.target as RTCPeerConnection).iceGatheringState;
        this.logInfo('Ice gathering state changed: ', iceGatheringState);
        this.sendState('gathering', iceGatheringState);
      }
    };

    this.connection.oniceconnectionstatechange = e => {
      if (e.target) {
        this.connectionChecker.reset();
        const iceConnectionState = (e.target as RTCPeerConnection).iceConnectionState;
        this.logInfo('Ice connection state changed: ', iceConnectionState);
        const rtcState = this.api.getState().rtc;
        switch (iceConnectionState) {
          case 'connected':
          case 'completed':
            this.wasIceConnectionFailed = false;

            if (this.callSuccessCallback) {
              this.callSuccessCallback();
              delete this.callSuccessCallback;
            }
            this.sendState('ice', iceConnectionState);
            if (
              rtcState.call &&
              (rtcState.call.status === 'connecting' || rtcState.call.status === 'connection-break')
            ) {
              this.api.dispatch(changeCallStatus('connected'));
            }
            this.getActiveCandidate().then((ip: ConnectedIceInfo) => {
              if (ip) {
                this.sendState('connected-candidates', ip);
              }
            });

            break;
          case 'failed':
            this.sendState('ice', iceConnectionState);

            this.handleIceConnectionFailed();
            break;
          case 'disconnected':
            this.sendState('ice', iceConnectionState);

            if (rtcState.call && rtcState.call.status !== 'reconnecting') {
              isSafari()
                ? setTimeout(() => {
                    this.api.dispatch(changeCallStatus('connection-break'));
                  }, 1000)
                : this.api.dispatch(changeCallStatus('connection-break'));
            }

            this.connectionChecker
              .checkConnection(this.connection)
              .then((iceRestartNeeded: boolean) => {
                if (iceRestartNeeded) {
                  this.iceSyntheticFailed(iceConnectionState);
                }
              });
            break;
          default:
            this.sendState('ice', iceConnectionState);
        }
      }
    };

    this.connection.onicecandidate = e => {
      const rtcState = this.api.getState().rtc;
      if (!rtcState.otherSessionCall) {
        if (rtcState.call && rtcState.call.id && rtcState.call.room_id) {
          if (e.candidate?.type && this.config.configuration.iceTypeAllowed[e.candidate.type]) {
            this.logCollapsed('Sending ICE candidate: ', e.candidate);
            this.api.dispatch(
              sendIceCandidate(rtcState.call.room_id, rtcState.call.id, e.candidate)
            );
          }
        } else {
          // eslint-disable-next-line no-console
          console.warn(iceCandidateCalledWithoutCallMessage);
        }
      }
    };

    this.connection.ontrack = ({transceiver, track, streams: [stream]}) => {
      if (track.kind === 'audio') {
        this.audioTransceiver = transceiver;
      } else if (track.kind === 'video') {
        this.videoTransceiver = transceiver;
      } else {
        throw new Error(`ontrack: unknown kind of track=${track.kind}`);
      }

      if (stream || !this.remoteStream) {
        stream = stream || new MediaStream([track]);
        this.remoteStream = stream;
        return;
      }

      this.remoteStream.addTrack(track);
    };

    this.connection.onnegotiationneeded = () => {
      if (this.api.getState().rtc.callInProgress) {
        this.renegotiation();
      }
    };

    return connectionId;
  };

  public async negotiation() {
    try {
      const {call} = this.api.getState().rtc;

      this.setCodecPreferences();

      this.localDescription = await this.createOffer(this.connectionId!);
      await this.setLocalDescription(this.localDescription, this.connectionId!);
      await this.api.dispatch(wampOffered(call!.room_id, call!.id!, this.localDescription!));

      this.setCallDurationInterval();
      this.setSendStatInterval();
    } catch (e) {
      return this.handleError(e, this.connectionId!);
    }
  }

  public async renegotiation(iceRestart?: boolean) {
    try {
      const isReconnecting = this.api.getState().rtc.call?.status === 'reconnecting';

      if (isReconnecting) {
        this.localDescription = await this.createOffer(this.connectionId!, iceRestart);

        const {call} = this.api.getState().rtc;

        const responseAction = await this.api.dispatch(
          upgradeCall(call!.id, call!.room_id, {offer: this.localDescription})
        );

        if (responseAction.type === WAMP_UPGRADE_CALL_FAIL) {
          const error = (responseAction as WampErrorAction<string, {}, Action>).error;
          if (error && error.args[0] === callIsNotReconnectingServerError) {
            // this means recipient has already started renegotiation, need to wait for upgraded event
            return;
          }

          throw RTCClient.createError(rtcErrors.WAMPError, error.error);
        }

        await this.setLocalDescription(this.localDescription, this.connectionId!);
      }
    } catch (e) {
      return this.handleError(e, this.connectionId!);
    }
  }

  public addIceCandidate = (candidate: RTCIceCandidateInit) => {
    try {
      if (this.connection) {
        if (this.connection.remoteDescription && this.connection.remoteDescription.type) {
          this.connection
            .addIceCandidate(new RTCIceCandidate(candidate))
            .then(this.addIceCandidateSuccessHandler.bind(this, candidate))
            .catch(this.addIceCandidateErrorHandler);
        } else {
          if (!this.iceCandidateQueue.includes(candidate)) {
            this.iceCandidateQueue.push(candidate);
          }
        }
      } else {
        throw RTCClient.createError(
          rtcErrors.AddIceCandidateError,
          'Trying to add ICE candidate before connection established'
        );
      }
    } catch (e) {
      this.handleError(e, this.connectionId!);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public handleError = (error: Error, connectionId: string, extra?: any) => {
    if (isLogEnabled) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    this.sendLogToSentry(error, extra);
    if (this.connection && connectionId === this.connectionId) {
      if (this.callFailedCallback) {
        this.callFailedCallback(error);
      }
      this.closeConnection();
      if (this.api) {
        const call = this.api.getState().rtc.call;
        if (call && call.room_id) {
          if (call.id) {
            const reason = {
              name: error.name,
              message: error.message
            };
            this.api.dispatch(hangUp(call.room_id, call.id, {reason}));
          } else {
            this.api.dispatch(callEnd());
          }
        }
      }
    }
  };

  private addIceCandidateSuccessHandler = (candidate: RTCIceCandidateInit) => {
    if (this.iceCandidateQueue.includes(candidate)) {
      this.iceCandidateQueue.splice(this.iceCandidateQueue.indexOf(candidate), 1);
    }
    this.logCollapsed('Ice candidate added', candidate);
  };

  private addIceCandidateErrorHandler = (e: Error) => {
    this.handleError(e, this.connectionId!);
  };

  private changeDeviceCallback = () => {
    const state = this.api.getState();
    if (!state.rtc.call || !state.rtc.localStream) {
      return;
    }
    const cam =
      (this.componentToChange === 'video' || this.componentToChange === 'both') &&
      state.rtc.localStream.video
        ? state.mediaDevices.cam
        : null;
    const mic =
      this.componentToChange === 'audio' || this.componentToChange === 'both'
        ? state.mediaDevices.mic
        : null;
    if (!cam && !mic) {
      return;
    }
    this.api.dispatch(changeCallMediaDevice(this.componentToChange, cam, mic));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private sendLogToSentry = (error: Error, extraParam: any) => {
    const rtcState = this.api.getState().rtc;
    const extra = Object.assign({rtcState}, extraParam);
    switch (error.name) {
      case rtcErrors.WAMPError:
        if (
          error.message.search(/wamp\.error\./) !== -1 ||
          error.message === unknownRTCErrorMessage
        ) {
          withScope(scope => {
            scope.setExtras(extra);
            captureException(error);
          });
        }
        break;
      case 'NotReadableError':
      case 'OverconstrainedError':
      case 'NotFoundError':
      case rtcErrors.AddTrackWithoutConnection:
      case rtcErrors.CallEndedWhileGettingStreamError:
      case rtcErrors.GetStreamTimeoutError:
      case rtcErrors.UncriticalError:
        break;
      default:
        withScope(scope => {
          scope.setExtras(extra);
          captureException(error);
        });
        break;
    }
  };

  private getDomainIfTurn = (ip: string) => {
    if (!this.config) {
      return;
    }
    const matchingTurn = this.config.configuration.iceServers!.find(
      server => server.ip === ip && server.urls.search(/^turns?:/) !== -1
    );
    if (matchingTurn) {
      return matchingTurn.urls.replace(/.*:([^:]+):\d+/, '$1');
    }
    return;
  };

  private getRTCSettings = () => {
    return new Promise<Action | string>(async resolve => {
      const rejectAction = {
        type: WAMP_GET_RTC_SETTINGS_FAIL,
        error: {
          error: RTCSettingsRejectedMessage
        }
      };
      this.rejectRTCSettings = () => {
        this.api.dispatch(rejectAction);
        resolve(rejectAction);
      };
      const response = await this.api.dispatch(getRTCSettings());
      delete this.rejectRTCSettings;
      resolve(response);
    });
  };

  private beforeUnloadHandler = (event: Event) => {
    event.returnValue = true;
  };

  private sendState = async (
    stateType: StateType,
    state: string | ConnectedIceInfo | BitrateInfo
  ) => {
    const call = this.api.getState().rtc.call;
    const queue =
      (stateType === 'signaling' && this.signalingStateSendingQueue) ||
      (stateType === 'gathering' && this.gatheringStateSendingQueue);
    const callStillInProgress = call && call.id && call.room_id;

    if (!callStillInProgress) {
      return;
    }
    if (queue) {
      const action = wampSendState(call!.room_id, call!.id, stateType, state);
      const position = queue.push(action) - 1;
      if (position === 0) {
        this.dispatchFromQueue(queue);
      }
    }
    if (!queue) {
      this.api.dispatch(wampSendState(call!.room_id, call!.id, stateType, state));
    }
  };

  private dispatchFromQueue = async (queue: WampSendStateAction[]) => {
    const call = this.api.getState().rtc.call;
    if (call && call.id && call.room_id && queue[0]) {
      await this.api.dispatch(queue[0]);
      queue.splice(0, 1);
      this.dispatchFromQueue(queue);
    } else {
      queue.splice(0, queue.length);
    }
  };

  private removeUnsupportedServers = (sdp: string) => {
    let changedSdp = sdp;
    if (!this.config.configuration.iceTypeAllowed.host) {
      changedSdp = changedSdp.replace(/a=candidate:.+?typ host.+?\s\n/g, '');
    }
    if (!this.config.configuration.iceTypeAllowed.relay) {
      changedSdp = changedSdp.replace(/a=candidate:.+?typ relay.+?\s\n/g, '');
    }
    if (!this.config.configuration.iceTypeAllowed.srflx) {
      changedSdp = changedSdp.replace(/a=candidate:.+?typ srflx.+?\s\n/g, '');
    }
    return changedSdp;
  };

  public setLocalStream(stream: MediaStream) {
    this.localStream = stream;
  }

  public async applyLocalStream(connectionId: string) {
    if (!this.localStream) {
      throw new Error('no local stream');
    }
    await this.applyStream(this.localStream, connectionId);
  }

  private async handleIceConnectionFailed() {
    if (this.wasIceConnectionFailed) return;

    this.wasIceConnectionFailed = true;

    const connectionId = this.connectionId;
    try {
      const rtcState = this.api.getState().rtc;
      // 'failed' fires on both peers simultaneously, so we have to start recall only from one of them
      const userIsCaller = rtcState.call!.caller_id === this.api.getState().user.id;
      const appOffline = !this.api.getState().layout.appOnline;
      if (appOffline) {
        await this.api.dispatch(callEnd());
        return;
      }
      if (userIsCaller) {
        if (rtcState.call!.isRecalling) {
          await this.api.dispatch(
            hangUp(rtcState.call!.room_id, rtcState.call!.id, {
              reason: 'Recall failed'
            })
          );
        } else {
          await this.api.dispatch(toggleRecall(true));
        }
      }
    } catch (e) {
      this.handleError(e, connectionId!);
    }
  }

  private async iceSyntheticFailed(state: RTCIceConnectionState) {
    const connectionId = this.connectionId;
    const userIsCaller = this.api.getState().rtc.call!.caller_id === this.api.getState().user.id;

    if (state === this.connection?.iceConnectionState) {
      try {
        userIsCaller && (await this.sendState('synthetic-failed', state));
        await this.handleIceConnectionFailed();
      } catch (e) {
        this.handleError(e, connectionId!);
      }
    }
  }

  private async ensureMaxBitrate(): Promise<void> {
    if (!this.connection) {
      throw new Error('Connection was not found');
    }

    const {audio, video} = this.config.media.bandwidth;
    const audioMaxBitrate = audio! * 1000;
    const videoMaxBitrate = video! * 1000;

    try {
      const transceivers = this.connection.getTransceivers();
      const senders = transceivers.map(t => t.sender);

      for (const sender of senders) {
        if (!sender.track) continue;

        const kind = sender.track.kind;
        const maxBitrate = kind === 'audio' ? audioMaxBitrate : videoMaxBitrate;
        const params = sender.getParameters();

        if (!params.encodings?.length || params.encodings.every(e => e.maxBitrate === maxBitrate)) {
          continue;
        }

        params.encodings.forEach(e => {
          e.maxBitrate = maxBitrate;
        });

        this.logInfo(`Set '${kind}' track maxBitrate=${maxBitrate}`);
        await sender.setParameters(params);
      }
    } catch (e) {
      this.handleError(e, this.connectionId!);
    }
  }

  public logCodecInfo() {
    if (!this.connection) {
      return;
    }

    setTimeout(async () => {
      if (!isLogEnabled) {
        // TODO: implement sending codec info to server then remove this if
        return;
      }
      if (!this.connection) {
        return;
      }

      try {
        const codecInfo = [];
        const transceivers = this.connection.getTransceivers();

        for (const transceiver of transceivers) {
          const senderStats = await transceiver.sender.getStats();
          const receiverStats = await transceiver.receiver.getStats();

          for (const stat of [...senderStats.values(), ...receiverStats.values()]) {
            if (!['inbound-rtp', 'outbound-rtp'].includes(stat.type)) {
              continue;
            }
            const source = stat.type === 'outbound-rtp' ? 'sender' : 'receiver';
            const codec =
              source === 'sender' ? senderStats.get(stat.codecId) : receiverStats.get(stat.codecId);

            if (!codec) {
              continue;
            }

            codecInfo.push(
              `${source} (${stat.kind}): mimeType='${codec.mimeType}' payloadType='${
                codec.payloadType
              }' sdpFmtpLine='${codec.sdpFmtpLine ?? ''}'`
            );
          }
        }

        if (isLogEnabled) {
          this.logCollapsed('Codec info', codecInfo.join('\n'));
        }

        // TODO: send codec info to server here
      } catch (e) {
        this.logInfo('Error occurred while logging codec info:', e);
      }
    }, 2000);
  }
}
