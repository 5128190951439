import {type Reducer, type ReducersMapObject} from 'redux';
import {List, type Map} from 'immutable';

import {FLIP_CARDS_FLIP_CARD} from 'store/exercise/editor/widgets/XFlipCards/actionTypes';
import {type FlipCardsCardPlayerAction} from 'store/exercise/editor/widgets/XFlipCards/actions';

import {INPUT_CHANGED} from './GapFill/actionTypes';
import {
  CHANGE_CARD_LABEL,
  CLEAR_ANSWER,
  CONTENT_CHANGE,
  DROP_ANSWER,
  QUEST_CONTENT_CHANGE,
  SELECT_CARD,
  SET_VOCABULARY_WIDGET_INFO,
  TOGGLE_COLLAPSIBLE,
  TURN_ESSAY_REVIEW_OFF,
  TURN_ESSAY_REVIEW_ON,
  TURN_QUEST_REVIEW_OFF,
  TURN_QUEST_REVIEW_ON,
  TURN_QUESTION_FIELDS_OFF,
  TURN_QUESTION_FIELDS_ON,
  TURN_QUESTIONS_REVIEW_OFF,
  TURN_QUESTIONS_REVIEW_ON
} from './actionTypes';
import {type GapFillAnswerAction} from './GapFill/actions';
import {type DNDInputValue, type WidgetAction, type WidgetProperties} from '../interface';
import {xplayerPrefix} from '../actionTypes';
import {
  type ChangeAction,
  type ChangeCardLabelAction,
  type GapAnswerAction,
  type GapClearAction,
  type QuestionChangeAction,
  type SelectCardAction,
  type SetWidgetVocabularyInfoAction
} from './actions';
import {type MCAnswerSelectedAction, type StyledListAction} from './List/actions';
import {
  MC_ANSWER_DESELECTED,
  MC_ANSWER_SELECTED,
  STYLED_LIST_SELECT_ITEM
} from './List/actionTypes';
import {
  MATCHING_ADD_CATEGORY,
  MATCHING_ANSWER_DROPPED,
  MATCHING_ANSWER_RETURNED,
  MATCHING_CHANGED_CATEGORY,
  MATCHING_DELETE_CATEGORY
} from './Matching/actionTypes';
import {
  type MatchingAnswerAction,
  type MatchingAnswerReturnAction,
  type MatchingCategoryChangedAction
} from './Matching/actions';
import {PICTURE_CHOICE_TOGGLE_VALUE, type SelectCard} from './PictureChoice/actionTypes';
import {DROP_CARD} from './ScrambledSentences/actionTypes';
import {type DropCardAction} from './ScrambledSentences/actions';
import {SET_LOADED_AUDIO} from './Audio/actionTypes';
import {type SetLoadedAudioAction} from './Audio/actions';
import {xpreviewPrefix} from '../preview/actionTypes';
import {GapFillType} from '../../../../components/Slate/interface';
import {type AudioProperties} from './Audio/interface';
import {type CardsProperties} from './Cards/interface';
import {type CommentProperties} from './Comment/interface';
import {type EssayProperties} from './Essay/interface';
import {type GapFillProperties} from './GapFill/interface';
import {type MatchingNoCategoriesProperties, type MatchingProperties} from './Matching/interface';
import {type ImageLabelingProperties} from './ImageLabeling/interface';
import {type ImageMatchingProperties} from './ImageMatching/interface';
import {MultipleChoiceElement, type MultipleChoiceProperties} from './List/interface';
import {type ScrambledProperties} from './ScrambledSentences/interface';
import {type VideoProperties} from './Video/interface';
import {type VocabularyProperties} from './Vocabulary/interface';
import {type QuestionsProperties, type QuestsProperties} from './Writing/interface';
import {type PictureChoiceProperties} from './PictureChoice/interface';
import {
  HIDE_ALL,
  HIDE_PART,
  SHOW_ALL,
  SHUFFLE_CARDS,
  SWITCH_IS_HIDDEN_CARD,
  SWITCH_IS_HIDDEN_IMAGE,
  SWITCH_IS_HIDDEN_TEXT,
  WORD_PICTURE_SET_SWITCH_IS_COLLABORATIVE_MANAGEMENT
} from './WordPictureSet/actionTyps';
import {type WordSetProperties} from './WordPictureSet/WordSet/interface';
import {type SwitchIsHiddenAction} from './WordPictureSet/actions';
import {type PictureSetProperties} from './WordPictureSet/PictureSet/interface';
import {type WordPictureSetProperties} from './WordPictureSet/WordPictureSet/interface';
import type FlipCardsRecord from './FlipCards/FlipCardsRecord';
import type VerticalStyledListRecord from './List/StyledList/VerticalStyledListRecord';
import type HorizontalStyledListRecord from './List/StyledList/HorizontalStyledListRecord';

const findPrevGapId = (
  state: GapFillProperties | ImageMatchingProperties,
  choiceId: string
): string | undefined =>
  (state.values || state.preFillValues)?.findKey((v?: string | DNDInputValue) =>
    !v || typeof v === 'string' ? v === choiceId : v.choiceId === choiceId
  );

const REDUCERS: ReducersMapObject = {
  [INPUT_CHANGED]: (state: GapFillProperties, action: GapFillAnswerAction) => {
    const {values} = state;
    if (!values) {
      state = state.createValues();
    }
    if (state.gap && state.gap === GapFillType.DND_INPUT && state.choices) {
      const choiceId = (state.values as Map<string, DNDInputValue>)?.get(action.gapId).choiceId;
      return state.setIn(['values', action.gapId], {choiceId, text: action.answer});
    }
    return state.setIn(['values', action.gapId], action.answer);
  },
  [DROP_ANSWER]: (
    state: GapFillProperties | ImageMatchingProperties,
    {choiceId, gapId}: GapAnswerAction
  ): GapFillProperties | ImageMatchingProperties => {
    const {values, preFillValues} = state;

    if (!values) {
      state = preFillValues ? state.set('values', preFillValues) : state.createValues();
    }

    const previousGapId = findPrevGapId(state, choiceId);
    const previousValue = previousGapId && state.getIn(['values', previousGapId]);
    if (previousGapId) {
      state = state.setIn(['values', previousGapId], null);
    }
    if (
      (state as GapFillProperties).gap &&
      (state as GapFillProperties).gap === GapFillType.DND_INPUT &&
      state.choices
    ) {
      return state.setIn(['values', gapId], previousValue || {choiceId});
    }
    return state.setIn(['values', gapId], choiceId);
  },
  [CLEAR_ANSWER]: (
    state: GapFillProperties | ImageMatchingProperties,
    {choiceId}: GapClearAction
  ): GapFillProperties | ImageMatchingProperties => {
    const {values, preFillValues} = state;

    if (!values) {
      state = preFillValues ? state.set('values', preFillValues) : state.createValues();
    }

    const previousGapId = findPrevGapId(state, choiceId);
    if (!previousGapId) {
      return state;
    }
    return state.setIn(['values', previousGapId], null);
  },
  [CONTENT_CHANGE]: (state: EssayProperties, {value}: ChangeAction): EssayProperties => {
    return state.contentChange(value);
  },
  [TURN_ESSAY_REVIEW_ON]: (state: EssayProperties): EssayProperties =>
    state.withMutations(w => {
      w.setIn(['values', 'onReview'], true);
      w.set('skipSync', false);
    }),
  [TURN_ESSAY_REVIEW_OFF]: (state: EssayProperties): EssayProperties =>
    state.withMutations(w => {
      w.setIn(['values', 'onReview'], false);
      w.set('skipSync', false);
    }),
  [QUEST_CONTENT_CHANGE]: (
    state: QuestsProperties,
    {value, id}: QuestionChangeAction
  ): QuestsProperties => {
    return state.contentChange(value, id);
  },
  [TURN_QUESTION_FIELDS_ON]: (state: QuestionsProperties): QuestionsProperties => {
    return state.withMutations(w => {
      w.setIn(['values', 'opened'], true);
      w.set('skipSync', false);
    });
  },
  [TURN_QUESTION_FIELDS_OFF]: (state: QuestionsProperties): QuestionsProperties => {
    return state.withMutations(w => {
      w.setIn(['values', 'opened'], false);
      w.set('skipSync', false);
    });
  },
  [TURN_QUESTIONS_REVIEW_ON]: (state: QuestionsProperties): QuestionsProperties => {
    return state.withMutations(w => {
      w.setIn(['values', 'onReview'], true);
      w.values
        .get('questions')
        .keySeq()
        .forEach(id => w.deleteIn(['values', 'questions', id, 'onReview']));
      w.set('skipSync', false);
    });
  },
  [TURN_QUESTIONS_REVIEW_OFF]: (state: QuestionsProperties): QuestionsProperties => {
    return state.withMutations(w => {
      w.deleteIn(['values', 'onReview']);
      w.values
        .get('questions')
        .keySeq()
        .forEach(id => w.deleteIn(['values', 'questions', id, 'onReview']));
      w.set('skipSync', false);
    });
  },
  [TURN_QUEST_REVIEW_ON]: (state: QuestsProperties): QuestsProperties => {
    return state.withMutations(w => {
      w.setIn(['values', w.values.keySeq().first(), 'onReview'], true);
      w.set('skipSync', false);
    });
  },
  [TURN_QUEST_REVIEW_OFF]: (state: QuestsProperties): QuestsProperties => {
    return state.withMutations(w => {
      w.values.keySeq().forEach(id => w.deleteIn(['values', id, 'onReview']));
      w.set('skipSync', false);
    });
  },

  [MC_ANSWER_SELECTED]: (state: MultipleChoiceProperties, action: MCAnswerSelectedAction) => {
    if (!state.values) {
      state = state.createValues();
    }
    const currentQuestionValue = state.values!.get(action.questionId);
    if (state.element === MultipleChoiceElement.RADIO || !currentQuestionValue) {
      // if nothing is selected yet for this answer or el type is radio, create new list with one selected answer
      return state.setIn(['values', action.questionId], List([action.answerId]));
    } else {
      // else push selected answer to other ones
      return state.setIn(['values', action.questionId], currentQuestionValue.push(action.answerId));
    }
  },
  [MC_ANSWER_DESELECTED]: (state: MultipleChoiceProperties, action: MCAnswerSelectedAction) => {
    const currentQuestionValue = state.values!.get(action.questionId);
    if (currentQuestionValue.size === 1) {
      // if values array had only one selected answer, return empty list
      // in order to track that question is actually touched
      return state.setIn(['values', action.questionId], List());
    } else {
      // else just remove selected answer from array
      return state.setIn(
        ['values', action.questionId],
        currentQuestionValue.filter(value => value !== action.answerId)
      );
    }
  },
  [MATCHING_ANSWER_DROPPED]: (state: MatchingProperties, action: MatchingAnswerAction) => {
    return state.dropAnswer(action.questionId, action.choiceId);
  },
  [MATCHING_ANSWER_RETURNED]: (state: MatchingProperties, action: MatchingAnswerReturnAction) => {
    return state.returnAnswer(action.choiceId);
  },
  [MATCHING_CHANGED_CATEGORY]: (
    state: MatchingNoCategoriesProperties,
    {id, category}: MatchingCategoryChangedAction
  ) => {
    return state.changeCategory(id, category);
  },
  [MATCHING_ADD_CATEGORY]: (
    state: MatchingNoCategoriesProperties,
    {id}: MatchingCategoryChangedAction
  ) => {
    return state.addCategory(id);
  },
  [MATCHING_DELETE_CATEGORY]: (
    state: MatchingNoCategoriesProperties,
    {id}: MatchingCategoryChangedAction
  ) => {
    return state.deleteCategory(id);
  },
  [DROP_CARD]: (
    state: ScrambledProperties,
    {sentenceId, cardIds}: DropCardAction
  ): ScrambledProperties =>
    (state.values ? state : state.createValues()).setIn(['values', sentenceId], List(cardIds)),
  [SET_LOADED_AUDIO]: (state: AudioProperties, {file}: SetLoadedAudioAction): AudioProperties => {
    return state.set('audio', file);
  },
  [TOGGLE_COLLAPSIBLE]: (
    state: AudioProperties | VideoProperties | CommentProperties
  ): AudioProperties | VideoProperties | CommentProperties => {
    if (!state.values) {
      state = state.createValues();
    }
    return state.setIn(['values', 'showCollapsible'], !state.values?.get('showCollapsible'));
  },
  [SET_VOCABULARY_WIDGET_INFO]: (
    state: VocabularyProperties,
    {widgetVocabularyInfo, preview}: SetWidgetVocabularyInfoAction
  ): VocabularyProperties => {
    if (preview) return state;
    return state.setWidgetVocabularyInfo(widgetVocabularyInfo);
  },
  [SELECT_CARD]: (state: CardsProperties, {cardId}: SelectCardAction): CardsProperties => {
    return state.set('values', cardId);
  },
  [CHANGE_CARD_LABEL]: (
    state: ImageLabelingProperties,
    {cardId, label}: ChangeCardLabelAction
  ): ImageLabelingProperties => {
    return state.changeCardLabel(cardId, label);
  },
  [STYLED_LIST_SELECT_ITEM]: (
    state: VerticalStyledListRecord | HorizontalStyledListRecord,
    {id}: StyledListAction
  ): VerticalStyledListRecord | HorizontalStyledListRecord => {
    return state.selectItem(id);
  },
  [PICTURE_CHOICE_TOGGLE_VALUE]: (
    state: PictureChoiceProperties,
    {questionId, cardId}: SelectCard
  ): PictureChoiceProperties => state.toggleValue(questionId, cardId),
  [FLIP_CARDS_FLIP_CARD]: (
    state: FlipCardsRecord,
    {cardId}: FlipCardsCardPlayerAction
  ): FlipCardsRecord => state.flipCard(cardId),

  // WordPictureSet
  [SWITCH_IS_HIDDEN_CARD]: (
    state: WordSetProperties | PictureSetProperties | WordPictureSetProperties,
    {cardId}: SwitchIsHiddenAction
  ) => {
    return state.switchIsHiddenByCardId(cardId);
  },
  [SWITCH_IS_HIDDEN_TEXT]: (state: WordPictureSetProperties, {cardId}: SwitchIsHiddenAction) => {
    return state.switchIsTextHidden(cardId);
  },
  [SWITCH_IS_HIDDEN_IMAGE]: (state: WordPictureSetProperties, {cardId}: SwitchIsHiddenAction) => {
    return state.switchIsImageHidden(cardId);
  },
  [HIDE_ALL]: (state: WordSetProperties | PictureSetProperties | WordPictureSetProperties) => {
    return state.hideAll();
  },
  [HIDE_PART]: (state: WordSetProperties | PictureSetProperties | WordPictureSetProperties) => {
    return state.hidePart();
  },
  [SHUFFLE_CARDS]: (state: WordSetProperties | PictureSetProperties | WordPictureSetProperties) => {
    return state.shuffleCards();
  },
  [SHOW_ALL]: (state: WordSetProperties | PictureSetProperties | WordPictureSetProperties) => {
    return state.showAll();
  },
  [WORD_PICTURE_SET_SWITCH_IS_COLLABORATIVE_MANAGEMENT]: (
    state: WordSetProperties | PictureSetProperties | WordPictureSetProperties
  ) => {
    return state.switchIsCollaborativeManagement();
  }
};

const createWidgetReducer = (preview?: boolean): ReducersMapObject => {
  const prefix = preview ? xpreviewPrefix : xplayerPrefix;
  const reducer = {};
  Object.keys(REDUCERS).forEach(
    (action: string) => (reducer[`${prefix}${action}`] = REDUCERS[action])
  );
  return reducer;
};

const previewReducer = createWidgetReducer(true);
const playerReducer = createWidgetReducer();

function widgetReducer(
  state: List<WidgetProperties>,
  action: WidgetAction
): List<WidgetProperties> {
  const reducer: Reducer<WidgetProperties> = action.preview
    ? previewReducer[action.type]
    : playerReducer[action.type];
  const widgetKey = state.findKey((w: WidgetProperties) => w.id === action.widgetId);
  const widget: WidgetProperties = state.get(widgetKey);
  if (widget) {
    return state.set(widgetKey, reducer(widget, action));
  }

  return state;
}

export default widgetReducer;
