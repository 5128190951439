import React from 'react';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {
  type WarningToastDispatchProps,
  WarningToastrWithButton,
  type WarningToastStateProps
} from './WarningToastrWithButton';

const mapStateTpProps = () => ({
  buttonText: <FormattedMessage id="Common.Reload" />
});

const mapDispatchToProps = () => ({
  handleClick: () => window.location.reload()
});

export default connect<WarningToastStateProps, WarningToastDispatchProps>(
  mapStateTpProps,
  mapDispatchToProps
)(WarningToastrWithButton);
