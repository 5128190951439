import {type Action} from 'redux';
import {type ThunkAction} from 'redux-thunk';
import {type MiddlewareRequestSuccess} from 'redux-axios-middleware';
import {type Descendant} from 'slate';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {
  type Dispatch,
  type WampCallAction,
  type WampCallResponseAction
} from 'services/wamp/actions/interface';

import {
  ADD_DRAFT_HOMEWORK_EXERCISE_REQUEST,
  CLEAR_DRAFT_COMMENT,
  CLEAR_XPLAYER_EXERCISE,
  CREATE_DRAFT_COMMENT,
  EXTRA_EXERCISES_ADDED_REQUEST,
  HIDE_SAVING_BADGE,
  INCREMENT_EXTRA_COUNT,
  INIT_PLAYER_TOP,
  PREVIEW_EXERCISE_COMPLETED,
  PREVIEW_EXERCISE_UNCOMPLETED,
  REMOVE_EXTRA_EXERCISE_FROM_XPLAYER,
  REMOVE_EXTRA_EXERCISE_REQUEST,
  REMOVE_HOMEWORK_EXERCISE_REQUEST,
  REQUEST_EXERCISE_COMPLETED,
  REQUEST_EXERCISE_UNCOMPLETED,
  REQUEST_UNIT_INSTANCE_PAGE,
  SET_COMMENT_VALUE,
  SET_MODAL_EXERCISE,
  SHOW_SAVING_BADGE,
  STORE_CLIENT_HEIGHT,
  STORE_SCROLL_TOP,
  UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK
} from './actionTypes';
import {type SetModalExerciseAction} from './interface';
import {type AppState, type ExerciseInstance} from '../../interface';
import {type ExerciseProperties} from './Exercise/interface';
import {labelFirstTask} from './widgets/actions';

export interface TopPositionAction extends Action {
  top: number;
}

export const initPlayerTop = (top: number): TopPositionAction => ({
  type: INIT_PLAYER_TOP,
  top
});

export const storeScrollTop = (top: number): TopPositionAction => ({
  type: STORE_SCROLL_TOP,
  top
});

export interface ClientHeightAction extends Action {
  clientHeight: number;
}

export const storeClientHeight = (clientHeight: number): ClientHeightAction => ({
  type: STORE_CLIENT_HEIGHT,
  clientHeight
});

export interface PreviewExerciseCompletedAction extends Action {
  exerciseId: string;
  completedAt: Date;
}

export const completeExercise =
  (
    exerciseId: string,
    preview?: boolean
  ): ThunkAction<Promise<PreviewExerciseCompletedAction | false>, AppState, never, Action> =>
  async (
    dispatch: Dispatch<Action, WampCallResponseAction<[string], {exerciseId: string}, never>>
  ) => {
    if (preview) {
      return dispatch(exerciseCompleted(exerciseId, new Date()));
    }
    let completedAt: Date;
    try {
      const {
        wamp: {
          callResult: {
            args: [completedDate]
          }
        }
      } = await dispatch(requestExerciseCompleted(exerciseId));
      completedAt = new Date(completedDate);
      return dispatch(exerciseCompleted(exerciseId, completedAt));
    } catch (e) {
      return false;
    }
  };

export const uncompleteExercise =
  (
    exerciseId: string,
    preview?: boolean
  ): ThunkAction<
    Promise<Omit<PreviewExerciseCompletedAction, 'completedAt'> | false>,
    AppState,
    never,
    Action
  > =>
  async (
    dispatch: Dispatch<Action, WampCallResponseAction<[string], {exerciseId: string}, never>>
  ) => {
    if (preview) {
      return dispatch(exerciseUncompleted(exerciseId));
    }
    try {
      await dispatch(requestExerciseUncompleted(exerciseId));
      return dispatch(exerciseUncompleted(exerciseId));
    } catch (e) {
      return false;
    }
  };

const exerciseCompleted = (
  exerciseId: string,
  completedAt: Date
): PreviewExerciseCompletedAction => ({
  type: PREVIEW_EXERCISE_COMPLETED,
  exerciseId,
  completedAt
});

const exerciseUncompleted = (
  exerciseId: string
): Omit<PreviewExerciseCompletedAction, 'completedAt'> => ({
  type: PREVIEW_EXERCISE_UNCOMPLETED,
  exerciseId
});

export const showSavingBadge = (): Action => ({
  type: SHOW_SAVING_BADGE
});

export const hideSavingBadge = (): Action => ({
  type: HIDE_SAVING_BADGE
});

const labelPageExercises =
  (pageNumber: number, onlyExtra?: boolean): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    let mainExerciseNumber = 1;
    let additionalExerciseNumber = 1;
    getState().xplayer?.exercises.forEach((ex: ExerciseProperties) => {
      if (onlyExtra && !ex.mainId) return;
      dispatch(
        labelFirstTask({
          exerciseId: ex.id,
          page: ex.mainId ? undefined : pageNumber,
          exerciseNumber: ex.mainId ? additionalExerciseNumber++ : mainExerciseNumber++
        })
      );
    });
  };

export const requestUnitInstancePage =
  (unitInstanceId: number, pageNumber: number): ThunkAction<void, AppState, never, Action> =>
  async dispatch => {
    await dispatch<AxiosRequestAction>({
      type: REQUEST_UNIT_INSTANCE_PAGE,
      payload: {
        client: 'v2',
        request: {
          url: `/v2/unit-instance/${unitInstanceId}/page/${pageNumber}`,
          params: {
            expand: 'grammar'
          }
        },
        options: {
          preventDispatch: {
            onSuccess(response: MiddlewareRequestSuccess<AppState>) {
              const {pathname} = response.getState().router.location!;
              return !pathname.endsWith(String(pageNumber)) && pageNumber > 1;
            }
          }
        }
      }
    });
    dispatch(labelPageExercises(pageNumber));
  };

export const extraExercisesAdded =
  (
    unitInstanceId: number,
    pageNumber: number
  ): ThunkAction<Promise<void>, AppState, never, Action> =>
  async dispatch => {
    await dispatch<AxiosRequestAction>({
      type: EXTRA_EXERCISES_ADDED_REQUEST,
      payload: {
        client: 'v2',
        request: {
          url: `/v2/unit-instance/${unitInstanceId}/page/${pageNumber}`,
          params: {
            expand: 'grammar'
          }
        },
        options: {
          preventDispatch: {
            onSuccess(response: MiddlewareRequestSuccess<AppState>) {
              const {pathname} = response.getState().router.location!;
              return !pathname.endsWith(String(pageNumber)) && pageNumber > 1;
            }
          }
        }
      }
    });
    dispatch(labelPageExercises(pageNumber, true));
  };

export const removeExtraExercise = (exerciseInstanceId: string): AxiosRequestAction => ({
  type: REMOVE_EXTRA_EXERCISE_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `/v2/exercise-instance/${exerciseInstanceId}/additional`
    }
  }
});

export interface UpdateUnitExercisesIsInHomeworkAction extends Action {
  homeworkId: string;
  homeworkExercises: string[];
}

export const updateUnitExercisesIsInHomework = (
  homeworkId: string,
  homeworkExercises: string[]
): UpdateUnitExercisesIsInHomeworkAction => ({
  type: UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK,
  homeworkId,
  homeworkExercises
});

const requestExerciseCompleted = (exerciseId: string): WampCallAction<[], {}> => ({
  type: REQUEST_EXERCISE_COMPLETED,
  wamp: {
    method: 'call',
    uri: `xplayer:exercise._${exerciseId}.completed`,
    rejectOnError: true
  }
});

const requestExerciseUncompleted = (exerciseId: string): WampCallAction<[], {}> => ({
  type: REQUEST_EXERCISE_UNCOMPLETED,
  wamp: {
    method: 'call',
    uri: `xplayer:exercise._${exerciseId}.incomplete`,
    rejectOnError: true
  }
});

export interface LoadHomeworkExerciseSuccess
  extends Action,
    AxiosResponseAction<{exerciseInstance: ExerciseInstance}> {}

export const clearXPlayerExercise = (): Action => ({
  type: CLEAR_XPLAYER_EXERCISE
});

export const toggleSupplementaryExercisesModal = (id?: string): SetModalExerciseAction => ({
  type: SET_MODAL_EXERCISE,
  id
});

export const addDraftHomeworkExerciseRequest = (exerciseId: string): AxiosRequestAction => ({
  type: ADD_DRAFT_HOMEWORK_EXERCISE_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `/v2/exercise-instance/${exerciseId}/homework`
    }
  }
});

export const removeHomeworkExerciseRequest = (exerciseId: string): AxiosRequestAction => ({
  type: REMOVE_HOMEWORK_EXERCISE_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `/v2/exercise-instance/${exerciseId}/homework`
    }
  }
});

export const moveHomeworkExerciseToDraft =
  (exerciseId: string): ThunkAction<Promise<void>, AppState, never, AxiosRequestAction> =>
  async dispatch => {
    try {
      await dispatch(removeHomeworkExerciseRequest(exerciseId));
    } catch (e) {
      throw new Error('removeFail');
    }
    try {
      await dispatch(addDraftHomeworkExerciseRequest(exerciseId));
    } catch (e) {
      throw new Error('addFail');
    }
  };

export interface ExtraExerciseAction extends Action {
  exerciseId: string;
}

export const removeExtraExerciseFromPlayer =
  (exerciseId: string, pageNumber: number): ThunkAction<void, AppState, never, Action> =>
  dispatch => {
    const extraAction: ExtraExerciseAction = {type: REMOVE_EXTRA_EXERCISE_FROM_XPLAYER, exerciseId};
    dispatch(extraAction);
    dispatch(labelPageExercises(pageNumber, true));
  };

export const incrementExtraCount = (exerciseId: string): ExtraExerciseAction => ({
  type: INCREMENT_EXTRA_COUNT,
  exerciseId
});

export interface CommentAction extends Action {
  exerciseId: string;
  draftCommentId: string | null;
  value: Descendant[];
}
export const setCommentValue = (
  exerciseId: string,
  draftCommentId: string | null,
  value: Descendant[]
): CommentAction => ({
  type: SET_COMMENT_VALUE,
  exerciseId,
  draftCommentId,
  value
});

export const createDraftComment = (
  exerciseId: string,
  draftCommentId: string | null,
  value: Descendant[]
): CommentAction => ({
  type: CREATE_DRAFT_COMMENT,
  exerciseId,
  draftCommentId,
  value
});

export interface ClearDraftCommentAction extends Action {
  exerciseId: string;
}
export const clearDraftComment = (exerciseId: string): ClearDraftCommentAction => ({
  type: CLEAR_DRAFT_COMMENT,
  exerciseId
});
