import type React from 'react';
import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {push} from 'store/router';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {setSearch} from 'store/dictionary/actions';
import {DictionarySearchMode} from 'common/enums';

import {requestSearchInODByIdEnToRus} from '../actions';
import {type SearchData} from '../interfaces';
import {type TextArticlesCache} from './useTextArticlesCash';
import {type TextArticlesStory} from './useTextArticlesStory';
import {groupLexicalEntries} from '../utils/groupLexicalEntries';
import {determineDirectionOfTranslation} from '../../../../../routes/Dictionary/utils';

interface Props {
  isSidebar?: boolean;
  textArticlesCache: TextArticlesCache;
  textArticlesStory: TextArticlesStory;
  currentSearchWord: string;
  setCurrentSearchWord: React.Dispatch<React.SetStateAction<string>>;
  setTextArticle: (value: React.SetStateAction<SearchData | undefined>) => void;
  setSearchFieldValue: (str: string) => void;
  changeSearchValue: (text: string) => void;
}

export const useSearchInEnToRusODByIdApiRequest = ({
  isSidebar,
  setTextArticle,
  textArticlesCache,
  textArticlesStory,
  currentSearchWord,
  setCurrentSearchWord,
  setSearchFieldValue,
  changeSearchValue
}: Props) => {
  const {key} = useLocation();
  const dispatch = useDispatch();
  const {set: setTextArticlesStory} = textArticlesStory;

  const searchInODByIdSuccessHandler = (data: SearchData) => {
    const groupedData = groupLexicalEntries(data, DictionarySearchMode.EN_TO_RUS) as SearchData;
    const isDataEmpty =
      (!!groupedData?.crossReferences && groupedData.crossReferences?.length > 0) ||
      groupedData?.lexicalEntries?.some(lexicalEntry =>
        lexicalEntry.entries?.some(entry => entry.translations && entry.translations?.length > 0)
      );
    const article = isDataEmpty ? groupedData : undefined;

    if (article?.word) {
      setSearchFieldValue(article?.word);
      changeSearchValue(article?.word);
      dispatch(setSearch(article?.word));
      setCurrentSearchWord(article?.word);
    }
    setTextArticle(article);
    if (!!article && !isSidebar) {
      textArticlesCache.setTextArticleToCache(currentSearchWord, article);
      if (key) setTextArticlesStory(key, article.word);
    }
  };

  const {isLoading: isLoadingByIdEnToRus, apiRequest: apiRequestSearchInODById} = useApiRequest2<
    [string, 'en' | 'ru', 'en' | 'ru']
  >(requestSearchInODByIdEnToRus, searchInODByIdSuccessHandler);

  const sendCrossLinkApiRequestEnToRus = useCallback(
    (id: string, word: string) => {
      if (!isSidebar) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('search', word);

        dispatch(push({search: searchParams.toString()}));

        setCurrentSearchWord(word);
      }

      const translationDirection = determineDirectionOfTranslation(word);

      if (translationDirection) {
        return apiRequestSearchInODById(
          id,
          translationDirection.sourceLocale,
          translationDirection.targetLocale
        );
      }
    },
    [apiRequestSearchInODById, dispatch, isSidebar, setCurrentSearchWord]
  );

  return {sendCrossLinkApiRequestEnToRus, isLoadingByIdEnToRus};
};
