import MatchingRecord from 'store/exercise/player/widgets/Matching/MatchingRecord';
import {
  type MatchingJSON,
  type MatchingNoCategoriesJSON,
  MatchingType
} from 'store/exercise/player/widgets/Matching/interface';
import MatchingNoCategoriesRecord from 'store/exercise/player/widgets/Matching/MatchingNoCategoriesRecord';

export const matchingFactory = (raw: MatchingJSON | MatchingNoCategoriesJSON) => {
  switch (raw.matchingType) {
    case MatchingType.NO_CATEGORIES: {
      return new MatchingNoCategoriesRecord(raw as MatchingNoCategoriesJSON);
    }
    default:
      return new MatchingRecord(raw as MatchingJSON);
  }
};
