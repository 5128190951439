import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';
import {createSearchParams, matchPath} from 'react-router-dom';
import {type ThunkAction} from 'redux-thunk';

import {parsePath, push, replace} from 'store/router';
import {
  batchMedia,
  clearRedirectedFromUrl,
  publishExerciseOpened,
  type PublishPointerData,
  publishPointerOpened,
  publishUrlOpened,
  setPointerSelection,
  urlOpened,
  urlOpenedExercise,
  urlOpenedPointer
} from 'common/action';
import * as toastr from 'components/toastr';
import {
  coursebookInstancePattern,
  courseInstanceHomeworkPattern,
  coursePattern,
  filesListPath,
  filesListPattern,
  filesListSelectedDocPath,
  filesListSelectedDocPattern,
  homeworkPath,
  homeworkPlayerPattern,
  studentTeacherAndCoursePath,
  unitInstancePattern
} from 'common/paths';
import {
  type AppState,
  type Courses,
  type HomeworkContents,
  type HomeworkWithContents,
  type HomeworkWithOverview,
  type SelectionData
} from 'store/interface';
import {
  type SubscribeCallback,
  type WampPublishAction,
  type WampSubscribeAction
} from 'services/wamp/actions/interface';
import {resolveEventFromSubscriptionTopic, resolveParamFromUri} from 'services/wamp/uriIdResolver';
import {type ToggleElementCreator} from 'common/interface';
import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';
import {updateUnitExercisesIsInHomework} from 'store/exercise/player/actions';
import {getHomeworkOptions} from 'components/Homework/utils';
import {defaultSearchString} from 'components/Homework/static';
import {ActionsTogether, MaterialsTab} from 'common/enums';

import {
  CHANGE_PARTNERS_FILTER,
  CLEAR_CLASSROOM,
  CLEAR_COURSES,
  CLEAR_OPEN_SOUND_WHEN_LOADED,
  OPEN_PARTNERS_TAB,
  OPEN_SOUND_WHEN_LOADED,
  PUBLISH_OPEN_MATERIALS,
  PUBLISH_OPENED_MATERIALS,
  PUBLISH_UPDATE_MATERIALS,
  REQUEST_COURSES,
  REQUEST_TEACHER_SETTINGS,
  SET_CLASSROOM_NETWORK_ERROR,
  SET_COURSES,
  SET_HAS_GRAMMAR,
  SET_TEACHER_SETTINGS,
  SUBSCRIBE_COURSE_EVENTS,
  TOGGLE_COURSES_DROPDOWN,
  TOGGLE_STUDENT_TEACHER_POPOVER
} from './actionTypes';
import {
  type ChangeFilterCreator,
  type ClassroomAction,
  type OpenTabActionCreator,
  type OpenWhenLoadedAction,
  type RequestCoursesCreator,
  type RequestTeacherSettings,
  type SetHasGrammarAction,
  type TeacherSettings
} from './interface';
import {
  clearOpenedTogetherDoc,
  loadDocuments
} from '../components/FilesWorkSpace/documentsTab/actions/action';
import {
  changePlaybackRate,
  changePlayStatus,
  changeTimestamp,
  clearOpenedTogetherSound,
  loadSounds,
  playSound
} from '../components/FilesWorkSpace/soundsTab/actions/action';
import {
  activateUnitInstanceEvent,
  completeUnitInstanceEvent,
  deactivateUnitInstanceEvent,
  selectedCBIIncrementCompletedUnits
} from '../pages/CoursebookInstancePage/actions';
import {
  clearHomeworkDraft,
  coursebookInstanceDeleted,
  coursebookInstanceIncrementCompletedUnits,
  isHomeworkDraft,
  loadHomeworkDraft,
  requestCourseInstanceInfo,
  type RequestCourseInstanceInfoResponseData,
  setCoursebookInstanceActivated,
  setCoursebookInstanceDeactivated,
  setCoursebookInstances,
  setCourseInstanceOverview,
  setHomeworkDraft
} from '../pages/CourseInstancePage/actions';
import {requestHomeworkList, setHomeworkList} from '../pages/HomeworkPage/actions';
import {setSelectedHomework} from '../pages/HomeworkPlayerPage/actions';
import {materialsTabParamName} from '../components/FilesWorkSpace/components/interface';
import {openMaterialsTab} from '../components/FilesWorkSpace/action';
import {confirmOpenExerciseOptions} from '../components/ConfirmOpenExerciseToastr';
import {type MediaContext, type MediaType} from '../../../components/media/interface';
import {type ActionTogetherContext} from '../components/FilesWorkSpace/context/AudioTogetherContext';
import {PAUSE, PLAYING, STOPPED} from '../components/FilesWorkSpace/soundsTab/actions/interface';
import {type GetRecentFilesCreator} from '../components/FilesWorkSpace/uploadingFiles/actions/interface';
import {
  GET_RECENT_DOCUMENTS,
  GET_RECENT_SOUNDS
} from '../components/FilesWorkSpace/uploadingFiles/actions/actionTypes';

type OpenUrlArgs = [string, string, MediaType, MediaContext];

interface UnitEventArg {
  id: number;
  coursebookInstanceId: string;
  ordinal: number | null;
}

export const openPartnersTab: OpenTabActionCreator = (id: string) => ({
  type: OPEN_PARTNERS_TAB,
  id
});

export const changePartnersFilter: ChangeFilterCreator = (filter: string) => ({
  type: CHANGE_PARTNERS_FILTER,
  filter
});

export const setClassroomNetworkError = (): Action => ({
  type: SET_CLASSROOM_NETWORK_ERROR
});

export const requestCourses: RequestCoursesCreator = (
  studentTeacherId: number,
  dontLoadFiles?: boolean
) => ({
  type: REQUEST_COURSES,
  payload: {
    request: {
      method: 'get',
      url: `/v1/classroom/student-teacher/${studentTeacherId}/course`,
      params: {
        expand: 'course'
      }
    }
  },
  dontLoadFiles
});

export interface SetCoursesAction extends Action {
  courses: Courses;
}

export const setCourses = (courses: Courses): SetCoursesAction => ({
  type: SET_COURSES,
  courses
});

export const publishMaterialsUpdated: (courseId: number) => WampPublishAction = courseId => ({
  type: PUBLISH_UPDATE_MATERIALS,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.materials.update`,
    options: {
      acknowledge: true,
      exclude_me: false
    }
  }
});

export const publishMaterialsOpen = (
  courseId: number,
  context: ActionTogetherContext
): WampPublishAction<Array<ActionTogetherContext>, {}> => ({
  type: PUBLISH_OPEN_MATERIALS,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.materials.open`,
    options: {
      exclude_me: true,
      eligible_authrole: ['student']
    },
    args: [context]
  }
});

const publishMaterialsOpened = (
  courseId: number,
  context: ActionTogetherContext,
  publishToSession: number
): WampPublishAction<Array<ActionTogetherContext>, {}> => ({
  type: PUBLISH_OPENED_MATERIALS,
  wamp: {
    method: 'publish' as 'publish',
    uri: `classroom:course._${courseId}.event.materials.opened`,
    options: {
      eligible: [publishToSession]
    },
    args: [context]
  }
});

export const getRecentDocuments: GetRecentFilesCreator = () => ({
  type: GET_RECENT_DOCUMENTS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/classroom/document-instance/recent-uploads',
      params: {
        expand: 'courseInstance',
        sort: '-created_at'
      }
    }
  }
});

export const getRecentSounds: GetRecentFilesCreator = () => ({
  type: GET_RECENT_SOUNDS,
  payload: {
    request: {
      method: 'get',
      url: '/v1/classroom/audio-instance/recent-uploads',
      params: {
        expand: 'courseInstance',
        sort: '-created_at'
      }
    }
  }
});

const updateCourseMaterials =
  (courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch: Dispatch<Action, AppState>, getState) => {
    const state = getState();
    dispatch(loadDocuments(courseInstanceId)).catch(() => null);
    dispatch(loadSounds(courseInstanceId)).catch(() => null);
    if (state.user.role === 'teacher') {
      dispatch(getRecentDocuments());
      dispatch(getRecentSounds());
    }
  };

const audioActionTogether =
  (context: ActionTogetherContext, isStudent = false): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();

    const currentPlayStatus = state.sounds?.playStatus;
    const currentUniquePlaybackId = state.sounds?.uniquePlaybackId;

    const {action, uniquePlaybackId, playStatus, playbackRate, timestamp} = context;

    switch (action) {
      case ActionsTogether.Play:
        if (currentPlayStatus === STOPPED || uniquePlaybackId !== currentUniquePlaybackId) {
          dispatch(playSound(uniquePlaybackId!, true));
        }

        if (isStudent) {
          dispatch(changeTimestamp(timestamp!));

          if (playbackRate) {
            dispatch(changePlaybackRate(playbackRate!));
          }
        }

        if (currentPlayStatus !== PLAYING) {
          dispatch(changePlayStatus(PLAYING));
        }

        break;

      case ActionsTogether.Pause:
        if (currentPlayStatus === PLAYING) {
          dispatch(changePlayStatus(PAUSE));
        }
        break;

      case ActionsTogether.Stop:
        if (currentPlayStatus !== STOPPED) {
          dispatch(changePlayStatus(STOPPED));
        }
        break;

      case ActionsTogether.ChangeTimestamp:
        if (currentPlayStatus === STOPPED || uniquePlaybackId !== currentUniquePlaybackId) {
          dispatch(playSound(uniquePlaybackId!, true));
        }

        if (currentPlayStatus !== playStatus) {
          dispatch(changePlayStatus(playStatus));
        }

        dispatch(changeTimestamp(timestamp!));
        break;

      case ActionsTogether.ChangePlaybackRate:
        dispatch(changePlaybackRate(playbackRate!));
        break;
    }
  };

const materialsOpen =
  (
    args: Array<ActionTogetherContext>,
    courseId: number,
    studentTeacherId: number,
    teacherSessionId: number
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    const pathname = state.router.location!.pathname;
    const search = state.router.location!.search;
    const filesPageMatch = matchPath({path: filesListPattern, end: false}, pathname);
    const homeworkPlayerPageMatch = matchPath({path: homeworkPlayerPattern, end: false}, pathname);

    const [context] = args;
    const {type, action, fileId, uniquePlaybackId} = context;

    if (type === 'audio') {
      if (!state.rtc.partnerSessionId || state.rtc.otherSessionCall) {
        return;
      }

      const isFilesPage =
        (filesPageMatch || homeworkPlayerPageMatch) && state.sounds && state.sounds.sounds;

      if (isFilesPage) {
        const audioNotExist = !state.sounds?.sounds?.[fileId];
        if (audioNotExist) return;
      } else {
        dispatch(push(filesListPath(studentTeacherId, courseId, MaterialsTab.AUDIO)));

        if (action === ActionsTogether.Play) {
          dispatch(playSoundWhenLoaded(uniquePlaybackId!));
        }
      }

      dispatch(audioActionTogether(context, true));

      dispatch(publishMaterialsOpened(courseId, context, teacherSessionId));
    }

    if (type === 'document') {
      if (filesPageMatch && state.docs && state.docs.documents) {
        const urlParams = createSearchParams(search);
        if (urlParams.get(materialsTabParamName) !== MaterialsTab.DOCUMENTS) {
          dispatch(openMaterialsTab(MaterialsTab.DOCUMENTS));
        }
        if (!state.docs.documents[fileId]) {
          return;
        }
      }

      dispatch(push(filesListSelectedDocPath(studentTeacherId, courseId, fileId)));
      dispatch(publishMaterialsOpened(courseId, context, teacherSessionId));
    }
  };

const materialsOpened =
  (
    args: Array<ActionTogetherContext>,
    courseId: number,
    studentTeacherId: number
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    const [context] = args;
    const {type, fileId} = context;
    const isAudio =
      type === 'audio' &&
      state.sounds &&
      state.sounds.sounds &&
      state.sounds.sounds[fileId] &&
      state.sounds.openTogetherFileId === fileId;

    if (isAudio) {
      dispatch(audioActionTogether(context));

      dispatch(clearOpenedTogetherSound());
    }

    const isDocument =
      type === 'document' &&
      state.docs!.documents &&
      state.docs!.documents[fileId] &&
      state.docs!.openTogetherFileId === fileId;

    if (isDocument) {
      dispatch(push(filesListSelectedDocPath(studentTeacherId, courseId, fileId)));
      dispatch(clearOpenedTogetherDoc());
    }
  };

const urlOpen =
  (args: OpenUrlArgs, courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();

    const [url, id, mediaType, mediaContext] = args;

    const prevLocation = state.router.location!;
    const receivedLocation = parsePath(url);
    const search = prevLocation.search || undefined;

    const isLocationChanged =
      receivedLocation.pathname !== prevLocation.pathname ||
      receivedLocation.search !== search ||
      receivedLocation.hash;

    if (isLocationChanged) {
      dispatch(push(receivedLocation));
    }
    const shouldScroll = !!mediaContext?.shouldScroll || !!isLocationChanged;

    if (id) {
      dispatch(batchMedia(id, mediaType as 'audio' | 'video', {shouldScroll, ...mediaContext}));
    } else {
      const filesListSelectedDocMatch = matchPath(
        {path: filesListSelectedDocPattern, end: false},
        url
      );
      if (filesListSelectedDocMatch) {
        const {
          params: {documentId}
        } = filesListSelectedDocMatch;
        if (state.docs?.documents && !state.docs.documents[documentId!]) {
          return;
        }
      }
    }

    if (state.rtc.partnerSessionId) {
      dispatch(
        publishUrlOpened(courseInstanceId, state.rtc.partnerSessionId, id, mediaType, mediaContext)
      );
    }
  };

const urlOpenExercise =
  (args: [string], courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();

    const [url] = args;

    const prevLocation = state.router.location!;
    const receivedLocation = parsePath(url);
    const search = prevLocation.search || undefined;
    if (
      receivedLocation.pathname !== prevLocation.pathname ||
      receivedLocation.search !== search ||
      receivedLocation.hash
    ) {
      dispatch(push(receivedLocation));
    }

    if (state.rtc.partnerSessionId) {
      dispatch(publishExerciseOpened(courseInstanceId, state.rtc.partnerSessionId));
    }
  };

const urlOpenPointer =
  (
    pointer: PublishPointerData,
    courseInstanceId: number
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();

    const {url, exerciseId, widgetId, editorId, range, elementId, relatedElement} = pointer || {};

    const prevLocation = state.router.location!;
    const receivedLocation = parsePath(url);
    const search = prevLocation.search || undefined;

    const condition =
      receivedLocation.pathname !== prevLocation.pathname ||
      receivedLocation.search !== search ||
      receivedLocation.hash;

    if (condition) {
      dispatch(push(receivedLocation));
    }

    const selection: SelectionData = {
      exerciseId,
      widgetId,
      editorId,
      range,
      elementId,
      relatedElement
    };
    dispatch(setPointerSelection(selection));

    if (state.rtc.partnerSessionId) {
      dispatch(publishPointerOpened(courseInstanceId, state.rtc.partnerSessionId));
    }
  };

const reloadHomeworkDraft =
  (courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch: Dispatch<Action, AppState>, getState) => {
    dispatch(loadHomeworkDraft(courseInstanceId)).then(
      ({payload: {data}}: AxiosResponseAction<HomeworkWithContents>) => {
        const matchCoursePath = matchPath(
          {path: coursePattern, end: false},
          getState().router.location!.pathname
        );
        if (!matchCoursePath) {
          return;
        }
        const currentCourseId = Number(matchCoursePath.params.courseId);
        if (currentCourseId !== courseInstanceId) {
          return;
        }
        if (isHomeworkDraft(data)) {
          dispatch(setHomeworkDraft(data));
        } else {
          dispatch(clearHomeworkDraft());
        }
      },
      () => {
        // TODO: handle request error
      }
    );
  };

const unitActivated =
  (unit: UnitEventArg): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();
    const isOnCoursebookInstancePage = !!matchPath(
      {
        path: coursebookInstancePattern,
        end: false
      },
      state.router.location!.pathname
    );
    if (isOnCoursebookInstancePage) {
      dispatch(activateUnitInstanceEvent(unit.id));
    }
  };

const unitCompleted =
  (unit: {
    id: number;
    coursebookInstanceId: string;
    completedAt: string;
    ordinal: number | null;
  }): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();
    const isTest = typeof unit.ordinal !== 'number';
    dispatch(coursebookInstanceIncrementCompletedUnits(unit.coursebookInstanceId, isTest));
    const coursebookInstancePageMatch = matchPath(
      {path: coursebookInstancePattern, end: false},
      state.router.location!.pathname
    );

    if (!coursebookInstancePageMatch) {
      return;
    }

    if (coursebookInstancePageMatch.params.coursebookInstanceId === unit.coursebookInstanceId) {
      dispatch(completeUnitInstanceEvent(unit.id, unit.completedAt));
      dispatch(selectedCBIIncrementCompletedUnits(isTest));
    }
  };

const unitDeactivated =
  (unit: UnitEventArg): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state: AppState = getState();
    const isOnCoursebookInstancePage = !!matchPath(
      {
        path: coursebookInstancePattern,
        end: false
      },
      state.router.location!.pathname
    );
    if (isOnCoursebookInstancePage) {
      dispatch(deactivateUnitInstanceEvent(unit.id));
    }
  };

const reloadCourseInstanceOverview =
  (courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch: Dispatch<Action, AppState>, getState) => {
    dispatch(requestCourseInstanceInfo(courseInstanceId, {expandOverview: true})).then(
      (action: AxiosResponseAction<RequestCourseInstanceInfoResponseData>) => {
        const courseInstanceMatch = matchPath(coursePattern, getState().router.location!.pathname);
        if (
          !courseInstanceMatch ||
          courseInstanceMatch.params.courseId !== String(courseInstanceId)
        ) {
          return;
        }
        dispatch(setCourseInstanceOverview(action.payload.data.overview!));
      },
      // if this request fails just do nothing bc it is not so important in the grand scheme of things
      () => null
    );
  };

const reloadCoursebookInstancesList =
  (courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch: Dispatch<Action, AppState>, getState) => {
    dispatch(requestCourseInstanceInfo(courseInstanceId, {expandCoursebookInstances: true})).then(
      (response: AxiosResponseAction<RequestCourseInstanceInfoResponseData>) => {
        const currentCourseInstanceMatch = matchPath(
          coursePattern,
          getState().router.location!.pathname
        );
        if (
          !currentCourseInstanceMatch ||
          Number(currentCourseInstanceMatch.params.courseId) !== courseInstanceId
        ) {
          return;
        }
        dispatch(setCoursebookInstances(response.payload.data.coursebookInstances!));
      },
      // todo: handle request error
      () => null
    );
  };

function isExactlyOnCourseInstanceRoute(pathname: string) {
  return !!matchPath({path: coursePattern, end: true}, pathname);
}

const handleCoursebookInstanceDeleted =
  (coursebookInstanceId: string): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const coursebookInstancePageMatch = matchPath(
      coursebookInstancePattern,
      getState().router.location!.pathname
    );
    if (
      coursebookInstancePageMatch &&
      coursebookInstancePageMatch.params.coursebookInstanceId === coursebookInstanceId
    ) {
      dispatch(
        replace(
          studentTeacherAndCoursePath(
            coursebookInstancePageMatch.params.studentTeacherId!,
            coursebookInstancePageMatch.params.courseId!
          )
        )
      );
    }
    dispatch(coursebookInstanceDeleted(coursebookInstanceId));
  };

const reloadHomeworks =
  (courseInstanceId: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch: Dispatch<Action, AppState>, getState) => {
    const state = getState();
    const homeworksPageMatch = matchPath(
      courseInstanceHomeworkPattern,
      state.router.location!.pathname
    );

    if (homeworksPageMatch) {
      let search = state.router.location!.search;
      if (search === '') {
        search = defaultSearchString;
      }
      const requestOptions = getHomeworkOptions(search);
      dispatch(
        requestHomeworkList(
          String(courseInstanceId),
          requestOptions.hideCompleted,
          requestOptions.statuses
        )
      ).then(({payload: {data}}: AxiosResponseAction<HomeworkWithOverview[]>) => {
        dispatch(setHomeworkList(data));
      });
    }
  };

const homeworkActivatedForStudent =
  (
    activatedHomework: HomeworkWithOverview & HomeworkContents,
    courseInstanceId: number
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();

    const unitPlayerPageMatch = matchPath(
      {path: unitInstancePattern, end: false},
      state.router.location!.pathname
    );
    if (unitPlayerPageMatch) {
      const exerciseIds = activatedHomework.exercises.map(ex => ex.exerciseInstanceId);
      dispatch(updateUnitExercisesIsInHomework(activatedHomework.id, exerciseIds));
    }

    dispatch(reloadHomeworks(courseInstanceId));
  };

const homeworkDeleted =
  (
    homeworkId: string,
    courseInstanceId: number,
    isOnUnitPlayerPage: boolean
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    if (isOnUnitPlayerPage) {
      dispatch(updateUnitExercisesIsInHomework(homeworkId, []));
      const redirectedFromHomeworkUrl = state.classroom
        ? state.classroom.courseInstanceState.coursebookInstanceState.redirectedFromUrl?.url
        : undefined;
      if (
        redirectedFromHomeworkUrl &&
        redirectedFromHomeworkUrl.split('homework/').reverse()[0] === homeworkId
      ) {
        dispatch(clearRedirectedFromUrl());
      }
    }
    const homeworkPlayerPageMatch = matchPath(
      {path: homeworkPlayerPattern, end: false},
      state.router.location!.pathname
    );

    if (homeworkPlayerPageMatch && homeworkPlayerPageMatch.params.homeworkId === homeworkId) {
      const {redirectedFromURL} = state.classroom!.courseInstanceState.homework;
      if (redirectedFromURL) {
        dispatch(push(redirectedFromURL));
      } else {
        const {studentTeacherId, courseId} = homeworkPlayerPageMatch.params;
        dispatch(push(homeworkPath(studentTeacherId!, courseId!)));
      }
    }

    dispatch(reloadHomeworks(courseInstanceId));
  };

const homeworkUpdated =
  (
    isOnUnitPlayerPage: boolean,
    courseInstanceId: number,
    newHomework: HomeworkWithContents
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    if (isOnUnitPlayerPage) {
      const draftExerciseIds = newHomework.exercises.map(ex => ex.exerciseInstanceId);
      dispatch(updateUnitExercisesIsInHomework(newHomework.id, draftExerciseIds));
    }
    const homeworkPlayerPageMatch = matchPath(
      {path: homeworkPlayerPattern, end: false},
      state.router.location!.pathname
    );

    if (homeworkPlayerPageMatch && homeworkPlayerPageMatch.params.homeworkId === newHomework.id) {
      dispatch(setSelectedHomework(newHomework));
    }

    dispatch(reloadHomeworks(courseInstanceId));
  };

const onCourseInstanceEvent: SubscribeCallback<unknown, unknown> = (
  args,
  kwargs,
  event,
  {dispatch, getState}
) => {
  const courseInstanceId = resolveParamFromUri('courseId', event.topic)!;
  const eventName = resolveEventFromSubscriptionTopic(event.topic);
  const state: AppState = getState();
  const pathname = state.router.location!.pathname;
  const studentTeacherId = Number(pathname.match(/room\/(\d+)/)![1]);
  const isStudent = state.user.role === 'student';
  const isOnUnitPlayerPage = !!matchPath({path: unitInstancePattern, end: false}, pathname);
  switch (eventName) {
    case 'materials.update':
      const soundsLoaded = state.sounds && state.sounds.sounds;
      const docsLoaded = state.docs && state.docs.documents;
      const recentUploads =
        (state.uploads && state.uploads.recentDocuments) || state.uploads.recentSounds;
      if (soundsLoaded || docsLoaded || recentUploads) {
        dispatch(updateCourseMaterials(courseInstanceId));
      }
      if (isExactlyOnCourseInstanceRoute(pathname)) {
        dispatch(reloadCourseInstanceOverview(courseInstanceId));
      }
      break;

    case 'materials.open':
      dispatch(
        materialsOpen(
          args as Array<ActionTogetherContext>,
          courseInstanceId,
          studentTeacherId,
          event.publisher!
        )
      );
      break;

    case 'materials.opened':
      dispatch(
        materialsOpened(args as Array<ActionTogetherContext>, courseInstanceId, studentTeacherId)
      );
      break;

    case 'url.open.exercise':
      {
        const data = args as [string];
        const intl = state.intl;
        const isTeacher = state.user.role === 'teacher';
        isTeacher
          ? toastr.info(
              intl.formatMessage({id: 'Exercise.TeacherConfirmTitle'}),
              intl.formatMessage({id: 'Exercise.TeacherConfirmContent'}),
              confirmOpenExerciseOptions(() => dispatch(urlOpenExercise(data, courseInstanceId)))
            )
          : dispatch(urlOpenExercise(data, courseInstanceId));
      }
      break;

    case 'url.opened.exercise':
      if (state.classroom?.openingExerciseForPartner) {
        dispatch(urlOpenedExercise());
      }
      break;

    case 'url.open':
      dispatch(urlOpen(args as OpenUrlArgs, courseInstanceId));
      break;

    case 'url.opened':
      if (state.classroom?.openingUrlForPartner) {
        const [id, mediaType, context] = args as [string, 'audio' | 'video', MediaContext];

        if (id) {
          dispatch(batchMedia(id, mediaType, context));
        }
        dispatch(urlOpened());
      }
      break;

    case 'url.open.pointer':
      const {pointer} = kwargs as {pointer: PublishPointerData};
      dispatch(urlOpenPointer(pointer, courseInstanceId));
      break;

    case 'url.opened.pointer':
      if (state.classroom?.publishingPointerUrl) {
        dispatch(urlOpenedPointer());
      }
      break;

    case 'homework.draft.updated':
      const [newDraft] = args as HomeworkWithContents[];
      if (!isStudent) {
        dispatch(reloadHomeworkDraft(courseInstanceId));
        dispatch(homeworkUpdated(isOnUnitPlayerPage, courseInstanceId, newDraft));
      }
      break;

    case 'homework.draft.deleted':
      if (!isStudent) {
        const {homeworkId} = kwargs as {homeworkId: string};
        dispatch(clearHomeworkDraft());
        dispatch(homeworkDeleted(homeworkId, courseInstanceId, isOnUnitPlayerPage));
      }
      break;

    case 'homework.updated':
      const [newHomework] = args as HomeworkWithContents[];
      dispatch(homeworkUpdated(isOnUnitPlayerPage, courseInstanceId, newHomework));
      break;

    case 'homework.activated':
      const [activatedHomework] = args as (HomeworkWithOverview & HomeworkContents)[];
      if (isStudent) {
        dispatch(homeworkActivatedForStudent(activatedHomework, courseInstanceId));
      } else {
        dispatch(clearHomeworkDraft());
        dispatch(reloadHomeworks(courseInstanceId));
      }
      break;

    case 'homework.deleted':
      const {homeworkId} = kwargs as {homeworkId: string};
      dispatch(homeworkDeleted(homeworkId, courseInstanceId, isOnUnitPlayerPage));
      break;

    case 'unit.activated':
      dispatch(unitActivated((args as [UnitEventArg])[0]));
      break;

    case 'unit.deactivated':
      dispatch(unitDeactivated((args as [UnitEventArg])[0]));
      break;

    case 'unit.completed':
      dispatch(
        unitCompleted(
          (
            args as [
              UnitEventArg & {
                completedAt: string;
              }
            ]
          )[0]
        )
      );
      break;

    case 'coursebook.created':
      dispatch(reloadCoursebookInstancesList(courseInstanceId));
      break;

    case 'coursebook.activated':
      if (isExactlyOnCourseInstanceRoute(pathname)) {
        const [coursebookInstanceId] = args as [string];
        dispatch(setCoursebookInstanceActivated(coursebookInstanceId));
      }
      break;

    case 'coursebook.completed':
      if (isExactlyOnCourseInstanceRoute(pathname)) {
        const [coursebookInstanceId] = args as [string];
        dispatch(setCoursebookInstanceDeactivated(coursebookInstanceId, new Date().toString()));
      }
      break;

    case 'coursebook.deleted': {
      const [coursebookInstanceId] = args as [string];
      dispatch(handleCoursebookInstanceDeleted(coursebookInstanceId));
      break;
    }

    case 'coursebook.hidden': {
      const [coursebookInstanceId] = args as [string];
      dispatch(handleCoursebookInstanceDeleted(coursebookInstanceId));
      break;
    }

    default:
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error(`onCourseInstanceEvent: unknown event:`, {
          args,
          kwargs,
          event
        });
      }
      break;
  }
};

export const subscribeCourseEvents: (
  courseId: number
) => WampSubscribeAction<{}, {}> = courseId => ({
  type: SUBSCRIBE_COURSE_EVENTS,
  wamp: {
    method: 'subscribe',
    uri: `classroom:course._${courseId}.event`,
    options: {
      match: 'prefix'
    }
  },
  callback: onCourseInstanceEvent
});

export const clearClassroom: () => ClassroomAction = () => ({
  type: CLEAR_CLASSROOM
});

export const toggleStudentTeacherPopover: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_STUDENT_TEACHER_POPOVER,
  show
});

export const toggleCoursesDropdown: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_COURSES_DROPDOWN,
  show
});

export const clearCourses = (): Action => ({
  type: CLEAR_COURSES
});

export const playSoundWhenLoaded = (id: string): OpenWhenLoadedAction => ({
  type: OPEN_SOUND_WHEN_LOADED,
  id
});

export const clearOpenWhenLoadedSound = (): Action => ({
  type: CLEAR_OPEN_SOUND_WHEN_LOADED
});

export const setHasGrammar = (hasGrammar: boolean): SetHasGrammarAction => ({
  type: SET_HAS_GRAMMAR,
  hasGrammar
});

export const requestTeacherSettings = (): AxiosRequestAction => ({
  type: REQUEST_TEACHER_SETTINGS,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/dashboard/teacher-settings`
    }
  }
});

export const setTeacherSettings = (teacherSettings: TeacherSettings): RequestTeacherSettings => ({
  type: SET_TEACHER_SETTINGS,
  teacherSettings
});
