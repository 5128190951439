import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';

import ScrambledRecord from '../ScrambledSentences/ScrambledRecord';
import {type SpellingProperties, type SpellingJSON} from './interface';
import {WidgetType} from '../../interface';
import {type ScrambledExample} from '../ScrambledSentences/interface';
import {getFullSentence} from '../ScrambledSentences/helpers';
import {SpellingType} from '../../../editor/widgets/XSpelling/interface';

class SpellingRecord extends ScrambledRecord implements SpellingProperties {
  public declare readonly ordinal?: string;
  public declare readonly spellingType: SpellingType;

  constructor(raw: SpellingJSON) {
    super(raw);
    this.initValues({
      ordinal: raw?.ordinal,
      spellingType: raw.spellingType || SpellingType.Phrases
    });
  }

  public get type() {
    return WidgetType.SPELLING;
  }

  public get example(): ScrambledExample | undefined {
    const {answers, choices, template} = this.sentences[0];

    if (!this.containsExample || !answers) return undefined;

    const templateArray = template.split('').filter(token => token.length);

    const fullSentence = getFullSentence(templateArray, answers, choices);

    return {fullSentence} as ScrambledExample;
  }
}

decorate(SpellingRecord, {
  ordinal: property(),
  spellingType: property(SpellingType.Phrases)
});
record()(SpellingRecord);
export default SpellingRecord;
