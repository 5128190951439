import React, {type PropsWithChildren} from 'react';
import Bowser from 'bowser';
import {type WrappedComponentProps} from 'react-intl';

import {throttle} from 'helpers/throttle';
import {defaultSmallVideoRatio, rtcThrottleToggleVideoTimeout} from 'config/static';
import {type Room} from 'store/interface';

import {type ChangeRatioCreator, type ConnectionComponent} from '../action/interface';
import {type SelectRoomCreator} from '../../components/Chat/actions/interface';
import Avatar from '../../components/Avatar';
import Loader from '../../components/Loader';
import CallDuration from '../containers/CallDuration';
import RemoteVideo from './RemoteVideo';
import LocalVideo from './LocalVideo';
import CallControlButtons from './CallControlButtons';
import {SoundNotification, soundUrl} from '../../helpers/sound';
import {playMedia} from '../../helpers/playMedia';
import {type CallStatus} from '../interface';
import {getClientPosition, isMouseEvent} from './helpers';
import {handleError} from '../handleError';

interface CallInProgressState {
  resizing: boolean;
  audioOutputSet: boolean;
}

interface CallInProgressOwnProps extends WrappedComponentProps {
  partnerName: string;
  chatSelectedRoom: number;
  partnerRoom?: Room;
  localVideoEnabled?: boolean;
  localAudioEnabled?: boolean;
  switchChatRoom: SelectRoomCreator;
  camId?: string;
  micId?: string;
  videoFullScreen?: boolean;
  toggleFullScreen: (show: boolean) => void;
  toggleUndocked: (show: boolean) => void;
  isUndocked?: boolean;
  width: number | string;
  height: number | string;
  hangUp: () => void;
  speedtestGloballyDisabled?: boolean;
}

export interface CallInProgressStateProps {
  status: CallStatus;
  remoteVideoEnabled?: boolean;
  smallVideoRatio: number;
}

interface DispatchCallInProgressProps {
  getLocalStream: () => Promise<MediaStream>;
  getRemoteStream: () => Promise<MediaStream>;
  muteCall: (
    component: ConnectionComponent,
    camId: string | null,
    micId: string | null
  ) => Promise<{}>;
  changeSmallVideoRatio: ChangeRatioCreator;
}

type Props = PropsWithChildren<CallInProgressOwnProps> &
  CallInProgressStateProps &
  DispatchCallInProgressProps;

export default class CallInProgress extends React.Component<Props, CallInProgressState> {
  public state: CallInProgressState = {
    resizing: false,
    audioOutputSet: false
  };

  private remoteVideoElement?: HTMLMediaElement;
  private localVideoElement?: HTMLMediaElement;
  private component?: HTMLDivElement;
  private callInterruptedNotification?: HTMLMediaElement;

  public shouldComponentUpdate(newProps: Props, nextState: CallInProgressState) {
    const stateChanged = this.state !== nextState;
    const propsChangedExceptChildren = !!Object.keys(newProps).find(
      key => newProps[key] !== this.props[key] && key !== 'children'
    );
    return stateChanged || propsChangedExceptChildren;
  }

  public render(): JSX.Element {
    const containerClass: string = `call-in-progress ${
      this.props.remoteVideoEnabled ? ' video' : ''
    }`;
    const shouldRenderLoader = this.props.remoteVideoEnabled && this.props.status !== 'connected';
    return (
      <div
        className={containerClass}
        onMouseMove={this.handleMouseMove}
        onTouchMove={this.handleMouseMove}
        ref={this.componentRef}
      >
        {this.props.children}
        <LocalVideo
          localVideoEnabled={this.props.localVideoEnabled}
          onLocalVideoPause={this.onLocalVideoPause}
          smallVideoRatio={this.props.smallVideoRatio}
          resizing={this.state.resizing}
          onMouseDown={this.handleLocalVideoMouseDown}
          localVideoRef={this.localVideoRef}
        />
        <RemoteVideo
          videoFullScreen={this.props.videoFullScreen}
          isUndocked={this.props.isUndocked}
          width={this.props.width}
          height={this.props.height}
          remoteVideoEnabled={this.props.remoteVideoEnabled}
          remoteVideoRef={this.remoteVideoRef}
          onRemoteVideoPause={this.onRemoteVideoPause}
        />
        {this.renderAudioNotification()}
        <Loader shouldRender={shouldRenderLoader} />
        <span className="opponent-name">{this.props.partnerName}</span>
        <Avatar size={72} url={this.props.partnerRoom!.recipient!.profile!.avatars.md} />
        <CallControlButtons
          camId={this.props.camId}
          localVideoEnabled={this.props.localVideoEnabled}
          localAudioEnabled={this.props.localAudioEnabled}
          hangUp={this.props.hangUp}
          toggleAudioState={this.toggleAudioState}
          toggleVideoState={this.throttledToggleVideoState}
          renderSwitchChatRoomBtn={
            this.props.partnerRoom!.id !== this.props.chatSelectedRoom &&
            !this.props.videoFullScreen
          }
          selectCurrentChatRoom={this.selectCurrentChatRoom}
          intl={this.props.intl}
          speedtestGloballyDisabled={this.props.speedtestGloballyDisabled}
          shouldDisableVideoBtn={!this.props.camId || this.props.status !== 'connected'}
        />
        <CallDuration />
      </div>
    );
  }

  public componentDidUpdate(prevProps: Props) {
    this.playSoundIfNecessary();
    if (
      this.props.status === 'connected' &&
      ['reconnecting', 'connecting'].includes(prevProps.status)
    ) {
      this.getStreams(this.props.localVideoEnabled);
    }
  }

  public componentDidMount() {
    if (this.props.status === 'connection-break' && this.callInterruptedNotification) {
      playMedia(this.callInterruptedNotification);
    }

    if (this.props.status === 'connected') {
      this.getStreams(this.props.localVideoEnabled);
    }
  }

  public componentWillUnmount() {
    if (this.remoteVideoElement && this.remoteVideoElement.played) {
      this.remoteVideoElement.srcObject = null;
      this.remoteVideoElement.pause();
    }
    delete this.remoteVideoElement;
  }

  private playSoundIfNecessary = () => {
    const callInterrupted = this.props.status === 'connection-break';
    if (
      callInterrupted &&
      this.callInterruptedNotification &&
      this.callInterruptedNotification.paused
    ) {
      playMedia(this.callInterruptedNotification);
    }
    if (
      !callInterrupted &&
      this.callInterruptedNotification &&
      !this.callInterruptedNotification.paused
    ) {
      this.callInterruptedNotification.pause();
    }
  };

  private async getStreams(shouldGetLocalStream?: boolean) {
    // local and remote streams requests are not simultaneous because if getLocalTrack fails, it closes connection
    // and getRemoteStream will produce unnecessary error to Sentry
    try {
      if (!this.state.audioOutputSet) {
        this.setAudioOutputDevice();
      }
      await this.getRemoteStream();
      if (shouldGetLocalStream && this.localVideoElement) {
        await this.getLocalStream();
      }
    } catch {
      return;
    }
  }

  private selectCurrentChatRoom = () => this.props.switchChatRoom(this.props.partnerRoom!.id);

  private remoteVideoRef = (el: HTMLVideoElement) => {
    this.remoteVideoElement = el;
  };

  private componentRef = (el: HTMLDivElement) => el && (this.component = el);

  private onRemoteVideoPause = () => {
    if (
      Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Yandex Browser' &&
      this.remoteVideoElement
    ) {
      playMedia(this.remoteVideoElement);
    }
  };

  private renderAudioNotification = () => (
    <audio
      src={soundUrl(SoundNotification.callInterrupted)}
      autoPlay={false}
      loop={true}
      ref={el => el && (this.callInterruptedNotification = el)}
      preload="auto"
    />
  );

  private handleMouseMove = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent) => {
    if (isMouseEvent(e)) e.preventDefault();

    if (this.state.resizing && this.component) {
      e.stopPropagation();
      const rect = this.component.getBoundingClientRect();

      const {pageX, pageY} = getClientPosition(e);

      const newRatio = (() => {
        const smallVideoWidth = rect.right - pageX;
        const mainComponentWidth = rect.right - rect.left;
        const ratioByWidth = smallVideoWidth / mainComponentWidth;

        const smallVideoHeight = pageY - rect.top;
        const mainComponentHeight = rect.bottom - rect.top;
        const ratioByHeight = smallVideoHeight / mainComponentHeight;

        return ratioByHeight > ratioByWidth ? ratioByHeight : ratioByWidth;
      })();

      if (newRatio <= 0.7 && newRatio >= defaultSmallVideoRatio) {
        this.props.changeSmallVideoRatio(newRatio);
      } else {
        if (newRatio > 0.7) {
          this.props.changeSmallVideoRatio(0.7);
        }
        if (newRatio < defaultSmallVideoRatio) {
          this.props.changeSmallVideoRatio(defaultSmallVideoRatio);
        }
      }
    }
  };

  private setAudioOutputDevice = () => {
    this.setState({audioOutputSet: true});
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.remoteVideoElement && (this.remoteVideoElement as any).setSinkId) {
      navigator.mediaDevices.enumerateDevices().then((devices: MediaDeviceInfo[]) => {
        const outputDevices = devices.filter(device => device.kind === 'audiooutput');
        const defaultDevice = outputDevices.find(device => device.deviceId === 'default');
        if (defaultDevice && defaultDevice.groupId !== 'default') {
          const deviceToUse = outputDevices.find(
            device => device.groupId === defaultDevice.groupId && device.deviceId !== 'default'
          );
          if (deviceToUse && this.remoteVideoElement) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (this.remoteVideoElement as any).setSinkId(deviceToUse.deviceId);
          }
        }
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onLocalVideoPause = () => this.localVideoElement && playMedia(this.localVideoElement);

  private getLocalStream = async () => {
    const localStream = await this.props.getLocalStream();
    const video = this.localVideoElement;
    if (video && video.srcObject !== localStream) {
      video.srcObject = null;
      video.srcObject = localStream;
      playMedia(video);
      video.volume = 0;
    }
  };

  private getRemoteStream = async () => {
    const remoteStream = await this.props.getRemoteStream();
    const video = this.remoteVideoElement;

    if (video && video.srcObject !== remoteStream) {
      video.srcObject = null;
      video.srcObject = remoteStream;
      playMedia(video);
    }
  };

  private toggleVideoState = async () => {
    try {
      await this.props.muteCall(
        'video',
        this.props.localVideoEnabled ? null : this.props.camId!,
        null
      );
    } catch (e) {
      handleError(e, this.props.intl.formatMessage, this.props.localVideoEnabled);
    }
  };

  private throttledToggleVideoState = throttle(
    this.toggleVideoState,
    rtcThrottleToggleVideoTimeout
  );

  private toggleAudioState = () => {
    this.props.muteCall('audio', null, this.props.localAudioEnabled ? null : this.props.micId!);
  };

  private localVideoRef = (el: HTMLVideoElement) => {
    this.localVideoElement = el;
  };

  private handleLocalVideoMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isMouseEvent(e)) e.preventDefault();

    e.stopPropagation();
    window.addEventListener('mouseup', this.handleMouseUp);
    window.addEventListener('touchend', this.handleMouseUp);
    this.setState({resizing: true});
  };

  private handleMouseUp = () => {
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('touchend', this.handleMouseUp);
    this.setState({resizing: false});
  };
}
