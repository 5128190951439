import React from 'react';
import './unread-messages-notification.scss';

interface UnreadMessagesProps {
  className?: string;
  unreadMessagesNumber: number;
  compact?: boolean;
}

export default class UnreadMessagesNotification extends React.Component<UnreadMessagesProps, {}> {
  public static defaultProps = {
    className: 'unread-messages-notification'
  };

  public render() {
    if (this.props.unreadMessagesNumber > 0) {
      return (
        <div className={`${this.props.className} ${this.props.compact ? 'compact' : ''}`}>
          <div>{this.props.unreadMessagesNumber}</div>
        </div>
      );
    }
    return null;
  }
}
