import {Editor, Value} from '@englex/slate';
import {Map, Set} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {isDocumentNotEmpty} from 'components/Slate/utils/documentNotEmpty';
import {insertIcon} from 'components/Slate/SlateEditor/plugins/button/Icon/changes';
import {type Role} from 'store/interface';

import {WidgetType} from '../../interface';
import {type CommentJSON, type CommentProperties, CommentType} from './interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';
import {commentDataByTypePlayer, getCommentType} from './commentDataByType';
import {valueFromText} from '../../../../../components/Slate/utils';
import {DisplayButtonRecord} from '../../DisplayButtonRecord';

class CommentRecord
  extends FormattedTextRecord<undefined, Map<'showCollapsible', boolean> | undefined>
  implements CommentProperties
{
  public declare readonly commentType: CommentType;
  public declare readonly commentLabel?: string;
  public declare readonly isCollapsed?: boolean;

  constructor(raw: CommentJSON) {
    super(raw);
    const task = raw.task ? Value.fromJSON(raw.task) : valueFromText();
    const commentType = getCommentType(raw);
    const commentData = commentDataByTypePlayer[commentType];
    const prefix = ` ${commentData.title}${isDocumentNotEmpty(task.document) ? ': ' : ''}`;
    const taskEditor = new Editor({value: task});
    if (this.task.selection) {
      taskEditor.select(this.task.selection.setMarks(Set()));
    }

    taskEditor
      .insertText(prefix)
      .moveToStartOfDocument()
      .command(insertIcon, commentData.iconName, 'not-bold');
    const taskValue = taskEditor.value;
    const displayButton = raw.displayButton
      ? DisplayButtonRecord.create(raw.displayButton).withMutations(db => {
          db.set(
            'title',
            isDocumentNotEmpty(db.title.document) ? db.title : valueFromText(commentData.title)
          );
          db.set('icon', 'lightbulb-o');
          db.set('className', 'purple-dim');
          db.set('modalTitle', commentData.title);
        })
      : undefined;
    this.initValues({
      content: Value.fromJSON(raw.content),
      task: taskValue,
      commentType,
      values: raw.values ? Map(raw.values) : undefined,
      displayButton,

      isCollapsed: raw.isCollapsed
    });
  }

  public setLabel(commentLabel: string) {
    return this.set('commentLabel', commentLabel);
  }

  public toJSON(): CommentJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      content: this.content.toJSON(),
      commentType: this.commentType,
      isCollapsed: this.isCollapsed
    };
  }
  public get type() {
    return WidgetType.COMMENT;
  }

  public createValues() {
    return this.set('values', Map({showCollapsible: false}));
  }

  public hasDisplayButton(role?: Role) {
    return (
      super.hasDisplayButton() &&
      this.commentType === CommentType.TEACHING_TIPS &&
      role === 'teacher'
    );
  }

  public setValuesFromJSON(values: {showCollapsible?: boolean}) {
    return values
      ? this.withMutations(v => {
          if (!v.values) v = v.set('values', Map());
          if (values.showCollapsible !== undefined)
            v.setIn(['values', 'showCollapsible'], values.showCollapsible);
          else v.delete('values');
        })
      : this;
  }
}

decorate(CommentRecord, {
  commentType: property(CommentType.TEACHING_NOTES),
  commentLabel: property(),
  isCollapsed: property()
});
record()(CommentRecord);
export default CommentRecord;
