import {composeWithDevTools, type EnhancerOptions} from 'redux-devtools-extension/developmentOnly';

import {CALL_CHANGE_DURATION} from '../webRTC/action/actionTypes';
import {SET_PAGE_VISIBILITY} from '../layouts/actions/actionTypes';
import {SET_CHANGE_TITLE_INTERVAL} from '../common/actionTypes';
import {STORE_SCROLL_TOP} from './exercise/player/actionTypes';

const reduxDevToolsOptions: EnhancerOptions = {
  actionsBlacklist: [
    CALL_CHANGE_DURATION,
    SET_PAGE_VISIBILITY,
    SET_CHANGE_TITLE_INTERVAL,
    STORE_SCROLL_TOP
  ],
  actionCreators: {},
  maxAge: 100
};

export default composeWithDevTools(reduxDevToolsOptions);
