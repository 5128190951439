import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

const prefix = 'LAYOUT/';

export const TOGGLE_CONTACTS_MODAL = prefix + 'TOGGLE_CONTACTS_MODAL';
export const REQUEST_NOTIFICATIONS = prefix + 'REQUEST_NOTIFICATIONS';
export const SET_NOTIFICATIONS_NUMBER = prefix + 'SET_NOTIFICATIONS_NUMBER';
export const SET_LAYOUT_NETWORK_ERROR = prefix + 'SET_NOTIFICATIONS_NETWORK_ERROR';
export const REQUEST_MESSAGES_SUBJECTS = prefix + 'REQUEST_MESSAGES_SUBJECTS';
export const REQUEST_MESSAGES_SUBJECTS_SUCCESS = REQUEST_MESSAGES_SUBJECTS + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_MESSAGES_SUBJECTS_ERROR = REQUEST_MESSAGES_SUBJECTS + AXIOS_ERROR_POSTFIX;
export const REQUEST_MESSAGE_SEND = prefix + 'REQUEST_MESSAGE_SEND';
export const REQUEST_MESSAGE_SEND_SUCCESS = REQUEST_MESSAGE_SEND + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_MESSAGE_SEND_ERROR = REQUEST_MESSAGE_SEND + AXIOS_ERROR_POSTFIX;
export const TOGGLE_CONTACTS_POPOVER = prefix + 'TOGGLE_CONTACTS_POPOVER';
export const TOGGLE_USER_DROPDOWN = prefix + 'TOGGLE_USER_DROPDOWN';
export const TOGGLE_NAV_DROPDOWN = prefix + 'TOGGLE_NAV_DROPDOWN';
export const CLOSE_NAV_DROPDOWN = prefix + 'CLOSE_NAV_DROPDOWN';
export const SET_IS_MOBILE = prefix + 'SET_IS_MOBILE';
export const SET_PAGE_VISIBILITY = prefix + 'SET_PAGE_VISIBILITY';
export const SET_VISIBILITY_INTERVAL = prefix + 'SET_VISIBILITY_INTERVAL';
export const CLEAR_VISIBILITY_INTERVAL = prefix + 'CLEAR_VISIBILITY_INTERVAL';
export const UPDATE_FRAMES_COUNT = prefix + 'UPDATE_FRAMES_COUNT';
export const APP_ONLINE = prefix + 'APP_ONLINE';
export const APP_OFFLINE = prefix + 'APP_OFFLINE';
export const TOGGLE_CHAT_POPOVER = prefix + 'TOGGLE_CHAT_POPOVER';
export const TOGGLE_REPORT_DROPDOWN = prefix + 'TOGGLE_REPORT_DROPDOWN';
export const SHOW_APP_OFFLINE_MODAL = prefix + 'SHOW_APP_OFFLINE_MODAL';
export const SET_APP_OFFLINE_TIMEOUT = prefix + 'SET_APP_OFFLINE_TIMEOUT';
export const COLLAPSE_LAYOUT = prefix + 'COLLAPSE_LAYOUT';
