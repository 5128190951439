import {type Plugin} from '@englex/slate-react';

import {type WidgetComponentProps} from 'store/exercise/player/interface';

import {getWidgetProps} from '../interface';

interface Options {
  getWidgetProps: () => WidgetComponentProps;
}

class GetWidgetProps implements Plugin {
  private readonly getWidgetProps: () => WidgetComponentProps;

  public queries = {
    [getWidgetProps]: () => this.getWidgetProps()
  };
  constructor(options: Options) {
    this.getWidgetProps = options.getWidgetProps;
  }
}

export default GetWidgetProps;
