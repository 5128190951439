import {useCallback, useEffect, useRef, useState} from 'react';

import {type DictionarySearchMode} from 'common/enums';

import {type SearchData} from '../interfaces';

export interface TextArticlesCache {
  getTextArticles: () => Record<string, SearchData>;
  setTextArticleToCache: (key: string, searchData: SearchData) => void;
  getTextArticleFromCache: (key: string) => SearchData;
}

export const useTextArticlesCache = (searchMode: DictionarySearchMode): TextArticlesCache => {
  const [textArticlesCache, setTextArticlesCache] = useState<Record<string, SearchData>>({});

  const cacheRef = useRef(textArticlesCache);

  const setTextArticleToCache = useCallback(
    (key: string, searchData: SearchData): void => {
      setTextArticlesCache(textArticlesCache => {
        const cache = {...textArticlesCache, [`key_${key}_${searchMode}`]: searchData};

        cacheRef.current = cache;

        return cache;
      });
    },
    [searchMode]
  );

  const getTextArticleFromCache = useCallback(
    (key: string): SearchData => {
      const {current: cache} = cacheRef;

      return cache[`key_${key}_${searchMode}`];
    },
    [searchMode]
  );

  const getTextArticles = useCallback(() => {
    const {current: cache} = cacheRef;

    return cache;
  }, []);

  useEffect(() => {
    cacheRef.current = textArticlesCache;
  }, [textArticlesCache]);

  return {
    getTextArticles,
    setTextArticleToCache,
    getTextArticleFromCache
  };
};
