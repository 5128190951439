import {type MarkDef} from '../../definitions';

export const textHighlights = ['orange', 'yellow', 'red', /*'brown', */ 'blue', 'green'].reduce<
  MarkDef[]
>((colors, value) => {
  colors.push({
    mark: 'highlight',
    value,
    attrs: {
      className: `sh-${value}`
    }
  });
  return colors;
}, []);

export const [
  highlightOrange,
  highlightRed,
  highlightBrown,
  highlightBlue,
  highlightGreen,
  highlightYellow
] = textHighlights;
