import React, {
  createContext,
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  memo
} from 'react';
import {useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {
  loadPronunciationsRequest,
  type LoadPronunciationsResponseAction
} from 'store/dictionary/requests';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';

import {initialState, reducer} from './reducer';
import {clearEdit, createOrEditWord, exitSelectionMode, resetModal} from './actions';
import {
  type EditEntryState,
  type EditEntryValues,
  type IEditEntryActionsContext,
  type SpellingObject
} from './interface';
import {originalToRequestPayload} from '../../utils';
import {useDictionaryContext} from '../index';

export const EditEntryStateContext = createContext<EditEntryState>({} as EditEntryState);
export const EditEntryActionsContext = createContext<IEditEntryActionsContext>(
  {} as IEditEntryActionsContext
);

export const EditEntryContextProvider: FC = memo(({children}) => {
  const intl = useIntl();
  const {pathname} = useLocation();
  const axiosDispatch = useAxiosDispatch();
  const [state, dispatch] = useReducer(reducer, initialState);
  const isMounted = useIsMounted();
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const maybeLoadPronunciation = useCallback(
    async (original: string): Promise<SpellingObject> => {
      const spellingObject: SpellingObject = {};
      if (state.editedEntry) {
        switch (state.pronunciationId) {
          case null:
            spellingObject['pronunciationId'] = null;
            break;
          case undefined:
            spellingObject['pronunciationId'] =
              state.editedEntry.dictionaryEntry.original === original
                ? state.editedEntry.pronunciationId
                : undefined;
            break;
          default:
            spellingObject['pronunciationId'] = state.pronunciationId;
        }
        switch (state.phoneticSpelling) {
          case null:
            spellingObject['phoneticSpelling'] = null;
            break;
          case undefined:
            spellingObject['phoneticSpelling'] =
              state.editedEntry.dictionaryEntry.original === original
                ? state.editedEntry.phoneticSpelling
                : undefined;
            break;
          default:
            spellingObject['phoneticSpelling'] = state.phoneticSpelling;
        }
      } else {
        spellingObject['pronunciationId'] =
          state.pronunciationId === null ? null : state.pronunciationId;
        spellingObject['phoneticSpelling'] =
          state.phoneticSpelling === null ? null : state.phoneticSpelling;
      }
      if (
        spellingObject.phoneticSpelling === undefined &&
        spellingObject.pronunciationId === undefined
      ) {
        const {partOfSpeech, word} = originalToRequestPayload(original);
        let response;
        try {
          response = await axiosDispatch<LoadPronunciationsResponseAction>(
            loadPronunciationsRequest(word, partOfSpeech)
          );
        } catch (e) {
          return {phoneticSpelling: null, pronunciationId: null};
        }
        if (response) {
          const {
            payload: {
              data: {audio, phoneticSpelling}
            }
          } = response;
          const options = audio;
          if (options.length) spellingObject['pronunciationId'] = options[0].id;
          if (phoneticSpelling) spellingObject['phoneticSpelling'] = phoneticSpelling;
        }
      }
      return spellingObject;
    },
    [axiosDispatch, state.editedEntry, state.phoneticSpelling, state.pronunciationId]
  );

  const submitForm = useCallback(
    async ({original, translation}: EditEntryValues, listId?: string) => {
      const spellingObject = await maybeLoadPronunciation(original);
      const dataObj = {original, translation, ...spellingObject};
      await axiosDispatch(
        createOrEditWord({
          dictionaryOwnerId,
          dictionaryOwnerRole,
          editedEntry: state.editedEntry,
          dataObj,
          intl,
          listId
        })
      ).catch(e => {
        if (isMounted.current) {
          throw e;
        }
      });
    },
    [
      axiosDispatch,
      dictionaryOwnerId,
      dictionaryOwnerRole,
      intl,
      isMounted,
      maybeLoadPronunciation,
      state.editedEntry
    ]
  );

  const actions = useMemo<IEditEntryActionsContext>(() => ({dispatch, submitForm}), [submitForm]);

  useEffect(() => {
    if (!state.showForm) dispatch(clearEdit());
  }, [state.showForm]);

  useEffect(() => {
    dispatch(resetModal());
  }, [dictionaryOwnerId]);

  useEffect(() => {
    dispatch(exitSelectionMode());
  }, [pathname]);

  return (
    <EditEntryActionsContext.Provider value={actions}>
      <EditEntryStateContext.Provider value={state}>{children}</EditEntryStateContext.Provider>
    </EditEntryActionsContext.Provider>
  );
});
