import {Value} from '@englex/slate';

import genKey from 'components/Slate/utils/genKey';
import {type QuizResultBlockJSON} from 'store/exercise/player/widgets/Quiz/interface';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';

import {type XQuizBlockProperties, type XQuizBlockProps} from './interface';

const Record = recordBase()(BaseRecord);

class XQuizBlockRecord extends Record implements XQuizBlockProperties {
  public declare readonly id: string;

  public declare readonly title: Value;

  public declare readonly text: Value;

  constructor(raw: XQuizBlockProps) {
    super();
    this.initValues({
      id: raw.id,
      title: Value.fromJSON(raw.title),
      text: Value.fromJSON(raw.text)
    });
  }

  public toJSON(): QuizResultBlockJSON {
    return {
      id: this.id,
      title: this.title.toJSON(),
      text: this.text.toJSON()
    };
  }
}

decorate(XQuizBlockRecord, {
  id: property(genKey()),
  title: property(),
  text: property()
});
record()(XQuizBlockRecord);
export default XQuizBlockRecord;
