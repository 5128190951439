import loadable from '@loadable/component';

export const slateDevToolsLSKey = 'dev-enlx-vc:slate-dev-tools-enabled';

const envDebugEnabled = import.meta.env.REACT_APP_SLATE_DEBUG === 'true';
export const slateDevToolsEnabled = (): boolean => {
  return (envDebugEnabled && !!localStorage.getItem(slateDevToolsLSKey)) || false;
};

export const SlateDevToolsLoadable = loadable.lib(() =>
  !envDebugEnabled
    ? import(/* webpackChunkName: "sdt-dummy" */ '@englex/slate-dev-tools/lib/DummyProvider')
    : import(/* webpackChunkName: "sdt" */ '@englex/slate-dev-tools/lib/Provider')
);
