import {type SyntheticEvent} from 'react';

type SetIsDropup = (isDropup: boolean) => void;
type GetContainer = () => Element;

export const dropdownDropupCheckCreator =
  (
    setIsDropup: SetIsDropup,
    dropdownHeight: number,
    getContainer: GetContainer = () =>
      document.getElementsByClassName('page-wrapper').item(0) || document.body
  ) =>
  (e: SyntheticEvent<{}>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dropdownButtonBottom = (e.currentTarget as any).getBoundingClientRect().bottom;
    const containerBottom = getContainer().getBoundingClientRect().bottom;
    if (containerBottom - dropdownButtonBottom < dropdownHeight) {
      setIsDropup(true);
    } else {
      setIsDropup(false);
    }
    e.stopPropagation();
  };
