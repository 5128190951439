import React from 'react';
import {useIntl} from 'react-intl';
import {HexColorPicker} from 'react-colorful';
import {type Color} from '@englex/paint-react';

import messages from '../../messages';

import './MobileColorPicker.scss';

interface Props {
  color?: Color;
  onChange: (color: Color) => void;
}

export const MobileColorPicker: React.FC<Props> = ({color, onChange}) => {
  return (
    <div className="mobile-color-picker">
      <ColorPicker color={color} />
      <HexColorPicker color={color} onChange={onChange} />
    </div>
  );
};

interface PickerColorProps {
  color?: Color;
}

const ColorPicker: React.FC<PickerColorProps> = ({color}) => {
  const intl = useIntl();

  const title = intl.formatMessage(messages.newColor);

  return (
    <div className="color-picker">
      <div className="color-picker__color">
        <div style={{backgroundColor: color}} />
      </div>
      <div className="color-picker__title">{title}</div>
    </div>
  );
};
