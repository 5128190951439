import React, {type FC, useCallback, useState} from 'react';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {connect} from 'react-redux';
import {type Dispatch} from 'redux';
import {Formik} from 'formik';

import * as toastr from 'components/toastr';
import {type AppState, type Role} from 'store/interface';
import {useApiRequestStateless} from 'hooks/rest/useApiRequestStateless';
import Icon from 'components/Icon';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import RoleSwitcher from 'components/XPreview/RoleSwitcher';
import {initialState} from 'store/exercise/player/Exercise/supplementary/reducer';
import {
  attachAdditionalExercises,
  changeRole as changeRoleActionCreator,
  clearState as clearStateActionCreator,
  requestAvailableAdditionalExercises,
  selectExercise as selectExerciseActionCreator
} from 'store/exercise/player/Exercise/supplementary/actions';
import {type SupplementaryExercisesModalState} from 'store/exercise/player/Exercise/supplementary/interface';

import i18n from './i18n';
import ModalBody from './ModalBody';
import {SEPARATOR} from './constants';

import './styles.scss';

interface DispatchProps {
  changeRole: (role: Role) => void;
  clearState: () => void;
  selectExercise: (id: string) => void;
}

interface Props extends SupplementaryExercisesModalState, DispatchProps, WrappedComponentProps {
  exerciseId: string;
  close: () => void;
  showRoleSwitcher?: boolean;
}

const SupplementaryExercisesModal: FC<Props> = ({
  exerciseId,
  activeExercise,
  changeRole,
  clearState,
  exercises,
  close,
  intl,
  role,
  selectExercise,
  showRoleSwitcher,
  xpreview
}) => {
  const [show, toggleShow] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useAxiosDispatch();

  const {isLoading, isError, reload, delay} = useApiRequestStateless<[string]>(
    true,
    requestAvailableAdditionalExercises,
    exerciseId
  );

  const onHide = useCallback(() => {
    if (!submitting) {
      toggleShow(false);
      delay(true);
    }
  }, [delay, submitting]);

  const killModal = useCallback(() => {
    close();
    clearState();
  }, [close, clearState]);

  const valuesToData = useCallback(
    (
      values: string[]
    ): Array<{
      exerciseId: string;
      homework?: true;
    }> => {
      return values
        .filter(v => v.split(SEPARATOR)[1] !== 'hidden')
        .map(v => {
          const [exerciseId, status] = v.split(SEPARATOR);
          return {
            exerciseId,
            ...(status === 'home' ? {homework: true} : {})
          };
        });
    },
    []
  );

  const onSubmit = useCallback(
    async (values: string[]) => {
      setSubmitting(true);
      let data: Array<{exerciseId: string; homework?: true}> | undefined;
      try {
        data = await dispatch(attachAdditionalExercises(exerciseId, valuesToData(values)));
      } catch (e) {
        toastr.error('', intl.formatMessage(i18n.savingStatesError));
      } finally {
        if (data) {
          onHide();
        }
        setSubmitting(false);
      }
    },
    [exerciseId, dispatch, intl, onHide, valuesToData]
  );

  return (
    <Modal
      backdrop="static"
      onHide={onHide}
      show={show}
      onEntered={() => delay(false)}
      onExited={killModal}
      className="supplementary-exercises-modal"
    >
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="SupplementaryExercisesModal.Title" />
          <Button className="btn-xs" onClick={onHide}>
            <Icon name="pc-close" />
          </Button>
        </Modal.Title>
        {showRoleSwitcher ? (
          <RoleSwitcher role={role} changeRole={changeRole} disabled={submitting} />
        ) : null}
      </Modal.Header>
      <Formik
        enableReinitialize={true}
        initialValues={exercises.map(ex => `${ex.id}${SEPARATOR}hidden`)}
        onSubmit={onSubmit}
      >
        {({values, touched}) => (
          <>
            <ModalBody
              activeExercise={activeExercise}
              exercises={exercises}
              hideControls={!showRoleSwitcher}
              isError={isError}
              isLoading={isLoading}
              reload={reload}
              role={role}
              selectExercise={selectExercise}
              xpreview={xpreview}
              submitting={submitting}
            />
            <Modal.Footer>
              <div className="actions">
                <Button bsStyle="default" bsSize="sm" onClick={onHide} disabled={submitting}>
                  <FormattedMessage id="Slate.Modal.Image.CancelButton" />
                </Button>
                <Button
                  bsStyle="primary"
                  bsSize="sm"
                  type="submit"
                  disabled={
                    isLoading || isError || submitting || !touched || !valuesToData(values).length
                  }
                  onClick={() => onSubmit(values)}
                >
                  <FormattedMessage id="Slate.Modal.Image.ApplyButton" />
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </Modal>
  );
};

const mapStateToProps = ({xplayer}: AppState): SupplementaryExercisesModalState => {
  if (!xplayer || !xplayer.supplementaryExercisesModal) {
    return initialState;
  }
  return xplayer.supplementaryExercisesModal;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  changeRole: (role: Role) => dispatch(changeRoleActionCreator(role)),
  clearState: () => dispatch(clearStateActionCreator()),
  selectExercise: (id: string) => dispatch(selectExerciseActionCreator(id))
});

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(SupplementaryExercisesModal)
);
