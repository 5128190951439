import React from 'react';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';
import {defineMessages, injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {type AppState, type AvatarUrl} from 'store/interface';
import {type Dispatch, type WampAction, type WampCallAction} from 'services/wamp/actions/interface';

import Icon from '../Icon';
import Avatar from '../Avatar';
import UnreadMessagesNotification from '../Chat/components/UnreadMessagesNotification/UnreadMessagesNotification';
import {type ConnectionComponent} from '../../webRTC/action/interface';
import {hangUp, muteCall} from '../../webRTC/action/action';
import {clearMessageToUpdate} from '../Chat/actions/action';
import {getMuteCamBtnTitle, RTCMessages} from '../../webRTC/components/RTCmessages';
import {handleError} from '../../webRTC/handleError';

const message = defineMessages({
  OpenChatPanel: {
    id: 'Chat.OpenChatPanel'
  }
});

interface OwnProps {
  sidebarPulledOut?: boolean;
  pullOutSidebar: (pulledOut: boolean) => void;
  shouldBeRendered: boolean;
  unreadMessagesCount: number;
}

interface StateProps {
  localVideoEnabled?: boolean;
  localAudioEnabled?: boolean;
  callId?: string;
  avatar?: AvatarUrl;
  callRoomId?: number;
  shouldRenderCallControlBtns?: boolean;
  shouldDisableVideoBtn: boolean;
  camId?: string;
  micId?: string;
  selectedRoomId?: number;
  isUpdatingMessage?: boolean;
}

interface DispatchProps {
  hangUp: (callRoomId: number, callId: string) => WampCallAction<Array<string | object>, {}>;
  muteCall: (
    component: ConnectionComponent,
    camId: string | null,
    micId: string | null
  ) => Promise<{}>;
  clearMessageToUpdate: (roomId: number) => void;
}

interface Props extends WrappedComponentProps, OwnProps, StateProps, DispatchProps {}

class LessonSidebarCollapsed extends React.Component<Props, {}> {
  public render() {
    const {intl, unreadMessagesCount, avatar, shouldBeRendered, shouldRenderCallControlBtns} =
      this.props;
    if (!shouldBeRendered) {
      return null;
    }
    return (
      <div
        className={classNames('lesson-sidebar-collapsed', {
          'has-actions': !!unreadMessagesCount || shouldRenderCallControlBtns
        })}
        onClick={this.expandSidebar}
      >
        <div className="left-arrow">
          <Icon name={`pc-thin-left-arrow`} />
        </div>
        <div className="avatar-container" title={intl.formatMessage(message.OpenChatPanel)}>
          <Avatar url={avatar} size={34} />
          <UnreadMessagesNotification unreadMessagesNumber={unreadMessagesCount} />
        </div>
        {this.renderMuteButtons()}
      </div>
    );
  }

  private expandSidebar = () => {
    const {
      pullOutSidebar,
      sidebarPulledOut,
      selectedRoomId,
      isUpdatingMessage,
      clearMessageToUpdate
    } = this.props;
    pullOutSidebar(!sidebarPulledOut);
    if (isUpdatingMessage && selectedRoomId !== undefined) {
      clearMessageToUpdate(selectedRoomId);
    }
  };

  private renderMuteButtons = () => {
    const {
      intl,
      camId,
      shouldDisableVideoBtn,
      localVideoEnabled,
      localAudioEnabled,
      shouldRenderCallControlBtns
    } = this.props;
    if (!shouldRenderCallControlBtns) {
      return null;
    }
    const {formatMessage} = intl;
    return (
      <div className="call-control-buttons" onClick={this.stopEventPropagation}>
        <Button
          className="btn-circle mute-button"
          onClick={this.toggleVideoState}
          title={formatMessage(getMuteCamBtnTitle(camId || '', localVideoEnabled || false))}
          disabled={shouldDisableVideoBtn}
        >
          <Icon name={localVideoEnabled ? 'video-camera' : 'virc-video_slash'} />
        </Button>
        <Button
          className="btn-circle mute-button"
          onClick={this.toggleAudioState}
          title={formatMessage(localAudioEnabled ? RTCMessages.DisableMic : RTCMessages.EnableMic)}
        >
          <Icon name={localAudioEnabled ? 'microphone' : 'microphone-slash'} />
        </Button>
        <Button
          className="btn-circle mute-button"
          bsStyle="danger"
          onClick={this.hangUp}
          title={formatMessage(RTCMessages.PartnerHangUp)}
        >
          <Icon name="phone" />
        </Button>
      </div>
    );
  };

  private stopEventPropagation = (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation();

  private toggleVideoState = async () => {
    try {
      await this.props.muteCall(
        'video',
        this.props.localVideoEnabled ? null : this.props.camId!,
        null
      );
    } catch (e) {
      handleError(e, this.props.intl.formatMessage, this.props.localVideoEnabled);
    }
  };

  private toggleAudioState = () => {
    this.props.muteCall('audio', null, this.props.localAudioEnabled ? null : this.props.micId!);
  };

  private hangUp = () => {
    const {hangUp: hangUpCall, callId, callRoomId} = this.props;
    if (callId && callRoomId) {
      hangUpCall(callRoomId, callId);
    }
  };
}

const mapStateToProps = (state: AppState): StateProps => {
  const {chat, mediaDevices, rtc, video} = state;
  const selectedRoom = chat!.rooms && chat!.selectedRoomId && chat!.rooms[chat!.selectedRoomId];
  const camId =
    mediaDevices.selectedCamPresent && mediaDevices.cameraAccess ? mediaDevices.cam : undefined;
  const micId =
    mediaDevices.selectedMicPresent && mediaDevices.micAccess ? mediaDevices.mic : undefined;
  return {
    localVideoEnabled: !!rtc.localStream?.video || false,
    localAudioEnabled: !!rtc.localStream?.audio || false,
    callId: rtc.call ? rtc.call.id : undefined,
    avatar:
      selectedRoom && selectedRoom.recipient
        ? (selectedRoom.recipient.profile!.avatars.sm as AvatarUrl)
        : undefined,
    callRoomId: rtc.call ? rtc.call.room_id : undefined,
    shouldRenderCallControlBtns:
      rtc.call &&
      !rtc.otherSessionCall &&
      !rtc.badBrowser &&
      !rtc.incomingCall &&
      !rtc.outgoingCall &&
      !video.undocked,
    shouldDisableVideoBtn: !camId || !rtc.call || rtc.call.status !== 'connected',
    camId,
    micId,
    selectedRoomId: chat!.selectedRoomId || undefined,
    isUpdatingMessage: !!chat!.messageToUpdateId
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, Action>) => ({
  muteCall: (component: ConnectionComponent, camId: string | null, micId: string | null) =>
    dispatch(muteCall(component, camId, micId) as WampAction),
  hangUp: (callRoomId: number, callId: string) =>
    dispatch<WampCallAction<Array<string | object>, {}>>(
      hangUp(callRoomId, callId, {reason: 'Ended by user'})
    ),
  clearMessageToUpdate: (roomId: number) => dispatch(clearMessageToUpdate(roomId))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LessonSidebarCollapsed));
