import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../../interface';
import MultipleChoiceRecord from '../MultipleChoice/MultipleChoiceRecord';

class OddWordRecord extends MultipleChoiceRecord {
  public get type() {
    return WidgetType.ODD_WORD;
  }
}

record()(OddWordRecord);
export default OddWordRecord;
