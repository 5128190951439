import {List, Map} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetType} from '../../interface';
import {
  type ImageLabelingCardJSON,
  type ImageLabelingJSON,
  type ImageLabelingProperties
} from './interface';
import WidgetRecord from '../../WidgetRecord';

class ImageLabelingRecord
  extends WidgetRecord<Map<string, string[]>, Map<string, string> | undefined>
  implements ImageLabelingProperties
{
  public declare readonly exampleAnswers?: Map<string, string[]>;

  public declare readonly cards: List<ImageLabelingCardJSON>;

  constructor(raw: ImageLabelingJSON) {
    super(raw);
    this.initValues({
      cards: List(raw.cards),
      values: raw.values ? Map(raw.values) : undefined,
      answers: raw.answers ? Map(raw.answers) : Map(),
      exampleAnswers: raw.exampleAnswers ? Map(raw.exampleAnswers) : Map()
    });
  }

  public get type() {
    return WidgetType.IMAGE_LABELING;
  }

  public get throttleSendValues() {
    return 500;
  }

  public get isAutoChecked() {
    return true;
  }

  public setAnswersFromJSON(json: Record<string, string[]>) {
    return this.set('answers', Map(json));
  }

  public createValues() {
    return this.set('values', Map());
  }

  public setValuesFromJSON(values?: {[key: string]: string}): this {
    return values ? this.set('values', Map(values)) : this;
  }

  public get isAvailableSelection() {
    return true;
  }

  public changeCardLabel(cardId: string, label: string) {
    return this.withMutations(d => {
      d.set('values', (d.values || Map()).set(cardId, label));
    });
  }
}
decorate(ImageLabelingRecord, {
  exampleAnswers: property(Map()),
  cards: property(List<ImageLabelingCardJSON>())
});
record()(ImageLabelingRecord);
export default ImageLabelingRecord;
