import {List, Map} from 'immutable';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';

import {genKey, valueJSONFromText} from 'components/Slate/utils';
import {type MediaMeta, WidgetMediaType, WidgetType} from 'store/exercise/player/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {
  type FlipCardsCardJSON,
  type FlipCardsJSON
} from 'store/exercise/player/widgets/FlipCards/interface';

import XWidgetRecord from '../XWidgetRecord';
import {type FlipCardsProps, type XFlipCardsProperties} from './interface';
import validationMessages from '../i18n';
import XFlipCardsCardRecord from './XFlipCardsCardRecord';
import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';

class XFlipCardsRecord extends XWidgetRecord implements XFlipCardsProperties {
  static minCardsCount: number = 1;

  static maxTextLength: number = 32;

  public declare readonly cards: List<XFlipCardsCardRecord>;

  public declare readonly defaultFlipped: boolean;

  public static createWidget(cards: List<XFlipCardsCardRecord>): XFlipCardsRecord {
    return new XFlipCardsRecord({
      id: genKey(),
      type: WidgetType.FLIP_CARDS,
      task: valueJSONFromText(''),
      cards,
      defaultFlipped: false
    });
  }

  public schema(intl: IntlShape): yup.AnyObjectSchema {
    return yup.object({
      cards: yup
        .mixed()
        .test(
          'This widget should contain at least one card',
          intl.formatMessage(validationMessages.FlipCardsNotLessOneCard),
          (cards: List<XFlipCardsCardRecord>) => cards.size >= XFlipCardsRecord.minCardsCount
        )
        .test(
          'Card images cannot be blank',
          intl.formatMessage(validationMessages.FlipCardsImagesNotEmpty),
          (cards: List<XFlipCardsCardRecord>) =>
            cards.every((card: XFlipCardsCardRecord) => !!card.imageId)
        )
        .test(
          'Card texts cannot be blank',
          intl.formatMessage(validationMessages.FlipCardsTextsNotEmpty),
          (cards: List<XFlipCardsCardRecord>) =>
            cards.every((card: XFlipCardsCardRecord) => card.text.trim().length !== 0)
        )
        .test(
          `Card text cannot be longer than ${XFlipCardsRecord.maxTextLength} characters`,
          intl.formatMessage(validationMessages.FlipCardsTextsNotMoreMaxLength),
          (cards: List<XFlipCardsCardRecord>) =>
            cards.every(
              (card: XFlipCardsCardRecord) => card.text.length <= XFlipCardsRecord.maxTextLength
            )
        )
    });
  }

  constructor(raw: FlipCardsProps) {
    super(raw);
    this.initValues({
      id: raw.id || genKey(),
      cards: this.createCards(raw),
      defaultFlipped: !!raw?.defaultFlipped
    });
  }

  public toJSON(): FlipCardsJSON {
    return {
      ...super.toJSON(),
      cards: this.cards.map((card: XFlipCardsCardRecord) => card.toJSON()).toArray(),
      defaultFlipped: this.defaultFlipped
    };
  }

  public changeDefaultFlipped() {
    return this.set('defaultFlipped', !this.defaultFlipped);
  }

  public changeImage(id: string, imageId: number) {
    return this.setIn(['cards', this.getCardIndex(id), 'imageId'], imageId);
  }

  public changeCardSizeType(id: string, cardSizeType: CardSizeType) {
    return this.setIn(['cards', this.getCardIndex(id), 'cardSizeType'], cardSizeType);
  }

  public changeText(id: string, value: string) {
    return this.setIn(['cards', this.getCardIndex(id), 'text'], value);
  }

  public addCard() {
    return this.set('cards', this.cards.push(new XFlipCardsCardRecord()));
  }

  public deleteCard(id: string) {
    return this.set('cards', this.cards.delete(this.getCardIndex(id)));
  }

  private getCardIndex(cardId: string): number {
    return this.cards.findIndex((card: XFlipCardsCardRecord) => card.id === cardId);
  }

  public moveCard(moveCardIndex: number, targetCardIndex: number) {
    const movedCard = this.cards.get(moveCardIndex);

    const newCards = this.cards.delete(moveCardIndex).insert(targetCardIndex, movedCard);

    return this.set('cards', newCards);
  }

  public rollBackCards(cards: List<XFlipCardsCardRecord>) {
    return this.set('cards', cards);
  }

  private createCards(raw: FlipCardsProps) {
    if (List.isList(raw.cards)) {
      return raw.cards;
    }
    return List(
      (raw.cards as FlipCardsCardJSON[]).map((card: FlipCardsCardJSON) => {
        return new XFlipCardsCardRecord({...card});
      })
    );
  }

  public get type() {
    return WidgetType.FLIP_CARDS;
  }

  public get excerpt(): string {
    return `${this.task.document.text} ${this.cards
      .map((c: XFlipCardsCardRecord) => `[Image] ${c.text}`)
      .join('; ')}.`.trim();
  }

  public get media(): MediaMeta | undefined {
    const images = this.cards.map((card: XFlipCardsCardRecord) => card.imageId).toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }
}

decorate(XFlipCardsRecord, {
  cards: property(List<XFlipCardsCardRecord>),
  defaultFlipped: property()
});
record()(XFlipCardsRecord);
export default XFlipCardsRecord;
