import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type HomeworkWithOverview} from 'store/interface';
import {homeworkPageSize} from 'components/Homework/static';

import {CLEAR_HOMEWORK_LIST, REQUEST_HOMEWORK_LIST, SET_HOMEWORK_LIST} from './actionTypes';

export const requestHomeworkList = (
  courseInstanceId: string,
  hideCompleted?: boolean,
  statuses?: number[],
  pageNumber?: number
): AxiosRequestAction => ({
  type: REQUEST_HOMEWORK_LIST,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/course-instance/${courseInstanceId}/homework`,
      params: {
        hideCompleted,
        statuses,
        pageNumber,
        pageSize: homeworkPageSize,
        expand: 'overview'
      }
    }
  }
});

export interface SetHomeworkListAction extends Action {
  homeworkList: HomeworkWithOverview[];
}

export const setHomeworkList = (homeworkList: HomeworkWithOverview[]): SetHomeworkListAction => ({
  type: SET_HOMEWORK_LIST,
  homeworkList
});

export const clearHomeworkList = (): Action => ({
  type: CLEAR_HOMEWORK_LIST
});
