import React, {useCallback, useState} from 'react';
import classNames from 'classnames';
import {ColorsPaint, withPaint} from '@englex/paint-react';

import {ToolbarButton} from '../../../button';
import {MobileColorModal} from './MobileColorModal';
import {ActiveColor} from '../../../ActiveColor';
import {useColor} from '../../../hooks';

import './MobileColorButton.scss';

export const MobileColorButton = withPaint(() => {
  const {active, isDisabled} = useColor();

  const [shoModal, setShowModal] = useState(false);

  const openModal = useCallback(() => setShowModal(true), []);

  const closeModal = useCallback(() => setShowModal(false), []);

  return (
    <>
      <ToolbarButton
        className={classNames('active-color-button', {active: shoModal})}
        disabled={isDisabled}
        onClick={openModal}
      >
        <ActiveColor value={active} />
      </ToolbarButton>

      <MobileColorModal show={shoModal} active={active} onHide={closeModal} />
    </>
  );
}, ColorsPaint.is);
