import {matchPath} from 'react-router-dom';
import {type IEvent} from 'autobahn';
import {type Action, type Dispatch, type MiddlewareAPI} from 'redux';
import {type Dispatch as AxiosDispatch} from 'redux-axios-middleware';

import {type AppState, type ExerciseInstanceComment, type Role} from 'store/interface';
import {type SubscribeCallback, type WampSubscribeAction} from 'services/wamp/actions/interface';
import {resolveEventFromSubscriptionTopic, resolveParamFromUri} from 'services/wamp/uriIdResolver';
import {
  type CompletedExerciseEventAction,
  type CompletedExerciseEventArgs,
  type CompletedExerciseEventKw,
  type CompletedExerciseWidgetValuesJSON,
  type ExerciseAddedEventKw,
  type UncompletedExerciseEventAction,
  type UncompletedExerciseEventArgs,
  type WidgetValuesEventArgs,
  type WidgetValuesUpdatedAction
} from 'store/exercise/player/persistence/interface';
import {
  EXERCISE_COMPLETED_EVENT,
  EXERCISE_UNCOMPLETED_EVENT,
  WIDGET_VALUES_VERSION_UPDATED_EVENT
} from 'store/exercise/player/persistence/actionTypes';
import {type ExerciseJSON, WidgetType} from 'store/exercise/player/interface';
import {loadDictionaryMetaRequest} from 'store/exercise/player/widgets/Vocabulary/action';
import {setWidgetVocabularyInfo} from 'store/exercise/player/widgets/actions';
import {
  deleteComment,
  pushComment,
  replaceComment
} from 'components/XPlayer/components/ExerciseFooter/ExerciseComments/actions';
import {type DictionaryList} from 'components/Dictionary/shared/interface';
import * as toastr from 'components/toastr';
import {
  coursebookInstancePattern,
  unitInstancePattern,
  unitInstancePatternStandalone
} from 'common/paths';
import {
  clearDraftComment,
  extraExercisesAdded,
  incrementExtraCount,
  removeExtraExerciseFromPlayer
} from 'store/exercise/player/actions';
import {type ToggleElementAction} from 'common/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {PAGE_SUBSCRIBE, REQUEST_UNIT_DETAILS, TOGGLE_UNIT_INSTANCES_DROPDOWN} from './actionTypes';
import {
  coursebookInstanceDecrementCompletedExercises,
  coursebookInstanceIncrementCompletedExercises
} from '../CourseInstancePage/actions';
import {
  unitInstanceDecrementCompletedExercises,
  unitInstanceIncrementCompletedExercises
} from '../CoursebookInstancePage/actions';
import {unitInstanceMessages} from './i18n';

export const subscribeUnitPage = (
  unitInstanceId: number,
  pageNumber: number
): WampSubscribeAction<[], {}> => ({
  type: PAGE_SUBSCRIBE,
  wamp: {
    method: 'subscribe',
    uri: `xplayer:unit._${unitInstanceId}.page._${pageNumber}.event`,
    options: {
      match: 'prefix'
    },
    rejectOnError: true
  },
  callback: onUnitPageEvent
});

const widgetValuesVersionUpdatedEvent = (
  exerciseId: string,
  widgetId: number,
  values: unknown,
  version: number
): WidgetValuesUpdatedAction => ({
  type: WIDGET_VALUES_VERSION_UPDATED_EVENT,
  exerciseId,
  widgetId,
  values,
  version
});

const exerciseCompletedEvent = (
  exerciseId: string,
  completedWidgets: CompletedExerciseWidgetValuesJSON,
  completedAt: string
): CompletedExerciseEventAction => ({
  type: EXERCISE_COMPLETED_EVENT,
  exerciseId,
  completedWidgets,
  completedAt: new Date(completedAt)
});

const exerciseUncompletedEvent = (
  exerciseId: string,
  role?: Role
): UncompletedExerciseEventAction => ({
  type: EXERCISE_UNCOMPLETED_EVENT,
  exerciseId,
  role
});

function exerciseCompletedHandler(
  args: CompletedExerciseEventArgs,
  kwargs: CompletedExerciseEventKw,
  details: IEvent,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) {
  const [widgetValuesMap, completedAt] = args;
  const {exerciseId: exerciseInstanceId, mainId} = kwargs;
  api.dispatch(exerciseCompletedEvent(exerciseInstanceId, widgetValuesMap, completedAt));
  const isMainExercise = !mainId;
  if (!isMainExercise) {
    return;
  }
  const unitInstanceId = resolveParamFromUri('unitId', details.topic)!;
  const state = api.getState();
  const unitInstances = state.classroom?.courseInstanceState.coursebookInstanceState.unitInstances;
  if (!unitInstances || !unitInstances.find(ui => ui.id === unitInstanceId)) {
    return;
  }
  api.dispatch(unitInstanceIncrementCompletedExercises(unitInstanceId));

  const coursebookInstancePageMatch = matchPath(
    {path: coursebookInstancePattern, end: false},
    state.router.location!.pathname
  );
  if (!coursebookInstancePageMatch) {
    return;
  }
  const {coursebookInstanceId} = coursebookInstancePageMatch.params;
  api.dispatch(coursebookInstanceIncrementCompletedExercises(coursebookInstanceId!));
}

const exerciseUncompletedHandler = (
  args: UncompletedExerciseEventArgs,
  details: IEvent,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  const [exerciseInstanceId, mainExerciseId] = args;
  api.dispatch(exerciseUncompletedEvent(exerciseInstanceId, api.getState().user.role));
  if (mainExerciseId) {
    return;
  }
  const unitInstanceId = resolveParamFromUri('unitId', details.topic)!;
  const state = api.getState();
  const unitInstances = state.classroom?.courseInstanceState.coursebookInstanceState.unitInstances;
  if (!unitInstances || !unitInstances.find(ui => ui.id === unitInstanceId)) {
    return;
  }
  api.dispatch(unitInstanceDecrementCompletedExercises(unitInstanceId));

  const coursebookInstancePageMatch = matchPath(
    {path: coursebookInstancePattern, end: false},
    state.router.location!.pathname
  );
  if (!coursebookInstancePageMatch) {
    return;
  }
  const {coursebookInstanceId} = coursebookInstancePageMatch.params;
  api.dispatch(coursebookInstanceDecrementCompletedExercises(coursebookInstanceId!));
};

const onUnitPageEvent: SubscribeCallback<
  | WidgetValuesEventArgs
  | CompletedExerciseEventArgs
  | UncompletedExerciseEventArgs
  | [Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>]
  | [ExerciseInstanceComment]
  | [],
  CompletedExerciseEventKw | ExerciseAddedEventKw | {}
> = (args, kwargs, details, api) => {
  const event = resolveEventFromSubscriptionTopic(details.topic);
  const state: AppState = api.getState();
  const intl = state.intl;
  switch (event) {
    case 'widget.values':
      const [exerciseId, widgetId, values, version] = args as WidgetValuesEventArgs;
      api.dispatch(widgetValuesVersionUpdatedEvent(exerciseId, widgetId, values, version));
      break;
    case 'exercise.completed':
      exerciseCompletedHandler(
        args as CompletedExerciseEventArgs,
        kwargs as CompletedExerciseEventKw,
        details,
        api
      );
      break;
    case 'exercise.incomplete':
      exerciseUncompletedHandler(args as UncompletedExerciseEventArgs, details, api);
      break;
    case 'exercise.deleted': {
      const currentUrl = api.getState().router.location!.pathname;
      const unitPagePath =
        matchPath({path: unitInstancePattern, end: false}, currentUrl) ||
        matchPath({path: unitInstancePatternStandalone, end: false}, currentUrl);

      if (!!unitPagePath) {
        const [{mainId, id}] = args as ExerciseJSON[];
        toastr.success('', intl.formatMessage(unitInstanceMessages.ExtraExercisesDeletedToast));

        const exerciseIsInXPlayer = !!api.getState().xplayer.exercises.get(id);
        if (id && exerciseIsInXPlayer) {
          const pageNumber =
            (unitPagePath.params.page && parseInt(unitPagePath.params.page, 10)) || 1;
          api.dispatch(removeExtraExerciseFromPlayer(id, pageNumber));
        }

        const parentExerciseIsInXPlayer = !!api.getState().xplayer.exercises.get(mainId);
        if (mainId && parentExerciseIsInXPlayer) {
          api.dispatch(incrementExtraCount(mainId));
        }
      }
      break;
    }
    case 'exercise.added':
      const unitInstanceId = resolveParamFromUri('unitId', details.topic)!;
      const pageNumber = resolveParamFromUri('pageId', details.topic)!;

      // if we subscribed to unit instance ID, we should just check if we are on the unit instance
      // page by matching current math like below and then update extra exercises
      const currentPath = api.getState().router.location!.pathname;
      const shouldUpdateUnitPage =
        !!matchPath({path: unitInstancePattern, end: false}, currentPath) ||
        !!matchPath({path: unitInstancePatternStandalone, end: false}, currentPath);
      if (shouldUpdateUnitPage) {
        api.dispatch(extraExercisesAdded(unitInstanceId, pageNumber)).then(() => {
          toastr.success('', intl.formatMessage(unitInstanceMessages.ExtraExerciseAddedToast));
        });
      }
      break;
    case 'exercise.comment.created':
      commentCreatedHandler(args[0] as ExerciseInstanceComment, api);
      break;
    case 'exercise.comment.updated':
      commentUpdatedHandler(args[0] as Omit<ExerciseInstanceComment, 'createdBy'>, api);
      break;
    case 'exercise.comment.deleted':
      commentDeletedHandler(
        args[0] as Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>,
        api
      );
      break;
    case 'exercise.dictionary.list.created':
    case 'exercise.dictionary.list.deleted':
      handleDictionaryListEvent(args[0] as DictionaryList, api);
      break;
    case 'exercise.dictionary.list.entries.updated':
    case 'exercise.dictionary.entries.widget':
    case 'exercise.dictionary.entry.deleted':
      handleDictionaryEntryEvent(args[0] as string, api);
      break;
    case 'exercise.dictionary.list.widget':
      handleDictionaryEntriesCreated(
        args[0] as string,
        api,
        (kwargs as {widgetId: string}).widgetId
      );
      break;
    default:
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error(`onUnitPageEvent: unknown event '${event}'`);
      }
      break;
  }
};

const commentCreatedHandler = (
  comment: ExerciseInstanceComment,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  const state = api.getState();
  const exerciseInstance = state.xplayer?.exercises.get(comment.exerciseInstanceId);
  if (exerciseInstance) api.dispatch(pushComment(comment));
};

const commentUpdatedHandler = (
  comment: Omit<ExerciseInstanceComment, 'createdBy'>,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  const state = api.getState();
  const exerciseInstance = state.xplayer?.exercises.get(comment.exerciseInstanceId);
  if (exerciseInstance) api.dispatch(replaceComment(comment));
};

const commentDeletedHandler = (
  commentDescriptor: Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  const state = api.getState();
  const exerciseInstance = state.xplayer?.exercises.get(commentDescriptor.exerciseInstanceId);
  const obsoleteDraftComment = state.xplayer?.draftComments?.find(
    dc => dc?.id === commentDescriptor.id
  );
  if (exerciseInstance) {
    api.dispatch(deleteComment(commentDescriptor));
    if (obsoleteDraftComment) {
      api.dispatch(clearDraftComment(exerciseInstance.id));
    }
  }
};

export const toggleUnitInstancesDropdown = (show: boolean): ToggleElementAction => ({
  type: TOGGLE_UNIT_INSTANCES_DROPDOWN,
  show
});

const handleDictionaryListEvent = (
  dictionaryList: DictionaryList,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  const {exerciseInstanceId} = dictionaryList;
  if (exerciseInstanceId) refreshGlossaries(exerciseInstanceId, api);
};

const handleDictionaryEntryEvent = (
  exerciseInstanceId: string,
  api: MiddlewareAPI<Dispatch<Action>, AppState>
) => {
  refreshGlossaries(exerciseInstanceId, api);
};

const handleDictionaryEntriesCreated = (
  exerciseInstanceId: string,
  api: MiddlewareAPI<Dispatch<Action>, AppState>,
  widgetId: string
) => {
  refreshGlossaries(exerciseInstanceId, api, widgetId);
};

const refreshGlossaries = (
  exerciseInstanceId: string,
  api: MiddlewareAPI<Dispatch<Action>, AppState>,
  widgetId?: string
) => {
  const {dispatch, getState} = api;
  const exercise = getState().xplayer?.exercises?.find(e => e?.id === exerciseInstanceId);
  if (!exercise) return;

  // we know which widget to refresh, no need to refresh all glossaries
  if (widgetId) return refreshGlossary(String(widgetId), exercise.id, dispatch);

  // opposite, refresh all found glossaries
  const glossaries = exercise.widgets.filter(w => w?.type === WidgetType.VOCABULARY);
  glossaries?.forEach(g => {
    if (g) refreshGlossary(g.id, exercise.id, dispatch);
  });
};

const refreshGlossary = (
  id: string,
  exerciseId: string,
  dispatch: AxiosDispatch<Action, AppState>
) => {
  const widgetId = String(id);
  dispatch(loadDictionaryMetaRequest(widgetId, exerciseId)).then(({payload: {data}}) =>
    dispatch(setWidgetVocabularyInfo(widgetId, data))
  );
};
export const requestUnitInstance: (
  unitInstanceId: number
) => AxiosRequestAction = unitInstanceId => ({
  type: REQUEST_UNIT_DETAILS,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/unit-instance/${unitInstanceId}`,
      params: {
        expand: ['coursebookInstance.hasGrammar', 'unit.intro']
      }
    }
  }
});
