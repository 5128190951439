import {type Descendant} from 'slate';
import {type ValueJSON} from '@englex/slate';

import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {type QuestValueJSON, type QuestValueProperties} from '../interface';
import {valueFromText} from '../../../../../../components/Slate/utils';
import {slateMigrateDown, slateMigrateUp} from '../../../../../../components/SlateJS/utils';

const Record = recordBase()(BaseRecord);

const WEAK_VALUES = new WeakMap<ValueJSON, Descendant[]>();

class QuestValueRecord extends Record implements QuestValueProperties {
  public declare readonly value: ValueJSON;

  public declare readonly onReview?: true;

  public get content(): Descendant[] {
    const val = this.value as ValueJSON;
    if (!WEAK_VALUES.has(val)) {
      WEAK_VALUES.set(val, slateMigrateUp(val));
    }
    return WEAK_VALUES.get(val)!;
  }

  constructor(raw: QuestValueJSON) {
    super();
    this.initValues({
      value: raw.value,
      onReview: raw.onReview
    });
  }

  public contentChange(value: Descendant[]): this {
    const val = slateMigrateDown(value);
    WEAK_VALUES.set(val, value);
    return this.set('value', val);
  }

  public toJSON(): QuestValueJSON {
    return {
      value: this.value,
      onReview: this.onReview
    };
  }
}
decorate(QuestValueRecord, {
  value: property(valueFromText('')),
  onReview: property()
});
record()(QuestValueRecord);
export default QuestValueRecord;
