import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {type CommonState} from 'store/interface';
import {type AxiosResponseAction} from 'services/axios/interface';

import {
  type SetAppVersionAction,
  type SetChangeTitleIntervalAction,
  type SetTitleAction
} from './interface';
import {
  SET_TITLE,
  REQUEST_APP_VERSION_SUCCESS,
  SET_APP_VERSION,
  SET_CHANGE_TITLE_INTERVAL
} from './actionTypes';

const ACTION_HANDLERS: ReducersMapObject = {
  [SET_TITLE]: (state: CommonState, action: SetTitleAction) => ({
    ...state,
    customTitle: action.title
  }),
  [REQUEST_APP_VERSION_SUCCESS]: (
    state: CommonState,
    action: AxiosResponseAction<{version: string; hash: string}>
  ) => ({
    ...state,
    newVersion:
      (state.version !== action.payload.data.version && action.payload.data.version) || undefined,
    newHash: (state.hash !== action.payload.data.hash && action.payload.data.hash) || undefined
  }),
  [SET_APP_VERSION]: (state: CommonState, action: SetAppVersionAction) => ({
    ...state,
    newVersion: (state.version !== action.version && action.version) || undefined,
    newHash: (state.hash !== action.hash && action.hash) || undefined
  }),
  [SET_CHANGE_TITLE_INTERVAL]: (state: CommonState, action: SetChangeTitleIntervalAction) => ({
    ...state,
    changeTitleInterval: action.id
  })
};

const initialState: CommonState = {
  version: import.meta.env.REACT_APP_RELEASE || 'unknown',
  hash: import.meta.env.REACT_APP_GIT_HASH || 'unknown'
};

function commonReducer(state: CommonState = initialState, action: Action): CommonState {
  const reducer: Reducer<CommonState> = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default commonReducer;
