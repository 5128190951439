import React from 'react';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import type MenuItem from 'react-bootstrap/lib/MenuItem';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Action} from 'redux';
import gfm from 'remark-gfm';

import {type ServiceMessageTypes, TextMessageTypes} from 'common/enums';
import * as toastr from 'components/toastr';
import InViewer from 'contexts/Viewer/adapters/InViewer';
import {Type} from 'contexts/Viewer/interface';
import {
  type ChatMessage,
  type MessageMeta,
  type ParsedMedia,
  type ParsedYTVideo,
  type TextMessageMeta
} from 'store/interface';

import {MESSAGE_DELETE_SUCCESS} from '../../../actions/actionTypes';
import UnreadCircle from './UnreadCircle';
import MessageArrow from './MessageArrow';
import EditDeleteDropdown from './EditDeleteDropdown';
import MessageBubble from './MessageBubble';
import {type ChatImageActionCreator, type ClientMessageTypes} from '../../../actions/interface';
import ChatPicture from './ChatPicture';
import Icon from '../../../../Icon';
import {checkIfParsedImage} from '../../../utils';
import YoutubeVideoPreview from './YoutubeVideoPreview';
import {emphasis, EmphasisRenderer} from '../../../../../helpers/customMarkdown/italicParser';
import {BoldRenderer} from '../../../../../helpers/customMarkdown/boldParser';
import {StrikeThroughRenderer} from '../../../../../helpers/customMarkdown/strikethrough';
import {emoji, EmojiRenderer} from '../../../../../helpers/customMarkdown/emojiParser';
import {dropdownDropupCheckCreator} from '../../../../../helpers/dropdownDropupCheck';
import LinkRenderer from '../../../../../helpers/customMarkdown/LinkRenderer';
import {ParagraphRenderer} from '../../../../../helpers/customMarkdown/ParagraphRenderer';
import {chatMessages} from './chatMessages';

import './message.scss';

interface MessageProps extends ChatMessage {
  getRef: (el: HTMLDivElement, message: ChatMessage) => void;
  type: MessageType;
  partnerName: string;
  selectedRoomId?: number;
  promoteMessageToUpdate: (roomId: number, id: number) => void;
  deleteMessage: (roomId: number, messageId: number) => Promise<{}>;
  updatingThisMessage: boolean;
  userTimezone: string;
  // todo: mb remove this prop?
  renderUpdateDeleteDropdown: boolean;
  isMobile?: boolean;
  imageLoadingFailed: ChatImageActionCreator;
  isLastMessage: boolean;
}

interface State {
  dropup: boolean;
  dropdownIsOpen: boolean;
}

type Props = MessageProps & WrappedComponentProps;

export type MessageType = ServiceMessageTypes | TextMessageTypes | ClientMessageTypes;

class TextMessage extends React.PureComponent<Props, State> {
  private static dropdownHeight: number = 105;

  private dropdownDropupCheck = dropdownDropupCheckCreator(
    dropup => this.setState({dropup}),
    TextMessage.dropdownHeight,
    () => document.getElementsByClassName('chat-messages').item(0) || document.body
  );

  public constructor(props: Props) {
    super(props);
    this.state = {
      dropup: false,
      dropdownIsOpen: false
    };
  }

  public render() {
    const {own, new: isNew, type, updatingThisMessage, intl, isLastMessage} = this.props;
    return (
      <div className={classNames('message text-message', {isLastMessage})} ref={this.ref}>
        <div className={this.getClassName()}>
          <MessageBubble
            own={own}
            type={type}
            updated_at={this.props.updated_at}
            deleted_at={this.props.deleted_at}
            created_at={this.props.created_at}
            updatingThisMessage={this.props.updatingThisMessage}
            userTimezone={this.props.userTimezone}
            intl={intl}
          >
            <UnreadCircle shouldRender={isNew} />
            {this.renderMainContent()}
          </MessageBubble>
          <MessageArrow own={own} updatingThisMessage={updatingThisMessage} type={type} />
        </div>
        {this.renderAdditionalPictureBubbles()}
      </div>
    );
  }

  private renderMainContent = () => {
    const {type, deleted_at, intl, thumbnail, meta} = this.props;
    const {Text} = this;
    if (deleted_at) {
      return (
        <React.Fragment>
          <Icon name="ban" className="deleted-message-icon" />
          <span>{intl.formatMessage(chatMessages.DeletedMessageText)}</span>
        </React.Fragment>
      );
    }
    if (type === TextMessageTypes.text || type === TextMessageTypes.textWithAdditionalBubbles) {
      return <Text />;
    }
    if (type === TextMessageTypes.image) {
      return (
        <React.Fragment>
          {this.renderEditDeleteDropdown()}
          <InViewer id={thumbnail!.clientId} type={Type.IMAGE}>
            {activate => (
              <ChatPicture
                src={thumbnail!.urls[0]}
                onError={this.imageLoadingFailed}
                onClick={activate}
                id={thumbnail!.clientId}
                isUploaded={true}
                isCorrupted={(meta as TextMessageMeta).imageCorrupted}
                intl={this.props.intl}
                retinaAvailable={true}
              />
            )}
          </InViewer>
        </React.Fragment>
      );
    }
    if (type === TextMessageTypes.mediaSingleLink) {
      const media = (meta as TextMessageMeta).parsedMedia![0];
      if (checkIfParsedImage(media)) {
        return (
          <React.Fragment>
            {this.renderEditDeleteDropdown()}
            <InViewer id={media.imageId} type={Type.IMAGE}>
              {activate => (
                <ChatPicture
                  src={media.src}
                  onError={this.imageLoadingFailed}
                  onClick={activate}
                  id={media.imageId}
                  isUploaded={false}
                  intl={this.props.intl}
                />
              )}
            </InViewer>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          {this.renderEditDeleteDropdown()}
          <YoutubeVideoPreview videoId={media.videoId} />
        </React.Fragment>
      );
    }
    return null;
  };

  private renderEditDeleteDropdown = () => {
    const {id, renderUpdateDeleteDropdown, intl, isMobile, meta} = this.props;
    if (!renderUpdateDeleteDropdown) {
      return null;
    }
    return (
      <EditDeleteDropdown
        messageId={id}
        isDropup={this.state.dropup}
        isOpen={this.state.dropdownIsOpen}
        toggleDropdown={this.toggleDropdown}
        dropdownDropupCheck={this.dropdownDropupCheck}
        intl={intl}
        editMessage={this.editMessage}
        deleteMessage={this.deleteMessage}
        isMobile={isMobile}
        canBeEdited={(meta as MessageMeta).type !== TextMessageTypes.image}
      />
    );
  };

  private getClassName = () => {
    const {own, isMobile} = this.props;
    return classNames('chat-message text', {own}, {mobile: isMobile});
  };

  private renderAdditionalPictureBubbles = () => {
    if (
      this.props.type === TextMessageTypes.textWithAdditionalBubbles &&
      (this.props.meta as TextMessageMeta).parsedMedia
    ) {
      return (this.props.meta as TextMessageMeta).parsedMedia!.map(
        this.renderAdditionalMediaBubble
      );
    }
    return null;
  };

  private renderAdditionalMediaBubble = (imageOrVideo: ParsedMedia, index: number) => {
    const {own, updatingThisMessage, type, intl} = this.props;
    return (
      <div className={this.getClassName()} key={index}>
        <MessageBubble
          own={own}
          type={type}
          isMediaMessage={true}
          updated_at={this.props.updated_at}
          deleted_at={this.props.deleted_at}
          created_at={this.props.created_at}
          updatingThisMessage={this.props.updatingThisMessage}
          userTimezone={this.props.userTimezone}
          intl={intl}
        >
          {this.renderAdditionalBubbleMedia(imageOrVideo)}
        </MessageBubble>
        <MessageArrow own={own} updatingThisMessage={updatingThisMessage} type={type} />
      </div>
    );
  };

  private renderAdditionalBubbleMedia = (imageOrVideo: ParsedMedia) => {
    if (checkIfParsedImage(imageOrVideo)) {
      return (
        <InViewer id={imageOrVideo.imageId} type={Type.IMAGE}>
          {activate => (
            <ChatPicture
              src={imageOrVideo.src}
              onError={this.imageLoadingFailed}
              onClick={activate}
              id={imageOrVideo.imageId}
              isUploaded={false}
              intl={this.props.intl}
            />
          )}
        </InViewer>
      );
    }
    return <YoutubeVideoPreview videoId={(imageOrVideo as ParsedYTVideo).videoId} />;
  };

  private imageLoadingFailed = (imageId: string) =>
    this.props.imageLoadingFailed(this.props.room_id, this.props.id, imageId);

  private ref = (el: HTMLDivElement) => el && this.props.getRef(el, this.props);

  private toggleDropdown = () => {
    this.setState({dropdownIsOpen: !this.state.dropdownIsOpen});
  };

  private editMessage = (e?: React.MouseEvent<MenuItem>) => {
    const {promoteMessageToUpdate, selectedRoomId, id} = this.props;
    if (e) {
      e.stopPropagation();
    }
    promoteMessageToUpdate(selectedRoomId!, id);
  };

  private deleteMessage = (e?: React.MouseEvent<{}>) => {
    if (e) {
      e.stopPropagation();
    }
    const {id, deleteMessage, intl, selectedRoomId} = this.props;
    deleteMessage(selectedRoomId!, id).then((action: Action) => {
      if (action.type !== MESSAGE_DELETE_SUCCESS) {
        const formatMessage = intl.formatMessage;
        toastr.error(
          formatMessage(chatMessages.DeleteMessageWampErrorHeader),
          formatMessage(chatMessages.DeleteMessageWampErrorText)
        );
      }
    });
  };

  private Markdown: React.FC<{content: string}> = ({content}) => {
    return (
      <ReactMarkdown
        linkTarget="_blank"
        remarkPlugins={[emoji, emphasis]}
        rehypePlugins={[gfm]}
        components={{
          p: ParagraphRenderer,
          code: EmojiRenderer,
          em: EmphasisRenderer,
          strong: BoldRenderer,
          del: StrikeThroughRenderer,
          a: LinkRenderer
        }}
        children={content}
        unwrapDisallowed={true}
      />
    );
  };

  private Text: React.FC = () => {
    const {text} = this.props;
    const {Markdown} = this;
    // we are interested in at least 2 '\n' occurrences simultaneously
    // 1 can brake block entities in parser and works well regardless
    // newLineBlocks is an array of matches to this condition
    const newLineBlocks = text.match(/(\n){2,}/g);
    // if no matches just don't bother renderer with all unnecessary calculations
    // and parse text. This ensures that this logic won't affect speed of rendering for
    // messages without empty lines.
    if (!newLineBlocks) {
      return (
        <React.Fragment>
          {this.renderEditDeleteDropdown()}
          <Markdown content={text} />
        </React.Fragment>
      );
    } else {
      // some elements of this array are multiple \n's in a row, and others are text content between them
      const textSplittedByEmptyLines: string[] = text.split(/(\n{2,})/);

      const length = textSplittedByEmptyLines.length - 1;
      if (textSplittedByEmptyLines[length] === '') {
        // if text ends with empty lines, last el in array is empty string ('') for some reason, and el before it is empty lines element;
        // we should ignore empty lines in the beginning and in the end of message, so remove both these elements
        textSplittedByEmptyLines.splice(length - 1, 2);
      }

      if (textSplittedByEmptyLines[0] === '') {
        // same as previous block, but for the first el in array
        textSplittedByEmptyLines.splice(0, 2);
      }

      return (
        <React.Fragment>
          {this.renderEditDeleteDropdown()}
          {textSplittedByEmptyLines.map(this.renderEmptyLineOrMarkdownText)}
        </React.Fragment>
      );
    }
  };

  private renderEmptyLineOrMarkdownText = (emptyLineOrText: string, i: number) => {
    const {Markdown} = this;
    const elIsEmptyLine = emptyLineOrText.match(/\n{2,}/);
    if (elIsEmptyLine) {
      const emptyLinesInEl = emptyLineOrText.length - 1;
      const breakLines: JSX.Element[] = [];
      for (let j = 0; j < emptyLinesInEl; j++) {
        breakLines[j] = <br key={`br-${i}-${j}`} />;
      }
      return breakLines;
    } else {
      return <Markdown content={emptyLineOrText} key={`markdown-${i}`} />;
    }
  };
}

export default injectIntl(TextMessage);
