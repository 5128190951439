import React, {type FC} from 'react';
import classNames from 'classnames';
import {PaintTool, useFocused} from '@englex/paint-react';
import {useSelector} from 'react-redux';

import {isMobileWebView} from 'helpers/browser';
import {type AppState} from 'store/interface';

import {MobileToolbar} from './mobile';
import {FontSizePicker} from './FontSizePicker';
import {YSyncHwStatus} from './YSyncHwStatus';
import {Separator} from './Separator';
import {
  CloneButton,
  ColorButton,
  DebugButton,
  DeleteButton,
  DownloadPNGButton,
  HistoryButton,
  ImageButton,
  OrientationButton,
  ScaleButton,
  SelectButton,
  ThicknessButton,
  ToolButton
} from './button';

import './Toolbar.scss';

interface Props {
  empty?: boolean;
}
export const Toolbar: FC<Props> = ({children, empty}) => {
  const isFocused = useFocused();

  const isMobile = useSelector((s: AppState) => s.layout.isMobile);

  if (isMobileWebView() || isMobile) {
    return <MobileToolbar empty={empty}>{children}</MobileToolbar>;
  }

  return (
    <div className={classNames('painter-toolbar', {focused: isFocused})}>
      {empty ? (
        children
      ) : (
        <>
          <SelectButton />
          <CloneButton />
          <DeleteButton />

          <Separator />

          <ColorButton />
          <ThicknessButton />

          <Separator />

          <Separator />

          <ToolButton tool={PaintTool.Pencil} iconName="pencil" />
          <ToolButton tool={PaintTool.Highlighter} iconName="virc-border-color" />
          <ToolButton tool={PaintTool.Line} iconName="virc-line" />
          <ToolButton tool={PaintTool.Arrow} iconName="arrow-popup" />
          <ToolButton tool={PaintTool.Rect} iconName="rectangle-popup" />
          <ToolButton tool={PaintTool.Ellipse} iconName="virc-ellipse" />
          <ImageButton />

          <Separator />

          <ToolButton tool={PaintTool.Text} iconName="font" />
          <FontSizePicker />

          <Separator />

          <HistoryButton type="undo" />
          <HistoryButton type="redo" />

          <Separator />

          <ScaleButton />
          <OrientationButton clockwise={false} />
          <OrientationButton />

          <Separator />

          {children}

          {import.meta.env.MODE === 'development' && (
            <>
              <DebugButton />
              <DownloadPNGButton />
            </>
          )}

          <Separator />

          <YSyncHwStatus />
        </>
      )}
    </div>
  );
};
