import {createTransform, purgeStoredState} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import sessionStorage from 'redux-persist/lib/storage/session';
import localForage from 'localforage';
import {type PersistConfig, type WebStorage} from 'redux-persist/es/types';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import verifyToken from '../config/jwt';
import transformAudio from './media/transformAudio';
import transformVideo from './media/transformVideo';
import transformXPlayerSync from './transformXPlayerSync';
import transformXPlayerDraftComments from './transformXPlayerDraftComments';
import transformXDraftExercises from './transformXDraftExercises';
import autoSetLevel1 from './stateReconciler/autoSetLevel1';
import {
  type AppState,
  type ClassroomState,
  type DocsState,
  type LayoutState,
  type MediaDevicesState,
  type MediaState,
  type RTCState,
  type SoundsState,
  type UserState
} from './interface';
import {type IntlState} from '../i18n/actions/interface';
import {type XEditorState} from './exercise/editor/interface';
import {type XPlayerState} from './exercise/player/interface';
import {type ClipboardState} from './exercise/editor/clipboardReducer';

export const PERSIST_PREFIX = 'enlx-vc:';

export enum PersistState {
  user = 'user',
  intl = 'intl',
  devices = 'devices',
  audio = 'audio',
  rtc = 'rtc',
  xeditor = 'xeditor',
  xplayer = 'xplayer',
  layout = 'layout',
  media = 'media',
  dictionary = 'dictionary',
  clipboard = 'clipboard',
  docs = 'docs',
  classroom = 'classroom'
}

const userTransform = createTransform(
  state => state,
  (state: string, key: string) => {
    if (key === 'token' && state) {
      if (verifyToken(state)) {
        return state;
      }
      return undefined;
    }
    return state;
  }
);

type Engine = WebStorage | typeof localForage | Storage;

interface CommonPersistConfigKeys {
  key: string;
  keyPrefix: string;
  storage: Engine;
  debug: boolean;
  timeout?: number;
}

function storageState(
  persistState: PersistState,
  engine: Engine = storage
): CommonPersistConfigKeys {
  return {
    key: persistState,
    keyPrefix: PERSIST_PREFIX,
    debug: import.meta.env.REACT_APP_DEV_REDUX_PERSIST_DEBUG_ENABLED === 'true',
    storage: engine,
    timeout: 0
  };
}

export function purgePersistState(persistState: PersistState): Promise<void> {
  return purgeStoredState(storageState(persistState));
}

export const persistIntlConfig: PersistConfig<IntlState> & {timeout?: number} = {
  ...storageState(PersistState.intl),
  whitelist: ['locale']
};

export const persistUserConfig: PersistConfig<UserState> & {timeout?: number} = {
  ...storageState(PersistState.user),
  whitelist: ['token'],
  transforms: [userTransform]
};

export const persistDevicesConfig: PersistConfig<MediaDevicesState> & {timeout?: number} = {
  ...storageState(PersistState.devices),
  whitelist: ['mic', 'cam', 'micLabel', 'camLabel', 'skipGreeting']
};

export const persistAudioConfig: PersistConfig<SoundsState> & {timeout?: number} = {
  ...storageState(PersistState.audio),
  whitelist: ['volume']
};

export const persistRTCConfig: PersistConfig<RTCState> & {timeout?: number} = {
  ...storageState(PersistState.rtc),
  whitelist: ['performSpeedtest', 'shouldHideBadBrowser']
};

export const persistLayoutConfig: PersistConfig<LayoutState> & {timeout?: number} = {
  ...storageState(PersistState.layout, sessionStorage),
  whitelist: ['enforceDesktop']
};

export const persistClipboardConfig: PersistConfig<ClipboardState> & {timeout?: number} = {
  ...storageState(PersistState.clipboard),
  stateReconciler: hardSet,
  whitelist: ['widget', 'units', 'exercises']
};

export const persistXeditorConfig: PersistConfig<XEditorState> & {timeout?: number} = {
  ...storageState(PersistState.xeditor),
  transforms: [transformXDraftExercises],
  stateReconciler: autoSetLevel1,
  whitelist: ['draftExercises'],
  storage: localForage,
  throttle: 600,
  timeout: 0
};

export const persistXPlayerConfig: PersistConfig<XPlayerState> & {timeout?: number} = {
  ...storageState(PersistState.xplayer),
  transforms: [transformXPlayerSync, transformXPlayerDraftComments],
  stateReconciler: autoSetLevel1,
  // whitelist: ['xexercise'],
  whitelist: ['sync', 'draftComments'],
  storage: localForage,
  throttle: 600,
  timeout: 0
};

export const persistMediaConfig: PersistConfig<MediaState> & {timeout?: number} = {
  ...storageState(PersistState.media),
  transforms: [transformAudio, transformVideo],
  whitelist: ['audio', 'video']
};

export const persistDictionaryConfig: PersistConfig<AppState> & {timeout?: number} = {
  ...storageState(PersistState.dictionary),
  whitelist: ['isReverse', 'listSort']
};

export const persistDocsConfig: PersistConfig<DocsState> & {timeout?: number} = {
  ...storageState(PersistState.docs),
  whitelist: ['lastPages']
};

export const persistClassroomConfig: PersistConfig<ClassroomState> & {timeout?: number} = {
  ...storageState(PersistState.classroom),
  whitelist: ['lastUnitPages']
};
