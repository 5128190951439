import React, {type FC} from 'react';
import classNames from 'classnames';
import {PaintTool} from '@englex/paint-react';

import {
  CloneButton,
  DebugButton,
  DeleteButton,
  DownloadPNGButton,
  HistoryButton,
  OrientationButton,
  SelectButton,
  ToolButton
} from '../../../button';
import {Separator} from '../../../Separator';
import {YSyncHwStatus} from '../../../YSyncHwStatus';
import {MobileColorButton} from '../color/MobileColorButton';
import {MobileThicknessButton} from '../thickness/MobileThicknessButton';
import {MobileFontSizeButton} from '../fontSize/MobileFontSizeButton';
import './MobileToolbarControls.scss';

interface Props {
  isOpen: boolean;
  isCollapsible: boolean;
}

export const MobileToolbarControls: FC<Props> = ({isOpen, isCollapsible}) => {
  return (
    <div
      className={classNames('painter-mobile-toolbar__controls', {
        open: isOpen,
        collapsible: isCollapsible
      })}
    >
      {!isCollapsible && <SelectButton />}
      <CloneButton />
      <DeleteButton />

      <Separator />

      <HistoryButton type="undo" />
      <HistoryButton type="redo" />

      <Separator />

      <MobileColorButton />
      <MobileThicknessButton />

      <Separator />

      <ToolButton tool={PaintTool.Pencil} iconName="pencil" />
      <ToolButton tool={PaintTool.Highlighter} iconName="virc-border-color" />
      <ToolButton tool={PaintTool.Line} iconName="virc-line" />
      <ToolButton tool={PaintTool.Arrow} iconName="arrow-popup" />
      <ToolButton tool={PaintTool.Rect} iconName="rectangle-popup" />
      <ToolButton tool={PaintTool.Ellipse} iconName="virc-ellipse" />

      <Separator />

      <MobileFontSizeButton />

      <Separator />

      <OrientationButton clockwise={false} />
      <OrientationButton />

      <Separator />

      {import.meta.env.MODE === 'development' && (
        <>
          <DebugButton />
          <DownloadPNGButton />
          <Separator />
        </>
      )}

      {!isCollapsible && <YSyncHwStatus />}
    </div>
  );
};
