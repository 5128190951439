import React from 'react';
import {components, type ClearIndicatorProps} from 'react-select';

import Icon from '../Icon';

export default function ClearIndicator<O, M extends boolean>(props: ClearIndicatorProps<O, M>) {
  const DefaultIndicator = components.ClearIndicator;
  return (
    <DefaultIndicator {...props}>
      <Icon name="pc-close" />
    </DefaultIndicator>
  );
}
