import React, {
  type Dispatch,
  type FC,
  type ReactNode,
  useCallback,
  useEffect,
  useRef,
  useContext
} from 'react';
import {type Action} from 'redux';

import {SwitchSidebarContext} from 'components/FloatSidebar/components/Sidebar';

import {type Id, Type} from '../interface';
import {useResourcesContext, useViewerContext} from '../';
import {activateResource, registerResource, unregisterResource} from '../state/actions';

type InViewerChild = (activate?: () => void) => ReactNode;

const InViewer: FC<{children: InViewerChild; id: Id; type: Type}> = React.memo(
  ({children, id, type}) => {
    const viewerContext = useViewerContext();
    const resourcesContext = useResourcesContext();

    if (!(resourcesContext && viewerContext)) {
      return <>{children()}</>;
    }
    return (
      <InViewerIfResources
        dispatch={viewerContext.dispatch}
        id={id}
        providerId={resourcesContext.id}
        type={type}
      >
        {children}
      </InViewerIfResources>
    );
  }
);

const InViewerIfResources: FC<{
  children: InViewerChild;
  dispatch: Dispatch<Action>;
  id: Id;
  providerId: Id;
  type: Type;
}> = React.memo(({children, dispatch, id, providerId, type}) => {
  const closeSidebar = useContext(SwitchSidebarContext);
  const activate = useCallback(() => {
    dispatch(activateResource(providerId, id, type));
    closeSidebar();
  }, [closeSidebar, dispatch, id, providerId, type]);

  useEffect(() => {
    return () => dispatch(unregisterResource(providerId, id, type));
  }, [dispatch, id, providerId, type]);

  return type === Type.YT ? (
    <Video dispatch={dispatch} id={id} providerId={providerId}>
      {children(activate)}
    </Video>
  ) : (
    <>{children(activate)}</>
  );
});

const Video: FC<{
  dispatch: Dispatch<Action>;
  id: Id;
  providerId: Id;
}> = ({children, dispatch, id, providerId}) => {
  const registered = useRef(false);

  useEffect(() => {
    if (!registered.current) {
      dispatch(registerResource(providerId, id, Type.YT));
      registered.current = true;
    }
  });

  useEffect(() => {
    return () => {
      registered.current = false;
    };
  }, [dispatch]);
  return <>{children}</>;
};

export default InViewer;
