import React from 'react';
import {connect, type MapStateToProps} from 'react-redux';
import classNames from 'classnames';

import {type AppState, CONNECTED, CONNECTING, RETRYING, type WampStatus} from 'store/interface';

import ReconnectingStatus from './ReconnectingStatus';
import './connection-indicator.scss';

interface StateProps {
  status: WampStatus;
  appOnline: boolean;
}

type Props = StateProps;

class ConnectionIndicator extends React.Component<Props, {}> {
  public render() {
    const statusClass = this.statusClass();
    const classes = classNames(['connection-indicator', this.statusClass()]);
    if (statusClass === 'reconnecting') {
      return (
        <span className={classes}>
          <ReconnectingStatus />
        </span>
      );
    }
    return <span className={classes} />;
  }

  private statusClass(): string {
    const {status, appOnline} = this.props;
    switch (status) {
      case CONNECTED:
        if (!appOnline) {
          return 'app-offline';
        }
        return 'online';
      case CONNECTING:
      case RETRYING:
        return 'reconnecting';
      default:
        return 'offline';
    }
  }
}

const mapStateToProps: MapStateToProps<StateProps, {}, {}> = (state: AppState) => ({
  status: state.wamp.status,
  appOnline: !!state.layout.appOnline
});

export default connect(mapStateToProps)(ConnectionIndicator);
