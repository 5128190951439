import React, {type FC, useEffect} from 'react';
import {useSelector} from 'react-redux';

import {type AppState, type ImageViewerImage} from 'store/interface';

import {loadResources} from '../state/actions';
import {Type, type ViewerContextShape} from '../interface';
import {useResourcesContext, useViewerContext} from '../';

interface Props {
  id: string;
  images: ImageViewerImage[];
  viewerContext: ViewerContextShape;
}

const ChatImageAdapter: FC = () => {
  const viewerContext = useViewerContext();
  const resourcesContext = useResourcesContext();
  const images = useSelector<AppState, ImageViewerImage[]>(s => s.chat!.images);

  return resourcesContext && viewerContext ? (
    <ChatImageAdapterIfResources
      images={images}
      id={resourcesContext.id}
      viewerContext={viewerContext}
    />
  ) : null;
};

const ChatImageAdapterIfResources: FC<Props> = ({id, images, viewerContext: {dispatch}}) => {
  useEffect(() => {
    dispatch(
      loadResources(
        id,
        images.map(im => {
          return {
            id: im.id,
            urls: im.retina ? [im.src, im.retina] : [im.src],
            type: Type.IMAGE
          };
        }),
        Type.IMAGE
      )
    );
  }, [dispatch, id, images]);

  return null;
};

export default ChatImageAdapter;
