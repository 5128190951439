import {fromJS, List, Map, Seq} from 'immutable';
import {type Block} from '@englex/slate';

import {genKey} from 'components/Slate/utils';
import {type XMatchingChoices} from 'store/exercise/editor/widgets/XMatching/interface';
import {getAnswerBlocks} from 'store/exercise/editor/widgets/XMatching/utils';

import {
  type MatchingNoCategoriesJSON,
  type MatchingNoCategoriesProperties,
  type QuestionBlockJSON,
  type QuestionBlockMap
} from './interface';
import {type ChoicesMap} from '../../interface';
import MatchingBaseRecord from './MatchingBaseRecord';

class MatchingNoCategoriesRecord
  extends MatchingBaseRecord<undefined, List<QuestionBlockMap> | undefined, ChoicesMap | undefined>
  implements MatchingNoCategoriesProperties
{
  constructor(raw: MatchingNoCategoriesJSON) {
    super(raw);
    const choices = this.сhoices;

    this.initValues({
      values: raw.values ? this.updateValues(raw.values, choices) : undefined,
      choices: choices
    });
  }

  public toJSON(): MatchingNoCategoriesJSON {
    return {
      ...super.toJSON(),
      values: this.values ? this.values.toJS() : undefined
    };
  }

  public setValuesFromJSON(values?: QuestionBlockJSON[]): this {
    return values ? this.set('values', fromJS(values)) : this;
  }

  public changeCategory(questionId: string, category: string): this {
    const questionBlockIndex = this.getQuestionBlockIndex(questionId);

    return this.setIn(['values', questionBlockIndex, 'category'], category);
  }

  private updateValues(values: QuestionBlockJSON[], choices: XMatchingChoices | undefined) {
    const choiceIds: Seq.Indexed<string> = choices ? choices.keySeq() : Seq([]);

    const filteredValues = values.map(value => ({
      ...value,
      answers: value.answers.filter(answer => choiceIds.includes(answer))
    }));

    if (filteredValues.length > choiceIds.size) {
      return fromJS(
        filteredValues.sort((a, b) => b.answers.length - a.answers.length).slice(0, choiceIds.size)
      );
    }

    return fromJS(filteredValues);
  }

  private get сhoices(): XMatchingChoices {
    const value = this.content;
    const answers = getAnswerBlocks(value.document);
    return Map(
      answers
        .sort((b1: Block, b2: Block) => b1.text.localeCompare(b2.text))
        .map((block: Block) => [block.text, {value: block.text}])
    );
  }

  public addCategory(): this {
    if (!this.values) {
      return this.set(
        'values',
        List([
          Map({
            id: genKey(),
            category: '',
            answers: List([])
          })
        ])
      );
    }

    const newCategory = Map({
      id: genKey(),
      category: '',
      answers: List([])
    });

    const newValues = this.get('values').push(newCategory);

    return this.set('values', newValues);
  }

  public deleteCategory(id: string): this {
    const newValues = this.get('values').filter(
      (questionBlock: QuestionBlockMap) => questionBlock.get('id') !== id
    );

    return this.set('values', newValues);
  }

  public dropAnswer(questionId: string, choiceId: string): this {
    let state = this;

    const questionBlock = this.getQuestionBlock(questionId);
    const questionBlockIndex = this.getQuestionBlockIndex(questionId);
    const currentQuestionValue = questionBlock?.get('answers');
    const previousQuestionKey = this.getQuestionContainingChoice(choiceId);

    if (previousQuestionKey) {
      state = this.removeAnswerFromQuestion(previousQuestionKey, choiceId);
    }
    if (!currentQuestionValue) {
      return state.setIn(['values', questionBlockIndex, 'answers'], List([choiceId]));
    } else {
      return state.setIn(
        ['values', questionBlockIndex, 'answers'],
        currentQuestionValue.push(choiceId)
      );
    }
  }

  public returnAnswer(choiceId: string): this {
    const state = this;

    const previousQuestionKey = this.getQuestionContainingChoice(choiceId);
    if (previousQuestionKey) {
      return this.removeAnswerFromQuestion(previousQuestionKey, choiceId);
    }
    return state;
  }

  public getMatchingValue(questionId: string) {
    const questionBlockIndex = this.getQuestionBlockIndex(questionId);

    return this.values?.getIn([questionBlockIndex, 'answers']);
  }

  public choiceIsUsed(choiceId?: string) {
    return choiceId && this.values
      ? !!this.values.find((questionBlock: QuestionBlockMap) =>
          questionBlock.get('answers')?.includes(choiceId)
        )
      : false;
  }

  public receivedChoicesWithThisValue(questionId: string, value: string) {
    const questionBlockIndex = this.getQuestionBlockIndex(questionId);

    return this.values
      ?.getIn([questionBlockIndex, 'answers'])
      .filter((id: string) => this.choices!.get(id!).value === value) as List<string>;
  }

  public getQuestionBlock(questionId: string) {
    if (!this.values) return undefined;

    return this.values.find(
      (questionBlock: QuestionBlockMap) => questionBlock.get('id') === questionId
    );
  }

  public getQuestionBlockIndex(questionId: string) {
    return this.values?.findIndex(
      (questionBlock: QuestionBlockMap) => questionBlock.get('id') === questionId
    );
  }

  private removeAnswerFromQuestion(questionId: string, choiceId: string) {
    const questionBlockIndex = this.getQuestionBlockIndex(questionId);

    if (this.getQuestionBlock(questionId)?.size === 1) {
      return this.setIn(['values', questionBlockIndex, 'answers'], List());
    } else {
      const answersWithoutReturned = this.getQuestionBlock(questionId)
        ?.get('answers')
        .filter((value: string) => value !== choiceId);
      return this.setIn(['values', questionBlockIndex, 'answers'], answersWithoutReturned);
    }
  }

  private getQuestionContainingChoice(choiceId: string) {
    if (!this.values) return undefined;

    const test = this.values.find((questionBlock: QuestionBlockMap) =>
      questionBlock.get('answers').includes(choiceId)
    );
    return test?.get('id');
  }
}

export default MatchingNoCategoriesRecord;
