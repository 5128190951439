import {Value, type ValueJSON} from '@englex/slate';

import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {valueFromText, valueJSONFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';

const Record = recordBase()(BaseRecord);

interface Btn {
  icon?: string;
  className?: string;
  modalTitle?: string;
}

export interface DisplayButtonJSON extends Btn {
  title: ValueJSON;
}

export interface DisplayButtonProperties extends Btn {
  title: Value;
  toJSON(): DisplayButtonJSON;
}

const isDisplayButtonJSON = (
  content: ValueJSON | DisplayButtonJSON
): content is DisplayButtonJSON => {
  return (content as DisplayButtonJSON).title !== undefined;
};

export class DisplayButtonRecord extends Record implements DisplayButtonProperties {
  public static create(raw?: DisplayButtonJSON | ValueJSON): DisplayButtonRecord {
    const json: DisplayButtonJSON =
      raw && isDisplayButtonJSON(raw) ? raw : {title: raw || valueJSONFromText()};
    return new DisplayButtonRecord(json);
  }

  public declare readonly title: Value;
  public declare readonly icon?: string;
  public declare readonly className?: string;
  public declare readonly modalTitle?: string;

  public constructor(raw: DisplayButtonJSON) {
    super();
    this.initValues({
      title: Value.fromJSON(raw.title),
      icon: raw.icon,
      className: raw.className,
      modalTitle: raw.modalTitle
    });
  }

  public toJSON(): DisplayButtonJSON {
    return {
      title: this.title.toJSON(),
      icon: this.icon,
      className: this.className,
      modalTitle: this.modalTitle
    };
  }
}

decorate(DisplayButtonRecord, {
  title: property(valueFromText()),
  icon: property(),
  className: property(),
  modalTitle: property()
});
record()(DisplayButtonRecord);
