import {Value} from '@englex/slate';

import genKey from 'components/Slate/utils/genKey';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';

import {type QuizBlockProps, type QuizResultBlockJSON} from './interface';

const Record = recordBase()(BaseRecord);

class QuizBlockRecord extends Record {
  public declare readonly id: string;

  public declare readonly title: Value;

  public declare readonly text: Value;

  constructor(raw: QuizBlockProps) {
    super();
    this.initValues({
      id: raw.id,
      title: Value.fromJSON(raw.title),
      text: Value.fromJSON(raw.text)
    });
  }

  public toJSON(): QuizResultBlockJSON {
    return {
      id: this.id,
      title: this.title.toJSON(),
      text: this.text.toJSON()
    };
  }
}

decorate(QuizBlockRecord, {
  id: property(genKey()),
  title: property(),
  text: property()
});
record()(QuizBlockRecord);
export default QuizBlockRecord;
