import React, {createContext, useCallback, useContext, useState} from 'react';

import {type ImageV2} from 'store/interface';

export interface ImageCacheContextShape {
  imageCache: ImageCache;
  addToImageCache: (imageId: number, imageData: ImageV2) => void;
}

interface ImageCache {
  [key: number]: ImageV2;
}

interface Props {
  children?: React.ReactNode;
}

export const ImageCacheContext = createContext<ImageCacheContextShape>(
  {} as ImageCacheContextShape
);

const ImageCacheProvider: React.FC<Props> = ({children}) => {
  const [imageCache, setImageCache] = useState<ImageCache>({});

  const addToImageCache = useCallback(
    (imageId: number, imageData: ImageV2) => {
      setImageCache({...imageCache, [imageId]: imageData});
    },
    [imageCache]
  );

  return (
    <ImageCacheContext.Provider value={{imageCache, addToImageCache}}>
      {children}
    </ImageCacheContext.Provider>
  );
};

const useImageCacheContext = () => useContext(ImageCacheContext);

export {ImageCacheProvider, useImageCacheContext};
