import React from 'react';
import {type Editor, type RenderInlineProps} from '@englex/slate-react';
import {type Next} from '@englex/slate';

import {type RendererPlugin} from '../../interface';
import {isLinkInline} from '../../../utils';

import './Link.scss';

type Target = '_blank' | '_self';

interface Options {
  target?: Target;
  className?: string;
}

class Link implements RendererPlugin {
  private readonly target: Target;
  private readonly className?: string;

  constructor(options: Options = {}) {
    const {target = '_blank', className} = options;
    this.target = target;
    this.className = className;
  }
  public renderInline = (props: RenderInlineProps, editor: Editor, next: Next) => {
    const {node, attributes, children} = props;
    if (!isLinkInline(node)) {
      return next();
    }

    const href = node.data.get('href');

    return (
      <a {...attributes} href={href} target={this.target} className={this.className}>
        {children}
      </a>
    );
  };
}

export default Link;
