import {AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

const PATH = 'COMMON/';

export const REQUEST_APP_VERSION = PATH + 'REQUEST_APP_VERSION';
export const REQUEST_APP_VERSION_SUCCESS = REQUEST_APP_VERSION + AXIOS_SUCCESS_POSTFIX;
export const SET_TITLE = PATH + 'SET_TITLE';
export const SUBSCRIBE_TO_APP_UPDATE = PATH + 'SUBSCRIBE_TO_APP_UPDATE';
export const SET_APP_VERSION = PATH + 'SET_APP_VERSION';
export const SET_CHANGE_TITLE_INTERVAL = PATH + 'SET_CHANGE_TITLE_INTERVAL';

export const BATCH_MEDIA = 'CLASSROOM/BATCH_MEDIA';
export const SET_POINTER_SELECTION = 'CLASSROOM/SET_POINTER_SELECTION';
export const CLEAR_POINTER_SELECTION = 'CLASSROOM/CLEAR_POINTER_SELECTION';
export const PUBLISH_OPEN_URL = PATH + 'PUBLISH_OPEN_URL';
export const PUBLISH_URL_OPENED = PATH + 'PUBLISH_URL_OPENED';
export const SET_OPENING_URL_FOR_PARTNER = PATH + 'SET_OPENING_URL_FOR_PARTNER';
export const URL_OPENED = PATH + 'URL_OPENED';
export const SET_OPENING_EXERCISE_FOR_PARTNER = PATH + 'SET_OPENING_EXERCISE_FOR_PARTNER';
export const URL_OPENED_EXERCISE = PATH + 'URL_OPENED_EXERCISE';
export const SET_INCORRECT_URL_ID = PATH + 'SET_INCORRECT_URL_ID';
export const SET_REDIRECTED_FROM_URL = PATH + 'SET_REDIRECTED_FROM_URL';
export const CLEAR_REDIRECTED_FROM_URL = PATH + 'CLEAR_REDIRECTED_FROM_URL';
export const REQUEST_UNIT_INSTANCES = PATH + 'REQUEST_UNIT_INSTANCES';
export const PUBLISH_EXERCISE_URL_OPEN = PATH + 'PUBLISH_EXERCISE_URL_OPEN';
export const PUBLISH_EXERCISE_URL_OPENED = PATH + 'PUBLISH_EXERCISE_URL_OPENED';
export const PUBLISH_POINTER_URL = PATH + 'PUBLISH_POINTER_URL';
export const PUBLISH_POINTER_URL_OPENED = PATH + 'PUBLISH_POINTER_URL_OPENED';
export const SET_PUBLISHING_POINTER = PATH + 'SET_PUBLISHING_POINTER';
export const URL_OPENED_POINTER = PATH + 'URL_OPENED_POINTER';
export const SET_LAST_VISITED_UNIT_PAGE = PATH + 'SET_LAST_VISITED_UNIT_PAGE';
