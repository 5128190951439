import React from 'react';
import {connect} from 'react-redux';

import {formatSecondsToString} from 'services/common-methods/secondsToString';
import {type AppState} from 'store/interface';

class CallDuration extends React.Component<{duration: number}, {}> {
  public render() {
    return <div className="call-duration">{formatSecondsToString(this.props.duration)}</div>;
  }
}

const mapStateToProp = (state: AppState) => ({
  duration: (state.rtc.call && state.rtc.call.duration) || 0
});

export default connect(mapStateToProp)(CallDuration);
