import React, {useCallback, useEffect, useRef, useState} from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import {useIntl} from 'react-intl';
import {ThicknessPaint, ToolsPaint, useFocused, usePaint, withPaint} from '@englex/paint-react';
import {type ShapeAttrs, Transforms} from '@englex/paint';

import Icon from 'components/Icon';
import {ThicknessPresetPaint} from 'components/Paint/Painter/plugins';
import isShortcut, {type Shortcut} from 'helpers/shortcut';

import {ToolbarButton} from './ToolbarButton';
import {thicknessTitles} from '../titles';
import {useThickness} from '../hooks';

interface Props {
  shortcut?: Shortcut;
}

export const ThicknessButton = withPaint(
  ({shortcut = 'mod+opt+l'}: Props) => {
    const intl = useIntl();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const paint = usePaint<ToolsPaint & ThicknessPresetPaint>();
    const isFocused = useFocused();
    const ref = useRef<HTMLSpanElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const firstButtonRef = useRef<HTMLButtonElement | null>(null);
    const lastButtonRef = useRef<HTMLButtonElement | null>(null);

    const {active, selectedNode, isSelectable, preset, isDisabled} = useThickness();

    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (!isFocused) {
          return;
        }

        if (isShortcut(e, shortcut)) {
          e.preventDefault();
          buttonRef.current?.focus();
          buttonRef.current?.click();
        }
        if (tooltipVisible && isShortcut(e, 'esc')) {
          setTooltipVisible(false);
          buttonRef.current?.focus();
        }
      },
      [isFocused, shortcut, tooltipVisible]
    );

    const onEdgeToolKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLButtonElement>) => {
        if (!tooltipVisible) {
          return;
        }
        if (isShortcut(e, 'tab') && lastButtonRef.current === e.target) {
          e.preventDefault();
          e.stopPropagation();
          firstButtonRef.current?.focus({preventScroll: true});
        }
        if (isShortcut(e, 'shift+tab') && firstButtonRef.current === e.target) {
          e.preventDefault();
          e.stopPropagation();
          lastButtonRef.current?.focus({preventScroll: true});
        }
      },
      [tooltipVisible]
    );

    const selectThickness = useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        const thicknessName = e.currentTarget.dataset.thickness;

        if (
          ThicknessPresetPaint.isThicknessName(paint, thicknessName) &&
          thicknessName !== active
        ) {
          if (selectedNode && isSelectable) {
            const thickness = ThicknessPresetPaint.toThickness(thicknessName, preset);
            const change: ShapeAttrs = {strokeWidth: thickness};
            if (selectedNode.className === 'Arrow' && thickness) {
              change.pointerLength = ThicknessPaint.getArrowAnchorLength(paint, thickness);
              change.pointerWidth = ThicknessPaint.getArrowAnchorWidth(paint, thickness);
            }
            Transforms.setNodes(paint, change);
          }

          ThicknessPresetPaint.setThicknessName(paint, thicknessName);

          setTooltipVisible(false);
          buttonRef.current?.focus();
        }
      },
      [active, isSelectable, paint, preset, selectedNode]
    );

    const onVisibleChange = useCallback(
      (v: boolean) => {
        !isDisabled && setTooltipVisible(v);
      },
      [isDisabled]
    );

    useEffect(() => {
      if (!isFocused && tooltipVisible) {
        setTooltipVisible(false);
      }
    }, [isFocused, tooltipVisible]);

    useEffect(() => {
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    });

    const presetEntries = Object.keys(paint.thickness.preset.defaultMap);

    return (
      <Tooltip
        overlay={() =>
          presetEntries.map((name, i) => {
            const isFirst = i === 0;
            const isLast = i === presetEntries.length - 1;
            return (
              <ToolbarButton
                ref={isFirst ? firstButtonRef : isLast ? lastButtonRef : null}
                className={classNames({active: name === active})}
                data-thickness={name}
                key={name}
                onClick={selectThickness}
                onKeyDown={isFirst || isLast ? onEdgeToolKeyDown : undefined}
                title={intl.formatMessage(thicknessTitles[name])}
              >
                <Icon name={`virc-line${name === 'medium' ? '' : `-${name}`}`} />
              </ToolbarButton>
            );
          })
        }
        visible={tooltipVisible && !isDisabled}
        onVisibleChange={onVisibleChange}
        destroyTooltipOnHide={{keepParent: false}}
        getTooltipContainer={() => ref.current || document.body}
        placement="bottomLeft"
        trigger={['click']}
      >
        <span ref={ref}>
          <ToolbarButton
            ref={buttonRef}
            className={classNames({active: tooltipVisible})}
            title={intl.formatMessage({id: 'Thickness.Selection'})}
            disabled={isDisabled}
            shortcut={shortcut}
          >
            <Icon name="virc-thickness" />
          </ToolbarButton>
        </span>
      </Tooltip>
    );
  },
  p => ToolsPaint.is(p) && ThicknessPresetPaint.is(p)
);
