import {List, Map, Seq} from 'immutable';
import {type Block} from '@englex/slate';

import {type XMatchingChoices} from 'store/exercise/editor/widgets/XMatching/interface';
import {SlateBlock} from 'components/Slate/interface';

import {type ChoicesMap} from '../../interface';
import MatchingBaseRecord from './MatchingBaseRecord';
import {
  type MatchingJSON,
  type MatchingProperties,
  MatchingType,
  type WidgetMatchingAnswersJSON
} from './interface';
class MatchingRecord
  extends MatchingBaseRecord<
    Map<string, List<string>> | undefined,
    Map<string, List<string>> | undefined,
    ChoicesMap | undefined
  >
  implements MatchingProperties
{
  constructor(raw: MatchingJSON) {
    super(raw);
    this.initValues({
      values: raw.values ? this.updateValues(raw.values, this.choices) : undefined
    });
  }

  public toJSON(): MatchingJSON {
    return {
      ...super.toJSON(),
      values: this.values ? this.values.toJS() : undefined
    };
  }

  private updateValues(values: WidgetMatchingAnswersJSON, choices: XMatchingChoices | undefined) {
    if (this.matchingType === MatchingType.DEFAULT) return this.valuesFromJSON(values);

    const choiceIds: Seq.Indexed<string> = choices ? choices.keySeq() : Seq([]);
    const categoryIds = this.content.document
      .filterDescendants((d: Block) => d.type === SlateBlock.LIST_ITEM)
      .map((item: Block) => item.getIn(['data', 'id']));

    return Map(values)
      .mapEntries((entry: [string, string[]]) => [
        entry[0],
        List(entry[1].filter(answer => choiceIds.includes(answer)))
      ])
      .filter((value: List<string>, key: string) => !value.isEmpty() && categoryIds.includes(key));
  }

  public setValuesFromJSON(values?: WidgetMatchingAnswersJSON): this {
    return values ? this.set('values', this.valuesFromJSON(values)) : this;
  }

  public createValues() {
    return this.set('values', Map());
  }

  public get isAutoChecked() {
    return true;
  }

  public getMatchingValue(questionId: string) {
    return this.values?.get(questionId);
  }

  public choiceIsUsed(choiceId?: string) {
    return choiceId && this.values
      ? !!this.values.find((valuesArr: List<string>) => valuesArr.includes(choiceId))
      : false;
  }

  public receivedChoicesWithThisValue(questionId: string, value: string) {
    return this.values!.get(questionId).filter(
      (id: string) => this.choices!.get(id!).value === value
    ) as List<string>;
  }

  public dropAnswer(questionId: string, choiceId: string) {
    let state = this;

    if (!state.values) {
      state = state.createValues();
    }
    const currentQuestionValue = state.values!.get(questionId);
    const previousQuestionKey = this.getQuestionContainingChoice(choiceId);
    if (previousQuestionKey) {
      state = this.removeAnswerFromQuestion(previousQuestionKey, choiceId);
    }
    if (!currentQuestionValue) {
      return state.setIn(['values', questionId], List([choiceId]));
    } else {
      return state.setIn(['values', questionId], currentQuestionValue.push(choiceId));
    }
  }

  public returnAnswer(choiceId: string) {
    const previousQuestionKey = this.getQuestionContainingChoice(choiceId);
    if (previousQuestionKey) {
      return this.removeAnswerFromQuestion(previousQuestionKey, choiceId);
    }
    return this;
  }

  private removeAnswerFromQuestion(questionId: string, choiceId: string) {
    if (this.values!.get(questionId).size === 1) {
      // if values array had only one selected answer, return empty list
      // in order to track that question is actually touched
      return this.setIn(['values', questionId], List());
    } else {
      // else just remove selected answer from array
      const answersWithoutReturned = this.values!.get(questionId).filter(
        (value: string) => value !== choiceId
      );
      return this.setIn(['values', questionId], answersWithoutReturned);
    }
  }

  private getQuestionContainingChoice(choiceId: string) {
    return this.values
      ? this.values.findKey((value: List<string>) => value.includes(choiceId))
      : undefined;
  }
}

export default MatchingRecord;
