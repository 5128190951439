import React, {type FC, useCallback} from 'react';
import classNames from 'classnames';
import Highlighter from 'react-highlight-words';

interface ContentsItemProps {
  title: string;
  searchWords: string[];
  index: number;
  selectPage(page: number): void;
  active: boolean;
  closeParent(): void;
}

export const GrammarContentsItem: FC<ContentsItemProps> = ({
  title,
  searchWords,
  index,
  selectPage,
  active,
  closeParent
}) => {
  const onClick = useCallback(() => {
    closeParent();
    if (!active) selectPage(index);
  }, [active, closeParent, index, selectPage]);

  return (
    <li className={classNames('unit-contents-list-item grammar', {active})} onClick={onClick}>
      <div className="index">{index}</div>
      <div className="excerpt">
        {title && (
          <span className="title">
            {searchWords.length !== 0 ? (
              <Highlighter
                highlightClassName="highlight"
                searchWords={searchWords}
                textToHighlight={title}
                autoEscape={true}
              />
            ) : (
              title
            )}
          </span>
        )}
      </div>
    </li>
  );
};
