import {type FC, type PropsWithChildren, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {type ThunkDispatch} from 'redux-thunk';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';
import {useUserId} from 'hooks/user/useUserId';

import {loginRequired} from '../actions/action';

type Props = PropsWithChildren<{}>;

export const IsAuthenticated: FC<Props> = ({children}) => {
  const isAuthenticated = !!useUserId();
  const dispatch = useDispatch<ThunkDispatch<AppState, never, Action>>();

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(loginRequired());
    }
  }, [dispatch, isAuthenticated]);

  if (!isAuthenticated) {
    return null;
  }

  return <>{children}</>;
};
