import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useIntl} from 'react-intl';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import {ColorsPaint, useFocused, withPaint, type Color} from '@englex/paint-react';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import {ToolbarButton} from './ToolbarButton';
import isShortcut, {type Shortcut} from '../../../../helpers/shortcut';
import {ColorPicker} from '../ColorPicker';
import {ActiveColor} from '../ActiveColor';
import {useColor} from '../hooks';

interface Props {
  shortcut?: Shortcut;
  palette?: Color[];
  showColorfulToggle?: boolean;
  breakpoint?: number;
}

const defaultPalette: Color[] = [
  '#414141',
  '#8f5721',
  '#b14b26',
  '#f36f20',
  '#ffb400',
  '#6e8532',
  '#33a6b3',
  '#7167b4'
];

const trigger = ['click'];
const destroyTooltipOnHide = {keepParent: false};
const mobileAlign = {offset: [10, 0]};
const mobilePaletteLength = 6;

export const ColorButton = withPaint(
  ({
    shortcut = 'mod+opt+c',
    palette = defaultPalette,
    showColorfulToggle = true,
    breakpoint = 480
  }: Props) => {
    const intl = useIntl();

    const {active, isDisabled} = useColor();

    const [tooltipVisible, setTooltipVisible] = useState(false);

    const isFocused = useFocused();

    const matches = useMediaQuery(breakpoint);

    const align = matches ? mobileAlign : undefined;
    const placement = matches ? 'bottom' : 'bottomLeft';
    const paletteLength = matches ? mobilePaletteLength : undefined;

    const ref = useRef<HTMLSpanElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);

    const hideTooltip = useCallback(() => {
      setTooltipVisible(false);
      buttonRef.current?.focus();
    }, []);

    const onChange = useCallback(() => {
      hideTooltip();
    }, [hideTooltip]);

    // TODO: remove code duplication for toolbar tooltip handlers (see ThicknessButton)
    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (!isFocused) {
          return;
        }

        if (isShortcut(e, shortcut)) {
          e.preventDefault();
          buttonRef.current?.focus();
          buttonRef.current?.click();
        }
        if (tooltipVisible && isShortcut(e, 'esc')) {
          hideTooltip();
        }
      },
      [hideTooltip, isFocused, shortcut, tooltipVisible]
    );

    const onVisibleChange = useCallback(
      (v: boolean) => {
        !isDisabled && setTooltipVisible(v);
      },
      [isDisabled]
    );

    const getTooltipContainer = useCallback(() => ref.current || document.body, []);

    useEffect(() => {
      if (!isFocused && tooltipVisible) {
        setTooltipVisible(false);
      }
    }, [isFocused, tooltipVisible]);

    // TODO: think about one listener for all toolbar buttons
    useEffect(() => {
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    });

    return (
      <Tooltip
        align={align}
        overlay={() => (
          <ColorPicker
            active={active}
            palette={palette}
            paletteLength={paletteLength}
            onChange={onChange}
            hideTooltip={hideTooltip}
            showColorfulToggle={showColorfulToggle}
          />
        )}
        overlayClassName="select-color__tooltip"
        visible={tooltipVisible && !isDisabled}
        onVisibleChange={onVisibleChange}
        destroyTooltipOnHide={destroyTooltipOnHide}
        getTooltipContainer={getTooltipContainer}
        placement={placement}
        trigger={trigger}
      >
        <span ref={ref}>
          <ToolbarButton
            className={classNames('active-color-button', {active: tooltipVisible})}
            ref={buttonRef}
            title={intl.formatMessage({id: 'Color.Selection'})}
            disabled={isDisabled}
            shortcut={shortcut}
          >
            <ActiveColor value={active} />
          </ToolbarButton>
        </span>
      </Tooltip>
    );
  },
  ColorsPaint.is
);
