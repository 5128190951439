import {type AxiosRequestAction} from 'services/axios/interface';

import {type Role} from '../interface';
import {REQUEST_STUDENT_TEACHERS} from './actionTypes';

export const requestStudentTeachers = (expand: Role): AxiosRequestAction => {
  return {
    type: REQUEST_STUDENT_TEACHERS,
    payload: {
      request: {
        method: 'get',
        url: '/v1/classroom/student-teacher',
        params: {expand}
      }
    }
  };
};
