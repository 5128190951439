import {useCallback, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {fromBase64} from 'lib0/buffer';
import {unstable_batchedUpdates} from 'react-dom';
import {createPaint, type Paint, type PaintPlugin, type PStage, withPlugins} from '@englex/paint';
import {withYjs, withYjsHistory, withYjsIndexedDb, withYSync} from '@englex/paint-yjs';
import {captureException, withScope} from '@sentry/react';

import {useWampDispatch} from 'hooks/redux/useWampDispatch';
import {useAxiosRequest} from 'hooks/rest/useAxiosRequest';
import {useWampSubscription} from 'hooks/wamp/useWampSubscription';

import {requestYDoc, subscribeYDoc, updateYDoc} from './actions';
import {type YDocEntity} from './interface';

interface Options {
  topic?: string;
  plugins?: PaintPlugin[];
  onError?: (reload?: () => void) => void;
  onLoading?: (loading: boolean) => void;
}

export const useYPainter = (
  docId: string,
  {topic = 'ydoc:doc', plugins, onError, onLoading}: Options = {}
) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const dispatch = useWampDispatch();
  const hwUpdate = useCallback(
    (update: string) => {
      return dispatch(updateYDoc(topic, docId, update)) as Promise<never>;
    },
    [dispatch, docId, topic]
  );
  const {request} = useAxiosRequest(requestYDoc);
  const fetchInitialUpdate = useCallback(
    async (stateVector?: Uint8Array) => {
      const result = await request<YDocEntity>(docId, stateVector);
      return fromBase64(result.update);
    },
    [docId, request]
  );

  const paint = useMemo(() => {
    const p = withPlugins(createPaint(), ...(plugins || []));
    const yPaint = withPlugins(
      p,
      withYjs({docId, fetchInitialUpdate}),
      withYjsIndexedDb,
      withYjsHistory,
      withYSync({update: hwUpdate})
    );
    return yPaint;
  }, [docId, fetchInitialUpdate, hwUpdate, plugins]);
  const paintRef = useRef<Paint | null>(paint);

  const [stage, setStage] = useState<PStage | null>(null);

  const onChange = useCallback(
    s => {
      if (s !== stage) {
        setStage(s);
      }
    },
    [stage]
  );

  const {isSubscribed, isOnline} = useWampSubscription(
    subscribeYDoc,
    topic,
    docId,
    paint.onYSyncEvent
  );
  const isConnected = isSubscribed && isOnline;

  const reload = useCallback(() => {
    unstable_batchedUpdates(() => {
      setIsLoaded(false);
      setIsLoading(false);
      setIsError(false);
      setStage(null);
      onLoading?.(true);
      onError?.();
    });
  }, [onError, onLoading]);

  useLayoutEffect(() => {
    if (isLoaded && paint.yDoc) {
      paint.ySyncEnable(isConnected);
    }
  }, [isConnected, isLoaded, paint, paint.ySync, paint.yDoc]);

  useLayoutEffect(() => {
    if (paintRef.current !== paint) {
      paintRef.current = paint;
      reload();
    }
  }, [docId, paint, reload]);

  useLayoutEffect(() => {
    if (!isLoading && !isLoaded && !isError) {
      unstable_batchedUpdates(() => {
        setIsLoading(true);
        onLoading?.(true);
        onError?.();
      });

      paint
        .yLoad()
        .then(() => {
          unstable_batchedUpdates(() => {
            setStage(paint.stage);
            setIsLoaded(true);
            setIsLoading(false);
            setIsError(false);
            onError?.();
          });
        })
        .catch(e => {
          withScope(scope => {
            scope.setLevel('warning');
            scope.setExtras({
              yPainterDocId: docId
            });
            captureException(e);
          });
          unstable_batchedUpdates(() => {
            setStage(null);
            setIsLoaded(false);
            setIsLoading(false);
            onLoading?.(false);
            setIsError(true);
            onError?.(reload);
          });
        });
    }
  }, [docId, isError, isLoaded, isLoading, onError, onLoading, paint, reload]);
  return {reload, paint, stage, isLoading, isLoaded, isError, onChange};
};
