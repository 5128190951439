import React, {useCallback, useEffect} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {useIntl} from 'react-intl';
import {useFocused, usePaint, withPaint} from '@englex/paint-react';

import Icon from 'components/Icon';
import ImageModal from 'components/Slate/SlateEditor/plugins/button/Image/Modal';
import {isMobileWebView} from 'helpers/browser';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';
import {ImagePaint} from '../../Painter/plugins';
import isShortcut from '../../../../helpers/shortcut';

import 'routes/Library/ImageModal.scss';

const noop = () => {};

interface Props {
  shortcut?: string;
}

export const ImageButton = withPaint(
  ({shortcut = 'mod+opt+0'}: Props) => {
    const intl = useIntl();
    const isFocused = useFocused();
    const paint = usePaint<ImagePaint>();
    const showImageModal = paint.showImageModal;

    const onToggle = useCallback(() => {
      paint.setImageDropInitials(undefined);
      paint.setShowImageModal(!showImageModal);
    }, [paint, showImageModal]);

    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (!isFocused) return;

        if (isShortcut(e, shortcut)) {
          e.preventDefault();
          e.stopPropagation();
          onToggle();
        }
      },
      [isFocused, onToggle, shortcut]
    );

    const insertImage = useCallback(
      (id: number, width: number, height: number, urls: string[]): void => {
        ImagePaint.insertImage(paint, {width, height, url: urls[0]});
      },
      [paint]
    );

    useEffect(() => {
      window.addEventListener('keydown', onKeyDown);
      return () => {
        window.removeEventListener('keydown', onKeyDown);
      };
    });

    return (
      <>
        <ToolbarButton
          title={intl.formatMessage(buttonTitles.insertImage)}
          onClick={onToggle}
          shortcut={shortcut}
        >
          <Icon name="image" />
        </ToolbarButton>
        {showImageModal && (
          <Modal
            backdrop="static"
            className="dnd-image-modal konva-image-input"
            show={showImageModal}
            onHide={onToggle}
            restoreFocus={true}
          >
            <ImageModal
              close={onToggle}
              freeze={noop}
              insertImage={insertImage}
              openedFromInlineToolbar={false}
              minHeight={100}
              minWidth={100}
              maxHeight={300}
              maxWidth={300}
              {...paint.afterDropInitials}
            />
          </Modal>
        )}
      </>
    );
  },
  e => ImagePaint.isImagePaint(e) && !isMobileWebView()
);
