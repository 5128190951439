import {
  type MiddlewareActionHandlers,
  type MiddlewareConfig
} from 'store/middleware/browser-notifications/interface';
import {requestBrowserNotificationPermissionsTimeout} from 'config/static';

import chatMessageReceivedNotification from './config/chat/messageReceived';
import incomingCallNotification from './config/RTC/incomingCall';
import clearIncomingCallNotification from './config/RTC/clearNotifications';
import {MESSAGE_RECEIVED} from '../../components/Chat/actions/actionTypes';
import {
  CALL_ACCEPT,
  CALL_END,
  INCOMING_CALL,
  WAMP_CALL_HANG_UP
} from '../../webRTC/action/actionTypes';
import {REQUEST_NOTIFICATION_PERMISSION, requestPermission} from './requestPermission';
import initializeRequestPermission from './initializeRequestPermission';
import {SET_IS_MOBILE} from '../../layouts/actions/actionTypes';

const notification: MiddlewareActionHandlers = {
  [REQUEST_NOTIFICATION_PERMISSION]: requestPermission,
  [MESSAGE_RECEIVED]: chatMessageReceivedNotification,
  [INCOMING_CALL]: incomingCallNotification,
  [WAMP_CALL_HANG_UP]: clearIncomingCallNotification,
  [CALL_ACCEPT]: clearIncomingCallNotification,
  [CALL_END]: clearIncomingCallNotification,
  [SET_IS_MOBILE]: initializeRequestPermission
};

const config: MiddlewareConfig = {
  requestPermissionsTimeout: requestBrowserNotificationPermissionsTimeout,
  notification
};

export default config;
