import {type FC} from 'react';

import {type Translation} from '../../interfaces';

import './TranslationsOfExample.scss';

interface Props {
  translations: Translation[];
}

export const TranslationsOfExample: FC<Props> = ({translations}) => {
  if (!translations?.length) return null;

  if (translations?.length === 1 && translations[0]?.text)
    return <div className="translation-of-example">{translations[0].text}</div>;

  return (
    <ul className="translations-of-example">
      {translations.map(
        translation =>
          !!translation?.text && (
            <li className="translation-of-example" key={translation.text}>
              {translation.text}
            </li>
          )
      )}
    </ul>
  );
};
