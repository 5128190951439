import {connect} from 'react-redux';
import {type Action} from 'redux';

import {videoBaseHeight, videoBaseWidth} from 'config/static';
import {type AppState} from 'store/interface';

import RTCComponent, {
  type DispatchRTCComponentProps,
  type RTCComponentOwnProps,
  type RTCComponentStateProps
} from '../components/RTCComponent';
import {
  callAccept,
  callCreate,
  changeVideoPosition,
  closeBadBrowser,
  hangUp,
  resizeVideo,
  toggleFullScreenMode,
  toggleTransformingMode,
  toggleVideoUndocked
} from '../action/action';
import {selectRoom} from '../../components/Chat/actions/action';
import {toggleDevicesModal} from '../../routes/ClassRoom/components/MediaSettingsWizard/action';
import {
  type ChangeVideoPositionAction,
  type Dispatch,
  type HangUpAction
} from '../action/interface';
import {type SelectRoomAction} from '../../components/Chat/actions/interface';
import {type ToggleElementAction} from '../../common/interface';
import {LessonSidebarTab} from '../../components/LessonSidebar/interfaces';

type RTCComponentStateToProps = (
  state: AppState,
  ownProps: RTCComponentOwnProps
) => RTCComponentStateProps;
type RTCComponentDispatchToProps = (
  dispatch: Dispatch<Action, Action>
) => DispatchRTCComponentProps;

function getFullScreenWidth() {
  if (document.documentElement.clientHeight / 9 > document.documentElement.clientWidth / 16) {
    return '100%';
  }
  return document.documentElement.clientHeight * (16 / 9);
}

function getFullScreenHeight() {
  if (document.documentElement.clientHeight / 9 < document.documentElement.clientWidth / 16) {
    return '100%';
  }
  return document.documentElement.clientWidth * (9 / 16);
}

const mapStateToProps: RTCComponentStateToProps = (state, ownProps) => {
  const undocked = state.video.undocked && !state.video.fullScreen;
  const hasPartnerStatus =
    state.chat!.rooms &&
    state.rtc.call &&
    state.chat!.rooms[state.rtc.call.room_id] &&
    state.chat!.rooms[state.rtc.call.room_id].recipient;
  return {
    partnerRoom:
      state.chat!.rooms && state.rtc.call ? state.chat!.rooms[state.rtc.call.room_id] : undefined,
    incomingCall: state.rtc.incomingCall,
    outgoingCall: state.rtc.outgoingCall,
    callInProgress: state.rtc.callInProgress,
    isBadBrowser: state.rtc.badBrowser,
    browserInfo: state.rtc.browserInfo,
    callStarting: state.rtc.callStarting,
    localVideoEnabled: state.rtc.localStream ? Boolean(state.rtc.localStream.video) : false,
    localAudioEnabled: state.rtc.localStream ? Boolean(state.rtc.localStream.audio) : false,
    callId: state.rtc.call ? state.rtc.call.id : undefined,
    answeringAwait: state.rtc.answeringAwait,
    chatSelectedRoom: state.chat!.selectedRoomId!,
    sessionId: state.wamp.sessionId!,
    otherSessionCall: state.rtc.otherSessionCall,
    camId:
      state.mediaDevices.selectedCamPresent && state.mediaDevices.cameraAccess
        ? state.mediaDevices.cam
        : undefined,
    micId:
      state.mediaDevices.selectedMicPresent && state.mediaDevices.micAccess
        ? state.mediaDevices.mic
        : undefined,
    partnerStatus: hasPartnerStatus
      ? state.chat!.rooms![state.rtc.call!.room_id].recipient!.userStatus
      : undefined,
    videoFullScreen: state.video.fullScreen,
    isUndocked: state.video.undocked,
    positionY: undocked ? state.video.positionY : 0,
    positionX: undocked ? state.video.positionX : 0,
    width:
      (state.video.fullScreen && getFullScreenWidth()) ||
      (state.video.undocked && state.video.width) ||
      videoBaseWidth,
    height:
      ((!state.rtc.call || state.rtc.otherSessionCall || !hasPartnerStatus) &&
        (!state.rtc.badBrowser ||
          state.rtc.shouldHideBadBrowser ||
          ownProps.selectedTab === LessonSidebarTab.DICTIONARY) &&
        '0') ||
      (state.video.fullScreen && getFullScreenHeight()) ||
      (state.video.undocked && state.video.height) ||
      videoBaseHeight,
    transformingMode: state.video.transformingMode,
    workspaceExpanded: state.layout.collapsed,
    shouldHideBadBrowser: state.rtc.shouldHideBadBrowser
  };
};

const mapDispatchToProps: RTCComponentDispatchToProps = dispatch => ({
  callStarted: (roomId: number, camId: string | null, micId: string | null) => {
    return dispatch(callCreate(roomId, camId, micId));
  },
  hangUp: (roomId: number, callId: string, details: {reason: string}) => {
    return dispatch<HangUpAction>(hangUp(roomId, callId, details));
  },
  answerCall: (roomId: number, callId: string, camId: string | null, micId: string | null) => {
    return dispatch(callAccept(roomId, callId, camId, micId));
  },
  switchChatRoom: (roomId: number) => {
    return dispatch<SelectRoomAction>(selectRoom(roomId));
  },
  toggleDevicesModal: (show: boolean) => dispatch<ToggleElementAction>(toggleDevicesModal(show)),
  toggleFullScreen: (show: boolean) => {
    dispatch(toggleFullScreenMode(show));
    // clear document horizontal scroll position
    if (show && document.documentElement.scrollLeft) {
      document.documentElement.scrollLeft = 0;
    }
  },
  toggleUndocked: (show: boolean) => dispatch(toggleVideoUndocked(show)),
  changeVideoPosition: (x: number, y: number) =>
    dispatch<ChangeVideoPositionAction>(changeVideoPosition(x, y)),
  resizeVideo: (x: number, y: number) => dispatch<ChangeVideoPositionAction>(resizeVideo(x, y)),
  toggleTransformingMode: (show: boolean) =>
    dispatch<ToggleElementAction>(toggleTransformingMode(show)),
  closeBadBrowser: () => dispatch(closeBadBrowser())
});

export default connect<RTCComponentStateProps, DispatchRTCComponentProps>(
  mapStateToProps,
  mapDispatchToProps
)(RTCComponent);
