import {Value} from '@englex/slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {valueFromText, valueJSONFromText} from '../../../../../components/Slate/utils';
import WidgetRecord from '../../WidgetRecord';
import {WidgetType} from '../../interface';
import {type FormattedTextProperties, type WidgetFormattedTextJSON} from './interface';

class FormattedTextRecord<A = undefined, V = undefined, C = undefined>
  extends WidgetRecord<A, V, C>
  implements FormattedTextProperties
{
  public declare readonly content: Value;

  constructor(raw: WidgetFormattedTextJSON & {type?: WidgetType}) {
    super({
      id: raw.id,
      type: raw.type,
      task: raw.task,
      version: raw.version,
      displayButton: raw.displayButton
    });
    this.initValues({
      content: Value.fromJSON(raw.content ? raw.content : valueJSONFromText(''))
    });
  }

  public toJSON(): WidgetFormattedTextJSON {
    return {
      ...super.toJSON(),
      content: this.content.toJSON()
    };
  }

  public get type() {
    return WidgetType.FORMATTED_TEXT;
  }

  public get isAvailableSelection() {
    return true;
  }
}
decorate(FormattedTextRecord, {
  content: property(valueFromText())
});
record()(FormattedTextRecord);
export default FormattedTextRecord;
