import {xWidgetPrefix} from '../../actionTypes';

export const XVOCABULARY_SET_ORIGINAL = xWidgetPrefix + 'XVOCABULARY_SET_ORIGINAL';
export const XVOCABULARY_SET_TRANSLATION = xWidgetPrefix + 'XVOCABULARY_SET_TRANSLATION';
export const XVOCABULARY_SET_CATEGORY = xWidgetPrefix + 'XVOCABULARY_SET_CATEGORY';
export const XVOCABULARY_ADD_ROWS = xWidgetPrefix + 'XVOCABULARY_ADD_ROWS';
export const XVOCABULARY_ADD_WORD = xWidgetPrefix + 'XVOCABULARY_ADD_WORD';
export const XVOCABULARY_ADD_CATEGORY = xWidgetPrefix + 'XVOCABULARY_ADD_CATEGORY';
export const XVOCABULARY_REMOVE_ENTRY = xWidgetPrefix + 'XVOCABULARY_REMOVE_ENTRY';
export const XVOCABULARY_MOVE_ENTRY = xWidgetPrefix + 'XVOCABULARY_MOVE_ENTRY';
export const XVOCABULARY_OPEN_PRONUNCIATION_MODAL =
  xWidgetPrefix + 'XVOCABULARY_OPEN_PRONUNCIATION_MODAL';
export const XVOCABULARY_CLOSE_PRONUNCIATION_MODAL =
  xWidgetPrefix + 'XVOCABULARY_CLOSE_PRONUNCIATION_MODAL';
export const XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED =
  xWidgetPrefix + 'XVOCABULARY_MODAL_PRONUNCIATIONS_LOADED';
export const XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION =
  xWidgetPrefix + 'XVOCABULARY_MODAL_SELECT_PRONUNCIATION_OPTION';
export const XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING =
  xWidgetPrefix + 'XVOCABULARY_MODAL_CHANGE_PHONETIC_SPELLING';
export const XVOCABULARY_RESET_PRONUNCIATION = xWidgetPrefix + 'XVOCABULARY_RESET_PRONUNCIATION';
export const XVOCABULARY_STRIP_SPACES = xWidgetPrefix + 'XVOCABULARY_STRIP_SPACES';
export const XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD =
  xWidgetPrefix + 'XVOCABULARY_SET_PRONUNCIATIONS_LOADING_FOR_WORD';
export const XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD =
  xWidgetPrefix + 'XVOCABULARY_CHANGE_PRONUNCIATIONS_FOR_WORD';
export const XVOCABULARY_CHANGE_QUIZLET_URL = xWidgetPrefix + 'XVOCABULARY_CHANGE_QUIZLET_URL';
export const XVOCABULARY_CHANGE_LIST_TITLE = xWidgetPrefix + 'XVOCABULARY_CHANGE_LIST_TITLE';
