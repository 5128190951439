import React, {type FC} from 'react';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from '../Icon';
import './ContentsHeader.scss';

interface Props {
  onClose: () => void;
  className?: string;
}

export const ContentsHeader: FC<Props> = ({onClose, className, children}) => {
  const intl = useIntl();
  return (
    <div className={classNames('toc-header', className)}>
      <span className="toc-header__title">{intl.formatMessage({id: 'UnitContents'})}</span>
      {children}
      <button onClick={onClose} title={intl.formatMessage({id: 'UnitContents.CloseContents'})}>
        <Icon name="pc-close" />
      </button>
    </div>
  );
};
