import React from 'react';
import classNames from 'classnames';

import './FontSize.scss';

interface Props {
  value: number | null;
  active?: boolean;
  onSelect: (scale: number | null) => void;
}

export const FontSize: React.FC<Props> = ({value, active, onSelect}) => {
  const handleClick = () => onSelect(value);

  return (
    <div className={classNames('font-size-item', {active})} onClick={handleClick}>
      <span className="value">{value}</span>
    </div>
  );
};
