import React from 'react';
import {connect} from 'react-redux';
import {type CompleteCrop} from 'react-image-crop';
import {type AxiosAction} from 'redux-axios-middleware';
import {type Dispatch as ReduxDispatch} from 'redux';

import {
  type AxiosRequestError,
  type AxiosResponseAction,
  type UploadProgressHandler
} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';
import {type ValidatedImageFile} from 'common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from 'common/ImageUpload/interface';
import PictureUploadingStatus from 'common/ImageUpload/PictureUploadingStatus';
import {getImageSize} from 'helpers/file';

import {
  createCrop,
  imageExistRequest,
  postCroppedImage,
  postImage
} from '../../../../../../Chat/components/Body/UploadingImages/action';
import Spinner from '../../../../../../Spinner';

import './ImageUploader.scss';

const {CLONING, FINISHED, PREPARING, UPLOADING, UPLOADING_ERROR} = UploadingPictureStatus;

interface OwnProps {
  file: ValidatedImageFile;
  freeze: (shouldFreeze: boolean) => void;
  handleResponse: (response: AxiosResponseAction<EnglexImage>) => void;
  hideModal: () => void;
  isErrorStatus: boolean;
  pixelCrop: CompleteCrop;
  setStatus: (status: UploadingPictureStatus, message?: string) => void;
  status?: UploadingPictureStatus | UploadingPictureValidationError;
  serverValidationMessage?: string;
}

interface DispatchedProps {
  imageExistRequest: () => Promise<AxiosResponseAction<EnglexImage>>;
  uploadFile: (
    isOriginal: boolean,
    data: FormData,
    width: number,
    height: number,
    x: number,
    y: number,
    onUploadProgress: UploadProgressHandler
  ) => Promise<AxiosResponseAction<EnglexImage>>;
  createCrop: (
    id: number,
    width: number,
    height: number,
    x: number,
    y: number
  ) => Promise<AxiosResponseAction<EnglexImage>>;
}

interface State {
  percentsUploaded: number;
  message?: string;
}

interface Props extends OwnProps, DispatchedProps {}

class ImageUploader extends React.Component<Props, State> {
  public state: State = {percentsUploaded: 0, message: undefined};

  public componentDidMount() {
    const {status} = this.props;
    if (!(status && status in UploadingPictureValidationError)) {
      this.checkFileExistence();
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (!prevProps.isErrorStatus && this.props.isErrorStatus) {
      this.props.freeze(false);
    }
  }

  public render() {
    const {isErrorStatus, status} = this.props;
    return (
      <div className="englex-image-crop-uploader">
        <PictureUploadingStatus
          isError={isErrorStatus}
          status={status!}
          serverValidationMessage={this.state.message}
        >
          {this.renderPercentageOrSpinner()}
        </PictureUploadingStatus>
        {this.renderScale()}
      </div>
    );
  }

  private renderScale = () => {
    const {status} = this.props;
    return status === UPLOADING || status === UPLOADING_ERROR ? (
      <div className={`uploading-scale ${status === UPLOADING_ERROR ? 'error' : ''}`}>
        <div style={{width: `${this.state.percentsUploaded}%`}} />
      </div>
    ) : null;
  };

  private renderPercentageOrSpinner = () => {
    const [{status}, {percentsUploaded}] = [this.props, this.state];
    if (status && status in UploadingPictureValidationError) {
      return null;
    }
    const shouldRenderSpinner =
      status === PREPARING || status === CLONING || percentsUploaded === 100;
    return shouldRenderSpinner ? (
      <Spinner size={15} />
    ) : (
      <span className="percentage">{percentsUploaded}%</span>
    );
  };

  private checkFileExistence = () =>
    this.props.imageExistRequest().then(this.cloneFile).catch(this.handleImageExistRequestFail);

  private handleImageExistRequestFail = ({error}: AxiosRequestError) => {
    if (error && error.response && error.response.status === 404) {
      this.uploadFile();
    } else {
      this.props.setStatus(UPLOADING_ERROR);
    }
  };

  private cloneFile = async (response: AxiosResponseAction<EnglexImage>) => {
    const {handleResponse, hideModal, pixelCrop, setStatus} = this.props;
    const {width, height, x, y} = pixelCrop;
    try {
      setStatus(CLONING);
      if (response.payload.data.urls) {
        const result = await this.props.createCrop(
          response.payload.data.id,
          Math.round(width),
          Math.round(height),
          x,
          y
        );
        handleResponse(result);
      }
      this.setStatusAndUnfreeze(FINISHED);
      hideModal();
    } catch (e) {
      this.resolveServerErrorResponse(e);
      this.setStatusAndUnfreeze(UPLOADING_ERROR);
    }
  };

  private uploadFile = async () => {
    const {file, handleResponse, hideModal, pixelCrop, setStatus, uploadFile} = this.props;
    const {width, height, x, y} = pixelCrop;

    try {
      setStatus(UPLOADING);

      const imageSize = await getImageSize(file.file);
      const isOriginal = imageSize.width === width && imageSize.height === height;

      const formData = new FormData();
      formData.append('file', file.file);

      const result = await uploadFile(
        isOriginal,
        formData,
        Math.round(width),
        Math.round(height),
        x,
        y,
        this.progressHandler
      );
      handleResponse(result);
      this.setStatusAndUnfreeze(FINISHED);
      hideModal();
    } catch (e) {
      this.resolveServerErrorResponse(e);
      this.setStatusAndUnfreeze(UPLOADING_ERROR);
    }
  };

  private resolveServerErrorResponse = ({error}: AxiosRequestError) => {
    if (error.response && [422, 400].includes(error.response.status)) {
      if (Array.isArray(error.response.data)) {
        this.setState({message: error.response.data[0].message});
      } else if (error.response.data.message) {
        this.setState({message: error.response.data.message});
      }
    }
  };

  private setStatusAndUnfreeze = (status: UploadingPictureStatus) => {
    this.props.freeze(false);
    this.props.setStatus(status);
  };

  private progressHandler = (e: ProgressEvent) =>
    this.setState({percentsUploaded: e.total ? Math.round((e.loaded * 100) / e.total) : 0});
}

interface Dispatch<R> extends ReduxDispatch {
  (action: AxiosAction): Promise<AxiosResponseAction<R>>;
}

const mapDispatchToProps = (dispatch: Dispatch<EnglexImage>, ownProps: OwnProps) => ({
  uploadFile: (
    isOriginal: boolean,
    data: FormData,
    width: number,
    height: number,
    x: number,
    y: number,
    onUploadProgress: UploadProgressHandler
  ) =>
    isOriginal
      ? dispatch(postImage(data, onUploadProgress))
      : dispatch(postCroppedImage(data, width, height, x, y, onUploadProgress)),
  createCrop: (id: number, width: number, height: number, x: number, y: number) =>
    dispatch(createCrop(id, width, height, x, y)),
  imageExistRequest: () => dispatch(imageExistRequest(ownProps.file.md5))
});

export default connect(null, mapDispatchToProps)(ImageUploader);
