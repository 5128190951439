import React, {type FC, useCallback, useContext, useMemo} from 'react';
import {type IntlShape, useIntl} from 'react-intl';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import DropDown from 'components/DropDown';

import {EditEntryActionsContext} from '../../contexts/entry/EditEntryContext';
import {
  addEntriesToList,
  setDictionaryEntryToDelete,
  setEntryToEdit,
  setListEntryToDelete
} from '../../contexts/entry/actions';
import {type DictionaryEntryInstance, type DictionaryList} from '../../interface';
import {EditListActionsContext} from '../../contexts/list/EditListContext';
import {showModal} from '../../contexts/actions';
import './WordEntryControls.scss';

interface Props {
  list?: DictionaryList;
  wordEntry: DictionaryEntryInstance;
  hasLists: boolean;
  showForm: boolean;
}

type Key = 'edit' | 'add_to_new_list' | 'add_to_existing_list' | 'delete' | 'destroy';

export const WordEntryBasicControls: FC<Props> = ({list, wordEntry, hasLists, showForm}) => {
  const intl = useIntl();
  const userId = useSelector<AppState, number>(s => s.user.id!);
  const isStudent = useSelector<AppState, boolean>(s => s.user.role === 'student');

  const items = useMemo(
    () =>
      getItems(
        intl,
        userId,
        wordEntry.createdById,
        isStudent,
        !!wordEntry.exerciseInstanceId,
        hasLists,
        list,
        showForm
      ),
    [
      intl,
      isStudent,
      userId,
      wordEntry.exerciseInstanceId,
      wordEntry.createdById,
      hasLists,
      list,
      showForm
    ]
  );
  const {dispatch} = useContext(EditEntryActionsContext);
  const editListDispatch = useContext(EditListActionsContext);

  const onChange = useCallback(
    (key: Key) => {
      switch (key) {
        case 'edit':
          return dispatch(setEntryToEdit(wordEntry));
        case 'add_to_new_list':
          dispatch(addEntriesToList([wordEntry.id]));
          editListDispatch(showModal());
          return;
        case 'add_to_existing_list':
          return dispatch(addEntriesToList([wordEntry.id], true));
        case 'delete':
          if (list) {
            return dispatch(setListEntryToDelete(list.id, wordEntry.id));
          } else {
            return dispatch(setDictionaryEntryToDelete(wordEntry.id));
          }
        case 'destroy':
          return dispatch(setDictionaryEntryToDelete(wordEntry.id, true));
        default:
          return;
      }
    },
    [dispatch, editListDispatch, list, wordEntry]
  );

  return (
    <div className="word-entry-controls">
      <DropDown
        items={items}
        onChange={onChange}
        value={<Icon name="virc-actions" />}
        noCaret={true}
        trigger={['click']}
        placement="bottomRight"
      />
    </div>
  );
};

const getItems = (
  intl: IntlShape,
  userId: number,
  createdById: number,
  isStudent: boolean,
  fromCoursebook: boolean,
  hasLists: boolean,
  list?: DictionaryList,
  showForm?: boolean
) => {
  const hideDeleteItems =
    !isStudent && list && list.createdById !== userId && !list.exerciseInstanceId;
  const deleteIsDisabled = isStudent ? false : createdById !== userId && !fromCoursebook;
  return {
    ...(showForm
      ? {}
      : {
          edit: {
            value: intl.formatMessage({id: 'Common.Edit'}),
            disabled: fromCoursebook ? true : isStudent ? false : userId !== createdById
          }
        }),
    add_to_new_list: intl.formatMessage({id: 'Dictionary.AddToNewList'}),
    add_to_existing_list: {
      value: intl.formatMessage({id: 'Dictionary.AddToExistingList'}),
      disabled: !hasLists
    },
    ...(hideDeleteItems
      ? {}
      : {
          delete: {
            value: list
              ? intl.formatMessage({id: 'Dictionary.DeleteFromList'})
              : intl.formatMessage({id: 'Common.Delete'}),
            disabled: deleteIsDisabled
          }
        }),
    ...(!list || hideDeleteItems
      ? {}
      : {
          destroy: {
            value: intl.formatMessage({id: 'Dictionary.DeleteFromDictionaryAndList'}),
            disabled: deleteIsDisabled
          }
        })
  };
};
