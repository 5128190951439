import {FAIL_POSTFIX, SUCCESS_POSTFIX} from 'services/wamp/actions/types';

const PATH: string = 'RTC/';

export const INCOMING_CALL: string = PATH + 'INCOMING_CALL';
export const OUTGOING_CALL: string = PATH + 'OUTGOING_CALL';
export const OUTGOING_CALL_INTERRUPTED: string = PATH + 'OUTGOING_CALL_INTERRUPTED';

export const OTHER_SESSION_CALL: string = PATH + 'OTHER_SESSION_CALL';

export const CALL_STARTING: string = PATH + 'CALL_STARTING';
export const CALL_CREATE: string = PATH + 'CALL_CREATE';
export const CALL_ACCEPT: string = PATH + 'CALL_ACCEPT';
export const CALL_ANSWERED: string = PATH + 'CALL_ANSWERED';
export const CALL_MUTE: string = PATH + 'CALL_MUTE';
export const CALL_CHANGE_STATUS: string = PATH + 'CALL_CHANGE_STATUS';
export const CALL_CHANGE_MEDIA_DEVICE: string = PATH + 'CALL_CHANGE_MEDIA_DEVICE';
export const CALL_CHANGE_DURATION: string = PATH + 'CALL_CHANGE_DURATION';
export const CALL_END: string = PATH + 'CALL_END'; // clears store RTC object

export const CALL_OFFERED_EVENT: string = PATH + 'CALL_OFFERED_EVENT';
export const CALL_ANSWERED_EVENT: string = PATH + 'CALL_ANSWERED_EVENT';
export const CALL_ACCEPTED_EVENT: string = PATH + 'CALL_ACCEPTED_EVENT';
export const CALL_UPGRADED_EVENT: string = PATH + 'CALL_UPGRADED_EVENT';
export const CALL_SIGNALING_STABLE_EVENT: string = PATH + 'CALL_SIGNALING_STABLE_EVENT';
export const ICE_CANDIDATE_EVENT: string = PATH + 'ICE_CANDIDATE_EVENT';

export const WAMP_CALL_CREATE: string = PATH + 'WAMP_CALL_CREATE';
export const WAMP_CALL_CREATE_SUCCESS: string = WAMP_CALL_CREATE + SUCCESS_POSTFIX;
export const WAMP_CALL_ACCEPT: string = PATH + 'WAMP_CALL_ACCEPT';
export const WAMP_CALL_OFFERED: string = PATH + 'WAMP_CALL_OFFERED';
export const WAMP_CALL_ANSWERED: string = PATH + 'WAMP_CALL_ANSWERED';
export const WAMP_CALL_ANSWERED_SUCCESS: string = WAMP_CALL_ANSWERED + SUCCESS_POSTFIX;
export const WAMP_CALL_HANG_UP: string = PATH + 'WAMP_CALL_HANG_UP';
export const WAMP_UPGRADE_CALL: string = PATH + 'WAMP_UPGRADE_CALL';
export const WAMP_UPGRADE_CALL_FAIL: string = WAMP_UPGRADE_CALL + FAIL_POSTFIX;
export const WAMP_RTC_BROWSER_CHECK: string = PATH + 'WAMP_RTC_BROWSER_CHECK';
export const WAMP_BROWSER_CHECK_SUCCESS: string = WAMP_RTC_BROWSER_CHECK + SUCCESS_POSTFIX;
export const WAMP_BROWSER_CHECK_FAIL: string = WAMP_RTC_BROWSER_CHECK + FAIL_POSTFIX;
export const WAMP_GET_RTC_SETTINGS: string = PATH + 'WAMP_GET_RTC_SETTINGS';
export const WAMP_GET_RTC_SETTINGS_SUCCESS: string = WAMP_GET_RTC_SETTINGS + SUCCESS_POSTFIX;
export const WAMP_GET_RTC_SETTINGS_FAIL: string = WAMP_GET_RTC_SETTINGS + FAIL_POSTFIX;
export const WAMP_MUTE_CALL: string = PATH + 'WAMP_MUTE_CALL';
export const WAMP_MUTE_CALL_SUCCESS: string = WAMP_MUTE_CALL + SUCCESS_POSTFIX;
export const WAMP_SEND_STATE: string = PATH + 'WAMP_SEND_STATE';
export const WAMP_SEND_ICE_CANDIDATE: string = PATH + 'WAMP_SEND_ICE_CANDIDATE';
export const WAMP_SEND_ICE_CANDIDATE_FAIL: string = WAMP_SEND_ICE_CANDIDATE + FAIL_POSTFIX;

export const GET_LOCAL_STREAM: string = PATH + 'GET_LOCAL_STREAM';
export const GET_REMOTE_STREAM: string = PATH + 'GET_REMOTE_STREAM';

export const TOGGLE_RECALL: string = PATH + 'TOGGLE_RECALL';
export const TOGGLE_FULLSCREEN_MODE: string = PATH + 'TOGGLE_FULLSCREEN_MODE';
export const TOGGLE_UNDOCKED_MODE: string = PATH + 'TOGGLE_UNDOCKED_MODE';
export const TOGGLE_REMOTE_VIDEO_DEVICE: string = PATH + 'TOGGLE_REMOTE_VIDEO_DEVICE';
export const TOGGLE_TRANSFORMING_MODE: string = PATH + 'TOGGLE_TRANSFORMING_MODE';

export const CHANGE_VIDEO_POSITION: string = PATH + 'CHANGE_VIDEO_POSITION';
export const CHANGE_SMALL_VIDEO_RATIO: string = PATH + 'CHANGE_SMALL_VIDEO_RATIO';
export const CHANGE_INCOMING_CALL_NOTIFICATION: string = PATH + 'CHANGE_INCOMING_CALL_NOTIFICATION';

export const RESIZE_VIDEO: string = PATH + 'RESIZE_VIDEO';
export const TOGGLE_SPEEDTEST_NECESSITY: string = PATH + 'TOGGLE_SPEEDTEST_NECESSITY';
export const SET_LOCAL_CONNECTION_QUALITY: string = PATH + 'SET_LOCAL_CONNECTION_QUALITY';
export const SET_REMOTE_CONNECTION_QUALITY: string = PATH + 'SET_REMOTE_CONNECTION_QUALITY';
export const START_SPEEDTEST: string = PATH + 'START_SPEEDTEST';
export const SHOW_LOW_SPEED_TOAST: string = PATH + 'SHOW_LOW_SPEED_TOAST';

export const PARTNER_DISCONNECTED: string = PATH + 'PARTNER_DISCONNECTED';
export const PARTNER_SESSION_ID_UPGRADED = PATH + 'PARTNER_SESSION_ID_UPGRADED';

export const CLOSE_BAD_BROWSER = PATH + 'CLOSE_BAD_BROWSER';
