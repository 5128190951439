import {type Map} from 'immutable';

import {
  type FormattedTextProperties,
  type WidgetFormattedTextJSON
} from '../FormattedText/interface';

export enum CommentType {
  TEACHING_NOTES = 'TeachingNotes',
  SUGGESTED_ANSWERS = 'SuggestedAnswers',
  TEACHING_TIPS = 'TeachingTips'
}

export interface CommentJSON extends WidgetFormattedTextJSON {
  // isSuggestedAnswer is deprecated
  isSuggestedAnswer?: boolean;
  commentType?: CommentType;
  values?: {showCollapsible: boolean};
  isCollapsed?: boolean;
}

export interface CommentProperties extends FormattedTextProperties {
  readonly commentType: CommentType;
  readonly commentLabel?: string;
  readonly isCollapsed?: boolean;
  values?: Map<'showCollapsible', boolean>;
}
