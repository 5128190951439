import {Editor, Value} from '@englex/slate';
import {Set} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {isDocumentNotEmpty} from 'components/Slate/utils/documentNotEmpty';
import {insertIcon} from 'components/Slate/SlateEditor/plugins/button/Icon/changes';

import {WidgetType} from '../../interface';
import {type NoteDataByType, type NoteJSON, type NoteProperties, NoteType} from './interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';
import {noteDataByType} from '../../../../../components/XPlayer/widgets/Note/noteDataByType';
import {valueFromText} from '../../../../../components/Slate/utils';

class NoteRecord extends FormattedTextRecord implements NoteProperties {
  public declare readonly noteType: NoteType;
  public declare readonly noteLabel?: string;
  public declare readonly isCollapsed?: boolean;

  constructor(raw: NoteJSON) {
    super(raw);
    const task = raw.task ? Value.fromJSON(raw.task) : valueFromText();
    const noteData = noteDataByType.find(d => d.type === raw.noteType)!;
    const prefix =
      raw.noteType !== NoteType.HOW_TO_STRATEGIES
        ? ` ${noteData.title}${isDocumentNotEmpty(task.document) ? ': ' : ''}`
        : ' ';
    this.initValues({
      content: Value.fromJSON(raw.content),
      noteType: raw.noteType,
      task: this.getTask(task, prefix, noteData),
      isCollapsed: raw.isCollapsed
    });
  }

  private getTask(task: Value, prefix: string, noteData: NoteDataByType) {
    if (this.task.selection) {
      return new Editor({value: task})
        .select(this.task.selection.setMarks(Set()))
        .insertText(prefix)
        .moveToStartOfDocument()
        .command(insertIcon, noteData.iconName, 'not-bold').value;
    }

    return new Editor({value: task})
      .insertText(prefix)
      .moveToStartOfDocument()
      .command(insertIcon, noteData.iconName, 'not-bold').value;
  }

  public setLabel(noteLabel: string) {
    return this.set('noteLabel', noteLabel);
  }

  public toJSON(): NoteJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      content: this.content.toJSON(),
      noteType: this.noteType,
      displayButton: this.displayButton?.toJSON(),
      isCollapsed: this.isCollapsed
    };
  }
  public get type() {
    return WidgetType.NOTE;
  }
  public get isCollapsible(): boolean {
    return this.noteType === NoteType.HOW_TO_STRATEGIES;
  }
}
decorate(NoteRecord, {
  noteType: property(NoteType.GRAMMAR_FOCUS),
  noteLabel: property(),
  isCollapsed: property()
});
record()(NoteRecord);
export default NoteRecord;
