import {Value} from '@englex/slate';

import {type GrammarRulesJSON} from 'store/exercise/player/widgets/GrammarRules/interface';
import {WidgetType} from 'store/exercise/player/interface';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';

import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import {valueFromText} from '../../../../../components/Slate/utils';
import {documentNotEmpty} from '../validation';

class XGrammarRulesRecord extends XFormattedTextRecord {
  public declare readonly grammarTitle: Value;

  constructor(raw: GrammarRulesJSON & {type?: WidgetType}) {
    super(raw);
    this.initValues({
      grammarTitle: raw.grammarTitle ? Value.fromJSON(raw.grammarTitle) : valueFromText()
    });
  }

  public get type() {
    return WidgetType.GRAMMAR_RULES;
  }

  public toJSON(): GrammarRulesJSON {
    return {
      ...super.toJSON(),
      grammarTitle: documentNotEmpty(this.grammarTitle.document)
        ? this.grammarTitle.toJSON()
        : undefined
    };
  }
}
decorate(XGrammarRulesRecord, {
  grammarTitle: property()
});
record()(XGrammarRulesRecord);
export default XGrammarRulesRecord;
