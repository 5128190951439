import {useCallback, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';
import {requestStudentLists} from 'store/dictionary/requests';
import {useApiRequest} from 'hooks/rest/useApiRequest';

import {type DictionaryList} from '../../interface';
import {useDictionaryContext} from '../../contexts';

export const useListsInitializer = () => {
  const [lists, setLists] = useState<DictionaryList[]>([]);
  const userId = useSelector<AppState, number>(s => s.user.id!);
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const requestAction = useMemo(
    () => requestStudentLists(dictionaryOwnerId, dictionaryOwnerRole),
    [dictionaryOwnerId, dictionaryOwnerRole]
  );
  const successHandler = useCallback(
    (dictionaryLists: DictionaryList[]) => {
      setLists(
        dictionaryLists.filter(dl => {
          if (userId === dictionaryOwnerId) return true;
          return !!dl.exerciseInstanceId || userId === dl.createdById;
        })
      );
    },
    [dictionaryOwnerId, userId]
  );
  const responseObj = useApiRequest(requestAction, successHandler);

  return {lists, ...responseObj};
};
