import {type Action, type MiddlewareAPI} from 'redux';

import {type AppState} from 'store/interface';

import {type MessageReceivedAction} from '../../../../components/Chat/actions/interface';
import {changeIncomingCallNotification} from '../../../../webRTC/action/action';
import {type Dispatch, type WampAction} from '../../../wamp/actions/interface';

type API = MiddlewareAPI<Dispatch<Action, WampAction>, AppState>;

const clearIncomingCallNotification = (
  api: API,
  action: MessageReceivedAction,
  notificationsList: {[key: string]: Notification}
) => {
  const id = api.getState().rtc.callNotificationId;
  if (id) {
    notificationsList[id].close();
    api.dispatch(changeIncomingCallNotification());
  }
  return undefined;
};

export default clearIncomingCallNotification;
