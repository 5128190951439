import {type Block, type Document, type Editor, type Node} from '@englex/slate';

import {
  isBlockOfType,
  isListBlock,
  isListBlockOfType,
  isListItemBlock,
  isListStyleType,
  isListType
} from '../../../../utils';
import {
  type ListBlock,
  type ListItemBlock,
  type ListStyleType,
  type ListType,
  SlateBlock
} from '../../../../interface';
import {getClosestListAncestor} from './Indentation/utils';

export const getFollowingListOfType = (document: Document, b: Block): ListBlock | undefined => {
  const mayBeList = document.getNextSibling(b.key);
  if (!mayBeList || !isBlockOfType(mayBeList as ListBlock, SlateBlock.LIST)) {
    return;
  }
  return mayBeList as ListBlock;
};

export const getFurthestListBlockOfBlock = (
  document: Document,
  b: Block
): ListBlock | undefined => {
  const path = document.getPath(b.key);
  const mayBeList = path && document.getFurthest(path, n => isListBlock(n));
  if (!mayBeList || !isListBlock(mayBeList)) {
    return;
  }
  return mayBeList;
};

export const getListItemBlockOfBlock = (
  document: Document,
  b: Block
): ListItemBlock | undefined => {
  const li = document.getParent(b.key);
  if (!li || !isListItemBlock(li)) {
    return;
  }
  return li;
};

export const getPreviousSiblingBlockInListItem = (
  document: Document,
  b: Block
): Block | undefined => {
  if (!getListItemBlockOfBlock(document, b)) {
    return;
  }
  return document.getPreviousSibling(b.key) as Block;
};

const getClosestListBlockOfBlock = (document: Document, b: Block): ListBlock | undefined => {
  const li = getListItemBlockOfBlock(document, b);
  if (!li) {
    return;
  }
  return document.getParent(li.key) as ListBlock | undefined;
};

export const getPreviousLi = (
  document: Document,
  block: ListItemBlock
): ListItemBlock | undefined => {
  const li = document.getPreviousSibling(block.key);
  if (!li || !isListItemBlock(li)) {
    return;
  }
  return li;
};

export const getNextLi = (document: Document, block: ListItemBlock): ListItemBlock | undefined => {
  const li = document.getNextSibling(block.key);
  if (!li || !isListItemBlock(li)) {
    return;
  }
  return li;
};

const getSubsequentListOfType = (
  document: Document,
  list: ListBlock,
  type: ListType,
  listStyleType?: ListStyleType
): ListBlock => {
  const parentLi = getListItemBlockOfBlock(document, list);
  const parentList = parentLi && (document.getParent(parentLi.key) as ListBlock | undefined);

  return parentList &&
    isListBlock(parentList) &&
    isListType(parentList, type) &&
    isListStyleType(parentList, listStyleType)
    ? getSubsequentListOfType(document, parentList, type, listStyleType)
    : list;
};

export const getFurthestSubsequentListOfType = (
  document: Document,
  list: ListBlock,
  type: ListType,
  listStyleType?: ListStyleType
): ListBlock | undefined => {
  const furthestList = getSubsequentListOfType(document, list, type, listStyleType);
  return !furthestList.equals(list) ? furthestList : undefined;
};

export const getPrevSiblings = (document: Document, block: Node) => {
  const parent = document.getParent(block.key) as Block;
  return parent.nodes.takeUntil(node => !!node && node.key === block.key);
};

const listStyleTypeCheck = (mayBeList: ListBlock, doCheck?: true, listStyleType?: ListStyleType) =>
  doCheck ? isListStyleType(mayBeList, listStyleType) : true;

export const listIsActive = (
  editor: Editor,
  options: {list: ListType; listStyleType?: ListStyleType; checkListStyleType?: true}
) => {
  const {document, selection, startBlock} = editor.value;
  if (selection?.isCollapsed && startBlock) {
    const mayBeList = startBlock && getClosestListBlockOfBlock(document, startBlock);
    if (
      mayBeList &&
      isListBlockOfType(mayBeList, options.list) &&
      listStyleTypeCheck(mayBeList, options.checkListStyleType, options.listStyleType)
    ) {
      // button should be active if caret is in first block of li
      const previousBlockInLi = getPreviousSiblingBlockInListItem(document, startBlock);
      return !previousBlockInLi;
    }
  }
  return false;
};

export const listIsDisabled = (document: Document, startBlock: Block, list: ListType) => {
  const listBlock = getClosestListAncestor(document, startBlock);
  const isFirstBlockInListItem = !getPreviousSiblingBlockInListItem(document, startBlock);
  if (!listBlock) {
    return false;
  }
  // if startBlock is not first block in list item and has list ancestor of different type, block this button
  return !isFirstBlockInListItem && !!listBlock && !isListBlockOfType(listBlock, list);
};
