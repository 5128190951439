// 93cfb5fb-f985-4dc4-965a-ee434c6f7e89 - is Selectel cloud bucket ID for 'vc-web' bucket name in account 59720 at 'englex' project
const soundUri = 'https://93cfb5fb-f985-4dc4-965a-ee434c6f7e89.selstorage.ru/sound/';

export enum SoundNotification {
  chatMessageReceived = 'chatMessageReceived.mp3',
  chatMessageSent = 'chatMessageSent.mp3',
  callIncoming = 'callIncoming.mp3',
  callOutgoing = 'callOutgoing.mp3',
  callInterrupted = 'callInterrupted.mp3',
  callEnd = 'callEnd.mp3'
}

export function soundUrl(notification: SoundNotification): string {
  return soundUri + notification;
}
