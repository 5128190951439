import {DictionarySearchMode} from 'common/enums';

import {
  type IEntry,
  type Grammar,
  type Grammars,
  type GroupedPartsOfSpeechEnToRus,
  type Sense
} from '../interfaces';

const areObjectsEqual = (obj1: Grammar, obj2: Grammar) => {
  const obj1Keys = Object.keys(obj1);

  return (
    obj1Keys.length === Object.keys(obj2).length && obj1Keys.every(key => obj1[key] === obj2[key])
  );
};

const areGrammarsEqual = (arr1: Grammars, arr2: Grammars) => {
  if (arr1.length !== arr2.length) return false;

  for (const item1 of arr1) {
    const index2 = arr2.findIndex(item2 => areObjectsEqual(item1, item2));

    if (index2 === -1) return false;
  }

  return true;
};

export const findGrammar = (grammar: Grammars, entries: IEntry[]) => {
  return entries.findIndex(
    entry => entry.grammaticalFeatures && areGrammarsEqual(grammar, entry.grammaticalFeatures)
  );
};

export const sortEntries = (entries: GroupedPartsOfSpeechEnToRus) => {
  return Object.entries(entries).reduce((newEntries, [key, array]) => {
    newEntries[key] = array.sort(
      (a, b) =>
        Number(a.hasOwnProperty('grammaticalFeatures')) -
        Number(b.hasOwnProperty('grammaticalFeatures'))
    );
    return newEntries;
  }, {});
};

const getGrammaticalFeatures = (sense: Sense) =>
  sense.grammaticalFeatures?.map(feature => feature.id).join(', ') || '';

const getDomains = (sense: Sense) => sense.domains?.map(domine => domine.id).join(', ') || '';

export const getGrammaticalFeaturesAndDomains = (sense: Sense) => {
  const grammaticalFeatures = getGrammaticalFeatures(sense);
  const domains = getDomains(sense);

  if (grammaticalFeatures && domains) return `${grammaticalFeatures}, ${domains}`;
  if (grammaticalFeatures) return grammaticalFeatures;
  if (domains) return domains;
  return '';
};

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getLexicalCategoryText = (
  partOfSpeech: {[key: string]: string},
  searchMode: DictionarySearchMode,
  lexicalCategory?: string
) => {
  if (!lexicalCategory) return '';
  if (searchMode === DictionarySearchMode.EN_TO_RUS) {
    if (Object.keys(partOfSpeech).includes(lexicalCategory.toLowerCase()))
      return partOfSpeech[lexicalCategory.toLowerCase()];
    else return partOfSpeech.other;
  } else {
    if (Object.keys(partOfSpeech).includes(lexicalCategory.toLowerCase()))
      return capitalizeFirstLetter(lexicalCategory);
    else return 'Other';
  }
};
