import type React from 'react';

type Loading = 'LOADING';
type Paused = 'PAUSE';
type Playing = 'PLAYING';

export const LOADING: Loading = 'LOADING';
export const PAUSE: Paused = 'PAUSE';
export const PLAYING: Playing = 'PLAYING';

export type PlayStatusV2 = Loading | Paused | Playing;

export interface AudioFile {
  length?: number;
  id: number;
  url: string;
  title: string;
}

export type AudioFileWithLength = Required<AudioFile>;

export enum VideoSourceType {
  YOUTUBE = 'youtube',
  SELECTEL = 'selectel'
}

export interface VideoData {
  id?: number;
  source: VideoSourceType;
}

export interface VideoOptions {
  youtubeSourceId?: string;
  start?: number;
  end?: number;
}

export type ReactMoTEvent = React.MouseEvent | React.TouchEvent;

export type MoTEvent = MouseEvent | TouchEvent;

export type MediaType = 'audio' | 'video';

export enum MediaContextAction {
  Play = 'play',
  Pause = 'pause',
  Stop = 'stop',
  ChangeRate = 'change-rate',
  ChangeTimestamp = 'change-timestamp'
}

export interface MediaContext {
  mediaId: number;
  widgetId: string;
  action?: MediaContextAction;
  playbackRate?: number;
  timestamp?: number;
  playStatus?: PlayStatusV2;
  shouldScroll?: boolean;
}
