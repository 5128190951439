import React, {type FC, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState, type Role} from 'store/interface';
import {studentTeachersReducer} from 'store/studentTeachers/reducer';
import {useInjectReducer} from 'hooks/redux/useInjectReducer';
import {collapseLayout as collapseLayoutActionCreator} from 'layouts/actions/action';
import {AuthRolesGuard} from 'authentication';

import ClassRoom from './ClassRoom';

const ClassRoomLayout: FC = () => {
  const isInjected = useInjectReducer({studentTeachers: studentTeachersReducer});

  const dispatch = useDispatch();
  const collapsed = useSelector<AppState, boolean>(s => s.layout.collapsed);
  const collapseLayout = useCallback(
    () => dispatch(collapseLayoutActionCreator(false)),
    [dispatch]
  );

  return isInjected ? <ClassRoom collapsed={collapsed} collapseLayout={collapseLayout} /> : null;
};

const academicRoles: Role[] = ['student', 'teacher'];

export default () => {
  return (
    <AuthRolesGuard roles={academicRoles}>
      <ClassRoomLayout />
    </AuthRolesGuard>
  );
};
