import {type Action} from 'redux';

import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';
import {type FileExtension} from 'common/enums';

const PATH: string = 'LIBRARY/';

export const MODAL_OPENED = PATH + 'MODAL_OPENED';
export const MODAL_CLOSED = PATH + 'MODAL_CLOSED';
export const REQUEST_ADD = PATH + 'REQUEST_ADD';
export const REQUEST_ADD_SUCCESS = REQUEST_ADD + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_ADD_FAIL = REQUEST_ADD + AXIOS_ERROR_POSTFIX;
export const TREE_COLLAPSE_NODE = PATH + 'TREE_COLLAPSE_NODE';
export const TREE_COLLAPSE_ALL = PATH + 'TREE_COLLAPSE_ALL';
export const TREE_REQUEST = PATH + 'TREE_REQUEST';
export const TREE_REQUEST_SUCCESS = TREE_REQUEST + AXIOS_SUCCESS_POSTFIX;
export const TREE_REQUEST_FAIL = TREE_REQUEST + AXIOS_ERROR_POSTFIX;
export const FILE_SELECTED = PATH + 'FILE_SELECTED';
export const PREVIEW_LIBRARY_PDF = PATH + 'PREVIEW_LIBRARY_PDF';
export const CLOUD_DOCUMENT_EXIST: string = PATH + 'CLOUD_DOCUMENT_EXIST';
export const STORE_SCROLL_POSITION: string = PATH + 'STORE_SCROLL_POSITION';

export interface LibraryItem {
  title: string;
  id: string;
  exist: boolean;
  type: FileExtension;
  audio_count: number;
}

export interface LibrarySearchItem extends LibraryItem {
  path: string[];
}

export type LibraryNode = {[key: string]: LibraryNode} | LibraryItem[] | LibraryItem;

export interface LibrarySelectedFiles {
  [key: string]: number;
}

export interface LibraryCollapsedState {
  [key: string]: boolean;
}

export interface PreviewLibraryPDFAction extends Action {
  item: LibraryItem | null;
}

export interface StoreScrollTopPositionAction extends Action {
  scrollTopPosition?: number;
}

export type PreviewLibraryPDFCreator = (item: LibraryItem | null) => PreviewLibraryPDFAction;

export interface CloudDocumentExistSuccessAction extends Action {
  payload: {
    data: {
      id: string;
      title: string;
      url: string;
      md5: string;
    };
  };
}
