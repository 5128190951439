import Bowser from 'bowser';

export function getLineSeparator() {
  switch (Bowser.getParser(window.navigator.userAgent).getOS().name) {
    case 'Linux':
      return '\n';
    case 'Windows':
      return '\r\n';
    default:
      return '\n';
  }
}
