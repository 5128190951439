import {defineMessages} from 'react-intl';

const validationMessages = defineMessages({
  ContentNonEmpty: {
    id: 'XEditor.Validation.ContentNonEmpty'
  },
  ConditionalNonEmpty: {
    id: 'XEditor.Validation.ConditionalNonEmpty'
  },
  GapFillHasNoGaps: {
    id: 'XEditor.Validation.GapFillHasNoGaps'
  },
  GapFillHasNoGaps2: {
    id: 'XEditor.Validation.GapFillHasNoGaps2'
  },
  ExerciseTitleTooLong: {
    id: 'XEditor.Validation.ExerciseTitleTooLong'
  },
  MinWidgetsCount: {
    id: 'XEditor.Validation.MinWidgetsCount'
  },
  WidgetsNotOnlyNotes: {
    id: 'XEditor.Validation.WidgetsNotOnlyNotes'
  },
  InternalEditorError: {
    id: 'XEditor.Validation.InternalEditorError'
  },
  TaskNonEmpty: {
    id: 'XEditor.Validation.TaskNonEmpty'
  },
  TaskMaxLength: {
    id: 'XEditor.Validation.TaskMaxLength'
  },
  HowToTaskNotEmpty: {
    id: 'XEditor.Validation.HowToTaskNotEmpty'
  },
  MinImagesCount: {
    id: 'XEditor.Validation.MinImagesCount'
  },
  Min2ImagesCount: {
    id: 'XEditor.Validation.Min2ImagesCount'
  },
  Min6ImagesCount: {
    id: 'XEditor.Validation.Min6ImagesCount'
  },
  Min6ImagesCountWithoutExamples: {
    id: 'XEditor.Validation.Min6ImagesCountWithoutExamples'
  },
  BlankWords: {
    id: 'XEditor.Validation.BlankWords'
  },
  BlankAnswers: {
    id: 'XEditor.Validation.BlankAnswers'
  },
  BlankImages: {
    id: 'XEditor.Validation.BlankImages'
  },
  QuestionsNonEmpty: {
    id: 'XEditor.Validation.QuestionsShouldNotBeEmpty'
  },
  CategoriesNonEmpty: {
    id: 'XEditor.Validation.CategoriesShouldNotBeEmpty'
  },
  MinRequiredAnswers: {
    id: 'XEditor.Validation.MinRequiredAnswers'
  },
  MinGroupRequiredAnswers: {
    id: 'XEditor.Validation.MinGroupRequiredAnswers'
  },
  AnswersNonEmpty: {
    id: 'XEditor.Validation.AnswersShouldNotBeEmpty'
  },
  NoMoreAnswersThanQuestions: {
    id: 'XEditor.Validation.NoMoreAnswersThanQuestions'
  },
  ExtraChoicesNotEmpty: {
    id: 'XEditor.Validation.ExtraChoicesNotEmpty'
  },
  ExtraChoicesNotEqualToContentChoices: {
    id: 'XEditor.Validation.ExtraChoicesNotEqualToContentChoices'
  },
  CorrectAnswersSelected: {
    id: 'XEditor.Validation.CorrectAnswersSelected'
  },
  OneAnswerRequired: {
    id: 'XEditor.Validation.OneAnswerRequired'
  },
  NoDuplicateAnswers: {
    id: 'XEditor.Validation.NoDuplicateAnswers'
  },
  SentenceIsTooShort: {
    id: 'XEditor.Validation.SentenceIsTooShort'
  },
  SpellingSentenceIsTooShort: {
    id: 'XEditor.Validation.SpellingSentenceIsTooShort'
  },
  ShouldContainAtLeastOneWord: {
    id: 'XEditor.Validation.NoWords'
  },
  WordsAndTranslationNonEmpty: {
    id: 'XEditor.Validation.WordsAndTranslationsNonEmpty'
  },
  WordsNotContainCyrillic: {
    id: 'XEditor.Validation.WordsNotContainCyrillic'
  },
  CategoryNameNonEmpty: {
    id: 'XEditor.Validation.CategoryNameNonEmpty'
  },
  CategoryContainsWords: {
    id: 'XEditor.Validation.CategoryContainsWords'
  },
  WordsAndTranslationsNotTooLong: {
    id: 'XEditor.Validation.WordsAndTranslationsNotTooLong'
  },
  CategoriesNotTooLong: {
    id: 'XEditor.Validation.CategoriesNotTooLong'
  },
  ValidQuizletURL: {
    id: 'XEditor.Validation.ValidQuizletURL'
  },
  AudioExists: {
    id: 'XEditor.Validation.AudioExistence'
  },
  NotOnlyExampleQuestion: {
    id: 'XEditor.Validation.NotOnlyExampleQuestion'
  },
  NotOnlyExamples: {
    id: 'XEditor.Validation.NotOnlyExamples'
  },
  ExampleAnswerNotEmpty: {
    id: 'XEditor.Validation.ExampleAnswerNotEmpty'
  },
  VideoExists: {
    id: 'XEditor.Validation.VideoExistence'
  },
  FirstWidgetTaskNotEmpty: {
    id: 'XEditor.Validation.FirstWidgetTaskNotEmpty'
  },
  ListTitleTooLong: {
    id: 'XEditor.Validation.ListTitleTooLong'
  },
  GrammarTitleNotEmpty: {
    id: 'XEditor.Validation.GrammarTitleNotEmpty'
  },
  UnknownWidgetInExercise: {
    id: 'XEditor.Validation.UnknownWidget'
  },
  MediaResourceTitleNotEmpty: {
    id: 'XEditor.Validation.MediaResourceTitleNotEmpty'
  },
  MediaResourceTitleMaxLength: {
    id: 'XEditor.Validation.MediaResourceTitleMaxLength'
  },
  MediaResourceUrlNotEmpty: {
    id: 'XEditor.Validation.MediaResourceUrlNotEmpty'
  },
  MediaResourceUrlNotValid: {
    id: 'XEditor.Validation.MediaResourceUrlNotValid'
  },
  CardTitleNotEmpty: {
    id: 'XEditor.Validation.CardTitleNotEmpty'
  },
  CardTitleMaxLength: {
    id: 'XEditor.Validation.CardTitleMaxLength'
  },
  CardTextNotEmpty: {
    id: 'XEditor.Validation.CardTextNotEmpty'
  },
  ResultTitleNotEmpty: {
    id: 'XEditor.Validation.ResultTitleNotEmpty'
  },
  ResultTextNotEmpty: {
    id: 'XEditor.Validation.ResultTextNotEmpty'
  },
  ButtonTitleNonEmpty: {
    id: 'XEditor.Validation.ButtonTitleNonEmpty'
  },
  DialogueNameNotEmpty: {
    id: 'XEditor.Validation.DialogueNameNotEmpty'
  },
  DialogueMessageTextNotEmpty: {
    id: 'XEditor.Validation.DialogueMessageTextNotEmpty'
  },
  DialogueMessagesNotEmpty: {
    id: 'XEditor.Validation.DialogueMessagesNotEmpty'
  },
  CardsMinSize: {
    id: 'XEditor.Validation.CardsMinSize'
  },
  ImagesMinSize: {
    id: 'XEditor.Validation.ImagesMinSize'
  },
  FlipCardsNotLessOneCard: {
    id: 'XEditor.Validation.FlipCardsNotLessOneCard'
  },
  FlipCardsImagesNotEmpty: {
    id: 'XEditor.Validation.FlipCardsImagesNotEmpty'
  },
  FlipCardsTextsNotEmpty: {
    id: 'XEditor.Validation.FlipCardsTextsNotEmpty'
  },
  FlipCardsTextsNotMoreMaxLength: {
    id: 'XEditor.Validation.FlipCardsTextsNotMoreMaxLength'
  },
  CharactersEntered: {
    id: 'XEditorWidget.FlipCards.CharactersEntered'
  },
  StyleListMustContainList: {
    id: 'XEditor.Validation.MustContainList'
  },
  StyleListItemsNonEmpty: {
    id: 'XEditor.Validation.ItemsNonEmpty'
  }
});

export default validationMessages;
