import {type IceServer} from './types';

export interface ServerSideCallInfo {
  status: string;
  offer?: string;
  caller_audio: string;
  caller_video?: string;
  recipient_audio: string;
  recipient_video?: string;
  caller_id: string;
  recipient_id: string;
}

export enum BrowserName {
  CHROME = 'Chrome',
  YANDEX = 'Yandex Browser',
  OPERA = 'Opera',
  FIREFOX = 'Firefox',
  SAFARI = 'Safari'
}

interface ServerSideSessionBrowser {
  name: BrowserName;
  version: string | number;
  type: 'browser';
}

export interface ServerSideBrowserInfo {
  outdated: boolean;
  browser: ServerSideSessionBrowser;
  supported: {[key in BrowserName]: string | number};
}

export interface SpeedtestResult {
  ip: string;
  uploadingSpeed: number;
  downloadingSpeed: number;
  ping: number;
  jitter: number;
}

export type CallStatus = 'connected' | 'connecting' | 'reconnecting' | 'connection-break';
export type ConnectionState = 'connected' | 'disconnected' | 'failed' | 'completed' | 'closed';

export interface IceConnectionInfo {
  availableCandidates: IceServer[];
  connectedIceIp?: string;
  connectionType?: string;
}

export interface RTCCall {
  room_id: number;
  created_at?: string | Date;
  id?: string;
  recipient_id?: number;
  caller_id?: number;
  caller_session?: number;
  started_at?: Date;
  status?: CallStatus;
  offer?: string;
  answer?: string;
  duration?: number;
  isRecalling?: boolean;
  recipient_session?: number;
  answered_at?: string | Date;
  caller_audio?: string;
  caller_video?: string;
  caller_state?: string;
  recipient_audio?: string;
  recipient_video?: string;
  recipient_state?: string;
}
