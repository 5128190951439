import {type Value, type ValueJSON} from '@englex/slate';

import {type RouterState} from 'store/router';

import {
  type PlayMode,
  type PlayStatus,
  type Sound
} from '../routes/ClassRoom/components/FilesWorkSpace/soundsTab/actions/interface';
import {
  type DocumentFile,
  type DocumentFileV2,
  type DocumentInstancePage
} from '../routes/ClassRoom/components/FilesWorkSpace/documentsTab/actions/interface';
import {
  type LibraryCollapsedState,
  type LibraryItem,
  type LibraryNode,
  type LibrarySelectedFiles
} from '../routes/ClassRoom/components/FilesWorkSpace/Library/interface';
import {type FileType} from '../routes/ClassRoom/actions/interface';
import {
  type IceConnectionInfo,
  type RTCCall,
  type ServerSideBrowserInfo,
  type SpeedtestResult
} from '../webRTC/interface';
import {type XEditorState} from './exercise/editor/interface';
import {type ExerciseJSON, type XPlayerState} from './exercise/player/interface';
import type UnitRecord from '../routes/Library/CoursebookPage/Unit/UnitExerciseList/dataRecords/UnitRecord';
import type HistoryRecord from '../routes/Library/CoursebookPage/Unit/UnitExerciseList/dataRecords/HistoryRecord';
import {type CoursebookOverview, type UserV2} from '../components/CoursebookLibrary/interface';
import {type AudioFile, type MediaContext, type PlayStatusV2} from '../components/media/interface';
import {type VideoState} from './media/interface';
import type ContentsRecord from '../routes/Library/CoursebookPage/Unit/Sections/ContentsRecord';
import {type IntlState} from '../i18n/actions/interface';
import {type XPreviewProperties, type XPreviewState} from './exercise/player/preview/interface';
import {type DictionaryState} from '../components/Dictionary/shared/interface';
import {type StudentTeachersState} from './studentTeachers/interface';
import {type HomeworkRouteState} from '../routes/Homework/interface';
import {type ClipboardState} from './exercise/editor/clipboardReducer';
import {type IntroRecord} from './intro/IntroRecord';
import {type XIntroState} from './intro/interface';
import {type SettingsState} from '../routes/ClassRoom/reducers/settingsReducer';
import {
  type BooleanEnum,
  type CoursebookAuthorRole,
  type CoursebookStatus,
  type CoursebookType,
  type FileExtension,
  type HomeworkStatus,
  type IntroTheme,
  type ParsedMediaType,
  type ServiceMessageTypes,
  type TextMessageTypes
} from '../common/enums';
// todo: remove imports from 'routes' folder

export type Role = 'student' | 'teacher' | 'methodist';
export type Locale = 'ru' | 'en';
export type WampStatus = 'NOT_CONNECTED' | 'CONNECTING' | 'CONNECTED' | 'RETRYING' | 'CLOSED';
export const NOT_CONNECTED: WampStatus = 'NOT_CONNECTED';
export const CONNECTING: WampStatus = 'CONNECTING';
export const CONNECTED: WampStatus = 'CONNECTED';
export const RETRYING: WampStatus = 'RETRYING';
export const CLOSED: WampStatus = 'CLOSED';

export type WampActionType = 'publish' | 'subscribe' | 'unsubscribe' | 'call';

export interface BaseUser {
  id?: number;
  created_at?: Date;
  profile?: Profile;
  onCall?: number;
}

export interface Recipient extends BaseUser {
  deleted_at: Date | null;
  isActive?: boolean;
}

export interface CommonState {
  version: string;
  hash: string;
  newVersion?: string;
  newHash?: string;
  customTitle?: string;
  changeTitleInterval?: NodeJS.Timeout;
}

export interface AppState {
  user: UserState;
  intl: IntlState;
  toastr: {};
  router: RouterState;
  layout: LayoutState;
  wamp: WampState;
  classroom?: ClassroomState;
  sounds?: SoundsState;
  docs?: DocsState;
  chat?: ChatState;
  autobahnTester?: AutobahnTesterState;
  autobahn?: WampState;
  rtc: RTCState;
  library: DocLibraryState;
  uploads: UploadsState;
  mediaDevices: MediaDevicesState;
  video: VideoPositionState;
  common: CommonState;
  xeditor?: XEditorState;
  xplayer?: XPlayerState;
  xintro: XIntroState;
  clipboard: ClipboardState;
  homework?: HomeworkRouteState;
  unitPreview: UnitPreviewState;
  coursebookLibrary?: CoursebookLibraryState;
  coursebookPage?: CoursebookPageState;
  coursebookLibraryCommon?: CoursebookLibraryCommonState;
  media: MediaState;
  floatSidebar?: FloatSidebarState;
  filesWorkSpace?: FilesWorkSpaceState;
  dictionary?: DictionaryState;
  studentTeachers?: StudentTeachersState;
  settings?: SettingsState;
}

export interface AutobahnTesterState {
  active: WampActionType;
  token: string;
  response?: string;
  topics: TestTopics;
}

export interface TestTopics {
  [key: string]: Array<{
    created: Date;
    json: string;
  }>;
}

export interface LayoutState {
  networkError?: boolean;
  notifications?: number;
  showContactsModal: boolean;
  contactsPopoverOpen?: boolean;
  userDropdownOpen?: boolean;
  navDropdownOpen?: boolean;
  sendingMessageAwait: boolean;
  subjects?: {[key: string]: string};
  show?: boolean;
  isMobile?: boolean;
  appOnline?: boolean;
  chatPopoverOpen?: boolean;
  reportDropdownOpen?: boolean;
  pageHasFocus?: boolean;
  intervalId?: NodeJS.Timeout;
  framesCount?: number;
  showAppOfflineModal?: boolean;
  appOfflineTimeout?: NodeJS.Timeout;
  enforceDesktop?: true;
  collapsed: boolean;
  buttonNode?: string;
  isFirstLoadedPage?: true;
}

export interface StudentTeachers {
  [key: string]: StudentTeacher;
}
export interface Courses {
  [key: string]: CourseInstance;
}

interface BaseStudentTeacher {
  id: number;
  deleted_at?: Date;
  created_at?: Date;
  student_id: string;
  teacher_id: string;
  room_id?: number;
}

export interface ServerStudentTeacher extends BaseStudentTeacher {
  student?: Recipient;
  teacher?: Recipient;
}

// client StudentTeacher
export interface StudentTeacher extends BaseStudentTeacher {
  recipient: Recipient;
}

export interface Course {
  id: number;
  name: string;
}

export interface CourseDetailed extends Course {
  short_name: string;
  course: number;
  image: {'@1x': string; '@2x': string};
}

export interface ExerciseCategory {
  id: number;
  title: string;
  parentId: number | null;
  authorId: string | null;
}

export interface LanguageLevel {
  id: number;
  title: string;
}

export interface CourseInstance {
  id: number;
  student_teacher_id: number;
  course_id: number;
  created_at: Date;
  deleted_at: Date | null;
  course: CourseDetailed;
}

export interface ExerciseInstanceComment {
  id: string;
  exerciseInstanceId: string;
  createdById: number;
  createdAt: Date;
  updatedAt: Date | null;
  comment: ValueJSON;
  createdBy?: UserV2;
}

export interface ExerciseInstanceCommentMap
  extends Omit<ExerciseInstanceComment, 'comment' | 'createdBy'> {
  createdBy: UserV2;
  comment: Value;
}

export interface ExerciseInstance extends ExerciseJSON {
  id: string;
  pageNumber: number;
  unitInstanceId: number;
  coursebookInstanceId?: string;
  unitInstance?: UnitInstance;
}

export type UnitPreviewState = {
  coursebookId?: string;
  unitId?: number;
  mainId?: string;
  grammarPreview?: string;
  xpreview?: XPreviewProperties;
  extraPreview?: XPreviewProperties;
} | null;

interface UnitOverview {
  id: number;
  pagesCount: number;
  mainExercisesCount: number;
  extraExercisesCount: number;
}

interface UnitInstanceOverview {
  id: number;
  mainExercisesCount: number;
  mainExercisesCompleted: number;
  extraExercisesCount: number;
  extraExercisesCompleted: number;
  homeworkExercisesCount: number;
  touched: boolean;
}

export interface UnitInstance {
  id: number;
  active: boolean;
  completedAt: string | null;
  ordinal: number | null;
  unit: CoursebookUnit;
  exerciseInstances: ExerciseInstance[];
  overview: UnitInstanceOverview;
  coursebookInstance?: CoursebookInstance;
}

export interface RedirectedSign {
  url: string;
  fromWhere?: string;
}

export interface CoursebookInstanceState {
  selectedCoursebookInstance?: CoursebookInstance;
  unitInstances?: UnitInstance[];
  unitInstancesDropdownOpen?: true;
  redirectedFromUrl?: RedirectedSign;
}

export interface Homework {
  id: string;
  courseInstanceId: number;
  status: HomeworkStatus;
  activatedAt: string | null;
  completedAt: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface HomeworkWithOverview extends Homework {
  overview: {
    documents: number;
    documentPages: number;
    exercises: number;
    exercisesCompleted: number;
    touched: boolean;
  };
}

export interface HomeworkPlayerState {
  selectedHomework?: HomeworkWithContents;
}

export interface HomeworkState {
  homeworkList: HomeworkWithOverview[];
  homeworkPlayer: HomeworkPlayerState;
  redirectedFromURL?: string;
  isLoading?: boolean;
  isError?: boolean;
}

export interface HomeworkDocumentInstance {
  documentInstanceId: number;
  comment: string;
}

export interface HomeworkDocumentInstancePage {
  documentInstancePageId: number;
  documentInstancePage: DocumentInstancePage;
  comment: string;
}

interface HomeworkExerciseInstance {
  exerciseInstanceId: string;
}

export interface HomeworkContents {
  exercises: HomeworkExerciseInstance[];
  documents: HomeworkDocumentInstance[];
  documentPages: HomeworkDocumentInstancePage[];
}

export interface HomeworkWithContents extends HomeworkContents, Homework {}

export interface CourseInstanceOverview {
  id: number;
  homeworkAmount: number;
  homeworkDraft: number;
  homeworkGiven: number;
  homeworkDone: number;
  homeworkChecked: number;
  homeworkCompleted: number;
  fileDocumentAmount: number;
  fileAudioAmount: number;
  filePdfCount: number;
}

export interface CourseInstanceState {
  coursebookInstanceList?: CoursebookInstance[];
  coursebookInstanceState: CoursebookInstanceState;
  homework: HomeworkState;
  coursebookInstancesDropdownOpen?: boolean;
  pagerPopoverOpen?: boolean;
  homeworkDraft?: HomeworkWithContents;
  courseInstanceOverview?: CourseInstanceOverview;
}

export interface SelectionData<R = unknown> {
  elementId?: string;
  exerciseId?: string;
  widgetId?: string;
  editorId?: string;
  range?: R;
  relatedElement?: string;
}

export interface ClassroomState {
  partnersFilter: string;
  partnersOpenTabId: string;
  lastUnitPages: Record<string, number>;
  courses?: Courses;
  networkError?: boolean;
  studentTeachersPopoverOpen?: boolean;
  coursesDropdownOpen?: boolean;
  courseInstanceState: CourseInstanceState;
  incorrectUrlId?: boolean;
  openWhenLoadedSoundId?: string;
  openingUrlForPartner?: boolean;
  openingExerciseForPartner?: boolean;
  publishingPointerUrl?: boolean;
  batchedMedia?: string;
  batchedMediaContext?: MediaContext;
  hasGrammar?: boolean;
  selection?: SelectionData;
}

export interface FilesWorkSpaceState {
  searchBarOpen?: boolean;
  filesFilter: string;
}

export interface Documents {
  [key: number]: DocumentFile | DocumentFileV2;
}
export interface Sounds {
  [key: number]: Sound;
}

export interface DocsState {
  lastPages: Record<string, number>;
  documents?: Documents;
  editedDocument?: number;
  editDocumentAwait: boolean;
  loadingDocuments?: boolean;
  networkError?: boolean;
  openTogetherFileId?: number;
  pinnedCount: number;
  pinAwait?: true;
  editHomeworkCommentForDocument?: number;
  editHomeworkCommentForDocumentPage?: number;
}

export interface DocLibraryState {
  libraryModalOpened?: boolean;
  library?: LibraryNode;
  libraryCollapsed?: LibraryCollapsedState;
  librarySelected?: LibrarySelectedFiles;
  librarySubmitting?: boolean;
  libraryError?: boolean;
  previewingPDF?: LibraryItem;
  scrollTopPosition?: number;
}

export interface SoundsState {
  sounds?: Sounds;
  activeSound?: number;
  startedAt?: number;
  playStatus: PlayStatus;
  playMode: PlayMode | null;
  volume?: number;
  timestamp: number;
  editedSound?: number;
  editSoundAwait: boolean;
  loadingSounds?: boolean;
  networkError?: boolean;
  openTogetherFileId?: number;
  volumePopoverOpen?: boolean;
  uniquePlaybackId?: string;
  playbackRate?: number;
}

export type UploadingStatus = 'cloning' | 'uploading' | 'converting' | 'fail' | 'uploaded';

interface View {
  document_instance_id?: number;
  read_by: number;
  read_at: Date;
}

export interface MaterialFile {
  id: number;
  href: string;
  title: string;
  isNew: boolean;
  created_at: Date;
  created_by: number;
  md5: string;
  course_instance_id: number;
  beingPinned?: boolean;
  pinned?: boolean | null;
  fileType?: FileType;
  views: View[];
  isBeingDeletedComponentId?: string;
  url?: string;
  type: FileExtension;
  courseInstance: {
    student_teacher_id: number;
  };
}

export interface UploadingFile {
  id: string;
  title: string;
  studentTeacherId: number;
  course_instance_id: number;
  md5?: string;
  fileType: FileType;
  uploadingStatus: UploadingStatus;
  percentsUploaded?: number;
  errorMessage?: string;
  uploadingStartedTime: number;
}

export interface UploadingFiles {
  [key: string]: UploadingFile;
}

export interface UploadsState {
  uploadingSounds: UploadingFiles;
  uploadingDocuments: UploadingFiles;
  recentDocuments: Documents;
  recentSounds: Sounds;
  recentSoundsUploaded?: boolean;
  recentDocumentsUploaded?: boolean;
  loadingRecentSounds?: boolean;
  loadingRecentDocuments?: boolean;
  blockSoundsUploading?: boolean;
  blockDocsUploading?: boolean;
  networkError?: boolean;
}

export interface UserWithRole extends BaseUser {
  role?: Role;
}

export interface UserState extends UserWithRole {
  token?: string;
  sessionExpired?: boolean;
}

export interface AvatarUrl {
  '@1x': string;
  '@2x': string;
}

export interface Profile {
  first_name: string;
  last_name: string;
  email: string;
  timeZone: string;
  avatars: {
    xs: AvatarUrl;
    sm: AvatarUrl;
    md: AvatarUrl;
    lg: AvatarUrl;
  };
}

export interface WampState {
  sessionId?: number;
  authid?: string;
  authrole?: string;
  status: WampStatus;
  retryCount?: number;
  subscriptions: string[];
}

export type UserStatus =
  | 'offline' // offline
  | 'oncall' // on call
  | 'online' // online in desktop browser
  | 'online-no-rtc' // online in desktop browser, unsupported for RTC calls
  | 'online-mobile' // online in mobile browser
  | 'online-mobile-no-rtc' // online in mobile browser, unsupported for RTC calls
  | 'app-vc-mobile' // online in mobile app VcMobile
  | 'app-vc-mobile-webview'; // online in mobile app VcMobile WebView

export interface RoomUser extends Recipient {
  onCall: number;
  online: number;
  userStatus: UserStatus;
  typingTimerId?: NodeJS.Timeout;
}

export interface ServiceMessageMeta {
  type: ServiceMessageTypes;
  callId: string;
  callerId: number;
  recipientId: number;
  video?: boolean;
  audio?: boolean;
}

export interface CallEndedMeta {
  type: ServiceMessageTypes;
  callId: string;
  endedBy: number | null;
  duration: number;
}

export interface ImageViewerImage {
  id: string;
  src: string;
  retina?: string;
}

export interface ParsedMedia {
  src: string;
  type: ParsedMediaType;
}

export interface ParsedYTVideo extends ParsedMedia {
  videoId: string;
}

export interface ParsedImage extends ParsedMedia {
  imageId: string;
  type: ParsedMediaType.image;
}

export interface TextMessageMeta {
  type: TextMessageTypes;
  parsedMedia?: Array<ParsedYTVideo | ParsedImage>;
  // only for type: image
  imageCorrupted?: boolean;
}

export type MessageMeta = ServiceMessageMeta | CallEndedMeta | TextMessageMeta;

export interface ChatMessage {
  id: number;
  sender_id: number;
  text: string;
  created_at: string;
  updated_at?: string;
  deleted_at?: string;
  new: boolean;
  own?: boolean;
  room_id?: number;
  meta: null | string | ServiceMessageMeta | CallEndedMeta | TextMessageMeta;
  answer?: string;
  thumbnail?: EnglexImage;
}

export interface Room {
  id: number;
  private: boolean;
  newMessagesCount: number;
  messages: ChatMessage[];
  recipient?: RoomUser;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  allMessagesLoaded: boolean;
  loadingNewMessages?: boolean;
  sendingMessageAwait?: boolean;
  showStartPhrases: boolean;
}

export interface RoomWithRecipient extends Room {
  recipient: RoomUser;
}

export interface Rooms {
  [key: string]: Room;
}

export interface ChatClipboard {
  image: File;
}

export interface ChatState {
  popoverOpenTabId: string;
  roomsFilter: string;
  roomsLoaded: boolean;
  rooms?: Rooms;
  selectedRoomId?: number;
  wampError?: boolean;
  roomsPopoverOpen?: boolean;
  typingTimerId?: NodeJS.Timeout;
  messageToUpdateId?: number;
  shouldLoadPrivateRoomsList?: boolean;
  shouldPlayNotification?: boolean;
  images: ImageViewerImage[];
  scrollbarAtBottom: boolean;
  selectedYTVideoId: string | null;
  clipboard?: ChatClipboard;
}

export interface RTCState {
  incomingCall?: boolean;
  outgoingCall?: boolean;
  callInProgress?: boolean;
  badBrowser?: boolean;
  browserInfo?: ServerSideBrowserInfo;
  callStarting?: boolean;
  localStream?: MediaStreamConstraints;
  remoteStream?: MediaStreamConstraints;
  call?: RTCCall;
  answeringAwait?: boolean;
  otherSessionCall?: boolean;
  partnerSessionId?: number;
  callNotificationId?: number;
  localConnectionQuality?: SpeedtestResult;
  remoteConnectionQuality?: SpeedtestResult;
  performSpeedtest?: boolean;
  speedtestRunnung?: boolean;
  speedtestGloballyDisabled?: boolean;
  iceConnectionInfo?: IceConnectionInfo;
  remoteSpeedtestTimeout?: number;
  lowSpeedToastWasShown?: boolean;
  shouldHideBadBrowser?: boolean;
}

export interface VideoPositionState {
  fullScreen?: boolean;
  undocked?: boolean;
  positionX: number;
  positionY: number;
  width: number;
  height: number;
  transformingMode?: boolean;
  smallVideoRatio: number;
}

export interface MediaDevicesState {
  showModal?: boolean;
  available?: MediaDeviceInfo[];
  skipGreeting?: boolean;
  mic?: string;
  cam?: string;
  camLabel?: string;
  micLabel?: string;
  modalSelectedMic?: string;
  modalSelectedCam?: string;
  modalSelectedMicLabel?: string;
  modalSelectedCamLabel?: string;
  cameraAccess?: boolean;
  micAccess?: boolean;
  selectedMicPresent?: boolean;
  selectedCamPresent?: boolean;
  noMicsAvailable?: boolean;
  noCamsAvailable?: boolean;
}

export interface XAudio {
  audioFile?: AudioFile;
  context?: MediaContext;
  forceRestart?: true;
  playStatus?: PlayStatusV2;
  playbackRate?: number;
  startedAt?: number;
  timestamp: number;
  volume: number;
}

export type XAudioState = XAudio | undefined;

export interface MediaState {
  audio?: XAudio;
  video: VideoState;
}

export interface EnglexImage {
  id: number;
  clientId: string;
  width: number;
  height: number;
  src_id: number;
  urls: string[];
  thumbs?: {
    ['188x188']: EnglexImage;
  };
  image?: EnglexImage;
}

export interface ImageV2 {
  alt: null;
  createdAt: string;
  createdById: number;
  deletedAt: string | null;
  height: number;
  id: number;
  md5: string;
  title: null;
  urls: string[];
  width: number;
}

export interface CoursebookFilter {
  title: string | null;
  role: CoursebookAuthorRole | null;
  published: BooleanEnum | null;
  courses: number[] | null;
  levels: number[] | null;
  authorId: number | null;
  originalAuthorId: number | null;
}

export interface CoursebookDataModalState {
  availableCourses?: Course[];
  availableLevels?: LanguageLevel[];
  isOpen?: boolean;
  // if undefined, the modal was opened to create new coursebook
  editedCoursebook?: Coursebook;
  copiedCoursebook?: Coursebook;
}

export interface CoursebookLibraryCommonState {
  // state used in /library and /library/coursebook routes
  coursebookDataModal: CoursebookDataModalState;
}

export type PartialCoursebook = Pick<Coursebook, 'id' | 'title'>;

export interface Cover {
  urls: string[];
  id: number;
  height: number;
  width: number;
}

export interface Coursebook {
  id: string;
  title: string;
  type: CoursebookType;
  status: CoursebookStatus;
  courses: Array<Pick<CourseDetailed, 'id' | 'name'>>;
  cover?: Cover;
  levels: LanguageLevel[];
  author: UserV2;
  originalAuthor?: UserV2;
  overview?: CoursebookOverview;
  units?: CoursebookUnit[];
  hasGrammar?: boolean;
  selfCheckEnabled: boolean;
}

export interface CoursebookInstanceOverview {
  extraExercisesCompleted: number;
  extraExercisesCount: number;
  id: string;
  mainExercisesCompleted: number;
  mainExercisesCount: number;
  testsActivated: number;
  testsCompleted: number;
  testsCount: number;
  touched: boolean;
  unitsActivated: number;
  unitsAmount: number;
  unitsCompleted: number;
  unitsCount: number;
}

export interface CoursebookInstance {
  id: string;
  coursebookId: string;
  courseInstanceId: number;
  createdAt: string;
  completedAt: string | null;
  coursebook: Coursebook;
  overview?: CoursebookInstanceOverview;
  hasGrammar?: boolean;
  selfCheckEnabled?: boolean;
}

export interface CoursebookLibraryState {
  coursebooks?: Coursebook[];
  totalCount?: number;
  updatingLibrary?: boolean;
  hasError?: boolean;
  availableAuthors?: UserV2[];
  availableOriginalAuthors?: UserV2[];
  extendedSearchPanelOpen?: true;
}

export interface CoursebookUnit {
  id: number;
  cover?: Cover;
  coverId?: number;
  title: string;
  isRevision?: boolean;
  lockedBy?: UserWithRole;
  ordinal: number | null;
  sequence: number;
  overview?: UnitOverview;
  intro: UnitIntroJSON | null;
}

export enum CoursebookRequestError {
  COURSEBOOK_NOT_FOUND = 'COURSEBOOK_NOT_FOUND',
  OTHER_ERROR = 'OTHER_ERROR '
}

export enum UnitExerciseRequestError {
  UNIT_NOT_FOUND = 'UNIT_NOT_FOUND',
  OTHER_ERROR = 'OTHER_ERROR'
}

export interface ExerciseViewerState {
  viewedExercise?: string;
  requestError?: boolean;
  xpreview?: XPreviewState;
}

export interface Section {
  id: number;
  title: string;
}

export interface CoursebookSection {
  id: number;
  ordinal: number;
  section: Section;
}

export interface CoursebookSectionsState {
  coursebookSections?: CoursebookSection[];
  unitSectionContents?: ContentsRecord;
  updatingContents?: boolean;
  coursebookSectionModal: {
    show?: boolean;
    editedCoursebookSectionId?: number;
  };
}

export interface CoursebookPageState {
  showDetailedCoursebookInfo?: boolean;
  suppPanelOpenForExercises: number[];
  isUpdating?: boolean;

  sections: CoursebookSectionsState;

  intro?: IntroRecord;
  units?: CoursebookUnit[];
  unit?: HistoryRecord<UnitRecord>;
  confirmRedirectToPath?: string;
  updatingUnit?: boolean;
  editedUnitId?: number;
  unitIdToDelete?: number;

  coursebookRequestError?: CoursebookRequestError;
  unitExerciseRequestError?: UnitExerciseRequestError;
  coursebookInfo?: Coursebook;
  exerciseViewer: ExerciseViewerState;
}

export interface UnitExerciseJSON {
  id: number;
  parentExerciseId: string | null;
  unitId: number;
  pageNumber: number;
  ordinal: number;
  createdAt: string;
  updatedAt: string;
  deleted?: boolean;
  exercise: {
    id: string;
    lastRevision: {
      id: number;
      exerciseId: string;
      number: number;
      excerpt: string;
      createdAt: string;
    };
  };
}

export interface UnitIntroJSON {
  coverId?: number;
  data: {
    theme: IntroTheme;
    content: ValueJSON;
  };
}

interface UnitExerciseSupplementary {
  id: number;
  excerpt: string;
  lockedBy?: UserV2;
  exerciseId: string;
}

export interface UnitExerciseMain extends UnitExerciseSupplementary {
  supplementaryUnitExerciseList: UnitExerciseSupplementary[];
}

export interface UnitPage {
  unitExerciseList: UnitExerciseMain[];
  id: string;
}

export interface FloatSidebarState {
  collapsed?: boolean;
  sidebarPulledOut?: boolean;
}
