import React, {createContext, useState, useContext} from 'react';

import {ErrorModal} from '../components/ErrorModal/ErrorModal';

interface ErrorModalContextProps {
  isOpen: boolean;
  isAddFromErrorModal: boolean;
  setIsAddFromErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  notChangedTranslation: [string, string] | undefined;
  setNotChangedTranslation: React.Dispatch<React.SetStateAction<[string, string]>>;
  hide: () => void;
  open: () => void;
  setCurrentOriginal: React.Dispatch<React.SetStateAction<string>>;
  setCurrentTranslate: React.Dispatch<React.SetStateAction<string>>;
}

interface ErrorModalContextProviderProps {
  submit: (original: string, translation: string) => void;
  isAddFromErrorModal: boolean;
  setIsAddFromErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  notChangedTranslation: [string, string] | undefined;
  setNotChangedTranslation: React.Dispatch<React.SetStateAction<[string, string]>>;
}

const ErrorModalContext = createContext<ErrorModalContextProps>({} as ErrorModalContextProps);

export const ErrorModalContextProvider: React.FC<ErrorModalContextProviderProps> = ({
  children,
  submit,
  isAddFromErrorModal,
  setIsAddFromErrorModal,
  notChangedTranslation,
  setNotChangedTranslation
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOriginal, setCurrentOriginal] = useState('');
  const [currentTranslate, setCurrentTranslate] = useState('');

  const hide = () => {
    setIsOpen(false);
  };

  const open = () => {
    setIsOpen(true);
  };

  return (
    <ErrorModalContext.Provider
      value={{
        isOpen,
        open,
        hide,
        setCurrentOriginal,
        setCurrentTranslate,
        isAddFromErrorModal,
        setIsAddFromErrorModal,
        notChangedTranslation,
        setNotChangedTranslation
      }}
    >
      {children}
      <ErrorModal
        original={currentOriginal}
        translate={currentTranslate}
        isOpen={isOpen}
        hide={hide}
        submit={submit}
        isAddFromErrorModal={isAddFromErrorModal}
        setIsAddFromErrorModal={setIsAddFromErrorModal}
        notChangedTranslation={notChangedTranslation}
        setNotChangedTranslation={setNotChangedTranslation}
      />
    </ErrorModalContext.Provider>
  );
};

export const useErrorModalContext = () => useContext(ErrorModalContext);
