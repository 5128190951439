interface Answers {
  [key: string]: string[];
}

interface Values {
  [key: string]: string;
}

const getCorrectAnswers = (answers: Values, count: number) => {
  const correctKeys = Object.keys(answers).slice(0, count);

  return correctKeys.reduce((obj, key) => {
    obj[key] = answers[key];
    return obj;
  }, {});
};

const getIncorrectAnswers = (answers: Values, count: number, extraAnswers: string[] = []) => {
  const answersKeys = Object.keys(answers).slice(-count);
  const answersValues = Object.values(answers).slice(-count);
  const allValues = [...answersValues, ...extraAnswers].sort((a, b) => b.localeCompare(a));

  return answersKeys.reduce((result, key) => {
    const incorrectIndex = allValues.findIndex(value => value !== answers[key]);
    const [incorrectValue] = allValues.splice(incorrectIndex, 1);

    if (incorrectValue) result[key] = incorrectValue;

    return result;
  }, {});
};

const shuffleAnswers = (answers: Answers): Values => {
  return Object.keys(answers)
    .map(key => ({key, value: answers[key]}))
    .sort((a, b) => b.key.localeCompare(a.key))
    .reduce((obj, answer) => {
      obj[answer.key] = answer.value.find(Boolean);
      return obj;
    }, {});
};

export const prefillValues = (
  answers: Answers,
  extraAnswers?: string[],
  incorrectAnswersProportion = 2
): Values => {
  const shuffledAnswers = shuffleAnswers(answers);

  if (incorrectAnswersProportion === 0) return shuffledAnswers;

  const answerCount = Object.keys(answers).length;

  const incorrectAnswerCount = Math.floor((incorrectAnswersProportion / 3) * answerCount);

  const correctAnswerCount = answerCount - incorrectAnswerCount;

  const correctAnswers = getCorrectAnswers(shuffledAnswers, correctAnswerCount);

  const incorrectAnswers = getIncorrectAnswers(shuffledAnswers, incorrectAnswerCount, extraAnswers);

  return {...correctAnswers, ...incorrectAnswers};
};
