import {useSelector} from 'react-redux';

import {type AppState} from '../../store/interface';

const licenseTeacherIds = [
  296719, // for Educational License
  378313 // for 'Ministry of Digital Development, Communications and Mass Media'
];

// TODO: temp quick fix, remove me later
export const isSampleLicenceTeacher = (s: AppState): boolean =>
  s.user.role === 'teacher' && !!s.user.id && licenseTeacherIds.includes(s.user.id);

export function useIsSampleLicenseTeacher(): boolean {
  // TODO: temp crutch, remove me later
  return useSelector(isSampleLicenceTeacher);
}
