import {List, Map} from 'immutable';
import {type IntlShape} from 'react-intl';
import * as yup from 'yup';

import {
  type ImageLabelingCardJSON,
  type ImageLabelingJSON
} from 'store/exercise/player/widgets/ImageLabeling/interface';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';

import genKey from '../../../../../../components/Slate/utils/genKey';
import validationMessages from '../../i18n';
import {valueJSONFromText} from '../../../../../../components/Slate/utils';
import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../../player/interface';
import XWidgetRecord from '../../XWidgetRecord';
import XImageLabelingCardRecord from './XImageLabelingCardRecord';
import {type XImageLabelingProps} from './interface';
import {type CardSizeType} from '../../XWordPictureSet/XPictureSet/interface';
import {getObjectWithNewIds} from '../../getObjectWithNewIds';

class XImageLabelingRecord extends XWidgetRecord {
  public static minImagesCount: number = 1;

  public declare readonly answers: Map<string, string[]>;

  public declare readonly exampleAnswers: Map<string, string[]>;

  public declare readonly cards: List<XImageLabelingCardRecord>;

  constructor(raw: XImageLabelingProps) {
    super(raw);

    this.initValues({
      id: raw.id || genKey(),
      cards: this.createCards(raw),
      answers: Map(raw.answers),
      exampleAnswers: Map(raw.exampleAnswers)
    });
  }

  private createCards(raw: XImageLabelingProps) {
    if (List.isList(raw.cards)) {
      return raw.cards;
    }

    return List(
      (raw.cards as ImageLabelingCardJSON[]).map((card: ImageLabelingCardJSON) => {
        return new XImageLabelingCardRecord({
          id: card.id,
          imageId: card.imageId,
          example: card.example,
          editable: card.editable,
          placeholder: card.placeholder,
          cardSizeType: card.cardSizeType
        });
      })
    );
  }

  public toJSON(options?: WidgetToJSONOptions): ImageLabelingJSON {
    const {cards, answers, exampleAnswers} = this.getCardsAndAnswers(options);

    return {
      ...super.toJSON(options),
      answers,
      exampleAnswers,
      cards
    };
  }

  private getCardsAndAnswers(options?: WidgetToJSONOptions) {
    const cards = this.cards.map((c: XImageLabelingCardRecord) => c.toCardJSON()).toArray();
    const answers = this.answers.toJS();
    const exampleAnswers = this.exampleAnswers.toJS();

    if (options?.generateIdentifiers) {
      const objWithNewIds = getObjectWithNewIds({answers, cards, exampleAnswers});

      return {
        cards: objWithNewIds.cards,
        answers: objWithNewIds.answers,
        exampleAnswers: objWithNewIds.exampleAnswers
      };
    }

    return {cards, answers, exampleAnswers};
  }

  public static fromCards(cards: List<XImageLabelingCardRecord>): XImageLabelingRecord {
    return new XImageLabelingRecord({
      id: genKey(),
      type: WidgetType.IMAGE_LABELING,
      task: valueJSONFromText(''),
      answers: Map(),
      exampleAnswers: Map(),
      cards
    });
  }

  public get type() {
    return WidgetType.IMAGE_LABELING;
  }

  public get excerpt(): string {
    return `${this.task.document.text} ${this.cards
      .map((card: XImageLabelingCardRecord) => {
        const answers = card.example ? this.exampleAnswers : this.answers;
        const firstAnswer = answers.get(card.id, []).find(Boolean);

        return `[Image] ${firstAnswer || ''}`;
      })
      .join('; ')}.`.trim();
  }

  public schema(intl: IntlShape) {
    return yup.object({
      cards: yup
        .mixed()
        .test(
          'Image should not be blank',
          intl.formatMessage(validationMessages.BlankImages),
          (cards: List<XImageLabelingCardRecord>) =>
            cards.every((c: XImageLabelingCardRecord) => !!c.imageId)
        )
        .test(
          'Images count should be valid',
          intl.formatMessage(validationMessages.MinImagesCount, {
            counts: XImageLabelingRecord.minImagesCount
          }),
          (cards: List<XImageLabelingCardRecord>) =>
            cards.size >= XImageLabelingRecord.minImagesCount
        )
        .test(
          'Should contain not only examples',
          intl.formatMessage(validationMessages.NotOnlyExamples),
          (cards: List<XImageLabelingCardRecord>) =>
            cards.filter((card: XImageLabelingCardRecord) => card.example).size !== cards.size
        )
        .test(
          'Answer should not be blank',
          intl.formatMessage(validationMessages.BlankAnswers),
          (cards: List<XImageLabelingCardRecord>) =>
            cards.every((card: XImageLabelingCardRecord) => {
              const cardAnswers = card.example
                ? this.exampleAnswers.get(card.id, [])
                : this.answers.get(card.id, []);

              return (
                cardAnswers.length > 0 &&
                cardAnswers.every((answer: string) => answer.trim().length > 0)
              );
            })
        )
    });
  }

  public createNewCard() {
    return this.withMutations(d => {
      d.set('cards', d.cards.push(new XImageLabelingCardRecord({})));
    });
  }

  public updateCard(
    cardId: string,
    fields: Pick<XImageLabelingRecord, 'answers'> & Partial<XImageLabelingCardRecord>
  ) {
    const {answers, example, editable, placeholder} = fields;

    return this.withMutations(d => {
      if (example) {
        d.deleteIn(['answers', cardId]).setIn(['exampleAnswers', cardId], answers);
      } else {
        d.setIn(['answers', cardId], answers).deleteIn(['exampleAnswers', cardId]);
      }

      d.set(
        'cards',
        d.cards.map((card: XImageLabelingCardRecord) =>
          card.id === cardId ? card.merge({example, editable, placeholder}) : card
        )
      );
    });
  }

  public get media(): MediaMeta | undefined {
    const images = this.cards.map((c: XImageLabelingCardRecord) => c.imageId).toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }

  public moveCard(moveCardIndex: number, targetIndex: number): XImageLabelingRecord {
    const card = this.cards.get(moveCardIndex);
    const cards = this.cards.delete(moveCardIndex).insert(targetIndex, card);
    return this.set('cards', cards);
  }

  public rollBackCards(cards: List<XImageLabelingCardRecord>): XImageLabelingRecord {
    return this.set('cards', cards);
  }

  public changeCardSizeType(cardId: string, newCardSizeType: CardSizeType) {
    const index = this.cards.findIndex(item => item?.id === cardId);
    return this.setIn(['cards', index, 'cardSizeType'], newCardSizeType);
  }
}

decorate(XImageLabelingRecord, {
  answers: property(Map()),
  exampleAnswers: property(Map()),
  cards: property(List<XImageLabelingCardRecord>())
});
record()(XImageLabelingRecord);
export default XImageLabelingRecord;
