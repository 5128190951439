import React from 'react';
import NavItem from 'react-bootstrap/lib/NavItem';
import Overlay from 'react-bootstrap/lib/Overlay';
import {type WrappedComponentProps, injectIntl} from 'react-intl';

import Icon from 'components/Icon';

import {
  type DispatchContactsNavItemProps,
  type ContactsNavItemStateProps
} from '../containers/ContactsNavItem';
import SupportPopover from './SupportPopover';
import {headerMessages} from './headerMessages';

interface Props extends ContactsNavItemStateProps, DispatchContactsNavItemProps {
  isStudent: boolean;
}

class ContactsNavItem extends React.PureComponent<Props & WrappedComponentProps, {}> {
  private supportNavItem: NavItem;

  public render() {
    const {toggleContactsModal, contactsPopoverOpen, isMobile, intl} = this.props;
    return (
      <NavItem className="support" ref={this.getRef} onClick={this.toggleContactsPopover}>
        <Icon
          name="pc-support"
          size="lg"
          title={intl.formatMessage(headerMessages.HeaderSupportMessage)}
        />
        {isMobile && contactsPopoverOpen ? this.renderCloseButton() : null}
        <Overlay
          rootClose={true}
          placement="bottom"
          animation={false}
          container={this.supportNavItem}
          show={contactsPopoverOpen}
          onHide={this.hidePopover}
        >
          <SupportPopover
            isStudent={this.props.isStudent}
            showModalFunc={toggleContactsModal}
            hidePopover={this.hidePopover}
          />
        </Overlay>
      </NavItem>
    );
  }

  private toggleContactsPopover = () => {
    this.props.toggleContactsPopover(!this.props.contactsPopoverOpen);
  };

  private renderCloseButton = () =>
    this.props.isMobile ? (
      <button className="popover-close-button" onClick={this.hidePopover}>
        <Icon name="pc-close" />
      </button>
    ) : null;

  private hidePopover = () => {
    this.props.toggleContactsPopover(false);
  };

  private getRef = (el: NavItem) => el && (this.supportNavItem = el);
}

export default injectIntl(ContactsNavItem);
