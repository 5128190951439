import {fromJS, List, Map} from 'immutable';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';
import {Value} from '@englex/slate';

import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';
import {
  exerciseExcerptLength,
  exerciseTitleMaxLength,
  mediaSourceTitleMaxLength
} from 'config/static';

import type XWidgetRecord from './XWidgetRecord';
import xwidgetFactory from './xwidgetFactory';
import {
  type ExerciseJSON,
  type ExerciseMediaSource,
  type ExerciseMediaSourceJSON,
  type ExerciseMediaSources,
  type ExerciseMetaJSON,
  type MediaMeta,
  type WidgetJSON,
  WidgetType,
  type XExerciseToJsonOptions
} from '../../player/interface';
import validationMessages from './i18n';
import {type PartialCoursebook} from '../../../interface';
import XExerciseMetaRecord from './XExerciseMetaRecord';
import {documentNotEmpty} from './validation';
import {type XExerciseOwnership, type XExerciseProperties} from './interface';
import {valueFromText} from '../../../../components/Slate/utils';
import genKey from '../../../../components/Slate/utils/genKey';
import {
  ValidationGrammarTitleError,
  ValidationMediaSourceError,
  ValidationTitleError,
  ValidationWidgetError
} from './customErrors';
import {isDocumentEmpty} from '../../../../components/Slate/utils/documentNotEmpty';

const buildExcerpt = (excerpt: string, widgets: List<XWidgetRecord<WidgetJSON>>): string => {
  let result = excerpt;
  widgets.forEach((w: XWidgetRecord): void | false => {
    if (result.length >= exerciseExcerptLength) {
      return false;
    }
    result += ` ${w.excerpt}`;
  });
  return result;
};

const Record = recordBase()(BaseRecord);

const isGrammarExercise = (
  widgets: List<XWidgetRecord<WidgetJSON>> | Array<WidgetJSON>
): boolean => {
  const filtered = widgets.filter(
    (w?: XWidgetRecord<WidgetJSON> | WidgetJSON) => w?.type === WidgetType.GRAMMAR_RULES
  );
  return filtered instanceof Array ? filtered.length !== 0 : filtered.size !== 0;
};

class XExerciseRecord extends Record implements XExerciseProperties {
  public declare readonly id: string | null;

  public declare readonly title: string;

  public declare readonly isNew?: boolean;

  public declare readonly grammarTitle?: Value | null;

  public declare readonly ownership: XExerciseOwnership;

  public declare readonly coursebooks: PartialCoursebook[];

  public declare readonly meta: XExerciseMetaRecord;

  public declare readonly grammar?: List<number>;

  public declare readonly widgets: List<XWidgetRecord<WidgetJSON>> & {toJSON: () => WidgetJSON[]};

  public declare readonly mediaSources: ExerciseMediaSources & {
    toJSON: () => ExerciseMediaSourceJSON[];
  };

  public static fromJSON(json: ExerciseJSON): XExerciseRecord {
    return new this(json);
  }

  public schema(intl: IntlShape) {
    return yup.object({
      title: yup.mixed().test({
        test: (title: string | null) => {
          if (title !== null && title.length > exerciseTitleMaxLength) {
            const errorMessage = intl.formatMessage(validationMessages.ExerciseTitleTooLong, {
              maxCharCount: exerciseTitleMaxLength
            });
            return new ValidationTitleError(errorMessage, title);
          }

          return true;
        }
      }),
      grammarTitle: yup.mixed().test({
        test: (v: Value | null) => {
          if (v !== null && isDocumentEmpty(v.document)) {
            const errorMessage = intl.formatMessage(validationMessages.GrammarTitleNotEmpty);
            return new ValidationGrammarTitleError(errorMessage, v.document.getText());
          }

          return true;
        }
      }),
      widgets: this.widgetSchema(intl)
        .test({
          test: widgets => {
            if (widgets && Object.keys(widgets).length === 0) {
              const message = intl.formatMessage(validationMessages.MinWidgetsCount);
              return new ValidationWidgetError(message);
            }

            return true;
          }
        })
        .test({
          test: widgets => {
            const isNotEmpty = widgets && Object.keys(widgets).length !== 0;

            const everyComments =
              isNotEmpty &&
              Object.values(widgets).every(
                (w: XWidgetRecord<WidgetJSON>) =>
                  w.type === WidgetType.NOTE || w.type === WidgetType.COMMENT || w.displayButton
              );

            if (everyComments) {
              const message = intl.formatMessage(validationMessages.WidgetsNotOnlyNotes);
              return new ValidationWidgetError(message);
            }

            return true;
          }
        }),
      mediaSources: this.mediaSourcesSchema(intl)
    });
  }

  public get excerpt(): string {
    let excerpt: string = this.title || '';
    excerpt = buildExcerpt(
      excerpt,
      this.widgets.filter(
        (w: XWidgetRecord) => w.type !== WidgetType.COMMENT
      ) as List<XWidgetRecord>
    );
    if (excerpt.length >= exerciseExcerptLength) {
      return excerpt.substr(0, exerciseExcerptLength).trim();
    }
    excerpt = buildExcerpt(
      excerpt,
      this.widgets.filter(
        (w: XWidgetRecord) => w.type === WidgetType.COMMENT
      ) as List<XWidgetRecord>
    );
    return excerpt.length >= exerciseExcerptLength
      ? excerpt.substr(0, exerciseExcerptLength).trim()
      : excerpt.trim();
  }

  public get isGrammarExercise(): boolean {
    return isGrammarExercise(this.widgets);
  }

  constructor(raw: ExerciseJSON) {
    super();
    const {
      id,
      title,
      isNew,
      widgets: rawWidgets,
      ownership,
      meta,
      grammar,
      coursebooks,
      mediaSources
    } = raw;
    this.initValues({
      id,
      title,
      isNew,
      grammarTitle: isGrammarExercise(rawWidgets)
        ? raw.grammarTitle
          ? Value.fromJSON(raw.grammarTitle)
          : valueFromText()
        : null,
      widgets: List.isList(rawWidgets)
        ? rawWidgets
        : List<XWidgetRecord<WidgetJSON>>(
            (rawWidgets as WidgetJSON[]).map((xwidgetProps: WidgetJSON) =>
              xwidgetFactory(xwidgetProps)
            )
          ),
      meta: new XExerciseMetaRecord(
        (meta as ExerciseMetaJSON) || {
          categories: [],
          levels: []
        }
      ),
      grammar: grammar ? List(grammar.map(option => option.id)) : undefined,
      ownership,
      coursebooks,
      mediaSources: mediaSources
        ? fromJS(mediaSources.map(source => ({...source, id: genKey()})))
        : List([])
    });
  }

  public toJSON(options: XExerciseToJsonOptions = {}): ExerciseJSON {
    const title = this.title && this.title.trim();
    return {
      id: this.id,
      title: title && title.length ? title : null,
      grammarTitle: this.isGrammarExercise ? this.grammarTitle?.toJSON() : null,
      excerpt: this.excerpt,
      widgets: this.widgets
        .map((w: XWidgetRecord) => w.toJSON(options))
        .toList()
        .toJS(),
      meta: this.meta.toJSON(),
      grammar: this.grammar?.toJS().map((id: number) => ({id})),
      mediaSources: this.mediaSources.size
        ? this.mediaSources.map(source => source?.delete('id')).toJS()
        : null
    };
  }

  protected widgetSchema(intl: IntlShape) {
    const firstWidgetIndex = this.widgets.findIndex(
      (widget: XWidgetRecord) =>
        widget.type !== WidgetType.NOTE &&
        widget.type !== WidgetType.COMMENT &&
        !widget.displayButton
    );

    return yup.object(
      this.widgets.reduce((schema, widget: XWidgetRecord, index: number) => {
        return {
          ...schema,
          [widget.id]: yup.mixed().test({
            test: async () => {
              const maybeError = await this.validateWidget(
                intl,
                widget,
                index === firstWidgetIndex
              );

              if (ValidationWidgetError.isInstanceof(maybeError)) {
                return maybeError;
              }

              return true;
            }
          })
        };
      }, {})
    );
  }

  protected async validateWidget(
    intl: IntlShape,
    widget: XWidgetRecord,
    shouldValidateAsFirstWidget: boolean
  ) {
    try {
      const schema = shouldValidateAsFirstWidget
        ? widget.schema(intl).concat(this.getFirstWidgetSchema(intl))
        : widget.schema(intl);

      return await schema.validate(widget);
    } catch (error) {
      return ValidationWidgetError.isInstanceof(error)
        ? error
        : new ValidationWidgetError(error.message, widget);
    }
  }

  protected mediaSourcesSchema(intl: IntlShape) {
    return yup.array().of(
      yup.mixed().test({
        test: async mediaSource => {
          const maybeError = await this.validateMediaSource(intl, mediaSource);

          if (ValidationMediaSourceError.isInstanceof(maybeError)) {
            return maybeError;
          }

          return true;
        }
      })
    );
  }

  protected async validateMediaSource(
    intl: IntlShape,
    mediaSource: ExerciseMediaSourceJSON & {id: string}
  ) {
    try {
      const schema = yup.object().shape({
        title: yup
          .string()
          .required(intl.formatMessage(validationMessages.MediaResourceTitleNotEmpty))
          .max(
            mediaSourceTitleMaxLength,
            intl.formatMessage(validationMessages.MediaResourceTitleMaxLength, {
              length: mediaSourceTitleMaxLength
            })
          ),
        url: yup
          .string()
          .required(intl.formatMessage(validationMessages.MediaResourceUrlNotEmpty))
          .url(intl.formatMessage(validationMessages.MediaResourceUrlNotValid))
      });

      return await schema.validate(mediaSource);
    } catch (error) {
      return new ValidationMediaSourceError(error.message, mediaSource);
    }
  }

  public get media(): MediaMeta | undefined {
    const media = this.widgets.reduce((r: MediaMeta, w: XWidgetRecord) => {
      if (w.media) {
        return r.merge(w.media);
      }
      return r;
    }, Map());
    return media.size ? media : undefined;
  }

  private getFirstWidgetSchema(intl: IntlShape) {
    return yup.object({
      task: yup
        .mixed()
        .test(
          'Task not empty',
          intl.formatMessage(validationMessages.FirstWidgetTaskNotEmpty),
          (v: Value) => documentNotEmpty(v.document)
        )
    });
  }
}

decorate(XExerciseRecord, {
  id: property(null),
  title: property(''),
  isNew: property(),
  grammarTitle: property(null),
  ownership: property({}),
  coursebooks: property([]),
  meta: property(new XExerciseMetaRecord({categories: [], levels: []})),
  grammar: property(),
  widgets: property(List<XWidgetRecord<WidgetJSON>>()),
  mediaSources: property(List<ExerciseMediaSource>())
});
record()(XExerciseRecord);
export default XExerciseRecord;
