import React, {type FC, useCallback, useEffect, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import {Transition} from 'react-transition-group';

import {type UnitContents, type UnitInstanceContents} from '../interface';
import {transition} from '../static';
import {ContentsHeader} from '../../ContentsHeader/ContentsHeader';
import './Contents.scss';

interface Props {
  contents?: UnitContents | UnitInstanceContents | string[];
  listIsOpened: boolean;
  listToggleBatched: boolean;
  toggleList(): void;
  renderItems: (closeSelf: () => void) => JSX.Element;
  renderSearch?: () => React.ReactElement;
}

export function Contents(props: Props) {
  if (!props.contents || !props.listIsOpened) return null;
  return <ContentsLoaded {...props} />;
}

const ContentsLoaded: FC<Props> = ({listToggleBatched, toggleList, renderSearch, renderItems}) => {
  const [expanded, setExpanded] = useState(false);

  const closeSelf = useCallback(() => setExpanded(false), []);

  useEffect(() => setExpanded(true), []);
  useEffect(() => {
    if (listToggleBatched) setExpanded(false);
  }, [listToggleBatched]);

  return (
    <Transition in={expanded} timeout={transition.timeout} onExited={toggleList}>
      {state => (
        <div
          className="unit-contents"
          style={{...transition.defaultStyle, ...transition.transitionStyles[state]}}
        >
          <ContentsHeader onClose={closeSelf} />
          {renderSearch && <div className="unit-contents-search">{renderSearch()}</div>}
          <div className="unit-contents-list-wrapper">
            <Scrollbars autoHide={true}>
              <ul className="unit-contents-list">{renderItems(closeSelf)}</ul>
            </Scrollbars>
          </div>
        </div>
      )}
    </Transition>
  );
};
