import {useMemo, useRef} from 'react';

import {type ServerStudentTeacher, type StudentTeachers} from 'store/interface';
import {requestStudentTeachers} from 'store/studentTeachers/requests';
import {useApiRequest} from 'hooks/rest/useApiRequest';

export const useStudentTeachers = (
  successHandler: (studentTeachers: ServerStudentTeacher[]) => void,
  shouldLoad: boolean,
  isStudent: boolean,
  classroomStudentTeachers?: StudentTeachers
) => {
  const successHandlerRef = useRef<typeof successHandler>();
  successHandlerRef.current = successHandler;

  const request = useMemo(
    () =>
      classroomStudentTeachers || !shouldLoad
        ? null
        : requestStudentTeachers(isStudent ? 'teacher' : 'student'),
    [classroomStudentTeachers, isStudent, shouldLoad]
  );

  return useApiRequest(request, successHandlerRef.current);
};
