import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {fixedScales, Paint} from '@englex/paint';
import {usePaint} from '@englex/paint-react';

interface Props {
  scale?: number;
  setScale?: (scale: number) => void;
  setFitToWidth?: (fitToWidth: boolean) => void;
}

const [min, max] = [fixedScales[0], fixedScales[fixedScales.length - 1]];

export const ScaleSlider: FC<Props> = ({scale, setFitToWidth, setScale}) => {
  const paint = usePaint();

  const [sliding, setSliding] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const onMouseDown = useCallback(() => setSliding(true), []);

  const progress = (((scale || paint.scale) - 0.5) / 1.5) * 100;
  useEffect(() => {
    const onMouseUp = () => setSliding(false);
    if (sliding) document.addEventListener('mouseup', onMouseUp);
    return () => document.removeEventListener('mouseup', onMouseUp);
  }, [sliding]);

  useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      if (!ref.current) return;
      const {pageX} = e;
      const {left, right} = ref.current.getBoundingClientRect();
      if (pageX <= left) {
        scale !== min && (setScale ? setScale(min) : Paint.setScale(paint, min));
      } else if (pageX > right) {
        scale !== max && (setScale ? setScale(max) : Paint.setScale(paint, max));
      } else {
        const scale = min + Math.round(((pageX - left) / (right - left)) * (max - min) * 10) / 10;
        setScale ? setScale(scale) : Paint.setScale(paint, scale);
      }
      setFitToWidth?.(false);
    };
    if (sliding) document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
  }, [paint, scale, setFitToWidth, setScale, sliding]);
  return (
    <div className="scale-slider" onMouseDown={onMouseDown}>
      <div className="scale-slider-max" ref={ref}>
        <div
          className="scale-slider-progress"
          style={{
            width: progress > 0 ? `${Math.min(progress, 100)}%` : `${Math.max(progress, 0)}%`
          }}
        >
          <div className="scale-slider-circle" />
        </div>
      </div>
    </div>
  );
};
