import {fromJS, List} from 'immutable';

import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {
  type ExerciseInstance,
  type ExerciseInstanceComment,
  type ExerciseInstanceCommentMap
} from 'store/interface';
import {type DataMap} from 'components/Slate/interface';

import widgetFactory from '../widgetFactory';
import {
  type ExerciseJSON,
  type ExerciseLocation,
  type ExerciseMediaSourceJSON,
  type ExerciseMediaSources,
  type WidgetJSON,
  type WidgetProperties,
  WidgetType
} from '../interface';
import type WidgetRecord from '../WidgetRecord';
import {type ExerciseProperties} from './interface';
import {commentFactory} from '../../../../components/XPlayer/components/ExerciseFooter/ExerciseComments/commentFactory';
import {genKey} from '../../../../components/Slate/utils';

const Record = recordBase()(BaseRecord);
class ExerciseRecord extends Record implements ExerciseProperties {
  public declare readonly id: string;
  public declare readonly title: string | null;
  public declare readonly widgets: List<WidgetProperties> & {toJSON: () => WidgetJSON[]};
  public declare readonly mediaSources: ExerciseMediaSources & {
    toJSON: () => ExerciseMediaSourceJSON[];
  };
  public declare readonly completedAt: Date | undefined;
  public declare readonly mainId?: string;
  public declare readonly additionalAvailable?: number;
  public declare readonly homeworkId?: string;
  public declare readonly location?: ExerciseLocation;
  public declare readonly grammar?: List<{id: number}>;
  public declare readonly comments?: List<DataMap<ExerciseInstanceCommentMap>>;

  public static fromJSON(json: ExerciseJSON): ExerciseRecord {
    return new this(json);
  }

  constructor(raw: ExerciseJSON | ExerciseProperties | ExerciseInstance) {
    super();

    this.initValues({
      id: raw.id,
      title: raw.title,
      widgets: List.isList(raw.widgets)
        ? raw.widgets
        : List<WidgetRecord>(
            (raw.widgets as WidgetJSON[]).map((widgetProps: WidgetJSON) =>
              widgetFactory(widgetProps)
            )
          ),
      mediaSources: raw.mediaSources
        ? fromJS(
            (raw.mediaSources as ExerciseMediaSources).map(source => ({
              ...source,
              id: genKey()
            }))
          )
        : List([]),
      completedAt: raw.completedAt ? new Date(raw.completedAt) : undefined,
      mainId: raw.mainId,
      additionalAvailable: raw.additionalAvailable,
      homeworkId: raw.homeworkId,
      location: raw.location,
      grammar: List.isList(raw.grammar)
        ? raw.grammar
        : raw.grammar
          ? List<{id: number}>(raw.grammar)
          : undefined,
      comments: List.isList(raw.comments)
        ? raw.comments
        : raw.comments
          ? List<DataMap<ExerciseInstanceCommentMap>>(
              (raw.comments as ExerciseInstanceComment[]).map(commentFactory)
            )
          : undefined
    });
  }

  public get isAutoChecked() {
    return !!this.widgets.find((w: WidgetProperties) => w.isAutoChecked);
  }

  public get headWidgetIndex() {
    const isOnlyNotes = this.widgets.every(
      w => w?.type === WidgetType.COMMENT || w?.type === WidgetType.NOTE
    );

    if (isOnlyNotes) {
      return 0;
    }

    return this.widgets.findIndex(
      w => w?.type !== WidgetType.COMMENT && w?.type !== WidgetType.NOTE && !w?.displayButton
    );
  }
}

decorate(ExerciseRecord, {
  id: property(''),
  title: property(''),
  widgets: property(List<WidgetProperties>()),
  mediaSources: property(List<ExerciseMediaSources>()),
  completedAt: property(undefined),
  mainId: property(undefined),
  additionalAvailable: property(undefined),
  homeworkId: property(undefined),
  location: property(undefined),
  grammar: property(undefined),
  comments: property(undefined)
});

record()(ExerciseRecord);
export default ExerciseRecord;
