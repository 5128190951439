import React from 'react';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import DropDown, {type DropDownItems, type DropDownProps} from 'components/DropDown';

import './SharingButton.scss';

interface Props {
  readonly items: DropDownItems;
  readonly onChange: (type: string) => void;
  readonly isHidden?: boolean;
  readonly props?: Partial<DropDownProps>;
}

export function SharingButton({items, isHidden, onChange, props}: Props) {
  const intl = useIntl();

  if (isHidden) {
    return null;
  }

  return (
    <DropDown
      items={items}
      value={
        <span title={intl.formatMessage({id: 'XPlayer.Grammar.GetLink'})}>
          <Icon name="chain" tag="i" />
        </span>
      }
      onChange={onChange}
      toggleClassName="controls-btn btn-circle btn btn-success"
      placement="topLeft"
      trigger={['click']}
      noCaret={true}
      className="sharing-button"
      {...props}
    />
  );
}
