import {type Id, type Resource, type Type} from '../interface';
import {
  ACTIVATE_RESOURCE,
  DEACTIVATE_RESOURCE,
  LOAD_RESOURCE,
  LOAD_RESOURCES,
  REGISTER_PROVIDER,
  REGISTER_RESOURCE,
  UNREGISTER_PROVIDER,
  UNREGISTER_RESOURCE
} from './actionTypes';

const resourceAction = (actionType: string) => (providerId: Id, resourceId: Id, type: Type) => ({
  type: actionType,
  providerId,
  resourceId,
  resourceType: type
});

export const activateResource = resourceAction(ACTIVATE_RESOURCE);
export const registerResource = resourceAction(REGISTER_RESOURCE);
export const unregisterResource = resourceAction(UNREGISTER_RESOURCE);

export const deactivateResource = () => ({type: DEACTIVATE_RESOURCE});

export const loadResource = (providerId: Id, data: Resource, type: Type) => ({
  type: LOAD_RESOURCE,
  providerId,
  data,
  resourceType: type
});

export const loadResources = (providerId: Id, data: Resource[], type: Type) => ({
  type: LOAD_RESOURCES,
  providerId,
  data,
  resourceType: type
});

export const registerProvider = (providerId: Id, node: Element | null) => ({
  type: REGISTER_PROVIDER,
  providerId,
  node
});

export const unregisterProvider = (providerId: Id) => ({type: UNREGISTER_PROVIDER, providerId});
