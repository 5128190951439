import React from 'react';
import {Route} from 'react-router-dom';

import DashboardLogin from 'routes/Login/dashboard';
import {DevLogin} from 'routes/DevLogin';
import MainLayout from 'layouts/MainLayout';
import ClassRoomLayout from 'layouts/ClassRoom';
import {AuthenticatedLayout} from 'layouts/AuthenticatedLayout';
import {ErrorBoundary} from 'components/ErrorBoundary';

import {PageNotFound} from './PageNotFound';
import SandboxRoute from './Sandbox';
import LibraryRoute from './Library';
import {XPlayerRoute} from './XPlayer';
import ClassRoomRoute from './ClassRoom';
import DictionaryRoute from './Dictionary';
import HomeworkRoute from './Homework';
import {HomePage} from './Home/HomePage';
import {LoginRoute} from './Login/LoginRoute';
import {LogoutRoute} from './Login/LogoutRoute';
import {LoginLayout} from '../layouts/Login/LoginLayout';

const isSandboxEnabled = import.meta.env.REACT_APP_SANDBOX_ENABLED === 'true';

const AppRoutes = () => (
  <Route path="*" ErrorBoundary={ErrorBoundary}>
    <Route path={`login`} element={<LoginLayout />}>
      <Route index={true} element={<LoginRoute />} />
    </Route>

    <Route path={`logout`} element={<LogoutRoute />} />
    <Route path={`login/:token/:uri?`} element={<DashboardLogin />} />
    <Route path={`dev-login/:token/*?`} element={<DevLogin />} />

    <Route path="*" element={<AuthenticatedLayout />}>
      <Route path="xplayer/*" element={<XPlayerRoute />} />

      {isSandboxEnabled ? <Route path="sandbox/*" element={<SandboxRoute />} /> : null}

      <Route element={<MainLayout />}>
        <Route index={true} element={<HomePage />} />

        <Route path="library/*" element={<LibraryRoute />} />

        <Route path="room/*" element={<ClassRoomLayout />}>
          <Route path="*" element={<ClassRoomRoute />} />
        </Route>

        <Route path="dictionary/*" element={<ClassRoomLayout />}>
          <Route path="*" element={<DictionaryRoute />} />
        </Route>

        <Route path="homework/*" element={<ClassRoomLayout />}>
          <Route path="*" element={<HomeworkRoute />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Route>
    </Route>
  </Route>
);

export default AppRoutes;
