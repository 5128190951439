import React, {
  type ChangeEvent,
  type FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {useFormik} from 'formik';
import FormControl, {type FormControlProps} from 'react-bootstrap/lib/FormControl';
import Button from 'react-bootstrap/lib/Button';
import {Transition} from 'react-transition-group';
import classNames from 'classnames';
import {FormattedMessage, useIntl} from 'react-intl';
import * as Yup from 'yup';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';
import {pipe, trim, collapseSpaces, removeHidden} from '@englex/utils';

import {type AxiosRequestError} from 'services/axios/interface';
import FormControlTooltip from 'components/Slate/SlateEditor/plugins/widget/GapFill/forms/FormControlTooltip';
import {error} from 'components/toastr';

import {bottomSlideOutTransition} from '../utils';
import {PronunciationTrigger} from './PronunciationTrigger';
import {EditEntryActionsContext, EditEntryStateContext} from '../contexts/entry/EditEntryContext';
import {DictionaryPronunciationModal} from './DictionaryPronunciationModal';
import {clearEdit, clearPronunciation} from '../contexts/entry/actions';
import {showModal} from '../contexts/actions';
import {type DictionaryEntryInstance} from '../interface';
import {PronunciationTooltip} from './SoundPlayer';
import './EditEntryForm.scss';

interface Values {
  original: string;
  translation: string;
}

interface Props {
  isCompact?: boolean;
  isSidebar?: boolean;
  reset?: () => void;
}

const max = 255;

const getInitialValues = (editedEntry?: DictionaryEntryInstance): Values => {
  if (!editedEntry) return {original: '', translation: ''};
  return {
    original: editedEntry.dictionaryEntry.original,
    translation: editedEntry.dictionaryEntry.translation
  };
};

export const EditEntryForm: FC<Props> = ({isCompact = false, isSidebar, reset}) => {
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const {dispatch, submitForm: onSubmit} = useContext(EditEntryActionsContext);
  const {showPronunciationModal, formListId, editedEntry, pronunciationId, phoneticSpelling} =
    useContext(EditEntryStateContext);
  const isMounted = useIsMounted();
  const [playerProps, setPlayerProps] = useState<{
    soundId?: number | null;
    phoneticSpelling?: string | null;
  }>(() =>
    editedEntry
      ? {phoneticSpelling: editedEntry.phoneticSpelling, soundId: editedEntry.pronunciationId}
      : {}
  );
  const {
    errors,
    handleChange,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setFieldTouched,
    setSubmitting
  } = useFormik<Values>({
    initialValues: getInitialValues(editedEntry),
    validateOnMount: false,
    validationSchema: Yup.object().shape({
      original: Yup.string()
        .transform(pipe(trim, removeHidden, collapseSpaces))
        .required(intl.formatMessage({id: 'Dictionary.Entry.Validation.BlankOriginal'}))
        .test(
          'should not contain cyrillic characters',
          intl.formatMessage({id: 'Dictionary.CyrillicForbidden'}),
          (original: string) => !/[а-яА-ЯЁё]+/.test(original)
        )
        .max(max, intl.formatMessage({id: 'Dictionary.Entry.Validation.MaxOriginal'}, {max})),
      translation: Yup.string()
        .transform(pipe(trim, removeHidden, collapseSpaces))
        .required(intl.formatMessage({id: 'Dictionary.Entry.Validation.BlankTranslation'}))
        .max(max, intl.formatMessage({id: 'Dictionary.Entry.Validation.MaxTranslation'}, {max}))
    }),
    onSubmit: values => {
      onSubmit(values, formListId)
        .finally(() => {
          if (isMounted.current) {
            setSubmitting(false);
          }
        })
        .then(() => {
          if (isMounted.current) {
            setOpen(false);
          }
        })
        .catch((e: AxiosRequestError) => {
          if (e.error.response?.status === 409)
            error('', intl.formatMessage({id: 'Dictionary.AlreadyExistsInDictionary'}));
        });
    }
  });

  const smallScreen = useMediaQuery(767);
  const originalRef = useRef<HTMLInputElement>();

  const transitionObj = useMemo(
    () => bottomSlideOutTransition(300, isCompact || smallScreen ? 105 : 40),
    [isCompact, smallScreen]
  );

  const onExited = useCallback(() => dispatch(clearEdit()), [dispatch]);

  const onChange = useCallback(
    (e: ChangeEvent<FormControlProps>) => {
      e.currentTarget?.name && setFieldTouched(e.currentTarget.name);
      handleChange(e);
    },
    [handleChange, setFieldTouched]
  );

  useEffect(() => {
    if (editedEntry?.dictionaryEntry.original !== values.original) {
      setPlayerProps({});
      dispatch(clearPronunciation());
    }
  }, [dispatch, editedEntry, values.original]);

  useEffect(() => {
    if (pronunciationId || phoneticSpelling) {
      setPlayerProps({soundId: pronunciationId, phoneticSpelling: phoneticSpelling});
    }
  }, [pronunciationId, phoneticSpelling]);

  useEffect(() => {
    setOpen(true);
    const timeout = setTimeout(() => {
      originalRef.current?.focus();
    }, 300);
    return () => {
      clearTimeout(timeout);
      dispatch(clearEdit());
    };
  }, [dispatch]);

  useEffect(() => () => reset && reset(), [reset]);

  return (
    <Transition timeout={transitionObj.timeout} in={open} onExited={onExited}>
      {state => (
        <div
          className="create-dictionary-entry-form-wrapper"
          style={{...transitionObj.defaultStyle, ...transitionObj.transitionStyles[state]}}
        >
          <form
            onSubmit={handleSubmit}
            className={classNames('create-dictionary-entry-form', {
              'is-compact': isCompact,
              open
            })}
          >
            <FormControlTooltip
              state={errors.original && touched.original && open ? 'error' : null}
              content={errors.original}
            >
              <FormControl
                inputRef={node => {
                  originalRef.current = node;
                }}
                bsSize="sm"
                type="text"
                name="original"
                value={values.original}
                onChange={onChange}
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: 'XEditorXWidget.Vocabulary.OriginalPlaceholder'
                })}
              />
              <PronunciationTooltip {...playerProps} inError={!!errors.original} />
            </FormControlTooltip>
            <FormControlTooltip
              state={errors.translation && touched.translation && open ? 'error' : null}
              content={errors.translation}
            >
              <FormControl
                bsSize="sm"
                type="text"
                name="translation"
                value={values.translation}
                onChange={onChange}
                autoComplete="off"
                placeholder={intl.formatMessage({
                  id: 'XEditorXWidget.Vocabulary.TranslationPlaceholder'
                })}
              />
            </FormControlTooltip>
            <PronunciationTrigger
              disabled={!values.original || !!errors.original}
              onClick={() => dispatch(showModal())}
            />
            <div className="actions">
              {!isSidebar && (
                <Button bsSize="sm" onClick={() => setOpen(false)} disabled={isSubmitting}>
                  <FormattedMessage id="Common.Cancel" />
                </Button>
              )}
              <Button
                bsSize="sm"
                bsStyle="primary"
                type="submit"
                disabled={!!(isSubmitting || errors.original || errors.translation)}
              >
                {editedEntry ? (
                  <FormattedMessage id="Common.Save" />
                ) : (
                  <FormattedMessage id="Common.Create" />
                )}
              </Button>
            </div>
          </form>
          {showPronunciationModal && <DictionaryPronunciationModal original={values.original} />}
        </div>
      )}
    </Transition>
  );
};
