import React, {type FC} from 'react';
import classNames from 'classnames';

import './BreadcrumbsPanel.scss';

interface Props {
  className?: string;
}

const BreadcrumbsPanel: FC<Props> = ({children, className}) => (
  <div className={classNames('breadcrumbs-panel', [className])}>{children}</div>
);

export default BreadcrumbsPanel;
