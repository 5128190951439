import {genKey} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type PictureSetCardJSON} from 'store/exercise/player/widgets/WordPictureSet/PictureSet/interface';

import XWordPictureSetBaseCardRecord from '../XWordPictureSetBaseCardRecord';
import {type XPictureSetCardProperties, type XPictureSetCardProps} from './interface';

class XPictureSetCardRecord
  extends XWordPictureSetBaseCardRecord
  implements XPictureSetCardProperties
{
  public declare readonly id: string;

  public declare readonly image: number;

  constructor(raw: XPictureSetCardProps) {
    super(raw);
    this.initValues({
      image: raw.image || ''
    });
  }

  public toJSON(): PictureSetCardJSON {
    return {
      ...super.toJSON(),
      image: this.image
    };
  }
}

decorate(XPictureSetCardRecord, {
  id: property(genKey()),
  image: property('')
});
record()(XPictureSetCardRecord);
export default XPictureSetCardRecord;
