import {produce, immerable} from 'immer';
import {Value} from '@englex/slate';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';

import {IntroTheme} from 'common/enums';

import {type UnitIntroJSON} from '../interface';
import {valueJSONFromText} from '../../components/Slate/utils';
import {contentExcerpt} from '../exercise/editor/widgets/excerpt';
import {introMessages} from '../../routes/Library/XIntroPage/messages';
import {documentNotEmpty} from '../exercise/editor/widgets/validation';

export class IntroRecord {
  [immerable] = true;

  theme: IntroTheme;
  content: Value;
  image?: number;

  static create() {
    return new IntroRecord({
      data: {
        theme: IntroTheme.Basic,
        content: valueJSONFromText('')
      }
    });
  }

  constructor(raw: UnitIntroJSON) {
    this.theme = raw.data.theme;
    this.content = Value.fromJSON(raw.data.content);
    this.image = raw.coverId;
  }

  public toJSON() {
    return {
      coverId: this.image!,
      data: {
        theme: this.theme,
        content: this.content.toJSON()
      }
    };
  }

  public schema(intl: IntlShape) {
    return yup.object({
      image: yup
        .mixed()
        .test(
          'Background should not be empty',
          intl.formatMessage(introMessages.BackgroundImageEmpty),
          (image: number | undefined) => Boolean(image)
        ),
      content: yup
        .mixed()
        .test(
          'Content should not be empty',
          intl.formatMessage(introMessages.ContentEmpty),
          (value: Value) => documentNotEmpty(value.document)
        )
    });
  }

  public validate(intl: IntlShape) {
    return this.schema(intl).validate(this);
  }

  public get excerpt(): string {
    return contentExcerpt(this.content).trim();
  }

  public change(data: Partial<IntroRecord>) {
    return produce(this, draft => {
      for (const prop in data) {
        if (data.hasOwnProperty(prop) && draft.hasOwnProperty(prop)) {
          draft[prop] = data[prop];
        }
      }
    });
  }
}
