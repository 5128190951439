import {type Dispatch, type Middleware} from 'redux';

import {NAVIGATE, type NavigateAction, type Router} from './interface';

type CreateRouterMiddlewareOptions = {
  getRouter: () => Router;
  passThroughNavigateAction?: boolean;
};

export const createRouterMiddleware =
  ({getRouter, passThroughNavigateAction}: CreateRouterMiddlewareOptions): Middleware =>
  () =>
  (next: Dispatch) =>
  (action: NavigateAction) => {
    if (action.type !== NAVIGATE) {
      return next(action);
    }

    const method = action.payload.method;
    const args = action.payload.args;

    const router = getRouter();

    const result = passThroughNavigateAction ? next(action) : undefined;

    switch (method) {
      case 'push': {
        const [to, opts] = args;
        router.navigate(to, {...opts, replace: false});
        break;
      }
      case 'replace': {
        const [to, opts] = args;
        router.navigate(to, {...opts, replace: true});

        break;
      }
      case 'go':
        const [delta] = args;
        router.navigate(delta);
        break;
    }

    return result;
  };
