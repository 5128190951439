export const CLOSE_MODAL = 'CLOSE_MODAL';
export const RESET = 'RESET';
export const CLEAR_EDIT = 'CLEAR_EDIT';
export const TOGGLE_FORM = 'TOGGLE_FORM';
export const CLOSE_FORM = 'CLOSE_FORM';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SUBMIT_PRONUNCIATION = 'SUBMIT_PRONUNCIATION';
export const CLEAR_PRONUNCIATION = 'CLEAR_PRONUNCIATION';
export const ADD_ENTRY_TO_LIST = 'ADD_ENTRY_TO_LIST';
export const SET_ENTRY_TO_EDIT = 'SET_ENTRY_TO_EDIT';
export const CLEAR_EDITED_ENTRY = 'CLEAR_EDITED_ENTRY';
export const SET_DICTIONARY_ENTRY_TO_DELETE = 'SET_DICTIONARY_ENTRY_TO_DELETE';
export const CLEAR_DELETE_ENTRY = 'CLEAR_DELETE_ENTRY';
export const ENTER_SELECTION_MODE = 'ENTER_SELECTION_MODE';
export const EXIT_SELECTION_MODE = 'EXIT_SELECTION_MODE';
export const SELECT_ENTRIES = 'SELECT_ENTRIES';
export const DESELECT_ENTRIES = 'DESELECT_ENTRIES';
export const DESELECT_ALL = 'DESELECT_ALL';
export const SELECT_ALL = 'SELECT_ALL';
export const SET_SELECTION_ACTION = 'SET_SELECTION_ACTION';

export const SET_LIST_ENTRY_TO_DELETE = 'SET_LIST_TO_DELETE';
export const SET_LIST_TO_DELETE = 'SET_LIST_TO_DELETE';
export const SET_LIST_TO_EDIT = 'SET_LIST_TO_EDIT';
