import {type Action} from 'redux';

import {
  LOAD_RESOURCE,
  LOAD_RESOURCES,
  REGISTER_PROVIDER,
  REGISTER_RESOURCE,
  UNREGISTER_PROVIDER,
  UNREGISTER_RESOURCE
} from './actionTypes';
import {
  type Id,
  type LoadResourceAction,
  type LoadResourcesAction,
  type ProviderAction,
  type ProviderShape,
  type RegisterProviderAction,
  type Resource,
  type ResourceAction,
  type Type
} from '../interface';

const idExists = <T extends {id: Id}>(iterable: T[], id: Id) =>
  iterable.map(item => item.id).includes(id);

const providerWithNewTypeResources = (
  provider: ProviderShape,
  type: Type,
  resources?: Resource[]
): ProviderShape => ({
  ...provider,
  resources: {
    ...provider.resources,
    [type]: resources
  }
});

const actionHandlers = {
  [REGISTER_RESOURCE]: (
    state: ProviderShape[],
    {providerId, resourceId, resourceType}: ResourceAction
  ): ProviderShape[] => {
    if (!state.find(p => p.id === providerId)) {
      return [
        ...state,
        {id: providerId, node: null, resources: {[resourceType]: [{id: resourceId}]}}
      ];
    }
    return state.map(p => {
      if (p.id !== providerId) {
        return p;
      }
      let typeResources = p.resources[resourceType] || [];
      if (idExists(typeResources, resourceId)) {
        return p;
      }
      typeResources = [...typeResources, {id: resourceId}];
      return providerWithNewTypeResources(p, resourceType, typeResources);
    });
  },
  [UNREGISTER_RESOURCE]: (
    state: ProviderShape[],
    {providerId, resourceId, resourceType}: ResourceAction
  ): ProviderShape[] => {
    if (!idExists(state, providerId)) {
      return state;
    }
    return state.map(p => {
      if (p.id !== providerId) {
        return p;
      }
      let typeResources = p.resources[resourceType];
      if (!typeResources) {
        return p;
      }
      typeResources = typeResources.filter(r => r.id !== resourceId);
      return providerWithNewTypeResources(
        p,
        resourceType,
        typeResources.length ? typeResources : undefined
      );
    });
  },
  [LOAD_RESOURCE]: (
    state: ProviderShape[],
    {providerId, data, resourceType}: LoadResourceAction
  ): ProviderShape[] => {
    return state.map(p => {
      if (p.id !== providerId) {
        return p;
      }
      const typeResources = p.resources[resourceType];
      return typeResources
        ? providerWithNewTypeResources(
            p,
            resourceType,
            typeResources.map(r => (r.id === data.id ? data : r))
          )
        : p;
    });
  },
  [LOAD_RESOURCES]: (
    state: ProviderShape[],
    {providerId, data, resourceType}: LoadResourcesAction
  ): ProviderShape[] => {
    if (!idExists(state, providerId)) {
      return [...state, {id: providerId, node: null, resources: {[resourceType]: data}}];
    }
    return state.map(p =>
      p.id === providerId ? providerWithNewTypeResources(p, resourceType, data) : p
    );
  },
  [REGISTER_PROVIDER]: (
    state: ProviderShape[],
    {providerId, node}: RegisterProviderAction
  ): ProviderShape[] => {
    if (idExists(state, providerId)) {
      return state.map(p => (p.id === providerId ? {...p, node} : p));
    }
    return [...state, {id: providerId, node, resources: {}}];
  },
  [UNREGISTER_PROVIDER]: (state: ProviderShape[], {providerId}: ProviderAction): ProviderShape[] =>
    state.filter(p => p.id !== providerId)
};

const reducer = (state: ProviderShape[], action: Action): ProviderShape[] => {
  return actionHandlers[action.type] ? actionHandlers[action.type](state, action) : state;
};

export default reducer;
