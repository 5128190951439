import React, {memo} from 'react';
import classNames from 'classnames';

import {useSearchingInODContext} from '../../contexts/searchingInODContext';

import './ScrambledItem.scss';

interface Props {
  word: string;
  isCurrent: boolean;
}

export const ScrambledItem: React.FC<Props> = memo(({word, isCurrent}) => {
  const {sendScrambledWordApiRequest} = useSearchingInODContext();

  const onClick = () => {
    sendScrambledWordApiRequest(word);
  };
  return (
    <div className={classNames('scrambled-item', {'is-current': isCurrent})} onClick={onClick}>
      {word}
    </div>
  );
});
