import {combineReducers, type ReducersMapObject} from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {persistReducer} from 'redux-persist';

import wampReducer from 'services/wamp/reducers/reducer';

import intlReducer from '../i18n/reducers/reducer';
import layoutReducer from '../layouts/reducers/layoutReducer';
import chatReducer from '../components/Chat/reducer/reducer';
import userReducer from '../authentication/reducers/userReducer';
import rtcReducer from '../webRTC/reducers/rtcReducer';
import libraryReducer from '../routes/ClassRoom/components/FilesWorkSpace/Library/reducer';
import uploadsReducer from '../routes/ClassRoom/components/FilesWorkSpace/uploadingFiles/reducers/uploadsReducer';
import mediaDevicesReducer from '../routes/ClassRoom/components/MediaSettingsWizard/reducer';
import settingsReducer from '../routes/ClassRoom/reducers/settingsReducer';
import {
  persistDevicesConfig,
  persistIntlConfig,
  persistLayoutConfig,
  persistMediaConfig,
  persistRTCConfig,
  persistUserConfig
} from './persist';
import videoReducer from '../webRTC/reducers/videoStateReducer';
import commonReducer from '../common/reducer';
import {
  type AppState,
  type LayoutState,
  type MediaDevicesState,
  type MediaState,
  type RTCState,
  type UserState
} from './interface';
import mediaReducer from './media/mediaReducer';
import unitPreviewReducer from '../components/modals/UnitPreview/reducer';
import {type IntlState} from '../i18n/actions/interface';
import {routerReducer} from './redux-router';

export interface ActionHandlersList<S, A> {
  [key: string]: ((state: S, action: A) => S) | undefined;
}

export const makeRootReducer = (asyncReducers?: ReducersMapObject) =>
  combineReducers<Partial<AppState>>({
    router: routerReducer,
    intl: persistReducer<IntlState>(persistIntlConfig, intlReducer),
    toastr: toastrReducer,
    user: persistReducer<UserState>(persistUserConfig, userReducer),
    wamp: wampReducer(),
    layout: persistReducer<LayoutState>(persistLayoutConfig, layoutReducer),
    chat: chatReducer,
    rtc: persistReducer<RTCState>(persistRTCConfig, rtcReducer),
    library: libraryReducer,
    uploads: uploadsReducer,
    mediaDevices: persistReducer<MediaDevicesState>(persistDevicesConfig, mediaDevicesReducer),
    common: commonReducer,
    video: videoReducer,
    media: persistReducer<MediaState>(persistMediaConfig, mediaReducer),
    unitPreview: unitPreviewReducer,
    settings: settingsReducer,
    ...asyncReducers
  });
