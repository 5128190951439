import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {type HomeworkWithContents} from 'store/interface';

import {
  CLEAR_HOMEWORK_PLAYER,
  LOAD_HOMEWORK,
  LOAD_HOMEWORK_DOCUMENT,
  LOAD_HOMEWORK_DOCUMENT_PAGE,
  LOAD_HOMEWORK_EXERCISE,
  SET_SELECTED_HOMEWORK
} from './actionTypes';

export const loadHomework = (
  courseInstanceId: number | string,
  homeworkId: string
): AxiosRequestAction => ({
  type: LOAD_HOMEWORK,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/course-instance/${courseInstanceId}/homework/${homeworkId}`
    }
  }
});

export const clearHomeworkPlayer = (): Action => ({
  type: CLEAR_HOMEWORK_PLAYER
});

export const loadHomeworkExercise = (
  courseInstanceId: number | string,
  homeworkId: string,
  exerciseInstanceId: string
): AxiosRequestAction => ({
  type: LOAD_HOMEWORK_EXERCISE,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/course-instance/${courseInstanceId}/homework/${homeworkId}/exercise/${exerciseInstanceId}`,
      params: {
        expand: 'grammar'
      }
    }
  }
});

export const loadHomeworkDocument = (
  courseInstanceId: number | string,
  homeworkId: string,
  documentInstanceId: number
): AxiosRequestAction => ({
  type: LOAD_HOMEWORK_DOCUMENT,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document/${documentInstanceId}`
    }
  }
});

export const loadHomeworkDocumentPage = (
  courseInstanceId: number | string,
  homeworkId: string,
  documentInstancePageId: number
): AxiosRequestAction => ({
  type: LOAD_HOMEWORK_DOCUMENT_PAGE,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document-page/${documentInstancePageId}`
    }
  }
});

export interface SetSelectedHomeworkAction extends Action {
  homework: HomeworkWithContents;
}

export const setSelectedHomework = (homework: HomeworkWithContents): SetSelectedHomeworkAction => ({
  type: SET_SELECTED_HOMEWORK,
  homework
});
