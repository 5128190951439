import {type Action, type Dispatch} from 'redux';
import {addBreadcrumb} from '@sentry/react';

import {type SentryMiddlewareFn} from './interface';

const ravenLogMiddleware: SentryMiddlewareFn = middlewareConfig => api => next =>
  ((action: Action) => {
    if (action.type && !middlewareConfig.ignore.find(el => action.type === el)) {
      addBreadcrumb({
        category: 'redux',
        message: action.type
      });
    }
    return next(action);
  }) as Dispatch<Action>;

export default ravenLogMiddleware;
