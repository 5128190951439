import {createIntl, createIntlCache} from 'react-intl';
import {type Reducer} from 'redux';

import {type ActionHandlersList} from 'store/reducers';

import {
  type IntlAction,
  type LocaleLoadingAction,
  type IntlUpdateAction,
  type IntlState
} from '../actions/interface';
import {LOCALE_LOADING, UPDATE, HOT_UPDATE} from '../actions/actionTypes';

const intlCache = createIntlCache();
export const initialIntlState: IntlState = createIntl(
  {
    textComponent: 'span',
    locale: 'ru',
    messages: {}
  },
  intlCache
) as IntlState;

const ACTION_HANDLERS: ActionHandlersList<IntlState, IntlAction> = {
  [LOCALE_LOADING]: (state: IntlState, action: LocaleLoadingAction): IntlState => ({
    ...state,
    loading: action.loading
  }),
  [UPDATE]: (state: IntlState, action: IntlUpdateAction): IntlState =>
    createIntl(
      {
        ...state,
        ...action.payload
      },
      intlCache
    ) as IntlState,
  [HOT_UPDATE]: (state: IntlState, action: IntlUpdateAction): IntlState => {
    const hotUpdated = state.hotUpdated || 0;
    const newIntl = createIntl(
      {
        ...state,
        ...action.payload
      },
      intlCache
    ) as IntlState;
    newIntl.hotUpdated = hotUpdated + 1;
    return newIntl;
  }
};

const intlReducer: Reducer<IntlState, IntlAction> = (state = initialIntlState, action) => {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
};

export default intlReducer;
