import React from 'react';
import {type Color} from '@englex/paint-react';

import {ColorElement} from './Color';

import './ColorPalette.scss';

interface Props {
  active?: Color;
  palette: Color[];
  title?: string;
  onSelect: (color: Color) => void;
}

export const ColorPalette: React.FC<Props> = ({active, palette, title, onSelect}) => {
  return (
    <div className="color-palette">
      {title && <div className="color-palette__title">{title}</div>}

      {palette.map(color => (
        <ColorElement active={color === active} key={color} color={color} onSelect={onSelect} />
      ))}
    </div>
  );
};
