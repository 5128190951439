import {type Reducer} from 'redux';
import {REHYDRATE, type RehydrateAction} from 'redux-persist';

import {type Profile, type UserState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';
import {PersistState} from 'store/persist';

import {
  GOT_TOKEN,
  LOGOUT,
  REQUEST_LOGIN_SUCCESS,
  REQUEST_PROFILE_SUCCESS,
  TOKEN_EXPIRED,
  TOKEN_RENEWED
} from '../actions/actionTypes';
import {
  type RequestLoginSuccess,
  type RequestProfileSuccess,
  type SetTokenAction,
  type UserAction
} from '../actions/interface';
import {type ParsedToken, parseToken} from '../../config/jwt';

export const initialUserState: UserState = {};

function tokenPayload(token: string) {
  const parsedToken: ParsedToken = parseToken(token);
  return {
    token,
    id: parsedToken.authid,
    role: parsedToken.authroles[0]
  };
}

const ACTION_HANDLERS: ActionHandlersList<UserState, UserAction> = {
  [REQUEST_LOGIN_SUCCESS]: (state: UserState, action: RequestLoginSuccess): UserState => {
    const {locale: Locale, ...userInfo} = action.payload.data.user;
    return {
      ...state,
      ...userInfo,
      created_at: new Date(action.payload.data.user.created_at!)
    };
  },
  [REQUEST_PROFILE_SUCCESS]: (state: UserState, action: RequestProfileSuccess): UserState => {
    const {locale: Locale, ...userInfo} = action.payload.data;
    return {
      ...state,
      ...userInfo,
      profile: {
        ...(userInfo.profile as Profile)
      }
    };
  },
  [LOGOUT]: (state: UserState): UserState => {
    const {token, ...newState} = state;
    return newState;
  },
  [REHYDRATE]: (
    state: UserState,
    action: RehydrateAction & {payload: {token: string}}
  ): UserState => {
    if (
      action.key === PersistState.user &&
      !state.token &&
      action.payload &&
      action.payload.token
    ) {
      return tokenPayload(action.payload.token);
    }
    return {...state};
  },
  [TOKEN_EXPIRED]: (state: UserState) => {
    const {token, ...newState} = state;
    newState.sessionExpired = true;
    return newState;
  },
  [TOKEN_RENEWED]: (state: UserState, action: SetTokenAction) => {
    return {
      ...state,
      ...tokenPayload(action.token)
    };
  },
  [GOT_TOKEN]: (state: UserState, action: SetTokenAction) => ({
    ...state,
    ...tokenPayload(action.token)
  })
};

// ------------------------------------
// Reducer
// ------------------------------------

const userReducer: Reducer<UserState, UserAction> = (
  state: UserState = initialUserState,
  action: UserAction
) => {
  const userReducer = ACTION_HANDLERS[action.type];
  return userReducer ? userReducer(state, action) : state;
};

export default userReducer;
