import React, {type FC, useCallback} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import FormGroup from 'react-bootstrap/lib/FormGroup';

import ReactSelect from 'components/ReactSelect/ReactSelect';

import {type GroupedOptionsType} from '../../../../ReactSelect/interface';

type Option = {label: string; value: string};

interface Props {
  options: GroupedOptionsType<Option>;
  validationState: 'error' | null;
  setValidationState(vs: 'error' | null): void;
  setSelectedOption(option?: Option): void;
}

export const AddEntryToListModalForm: FC<Props> = ({
  options,
  setSelectedOption,
  setValidationState,
  validationState
}) => {
  const intl = useIntl();

  const onChange = useCallback(
    (v: Option | null) => {
      setValidationState(!v ? 'error' : null);
      if (!v) return setSelectedOption(undefined);
      const option = options
        .map(g => g.options)
        .flat()
        .find(o => o.value === v.value);
      return option ? setSelectedOption(option) : setSelectedOption(undefined);
    },
    [options, setSelectedOption, setValidationState]
  );

  const customFilter = (option: Option, searchValue: string) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase());

  return (
    <form onSubmit={useCallback(e => e.preventDefault(), [])}>
      <FormGroup validationState={validationState}>
        <span className="select-label">{intl.formatMessage({id: 'Dictionary.List'})}</span>
        <ReactSelect
          onChange={onChange}
          options={options}
          placeholder={intl.formatMessage({id: 'Dictionary.List.SelectorPlaceholder'})}
          isMulti={false}
          filterOption={customFilter}
        />
        <div className="help-block error">
          <FormattedMessage id="Dictionary.Toastr.AddEntryToListValidationEmptyError" />
        </div>
      </FormGroup>
    </form>
  );
};
