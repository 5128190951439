import type * as axios from 'axios';
import {type MiddlewareOptions} from 'redux-axios-middleware';

import {type AppState} from 'store/interface';

import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from './interface';

const config: axios.AxiosRequestConfig = {
  baseURL: window.location.origin,
  responseType: 'json'
};

export const staticMiddlewareOptions: MiddlewareOptions<AppState> = {
  successSuffix: AXIOS_SUCCESS_POSTFIX,
  errorSuffix: AXIOS_ERROR_POSTFIX
};

export default config;
