import {type Action} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';

import {
  CLEAR_OPENED_TOGETHER,
  CLOSE_EDIT_HOMEWORK_COMMENT_MODAL,
  DELETE_DOCUMENT,
  DOCUMENT_VIEWED,
  DOCUMENTS_RESET,
  EDIT_DOCUMENT,
  EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT,
  EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE,
  OPEN_EDITOR,
  PIN_DOCUMENT,
  REQUEST_DOCUMENTS,
  REQUEST_ENSURE_PDF_PAGE,
  SET_DOCUMENT_DELETING,
  SET_LAST_DOC_PAGE_NUMBER
} from './actionTypes';
import {
  type FileAction,
  type LoadFileAction,
  type OpenEditorAction,
  type PinAction,
  type SetIsDeleteRequestCreator
} from '../../../../actions/interface';

export const documentOpenEditor = (id: number | null): OpenEditorAction => ({
  type: OPEN_EDITOR,
  id
});

export const documentEdit = (id: number, title: string): AxiosRequestAction => ({
  type: EDIT_DOCUMENT,
  payload: {
    request: {
      url: `/v1/classroom/document-instance/${id}`,
      method: 'put',
      data: {
        title
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }
});

export const documentDelete = (id: number): AxiosRequestAction => ({
  type: DELETE_DOCUMENT,
  payload: {
    request: {
      url: `/v1/classroom/document-instance/${id}`,
      method: 'delete'
    }
  }
});

export const loadDocuments = (courseId: number): LoadFileAction => ({
  type: REQUEST_DOCUMENTS,
  payload: {
    request: {
      method: 'get',
      url: `/v1/classroom/course-instance/${courseId}/document`,
      params: {
        expand: 'views'
      }
    }
  }
});

export const documentViewed = (id: number): AxiosRequestAction => ({
  type: DOCUMENT_VIEWED,
  payload: {
    request: {
      method: 'post',
      url: `/v1/classroom/document-instance/${id}/read`
    }
  }
});

export const resetDocuments: () => FileAction = () => ({
  type: DOCUMENTS_RESET
});

export const clearOpenedTogetherDoc: () => FileAction = () => ({
  type: CLEAR_OPENED_TOGETHER
});

export const pinDocument = (id: number, pinned: boolean): PinAction => ({
  type: PIN_DOCUMENT,
  id,
  payload: {
    request: {
      url: `/v1/classroom/document-instance/${id}`,
      method: 'put',
      data: {
        pinned
      },
      headers: {
        'Content-Type': 'application/json'
      }
    }
  }
});

export const setDocumentDeleting: SetIsDeleteRequestCreator = (
  fileId: string,
  componentId: string,
  isBeingDeleted: boolean
) => ({
  type: SET_DOCUMENT_DELETING,
  fileId,
  componentId,
  isBeingDeleted
});

export interface EditHomeworkCommentForDocAction extends Action {
  docId: number;
}

export interface SetLastDocPagesAction extends Action {
  documentInstanceId: number;
  page: number;
}

export interface EditHomeworkCommentForDocPageAction extends EditHomeworkCommentForDocAction {
  pageNumber: number;
}

export const editHomeworkCommentForDocument = (docId: number): EditHomeworkCommentForDocAction => ({
  type: EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT,
  docId
});

export const setLastDocPageNumber = (
  documentInstanceId: number,
  page: number
): SetLastDocPagesAction => ({
  type: SET_LAST_DOC_PAGE_NUMBER,
  documentInstanceId,
  page
});

export const editHomeworkCommentForDocumentPage = (
  docId: number,
  pageNumber: number
): EditHomeworkCommentForDocPageAction => ({
  type: EDIT_HOMEWORK_COMMENT_FOR_DOCUMENT_PAGE,
  docId,
  pageNumber
});

export const closeEditHomeworkCommentModal = (): Action => ({
  type: CLOSE_EDIT_HOMEWORK_COMMENT_MODAL
});

export const requestEnsurePDFPage = (
  documentInstanceId: number,
  pageNumber: number,
  pageWidth: number,
  pageHeight: number
): AxiosRequestAction => {
  return {
    type: REQUEST_ENSURE_PDF_PAGE,
    payload: {
      client: 'v2',
      request: {
        method: 'POST',
        url: `/v2/document-instance/${documentInstanceId}/page/${pageNumber}`,
        data: {
          width: pageWidth,
          height: pageHeight
        }
      }
    }
  };
};
