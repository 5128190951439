import {type Plugin} from '@englex/slate-react';
import {Block, type Editor, type SchemaProperties, type SlateError} from '@englex/slate';

import {SlateBlock, SlateObject} from '../../interface';
import {logNormalizeError} from './utils';

export default class SingleParagraphEditor implements Plugin {
  public schema: SchemaProperties = {
    rules: [
      {
        match: {object: SlateObject.DOCUMENT},
        nodes: [
          {
            match: [{type: SlateBlock.DEFAULT, object: SlateObject.BLOCK}],
            min: 1,
            max: 1
          }
        ],
        normalize: (change: Editor, error: SlateError) => {
          const {code, child} = error;
          if (code === 'child_unknown') {
            if (Block.isBlock(child)) {
              if (child.type === SlateBlock.DEFAULT) {
                change.mergeNodeByKey(child.key);
                logNormalizeError(error, 'SingleParagraphEditor.schema.rules', true, '0');
                return;
              } else {
                change.unwrapNodeByKey(child.nodes.get(0).key);
                logNormalizeError(error, 'SingleParagraphEditor.schema.rules', true, '0');
                return;
              }
            }
          }
          if (error.code === 'child_type_invalid') {
            change.unwrapNodeByKey(error.child!.key);
            logNormalizeError(error, 'SingleParagraphEditor.schema.rules', true, '0');
            return;
          }
          logNormalizeError(error, 'SingleParagraphEditor.schema.rules', false, '0');
        }
      }
    ]
  };
}
