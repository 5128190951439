import React, {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import Helmet from 'react-helmet';

import {push} from 'store/router';
import XPlayerPage from 'routes/ClassRoom/pages/XPlayerPage/XPlayerPage';
import Loader from 'components/Loader';
import {ErrorMask} from 'components/ErrorMask';
import {XIntroPreview} from 'components/XIntroPreview';
import {type CoursebookInstance, type UnitInstance} from 'store/interface';
import {IntroRecord} from 'store/intro/IntroRecord';
import {ImageCacheProvider} from 'components/XPlayer/contexts/imageCacheContext';
import {WebviewPlayerProvider} from 'contexts/Webview/WebviewPlayerContext';
import {Resources, ViewerProvider} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import {requestCoursebookInstance} from 'routes/ClassRoom/pages/CoursebookInstancePage/actions';
import {requestUnitInstance} from 'routes/ClassRoom/pages/UnitInstancePage/actions';
import {useApiRequest} from 'hooks/rest/useApiRequest';
import {isMobileWebView} from 'helpers/browser';
import {
  useWebViewMessage,
  type WebViewMessageData,
  WebViewMessageType
} from 'hooks/webview/useWebViewMessage';

import './StandaloneUnitPlayer.scss';

const getStandalonePlayerPath = (
  coursebookInstanceId: string,
  unitInstanceId: number,
  pageNumber: number = 1
) => {
  return `/xplayer/coursebook/${coursebookInstanceId}/unit/${unitInstanceId}${
    pageNumber ? `/page/${pageNumber}` : ''
  }`;
};

type RouteParams = {
  coursebookInstanceId: string;
  unitInstanceId: string;
  page?: string;
};

export const StandaloneUnitPlayer: FC = () => {
  const {coursebookInstanceId, unitInstanceId, page} = useParams<RouteParams>();
  const [unitInstance, setUnitInstance] = useState<UnitInstance | null>(null);

  const [coursebookInstance, setCoursebookInstance] = useState<CoursebookInstance | null>(null);

  const requestCoursebookInstanceAction = useMemo(() => {
    return requestCoursebookInstance(coursebookInstanceId!, {});
  }, [coursebookInstanceId]);

  const requestUnitInstanceAction = useMemo(() => {
    return requestUnitInstance(Number(unitInstanceId));
  }, [unitInstanceId]);

  const {
    isLoading: isUnitInstanceLoading,
    isError: isUnitInstanceError,
    reload: reloadUnitInstance
  } = useApiRequest(requestUnitInstanceAction, (response: UnitInstance) =>
    setUnitInstance(response)
  );

  const {
    isLoading: isCoursebookInstanceLoading,
    isError: isCoursebookInstanceError,
    reload: reloadCoursebookInstance
  } = useApiRequest<CoursebookInstance>(requestCoursebookInstanceAction, data =>
    setCoursebookInstance(data)
  );

  const reload = useCallback(() => {
    reloadUnitInstance();
    reloadCoursebookInstance();
  }, [reloadUnitInstance, reloadCoursebookInstance]);

  const isLoading = isUnitInstanceLoading || isCoursebookInstanceLoading;
  const isError = isUnitInstanceError || isCoursebookInstanceError;

  return (
    <div className="standalone-player">
      {isMobileWebView() && (
        <Helmet
          bodyAttributes={{class: 'x-player-mobile-webview'}}
          meta={[{name: 'viewport', content: 'initial-scale=1.0, maximum-scale=1.0'}]}
        />
      )}
      {isError ? (
        <ErrorMask reload={reload} />
      ) : isLoading || !coursebookInstance || !unitInstance ? (
        <Loader />
      ) : (
        <ImageCacheProvider>
          <StandalonePlayerContent
            page={page}
            coursebookInstance={coursebookInstance}
            unitInstance={unitInstance}
          />
        </ImageCacheProvider>
      )}
    </div>
  );
};

interface StandalonePlayerContentProps {
  page?: string | number;
  unitInstance: UnitInstance;
  coursebookInstance: CoursebookInstance;
}

export const StandalonePlayerContent: FC<StandalonePlayerContentProps> = ({
  page,
  unitInstance,
  coursebookInstance
}) => {
  const coursebookInstanceId = coursebookInstance.id;
  const unitInstanceId = unitInstance.id;

  const dispatch = useDispatch();

  const resourcesNode = useNode('.standalone-player');

  const onMessage = useCallback(
    (message: WebViewMessageData) => {
      const {type} = message;

      switch (type) {
        case WebViewMessageType.Prev:
          return dispatch(
            push(getStandalonePlayerPath(coursebookInstanceId, unitInstanceId, Number(page) - 1))
          );

        case WebViewMessageType.Next:
          return dispatch(
            push(getStandalonePlayerPath(coursebookInstanceId, unitInstanceId, Number(page) + 1))
          );

        case WebViewMessageType.SetPage:
          const {
            payload: {pageNumber}
          } = message;

          return dispatch(
            push(getStandalonePlayerPath(coursebookInstanceId, unitInstanceId, pageNumber))
          );

        default:
          return;
      }
    },
    [dispatch, coursebookInstanceId, unitInstanceId, page]
  );

  const postMessage = useWebViewMessage(onMessage);

  const haveIntro = !!unitInstance.unit.intro;
  const isIntroPreview = Boolean(!page && haveIntro);

  const onStart = useCallback(() => {
    if (isMobileWebView()) {
      return postMessage({type: WebViewMessageType.SetPage, payload: {pageNumber: 1}});
    }

    return dispatch(push(getStandalonePlayerPath(coursebookInstanceId, unitInstanceId, 1)));
  }, [dispatch, postMessage, coursebookInstanceId, unitInstanceId]);

  const onView = useCallback(() => {
    if (isMobileWebView()) {
      return postMessage({
        type: WebViewMessageType.UnitContents,
        payload: {unitInstanceId: Number(unitInstanceId), coursebookInstanceId, pageNumber: 0}
      });
    }
  }, [coursebookInstanceId, postMessage, unitInstanceId]);

  useEffect(() => {
    postMessage({type: WebViewMessageType.Ready});
  }, [postMessage]);

  const intro = useMemo(
    () => (isIntroPreview ? new IntroRecord(unitInstance.unit.intro!) : null),
    [isIntroPreview, unitInstance]
  );

  return isIntroPreview ? (
    <XIntroPreview
      intro={intro!}
      unit={unitInstance.unit}
      ordinal={unitInstance.ordinal}
      onStart={onStart}
      onView={onView}
    />
  ) : (
    <ImageCacheProvider>
      <WebviewPlayerProvider>
        <ViewerProvider>
          <Resources id="player-page" node={resourcesNode}>
            <XPlayerPage
              pageNumber={Number(page)}
              unitInstanceId={Number(unitInstanceId)}
              selfCheckEnabled={coursebookInstance.selfCheckEnabled}
            />
          </Resources>
        </ViewerProvider>
      </WebviewPlayerProvider>
    </ImageCacheProvider>
  );
};
