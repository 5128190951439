import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type FlipCardsCardJSON} from 'store/exercise/player/widgets/FlipCards/interface';
import {
  type FlipCardsCardProperties,
  type FlipCardsCardProps
} from 'store/exercise/editor/widgets/XFlipCards/interface';
import {CardSizeType} from 'store/exercise/editor/widgets/XWordPictureSet/XPictureSet/interface';

const Record = recordBase()(BaseRecord);

class FlipCardsCardRecord extends Record implements FlipCardsCardProperties {
  public declare readonly id: string;

  public declare readonly imageId: number;

  public declare readonly text: string;

  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: Partial<FlipCardsCardProps> = {}) {
    super();
    this.initValues({
      id: raw.id,
      imageId: raw.imageId,
      text: raw.text,
      cardSizeType: raw.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toJSON(): FlipCardsCardJSON {
    return {
      id: this.id,
      imageId: this.imageId,
      text: this.text,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(FlipCardsCardRecord, {
  id: property(),
  imageId: property(),
  text: property(),
  cardSizeType: property()
});
record()(FlipCardsCardRecord);
export default FlipCardsCardRecord;
