const duration = 300;
const left = 400;
export const transition = {
  timeout: {
    enter: 0,
    exit: duration
  },
  defaultStyle: {
    transition: `left ${duration}ms ease-in-out`,
    left: `-${left}px`
  },
  transitionStyles: {
    entering: {left: `-${left}px`},
    entered: {left: 0},
    exiting: {left: `-${left}px`},
    exited: {left: `-${left}px`}
  }
};
