import {wordTemplateLiteral} from 'config/static';

import {type ScrambledArrayChoicesJSON, type ScrambledChoicesJSON} from './interface';

export const getFullSentence = (
  templateArray: string[],
  answers: string[],
  choices: ScrambledChoicesJSON | ScrambledArrayChoicesJSON
) => {
  const fullSentence: Array<{value: string; capitalize?: true; static?: true} | string> = [
    ...templateArray
  ];

  for (const wordId of answers) {
    const {capitalize, value} = Array.isArray(choices)
      ? choices.find(c => c.id === wordId)!
      : choices[wordId];
    fullSentence[fullSentence.indexOf(wordTemplateLiteral)] = {
      value,
      ...(capitalize ? {capitalize} : {})
    };
  }

  return fullSentence.map(item =>
    typeof item === 'string' ? {value: item, isStatic: true} : item
  );
};
