import React from 'react';
import {type Editor as ReactEditor, type RenderMarkProps} from '@englex/slate-react';
import {type Editor, type Next, type Mark as CoreMark, type GenericQuery} from '@englex/slate';

import {getMarkClassNames} from 'components/Slate/interface';

import Mark from './Mark';
import {isClassMarkOfType} from '../../utils';

abstract class ClassNameMark extends Mark {
  public abstract className?: string;
  public wrapper = 'span';

  public onQuery = (query: GenericQuery<[CoreMark]>, editor: Editor, next: () => void) => {
    if (query.type === getMarkClassNames) {
      const [mark] = query.args;
      if (mark.type === this.mark && mark.data.get('className') === this.className) {
        return this.getMarkClassNames();
      }
    }
    return next();
  };

  public getMarkClassNames = (): string => {
    return this.className ? `${this.mark}-${this.className}` : this.mark;
  };

  public renderMark = (
    {attributes, mark, children}: RenderMarkProps,
    editor: ReactEditor,
    next: Next
  ) => {
    if (!isClassMarkOfType(mark, this.mark)) {
      return next();
    }
    const className = mark.data.get('className');
    const Wrapper = this.wrapper as React.ElementType;
    return (
      <Wrapper {...attributes} className={`${this.mark}-${className}`}>
        {children}
      </Wrapper>
    );
  };
}

export default ClassNameMark;
