export const IMAGE_MAX_WIDTH = 720;
export const IMAGE_MIN_WIDTH = 100;
export const IMAGE_MIN_HEIGHT = 100;
export const IMAGE_RESIZE_GRID = 20;

// min height of 100px is not guaranteed due to adjusting width of picture, gotten via response
// to fit 20px grid width wise. I.e. response contains picture crop with dimensions 106*100px ->
// adjustment will make it render with 100*94px size. 20px is maximum mistake possible.
export const IMAGE_MIN_RENDER_HEIGHT = IMAGE_MIN_HEIGHT - 20;

// modal image area sizes
export const MODAL_HEIGHT = 500;
export const MODAL_WIDTH = 1000;
