import {type Block, type Document} from '@englex/slate';

import {SlateBlock} from '../interface';
import {isBlock, isGapFillInline, isIconInline} from './guard';

export const isDocumentNotEmpty = (document: Document): boolean =>
  !!document
    .getBlocks()
    .find(
      (block: Block) =>
        (isBlock(block) && block.type === SlateBlock.IMAGE) ||
        !!block.text.match(/\S/) ||
        !!block.getInlines().find(inline => isIconInline(inline!) || isGapFillInline(inline!))
    );

export const isDocumentEmpty = (document: Document): boolean => !isDocumentNotEmpty(document);
