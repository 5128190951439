import isHotkey, {parseHotkey} from 'is-hotkey';
import type React from 'react';

type ShortcutArray = string[]; // array of hotkeys like 'mod+z'
interface ShortcutObject {
  // key:    shortcut string like 'mod+z';
  // value:  should be handled (shortcut has no built in handler);
  [key: string]: boolean;
}

export type Shortcut = ShortcutArray | ShortcutObject | string;

export const isMac =
  typeof window !== 'undefined' && /Mac|iPod|iPhone|iPad/.test(window.navigator.platform);

function isKeyboardShortcutString(shortcut: Shortcut): shortcut is string {
  return typeof shortcut === 'string';
}

function isKeyboardShortcutObject(shortcut: Shortcut): shortcut is ShortcutObject {
  return !isKeyboardShortcutString(shortcut) && (shortcut as ShortcutArray).length === undefined;
}

/**
 * https://wincent.com/wiki/Unicode_representations_of_modifier_keys
 */
const macKeys = {
  /* ⎋ */ escape: '\u238B',
  /* ⇥ */ tab: '\u21E5',
  /* ⇪ */ capslock: '\u21EA',
  /* ⇧ */ shift: '\u21E7',
  /* ⌃ */ control: '\u2303',
  /* ⌥ */ alt: '\u2325',
  /* ⌘ */ meta: '\u2318',
  /* ␣ */ ' ': '\u2423',
  /* ⏎ */ enter: '\u23CE',
  /* ⌫ */ backspace: '\u232B',
  /* ⌦ */ delete: '\u2326',
  /* ⇱ */ home: '\u21F1',
  /* ⇲ */ end: '\u21F2',
  /* ⇞ */ pageup: '\u21DE',
  /* ⇟ */ pagedown: '\u21DF',
  /* ↑ */ arrowup: '\u2191',
  /* ↓ */ arrowdown: '\u2193',
  /* ← */ arrowleft: '\u2190',
  /* → */ arrowright: '\u2192',
  /* ⇭ */ numlock: '\u21ED',
  /* ⏏ */ eject: '\u23CF',
  /* ⌽ */ power: '\u233D'
};

const renderModifiers = {
  metaKey: isMac ? macKeys.meta : 'Win',
  ctrlKey: isMac ? macKeys.control : 'Ctrl',
  altKey: isMac ? macKeys.alt : 'Alt',
  shiftKey: isMac ? macKeys.shift : 'Shift'
};

const renderKey = (key: string): string => {
  return isMac && macKeys[key] ? macKeys[key] : key.charAt(0).toUpperCase() + key.slice(1);
};

export const renderShortcut = (shortcut: Shortcut): string => {
  const hotkeys = isKeyboardShortcutObject(shortcut)
    ? Object.keys(shortcut)
    : isKeyboardShortcutString(shortcut)
      ? [shortcut]
      : shortcut;
  if (hotkeys.length <= 0) {
    return '';
  }

  const hotKey = parseHotkey(hotkeys[0], {byKey: true});
  const {key, ...modifiers} = hotKey;
  const renderKeys: string[] = Object.keys(renderModifiers)
    .reverse()
    .reduce<string[]>(
      (render, modifier) =>
        modifiers[modifier] ? render.concat(renderModifiers[modifier]) : render,
      [renderKey(key!)]
    );
  return renderKeys.reverse().join(isMac ? '' : '+');
};

export const isShortcut = (e: React.KeyboardEvent | KeyboardEvent, shortcut?: Shortcut) => {
  if (!shortcut) {
    return;
  }
  const event = e as KeyboardEvent;
  if (!isKeyboardShortcutObject(shortcut)) {
    return isHotkey(shortcut, event);
  }
  const hotkey = Object.keys(shortcut).filter(h => shortcut[h]);
  return isHotkey(hotkey, event);
};

export default isShortcut;
