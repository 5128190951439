import classNames from 'classnames';
import React, {useCallback, useMemo} from 'react';
import {type YSyncPaint, YSyncPant, YSyncStatus} from '@englex/paint-yjs';
import {IS_APPLE, useFocused, usePaint, withPaint} from '@englex/paint-react';

import Icon from 'components/Icon';

import './YSyncHwStatus.scss';

const isModKey = (e: React.MouseEvent): boolean => {
  return IS_APPLE ? e.metaKey : e.ctrlKey;
};

export const YSyncHwStatus = withPaint(() => {
  const paint = usePaint<YSyncPaint>();
  const isFocused = useFocused();

  const {ySyncEnabled} = paint;
  const sync = useCallback(
    (e: React.MouseEvent) => {
      if (!isModKey(e) || !e.altKey) {
        return;
      }
      if (e.shiftKey) {
        paint.ySyncEnable(!ySyncEnabled); // toggle sync
      } else if (ySyncEnabled) {
        paint.ySync();
      }
    },
    [ySyncEnabled, paint]
  );

  const status = YSyncPant.getStatus(paint);

  const statusIcon = useMemo(
    () => (status: YSyncStatus) =>
      YSyncStatus.OFFLINE === status ? 'close' : YSyncStatus.SAVED === status ? 'cloud' : 'refresh',
    []
  );

  return (
    <button
      className={classNames('painter-toolbar-button', 'y-sync-hw-status', {
        [status]: true,
        disabled: !isFocused
      })}
      onClick={sync}
    >
      <Icon name={statusIcon(status)} /> <span className="title">{status}</span>
    </button>
  );
}, YSyncPant.is);
