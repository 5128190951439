import React, {type FC, useCallback, useEffect} from 'react';
import {useFocused, usePaint} from '@englex/paint-react';
import {Paint, type POrientation} from '@englex/paint';
import {useIntl} from 'react-intl';

import Icon from 'components/Icon';
import isShortcut from 'helpers/shortcut';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';

interface Props {
  orientation?: POrientation;
  rotate?: (cw?: boolean) => void;
  clockwise?: boolean;
}

const shortcuts = {
  clockwise: 'mod+opt+shift+right',
  antiClockwise: 'mod+opt+shift+left'
};

export const OrientationButton: FC<Props> = ({clockwise = true, rotate}) => {
  const isFocused = useFocused();
  const paint = usePaint();
  const intl = useIntl();

  const shortcut = clockwise ? shortcuts.clockwise : shortcuts.antiClockwise;

  const isDisabled = !isFocused;

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (isDisabled) return;

      if (shortcut && isShortcut(e, shortcut)) {
        e.preventDefault();
        e.stopPropagation();

        rotate?.(clockwise);
        clockwise ? Paint.orientateRight(paint) : Paint.orientateLeft(paint);
      }
    },
    [clockwise, isDisabled, paint, rotate, shortcut]
  );

  const onClick = useCallback(() => {
    if (isDisabled) return;
    rotate?.(clockwise);
    clockwise ? Paint.orientateRight(paint) : Paint.orientateLeft(paint);
  }, [clockwise, isDisabled, paint, rotate]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <ToolbarButton
      onClick={onClick}
      disabled={isDisabled}
      title={
        clockwise
          ? intl.formatMessage(buttonTitles.rotatePageClockwise)
          : intl.formatMessage(buttonTitles.rotatePageCounterClockwise)
      }
      shortcut={shortcut}
    >
      <Icon name="share-square-o" flip={!clockwise && 'horizontal'} />
    </ToolbarButton>
  );
};
