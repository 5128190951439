import {type Action} from 'redux';
import {type Descendant} from 'slate';

import {
  CHANGE_CARD_LABEL,
  CLEAR_ANSWER,
  CONTENT_CHANGE,
  DROP_ANSWER,
  LABEL_FIRST_TASK,
  QUEST_CONTENT_CHANGE,
  SELECT_CARD,
  SET_VOCABULARY_WIDGET_INFO,
  TOGGLE_COLLAPSIBLE,
  TURN_ESSAY_REVIEW_OFF,
  TURN_ESSAY_REVIEW_ON,
  TURN_QUEST_REVIEW_OFF,
  TURN_QUEST_REVIEW_ON,
  TURN_QUESTION_FIELDS_OFF,
  TURN_QUESTION_FIELDS_ON,
  TURN_QUESTIONS_REVIEW_OFF,
  TURN_QUESTIONS_REVIEW_ON
} from './actionTypes';
import {xplayerPrefix} from '../actionTypes';
import {xpreviewPrefix} from '../preview/actionTypes';
import {type WidgetAction} from '../interface';
import {type WidgetVocabularyInfo} from './Vocabulary/interface';

const prefix = (preview?: boolean) => (preview ? xpreviewPrefix : xplayerPrefix);

export interface ChangeAction extends WidgetAction {
  value: Descendant[];
}

export interface QuestionChangeAction extends ChangeAction {
  id: string;
}

interface QuestsAction extends WidgetAction {
  id: string;
}

export const contentChange = (
  widgetId: string,
  value: Descendant[],
  preview?: boolean
): ChangeAction => ({
  type: `${prefix(preview)}${CONTENT_CHANGE}`,
  value,
  preview,
  widgetId
});

export const turnEssayReviewOn = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_ESSAY_REVIEW_ON}`,
  preview,
  widgetId
});

export const turnEssayReviewOff = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_ESSAY_REVIEW_OFF}`,
  preview,
  widgetId
});

export const turnQuestsReviewOn = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_QUEST_REVIEW_ON}`,
  preview,
  widgetId
});

export const turnQuestsReviewOff = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_QUEST_REVIEW_OFF}`,
  preview,
  widgetId
});

export const turnQuestionsReviewOn = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_QUESTIONS_REVIEW_ON}`,
  preview,
  widgetId
});

export const turnQuestionsReviewOff = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TURN_QUESTIONS_REVIEW_OFF}`,
  preview,
  widgetId
});

export const turnQuestionFieldsOn = (
  widgetId: string,
  id: string,
  preview?: boolean
): QuestsAction => ({
  type: `${prefix(preview)}${TURN_QUESTION_FIELDS_ON}`,
  id,
  preview,
  widgetId
});

export const turnQuestionFieldsOff = (
  widgetId: string,
  id: string,
  preview?: boolean
): QuestsAction => ({
  type: `${prefix(preview)}${TURN_QUESTION_FIELDS_OFF}`,
  id,
  preview,
  widgetId
});

export const questContentChange = (
  widgetId: string,
  id: string,
  value: Descendant[],
  preview?: boolean
): QuestionChangeAction => ({
  type: `${prefix(preview)}${QUEST_CONTENT_CHANGE}`,
  widgetId,
  id,
  preview,
  value
});

export interface GapAnswerAction extends WidgetAction {
  widgetId: string;
  gapId: string;
  choiceId: string;
}

export interface GapClearAction extends WidgetAction {
  widgetId: string;
  choiceId: string;
}

export const answerDropped = (
  widgetId: string,
  gapId: string,
  choiceId: string,
  preview?: boolean
): GapAnswerAction => {
  return {
    type: `${prefix(preview)}${DROP_ANSWER}`,
    widgetId,
    choiceId,
    gapId,
    preview
  };
};

export const clearValue = (
  widgetId: string,
  choiceId: string,
  preview?: boolean
): GapClearAction => {
  return {
    type: `${prefix(preview)}${CLEAR_ANSWER}`,
    widgetId,
    choiceId,
    preview
  };
};

export type LabelFirstTaskParam = {exerciseId: string; page?: number; exerciseNumber: number};
export type LabelFirstTaskAction = LabelFirstTaskParam & Action;

export const labelFirstTask = (param: LabelFirstTaskParam): LabelFirstTaskAction => ({
  type: LABEL_FIRST_TASK,
  ...param
});

export const toggleCollapsible = (widgetId: string, preview?: boolean): WidgetAction => ({
  type: `${prefix(preview)}${TOGGLE_COLLAPSIBLE}`,
  widgetId,
  preview
});

export interface SetWidgetVocabularyInfoAction extends WidgetAction {
  widgetVocabularyInfo: WidgetVocabularyInfo;
}

export const setWidgetVocabularyInfo = (
  widgetId: string,
  widgetVocabularyInfo: WidgetVocabularyInfo,
  preview?: boolean
): SetWidgetVocabularyInfoAction => ({
  type: `${prefix(preview)}${SET_VOCABULARY_WIDGET_INFO}`,
  widgetId,
  preview,
  widgetVocabularyInfo
});

export interface SelectCardAction extends WidgetAction {
  cardId: string;
}

export const selectCard = (
  widgetId: string,
  cardId: string,
  preview?: boolean
): SelectCardAction => ({
  type: `${prefix(preview)}${SELECT_CARD}`,
  widgetId,
  cardId,
  preview
});

export interface ChangeCardLabelAction extends WidgetAction {
  cardId: string;
  label: string;
}

export const changeCardLabel = (
  widgetId: string,
  cardId: string,
  label: string,
  preview?: boolean
): ChangeCardLabelAction => ({
  type: `${prefix(preview)}${CHANGE_CARD_LABEL}`,
  widgetId,
  cardId,
  label,
  preview
});
