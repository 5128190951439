import React, {useState, useEffect, useRef} from 'react';
import {PaintTool, type ToolsPaint, usePaint} from '@englex/paint-react';

import {ToolButton} from '../../../button';
import {MobileFontSizeButton} from '../fontSize';

export const LastToolButton: React.FC = () => {
  const paint = usePaint<ToolsPaint>();

  const {active} = paint.tools;

  const isReady = useRef(false);

  const [tool, setTool] = useState<PaintTool | null>(active);

  useEffect(() => {
    if (isReady.current && active) {
      setTool(active);
    }

    isReady.current = true;
  }, [active]);

  switch (tool) {
    case PaintTool.Pencil:
      return <ToolButton tool={PaintTool.Pencil} iconName="pencil" />;

    case PaintTool.Highlighter:
      return <ToolButton tool={PaintTool.Highlighter} iconName="virc-border-color" />;

    case PaintTool.Line:
      return <ToolButton tool={PaintTool.Line} iconName="virc-line" />;

    case PaintTool.Arrow:
      return <ToolButton tool={PaintTool.Arrow} iconName="arrow-popup" />;

    case PaintTool.Rect:
      return <ToolButton tool={PaintTool.Rect} iconName="rectangle-popup" />;

    case PaintTool.Text:
      return <MobileFontSizeButton />;

    case PaintTool.Ellipse:
      return <ToolButton tool={PaintTool.Ellipse} iconName="virc-ellipse" />;

    default:
      return null;
  }
};
