import {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {ReactPaint, usePaint} from '@englex/paint-react';
import {Transforms, copyNodeOffset} from '@englex/paint';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';

export const CloneButton: FC = () => {
  const intl = useIntl();
  const paint = usePaint();

  const isEnabled = ReactPaint.canCopyNode(paint);

  const onClick = useCallback(() => {
    isEnabled && Transforms.cloneNodes(paint, {offset: copyNodeOffset});
  }, [isEnabled, paint]);

  return (
    <ToolbarButton
      shortcut="mod+shift+c"
      title={intl.formatMessage(buttonTitles.clone)}
      onClick={onClick}
      disabled={!isEnabled}
    >
      <Icon name="clone" />
    </ToolbarButton>
  );
};
