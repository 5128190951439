import RetinaImage from '@englex/react-retina-image';
import {type FC, useEffect} from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {useNavigate, useRouteError} from 'react-router-dom';
import {captureException, withScope} from '@sentry/react';

import cat from 'assets/404_pic.png';
import cat2x from 'assets/404_pic@2x.png';

import {DevErrorBoundary} from './DevErrorBoundary';

import './ErrorBoundary.scss';

const src = [cat, cat2x];

const isDev = import.meta.env.MODE === 'development';

export const ErrorBoundary: FC = () => {
  return isDev ? <DevErrorBoundary /> : <Boundary />;
};

const Boundary: FC = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const back = () => {
    navigate(-1);
  };

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    withScope(scope => {
      scope.setLevel('fatal');
      scope.setTag('error-boundary', true);
      captureException(error);
    });
  }, [error]);

  return (
    <div className="error-boundary">
      <div className="error-boundary__container">
        <h3>
          <FormattedMessage id="Common.ErrorBoundary.Title" />
        </h3>
        <RetinaImage src={src} className="error-img img-responsive" />
        <FormattedMessage id="Common.ErrorBoundary.Message" />
        <div className="actions">
          <Button bsStyle="success" onClick={back}>
            <FormattedMessage id="Common.Back" />
          </Button>
          <Button bsStyle="danger" onClick={reload}>
            <FormattedMessage id="Common.Refresh" />
          </Button>
        </div>
      </div>
    </div>
  );
};
