import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {Map} from 'immutable';

import {
  DELETE_PROCESSING_WIDGET_VALUES,
  PERSIST_WIDGET_VALUES_UNPROCESSED,
  PROCESS_WIDGET_VALUES,
  WIDGET_VALUES_VERSION_UPDATED
} from './actionTypes';
import {
  type DeleteProcessingWidgetValues,
  type PersistUnprocessedWidgetValuesAction,
  type ProcessWidgetValuesAction,
  type WidgetValuesVersionChangedAction,
  type WidgetInstanceValuesMap
} from './interface';

const REDUCERS: ReducersMapObject = {
  [PROCESS_WIDGET_VALUES]: (
    state: WidgetInstanceValuesMap,
    {widgetKey, widgetValues}: ProcessWidgetValuesAction
  ): WidgetInstanceValuesMap => {
    return state.set(widgetKey, widgetValues);
  },
  [PERSIST_WIDGET_VALUES_UNPROCESSED]: (
    state: WidgetInstanceValuesMap,
    {widgetKey}: PersistUnprocessedWidgetValuesAction
  ): WidgetInstanceValuesMap => {
    return state.delete(widgetKey);
  },
  [DELETE_PROCESSING_WIDGET_VALUES]: (
    state: WidgetInstanceValuesMap,
    {widgetKey}: DeleteProcessingWidgetValues
  ): WidgetInstanceValuesMap => {
    return state.delete(widgetKey);
  },
  [WIDGET_VALUES_VERSION_UPDATED]: (
    state: WidgetInstanceValuesMap,
    {exerciseId, widgetId}: WidgetValuesVersionChangedAction
  ): WidgetInstanceValuesMap => {
    return state.delete(`${exerciseId}:${widgetId}`);
  }
};

function processingWidgetValuesReducer(
  state: WidgetInstanceValuesMap = Map(),
  action: Action
): WidgetInstanceValuesMap {
  const reducer: Reducer<WidgetInstanceValuesMap> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default processingWidgetValuesReducer;
