import {Editor, Range, Text, Transforms} from 'slate';
import {ReactEditor} from 'slate-react';

import {
  type CreatePointerDecoration,
  type PointerDecoration,
  PointerDecorationType,
  type PointerSelectionDecoration,
  type PointerSelectionProps
} from '../../../interface';
import {genKey} from '../../../../Slate/utils';
import {SlateEditor} from '../../core';

interface PointerData {
  decorations: PointerSelectionProps[];
  animationTimeout: number;
}

export interface SelectionPointerEditor {
  pointer: PointerData;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SelectionPointerEditor = {
  is(editor: Editor): editor is Editor & SelectionPointerEditor {
    return Boolean(editor.pointer);
  },
  isPointer(editor: Editor, leaf: Text): leaf is PointerSelectionDecoration {
    return (
      SelectionPointerEditor.isCreatePointerDecoration(editor, leaf) ||
      SelectionPointerEditor.isPointerDecoration(editor, leaf)
    );
  },
  isCreatePointerDecoration(editor: Editor, leaf: Text): leaf is CreatePointerDecoration {
    const l = leaf as PointerSelectionProps;
    return Text.isText(l) && l.type === PointerDecorationType.CreatePointer;
  },
  isPointerDecoration(editor: Editor, leaf: Text): leaf is PointerDecoration {
    const l = leaf as PointerSelectionProps;
    return Text.isText(l) && l.type === PointerDecorationType.Pointer;
  },
  createPointerSelection(editor: Editor, range: Range) {
    const hasDecorationIntersection = editor.pointer.decorations.some(
      decoration =>
        decoration.rangeRef.current && Range.intersection(range, decoration.rangeRef.current)
    );

    if (!hasDecorationIntersection) {
      const rangeRef = Editor.rangeRef(editor, range);
      const decoration: PointerSelectionProps = {
        id: genKey(),
        type: PointerDecorationType.CreatePointer,
        rangeRef
      };

      editor.pointer.decorations = editor.pointer.decorations.concat(decoration);

      ReactEditor.deselect(editor);
    }
  },
  deleteCreatePointerDecoration(editor: Editor, shouldChange?: boolean) {
    const selectionDecoration = editor.pointer.decorations.find(
      decoration => decoration.type === PointerDecorationType.CreatePointer
    );

    if (selectionDecoration) {
      selectionDecoration.rangeRef.unref();

      editor.pointer.decorations = editor.pointer.decorations.filter(
        decoration => decoration.id !== selectionDecoration.id
      );

      if (shouldChange) editor.onChange();
    }
  },
  addPointerDecoration(
    editor: Editor,
    range: Range,
    options?: Pick<PointerDecoration, 'elementId'>
  ) {
    const rangeRef = Editor.rangeRef(editor, range);
    const decoration = {
      id: genKey(),
      type: PointerDecorationType.Pointer,
      rangeRef,
      ...options
    };

    editor.pointer.decorations = editor.pointer.decorations.concat(decoration);

    SlateEditor.flush(editor);

    setTimeout(() => {
      SelectionPointerEditor.deletePointerDecoration(editor, decoration.id);
    }, editor.pointer.animationTimeout);
  },
  deletePointerDecoration(editor: Editor, decorationId: string) {
    const selectionAnimatedDecoration = editor.pointer.decorations.find(
      decoration => decoration.id === decorationId
    );

    if (selectionAnimatedDecoration) {
      selectionAnimatedDecoration.rangeRef.unref();

      editor.pointer.decorations = editor.pointer.decorations.filter(
        decoration => decoration.id !== selectionAnimatedDecoration.id
      );

      SlateEditor.flush(editor);
      SelectionPointerEditor.restoreSelection(editor);
    }
  },
  hasDomSelection(editor: Editor, selection: Selection | null): boolean {
    try {
      return (
        !!selection?.focusNode &&
        !!selection?.anchorNode &&
        ReactEditor.hasDOMNode(editor, selection.focusNode) &&
        ReactEditor.hasDOMNode(editor, selection.anchorNode)
      );
    } catch {
      return false;
    }
  },
  restoreSelection(editor: Editor) {
    const selection = document.getSelection();

    if (selection && SelectionPointerEditor.hasDomSelection(editor, selection)) {
      let range: Range | null;
      try {
        range = ReactEditor.toSlateRange(editor, selection, {
          exactMatch: true,
          suppressThrow: true
        });
      } catch {
        range = null;
      }

      if (range && ReactEditor.hasRange(editor, range)) {
        const isFocused = ReactEditor.isFocused(editor);

        if (!isFocused) ReactEditor.focus(editor);

        Transforms.select(editor, range);

        if (!isFocused) Promise.resolve().then(() => ReactEditor.blur(editor));
      }
    }
  }
};
