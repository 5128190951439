import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {type Options, Status, type UnitContents, type UnitInstanceContents} from './interface';
import {useContentLoader} from './useContentLoader';
import {type GrammarList} from '../../common/GrammarPlayerPage/interface';

const hasNextPage = (pageNumber: number, pageCount: number) => pageNumber + 1 <= pageCount;
const hasPreviousPage = (pageNumber: number) => pageNumber - 1 >= 1;

interface HookResult {
  batchListToggle: () => void;
  contents?: UnitInstanceContents | UnitContents | string[];
  listIsOpened: boolean;
  loading: boolean;
  hasNext: boolean;
  hasPrev: boolean;
  listToggleBatched: boolean;
  toggleList: () => void;
}

export const useContentsList = (
  pageCount?: number | null,
  currentPage?: number | null,
  options?: Options,
  grammarList?: GrammarList
): HookResult => {
  const pathname = useSelector<AppState, string>(s => s.router.location!.pathname);
  const [listIsOpened, setListIsOpened] = useState(false);
  const [listToggleBatched, setListToggleBatched] = useState(false);
  const {contents, status} = useContentLoader(listIsOpened, options);
  const preloadContents = grammarList ? grammarList.map(a => a.title || 'Untitled') : contents;
  const batchListToggle = useCallback(() => setListToggleBatched(true), []);
  const toggleList = useCallback(() => setListIsOpened(!listIsOpened), [listIsOpened]);

  const hasNext =
    typeof currentPage !== 'number' || typeof pageCount !== 'number'
      ? false
      : hasNextPage(currentPage, pageCount);

  const hasPrev = typeof currentPage !== 'number' ? false : hasPreviousPage(currentPage);

  useEffect(() => {
    if (!listIsOpened) setListToggleBatched(false);
  }, [listIsOpened]);

  useEffect(() => {
    if (listIsOpened) setListIsOpened(false);
  }, [currentPage, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === Status.error) {
      setListIsOpened(false);
    }
  }, [status]);

  return {
    batchListToggle,
    contents: preloadContents,
    listIsOpened,
    loading: status === Status.loading,
    hasNext,
    hasPrev,
    listToggleBatched,
    toggleList
  };
};
