import {useCallback} from 'react';
import {useIntl} from 'react-intl';
import {IS_IOS, ReactPaint, usePaint, withPaint} from '@englex/paint-react';
import {Paint, PaintNode} from '@englex/paint';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';

function iOSCopyToClipboard(string: string) {
  let textarea: HTMLTextAreaElement | null = null;
  let result: boolean = false;

  try {
    textarea = document.createElement('textarea');
    textarea.readOnly = true;
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus({preventScroll: true});
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);
    const sel = window.getSelection();
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
      textarea.setSelectionRange(0, textarea.value.length);
      result = document.execCommand('copy');
    }
  } catch (err) {
    result = false;
  } finally {
    textarea && document.body.removeChild(textarea);
  }
  return result;
}

export const CopyButton = withPaint(() => {
  const paint = usePaint<ReactPaint>();
  const isEnabled = ReactPaint.canCopyNode(paint);
  const intl = useIntl();

  const onClick = useCallback(async () => {
    if (!isEnabled) return;
    const res = document.execCommand?.('copy');
    if (!res || IS_IOS) {
      const [selectedNode] = Paint.selectedEntry(paint);
      const dataUrl = selectedNode && PaintNode.toDataUrl(selectedNode);
      dataUrl && iOSCopyToClipboard(dataUrl);
    }
  }, [isEnabled, paint]);

  return (
    <ToolbarButton
      onClick={onClick}
      disabled={!isEnabled}
      shortcut="mod+c"
      title={intl.formatMessage(buttonTitles.copy)}
    >
      <Icon name="copy" />
    </ToolbarButton>
  );
}, ReactPaint.isClipboardEnabled);
