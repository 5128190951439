import React from 'react';
import classNames from 'classnames';

import {type Room, type StudentTeacher} from 'store/interface';
import {userFullName} from 'helpers/user';
import preventFilterRegexpErrors from 'helpers/toValidFilter';
import SelectPartnerPopover from 'components/SelectPartnerPopover';
import PrivateUser from 'components/Chat/PrivateUser';

export default class StudentTeacherPopover extends SelectPartnerPopover<StudentTeacher> {
  public isElementActive = (studentTeacher: StudentTeacher) => !studentTeacher.deleted_at;

  public elementMatchesSearchFilter = (studentTeacher: StudentTeacher) =>
    userFullName(studentTeacher.recipient.profile!)
      .toLowerCase()
      .search(preventFilterRegexpErrors(this.props.filter)) !== -1;

  public renderElement = (studentTeacher: StudentTeacher) => {
    const {currentStudentTeachersId} = this.props;
    return (
      <li
        key={studentTeacher.id}
        onClick={() => {
          this.props.hidePopover();
          this.props.partnerSelectedHandler(studentTeacher.id);
        }}
        className={classNames('additional-padding', {
          selected:
            currentStudentTeachersId && Number(currentStudentTeachersId) === studentTeacher.id
        })}
      >
        <PrivateUser
          isCompact={true}
          name={userFullName(studentTeacher.recipient.profile!)}
          avatar={studentTeacher.recipient.profile!.avatars.xs}
        />
      </li>
    );
  };

  protected sortByAlphabet = (el1: StudentTeacher | Room, el2: StudentTeacher | Room) => {
    if (
      userFullName((el1 as StudentTeacher).recipient.profile!).toUpperCase() >
      userFullName((el2 as StudentTeacher).recipient.profile!).toUpperCase()
    ) {
      return 1;
    }
    return -1;
  };

  protected sortActiveRooms = (elements: StudentTeacher[]) => {
    return elements.sort(this.sortByAlphabet);
  };
}
