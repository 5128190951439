import {type RendererPlugin} from '../../interface';
import Orange from './Orange';
import Red from './Red';
import Brown from './Brown';
import Blue from './Blue';
import Green from './Green';
import Purple from './Purple';

class Color implements RendererPlugin {
  public plugins = [new Orange(), new Red(), new Brown(), new Blue(), new Green(), new Purple()];
}

export default Color;
