export enum UploadingPictureStatus {
  UPLOADING_ERROR = 'UPLOADING_ERROR',
  CLONING = 'CLONING',
  UPLOADING = 'UPLOADING',
  PREPARING = 'PREPARING',
  FINISHED = 'FINISHED'
}

export enum UploadingPictureValidationError {
  FILE_TOO_BIG = 'FILE_TOO_BIG',
  INVALID_EXTENSION = 'INVALID_EXTENSION',
  EMPTY_FILE = 'EMPTY_FILE',
  ALREADY_UPLOADING = 'ALREADY_UPLOADING',
  FILE_CORRUPTED = 'FILE_CORRUPTED',
  INVALID_IMAGE_SIZE = 'INVALID_IMAGE_SIZE',
  IMAGE_SIZE_TOO_SMALL = 'IMAGE_SIZE_TOO_SMALL'
}
