import {type Descendant, Element, Node, Text} from 'slate';
import {
  type BlockJSON,
  type InlineJSON,
  type LeafJSON,
  type MarkJSON,
  type TextJSON,
  type ValueJSON
} from '@englex/slate';

import {valueJSONFromText} from 'components/Slate/utils';
import {SlateInline, SlateMark} from 'components/Slate/interface';
import {enumValues} from 'helpers/enum';

import {type CustomBlock} from '../interface';

type OldTextJSON = TextJSON & {leaves?: LeafJSON[]};
type OldElementJSON = BlockJSON | InlineJSON;

type OldNodeJSON = OldElementJSON | OldTextJSON;
type NodeProps = {[key: string]: never};

// utils

/**
 * Slate JSON in versions less than 0.46 used to contain text.leaves (leaf nodes with marks)
 * There are tons of exercises in such an obsolete format on the backend side
 * This helper migrates old slate text nodes with leaves by moving all leaf marks as '0.46 < slate < 0.47' text nodes
 * @param nodes
 */
const removeLeaves = (nodes: Array<OldNodeJSON>): Array<OldElementJSON | TextJSON> => {
  if (!nodes) {
    return [];
  }
  const cleanedNodes = nodes.reduce((acc, node) => {
    const leaves = (node as OldTextJSON).leaves;
    if (leaves) {
      // we don't need the node itself, as we exepct it to be a text node
      return [
        ...acc,
        ...leaves.map((leave: LeafJSON) => ({
          ...leave,
          object: 'text'
        }))
      ];
    } else {
      const nodes = (node as OldElementJSON).nodes as (OldElementJSON & OldTextJSON)[];
      const cleanedNode = nodes
        ? {
            ...node,
            nodes: removeLeaves(nodes)
          }
        : node;
      return [...acc, cleanedNode];
    }
  }, []);

  return cleanedNodes as never;
};

const decapitalize = (str: string): string => {
  return str[0].toLowerCase() + str.slice(1);
};

const capitalize = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1);
};

const isClassNameMark = (type: string): boolean => {
  const types: string[] = [SlateMark.FONT_SIZE, SlateMark.COLOR, SlateMark.HIGHLIGHT].map(
    decapitalize
  );
  return types.includes(decapitalize(type));
};

const isInline = (type: string): boolean => {
  const types: string[] = enumValues(SlateInline).map(decapitalize);
  return types.includes(decapitalize(type));
};

// migrate up

const migrateMarksUp = (props: NodeProps, mark: MarkJSON): {[key: string]: boolean | string} => {
  const type = decapitalize(mark.type);
  const markData: {[key: string]: boolean | string} = mark.data || {};
  const {className, ...data} = markData;

  let value: string | boolean = true;

  if (isClassNameMark(type)) {
    value = className;
  }
  return {
    ...props,
    ...data,
    [type]: value
  };
};

const migrateTextNodeUp = (oldNode: OldTextJSON): Text => {
  return {
    text: oldNode.text,
    ...(oldNode.marks?.reduce(migrateMarksUp, {}) ?? {})
  };
};

const migrateNodeUp = (oldNode: OldNodeJSON): Node => {
  if (oldNode.object === 'text') {
    return migrateTextNodeUp(oldNode);
  } else {
    return migrateElementNodeUp(oldNode as OldElementJSON);
  }
};

const migrateElementNodeUp = (node: OldElementJSON): Element => {
  const element = {
    ...node.data,
    children: node.nodes?.map(migrateNodeUp) ?? []
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as Element;
  if (node.type !== 'paragraph') {
    element.type = decapitalize(node.type) as never;
  }
  return element;
};

// migrate down

const migrateElementDown = (el: Element): OldElementJSON => {
  const {type = 'paragraph', ...node} = el;
  const data = Node.extractProps(node as CustomBlock);

  const object = isInline(type) ? 'inline' : 'block';

  const element = {
    object,
    type: type === 'paragraph' ? type : capitalize(type),
    data,
    nodes: node.children.map(migrateNodeDown)
  } as OldElementJSON;

  return element;
};

const migrateTextDown = (node: Text): TextJSON => {
  const data = Node.extractProps(node);
  const marks = Object.keys(data).length
    ? Object.entries(data).reduce<MarkJSON[]>((res, [k, v]) => {
        const type = capitalize(k);
        const object: MarkJSON = {
          object: 'mark',
          type,
          data: {}
        };
        if (isClassNameMark(type)) {
          // TODO: improve here
          const {data = {}} = object;
          data.className = v;
          object.data = data;
        }
        res.push(object);
        return res;
      }, [])
    : [];

  const text: TextJSON = {
    object: 'text',
    text: node.text,
    marks: marks
  };

  return text;
};

const migrateNodeDown = (node: Element | Text): OldNodeJSON => {
  if (Element.isElement(node)) {
    return migrateElementDown(node);
  } else if (Text.isText(node)) {
    return migrateTextDown(node);
  } else {
    throw new Error(`Down conversion failed - unkonwn node type ${node}`);
  }
};

const slateRemoveLeaves = (value: ValueJSON): ValueJSON => {
  const {document} = value;
  const nodes = document?.nodes!;

  return {
    ...value,
    document: {
      ...document,
      nodes: removeLeaves(nodes as OldNodeJSON[])
    }
  };
};

const slateMigrateUp = (value: ValueJSON): Descendant[] => {
  const {document: {nodes} = {document: {nodes: []}}} = value || valueJSONFromText('text');

  return removeLeaves(nodes as OldElementJSON[]).map(migrateNodeUp) as Descendant[];
};

const slateMigrateDown = (children: Descendant[]): ValueJSON => {
  return {
    object: 'value',
    document: {
      object: 'document',
      data: {},
      nodes: children.map(migrateNodeDown)
    }
  };
};

export {slateMigrateUp, slateMigrateDown, slateRemoveLeaves};
