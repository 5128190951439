import {connect} from 'react-redux';
import {type Action, type Dispatch} from 'redux';

import {type AppState} from 'store/interface';

import MediaSettingsModal, {
  type DispatchMediaSettingsProps,
  type MediaSettingsStateProps
} from './MediaSettingsWizard';
import {
  type Devices,
  changePermissions,
  saveDevices,
  saveModalSelection,
  selectCam,
  selectMic,
  setDevices,
  setNoCamsAvailable,
  setNoMicsAvailable,
  skipGreeting,
  toggleDevicesModal
} from './action';

type MediaMapStateToProps = (state: AppState) => MediaSettingsStateProps;
type MediaMapDispatchToProps = (dispatch: Dispatch<Action>) => DispatchMediaSettingsProps;

const mapStateToProps: MediaMapStateToProps = state => ({
  autoShowModal: state.mediaDevices.skipGreeting === undefined,
  showGreeting: !state.mediaDevices.skipGreeting,
  browserHasRTC: state.rtc.badBrowser === false,
  showModal: state.mediaDevices.showModal,
  micAccess: state.mediaDevices.micAccess,
  camAccess: state.mediaDevices.cameraAccess,
  audioDevices: state.mediaDevices.available
    ? state.mediaDevices.available.filter(device => device.kind === 'audioinput')
    : undefined,
  videoDevices: state.mediaDevices.available
    ? state.mediaDevices.available.filter(device => device.kind === 'videoinput')
    : undefined,
  locale: state.intl.locale,
  micLabel: state.mediaDevices.micLabel,
  camLabel: state.mediaDevices.camLabel,
  modalSelectedMic: state.mediaDevices.modalSelectedMic,
  modalSelectedCam: state.mediaDevices.modalSelectedCam,
  noCamsAvailable: state.mediaDevices.noCamsAvailable,
  noMicsAvailable: state.mediaDevices.noMicsAvailable,
  callInProgress: Boolean(state.rtc.call),
  appVersion: state.common.version
});

const mapDispatchToProps: MediaMapDispatchToProps = dispatch => ({
  toggleModal: (show: boolean) => dispatch(toggleDevicesModal(show)),
  skipGreeting: () => dispatch(skipGreeting()),
  changePermissions: (options: MediaStreamConstraints) => dispatch(changePermissions(options)),
  saveDevices: (devices: MediaDeviceInfo[], mic?: MediaDeviceInfo, cam?: MediaDeviceInfo) =>
    dispatch(saveDevices(devices, mic, cam)),
  selectCam: (device: MediaDeviceInfo) => dispatch(selectCam(device.deviceId, device.label)),
  selectMic: (device: MediaDeviceInfo) => dispatch(selectMic(device.deviceId, device.label)),
  saveModalSelection: () => dispatch(saveModalSelection()),
  setNoCamsAvailable: (notAvailable: boolean) => dispatch(setNoCamsAvailable(notAvailable)),
  setNoMicsAvailable: (notAvailable: boolean) => dispatch(setNoMicsAvailable(notAvailable)),
  skipAutoShowModal: () => dispatch(skipGreeting(false)),
  setDevices: (devices: Devices) => dispatch(setDevices(devices))
});

const MediaSettingsWizard = connect(mapStateToProps, mapDispatchToProps)(MediaSettingsModal);

export default MediaSettingsWizard;
