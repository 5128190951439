import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';
import {
  type Dispatch,
  type WampErrorAction,
  type WampSubscribeAction
} from 'services/wamp/actions/interface';
import {defaultAutobahnClientName, UNSUBSCRIBE_POSTFIX} from 'services/wamp/actions/types';

type SimpleArgsArray = Array<number | string | boolean | Function>;

const noop = () => undefined;

type Subscribe = () => () => void;

export const useWampSubscription = <
  A extends SimpleArgsArray = SimpleArgsArray,
  Args = [],
  Kw = {}
>(
  actionCreator: (...args: A) => WampSubscribeAction<Args, Kw>,
  ...args: A
) => {
  const dispatch: Dispatch<Action, AppState> = useDispatch();

  const [isSubscribing, setIsSubscribing] = useState(true);
  const subscriptions = useSelector((state: AppState) => state.wamp.subscriptions);
  const action = useMemo(() => actionCreator(...args), [actionCreator, ...args]); // eslint-disable-line react-hooks/exhaustive-deps
  const [error, setError] = useState<WampErrorAction<[string], unknown, typeof action> | null>(
    null
  );
  const isOnline = useSelector((state: AppState) => state.wamp.status === 'CONNECTED');
  const isSubscribed = subscriptions.some(s => s === action.wamp.uri);

  const subscribe: Subscribe = useCallback(() => {
    if (!isOnline) return noop;

    const unsubscribe = () => {
      if (isSubscribed && isOnline) {
        const client = action.client || defaultAutobahnClientName;

        dispatch({
          type: action.type + UNSUBSCRIBE_POSTFIX,
          client,
          wamp: {
            method: 'unsubscribe',
            uri: action.wamp.uri
          }
        });
      }
    };

    if (isSubscribed) return unsubscribe;

    setIsSubscribing(true);
    setError(null);

    dispatch(action)
      .catch(e => setError(e))
      .finally(() => setIsSubscribing(false));

    return unsubscribe;
  }, [dispatch, action, isOnline, isSubscribed]);

  const wampSubscribe = useCallback(() => {
    const unsubscribe = subscribe();

    return () => {
      unsubscribe();
    };
  }, [subscribe]);

  useEffect(wampSubscribe, [wampSubscribe]);

  return {
    isOnline,
    isSubscribed,
    isSubscribing,
    error,
    retry: wampSubscribe
  };
};
