// TODO: object should be used instead of enum to be able to full support Shortcut type as value
export enum SlateHotkey {
  Undo = 'mod+z',
  Redo = 'mod+shift+z',
  Bold = 'mod+b',
  Italic = 'mod+i',
  Underline = 'mod+u',
  StrikeThrough = 'mod+shift+x',
  Link = 'mod+k',
  UnorderedList = 'mod+shift+7',
  OrderedList = 'mod+shift+8',
  OrderedListAlpha = 'mod+shift+9',
  SoftBreak = 'shift+enter',
  Enter = 'enter',
  Delete = 'delete',
  Backspace = 'backspace',
  Left = 'left',
  Right = 'right',
  ToggleExample = 'mod+opt+e'
}
