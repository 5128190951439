import {valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type WidgetToJSONOptions, WidgetType} from 'store/exercise/player/interface';
import {type HorizontalStyledListJSON} from 'store/exercise/player/widgets/List/StyledList/interface';

import {type XHorizontalStyledListProperties, type XHorizontalStyledListProps} from './interface';
import XStyledList from './XStyledListRecord';

class XHorizontalStyledListRecord extends XStyledList implements XHorizontalStyledListProperties {
  public declare readonly withNumbering: boolean;

  constructor(raw: XHorizontalStyledListProps) {
    super(raw);
    this.initValues({
      withNumbering: !!raw?.withNumbering
    });
  }

  public switchWithNumbering() {
    return this.set('withNumbering', !this.withNumbering);
  }

  public toJSON(options?: WidgetToJSONOptions): HorizontalStyledListJSON {
    return {
      ...super.toJSON(options),
      withNumbering: this.withNumbering
    };
  }

  public get type() {
    return WidgetType.HORIZONTAL_STYLED_LIST;
  }
}

decorate(XHorizontalStyledListRecord, {
  content: property(valueFromText()),
  styleName: property(''),
  withNumbering: property()
});
record()(XHorizontalStyledListRecord);
export default XHorizontalStyledListRecord;
