import {type FC, memo, useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useIntl} from 'react-intl';
import {useDispatch} from 'react-redux';

import Icon from 'components/Icon';
import {useCloseSidebarPanels} from 'components/Dictionary/hooks/useCloseSidebarDictionaryPanels';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {setSearch} from 'store/dictionary/actions';
import {DictionarySearchMode} from 'common/enums';

import './CloseButton.scss';

export const CloseButton: FC = memo(() => {
  const {formatMessage} = useIntl();
  const dispatch = useDispatch();

  const {closeSidebarPanels} = useCloseSidebarPanels();
  const {
    closeResultOfSearchingInOD,
    setSearchFieldValue,
    setSearchMode,
    changeSearchValue,
    setCurrentSearchWord
  } = useSearchingInODContext();

  const onClick = useCallback(() => {
    closeSidebarPanels();

    dispatch(setSearch(undefined));
    setSearchFieldValue('');
    closeResultOfSearchingInOD();
    setSearchMode(DictionarySearchMode.MY_DICTIONARY);
    changeSearchValue('');
    setCurrentSearchWord('');
  }, [
    changeSearchValue,
    closeResultOfSearchingInOD,
    closeSidebarPanels,
    dispatch,
    setCurrentSearchWord,
    setSearchFieldValue,
    setSearchMode
  ]);

  return (
    <Button
      className="close-button"
      bsStyle="success"
      onClick={onClick}
      title={formatMessage({id: 'Common.Cancel'})}
    >
      <span className="icon-container">
        <Icon name="close" />
      </span>
    </Button>
  );
});
