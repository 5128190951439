import genKey from 'components/Slate/utils/genKey';
import {type ImageLabelingCardJSON} from 'store/exercise/player/widgets/ImageLabeling/interface';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';

import {type XImageLabelingCardProperties, type XImageLabelingCardProps} from './interface';
import {CardSizeType} from '../../XWordPictureSet/XPictureSet/interface';

const Record = recordBase()(BaseRecord);

class XImageLabelingCardRecord extends Record implements XImageLabelingCardProperties {
  public declare readonly id: string;

  public declare readonly imageId: number;

  public declare readonly example: boolean;

  public declare readonly editable: boolean;

  public declare readonly placeholder: string;

  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: Partial<XImageLabelingCardProps> = {}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      imageId: raw.imageId || '',
      example: raw.example,
      editable: raw.editable,
      placeholder: raw.placeholder || '',
      cardSizeType: raw?.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toCardJSON(): ImageLabelingCardJSON {
    return {
      id: this.id,
      imageId: this.imageId,
      example: this.example,
      editable: this.editable,
      placeholder: this.placeholder,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(XImageLabelingCardRecord, {
  id: property(genKey()),
  imageId: property(''),
  example: property(),
  editable: property(),
  placeholder: property(''),
  cardSizeType: property('')
});
record()(XImageLabelingCardRecord);
export default XImageLabelingCardRecord;
