import {Value} from '@englex/slate';
import {defineMessages, type IntlShape, type MessageDescriptor} from 'react-intl';
import * as yup from 'yup';

import {type NoteJSON, NoteType} from 'store/exercise/player/widgets/Note/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {widgetTaskMaxLength} from 'config/static';

import {WidgetTitle, type WidgetToJSONOptions, WidgetType} from '../../../player/interface';
import {type XNoteProperties} from './interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import {noteDataByType} from '../../../../../components/XPlayer/widgets/Note/noteDataByType';
import validationMessages from '../i18n';
import {documentNotEmpty, taskMaxLength} from '../validation';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

const messages = defineMessages({
  placeholder: {
    id: 'XEditor.XWidget.Task.XNote'
  },
  howToPlaceholder: {
    id: 'XEditor.XWidget.Task.XHowTo'
  }
});

class XNoteRecord extends XFormattedTextRecord implements XNoteProperties {
  public declare readonly noteType: NoteType;
  public declare readonly isCollapsed?: boolean;

  constructor(raw: NoteJSON) {
    super(raw);
    this.initValues({
      content: Value.fromJSON(raw.content),
      noteType: raw.noteType,
      isCollapsed: this.getDefaultCollapsed(raw.isCollapsed, raw.noteType)
    });
  }

  public toJSON(options?: WidgetToJSONOptions): NoteJSON {
    return {
      ...super.toJSON(options),
      noteType: this.noteType,
      isCollapsed: this.isCollapsed || undefined
    };
  }

  public get taskPlaceholder(): MessageDescriptor {
    return this.noteType === NoteType.HOW_TO_STRATEGIES
      ? messages.howToPlaceholder
      : messages.placeholder;
  }

  private getDefaultCollapsed(isCollapsed: boolean | undefined, noteType: NoteType) {
    if (isCollapsed !== undefined) return isCollapsed;

    return noteType === NoteType.HOW_TO_STRATEGIES ? false : undefined;
  }

  public get type() {
    return WidgetType.NOTE;
  }

  public get title(): string {
    return `${WidgetTitle[this.type]} (${
      noteDataByType.find(data => data.type === this.noteType)!.title
    })`;
  }

  public get titleIconName(): string {
    return noteDataByType.find(data => data.type === this.noteType)!.iconName;
  }

  public get customBodyClass() {
    return this.noteType;
  }

  public toggleCollapsed(): this {
    return this.set('isCollapsed', !this.isCollapsed);
  }

  public get isCollapsible(): boolean {
    return this.noteType === NoteType.HOW_TO_STRATEGIES;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ContentNonEmpty),
          (v: Value) => documentNotEmpty(v.document)
        ),
      task: yup
        .mixed()
        .test(
          'Task should not be empty',
          intl.formatMessage(validationMessages.HowToTaskNotEmpty),
          (v: Value) =>
            this.noteType === NoteType.HOW_TO_STRATEGIES && v ? documentNotEmpty(v.document) : true
        )
        .test(
          'Task max length',
          intl.formatMessage(validationMessages.TaskMaxLength, {
            maxCharCount: widgetTaskMaxLength
          }),
          (v: Value) => taskMaxLength(v.document, widgetTaskMaxLength)
        ),
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }
}

decorate(XNoteRecord, {
  noteType: property(NoteType.SPEAKING_FOCUS),
  isCollapsed: property()
});
record()(XNoteRecord);
export default XNoteRecord;
