import React, {type PropsWithChildren} from 'react';
import classNames from 'classnames';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';

import Icon from 'components/Icon';

export interface EnglexDropdownStateProps {
  isMobile?: boolean;
}

interface EnglexDropdownOwnProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title?: any;
  dropdownOpen?: boolean;
  toggle: (show: boolean) => void;
  id: string;
}

interface Props extends EnglexDropdownStateProps, EnglexDropdownOwnProps {}

export default class EnglexDropdown extends React.PureComponent<PropsWithChildren<Props>, {}> {
  public render() {
    const {id, children, dropdownOpen, isMobile, title} = this.props;
    return (
      <NavDropdown
        className={classNames('nav-dropdown', 'profile-dropdown', {mobile: isMobile})}
        id={id}
        onToggle={this.toggle}
        open={dropdownOpen}
        noCaret={isMobile}
        title={title}
        onSelect={this.toggle}
      >
        <button className="dropdown-close-button" onClick={this.toggle}>
          <Icon name="pc-close" />
        </button>
        {children}
      </NavDropdown>
    );
  }

  private toggle = () => {
    this.props.toggle(!this.props.dropdownOpen);
  };
}
