import React, {type FC, useCallback, useMemo, useRef, useState} from 'react';
import classNames from 'classnames';

import PronunciationSoundPlayer, {
  type IntlPronunciationSoundPlayer,
  type PronunciationPlayerStatus
} from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';

import {type DictionaryEntryInstance} from '../../interface';
import {OriginalView} from './components';

interface Props {
  search?: string[];
  wordEntry: DictionaryEntryInstance;
}

export const Original: FC<Props> = ({search, wordEntry}) => {
  const {dictionaryEntry, phoneticSpelling, pronunciation} = wordEntry;
  const [playStatus, setPlayStatus] = useState<PronunciationPlayerStatus>(null);

  const playerRef = useRef<IntlPronunciationSoundPlayer | null>(null);
  const soundWithPronunciationRef = useRef<HTMLSpanElement | null>(null);

  const playPronunciation = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
    // if user has selected word title, we shouldn't play sound
    const userSelection = window.getSelection();
    if (userSelection?.toString().length) {
      const selectionRange = userSelection.getRangeAt(0);
      const parentNode = selectionRange.startContainer.parentNode;
      if (
        parentNode &&
        soundWithPronunciationRef.current &&
        parentNode === soundWithPronunciationRef.current
      ) {
        return e.stopPropagation();
      }
    }
    playerRef.current?.playSound();
  }, []);

  const viewProps = useMemo(
    () => ({
      original: dictionaryEntry.original,
      phoneticSpelling,
      playStatus,
      pronunciation,
      search
    }),
    [dictionaryEntry.original, phoneticSpelling, playStatus, pronunciation, search]
  );

  return (
    <div className="original">
      {pronunciation ? (
        <PronunciationSoundPlayer
          ref={playerRef}
          pronunciation={pronunciation}
          onStatusChange={setPlayStatus}
        >
          <div
            className={classNames('pronunciation-sound-player', [playStatus])}
            onClick={playPronunciation}
          >
            <OriginalView ref={soundWithPronunciationRef} {...viewProps} />
          </div>
        </PronunciationSoundPlayer>
      ) : (
        <OriginalView {...viewProps} />
      )}
    </div>
  );
};
