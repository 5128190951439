import {useCallback, useEffect, useMemo, useState} from 'react';

import {useApiRequest} from 'hooks/rest/useApiRequest';

import {type DocumentInstancePage} from '../../components/FilesWorkSpace/documentsTab/actions/interface';
import {loadHomeworkDocumentPage} from './actions';

export function useHomeworkDocumentPage(
  courseId: number,
  homeworkId: string,
  documentInstancePageId: number
) {
  const [docInstancePage, setDocInstancePage] = useState<DocumentInstancePage | undefined>();

  const action = useMemo(
    () => loadHomeworkDocumentPage(courseId!, homeworkId, documentInstancePageId),
    [courseId, homeworkId, documentInstancePageId]
  );

  const successHandler = useCallback(
    (response: {documentInstancePage: DocumentInstancePage}) =>
      setDocInstancePage(response.documentInstancePage),
    []
  );
  useEffect(
    () => () => {
      setDocInstancePage(undefined);
    },
    [documentInstancePageId]
  );

  const {isError, reload} = useApiRequest(action, successHandler);

  return {docInstancePage, isError, reload};
}
