import {toBase64} from 'lib0/buffer';

import {
  type SubscribeCallback,
  type WampCallAction,
  type WampSubscribeAction
} from 'services/wamp/actions/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

const SUBSCRIBE_Y_DOC = 'SUBSCRIBE_Y_DOC';
const UPDATE_Y_DOC = 'UPDATE_Y_DOC';
const REQUEST_Y_DOC = 'REQUEST_Y_DOC';

export const subscribeYDoc = (
  topic: string,
  docId: string,
  callback: SubscribeCallback<unknown, unknown>
): WampSubscribeAction<unknown, unknown> => {
  return {
    type: SUBSCRIBE_Y_DOC,
    wamp: {
      uri: `${topic}._${docId}.sync.event`,
      method: 'subscribe',
      rejectOnError: true,
      options: {
        match: 'prefix'
      }
    },
    callback
  };
};
export const updateYDoc = (
  topic: string,
  docId: string,
  data: string
): WampCallAction<[string], never> => {
  return {
    type: UPDATE_Y_DOC,
    wamp: {
      uri: `${topic}._${docId}.sync`,
      method: 'call',
      args: [data],
      rejectOnError: true
    }
  };
};

export const requestYDoc = (docId: string, stateVector?: Uint8Array): AxiosRequestAction => ({
  type: REQUEST_Y_DOC,
  payload: {
    client: 'v2',
    request: {
      method: stateVector ? 'post' : 'get',
      url: `/v2/y-doc/${docId}`,
      data: stateVector
        ? {
            stateVector: toBase64(stateVector)
          }
        : undefined
    }
  }
});
