import React, {type FC, Fragment, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import {useDispatch, useSelector} from 'react-redux';
import {splitSearchByFirstBraces} from '@englex/trainer';

import {type AppState} from 'store/interface';
import {clearAllEntries, updateAllEntries} from 'store/dictionary/actions';
import {requestDictionaryInstanceChunk} from 'store/dictionary/requests';
import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {type AxiosResponseAction} from 'services/axios/interface';
import {dictionaryPageSize} from 'config/static';
import InfiniteScroll from 'components/InfiniteScroll';
import Loader from 'components/Loader';
import DateSeparator from 'components/DateSeparator/DateSeparator';
import WampErrorMask from 'components/WampErrorMask';

import {type DictionaryEntryInstance} from '../shared/interface';
import {useScrollSaver} from '../shared/WordsList/useScrollSaver';
import {WordsList} from '../shared/WordsList/WordsList';
import {WordEntry} from '../shared/WordsList/WordEntry';
import {useLocaleDate} from '../hooks/useLocaleDate';
import {NoEntries} from '../shared/NoEntries';
import {DateSeparatorController} from './DateSeparatorController';
import {DictionaryActions} from './DictionaryActions';
import {DictionaryArticle} from './DictionaryArticle/DictionaryArticle';
import {useSearchingInODContext} from './DictionaryArticle/contexts/searchingInODContext';
import {useDictionaryContext} from '../shared/contexts';

interface Props {
  hasError: boolean;
  hasLists: boolean;
  resizeHandler(height: number): void;
  setHasError(hasError: boolean): void;
}

export const DictionaryEntries: FC<Props> = ({hasError, hasLists, resizeHandler, setHasError}) => {
  const dispatch = useDispatch();
  const axiosDispatch = useAxiosDispatch();
  const {dictionaryOwnerId} = useDictionaryContext();

  const dictionaryIsReadonly = useSelector<AppState, boolean>(s => !!s.dictionary?.isReadonly);
  const timezone = useSelector<AppState, string | undefined>(s => s.dictionary?.overview?.tz);
  const search = useSelector<AppState, string | undefined>(s => s.dictionary?.search || '');
  const entries = useSelector<AppState, DictionaryEntryInstance[]>(
    s => s.dictionary?.entries || []
  );
  const currentStudent = useSelector((state: AppState) => state.dictionary?.studentId);

  const wordSearch = useMemo(
    () => (search ? splitSearchByFirstBraces(search) : undefined),
    [search]
  );

  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const {isSearchingInOD, isSearching} = useSearchingInODContext();

  const pageNumber = useRef(0);
  const lastEntry = useRef<DictionaryEntryInstance>();
  const scrollbarRef = useRef<Scrollbars>(null);
  const infiniteScrollRef = useRef<InfiniteScroll>(null);
  const cancel = useRef(false);
  const {saveScrollPosition, scrollToSavedPosition} = useScrollSaver(scrollbarRef.current);
  const initialized = useRef(false);
  const datesRecord = useLocaleDate(entries, timezone);

  const loadEntries = useCallback(() => {
    setLoading(true);
    cancel.current = false;
    return axiosDispatch(
      requestDictionaryInstanceChunk(currentStudent || dictionaryOwnerId, 'student', {
        before: lastEntry.current?.updatedAt,
        beforeId: lastEntry.current?.id,
        search
      })
    )
      .then(({payload: {data}}: AxiosResponseAction<DictionaryEntryInstance[]>) => {
        if (cancel.current) return;
        saveScrollPosition(true);
        pageNumber.current++;
        initialized.current = true;
        lastEntry.current = data.length ? data[data.length - 1] : undefined;
        if (data.length < dictionaryPageSize) setHasMore(false);
        dispatch(updateAllEntries(data, pageNumber.current));
        if (pageNumber.current === 1 && !isSearchingInOD) scrollbarRef.current?.scrollToBottom();
        if (hasError) setHasError(false);
        setLoading(false);
      })
      .catch(() => {
        if (cancel.current) return;
        setLoading(false);
        setHasError(true);
      });
  }, [
    axiosDispatch,
    currentStudent,
    dictionaryOwnerId,
    search,
    saveScrollPosition,
    dispatch,
    isSearchingInOD,
    hasError,
    setHasError
  ]);

  const clearEntries = useCallback(() => dispatch(clearAllEntries()), [dispatch]);

  const reset = useCallback(() => {
    if (!initialized.current) return;
    if (infiniteScrollRef.current && !isSearchingInOD) infiniteScrollRef.current.pageLoaded = 0;
    cancel.current = true;
    pageNumber.current = 0;
    lastEntry.current = undefined;
    clearEntries();
    setHasMore(true);
  }, [clearEntries, isSearchingInOD]);

  const onResize = useCallback(
    (_: number, height: number) => {
      resizeHandler(height);
    },
    [resizeHandler]
  );

  const entriesLengthRef = useRef(entries.length);

  useEffect(() => {
    if (
      pageNumber.current &&
      scrollbarRef.current &&
      entries.length > entriesLengthRef.current &&
      scrollbarRef.current.getScrollHeight() - scrollbarRef.current.getScrollTop() <=
        scrollbarRef.current.getClientHeight() * 2
    ) {
      !isSearchingInOD && scrollbarRef.current.scrollToBottom();
    } else if (pageNumber.current !== 1 && !isSearchingInOD) scrollToSavedPosition(true);
    entriesLengthRef.current = entries.length;
  }, [entries.length, isSearchingInOD, scrollToSavedPosition]);

  if (hasError) return <WampErrorMask reload={loadEntries} />;

  const isLoadingFirstPage = loading && !pageNumber.current;
  const allEntriesIsEmpty = !isLoadingFirstPage && !entries.length;
  return (
    <ReactResizeDetector onResize={onResize} refreshRate={16} refreshMode="throttle">
      <div
        className={classNames('dictionary-entries', {
          'initial-load': isLoadingFirstPage && !isSearchingInOD,
          'has-more': hasMore && !isSearchingInOD
        })}
      >
        <Scrollbars ref={scrollbarRef} autoHide={true}>
          <InfiniteScroll
            className={classNames({
              empty: allEntriesIsEmpty || isSearchingInOD
            })}
            hasMore={hasMore}
            initialLoad={!isSearchingInOD}
            isReverse={!isSearchingInOD}
            loader={!isSearchingInOD ? <Loader key="loader-key" /> : undefined}
            loadMore={!isSearchingInOD ? loadEntries : undefined}
            ref={infiniteScrollRef}
            threshold={300}
            useWindow={false}
          >
            <>
              {isSearchingInOD ? (
                <DictionaryArticle isSidebar={true} dictionaryIsReadonly={dictionaryIsReadonly} />
              ) : allEntriesIsEmpty && !isSearching ? (
                <NoEntries
                  dictionaryIsReadonly={dictionaryIsReadonly}
                  isCompact={true}
                  userId={dictionaryOwnerId}
                />
              ) : (
                <WordsList isSidebar={true} numberOfWords={entries.length} isLoading={loading}>
                  {entries.map(we => (
                    <Fragment key={we.id}>
                      {datesRecord[we.id] && (
                        <DateSeparator date={datesRecord[we.id]}>
                          {timezone && (
                            <DateSeparatorController timezone={timezone} updatedAt={we.updatedAt} />
                          )}
                        </DateSeparator>
                      )}
                      <WordEntry
                        dictionaryIsReadonly={dictionaryIsReadonly}
                        search={wordSearch}
                        wordEntry={we}
                        hasLists={hasLists}
                      />
                    </Fragment>
                  ))}
                </WordsList>
              )}
            </>
          </InfiniteScroll>
        </Scrollbars>
        <DictionaryActions
          entries={entries}
          hasLists={hasLists}
          studentId={dictionaryOwnerId}
          reset={reset}
          dictionaryIsReadonly={dictionaryIsReadonly}
        />
      </div>
    </ReactResizeDetector>
  );
};
