import {List} from 'immutable';

import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../interface';
import WidgetRecord from '../../WidgetRecord';
import FlipCardsCardRecord from './FlipCardsCardRecord';
import {
  type FlipCardsCardJSON,
  type FlipCardsJSON,
  type FlipCardsMetaData,
  type FlipCardsProperties
} from './interface';

class FlipCardsRecord
  extends WidgetRecord<string, List<FlipCardsMetaData>, unknown>
  implements FlipCardsProperties
{
  public declare readonly cards: List<FlipCardsCardRecord>;

  public declare readonly defaultFlipped: boolean;

  constructor(raw: FlipCardsJSON) {
    super(raw);
    this.initValues({
      cards: this.createCards(raw),
      defaultFlipped: raw.defaultFlipped,
      values: this.createVal(raw)
    });
  }

  public toJSON(): FlipCardsJSON {
    return {
      ...super.toJSON(),
      cards: this.cards.map((card: FlipCardsCardRecord) => card.toJSON()).toArray(),
      defaultFlipped: this.defaultFlipped,
      values: this.values.toArray()
    };
  }

  public flipCard(cardId: string) {
    const cardIndex = this.values.findIndex((value: FlipCardsMetaData) => value.cardId === cardId);
    const newValues = this.values.set(cardIndex, {
      ...this.values.get(cardIndex),
      flipped: !this.values.get(cardIndex).flipped
    });
    return this.set('values', newValues);
  }

  private createCards = (raw: FlipCardsJSON) => {
    return List(
      raw.cards.map((card: FlipCardsCardJSON) => {
        return new FlipCardsCardRecord({...card});
      })
    );
  };

  public createVal = (raw: FlipCardsJSON) => {
    if (raw.values) return List(raw.values);
    return List(
      raw.cards.map((card: FlipCardsCardJSON) => {
        return {cardId: card.id, flipped: raw.defaultFlipped};
      })
    );
  };

  public get type() {
    return WidgetType.FLIP_CARDS;
  }

  public setValuesFromJSON(values: FlipCardsMetaData[]): this {
    return this.withMutations(record => {
      record.set('values', List(values));
    });
  }

  public get isAvailableSelection() {
    return true;
  }
}

decorate(FlipCardsRecord, {
  cards: property(),
  defaultFlipped: property(false)
});
record()(FlipCardsRecord);
export default FlipCardsRecord;
