import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {LOADING, PLAYING} from '../../../components/media/interface';
import {
  ALLOW_AUDIO,
  CHANGE_ACTIVE_AUDIO,
  CHANGE_PLAY_STATUS,
  CHANGE_PLAYBACK_RATE,
  CHANGE_TIMESTAMP,
  CHANGE_VOLUME,
  CLEAR_FORCE_RESTART,
  SET_DURATION
} from './actionTypes';
import {type ChangeAudioAction, type ChangePlaybackRateAction} from './actions';
import {type XAudio, type XAudioState} from '../../interface';
import {
  type ChangePlayStatusAction,
  type ChangeTimestampAction,
  type ChangeVolumeAction,
  type SetDurationAction
} from '../interface';

const REDUCERS: ReducersMapObject = {
  [ALLOW_AUDIO]: (state: XAudioState) => {
    if (state) {
      const {volume} = state;
      return {volume};
    }
    return {timestamp: 0, volume: 1};
  },
  [CHANGE_ACTIVE_AUDIO]: (
    state: XAudioState,
    {audioFile, context}: ChangeAudioAction
  ): XAudioState => {
    if (!state) {
      return state;
    }

    if (!audioFile) {
      const {startedAt, audioFile: removedAudioFile, ...nextState}: XAudio = state;
      return {...nextState, context};
    }

    if (audioFile?.id === state.audioFile?.id) {
      return {...state, audioFile, context, startedAt: undefined, timestamp: 0};
    }

    return {
      ...state,
      audioFile,
      context,
      timestamp: 0,
      playStatus: LOADING
    };
  },
  [CHANGE_PLAY_STATUS]: (
    state: XAudioState,
    {playStatus, startedAt: actionStartedAt, forceRestart}: ChangePlayStatusAction
  ): XAudioState => {
    if (!state || !state.audioFile) {
      return state;
    }
    if (playStatus !== PLAYING) {
      const {audioFile, startedAt, ...nextState} = state;
      if (!playStatus) {
        return {
          ...nextState,
          playStatus: undefined,
          timestamp: 0
        };
      } else {
        return {...nextState, audioFile, playStatus};
      }
    }
    return {...state, startedAt: actionStartedAt, playStatus, forceRestart};
  },
  [CHANGE_PLAYBACK_RATE]: (
    state: XAudioState,
    {playbackRate}: ChangePlaybackRateAction
  ): XAudioState => (state ? {...state, playbackRate} : undefined),
  [CLEAR_FORCE_RESTART]: (state: XAudioState): XAudioState => {
    if (state) {
      const {forceRestart, ...rest} = state;
      return rest;
    }
    return state;
  },
  [CHANGE_TIMESTAMP]: (
    state: XAudioState,
    {startedAt, timestamp}: ChangeTimestampAction
  ): XAudioState => {
    if (!state || timestamp < 0) {
      return state;
    }
    return {...state, timestamp, startedAt};
  },
  [CHANGE_VOLUME]: (state: XAudioState, {volume}: ChangeVolumeAction): XAudioState => {
    if (!state) {
      return state;
    }
    return {...state, volume};
  },
  [SET_DURATION]: (state: XAudioState, {length}: SetDurationAction): XAudioState => {
    if (!state || !state.audioFile) {
      return state;
    }
    return {...state, audioFile: {...state.audioFile, length}};
  }
};

const initialState = undefined;

function audioReducer(state: XAudioState = initialState, action: Action): XAudioState {
  const reducer: Reducer<XAudioState> = REDUCERS[action.type];
  return reducer ? reducer(state, action) : state;
}

export default audioReducer;
