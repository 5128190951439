import {type Action} from 'redux';
import {type ThunkAction} from 'redux-thunk';
import {type Dispatch as AxiosDispatch} from 'redux-axios-middleware';
import {batch} from 'react-redux';

import {
  type DictionaryEntryInstance,
  type DictionaryList,
  type DictionaryOverview,
  type ListSort
} from 'components/Dictionary/shared/interface';

import {
  ACTIVATE_DICTIONARY,
  CLEAR_ALL_ENTRIES,
  DELETE_ENTRIES_FROM_LIST,
  DELETE_ENTRIES_FROM_STORE,
  DELETE_ENTRY_FROM_STORE,
  DELETE_FROM_LIST,
  DELETE_LIST_FROM_STORE,
  REPLACE_LIST,
  RESET_DICTIONARY,
  SET_DICTIONARY_OVERVIEW,
  SET_IS_READONLY,
  SET_IS_REVERSE,
  SET_LIST_ENTRIES,
  SET_SEARCH,
  SET_STUDENT_ID,
  SET_STUDENT_LISTS,
  SORT_LIST,
  UNSHIFT_LIST,
  UPDATE_ALL_ENTRIES,
  UPDATE_LIST_MEMBER_OF
} from './actionTypes';
import {type DictionaryOwnerRole} from '../../components/Dictionary/shared/contexts';
import {type AppState} from '../interface';
import {type AxiosResponseAction} from '../../services/axios/interface';
import {
  getDictionaryOverviewRequest,
  requestDictionaryInstanceChunk,
  requestDictionaryList,
  requestStudentLists
} from './requests';
import {dictionaryAllEntriesPath, teacherDictionaryAllEntriesPath} from '../../common/paths';

export interface SetStudentListsAction extends Action {
  lists: DictionaryList[];
}
export const setStudentLists = (lists: DictionaryList[]): SetStudentListsAction => ({
  type: SET_STUDENT_LISTS,
  lists
});

export interface SetDictionaryOverviewAction extends Action {
  overview: DictionaryOverview;
}
export const setDictionaryOverview = (
  overview: DictionaryOverview
): SetDictionaryOverviewAction => ({
  type: SET_DICTIONARY_OVERVIEW,
  overview
});

export interface EditDictionaryListAction extends Action {
  list: DictionaryList;
}
export const unshiftList = (list: DictionaryList): EditDictionaryListAction => ({
  type: UNSHIFT_LIST,
  list
});

export const replaceList = (list: DictionaryList): EditDictionaryListAction => ({
  type: REPLACE_LIST,
  list
});

export interface DeleteListFromStoreAction extends Action {
  listId: string;
}
export const deleteListFromStore = (listId: string): DeleteListFromStoreAction => ({
  type: DELETE_LIST_FROM_STORE,
  listId
});

export interface UpdateAllEntriesAction extends Action {
  entries: DictionaryEntryInstance[];
  page: number;
  isCallback?: boolean;
}
export const updateAllEntries = (
  entries: DictionaryEntryInstance[],
  page: number,
  isCallback?: boolean
): UpdateAllEntriesAction => ({
  type: UPDATE_ALL_ENTRIES,
  entries,
  page,
  isCallback
});

export interface SetListEntriesAction extends Action {
  entries: DictionaryEntryInstance[];
}
export const setListEntries = (entries: DictionaryEntryInstance[]) => ({
  type: SET_LIST_ENTRIES,
  entries
});

export interface SetSearchAction extends Action {
  search?: string;
}
export const setSearch = (search?: string): SetSearchAction => ({
  type: SET_SEARCH,
  search: search
});

export interface SetStudentIdAction extends Action {
  studentId: number;
}
export const setStudentId = (studentId: number): SetStudentIdAction => ({
  type: SET_STUDENT_ID,
  studentId
});

export interface DeleteEntryAction extends Action {
  entryId: string;
}
export const deleteFromList = (entryId: string) => ({type: DELETE_FROM_LIST, entryId});

export const deleteEntriesFromList = (entryIds: string[]) => ({
  type: DELETE_ENTRIES_FROM_LIST,
  entryIds
});

export const deleteEntryFromStore = (entryId: string) => ({type: DELETE_ENTRY_FROM_STORE, entryId});

export const deleteEntriesFromStore = (entryIds: string[]) => ({
  type: DELETE_ENTRIES_FROM_STORE,
  entryIds
});

export interface SetIsReverseAction extends Action {
  isReverse: boolean;
}
export const setIsReverse = (isReverse: boolean) => ({type: SET_IS_REVERSE, isReverse});

export const clearAllEntries = () => ({type: CLEAR_ALL_ENTRIES});

export const activateDictionary = () => ({type: ACTIVATE_DICTIONARY});

export const resetDictionary = () => ({type: RESET_DICTIONARY});

export interface SetListSortAction extends Action {
  listSort: ListSort;
}
export const setListSort = (listSort: ListSort) => ({
  type: SORT_LIST,
  listSort
});

export interface UpdateListMemberOfAction extends Action {
  entryIds: string[];
  isDeleted?: boolean;
}

export interface DictionaryEnriesIds extends Action {
  entryIds: string[];
}

export const updateListMemberOf = (
  entryIds: string[],
  isDeleted?: boolean
): UpdateListMemberOfAction => ({
  type: UPDATE_LIST_MEMBER_OF,
  entryIds,
  isDeleted
});

export interface SetReadonlyAction extends Action {
  isReadonly: boolean;
}

export const setIsReadonly = (isReadonly: boolean): SetReadonlyAction => ({
  type: SET_IS_READONLY,
  isReadonly
});
export const updateOverviews =
  (
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole,
    listId: string
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>) => {
    Promise.all([
      dispatch<AxiosResponseAction<DictionaryOverview>>(
        getDictionaryOverviewRequest(dictionaryOwnerId, dictionaryOwnerRole)
      ),
      dispatch<AxiosResponseAction<DictionaryList>>(requestDictionaryList(listId, ['overview']))
    ]).then(response => {
      const [overviewResponse, listResponse] = response;

      batch(() => {
        dispatch(setDictionaryOverview(overviewResponse.payload.data));
        dispatch(replaceList(listResponse.payload.data));
      });
    });
  };
export const updateOverviewAndLists =
  (
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>) => {
    Promise.all([
      dispatch<AxiosResponseAction<DictionaryOverview>>(
        getDictionaryOverviewRequest(dictionaryOwnerId, dictionaryOwnerRole)
      ),
      dispatch<AxiosResponseAction<DictionaryList[]>>(
        requestStudentLists(dictionaryOwnerId, dictionaryOwnerRole)
      )
    ]).then(response => {
      const [overviewResponse, listResponse] = response;

      batch(() => {
        dispatch(setDictionaryOverview(overviewResponse.payload.data));
        dispatch(setStudentLists(listResponse.payload.data));
      });
    });
  };
export const refreshList =
  (listId: string): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>) =>
    dispatch(requestDictionaryList(listId)).then(({payload: {data}}) =>
      dispatch(setListEntries(data.entryInstances))
    );

const getParams = (
  search: string
): {search?: string; fromCoursebook?: boolean; inLists?: string} => {
  const searchParams = new URLSearchParams(search);

  return Object.fromEntries([...searchParams.entries()]);
};

export const updateAllEntriesPage =
  (
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>, getState) => {
    const pathname = getState().router.location!.pathname;

    if (
      [
        dictionaryAllEntriesPath(dictionaryOwnerId),
        teacherDictionaryAllEntriesPath(dictionaryOwnerId)
      ].includes(pathname)
    ) {
      const search = getState().router.location!.search;
      const params = getParams(search);
      dispatch(requestDictionaryInstanceChunk(dictionaryOwnerId, dictionaryOwnerRole, params)).then(
        ({payload: {data}}: AxiosResponseAction<DictionaryEntryInstance[]>) => {
          dispatch(updateAllEntries(data, 1, true));
        }
      );
    } else {
      dispatch(maybeUpdateSidebarDictionary(dictionaryOwnerId, dictionaryOwnerRole));
    }
  };
const maybeUpdateSidebarDictionary =
  (
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>, getState) => {
    const dictionaryState = getState().dictionary;
    const selectedStudentId = dictionaryState?.studentId;
    const search = dictionaryState?.search;

    if (!selectedStudentId || dictionaryOwnerId !== selectedStudentId) return;
    dispatch(requestDictionaryInstanceChunk(dictionaryOwnerId, dictionaryOwnerRole, {search})).then(
      ({payload: {data}}: AxiosResponseAction<DictionaryEntryInstance[]>) => {
        dispatch(updateAllEntries(data, 1, true));
      }
    );
  };
