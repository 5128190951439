import {useCallback, useEffect, useState} from 'react';
import {type POrientation} from '@englex/paint';
import {type Size} from '@englex/paint-react';

import {isIOSMobileWebView} from 'helpers/browser';

import {renderPage} from './utils';
import {
  useWebViewMessage,
  type WebViewMessageData,
  WebViewMessageType
} from '../../../hooks/webview/useWebViewMessage';

export interface MobilePageAttrs {
  url: string;
  orientation: POrientation;
  size: Size;
}

export const useMobilePainterAttrs = (docUrl?: string, pageNumber = 1) => {
  const [attrs, setAttrs] = useState<MobilePageAttrs>();

  const [isError, setIsError] = useState(false);

  const renderAsWeb = useCallback(() => {
    if (!docUrl) return;

    setAttrs(undefined);
    setIsError(false);

    renderPage(docUrl, pageNumber)
      .then(setAttrs)
      .catch(error => {
        if (import.meta.env.MODE === 'development') {
          // eslint-disable-next-line no-console
          console.error(error);
        }

        setIsError(true);
      });
  }, [docUrl, pageNumber]);

  const onMessage = useCallback((message: WebViewMessageData) => {
    const {type} = message;

    if (type === WebViewMessageType.ImageFileInfo) {
      setAttrs(message.payload);
    }

    if (type === WebViewMessageType.FailedCreatingImage) {
      setIsError(true);
    }
  }, []);

  const postMessage = useWebViewMessage(onMessage, isIOSMobileWebView);

  const renderAsNative = useCallback(() => {
    if (!docUrl) return;

    setAttrs(undefined);
    setIsError(false);

    postMessage({type: WebViewMessageType.CreateImageFromPDFPage, payload: {docUrl, pageNumber}});
  }, [postMessage, docUrl, pageNumber]);

  const render = isIOSMobileWebView ? renderAsNative : renderAsWeb;

  useEffect(() => {
    render();
  }, [render]);

  return {attrs, isError, reload: render};
};
