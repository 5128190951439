import React, {type PropsWithChildren} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import lineImage from './assets/Line.svg';

import './Breadcrumb.scss';

interface Props extends PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  title?: string;
  titleElement?: React.ReactNode;
  icon?: string;
  iconTag?: 'span' | 'i' | 'a';
  iconElement?: React.ReactNode;
  iconClassName?: string;
  accessibilityTitle?: string;
  withDivider?: boolean;
}

export const Breadcrumb = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      className,
      title,
      titleElement,
      icon,
      iconTag,
      iconElement,
      iconClassName,
      accessibilityTitle,
      children,
      onClick,
      withDivider = true,
      ...rest
    },
    ref
  ) => {
    const shouldShowIcon = !!icon || !!iconElement;
    const shouldShowTitle = !!title || !!titleElement;

    return (
      <div
        ref={ref}
        className={classNames('breadcrumb', className)}
        title={accessibilityTitle}
        onClick={onClick}
        {...rest}
      >
        {shouldShowIcon && (
          <span className={classNames('breadcrumb-icon', iconClassName)}>
            {icon && <Icon name={icon} tag={iconTag} />}
            {React.isValidElement(iconElement) && iconElement}
          </span>
        )}
        {shouldShowTitle && (
          <span className="breadcrumb-title">
            {title}
            {React.isValidElement(titleElement) && titleElement}
          </span>
        )}
        {children}
        {withDivider && <img src={lineImage} className="triangle-divider" alt="" />}
      </div>
    );
  }
);
