import React from 'react';

import './wamp-error-mask.scss';
import {ErrorMask} from './ErrorMask';

interface WampErrorMaskProps {
  reload: () => void;
}

class WampErrorMask extends React.Component<WampErrorMaskProps, {}> {
  public render() {
    return <ErrorMask className="wamp-error-mask" reload={this.props.reload} />;
  }
}

export default WampErrorMask;
