import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';
import {type Action, type ActionCreator} from 'redux';

import {type Locale} from 'store/interface';
import Icon from 'components/Icon';

import GreetingFooter from './GreetingFooter';
import GreetingBody from './GreetingBody';
import SelectDevicesBody from './SelectDevicesBody';
import SelectDevicesFooter from './SelectDevicesFooter';
import {isSafari} from '../../../../helpers/browser';
import {type ToggleElementCreator} from '../../../../common/interface';
import {
  type Devices,
  type ChangePermissionsCreator,
  type NoDeviceAvailableCreator,
  type SelectDeviceCreator
} from './action';

import './media-settings-wizard.scss';

export interface MediaSettingsStateProps {
  showGreeting: boolean;
  autoShowModal: boolean;
  showModal?: boolean;
  browserHasRTC?: boolean;
  micAccess?: boolean;
  camAccess?: boolean;
  audioDevices?: MediaDeviceInfo[];
  videoDevices?: MediaDeviceInfo[];
  locale: Locale;
  micLabel?: string;
  camLabel?: string;
  modalSelectedCam?: string;
  modalSelectedMic?: string;
  modalSelectedCamLabel?: string;
  modalSelectedMicLabel?: string;
  noMicsAvailable?: boolean;
  callInProgress?: boolean;
  noCamsAvailable?: boolean;
  appVersion: string;
}

export interface DispatchMediaSettingsProps {
  toggleModal: ToggleElementCreator;
  skipGreeting: () => void;
  skipAutoShowModal: () => void;
  changePermissions: ChangePermissionsCreator;
  saveDevices: (devices: MediaDeviceInfo[], mic?: MediaDeviceInfo, cam?: MediaDeviceInfo) => void;
  selectCam: SelectDeviceCreator;
  selectMic: SelectDeviceCreator;
  saveModalSelection: ActionCreator<Action>;
  setNoCamsAvailable: NoDeviceAvailableCreator;
  setNoMicsAvailable: NoDeviceAvailableCreator;
  setDevices: (devices: Devices) => void;
}

export interface MediaSettingsProps extends MediaSettingsStateProps, DispatchMediaSettingsProps {}

class MediaSettingsWizard extends React.Component<MediaSettingsProps, {}> {
  componentDidUpdate(prevProps: MediaSettingsProps) {
    if (isSafari() && !prevProps.browserHasRTC && this.props.browserHasRTC) {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        const selectedDevices = devices.reduce((selected, device) => {
          if (device.kind === 'audioinput' && device.label === this.props.micLabel) {
            return {...selected, mic: device.deviceId};
          }

          if (device.kind === 'videoinput' && device.label === this.props.camLabel) {
            return {...selected, cam: device.deviceId};
          }
          return selected;
        }, {});

        this.props.setDevices(selectedDevices);
      });
    }
  }

  public render() {
    return (
      <Modal
        show={(this.props.showModal || this.props.autoShowModal) && this.props.browserHasRTC}
        backdrop="static"
        className="media-settings-wizard"
        onHide={() => ({})}
      >
        {this.modalHeader()}
        {this.modalBody()}
        {this.modalFooter()}
      </Modal>
    );
  }

  private modalBody() {
    return this.props.showGreeting ? <GreetingBody /> : <SelectDevicesBody {...this.props} />;
  }

  private modalHeader() {
    return (
      <Modal.Header>
        <Modal.Title>
          <span>
            <Icon className="title-icon" name="pc-profile" tag="i" />
            <FormattedMessage id="MediaDevicesWizard.ModalTitle" />
          </span>
          <a onClick={this.handleClose}>
            <Icon name="pc-close" tag="i" />
          </a>
        </Modal.Title>
      </Modal.Header>
    );
  }

  private handleClose = () => {
    if (this.props.autoShowModal) {
      this.props.skipAutoShowModal();
    }
    this.props.toggleModal(false);
  };

  private modalFooter() {
    return this.props.showGreeting ? (
      <GreetingFooter {...this.props} />
    ) : (
      <SelectDevicesFooter {...this.props} />
    );
  }
}
export default MediaSettingsWizard;
