import React, {memo} from 'react';

import './ExampleItem.scss';
import {type Translation} from '../../interfaces';
import {TranslationsOfExample} from '../TranslationsOfExample/TranslationsOfExample';

interface Props {
  example: string;
  translations: Translation[];
}

export const ExampleItem: React.FC<Props> = memo(({example, translations}) => {
  return (
    <div className="example-item">
      <div className="example-item__text">{example}</div>
      <TranslationsOfExample translations={translations} />
    </div>
  );
});
