import React from 'react';
import classNames from 'classnames';

import {type AvatarUrl} from 'store/interface';

import './avatar.scss';

interface AvatarProps {
  url?: AvatarUrl;
  size: number;
  className?: string;
  title?: string;
  mask?: 'default' | 'phone';
}

export default class Avatar extends React.PureComponent<AvatarProps, {}> {
  public render() {
    const {url, title} = this.props;
    const size: string = String(this.props.size) + 'px';
    const classes = classNames(['avatar', this.props.className]);
    return (
      <div className={classes}>
        <img
          onDragStart={this.preventDefault}
          src={url && url['@1x']}
          srcSet={`${url && url['@2x']} 2x`}
          width={size}
          height={size}
          title={title}
          alt=""
        />
        {this.renderMask()}
      </div>
    );
  }

  private renderMask = () => {
    if (this.props.mask === 'default') {
      return <div className="offline mask" />;
    }
    if (this.props.mask === 'phone') {
      return <div className="oncall mask" key="1" />;
    }
    return null;
  };

  private getMaskIconSize = () => {
    if (this.props.size > 35) {
      return '2x';
    }
    return 'lg';
  };

  private preventDefault = (e: React.DragEvent<HTMLImageElement>) => e.preventDefault();
}
