import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {WidgetType} from 'store/exercise/player/interface';

import {type HorizontalStyledListJSON} from './interface';
import StyledListRecord from './StyledListRecord';

class HorizontalStyledListRecord extends StyledListRecord {
  public declare readonly withNumbering: boolean;

  constructor(raw: HorizontalStyledListJSON) {
    super(raw);
    this.initValues({
      withNumbering: !!raw?.withNumbering
    });
  }

  public get type() {
    return WidgetType.HORIZONTAL_STYLED_LIST;
  }
}

decorate(HorizontalStyledListRecord, {
  withNumbering: property()
});
record()(HorizontalStyledListRecord);
export default HorizontalStyledListRecord;
