import React, {type FC} from 'react';
import {useIntl} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import {Outlet} from 'react-router-dom';

import LessonSidebar from 'components/LessonSidebar/LessonSidebar';
import {ViewerProvider} from 'contexts/Viewer';
import Icon from 'components/Icon';
import './ClassRoom.scss';

interface Props {
  collapsed: boolean;
  collapseLayout(): void;
}

const ClassRoom: FC<Props> = ({collapsed, collapseLayout}) => {
  const {formatMessage} = useIntl();

  return (
    <ViewerProvider>
      <div className="classroom-layout">
        <LessonSidebar />
        <div className="page-left">
          <Outlet />
        </div>
        {collapsed ? (
          <Button
            className="btn-circle expand-layout-button"
            onClick={collapseLayout}
            title={formatMessage({id: 'File.ResetWorkspaceFromFullScreen'})}
          >
            <Icon name="virc-clutter" />
          </Button>
        ) : null}
      </div>
    </ViewerProvider>
  );
};

export default ClassRoom;
