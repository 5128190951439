import React, {type FC, useCallback, useEffect} from 'react';
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {gotToken} from '../../authentication/actions/action';

const devLoginEnabled = import.meta.env.REACT_APP_DEV_LOGIN_ENABLED === 'true';

export const DevLogin: FC = () => {
  const {token, '*': uri} = useParams<{token: string; '*': string}>();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const login = useCallback((token: string) => dispatch(gotToken(token)), [dispatch]);

  const to = `/${uri || ''}`;

  useEffect(() => {
    if (token) {
      login(token);
      navigate(to, {replace: true});
    }
  }, [login, navigate, to, token, uri]);

  if (!devLoginEnabled || !token) {
    return <Navigate replace={true} to="404" />;
  }

  return null;
};
