import {type FC, useCallback} from 'react';
import {useIntl} from 'react-intl';
import {ReactPaint, usePaint} from '@englex/paint-react';
import {Transforms} from '@englex/paint';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import {buttonTitles} from '../titles';

export const DeleteButton: FC = () => {
  const intl = useIntl();
  const paint = usePaint();

  const isEnabled = ReactPaint.canCopyNode(paint);

  const onClick = useCallback(() => {
    isEnabled && Transforms.removeNodes(paint);
  }, [isEnabled, paint]);

  return (
    <ToolbarButton
      shortcut="backspace"
      title={intl.formatMessage(buttonTitles.delete)}
      onClick={onClick}
      disabled={!isEnabled}
    >
      <Icon name="trash-o" />
    </ToolbarButton>
  );
};
