import {Map} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {type AudioFile} from 'components/media/interface';

import {WidgetType} from '../../interface';
import FormattedTextRecord from '../FormattedText/FormattedTextRecord';
import {type AudioProperties, type AudioWidgetJSON} from './interface';

class AudioRecord
  extends FormattedTextRecord<undefined, Map<'showCollapsible', boolean> | undefined>
  implements AudioProperties
{
  public declare readonly audio?: AudioFile;

  public declare readonly audioId: number;

  constructor(raw: AudioWidgetJSON) {
    super(raw);
    this.initValues({
      audioId: raw.audio.id,
      values: raw.values ? Map(raw.values) : undefined
    });
  }

  public toJSON(): AudioWidgetJSON {
    return {
      ...super.toJSON(),
      audio: {id: this.audioId}
    };
  }

  public get type() {
    return WidgetType.AUDIO;
  }

  public createValues() {
    return this.set('values', Map({showCollapsible: false}));
  }

  public setValuesFromJSON(values: {showCollapsible?: boolean}) {
    return values
      ? this.withMutations(v => {
          if (!v.values) v = v.set('values', Map());
          if (values.showCollapsible !== undefined)
            v.setIn(['values', 'showCollapsible'], values.showCollapsible);
          else v.delete('values');
        })
      : this;
  }
}

decorate(AudioRecord, {
  audio: property(),
  audioId: property()
});
record()(AudioRecord);
export default AudioRecord;
