import {record} from 'immutable-record/decorator/record';
import XWordSetCardRecord from 'store/exercise/editor/widgets/XWordPictureSet/XWordSet/XWordSetCardRecord';
import {WidgetType} from 'store/exercise/player/interface';

import {type WordPictureSetBaseCardJSON} from '../baseInterface';
import WordPictureSetBaseRecord from '../WordPictureSetBaseRecord';
import {
  type WordSetCardMetaDataJSON,
  type WordSetMetaDataJSON,
  type WordSetProperties,
  type WordSetProps,
  type WordSetValues
} from './interface';

class WordSetRecord
  extends WordPictureSetBaseRecord<
    WordSetCardMetaDataJSON,
    WordSetValues,
    WordSetMetaDataJSON,
    XWordSetCardRecord
  >
  implements WordSetProperties
{
  constructor(raw: WordSetProps) {
    super(raw);
    this.initValues({
      cards: this.createCards(raw),
      values: this.createValue(raw),
      isDefaultHidden: raw.isDefaultHidden,
      isNotInteractive: raw.isNotInteractive,
      defaultValues: this.makeDefaultValues(raw.defaultValues)
    });
  }

  public createCard(cardJSON: WordPictureSetBaseCardJSON) {
    return new XWordSetCardRecord(cardJSON);
  }

  public switchIsCardHiddenInValue(
    cardMetaData: WordSetCardMetaDataJSON,
    setField?: boolean
  ): WordSetCardMetaDataJSON {
    return {
      ...cardMetaData,
      isTextHidden: setField !== undefined ? setField : !cardMetaData.isTextHidden
    };
  }

  public get type() {
    return WidgetType.WORD_SET;
  }
}

record()(WordSetRecord);
export default WordSetRecord;
