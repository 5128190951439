import React, {type PropsWithChildren} from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import classNames from 'classnames';
import {type WrappedComponentProps, injectIntl} from 'react-intl';
import {type AxiosAction, type Dispatch} from 'redux-axios-middleware';
import {type Action} from 'redux';
import {Outlet} from 'react-router-dom';

import {type AppState, type Locale} from 'store/interface';
import {ImageCacheProvider} from 'components/XPlayer/contexts/imageCacheContext';

import MediaSettingsWizard from '../../routes/ClassRoom/components/MediaSettingsWizard';
import {
  requestNotifications,
  type RequestNotificationsResponseAction,
  setAppOfflineAndTimeout,
  setAppOnlineAndClearTimeout,
  setLayoutNetworkError,
  setNotificationsNumber
} from '../actions/action';
import Header from '../containers/Header';
import {makeCancellable} from '../../helpers/cancellablePromise';
import {isIOS} from '../../helpers/browser';
import {titleMessages} from './i18n';

import './main-layout.scss';

interface MainLayoutStateProps {
  pathname: string;
  isMobile?: boolean;
  locale: Locale;
  customTitle?: string;
  callInProgress?: boolean;
}

interface DispatchMainLayoutProps {
  appOnline(): void;
  appOffline(): void;
  requestNotifications(): Promise<RequestNotificationsResponseAction>;
  setNotificationsNumber(notificationsNumber: number): void;
  setLayoutNetworkError(): void;
}

interface Props extends MainLayoutStateProps, DispatchMainLayoutProps {}

class MainLayout extends React.Component<PropsWithChildren<Props> & WrappedComponentProps, {}> {
  public componentDidMount() {
    makeCancellable(
      this.props.requestNotifications(),
      this.handleNotificationsNumberLoaded,
      this.props.setLayoutNetworkError
    );
    window.addEventListener('online', this.props.appOnline);
    window.addEventListener('offline', this.props.appOffline);
  }

  public render(): JSX.Element {
    const {locale, intl, isMobile, callInProgress} = this.props;
    return (
      <div className="vc-layout-main">
        <MediaSettingsWizard />
        <Helmet
          defer={false}
          defaultTitle={intl.formatMessage(titleMessages.AppTitle)}
          htmlAttributes={{lang: locale}}
          title={this.props.customTitle}
          bodyAttributes={{
            class: classNames({
              mobile: isMobile,
              'overflow-hidden': isIOS && callInProgress
            })
          }}
        />
        <Header />
        <main className="main">
          <ImageCacheProvider>
            <div className="container">
              <div className="page-wrapper">
                <div className="content">
                  <Outlet />
                </div>
              </div>
            </div>
          </ImageCacheProvider>
        </main>
        <footer className="main-footer" />
      </div>
    );
  }
  private handleNotificationsNumberLoaded = (action: RequestNotificationsResponseAction) =>
    this.props.setNotificationsNumber(action.payload.data.count);
}

const mapStateToProps = (state: AppState) => ({
  isMobile: state.layout.isMobile,
  pathname: state.router.location!.pathname,
  customTitle: state.common.customTitle,
  locale: state.intl.locale,
  callInProgress: state.rtc.callInProgress
});

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): DispatchMainLayoutProps => ({
  requestNotifications: () => dispatch(requestNotifications() as AxiosAction),
  appOnline: () => dispatch(setAppOnlineAndClearTimeout),
  appOffline: () => dispatch(setAppOfflineAndTimeout),
  setLayoutNetworkError: () => dispatch(setLayoutNetworkError()),
  setNotificationsNumber: (notificationsNumber: number) =>
    dispatch(setNotificationsNumber(notificationsNumber))
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MainLayout));
