import React from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

import micAndCamImg from './micandcam.png';
import micAndCamImg2x from './micandcam@2x.png';

class GreetingBody extends React.Component<{}, {}> {
  public render() {
    const attention = <FormattedMessage id="MediaDevicesWizard.GreetingAttention" />;
    return (
      <Modal.Body className="greeting">
        <div className="description">
          <img className="image" src={micAndCamImg} srcSet={`${micAndCamImg2x} 2x`} alt="" />
          <div className="text">
            <p>
              <FormattedMessage id="MediaDevicesWizard.GreetingText" />
            </p>
            <p className="attention">
              <FormattedMessage
                id="MediaDevicesWizard.GreetingAttentionText"
                values={{attention}}
              />
            </p>
          </div>
        </div>
      </Modal.Body>
    );
  }
}
export default GreetingBody;
