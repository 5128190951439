import {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {push} from 'store/router';
import {type AppState} from 'store/interface';
import {DictionarySearchMode} from 'common/enums';

import {type Articles, type SearchData} from '../interfaces';
import {useSearchInEnToRusODApiRequest} from './useSearchInEnToRusODApiRequest';
import {useScrambledWordRusToEnApiRequest} from './useScrambledWordRusToEnApiRequest';
import {useSearchInEnToRusODByIdApiRequest} from './useSearchInEnToRusODByIdApiRequest';
import {useTextArticlesStory} from './useTextArticlesStory';
import {useTextArticlesCache} from './useTextArticlesCash';
import {useSearchInEnODApiRequest} from './useSearchInEnODApiRequest';
import {useSearchInEnODByIdApiRequest} from './useSearchInEnODByIdApiRequest';
import {useScrambledWordEnApiRequest} from './useScrambledWordEnApiRequest';

const defaultMode = DictionarySearchMode.MY_DICTIONARY;

export const useSearchInOD = (isSidebar?: boolean) => {
  const {search, pathname, key} = useLocation();
  const dispatch = useDispatch();

  const [searchOpen, setSearchOpen] = useState(false);
  const [isSearchingInOD, setIsSearchingInOD] = useState(false);
  const [textArticle, setTextArticle] = useState<SearchData | undefined>(undefined);
  const [currentSearchWord, setCurrentSearchWord] = useState('');
  const [includedTranslations, setIncludedTranslations] = useState<Record<string, string>>({});
  const [currentWord, setCurrentWord] = useState('');
  const [searchMode, setSearchMode] = useState<DictionarySearchMode>(defaultMode);
  const [isSearching, setIsSearching] = useState(false);
  const [searchParams] = useSearchParams({search: ''});
  const [searchFieldValue, setSearchFieldValue] = useState(searchParams.get('search') || '');

  const [scrambledArticles, setScrambledArticles] = useState<Articles>({});
  const [currentScrambledWord, setCurrentScrambledWord] = useState<string | undefined>(undefined);

  const textArticlesStory = useTextArticlesStory(currentSearchWord);
  const textArticlesCache = useTextArticlesCache(searchMode);

  const studentId = useSelector((state: AppState) => state.dictionary?.studentId);
  const isSearchParamOfDictionaryStoreIsNotEmpty = useSelector(
    (state: AppState) => !!state.dictionary?.search
  );

  const searchValue = useRef('');

  const changeSearchValue = useCallback((text: string) => {
    searchValue.current = text;
  }, []);

  const {set: setTextArticlesStory} = textArticlesStory;

  const {sendApiRequestEnToRus, isLoadingByWordEnToRus} = useSearchInEnToRusODApiRequest({
    setTextArticle,
    searchValue,
    textArticlesCache,
    textArticlesStory,
    currentSearchWord,
    setIncludedTranslations
  });

  const {sendApiRequestEn, isLoadingByWordEn} = useSearchInEnODApiRequest({
    setTextArticle,
    searchValue,
    textArticlesCache,
    textArticlesStory,
    currentSearchWord,
    setIncludedTranslations
  });

  const {isScrambledWordLoadingRusToEn, sendScrambledWordApiRequestRusToEn} =
    useScrambledWordRusToEnApiRequest({
      scrambledArticles,
      setScrambledArticles,
      currentScrambledWord,
      setCurrentScrambledWord
    });

  const {isScrambledWordLoadingEn, sendScrambledWordApiRequestEn} = useScrambledWordEnApiRequest({
    scrambledArticles,
    setScrambledArticles,
    currentScrambledWord,
    setCurrentScrambledWord
  });

  const {sendCrossLinkApiRequestEnToRus, isLoadingByIdEnToRus} = useSearchInEnToRusODByIdApiRequest(
    {
      isSidebar,
      textArticlesCache,
      textArticlesStory,
      currentSearchWord,
      setCurrentSearchWord,
      setTextArticle,
      setSearchFieldValue,
      changeSearchValue
    }
  );

  const {sendCrossLinkApiRequestEn, isLoadingByIdEn} = useSearchInEnODByIdApiRequest({
    isSidebar,
    textArticlesCache,
    textArticlesStory,
    currentSearchWord,
    setCurrentSearchWord,
    setTextArticle,
    setSearchFieldValue,
    changeSearchValue
  });

  const changeSearchMode = useCallback(
    (newSearchMode: DictionarySearchMode) => {
      if (newSearchMode !== searchMode) {
        if (!isSidebar) {
          const searchParams = new URLSearchParams(window.location.search);
          searchParams.set('searchMode', newSearchMode);

          if ([DictionarySearchMode.EN, DictionarySearchMode.EN_TO_RUS].includes(newSearchMode)) {
            searchParams.set('isSearchingInOD', 'true');
            setIsSearchingInOD(true);
          } else {
            searchParams.delete('isSearchingInOD');
            setIsSearchingInOD(false);
          }

          dispatch(push({search: searchParams.toString()}));
        }

        if ([DictionarySearchMode.EN, DictionarySearchMode.EN_TO_RUS].includes(newSearchMode)) {
          setIsSearchingInOD(true);
        } else {
          setIsSearchingInOD(false);
        }

        if (!searchValue.current) {
          setTextArticle(undefined);
          setScrambledArticles({});
          setCurrentScrambledWord(undefined);
        }

        setSearchMode(newSearchMode);
      }
    },
    [dispatch, isSidebar, searchMode]
  );

  useEffect(() => {
    if (!isSidebar) {
      const searchParams = new URLSearchParams(window.location.search);
      const isSearchingInODValue = searchParams.get('isSearchingInOD');
      const searchValue = searchParams.get('search');
      const searchMode = searchParams.get('searchMode');

      setIsSearchingInOD(!!isSearchingInODValue);
      if (searchMode) setSearchMode(searchMode as DictionarySearchMode);
      else setSearchMode(DictionarySearchMode.MY_DICTIONARY);

      if (key) setTextArticlesStory(key, searchValue ? searchValue : '');

      setSearchFieldValue(searchValue || '');

      if (!!searchValue) {
        setScrambledArticles({});
        setCurrentScrambledWord(undefined);
        setCurrentSearchWord(searchValue);
        changeSearchValue(searchValue);
        setSearchFieldValue(searchValue);
      }
    }
  }, [
    isSidebar,
    setCurrentScrambledWord,
    setScrambledArticles,
    textArticle,
    pathname,
    key,
    setTextArticlesStory,
    changeSearchValue
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const isSearchParamNoteNorEmpty = !!searchParams.get('search');

    const newIsSearching = isSearchParamNoteNorEmpty || isSearchParamOfDictionaryStoreIsNotEmpty;

    if (isSearching !== newIsSearching) setIsSearching(newIsSearching);
  }, [isSearchParamOfDictionaryStoreIsNotEmpty, isSearching, search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (!isSidebar) {
      if ([DictionarySearchMode.EN, DictionarySearchMode.EN_TO_RUS].includes(searchMode)) {
        searchParams.set('isSearchingInOD', 'true');
        setIsSearchingInOD(true);
        dispatch(push({search: searchParams.toString()}));
      } else {
        searchParams.delete('isSearchingInOD');
        setIsSearchingInOD(true);
        dispatch(push({search: searchParams.toString()}));
      }

      const isSearchingInOD = searchParams.get('isSearchingInOD');
      const search = searchParams.get('search');

      if (isSearchingInOD && search) {
        searchValue.current = search;
      } else {
        return;
      }
    }

    setCurrentWord(searchValue.current);
    if (searchMode === DictionarySearchMode.EN_TO_RUS) sendApiRequestEnToRus();
    else sendApiRequestEn();
  }, [dispatch, isSidebar, searchMode, sendApiRequestEn, sendApiRequestEnToRus]);

  useEffect(() => {
    if (isSidebar && studentId) setSearchFieldValue('');
  }, [isSidebar, setSearchFieldValue, studentId]);

  const openResultOfSearchingInOD = useCallback(() => {
    if (!isSidebar) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('isSearchingInOD', 'true');
      dispatch(push({search: searchParams.toString()}));
      setScrambledArticles({});
      setCurrentScrambledWord(undefined);
      setCurrentSearchWord(searchValue.current);
      setCurrentWord(searchValue.current);
    } else {
      setIsSearchingInOD(true);
      setScrambledArticles({});
      setCurrentScrambledWord(undefined);
      setCurrentSearchWord(searchValue.current);
      setCurrentWord(searchValue.current);
    }
  }, [dispatch, isSidebar, setCurrentScrambledWord, setScrambledArticles]);

  const closeResultOfSearchingInOD = useCallback(() => {
    if (!isSidebar) {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('isSearchingInOD');
      dispatch(push({search: searchParams.toString()}));
    } else {
      setIsSearchingInOD(false);
    }
    setTextArticle(undefined);
    setScrambledArticles({});
    setCurrentScrambledWord(undefined);
  }, [dispatch, isSidebar, search]);

  const sendScrambledWordApiRequest = useCallback(
    (word: string) => {
      searchMode === DictionarySearchMode.EN_TO_RUS
        ? sendScrambledWordApiRequestRusToEn(word)
        : sendScrambledWordApiRequestEn(word);
    },
    [searchMode, sendScrambledWordApiRequestEn, sendScrambledWordApiRequestRusToEn]
  );

  return {
    isSearchingInOD,
    textArticle,
    textArticlesStory,
    textArticlesCache,
    scrambledArticles,
    isLoading:
      isLoadingByWordEnToRus || isLoadingByWordEn || isLoadingByIdEnToRus || isLoadingByIdEn,
    currentSearchWord,
    isScrambledWordLoading: isScrambledWordLoadingRusToEn || isScrambledWordLoadingEn,
    currentScrambledWord,
    includedTranslations,
    currentWord,
    isSearching,
    searchFieldValue,
    searchOpen,
    searchMode,
    setSearchFieldValue,
    openResultOfSearchingInOD,
    closeResultOfSearchingInOD,
    changeSearchValue,
    sendApiRequestEnToRus,
    sendApiRequestEn,
    sendScrambledWordApiRequest,
    sendCrossLinkApiRequestEnToRus,
    sendCrossLinkApiRequestEn,
    setIncludedTranslations,
    setCurrentSearchWord,
    setSearchMode: changeSearchMode,
    setCurrentWord,
    setSearchOpen
  };
};
