import {type Room, type RoomUser} from 'store/interface';
import {onlineState2UserStatus} from 'helpers/user';

import {
  type ChatAction,
  type ChatImageAction,
  type GetPrivateRoomsSuccessAction
} from '../actions/interface';
import {GET_PRIVATE_ROOMS_LIST_SUCCESS, GET_PRIVATE_ROOMS_SUCCESS} from '../actions/actionTypes';
import {messagesReducer} from './messageReducer';
import {IMAGE_LOADING_FAILED} from '../actions/imageActionTypes';

export const roomsReducer = (rooms: {[id: string]: Room} | undefined, action: ChatAction) => {
  // todo: move all changes in chatrooms here
  switch (action.type) {
    case GET_PRIVATE_ROOMS_LIST_SUCCESS: {
      const newState = {...rooms};
      if (!(action as GetPrivateRoomsSuccessAction).wamp.callResult.args[0]) {
        return newState;
      }
      const receivedRooms = (action as GetPrivateRoomsSuccessAction).wamp.callResult.args[0];
      receivedRooms.forEach(room => {
        if (!newState[room.id]) {
          newState[room.id] = {
            ...room,
            images: [],
            showStartPhrases: false
          } as Room;
        }
      });
      return newState;
    }

    case GET_PRIVATE_ROOMS_SUCCESS: {
      const newState = {...rooms};
      if (!(action as GetPrivateRoomsSuccessAction).wamp.callResult.args[0] || !rooms) {
        return newState;
      }
      const receivedRooms = (action as GetPrivateRoomsSuccessAction).wamp.callResult.args[0];
      receivedRooms.forEach(receivedRoom => {
        if (newState[receivedRoom.id]) {
          newState[receivedRoom.id] = {
            ...rooms[receivedRoom.id],
            ...receivedRoom,
            loadingNewMessages: false,
            recipient: {
              ...rooms[receivedRoom.id].recipient,
              ...receivedRoom.recipient,
              userStatus: onlineState2UserStatus(
                receivedRoom.recipient?.online,
                receivedRoom.recipient?.onCall
              )
            } as RoomUser,
            images: []
          } as Room;
        }
      });
      return newState;
    }

    case IMAGE_LOADING_FAILED: {
      const {roomId} = action as ChatImageAction;
      const newState = {...rooms};
      if (newState) {
        const room: Room = {...newState[roomId]};
        if (room && room.messages) {
          room.messages = messagesReducer(room.messages, action);
          newState[roomId] = room;
        }
      }
      return newState;
    }

    default:
      return rooms;
  }
};
