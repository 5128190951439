import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../../player/interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';

class XSpeakingRecord extends XFormattedTextRecord {
  public get type() {
    return WidgetType.SPEAKING;
  }
}

record()(XSpeakingRecord);
export default XSpeakingRecord;
