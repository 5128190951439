import React from 'react';
import Button from 'react-bootstrap/lib/Button';

import Icon from '../../../../Icon';

interface Props {
  messageTitle: string;
  messageToUpdate?: number;
  shouldDisableSendButton: boolean;
  shouldDisableUpdateButton: boolean;
  sendMessage: () => void;
  updateMessage: () => void;
  isCompact?: boolean;
}

class SendButton extends React.PureComponent<Props, {}> {
  public render() {
    const {
      messageToUpdate,
      messageTitle,
      sendMessage,
      updateMessage,
      shouldDisableSendButton,
      shouldDisableUpdateButton,
      isCompact
    } = this.props;
    return (
      <Button
        className={`chat-send btn-circle ${isCompact ? 'compact btn-transparent' : ''}`}
        bsStyle={isCompact ? undefined : 'success'}
        onClick={messageToUpdate ? updateMessage : sendMessage}
        disabled={messageToUpdate ? shouldDisableUpdateButton : shouldDisableSendButton}
      >
        <Icon
          name={messageToUpdate ? 'pc-ok' : 'send'}
          size="lg"
          className="i-inverse"
          title={messageTitle}
        />
      </Button>
    );
  }
}

export default SendButton;
