import {AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';

export const xplayerPrefix = 'XPLAYER/';

const prefix = 'XPLAYER_PAGE/';

export const INIT_PLAYER_TOP = prefix + 'INIT_PLAYER_TOP';
export const STORE_SCROLL_TOP = prefix + 'STORE_SCROLL_TOP';
export const STORE_CLIENT_HEIGHT = prefix + 'STORE_CLIENT_HEIGHT';
export const SHOW_SAVING_BADGE = prefix + 'SHOW_SAVING_BADGE';
export const HIDE_SAVING_BADGE = prefix + 'HIDE_SAVING_BADGE';
export const PREVIEW_EXERCISE_COMPLETED = prefix + 'PREVIEW_EXERCISE_COMPLETED';
export const PREVIEW_EXERCISE_UNCOMPLETED = prefix + 'PREVIEW_EXERCISE_UNCOMPLETED';
export const REQUEST_EXERCISE_COMPLETED = prefix + 'REQUEST_EXERCISE_COMPLETED';
export const REQUEST_EXERCISE_UNCOMPLETED = prefix + 'REQUEST_EXERCISE_UNCOMPLETED';
export const REQUEST_UNIT_INSTANCE_PAGE = prefix + 'REQUEST_UNIT_INSTANCE_PAGE';
export const REQUEST_UNIT_INSTANCE_PAGE_SUCCESS =
  REQUEST_UNIT_INSTANCE_PAGE + AXIOS_SUCCESS_POSTFIX;
export const EXTRA_EXERCISES_ADDED_REQUEST = prefix + 'EXTRA_EXERCISES_ADDED_REQUEST';
export const EXTRA_EXERCISES_ADDED_REQUEST_SUCCESS =
  EXTRA_EXERCISES_ADDED_REQUEST + AXIOS_SUCCESS_POSTFIX;
export const CLEAR_XPLAYER_EXERCISE = prefix + 'CLEAR_XPLAYER_EXERCISE';
export const SET_MODAL_EXERCISE = prefix + 'SET_MODAL_EXERCISE';
export const ADD_DRAFT_HOMEWORK_EXERCISE_REQUEST = prefix + 'ADD_DRAFT_HOMEWORK_EXERCISE_REQUEST';
export const REMOVE_HOMEWORK_EXERCISE_REQUEST = prefix + 'REMOVE_HOMEWORK_EXERCISE_REQUEST';
export const UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK = prefix + 'UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK';
export const REMOVE_EXTRA_EXERCISE_FROM_XPLAYER = prefix + 'REMOVE_EXTRA_EXERCISE_FROM_XPLAYER';
export const INCREMENT_EXTRA_COUNT = prefix + 'INCREMENT_EXTRA_COUNT';
export const REMOVE_EXTRA_EXERCISE_REQUEST = prefix + 'REMOVE_EXTRA_EXERCISE_REQUEST';

export const SET_COMMENT_VALUE = prefix + 'SET_COMMENT_VALUE';
export const CREATE_DRAFT_COMMENT = prefix + 'CREATE_DRAFT_COMMENT';
export const CLEAR_DRAFT_COMMENT = prefix + 'CLEAR_DRAFT_COMMENT';
