import {useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';

import {push} from 'store/router';
import {DictionarySearchMode} from 'common/enums';

import {useSearchingInODContext} from '../contexts/searchingInODContext';

export function useFuzzyApiRequest(isSidebar?: boolean) {
  const {search} = useLocation();

  const dispatch = useDispatch();

  const {
    setCurrentSearchWord,
    searchMode,
    setSearchFieldValue,
    changeSearchValue,
    sendApiRequestEnToRus,
    sendApiRequestEn
  } = useSearchingInODContext();

  const searchRef = useRef<string>(search);

  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  return useCallback(
    (id: string, word: string) => {
      setCurrentSearchWord(word);
      setSearchFieldValue(word);
      changeSearchValue(word);

      if (!isSidebar) {
        const searchParams = new URLSearchParams(searchRef.current);
        searchParams.set('search', word);

        dispatch(push({search: searchParams.toString()}));
      }

      if (searchMode === DictionarySearchMode.EN_TO_RUS) sendApiRequestEnToRus();

      if (searchMode === DictionarySearchMode.EN) sendApiRequestEn();
    },
    [
      dispatch,
      isSidebar,
      searchMode,
      setCurrentSearchWord,
      setSearchFieldValue,
      changeSearchValue,
      sendApiRequestEnToRus,
      sendApiRequestEn
    ]
  );
}
