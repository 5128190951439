import React, {memo} from 'react';
import Table from 'react-bootstrap/lib/Table';

import {type Translation} from '../../interfaces';
import {TranslationItem} from '../TranslationItem/TranslationItem';

import './Translations.scss';

type Callback = (entryId: string) => void;

interface Props {
  translations: Translation[];
  includedTranslations: Record<string, string>;
  dictionaryIsReadonly: boolean;
  addWord: (translation: string, callback?: Callback) => void;
  deleteWord: (translation: string) => void;
}

export const Translations: React.FC<Props> = memo(
  ({translations, includedTranslations, dictionaryIsReadonly, addWord, deleteWord}) => {
    return (
      <Table className="translations" striped>
        <tbody>
          {translations.map((translation, index) => (
            <TranslationItem
              key={translation.text + index}
              includedTranslation={includedTranslations[translation.text]}
              translation={translation.text}
              grammaticalFeatures={translation.grammaticalFeatures}
              domains={translation?.domains}
              dictionaryIsReadonly={dictionaryIsReadonly}
              addWord={addWord}
              deleteWord={deleteWord}
            />
          ))}
        </tbody>
      </Table>
    );
  }
);
