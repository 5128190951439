import {type Paint} from '@englex/paint';
import {type ToolsPaint} from '@englex/paint-react';
import produce from 'immer';

import {type PaintThicknessPreset, type ThicknessName, type ThicknessPreset} from './interface';

export interface ThicknessPresetPaint extends ToolsPaint, PaintThicknessPreset {}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ThicknessPresetPaint = {
  is(p: Paint): p is ThicknessPresetPaint {
    return typeof (p as ThicknessPresetPaint).thickness?.preset === 'object';
  },

  isThicknessName(p: ThicknessPresetPaint, name: ThicknessName | unknown): name is ThicknessName {
    return typeof name === 'string' && Object.keys(p.thickness.preset.defaultMap).includes(name);
  },

  setThicknessName(p: Paint, name?: ThicknessName): void {
    if (!ThicknessPresetPaint.is(p)) return;

    if (ThicknessPresetPaint.isThicknessName(p, name)) {
      p.thickness = produce(p.thickness, th => {
        th.preset.active = name;
      });
      p.flush();
    }
  },

  toThickness(thickness: ThicknessName | undefined, preset: ThicknessPreset): number | undefined {
    return thickness && preset[thickness];
  },

  toName(width: number | undefined, preset: ThicknessPreset): ThicknessName | undefined {
    const keys: ThicknessName[] = Object.keys(preset) as ThicknessName[];
    return keys.find((t: ThicknessName) => preset[t] === width);
  }
};
