import React, {useCallback} from 'react';

import {useMobilePainterAttrs} from 'components/Paint/hooks/useMobilePainterAttrs';
import WampErrorMask from 'components/WampErrorMask';
import Loader from 'components/Loader';

import {useHomeworkDocumentPage} from '../../ClassRoom/pages/HomeworkPlayerPage/useHomeworkDocumentPage';
import {MobileDocumentInstancePage} from './MobileDocumentInstancePage';

interface Props {
  courseId: number;
  homeworkId: string;
  documentInstancePageId: number;
  pageNumber: number;
}

export const MobileHomeworkDocumentPage: React.FC<Props> = ({
  courseId,
  homeworkId,
  documentInstancePageId,
  pageNumber
}) => {
  const {
    docInstancePage,
    isError: isHomeworkError,
    reload: reloadHomework
  } = useHomeworkDocumentPage(courseId, homeworkId, documentInstancePageId);

  const docUrl = docInstancePage?.documentInstance?.url;

  const {
    attrs,
    isError: isAttrsError,
    reload: reloadAttrs
  } = useMobilePainterAttrs(docUrl, pageNumber);

  const reload = useCallback(() => {
    reloadHomework();
    reloadAttrs();
  }, [reloadHomework, reloadAttrs]);

  const isError = isHomeworkError || isAttrsError;

  if (isError) {
    return <WampErrorMask reload={reload} />;
  }

  if (!docInstancePage || !attrs) {
    return <Loader />;
  }

  return <MobileDocumentInstancePage attrs={attrs} docInstancePage={docInstancePage} />;
};
