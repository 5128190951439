import {type Value, type ValueJSON} from '@englex/slate';
import {type List} from 'immutable';

import {type WidgetJSON, type WidgetProperties, type WidgetType} from '../../../interface';

export interface StyledListJSON extends WidgetJSON {
  content: ValueJSON;
  styleName: StyledListStyles;
  values: string[];
}

export interface HorizontalStyledListJSON extends StyledListJSON {
  withNumbering: boolean;
}

export interface VerticalStyledListJSON extends StyledListJSON {
  twoColumns?: boolean;
}

export interface StyledListOptions extends WidgetJSON {
  sort: WidgetType.HORIZONTAL_STYLED_LIST | WidgetType.VERTICAL_STYLED_LIST;
}

export interface StyledListProperties extends WidgetProperties {
  readonly content: Value;
  readonly styleName: StyledListStyles;
  readonly values?: List<string>;
  readonly withNumbering?: boolean;
  readonly twoColumns?: boolean;
  selectItem: (id: string) => this;
}

export enum StyledListStyles {
  BLUE = 'blue',
  GREEN = 'green',
  ORANGE = 'orange',
  WHITE = 'white',
  WHITE_OUTLINE = 'white-outline',
  BLUE_OUTLINE = 'blue-outline',
  GREEN_OUTLINE = 'green-outline',
  ORANGE_OUTLINE = 'orange-outline',
  NUMBERED_BLUE = 'numbered-blue',
  NUMBERED_GREEN = 'numbered-green',
  NUMBERED_ORANGE = 'numbered-orange',
  NUMBERED_WHITE = 'numbered-white',
  NUMBERED_WHITE_OUTLINE = 'numbered-white-outline',
  BULLETED_BLUE = 'bulleted-blue',
  BULLETED_GREEN = 'bulleted-green',
  BULLETED_ORANGE = 'bulleted-orange',
  BULLETED_WHITE = 'bulleted-white'
}
