import React, {type FC} from 'react';
import Helmet from 'react-helmet';

import {isMobileWebView} from 'helpers/browser';
import {ImageCacheProvider} from 'components/XPlayer/contexts/imageCacheContext';
import {Resources, ViewerProvider} from 'contexts/Viewer';
import {useNode} from 'contexts/Viewer/utils';
import GrammarPlayerPage from 'common/GrammarPlayerPage/GrammarPlayerPage';

import './StandaloneUnitPlayer.scss';

export const StandaloneGrammarPlayer: FC = props => {
  const resourcesNode = useNode('.standalone-player');

  return (
    <div className="standalone-player">
      {isMobileWebView() && (
        <Helmet
          bodyAttributes={{class: 'x-player-mobile-webview'}}
          meta={[{name: 'viewport', content: 'initial-scale=1.0, maximum-scale=1.0'}]}
        />
      )}
      <ImageCacheProvider>
        <ViewerProvider>
          <Resources id="grammar-player-page" node={resourcesNode}>
            <GrammarPlayerPage {...props} />
          </Resources>
        </ViewerProvider>
      </ImageCacheProvider>
    </div>
  );
};
