import {type Action, type Reducer, type ReducersMapObject} from 'redux';
import {REHYDRATE} from 'redux-persist/es/constants';
import {type RehydrateAction} from 'redux-persist/es/types';

import {PAUSE, PLAYING} from 'components/media/interface';

import audioReducer from './audio/audioReducer';
import videoReducer from './video/videoReducer';
import {CHANGE_PLAY_STATUS} from './audio/actionTypes';
import {ACTIVATE_PLAYER} from './video/actionTypes';
import {type MediaState} from '../interface';
import {type ChangePlayStatusAction, type VideoState} from './interface';
import {STOP_MEDIA} from './actionTypes';
import {PersistState} from '../persist';

const REDUCERS: ReducersMapObject = {
  [REHYDRATE]: (
    state: MediaState,
    {key, payload}: RehydrateAction & {payload: MediaState}
  ): MediaState => {
    if (key === PersistState.media) {
      const players = state.video.players;
      if (players && players.length) {
        return {...state, video: {...payload.video, players}};
      }
    }
    return state;
  },
  [ACTIVATE_PLAYER]: (state: MediaState): MediaState => {
    if (state.audio && state.audio.playStatus && state.audio.playStatus !== PAUSE) {
      return {
        ...state,
        audio: {
          volume: state.audio.volume,
          audioFile: undefined,
          playStatus: undefined,
          timestamp: 0,
          startedAt: undefined
        }
      };
    }
    return state;
  },
  [CHANGE_PLAY_STATUS]: (state: MediaState, {playStatus}: ChangePlayStatusAction): MediaState => {
    const {video} = state;
    if (playStatus === PLAYING && video.activePlayerId) {
      return {...state, video: {...video, activePlayerId: undefined}};
    }
    return state;
  },
  [STOP_MEDIA]: (state: MediaState): MediaState => {
    const audio = state.audio ? {timestamp: 0, volume: state.audio.volume} : undefined;
    const video: VideoState = state.video.activePlayerId
      ? {
          volume: state.video.volume,
          players: state.video.players
        }
      : state.video;
    return {audio, video};
  }
};

const initialState: MediaState = {video: {players: [], volume: 1}};

const mediaReducer: Reducer<MediaState, Action> = (state = initialState, action) => {
  const reducer: Reducer<MediaState> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;
  return {
    ...state,
    audio: audioReducer(state.audio, action),
    video: videoReducer(state.video, action)
  };
};

export default mediaReducer;
