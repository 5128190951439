import React, {type FC} from 'react';
import {FormattedDate, FormattedMessage} from 'react-intl';

import './DateSeparator.scss';

const DateSeparator: FC<{date: Date; prevDate?: Date}> = React.memo(
  ({children, date, prevDate}) => {
    const dateString = date.toDateString();
    const show = !prevDate || prevDate.toDateString() !== dateString;
    if (!show) return null;
    const isYesterday = new Date().getTime() - date.getTime() <= 8.64e7;
    return (
      <div className="date-separator">
        {dateString === new Date().toDateString() ? (
          <FormattedMessage id="Common.Today" />
        ) : isYesterday ? (
          <FormattedMessage id="Common.Yesterday" />
        ) : (
          <FormattedDate value={date} day="numeric" month="long" year="numeric" />
        )}
        {children}
      </div>
    );
  }
);

export default DateSeparator;
