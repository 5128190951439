import {defineMessages} from 'react-intl';

export const headerMessages = defineMessages({
  HeaderNotificationsMessage: {
    id: 'Header.NotificationsMessage'
  },
  HeaderSupportMessage: {
    id: 'Header.SupportMessage'
  }
});
