import React, {createContext, useContext, useMemo, useState} from 'react';

interface Props {
  isTeacherDictionary: boolean;
  dictionaryOwnerId: number;
}

export type DictionaryOwnerRole = 'teacher' | 'student';

interface DictionaryCtx {
  isTeacherDictionary: boolean;
  dictionaryOwnerId: number;
  dictionaryOwnerRole: DictionaryOwnerRole;
  hasError?: boolean;
  setError: (e: boolean) => void;
}

const DictionaryContext = createContext<DictionaryCtx>({} as DictionaryCtx);

export const DictionaryContextProvider: React.FC<Props> = ({
  children,
  isTeacherDictionary,
  dictionaryOwnerId
}) => {
  const [error, setError] = useState(false);
  const value: DictionaryCtx = useMemo(
    () => ({
      hasError: error,
      isTeacherDictionary,
      dictionaryOwnerId,
      dictionaryOwnerRole: isTeacherDictionary ? 'teacher' : 'student',
      setError
    }),
    [error, isTeacherDictionary, dictionaryOwnerId]
  );

  return <DictionaryContext.Provider value={value}>{children}</DictionaryContext.Provider>;
};

export const useDictionaryContext = () => useContext(DictionaryContext);
