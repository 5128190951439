export enum MaterialsTab {
  DOCUMENTS = 'docs',
  AUDIO = 'audio',
  UPLOADS = 'uploads'
}

export enum ActionsTogether {
  Play = 'play',
  Pause = 'pause',
  Stop = 'stop',
  ChangePlaybackRate = 'change-rate',
  ChangeTimestamp = 'change-timestamp'
}

export enum DictionarySearchMode {
  MY_DICTIONARY = 'myDictionary',
  EN_TO_RUS = 'enToRus',
  EN = 'en'
}

export enum HomeworkStatus {
  GIVEN,
  DONE,
  CHECKED
}

export enum FileExtension {
  mp3 = 'mp3',
  pdf = 'pdf'
}

export enum OnlineState {
  OFFLINE = 0,
  WEB = 1 << 0,
  WEB_NO_RTC = 1 << 1,
  WEB_MOBILE = 1 << 2,
  WEB_MOBILE_NO_RTC = 1 << 3,
  VC_MOBILE_APP = 1 << 4,
  VC_MOBILE_APP_WEB_VIEW = 1 << 5
}

export enum TextMessageTypes {
  text = 'text',
  // for parsedMedia links parsed from text messages
  mediaSingleLink = 'mediaSingleLink',
  textWithAdditionalBubbles = 'textWithAdditionalBubbles',
  // for parsedMedia that were uploaded by users to Englex server
  image = 'image'
}

export enum ServiceMessageTypes {
  missed = 'missed',
  busy = 'busy',
  endCall = 'end-call',
  answer = 'answer'
}

export enum ParsedMediaType {
  image = 'image',
  youtubeVideo = 'youtubeVideo'
}

export enum CoursebookType {
  COMMON = 'Common',
  PROPRIETARY = 'Proprietary'
}

export enum CoursebookStatus {
  HIDDEN = 'Hidden',
  PUBLISHED = 'Published'
}

export enum CoursebookAuthorRole {
  METHODIST = 'methodist',
  TEACHER = 'teacher'
}

export enum BooleanEnum {
  TRUE = 'true',
  FALSE = 'false'
}

export enum IntroTheme {
  Basic = 'basic'
}
