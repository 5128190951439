import type React from 'react';
import {useCallback} from 'react';

import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {determineDirectionOfTranslation} from 'routes/Dictionary/utils';
import {DictionarySearchMode} from 'common/enums';

import {requestSearchInODEn} from '../actions';
import {type IncludedTranslations, type SearchData} from '../interfaces';
import {type TextArticlesCache} from './useTextArticlesCash';
import {type TextArticlesStory} from './useTextArticlesStory';
import {groupLexicalEntries} from '../utils/groupLexicalEntries';

interface Props {
  setTextArticle: (value: React.SetStateAction<SearchData | undefined>) => void;
  searchValue: React.MutableRefObject<string>;
  textArticlesCache: TextArticlesCache;
  textArticlesStory: TextArticlesStory;
  currentSearchWord: string;
  setIncludedTranslations: (translations: IncludedTranslations) => void;
}

export const useSearchInEnODApiRequest = ({
  setTextArticle,
  searchValue,
  textArticlesCache,
  textArticlesStory,
  currentSearchWord,
  setIncludedTranslations
}: Props) => {
  const {getTextArticleFromCache} = textArticlesCache;

  const searchInODSuccessHandler = useCallback(
    (data: SearchData) => {
      const groupedData = groupLexicalEntries(data, DictionarySearchMode.EN);
      setTextArticle(groupedData);
      groupedData && textArticlesCache.setTextArticleToCache(currentSearchWord, groupedData);
    },
    [currentSearchWord, setTextArticle, textArticlesCache]
  );

  const searchInODErrorHandler = useCallback(() => {
    setTextArticle(undefined);
  }, [setTextArticle]);

  const {isLoading: isLoadingByWordEn, apiRequest: apiRequestSearchInOD} = useApiRequest2<[string]>(
    requestSearchInODEn,
    searchInODSuccessHandler,
    searchInODErrorHandler
  );

  const sendApiRequestEn = useCallback(() => {
    if (!searchValue.current.trim()) return;

    if (!getTextArticleFromCache(searchValue.current)) {
      const translationDirection = determineDirectionOfTranslation(searchValue.current);
      if (translationDirection) {
        setIncludedTranslations({});
        apiRequestSearchInOD(searchValue.current);
      } else setTextArticle(undefined);
    } else setTextArticle(getTextArticleFromCache(searchValue.current));
  }, [
    apiRequestSearchInOD,
    getTextArticleFromCache,
    searchValue,
    setIncludedTranslations,
    setTextArticle
  ]);

  return {sendApiRequestEn, isLoadingByWordEn};
};
