import {type IntlShape} from 'react-intl';
import {List, Map} from 'immutable';
import * as yup from 'yup';

import {
  type CardJSON,
  type CardsJSON,
  CardsType
} from 'store/exercise/player/widgets/Cards/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import validationMessages from '../i18n';
import {
  type MediaMeta,
  WidgetMediaType,
  WidgetTitle,
  type WidgetToJSONOptions,
  WidgetType
} from '../../../player/interface';
import {type XCardsProperties, type XCardsProps} from './interface';
import XWidgetRecord from '../XWidgetRecord';
import XEditorCardRecord from './XEditorCardRecord';
import {valueJSONFromText} from '../../../../../components/Slate/utils';
import {isDocumentEmpty} from '../../../../../components/Slate/utils/documentNotEmpty';
import genKey from '../../../../../components/Slate/utils/genKey';
import {taskMaxLength} from '../validation';
import {ValidationWidgetError} from '../customErrors';
import {contentExcerpt, taskExcerpt} from '../excerpt';
import {getObjectWithNewIds} from '../getObjectWithNewIds';

class XCardsRecord extends XWidgetRecord implements XCardsProperties {
  static MinCardsCount = 2;
  static MaxCardsCount = 12;
  static MaxTitleCardLength = 32;

  public static fromCards(cardsType: CardsType, count = XCardsRecord.MinCardsCount) {
    const cardsList = List(Array.from(Array(count).keys())).map(() =>
      this.createCard(cardsType)
    ) as List<XEditorCardRecord>;

    return new XCardsRecord({
      id: genKey(),
      task: valueJSONFromText(''),
      type: WidgetType.CARDS,
      cardsType,
      cardsList
    });
  }

  private static createCard(type: CardsType, card: Partial<CardJSON> = {}) {
    const cardProps =
      type === CardsType.TEXT
        ? {
            type,
            id: card.id || genKey(),
            text: card.text || valueJSONFromText(''),
            title: card.title || valueJSONFromText('')
          }
        : {
            type,
            id: card.id || genKey(),
            text: card.text || valueJSONFromText(''),
            image: card.image
          };

    return new XEditorCardRecord(cardProps);
  }

  private static createCards(raw: XCardsProps) {
    if (List.isList(raw.cardsList)) {
      return raw.cardsList;
    }

    return List(
      (raw.cardsList as CardJSON[]).map((card: CardJSON) =>
        XCardsRecord.createCard(raw.cardsType, card)
      )
    );
  }

  public declare readonly cardsType: CardsType;

  public declare readonly cardsList: List<XEditorCardRecord>;

  public get type() {
    return WidgetType.CARDS;
  }

  public get title(): string {
    return `${WidgetTitle.Cards} (${this.cardsType})`;
  }

  constructor(raw: XCardsProps) {
    super(raw);

    this.initValues({
      cardsType: raw.cardsType,
      cardsList: XCardsRecord.createCards(raw)
    });
  }

  public toJSON(options?: WidgetToJSONOptions): CardsJSON {
    return {
      ...super.toJSON(options),
      cardsType: this.cardsType,
      cardsList: this.getCardsList(options)
    };
  }

  private getCardsList(options?: WidgetToJSONOptions) {
    const cardsList = this.cardsList
      .map((card: XEditorCardRecord) => card.toJSON(this.cardsType))
      .toArray();

    if (options?.generateIdentifiers) return getObjectWithNewIds(cardsList);

    return cardsList;
  }

  public get excerpt(): string {
    const task = taskExcerpt(this.task);

    if (task.length >= exerciseExcerptLength) {
      return task;
    }

    const cardsText = this.cardsList
      .map((c: XEditorCardRecord) => {
        const title = this.cardsType === CardsType.TEXT ? contentExcerpt(c.title, task.length) : '';
        const text = contentExcerpt(c.text, task.length + title.length);

        return this.cardsType === CardsType.TEXT ? `[${title}] ${text}` : `[Image] ${text}`;
      })
      .join(' ');

    return `${this.task.document.text} ${cardsText}.`.trim();
  }

  public get media(): MediaMeta | undefined {
    const images = this.cardsList
      .filter((c: XEditorCardRecord) => c.type === CardsType.IMAGES)
      .map((c: XEditorCardRecord) => c.image)
      .toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }

  public addEmptyCard() {
    const cards = this.cardsList.push(XCardsRecord.createCard(this.cardsType));
    return this.set('cardsList', cards);
  }

  public deleteCardById(id: string) {
    const cardIndex = this.cardsList.findIndex((card: XEditorCardRecord) => card.id === id);
    const cards = this.cardsList.delete(cardIndex);
    return this.set('cardsList', cards);
  }

  public moveCard(from: number, to: number) {
    const source = this.cardsList.get(from);
    const destination = this.cardsList.get(to);
    const cards = this.cardsList.set(to, source).set(from, destination);
    return this.set('cardsList', cards);
  }

  public schema(intl: IntlShape) {
    return yup.object({cardsList: this.cardsListSchema(intl)});
  }

  private textCardSchema(intl: IntlShape) {
    return yup
      .mixed()
      .test({
        test: (cards: List<XEditorCardRecord>) => {
          const withError = cards.filter((card: XEditorCardRecord) =>
            isDocumentEmpty(card.title.document)
          );

          if (withError.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.CardTitleNotEmpty),
              this,
              withError.reduce(
                (props, card: XEditorCardRecord) => ({
                  ...props,
                  [card.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      })
      .test({
        test: (cards: List<XEditorCardRecord>) => {
          const withError = cards.filter(
            (card: XEditorCardRecord) =>
              !taskMaxLength(card.title.document, XCardsRecord.MaxTitleCardLength)
          );

          if (withError.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.CardTitleMaxLength, {
                maxLength: XCardsRecord.MaxTitleCardLength
              }),
              this,
              withError.reduce(
                (props, card: XEditorCardRecord) => ({
                  ...props,
                  [card.id]: {maxLength: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      })
      .test({
        test: (cards: List<XEditorCardRecord>) => {
          const withError = cards.filter((card: XEditorCardRecord) =>
            isDocumentEmpty(card.text.document)
          );

          if (withError.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.CardTextNotEmpty),
              this,
              withError.reduce(
                (props, card: XEditorCardRecord) => ({
                  ...props,
                  [card.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      });
  }

  private imageCardSchema(intl: IntlShape) {
    return yup
      .mixed()
      .test({
        test: (cards: List<XEditorCardRecord>) => {
          const withError = cards.filter((card: XEditorCardRecord) => !card.image);

          if (withError.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.BlankImages),
              this,
              withError.reduce(
                (props, card: XEditorCardRecord) => ({
                  ...props,
                  [card.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      })
      .test({
        test: (cards: List<XEditorCardRecord>) => {
          const withError = cards.filter((card: XEditorCardRecord) =>
            isDocumentEmpty(card.text.document)
          );

          if (withError.size) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.CardTextNotEmpty),
              this,
              withError.reduce(
                (props, card: XEditorCardRecord) => ({
                  ...props,
                  [card.id]: {empty: true}
                }),
                {}
              )
            );
          }

          return true;
        }
      });
  }

  private cardsListSchema(intl: IntlShape): yup.AnySchema {
    return this.cardsType === CardsType.TEXT
      ? this.textCardSchema(intl)
      : this.imageCardSchema(intl);
  }
}

decorate(XCardsRecord, {
  cardsType: property(''),
  cardsList: property(List())
});
record()(XCardsRecord);
export default XCardsRecord;
