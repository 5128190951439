import {connect} from 'react-redux';
import {type Action} from 'redux';

import {type AppState} from 'store/interface';

import CallInProgress, {type CallInProgressStateProps} from '../components/CallInProgress';
import {type ChangeRatioAction, type ConnectionComponent, type Dispatch} from '../action/interface';
import {changeSmallVideoRatio, getLocalStream, getRemoteStream, muteCall} from '../action/action';

const mapStateToProps = (state: AppState) => {
  return {
    localStream: state.rtc.localStream,
    status: state.rtc.call ? state.rtc.call.status : 'connecting',
    remoteVideoEnabled: state.rtc.remoteStream && state.rtc.remoteStream.video,
    smallVideoRatio: state.video.smallVideoRatio,
    speedtestGloballyDisabled: state.rtc.speedtestGloballyDisabled
  } as CallInProgressStateProps;
};

const mapDispatchToProps = (dispatch: Dispatch<Action, Action | MediaStream>) => ({
  getLocalStream: () => dispatch(getLocalStream()),
  getRemoteStream: () => dispatch(getRemoteStream()),
  muteCall: (component: ConnectionComponent, camId: string | null, micId: string | null) =>
    dispatch(muteCall(component, camId, micId)),
  changeSmallVideoRatio: (ratio: number) =>
    dispatch<ChangeRatioAction>(changeSmallVideoRatio(ratio))
});

export default connect(mapStateToProps, mapDispatchToProps)(CallInProgress);
