import React, {type FC, useContext} from 'react';

import {EditEntryStateContext} from '../../contexts/entry/EditEntryContext';
import {type EntrySelectionMode} from '../../contexts/entry/interface';
import {type DictionaryEntryInstance, type DictionaryList} from '../../interface';
import {WordEntryBasicControls} from './WordEntryBasicControls';
import {SelectionCheckbox} from './SelectionCheckbox';

import './WordEntryControls.scss';

interface ControlsProps {
  list?: DictionaryList;
  wordEntry: DictionaryEntryInstance;
  hasLists: boolean;
}

export const Controls: FC<ControlsProps> = React.memo(props => {
  const {selectionMode, showForm} = useContext(EditEntryStateContext);

  return <ControlsInner selectionMode={selectionMode} showForm={showForm} {...props} />;
});

interface ControlsInnerProps extends ControlsProps {
  selectionMode?: EntrySelectionMode;
  showForm: boolean;
}

const ControlsInner: FC<ControlsInnerProps> = React.memo(({selectionMode, ...props}) => {
  return (
    <>
      {selectionMode ? (
        <div className="word-entry-controls">
          <SelectionCheckbox id={props.wordEntry.id} />
        </div>
      ) : (
        <WordEntryBasicControls {...props} />
      )}
    </>
  );
});
