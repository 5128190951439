import {List, Map} from 'immutable';
import {Value} from '@englex/slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import FormattedTextRecord from '../../FormattedText/FormattedTextRecord';
import {WidgetType} from '../../../interface';
import {
  MultipleChoiceElement,
  type MultipleChoiceJSON,
  type MultipleChoiceProperties,
  type WidgetMultipleChoiceAnswersJSON
} from '../interface';

class MultipleChoiceRecord
  extends FormattedTextRecord<
    Map<string, List<string>> | undefined,
    Map<string, List<string>> | undefined
  >
  implements MultipleChoiceProperties
{
  public declare readonly element: MultipleChoiceElement;
  public declare readonly horizontal?: true;
  public declare readonly withoutQuestions: boolean;

  constructor(raw: MultipleChoiceJSON) {
    super(raw);
    this.initValues({
      content: Value.fromJSON(raw.content),
      answers: raw.answers ? this.valuesFromJSON(raw.answers) : Map(),
      element: raw.element,
      values: raw.values ? this.valuesFromJSON(raw.values) : undefined,
      horizontal: raw.options?.horizontal,
      withoutQuestions: !!raw.options?.withoutQuestions
    });
  }

  public toJSON(): MultipleChoiceJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      content: this.content.toJSON(),
      element: this.element,
      values: this.values ? this.values.toJS() : undefined,
      options: {withoutQuestions: this.withoutQuestions, horizontal: this.horizontal}
    };
  }

  public get type() {
    return WidgetType.MULTIPLE_CHOICE;
  }

  public setAnswersFromJSON(values: WidgetMultipleChoiceAnswersJSON): this {
    return this.set('answers', this.valuesFromJSON(values));
  }

  public createValues() {
    return this.set('values', Map());
  }

  public get isAutoChecked() {
    return true;
  }

  public setValuesFromJSON(values?: WidgetMultipleChoiceAnswersJSON): this {
    return values ? this.set('values', this.valuesFromJSON(values)) : this;
  }

  private valuesFromJSON(values: WidgetMultipleChoiceAnswersJSON) {
    return Map(values).mapEntries((entry: string[]) => [entry[0], List(entry[1])]);
  }

  public get isAvailableSelection() {
    return true;
  }
}
decorate(MultipleChoiceRecord, {
  element: property(MultipleChoiceElement.CHECKBOX),
  answers: property(Map()),
  values: property(undefined),
  horizontal: property(undefined),
  withoutQuestions: property(false)
});
record()(MultipleChoiceRecord);
export default MultipleChoiceRecord;
