import React from 'react';
import {visit, type Parent} from 'unist-util-visit';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;

export const EmphasisRenderer = (props: Props) => <em>{props.children}</em>;

interface VFile {
  value: string;
}

function transformer(tree: Parent, file: object) {
  visit(tree, 'emphasis', function (node: Parent) {
    const {value} = file as VFile;

    if (hasOneChild(node)) {
      const reg = /\*.*\*/;

      const child = node.children.find(Boolean);

      if (child?.position) {
        const {start, end} = child.position;

        if (typeof start.offset === 'number' && typeof end.offset === 'number') {
          const emphasisOffset = 1;
          const word = value.slice(start.offset - emphasisOffset, end.offset + emphasisOffset);

          if (reg.test(word)) {
            node.type = 'strong';
          }
        }
      }
    }
  });
}

function hasOneChild(node: Parent) {
  return node.children.length === 1;
}

export function emphasis() {
  return transformer;
}
