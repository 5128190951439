import {type MarkDef} from '../../definitions';

export const textColors = ['orange', 'red', /*'brown', */ 'blue', 'green', 'purple'].reduce<
  MarkDef[]
>((colors, value) => {
  colors.push({
    mark: 'color',
    value,
    attrs: {
      className: `sc-${value}`
    }
  });
  return colors;
}, []);

export const [textOrange, textRed, textBrown, textBlue, textGreen, textPurple] = textColors;
