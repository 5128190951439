import {type List, type Map} from 'immutable';

import {type Answer, type WidgetType} from '../../interface';
import {
  type FormattedTextProperties,
  type WidgetFormattedTextJSON
} from '../FormattedText/interface';

export enum MultipleChoiceDefaultAnswers {
  BOOLEAN = 'Boolean',
  EMPTY = 'Empty'
}

export enum MultipleChoiceElement {
  CHECKBOX = 'Checkbox',
  RADIO = 'Radio',
  CIRCLE = 'Circle',
  UpperAlpha = 'UpperAlpha'
}

export interface MultipleChoiceOptions {
  horizontal?: true;
  defaultAnswersType: MultipleChoiceDefaultAnswers;
  defaultAnswersNumber: number;
  initialQuestionsNumber: number;
  withoutQuestions?: boolean;
}

export interface MultipleChoiceJSON extends WidgetFormattedTextJSON {
  element: MultipleChoiceElement;
  options?: Partial<MultipleChoiceOptions>;
  answers?: WidgetMultipleChoiceAnswersJSON;
  values?: WidgetMultipleChoiceAnswersJSON;
}

export interface WidgetMultipleChoiceAnswersJSON {
  [id: string]: Answer[];
}

export interface MultipleChoiceProperties extends FormattedTextProperties {
  readonly element: MultipleChoiceElement;
  readonly answers?: Map<string, List<string>> | undefined;
  readonly values?: Map<string, List<string>> | undefined;
  readonly horizontal?: true;
  readonly withoutQuestions: boolean;
}

export interface XMultipleChoiceWizardOptions extends WidgetFormattedTextJSON {
  type: WidgetType;
  element: MultipleChoiceElement;
  defaultAnswersType?: MultipleChoiceDefaultAnswers;
  defaultAnswersNumber?: number;
  horizontal?: true;
  initialQuestionsNumber?: number;
  withoutQuestions: boolean;
}
