import {type BlockJSON, type MarkJSON, Value, type ValueJSON} from '@englex/slate';

import {type DataJSON, ListType, SlateBlock, SlateObject, type StyleData} from '../interface';

export function valueJSONFromText(
  text: string = '',
  marks: MarkJSON[] = [],
  blockData: DataJSON & StyleData = {}
): ValueJSON {
  return {
    object: 'value',
    document: {
      object: 'document',
      data: {},
      nodes: [
        {
          object: SlateObject.BLOCK,
          type: SlateBlock.DEFAULT,
          data: blockData,
          nodes: [
            {
              object: SlateObject.TEXT,
              text,
              marks
            }
          ]
        }
      ]
    }
  };
}

/**
 * Creates the new Slate Value record from text
 *
 * @param {string} text
 * @returns {Value}
 */
export function valueFromText(text: string = ''): Value {
  return Value.fromJSON(valueJSONFromText(text));
}

const initialQuestions = (initialText: string, initialCount: number): BlockJSON[] =>
  Array.from(Array(initialCount), (_, index) => index + 1).map(v => ({
    object: SlateObject.BLOCK as 'block',
    type: SlateBlock.LIST_ITEM,
    data: {},
    nodes: [
      {
        object: SlateObject.BLOCK,
        type: SlateBlock.DEFAULT,
        data: {},
        nodes: [
          {
            object: SlateObject.TEXT,
            text: `${initialText} ${v}`
          }
        ]
      }
    ] as BlockJSON[]
  }));

export const questsInitialValue = (initialText = 'Question', initialCount = 3): ValueJSON => ({
  document: {
    object: SlateObject.DOCUMENT,
    nodes: [
      {
        object: SlateObject.BLOCK,
        type: SlateBlock.LIST,
        data: {
          list: ListType.OL
        },
        nodes: initialQuestions(initialText, initialCount)
      }
    ]
  }
});
