import React from 'react';
import {useIntl} from 'react-intl';
import {type ModalProps} from 'react-bootstrap/lib/Modal';
import {ThicknessPaint, type ToolsPaint, usePaint} from '@englex/paint-react';
import {type ShapeAttrs, Transforms} from '@englex/paint';

import {type ThicknessName, ThicknessPresetPaint} from 'components/Paint/Painter/plugins';

import messages from '../../messages';
import {useThickness} from '../../../hooks';
import {MobileModal} from '../modal';
import {PresetEntries} from './PresetEntries';

interface Props extends ModalProps {
  active?: ThicknessName;
}

export const MobileThicknessModal: React.FC<Props> = ({active, onHide, ...rest}) => {
  const paint = usePaint<ToolsPaint & ThicknessPresetPaint>();

  const {selectedNode, isSelectable, preset} = useThickness();

  const intl = useIntl();

  const title = intl.formatMessage(messages.selectLineWeight);

  const presetEntries = Object.keys(paint.thickness.preset.defaultMap) as ThicknessName[];

  const selectPreset = (name: ThicknessName) => {
    if (selectedNode && isSelectable) {
      const thickness = ThicknessPresetPaint.toThickness(name, preset);
      const change: ShapeAttrs = {strokeWidth: thickness};
      if (selectedNode.className === 'Arrow' && thickness) {
        change.pointerLength = ThicknessPaint.getArrowAnchorLength(paint, thickness);
        change.pointerWidth = ThicknessPaint.getArrowAnchorWidth(paint, thickness);
      }
      Transforms.setNodes(paint, change);
    }

    if (name !== active) {
      ThicknessPresetPaint.setThicknessName(paint, name);
    }

    onHide();
  };

  return (
    <MobileModal title={title} onHide={onHide} {...rest}>
      <PresetEntries entries={presetEntries} active={active} onSelect={selectPreset} />
    </MobileModal>
  );
};
