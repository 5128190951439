import {List, Map} from 'immutable';
import * as yup from 'yup';
import {type Value} from '@englex/slate';
import {type IntlShape} from 'react-intl';

import {genKey, valueJSONFromText} from 'components/Slate/utils';
import {
  type DialogueSpeakerJSON,
  type DialogueMessageJSON,
  type DialogueJSON
} from 'store/exercise/player/widgets/Dialogue/interface';
import {type MediaMeta, WidgetMediaType, WidgetType} from 'store/exercise/player/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {isDocumentEmpty} from 'components/Slate/utils/documentNotEmpty';

import XWidgetRecord from '../XWidgetRecord';
import {type XDialogueProperties, type XDialogueProps} from './interface';
import XDialogueSpeakerRecord from './XDialogueSpeakerRecord';
import XDialogueMessageRecord from './XDialogueMessageRecord';
import validationMessages from '../i18n';
import {ValidationWidgetError} from '../customErrors';
import {documentNotEmpty} from '../validation';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

class XDialogueRecord extends XWidgetRecord implements XDialogueProperties {
  static messagesMinCount: number = 1;

  public declare readonly speakers: List<XDialogueSpeakerRecord>;

  public declare readonly messages: List<XDialogueMessageRecord>;

  public declare readonly styleName: string;

  public static createDialogue(
    speakers: List<XDialogueSpeakerRecord>,
    messages: List<XDialogueMessageRecord>
  ): XDialogueRecord {
    return new XDialogueRecord({
      id: genKey(),
      type: WidgetType.IMAGE_MATCHING,
      task: valueJSONFromText(''),
      speakers,
      messages
    });
  }

  public schema(intl: IntlShape): yup.AnyObjectSchema {
    return yup.object({
      messages: yup
        .mixed()
        .test((messages: List<XDialogueMessageRecord>) => {
          if (messages.size < XDialogueRecord.messagesMinCount) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.DialogueMessagesNotEmpty),
              this,
              {emptyMessages: true}
            );
          }

          return true;
        })
        .test((messages: List<XDialogueMessageRecord>) => {
          const withEmptyMessage = messages.filter((message: XDialogueMessageRecord) =>
            isDocumentEmpty(message.message.document)
          );

          if (withEmptyMessage.size > 0) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.DialogueMessageTextNotEmpty),
              this,
              withEmptyMessage.reduce(
                (props, message: XDialogueMessageRecord) => ({
                  ...props,
                  [message.id]: true
                }),
                {}
              )
            );
          }

          return true;
        }),
      speakers: yup.mixed().test({
        test: (speakers: List<XDialogueSpeakerRecord>) => {
          const withEmptyNames = speakers.filter((speaker: XDialogueSpeakerRecord) =>
            isDocumentEmpty(speaker.name.document)
          );

          if (withEmptyNames.size > 0) {
            return new ValidationWidgetError(
              intl.formatMessage(validationMessages.DialogueNameNotEmpty),
              this,
              withEmptyNames.reduce(
                (props, speaker: XDialogueSpeakerRecord) => ({
                  ...props,
                  [speaker.id]: true
                }),
                {}
              )
            );
          }

          return true;
        }
      }),
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }

  constructor(raw: XDialogueProps) {
    super(raw);
    this.initValues({
      id: raw.id || genKey(),
      speakers: this.createSpeakers(raw),
      messages: this.createMessage(raw),
      styleName: raw?.styleName ? raw.styleName : 'orange_pink'
    });
  }

  public toJSON(): DialogueJSON {
    return {
      ...super.toJSON(),
      speakers: this.speakers.map((speaker: XDialogueSpeakerRecord) => speaker.toJSON()).toArray(),
      messages: this.messages.map((message: XDialogueMessageRecord) => message.toJSON()).toArray(),
      styleName: this.styleName
    };
  }

  public addSpeaker() {
    const speakers = this.speakers.push(new XDialogueSpeakerRecord());
    return this.set('speakers', speakers);
  }

  public removeSpeaker(speakerId: string) {
    const speakers = this.speakers.filter(speaker => speaker?.id !== speakerId);
    return this.set('speakers', speakers);
  }

  public addMessage(speakerId: string) {
    const messages = this.messages.push(new XDialogueMessageRecord({speakerId}));
    return this.set('messages', messages);
  }

  public removeMessage(messageId: string) {
    const messages = this.messages.filter(messages => messages?.id !== messageId);
    return this.set('messages', messages);
  }

  public changeAvatar(speakerId: string, avatar: number) {
    const speakerIndex = this.speakers.findIndex(
      (speaker: XDialogueSpeakerRecord) => speaker.id === speakerId
    );
    return this.setIn(['speakers', speakerIndex, 'avatar'], avatar);
  }

  public changeName(speakerId: string, name: Value) {
    const speakerIndex = this.speakers.findIndex(speaker => speaker?.id === speakerId);
    return this.setIn(['speakers', speakerIndex, 'name'], name);
  }

  public changeMessage(messageId: string, message: Value) {
    const messageIndex = this.messages.findIndex(message => message?.id === messageId);
    return this.setIn(['messages', messageIndex, 'message'], message);
  }

  public changeSpeaker(messageId: string, newSpeakerId: string) {
    const messageIndex = this.messages.findIndex(message => message?.id === messageId);
    return this.setIn(['messages', messageIndex, 'speakerId'], newSpeakerId);
  }

  public moveMessageDown(messageId: string) {
    const messageIndex = this.messages.findIndex(message => message?.id === messageId);
    const message = this.messages.get(messageIndex);
    const messages = this.messages.delete(messageIndex).insert(messageIndex + 1, message);
    return this.setIn(['messages'], messages);
  }

  public moveMessageUp(messageId: string) {
    const messageIndex = this.messages.findIndex(message => message?.id === messageId);
    const message = this.messages.get(messageIndex);
    const messages = this.messages.delete(messageIndex).insert(messageIndex - 1, message);
    return this.setIn(['messages'], messages);
  }

  public changeColorSchema(newStyleName: string) {
    return this.set('styleName', newStyleName);
  }

  private createSpeakers(raw: XDialogueProps) {
    if (List.isList(raw.speakers)) {
      return raw.speakers;
    }
    return List(
      (raw.speakers as DialogueSpeakerJSON[]).map((speaker: DialogueSpeakerJSON) => {
        const {id, avatar, name} = speaker;
        return new XDialogueSpeakerRecord({id, avatar, name});
      })
    );
  }

  private createMessage(raw: XDialogueProps) {
    if (List.isList(raw.messages)) {
      return raw.messages;
    }
    return List(
      (raw.messages as DialogueMessageJSON[]).map((messageItem: DialogueMessageJSON) => {
        const {id, speakerId, message} = messageItem;
        return new XDialogueMessageRecord({id, speakerId, message});
      })
    );
  }

  public get type() {
    return WidgetType.DIALOGUE;
  }

  public get excerpt(): string {
    return `${this.task.document.text} ${this.messages
      .map((message: XDialogueMessageRecord) => {
        const speakerName = this.speakers.find(
          (speaker: XDialogueSpeakerRecord) => speaker.id === message.speakerId
        ).name;
        return `[${speakerName.document.text}] ${message.message.document.text}`;
      })
      .join(' ')}`.trim();
  }

  public get media(): MediaMeta | undefined {
    const images = this.speakers.map((c: XDialogueSpeakerRecord) => c.avatar).toSet();
    return images.size ? (Map({[WidgetMediaType.IMAGES]: images}) as MediaMeta) : undefined;
  }
}

decorate(XDialogueRecord, {
  speakers: property(List<XDialogueSpeakerRecord>()),
  messages: property(List<XDialogueMessageRecord>()),
  styleName: property('')
});
record()(XDialogueRecord);
export default XDialogueRecord;
