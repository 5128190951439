import {type Action, type ActionCreator, type Dispatch} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {intervalBetweenSpeedTests, speedTestLength} from 'config/static';
import {CALL} from 'services/wamp/actions/types';
import {type AppState} from 'store/interface';
import {type WampCallAction} from 'services/wamp/actions/interface';

import {
  type AddIceCandidateCreator,
  type BrowserCheckCreator,
  type CallAnswerCreator,
  type CallAnsweredCreator,
  type CallStartedCreator,
  type CallStartingAction,
  type CallUpgradedCreator,
  type ChangeCallDurationCreator,
  type ChangeCallStatusAction,
  type ChangeRatioCreator,
  type ChangeVideoPositionCreator,
  type ConnectionComponent,
  type GetStreamAction,
  type HangUpCreator,
  type IncomingCallCreator,
  type MuteCallCreator,
  type MuteStreamCreator,
  type OtherSessionCallCreator,
  type OutgoingCallCreator,
  type RTCActionCreator,
  type SdpAction,
  type SendIceCandidateCreator,
  type SetQualityCreator,
  type SetRemoteQualityCreator,
  type StateType,
  type UpgradeCallCreator,
  type WampCallAnswerCreator,
  type WampCallCreateAction,
  type WampMuteCallCreator,
  type WampSendStateCreator
} from './interface';
import {
  CALL_ACCEPT,
  CALL_ACCEPTED_EVENT,
  CALL_ANSWERED,
  CALL_ANSWERED_EVENT,
  CALL_CHANGE_DURATION,
  CALL_CHANGE_MEDIA_DEVICE,
  CALL_CHANGE_STATUS,
  CALL_CREATE,
  CALL_END,
  CALL_MUTE,
  CALL_SIGNALING_STABLE_EVENT,
  CALL_OFFERED_EVENT,
  CALL_STARTING,
  CALL_UPGRADED_EVENT,
  CHANGE_INCOMING_CALL_NOTIFICATION,
  CHANGE_SMALL_VIDEO_RATIO,
  CHANGE_VIDEO_POSITION,
  GET_LOCAL_STREAM,
  GET_REMOTE_STREAM,
  ICE_CANDIDATE_EVENT,
  INCOMING_CALL,
  OTHER_SESSION_CALL,
  OUTGOING_CALL,
  OUTGOING_CALL_INTERRUPTED,
  PARTNER_DISCONNECTED,
  PARTNER_SESSION_ID_UPGRADED,
  RESIZE_VIDEO,
  SET_LOCAL_CONNECTION_QUALITY,
  SET_REMOTE_CONNECTION_QUALITY,
  SHOW_LOW_SPEED_TOAST,
  START_SPEEDTEST,
  TOGGLE_FULLSCREEN_MODE,
  TOGGLE_RECALL,
  TOGGLE_REMOTE_VIDEO_DEVICE,
  TOGGLE_SPEEDTEST_NECESSITY,
  TOGGLE_TRANSFORMING_MODE,
  TOGGLE_UNDOCKED_MODE,
  WAMP_CALL_ACCEPT,
  WAMP_CALL_ANSWERED,
  WAMP_CALL_CREATE,
  WAMP_CALL_HANG_UP,
  WAMP_CALL_OFFERED,
  WAMP_GET_RTC_SETTINGS,
  WAMP_MUTE_CALL,
  WAMP_RTC_BROWSER_CHECK,
  WAMP_SEND_ICE_CANDIDATE,
  WAMP_SEND_STATE,
  WAMP_UPGRADE_CALL,
  CLOSE_BAD_BROWSER
} from './actionTypes';
import {MUTE_REMOTE_STREAM} from '../../components/Chat/actions/actionTypes';
import {type ToggleElementCreator} from '../../common/interface';
import {type BitrateInfo, type ConnectedIceInfo} from '../types';
import {type CallStatus, type RTCCall, type SpeedtestResult} from '../interface';

export const incomingCall: IncomingCallCreator = (
  call: RTCCall,
  options: MediaStreamConstraints
) => ({
  type: INCOMING_CALL,
  call,
  options
});

export const outgoingCall: OutgoingCallCreator = (roomId: number) => ({
  type: OUTGOING_CALL,
  roomId
});

export const callStarting =
  (roomId: number, video?: boolean): ThunkAction<void, AppState, void, Action> =>
  (dispatch, getState) => {
    const action: CallStartingAction = {
      type: CALL_STARTING,
      roomId,
      video
    };
    dispatch(action);
    const {layout, floatSidebar} = getState();
    if (floatSidebar?.collapsed || layout.collapsed) {
      dispatch(toggleVideoUndocked(true));
    }
  };

export const callCreate: CallStartedCreator = (
  roomId: number,
  camId: string | null,
  micId: string | null
) => ({
  type: CALL_CREATE,
  roomId,
  camId,
  micId
});

export const wampCreateCall = (
  roomId: number,
  cam: string | null,
  mic: string | null
): WampCallCreateAction => ({
  type: WAMP_CALL_CREATE,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.private.rtc.create`,
    args: [cam, mic],
    rejectOnError: true
  }
});

export const wampOffered = (
  roomId: number,
  callId: string,
  offer: RTCSessionDescriptionInit
): WampCallAction<[string, RTCSessionDescriptionInit]> => ({
  type: WAMP_CALL_OFFERED,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.private.rtc.offered`,
    args: [callId, offer],
    rejectOnError: true
  }
});

export const wampAnswered = (
  roomId: number,
  callId: string,
  answer: RTCSessionDescriptionInit
): WampCallAction<[string, RTCSessionDescriptionInit]> => ({
  type: WAMP_CALL_ANSWERED,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.private.rtc.answered`,
    args: [callId, answer],
    rejectOnError: true
  }
});

export const callEnd: RTCActionCreator = () => ({
  type: CALL_END
});

export const hangUp: HangUpCreator = (
  roomId: number,
  callId: string,
  details: {reason: string} | Error
) => ({
  type: WAMP_CALL_HANG_UP,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.private.rtc.hangup`,
    args: [callId, details],
    kwargs: {}
  }
});

export const callAccept: CallAnswerCreator = (
  roomId: number,
  callId: string,
  camId: string | null,
  micId: string | null
) => ({
  type: CALL_ACCEPT,
  callId,
  roomId,
  camId,
  micId
});

export const callOfferedEvent = (offerSdp: string): SdpAction => ({
  type: CALL_OFFERED_EVENT,
  sdp: offerSdp
});

export const callAnsweredEvent = (answerSdp: string): SdpAction => ({
  type: CALL_ANSWERED_EVENT,
  sdp: answerSdp
});

type Device = string | null;

export const wampCallAccept: WampCallAnswerCreator = (
  roomId: number,
  callId: string,
  cam: Device,
  mic: Device
): WampCallAction<[string, string | null, string | null], {}> => ({
  type: WAMP_CALL_ACCEPT,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.private.rtc.accept`,
    args: [callId, cam, mic],
    rejectOnError: true
  }
});

export const callAnswered: CallAnsweredCreator = (
  options?: MediaStreamConstraints,
  answererUserId?: string,
  answerSDP?: string
) => ({
  type: CALL_ANSWERED,
  options,
  answererUserId,
  answerSDP
});

export const callAcceptedEvent = (options?: MediaStreamConstraints, answererUserId?: string) => ({
  type: CALL_ACCEPTED_EVENT,
  options,
  answererUserId
});

export const otherSessionCall: OtherSessionCallCreator = (call?: RTCCall) => ({
  type: OTHER_SESSION_CALL,
  call
});

export const upgradeCall: UpgradeCallCreator = (
  callId: string,
  roomId: number,
  kwargs?: {offer?: string; answer?: string}
) => ({
  type: WAMP_UPGRADE_CALL,
  wamp: {
    method: 'call' as 'call',
    uri: `chatroom:_${roomId}.private.rtc.upgrade`,
    args: [callId],
    kwargs
  }
});

export const browserCheckRequest: BrowserCheckCreator = () => ({
  type: WAMP_RTC_BROWSER_CHECK,
  wamp: {
    method: 'call' as 'call',
    uri: 'chat:rtc.browser-check'
  }
});

export const muteCall: MuteCallCreator = (
  component: ConnectionComponent,
  camId: string | null,
  micId: string | null
) => ({
  type: CALL_MUTE,
  component,
  camId,
  micId
});

export const wampMuteCall: WampMuteCallCreator = (
  roomId: number,
  callId: string,
  cam: Device,
  mic: Device,
  reconnect: boolean
) => ({
  type: WAMP_MUTE_CALL,
  wamp: {
    method: 'call' as 'call',
    uri: `chatroom:_${roomId}.private.rtc.mute`,
    args: [callId, cam, mic],
    kwargs: {reconnect}
  }
});

export const muteRemoteStream: MuteStreamCreator = (
  video: boolean,
  audio: boolean,
  shouldReconnect?: boolean
) => ({
  type: MUTE_REMOTE_STREAM,
  video,
  audio,
  shouldReconnect
});

export const sendIceCandidate: SendIceCandidateCreator = (
  roomId: number,
  callId: string,
  candidate: RTCIceCandidate
) => ({
  type: WAMP_SEND_ICE_CANDIDATE,
  wamp: {
    method: 'call' as 'call',
    uri: `chatroom:_${roomId}.private.rtc.candidate`,
    args: [callId, candidate]
  }
});

export const iceCandidateEvent: AddIceCandidateCreator = (candidate: RTCIceCandidateInit) => ({
  type: ICE_CANDIDATE_EVENT,
  candidate
});

export const getLocalStream: ActionCreator<GetStreamAction> = () => ({
  type: GET_LOCAL_STREAM
});

export const getRemoteStream: ActionCreator<GetStreamAction> = () => ({
  type: GET_REMOTE_STREAM
});

export const callUpgradedEvent: CallUpgradedCreator = (sdp: string, sdpType: string) => ({
  type: CALL_UPGRADED_EVENT,
  sdp,
  sdpType
});

export const callSignalingStableEvent = () => ({
  type: CALL_SIGNALING_STABLE_EVENT
});

export const wampSendState: WampSendStateCreator = (
  roomId: number,
  callId: string,
  stateType: StateType,
  state: string | ConnectedIceInfo | BitrateInfo
) => ({
  type: WAMP_SEND_STATE,
  wamp: {
    method: 'call' as 'call',
    uri: `chatroom:_${roomId}.private.rtc.state`,
    args: [callId, stateType, state]
  }
});

export const changeCallStatus = (status: CallStatus): ChangeCallStatusAction => ({
  type: CALL_CHANGE_STATUS,
  status
});

export const getRTCSettings: ActionCreator<WampCallAction<{}, {}>> = () => ({
  type: WAMP_GET_RTC_SETTINGS,
  wamp: {
    method: 'call' as 'call',
    uri: `chat:private.rtc.settings`
  }
});

export const changeCallMediaDevice: MuteCallCreator = (
  component: ConnectionComponent,
  camId: string | null,
  micId: string | null
) => ({
  type: CALL_CHANGE_MEDIA_DEVICE,
  component,
  camId,
  micId
});

export const toggleFullScreenMode: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_FULLSCREEN_MODE,
  show
});

export const changeCallDuration: ChangeCallDurationCreator = (duration: number) => ({
  type: CALL_CHANGE_DURATION,
  duration
});

export const toggleVideoUndocked: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_UNDOCKED_MODE,
  show
});

export const changeVideoPosition: ChangeVideoPositionCreator = (x: number, y: number) => ({
  type: CHANGE_VIDEO_POSITION,
  x,
  y
});

export const resizeVideo: ChangeVideoPositionCreator = (x: number, y: number) => ({
  type: RESIZE_VIDEO,
  x,
  y
});

export const toggleTransformingMode: ToggleElementCreator = (show: true) => ({
  type: TOGGLE_TRANSFORMING_MODE,
  show
});

export const changeSmallVideoRatio: ChangeRatioCreator = (ratio: number) => ({
  type: CHANGE_SMALL_VIDEO_RATIO,
  ratio
});

export const changeIncomingCallNotification = (notificationId?: number) => ({
  type: CHANGE_INCOMING_CALL_NOTIFICATION,
  notificationId
});

export const setLocalConnectionQuality: SetQualityCreator = (
  quality: SpeedtestResult,
  roomId: number,
  callId: string
) => ({
  type: SET_LOCAL_CONNECTION_QUALITY,
  wamp: {
    method: 'call' as 'call',
    uri: `chatroom:_${roomId}.private.rtc.speedtest`,
    args: [callId],
    kwargs: {
      ip: quality.ip,
      u: quality.uploadingSpeed,
      d: quality.downloadingSpeed,
      j: quality.jitter,
      p: quality.ping
    }
  }
});

export const startSpeedtest: RTCActionCreator = () => ({
  type: START_SPEEDTEST
});

export const toggleSpeedtestNecessity: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_SPEEDTEST_NECESSITY,
  show
});

const setRemoteQuality: SetRemoteQualityCreator = (
  quality?: SpeedtestResult,
  timeout?: number
) => ({
  type: SET_REMOTE_CONNECTION_QUALITY,
  quality,
  timeout
});

export const saveQualityAndSetTimeout =
  (quality: SpeedtestResult) => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const oldTimeout = getState().rtc.remoteSpeedtestTimeout;
    if (oldTimeout) {
      clearTimeout(oldTimeout);
    }
    const callId = getState().rtc.call && getState().rtc.call!.id;
    const newTimeout = setTimeout(
      () => {
        const rtcState = getState().rtc;
        if (rtcState.remoteConnectionQuality && rtcState.call && rtcState.call.id === callId) {
          dispatch(setRemoteQuality());
        }
      },
      (speedTestLength + intervalBetweenSpeedTests) * 1.5
    );
    dispatch(setRemoteQuality(quality, newTimeout));
  };

export const showLowSpeedToast = () => ({type: SHOW_LOW_SPEED_TOAST});

export const outgoingCallInterrupted = () => ({
  type: OUTGOING_CALL_INTERRUPTED
});

export const toggleRecall: ToggleElementCreator = (recall: boolean) => ({
  type: TOGGLE_RECALL,
  show: recall
});

export const partnerDisconnected: RTCActionCreator = () => ({
  type: PARTNER_DISCONNECTED
});

export const toggleRemoteVideoDevice: ToggleElementCreator = (
  recipientIsTransmittingVideo: boolean
) => ({
  type: TOGGLE_REMOTE_VIDEO_DEVICE,
  show: recipientIsTransmittingVideo
});

export interface UpgradePartnerSessionAction extends Action {
  sessionId: number;
}

export const partnerSessionIdUpgraded = (sessionId: number): UpgradePartnerSessionAction => ({
  type: PARTNER_SESSION_ID_UPGRADED,
  sessionId
});

export const closeBadBrowser = () => ({type: CLOSE_BAD_BROWSER});
