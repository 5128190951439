import React, {type MutableRefObject} from 'react';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';

interface Props {
  className?: string;
  element: string | React.FC | React.ComponentClass;
  hasMore?: boolean;
  initialLoad?: boolean;
  isReverse?: boolean;
  loader?: JSX.Element;
  loadMore: (pageNumber: number) => Promise<unknown> | void;
  pageStart?: number;
  ref?: ((el: HTMLElement | null) => void) | MutableRefObject<InfiniteScroll | null>;
  getScrollParent?: () => void;
  threshold?: number;
  useCapture?: boolean;
  useWindow?: boolean;
  withResizeDetector?: boolean;
  children: JSX.Element | null;
}

type Options = undefined | boolean | EventListenerOptions;

interface State {
  isLoading: boolean;
}

export default class InfiniteScroll extends React.Component<Props, State> {
  public static defaultProps: Partial<Props> = {
    element: 'div',
    hasMore: false,
    initialLoad: true,
    pageStart: 0,
    threshold: 250,
    useWindow: true,
    isReverse: false,
    useCapture: false,
    children: null
  };

  public state: State = {isLoading: false};
  public pageLoaded?: number;
  public observeResize = true;

  private options?: Options;
  private scrollComponent: HTMLElement | null;
  private loadMore?: boolean;
  private beforeScrollHeight: number;
  private beforeScrollTop: number;
  private defaultLoader?: JSX.Element;
  private mounted?: boolean;

  constructor(props: Props) {
    super(props);

    this.scrollListener = this.scrollListener.bind(this);
    this.eventListenerOptions = this.eventListenerOptions.bind(this);
    this.mousewheelListener = this.mousewheelListener.bind(this);
  }

  public componentDidMount() {
    this.pageLoaded = this.props.pageStart;
    this.options = this.eventListenerOptions();
    this.attachScrollListener();
    this.mounted = true;
  }

  public componentDidUpdate() {
    if (this.props.isReverse && this.loadMore) {
      const parentElement = this.getParentElement(this.scrollComponent!);
      parentElement.scrollTop =
        parentElement.scrollHeight - this.beforeScrollHeight + this.beforeScrollTop;
      this.loadMore = false;
    }
    if (!this.state.isLoading) {
      this.attachScrollListener();
    }
  }

  public componentWillUnmount() {
    this.detachScrollListener();
    this.detachMousewheelListener();
    this.mounted = false;
  }

  public render() {
    const renderProps: Props = this.props;
    const {
      children,
      className,
      element,
      hasMore,
      initialLoad,
      isReverse,
      loader,
      loadMore,
      pageStart,
      threshold,
      useCapture,
      useWindow,
      getScrollParent,
      withResizeDetector,
      ...props
    } = renderProps;

    (props as Props).ref = (node: HTMLElement | null) => {
      this.scrollComponent = node;
    };

    const childrenArray = [children];
    if (hasMore) {
      if (loader) {
        isReverse ? childrenArray.unshift(loader) : childrenArray.push(loader);
      } else if (this.defaultLoader) {
        isReverse
          ? childrenArray.unshift(this.defaultLoader)
          : childrenArray.push(this.defaultLoader);
      }
    }
    return (
      <>
        {React.createElement(
          element,
          {
            ...props,
            className: classNames('infinite-scroll', [className])
          } as React.Attributes,
          childrenArray
        )}
        {withResizeDetector && (
          <ReactResizeDetector onResize={this.onResize} refreshRate={16} refreshMode="throttle" />
        )}
      </>
    );
  }

  public onResize = () => {
    this.observeResize && this.scrollListener();
  };

  private isPassiveSupported() {
    let passive = false;
    const testOptions = {
      get passive() {
        passive = true;
        return undefined;
      }
    } as EventListenerOptions;

    try {
      document.addEventListener('test', {} as EventListenerObject, testOptions);
      document.removeEventListener('test', {} as EventListenerObject, testOptions);
    } catch (e) {
      // ignore
    }
    return passive;
  }

  private eventListenerOptions() {
    let options: Options = this.props.useCapture;

    if (this.isPassiveSupported()) {
      options = {
        useCapture: this.props.useCapture,
        passive: true
      } as EventListenerOptions;
    }
    return options;
  }

  private detachMousewheelListener() {
    let scrollEl: Node | Window = window;
    if (this.props.useWindow === false) {
      scrollEl = this.scrollComponent!.parentNode!;
    }

    scrollEl.removeEventListener(
      'mousewheel',
      this.mousewheelListener,
      this.options ? this.options : this.props.useCapture
    );
  }

  private detachScrollListener() {
    let scrollEl: Node | Window = window;
    if (this.props.useWindow === false) {
      scrollEl = this.getParentElement(this.scrollComponent!) as Node;
    }

    scrollEl.removeEventListener(
      'scroll',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    );
    scrollEl.removeEventListener(
      'resize',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    );

    this.observeResize = false;
  }

  private getParentElement(el: HTMLElement): HTMLElement {
    const scrollParent = this.props.getScrollParent && this.props.getScrollParent();
    if (scrollParent != null) {
      return scrollParent;
    }
    return el && (el.parentNode as HTMLElement);
  }

  private attachScrollListener() {
    const parentElement = this.getParentElement(this.scrollComponent!);

    if (!this.props.hasMore || !parentElement) {
      return;
    }

    let scrollEl: Node | Window = window;
    if (this.props.useWindow === false) {
      scrollEl = parentElement;
    }

    scrollEl.addEventListener(
      'mousewheel',
      this.mousewheelListener,
      this.options ? this.options : this.props.useCapture
    );
    scrollEl.addEventListener(
      'scroll',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    );
    scrollEl.addEventListener(
      'resize',
      this.scrollListener,
      this.options ? this.options : this.props.useCapture
    );

    this.observeResize = true;

    if (this.props.initialLoad) {
      this.scrollListener();
    }
  }

  private mousewheelListener(e: WheelEvent) {
    // Prevents Chrome hangups
    // See: https://stackoverflow.com/questions/47524205/random-high-content-download-time-in-chrome/47684257#47684257
    if (e.deltaY === 1 && !this.isPassiveSupported()) {
      e.preventDefault();
    }
  }

  private scrollListener() {
    const el = this.scrollComponent;
    const scrollEl = window;
    const parentNode = this.getParentElement(el!);

    let offset;
    if (this.props.useWindow) {
      const doc = document.documentElement || document.body.parentNode || document.body;
      const scrollTop = scrollEl.pageYOffset !== undefined ? scrollEl.pageYOffset : doc.scrollTop;
      if (this.props.isReverse) {
        offset = scrollTop;
      } else {
        offset = this.calculateOffset(el!, scrollTop);
      }
    } else if (this.props.isReverse) {
      offset = parentNode.scrollTop;
    } else {
      offset = el!.scrollHeight - parentNode.scrollTop - parentNode.clientHeight;
    }

    // Here we make sure the element is visible as well as checking the offset
    if (offset < Number(this.props.threshold) && el && el.offsetParent !== null) {
      this.detachScrollListener();
      this.beforeScrollHeight = parentNode.scrollHeight;
      this.beforeScrollTop = parentNode.scrollTop;
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (typeof this.props.loadMore === 'function' && this.props.hasMore) {
        this.setState({isLoading: true});
        const maybePromise = this.props.loadMore((this.pageLoaded! += 1));
        if (maybePromise['then']) {
          maybePromise['then'](() => {
            if (this.mounted) this.setState({isLoading: false});
          });
        }
        this.loadMore = true;
      }
    }
  }

  private calculateOffset(el: HTMLElement, scrollTop: number) {
    if (!el) {
      return 0;
    }

    return this.calculateTopPosition(el) + (el.offsetHeight - scrollTop - window.innerHeight);
  }

  private calculateTopPosition(el: HTMLElement): number {
    if (!el) {
      return 0;
    }
    return el.offsetTop + this.calculateTopPosition(el.offsetParent as HTMLElement);
  }
}
