export function englexUrl(path = ''): string {
  return import.meta.env.REACT_APP_ENGLEX_URL + path;
}

export function dashboardUrl(path: string = ''): string | null {
  const url = import.meta.env.REACT_APP_DASHBOARD_URL;
  if (!url) {
    return null;
  }
  return url + path;
}

export const betaLoginUrl = (token: string): string | null => {
  const url = import.meta.env.REACT_APP_BETA_URL;
  if (!url) return null;
  return `${url}/dev-login/${token}`;
};
