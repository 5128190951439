import {type IntlShape} from 'react-intl';
import {Map} from 'immutable';
import {type Block, Editor, type Inline, Value, type ValueJSON} from '@englex/slate';
import * as yup from 'yup';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';
import {
  type ChoicesMap,
  type DNDChoice,
  WidgetTitle,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {
  type GapFillAnswersJSON,
  type GapFillChoicesJSON,
  type GapFillJSON
} from 'store/exercise/player/widgets/GapFill/interface';
import {isGapFillInline, isGapFillInlineOfType} from 'components/Slate/utils';
import {type GapFillInline, GapFillSize, GapFillType, SlateBlock} from 'components/Slate/interface';
import genKey from 'components/Slate/utils/genKey';
import {prefillValues} from 'helpers/prefillValues/prefillValues';

import {type XGapFillAnswers, type XGapFillProperties} from './interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import {documentHasGaps, documentNotEmpty} from '../validation';
import validationMessages from '../i18n';

const minCountIncorrectAnswers = 6;

const stripAnswers = (editor: Editor, gap: GapFillType, options: WidgetToJSONOptions): Editor =>
  editor.value.inlines.reduce(
    (ch: Editor, inline: GapFillInline): Editor =>
      isGapFillInlineOfType(inline, gap)
        ? ch.setNodeByKey(inline.key, {
            data: inline.data.withMutations(d => {
              const {generateIdentifiers, preserveAnswers} = options;
              if (!preserveAnswers) {
                if (!d.get('example')) {
                  d.delete('answer');
                }
                if ([GapFillType.DND, GapFillType.DND_INPUT].includes(gap) && !d.get('example')) {
                  d.delete('choiceId');
                }
              }
              if (generateIdentifiers) {
                d.set('id', genKey());
                if ([GapFillType.DND, GapFillType.DND_INPUT].includes(gap)) {
                  d.set('choiceId', genKey());
                }
              }
            })
          })
        : ch,
    editor
  );

const fillAnswers = (
  change: Editor,
  gap: GapFillType,
  answers: GapFillAnswersJSON,
  choices: GapFillChoicesJSON
): Editor =>
  change.value.inlines.reduce((ch: Editor, inline: GapFillInline): Editor => {
    const gapId = inline.data.get('id');
    if (isGapFillInline(inline) && answers[gapId]) {
      const choiceId: string = answers[gapId][0];
      const data = inline.data.withMutations(d => {
        if (inline.data.get('type') === GapFillType.DND) {
          d.set('answer', [choices[choiceId].value]).set('choiceId', choiceId);
        } else if (inline.data.get('type') === GapFillType.DND_INPUT) {
          d.set('answer', [choices[choiceId].value, ...answers[gapId].slice(1)]).set(
            'choiceId',
            choiceId
          );
        } else {
          d.set('answer', answers[gapId]);
        }
      });
      return ch.setNodeByKey(inline.key, {data});
    }
    return ch;
  }, change);

const initExtraChoices = (
  choices?: GapFillChoicesJSON,
  answers?: GapFillAnswersJSON
): ChoicesMap | undefined => {
  if (!answers || !choices) return undefined;
  const contentChoiceIds = Object.values(answers).map(v => v[0]);
  const extraChoiceIds = Object.keys(choices).filter(k => !contentChoiceIds.includes(k));
  return Map(extraChoiceIds.map(id => [id, choices[id]]));
};

class XGapFillRecord extends XFormattedTextRecord implements XGapFillProperties {
  public declare readonly gap: GapFillType;
  public declare readonly gapSize: GapFillSize;
  public declare readonly extraChoices?: ChoicesMap;
  public declare readonly hasPreFillValues: boolean;

  constructor(raw: GapFillJSON) {
    super(raw);
    this.initValues({
      content: this.contentFromJSON(raw),
      extraChoices:
        raw.gap === GapFillType.DND ? initExtraChoices(raw.choices, raw.answers) : undefined,
      gap: raw.gap,
      gapSize: raw.gapSize,
      hasPreFillValues: Boolean(raw.preFillValues)
    });
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ContentNonEmpty),
          (v: Value) => documentNotEmpty(v.document)
        )
        .test(
          'Has at least two Gaps',
          intl.formatMessage(validationMessages.GapFillHasNoGaps2),
          (v: Value) => {
            return [GapFillType.DND, GapFillType.DND_INPUT].includes(this.gap)
              ? documentHasGaps(v.document, 2)
              : true;
          }
        )
        .test(
          'Has at least one Gap',
          intl.formatMessage(validationMessages.GapFillHasNoGaps),
          (v: Value) => documentHasGaps(v.document)
        ),
      extraChoices: yup
        .mixed()
        .test(
          'Extra choices can not be blank',
          intl.formatMessage(validationMessages.ExtraChoicesNotEmpty),
          (ecs?: ChoicesMap) => {
            let valid = true;
            ecs?.forEach((ec: DNDChoice) => {
              if (!ec.value.trim()) {
                valid = false;
                return false;
              }
              return;
            });
            return valid;
          }
        )
        .test(
          'Extra choices can not be equal to ordinary choices',
          intl.formatMessage(validationMessages.ExtraChoicesNotEqualToContentChoices),
          (ecs?: ChoicesMap) => {
            if (!ecs) return true;
            const contentValues = this.contentChoices()
              .map((cc: DNDChoice) => cc.value.toLowerCase())
              .toArray();
            let valid = true;
            ecs.forEach((ec: DNDChoice) => {
              if (contentValues.includes(ec.value.trim().toLowerCase())) {
                valid = false;
                return false;
              }
              return;
            });
            return valid;
          }
        )
        .test(
          'Validation of incorrect answers',
          intl.formatMessage(validationMessages.Min6ImagesCountWithoutExamples),
          () => {
            const realChoices = this.choices.filter(item => !item?.isExtra);

            return (
              !this.hasPreFillValues ||
              (this.hasPreFillValues && realChoices.size >= minCountIncorrectAnswers)
            );
          }
        )
    });
  }

  public toJSON(options?: WidgetToJSONOptions): GapFillJSON {
    return {
      id: this.id,
      type: this.type,
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      content: this.contentToJSON(options),
      gap: this.gap,
      gapSize: this.gapSize,
      answers: this.answers.toJS(),
      choices: this.choices.size ? this.choices.toJS() : undefined,
      media: options?.withMedia && this.media ? this.media.toJS() : undefined,
      preFillValues: this.hasPreFillValues ? this.getPreFillValues() : undefined
    };
  }

  public get answers(): XGapFillAnswers {
    const change = new Editor({value: this.content});
    const value = change.moveToRangeOfDocument().value;
    const inlines = value.inlines.filter(
      (inline: GapFillInline) =>
        isGapFillInlineOfType(inline, this.gap) && !inline.data.get('example')
    );

    return Map(
      inlines.map((inline: GapFillInline) => [
        inline.data.get('id'),
        isGapFillInline(inline) && inline.data.get('type') === GapFillType.DND
          ? [inline.data.get('choiceId')]
          : inline.data.get('type') === GapFillType.DND_INPUT
            ? [inline.data.get('choiceId'), ...inline.data.get('answer').slice(1)]
            : inline.data.get('answer')
      ])
    );
  }

  public get choices(): ChoicesMap {
    const contentChoices = this.contentChoices();
    return (
      this.extraChoices
        ? contentChoices.concat(
            this.extraChoices.map(
              (ec: DNDChoice): DNDChoice => ({
                value: ec.value.trim(),
                caseSensitive: ec.caseSensitive,
                isExtra: true
              })
            )
          )
        : contentChoices
    ).sortBy(
      (_, k: string) => k,
      (k1, k2) => k1.localeCompare(k2)
    ) as ChoicesMap;
  }

  public get type() {
    return WidgetType.GAP_FILL;
  }

  public get title(): string {
    const title = WidgetTitle[this.type];
    switch (this.gap) {
      case GapFillType.DND:
        return title + ' (Drag-and-Drop)';
      case GapFillType.INPUT:
        return title + ' (Text Fields)';
      case GapFillType.DROPDOWN:
        return title + ' (Dropdown Lists)';
      case GapFillType.DND_INPUT:
        return title + ' (Drag-and-Drop Text Fields)';
      default:
        return title;
    }
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }

    let [text, cellIndex] = ['', 0];
    const {document} = this.content;

    document.getBlocks().forEach((b: Block): void | boolean => {
      if (text.length > exerciseExcerptLength - task.length) {
        return false;
      }
      const inlines = b.getInlines();
      const ancestors = document.getAncestors(b.key);
      const cellIsEven = cellIndex % 2 === 0;
      const isInDialogCell =
        ancestors && ancestors.find((node: Block) => node.type === SlateBlock.DIALOG_CELL);

      if (!inlines.size) {
        if (isInDialogCell) {
          cellIndex++;
          text += cellIsEven ? `${b.text}:` : b.text;
        } else {
          text += b.text;
        }
        return true;
      }

      let offset: number = 0;
      let reminder: string = '';
      const result =
        inlines.reduce((r: string, {data, key}: Inline) => {
          const slice = b.text.slice(offset, b.getOffset(key));
          reminder = b.text.slice(b.getOffset(key));
          offset += slice.length;
          return `${r}${slice}[${data.get('answer')}]`;
        }, '') + reminder;

      if (isInDialogCell) {
        cellIndex++;
        text += cellIsEven ? `${result}:` : result;
      } else {
        text += result;
      }
    });
    text = text.trim().substring(0, exerciseExcerptLength - task.length);
    return `${task} ${text}`.trim();
  }

  public addExtraChoice(): this {
    const extraChoices = this.extraChoices;
    return !extraChoices
      ? this.set('extraChoices', Map({[genKey()]: {value: '', isExtra: true}}))
      : this.set('extraChoices', extraChoices.set(genKey(), {value: '', isExtra: true}));
  }

  public deleteExtraChoice(extraChoiceId: string): this {
    if (!this.extraChoices) return this;
    if (this.extraChoices.size === 1) {
      return this.delete('extraChoices');
    }
    return this.set('extraChoices', this.extraChoices.delete(extraChoiceId));
  }

  public setExtraChoiceValue(extraChoiceId: string, value: string): this {
    return this.set('extraChoices', this.extraChoices?.set(extraChoiceId, {value}));
  }

  private contentFromJSON(contentJSON: GapFillJSON): Value {
    const {content, gap, answers = {}, choices = {}} = contentJSON;
    const value = Value.fromJSON(content);
    const change = new Editor({value});
    change.withoutSaving(() => {
      change
        .moveToRangeOfDocument()
        .command(fillAnswers, gap, answers, choices)
        .moveToStartOfDocument();
    });
    return change.value;
  }

  private contentChoices(): ChoicesMap {
    const editor = new Editor({value: this.content});
    const value = editor.moveToRangeOfDocument().value;
    const inlines = value.inlines.filter(
      (inline: GapFillInline) =>
        (isGapFillInlineOfType(inline, GapFillType.DND) ||
          isGapFillInlineOfType(inline, GapFillType.DND_INPUT)) &&
        !inline.data.get('example')
    );
    return Map(
      inlines.map((inline: GapFillInline) => {
        const caseSensitive = inline.data.get('caseSensitive');
        const choice: DNDChoice = {
          value: inline.data.get('answer')[0]
        };
        if (caseSensitive) {
          choice.caseSensitive = caseSensitive;
        }
        return [inline.data.get('choiceId'), choice];
      })
    );
  }

  private contentToJSON(options: WidgetToJSONOptions = {}): ValueJSON {
    const editor = new Editor({value: this.content});
    editor.moveToRangeOfDocument().command(stripAnswers, this.gap, options);
    return editor.value.toJSON();
  }

  private getPreFillValues() {
    const extraAnswers = this.choices
      .filter((choice: DNDChoice) => choice.isExtra === true)
      .keySeq()
      .toArray();

    return prefillValues(this.answers.toJS(), extraAnswers);
  }

  public togglePreFillValues() {
    return this.set('hasPreFillValues', !this.hasPreFillValues);
  }
}

decorate(XGapFillRecord, {
  gap: property(GapFillType.INPUT),
  gapSize: property(GapFillSize.LARGE),
  extraChoices: property(),
  hasPreFillValues: property()
});
record()(XGapFillRecord);
export default XGapFillRecord;
