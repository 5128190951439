import React, {type FC} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

interface Props {
  direction: 'left' | 'right';
  disabled?: boolean;
}

const Arrow: FC<Props> = ({direction, disabled}) => {
  return (
    <div className={classNames(`custom-arrow`, direction, {disabled})}>
      <Icon name={`chevron-${direction}`} />
    </div>
  );
};

export default Arrow;
