import {record} from 'immutable-record/decorator/record';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';

import genKey from '../../../../../components/Slate/utils/genKey';
import {type VocabularyCategoryProperties} from '../../../editor/widgets/XVocabulary/interface';
import {type VocabularyCategoryJSON} from './interface';

const Record = recordBase()(BaseRecord);

class VocabularyCategoryRecord extends Record implements VocabularyCategoryProperties {
  public declare readonly name: string;

  public declare readonly categoryId: string;

  constructor(raw: VocabularyCategoryJSON) {
    super();
    this.initValues({
      name: raw.name,
      categoryId: genKey()
    });
  }

  public toJSON(): VocabularyCategoryJSON {
    return {
      name: this.name
    } as VocabularyCategoryJSON;
  }

  public stripSpaces() {
    return this.set('name', this.name.trim());
  }
}

decorate(VocabularyCategoryRecord, {
  name: property(''),
  categoryId: property('')
});
record()(VocabularyCategoryRecord);
export default VocabularyCategoryRecord;
