import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {type ReactMarkdownProps} from 'react-markdown/lib/ast-to-react';

import {push} from 'store/router';

type Props = React.PropsWithChildren<
  Pick<
    React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    'key' | keyof React.AnchorHTMLAttributes<{}>
  > &
    ReactMarkdownProps
>;

const LinkRenderer: React.FC<Props> = ({children, href}) => {
  // children are arrayLike object which consists of text and react nodes gotten after parse process
  // if first element of this object is actual url, just render it w/o any parsing
  // if it is not - parse title.
  const dispatch = useDispatch();
  const onClick = useCallback(
    (e: React.MouseEvent) => {
      if (!href?.startsWith(window.origin)) return;
      e.preventDefault();
      e.stopPropagation();
      dispatch(push(href.replace(window.origin, '')));
    },
    [dispatch, href]
  );
  return (
    <a onClick={onClick} className="lnk" href={href} target="_blank" rel="noopener noreferrer">
      {typeof children?.[0] === 'string' &&
      (children[0].startsWith('https://') || children[0].startsWith('http://'))
        ? href
        : children}
    </a>
  );
};
export default LinkRenderer;
