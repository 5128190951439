import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../../interface';
import MultipleChoiceRecord from '../MultipleChoice/MultipleChoiceRecord';

class FreeChoiceRecord extends MultipleChoiceRecord {
  public get type() {
    return WidgetType.FREE_CHOICE;
  }
}

record()(FreeChoiceRecord);
export default FreeChoiceRecord;
