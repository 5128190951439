import React, {memo, useState} from 'react';
import classNames from 'classnames';

import Icon from 'components/Icon';

import './ArticleHeader.scss';
import {type Pronunciation} from '../../interfaces';

interface Props {
  word: string;
  pronunciation?: Pronunciation;
  isSidebar: boolean;
}

export const ArticleHeader: React.FC<Props> = memo(({word, pronunciation, isSidebar}) => {
  const [playing, setPlaying] = useState(false);

  const player = new Audio(pronunciation && pronunciation.audioFile);

  const onClick = () => {
    setPlaying(true);
    player.play();
    setTimeout(
      () => {
        setPlaying(false);
      },
      player.seekable.end(0) * 1000
    );
  };

  return (
    <div className={classNames('article-header', {'is-sidebar': isSidebar})}>
      <div className="article-header__word-wrapper">
        <span className="article-header__word">{word}</span>
        {pronunciation && (
          <button
            className={classNames('article-header__audio-button', {
              playing: playing
            })}
            onClick={onClick}
          >
            <Icon name="volume-up" />
          </button>
        )}
      </div>
      {pronunciation?.phoneticSpelling && (
        <div className="article-header__transcription">{`[${pronunciation.phoneticSpelling}]`}</div>
      )}
    </div>
  );
});
