import {Block, type Editor} from '@englex/slate';
import {List} from 'immutable';

import {type DataMap, type QuestionItemData, SlateBlock} from '../../../../interface';
import {getAllSelectedQuestions, getAncestorQuestionOfBlock} from './utils';
import {genKey} from '../../../../utils';

const removeQuestion = (ch: Editor, questionBlock: Block) => {
  const {
    value: {document}
  } = ch;
  const isOnlyQuestion =
    !document.getPreviousSibling(questionBlock.key) && !document.getNextSibling(questionBlock.key);
  if (isOnlyQuestion) {
    addQuestion(ch);
  }
  ch.removeNodeByKey(questionBlock.key);
};

export const removeQuestions = (ch: Editor) => {
  const {value} = ch;
  const selectedQuestionBlocks = getAllSelectedQuestions(value);

  ch.snapshotSelection();

  selectedQuestionBlocks.forEach(questionBlockToRemove =>
    ch.command(removeQuestion, questionBlockToRemove)
  );

  return ch;
};

const moveSelectionToStartOfLastQuestion = (ch: Editor) => {
  const questionsListBlock = ch.value.document.nodes.get(0) as Block;
  const lastQuestionLabelBlock = (
    questionsListBlock.nodes.get(questionsListBlock.nodes.size - 1) as Block
  ).nodes.get(0);
  return ch.moveToStartOfNode(lastQuestionLabelBlock);
};

export const addQuestion = (ch: Editor) => {
  const {document} = ch.value;
  const questionsList = document.nodes.get(0) as Block;
  // this paragraph will be wrapped in QUESTION_ITEM block and added answers in normalizer
  return ch
    .insertNodeByKey(questionsList.key, questionsList.nodes.size, Block.create(SlateBlock.DEFAULT))
    .command(moveSelectionToStartOfLastQuestion);
};

const mergeAnswerBlock = (change: Editor, answerBlock: Block) => {
  const childParagraph = answerBlock.nodes.get(0);
  return change.withoutNormalizing(() => {
    change.mergeNodeByKey(answerBlock.key);
    return change.mergeNodeByKey(childParagraph.key);
  });
};

const moveCursorToLastInsertedAnswer = (change: Editor, indexOfLastInsertedAnswer: number) => {
  const {document, startBlock} = change.value;
  const questionBlock = getAncestorQuestionOfBlock(document, startBlock!);
  return change.moveToEndOfNode(questionBlock.nodes.get(indexOfLastInsertedAnswer));
};

export const insertNewAnswers = (change: Editor, tmpChange: Editor, genKey: () => string) => {
  const insertedAnswers = tmpChange.value.document.nodes;

  const selectionIsAtEndOfNode = change.value.selection?.focus.isAtEndOfNode(
    change.value.startBlock!
  );
  const nodeIsEmpty = change.value.startBlock!.getText() === '';
  const shouldSplitAnswerBeforePasting = !selectionIsAtEndOfNode || nodeIsEmpty;
  // if cursor is in the middle of answer, and this answer is not empty, we should split this answer
  // in two and paste between separated parts
  if (!selectionIsAtEndOfNode || nodeIsEmpty) {
    change.splitBlock(2).moveToEndOfPreviousBlock();
    const splitAnswer = change.value.document.getParent(change.value.startBlock!.key) as Block;
    change.setNodeByKey(splitAnswer.key, {data: {id: genKey()}});
  }

  const lastAnswerBeforePastedFragment: Block = change.value.document.getParent(
    change.value.startBlock!.key
  ) as Block;
  const firstAnswerAfterPastedFragment: Block | null = change.value.document.getNextSibling(
    lastAnswerBeforePastedFragment.key
  ) as Block | null;
  const questionBlock = getAncestorQuestionOfBlock(
    change.value.document,
    lastAnswerBeforePastedFragment
  );
  const pasteIndex = questionBlock.nodes.indexOf(lastAnswerBeforePastedFragment) + 1;
  // perform paste for each block, also generate new id for each pasted block
  insertedAnswers.forEach((node: Block, index: number) => {
    tmpChange.setNodeByKey(node.key, {data: {id: genKey()}});
    change.insertNodeByKey(
      questionBlock.key,
      pasteIndex + index,
      tmpChange.value.document.getNode(node.key)!
    );
  });

  const indexOfLastInsertedAnswer = pasteIndex + insertedAnswers.size - 1;
  // cursor is now before pasted fragment, we should move it to last inserted answer
  change.command(moveCursorToLastInsertedAnswer, indexOfLastInsertedAnswer);

  const firstInsertedAnswer = change.value.document.getNextSibling(
    lastAnswerBeforePastedFragment.key
  ) as Block;

  // merge first inserted answer with previous block
  change.command(mergeAnswerBlock, firstInsertedAnswer);
  if (firstAnswerAfterPastedFragment && shouldSplitAnswerBeforePasting) {
    // if answer was split in the beginning, merge split part with last inserted answer
    change.command(mergeAnswerBlock, firstAnswerAfterPastedFragment);
  }
  return change;
};

export function toggleFirstLiIsExample(
  editor: Editor,
  containsExample: boolean,
  generateItemKey: () => string = genKey
) {
  const list = editor.value.document.getNode(List([0])) as Block;
  const listItem = editor.value.document.getNode(List([0, 0])) as Block;
  let data: DataMap<QuestionItemData>;
  if (containsExample) {
    data = listItem.data.withMutations(d => {
      d.delete('example');
      d.set('id', generateItemKey());
    }) as DataMap<QuestionItemData>;
  } else {
    data = listItem.data.withMutations(d => {
      d.set('example', true);
      d.delete('id');
    }) as DataMap<QuestionItemData>;
    if (list.nodes.size === 1) {
      editor.moveFocusToEndOfDocument().splitBlock(2);
    }
  }
  editor.setNodeByKey(listItem.key, {data}).moveToEndOfNode(listItem).focus();
  return editor;
}
