import React from 'react';
import classNames from 'classnames';
import {default as Tooltip} from 'rc-tooltip';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import isShortcut from 'helpers/shortcut';

import Icon from './Icon';
import {type PointerListenerProps} from './Pointer/element/PointerElementListener';

import './DropDown.scss';

export interface DropDownItem {
  value: string;
  icon?: string;
  disabled?: boolean;
}

export interface DropDownItems {
  [key: string]: string | DropDownItem;
}

interface Defaults extends PointerListenerProps {
  className: string;
  menuClassName: string;
  itemClassName: string;
  toggleClassName: string;
  overlayClassName: string;
  placement: TooltipProps['placement'];
  trigger: string[];
  disabled: boolean;
  destroyTooltipOnHide: TooltipProps['destroyTooltipOnHide'];
}

export interface DropDownProps extends Defaults {
  activeKey?: string;
  items: DropDownItems;
  id?: string;
  value?: string | JSX.Element;
  onChange?: (key: string) => void;
  onVisibleChangeCallback?(visible?: boolean): void;
  getTooltipContainer?(): HTMLElement;
  defaultVisible?: boolean;
  placeholder?: string;
  noCaret?: boolean;
  inline?: true;
  emptyItem?: string;
  emptyValue?: string;
}

interface State {
  visible: boolean;
  itemFocused?: string;
}

class DropDown extends React.Component<DropDownProps, State> {
  private get isVisible() {
    return this.state.visible;
  }

  private get isItemFocused(): boolean {
    const {itemFocused} = this.state;
    return itemFocused !== undefined;
  }
  public static readonly defaultProps: Defaults = {
    className: 'rc-dropdown',
    menuClassName: 'rc-dropdown-menu',
    itemClassName: 'rc-dropdown-menu-item',
    toggleClassName: 'rc-dropdown-toggle',
    overlayClassName: 'rc-dropdown-overlay',
    placement: 'bottomLeft',
    trigger: ['focus'],
    disabled: false,
    destroyTooltipOnHide: {keepParent: false}
  };

  private container: HTMLDivElement;
  private menu: HTMLUListElement;
  private toggleButton: HTMLDivElement | HTMLButtonElement;

  constructor(props: DropDownProps) {
    super(props);
    this.state = {
      visible: !!props.defaultVisible,
      itemFocused: undefined
    };
  }

  public render() {
    const {
      id,
      items,
      className,
      getTooltipContainer,
      placement,
      toggleClassName,
      trigger,
      overlayClassName,
      inline = false,
      destroyTooltipOnHide,
      disabled,
      onClick,
      onAnimationStart,
      onAnimationEnd
    } = this.props;
    const classes = classNames(className, {open: this.isVisible, inline});
    return (
      <div
        id={id}
        className={classes}
        ref={this.containerRef}
        onClick={onClick}
        onAnimationStart={onAnimationStart}
        onAnimationEnd={onAnimationEnd}
      >
        <Tooltip
          trigger={trigger}
          defaultVisible={false}
          onVisibleChange={this.onVisibleChange}
          destroyTooltipOnHide={destroyTooltipOnHide}
          visible={this.isVisible}
          overlay={this.renderMenu(items)}
          getTooltipContainer={getTooltipContainer ?? this.getTooltipContainer}
          placement={placement}
          overlayClassName={overlayClassName}
          align={{
            offset: [0, 0]
          }}
        >
          {React.createElement(
            inline ? 'div' : 'button',
            {
              className: classNames(toggleClassName, {disabled}),
              tabIndex: 0,
              onKeyDown: this.onKeyDown,
              onClick: this.toggle,
              disabled,
              ref: this.toggleRef
            },
            this.renderValue(),
            this.renderArrow()
          )}
        </Tooltip>
      </div>
    );
  }
  private containerRef = (el: HTMLDivElement) => (this.container = el);
  private toggleRef = (el: HTMLDivElement | HTMLButtonElement) => (this.toggleButton = el);
  private menuRef = (el: HTMLUListElement) => (this.menu = el);

  private renderValue = () => {
    const {value} = this.props;
    if (typeof value === 'string') {
      const empty = value.trim().length === 0;
      const title = !empty ? value : undefined;
      return (
        <span title={title} className={classNames('value', {empty})}>
          {empty ? this.props.emptyValue : value}
        </span>
      );
    }

    return value || this.renderPlaceholder();
  };

  private renderArrow = () => {
    if (this.props.noCaret) {
      return null;
    }
    return <Icon name="angle-down" className="arrow" />;
  };

  private renderPlaceholder = () => {
    const {placeholder = ''} = this.props;

    return <span className="placeholder">{placeholder}</span>;
  };

  private renderMenu = (items: DropDownItems) => {
    const {menuClassName} = this.props;
    return (
      <ul role="menu" className={menuClassName} ref={this.menuRef}>
        {Object.keys(items).map(this.renderChoice)}
      </ul>
    );
  };

  private renderChoice = (choiceKey: string) => {
    const {activeKey, items, itemClassName} = this.props;
    const item = items[choiceKey];
    let value: string;
    let icon: string | undefined;
    let disabled: boolean | undefined;
    if (typeof item === 'string') {
      value = item;
    } else {
      value = item.value;
      icon = item.icon;
      disabled = item.disabled;
    }

    const choice = value?.length ? value : this.props.emptyItem || ' ';
    return (
      <li
        key={choiceKey}
        className={classNames(itemClassName, {
          active: !activeKey ? value === this.props.value : activeKey === choiceKey,
          none: !value?.length && choice === this.props.emptyItem,
          disabled
        })}
      >
        <a
          tabIndex={disabled ? -2 : -1}
          onFocus={e => this.onItemFocus(e, choiceKey)}
          onBlur={e => this.onItemBlur(e, choiceKey)}
          onKeyDown={this.onKeyDown}
          onClick={e => this.onChange(e, choiceKey)}
        >
          {icon && <Icon name={icon} />}
          {choice}
        </a>
      </li>
    );
  };

  private toggle = (
    e: React.SyntheticEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    if (this.props.disabled) return;

    this.toggleButton.focus();

    if (this.isItemFocused) {
      this.setState({visible: !this.isVisible, itemFocused: undefined});
    } else {
      this.setState({visible: !this.isVisible});
    }
  };

  private onVisibleChange = (visible?: boolean) => {
    this.props.onVisibleChangeCallback?.(visible);
    if (!this.state.visible || this.isItemFocused || this.props.disabled) {
      return;
    }

    this.setState({visible: !!visible});
  };

  private getTooltipContainer = () => this.container as HTMLElement;

  private onItemFocus = (e: React.SyntheticEvent<HTMLAnchorElement>, choiceKey: string) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({itemFocused: choiceKey});
  };

  private onItemBlur = (e: React.SyntheticEvent<HTMLAnchorElement>, choiceKey: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.isItemFocused && this.state.itemFocused === choiceKey) {
      this.setState({itemFocused: undefined}, () => {
        requestAnimationFrame(() => {
          if (!this.isItemFocused) {
            this.setState({visible: false});
          }
        });
      });
    }
  };

  private onChange = (
    e: React.SyntheticEvent<HTMLAnchorElement | HTMLDivElement | HTMLButtonElement>,
    choiceKey: string
  ) => {
    this.toggle(e);
    if (this.props.onChange) {
      requestAnimationFrame(() => {
        this.props.onChange?.(choiceKey);
      });
    }
  };

  private onKeyDown = (
    e: React.KeyboardEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (isShortcut(e.nativeEvent, 'escape')) {
      if (this.isItemFocused || this.state.visible) {
        this.toggle(e);
      }
    }
    if (isShortcut(e.nativeEvent, 'down')) {
      e.preventDefault();
      if (this.state.visible) {
        this.focusNext();
      } else {
        this.toggle(e);
      }
    }
    if (isShortcut(e.nativeEvent, 'up')) {
      e.preventDefault();
      if (this.state.visible) {
        this.focusPrevious();
      }
    }
    if (isShortcut(e.nativeEvent, 'enter')) {
      if (this.isItemFocused) {
        e.preventDefault();
        this.onChange(e, this.state.itemFocused!);
      }
      if (this.props.inline) {
        this.toggle(e);
      }
    }
  };

  private focusNext = () => {
    const {items, activeItemIndex} = this.getItemsAndActiveIndex();

    if (!items || items.length === 0) {
      return;
    }

    if (items.length === 0) {
      return;
    }

    if (activeItemIndex === items.length - 1) {
      items[0].focus();
      return;
    }

    items[activeItemIndex + 1].focus();
  };

  private focusPrevious = () => {
    const itemsAndActiveIndex = this.getItemsAndActiveIndex();
    const {items} = itemsAndActiveIndex;
    let {activeItemIndex} = itemsAndActiveIndex;

    if (!items || items.length === 0) {
      return;
    }

    if (activeItemIndex < 0) {
      activeItemIndex = 0;
    }

    if (activeItemIndex === 0) {
      items[items.length - 1].focus();
      return;
    }

    items[activeItemIndex - 1].focus();
  };

  private getItemsAndActiveIndex = () => {
    const items = this.getFocusableMenuItems();
    const activeElement = document.activeElement;
    const activeItemIndex = items.indexOf(activeElement);

    return {items, activeItemIndex};
  };

  private getFocusableMenuItems = () => {
    const menuNode = this.menu;

    if (!menuNode) {
      return [];
    }

    return [].slice.call(menuNode.querySelectorAll('[tabIndex="-1"]'), 0);
  };
}

export default DropDown;
