import {type To} from 'react-router-dom';

import {
  type HistoryAction,
  type NavigateMethods,
  type RouterNavigateArgs,
  type RouterNavigateOptions,
  type Location,
  type LocationChangeAction,
  LOCATION_CHANGE,
  NAVIGATE,
  type NavigateAction
} from './interface';

export const locationChangeAction = (
  location: Location,
  action: HistoryAction
): LocationChangeAction => ({
  type: LOCATION_CHANGE,
  payload: {location, action}
});

function createNavigateAction<T extends NavigateMethods>(method: T) {
  return (...args: RouterNavigateArgs): NavigateAction => {
    return {
      type: NAVIGATE,
      payload: {method, args}
    };
  };
}

export const push: (
  to: To | null,
  opts?: Omit<RouterNavigateOptions, 'replace'>
) => NavigateAction = createNavigateAction('push');
export const replace: (
  to: To | null,
  opts?: Omit<RouterNavigateOptions, 'replace'>
) => NavigateAction = createNavigateAction('replace');
export const go: (...args: RouterNavigateArgs) => NavigateAction = createNavigateAction('go');
