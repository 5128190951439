import {Editor, Value} from '@englex/slate';
import {Set} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';
import {valueFromText} from 'components/Slate/utils';
import genKey from 'components/Slate/utils/genKey';
import {SlateMark} from 'components/Slate/interface';

import {type DisplayButtonProperties, DisplayButtonRecord} from './DisplayButtonRecord';
import {
  type ChoicesMap,
  type WidgetJSON,
  type WidgetProperties,
  type WidgetType
} from './interface';

const Record = recordBase()(BaseRecord);

abstract class WidgetRecord<A = unknown, V = unknown, C = ChoicesMap | undefined>
  extends Record
  implements WidgetProperties
{
  public declare readonly id: string;

  public declare readonly pristineTask?: Value;

  public declare readonly task: Value;

  public declare readonly displayButton?: DisplayButtonProperties;

  public declare readonly values: V;

  public declare readonly answers: A;

  public declare readonly choices: C;

  public declare readonly version: number | null;

  public declare readonly skipSync?: true;

  public abstract get type(): WidgetType;

  public setAnswersFromJSON(answers: unknown) {
    return this;
  }
  public createValues() {
    return this;
  }
  public setValuesFromJSON(values?: unknown) {
    return this;
  }
  public get throttleSendValues(): number | undefined {
    return undefined;
  }

  public hasDisplayButton() {
    return Boolean(this.displayButton);
  }

  public constructor(raw: WidgetJSON) {
    super();
    this.initValues({
      id: raw.id,
      task: raw.task ? Value.fromJSON(raw.task) : valueFromText(),
      version: raw.version || 0,
      displayButton: raw.displayButton && DisplayButtonRecord.create(raw.displayButton)
    });
  }

  /*
   * pristineTask is Value and unnecessary values in memory are expensive, so this property exists
   * only for extra exercises, because in a scope of a page enumeration of main exercises should not
   * change
   * */
  public setLabel(label: string) {
    const task = this.pristineTask || this.task;
    const editor = new Editor({value: task});

    if (!editor.value.selection) return this;

    const value = editor
      .moveToStart()
      .select(editor.value.selection.setMarks(Set()))
      .insertText(label + ' ')
      .moveToStartOfDocument()
      .moveEndForward(label.length)
      .toggleMark({type: SlateMark.EXERCISE_LABEL}).value;
    return this.withMutations(w => {
      w.set('task', value);
      if (!this.pristineTask) {
        w.set('pristineTask', this.task);
      }
    });
  }

  public toJSON(): WidgetJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version
    };
  }

  public get isAutoChecked() {
    return false;
  }

  public get isAvailableSelection() {
    return false;
  }
}

decorate(WidgetRecord, {
  id: property(genKey()),
  task: property(valueFromText()),
  pristineTask: property(),
  values: property(undefined),
  answers: property(undefined),
  choices: property(undefined),
  version: property(null),
  skipSync: property(undefined),
  displayButton: property(undefined)
} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
record()(WidgetRecord);
export default WidgetRecord;
