import {type ComponentType} from 'react';

import {type XPlayerProps} from 'components/XPlayer/interface';

interface CyclicComponent {
  XPlayer: ComponentType<XPlayerProps>;
}

const registry: Partial<CyclicComponent> = {};

/**
 * Use this function for components that somehow have cyclic dependencies, e.g. XPlayer:
 * XPlayer -> Exercise -> Widgets -> Widget -> ShowGrammarRules -> GrammarPlayerPage -> GrammarExercise -> XPlayer
 * TODO: XPlayer should be refactored to avoid rendering itself, like extra/supplementary exercises are implemented
 * @param name
 * @param type
 */
export const registerCyclicComponent = (
  name: keyof CyclicComponent,
  type: CyclicComponent[typeof name]
) => {
  registry[name] = type;
};

export const useCyclicComponent = (name: keyof CyclicComponent): CyclicComponent[typeof name] => {
  const component = registry[name];

  if (!component) {
    throw new Error(
      `Component '${name}' not found. Consider register your component by 'registerCyclicComponent'`
    );
  }
  return component;
};
