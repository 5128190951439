import {type Block, type Inline, type Mark, type Operation, type Value} from '@englex/slate';
import {type CSSProperties} from 'react';
import type * as Immutable from 'immutable';

export interface SlateReactChange {
  operations: Immutable.List<Operation>;
  value: Value;
}

export interface DataJSON {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  [key: string]: any;
}

type ImmutableMapData<D = DataJSON> = Immutable.Map<keyof D, D[keyof D]>;

export interface DataMap<D = DataJSON> extends ImmutableMapData<D> {
  get<K extends keyof D>(key: K, notSetValue?: D[K]): D[K];
  set<K extends keyof D>(key: K, value: D[K]): this;
  delete<K extends keyof D>(key: K): DataMap<D>;
  remove<K extends keyof D>(key: K): DataMap<D>;
}

enum SlateQueryType {
  getEditorId = 'getEditorId',
  getToolBox = 'getToolBox',
  getMarkClassNames = 'getMarkClassNames',
  getHtmlRules = 'getHtmlRules',
  getToolbarPortalId = 'getToolbarPortalId',
  getToolbarButtons = 'getToolbarButtons',
  getBeforeInsertFragmentPlugins = 'getBeforeInsertFragmentPlugins',
  getToolbarButtonDisablers = 'getToolbarButtonDisablers',
  getWidgetProps = 'getWidgetProps',
  getPlaceholder = 'getPlaceholder',
  getVoidInlineSelectedOnlyMarks = 'getVoidInlineSelectedOnlyMarks',
  isVoidInlineSelectedOnly = 'isVoidInlineSelectedOnly'
}

export const {
  getEditorId,
  getToolBox,
  getMarkClassNames,
  getHtmlRules,
  getToolbarPortalId,
  getToolbarButtons,
  getBeforeInsertFragmentPlugins,
  getToolbarButtonDisablers,
  getWidgetProps,
  getPlaceholder,
  getVoidInlineSelectedOnlyMarks,
  isVoidInlineSelectedOnly
} = SlateQueryType;

// Slate enums
export enum SlateObject {
  DOCUMENT = 'document',
  BLOCK = 'block',
  INLINE = 'inline',
  TEXT = 'text',
  MARK = 'mark'
}

export enum SlateBlock {
  DEFAULT = 'paragraph',
  EXAMPLE = 'Example',
  IMAGE = 'Image',
  LIST = 'List',
  LIST_ITEM = 'ListItem',
  DIALOG_TABLE = 'DialogTable',
  DIALOG_ROW = 'DialogRow',
  DIALOG_CELL = 'DialogCell',
  QUESTION_LIST = 'QuestionList',
  QUESTION_ITEM = 'QuestionItem',
  QUESTION_ANSWER = 'QuestionAnswer'
}

export enum SlateInline {
  GAP = 'Gap',
  ICON = 'Icon',
  LINK = 'Link',
  LABEL = 'Label'
}

export enum SlateMark {
  BOLD = 'Bold',
  ITALIC = 'Italic',
  UNDERLINE = 'Underline',
  STRIKE_THROUGH = 'StrikeThrough',
  FONT_SIZE = 'FontSize',
  COLOR = 'Color',
  HIGHLIGHT = 'Highlight',
  EXERCISE_LABEL = 'ExerciseLabel'
}

export enum SlateDecoration {
  PLACEHOLDER = 'placeholder',
  WORD = 'word',
  LETTER = 'letter'
}

export enum SlateAnnotation {
  CREATE_LINK = 'createLink',
  PARSED_WORD = 'parsedWord',
  CREATE_POINTER = 'createPointer',
  POINTER = 'pointer'
}

// Type enums
export enum BlockFloat {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right'
}

export enum ListType {
  OL = 'ol',
  UL = 'ul'
}

export enum ListStyleType {
  ALPHA = 'alpha'
}

export enum GapFillType {
  INPUT = 'input',
  DROPDOWN = 'dropdown',
  DND = 'dnd',
  DND_INPUT = 'dnd-input'
}

export enum GapFillSize {
  LARGE = 'large',
  SMALL = 'small'
}

export enum TextAlignValue {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  JUSTIFY = 'justify'
}

export enum JustifyContentValue {
  CENTER = 'center',
  LEFT = 'flex-start',
  RIGHT = 'flex-end',
  JUSTIFY = 'space-between'
}

// Data
export interface StyleData {
  style?: CSSProperties;
}

export interface ImageData extends StyleData {
  id: number;
  src: string;
  width: number;
  height: number;
  float?: BlockFloat;
}

export interface EditGapFillOptions {
  example?: true;
  caseSensitive?: true;
  choices?: string[];
  startOfSentence?: true;
  indefiniteForm?: string;
  editable?: true;
  idGen?: () => string;
  choiceIdGen?: () => string;
}

export interface GapFillInlineData extends EditGapFillOptions {
  id: string;
  choiceId?: string;
  type: GapFillType;
  answer: string[];
}

export type GapFillData = DataMap<GapFillInlineData>;

export interface LinkInlineData {
  href: string;
}

interface IsExampleData {
  example?: true;
}

export interface QuestionItemData extends IsExampleData {
  id: string;
}

// TODO: I think this aliases should be removed
export type StyledBlock = Block;
export type ImageBlock = Block;
export type ListBlock = Block;
export type ListItemBlock = Block;
export type QuestionItemBlock = Block;

export type StyledInline = Inline;
export type LinkInline = Inline;
export type GapFillInline = Inline;
export type IconInline = Inline;

export type ClassMark = Mark;
