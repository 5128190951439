import React, {type FC, type PropsWithChildren} from 'react';
import {FormattedMessage} from 'react-intl';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import {type Action} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {type ThunkDispatch} from 'redux-thunk';
import {Link} from 'react-router-dom';

import {type AppState} from 'store/interface';
import {
  visibleOnlyAcademic,
  visibleOnlyMethodist,
  visibleOnlyStudent,
  visibleOnlyTeacher
} from 'authentication';
import Icon from 'components/Icon';
import {push} from 'store/router';
import {betaLoginUrl, dashboardUrl} from 'helpers/url';

export const Profile = () => {
  const href = dashboardUrl('/profile');
  return href ? (
    <MenuItem href={href} className="profile-item">
      <FormattedMessage id="Header.Profile" />
      <Icon name="pc-profile" size="lg" />
    </MenuItem>
  ) : null;
};

export const Dashboard = (props: {className?: string}) => {
  const href = dashboardUrl();
  return href ? (
    <MenuItem href={href} className={props.className}>
      <FormattedMessage id="Header.Dashboard" />
      <Icon name="home" size="lg" />
    </MenuItem>
  ) : null;
};

const BetaLink: FC<PropsWithChildren<{token: string; className?: string}>> = props => {
  const href = betaLoginUrl(props.token);
  return href ? (
    <MenuItem href={href} target="_blank" className={props.className}>
      <span>Beta</span>
      <Icon name="user-secret" size="lg" />
    </MenuItem>
  ) : null;
};

export const MethodistBeta = visibleOnlyMethodist(BetaLink);

class LogoutComponent extends React.Component<{logout: () => Promise<void>}, {}> {
  public render() {
    const {logout} = this.props;
    return (
      <MenuItem onClick={logout}>
        <FormattedMessage id="Header.Logout" />
        <Icon name="pc-exit" size="lg" />
      </MenuItem>
    );
  }
}

type DispatchLogoutAction = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  logout: () => void;
};

const LogoutMDTP: DispatchLogoutAction = dispatch => ({
  logout: () => dispatch(push('/logout'))
});
export const Logout = connect(undefined, LogoutMDTP)(LogoutComponent);

export const Divider = () => <MenuItem divider={true} />;

export const Classroom = (props: {className?: string; active?: boolean; callback(): void}) => (
  <li
    role="presentation"
    className={classNames(props.className, {active: props.active})}
    onClick={props.callback}
  >
    <Link to="/room" role="menuitem" tabIndex={-1}>
      <FormattedMessage id="Header.Classroom" />
      <Icon name="pc-book" size="lg" />
    </Link>
  </li>
);

export const Intensive: FC<{callback(): void; pathname: string}> = ({callback, pathname}) => {
  const href = dashboardUrl('/student/intensiveUser/intensive');
  return href ? (
    <MenuItem href={href}>
      <FormattedMessage id="Header.Intensive" />
      <Icon name="pc-crm" size="lg" />
    </MenuItem>
  ) : null;
};

export const Skills = (props: {className?: string}) => {
  const href = dashboardUrl('/speakingclub/speakingclub/index/');
  return href ? (
    <MenuItem href={href} className={props.className}>
      <span>Skills</span>
      <i className="label label-success skills-icon-label">new</i>
    </MenuItem>
  ) : null;
};

export const NotificationsMenuItem = (props: {
  notificationsNumber?: number;
  isMobile?: boolean;
}) => {
  const className = classNames('notifications-menu-item', {
    mobile: props.isMobile
  });
  const href = dashboardUrl('/notification/notification');
  return href ? (
    <MenuItem className={className} href={href}>
      <FormattedMessage id="Header.NotificationsMessage" />
      <span className="wrapper">
        <Icon name="pc-notify" size="lg" />
        {props.notificationsNumber ? <span className="notifications-number" /> : null}
      </span>
    </MenuItem>
  ) : null;
};

const NewsMenuItem: FC = () => {
  const href = dashboardUrl('/news');
  return href ? (
    <MenuItem className="news-item" href={href}>
      <FormattedMessage id="Header.News" />
      <Icon name="pc-news" size="lg" />
    </MenuItem>
  ) : null;
};

export const News = visibleOnlyAcademic(NewsMenuItem);

const TeachersCornerMenuItem = () => {
  const href = dashboardUrl() && import.meta.env.REACT_APP_TEACHERS_CORNER_URL;
  return href ? (
    <MenuItem href={href}>
      <FormattedMessage id="Header.TeachersCorner" />
      <Icon name="book" size="lg" />
    </MenuItem>
  ) : null;
};

export const WrappedTeachersCornerMenuItem = visibleOnlyTeacher(TeachersCornerMenuItem);

const PaymentsMenuItem = () => {
  const href = dashboardUrl('/payment/payment/index');
  return href ? (
    <MenuItem href={href}>
      <FormattedMessage id="Header.Payments" />
      <Icon name="pc-payments" size="lg" />
    </MenuItem>
  ) : null;
};

export const WrappedPaymentsMenuItem = visibleOnlyStudent(PaymentsMenuItem);

const LessonHistoryMenuItem: FC = () => {
  const href = dashboardUrl('/lesson/lesson/index');
  return href ? (
    <MenuItem href={href}>
      <FormattedMessage id="Header.LessonHistory" />
      <Icon name="pc-history" size="lg" />
    </MenuItem>
  ) : null;
};

export const WrappedHistoryMenuItem = visibleOnlyStudent(LessonHistoryMenuItem);

export const HomeworkLink: FC<{callback(): void; isMethodist: boolean; pathname: string}> = ({
  callback,
  isMethodist,
  pathname
}) => {
  if (isMethodist) {
    return null;
  }
  return (
    <li
      role="presentation"
      className={classNames({active: pathname === '/homework'})}
      onClick={callback}
    >
      <Link to="/homework" role="menuitem" tabIndex={-1}>
        <FormattedMessage id="LessonPage.Homework" />
        <Icon name="virc-homework-added" size="lg" />
      </Link>
    </li>
  );
};

export const DictionaryLink = (props: {
  className?: string;
  active?: boolean;
  callback(): void;
  isMethodist: boolean;
}) =>
  props.isMethodist ? null : (
    <li
      role="presentation"
      className={classNames(props.className, {active: props.active})}
      onClick={props.callback}
    >
      <Link to="/dictionary" role="menuitem" tabIndex={-1}>
        <FormattedMessage id="Vocabulary" />
        <Icon name="virc-vocabulary" size="lg" />
      </Link>
    </li>
  );

export const FeedbackLink = (props: {
  className?: string;
  active?: boolean;
  callback(): void;
  isMethodist: boolean;
}) =>
  props.isMethodist ? (
    <li
      role="presentation"
      className={classNames(props.className, {active: props.active})}
      onClick={props.callback}
    >
      <Link to="/library/feedback" role="menuitem" tabIndex={-1}>
        <FormattedMessage id="Feedback.Feedback" />
        <Icon name="comments-o" size="lg" />
      </Link>
    </li>
  ) : null;
