import React, {useCallback, useEffect} from 'react';
import {useIntl} from 'react-intl';
import {HistoryPaint} from '@englex/paint-history';
import {useFocused, usePaint, withPaint} from '@englex/paint-react';

import Icon from 'components/Icon';
import isShortcut from 'helpers/shortcut';

import {ToolbarButton} from './ToolbarButton';

interface Props {
  type: 'undo' | 'redo';
}

const shortcuts = {
  undo: 'mod+z',
  redo: ['mod+shift+z', 'mod+y']
};

export const HistoryButton = withPaint(({type}: Props) => {
  const isFocused = useFocused();
  const intl = useIntl();
  const paint = usePaint<HistoryPaint>();

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isFocused) {
        return;
      }

      const shortcut = shortcuts[type];
      if (shortcut && isShortcut(e, shortcut)) {
        e.preventDefault();
        e.stopPropagation();
        paint[type]?.();
      }
    },
    [isFocused, paint, type]
  );

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  const disabled = type === 'undo' ? !HistoryPaint.hasUndos(paint) : !HistoryPaint.hasRedos(paint);

  return (
    <ToolbarButton
      onClick={useCallback(
        () => (type === 'undo' ? HistoryPaint.undo(paint) : HistoryPaint.redo(paint)),
        [paint, type]
      )}
      disabled={disabled}
      title={
        type === 'undo'
          ? intl.formatMessage({id: 'Common.Undo'})
          : intl.formatMessage({id: 'Common.Redo'})
      }
      shortcut={shortcuts[type]}
    >
      <Icon name="undo" flip={type !== 'undo' && 'horizontal'} />
    </ToolbarButton>
  );
}, HistoryPaint.is);
