import {type List, Map} from 'immutable';
import {Value} from '@englex/slate';
import {type Descendant} from 'slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import FormattedTextRecord from '../../FormattedText/FormattedTextRecord';
import {WidgetType} from '../../../interface';
import {
  type QuestionsJSON,
  type QuestionsProperties,
  type QuestionsValues,
  type QuestionsValuesJSON,
  type QuestValueProperties
} from '../interface';
import {getQuestions, initValues} from '../Quests/utils';

class QuestionsRecord
  extends FormattedTextRecord<undefined, QuestionsValues>
  implements QuestionsProperties
{
  public declare readonly questions: List<Value>;

  public declare readonly questionsCountWords?: boolean;

  public declare readonly questionsExampleContent?: Value;

  constructor(raw: QuestionsJSON & {type?: WidgetType}) {
    super(raw);
    const questions = getQuestions(raw.content);
    this.initValues({
      questions,
      questionsCountWords: raw.options && raw.options.countWords,
      questionsExampleContent: raw.questsExampleContent
        ? Value.fromJSON(raw.questsExampleContent)
        : undefined,
      values: Map({
        onReview: raw.values?.onReview,
        opened: raw.values?.opened,
        questions: initValues(questions, raw.values?.questions)
      })
    });
  }
  public contentChange(value: Descendant[], id: string): this {
    const question: QuestValueProperties = this.getIn(['values', 'questions', id]);

    if (!question) return this;

    return this.withMutations(r => {
      r.setIn(['values', 'questions', id], question.contentChange(value));
      r.setIn(['values', 'opened'], true);
    });
  }

  public toJSON(): QuestionsJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      skipSync: this.skipSync,
      content: this.content.toJSON(),
      values: this.values ? this.values.toJS() : undefined
    };
  }

  public get type() {
    return WidgetType.QUESTIONS;
  }

  public setValuesFromJSON(values?: QuestionsValuesJSON) {
    return this.set(
      'values',
      Map({
        opened: values?.opened,
        questions: initValues(this.questions, values?.questions),
        onReview: values?.onReview
      })
    );
  }

  public get throttleSendValues(): number {
    return 500;
  }
}

decorate(QuestionsRecord, {
  questions: property(),
  questionsCountWords: property(false),
  questionsExampleContent: property()
});
record()(QuestionsRecord);
export default QuestionsRecord;
