import {
  type Annotation,
  type Block,
  type Document,
  Editor,
  type Path,
  Point,
  Value
} from '@englex/slate';
import {type List, Map} from 'immutable';
import * as yup from 'yup';
import {type IntlShape} from 'react-intl';

import {
  type ScrambledAnswersJSON,
  type ScrambledArrayChoicesJSON,
  type ScrambledChoicesJSON,
  type ScrambledJSON,
  type ScrambledOptions,
  type ScrambledSentencesJSON
} from 'store/exercise/player/widgets/ScrambledSentences/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {type WidgetToJSONOptions, WidgetType} from 'store/exercise/player/interface';
import {genKey, isBlockOfType, valueJSONFromText} from 'components/Slate/utils';
import {type DataMap, SlateAnnotation, SlateBlock} from 'components/Slate/interface';
import {
  exerciseExcerptLength,
  letterMatcher,
  wordFilter,
  wordSplitter,
  wordTemplateLiteral
} from 'config/static';

import validationMessages from '../i18n';
import {jsonContentFromSentences} from './initials';
import {
  containsNonExampleScrambledSentence,
  documentNotEmpty,
  sentencesContainDraggablesAmount,
  sentencesContainWordsAmount
} from '../validation';
import XWidgetRecord from '../XWidgetRecord';
import {
  type GetPartsAndWordsResult,
  type Token,
  type XScrambledJSON,
  type XScrambledProperties
} from './interface';
import {addAnnotation} from '../../../../../components/Slate/SlateEditor/plugins/TextDecorator';

class XScrambledRecord
  extends XWidgetRecord<XScrambledJSON, DataMap<ScrambledOptions>>
  implements XScrambledProperties
{
  public declare readonly sentencesValue: Value;
  public declare readonly cachedSentences?: ScrambledSentencesJSON;
  public declare readonly cachedAnswers?: ScrambledAnswersJSON;
  private exampleAnswers?: string[];

  public static create(sentences: string[], manualSplitting?: boolean): XScrambledRecord {
    return new this({
      id: genKey(),
      type: WidgetType.SCRAMBLED_SENTENCES,
      task: valueJSONFromText(''),
      sentencesValueJSON: jsonContentFromSentences(sentences),
      ...(manualSplitting ? {options: {manualSplitting}} : {})
    });
  }

  public constructor(raw: XScrambledJSON) {
    super(raw);
    this.initValues({
      sentencesValue: this.initContent(raw),
      cachedSentences: raw.sentences ? this.sentencesChoicesToMap(raw.sentences) : undefined,
      cachedAnswers: raw.answers ? raw.answers : undefined,
      options: raw.options ? Map(raw.options) : undefined
    });
  }

  public get type() {
    return WidgetType.SCRAMBLED_SENTENCES;
  }

  public get answers(): ScrambledAnswersJSON {
    const {annotations, document} = this.sentencesValue;
    const listItems = (document.nodes.get(0) as Block).nodes;
    if (this.options?.get('manualSplitting')) {
      return listItems.reduce((answers: ScrambledAnswersJSON, li: Block, i: number) => {
        const id = li.data.get('id');
        const ids = this.liAnnotations(document, li, annotations)
          .sort((a1, a2) => a1.start.offset - a2.start.offset)
          .keySeq()
          .toArray();
        if (i === 0) {
          if (this.options && this.options.get('containsExample')) {
            this.exampleAnswers = ids;
            return {};
          } else {
            this.exampleAnswers = undefined;
          }
        }
        return {
          ...answers,
          [id]: ids
        };
      }, {});
    } else {
      return this.autoAnswers();
    }
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = this.sentencesValue.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.LIST_ITEM))
      .map((b: Block, i: number) => `${i + 1}. ${b.text}`)
      .join(' ');
    return `${task} ${text}`.trim();
  }

  public toggleExample(): XScrambledRecord {
    const {document} = this.sentencesValue;
    const firstItem = document.nodes.first();
    if (!firstItem) return this;
    if (this.options?.get('containsExample')) {
      return this.deleteIn(['options', 'containsExample']);
    }
    return this.options
      ? this.setIn(['options', 'containsExample'], true)
      : this.set('options', Map({containsExample: true}));
  }

  public schema(intl: IntlShape) {
    return yup.object({
      sentencesValue: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ContentNonEmpty),
          ({document}: Value) => documentNotEmpty(document)
        )
        .test(
          'Should contain non example scrambled sentence',
          intl.formatMessage(validationMessages.NotOnlyExampleQuestion),
          ({document}: Value) => containsNonExampleScrambledSentence(document, this.options)
        )
        .test(
          'Sentence should contain at least two words',
          intl.formatMessage(validationMessages.SentenceIsTooShort),
          (v: Value) => {
            if (this.options?.get('manualSplitting')) return true;
            return sentencesContainWordsAmount(v);
          }
        )
        .test(
          'Sentence should contain at least two draggable blocks',
          intl.formatMessage({id: 'XEditor.Validation.NotEnoughDraggables'}),
          (v: Value) => {
            if (!this.options?.get('manualSplitting')) return true;
            return sentencesContainDraggablesAmount(v);
          }
        )
    });
  }

  public toJSON(options?: WidgetToJSONOptions): ScrambledJSON {
    const answers = this.answers;
    return {
      id: this.id,
      type: this.type,
      task: documentNotEmpty(this.task.document) ? this.task.toJSON() : null,
      options: this.options?.toJS(),
      answers,
      sentences: this.computeSentences(
        this.sentencesValue,
        answers,
        this.exampleAnswers,
        this.options?.get('manualSplitting')
      ),
      media: options && options.withMedia && this.media ? this.media.toJS() : undefined
    };
  }

  protected autoAnswers(): ScrambledAnswersJSON {
    const {document} = this.sentencesValue;
    const listItems = (document.nodes.get(0) as Block).nodes;
    return listItems.reduce((answers: ScrambledAnswersJSON, li: Block, i: number) => {
      const id = li.data.get('id');
      const text = li.text;
      const words = this.filterSentences(this.splitSentences(text));

      const liCachedAnswers =
        i === 0 && this.cachedSentences && this.cachedSentences[0].answers
          ? this.cachedSentences[0].answers
          : this.cachedAnswers
            ? this.cachedAnswers[id]
            : undefined;

      let ids;

      if (!liCachedAnswers) {
        ids = words.map(() => genKey());
      } else if (liCachedAnswers.length !== words.length) {
        const length = words.length;
        if (liCachedAnswers.length < length) {
          ids = [
            ...liCachedAnswers,
            ...Array.from({length: length - liCachedAnswers.length}).map(() => genKey())
          ];
        } else {
          ids = liCachedAnswers.slice(0, length);
        }
      } else {
        ids = liCachedAnswers;
      }
      if (i === 0) {
        if (this.options && this.options.get('containsExample')) {
          this.exampleAnswers = ids;
          return {};
        } else {
          this.exampleAnswers = undefined;
        }
      }
      return {...answers, [id]: ids};
    }, {});
  }

  protected splitSentences(text: string) {
    return text.split(wordSplitter);
  }

  protected filterSentences(texts: string[]) {
    return texts.filter(p => wordFilter.test(p) && letterMatcher.test(p));
  }

  protected initContent({
    sentencesValueJSON,
    sentences: givenSentences,
    answers: givenAnswers,
    options
  }: XScrambledJSON): Value {
    if (sentencesValueJSON) {
      return Value.fromJSON(sentencesValueJSON);
    }
    if (!givenSentences || !givenAnswers) {
      throw new Error(
        'Unable to create XScrambledRecord instance: ensure "sentences", "choices" and "answers" fields are exist'
      );
    }

    const preparedSentences = this.sentencesChoicesToMap(givenSentences);
    const answers = preparedSentences[0].answers
      ? {[preparedSentences[0].id]: preparedSentences[0].answers, ...givenAnswers}
      : givenAnswers;
    const wordSequences = this.buildWordSequences(preparedSentences, answers);
    const sentences = this.buildSentences(preparedSentences, wordSequences);
    const editor = new Editor({value: Value.fromJSON(jsonContentFromSentences(sentences))});

    this.setListItemsIds(
      editor,
      preparedSentences.map(s => s.id)
    );

    if (options?.manualSplitting) {
      this.annotateValueManual(editor, preparedSentences, answers, wordSequences);
    } else {
      this.annotateValue(editor, preparedSentences, answers, wordSequences);
    }

    return editor.value;
  }

  protected annotateValueManual = (
    editor: Editor,
    givenSentences: ScrambledSentencesJSON,
    answers: ScrambledAnswersJSON,
    wordSequences: Array<{[id: string]: string[]}>
  ): void => {
    editor.withoutNormalizing(() => {
      givenSentences.forEach((s, i) => {
        const textNodePath = [0, i, 0, 0];
        const wordsIds = answers[s.id];
        let filledTemplate = s.template;

        wordsIds.forEach((wordId, n) => {
          const capitalize = s.choices[wordId].capitalize;
          const offset = filledTemplate.indexOf(wordTemplateLiteral);
          const wordSequence = wordSequences.find(ws => Object.keys(ws)[0] === s.id)![s.id];
          const word = wordSequence[n];
          filledTemplate = filledTemplate.replace(wordTemplateLiteral, word);
          editor.addAnnotation(
            editor.value.document.createAnnotation({
              type: SlateAnnotation.PARSED_WORD,
              key: wordId,
              anchor: Point.create({path: textNodePath, offset}),
              focus: Point.create({path: textNodePath, offset: offset + word.length}),
              data: capitalize ? {capitalize} : {}
            })
          );
        });
      });
    });
  };

  protected getPartsAndWords(text: string, tokenIds: string[]): GetPartsAndWordsResult {
    const parts = this.splitSentences(text);
    const words = this.filterSentences(parts);

    return {parts, words};
  }

  protected liTemplate(text: string, words: string[]) {
    let template = text;
    words.forEach(w => {
      template = template.replace(w, wordTemplateLiteral);
    });

    return template;
  }

  protected liTokens(
    tokenIds: string[],
    parts: string[],
    words: string[],
    shuffledIds?: string[],
    sentenceId?: string,
    wordIds?: string[]
  ): Token[] {
    const tokens: Token[] = [];
    let offset = 0;
    let i = 0;
    parts.forEach(part => {
      if (words.includes(part)) {
        tokens.push({
          id: `${tokenIds[i]}`,
          value: part,
          offset
        });
        i++;
      }
      offset += part.length;
    });
    return tokens;
  }

  protected liChoices(
    tokens: Token[],
    annotations: Map<string, Annotation>,
    answers: string[]
  ): ScrambledChoicesJSON {
    const choices = this.tokensToChoices(tokens, annotations);
    return this.shuffleChoices(choices, answers);
  }

  protected tokensToChoices(
    tokens: Token[],
    annotations: Map<string, Annotation>
  ): ScrambledChoicesJSON {
    return tokens.reduce((r: ScrambledChoicesJSON, {id, offset, value, tokenGroupId}: Token) => {
      const capitalize =
        !!annotations.filter((a: Annotation) => a.anchor.offset === offset).size || undefined;
      r[id] = {value, tokenGroupId};
      if (capitalize) {
        r[id].capitalize = capitalize;
      }
      if (tokenGroupId) {
        r[id].tokenGroupId = tokenGroupId;
      }
      return r;
    }, {});
  }

  protected choicesToArray(choices: ScrambledChoicesJSON): ScrambledArrayChoicesJSON {
    return Object.entries(choices).map(([id, choice]) => ({...choice, id}));
  }

  protected liTemplateManual(li: Block, annotations: Map<string, Annotation>): string {
    const {text} = li.getTexts().first();
    let template = text;
    annotations
      .sort((a1, a2) => a2.start.offset - a1.start.offset)
      .forEach(({start: {offset: sO}, end: {offset: eO}}: Annotation) => {
        template = `${template.substring(0, sO)}${wordTemplateLiteral}${template.substring(eO)}`;
      });
    return template;
  }

  protected liTokensManual(
    li: Block,
    tokenIds: string[],
    annotations: Map<string, Annotation>
  ): Token[] {
    const {text} = li.getTexts().first();
    return annotations
      .sort((a1, a2) => a1.start.offset - a2.start.offset)
      .valueSeq()
      .map((a: Annotation, i: number) => {
        return {
          id: `${tokenIds[i]}`,
          capitalize: a.data.get('capitalize'),
          value: text.substring(a.start.offset, a.end.offset),
          offset: a.start.offset
        };
      })
      .toArray();
  }

  /**
   * Shuffle choice IDs
   *
   * This helper shuffles the scrambled sentences (SS) choices to ensure that the choices order in the SS widget is not correct by default.
   * The result should be stable and deterministic, so we assume that the choice IDs are already random-generated strings
   * and for achieving the deterministic shuffle result we just reverse-sorting the choice ID strings.
   * Using such an approach, it's unfortunately possible to get the correct choices order in some particular cases,
   * so in this case we just reverse our shuffled result.
   *
   * @param choices
   * @param answers
   */
  protected shuffleChoices(choices: ScrambledChoicesJSON, answers: string[]): ScrambledChoicesJSON {
    let shuffledKeys = Object.keys(choices).sort((a, b) => b.localeCompare(a));
    let shouldShuffleAgain = false;

    if (answers) {
      shouldShuffleAgain = true;
      answers.forEach((a, i) => {
        if (shouldShuffleAgain && a !== shuffledKeys[i]) {
          shouldShuffleAgain = false;
        }
      });
    }

    if (shouldShuffleAgain) {
      shuffledKeys = shuffledKeys.reverse();
    }

    return shuffledKeys.reduce((r: {[id: string]: {value: string; capitalize?: true}}, k) => {
      r[k] = choices[k];
      return r;
    }, {});
  }

  protected liChoicesManual(tokens: Token[], answers: string[]): ScrambledChoicesJSON {
    const choices = tokens.reduce((r: ScrambledChoicesJSON, {id, capitalize, value}: Token) => {
      r[id] = capitalize ? {value, capitalize} : {value};
      return r;
    }, {});
    return this.shuffleChoices(choices, answers);
  }

  protected computeSentences(
    {annotations, document}: Value,
    answers: ScrambledAnswersJSON,
    exampleAnswers?: string[],
    manualSplitting?: true
  ) {
    return (document.nodes.get(0) as Block).nodes
      .map((li: Block, i: number) => {
        const {text} = li.getTexts().first();
        const blockAnnotations = this.liAnnotations(document, li, annotations);
        const sentenceId = li.data.get('id');
        const answerIds = i === 0 && exampleAnswers ? exampleAnswers : answers[sentenceId];
        const {parts, words, wordIds, shuffledIds} = this.getPartsAndWords(text, answerIds);
        const template = manualSplitting
          ? this.liTemplateManual(li, blockAnnotations)
          : this.liTemplate(text, words);
        const tokens = manualSplitting
          ? this.liTokensManual(li, answerIds, blockAnnotations)
          : this.liTokens(answerIds, parts, words, shuffledIds, sentenceId, wordIds);
        const choices = manualSplitting
          ? this.liChoicesManual(tokens, answerIds)
          : this.liChoices(tokens, blockAnnotations, answerIds);
        return {
          id: sentenceId,
          template,
          choices: this.choicesToArray(choices),
          answers: i === 0 ? exampleAnswers : undefined
        };
      })
      .toArray();
  }

  protected liAnnotations(
    document: Document,
    li: Block,
    annotations: Map<string, Annotation>
  ): Map<string, Annotation> {
    const liPath = document.getPath(li.key);
    return annotations.filter((a: Annotation) =>
      (a.anchor.path as List<number>).slice(0, 2).equals(liPath as List<number>)
    ) as Map<string, Annotation>;
  }

  protected annotateValue(
    editor: Editor,
    givenSentences: ScrambledSentencesJSON,
    answers: ScrambledAnswersJSON,
    wordSequences: Array<{[id: string]: string[]}>
  ) {
    const {document} = editor.value;
    const listItems = (document.nodes.first() as Block).nodes;
    editor.withoutNormalizing(() => {
      givenSentences.forEach((s, i) => {
        const textNodePath = document.getPath(listItems.get(i).getFirstText()!.key) as Path;
        const wordsIds = answers[s.id];
        let filledTemplate = s.template;

        wordsIds.forEach((wordId, n) => {
          const capitalize = s.choices[wordId]?.capitalize;
          const offset = filledTemplate.indexOf(wordTemplateLiteral);
          const wordSequence = wordSequences.find(ws => Object.keys(ws)[0] === s.id)![s.id];
          const word = wordSequence[n];
          filledTemplate = filledTemplate.replace(wordTemplateLiteral, word);
          if (capitalize) {
            editor.command(addAnnotation, textNodePath, offset, offset + word.length, genKey);
          }
        });
      });
    });
  }

  protected sentencesChoicesToMap(sentences: ScrambledSentencesJSON) {
    return sentences.map(s => ({
      ...s,
      choices: Array.isArray(s.choices)
        ? s.choices.reduce((o, c) => ({...o, [c.id]: {...c}}), {})
        : s.choices
    }));
  }

  protected buildWordSequences = (
    sentences: ScrambledSentencesJSON,
    answers: ScrambledAnswersJSON
  ) => {
    const result = [];

    for (const id in answers) {
      if (id) {
        const wordSequence: string[] = [];
        answers[id].forEach(answer => {
          wordSequence.push(sentences.find(s => s.id === id)!.choices[answer]?.value);
        });
        result.push({[id]: wordSequence});
      }
    }
    return result;
  };

  protected setListItemsIds(editor: Editor, ids: string[]) {
    const listItems = (editor.value.document.nodes.get(0) as Block).nodes;
    editor.withoutNormalizing(() => {
      listItems.forEach((li: Block, i: number) => {
        editor.setNodeByKey(li.key, {type: SlateBlock.LIST_ITEM, data: {id: ids[i]}});
      });
    });
  }

  protected buildSentences(
    sentences: ScrambledSentencesJSON,
    wordSequences: Array<{[id: string]: string[]}>
  ) {
    return sentences.reduce((r: string[], {template, id}) => {
      const wordSentence = wordSequences.find(ws => Object.keys(ws)[0] === id)![id];
      let constructed = template;
      wordSentence.forEach((w: string) => {
        constructed = constructed.replace(wordTemplateLiteral, w);
      });
      return [...r, constructed];
    }, []);
  }
}

decorate(XScrambledRecord, {
  sentencesValue: property(valueJSONFromText()),
  cachedSentences: property(),
  cachedAnswers: property()
});
record()(XScrambledRecord);
export default XScrambledRecord;
