import {Value} from '@englex/slate';

import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {record} from 'immutable-record/decorator/record';
import genKey from 'components/Slate/utils/genKey';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {type DialogueSpeakerJSON} from 'store/exercise/player/widgets/Dialogue/interface';
import {valueFromText} from 'components/Slate/utils';

import {type XDialogueSpeakerProperties, type XDialogueSpeakerProps} from './interface';

const Record = recordBase()(BaseRecord);

class XDialogueSpeakerRecord extends Record implements XDialogueSpeakerProperties {
  public declare readonly id: string;

  public declare readonly name: Value;

  public declare readonly avatar: number;

  constructor(raw: Partial<XDialogueSpeakerProps> = {}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      name: raw.name ? Value.fromJSON(raw.name) : valueFromText(''),
      avatar: raw.avatar || ''
    });
  }

  public toJSON(): DialogueSpeakerJSON {
    return {
      id: this.id,
      name: this.name.toJSON(),
      avatar: this.avatar
    };
  }
}

decorate(XDialogueSpeakerRecord, {
  id: property(genKey()),
  name: property(),
  avatar: property('')
});

record()(XDialogueSpeakerRecord);
export default XDialogueSpeakerRecord;
