import React, {type FC} from 'react';
import {type BasicToastrOptions} from 'react-redux-toastr';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage} from 'react-intl';

import {openingUrlForTeacherTimeout} from 'config/static';
import Icon from 'components/Icon';

import './ConfirmOpenExerciseToastr.scss';

type Void = () => void;

export const confirmOpenExerciseOptions = (
  handleClick: Void
): BasicToastrOptions & {disableCloseButtonFocus: true} => ({
  className: 'confirm-open-exercise-toastr',
  component: <ConfirmButton handleClick={handleClick} />,
  icon: <Icon name="warning" />,
  removeOnHover: false,
  timeOut: openingUrlForTeacherTimeout - 1000,
  disableCloseButtonFocus: true
});

const ConfirmButton: FC<{handleClick: Void; remove?: Void}> = ({handleClick, remove}) => (
  <Button
    onMouseDown={e => {
      e.preventDefault();
      e.stopPropagation();
      remove!();
      handleClick();
    }}
    bsSize="sm"
  >
    <Icon name="virc-target" />
    <FormattedMessage id="Exercise.Redirect" />
  </Button>
);
