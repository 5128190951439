import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/lib/Button';
import Tooltip from 'rc-tooltip';

import {push} from 'store/router';
import Icon from 'components/Icon';
import {type ExerciseLocation} from 'store/exercise/player/interface';
import {type AppState, type CoursebookUnit, type Role} from 'store/interface';
import {unitInstancePagePath, unitInstancePreviewPath} from 'common/paths';

import {type GrammarRouterParams} from './GrammarPlayerPage';
import {type Grammar} from './interface';
import {setRedirectedFromUrl} from '../action';

import './ViewGrammarInCoursebook.scss';

interface Props {
  exerciseLocation?: ExerciseLocation;
  selectedGrammar?: Grammar;
  loaded?: boolean;
}

interface TooltipProps {
  unitTitle?: string;
  unitNumber?: number | null;
  isRevision?: boolean;
  role?: Role;
}

const ViewGrammarInCoursebook: React.FC<Props> = ({exerciseLocation, selectedGrammar, loaded}) => {
  const dispatch = useDispatch();
  const params = useParams<GrammarRouterParams>();
  const location = useLocation();
  const role = useSelector<AppState, Role>(state => state.user.role!);

  const currentURL = location.pathname;

  const [canNotRedirect, setCanNotRedirect] = useState<boolean>(false);

  const exercisePath = useMemo<string | null>(() => {
    if (!exerciseLocation) {
      return null;
    }
    const {courseId, studentTeacherId} = params;
    const {coursebookInstanceId, unitInstanceId, pageNumber, preview} = exerciseLocation;
    const actionCreator = !preview ? unitInstancePagePath : unitInstancePreviewPath;
    return actionCreator(
      {courseId, studentTeacherId, coursebookInstanceId},
      unitInstanceId,
      pageNumber,
      selectedGrammar?.grammarInstanceId || selectedGrammar?.grammarId
    );
  }, [exerciseLocation, params, selectedGrammar]);

  const unitInstanceId = useMemo(() => selectedGrammar?.unitInstanceId, [selectedGrammar]);

  const unit = useSelector<AppState, CoursebookUnit | undefined>(state => {
    return state.classroom?.courseInstanceState.coursebookInstanceState.unitInstances?.find(
      instance => instance.id === unitInstanceId
    )?.unit;
  });

  const viewInCoursebook = useCallback(() => {
    if (exerciseLocation && exercisePath) {
      dispatch(setRedirectedFromUrl(currentURL, 'grammar'));
      dispatch(push(exercisePath));
    } else {
      setCanNotRedirect(true);
    }
  }, [exerciseLocation, dispatch, currentURL, exercisePath]);

  useEffect(() => {
    if (!exerciseLocation) {
      setCanNotRedirect(true);
    }
    return () => {
      setCanNotRedirect(false);
    };
  }, [exerciseLocation]);

  return !canNotRedirect || !loaded ? (
    <Control viewInCoursebook={viewInCoursebook} />
  ) : (
    <ErrorTooltip
      unitTitle={unit?.title}
      isRevision={unit?.isRevision}
      unitNumber={unit?.ordinal}
      role={role}
    >
      <Control viewInCoursebook={viewInCoursebook} />
    </ErrorTooltip>
  );
};

const Control: React.FC<{viewInCoursebook: () => void}> = ({viewInCoursebook}) => {
  const intl = useIntl();
  return (
    <Button
      className="controls-btn btn-circle"
      bsStyle="success"
      disabled={false}
      onClick={viewInCoursebook}
      title={intl.formatMessage({id: 'XPlayer.ViewExerciseInCoursebook'})}
    >
      <Icon name="virc-mnu" tag="i" />
    </Button>
  );
};

const ErrorTooltipOverlay: React.FC<TooltipProps> = ({unitTitle, unitNumber, isRevision, role}) => {
  const closedUnitDetails = useMemo(
    () => (unitTitle ? ` (${!isRevision ? unitNumber + '. ' : ''}${unitTitle})` : null),
    [isRevision, unitNumber, unitTitle]
  );

  return role === 'student' ? (
    <>
      <FormattedMessage id="XPlayer.Grammar.ViewInCoursebook.ErrorTooltip.Student" />
      {closedUnitDetails}
    </>
  ) : (
    <>
      <FormattedMessage id="XPlayer.Grammar.ViewInCoursebook.ErrorTooltip.Teacher" />
      {closedUnitDetails}
    </>
  );
};

const ErrorTooltip: React.FC<TooltipProps> = props => {
  const controlRef: React.RefObject<HTMLDivElement> = React.createRef();

  const getTooltipContainer: () => HTMLElement = useCallback(
    () => controlRef.current || document.body,
    [controlRef]
  );

  return (
    <Tooltip
      overlay={ErrorTooltipOverlay(props)}
      overlayClassName="view-in-coursebook-error"
      getTooltipContainer={getTooltipContainer}
      placement="top"
      defaultVisible={false}
      trigger={['hover']}
    >
      <span ref={controlRef}>{props.children}</span>
    </Tooltip>
  );
};

export default ViewGrammarInCoursebook;
