import momentTimezone from 'moment-timezone';

export function maybeToLocalDate(date: Date, timeZone?: string): Date {
  return timeZone ? toLocalDate(date, timeZone) : date;
}

export function toLocalDate(date: Date, timeZone: string): Date {
  const moment: momentTimezone.Moment = momentTimezone.tz(date.getTime(), timeZone);
  return new Date(
    moment.year(),
    moment.month(),
    moment.date(),
    moment.hour(),
    moment.minutes(),
    moment.seconds()
  );
}

export function toLocalDay(date: Date, timeZone: string) {
  const moment: momentTimezone.Moment = momentTimezone.tz(date.getTime(), timeZone);
  return new Date(moment.year(), moment.month(), moment.date());
}
