import {useSelector} from 'react-redux';

import {type AppState} from '../../store/interface';

// TODO: temp quick fix, remove me later
export const isSampleLicenceStudent = (s: AppState): boolean =>
  s.user.role === 'student' && s.user.id === 296716;

export function useIsSampleLicenseStudent(): boolean {
  // TODO: temp crutch, remove me later
  return useSelector(isSampleLicenceStudent);
}
