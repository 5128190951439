import React from 'react';
import {FormattedMessage, type WrappedComponentProps, injectIntl, defineMessages} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import {type AvatarUrl} from 'store/interface';
import Icon from 'components/Icon';

import Avatar from '../../components/Avatar';
import {SoundNotification, soundUrl} from '../../helpers/sound';
import {RTCMessages} from './RTCmessages';

export const messages = defineMessages({
  IncomingCallTitle: {
    id: 'Chat.IncomingCallTitle'
  }
});

interface IncomingCallProps {
  partnerName: string;
  hangUp: () => void;
  answeringAwait?: boolean;
  avatar: AvatarUrl;
  camId?: string;
  answerCall: (withVideo?: boolean) => void;
}

type Props = IncomingCallProps & WrappedComponentProps;

class IncomingCall extends React.Component<Props, {}> {
  private audioEl: HTMLMediaElement | null;

  public componentWillUnmount(): void {
    // hack to prevent user on MacOS Chrome push 'play' button on headphones or keyboard and hearing call notification after call was ended
    if (this.audioEl) {
      this.audioEl.volume = 0;
    }
  }

  public render() {
    const videoCallBtnTitle = !this.props.camId
      ? this.props.intl.formatMessage(RTCMessages.NoWebcam)
      : this.props.intl.formatMessage(RTCMessages.PartnerCallVideoAnswer);
    return (
      <div className="incoming-call">
        <audio
          src={soundUrl(SoundNotification.callIncoming)}
          autoPlay={true}
          ref={el => (this.audioEl = el)}
          loop={true}
        />
        <div className="header">
          {this.props.children}
          <FormattedMessage id="Chat.CallIncoming" />
        </div>
        <div className="avatar-and-name animation">
          <div>
            <Avatar size={72} url={this.props.avatar} />
            <span className="opponent-name">{this.props.partnerName}</span>
          </div>
          <div>
            <Button
              bsSize="large"
              className="btn-circle answer-incoming-call with-mic"
              title={this.props.intl.formatMessage(RTCMessages.PartnerCallVoiceAnswer)}
              onClick={this.answerWithoutVideo}
              disabled={this.props.answeringAwait}
            >
              <Icon name="phone" size="lg" className="answer-incoming-call with-mic" />
            </Button>
            <Button
              bsSize="large"
              className="btn-circle answer-incoming-call with-video"
              onClick={this.answerWithVideo}
              disabled={this.props.answeringAwait || !this.props.camId}
              title={videoCallBtnTitle}
            >
              <Icon name="video-camera" size="lg" className="answer-incoming-call with-video" />
            </Button>
          </div>
        </div>
        <Button
          bsStyle="danger"
          className="btn-circle hang-up-button hangup-incoming-call"
          title={this.props.intl.formatMessage(RTCMessages.PartnerRejectCall)}
          onClick={this.props.hangUp}
          disabled={this.props.answeringAwait}
        >
          <Icon name="phone" size="lg" className="hangup-incoming-call" />
        </Button>
      </div>
    );
  }

  private answerWithVideo = () => {
    this.props.answerCall(true);
  };

  private answerWithoutVideo = () => {
    this.props.answerCall(false);
  };
}

export default injectIntl(IncomingCall);
