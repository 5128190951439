import React from 'react';
import {type Editor, type RenderBlockProps} from '@englex/slate-react';
import classNames from 'classnames';
import {type Next} from '@englex/slate';

import {type RendererPlugin} from '../../interface';
import {type ListStyleType, type ListType, SlateBlock} from '../../../interface';
import {isListBlockOfType, isListStyleType} from '../../../utils';
import {
  getFurthestListBlockOfBlock,
  getFurthestSubsequentListOfType
} from '../../../SlateEditor/plugins/button/List/utils';

import './List.scss';

abstract class List implements RendererPlugin {
  public abstract list: ListType;
  public listStyleType?: ListStyleType;
  public block = SlateBlock.LIST;

  public renderBlock = (
    {node, attributes, children}: RenderBlockProps,
    editor: Editor,
    next: Next
  ) => {
    const listStyleType = this.listStyleType;
    if (!isListBlockOfType(node, this.list) || !isListStyleType(node, this.listStyleType)) {
      return next();
    }

    const {document} = editor.value;

    // get furthest subsequent list of the same type for determine relative list depth
    const furthestList = getFurthestSubsequentListOfType(
      document,
      node,
      this.list,
      this.listStyleType
    );
    const relativeDepth = furthestList ? furthestList.getDepth(node.key)! / 2 : 0;

    // get root list to determine absolute list depth
    const rootList = getFurthestListBlockOfBlock(document, node);
    const absoluteDepth = rootList ? rootList.getDepth(node.key)! / 2 : 0;

    const Wrapper = this.list;
    return (
      <Wrapper
        {...attributes}
        className={classNames(
          'list',
          `list-${this.list}`,
          `list-${this.list}-${absoluteDepth}-${relativeDepth}`,
          listStyleType
        )}
      >
        {children}
      </Wrapper>
    );
  };
}
export default List;
