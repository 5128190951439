import React, {useEffect, useState, type SyntheticEvent} from 'react';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import FormControl, {type FormControlProps} from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {useIntl} from 'react-intl';
import classNames from 'classnames';

import './ErrorModal.scss';

interface Props {
  original: string;
  translate: string;
  isOpen: boolean;
  hide: () => void;
  submit: (original: string, translation: string) => void;
  isAddFromErrorModal: boolean;
  setIsAddFromErrorModal: React.Dispatch<React.SetStateAction<boolean>>;
  notChangedTranslation: [string, string] | undefined;
  setNotChangedTranslation: React.Dispatch<React.SetStateAction<[string, string]>>;
}

export const ENTRY_MAX_LENGTH = 255;

export const ErrorModal: React.FC<Props> = ({
  isOpen,
  hide,
  submit,
  original,
  translate,
  isAddFromErrorModal,
  setIsAddFromErrorModal,
  notChangedTranslation,
  setNotChangedTranslation
}) => {
  const {formatMessage} = useIntl();

  const [newOriginal, setNewOriginal] = useState(original);
  const [newTranslate, setNewTranslate] = useState(translate);

  useEffect(() => {
    if (isOpen) {
      setNewOriginal(original);
      setNewTranslate(translate);
    }
  }, [isOpen, original, translate]);

  const originalLongerThanMaxValue = newOriginal.trim().length > ENTRY_MAX_LENGTH;

  const translateLongerThanMaxValue = newTranslate.trim().length > ENTRY_MAX_LENGTH;

  const originalIsEmpty = newOriginal.trim().length === 0;

  const translateIsEmpty = newTranslate.trim().length === 0;

  const originalIsCyrillic = /[А-ЯЁ,а-яё]/i.test(newOriginal);

  const disabledOriginal = originalLongerThanMaxValue || originalIsEmpty || originalIsCyrillic;

  const disabledTranslate = translateLongerThanMaxValue || translateIsEmpty;

  const disabled = disabledOriginal || disabledTranslate;

  const onChangeOriginal = (e: SyntheticEvent<FormControlProps>) => {
    const {value} = e.currentTarget;
    if (typeof value === 'string') setNewOriginal(value || '');
  };

  const onChangeTranslate = (e: SyntheticEvent<FormControlProps>) => {
    const {value} = e.currentTarget;
    if (typeof value === 'string') setNewTranslate(value || '');
  };

  const onsubmit = () => {
    setIsAddFromErrorModal(true);
    setNotChangedTranslation([original, translate]);
    submit(newOriginal, newTranslate);
    hide();
  };

  return (
    <Modal backdrop="static" className="dictionary-error-modal" show={isOpen} onHide={hide}>
      <Modal.Header className="modal-header">
        <span>{formatMessage({id: 'Dictionary.AddToDictionary'})}</span>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <FormGroup
          controlId="original"
          validationState={disabledOriginal ? 'error' : undefined}
          className="group"
        >
          <ControlLabel className="label">
            {formatMessage({id: 'Dictionary.WordOrPhrase'})}
            <span
              className={classNames('counter', {
                'has-error': originalLongerThanMaxValue || originalIsEmpty
              })}
            >{`${newOriginal.trim().length} / ${ENTRY_MAX_LENGTH}`}</span>
          </ControlLabel>
          <FormControl
            componentClass="textarea"
            placeholder={formatMessage({id: 'Dictionary.WordOrPhrase'})}
            className="textarea"
            value={newOriginal}
            onChange={onChangeOriginal}
          />
        </FormGroup>

        <FormGroup
          controlId="translate"
          validationState={disabledTranslate ? 'error' : undefined}
          className="group"
        >
          <ControlLabel className="label">
            {formatMessage({id: 'Dictionary.Translation'})}
            <span className={classNames('counter', {'has-error': disabledTranslate})}>{`${
              newTranslate.trim().length
            } / ${ENTRY_MAX_LENGTH}`}</span>
          </ControlLabel>
          <FormControl
            componentClass="textarea"
            className="textarea"
            placeholder={formatMessage({id: 'Dictionary.Translation'})}
            value={newTranslate}
            onChange={onChangeTranslate}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer className={classNames('model-footer', {'without-error': !disabled})}>
        <div className="errors">
          {(originalLongerThanMaxValue || translateLongerThanMaxValue) && (
            <div className="text">
              {formatMessage({id: 'Dictionary.NumberOfCharactersExceedsLimit'})}
            </div>
          )}
          {originalIsEmpty && (
            <span className="text">
              {formatMessage({id: 'Dictionary.Entry.Validation.BlankOriginal'})}
            </span>
          )}
          {translateIsEmpty && (
            <span className="text">
              {formatMessage({id: 'Dictionary.Entry.Validation.BlankTranslation'})}
            </span>
          )}
          {originalIsCyrillic && (
            <span className="text">{formatMessage({id: 'Dictionary.CyrillicForbidden'})}</span>
          )}
        </div>

        <div className="controls">
          <Button bsSize="small" onClick={hide}>
            {formatMessage({id: 'Slate.Modal.Image.CancelButton'})}
          </Button>
          <Button
            bsSize="small"
            bsStyle="primary"
            onClick={onsubmit}
            disabled={disabledOriginal || disabledTranslate}
          >
            {formatMessage({id: 'Common.Create'})}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
