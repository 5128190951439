import React from 'react';
import {type Block, type Inline, type Next, type Node} from '@englex/slate';
import {type Editor, type RenderBlockProps, type RenderInlineProps} from '@englex/slate-react';

import {SlateBlock, type SlateInline, type StyledBlock, type StyledInline} from '../../interface';
import {isBlock, isInline} from '../../utils';
import {type RendererPlugin} from '../interface';

function isAllowedStyledBlock(
  node: Node,
  allowed: SlateBlock[] = [SlateBlock.DEFAULT]
): node is StyledBlock {
  return (
    isBlock(node) &&
    allowed.indexOf(node.type as SlateBlock) !== -1 &&
    (node as StyledBlock).data.has('style')
  );
}

function isAllowedStyledInline(node: Node, allowed: SlateInline[]): node is StyledInline {
  return (
    isInline(node) &&
    allowed.indexOf(node.type as SlateInline) !== -1 &&
    (node as StyledInline).data.has('style')
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
abstract class StyledNode<T extends SlateInline | SlateBlock, N extends Inline | Block>
  implements RendererPlugin
{
  public abstract wrapper: React.ElementType | string;
  protected renderTypes: T[] = [SlateBlock.DEFAULT] as T[];

  public renderBlock = (props: RenderBlockProps, editor: Editor, next: Next) => {
    const {node} = props;
    if (!isAllowedStyledBlock(node, this.renderTypes as SlateBlock[])) {
      return next();
    }

    return this.renderStyledNode(props);
  };

  public renderInline = (props: RenderInlineProps, editor: Editor, next: Next) => {
    const {node} = props;
    if (!isAllowedStyledInline(node, this.renderTypes as SlateInline[])) {
      return next();
    }

    return this.renderStyledNode(props);
  };

  public renderStyledNode = (props: RenderBlockProps | RenderInlineProps) => {
    const {attributes, children, node} = props;
    const style = node.data.get('style');
    const Wrapper = this.wrapper;
    return (
      <Wrapper {...attributes} style={style}>
        {children}
      </Wrapper>
    );
  };
}

export default StyledNode;
