import {type Map, type List} from 'immutable';
import {type Editor} from '@englex/slate';

import {type WidgetJSON} from 'store/exercise/player/interface';
import {
  type Answer,
  type PictureChoiceQuestionJSON,
  type PictureChoiceQuestionProperties
} from 'store/exercise/player/widgets/PictureChoice/interface';

import {type XWidgetProperties} from '../interface';
import {type CardSizeType} from '../XWordPictureSet/XPictureSet/interface';

export type PictureChoiceAnswers = Map<string, List<string>>;

export type PictureChoiceValues = Map<string, List<string>>;

export enum PictureChoiceMode {
  MULTIPLE_CHOICE = 'MultipleChoice',
  FREE_CHOICE = 'FreeChoice'
}

export enum PictureChoiceAnswerType {
  One = 'One',
  Multiple = 'Multiple'
}

export enum PictureChoiceQuestionsMode {
  Default = 'Default',
  WithoutQuestionsTitle = 'WithoutQuestionsTitle'
}

export interface XPictureChoiceWizardProps extends WidgetJSON {
  defaultAnswersNumber: number;
  initialQuestionsNumber: number;
  mode: PictureChoiceMode;
  answerType: PictureChoiceAnswerType;
  questionsMode: PictureChoiceQuestionsMode;
  answers: Answer;
}

export interface XPictureChoiceProperties extends XWidgetProperties {
  readonly mode: PictureChoiceMode;
  readonly answerType: PictureChoiceAnswerType;
  readonly questionsMode: PictureChoiceQuestionsMode;
  readonly questions: List<PictureChoiceQuestionProperties>;
  readonly answers: PictureChoiceAnswers;
  deleteQuestion(questionId: string): this;
  moveQuestion(from: number, to: number): this;
  addEmptyQuestion(): this;
  changeQuestionContent(questionIndex: number, change: Editor): this;
  addEmptyCard(questionIndex: number): this;
  setCardImage(questionIndex: number, cardIndex: number, imageId: number): this;
  setCardSizeType(questionIndex: number, cardIndex: number, cardSizeType: CardSizeType): this;
  deleteCard(questionId: string, cardId: string): this;
  toggleAnswer(questionId: string, cardId: string): this;
  moveCard(questionId: string, moveCardIndex: number, targetCardIndex: number): this;
  rollBackCards(questions: List<PictureChoiceQuestionProperties>): this;
}

export interface XPictureChoiceOptions {
  readonly defaultAnswersNumber: number;
}

export interface XPictureChoiceProps extends WidgetJSON {
  options: Partial<XPictureChoiceOptions>;
  mode: PictureChoiceMode;
  answerType: PictureChoiceAnswerType;
  questionsMode?: PictureChoiceQuestionsMode;
  questions: PictureChoiceQuestionJSON[] | List<PictureChoiceQuestionProperties>;
  answers: Answer;
  values?: Answer;
}
