import {useCallback, useState} from 'react';
import {useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

export function useLocalState() {
  const layoutCollapsed = useSelector<AppState, boolean>(state => state.layout.collapsed);

  const [collapsed, setCollapsed] = useState(false);
  const [sidebarPulledOut, setSidebarPulledOut] = useState(false);

  const sidebarCollapsed = layoutCollapsed || collapsed;

  const pullSidebarCb = useCallback(() => setSidebarPulledOut(false), []);

  const toggleCollapseCb = useCallback(() => setCollapsed(prevState => !prevState), []);

  const clickHandler = useCallback(() => setSidebarPulledOut(prevState => !prevState), []);

  return {
    sidebarCollapsed,
    layoutCollapsed,
    pullSidebarCb,
    sidebarPulledOut,
    toggleCollapseCb,
    clickHandler,
    collapsed
  };
}
