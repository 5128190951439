import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {FormattedMessage} from 'react-intl';

interface Props {
  show: boolean;
  renewSession: () => void;
}

interface State {
  renewDisabled: boolean;
}

export default class SessionExpiredModal extends React.Component<Props, State> {
  public state: State = {
    renewDisabled: false
  };

  public render() {
    return (
      <Modal
        backdrop="static"
        className="error-modal modal-center"
        show={this.props.show}
        onHide={this.onHide}
      >
        <Modal.Body>
          <div className="description text text-center">
            <FormattedMessage id="SessionExpired.Description" />
          </div>
          <Button
            bsStyle="primary"
            bsSize="sm"
            onClick={this.renewSession}
            disabled={this.state.renewDisabled}
            className="connect"
          >
            <FormattedMessage id="SessionExpired.Button" />
          </Button>
        </Modal.Body>
      </Modal>
    );
  }

  private onHide = () => null;

  private renewSession = () => {
    this.setState({renewDisabled: true}, () => this.props.renewSession());
  };
}
