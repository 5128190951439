import React, {useCallback} from 'react';
import Button from 'react-bootstrap/lib/Button';
import Modal from 'react-bootstrap/lib/Modal';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {type Action, type ActionCreator} from 'redux';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import * as toastr from 'components/toastr';

import {type ToggleElementCreator} from '../../../../common/interface';
interface Props {
  micAccess?: boolean;
  toggleModal: ToggleElementCreator;
  saveModalSelection: ActionCreator<Action>;
  modalSelectedMic?: string;
  audioDevices?: MediaDeviceInfo[];
  noMicsAvailable?: boolean;
  callInProgress?: boolean;
  appVersion: string;
}

const messages = defineMessages({
  SettingsSaved1: {
    id: 'MediaDevicesWizard.SettingsSaved1'
  },
  SettingsSaved2: {
    id: 'MediaDevicesWizard.SettingsSaved2'
  }
});

const SelectDevicesFooter: React.FC<Props> = ({
  appVersion,
  modalSelectedMic,
  audioDevices,
  noMicsAvailable,
  micAccess,
  toggleModal,
  callInProgress,
  saveModalSelection
}) => {
  const {formatMessage} = useIntl();

  const matches = useMediaQuery(480);

  const closeModal = useCallback(() => toggleModal(false), [toggleModal]);

  const saveSelection = useCallback(() => {
    const successMessage = `${formatMessage(messages.SettingsSaved1)} ${
      callInProgress ? '' : formatMessage(messages.SettingsSaved2)
    }`;
    toastr.success('', successMessage);
    saveModalSelection();
    toggleModal(false);
  }, [callInProgress, formatMessage, saveModalSelection, toggleModal]);

  if (micAccess === false) {
    return (
      <Modal.Footer>
        <div className="buttons">
          <Button bsStyle="primary" bsSize="sm" onClick={closeModal}>
            <FormattedMessage id="MediaDevicesWizard.ButtonClose" />
          </Button>
        </div>
      </Modal.Footer>
    );
  }

  const disableSend =
    !modalSelectedMic ||
    !audioDevices ||
    !audioDevices.find(d => d.deviceId === modalSelectedMic) ||
    noMicsAvailable;

  return (
    <Modal.Footer>
      <div className="modal-footer-text">
        {matches ? (
          appVersion
        ) : (
          <FormattedMessage id="Common.AppNameAndVersion" values={{version: appVersion}} />
        )}
      </div>
      <div className="buttons">
        <Button bsStyle="default" className="btn-transparent" bsSize="sm" onClick={closeModal}>
          <FormattedMessage id="Common.Cancel" />
        </Button>
        <Button bsStyle="primary" bsSize="sm" disabled={disableSend} onClick={saveSelection}>
          <FormattedMessage id="MediaDevicesWizard.ButtonSave" />
        </Button>
      </div>
    </Modal.Footer>
  );
};

export default SelectDevicesFooter;
