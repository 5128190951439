import {type Block, type Value, type Document} from '@englex/slate';
import {Set} from 'immutable';

import {SlateBlock} from 'components/Slate/interface';
import {isBlockOfType, isIconInline} from 'components/Slate/utils';

export const valueToString = (content: Value) =>
  content.document
    .filterDescendants(node => isBlockOfType(node, SlateBlock.LIST_ITEM))
    .map((b: Block, i: number) => `${i + 1}. ${b.text}`)
    .join(' ');

export const checkingForDuplicates = (document: Document): boolean => {
  const texts = document
    .filterDescendants(block => isBlockOfType(block, SlateBlock.LIST_ITEM))
    .map(item => item?.text);

  const setTexts = Set(texts);

  return texts.size === setTexts.size;
};

export const isList = (content: Value) =>
  !!content.document.filterDescendants(block => isBlockOfType(block, SlateBlock.LIST)).size;

export const getAnswerBlocks = (document: Document) => {
  return document.filterDescendants(node => isBlockOfType(node, SlateBlock.LIST_ITEM));
};

export const labelsNotEmpty = (document: Document): boolean =>
  document
    .filterDescendants(block => isBlockOfType(block, SlateBlock.LIST_ITEM))
    .every(questionBlock => {
      const labelBlock = (questionBlock as Block).nodes.first() as Block;
      return (
        !!labelBlock.text.trim().length ||
        !!labelBlock.getInlines().find(node => isIconInline(node!))
      );
    });
