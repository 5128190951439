import {type ParsedImage, type ParsedMedia} from 'store/interface';
import {ParsedMediaType} from 'common/enums';

export const checkIfParsedImage = (media: ParsedMedia): media is ParsedImage => {
  return media.type === ParsedMediaType.image;
};

export const shufflePhrases = (array: string[]): string[] => {
  const newArray = array.slice();

  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }

  return newArray;
};
