import * as yup from 'yup';
import {Value} from '@englex/slate';
import {type IntlShape} from 'react-intl';

import {questsInitialValue} from 'components/Slate/utils';
import {type WidgetToJSONOptions} from 'store/exercise/player/interface';
import {type MatchingJSON} from 'store/exercise/player/widgets/Matching/interface';
import {exerciseExcerptLength} from 'config/static';

import {type XMatchingProperties} from './interface';
import XMatchingRecord from './XMatchingRecord';
import validationMessages from '../i18n';
import {checkingForDuplicates, isList, labelsNotEmpty, valueToString} from './utils';

class XMatchingNoCategoriesRecord extends XMatchingRecord implements XMatchingProperties {
  public declare readonly extraContent: Value;

  constructor(raw: MatchingJSON) {
    super(raw);
    const contentValue = Value.fromJSON(raw.content);
    this.initValues({
      content: isList(contentValue)
        ? contentValue
        : Value.fromJSON(questsInitialValue('Sample answer', 6))
    });
  }

  public toJSON(options: WidgetToJSONOptions): MatchingJSON {
    const {answers, choices, ...data} = super.toJSON(options);

    return {
      ...data,
      content: this.content.toJS()
    };
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => labelsNotEmpty(v.document)
        )
        .test(
          'No duplicate answers',
          intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Unique'}),
          (v: Value) => checkingForDuplicates(v.document)
        )
    });
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }

    const answers = valueToString(this.content);

    return `${task} ${answers}`.trim();
  }
}

export default XMatchingNoCategoriesRecord;
