import {useCallback} from 'react';

import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import {determineDirectionOfTranslation} from 'routes/Dictionary/utils';
import {DictionarySearchMode} from 'common/enums';

import {requestSearchInODEn} from '../actions';
import {type Articles, type SearchData} from '../interfaces';
import {groupLexicalEntries} from '../utils/groupLexicalEntries';

interface Props {
  scrambledArticles: Articles;
  currentScrambledWord?: string;
  setScrambledArticles: React.Dispatch<React.SetStateAction<Articles>>;
  setCurrentScrambledWord: (str?: string) => void;
}

export const useScrambledWordEnApiRequest = ({
  scrambledArticles,
  currentScrambledWord,
  setScrambledArticles,
  setCurrentScrambledWord
}: Props) => {
  const scrambledWordSuccessHandler = (data: SearchData) => {
    setScrambledArticles(scrambledArticles => {
      const groupedData = groupLexicalEntries(data, DictionarySearchMode.EN);
      return {
        [currentScrambledWord as string]: groupedData ? groupedData : null,
        ...scrambledArticles
      };
    });
  };

  const scrambledWordErrorHandler = () => {
    if (currentScrambledWord) {
      setScrambledArticles(scrambledArticles => ({
        [currentScrambledWord.toLowerCase()]: null,
        ...scrambledArticles
      }));
    }
  };

  const {isLoading: isScrambledWordLoadingEn, apiRequest: apiRequestScrambledWord} = useApiRequest2<
    [string]
  >(requestSearchInODEn, scrambledWordSuccessHandler, scrambledWordErrorHandler);

  const sendScrambledWordApiRequestEn = useCallback(
    (word: string) => {
      if (!Object.keys(scrambledArticles).includes(word)) {
        const translationDirection = determineDirectionOfTranslation(word);
        if (translationDirection) {
          apiRequestScrambledWord(word);
          setCurrentScrambledWord(word);
        }
      } else {
        setCurrentScrambledWord(word);
      }
    },
    [apiRequestScrambledWord, scrambledArticles, setCurrentScrambledWord]
  );

  return {
    scrambledArticles,
    currentScrambledWord,
    isScrambledWordLoadingEn,
    setScrambledArticles,
    setCurrentScrambledWord,
    sendScrambledWordApiRequestEn
  };
};
