import React, {Component} from 'react';
import {type CompleteCrop} from 'react-image-crop';

import {MODAL_HEIGHT, MODAL_WIDTH} from '../static';

interface Props {
  originalHeight: number;
  imageDataUrl: string;
  cropResult: CompleteCrop;
  originalWidth: number;
  containerWidth?: number;
  containerHeight?: number;
}

// This component is tough to understand. Unfortunately I couldn't make it more clear,
// so I'll try my best to explain in comments
export default class CropResult extends Component<Props> {
  public render() {
    const {x, y, height, width} = this.props.cropResult;
    const {
      originalHeight,
      originalWidth,
      containerWidth = MODAL_WIDTH,
      containerHeight = MODAL_HEIGHT
    } = this.props;

    // Crop dimensions may be larger than modal ones if original image is large enough.
    // This leads to necessity in shrinking LARGEST crop dimension to corresponding modal one.
    // Thus we need to pick SMALLER coefficient gotten from dividing modal dimension by
    // corresponding crop one.
    const [heightRatio, widthRatio] = [containerHeight / height, containerWidth / width];
    let ratio = heightRatio > widthRatio ? widthRatio : heightRatio;
    ratio = ratio > 1 ? 1 : ratio;

    // Image is actually rendered from imageDataUrl - so it is full image, not cropped piece of one.
    // image(Height/Width) are dimensions of full squeezed image.
    const [imageHeight, imageWidth] = [originalHeight * ratio, originalWidth * ratio];

    // Wrapper div has dimensions of squeezed to max appropriate crop dimension.
    const [wrapperHeight, wrapperWidth] = [height * ratio, width * ratio];

    // Negative margins for image and hidden overflow for wrapper allow to show crop as it is.
    const [marginTop, marginLeft] = [-y * ratio, -x * ratio];
    const [marginRight, marginBottom] = [
      imageWidth + marginLeft - wrapperWidth,
      imageHeight + marginTop - wrapperHeight
    ];

    return (
      <div className="dnd-image">
        <div style={{height: wrapperHeight, width: wrapperWidth, overflow: 'hidden'}}>
          <img
            height={imageHeight}
            src={this.props.imageDataUrl}
            style={{marginTop, marginRight, marginBottom, marginLeft}}
            width={imageWidth}
            alt=""
          />
        </div>
      </div>
    );
  }
}
