import {type Action} from 'redux';
import {type ThunkAction} from 'redux-thunk';

import {type AppState} from 'store/interface';

import {toggleVideoUndocked} from '../../webRTC/action/action';

const PREFIX = 'FLOAT_SIDEBAR/';
export const PULL_SIDEBAR = PREFIX + 'PULL_SIDEBAR';
export const TOGGLE_COLLAPSE = PREFIX + 'TOGGLE_COLLAPSE';

export interface PullSidebarAction extends Action {
  pulledOut: boolean;
}

export const pullSidebar = (pulledOut: boolean): PullSidebarAction => ({
  type: PULL_SIDEBAR,
  pulledOut
});

export interface ToggleCollapseAction extends Action {
  collapse: boolean;
}

export const toggleCollapse =
  (collapse: boolean): ThunkAction<void, AppState, void, Action> =>
  (dispatch, getState) => {
    const action: ToggleCollapseAction = {
      type: TOGGLE_COLLAPSE,
      collapse
    };
    dispatch(action);
    const {
      rtc: {call, badBrowser},
      video: {undocked}
    } = getState();
    if (!call || badBrowser) {
      return;
    }
    if (collapse && !undocked) {
      dispatch(toggleVideoUndocked(true));
    }
    if (!collapse && undocked) {
      dispatch(toggleVideoUndocked(false));
    }
  };
