import React, {type FC, useCallback, useEffect, useState} from 'react';
import {type Action} from 'redux';
import {useDispatch} from 'react-redux';
import {type Dispatch} from 'redux-axios-middleware';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';

import {type AxiosResponseAction} from 'services/axios/interface';
import {type AppState, type CoursebookUnit, type ImageV2} from 'store/interface';
import {type IntroRecord} from 'store/intro/IntroRecord';
import {useImageCacheContext} from 'components/XPlayer/contexts/imageCacheContext';

import {requestImage} from '../LoadableImage/LoadableImage';
import SlatePlayer from '../Slate/SlatePlayer/SlatePlayer';
import Bold from '../Slate/plugins/renderers/Bold';
import Italic from '../Slate/plugins/renderers/Italic';
import Underline from '../Slate/plugins/renderers/Underline';
import StrikeThrough from '../Slate/plugins/renderers/StrikeThrough';
import FontSize from '../Slate/plugins/renderers/FontSize';
import Color from '../Slate/plugins/renderers/Color';
import Highlight from '../Slate/plugins/renderers/Highlight';
import TextAlignment from '../Slate/plugins/renderers/TextAlign/TextAlignment';
import Link from '../Slate/plugins/renderers/Link/Link';
import Lists from '../Slate/plugins/renderers/List/Lists';
import {default as IconPlugin} from '../Slate/plugins/renderers/Icon';
import Loader from '../Loader';
import Icon from '../../components/Icon';

import './XIntroPreview.scss';

const Plugins = [
  new Bold(),
  new Italic(),
  new Underline(),
  new StrikeThrough(),
  new FontSize(),
  new Color(),
  new Highlight(),
  new TextAlignment(),
  new Link(),
  new Lists(),
  new IconPlugin()
];

interface Props {
  ordinal?: number | null;
  unit: CoursebookUnit;
  intro: IntroRecord;
  onStart?: () => void;
  onView?: () => void;
}

interface ResponseData {
  urls: string[];
}

type ImageResponseAction = AxiosResponseAction<ResponseData>;

export const XIntroPreview: FC<Props> = React.memo(({unit, intro, ordinal, onStart, onView}) => {
  const {formatMessage} = useIntl();
  const dispatch: Dispatch<Action, AppState> = useDispatch();
  const {imageCache, addToImageCache} = useImageCacheContext();

  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState('');

  const loadImage = useCallback(
    (imageId: number) => {
      dispatch(requestImage(imageId))
        .then(({payload}: ImageResponseAction) => {
          setUrl(`url("${payload.data.urls[0]}")`);
          addToImageCache(imageId, payload.data as ImageV2);
        })
        .finally(() => setLoading(false));
    },
    [addToImageCache, dispatch]
  );

  const getImageFromCache = useCallback(
    (imageId: number) => {
      setUrl(`url("${imageCache[imageId].urls[0]}")`);
      setLoading(false);
    },
    [imageCache]
  );

  useEffect(() => {
    if (!intro.image) return setLoading(false);
    const imageId = intro.image;

    imageCache[imageId] ? getImageFromCache(imageId) : loadImage(imageId);
  }, [addToImageCache, dispatch, getImageFromCache, imageCache, intro, loadImage]);

  return (
    <Scrollbars>
      <div className="intro-preview-container">
        <div className="intro-preview">
          <div className="intro-preview__inner">
            {loading ? (
              <div className="intro-preview__loader">
                <Loader />
              </div>
            ) : (
              <>
                <div className="intro-preview__header">
                  {unit.isRevision ? (
                    unit.title
                  ) : (
                    <>
                      <span className="ordinal">{ordinal || unit.ordinal}</span>
                      <span className="title">{unit.title}</span>
                    </>
                  )}
                </div>
                <div className="intro-preview__content" style={{backgroundImage: url}}>
                  <div className="content-wrapper">
                    <div className="content-wrapper__inner">
                      <SlatePlayer
                        value={intro.content}
                        plugins={Plugins}
                        trimEmptyTrailingParagraphs={true}
                      />
                      <div className="controls">
                        <Button
                          bsSize="sm"
                          bsStyle="success"
                          onClick={onStart}
                          className="start-button"
                          title={formatMessage({id: 'XIntro.Preview.Start'})}
                        >
                          <Icon name="play" />
                          <FormattedMessage id="XIntro.Preview.Start" />
                        </Button>

                        <Button
                          bsSize="sm"
                          bsStyle="link"
                          onClick={onView}
                          className="view-button"
                          title={formatMessage({id: 'XIntro.Preview.ViewContent'})}
                        >
                          <FormattedMessage id="XIntro.Preview.ViewContent" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </Scrollbars>
  );
});
