import replaceableMiddleware from 'redux-replaceable-middleware';
import {type ModuleNamespace} from 'vite/types/hot';

import webRTCMiddleware, {type RTCMiddleware} from '../../webRTC/middleware';
import {type ReplaceableMiddleware} from '../../../types/redux-replaceable-middleware/namespace';
import {RTCClient} from '../../webRTC/rtcclient';
import 'webrtc-adapter';

const client = new RTCClient();

const RTCReplacebleMiddleware: ReplaceableMiddleware = replaceableMiddleware(
  webRTCMiddleware(client)
);

if (import.meta.hot) {
  import.meta.hot.accept('../../webRTC/middleware', (module: ModuleNamespace) => {
    const newMiddleware: RTCMiddleware = module.default;
    RTCReplacebleMiddleware.replaceBy(newMiddleware(client));
  });
}

export default RTCReplacebleMiddleware;
