import {type CommentJSON, CommentType} from './interface';

export function getCommentType(raw: Partial<CommentJSON>) {
  if (raw.isSuggestedAnswer) {
    return CommentType.SUGGESTED_ANSWERS;
  }
  return raw.commentType || CommentType.TEACHING_NOTES;
}

interface CommentDataByType {
  iconName: string;
  title: string;
}

export const commentDataByType: {[key: string]: CommentDataByType} = {
  [CommentType.SUGGESTED_ANSWERS]: {
    iconName: 'check-square-o',
    title: 'Suggested Answers'
  },
  [CommentType.TEACHING_NOTES]: {
    iconName: 'hand-o-right',
    title: 'Teaching Notes'
  },
  [CommentType.TEACHING_TIPS]: {
    iconName: 'lightbulb-o',
    title: 'Teaching Tips'
  }
};

export const commentDataByTypePlayer: {[key: string]: CommentDataByType} = {
  ...commentDataByType,
  [CommentType.TEACHING_TIPS]: {
    iconName: 'lightbulb-o',
    title: 'Teaching Tip'
  }
};

export const commentIconSet = {
  'hand-o-right': '\uEB32',
  'check-square-o': '\uEA80',
  'lightbulb-o': '\uEB73'
};
