import {type Action, type Middleware} from 'redux';
import {type ThunkAction} from 'redux-thunk';
import {REHYDRATE} from 'redux-persist/es/constants';
import {type RehydrateAction} from 'redux-persist/es/types';

import {REQUEST_UNIT_INSTANCE_PAGE_SUCCESS, xplayerPrefix} from 'store/exercise/player/actionTypes';
import {getWidgetValues} from 'store/exercise/player/player.util';
import {type WidgetAction, type XPlayerState} from 'store/exercise/player/interface';
import {
  type Dispatch,
  type WampCallAction,
  type WampCallResponseAction,
  type WampErrorAction
} from 'services/wamp/actions/interface';
import {NOT_AUTHORIZED} from 'services/wamp/errorUri';

import {
  type CompletedExerciseEventAction,
  type CompletedExerciseWidgetValuesJSON,
  type DeleteProcessingWidgetValues,
  type PersistUnprocessedWidgetValuesAction,
  type PersistWidgetValues,
  type PersistWidgetValuesAction,
  type ProcessWidgetValuesAction,
  type RehydrateUserWidgetValuesAction,
  type RehydrateWidgetValuesAction,
  type SetUserIdAction,
  type WidgetInstanceValues,
  type WidgetInstanceValuesMap,
  type WidgetValuesVersionChanged,
  type WidgetValuesVersionChangedAction
} from '../exercise/player/persistence/interface';
import {
  DELETE_PROCESSING_WIDGET_VALUES,
  EXERCISE_ALREADY_COMPLETED,
  PERSIST_WIDGET_VALUES,
  PERSIST_WIDGET_VALUES_UNPROCESSED,
  PROCESS_WIDGET_VALUES,
  REHYDRATE_USER_WIDGET_VALUES,
  REHYDRATE_WIDGET_VALUES,
  SEND_WIDGET_VALUES,
  SET_USER_ID,
  WIDGET_VALUES_VERSION_UPDATED
} from '../exercise/player/persistence/actionTypes';
import {type AppState} from '../interface';
import {hideSavingBadge, showSavingBadge} from '../exercise/player/actions';
import {PersistState} from '../persist';
import {LOAD_HOMEWORK_EXERCISE_SUCCESS} from '../../routes/ClassRoom/pages/HomeworkPlayerPage/actionTypes';

interface XPlayerMiddlewareOptions {
  syncTimeout: number;
}

type ExerciseAlreadyCompletedErrorArgs = [CompletedExerciseWidgetValuesJSON, string];

const widgetActionPrefix = xplayerPrefix;

const persistWidgetValues = (widgetValues: PersistWidgetValues): PersistWidgetValuesAction => ({
  type: PERSIST_WIDGET_VALUES,
  widgetValues
});

const rehydrateWidgetValues = (
  exerciseId: string,
  widgetId: number,
  values: unknown,
  version: number | null
): RehydrateWidgetValuesAction => ({
  type: REHYDRATE_WIDGET_VALUES,
  exerciseId,
  widgetId,
  values,
  version
});

const widgetValuesVersionChanged = (
  exerciseId: string,
  widgetId: number,
  values: unknown,
  version: number
): WidgetValuesVersionChangedAction => ({
  type: WIDGET_VALUES_VERSION_UPDATED,
  exerciseId,
  widgetId,
  values,
  version
});

const processWidgetValues = (
  widgetKey: string,
  widgetValues: WidgetInstanceValues
): ProcessWidgetValuesAction => ({
  type: PROCESS_WIDGET_VALUES,
  widgetKey,
  widgetValues
});

const persistWidgetValuesUnprocessed = (
  widgetKey: string,
  widgetValues: WidgetInstanceValues
): PersistUnprocessedWidgetValuesAction => ({
  type: PERSIST_WIDGET_VALUES_UNPROCESSED,
  widgetKey,
  widgetValues
});

const sendWidgetValues = (
  widgetKey: string,
  widgetValues: WidgetInstanceValues
): WampCallAction<[unknown], {}> => {
  const [exerciseId, widgetId] = widgetKey.split(':');
  // ensure version is number
  if (widgetValues.version === undefined || widgetValues.version === null) {
    widgetValues.version = 0;
  }
  return {
    type: SEND_WIDGET_VALUES,
    wamp: {
      method: 'call',
      uri: `xplayer:exercise._${exerciseId}.widget._${widgetId}.values`,
      args: [widgetValues],
      rejectOnError: true
    }
  };
};

const rehydrateWidgetInstances =
  (widgetInstanceValues: WidgetInstanceValuesMap): ThunkAction<void, AppState, never, Action> =>
  dispatch => {
    widgetInstanceValues.forEach((widgetValues: WidgetInstanceValues, widgetKey: string) => {
      const [exerciseId, widgetId] = widgetKey.split(':');
      if (widgetValues) {
        dispatch(
          rehydrateWidgetValues(
            exerciseId,
            Number(widgetId),
            widgetValues.values,
            widgetValues.version
          )
        );
      }
    });
  };

const setXplayerUserId = (userId: number): SetUserIdAction => ({
  type: SET_USER_ID,
  userId
});

const rehydrateUserWidgetInstances = (
  userId: number,
  widgetValues: WidgetInstanceValuesMap
): RehydrateUserWidgetValuesAction => ({
  type: REHYDRATE_USER_WIDGET_VALUES,
  userId,
  widgetValues
});

const deleteProcessingWidgetValues = (widgetKey: string): DeleteProcessingWidgetValues => {
  return {
    type: DELETE_PROCESSING_WIDGET_VALUES,
    widgetKey
  };
};

const exerciseAlreadyCompleted = (
  exerciseId: string,
  completedWidgets: CompletedExerciseWidgetValuesJSON,
  completedAt: string
): CompletedExerciseEventAction => ({
  type: EXERCISE_ALREADY_COMPLETED,
  exerciseId,
  completedWidgets,
  completedAt: new Date(completedAt)
});

const xplayerMiddleware = ({
  syncTimeout
}: XPlayerMiddlewareOptions): Middleware<
  {},
  AppState,
  Dispatch<Action, WampCallResponseAction<[unknown], {}, never>>
> => {
  let syncTimeoutId: NodeJS.Timeout | null = null;

  const sendWidgetValuesThrottled =
    (
      widgetKey: string,
      widgetValues: WidgetInstanceValues
    ): ThunkAction<
      void,
      AppState,
      never,
      WampCallAction<[unknown], {}> | ProcessWidgetValuesAction
    > =>
    (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        setTimeout(async () => {
          const values = getState().xplayer!.sync.widgetValues.get(widgetKey, widgetValues);
          dispatch(processWidgetValues(widgetKey, values));
          try {
            resolve(await dispatch(sendWidgetValues(widgetKey, values)));
          } catch (e) {
            reject(e);
          }
        }, widgetValues.throttle);
      });
    };

  const syncWidgetInstances: ThunkAction<void, AppState, never, Action> = (
    dispatch: Dispatch<Action, WampCallResponseAction<[unknown], {}, never>>,
    getState: () => AppState
  ) => {
    if (syncTimeoutId !== null) {
      return;
    }
    const {xplayer} = getState();

    if (!xplayer) {
      return;
    }

    const {widgetValues, processingWidgetValues} = xplayer.sync;
    const mergeValues = processingWidgetValues.merge(widgetValues);
    if (!mergeValues.size) {
      const showSavingBadge = xplayer.layout && xplayer.layout.showSavingBadge;
      if (showSavingBadge) {
        dispatch(hideSavingBadge());
      }
      return;
    }

    widgetValues
      .filterNot((_: WidgetInstanceValues, widgetKey: string) =>
        processingWidgetValues.has(widgetKey)
      )
      .forEach((widgetValues: WidgetInstanceValues, widgetKey: string) => {
        dispatch(syncWidgetInstance(widgetKey, widgetValues));
      });
  };

  const timeoutSyncWidgetInstances: ThunkAction<void, AppState, never, Action> = dispatch => {
    syncTimeoutId = setTimeout(() => {
      if (syncTimeoutId) {
        clearTimeout(syncTimeoutId);
        syncTimeoutId = null;
      }
      dispatch(syncWidgetInstances);
    }, syncTimeout);
  };

  const sendWidgetValuesError =
    (widgetKey: string): ThunkAction<void, AppState, never, Action> =>
    (dispatch, getState) => {
      dispatch(timeoutSyncWidgetInstances);
      const {xplayer} = getState();
      if (xplayer) {
        const {processingWidgetValues} = xplayer.sync;
        const values = processingWidgetValues.get(widgetKey);
        if (values) {
          dispatch(persistWidgetValuesUnprocessed(widgetKey, values));
        }
      }
    };

  const syncWidgetInstance =
    (widgetKey: string, widgetValues: WidgetInstanceValues) =>
    async (
      dispatch: Dispatch<Action, WampCallResponseAction<[unknown, number], {}, never>>,
      getState: () => AppState
    ) => {
      await dispatch(processWidgetValues(widgetKey, widgetValues));
      try {
        const state: AppState = getState();
        const isSavingBadgeShown = !!state?.xplayer?.layout?.showSavingBadge;

        if (!isSavingBadgeShown) {
          dispatch(showSavingBadge());
        }
        widgetValues.throttle
          ? await dispatch(sendWidgetValuesThrottled(widgetKey, widgetValues))
          : await dispatch(sendWidgetValues(widgetKey, widgetValues));
      } catch (e) {
        const errorAction = e as WampErrorAction<
          [string] | [WidgetValuesVersionChanged] | ExerciseAlreadyCompletedErrorArgs,
          unknown,
          never
        >;
        const errorUri = errorAction.error.error;

        if (import.meta.env.MODE === 'development') {
          console.error('syncWidgetInstance: send error', errorUri); // eslint-disable-line no-console
        }

        // TODO: handle offline worker errors like 'wamp.error.not_authorized' and probably some others
        if (errorUri.endsWith(NOT_AUTHORIZED)) {
          // user is not authorized to send widget exercise values,
          // so we should do nothing here because removing values from sync
          // is done in finally block below
        } else if (errorUri.endsWith('xplayer.widget.stale.error')) {
          const staleErrorAction = errorAction as WampErrorAction<
            [WidgetValuesVersionChanged],
            {exerciseId: string; widgetId: number},
            never
          >;
          const [actialWidgetValues] = staleErrorAction.error.args;
          const {exerciseId, widgetId} = staleErrorAction.error.kwargs;
          const {values, version} = actialWidgetValues;
          dispatch(widgetValuesVersionChanged(exerciseId, widgetId, values, version));
        } else if (errorUri.endsWith('xplayer.exercise.already.completed.error')) {
          const alreadyCompletedErrorAction = errorAction as WampErrorAction<
            ExerciseAlreadyCompletedErrorArgs,
            {exerciseId: string},
            never
          >;
          const {exerciseId} = alreadyCompletedErrorAction.error.kwargs;
          const [completedWidgetValues, completedAt] = alreadyCompletedErrorAction.error.args;
          dispatch(exerciseAlreadyCompleted(exerciseId, completedWidgetValues, completedAt));
        } else {
          dispatch(sendWidgetValuesError(widgetKey));
        }
      } finally {
        dispatch(deleteProcessingWidgetValues(widgetKey));
        dispatch(syncWidgetInstances);
      }
    };

  return ({dispatch, getState}) =>
    next =>
    (action: Action<string>) => {
      let userId: number | undefined = undefined;
      const xplayer = getState().xplayer;
      if (!xplayer) {
        return next(action);
      }
      const {user} = getState();
      if (xplayer.sync.userId) {
        userId = xplayer.sync.userId;
      } else if (user.id && action.type !== SET_USER_ID) {
        dispatch(setXplayerUserId(user.id));
        userId = user.id;
      }
      if (!action.type || !userId) {
        return next(action);
      }

      if (action.type.startsWith(widgetActionPrefix)) {
        const result = next(action);
        const widgetAction = action as WidgetAction;
        const state = getState();
        const sendValues = getWidgetValues(state, widgetAction.widgetId);
        if (sendValues) {
          dispatch(persistWidgetValues(sendValues));
        }
        return result;
      } else if (action.type === PERSIST_WIDGET_VALUES) {
        const result = next(action);
        dispatch(syncWidgetInstances);
        return result;
      } else if (action.type === REHYDRATE) {
        const rehydrateAction = action as RehydrateAction & {payload: Pick<XPlayerState, 'sync'>};
        const result = next(action);

        if (rehydrateAction.key !== PersistState.xplayer) return result;

        const {user, xplayer} = getState();
        userId = xplayer?.sync.userId;

        if (rehydrateAction.payload?.sync && !userId && user.id) {
          // even we are sure that userId is defined, after
          // handling REHYDRATE action (next), the `xplayer?.sync.userId` could be
          // reset to undefined, so we need to set it here
          dispatch(setXplayerUserId(user.id));
          userId = user.id;
        }
        if (userId && rehydrateAction.payload?.sync?.otherUserWidgetValues) {
          const otherWidgetValues = rehydrateAction.payload.sync.otherUserWidgetValues.get(userId);
          if (otherWidgetValues && otherWidgetValues.size) {
            dispatch(rehydrateUserWidgetInstances(userId, otherWidgetValues));
            dispatch(rehydrateWidgetInstances(otherWidgetValues));
            dispatch(syncWidgetInstances);
          }
        }
        return result;
      } else if (
        [REQUEST_UNIT_INSTANCE_PAGE_SUCCESS, LOAD_HOMEWORK_EXERCISE_SUCCESS].includes(action.type)
      ) {
        const result = next(action);
        const state = getState();
        const xplayer = state.xplayer;
        if (xplayer) {
          const {processingWidgetValues} = xplayer.sync;
          const widgetValues = processingWidgetValues.merge(xplayer.sync.widgetValues);
          if (widgetValues.size) {
            dispatch(rehydrateWidgetInstances(widgetValues));
          }
        }
        return result;
      }

      return next(action);
    };
};

export default xplayerMiddleware;
