import React from 'react';
import {FormattedMessage, type WrappedComponentProps, injectIntl} from 'react-intl';
import classNames from 'classnames';

import {type AvatarUrl, type UserStatus} from 'store/interface';
import Icon from 'components/Icon';

import Avatar from '../../components/Avatar';
import {isUserStatusSupportsRtc, onlineState2UserStatus} from '../../helpers/user';
import {UserTyping} from './components/UserTyping/UserTyping';

import './private-user.scss';

interface PrivateUserProps {
  name: string;
  isCallPartner?: boolean;
  isAwaitingAnswer?: boolean;
  status?: UserStatus;
  videoCall?: boolean;
  avatar: AvatarUrl;
  isCompact?: boolean;
  showCallButtons?: boolean;
  onlineState?: number;
  partnerIsTyping?: boolean;
  className?: string;
}

class PrivateUser extends React.Component<PrivateUserProps & WrappedComponentProps, {}> {
  public render() {
    const {isCallPartner, isCompact, status, showCallButtons, avatar, className} = this.props;
    const componentClass: string = classNames('private-user', className, {compact: isCompact});
    const isCallToRecipientAllowed = status !== undefined && isUserStatusSupportsRtc(status);

    const title = this.renderStatusTitle();
    const isMobile = this.getStatusType() === 'mobile';

    const avatarContent = (
      <>
        <Avatar size={isCompact ? 28 : 42} url={avatar} mask={this.getAvatarMask()} />
        {!!this.props.status && (
          <div className={classNames('user-status', this.getStatusType())}>
            {isMobile && <Icon name="mobile" className="mobile-icon" />}
          </div>
        )}
      </>
    );

    const avatarContainer = this.props.status ? (
      <div className={classNames('avatar-container', {offline: this.getStatus() === 'offline'})}>
        {avatarContent}
      </div>
    ) : (
      <>{avatarContent}</>
    );
    const nameClass: string = classNames(
      {'is-call-partner': isCallPartner},
      {'with-buttons': showCallButtons && isCallToRecipientAllowed},
      `avatar-${isCompact ? 'xs' : 'sm'}`
    );

    return (
      <div className={componentClass}>
        {avatarContainer}

        <div className="user-info">
          <span className={nameClass}>{this.props.name}</span>
          {this.props.partnerIsTyping ? (
            <UserTyping />
          ) : status ? (
            <span className="status-container">
              {!isCompact && this.renderStatusText()}
              {title && <Icon name="virc-no-rtc" title={title} />}
            </span>
          ) : null}
        </div>
      </div>
    );
  }

  protected renderStatusTitle = () => {
    const status = this.getStatus();
    switch (status) {
      case 'app-vc-mobile':
      case 'app-vc-mobile-webview':
        return this.props.intl.formatMessage({id: 'Chat.UnableToMakeCallsMobileApp'});
      case 'online-no-rtc':
      case 'online-mobile-no-rtc':
        return this.props.intl.formatMessage({id: 'Chat.UnableToMakeCallsBrowser'});
      default:
        return null;
    }
  };

  protected renderStatusText = () => {
    const status = this.getStatus();
    switch (status) {
      case 'online':
      case 'online-mobile':
      case 'app-vc-mobile':
      case 'app-vc-mobile-webview':
      case 'online-no-rtc':
      case 'online-mobile-no-rtc':
        return <FormattedMessage id="Chat.OnlineStatus" />;
      case 'offline':
        return <FormattedMessage id="Chat.OfflineStatus" />;
      case 'oncall':
        return <FormattedMessage id="Chat.OncallStatus" />;
      default:
        return null;
    }
  };

  protected getStatusType = () => {
    const status = this.getStatus();
    switch (status) {
      case 'online':
      case 'online-no-rtc':
        return 'online';
      case 'online-mobile':
      case 'app-vc-mobile':
      case 'app-vc-mobile-webview':
      case 'online-mobile-no-rtc':
        return 'mobile';
      case 'offline':
        return 'offline';
      case 'oncall':
        return 'oncall';
      default:
        return null;
    }
  };

  private getAvatarMask = () => {
    const status = this.getStatus();
    if (status === 'offline') {
      return 'default';
    }
    if (status === 'oncall') {
      return 'phone';
    }
    return;
  };

  private getStatus = () => {
    // don't show "unavailable" (oncall) for recipient, so we don't confuse user
    const {status, isAwaitingAnswer, isCallPartner, onlineState} = this.props;
    if (!!status && isAwaitingAnswer && isCallPartner) {
      return onlineState2UserStatus(onlineState, 0);
    }
    return this.props.status;
  };
}

export default injectIntl(PrivateUser);
