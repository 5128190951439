import {shufflePhrases} from 'components/Chat/utils';

interface Phrases {
  greeting: string[];
  questions: string[];
}

const startPhrases: Phrases = {
  greeting: ['Hello!', 'Hello there!', 'Hi!', 'Hi there!'],
  questions: [
    'Ready to start our lesson?',
    'Are you ready to start?',
    'Ready for the lesson?',
    'Shall we begin?',
    'Ready when you are.',
    'Ready for the class?',
    'Shall we start?',
    'Shall we get started?',
    "Let's get started, shall we?"
  ]
};

const startPhrasesCount = 4;
const initialStartPhrasesCount = startPhrases.greeting.length;
const totalStartPhrasesCount =
  startPhrasesCount > initialStartPhrasesCount ? initialStartPhrasesCount : startPhrasesCount;

export const useStartPhrases = (): string[] => {
  const randomGreeting = shufflePhrases(startPhrases.greeting);
  const randomQuestions = shufflePhrases(startPhrases.questions);

  const randomPhrases = ['Hello! Are you ready to start?'];

  let index = 0;
  while (randomPhrases.length < totalStartPhrasesCount) {
    const phrase = `${randomGreeting[index]} ${randomQuestions[index]}`;
    if (phrase !== randomPhrases[0]) randomPhrases.push(phrase);
    index += 1;
  }

  return randomPhrases;
};
