import {useCallback} from 'react';

import {type AxiosRequestAction, type AxiosResponseAction} from 'services/axios/interface';

import {useAxiosDispatch} from '../redux/useAxiosDispatch';

export const useAxiosRequest = <T extends unknown[] = unknown[]>(
  actionCreator: (...args: T) => AxiosRequestAction
) => {
  const dispatch = useAxiosDispatch();

  const request = useCallback(
    async <R>(...args: T): Promise<R> => {
      const action = actionCreator(...args);
      const req: Promise<AxiosResponseAction<R>> = dispatch(action);
      const response = await req;
      return response.payload.data;
    },
    [actionCreator, dispatch]
  );

  return {request};
};
