import React from 'react';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import {FormattedMessage} from 'react-intl';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

import ControlledTooltip from '../components/ControlledTooltip';
import './Tooltip.scss';

interface Props extends Partial<TooltipProps> {
  disabled?: boolean;
  capitalize?: true;
  contentEditable?: false;
  getTooltipContainer?: () => HTMLElement;
  toggleCapitalize: () => void;
}

export class Tooltip extends React.Component<Props> {
  public render() {
    const {children, contentEditable, getTooltipContainer, disabled} = this.props;

    return !disabled ? (
      <ControlledTooltip
        destroyTooltipOnHide={true}
        getTooltipContainer={getTooltipContainer}
        overlay={this.overlay()}
        placement="bottomLeft"
        prefixCls="slate-link-tooltip slate-nested-toolbar"
        contentEditable={contentEditable}
      >
        {children}
      </ControlledTooltip>
    ) : (
      {children}
    );
  }

  private overlay = () => {
    const {capitalize} = this.props;
    return (
      <div className="capitalize-overlay">
        <Checkbox onChange={this.toggleCapitalize} defaultChecked={capitalize || false}>
          <FormattedMessage id="XEditor.Form.EditGap.PreserveCapitalization" />
        </Checkbox>
      </div>
    );
  };

  private toggleCapitalize = () => {
    this.props.toggleCapitalize();
  };
}
