import React, {type FC, useCallback, useContext} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import classNames from 'classnames';

import Icon from 'components/Icon';
import {useCloseSidebarPanels} from 'components/Dictionary/hooks/useCloseSidebarDictionaryPanels';

import {EditEntryActionsContext} from '../contexts/entry/EditEntryContext';
import {enterSelectionMode} from '../contexts/entry/actions';

interface Props {
  listId?: string;
  callback?: () => void;
  disabled?: boolean;
}

export const EnterSelectionModeButton: FC<Props> = ({listId, callback, disabled}) => {
  const intl = useIntl();
  const {dispatch} = useContext(EditEntryActionsContext);
  const {closeSidebarPanels} = useCloseSidebarPanels();

  const enterSelectionModeCallback = useCallback(() => {
    closeSidebarPanels();
    callback?.();
    dispatch(enterSelectionMode(listId));
  }, [callback, closeSidebarPanels, dispatch, listId]);

  return (
    <Button
      className={classNames('dictionary-selection-controls select-words-btn')}
      bsStyle="success"
      onClick={enterSelectionModeCallback}
      title={intl.formatMessage({id: 'Dictionary.SelectWords'})}
      disabled={disabled}
    >
      <Icon name="check" />
      <FormattedMessage id="Dictionary.SelectWords" />
    </Button>
  );
};
