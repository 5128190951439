import {type Immutable} from 'immer';
import {Editor, Element, Node, Path, Range} from 'slate';
import {HistoryEditor} from 'slate-history';
import {ReactEditor} from 'slate-react';

import {type SlateEditorProps} from '../../components/SlateEditor';
import {type BlockDef, type BlockType, type SlateDefRegistry} from '../../definitions';
import {type EditableProps} from '../../interface';
import {type WidgetTypeComponentProps} from '../../../../store/exercise/player/interface';

export interface SlateEditor {
  readonly getEditorId: () => string;
  readonly getEditorProps?: () => Readonly<SlateEditorProps> | null;
  readonly defRegistry?: Immutable<SlateDefRegistry>;
  addBlockDef?: (...types: BlockDef[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlateEditor = {
  id(editor: Editor & SlateEditor): string {
    return editor.getEditorId();
  },
  prop<T extends keyof SlateEditorProps>(editor: Editor, key: T): SlateEditorProps[T] {
    return editor.getEditorProps?.()?.[key];
  },
  editableProp<T extends keyof EditableProps>(editor: Editor, key: T): EditableProps[T] {
    return editor.editableProps?.[key];
  },
  placeholder(editor: Editor): string | undefined {
    return SlateEditor.prop(editor, 'placeholder');
  },
  toolbarPortalId(editor: Editor): string | undefined {
    return SlateEditor.prop(editor, 'toolbar')?.portalId;
  },
  autoFocus(editor: Editor & SlateEditor): SlateEditorProps['autoFocus'] {
    return SlateEditor.prop(editor, 'autoFocus');
  },
  getWidgetProps(editor: Editor): WidgetTypeComponentProps {
    const getWidgetProps = SlateEditor.prop(editor, 'getWidgetProps');
    if (!getWidgetProps) {
      throw new Error('SlateJS: getWidgetProps prop is not defined.');
    }
    return getWidgetProps();
  },
  wordCount(editor: Editor): number {
    const leafBlocks = Editor.nodes(editor, {
      at: [],
      mode: 'lowest',
      match: n => Element.isElement(n) && Editor.isBlock(editor, n) && Editor.hasInlines(editor, n)
    });
    let count = 0;
    for (const [leafBlock] of leafBlocks) {
      count += Node.string(leafBlock)
        .trim()
        .split(/\s+/)
        .filter(t => /[a-zA-Z0-9А-Яа-я]+/.test(t)).length;
    }
    return count;
  },
  getBlockTypes(editor: Editor): ReadonlySet<BlockType> {
    return editor.defRegistry?.getBlockTypes() || new Set();
  },
  defineBlock(editor: Editor, ...block: BlockDef[]): void {
    editor.addBlockDef?.(...block);
  },
  defRegistry(editor: Editor): Immutable<SlateDefRegistry> | undefined {
    return editor.defRegistry;
  },
  flush(editor: Editor) {
    // When there are no operations applied,
    // we apply custom operation here just to schedule react rerender
    // Such a custom operation shouldn't be saved into history to prevent undo/redo issues.
    if (!editor.operations.length) {
      HistoryEditor.withoutSaving(editor, () => {
        editor.apply({type: 'react_request_rerender'});
      });
    }
  },
  isPreviousPointInMarkableVoid(editor: Editor): boolean {
    const {selection} = editor;
    if (selection && Range.isCollapsed(selection) && selection.anchor.offset === 0) {
      const {
        anchor: {path}
      } = selection;
      const prevIsMarkableVoid =
        Path.hasPrevious(path) &&
        Editor.previous(editor, {
          at: path,
          match: (n, p) =>
            Path.equals(Path.previous(path), p) && Element.isElement(n) && editor.markableVoid(n)
        });
      return !!prevIsMarkableVoid;
    }
    return false;
  },
  isVoidInlineSelectedOnly(editor: Editor): boolean {
    const {selection} = editor;
    if (!selection || Range.isExpanded(selection) || selection.anchor.offset !== 0) return false;

    const [node] = Editor.parent(editor, selection.anchor.path, {edge: 'end'});
    return Element.isElement(node) && editor.isVoid(node) && editor.isInline(node);
  },
  isDOMSelectionInEditor(editor: Editor): boolean {
    const selection = window.getSelection();
    if (!selection) return false;
    let range: Range | null = null;
    try {
      range = ReactEditor.toSlateRange(editor, selection, {exactMatch: true, suppressThrow: true});
    } catch {}
    return !!range;
  }
};
