import {type Action, type Reducer} from 'redux';

import {type MediaDevicesState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  SET_DEVICES,
  CHANGE_PERMISSIONS,
  NO_CAMS_AVAILABLE,
  NO_MICS_AVAILABLE,
  SAVE_DEVICES,
  SAVE_MODAL_SELECTION,
  SELECT_CAM,
  SELECT_MIC,
  SELECTED_CAM_PRESENCE,
  SELECTED_MIC_PRESENCE,
  SKIP_GREETING,
  TOGGLE_DEVICES_MODAL
} from './actionTypes';
import {type ToggleElementAction} from '../../../../common/interface';
import {
  type SetDevicesAction,
  type ChangePermissionsAction,
  type NoDeviceAvailableAction,
  type SaveDevicesAction,
  type SelectDeviceAction,
  type SelectedDevicePresenceAction,
  type SkipGreetingAction
} from './action';

const actionHanlers: ActionHandlersList<MediaDevicesState, Action> = {
  [TOGGLE_DEVICES_MODAL]: (state: MediaDevicesState, action: ToggleElementAction) => {
    if (!action.show) {
      const {showModal, modalSelectedCam, modalSelectedMic, ...newState} = state;
      return newState;
    }
    return {
      ...state,
      showModal: action.show,
      modalSelectedCam: state.cam,
      modalSelectedMic: state.mic
    };
  },
  [SKIP_GREETING]: (state: MediaDevicesState, action: SkipGreetingAction) => ({
    ...state,
    skipGreeting: action.skip
  }),
  [CHANGE_PERMISSIONS]: (state: MediaDevicesState, action: ChangePermissionsAction) => ({
    ...state,
    cameraAccess: Boolean(action.video),
    micAccess: Boolean(action.audio)
  }),
  [SAVE_DEVICES]: (state: MediaDevicesState, action: SaveDevicesAction) => {
    return {
      ...state,
      available: [...action.devices],
      modalSelectedMic: action.mic?.deviceId,
      modalSelectedCam: action.cam?.deviceId,
      modalSelectedMicLabel: action.mic?.label,
      modalSelectedCamLabel: action.cam?.label
    };
  },
  [SELECT_CAM]: (state: MediaDevicesState, action: SelectDeviceAction) => ({
    ...state,
    modalSelectedCam: action.deviceId,
    modalSelectedCamLabel: action.deviceLabel
  }),
  [SELECT_MIC]: (state: MediaDevicesState, action: SelectDeviceAction) => ({
    ...state,
    modalSelectedMic: action.deviceId,
    modalSelectedMicLabel: action.deviceLabel
  }),
  [SAVE_MODAL_SELECTION]: (state: MediaDevicesState) => ({
    ...state,
    cam: state.modalSelectedCam,
    mic: state.modalSelectedMic,
    camLabel: state.modalSelectedCamLabel,
    micLabel: state.modalSelectedMicLabel,
    selectedMicPresent: !!state.modalSelectedMic || undefined,
    selectedCamPresent: !!state.modalSelectedCam || undefined
  }),
  [SET_DEVICES]: (state: MediaDevicesState, action: SetDevicesAction) => ({
    ...state,
    cam: action.cam || state.cam,
    mic: action.mic || state.mic
  }),
  [SELECTED_CAM_PRESENCE]: (state: MediaDevicesState, action: SelectedDevicePresenceAction) => ({
    ...state,
    selectedCamPresent: action.present
  }),
  [SELECTED_MIC_PRESENCE]: (state: MediaDevicesState, action: SelectedDevicePresenceAction) => ({
    ...state,
    selectedMicPresent: action.present
  }),
  [NO_MICS_AVAILABLE]: (state: MediaDevicesState, action: NoDeviceAvailableAction) => {
    if (!action.notAvailable) {
      const {noMicsAvailable, ...newState} = state;
      return newState;
    }
    return {
      ...state,
      noMicsAvailable: action.notAvailable
    };
  },
  [NO_CAMS_AVAILABLE]: (state: MediaDevicesState, action: NoDeviceAvailableAction) => {
    if (!action.notAvailable) {
      const {noCamsAvailable, ...newState} = state;
      return newState;
    }
    return {
      ...state,
      noCamsAvailable: action.notAvailable
    };
  }
};

const initialState: MediaDevicesState = {};

const mediaDevisesReducer: Reducer<MediaDevicesState, Action> = (state = initialState, action) => {
  const reducer = actionHanlers[action.type];
  return reducer ? reducer(state, action) : state;
};

export default mediaDevisesReducer;
