import {type Dispatch, type MiddlewareAPI, type Store} from 'redux';

import {type AppState} from 'store/interface';

import {type ParsedToken, parseToken} from '../../config/jwt';
import {type ConnectionOptions} from '../wamp/actions/types';
import {type InitializeClientAction} from '../../routes/Sandbox/AutobahnTester/actions/actions';
import uriMap from '../wamp/uriMap';
const realm: string = import.meta.env.REACT_APP_WAMP_REALM;
const maxRetries: number = import.meta.env.REACT_APP_WAMP_MAX_RETRIES;

export const getAutobahnConfig = (
  action: InitializeClientAction
): ConnectionOptions<Dispatch, AppState> => ({
  client: action.client || 'autobahn',
  url: action.url,
  realm,
  uriPrefix: import.meta.env.REACT_APP_WAMP_URI_PREFIX,
  uriMap,
  max_retries: maxRetries,
  use_es6_promises: true,
  authmethods: ['ticket'],
  stateAuthId(store: MiddlewareAPI<Dispatch, AppState>) {
    const tokenPayload: ParsedToken = parseToken(store.getState().autobahnTester!.token);
    return String(tokenPayload.authid);
  },
  stateToken(store: Store<AppState>) {
    return store.getState().autobahnTester!.token;
  }
});
