import {type Action, type ActionCreator} from 'redux';

import {type ToggleElementCreator} from '../../../../common/interface';
import {
  CHANGE_PERMISSIONS,
  NO_CAMS_AVAILABLE,
  NO_MICS_AVAILABLE,
  SAVE_DEVICES,
  SAVE_MODAL_SELECTION,
  SELECT_CAM,
  SELECT_MIC,
  SET_DEVICES,
  SELECTED_CAM_PRESENCE,
  SELECTED_MIC_PRESENCE,
  SKIP_GREETING,
  TOGGLE_DEVICES_MODAL
} from './actionTypes';

export interface Devices {
  cam?: string;
  mic?: string;
}

export interface SetDevicesAction extends Action, Devices {}

export const toggleDevicesModal: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_DEVICES_MODAL,
  show
});

export const skipGreeting: ActionCreator<Action> = (skip = true): SkipGreetingAction => ({
  type: SKIP_GREETING,
  skip
});

export interface SkipGreetingAction extends Action {
  skip: boolean;
}
export interface ChangePermissionsAction extends Action, MediaStreamConstraints {}

export interface ChangePermissionsCreator extends ActionCreator<ChangePermissionsAction> {
  (options: MediaStreamConstraints): ChangePermissionsAction;
}

export const changePermissions: ChangePermissionsCreator = (options: MediaStreamConstraints) => ({
  type: CHANGE_PERMISSIONS,
  ...options
});

export interface SaveDevicesAction extends Action {
  devices: MediaDeviceInfo[];
  mic?: MediaDeviceInfo;
  cam?: MediaDeviceInfo;
}

export const saveDevices = (
  devices: MediaDeviceInfo[],
  mic?: MediaDeviceInfo,
  cam?: MediaDeviceInfo
): SaveDevicesAction => ({
  type: SAVE_DEVICES,
  mic,
  cam,
  devices
});

export interface SelectDeviceAction extends Action {
  deviceId: string;
  deviceLabel: string;
}

export interface SelectDeviceCreator extends ActionCreator<SelectDeviceAction> {
  (device: MediaDeviceInfo): SelectDeviceAction;
}

export const selectCam = (deviceId: string, deviceLabel: string) => ({
  type: SELECT_CAM,
  deviceId,
  deviceLabel
});

export const selectMic = (deviceId: string, deviceLabel: string) => ({
  type: SELECT_MIC,
  deviceId,
  deviceLabel
});

export const saveModalSelection: ActionCreator<Action> = () => ({
  type: SAVE_MODAL_SELECTION
});

export interface SelectedDevicePresenceAction extends Action {
  present: boolean;
}

export interface SelectedDevicePresenceCreator extends ActionCreator<SelectedDevicePresenceAction> {
  (present: boolean): SelectedDevicePresenceAction;
}

export const selectedMicPresence: SelectedDevicePresenceCreator = (present: boolean) => ({
  type: SELECTED_MIC_PRESENCE,
  present
});

export const selectedCamPresence: SelectedDevicePresenceCreator = (present: boolean) => ({
  type: SELECTED_CAM_PRESENCE,
  present
});

export interface NoDeviceAvailableAction extends Action {
  notAvailable: boolean;
}

export interface NoDeviceAvailableCreator extends ActionCreator<NoDeviceAvailableAction> {
  (notAvailable: boolean): NoDeviceAvailableAction;
}

export const setNoCamsAvailable: NoDeviceAvailableCreator = (notAvailable: boolean) => ({
  type: NO_CAMS_AVAILABLE,
  notAvailable
});

export const setNoMicsAvailable: NoDeviceAvailableCreator = (notAvailable: boolean) => ({
  type: NO_MICS_AVAILABLE,
  notAvailable
});

export const setDevices = (devices: Devices) => ({type: SET_DEVICES, ...devices});
