import {type ReducersMapObject} from 'redux';
import {Value} from '@englex/slate';
import {type List} from 'immutable';

import {type ExerciseInstanceComment, type ExerciseInstanceCommentMap} from 'store/interface';
import {type DataMap} from 'components/Slate/interface';

import {type CommentAction, type CommentDescriptorAction} from './actions';
import {DELETE_COMMENT, PUSH_COMMENT, REPLACE_COMMENT} from './actionTypes';
import {commentFactory} from './commentFactory';

type State = List<DataMap<ExerciseInstanceCommentMap>>;

const reducers: ReducersMapObject = {
  [PUSH_COMMENT]: (state: State, {comment}: CommentAction): State =>
    state.push(commentFactory(comment as ExerciseInstanceComment)),
  [REPLACE_COMMENT]: (state: State, {comment}: CommentAction): State =>
    state.map((c: DataMap<ExerciseInstanceCommentMap>) =>
      c.get('id') === comment.id
        ? c.withMutations(cc => {
            cc.set('updatedAt', comment.updatedAt);
            cc.set('comment', Value.fromJSON(comment.comment));
          })
        : c
    ) as State,
  [DELETE_COMMENT]: (state: State, {comment}: CommentDescriptorAction): State =>
    state.filter((c: DataMap<ExerciseInstanceCommentMap>) => c.get('id') !== comment.id) as State
};

export const commentsReducer = (
  state: State,
  action: CommentAction | CommentDescriptorAction
): State => reducers[action.type]?.(state, action) || state;
