import React from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';

import Icon from './Icon';

import './library-error.scss';

interface Props {
  reload: () => void;
  errorTitle: JSX.Element;
  errorText: JSX.Element;
}

class ModalError extends React.Component<Props, {}> {
  public render() {
    return (
      <div className="library-error">
        <div className="info-block">
          <Icon size="lg" name="refresh" className="ico text text-danger text-center" />

          <div className="title text text-danger text-center">{this.props.errorTitle}</div>

          <div className="description text text-center">{this.props.errorText}</div>

          <Button bsStyle="primary" bsSize="sm" className="reload" onClick={this.props.reload}>
            <FormattedMessage id="Common.Reload" />
          </Button>
        </div>
      </div>
    );
  }
}
export default ModalError;
