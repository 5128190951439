import React from 'react';
import classNames from 'classnames';

import {type Room, type RoomUser, type RoomWithRecipient} from 'store/interface';
import {changeChatSize, emitter, requestChatSize} from 'services/event-emitter';

import SelectPartnerPopover from '../../../SelectPartnerPopover';
import UnreadMessagesNotification from '../UnreadMessagesNotification/UnreadMessagesNotification';
import PrivateUser from '../../PrivateUser';
import {userFullName} from '../../../../helpers/user';
import preventFilterRegexpErrors from '../../../../helpers/toValidFilter';

export default class ChatSelectRoomPopover extends SelectPartnerPopover<RoomWithRecipient> {
  protected containerClassName: string = 'chat-popover-wide';

  public isElementActive = (room: RoomWithRecipient) => !room.deleted_at;

  public elementMatchesSearchFilter = (room: RoomWithRecipient) =>
    !!room.recipient &&
    userFullName(room.recipient.profile!)
      .toLowerCase()
      .search(preventFilterRegexpErrors(this.props.filter)) !== -1;

  public componentDidMount() {
    emitter.addListener(changeChatSize, this.changeChatSizeHandler);
    emitter.emit(requestChatSize);
  }

  public componentWillUnmount() {
    this.props.changeActiveTab('1');
    emitter.removeListener(changeChatSize, this.changeChatSizeHandler);
  }

  protected renderPrivateUser(recipient: RoomUser, showStatus: boolean): JSX.Element {
    let isCallPartner: boolean = false;
    if (this.props.callPartnerId && this.activeElements[this.props.callPartnerId]) {
      if (recipient === (this.activeElements[this.props.callPartnerId] as Room).recipient) {
        isCallPartner = true;
      }
    }
    const awaitingCallAnswer = this.props.awaitingCallAnswer && isCallPartner;
    return (
      <PrivateUser
        status={showStatus ? recipient.userStatus : undefined}
        name={userFullName(recipient.profile!)}
        isCallPartner={isCallPartner}
        videoCall={this.props.videoCall}
        avatar={recipient.profile!.avatars.sm}
        isAwaitingAnswer={awaitingCallAnswer}
        isCompact={this.props.isChatCompact}
        onlineState={recipient.online}
      />
    );
  }

  protected sortByAlphabet = (room1: RoomWithRecipient, room2: RoomWithRecipient) => {
    if (
      userFullName(room1.recipient.profile!).toUpperCase() >
      userFullName(room2.recipient.profile!).toUpperCase()
    ) {
      return 1;
    }
    return -1;
  };

  protected renderUnreadMessagesNotification(room: RoomWithRecipient): JSX.Element | null {
    return (
      <UnreadMessagesNotification
        unreadMessagesNumber={room.newMessagesCount}
        compact={this.props.isChatCompact}
      />
    );
  }

  public renderElement = (room: RoomWithRecipient) => {
    if (room.private) {
      return this.renderRecipient(room);
    }

    return (
      // TODO: implement view pubic room
      <div />
    );
  };

  protected getScrollBarHeight = () => {
    const contentHeight: number = this.getPopoverHeight();
    if (this.state.chatHeight > contentHeight) {
      return contentHeight - this.state.searchBarHeight - this.state.tabsHeight;
    }
    return this.state.chatHeight - this.state.searchBarHeight - this.state.tabsHeight;
  };

  protected sortActiveRooms = (roomsArg: RoomWithRecipient[]) => {
    const onlineRooms: RoomWithRecipient[] = [];
    const offlineRooms: RoomWithRecipient[] = [];
    (roomsArg as RoomWithRecipient[]).forEach((room: RoomWithRecipient) => {
      switch (room.recipient.userStatus) {
        case 'offline':
          offlineRooms.push(room);
          break;
        default:
          onlineRooms.push(room);
          break;
      }
    });
    onlineRooms.sort(this.sortByAlphabet);
    offlineRooms.sort(this.sortByAlphabet);
    const rooms: RoomWithRecipient[] = onlineRooms.concat(offlineRooms);
    rooms.forEach((room: RoomWithRecipient, i: number) => {
      if (this.props.callPartnerId && room.id === this.props.callPartnerId) {
        rooms.splice(i, 1);
        rooms.unshift(room);
      }
    });
    return rooms;
  };

  protected renderRecipient = (recipient: RoomWithRecipient) => {
    return (
      <li
        key={recipient.id}
        onClick={() => {
          this.props.partnerSelectedHandler(recipient.id);
          this.props.hidePopover();
        }}
        className={classNames({selected: this.props.selectedElementId === recipient.id})}
      >
        {this.renderPrivateUser(recipient.recipient, !recipient.deleted_at)}
        {this.renderUnreadMessagesNotification(recipient)}
      </li>
    );
  };

  private getPopoverHeight = () => {
    let height: number = this.state.searchBarHeight + this.state.tabsHeight;
    if (this.props.openTabId === '1') {
      if (Object.keys(this.activeElements).length === 0) {
        height += SelectPartnerPopover.noElementsFoundHeight;
      }
      height += this.state.listElementHeight * Object.keys(this.activeElements).length;
    } else {
      if (Object.keys(this.inactiveElements).length === 0) {
        height += SelectPartnerPopover.noElementsFoundHeight;
      }
      height += this.state.listElementHeight * Object.keys(this.inactiveElements).length;
    }
    return height;
  };

  private changeChatSizeHandler = (height: number) => {
    this.setState({chatHeight: height});
  };
}
