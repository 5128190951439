import * as axios from 'axios';
import {type MiddlewareOptions} from 'redux-axios-middleware';

import * as toastr from 'components/toastr';
import {sessionExpiredReason} from 'config/static';
import {type AppState} from 'store/interface';

import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX, type AxiosRequestAction} from './interface';
import {loginRequired, userSessionExpired} from '../../authentication/actions/action';

const config: axios.AxiosRequestConfig = {
  baseURL: import.meta.env.REACT_APP_REST_API_URL,
  responseType: 'json'
};

export const middlewareOptions: MiddlewareOptions<AppState> = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      (reduxStore, requestConfig) => {
        const state: AppState = reduxStore.getState();
        if (state.user.token && !state.user.sessionExpired) {
          if (!requestConfig.headers) requestConfig.headers = {};
          requestConfig.headers.authorization = 'Bearer ' + state.user.token;
          requestConfig.headers['accept-language'] = state.intl.locale;
        } else {
          if (
            requestConfig.url &&
            !['/v1/user/login', '/v2/dashboard/login'].includes(requestConfig.url)
          ) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            requestConfig.cancelToken = new (axios as any).CancelToken(
              (cancel: (message: string) => void) => cancel(sessionExpiredReason)
            );
          }
        }
        return requestConfig;
      }
    ],
    response: [
      {
        success: (redux, response) => response,
        error: (redux, error) => {
          // TODO: review default error handler
          if (
            error.response &&
            error.response.status === 401 &&
            error.config.url !== '/v2/dashboard/login' &&
            !redux.getState().user.sessionExpired
          ) {
            if (redux.getState().user.profile) {
              redux.dispatch(userSessionExpired);
            } else {
              redux.dispatch(loginRequired());
            }
          }
          if (
            error.response &&
            error.response.status >= 500 &&
            import.meta.env.MODE === 'development'
          ) {
            // eslint-disable-next-line no-console
            console.error('axios error:', error);
            toastr.error('Internal server error.', 'Look it up in the console.');
          }
          return Promise.reject(error);
        }
      }
    ]
  },
  onSuccess: res => {
    const {action, next, response} = res;
    const preventDispatch = (action as AxiosRequestAction).payload.options?.preventDispatch;

    const nextAction = {
      type: `${action.type}${AXIOS_SUCCESS_POSTFIX}`,
      payload: response,
      meta: {
        previousAction: action
      }
    };

    if (preventDispatch?.onSuccess?.(res)) return nextAction;

    next(nextAction);
    return nextAction;
  },
  successSuffix: AXIOS_SUCCESS_POSTFIX, // probably not used after onSuccess overload
  errorSuffix: AXIOS_ERROR_POSTFIX
};

export default config;
