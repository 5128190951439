import * as yup from 'yup';
import {type IntlShape} from 'react-intl';
import {Map, Set} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {type VideoWidgetJSON} from 'store/exercise/player/widgets/Video/interface';
import {exerciseExcerptLength} from 'config/static';
import {type VideoData, type VideoOptions} from 'components/media/interface';

import {type XVideoProperties} from './interface';
import {taskExcerpt, contentExcerpt} from '../excerpt';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import validationMessages from '../i18n';
import {documentNotEmpty, videoExists} from '../validation';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

class XVideoRecord extends XFormattedTextRecord implements XVideoProperties {
  public declare readonly video: VideoData;
  public declare readonly posterId?: number;
  public declare readonly videoOptions?: VideoOptions;

  constructor(raw: VideoWidgetJSON) {
    super(raw);
    this.initValues({video: raw.video, posterId: raw.posterId, videoOptions: raw.videoOptions});
  }

  public toJSON(options?: WidgetToJSONOptions): VideoWidgetJSON {
    return {
      ...super.toJSON(options),
      video: {id: this.video.id, source: this.video.source},
      videoOptions: this.videoOptions,
      posterId: this.posterId
    };
  }

  public get type() {
    return WidgetType.VIDEO;
  }

  public get excerpt(): string {
    const task = this.task ? taskExcerpt(this.task) : '';
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const label = '[Video]';
    return `${task} ${label} ${contentExcerpt(this.content, task.length + label.length)}`;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      video: yup
        .mixed()
        .test(
          'Should contain video',
          intl.formatMessage(validationMessages.VideoExists),
          (v: VideoData) => videoExists(v)
        ),
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    });
  }

  public get media(): MediaMeta | undefined {
    if (!this.video.id) {
      return undefined;
    }
    return Map({[WidgetMediaType.VIDEOS]: Set([this.video.id])}) as MediaMeta;
  }
}

decorate(XVideoRecord, {
  video: property(),
  posterId: property(),
  videoOptions: property()
});
record()(XVideoRecord);
export default XVideoRecord;
