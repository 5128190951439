import React, {useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';

import {useDebounceCallback} from 'helpers/debounce';
import {type Role, type SelectionData} from 'store/interface';

import {clearPointerSelection} from '../../../common/action';
import {SlatePointerFacade} from './SlatePointerFacade';
import {type Subscriber} from './interface';

interface ContextProps {
  role?: Role;
  selectionData?: SelectionData;
  subscribe: <E = unknown>(id: string, editor: E, isAvailable: () => boolean) => Function;
  getSubscriber: () => Subscriber | null;
  highlightTeacherRange: () => () => void;
  highlightStudentRange: (id: string, data: SelectionData) => void;
}

export const SelectionContext = React.createContext<ContextProps>({
  subscribe: () => () => {},
  highlightTeacherRange: () => () => {},
  highlightStudentRange: () => {},
  getSubscriber: () => null
});

interface Props {
  role: Role;
  active: boolean;
  selectionData?: SelectionData;
}

export const SelectionProvider: React.FC<Props> = ({children, role, active, selectionData}) => {
  const dispatch = useDispatch();

  const subscribers = useRef<Subscriber[]>([]);
  const clickNumber = useRef<number>(0);

  const subscriber = useRef<Subscriber | null>(null);

  const subscribe = useCallback((id: string, editor: unknown, isAvailable: () => boolean) => {
    const subscriber = {id, editor, isAvailable} as Subscriber;

    subscribers.current.push(subscriber);

    return () => {
      subscribers.current = subscribers.current.filter(s => s.id !== id);
    };
  }, []);

  const getSubscriber = useCallback(() => subscriber.current, []);

  const findSubscriber = useCallback(
    (id: string) => subscribers.current.find(s => s.id === id),
    []
  );

  const onMouseUp = useDebounceCallback(
    (e: MouseEvent) => {
      const selection = document.getSelection();

      if (e.detail < clickNumber.current) return;

      if (selection && !selection.isCollapsed) {
        const details = {isTripleClick: clickNumber.current === 3, target: e.target};
        subscriber.current = SlatePointerFacade.stopSelection(
          selection,
          subscribers.current,
          details
        );
      }
    },
    [],
    300
  );

  const onMouseDown = useCallback((e: MouseEvent) => {
    clickNumber.current = e.detail;

    const selection = document.getSelection();

    if (subscriber.current) {
      const {editor} = subscriber.current;

      SlatePointerFacade.startSelection(editor, e, selection);

      subscriber.current = null;
    }
  }, []);

  const highlightTeacherRange = useCallback(() => {
    const subscriberWithRange = subscriber.current;

    return () => {
      if (subscriberWithRange) {
        SlatePointerFacade.createForTeacher(subscriberWithRange);
      }
    };
  }, []);

  const highlightStudentRange = useCallback(
    (id: string, data: SelectionData) => {
      dispatch(clearPointerSelection());

      const subscriber = findSubscriber(id);

      if (subscriber) {
        SlatePointerFacade.createForStudent(subscriber, data);
      }
    },
    [dispatch, findSubscriber]
  );

  useEffect(() => {
    if (role === 'student' || !active) {
      const editor = subscriber.current?.editor;

      SlatePointerFacade.removeCreatePointer(editor);

      return;
    }

    document.addEventListener('mouseup', onMouseUp);
    document.addEventListener('mousedown', onMouseDown);

    return () => {
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousedown', onMouseDown);
    };
  }, [active, onMouseDown, onMouseUp, role]);

  const value = {
    role,
    selectionData,
    subscribe,
    getSubscriber,
    highlightTeacherRange,
    highlightStudentRange
  };

  return <SelectionContext.Provider value={value}>{children}</SelectionContext.Provider>;
};
