import {type Action} from 'redux';
import {type AxiosResponse} from 'axios';
import {captureMessage} from '@sentry/react';

import {type DocLibraryState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  FILE_SELECTED,
  type LibraryCollapsedState,
  type LibraryNode,
  MODAL_CLOSED,
  MODAL_OPENED,
  PREVIEW_LIBRARY_PDF,
  type PreviewLibraryPDFAction,
  REQUEST_ADD,
  REQUEST_ADD_FAIL,
  REQUEST_ADD_SUCCESS,
  STORE_SCROLL_POSITION,
  type StoreScrollTopPositionAction,
  TREE_COLLAPSE_ALL,
  TREE_COLLAPSE_NODE,
  TREE_REQUEST,
  TREE_REQUEST_FAIL,
  TREE_REQUEST_SUCCESS
} from './interface';

type LibraryResponseAction = Action & {payload: AxiosResponse};

function transformLibrary(
  response: LibraryNode,
  collapsed: LibraryCollapsedState,
  prevKey: string = ''
): LibraryNode {
  const defaultCollapsed = true;

  return Object.keys(response).map((dir: string) => {
    const node = response[dir];
    if (node.hasOwnProperty('id') && node.hasOwnProperty('title')) {
      const key = prevKey ? `${prevKey}-${node.id}` : node.id;
      collapsed[key] = defaultCollapsed;

      return node;
    }

    const key = prevKey ? `${prevKey}-${dir.toLowerCase()}` : dir.toLowerCase();
    collapsed[key] = defaultCollapsed;

    return transformLibrary(response[dir], collapsed, key);
  });
}

const actionHandlers: ActionHandlersList<DocLibraryState, Action> = {
  [MODAL_OPENED]: (state: DocLibraryState) => {
    const {library, librarySelected, libraryCollapsed, libraryError, scrollTopPosition, ...rest} =
      state;
    return {
      ...rest,
      libraryModalOpened: true
    };
  },
  [MODAL_CLOSED]: (state: DocLibraryState) => {
    const {library, librarySelected, libraryCollapsed, libraryError, ...rest} = state;
    return {
      ...rest,
      libraryModalOpened: false
    };
  },
  [TREE_REQUEST]: (state: DocLibraryState) => {
    const {libraryError, ...rest} = state;
    return {
      ...rest
    };
  },
  [TREE_REQUEST_FAIL]: (state: DocLibraryState) => {
    return {
      ...state,
      libraryError: true
    };
  },
  [TREE_REQUEST_SUCCESS]: (state: DocLibraryState, action: LibraryResponseAction) => {
    const response: LibraryNode | LibraryNode[] = action.payload.data;
    if ((response as LibraryNode[]).length === 0) {
      captureMessage('Library tree request: empty list received.', 'warning');
      return {
        ...state,
        libraryError: true
      };
    }
    const collapsed = state.libraryCollapsed ? state.libraryCollapsed : {};

    transformLibrary(response as LibraryNode, collapsed);

    return {
      ...state,
      library: response as LibraryNode,
      librarySelected: {},
      libraryCollapsed: collapsed
    };
  },
  [TREE_COLLAPSE_NODE]: (state: DocLibraryState, action: Action & {key: string}) => {
    const key = action.key;

    const {libraryCollapsed} = state;
    if (libraryCollapsed && libraryCollapsed[key] !== undefined) {
      libraryCollapsed[key] = !libraryCollapsed[key];
    }
    return {
      ...state,
      libraryCollapsed: {...libraryCollapsed}
    };
  },
  [TREE_COLLAPSE_ALL]: (state: DocLibraryState) => {
    const {libraryCollapsed, ...rest} = state;
    if (libraryCollapsed) {
      for (const i in libraryCollapsed) {
        libraryCollapsed[i] = true;
      }
    }

    return {
      ...rest,
      libraryCollapsed: {...libraryCollapsed}
    };
  },
  [FILE_SELECTED]: (state: DocLibraryState, action: Action & {id: string; audios: number}) => {
    const {librarySelected, ...rest} = state;

    if (librarySelected![action.id] !== undefined) {
      delete librarySelected![action.id];
    } else {
      librarySelected![action.id] = action.audios;
    }

    return {
      ...rest,
      librarySelected: {...librarySelected}
    };
  },
  [REQUEST_ADD]: (state: DocLibraryState, action: Action & {id: string}) => {
    const {librarySubmitting, ...rest} = state;

    return {
      ...rest,
      librarySubmitting: true
    };
  },
  [REQUEST_ADD_SUCCESS]: (state: DocLibraryState) => {
    const {librarySubmitting, ...rest} = state;

    return {
      ...rest,
      libraryModalOpened: false,
      librarySubmitting: false
    };
  },
  [REQUEST_ADD_FAIL]: (state: DocLibraryState) => {
    const {librarySubmitting, ...rest} = state;

    return {
      ...rest
    };
  },
  [PREVIEW_LIBRARY_PDF]: (state: DocLibraryState, action: PreviewLibraryPDFAction) => ({
    ...state,
    previewingPDF: action.item || undefined
  }),
  [STORE_SCROLL_POSITION]: (
    state: DocLibraryState,
    {scrollTopPosition}: StoreScrollTopPositionAction
  ) => ({
    ...state,
    scrollTopPosition
  })
};

const initialLibState: DocLibraryState = {};

export default function (
  state: DocLibraryState = initialLibState,
  action: Action
): DocLibraryState {
  const docsReducer = actionHandlers[action.type];
  return docsReducer ? docsReducer(state, action) : state;
}
