import {SlateHotkey} from '../../utils/hotkeys';
import {type MarkDef} from '../../definitions';

export const bold: Readonly<MarkDef> = {
  mark: 'bold',
  value: true,
  shortcut: SlateHotkey.Bold,
  attrs: {
    className: 'sf-b'
    // style: {fontWeight: 'bold'}
  }
};

export const italic: Readonly<MarkDef> = {
  mark: 'italic',
  value: true,
  shortcut: SlateHotkey.Italic,
  attrs: {
    className: 'sf-i'
    // style: {fontStyle: 'italic'}
  }
};
export const underline: Readonly<MarkDef> = {
  mark: 'underline',
  value: true,
  shortcut: SlateHotkey.Underline,
  attrs: {
    className: 'sf-u'
    // style: {textDecorationLine: 'underline'}
  }
};

export const strikeThrough: Readonly<MarkDef> = {
  mark: 'strikeThrough',
  value: true,
  shortcut: SlateHotkey.StrikeThrough,
  attrs: {
    className: 'sf-s'
    // style: {textDecorationLine: 'line-through'}
  }
};

export const textFormats: Readonly<MarkDef>[] = [bold, italic, underline, strikeThrough];
