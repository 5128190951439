import {type AxiosRequestAction} from 'services/axios/interface';

const REQUEST_UNIT_INSTANCE_CONTENTS = 'REQUEST_UNIT_INSTANCE_CONTENTS';

export const requestContents = (
  url: string,
  params: {forStudent?: boolean} = {}
): AxiosRequestAction => ({
  type: REQUEST_UNIT_INSTANCE_CONTENTS,
  payload: {
    client: 'v2',
    request: {method: 'get', url, params}
  }
});
