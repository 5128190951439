import React from 'react';
import {Portal} from 'react-portal';

interface Props {
  shouldPortal?: boolean;
}

export default class RTCPortal extends React.Component<Props, {}> {
  public render() {
    if (this.props.shouldPortal) {
      return <Portal>{this.props.children}</Portal>;
    }
    return this.props.children;
  }
}
