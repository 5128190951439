import {type FC} from 'react';
import type React from 'react';

import {useRedux} from '../hooks/useRedux';
import {type ProviderProps} from './types';

interface Props {
  children: (props: ProviderProps) => React.ReactElement;
}

export const ReduxProvider: FC<Props> = ({children}) => {
  const reduxProps = useRedux();

  return children(reduxProps);
};
