import React, {type FC, useState} from 'react';
import classNames from 'classnames';
import {useFocused} from '@englex/paint-react';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import {isMobileWebView} from 'helpers/browser';

import {MobileToolbarHeader, MobileToolbarControls} from './components';
import './MobileToolbar.scss';
import {BootstrapBreakpoint} from '../../../../config/static';

interface Props {
  empty?: boolean;
  breakpoint?: number;
}

export const MobileToolbar: FC<Props> = ({
  children,
  empty,
  breakpoint = BootstrapBreakpoint.MD
}) => {
  const isFocused = useFocused();

  const [controlsIsOpen, setIsOpen] = useState(false);

  const isCollapsible = useMediaQuery(breakpoint);

  const toggleIsOpen = () => setIsOpen(prevState => !prevState);

  const webview = isMobileWebView();

  return (
    <div
      className={classNames('painter-toolbar painter-mobile-toolbar', {
        webview,
        focused: isFocused
      })}
    >
      {empty ? (
        children
      ) : (
        <>
          {isCollapsible && (
            <MobileToolbarHeader controlsIsOpen={controlsIsOpen} toggleIsOpen={toggleIsOpen} />
          )}
          <MobileToolbarControls isOpen={controlsIsOpen} isCollapsible={isCollapsible} />
        </>
      )}
    </div>
  );
};
