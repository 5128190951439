import {type Action} from 'redux';

import {CLOSE_MODAL, SET_LIST_TO_DELETE, SET_LIST_TO_EDIT, SHOW_MODAL} from '../actionTypes';
import {type EditListState} from './interface';
import {type EditListAction} from './actions';

export const initialState = {show: false};

const handlers = {
  [SET_LIST_TO_DELETE]: (state: EditListState, {list}: EditListAction): EditListState => ({
    listToDelete: list,
    show: !!list
  }),
  [SET_LIST_TO_EDIT]: (state: EditListState, {list}: EditListAction): EditListState => ({
    editedList: list,
    show: !!list
  }),
  [SHOW_MODAL]: (): EditListState => ({show: true}),
  [CLOSE_MODAL]: (): EditListState => initialState
};

export const reducer = (state: EditListState, action: Action): EditListState => {
  return handlers[action.type]?.(state, action) || state;
};
