import {
  stripWordArticle,
  stripWordVerbParticle,
  splitWordByBraces,
  stripWordCurlyBracketsWithContent
} from '@englex/trainer';

import {type ExerciseRecordMap, WidgetType} from 'store/exercise/player/interface';
import {type VocabularyProperties} from 'store/exercise/player/widgets/Vocabulary/interface';
import type VocabularyWordRecord from 'store/exercise/player/widgets/Vocabulary/VocabularyWordRecord';

import {PartOfSpeech} from './interface';

interface RequestPayload {
  partOfSpeech?: PartOfSpeech;
  word: string;
}

export const originalToRequestPayload = (original: string): RequestPayload => {
  const word = stripWordCurlyBracketsWithContent(
    splitWordByBraces(original).textBeforeBraces.trim()
  );

  const maybeNoun = stripWordArticle(word);
  if (maybeNoun !== word) return {word: maybeNoun, partOfSpeech: PartOfSpeech.NOUN};

  const maybeVerb = stripWordVerbParticle(word);
  if (maybeVerb !== word) return {word: maybeVerb, partOfSpeech: PartOfSpeech.VERB};

  return {word: word};
};

export const bottomSlideOutTransition = (duration: number, bottom: number) => ({
  timeout: {
    enter: 0,
    exit: duration
  },
  defaultStyle: {
    transition: `bottom ${duration}ms ease`,
    bottom: `-${bottom}px`
  },
  transitionStyles: {
    entering: {bottom: `-${bottom}px`},
    entered: {bottom: 0},
    exiting: {bottom: `-${bottom}px`},
    exited: {bottom: `-${bottom}px`}
  }
});

export const getExerciseWordIds = (
  exercises: ExerciseRecordMap,
  exerciseId: string
): {[widgetId: string]: string[]} => {
  const exercise = exercises.find(e => e?.id === exerciseId);
  return !exercise
    ? {}
    : exercise.widgets
        .filter(w => w?.type === WidgetType.VOCABULARY && !w.displayButton)
        .reduce((a: {[widgetId: string]: string[]}, w: VocabularyProperties) => {
          const widgetWords = w.vocabulary.reduce((a: string[], v) => {
            const wordId = (v as VocabularyWordRecord).wordId;
            return wordId ? [...a, wordId] : a;
          }, []);
          return {...a, [w.id]: widgetWords};
        }, {});
};
