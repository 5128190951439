import {useCallback, useEffect, useMemo, useState} from 'react';

import {useApiRequest} from 'hooks/rest/useApiRequest';
import {type DocumentFileV2} from 'routes/ClassRoom/components/FilesWorkSpace/documentsTab/actions/interface';

import {loadDocumentInstance} from '../actions';

export function useDocumentInstance(documentInstanceId: number) {
  const [documentInstance, setDocumentInstance] = useState<DocumentFileV2 | undefined>();

  const action = useMemo(() => loadDocumentInstance(documentInstanceId), [documentInstanceId]);

  const successHandler = useCallback((file: DocumentFileV2) => {
    setDocumentInstance(file);
  }, []);

  useEffect(
    () => () => {
      setDocumentInstance(undefined);
    },
    [documentInstanceId]
  );

  const {isLoading, isError, reload} = useApiRequest(action, successHandler);

  return {documentInstance, isLoading, isError, reload};
}
