import React from 'react';
import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';
import {FormattedMessage} from 'react-intl';

import {requestPermissionActionCreator} from 'services/browser-notifications/requestPermission';

import {
  type WarningToastDispatchProps,
  type WarningToastOwnProps,
  WarningToastrWithButton,
  type WarningToastStateProps
} from './WarningToastrWithButton';

const mapStateToProps = () => ({
  buttonText: <FormattedMessage id="Notification.PermissionToastButton" />
});

const mapDispatchToProps = (dispatch: Dispatch<Action>, ownProps: WarningToastOwnProps) => ({
  handleClick: () => {
    ownProps.remove();
    dispatch(requestPermissionActionCreator());
  }
});

export default connect<WarningToastStateProps, WarningToastDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(WarningToastrWithButton);
