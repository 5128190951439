import {type Block, type Editor, Value, type Inline} from '@englex/slate';
import {type IntlShape} from 'react-intl';
import * as yup from 'yup';
import {List, Map} from 'immutable';

import {valueFromText} from 'components/Slate/utils';
import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {
  type StyledListJSON,
  StyledListStyles
} from 'store/exercise/player/widgets/List/StyledList/interface';
import {type ImageBlock, SlateBlock, SlateInline} from 'components/Slate/interface';
import {exerciseExcerptLength} from 'config/static';

import XWidgetRecord from '../XWidgetRecord';
import {type XStyledListProperties, type XStyledListProps} from './interface';
import validationMessages from '../i18n';
import {styledListInitialValue} from './styledListInitialValue';
import {contentExcerpt, taskExcerpt} from '../excerpt';

class XStyledListRecord extends XWidgetRecord implements XStyledListProperties {
  public declare readonly content: Value;

  public declare readonly styleName: StyledListStyles;

  constructor(raw: XStyledListProps) {
    super(raw);
    this.initValues({
      content: raw?.content ? Value.fromJSON(raw.content) : styledListInitialValue(),
      styleName: raw?.styleName
        ? raw.styleName
        : raw.type === WidgetType.HORIZONTAL_STYLED_LIST
          ? StyledListStyles.BLUE
          : StyledListStyles.BULLETED_BLUE
    });
  }

  public toJSON(options?: WidgetToJSONOptions): StyledListJSON {
    return {
      ...super.toJSON(options),
      content: this.content.toJSON(),
      styleName: this.styleName,
      values: []
    };
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'This widget must contain a list',
          intl.formatMessage(validationMessages.StyleListMustContainList),
          (v: Value) => {
            const numberOfLists = List(v.document.blocks()).filter(
              (item: [Block]) => item[0].get('type') === SlateBlock.LIST
            );

            return numberOfLists.size !== 0;
          }
        )

        .test(
          'List items cannot be blank',
          intl.formatMessage(validationMessages.StyleListItemsNonEmpty),
          (v: Value) => {
            const items = List(v.document.blocks())
              .filter((item: [Block]) => item[0].get('type') === SlateBlock.LIST_ITEM)
              .filter((item: [Block]) => {
                return (
                  item[0].text.trim() === '' &&
                  !(item[0].nodes.get(0) as Block).nodes.find(
                    (inline: Inline) => inline.type === SlateInline.ICON
                  )
                );
              });

            return items.size === 0;
          }
        )
    });
  }

  public changeContent(editor: Editor) {
    return this.set('content', editor.value);
  }

  public changeStyleName(styleName: string) {
    return this.set('styleName', styleName);
  }

  public get excerpt(): string {
    const task = taskExcerpt(this.task);
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = contentExcerpt(this.content, task.length);
    return `${task} ${text}`.trim();
  }

  public get type() {
    return WidgetType.HORIZONTAL_STYLED_LIST;
  }

  public get media(): MediaMeta | undefined {
    const imageBlocks = this.content.document.getBlocksByType(SlateBlock.IMAGE);
    if (imageBlocks.size) {
      const ids = imageBlocks.map((ib: ImageBlock) => ib.data.get('id')).toSet();
      return Map({[WidgetMediaType.IMAGES]: ids}) as MediaMeta;
    }
    return undefined;
  }
}

decorate(XStyledListRecord, {
  content: property(valueFromText()),
  styleName: property('')
});
record()(XStyledListRecord);
export default XStyledListRecord;
