import {type Value, type Block} from '@englex/slate';

import {exerciseExcerptLength} from 'config/static';

import {SlateBlock} from '../../../../components/Slate/interface';

export const contentExcerpt = ({document}: Value, alreadyGeneratedLength: number = 0): string => {
  let [text, cellIndex] = ['', 0];
  document.getBlocks().forEach((b: Block): void | false => {
    if (text.length > exerciseExcerptLength - alreadyGeneratedLength) {
      return false; // immutable forEach stops iteration if returns 'false'.
    }
    const cellIsEven = cellIndex % 2 === 0;
    const ancestors = document.getAncestors(b.key);
    const isInDialogCell =
      ancestors && ancestors.find((node: Block) => node.type === SlateBlock.DIALOG_CELL);

    if (isInDialogCell) {
      cellIndex++;
      text += cellIsEven ? `${b.text}:` : b.text;
    } else {
      text += b.text;
    }
    text.length && (text += ' ');
  });
  return text.trim().substring(0, exerciseExcerptLength - alreadyGeneratedLength);
};

export const taskExcerpt = ({document}: Value): string => document.text;
