import classNames from 'classnames';
import React, {memo} from 'react';

import './CrossLink.scss';

interface Props {
  id: string;
  text: string;
  isInvertColor?: boolean;
  onLinkClick: (id: string, word: string) => void;
}

export const CrossLink: React.FC<Props> = memo(({id, text, isInvertColor = false, onLinkClick}) => {
  const onClick = () => {
    onLinkClick(id, text);
  };

  return (
    <div className={classNames('cross-link', {'is-invert-color': isInvertColor})} onClick={onClick}>
      {text}
    </div>
  );
});
