import {META_KEY} from '../meta';

export function property(value?: {} | null): PropertyDecorator {
  return (target: object, propertyKey: string | symbol): void => {
    if (!target[META_KEY]) {
      target[META_KEY] = {};
    }

    target[META_KEY][propertyKey] = value;
  };
}
