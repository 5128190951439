import {DictionarySearchMode} from 'common/enums';

import {
  type EnglishLexicalEntryType,
  type IEntry,
  type Example,
  type Grammars,
  type GroupedPartsOfSpeechEnToRus,
  type LexicalEntryType,
  type SearchData,
  type Translation,
  type EnEntry,
  type Sense,
  type GroupedPartsOfSpeechEn,
  type EnglishSearchData
} from '../interfaces';
import {findGrammar, sortEntries} from './helpers';

const possiblePartsOfSpeech = [
  'noun',
  'adjective',
  'verb',
  'adverb',
  'article',
  'pronoun',
  'numeral',
  'conjunction',
  'preposition',
  'interjection',
  'particle',
  'prefix',
  'other',
  'residual',
  'combining form',
  'determiner',
  'idiomatic',
  'suffix'
];

export const combiningPartOfSpeech = (
  lexicalEntries?: LexicalEntryType[] | EnglishLexicalEntryType[]
): GroupedPartsOfSpeechEnToRus => {
  const newEntries = {};

  lexicalEntries?.forEach(entry => {
    const partOfSpeech = entry.lexicalCategory?.text.toLowerCase();
    const possiblePartOfSpeech = partOfSpeech && possiblePartsOfSpeech.includes(partOfSpeech);

    if (entry.entries && entry.entries.length && possiblePartOfSpeech) {
      if (newEntries[partOfSpeech]) {
        newEntries[partOfSpeech] = [...newEntries[partOfSpeech], ...entry.entries];
      } else {
        newEntries[partOfSpeech] = [...entry.entries];
      }
    }
  });

  const sortedEntries = sortEntries(newEntries);

  return sortedEntries;
};

const addEntryToAccumulatorEnToRus = (
  index: number,
  accumulator: IEntry[],
  translations: [Translation[], Translation[]],
  examples: [Example[], Example[]],
  grammaticalFeatures?: Grammars
) => {
  return accumulator.map((entry, entryIndex) => {
    if (entryIndex === index) {
      return {
        grammaticalFeatures,
        translations: [...translations[0], ...translations[1]],
        examples: [...examples[0], ...examples[1]]
      };
    } else {
      return entry;
    }
  });
};

const addEntryToAccumulatorEn = (
  index: number,
  accumulator: EnEntry[],
  senses: [Sense[], Sense[]],
  grammaticalFeatures?: Grammars
) => {
  return accumulator.map((entry, entryIndex) => {
    if (entryIndex === index) {
      return {
        grammaticalFeatures,
        senses: [...senses[0], ...senses[1]]
      };
    } else {
      return entry;
    }
  });
};

export const combiningGrammaticalFeaturesEnToRus = (
  data: GroupedPartsOfSpeechEnToRus
): GroupedPartsOfSpeechEnToRus => {
  const partOfSpeech = {};
  Object.keys(data).forEach(part => {
    const newArr = data[part].reduce((accumulator: IEntry[], item: IEntry) => {
      const grammars = item.grammaticalFeatures;
      if (grammars) {
        const index = findGrammar(grammars, accumulator);
        if (index !== -1) {
          const {translations = [], examples = [], grammaticalFeatures} = accumulator[index];
          const {translations: itemTranslations = [], examples: itemExamples = []} = item;

          return addEntryToAccumulatorEnToRus(
            index,
            accumulator,
            [translations, itemTranslations],
            [examples, itemExamples],
            grammaticalFeatures
          );
        } else {
          return [...accumulator, item];
        }
      } else {
        const index = accumulator.findIndex(item => !item.grammaticalFeatures);

        if (index !== -1) {
          const {translations = [], examples = []} = accumulator[index];
          const {translations: itemTranslations = [], examples: itemExamples = []} = item;

          return addEntryToAccumulatorEnToRus(
            index,
            accumulator,
            [translations, itemTranslations],
            [examples, itemExamples]
          );
        }
        return [...accumulator, item];
      }
    }, []);

    partOfSpeech[part] = newArr;
  });

  return partOfSpeech;
};

const combiningGrammaticalFeaturesEn = (data: GroupedPartsOfSpeechEn): GroupedPartsOfSpeechEn => {
  const partOfSpeech = {};
  Object.keys(data).forEach(part => {
    const newArr = data[part].reduce((accumulator: EnEntry[], item: EnEntry) => {
      const grammars = item.grammaticalFeatures;
      if (grammars) {
        const index = findGrammar(grammars, accumulator);
        if (index !== -1) {
          const {senses = [], grammaticalFeatures} = accumulator[index];
          const {senses: itemSenses = []} = item;

          return addEntryToAccumulatorEn(
            index,
            accumulator,
            [senses, itemSenses],
            grammaticalFeatures
          );
        } else {
          return [...accumulator, item];
        }
      } else {
        const index = accumulator.findIndex(item => !item.grammaticalFeatures);

        if (index !== -1) {
          const {senses = []} = accumulator[index];
          const {senses: itemSenses = []} = item;

          return addEntryToAccumulatorEn(index, accumulator, [senses, itemSenses]);
        }
        return [...accumulator, item];
      }
    }, []);

    partOfSpeech[part] = newArr;
  });

  return partOfSpeech;
};

const transformLexicalEntries = (lexicalEntries: GroupedPartsOfSpeechEnToRus) => {
  const newLexicalEntries: LexicalEntryType[] = [];

  for (const key in lexicalEntries) {
    lexicalEntries[key].forEach(entry => {
      newLexicalEntries.push({
        lexicalCategory: {text: key},
        entries: [entry]
      });
    });
  }

  return newLexicalEntries;
};

export const groupLexicalEntries = (
  data: SearchData,
  searchMode: DictionarySearchMode | EnglishSearchData
): SearchData | EnglishSearchData => {
  const groupedPartsOfSpeech = combiningPartOfSpeech(data.lexicalEntries);
  const groupedGrammaticalFeatures =
    searchMode === DictionarySearchMode.EN_TO_RUS
      ? combiningGrammaticalFeaturesEnToRus(groupedPartsOfSpeech)
      : combiningGrammaticalFeaturesEn(groupedPartsOfSpeech);
  const originalFormat = transformLexicalEntries(groupedGrammaticalFeatures);

  const newData = {...data, lexicalEntries: originalFormat};

  return newData;
};
