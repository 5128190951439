const prefix = 'UNIT_PREVIEW/';

export const INIT_UNIT_PREVIEW = prefix + 'INIT_UNIT_PREVIEW';
export const INIT_UNIT_PREVIEW_ADDITIONAL = prefix + 'INIT_UNIT_PREVIEW_ADDITIONAL';
export const CLEAN_UNIT_PREVIEW = prefix + 'CLEAN_UNIT_PREVIEW';
export const REQUEST_UNIT_PREVIEW = prefix + 'REQUEST_UNIT_PREVIEW';
export const REQUEST_UNIT_PREVIEW_PAGE = prefix + 'REQUEST_UNIT_PREVIEW_PAGE';

export const REQUEST_UNIT_PREVIEW_ADDITIONAL_EXERCISES =
  prefix + 'REQUEST_UNIT_PREVIEW_ADDITIONAL_EXERCISES';

export const SET_MAIN_EXERCISE_ID = prefix + 'SET_MAIN_EXERCISE_ID';
export const CLEAR_MAIN_EXERCISE_ID = prefix + 'CLEAR_MAIN_EXERCISE_ID';

export const SET_GRAMMAR_PREVIEW_OPEN = prefix + 'SET_GRAMMAR_PREVIEW_OPEN';
export const CLEAR_GRAMMAR_PREVIEW_OPEN = prefix + 'CLEAR_GRAMMAR_PREVIEW_OPEN';
