import {useMatch, useParams} from 'react-router-dom';

import {useRole} from 'hooks/user/useRole';

export const useIsTeacherDictionary = (): boolean => {
  const isTeacher = useRole() === 'teacher';
  const isDictionary = useMatch('dictionary/*');
  const {studentId} = useParams();

  const result = isTeacher && !!isDictionary && !studentId;

  return result;
};
