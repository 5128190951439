import {genKey} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {type WordPictureSetBaseCardJSON} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import {
  type XWordPictureSetBaseCardProperties,
  type XWordPictureSetBaseCardProps
} from './baseInterface';
import {CardSizeType} from './XPictureSet/interface';

const Record = recordBase()(BaseRecord);

abstract class XWordPictureSetBaseCardRecord
  extends Record
  implements XWordPictureSetBaseCardProperties
{
  public declare readonly id: string;

  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: XWordPictureSetBaseCardProps) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      cardSizeType: raw.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toJSON(): WordPictureSetBaseCardJSON {
    return {
      id: this.id,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(XWordPictureSetBaseCardRecord, {
  id: property(genKey()),
  cardSizeType: property()
} as any); // eslint-disable-line @typescript-eslint/no-explicit-any
record()(XWordPictureSetBaseCardRecord);
export default XWordPictureSetBaseCardRecord;
