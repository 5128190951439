import {type RendererPlugin} from '../../interface';
import FontSizeDefault from './FontSizeDefault';
import FontSizeLargest from './FontSizeLargest';
import FontSizeLarge from './FontSizeLarge';
import FontSizeSmall from './FontSizeSmall';
import FontSizeSmaller from './FontSizeSmaller';
import FontSizeSmallest from './FontSizeSmallest';

class FontSize implements RendererPlugin {
  public plugins = [
    new FontSizeLargest(),
    new FontSizeLarge(),
    new FontSizeDefault(),
    new FontSizeSmall(),
    new FontSizeSmaller(),
    new FontSizeSmallest()
  ];
}

export default FontSize;
