import {createTransform} from 'redux-persist';
import {Map} from 'immutable';

import {
  type UserWidgetInstanceValuesJSON,
  type UserWidgetInstanceValuesMap,
  type WidgetInstanceValuesMap,
  type XPlayerSync
} from './exercise/player/persistence/interface';

const transformXPlayerSync = createTransform<XPlayerSync, UserWidgetInstanceValuesJSON>(
  immutableToObject,
  objectToImmutable
);

export default transformXPlayerSync;

function immutableToObject(
  state: UserWidgetInstanceValuesJSON | XPlayerSync,
  key: string
): UserWidgetInstanceValuesJSON {
  if (key !== 'sync') {
    return state as UserWidgetInstanceValuesJSON;
  }
  const sync = state as XPlayerSync;
  const userId = sync.userId;
  const otherUserWidgetValues = sync.otherUserWidgetValues;
  const mergeWidgetValues = sync.processingWidgetValues.merge(sync.widgetValues);
  const userWidgetValues: UserWidgetInstanceValuesMap = userId
    ? Map([[userId, mergeWidgetValues]])
    : Map();
  return otherUserWidgetValues
    .merge(userWidgetValues)
    .filter((v: WidgetInstanceValuesMap) => v.size > 0)
    .toJS();
}

function objectToImmutable(
  state: UserWidgetInstanceValuesJSON | XPlayerSync,
  key: string
): XPlayerSync {
  if (key !== 'sync') {
    return state as XPlayerSync;
  }

  const sync = state as UserWidgetInstanceValuesJSON;
  const userKeys = Object.keys(sync).filter(key => key);
  return {
    widgetValues: Map(),
    processingWidgetValues: Map(),
    otherUserWidgetValues: userKeys.length
      ? Map(userKeys.map(userId => [Number(userId), Map(sync[userId])]))
      : Map()
  } as XPlayerSync;
}
