import React, {type FC, memo, useCallback, useContext} from 'react';
import {useIntl} from 'react-intl';

import Confirm from 'components/modals/Confirm';
import * as toastr from 'components/toastr';

import {
  EditEntryActionsContext,
  EditEntryStateContext
} from '../shared/contexts/entry/EditEntryContext';
import {useDeleteEntryFromDictionary} from '../hooks/useDeleteEntryFromDictionary';
import {clearDeleteEntry} from '../shared/contexts/entry/actions';

export const DeleteEntryModal: FC = memo(() => {
  const intl = useIntl();

  const {entryToDeleteId} = useContext(EditEntryStateContext);
  const {dispatch} = useContext(EditEntryActionsContext);
  const deleteClear = useCallback(() => dispatch(clearDeleteEntry()), [dispatch]);

  const [submitting, deleteEntryFromDictionary] = useDeleteEntryFromDictionary(
    () => {
      toastr.success(
        '',
        intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionarySuccess'})
      );
      deleteClear();
    },
    () =>
      toastr.error(
        '',
        intl.formatMessage({id: 'Dictionary.Toastr.EntryDeletedFromDictionaryError'})
      ),
    entryToDeleteId
  );

  return (
    <Confirm
      headerText={intl.formatMessage({id: 'Dictionary.DeleteEntryFromDictionaryModalHeader'})}
      disableButtons={submitting}
      onAccept={deleteEntryFromDictionary}
      onDecline={deleteClear}
      show={!!entryToDeleteId}
    />
  );
});
