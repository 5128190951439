import React, {useCallback, useState} from 'react';
import {withPaint, ToolsPaint} from '@englex/paint-react';

import {ThicknessPresetPaint} from 'components/Paint/Painter/plugins';
import Icon from 'components/Icon';

import {ToolbarButton} from '../../../button';
import {MobileThicknessModal} from './MobileThicknessModal';
import {useThickness} from '../../../hooks';

export const MobileThicknessButton = withPaint(
  () => {
    const {active, isDisabled} = useThickness();

    const [shoModal, setShowModal] = useState(false);

    const openModal = useCallback(() => {
      if (isDisabled) return;

      setShowModal(true);
    }, [isDisabled]);

    const closeModal = useCallback(() => setShowModal(false), []);

    return (
      <>
        <ToolbarButton disabled={isDisabled} onClick={openModal}>
          <Icon name="virc-thickness" />
        </ToolbarButton>

        <MobileThicknessModal show={shoModal} active={active} onHide={closeModal} />
      </>
    );
  },
  p => ToolsPaint.is(p) && ThicknessPresetPaint.is(p)
);
