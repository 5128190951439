import {FAIL_POSTFIX, SUCCESS_POSTFIX} from 'services/wamp/actions/types';

export const CHAT_PATH: string = 'CHAT/';
export const CHANGE_ROOMS_FILTER: string = CHAT_PATH + 'CHANGE_ROOMS_FILTER';
export const GET_PRIVATE_ROOMS_LIST: string = CHAT_PATH + 'GET_PRIVATE_ROOMS_LIST'; // only ids
export const GET_PRIVATE_ROOMS_LIST_SUCCESS: string = GET_PRIVATE_ROOMS_LIST + SUCCESS_POSTFIX;
export const GET_PRIVATE_ROOMS_LIST_FAIL: string = GET_PRIVATE_ROOMS_LIST + FAIL_POSTFIX;
export const GET_PRIVATE_ROOMS: string = CHAT_PATH + 'GET_PRIVATE_ROOMS'; // full rooms list with all fields
export const GET_PRIVATE_ROOMS_SUCCESS: string = GET_PRIVATE_ROOMS + SUCCESS_POSTFIX;
export const GET_PRIVATE_ROOMS_FAIL: string = GET_PRIVATE_ROOMS + FAIL_POSTFIX;
export const GET_ROOM_MESSAGES: string = CHAT_PATH + 'GET_ROOM_MESSSAGES';
export const GET_ROOM_MESSAGES_SUCCESS: string = GET_ROOM_MESSAGES + SUCCESS_POSTFIX;
export const GET_ROOM_MESSAGES_FAIL: string = GET_ROOM_MESSAGES + FAIL_POSTFIX;
export const SELECT_ROOM: string = CHAT_PATH + 'SELECT_ROOM';
export const CHANGE_ACTIVE_TAB: string = CHAT_PATH + 'CHANGE_ACTIVE_TAB';
export const MESSAGE_RECEIVED: string = CHAT_PATH + 'MESSAGE_RECEIVED';
export const MESSAGE_READ: string = CHAT_PATH + 'MESSAGE_READ';
export const MESSAGE_READ_FAIL: string = MESSAGE_READ + FAIL_POSTFIX;
export const MESSAGE_SEND: string = CHAT_PATH + 'MESSAGE_SEND';
export const MESSAGE_SEND_SUCCESS: string = MESSAGE_SEND + SUCCESS_POSTFIX;
export const MESSAGE_SEND_FAIL: string = MESSAGE_SEND + FAIL_POSTFIX;
export const MESSAGE_UPDATE: string = CHAT_PATH + 'MESSAGE_UPDATE';
export const MESSAGE_UPDATE_SUCCESS: string = CHAT_PATH + 'MESSAGE_UPDATE_SUCCESS';
export const MESSAGE_UPDATE_FAIL: string = CHAT_PATH + 'MESSAGE_UPDATE_FAIL';
export const UPDATED_MESSAGE_RECEIVED: string = CHAT_PATH + 'UPDATED_MESSAGE_RECEIVED';
export const MESSAGE_DELETE: string = CHAT_PATH + 'MESSAGE_DELETE';
export const MESSAGE_DELETE_SUCCESS: string = CHAT_PATH + 'MESSAGE_DELETE_SUCCESS';
export const MESSAGE_DELETE_FAIL: string = CHAT_PATH + 'MESSAGE_DELETE_FAIL';
export const DELETED_MESSAGE_RECEIVED: string = CHAT_PATH + 'DELETED_MESSAGE_RECEIVED';
export const SUBSCRIBE_TO_ROOM: string = CHAT_PATH + 'SUBSCRIBE_TO_ROOM';
export const SUBSCRIBE_TO_ROOM_SUCCESS: string = SUBSCRIBE_TO_ROOM + SUCCESS_POSTFIX;
export const SUBSCRIBE_TO_ROOM_FAIL: string = SUBSCRIBE_TO_ROOM + FAIL_POSTFIX;
export const CHANGE_USER_SESSIONS: string = CHAT_PATH + 'CHANGE_USER_SESSIONS';
export const UNSUBSCRIBE_TO_ROOM: string = CHAT_PATH + 'UNSUBSCRIBE_TO_ROOM';
export const CLEAR_CHAT: string = CHAT_PATH + 'CLEAR_CHAT';
export const TOGGLE_ROOMS_POPOVER: string = 'TOGGLE_ROOMS_POPOVER';
export const MUTE_REMOTE_STREAM: string = CHAT_PATH + 'MUTE_REMOTE_STREAM';
export const TYPING_STARTED: string = CHAT_PATH + 'TYPING_STARTED';
export const TYPING_STOPPED: string = CHAT_PATH + 'TYPING_STOPPED';
export const RECIPIENT_TYPING_STARTED: string = CHAT_PATH + 'RECIPIENT_TYPING_STARTED';
export const RECIPIENT_TYPING_STOPPED: string = CHAT_PATH + 'RECIPIENT_TYPING_STOPPED';
export const PUBLISH_TYPING_STARTED: string = CHAT_PATH + 'PUBLISH_TYPING_STARTED';
export const PROMOTE_MESSAGE_TO_UPDATE: string = CHAT_PATH + 'PROMOTE_MESSAGE_TO_UPDATE';
export const CLEAR_MESSAGE_TO_UPDATE: string = CHAT_PATH + 'CLEAR_MESSAGE_TO_UPDATE';
export const CLEAR_ROOM_MESSAGES_EXEPT_SELECTED: string =
  CHAT_PATH + 'CLEAR_ROOM_MESSAGES_EXEPT_SELECTED';
export const SET_NOTIFICATION_TO_FALSE: string = CHAT_PATH + 'SET_NOTIFICATION_TO_FALSE';
export const TOGGLE_SCROLLED_TO_BOTTOM: string = CHAT_PATH + 'TOGGLE_SCROLLED_TO_BOTTOM';
export const SET_CHAT_CLIPBOARD: string = CHAT_PATH + 'SET_CHAT_CLIPBOARD';
export const CLEAR_CHAT_CLIPBOARD: string = CHAT_PATH + 'CLEAR_CHAT_CLIPBOARD';
export const TOGGLE_START_PHRASES: string = CHAT_PATH + 'TOGGLE_START_PHRASES';
export const HIDE_START_PHRASES: string = CHAT_PATH + 'HIDE_START_PHRASES';
