import {normalizeOrientation, Paint, type PNode} from '@englex/paint';
import {ReactPaint} from '@englex/paint-react';

import {type PaintImage, type PaintImageData} from './interface';

export interface ImagePaint extends Paint, PaintImage {}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ImagePaint = {
  insertImage(paint: Paint, imageData: PaintImageData): void {
    const stage = ReactPaint.toStage(paint);
    if (!stage) return;

    const {height, url, width} = imageData;
    const scaledStageWidth = stage.width();
    const scaledStageHeight = stage.height();

    const node: PNode = {
      className: 'Image',
      attrs: {width, height, url}
    };
    if (paint.orientation) {
      // insert as is, w/o considering paint orientation
      node.attrs.rotation = normalizeOrientation(-paint.orientation);
    }

    const stageWidth = scaledStageWidth / stage.scaleX();
    const stageHeight = scaledStageHeight / stage.scaleY();

    let imgScale = 1;
    if (width > stageWidth || height > stageHeight) {
      // image is larger than Stage, scaling it
      imgScale = Math.min(stageWidth / width, stageHeight / height);
      node.attrs.scaleX = imgScale;
      node.attrs.scaleY = imgScale;
    }
    const transform = stage.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();
    // now we find point relative to the center of the visible stage
    const {x, y} = transform.point({
      x: scaledStageWidth / 2 - (width / 2) * imgScale * stage.scaleX(),
      y: scaledStageHeight / 2 - (height / 2) * imgScale * stage.scaleY()
    });

    node.attrs.x = x;
    node.attrs.y = y;

    Paint.insertNode(paint, node);
  },
  isImagePaint(paint: Paint): paint is ImagePaint {
    const p = paint as ImagePaint;
    return !!p.setImageDropInitials && !!p.setShowImageModal;
  }
};
