import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import {xpreviewExerciseReducer} from 'store/exercise/player/Exercise/reducer';

import {type XPreviewRoleAction} from './actions';
import {CHANGE_ROLE} from './actionTypes';
import {type XPreviewState} from './interface';
import {createExercisesReducer} from '../exercisesReducer';

const REDUCERS: ReducersMapObject = {
  [CHANGE_ROLE]: (state: XPreviewState, action: XPreviewRoleAction): XPreviewState => {
    if (!state) {
      return state;
    }
    return state.set('role', action.role);
  }
};

const initialState = undefined;

const xpreviewExercisesReducer = createExercisesReducer(xpreviewExerciseReducer);
function xpreviewReducer(state: XPreviewState = initialState, action: Action): XPreviewState {
  const reducer: Reducer<XPreviewState> = REDUCERS[action.type];
  state = reducer ? reducer(state, action) : state;
  const exercises = xpreviewExercisesReducer(state?.exercises, action);
  return !state || state.exercises === exercises ? state : state.set('exercises', exercises);
}

export default xpreviewReducer;
