import {Connection} from 'autobahn';
import replaceableMiddleware from 'redux-replaceable-middleware';
import {type ModuleNamespace} from 'vite/types/hot';

import config from 'services/wamp/config';
import wampMiddleware, {type WampMiddleware} from 'services/wamp/middleware';

import {type ReplaceableMiddleware} from '../../../types/redux-replaceable-middleware/namespace';
import {type AppState} from '../interface';

const wampConnection: Connection = new Connection(config);

const clients = {
  wamp: {
    connection: wampConnection,
    config,
    currentCalls: []
  }
};

const wampReplaceableMiddleware: ReplaceableMiddleware = replaceableMiddleware(
  wampMiddleware(clients)
);

if (import.meta.hot) {
  import.meta.hot.accept('services/wamp/middleware', (module: ModuleNamespace) => {
    const newMiddleware: WampMiddleware<AppState> = module.default;
    wampReplaceableMiddleware.replaceBy(newMiddleware(clients));
  });
}
export default wampReplaceableMiddleware;
