import {type AutobahnClientName, defaultAutobahnClientName} from 'services/wamp/actions/types';
export const CONNECTION_OPENING = 'CONNECTION_OPENING';
export const CONNECTION_OPENED = 'CONNECTION_OPENED';
export const CONNECTION_RETRYING = 'CONNECTION_RETRYING';
export const CONNECTION_CLOSED = 'CONNECTION_CLOSED';
export const CONNECTION_CLOSE = 'CONNECTION_CLOSE';
export const ADD_SUBSCRIPTION = 'ADD_SUBSCRIPTION';
export const REMOVE_SUBSCRIPTION = 'REMOVE_SUBSCRIPTION';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const INITIALIZE_CLIENT = 'INITIALIZE_CLIENT';

type WampActionType =
  | 'CONNECTION_OPENING'
  | 'CONNECTION_OPENED'
  | 'CONNECTION_RETRYING'
  | 'CONNECTION_CLOSE'
  | 'CONNECTION_CLOSED'
  | 'ADD_SUBSCRIPTION'
  | 'REMOVE_SUBSCRIPTION'
  | 'UNSUBSCRIBE'
  | 'INITIALIZE_CLIENT';

export function wampClientActionType(
  actionType: WampActionType,
  client: AutobahnClientName = defaultAutobahnClientName
): string {
  return `${client.toUpperCase()}/${actionType}`;
}
