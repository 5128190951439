import {captureException, captureMessage, configureScope, init, withScope} from '@sentry/react';
import {SessionTiming} from '@sentry/integrations';

import {isMobileWebView} from 'helpers/browser';

import {sessionExpiredReason} from './static';

const playRequestWasInterruptedMessage =
  'The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22';

function isSessionExpiredRejection(e: PromiseRejectionEvent) {
  return isAxiosUnauthorizedResponse(e) || isAxiosSessionExpiredCancellation(e);
}

function isAxiosSessionExpiredCancellation(e: PromiseRejectionEvent) {
  return e.reason?.error?.data === sessionExpiredReason;
}

function isAxiosUnauthorizedResponse(e: PromiseRejectionEvent) {
  const baseURL = import.meta.env.REACT_APP_REST_API_URL;
  return (
    e.reason?.error?.response?.status === 401 && e.reason.error.response.config?.baseURL === baseURL
  );
}

function isNoConnectionError(e: PromiseRejectionEvent) {
  return (
    e.reason?.error?.data === 'Network Error' ||
    (e.reason?.error?.data === 'timeout of 0ms exceeded' && e.reason.error.status === 0)
  );
}

function logWarningMessage(e: PromiseRejectionEvent) {
  if (isNoConnectionError(e)) {
    if (e.preventDefault) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
    }
    return;
  }
  const message = typeof e.reason === 'object' ? JSON.stringify(e.reason, null, 2) : e.reason;
  withScope(scope => {
    scope.setExtra('scope', 'logUnhandledPromiseRejection() -> logWarningMessage()');
    captureMessage(message, 'warning');
  });
}

function logUnhandledPromiseRejection(e: PromiseRejectionEvent) {
  if (e.reason) {
    if (e.reason instanceof Error) {
      if (e.reason.message === playRequestWasInterruptedMessage) {
        return;
      }

      withScope(scope => {
        scope.setExtra('scope', 'logUnhandledPromiseRejection()');
        captureException(e.reason);
      });
    } else if (isSessionExpiredRejection(e)) {
      if (e.preventDefault) {
        e.preventDefault();
        e.stopPropagation();
        e.stopImmediatePropagation();
      }
    } else {
      logWarningMessage(e);
    }
  }
}

function getDefaultTags() {
  if (isMobileWebView()) {
    const {appVersion} = window.webViewData || {};

    return {
      git_hash: String(import.meta.env.REACT_APP_GIT_HASH),
      mobileAppVersion: appVersion || 'unknown',
      isMobileWebView: true
    };
  }

  return {git_hash: String(import.meta.env.REACT_APP_GIT_HASH)};
}

function configureSentry(enabled: boolean = import.meta.env.REACT_APP_SENTRY_ENABLED === 'true') {
  const dsn = import.meta.env.REACT_APP_SENTRY_PUBLIC_DSN;
  const release = import.meta.env.REACT_APP_RELEASE;
  const defaultTags = getDefaultTags();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).onunhandledrejection = logUnhandledPromiseRejection;

  init({
    dsn,
    enabled,
    release,
    integrations: [new SessionTiming()],
    // 'ignoreErrors' and 'denyUrls' below are mostly based on this guide page:
    // @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      /.*@webkit-masked-url.*/,
      /window\.cb\d+ is not a function/
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  });

  configureScope(scope => {
    scope.setTags(defaultTags);
  });
}

configureSentry();
