import {type IntlShape} from 'react-intl';
import * as yup from 'yup';
import {Map} from 'immutable';

import {type DataMap} from 'components/Slate/interface';
import {
  type WidgetJSON,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {record} from 'immutable-record/decorator/record';

import XWidgetRecord from '../XWidgetRecord';
import {type XEssayJSON} from './interface';

interface XEssayOptions {
  countWords?: true;
}

class XEssayRecord extends XWidgetRecord<WidgetJSON, DataMap<XEssayOptions>> {
  constructor(raw: XEssayJSON) {
    super(raw);
    this.initValues({
      options: Map(raw.options || {})
    });
  }

  public get type() {
    return WidgetType.ESSAY;
  }

  public get excerpt(): string {
    return `${this.task.document.text} [Essay]`.trim();
  }

  public toJSON(options?: WidgetToJSONOptions): XEssayJSON {
    return {
      ...super.toJSON(options),
      options: this.options.size
        ? {
            countWords: this.options.get('countWords')
          }
        : undefined
    };
  }

  public schema(intl: IntlShape) {
    return yup.object({});
  }
}

record()(XEssayRecord);
export default XEssayRecord;
