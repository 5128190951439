import {Value, type ValueJSON} from '@englex/slate';
import {List} from 'immutable';

import {BaseRecord} from 'immutable-record/Record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {genKey, valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';

import {PictureChoiceCardRecord} from './PictureChoiceCardRecord';
import {
  type PictureChoiceCardJSON,
  type PictureChoiceCardProperties,
  type PictureChoiceQuestionJSON,
  type PictureChoiceQuestionProperties,
  type PictureChoiceQuestionProps,
  type XPictureChoiceCardJSON
} from './interface';

const Record = recordBase()(BaseRecord);

export class PictureChoiceQuestionRecord extends Record implements PictureChoiceQuestionProperties {
  public declare readonly id: string;
  public declare readonly content?: Value;
  public declare readonly cards: List<PictureChoiceCardRecord>;

  constructor(raw: PictureChoiceQuestionProps) {
    super();
    this.initValues({
      id: raw.id,
      content: this.createContent(raw.content),
      cards: this.createCards(raw.cards)
    });
  }

  private createContent(content?: ValueJSON | Value) {
    if (!content) return;

    return Value.isValue(content) ? content : Value.fromJSON(content);
  }

  private createCards(cards: XPictureChoiceCardJSON[] | List<PictureChoiceCardProperties>) {
    if (List.isList(cards)) {
      return cards;
    }

    return List(
      (cards as PictureChoiceCardJSON[]).map((card: PictureChoiceCardJSON) => {
        return new PictureChoiceCardRecord({
          id: card.id,
          imageId: card.imageId,
          cardSizeType: card.cardSizeType
        });
      })
    );
  }

  public toJSON(): PictureChoiceQuestionJSON {
    return {
      id: this.id,
      content: this.content ? this.content.toJSON() : undefined,
      cards: this.cards.map((card: PictureChoiceCardRecord) => card.toJSON()).toArray()
    };
  }
}

decorate(PictureChoiceQuestionRecord, {
  id: property(genKey()),
  content: property(valueFromText()),
  cards: property(List())
});
record()(PictureChoiceQuestionRecord);
