import {connect} from 'react-redux';
import {type Action} from 'redux';
import {type Dispatch} from 'redux-axios-middleware';

import {type AppState, type Room, type RoomWithRecipient} from 'store/interface';
import {isUserStatusSupportsRtc} from 'helpers/user';

import Header, {type HeaderDispatchProps, type HeaderStateProps} from './Header';
import {callStarting} from '../../../../webRTC/action/action';
import {
  changeRoomsFilter,
  hideStartPhrases,
  roomsChangeActiveTab,
  toggleRoomsPopover
} from '../../actions/action';
import {toggleDevicesModal} from '../../../../routes/ClassRoom/components/MediaSettingsWizard/action';

const mapStateToProps = (state: AppState): HeaderStateProps => {
  const selectedRoom: Room | null = state.chat!.rooms![state.chat!.selectedRoomId!];
  return {
    micId:
      state.mediaDevices.selectedMicPresent && state.mediaDevices.micAccess
        ? state.mediaDevices.mic
        : undefined,
    videoCall: state.rtc && state.rtc.localStream && Boolean(state.rtc.localStream.video),
    shouldDisableCallButtons: Boolean(state.rtc.callStarting || state.rtc.badBrowser),
    selectedRoomId: state.chat!.selectedRoomId!,
    roomsPopoverOpen: state.chat!.roomsPopoverOpen,
    callInProgress: !!state.rtc.call,
    isAwaitingAnswer: state.rtc.outgoingCall || state.rtc.incomingCall,
    camId:
      state.mediaDevices.selectedCamPresent && state.mediaDevices.cameraAccess
        ? state.mediaDevices.cam
        : undefined,
    showRTCControls:
      !state.user.onCall && !state.rtc.call && selectedRoom && selectedRoom.recipient
        ? !selectedRoom.deleted_at &&
          isUserStatusSupportsRtc(selectedRoom.recipient.userStatus) &&
          state.rtc.badBrowser === false
        : false,
    otherSessionCall: state.rtc ? state.rtc.otherSessionCall : undefined,
    rooms: state.chat!.rooms as {[key: string]: RoomWithRecipient},
    callPartnerId: state.rtc.call && state.rtc.call.room_id,
    roomsPopoverFilter: state.chat!.roomsFilter,
    roomsPopoverTab: state.chat!.popoverOpenTabId,
    partnerIsTyping:
      selectedRoom && selectedRoom.recipient ? !!selectedRoom.recipient.typingTimerId : false
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action, AppState>): HeaderDispatchProps => ({
  callStarting: (roomId: number, video?: boolean) => dispatch(callStarting(roomId, video)),
  hideStartPhrases: (roomId: number) => dispatch(hideStartPhrases(roomId)),
  toggleRoomsPopover: (show: boolean) => dispatch(toggleRoomsPopover(show)),
  toggleDevicesModal: (show: boolean) => dispatch(toggleDevicesModal(show)),
  changeRoomsFilter: (filter: string) => dispatch(changeRoomsFilter(filter)),
  changeRoomsPopoverTab: (tabId: string) => dispatch(roomsChangeActiveTab(tabId))
});

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);

export default HeaderContainer;
