import React, {type FC} from 'react';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import {type IntlShape} from 'react-intl';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';

import {AspectRatio} from 'components/Crop/static';

import messages from '../i18n';
import {CropDropdown} from './CropDropdown';

const {aspectTitle, freeAspect, originalAspect} = messages;

const CropControls: FC<{
  aspectRatio: AspectRatio;
  intl: IntlShape;
  lockedAspectRatios: AspectRatio[];
  setAspectRatio: (aspectRatio: AspectRatio) => void;
  breakpoint?: number;
}> = ({aspectRatio, intl, lockedAspectRatios, setAspectRatio, breakpoint = 480}) => {
  const matches = useMediaQuery(breakpoint);

  const {FREE, HORIZONTAL_RECT, ORIGINAL, SQUARE, VERTICAL_RECT} = AspectRatio;
  const {formatMessage} = intl;

  if (matches) {
    return (
      <div className="crop-dropdown dropdown-container ">
        <CropDropdown
          intl={intl}
          aspectRatio={aspectRatio}
          lockedAspectRatios={lockedAspectRatios}
          setAspectRatio={setAspectRatio}
        />
      </div>
    );
  }

  return (
    <ToggleButtonGroup
      className="btn-group-sm"
      name="image-upload-btn-group"
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={setAspectRatio as any}
      type="radio"
      value={aspectRatio}
    >
      <ToggleButton value={ORIGINAL} title={formatMessage(aspectTitle)}>
        {formatMessage(originalAspect)}
      </ToggleButton>
      <ToggleButton value={SQUARE} title={formatMessage(aspectTitle)}>
        1:1
      </ToggleButton>
      <ToggleButton
        value={HORIZONTAL_RECT}
        title={formatMessage(aspectTitle)}
        disabled={lockedAspectRatios.includes(HORIZONTAL_RECT)}
      >
        4:3
      </ToggleButton>
      <ToggleButton
        value={VERTICAL_RECT}
        title={formatMessage(aspectTitle)}
        disabled={lockedAspectRatios.includes(VERTICAL_RECT)}
      >
        3:4
      </ToggleButton>
      <ToggleButton value={FREE} title={formatMessage(aspectTitle)}>
        {formatMessage(freeAspect)}
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default CropControls;
