import {type Action, type Dispatch} from 'redux';
import {type ThunkAction} from 'redux-thunk';
import moment from 'moment';
import {type ModuleNamespace} from 'vite/types/hot';

import {type AppState, type Locale} from 'store/interface';

import {RUSSIAN} from '../languages';
import {LOCALE_LOADING, UPDATE, HOT_UPDATE} from './actionTypes';
import {type LocaleLoadingAction, type IntlUpdateAction} from './interface';

type LocaleLoading = (loading: boolean) => LocaleLoadingAction;

type ChangeLocaleAction = ThunkAction<Promise<void>, AppState, undefined, Action>;

type ChangeLocaleCreator = (locale: Locale) => ChangeLocaleAction;

const localeLoading: LocaleLoading = loading => ({
  type: LOCALE_LOADING,
  loading
});

interface IntlUpdate {
  locale: Locale;
  messages: {};
}

const updateIntl = (payload: IntlUpdate): IntlUpdateAction => {
  return {
    type: UPDATE,
    payload
  };
};

const hotUpdateIntl = (payload: IntlUpdate): IntlUpdateAction => {
  return {
    type: HOT_UPDATE,
    payload
  };
};

export const changeLocale: ChangeLocaleCreator = (locale: Locale) => {
  return (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const messages = getState().intl.messages || {};
    if (Object.keys(messages).length && locale === getState().intl.locale) {
      return Promise.resolve();
    }
    return new Promise(resolve => {
      dispatch(localeLoading(true));
      switch (locale) {
        case RUSSIAN:
          import(/* webpackChunkName: "i18n-ru-RU" */ 'i18n/translations/ru-RU.json').then(
            ({default: def, ...msg}) => {
              if (import.meta.env.MODE === 'development' && import.meta.hot) {
                import.meta.hot.accept(
                  '../translations/ru-RU.json',
                  ({default: def, ...msg}: ModuleNamespace) => {
                    dispatch(
                      hotUpdateIntl({
                        locale: getState().intl.locale,
                        messages: {...msg, ...def}
                      })
                    );
                  }
                );
              }
              resolve({...msg, ...def});
            }
          );
          import(/* webpackChunkName: "moment-locale-ru" */ 'moment/locale/ru').then(() => {
            moment.locale('ru');
          });
          break;
        default:
          import(/* webpackChunkName: "i18n-en-US" */ '../translations/en-US.json').then(
            ({default: def, ...msg}) => {
              if (import.meta.env.MODE === 'development' && import.meta.hot) {
                import.meta.hot.accept(
                  '../translations/en-US.json',
                  ({default: def, ...msg}: ModuleNamespace) => {
                    dispatch(
                      hotUpdateIntl({
                        locale: getState().intl.locale,
                        messages: {...msg, ...def}
                      })
                    );
                  }
                );
              }
              resolve({...msg, ...def});
            }
          );
          break;
      }
    })
      .then((messages: {}) => {
        dispatch(
          updateIntl({
            locale,
            messages
          })
        );
        dispatch(localeLoading(false));
        return;
      })
      .catch(() => {
        dispatch(localeLoading(false));
        return;
      });
  };
};
