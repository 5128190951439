import {type AxiosAction} from 'redux-axios-middleware';

import {type UploadProgressHandler} from 'services/axios/interface';
import {CALL} from 'services/wamp/actions/types';

import {
  CREATE_THUMBNAIL,
  IMAGE_EXIST_REQUEST,
  POST_CROPPED_IMAGE,
  POST_IMAGE,
  POST_IMAGE_MESSAGE
} from './actionTypes';

export const imageExistRequest = (md5: string) =>
  ({
    type: IMAGE_EXIST_REQUEST,
    payload: {
      request: {
        method: 'get',
        url: 'v1/upload/image/exist',
        params: {
          md5,
          expand: 'thumbs'
        }
      }
    }
  }) as AxiosAction;

export const postImage = (data: FormData, onUploadProgress: UploadProgressHandler) =>
  ({
    type: POST_IMAGE,
    payload: {
      request: {
        url: 'v1/upload/image',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data,
        onUploadProgress
      }
    }
  }) as AxiosAction;

export const createCrop = (
  id: number,
  width: number,
  height: number,
  x: number,
  y: number
): AxiosAction => ({
  type: POST_CROPPED_IMAGE,
  payload: {
    request: {
      url: `v1/upload/image/${id}/crop/${width}/${height}/${x}/${y}`,
      method: 'put'
    }
  }
});

export const postCroppedImage = (
  data: FormData,
  width: number,
  height: number,
  x: number,
  y: number,
  onUploadProgress: UploadProgressHandler
): AxiosAction => ({
  type: POST_CROPPED_IMAGE,
  payload: {
    request: {
      url: `v1/upload/image/${width}/${height}/${x}/${y}`,
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      data,
      onUploadProgress
    }
  }
});

export const createThumbnail = (id: number, width: number = 188, height: number = 188) =>
  ({
    type: CREATE_THUMBNAIL,
    payload: {
      request: {
        method: 'put',
        url: `v1/upload/image/${id}/thumbnail/${width}/${height}`
      }
    }
  }) as AxiosAction;

export const postImageMessage = (roomId: number, thumbId: number) => ({
  type: POST_IMAGE_MESSAGE,
  wamp: {
    method: CALL,
    uri: `chatroom:_${roomId}.message.image`,
    args: [thumbId]
  }
});
