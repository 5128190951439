import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {genKey} from 'components/Slate/utils';
import {type FlipCardsCardJSON} from 'store/exercise/player/widgets/FlipCards/interface';

import {type FlipCardsCardProperties, type FlipCardsCardProps} from './interface';
import {CardSizeType} from '../XWordPictureSet/XPictureSet/interface';

const Record = recordBase()(BaseRecord);

class XFlipCardsCardRecord extends Record implements FlipCardsCardProperties {
  public declare readonly id: string;

  public declare readonly imageId: number;

  public declare readonly text: string;

  public declare readonly cardSizeType: CardSizeType;

  constructor(raw: Partial<FlipCardsCardProps> = {}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      imageId: raw.imageId,
      text: raw.text || '',
      cardSizeType: raw.cardSizeType || CardSizeType.SQUARE
    });
  }

  public toJSON(): FlipCardsCardJSON {
    return {
      id: this.id,
      imageId: this.imageId,
      text: this.text,
      cardSizeType: this.cardSizeType
    };
  }
}

decorate(XFlipCardsCardRecord, {
  id: property(genKey()),
  imageId: property(),
  text: property(),
  cardSizeType: property('')
});
record()(XFlipCardsCardRecord);
export default XFlipCardsCardRecord;
