import {type IntlShape} from 'react-intl';
import {type Block, Value, Editor} from '@englex/slate';
import {Map, List} from 'immutable';
import * as yup from 'yup';

import {record} from 'immutable-record/decorator/record';
import {exerciseExcerptLength} from 'config/static';
import {
  type CountWordsOption,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {type QuestsJSON} from 'store/exercise/player/widgets/Writing/interface';
import {type DataMap, SlateBlock} from 'components/Slate/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';

import {type XQuestsProperties} from './interface';
import {documentNotEmpty, minQuestionsAmount, questionsNotEmpty} from '../validation';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import validationMessages from '../i18n';
import {genKey, isBlockOfType, valueFromText} from '../../../../../components/Slate/utils';
import {toggleFirstLiIsExample} from '../../../../../components/Slate/SlateEditor/plugins/widget/QuestionsList/changes';

const contentToJSON = (content: Value) => {
  const change = new Editor({value: content});
  change.command(change => {
    const ids: string[] = [];
    const lis = content.document.filterDescendants((d: Block) => d.type === SlateBlock.LIST_ITEM);
    return lis.reduce((c: Editor, li: Block) => {
      const id = li.data.get('id');
      const isExample = li.data.get('example') || false;
      if ((!id || ids.includes(id)) && !isExample) {
        const newId = genKey();
        ids.push(newId);
        return c.setNodeByKey(li.key, {
          data: li.data.set('id', newId)
        });
      }
      ids.push(id);
      return c;
    }, change);
  });
  return change.value.toJSON();
};

class XQuestsRecord
  extends XFormattedTextRecord<DataMap<CountWordsOption>>
  implements XQuestsProperties
{
  public declare readonly questsExampleContent: Value;
  constructor(raw: QuestsJSON) {
    super(raw);
    this.initValues({
      questsExampleContent: raw.questsExampleContent
        ? Value.fromJSON(raw.questsExampleContent)
        : valueFromText(''),
      options: Map(raw.options || {})
    });
  }

  public get type() {
    return WidgetType.QUESTS;
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = this.content.document
      .filterDescendants(node => isBlockOfType(node, SlateBlock.LIST_ITEM))
      .map((b: Block, i: number) => `${i + 1}. ${b.text}`)
      .join(' ');
    return `${task} ${text}`.trim();
  }

  public toJSON(options?: WidgetToJSONOptions): QuestsJSON {
    return {
      ...super.toJSON(options),
      content: contentToJSON(this.content),
      questsExampleContent: this.containsExample ? this.questsExampleContent.toJSON() : undefined,
      options: this.options.size
        ? {
            countWords: this.options.get('countWords')
          }
        : undefined
    };
  }

  public toggleExample(): XQuestsRecord {
    const ch = new Editor({value: this.content});
    const newValue = ch.command(toggleFirstLiIsExample, this.containsExample).value;
    return this.set('content', newValue);
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ContentNonEmpty),
          (v: Value) => documentNotEmpty(v.document)
        )
        .test(
          'Question cannot be blank',
          intl.formatMessage(validationMessages.QuestionsNonEmpty),
          (v: Value) => questionsNotEmpty(v.document)
        )
        .test(
          'Should be at least two items if example',
          intl.formatMessage(validationMessages.NotOnlyExampleQuestion),
          (v: Value) => minQuestionsAmount(v.document)
        ),
      questsExampleContent: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ExampleAnswerNotEmpty),
          (v: Value) => {
            if (!this.containsExample) {
              return true;
            }
            return documentNotEmpty(v.document);
          }
        )
    });
  }

  public get containsExample(): boolean {
    return !!(this.content.document.getDescendant(List([0, 0])) as Block).data.get('example');
  }
}

decorate(XQuestsRecord, {
  questsExampleContent: property(valueFromText(''))
});

record()(XQuestsRecord);

export default XQuestsRecord;
