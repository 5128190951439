import {
  type Color,
  ColorsPaint,
  FontSizePaint,
  PaintTool,
  SelectablePaint,
  ToolsPaint
} from '@englex/paint-react';

import {enumContainsValue} from 'helpers/enum';

import {withLocalStorage} from './withLocalStorage';
import {type ThicknessName, ThicknessPresetPaint} from '../thicknessPreset';

export interface StoredState {
  tool?: PaintTool;
  color?: Color;
  recentColors?: Color[];
  thicknessName?: ThicknessName;
  fontSize?: number;
}

export const withStoredState = withLocalStorage<StoredState>({
  persist: p => {
    const state: StoredState = {
      tool: (ToolsPaint.is(p) && p.tools.active) || undefined,
      color: (ColorsPaint.is(p) && p.colors.active) || undefined,
      recentColors: (ColorsPaint.is(p) && p.colors.recent) || undefined,
      thicknessName: (ThicknessPresetPaint.is(p) && p.thickness.preset.active) || undefined,
      fontSize: (FontSizePaint.is(p) && p.fontSize.value) || undefined
    };
    return state;
  },
  hydrate: (p, data) => {
    if (ToolsPaint.is(p) && data.tool && enumContainsValue(PaintTool, data.tool)) {
      ToolsPaint.selectTool(p, data.tool);
      if (SelectablePaint.isEnabled(p)) {
        SelectablePaint.disable(p);
      }
    }

    if (ColorsPaint.is(p)) {
      if (data.color && ColorsPaint.isColor(data.color)) {
        ColorsPaint.setColor(p, data.color);
      }
      data.recentColors
        ?.filter(c => ColorsPaint.isColor(c))
        .reverse()
        .forEach(c => ColorsPaint.addRecent(p, c, {maxRecent: 16}));
    }

    if (ThicknessPresetPaint.is(p) && ThicknessPresetPaint.isThicknessName(p, data.thicknessName)) {
      ThicknessPresetPaint.setThicknessName(p, data.thicknessName);
    }

    if (data.fontSize) {
      FontSizePaint.setFontSize(p, data.fontSize);
    }
  }
});
