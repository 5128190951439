import {type CSSProperties} from 'react';
import produce from 'immer';

import {type CustomBlock, type FormatMarks} from './interface';
import {type Shortcut} from '../../helpers/shortcut';

export type SlateDef = BlockDef | MarkDef;

export type BlockType = CustomBlock['type'];

export type MarkType = keyof FormatMarks;

export interface BlockDef {
  block: BlockType;
  props?: Record<string, unknown>;
  shortcut?: Shortcut;
  attrs?: Readonly<FormatAttrs>;
}

export interface MarkDef {
  mark: MarkType;
  value: unknown;
  shortcut?: Shortcut;
  attrs: Readonly<FormatAttrs>;
}

export interface FormatAttrs {
  className?: string;
  style?: CSSProperties;
}

export interface SlateDefRegistry {
  marks?: MarkDef[];
  blocks?: BlockDef[];
  getBlockTypes(): ReadonlySet<BlockType>;
}

export const addBlockDef = produce((registry: SlateDefRegistry, ...blocks: BlockDef[]) => {
  if (!registry.blocks) {
    registry.blocks = [];
  }
  registry.blocks.push(...blocks);
  const types = new Set(registry?.blocks?.map(({block}) => block));
  registry.getBlockTypes = () => types;
});
