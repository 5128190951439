import React from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';

import {videoBaseHeight, videoBaseWidth} from 'config/static';
import {type Room, type UserStatus} from 'store/interface';

import {type ChangeVideoPositionCreator, type HangUpCreator} from '../action/interface';
import BadBrowser from './BadBrowser';
import {type SelectRoomCreator} from '../../components/Chat/actions/interface';
import {type LessonSidebarTab} from '../../components/LessonSidebar/interfaces';
import CallInProgress from '../containers/CallInProgress';
import OutgoingCall from './OutgoingCall';
import IncomingCall from './IncomingCall';
import {type ToggleElementCreator} from '../../common/interface';
import {userCompactName} from '../../helpers/user';
import ResizeTrigger from './ResizeTrigger';
import VideoControlPanel from './VideoControlPanel';
import RTCPortal from './RTCPortal';
import OutdatedBrowser from './OutdatedBrowser';
import {type ServerSideBrowserInfo} from '../interface';
import {getClientPosition, isMouseEvent} from './helpers';
import {handleError} from '../handleError';

import './rtc-component.scss';

export interface RTCComponentStateProps {
  partnerRoom?: Room;
  incomingCall?: boolean;
  outgoingCall?: boolean;
  callInProgress?: boolean;
  isBadBrowser?: boolean;
  browserInfo?: ServerSideBrowserInfo;
  callStarting?: boolean;
  localVideoEnabled?: boolean;
  localAudioEnabled?: boolean;
  answeringAwait?: boolean;
  chatSelectedRoom: number;
  sessionId: number;
  otherSessionCall?: boolean;
  partnerStatus?: UserStatus;
  camId?: string;
  micId?: string;
  videoFullScreen?: boolean;
  isUndocked?: boolean;
  positionX: number;
  positionY: number;
  width: number | string;
  height: number | string;
  transformingMode?: boolean;
  callId?: string;
  workspaceExpanded?: boolean;
  shouldHideBadBrowser?: boolean;
}

export interface DispatchRTCComponentProps {
  callStarted: (roomId: number, camId: string | null, micId: string | null) => Promise<{}>;
  hangUp: HangUpCreator;
  answerCall: (
    roomId: number,
    callId: string,
    camId: string | null,
    micId: string | null
  ) => Promise<{}>;
  switchChatRoom: SelectRoomCreator;
  toggleDevicesModal: ToggleElementCreator;
  toggleFullScreen: (show: boolean) => void;
  toggleUndocked: (show: boolean) => void;
  changeVideoPosition: ChangeVideoPositionCreator;
  resizeVideo: ChangeVideoPositionCreator;
  toggleTransformingMode: ToggleElementCreator;
  closeBadBrowser: () => void;
}

export interface RTCComponentOwnProps {
  selectedTab: LessonSidebarTab;
}

interface RTCComponentProps
  extends RTCComponentOwnProps,
    RTCComponentStateProps,
    DispatchRTCComponentProps {}

interface RTCComponentState {
  mouseDownX: number;
  mouseDownY: number;
  prevX: number;
  prevY: number;
  isResizing: boolean;
}

export class RTCComponent extends React.PureComponent<
  RTCComponentProps & WrappedComponentProps,
  RTCComponentState
> {
  public state: RTCComponentState = {
    mouseDownX: 0,
    mouseDownY: 0,
    prevX: 0,
    prevY: 0,
    isResizing: false
  };

  public componentDidUpdate(prevProps: RTCComponentProps) {
    if (!prevProps.callStarting && this.props.callStarting && this.props.partnerRoom) {
      this.createCall(Boolean(this.props.localVideoEnabled), this.props.partnerRoom);
      if (this.props.workspaceExpanded) {
        prevProps.toggleUndocked(true);
      }
    }
    if (
      this.props.outgoingCall &&
      prevProps.partnerStatus === 'oncall' &&
      this.props.partnerStatus === 'offline'
    ) {
      this.hangUpCall('Recipient gone offline');
    }
  }

  public componentWillUnmount(): void {
    if (this.props.callInProgress || this.props.incomingCall || this.props.outgoingCall) {
      this.hangUpCall('RTC Component unmounted');
    }
  }

  public render() {
    const {isUndocked, videoFullScreen, positionY, positionX, width, height, isBadBrowser} =
      this.props;
    const undocked = isUndocked && !videoFullScreen;
    const shouldPortal = (isUndocked || videoFullScreen) && !isBadBrowser;
    const style = {
      top: positionY,
      left: positionX,
      width,
      height
    };

    return (
      <RTCPortal shouldPortal={shouldPortal}>
        <div className={videoFullScreen ? 'fullscreen-black-mask' : ''}>
          <div
            style={style}
            className={classNames('webrtc-component', `cursor-${this.getCursor()}`, {
              fullscreen: videoFullScreen,
              undocked
            })}
            onMouseDown={this.startMoving}
            onMouseUp={this.handleMouseUp}
            onTouchStart={this.startMoving}
            onTouchEnd={this.handleMouseUp}
          >
            {this.renderContent()}
            <ResizeTrigger
              startResizing={this.startResizing}
              shouldRender={!videoFullScreen && isUndocked}
              isResizing={this.state.isResizing}
            />
          </div>
        </div>
      </RTCPortal>
    );
  }

  private getCursor = () => {
    if (!this.props.isUndocked || this.props.videoFullScreen) {
      return 'default';
    }
    if (!this.props.transformingMode) {
      return 'grab';
    }
    if (this.state.isResizing) {
      return 'resize';
    }
    return 'grabbing';
  };

  private hangUpCall = (reason: string) => {
    const {hangUp, partnerRoom, callId} = this.props;
    if (partnerRoom && callId) {
      hangUp(partnerRoom.id, callId, {reason});
    }
  };

  private handleHangupPress = () => this.hangUpCall('Ended by user');

  private handleMouseUp = () => {
    if (this.props.isUndocked && !this.props.videoFullScreen) {
      this.props.toggleTransformingMode(false);
      window.removeEventListener('mousemove', this.moveComponent);
      window.removeEventListener('mousemove', this.resizeComponent);
      window.removeEventListener('mouseup', this.handleMouseUp);

      window.removeEventListener('touchmove', this.moveComponent);
      window.removeEventListener('touchmove', this.resizeComponent);
      window.removeEventListener('touchend', this.handleMouseUp);

      this.setState({
        isResizing: false,
        mouseDownX: 0,
        mouseDownY: 0,
        prevX: 0,
        prevY: 0
      });
    }
  };

  private startMoving = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent) => {
    if (isMouseEvent(e)) e.preventDefault();

    if (this.props.isUndocked && !this.props.videoFullScreen && !this.state.isResizing) {
      this.props.toggleTransformingMode(true);

      window.addEventListener('mousemove', this.moveComponent);
      window.addEventListener('mouseup', this.handleMouseUp);

      window.addEventListener('touchmove', this.moveComponent);
      window.addEventListener('touchend', this.handleMouseUp);

      const {clientX, clientY} = getClientPosition(e);

      this.setState({
        mouseDownX: clientX,
        mouseDownY: clientY,
        prevX: this.props.positionX,
        prevY: this.props.positionY
      });
    }
  };

  private startResizing = (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent) => {
    if (this.props.isUndocked && !this.props.videoFullScreen) {
      if (isMouseEvent(e)) e.preventDefault();

      e.stopPropagation();
      this.props.toggleTransformingMode(true);
      window.addEventListener('mousemove', this.resizeComponent);
      window.addEventListener('mouseup', this.handleMouseUp);

      window.addEventListener('touchmove', this.resizeComponent);
      window.addEventListener('touchend', this.handleMouseUp);

      const {clientX, clientY} = getClientPosition(e);

      this.setState({
        mouseDownX: clientX,
        mouseDownY: clientY,
        prevX: this.props.width as number,
        prevY: this.props.height as number,
        isResizing: true
      });
    }
  };

  private resizeComponent = (e: MouseEvent) => {
    if (this.props.transformingMode && this.props.isUndocked && !this.props.videoFullScreen) {
      const {clientX, clientY} = getClientPosition(e);

      const heightChange = clientY - this.state.mouseDownY;
      const widthChange = clientX - this.state.mouseDownX;

      if (heightChange >= widthChange) {
        let newHeight = heightChange + this.state.prevY;
        if (newHeight < videoBaseHeight) {
          newHeight = videoBaseHeight;
        }
        if (newHeight + this.props.positionY > document.documentElement.clientHeight) {
          newHeight = document.documentElement.clientHeight - this.props.positionY;
        }
        let newWidth = (this.state.prevX * newHeight) / this.state.prevY;
        if (newWidth + this.props.positionX > document.documentElement.clientWidth) {
          newWidth = document.documentElement.clientWidth - this.props.positionX;
          newHeight = (this.state.prevY * newWidth) / this.state.prevX;
        }
        this.props.resizeVideo(newWidth, newHeight);
      } else {
        let newWidth = widthChange + this.state.prevX;
        if (newWidth < videoBaseWidth) {
          newWidth = videoBaseWidth;
        }
        if (newWidth + this.props.positionX > document.documentElement.clientWidth) {
          newWidth = document.documentElement.clientWidth - this.props.positionX;
        }
        let newHeight = (this.state.prevY * newWidth) / this.state.prevX;
        if (newHeight + this.props.positionY > document.documentElement.clientHeight) {
          newHeight = document.documentElement.clientHeight - this.props.positionY;
          newWidth = (this.state.prevX * newHeight) / this.state.prevY;
        }
        this.props.resizeVideo(newWidth, newHeight);
      }
    }
  };

  private moveComponent = (e: MouseEvent | TouchEvent) => {
    if (this.props.transformingMode && this.props.isUndocked && !this.props.videoFullScreen) {
      const {clientX, clientY} = getClientPosition(e);

      let x = clientX - (this.state.mouseDownX - this.state.prevX);
      let y = clientY - (this.state.mouseDownY - this.state.prevY);
      if (x < 0) {
        x = 0;
      }
      const maxAllowedX = document.documentElement.clientWidth - (this.props.width as number);
      const maxAllowedY = document.documentElement.clientHeight - (this.props.height as number);
      if (x > maxAllowedX) {
        x = maxAllowedX;
      }
      if (y < 0) {
        y = 0;
      }
      if (y > maxAllowedY) {
        y = maxAllowedY;
      }
      this.props.changeVideoPosition(x, y);
    }
  };

  private async createCall(withVideo: boolean, partnerRoom: Room) {
    const camId = withVideo ? this.props.camId! : null;
    try {
      await this.props.callStarted(partnerRoom.id, camId, this.props.micId!);
    } catch (e) {
      handleError(e, this.props.intl.formatMessage, withVideo);
    }
  }

  private answerCall = async (videoEnabled?: boolean) => {
    if (this.props.micId) {
      const camId = videoEnabled ? this.props.camId! : null;
      try {
        await this.props.answerCall(
          this.props.partnerRoom!.id,
          this.props.callId!,
          camId,
          this.props.micId
        );
      } catch (error) {
        handleError(error, this.props.intl.formatMessage, videoEnabled);
      }
    } else {
      this.props.toggleDevicesModal(true);
    }
  };

  private renderContent = () => {
    if (!this.props.shouldHideBadBrowser && this.props.isBadBrowser) {
      return this.renderBadBrowser();
    }

    if (this.props.partnerRoom && this.props.partnerRoom.recipient) {
      const videoControlPanel = (
        <VideoControlPanel
          videoFullScreen={this.props.videoFullScreen}
          isUndocked={this.props.isUndocked}
          toggleUndocked={this.toggleUndocked}
          toggleFullScreen={this.toggleFullScreen}
          intl={this.props.intl}
        />
      );
      const name: string = userCompactName(this.props.partnerRoom.recipient.profile!);
      if (this.props.incomingCall) {
        return (
          <IncomingCall
            partnerName={name}
            avatar={this.props.partnerRoom.recipient.profile!.avatars.md}
            hangUp={this.handleHangupPress}
            answerCall={this.answerCall}
            answeringAwait={this.props.answeringAwait}
            camId={this.props.camId}
          >
            {videoControlPanel}
          </IncomingCall>
        );
      }
      if (this.props.outgoingCall || this.props.callStarting) {
        return (
          <OutgoingCall
            partnerName={name}
            avatar={this.props.partnerRoom.recipient.profile!.avatars.md}
            callStarting={this.props.callStarting}
            hangUp={this.handleHangupPress}
            callRequestSent={this.props.outgoingCall}
          >
            {videoControlPanel}
          </OutgoingCall>
        );
      }
      if (this.props.callInProgress) {
        return (
          <CallInProgress
            partnerName={name}
            hangUp={this.handleHangupPress}
            chatSelectedRoom={this.props.chatSelectedRoom}
            width={this.props.width}
            height={this.props.height}
            switchChatRoom={this.props.switchChatRoom}
            toggleFullScreen={this.props.toggleFullScreen}
            toggleUndocked={this.props.toggleUndocked}
            intl={this.props.intl}
            partnerRoom={this.props.partnerRoom}
            localAudioEnabled={this.props.localAudioEnabled}
            localVideoEnabled={this.props.localVideoEnabled}
            camId={this.props.camId}
            micId={this.props.micId}
            videoFullScreen={this.props.videoFullScreen}
            isUndocked={this.props.isUndocked}
          >
            {videoControlPanel}
          </CallInProgress>
        );
      }
    }
    return null;
  };

  private renderBadBrowser = () => {
    if (this.props.browserInfo && this.props.browserInfo.outdated) {
      const {name: browserName, version} = this.props.browserInfo.browser;
      const minVersionSupported = this.props.browserInfo.supported[browserName];
      return (
        <OutdatedBrowser
          name={browserName}
          version={version}
          minVersion={minVersionSupported}
          onClose={this.props.closeBadBrowser}
        />
      );
    }

    return <BadBrowser onClose={this.props.closeBadBrowser} />;
  };

  private toggleUndocked = () => {
    this.props.toggleUndocked(!this.props.isUndocked);
  };

  private toggleFullScreen = () => {
    this.props.toggleFullScreen(!this.props.videoFullScreen);
  };
}

export default injectIntl(RTCComponent);
