import {type ImageViewerImage, type ChatState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {type ChatAction, type ChatImageAction} from '../actions/interface';
import {roomsReducer} from './roomsReducer';
import {IMAGE_LOADING_FAILED} from '../actions/imageActionTypes';

export const IMAGES_ACTION_HANDLERS: ActionHandlersList<ChatState, ChatAction> = {
  [IMAGE_LOADING_FAILED]: (state: ChatState, action: ChatImageAction) => {
    const index = state.images.findIndex((image: ImageViewerImage) => image.id === action.imageId);
    const images = [...state.images];
    images.splice(index, 1);
    return {
      ...state,
      rooms: roomsReducer(state.rooms, action),
      images
    };
  }
};
