import {type AppState} from 'store/interface';

import type WidgetRecord from './WidgetRecord';
import {type ExerciseProperties} from './Exercise/interface';
import {type PersistWidgetValues} from './persistence/interface';
import {type WidgetProperties} from './interface';

const getXPlayerWidget = (
  state: AppState,
  exerciseId: string,
  widgetId: string
): WidgetProperties | undefined => {
  const {xplayer} = state;
  if (!xplayer) {
    return;
  }
  const exercise = xplayer.exercises.get(exerciseId);
  if (!exercise) {
    return;
  }
  return exercise.widgets.find((w: WidgetProperties) => w.id === widgetId);
};

export const getWidgetValues = (
  state: AppState,
  widgetId: string
): PersistWidgetValues | undefined => {
  const {xplayer} = state;
  let exerciseId: string | null = null;
  if (!xplayer) {
    return;
  }
  xplayer.exercises.forEach((e: ExerciseProperties): false | void => {
    const widget = e.widgets.find((w: WidgetRecord) => w.id === widgetId);
    if (widget) {
      exerciseId = e.id;
      return false;
    }
  });

  if (!exerciseId) {
    return;
  }

  const widget = getXPlayerWidget(state, exerciseId, widgetId);

  if (!widget || !widget.values || widget.skipSync) {
    return;
  }
  return {
    exerciseId,
    widgetId,
    values: widget.values,
    version: widget.version,
    throttle: widget.throttleSendValues
  };
};
