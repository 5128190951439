import React, {type FC, useCallback, useMemo} from 'react';
import {injectIntl, type WrappedComponentProps} from 'react-intl';
import classNames from 'classnames';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import {Field} from 'formik';
import Scrollbars from 'react-custom-scrollbars';

import {type ExerciseJSON} from 'store/exercise/player/interface';

import RadioButton from './RadioButton';
import i18n from './i18n';
import {SEPARATOR} from './constants';
import './ExtraExercisesList.scss';

interface Props extends WrappedComponentProps {
  selectedId: string;
  exercises: ExerciseJSON[];
  selectExercise: (id: string) => void;
  showFormControls?: boolean;
}

const ExtraExercisesList: FC<Props> = ({
  exercises,
  intl,
  selectExercise,
  selectedId,
  showFormControls
}) => {
  const exerciseSelectors = useMemo(() => {
    const selectors = {};
    exercises.forEach(ex => {
      selectors[ex.id!] = () => selectExercise(ex.id!);
    });
    return selectors;
  }, [exercises, selectExercise]);

  const stopPropagation = useCallback<(e: React.MouseEvent<FormGroup, MouseEvent>) => void>(e => {
    e.stopPropagation();
  }, []);

  if (!exercises.length) {
    return null;
  }

  return (
    <div className={classNames('extra-exercises-list', {'show-controls': showFormControls})}>
      <Scrollbars autoHide={true}>
        {exercises.map((exercise, i) => (
          <div
            key={exercise.id!}
            className={classNames('item', {selected: exercise.id === selectedId})}
            onClick={selectedId === exercise.id ? undefined : exerciseSelectors[exercise.id!]}
          >
            <div className="excerpt">{exercise.excerpt}</div>
            {showFormControls && (
              <FormGroup onClick={stopPropagation}>
                <Field
                  component={RadioButton}
                  name={i}
                  id={`${exercise.id}${SEPARATOR}hidden`}
                  label={intl.formatMessage(i18n.hidden)}
                />
                <Field
                  component={RadioButton}
                  name={i}
                  id={`${exercise.id}${SEPARATOR}class`}
                  label={intl.formatMessage(i18n.inClass)}
                />
                <Field
                  component={RadioButton}
                  name={i}
                  id={`${exercise.id}${SEPARATOR}home`}
                  label={intl.formatMessage(i18n.atHome)}
                />
              </FormGroup>
            )}
          </div>
        ))}
        <div className="empty-space" />
      </Scrollbars>
    </div>
  );
};

export default injectIntl(ExtraExercisesList);
