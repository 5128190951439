import {useCallback, useEffect, useRef, useState} from 'react';

export type AsyncFn<A extends unknown[], R> = (...args: A) => Promise<R>;

type HookResult<A extends unknown[], R> = [
  request: (...args: Parameters<AsyncFn<A, R>>) => void,
  result: R | null,
  loading: boolean,
  error: Err,
  cancel: () => void
];

type Err = unknown | null;

interface Options<R> {
  onSuccess?: (result: R) => void;
  onError?: (err?: Err) => void;
}

export const useLoadable = <R, A extends unknown[]>(
  asyncFunction: AsyncFn<A, R>,
  {onSuccess, onError}: Options<R> = {}
): HookResult<A, R> => {
  const [result, setResult] = useState<R | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Err>(null);

  const successRef = useRef(onSuccess);
  const errorRef = useRef(onError);

  const cancelled = useRef(false);

  const cancel = useCallback(() => {
    cancelled.current = true;
  }, []);

  const request = useCallback(
    async (...args: Parameters<typeof asyncFunction>): Promise<void> => {
      try {
        if (!cancelled.current) {
          setError(null);
          setResult(null);
          setLoading(true);
        }

        const result = await asyncFunction(...args);

        if (!cancelled.current) {
          setResult(result);
          successRef.current?.(result);
        }
      } catch (e) {
        if (!cancelled.current) {
          setError(e);
          errorRef.current?.(e);
        }
      } finally {
        if (!cancelled.current) {
          setLoading(false);
        }
      }
    },
    [asyncFunction]
  );

  useEffect(() => {
    successRef.current = onSuccess;
    errorRef.current = onError;
  });

  useEffect(() => () => cancel(), [cancel]);

  return [request, result, loading, error, cancel];
};
