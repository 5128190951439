import {type Annotation, type Block, type Document, type Inline, type Value} from '@englex/slate';
import {type List} from 'immutable';

import {type ScrambledOptions} from 'store/exercise/player/widgets/ScrambledSentences/interface';
import {letterMatcher, spellingLetterMatcher, wordFilter, wordSplitter} from 'config/static';
import {isBlockOfType, isGapFillInline, isIconInline} from 'components/Slate/utils';
import {type DataMap, SlateBlock} from 'components/Slate/interface';
import {type AudioFile, type VideoData} from 'components/media/interface';
import {isDocumentNotEmpty} from 'components/Slate/utils/documentNotEmpty';

export const documentHasGaps = (document: Document, amount: number = 1): boolean =>
  document
    .getInlines()
    .filter((inline: Inline) => isGapFillInline(inline) && !inline.data.get('example')).size >=
  amount;

export const taskMaxLength = (document: Document, maxLength: number): boolean => {
  const iconsNumber = document.getInlines().filter(inline => isIconInline(inline!)).size;
  return document.text.length + iconsNumber <= maxLength;
};

export const documentNotEmpty = isDocumentNotEmpty;
export const audioExists = (audio?: AudioFile) => {
  return !!audio;
};

export const videoExists = (video: VideoData) => !!video.id;

export const questionLabelsNotEmpty = (document: Document): boolean =>
  document
    .filterDescendants(block => isBlockOfType(block, SlateBlock.QUESTION_ITEM))
    .every(questionBlock => {
      const labelBlock = (questionBlock as Block).nodes.first() as Block;
      return (
        !!labelBlock.text.trim().length ||
        !!labelBlock.getInlines().find(node => isIconInline(node!))
      );
    });

export const answerBlocksNotEmpty = (document: Document): boolean =>
  document
    .filterDescendants(block => isBlockOfType(block, SlateBlock.QUESTION_ANSWER))
    .every(
      answerBlock =>
        !!(answerBlock as Block).text.trim().length ||
        !!(answerBlock as Block).getInlines().find(node => isIconInline(node!))
    );

export const minRequiredAnswers = (document: Document, numberOfAnswersRequired: number): boolean =>
  (document.nodes.get(0) as Block).nodes.every(
    (questionBlock: Block) =>
      numberOfAnswersRequired <=
      questionBlock.filterDescendants(block => isBlockOfType(block, SlateBlock.QUESTION_ANSWER))
        .size
  );

export const correctAnswerSelectedForEveryQuestion = (document: Document) =>
  (document.nodes.get(0) as Block).nodes.every((questionBlock: Block) => {
    const checkedAnswers = questionBlock.filterDescendants(
      answerBlock =>
        isBlockOfType(answerBlock, SlateBlock.QUESTION_ANSWER) && answerBlock.data.get('checked')
    );
    return checkedAnswers.size > 0;
  });

export const questionsDoNotContainIdenticalAnswers = (document: Document) =>
  (document.nodes.get(0) as Block).nodes.every(questionNode => {
    const blockNodes = (questionNode as Block).nodes.filter(node =>
      isBlockOfType(node!, SlateBlock.QUESTION_ANSWER)
    );
    return blockNodes.every(
      // only one answer block in array has this text
      node =>
        blockNodes.filter(
          nodeToCompare =>
            nodeToCompare!.text.toLocaleLowerCase() === node!.text.toLocaleLowerCase()
        ).size === 1
    );
  });

export const sentencesContainWordsAmount = (value: Value, amount: number = 2): boolean => {
  let result = true;
  const listItems = (value.document.nodes.get(0) as Block).nodes;
  listItems.forEach((li: Block) => {
    const parts = li.text.split(wordSplitter);
    if (parts.filter(p => wordFilter.test(p) && letterMatcher.test(p)).length < amount) {
      result = false;
    }
  });
  return result;
};

export const spellingSentencesContainWordsAmount = (value: Value, amount: number = 2): boolean => {
  let result = true;
  const listItems = (value.document.nodes.get(0) as Block).nodes;
  listItems.forEach((li: Block) => {
    const parts = li.text.split('');
    if (parts.filter(p => spellingLetterMatcher.test(p)).length < amount) {
      result = false;
    }
  });
  return result;
};

export const sentencesContainDraggablesAmount = (value: Value, amount: number = 2): boolean => {
  let result = true;
  const {annotations, document} = value;
  const listItems = (document.nodes.get(0) as Block).nodes;
  listItems.forEach((li: Block) => {
    const liPath = document.getPath(li.key);
    if (
      annotations.filter((a: Annotation) =>
        (a.anchor.path as List<number>).slice(0, 2).equals(liPath as List<number>)
      ).size < amount
    )
      result = false;
  });
  return result;
};

export const containsNonExampleScrambledSentence = (
  document: Document,
  options?: DataMap<ScrambledOptions>
) => {
  const listItems = (document.nodes.get(0) as Block).nodes as List<Block>;
  if (listItems.size > 1) {
    return true;
  }
  return !(options && options.get('containsExample'));
};

export const questionsNotEmpty = (document: Document) => {
  const listItems = (document.nodes.get(0) as Block).nodes;
  return listItems.filter((node: Block) => !!node.text.trim().length).size === listItems.size;
};

export const minQuestionsAmount = (document: Document) => {
  const list = document.nodes.get(0) as Block;

  if (!(list.nodes.first() as Block).data.get('example')) {
    return true;
  }

  return list.nodes.size > 1;
};
