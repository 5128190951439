import React, {type ButtonHTMLAttributes, forwardRef, type PropsWithChildren} from 'react';
import classNames from 'classnames';
import {useFocused} from '@englex/paint-react';

import {buttonTitle} from 'helpers/title';
import {type Shortcut} from 'helpers/shortcut';

import './ToolbarButton.scss';

interface Props
  extends React.DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  shortcut?: Shortcut;
}

export const ToolbarButton = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({children, className, style, disabled: isDisabled, shortcut, title, ...rest}, ref) => {
    const isFocused = useFocused();
    const disabled = !!isDisabled || !isFocused;
    const t = buttonTitle(title, shortcut);
    const tabIndex = isDisabled ? -1 : undefined;
    return (
      <button
        tabIndex={tabIndex}
        {...rest}
        className={classNames('painter-toolbar-button', className, {disabled})}
        style={style}
        ref={ref}
        title={t}
      >
        {children}
      </button>
    );
  }
);
