import React, {type FC, memo, useCallback, useContext, useMemo, useState} from 'react';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';
import {FormattedMessage, useIntl} from 'react-intl';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {addEntriesToListRequest} from 'store/dictionary/requests';
import {useApiRequest2} from 'hooks/rest/useApiRequest2';
import Loader from 'components/Loader';
import Icon from 'components/Icon';
import * as toastr from 'components/toastr';

import {
  EditEntryActionsContext,
  EditEntryStateContext
} from '../../contexts/entry/EditEntryContext';
import {clearEdit, exitSelectionMode, setSelectionAction} from '../../contexts/entry/actions';
import {useListsInitializer} from './useListsInitializer';
import {AddEntryToListModalForm} from './AddEntryToListModalForm';
import {maxListEntries} from '../../static';
import {type DictionaryEntryInstance} from '../../interface';
import './AddEntryToListModal.scss';
import {type DictionaryOwnerRole, useDictionaryContext} from '../../contexts';

type Callback = (entryId: string) => void;

type GroupType = {label: string; options: {label: string; value: string}[]};

interface Props {
  listId?: string;
}

export const AddEntryToListModal: FC<Props> = memo(({listId}) => {
  const {selectionMode, addToListObject} = useContext(EditEntryStateContext);
  const show = addToListObject && addToListObject.existingList;

  const showInSelectionMode = selectionMode?.action === 'add' && selectionMode.existingList;

  if (showInSelectionMode) {
    return <InnerModal listId={listId} entryIds={selectionMode!.entryIds} />;
  }

  return show ? (
    <InnerModal
      listId={listId}
      entryIds={addToListObject!.entryIds}
      onSubmit={addToListObject.submit}
    />
  ) : null;
});

interface InnerProps {
  entryIds: string[];
  listId?: string;
  onSubmit?: (callback: Callback) => void;
}

const InnerModal: FC<InnerProps> = memo(({entryIds, listId, onSubmit}) => {
  const intl = useIntl();
  const {dispatch} = useContext(EditEntryActionsContext);
  const isMounted = useIsMounted();
  const {dictionaryOwnerId, dictionaryOwnerRole} = useDictionaryContext();

  const [selectedOption, setSelectedOption] = useState<
    {label: string; value: string} | undefined
  >();
  const [show, setShow] = useState(true);
  const [validationState, setValidationState] = useState<'error' | null>(null);

  const {lists, isError, isLoading} = useListsInitializer();
  const options = useMemo<GroupType[]>(() => {
    const activeGroup: GroupType = {
      label: intl.formatMessage({id: 'Dictionary.ActiveLists'}),
      options: []
    };
    const archiveGroup: GroupType = {
      label: intl.formatMessage({id: 'Dictionary.ArchivedLists2'}),
      options: []
    };
    const filteredLists = lists.filter(l => l.id !== listId);
    for (const list of filteredLists) {
      const option = {label: list.title.replace(/\s/g, ' '), value: list.id};
      if (list.deletedAt) archiveGroup.options.push(option);
      else activeGroup.options.push(option);
    }

    return [activeGroup, archiveGroup];
  }, [intl, listId, lists]);

  const onHide = useCallback(() => {
    isMounted.current && setShow(false);
  }, [isMounted]);

  const cleanup = useCallback(() => {
    dispatch(clearEdit());
    dispatch(setSelectionAction());
  }, [dispatch]);

  const {apiRequest, isLoading: submitting} = useApiRequest2<
    [number, DictionaryOwnerRole, string[], string],
    DictionaryEntryInstance[]
  >(
    addEntriesToListRequest,
    data => {
      if (!data.length && !onSubmit) {
        toastr.error(
          '',
          entryIds.length > 1
            ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntriesToListError2'})
            : intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListError2'})
        );
      } else {
        toastr.success(
          '',
          entryIds.length > 1
            ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntriesToListSuccess'})
            : intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListSuccess'})
        );
        dispatch(exitSelectionMode());
      }
      onHide();
    },
    () =>
      toastr.error(
        '',
        entryIds.length > 1
          ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntriesToListError'})
          : intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListError'})
      )
  );

  const handleSubmit = useCallback(() => {
    if (submitting) return;
    if (!selectedOption) {
      return setValidationState('error');
    }
    if (
      (lists.find(l => l.id === selectedOption.value)?.entryCount || 0) + entryIds.length >
      maxListEntries
    ) {
      dispatch(setSelectionAction());
      return toastr.error(
        '',
        entryIds.length > 1
          ? intl.formatMessage({id: 'Dictionary.Toastr.AddEntriesToListValidationError'})
          : intl.formatMessage({id: 'Dictionary.Toastr.AddEntryToListValidationError'})
      );
    }

    if (onSubmit) {
      return onSubmit(entryId => {
        apiRequest(dictionaryOwnerId, dictionaryOwnerRole, [entryId], selectedOption.value);
      });
    }

    apiRequest(dictionaryOwnerId, dictionaryOwnerRole, entryIds, selectedOption.value);
  }, [
    apiRequest,
    onSubmit,
    dictionaryOwnerId,
    dictionaryOwnerRole,
    dispatch,
    entryIds,
    intl,
    lists,
    selectedOption,
    submitting
  ]);

  return (
    <Modal
      backdrop="static"
      show={show}
      onHide={onHide}
      onExited={cleanup}
      className="add-entry-to-list-modal"
    >
      <Modal.Header>
        <Modal.Title>{intl.formatMessage({id: 'Dictionary.AddToExistingList'})}</Modal.Title>
        <a onClick={onHide}>
          <Icon name="pc-close" tag="i" />
        </a>
      </Modal.Header>
      <Modal.Body>
        {!lists || isLoading ? (
          <Loader />
        ) : (
          <AddEntryToListModalForm
            options={options}
            setSelectedOption={setSelectedOption}
            validationState={validationState}
            setValidationState={setValidationState}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="actions">
          <Button bsStyle="default" bsSize="sm" onClick={onHide} disabled={submitting}>
            <FormattedMessage id="Common.Cancel" />
          </Button>
          <Button
            bsStyle="primary"
            bsSize="sm"
            type="submit"
            disabled={isLoading || isError || submitting}
            onClick={handleSubmit}
          >
            <FormattedMessage id="Common.Save" />
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
});
