import {defineMessages} from 'react-intl';

export const RTCMessages = defineMessages({
  MediaErrorHeader: {
    id: 'WebRTC.MediaErrorHeader'
  },
  MediaErrorText: {
    id: 'WebRTC.MediaErrorText'
  },
  CallStartedUnknownErr: {
    id: 'WebRTC.CallStartedUnknownErr'
  },
  DisableWebcam: {
    id: 'WebRTC.DisableWebcam'
  },
  EnableWebcam: {
    id: 'WebRTC.EnableWebcam'
  },
  DisableMic: {
    id: 'WebRTC.DisableMic'
  },
  EnableMic: {
    id: 'WebRTC.EnableMic'
  },
  NoWebcam: {
    id: 'WebRTC.NoWebcam'
  },
  PartnerDisconnectedHeader: {
    id: 'WebRTC.PartnerDisconnectedHeader'
  },
  PartnerDisconnectedText: {
    id: 'WebRTC.PartnerDisconnectedText' // tslint:disable-next-line max-line-length
  },
  PartnerVoiceCall: {
    id: 'WebRTC.PartnerVoiceCall'
  },
  PartnerVideoCall: {
    id: 'WebRTC.PartnerVideoCall'
  },
  PartnerCallVoiceAnswer: {
    id: 'WebRTC.PartnerCallVoiceAnswer'
  },
  PartnerCallVideoAnswer: {
    id: 'WebRTC.PartnerCallVideoAnswer'
  },
  PartnerRejectCall: {
    id: 'WebRTC.PartnerRejectCall'
  },
  OpenChatWithPartner: {
    id: 'WebRTC.OpenChatWithPartner'
  },
  PartnerHangUp: {
    id: 'WebRTC.PartnerHangUp'
  },
  ExpandVideoCallWindow: {
    id: 'WebRTC.ExpandVideoCallWindow'
  },
  ResetVideoCallWindow: {
    id: 'WebRTC.ResetVideoCallWindow'
  },
  UndockVideoCallWindow: {
    id: 'WebRTC.UndockVideoCallWindow'
  },
  DockVideoCallWindow: {
    id: 'WebRTC.DockVideoCallWindow'
  },
  CallToUnsupportedBrowserError: {
    id: 'WebRTC.CallToUnsupportedBrowserError'
  },
  CaptureStreamErrorHeader: {
    id: 'WebRTC.CaptureStreamErrorHeader'
  },
  CaptureStreamErrorMessage: {
    id: 'WebRTC.CaptureStreamErrorMessage'
  },
  MediaNotFoundErrorHeader: {
    id: 'WebRTC.MediaNotFoundErrorHeader'
  },
  MediaNotFoundErrorMessage: {
    id: 'WebRTC.MediaNotFoundErrorMessage'
  },
  MicNotFoundErrorHeader: {
    id: 'WebRTC.MicNotFoundErrorHeader'
  },
  MicNotFoundErrorMessage: {
    id: 'WebRTC.MicNotFoundErrorMessage'
  },
  CallInterrupted: {
    id: 'WebRTC.CallInterrupted'
  },
  UserGoneOffline: {
    id: 'WebRTC.UserGoneOffline'
  }
});

export const getMuteCamBtnTitle = (camId: string, localVideoEnabled: boolean) => {
  if (!camId) {
    return RTCMessages.NoWebcam;
  }
  if (localVideoEnabled) {
    return RTCMessages.DisableWebcam;
  }
  return RTCMessages.EnableWebcam;
};
