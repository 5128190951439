import React from 'react';
import Overlay from 'react-bootstrap/lib/Overlay';
import {injectIntl, type WrappedComponentProps, defineMessages} from 'react-intl';
import classNames from 'classnames';

import {type RoomWithRecipient} from 'store/interface';

import SelectedRoom from '../../SelectedRoom';
import ChatSelectRoomPopover from '../ChatSelectRoomPopover/ChatSelectRoomPopover';
import UnreadMessagesNotification from '../UnreadMessagesNotification/UnreadMessagesNotification';
import Icon from '../../../Icon';
import {SoundNotification, soundUrl} from '../../../../helpers/sound';
import {playMedia} from '../../../../helpers/playMedia';
import {type ToggleElementCreator} from '../../../../common/interface';

const headerMessages = defineMessages({
  ContactsDropdownMessage: {
    id: 'Chat.ContactsDropdownMessage'
  }
});

export interface HeaderStateProps {
  shouldDisableCallButtons: boolean;
  callInProgress: boolean;
  isAwaitingAnswer?: boolean;
  roomsPopoverOpen?: boolean;
  showRTCControls: boolean;
  videoCall?: boolean;
  camId?: string;
  micId?: string;
  selectedRoomId: number;
  otherSessionCall?: boolean;
  rooms: {[key: string]: RoomWithRecipient};
  callPartnerId?: number;
  roomsPopoverFilter: string;
  roomsPopoverTab: string;
  partnerIsTyping: boolean;
}

export interface HeaderDispatchProps {
  toggleRoomsPopover: ToggleElementCreator;
  toggleDevicesModal: ToggleElementCreator;
  callStarting: (roomId: number, video?: boolean) => void;
  hideStartPhrases: (roomId: number) => void;
  changeRoomsFilter: (filter: string) => void;
  changeRoomsPopoverTab: (tabId: string) => void;
}

interface HeaderOwnProps {
  unreadMessagesCount: number;
  isChatCompact?: boolean;
  selectRoom: (id: number) => void;
}

interface HeaderProps extends HeaderStateProps, HeaderDispatchProps, HeaderOwnProps {}

type Props = HeaderProps & WrappedComponentProps;

class Header extends React.PureComponent<Props, {}> {
  private audioNotification: HTMLMediaElement;

  public componentDidUpdate(prevProps: Props) {
    if (
      prevProps.callInProgress &&
      !this.props.callInProgress &&
      !prevProps.otherSessionCall &&
      !this.props.otherSessionCall &&
      this.audioNotification
    ) {
      playMedia(this.audioNotification);
    }
  }

  public render() {
    const {
      selectRoom,
      unreadMessagesCount,
      isChatCompact,
      roomsPopoverOpen,
      intl,
      showRTCControls,
      callInProgress,
      rooms,
      camId,
      micId,
      shouldDisableCallButtons,
      videoCall,
      isAwaitingAnswer,
      callPartnerId,
      selectedRoomId,
      roomsPopoverFilter,
      roomsPopoverTab,
      changeRoomsFilter,
      changeRoomsPopoverTab
    } = this.props;
    const headerClass = classNames('chat-header', {
      collapsed: callInProgress && !isChatCompact,
      compact: isChatCompact
    });
    const isCallPartner = callPartnerId === selectedRoomId;
    return (
      <div className={headerClass}>
        <SelectedRoom
          showRTCControls={showRTCControls}
          isAwaitingAnswer={isAwaitingAnswer && isCallPartner}
          startAudioCall={this.startAudioCall}
          startVideoCall={this.startVideoCall}
          compact={isChatCompact}
          recipient={this.activeRoom.recipient}
          isRoomDeleted={!!this.activeRoom.deleted_at}
          isRoomPrivate={this.activeRoom.private}
          camId={camId}
          micId={micId}
          shouldDisableCallButtons={shouldDisableCallButtons}
          partnerIsTyping={this.props.partnerIsTyping}
        />
        <div className={`room-overlay-trigger ${isChatCompact && 'compact'}`}>
          <Icon
            name="virc-contact_list"
            size="2x"
            onClick={this.togglePopover}
            title={intl.formatMessage(headerMessages.ContactsDropdownMessage)}
          />
          <Overlay
            animation={false}
            container={this}
            placement="bottom"
            rootClose={true}
            show={roomsPopoverOpen}
            onHide={this.hidePopover}
          >
            <ChatSelectRoomPopover
              isChatCompact={isChatCompact}
              partnerSelectedHandler={selectRoom}
              hidePopover={this.hidePopover}
              elements={rooms}
              videoCall={videoCall}
              awaitingCallAnswer={isAwaitingAnswer}
              callPartnerId={callPartnerId}
              popoverId="chat-select-room"
              topPosition="58px"
              arrowLeftOffset="calc(100% - 28px)"
              filter={roomsPopoverFilter}
              openTabId={roomsPopoverTab}
              changeActiveTab={changeRoomsPopoverTab}
              changeFilter={changeRoomsFilter}
              selectedElementId={selectedRoomId}
            />
          </Overlay>
          {this.renderAudioNotification()}
          <UnreadMessagesNotification unreadMessagesNumber={unreadMessagesCount} />
        </div>
      </div>
    );
  }

  private hidePopover = () => {
    this.props.toggleRoomsPopover(false);
  };

  private togglePopover = () => {
    this.props.toggleRoomsPopover(!this.props.roomsPopoverOpen);
  };

  private startCall = (withVideo?: boolean) => {
    if (this.props.micId) {
      this.props.callStarting(this.props.selectedRoomId, withVideo);
      this.props.hideStartPhrases(this.props.selectedRoomId);
    } else {
      this.props.toggleDevicesModal(true);
    }
  };

  private startAudioCall = () => {
    this.startCall(false);
  };

  private startVideoCall = () => {
    this.startCall(true);
  };

  private renderAudioNotification = () => (
    <audio
      src={soundUrl(SoundNotification.callEnd)}
      loop={false}
      autoPlay={false}
      preload="auto"
      ref={el => el && (this.audioNotification = el)}
    />
  );

  private get activeRoom() {
    return this.props.rooms[this.props.selectedRoomId];
  }
}
export default injectIntl(Header);
