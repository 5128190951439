import {List} from 'immutable';

import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {decorate} from 'immutable-record/decorate.util';

import {type ExerciseCategory, type LanguageLevel} from '../../../interface';
import {type ExerciseMetaProperties} from './interface';
import {type ExerciseMetaJSON} from '../../player/interface';

const Record = recordBase()(BaseRecord);

class XExerciseMetaRecord extends Record implements ExerciseMetaProperties {
  public declare readonly categories: List<ExerciseCategory>;

  public declare readonly levels: List<number>;

  public static fromJSON(json: ExerciseMetaJSON): XExerciseMetaRecord {
    return new this(json);
  }

  constructor(raw: ExerciseMetaJSON = {categories: [], levels: []}) {
    super();
    this.initValues({
      categories: List(raw.categories),
      levels: List(raw.levels.map(({id}: LanguageLevel) => id))
    });
  }

  public toJSON(): ExerciseMetaJSON {
    return {
      categories: this.categories.toJS(),
      levels: this.levels.toJS().map((id: number) => ({id}))
    };
  }
}

decorate(XExerciseMetaRecord, {
  categories: property(List()),
  levels: property(List())
});

record()(XExerciseMetaRecord);

export default XExerciseMetaRecord;
