import {EventEmitter} from 'eventemitter3';

export const changeCurrentTimeEventName: string = 'audio:change-current-time';
export const changeBufferedEventName: string = 'audio:change-buffered';

export const changeChatSize: string = 'chat:change-size';
export const requestChatSize: string = 'chat:request-chat-size';

export const xAudioCurrentTimeChanged = 'xaudio:current-time-changed';
export const xAudioBufferChanged = 'xaudio:buffer-changed';

export const emitter = new EventEmitter();
