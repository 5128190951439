import {useMemo} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {type AppState, type Role, type Room, type StudentTeachers} from 'store/interface';
import {homeworkPlayerPattern} from 'common/paths';

interface Params {
  preview?: boolean;
  studentTeacherId?: string;
  allowStudentToInitiate?: boolean;
}

const useCanPlayTogether = ({
  preview = false,
  studentTeacherId,
  allowStudentToInitiate
}: Params): boolean => {
  const {pathname} = useLocation();
  const role = useSelector<AppState, Role | undefined>(s => s.user.role);
  const isConnected = useSelector<AppState, boolean>(s => s.rtc.call?.status === 'connected');
  const otherSessionCall = useSelector<AppState, boolean | undefined>(s => s.rtc.otherSessionCall);
  const partnerSessionId = useSelector<AppState, number | undefined>(s => s.rtc.partnerSessionId);
  const callRoom = useSelector<AppState, Room | undefined>(
    s => {
      const callRoomId = s.rtc.call?.room_id;
      return callRoomId && s.chat?.rooms ? s.chat.rooms[callRoomId] : undefined;
    },
    (l, r) => (l && r ? l.id === r.id : l === r)
  );
  const studentTeachers = useSelector<AppState, StudentTeachers | undefined>(
    s => s.studentTeachers?.studentTeachers,
    (l, r) => Object.keys(l || {}).join('') === Object.keys(r || {}).join('')
  );
  const homeworkIsNotSelected = useSelector<AppState, boolean>(
    s => !s.classroom?.courseInstanceState.homework.homeworkPlayer.selectedHomework?.activatedAt
  );

  const callStudentTeacherEqualsSelected = useMemo<boolean>(() => {
    if (!callRoom || !studentTeachers) return false;
    const callST = Object.values(studentTeachers).find(
      st => callRoom.recipient && st.recipient.id === callRoom.recipient.id
    );
    return callST?.id === Number(studentTeacherId);
  }, [callRoom, studentTeacherId, studentTeachers]);

  return useMemo<boolean>(() => {
    if (role === 'student' && !allowStudentToInitiate) {
      return false;
    }
    if (preview || otherSessionCall || !partnerSessionId || !isConnected) {
      return false;
    }
    const [isHomework] = [matchPath({path: homeworkPlayerPattern, end: false}, pathname)];
    if (isHomework && homeworkIsNotSelected) {
      return false;
    }
    return callStudentTeacherEqualsSelected;
  }, [
    allowStudentToInitiate,
    callStudentTeacherEqualsSelected,
    homeworkIsNotSelected,
    isConnected,
    otherSessionCall,
    partnerSessionId,
    pathname,
    preview,
    role
  ]);
};

export default useCanPlayTogether;
