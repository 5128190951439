import {type IEvent} from 'autobahn';
import {type Action, type Dispatch, type MiddlewareAPI} from 'redux';
import IntlMessageFormat from 'intl-messageformat';

import {notificationInTitleInterval} from 'config/static';
import {type AxiosRequestAction} from 'services/axios/interface';
import {SUBSCRIBE} from 'services/wamp/actions/types';
import {type AppState, type SelectionData} from 'store/interface';
import {type WampPublishAction} from 'services/wamp/actions/interface';

import {
  BATCH_MEDIA,
  URL_OPENED,
  CLEAR_REDIRECTED_FROM_URL,
  CLEAR_POINTER_SELECTION,
  PUBLISH_OPEN_URL,
  PUBLISH_URL_OPENED,
  REQUEST_APP_VERSION,
  REQUEST_UNIT_INSTANCES,
  SET_APP_VERSION,
  SET_CHANGE_TITLE_INTERVAL,
  SET_INCORRECT_URL_ID,
  SET_OPENING_URL_FOR_PARTNER,
  SET_REDIRECTED_FROM_URL,
  SET_POINTER_SELECTION,
  SET_TITLE,
  SUBSCRIBE_TO_APP_UPDATE,
  PUBLISH_POINTER_URL,
  SET_PUBLISHING_POINTER,
  URL_OPENED_POINTER,
  PUBLISH_POINTER_URL_OPENED,
  PUBLISH_EXERCISE_URL_OPENED,
  URL_OPENED_EXERCISE,
  SET_OPENING_EXERCISE_FOR_PARTNER,
  PUBLISH_EXERCISE_URL_OPEN,
  SET_LAST_VISITED_UNIT_PAGE
} from './actionTypes';
import {
  type SetAppVersionCreator,
  type SetChangeTitleIntervalCreator,
  type SetTitleActionCreator
} from './interface';
import {setPageVisibility} from '../layouts/actions/action';
import {getUnreadMessagesCount} from '../components/Chat/reducer/reducer';
import {messages as IncomingCallMessages} from '../webRTC/components/IncomingCall';
import {titleMessages} from '../layouts/MainLayout/i18n';
import {type MediaContext, type MediaType} from '../components/media/interface';
import {type SetLastUnitPageAction} from '../routes/ClassRoom/actions/interface';

export const getAppVersion = (): AxiosRequestAction => ({
  type: REQUEST_APP_VERSION,
  payload: {
    client: 'static',
    request: {
      method: 'get',
      url: '/version.json',
      headers: {
        'Cache-Control': 'no-cache'
      }
    }
  }
});

const setAppVersion: SetAppVersionCreator = (version: string, hash: string) => ({
  type: SET_APP_VERSION,
  version,
  hash
});

const setTitle: SetTitleActionCreator = (title?: string) => ({
  type: SET_TITLE,
  title
});

const handleAppUpdate = (
  args: {},
  kwargs: {version: string; hash: string},
  details: IEvent,
  api: MiddlewareAPI<Dispatch>
) => {
  if (import.meta.env.REACT_APP_HANDLE_UPDATES === 'true') {
    api.dispatch(setAppVersion(kwargs.version, kwargs.hash));
  } else {
    // eslint-disable-next-line no-console
    console.log('App update handler is disabled', kwargs);
  }
};

export const subscribeToAppUpdate = () => ({
  type: SUBSCRIBE_TO_APP_UPDATE,
  wamp: {
    method: SUBSCRIBE,
    uri: 'system:update.web'
  },
  callback: handleAppUpdate
});

const setChangeTitleInterval: SetChangeTitleIntervalCreator = (id: NodeJS.Timeout) => ({
  type: SET_CHANGE_TITLE_INTERVAL,
  id
});

export const pageBlurred = () => (dispatch: Dispatch<Action>, getState: () => AppState) => {
  const pageHasFocus = getState().layout.pageHasFocus;
  if (!pageHasFocus) {
    return;
  }
  dispatch(setPageVisibility(false));
  const initialState = getState();
  const initialUnreadMessagesCount =
    initialState.chat && initialState.chat.rooms
      ? getUnreadMessagesCount(initialState.chat.rooms)
      : 0;
  const intervalId = setInterval(() => {
    const newState = getState();
    if (!newState.common.customTitle) {
      const newUnreadMessagesCount =
        newState.chat && newState.chat.rooms ? getUnreadMessagesCount(newState.chat.rooms) : 0;
      if (newState.rtc.incomingCall) {
        dispatch(setTitle(newState.intl.formatMessage(IncomingCallMessages.IncomingCallTitle)));
      } else if (newUnreadMessagesCount > initialUnreadMessagesCount) {
        const unreadMessagesTitle = new IntlMessageFormat(
          newState.intl.formatMessage(titleMessages.NewMessagesCountTitle),
          newState.intl.locale
        );
        const output = unreadMessagesTitle.format({
          newMessagesCount: newUnreadMessagesCount - initialUnreadMessagesCount
        });
        dispatch(setTitle(output));
      }
    } else {
      dispatch(setTitle());
    }
  }, notificationInTitleInterval);
  dispatch(setChangeTitleInterval(intervalId));
};

export const pageFocused = () => (dispatch: Dispatch<Action>, getState: () => AppState) => {
  const pageHasFocus = getState().layout.pageHasFocus;
  if (pageHasFocus) {
    return;
  }
  dispatch(setPageVisibility(true));
  const state = getState();
  if (state.common.changeTitleInterval) {
    clearInterval(state.common.changeTitleInterval);
    dispatch(setChangeTitleInterval());
  }
  if (state.common.customTitle) {
    dispatch(setTitle());
  }
};

export interface BatchMediaAction extends Action {
  id?: string;
  mediaType?: 'audio' | 'video';
  context?: MediaContext;
}

export interface SetSelectionAction extends Action {
  selection: SelectionData;
}

export const batchMedia = (
  id?: string,
  mediaType?: MediaType,
  context?: MediaContext
): BatchMediaAction => ({
  type: BATCH_MEDIA,
  id,
  mediaType,
  context
});

export type PublishPointerData = {
  courseId: number;
  url: string;
  partnerSession: number;
  exerciseId?: string;
  widgetId?: string;
  editorId?: string;
  range?: object;
  elementId?: string;
  relatedElement?: string;
};

type PublishUrlParam = {
  courseId: number;
  url: string;
  partnerSession: number;
  id?: string;
  mediaType?: MediaType;
  mediaContext?: MediaContext;
};

export const publishOpenExercise = (
  o: Omit<PublishUrlParam, 'id' | 'mediaType'>
): WampPublishAction<[string], {}> => {
  return {
    type: PUBLISH_EXERCISE_URL_OPEN,
    wamp: {
      method: 'publish',
      uri: `classroom:course._${o.courseId}.event.url.open.exercise`,
      options: {
        eligible: [o.partnerSession]
      },
      args: [o.url]
    }
  };
};

export const publishOpenUrl = ({
  courseId,
  id,
  partnerSession,
  url,
  mediaType,
  mediaContext
}: PublishUrlParam): WampPublishAction<Array<string | MediaContext>, {}> => ({
  type: PUBLISH_OPEN_URL,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.url.open`,
    options: {
      eligible: [partnerSession]
    },
    args:
      id && mediaType
        ? mediaContext
          ? [url, id, mediaType, mediaContext]
          : [url, id, mediaType]
        : [url]
  }
});

export const publishUrlOpened = (
  courseId: number,
  partnerSession: number,
  id?: string,
  mediaType?: MediaType,
  context?: MediaContext
): WampPublishAction => ({
  type: PUBLISH_URL_OPENED,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.url.opened`,
    options: {
      eligible: [partnerSession]
    },
    args: id ? [id, mediaType, context] : []
  }
});

export const setOpeningUrlForPartner = (): Action => ({
  type: SET_OPENING_URL_FOR_PARTNER
});

export const urlOpened = (): Action => ({
  type: URL_OPENED
});

export const setIncorrectUrlId = (): Action => ({
  type: SET_INCORRECT_URL_ID
});

export interface SetRedirectedFromUrlAction extends Action {
  url: string;
  fromWhere: string;
}

export const setRedirectedFromUrl = (
  url: string,
  fromWhere: string
): SetRedirectedFromUrlAction => ({
  type: SET_REDIRECTED_FROM_URL,
  url,
  fromWhere
});

export const clearRedirectedFromUrl = (): Action => ({
  type: CLEAR_REDIRECTED_FROM_URL
});
export const requestUnitInstances = (
  coursebookInstanceId: string,
  expandOverview?: boolean
): AxiosRequestAction => ({
  type: REQUEST_UNIT_INSTANCES,
  payload: {
    client: 'v2',
    request: {
      method: 'get',
      url: `/v2/unit-instance`,
      params: {
        coursebookInstanceId,
        ...(expandOverview ? {expand: 'overview'} : {})
      }
    }
  }
});

export const setPointerSelection = (selection: SelectionData) => ({
  type: SET_POINTER_SELECTION,
  selection
});

export const clearPointerSelection = () => ({type: CLEAR_POINTER_SELECTION});

export const setPublishingPointer = (): Action => ({
  type: SET_PUBLISHING_POINTER
});

export const setLastUnitPage = (unitInstanceId: string, page: number): SetLastUnitPageAction => ({
  type: SET_LAST_VISITED_UNIT_PAGE,
  unitInstanceId,
  page
});

export const urlOpenedPointer = (): Action => ({
  type: URL_OPENED_POINTER
});

export const publishPointerUrl = (
  pointer: PublishPointerData
): WampPublishAction<[string], {pointer: PublishPointerData}> => {
  return {
    type: PUBLISH_POINTER_URL,
    wamp: {
      method: 'publish',
      uri: `classroom:course._${pointer.courseId}.event.url.open.pointer`,
      options: {
        eligible: [pointer.partnerSession]
      },
      args: [pointer.url],
      kwargs: {pointer}
    }
  };
};

export const publishPointerOpened = (
  courseId: number,
  partnerSession: number
): WampPublishAction => ({
  type: PUBLISH_POINTER_URL_OPENED,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.url.opened.pointer`,
    options: {
      eligible: [partnerSession]
    }
  }
});

export const setOpeningExerciseForPartner = (): Action => ({
  type: SET_OPENING_EXERCISE_FOR_PARTNER
});

export const urlOpenedExercise = (): Action => ({
  type: URL_OPENED_EXERCISE
});

export const publishExerciseOpened = (
  courseId: number,
  partnerSession: number
): WampPublishAction => ({
  type: PUBLISH_EXERCISE_URL_OPENED,
  wamp: {
    method: 'publish',
    uri: `classroom:course._${courseId}.event.url.opened.exercise`,
    options: {
      eligible: [partnerSession]
    }
  }
});
