import React from 'react';
import classNames from 'classnames';

import {type ThicknessName} from 'components/Paint/Painter/plugins';
import Icon from 'components/Icon';

import './PresetEntry.scss';

function getIconName(name: ThicknessName) {
  if (name === 'medium') return 'virc-line';

  return `virc-line-${name}`;
}

interface Props {
  name: ThicknessName;
  active?: boolean;
  onSelect: (name: ThicknessName) => void;
}

export const PresetEntry: React.FC<Props> = ({name, active, onSelect}) => {
  const handlerClick = () => onSelect(name);

  const iconName = getIconName(name);

  return (
    <div className={classNames('preset-entry', {active})} onClick={handlerClick}>
      <Icon name={iconName} size="xlg" />
    </div>
  );
};
