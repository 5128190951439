import React, {type FC} from 'react';
import Highlighter from 'react-highlight-words';

import Icon from 'components/Icon';

import {type DictionaryEntryInstance, type DictionaryList} from '../interface';
import {Controls} from './Controls/WordEntryControls';
import {Original} from './Original/Original';
import {WordEntryLists} from './WordEntryLists';

import './WordEntry.scss';

interface Props {
  dictionaryIsReadonly: boolean;
  list?: DictionaryList;
  search?: string[];
  wordEntry: DictionaryEntryInstance;
  hasLists: boolean;
}

export const WordEntry: FC<Props> = React.memo(
  ({dictionaryIsReadonly, list, search, wordEntry, hasLists}) => {
    return (
      <div className="word-entry">
        <div className="word-entry-main">
          <Original search={search} wordEntry={wordEntry} />

          <div className="translation">
            {search ? (
              <Highlighter
                highlightClassName="highlight"
                searchWords={[search[0]]}
                textToHighlight={wordEntry.dictionaryEntry.translation}
                autoEscape={true}
              />
            ) : (
              wordEntry.dictionaryEntry.translation
            )}
          </div>
        </div>
        <div className="word-entry-side">
          <WordEntryLists
            id={wordEntry.id}
            listsMember={wordEntry.listsMember}
            alone={dictionaryIsReadonly && !wordEntry.exerciseInstanceId}
          />
          {wordEntry.exerciseInstanceId && (
            <Icon name="pc-book" className="is-from-coursebook-indicator" />
          )}
          {!dictionaryIsReadonly && (
            <Controls list={list} wordEntry={wordEntry} hasLists={hasLists} />
          )}
        </div>
      </div>
    );
  }
);
