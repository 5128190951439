import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import Tooltip from 'rc-tooltip';
import {useIntl} from 'react-intl';
import {type ReactPaint, useFocused, usePaint} from '@englex/paint-react';

import Icon from 'components/Icon';

import {ToolbarButton} from './ToolbarButton';
import isShortcut, {type Shortcut} from '../../../../helpers/shortcut';
import {buttonTitles} from '../titles';
import {ScalePicker} from '../ScalePicker';

import './ScaleButton.scss';

interface Props {
  scale?: number;
  fitToWidth?: boolean;
  setScale?: (scale: number) => void;
  setFitToWidth?: (fitToWidth: boolean) => void;
  showSlider?: boolean;
  shortcut?: Shortcut;
}

export const ScaleButton: FC<Props> = ({
  scale,
  setScale,
  setFitToWidth,
  fitToWidth,
  showSlider = false,
  shortcut = 'mod+opt+z'
}) => {
  const isFocused = useFocused();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const intl = useIntl();
  const paint = usePaint<ReactPaint>();
  const ref = useRef<HTMLSpanElement | null>(null);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // TODO: remove code duplication for toolbar tooltip handlers (see ThicknessButton)
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!isFocused) {
        return;
      }

      if (isShortcut(e, shortcut)) {
        e.preventDefault();
        buttonRef.current?.focus();
        buttonRef.current?.click();
      }
      if (tooltipVisible && isShortcut(e, 'esc')) {
        setTooltipVisible(false);
        buttonRef.current?.focus();
      }
    },
    [isFocused, shortcut, tooltipVisible]
  );

  useEffect(() => {
    if (!isFocused && tooltipVisible) {
      setTooltipVisible(false);
    }
  }, [isFocused, tooltipVisible]);

  useEffect(() => {
    window.addEventListener('keydown', onKeyDown);
    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  });

  return (
    <Tooltip
      overlay={() => (
        <ScalePicker
          paint={paint}
          scale={scale}
          setScale={setScale}
          tooltipVisible={tooltipVisible}
          setTooltipVisible={setTooltipVisible}
          toggleRef={buttonRef}
          fitToWidth={fitToWidth}
          setFitToWidth={setFitToWidth}
          showSlider={showSlider}
        />
      )}
      visible={tooltipVisible}
      onVisibleChange={setTooltipVisible}
      destroyTooltipOnHide={true}
      getTooltipContainer={() => ref.current || document.body}
      placement="bottom"
      trigger={['click']}
    >
      <span ref={ref}>
        <ToolbarButton
          ref={buttonRef}
          className={classNames('painter-scale-button', {active: tooltipVisible})}
          title={intl.formatMessage(buttonTitles.scale)}
          shortcut={shortcut}
        >
          <Icon name="search-plus" />
        </ToolbarButton>
      </span>
    </Tooltip>
  );
};
