import {Value} from '@englex/slate';
import {Map} from 'immutable';
import {defineMessages, type IntlShape, type MessageDescriptor} from 'react-intl';
import * as yup from 'yup';

import {valueFromText} from 'components/Slate/utils';
import {
  type MediaMeta,
  WidgetMediaType,
  type WidgetToJSONOptions,
  WidgetType
} from 'store/exercise/player/interface';
import {type WidgetFormattedTextJSON} from 'store/exercise/player/widgets/FormattedText/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {exerciseExcerptLength} from 'config/static';

import XWidgetRecord from '../XWidgetRecord';
import {documentNotEmpty} from '../validation';
import validationMessages from '../i18n';
import {type ImageBlock, SlateBlock} from '../../../../../components/Slate/interface';
import {taskExcerpt, contentExcerpt} from '../excerpt';
import {type XFormattedTextProperties} from './interface';
import {type DisplayButtonProperties} from '../../../player/DisplayButtonRecord';

const messages = defineMessages({
  placeholderRequired: {
    id: 'XEditor.XWidget.Task.PlaceholderRequired'
  }
});

class XFormattedTextRecord<O = unknown>
  extends XWidgetRecord<WidgetFormattedTextJSON, O>
  implements XFormattedTextProperties
{
  public declare readonly content: Value;

  constructor(raw: WidgetFormattedTextJSON) {
    super(raw);
    this.initValues({
      content: Value.fromJSON(raw.content)
    });
  }

  public toJSON(options?: WidgetToJSONOptions): WidgetFormattedTextJSON {
    return {
      ...super.toJSON(options),
      content: this.content.toJSON()
    };
  }

  public get type() {
    return WidgetType.FORMATTED_TEXT;
  }

  public get taskPlaceholder(): MessageDescriptor | undefined {
    return this.type === WidgetType.FORMATTED_TEXT
      ? documentNotEmpty(this.content.document)
        ? undefined
        : messages.placeholderRequired
      : undefined;
  }

  public get excerpt(): string {
    const task = taskExcerpt(this.task);
    if (task.length >= exerciseExcerptLength) {
      return task;
    }
    const text = contentExcerpt(this.content, task.length);
    return `${task} ${text}`.trim();
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Content should not be empty',
          intl.formatMessage(validationMessages.ConditionalNonEmpty),
          (v: Value) => documentNotEmpty(v.document) || documentNotEmpty(this.task.document)
        ),
      displayButton: yup
        .mixed()
        .test(
          'Button title should not be empty',
          intl.formatMessage(validationMessages.ButtonTitleNonEmpty),
          (displayButton: DisplayButtonProperties) =>
            displayButton ? documentNotEmpty(displayButton.title.document) : true
        )
    }) as yup.ObjectSchema<Partial<unknown>>;
  }

  public get media(): MediaMeta | undefined {
    const imageBlocks = this.content.document.getBlocksByType(SlateBlock.IMAGE);
    if (imageBlocks.size) {
      const ids = imageBlocks.map((ib: ImageBlock) => ib.data.get('id')).toSet();
      return Map({[WidgetMediaType.IMAGES]: ids}) as MediaMeta;
    }
    return undefined;
  }
}

decorate(XFormattedTextRecord, {
  content: property(valueFromText())
});
record()(XFormattedTextRecord);

export default XFormattedTextRecord;
