import React from 'react';
import Nav from 'react-bootstrap/lib/Nav';
import Navbar from 'react-bootstrap/lib/Navbar';
import NavItem from 'react-bootstrap/lib/NavItem';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {type Action, type Dispatch} from 'redux';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Link, matchPath, type Pathname} from 'react-router-dom';

import {
  type AppState,
  type AvatarUrl,
  CONNECTED,
  type Role,
  type UserStatus
} from 'store/interface';

import {dashboardUrl} from '../../helpers/url';
import ContactsNavItem from '../containers/ContactsNavItem';
import EnglexDropdown from '../containers/EnglexDropdown';
import MessageModal from '../containers/MessageModal';
import SelectedDevicesIcon from '../../routes/ClassRoom/components/MediaSettingsWizard/SelectDevicesIcon';
import {
  Divider,
  Logout,
  MethodistBeta,
  News,
  NotificationsMenuItem,
  Profile,
  WrappedHistoryMenuItem,
  WrappedPaymentsMenuItem,
  WrappedTeachersCornerMenuItem
} from './DropdownItemsLibrary';
import Brand from './Brand';
import Icon from '../../components/Icon';
import {closeNavDropdown, toggleNavDropdown, toggleUserDropdown} from '../actions/action';
import {type ToggleElementCreator} from '../../common/interface';
import ConnectionIndicator from './ConnectionIndicator';
import Avatar from '../../components/Avatar';
import Report from './ReportDropdown';
import LanguageSelector from './LanguageSelector';
import {isSampleLicenceStudent} from '../../hooks/user/useIsSampleLicenseStudent';
import {isSampleLicenceTeacher} from '../../hooks/user/useIsSampleLicenseTeacher';

interface NavbarOwnProps {
  collapsed: boolean;
  collapseLayout: (collapse: boolean) => void;
  deviceIsMobile?: boolean;
  notifications?: number;
}

interface NavbarStateProps {
  avatar: AvatarUrl;
  status: UserStatus;
  pathname: Pathname;
  profileDropdownOpen: boolean;
  navDropdownOpen: boolean;
  role: Role;
  appOnline?: boolean;
  tokenForBeta?: string;
  intensiveHidden?: boolean; // TODO: remove me later
  isSampleLicenceTeacher?: boolean; // TODO: remove me later
}

interface NavbarDispatchProps {
  toggleNavDropdown: ToggleElementCreator;
  closeNavDropdown: () => void;
  toggleProfileDropdown: ToggleElementCreator;
}

interface Props
  extends NavbarOwnProps,
    NavbarStateProps,
    NavbarDispatchProps,
    WrappedComponentProps {}

class EnglexNavbar extends React.PureComponent<Props, {}> {
  private get isMethodist() {
    return this.props.role === 'methodist';
  }

  private get isStudent() {
    return this.props.role === 'student';
  }

  private get isDictionaryRoute() {
    return !!this.props.pathname.match(/^\/dictionary/);
  }

  private get isHomeworkRoute() {
    return !!this.props.pathname.match(/^\/homework/);
  }

  private get isFeedbackRoute() {
    return !!this.props.pathname.match(/^\/library\/feedback/);
  }

  private get isLibraryRoute() {
    return !!this.props.pathname.match(/^\/library/);
  }

  private get isRoomRoute() {
    return !!this.props.pathname.match(/^\/room/);
  }

  public render() {
    const {
      collapsed,
      deviceIsMobile,
      navDropdownOpen,
      profileDropdownOpen,
      avatar,
      status,
      appOnline,
      pathname,
      isSampleLicenceTeacher
    } = this.props;
    const userOnline = status === 'online' && appOnline;
    const ProfileDropdownIcon = (
      <div className={classNames('profile-dropdown-icon', {mobile: deviceIsMobile})}>
        <Icon name="pc-menu-collapse" />
        <div className="profile-name">
          <div className="header-avatar">
            <Avatar
              size={28}
              url={avatar}
              className="profile-avatar"
              mask={userOnline ? undefined : 'default'}
            />
            <ConnectionIndicator />
          </div>
        </div>
      </div>
    );

    return (
      <Navbar>
        {!collapsed && (
          <>
            <Navbar.Header>
              <Brand
                deviceIsMobile={deviceIsMobile}
                toggle={this.props.toggleNavDropdown}
                close={this.props.closeNavDropdown}
                dropdownOpen={navDropdownOpen}
                isMethodist={this.isMethodist}
                isStudent={this.isStudent}
                pathname={pathname}
              />
            </Navbar.Header>
            <Nav className="visible-lg">
              {this.renderDashboardLink()}
              {this.renderOnlineClassLink()}
              {this.renderDictionaryLink()}
              {this.renderHomeworkLink()}
              {this.isStudent && this.renderIntensive()}
              {this.isStudent && this.renderSkills()}
              {this.renderFeedbackLink()}
            </Nav>
            <Nav pullRight={true}>
              <LanguageSelector showCloseBtn={true} />
              <Report />
              {this.isStudent && this.renderOfferButton()}
              {this.renderCollapseButton()}
              <SelectedDevicesIcon />
              {!this.isMethodist && <ContactsNavItem isStudent={this.isStudent} />}
              <EnglexDropdown
                title={ProfileDropdownIcon}
                toggle={this.props.toggleProfileDropdown}
                dropdownOpen={profileDropdownOpen}
                id="profile-dropdown"
              >
                <Profile />
                {!isSampleLicenceTeacher && <News />}
                <NotificationsMenuItem
                  notificationsNumber={this.props.notifications}
                  isMobile={deviceIsMobile}
                />
                {this.props.tokenForBeta && <MethodistBeta token={this.props.tokenForBeta} />}
                {!isSampleLicenceTeacher && <WrappedTeachersCornerMenuItem />}
                <WrappedPaymentsMenuItem />
                <WrappedHistoryMenuItem />
                <Divider />
                <Logout />
              </EnglexDropdown>
              <MessageModal />
            </Nav>
          </>
        )}
      </Navbar>
    );
  }

  private renderCollapseButton = () => {
    const {
      collapseLayout,
      deviceIsMobile,
      intl: {formatMessage}
    } = this.props;
    if (
      (this.isRoomRoute || this.isHomeworkRoute || this.isDictionaryRoute) &&
      !this.isMethodist &&
      !deviceIsMobile
    ) {
      return (
        <NavItem
          className="collapse-button"
          onClick={() => collapseLayout(true)}
          title={formatMessage({id: 'File.ExpandWorkspaceToFullScreen'})}
        >
          <Icon name="virc-no_clutter" />
        </NavItem>
      );
    }
    return null;
  };

  private renderOfferButton = () => {
    const {
      intl: {formatMessage}
    } = this.props;
    const href = dashboardUrl('/invite-your-friend');
    return href ? (
      <NavItem href={href} title={formatMessage({id: 'Header.SpecialOffer'})}>
        <Icon name="giftbox" size="lg" />
      </NavItem>
    ) : null;
  };

  private renderHomeworkLink = () => {
    if (this.isMethodist) {
      return null;
    }
    const {pathname} = this.props;
    const props = {to: '/homework'};
    const active = !!matchPath('/homework/*', pathname);
    return (
      <NavItem componentClass={Link} active={active} disabled={active} href={props.to} {...props}>
        <FormattedMessage id="LessonPage.Homework" />
      </NavItem>
    );
  };

  private renderDictionaryLink = () => {
    if (this.isMethodist) return null;
    const {pathname} = this.props;
    const props = {to: '/dictionary'};
    const active = !!matchPath('/dictionary/*', pathname);
    return (
      <NavItem componentClass={Link} active={active} disabled={active} href={props.to} {...props}>
        <FormattedMessage id="Vocabulary" />
      </NavItem>
    );
  };

  private renderDashboardLink = () => {
    const href = dashboardUrl('');
    if (href && !this.props.deviceIsMobile) {
      return (
        <NavItem href={href}>
          <FormattedMessage id="Header.Dashboard" />
        </NavItem>
      );
    }
    return null;
  };

  private renderFeedbackLink = () => {
    if (!this.isMethodist) {
      return null;
    }
    const props = {to: '/library/feedback'};
    const active = this.isFeedbackRoute;
    return (
      <NavItem componentClass={Link} active={active} href={props.to} {...props}>
        <FormattedMessage id="Feedback.Feedback" />
      </NavItem>
    );
  };

  private renderOnlineClassLink = () => {
    const props = {to: this.isMethodist ? '/library' : '/room'};
    const active = this.isMethodist
      ? this.isLibraryRoute && !this.isFeedbackRoute
      : this.isRoomRoute;
    return !this.props.deviceIsMobile ? (
      <NavItem componentClass={Link} active={active} disabled={active} href={props.to} {...props}>
        <FormattedMessage id="Header.Classroom" />
      </NavItem>
    ) : null;
  };

  private renderSkills = () => {
    const href = dashboardUrl('/speakingclub/speakingclub/index/');
    if (!href || this.isMethodist || this.props.deviceIsMobile) {
      return null;
    }
    return (
      <NavItem href={href}>
        Skills
        <i className="label label-success skills-icon-label">new</i>
      </NavItem>
    );
  };

  private renderIntensive = () => {
    const href = dashboardUrl('/student/intensiveUser/intensive');
    if (!href || this.isMethodist || this.props.intensiveHidden) return null;
    return (
      <NavItem href={href}>
        <FormattedMessage id="Header.Intensive" />
      </NavItem>
    );
  };
}

const mapStateToProps = (state: AppState): NavbarStateProps => {
  return {
    tokenForBeta:
      import.meta.env.REACT_APP_BETA_URL && state.user.token ? state.user.token : undefined,
    navDropdownOpen: !!state.layout.navDropdownOpen,
    profileDropdownOpen: !!state.layout.userDropdownOpen,
    avatar: state.user.profile!.avatars.xs,
    status: state.wamp.status === CONNECTED ? ('online' as UserStatus) : ('offline' as UserStatus),
    pathname: state.router.location!.pathname,
    role: state.user.role!,
    intensiveHidden: isSampleLicenceStudent(state),
    isSampleLicenceTeacher: isSampleLicenceTeacher(state),
    appOnline: state.layout.appOnline
  };
};

const mapDispatchToProps = (dispatch: Dispatch<Action>): NavbarDispatchProps => ({
  toggleNavDropdown: (show: boolean) => dispatch(toggleNavDropdown(show)),
  closeNavDropdown: () => dispatch(closeNavDropdown()),
  toggleProfileDropdown: (show: boolean) => dispatch(toggleUserDropdown(show))
});

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EnglexNavbar));
