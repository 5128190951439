import {type Locale} from 'store/interface';
export const RUSSIAN: Locale = 'ru';
export const ENGLISH: Locale = 'en';

const languages: {[key: string]: string} = {
  [RUSSIAN]: 'Русский',
  [ENGLISH]: 'English'
};

export default languages;
