import React from 'react';
import {FormattedMessage, type IntlShape} from 'react-intl';

import {imageMaxResolution, maxImageSize, megaByteMultiplier} from 'config/static';

import {UploadingPictureStatus, UploadingPictureValidationError} from './interface';

export interface ValidatedImageFile {
  file: File;
  md5: string;
}

interface ChatUploadingImage extends ValidatedImageFile {
  roomId: number;
  id?: number;
}

const getImageValidationError = (file: File, minResolution?: number | [number, number]) =>
  new Promise<null | UploadingPictureValidationError>(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const image = new Image();
      if (reader.result === null) {
        resolve(UploadingPictureValidationError.FILE_CORRUPTED);
      } else {
        image.src = reader.result as string;
        image.onerror = () => resolve(UploadingPictureValidationError.FILE_CORRUPTED);
        image.onload = () => {
          if (image.width > imageMaxResolution || image.height > imageMaxResolution) {
            resolve(UploadingPictureValidationError.INVALID_IMAGE_SIZE);
          }
          if (minResolution) {
            if (typeof minResolution === 'number') {
              if (image.width < minResolution || image.height < minResolution) {
                resolve(UploadingPictureValidationError.IMAGE_SIZE_TOO_SMALL);
              }
            }
            if (Array.isArray(minResolution)) {
              if (image.width < minResolution[0] || image.height < minResolution[1]) {
                resolve(UploadingPictureValidationError.IMAGE_SIZE_TOO_SMALL);
              }
            }
          }
          resolve(null);
        };
      }
    };
  });

export const validateImageFile = async (
  file: File,
  md5: string,
  roomId?: number,
  files?: ChatUploadingImage[],
  minResolution?: number | [number, number]
) => {
  const {name, size} = file;
  if (!name.match(/\.(png|jpe?g|bmp)$/i)) {
    return UploadingPictureValidationError.INVALID_EXTENSION;
  }
  if (size > maxImageSize) {
    return UploadingPictureValidationError.FILE_TOO_BIG;
  }
  if (size === 0) {
    return UploadingPictureValidationError.EMPTY_FILE;
  }
  if (files && roomId) {
    if (files.find(el => el && el.md5 === md5 && el.roomId === roomId)) {
      return UploadingPictureValidationError.ALREADY_UPLOADING;
    }
  }
  const validationError = await getImageValidationError(file, minResolution);
  if (!validationError) {
    return UploadingPictureStatus.PREPARING;
  }
  return validationError;
};

export const getStatusText = (
  {formatMessage}: IntlShape,
  status: UploadingPictureStatus | UploadingPictureValidationError,
  imageMinResolution?: number | [number, number]
) => {
  switch (status) {
    case UploadingPictureValidationError.ALREADY_UPLOADING:
      return formatMessage({id: 'Chat.FileIsAlreadyUploading'});
    case UploadingPictureValidationError.EMPTY_FILE:
      return formatMessage({id: 'Chat.EmptyFile'});
    case UploadingPictureValidationError.INVALID_EXTENSION:
      return formatMessage({id: 'Chat.InvalidImageExtension'});
    case UploadingPictureValidationError.FILE_TOO_BIG:
      return formatMessage({id: 'Chat.IvalidFileSize'}, {size: maxImageSize / megaByteMultiplier});
    case UploadingPictureValidationError.INVALID_IMAGE_SIZE:
      return formatMessage({id: 'Chat.IvalidImageSize'}, {resolution: imageMaxResolution});
    case UploadingPictureStatus.UPLOADING_ERROR:
      return formatMessage({id: 'Chat.UploadError'});
    case UploadingPictureValidationError.FILE_CORRUPTED:
      return formatMessage({id: 'Chat.ImageCorrupted'});
    case UploadingPictureValidationError.IMAGE_SIZE_TOO_SMALL:
      let [width, height] = [150, 70];
      if (imageMinResolution) {
        if (typeof imageMinResolution === 'number') {
          [width, height] = [imageMinResolution, imageMinResolution];
        } else {
          width = imageMinResolution[0] ? imageMinResolution[0] : 150;
          height = imageMinResolution[1] ? imageMinResolution[1] : 70;
        }
      }
      return formatMessage({id: 'Slate.Modal.Image.ImageTooSmallMessage'}, {width, height});
    default:
      return formatMessage({id: 'Chat.UploadingImage'});
  }
};

export const renderStatusText = (
  status: UploadingPictureStatus | UploadingPictureValidationError,
  imageMinResolution?: number | [number, number]
) => {
  switch (status) {
    case UploadingPictureValidationError.ALREADY_UPLOADING:
      return <FormattedMessage id="Chat.FileIsAlreadyUploading" />;
    case UploadingPictureValidationError.EMPTY_FILE:
      return <FormattedMessage id="Chat.EmptyFile" />;
    case UploadingPictureValidationError.INVALID_EXTENSION:
      return <FormattedMessage id="Chat.InvalidImageExtension" />;
    case UploadingPictureValidationError.FILE_TOO_BIG:
      return (
        <FormattedMessage
          id="Chat.IvalidFileSize"
          values={{size: maxImageSize / megaByteMultiplier}}
        />
      );
    case UploadingPictureValidationError.INVALID_IMAGE_SIZE:
      return (
        <FormattedMessage id="Chat.IvalidImageSize" values={{resolution: imageMaxResolution}} />
      );
    case UploadingPictureStatus.UPLOADING_ERROR:
      return <FormattedMessage id="Chat.UploadError" />;
    case UploadingPictureValidationError.FILE_CORRUPTED:
      return <FormattedMessage id="Chat.ImageCorrupted" />;
    case UploadingPictureValidationError.IMAGE_SIZE_TOO_SMALL:
      let [width, height] = [150, 70];
      if (imageMinResolution) {
        if (typeof imageMinResolution === 'number') {
          [width, height] = [imageMinResolution, imageMinResolution];
        } else {
          width = imageMinResolution[0] ? imageMinResolution[0] : 150;
          height = imageMinResolution[1] ? imageMinResolution[1] : 70;
        }
      }
      return (
        <FormattedMessage id="Slate.Modal.Image.ImageTooSmallMessage" values={{width, height}} />
      );
    default:
      return <FormattedMessage id="Chat.UploadingImage" />;
  }
};
