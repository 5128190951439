import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Button from 'react-bootstrap/lib/Button';

import {
  type AxiosRequestError,
  type AxiosResponseAction,
  type UploadProgressHandler
} from 'services/axios/interface';
import {type EnglexImage} from 'store/interface';
import {
  type Dispatch,
  type WampAction,
  type WampCallResponseAction
} from 'services/wamp/actions/interface';

import {type ChatUploadingImage} from '../interface';
import Icon from '../../../../Icon';
import Spinner from '../../../../Spinner';
import {createThumbnail, imageExistRequest, postImage, postImageMessage} from './action';
import {renderStatusText} from '../../../../../common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from '../../../../../common/ImageUpload/interface';
import './uploading-image.scss';

interface DispatchUploadingImageProps {
  imageExistRequest: () => Promise<AxiosResponseAction<EnglexImage>>;
  setStatus: (status?: UploadingPictureStatus) => void;
  postImage: (
    data: FormData,
    onUploadProgress: UploadProgressHandler
  ) => Promise<AxiosResponseAction<EnglexImage>>;
  createThumbnail: (id: number) => Promise<AxiosResponseAction<EnglexImage>>;
  setId: (id: number) => void;
  postImageMessage: (thumbId: number) => Promise<WampCallResponseAction<{}, {}, {}>>;
}

interface UploadingImageOwnProps {
  image: ChatUploadingImage;
  setStatusByPosition: (position: number, status?: UploadingPictureStatus) => void;
  position: number;
  setIdByPosition: (position: number, id: number) => void;
}

interface UploadingImageProps extends DispatchUploadingImageProps, UploadingImageOwnProps {}

interface UploadingImageState {
  percentsUploaded: number;
}

class UploadingImage extends React.Component<UploadingImageProps, UploadingImageState> {
  public state: UploadingImageState = {percentsUploaded: 0};

  public componentDidMount() {
    if (!(this.props.image.status in UploadingPictureValidationError)) {
      this.checkFileExistence();
    }
  }

  public render() {
    const {image} = this.props;
    if (image.status === UploadingPictureStatus.FINISHED) {
      return null;
    }
    return (
      <div>
        <div className="chat-message text own">
          <div
            className={`chat-message-text text own uploading-image ${
              this.hasError() ? 'error' : ''
            }`}
          >
            <span className={`header ${this.hasError() ? 'error' : ''}`}>
              {this.renderIcon()}
              {renderStatusText(image.status)}
            </span>
            <div className="body">
              <span className="file-name">{image.file.name}</span>
              <span className="percentage">{this.renderPercentageOrSpinner()}</span>
            </div>
            {this.renderScale()}
            {this.renderControlButtons()}
          </div>
          <div className="chat-message-arrow own" />
        </div>
      </div>
    );
  }

  private renderPercentageOrSpinner = () => {
    const status = this.props.image.status;
    if (
      this.props.image.status in UploadingPictureValidationError ||
      this.props.image.status === UploadingPictureStatus.UPLOADING_ERROR
    ) {
      return null;
    }
    if (
      status === UploadingPictureStatus.PREPARING ||
      status === UploadingPictureStatus.CLONING ||
      this.state.percentsUploaded === 100
    ) {
      return <Spinner size={15} />;
    }
    return `${this.state.percentsUploaded}%`;
  };

  private renderScale = () => {
    const status = this.props.image.status;
    if (
      status === UploadingPictureStatus.UPLOADING ||
      status === UploadingPictureStatus.UPLOADING_ERROR
    ) {
      return (
        <div className={`uploading-scale ${this.hasError() ? 'error' : ''}`}>
          <div style={{width: `${this.state.percentsUploaded}%`}} />
        </div>
      );
    }
    return null;
  };

  private hasError = () => {
    const {image} = this.props;
    return (
      image.status in UploadingPictureValidationError ||
      image.status === UploadingPictureStatus.UPLOADING_ERROR
    );
  };

  private renderIcon = () => {
    if (this.props.image.status in UploadingPictureValidationError) {
      return null;
    }
    if (this.hasError()) {
      return <Icon name="times-circle" size="lg" />;
    }
    return <Icon name="virc-upload" size="lg" />;
  };

  private renderControlButtons = () => {
    if (this.hasError()) {
      return (
        <div className="buttons">
          <Button bsStyle="default" bsSize="xs" onClick={this.removeFromUploads}>
            <FormattedMessage id="Common.Delete" />
          </Button>
          {this.renderSecondButton()}
        </div>
      );
    }
    return null;
  };

  private removeFromUploads = () => {
    this.props.setStatus(UploadingPictureStatus.FINISHED);
  };

  private renderSecondButton = () => {
    if (this.props.image.status === UploadingPictureStatus.UPLOADING_ERROR) {
      return (
        <Button bsStyle="primary" bsSize="xs" onClick={this.retry}>
          <FormattedMessage id="Common.Retry" />
        </Button>
      );
    }
    return null;
  };

  private retry = () => {
    this.props.setStatus(UploadingPictureStatus.PREPARING);
    this.checkFileExistence();
  };

  private checkFileExistence = () => {
    this.props.imageExistRequest().then(this.cloneFile).catch(this.handleImageExistRequestFail);
  };

  private handleImageExistRequestFail = (action: AxiosRequestError) => {
    if (action.error.response && action.error.response.status === 404) {
      this.uploadFile();
    } else {
      this.props.setStatus(UploadingPictureStatus.UPLOADING_ERROR);
    }
  };

  private cloneFile = async (response: AxiosResponseAction<EnglexImage>) => {
    try {
      this.props.setStatus(UploadingPictureStatus.CLONING);
      if (response.payload.data.thumbs!['188x188']) {
        await this.props.postImageMessage(response.payload.data.thumbs!['188x188'].id);
      } else {
        const thumbResult = await this.props.createThumbnail(response.payload.data.id);
        await this.props.postImageMessage(thumbResult.payload.data.id);
      }
      this.removeFromUploads();
    } catch (e) {
      this.props.setStatus(UploadingPictureStatus.UPLOADING_ERROR);
    }
  };

  private uploadFile = async () => {
    try {
      this.props.setStatus(UploadingPictureStatus.UPLOADING);
      const formData = new FormData();
      formData.append('file', this.props.image.file);
      const result = await this.props.postImage(formData, this.progressHandler);
      this.props.setId(result.payload.data.id);
      const thumbResult = await this.props.createThumbnail(result.payload.data.id);
      await this.props.postImageMessage(thumbResult.payload.data.id);
      this.removeFromUploads();
    } catch (e) {
      this.props.setStatus(UploadingPictureStatus.UPLOADING_ERROR);
    }
  };

  private progressHandler = (event: ProgressEvent) => {
    this.setState({
      percentsUploaded: Math.round((event.loaded * 100) / event.total)
    });
  };
}

const mapDispatchToProps = (
  dispatch: Dispatch<AxiosResponseAction<EnglexImage>, WampCallResponseAction<{}, {}, {}>>,
  ownProps: UploadingImageOwnProps
) => ({
  imageExistRequest: () =>
    dispatch<AxiosResponseAction<EnglexImage>>(imageExistRequest(ownProps.image.md5)),
  setStatus: (error: UploadingPictureStatus) =>
    ownProps.setStatusByPosition(ownProps.position, error),
  postImage: (data: FormData, onUploadProgress: UploadProgressHandler) =>
    dispatch<AxiosResponseAction<EnglexImage>>(postImage(data, onUploadProgress)),
  createThumbnail: (id: number) => dispatch<AxiosResponseAction<EnglexImage>>(createThumbnail(id)),
  setId: (id: number) => ownProps.setIdByPosition(ownProps.position, id),
  postImageMessage: (thumbId: number) =>
    dispatch(postImageMessage(ownProps.image.roomId, thumbId) as WampAction)
});

export default connect<{}, DispatchUploadingImageProps, UploadingImageOwnProps>(
  null,
  mapDispatchToProps
)(UploadingImage);
