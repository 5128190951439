import React, {Component} from 'react';
import ReactCrop, {type CompleteCrop, type Crop} from 'react-image-crop';

import {AspectRatio} from './static';

import 'react-image-crop/dist/ReactCrop.css';

interface Props {
  aspectRatio: AspectRatio | number;
  getCrop: (crop: CompleteCrop) => void;
  imageDataUrl: string;
  initialCrop?: CompleteCrop;
  lockAspectRatios?: (aspectRatio: AspectRatio[]) => void;
  minHeight: number;
  minWidth: number;
  setImageSize?: (imageSize: [number, number]) => void;
}

interface State {
  crop: Crop;
  init: boolean;
  temp?: Crop;
}

const makeAspectCrop = (
  crop: Partial<Crop> & {aspect: number},
  minWidth: number,
  minHeight: number,
  maxWidth: number,
  maxHeight: number
): CompleteCrop => {
  const completeCrop = Object.assign(
    {
      unit: 'px',
      x: 0,
      y: 0
    },
    crop
  );

  if (crop.width) {
    completeCrop.height = Math.min(
      Math.max(Math.ceil(crop.width / crop.aspect), minHeight),
      maxHeight
    );
  }

  if (crop.height) {
    completeCrop.width = Math.min(
      Math.max(Math.ceil(crop.height * crop.aspect), minWidth),
      maxWidth
    );
  }

  return completeCrop as CompleteCrop;
};

class CropComponent extends Component<Props, State> {
  public state: State = {
    crop: {unit: 'px', x: 0, y: 0, height: 0, width: 0},
    init: false
  };

  private image: HTMLImageElement | null;
  private imageAspectRatio: number;
  private scale: number;

  private minHeight: number = 0;
  private minWidth: number = 0;

  private cropCanvasWidth = 0;
  private cropCanvasHeight = 0;

  public componentDidUpdate(prevProps: Props) {
    if (this.props.aspectRatio !== prevProps.aspectRatio) {
      this.makeCrop();
    }
  }

  public render() {
    const {imageDataUrl} = this.props;

    const aspect = this.aspectRatioToNumber(this.props.aspectRatio);

    return (
      <div className="dnd-image">
        <ReactCrop
          aspect={aspect}
          crop={this.state.crop}
          keepSelection={true}
          minHeight={this.minHeight}
          minWidth={this.minWidth}
          onChange={this.onCropChange}
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <img src={imageDataUrl} onLoad={this.onImageLoaded} alt="Crop area" />
        </ReactCrop>
      </div>
    );
  }

  private onDragStart = () => {
    this.setState(({crop}) => ({temp: crop}));
  };

  private initCrop = (aspect?: number) => {
    const {
      crop: {x, y, height, width}
    } = this.state;
    const dim = aspect && aspect > 1 ? {height: this.minHeight} : {width: this.minWidth};
    const crop = aspect
      ? makeAspectCrop(
          {aspect, ...dim},
          this.minWidth,
          this.minHeight,
          this.cropCanvasWidth,
          this.cropCanvasHeight
        )
      : {x, y, unit: 'px', height: height || this.minHeight, width: width || this.minWidth};

    this.setState({crop: crop as Crop, init: true}, () =>
      this.props.getCrop(this.scaleCrop(crop as CompleteCrop))
    );
  };

  private scaleCrop = ({x, y, height, width, aspect}: CompleteCrop): CompleteCrop => {
    const [{floor, max, min}, {image, scale}, {minWidth, minHeight}] = [Math, this, this.props];

    // min wrap is usually overhead, but there can occur 1px rounding problem, for example,
    // when image has like 1500*1501 dimensions (or vise versa) addressed here.
    const scaledWidth = max(min(floor(width * scale), image!.naturalWidth), minWidth);
    const scaledHeight = max(min(floor(height * scale), image!.naturalHeight), minHeight);

    const scaledX = max(min(floor(x * scale), image!.naturalWidth - scaledWidth), 0);
    const scaledY = max(min(floor(y * scale), image!.naturalHeight - scaledHeight), 0);

    return {
      unit: 'px',
      x: scaledX,
      y: scaledY,
      width: scaledWidth,
      height: scaledHeight,
      aspect
    };
  };

  private initialCropFromCropResult = ({x, y, height, width}: CompleteCrop): Crop => {
    const [{round}, {scale}] = [Math, this];
    return {
      unit: 'px',
      x: round(x / scale),
      y: round(y / scale),
      width: round(width / scale),
      height: round(height / scale)
    };
  };

  private makeCrop = (mountedAndInitialCrop?: boolean) => {
    const [{aspectRatio, initialCrop}] = [this.props];
    if (mountedAndInitialCrop) {
      this.setState({crop: this.initialCropFromCropResult(initialCrop!), init: true});
    } else {
      this.initCrop(this.aspectRatioToNumber(aspectRatio));
    }
  };

  private onImageLoaded = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const {setImageSize} = this.props;
    const image = event.target as HTMLImageElement;

    this.cropCanvasWidth = image.width;
    this.cropCanvasHeight = image.height;

    setImageSize?.([this.cropCanvasWidth, this.cropCanvasHeight]);

    this.image = image;
    const [{lockAspectRatios, minHeight, minWidth}, {naturalHeight, naturalWidth}, {round}] = [
      this.props,
      image,
      Math
    ];
    this.imageAspectRatio = naturalWidth / naturalHeight;
    this.scale = naturalWidth / image.width;
    this.minHeight = round(minHeight / this.scale);
    this.minWidth = round(minWidth / this.scale);
    if (lockAspectRatios) {
      // locking aspect ratios makes corresponding buttons in CropControls
      // disabled - impossible aspect ratios for given image
      const lockedAspectRatios: AspectRatio[] = [];
      // if image height is less than minHeight * 4 / 3 - AspectRatio.VERTICAL_RECT
      // is impossible to select, so turn off this possibility. I.e. if image height is 120 px -
      // selecting full height with AspectRatio.VERTICAL_RECT will end up having 90px width, which is
      // less than 100px - minWidth
      if (naturalHeight < (minHeight * 4) / 3) {
        lockedAspectRatios.push(AspectRatio.VERTICAL_RECT);
      }
      // if image height is less than minWidth * 4 / 3 - AspectRatio.HORIZONTAL_RECT
      // is impossible to select, so turn off this possibility. I.e. if image width is 120 px -
      // selecting full height with AspectRatio.HORIZONTAL_RECT will end up having 90px height, which is
      // less than 100px - minHeight
      if (naturalWidth < (minWidth * 4) / 3) {
        lockedAspectRatios.push(AspectRatio.HORIZONTAL_RECT);
      }
      if (lockedAspectRatios.length) {
        lockAspectRatios(lockedAspectRatios);
      }
    }

    this.makeCrop(!!this.props.initialCrop);
  };

  private onCropChange = (crop: CompleteCrop) => {
    if (this.cropIsInvalid(crop) || !this.state.init) return;

    this.setState({crop});
  };

  private onDragEnd = () => {
    const {crop, temp} = this.state;
    if (this.cropIsInvalid(crop)) {
      this.setState({crop: temp!, temp: undefined});
      return;
    }
    this.props.getCrop(this.scaleCrop(crop as CompleteCrop));
  };

  private aspectRatioToNumber = (aspectRatio: AspectRatio | number) => {
    if (typeof aspectRatio === 'number') {
      return aspectRatio;
    }
    switch (aspectRatio) {
      case AspectRatio.SQUARE:
        return 1;
      case AspectRatio.SCREEN:
        return 16 / 9;
      case AspectRatio.HORIZONTAL_RECT:
        return 4 / 3;
      case AspectRatio.VERTICAL_RECT:
        return 3 / 4;
      case AspectRatio.RECTANGLE:
        return 97 / 47;
      case AspectRatio.FREE:
        return undefined;
      case AspectRatio.ORIGINAL:
      default:
        return this.imageAspectRatio;
    }
  };

  private cropIsInvalid(crop: Crop) {
    const accuracy = -0.5;

    if (crop.x === undefined || crop.x < accuracy) {
      return true;
    }
    if (crop.y === undefined || crop.y < accuracy) {
      return true;
    }
    if (Math.floor(crop.x + crop.width) > this.cropCanvasWidth) {
      return true;
    }
    if (Math.floor(crop.y + crop.height) > this.cropCanvasHeight) {
      return true;
    }
    if (crop.width === undefined || crop.width < this.minWidth) {
      return true;
    }
    return crop.height === undefined || crop.height < this.minHeight;
  }
}

export default CropComponent;
