import {toastr, type BasicToastrOptions} from 'react-redux-toastr';
import React from 'react';

import Icon from '../Icon';

type ToastrOptions = BasicToastrOptions & {disableCloseButtonFocus?: true};

const defaultSuccessOptions: ToastrOptions = {
  timeOut: 8000,
  removeOnHover: false,
  disableCloseButtonFocus: true,
  icon: <Icon name="pc-check" size="lg" />
};
const defaultErrorOptions: ToastrOptions = {
  removeOnHover: false,
  disableCloseButtonFocus: true,
  icon: <Icon name="warning" />
};

const defaultInfoOptions: ToastrOptions = {
  removeOnHover: false,
  disableCloseButtonFocus: true,
  icon: <Icon name="info" />
};
const defaultWarningOptions: ToastrOptions = {
  removeOnHover: false,
  disableCloseButtonFocus: true,
  icon: <Icon name="exclamation-circle" />
};

export const success = (title: string, message: string, options: ToastrOptions = {}) => {
  toastr.success(title, message, {...defaultSuccessOptions, ...options});
};

export const error = (title: string, message: string, options: ToastrOptions = {}) => {
  toastr.error(title, message, {...defaultErrorOptions, ...options});
};

export const warning = (title: string, message: string, options: ToastrOptions = {}) => {
  toastr.warning(title, message, {...defaultWarningOptions, ...options});
};

export const info = (title: string, message: string, options: ToastrOptions = {}) => {
  toastr.info(title, message, {...defaultInfoOptions, ...options});
};
