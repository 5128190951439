import {type List} from 'immutable';
import {Value} from '@englex/slate';
import {type Descendant} from 'slate';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import FormattedTextRecord from '../../FormattedText/FormattedTextRecord';
import {WidgetType} from '../../../interface';
import {
  type QuestsJSON,
  type QuestsProperties,
  type QuestsValues,
  type QuestsValuesJSON,
  type QuestValueProperties
} from '../interface';
import {getQuestions, initValues} from './utils';

class QuestsRecord
  extends FormattedTextRecord<undefined, QuestsValues>
  implements QuestsProperties
{
  public declare readonly quests: List<Value>;

  public declare readonly questsCountWords?: boolean;

  public declare readonly questsExampleContent?: Value;

  constructor(raw: QuestsJSON & {type?: WidgetType}) {
    super(raw);
    const quests = getQuestions(raw.content);
    this.initValues({
      quests,
      questsCountWords: raw.options && raw.options.countWords,
      questsExampleContent: raw.questsExampleContent
        ? Value.fromJSON(raw.questsExampleContent)
        : undefined,
      values: initValues(quests, raw.values, true)
    });
  }

  public toJSON(): QuestsJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      skipSync: this.skipSync,
      content: this.content.toJSON(),
      values: this.values ? this.values.toJS() : undefined
    };
  }

  public get type() {
    return WidgetType.QUESTS;
  }

  public setValuesFromJSON(values?: QuestsValuesJSON, withOnReview = true) {
    return this.set('values', initValues(this.quests, values, withOnReview));
  }

  public get throttleSendValues(): number {
    return 500;
  }

  public contentChange(value: Descendant[], id: string): this {
    const question: QuestValueProperties = this.getIn(['values', id]);

    if (!question) return this;

    return this.setIn(['values', id], question.contentChange(value));
  }
}

decorate(QuestsRecord, {
  quests: property(),
  questsCountWords: property(false),
  questsExampleContent: property()
});
record()(QuestsRecord);
export default QuestsRecord;
