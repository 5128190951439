import React from 'react';
import {type AxiosAction} from 'redux-axios-middleware';
import {ReactReduxContext} from 'react-redux';

import {type AppState, CONNECTED} from 'store/interface';
import {type ReduxContext} from 'store';

import {
  type Dispatch,
  type WampCallResponseAction,
  type WampErrorAction,
  type WampSubscribeAction,
  type WampSubscribeResponseAction
} from './actions/interface';
import {defaultAutobahnClientName} from './actions/types';

export type GetActions = (getState: () => AppState) => Array<WampSubscribeAction<{}, {}>> | null;
type Actions = Array<
  | WampSubscribeResponseAction<WampSubscribeAction<{}, {}>>
  | WampErrorAction<{}, {}, WampSubscribeAction<{}, {}>>
>;
type MyDispatch = Dispatch<AxiosAction, WampCallResponseAction<{}, {}, {}>>;
export type OnSubscribe = (
  actions: Actions,
  dispatch: MyDispatch,
  allSubscriptions: string[],
  getState: () => AppState
) => void;

function getUri(arg: string | WampSubscribeAction<{}, {}>) {
  if ((arg as WampSubscribeAction<{}, {}>).wamp) {
    return (arg as WampSubscribeAction<{}, {}>).wamp.uri;
  }
  return arg as string;
}

function filterByUri(
  filteredArr: Array<string | WampSubscribeAction<{}, {}>>,
  filteringArr: Array<string | WampSubscribeAction<{}, {}>>
) {
  // returns array of elements of filteredArr which are not present in filteringArr
  // can filter array of URIs by array of actions (will use action.wamp.uri) and conversely
  return filteredArr.filter(
    filteredEl => !filteringArr.find(filteringEl => getUri(filteringEl) === getUri(filteredEl))
  );
}

type SubscribeResponseActions = Array<
  | WampSubscribeResponseAction<WampSubscribeAction<{}, {}>>
  | WampErrorAction<{}, {}, WampSubscribeAction<{}, {}>>
>;

export interface WampAutoSubInjectedProps {
  wampSubscribed: boolean;
}

interface State {
  subscribed: boolean;
}

// todo: handle duplicate subscriptions

type AutoSubscriptionHOC = (
  getActions: GetActions,
  onSubscribe?: OnSubscribe
) => <P>(
  WrappedComponent: React.ComponentType<P & WampAutoSubInjectedProps>
) => React.ComponentType<P>;

export const withWampSubscription: AutoSubscriptionHOC = (getActions, subscribeCallback) => {
  return <P>(
    WrappedComponent: React.ComponentType<P & WampAutoSubInjectedProps>
  ): React.ComponentType<P> => {
    class Component extends React.Component<P, State> {
      public static displayName: string = `withWampSubscription(${
        WrappedComponent.displayName || WrappedComponent.name
      })`;
      public static contextType = ReactReduxContext;
      public declare context: ReduxContext;
      public subscriptions: string[] = [];
      public blockSubscribing: boolean = false;
      public state: State = {subscribed: false};

      public clientName: string;
      private storeUnsubscribe?: () => void;

      public componentDidMount() {
        this.storeUnsubscribe = this.context.store.subscribe(this.handleStoreChange);
        this.handleStoreChange();
      }

      public componentWillUnmount() {
        this.storeUnsubscribe?.();
        delete this.storeUnsubscribe;
        this.subscriptions.forEach((topic: string) => {
          this.context.store.dispatch({
            type: `${this.clientName.toUpperCase()}/UNSUBSCRIBE`,
            wamp: {
              method: 'unsubscribe',
              uri: topic
            }
          });
        });
      }

      public render() {
        return React.createElement(WrappedComponent, {
          ...this.props,
          wampSubscribed: this.state.subscribed
        });
      }

      private handleStoreChange = () => {
        if (this.blockSubscribing) {
          return;
        }
        const actions: Array<WampSubscribeAction<{}, {}>> | null = getActions(
          this.context.store.getState
        );
        if (!this.clientName && actions && actions.length > 0) {
          this.clientName = actions[0].client || defaultAutobahnClientName;
        }
        const status = this.clientName && this.context.store.getState()[this.clientName].status;
        if (status !== CONNECTED) {
          this.state.subscribed && this.setState({subscribed: false});
          this.subscriptions = [];
          this.blockSubscribing = false;
          return;
        }

        const toSubscribeList = actions
          ? (filterByUri(actions, this.subscriptions) as Array<WampSubscribeAction<{}, {}>>)
          : [];
        if (toSubscribeList.length) {
          this.subscribe(toSubscribeList);
          return;
        }

        const toUnsubList = actions
          ? (filterByUri(this.subscriptions, actions) as string[])
          : this.subscriptions;
        if (toUnsubList.length) {
          this.unsubscribe(toUnsubList);
        }
      };

      private subscribe = (actions: Array<WampSubscribeAction<{}, {}>>) => {
        this.blockSubscribing = true;
        this.state.subscribed && this.setState({subscribed: false});
        Promise.all(actions.map(this.context.store.dispatch)).then(
          (responseActions: SubscribeResponseActions) => {
            this.subscriptions = this.subscriptions.concat(actions.map(getUri));
            this.blockSubscribing = false;
            this.setState({subscribed: true});
            if (subscribeCallback) {
              subscribeCallback(
                responseActions,
                this.context.store.dispatch as never,
                this.subscriptions,
                this.context.store.getState
              );
            }
          }
        );
      };

      private unsubscribe = (unsubs: string[]) => {
        this.blockSubscribing = true;
        Promise.all(
          unsubs.map((topic: string) =>
            this.context.store.dispatch({
              type: `${this.clientName.toUpperCase()}/UNSUBSCRIBE`,
              wamp: {
                method: 'unsubscribe',
                uri: topic
              }
            })
          )
        ).then(() => {
          this.blockSubscribing = false;
          this.subscriptions = this.subscriptions.filter(uri => !unsubs.includes(uri));
        });
      };
    }

    return Component;
  };
};
