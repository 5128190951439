import {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {useDrop} from 'react-dnd';
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from 'react-intl';
import {NativeTypes} from 'react-dnd-html5-backend';

import {md5Chunk} from 'services/common-methods';
import {type AppState, type ChatClipboard} from 'store/interface';

import {type ChatUploadingImage} from '../interface';
import {type ImageUploadOwnProps} from './interface';
import UploadingImage from './UploadingImage';
import Body from '../index';
import Icon from '../../../../Icon';
import Footer from '../../Footer';
import {validateImageFile} from '../../../../../common/ImageUpload/functions';
import {
  UploadingPictureStatus,
  UploadingPictureValidationError
} from '../../../../../common/ImageUpload/interface';
import {clearChatClipboard} from '../../../actions/action';
import {usePrevious} from '../../../../../hooks/usePrevious';

export const ChatImageUpload: FC<ImageUploadOwnProps> = ({
  chatIsActive,
  isChatCompact,
  editLastOwnMessage
}) => {
  const dispatch = useDispatch();
  const [listFiles, setListFiles] = useState<ChatUploadingImage[]>([]);
  const fileInput = useRef<HTMLInputElement>(null);
  const selectedRoomId = useSelector((state: AppState) => state.chat!.selectedRoomId);
  const clipboard = useSelector((state: AppState) => state.chat!.clipboard);

  const prevProps = usePrevious<ChatClipboard>(clipboard);

  const beforeUnloadHandler = useCallback((e: Event) => (e.returnValue = true), []);

  const performUpload = useCallback(
    async (files: File[] | FileList) => {
      if (selectedRoomId) {
        window.addEventListener('beforeunload', beforeUnloadHandler);
        const newFiles: ChatUploadingImage[] = [];
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          try {
            const md5 = await md5Chunk(file);
            const status = await validateImageFile(file, md5, selectedRoomId);
            newFiles.push({
              md5,
              file,
              status,
              roomId: selectedRoomId
            });
          } catch {
            // if cant parse md5, generate random string
            newFiles.push({
              md5: Math.random().toString(),
              file,
              status: UploadingPictureValidationError.FILE_CORRUPTED,
              roomId: selectedRoomId
            });
          }
        }
        if (fileInput.current) fileInput.current.value = '';
        setListFiles([...listFiles].concat(newFiles));
      }
    },
    [selectedRoomId, beforeUnloadHandler, listFiles]
  );

  const onPasteImage = useCallback(
    (image: File) => {
      dispatch(clearChatClipboard());
      return performUpload([image]);
    },
    [dispatch, performUpload]
  );

  useEffect(() => {
    if (listFiles.length) {
      if (listFiles.every(file => file.status === UploadingPictureStatus.FINISHED)) {
        setListFiles([]);
      }
      if (
        listFiles.every(
          file =>
            file.status === UploadingPictureStatus.FINISHED ||
            file.status in UploadingPictureValidationError
        )
      ) {
        window.removeEventListener('beforeunload', beforeUnloadHandler);
      }
    }

    if (!prevProps?.image && clipboard?.image) {
      onPasteImage(clipboard.image);
    }
  }, [prevProps, listFiles, clipboard, onPasteImage, beforeUnloadHandler]);

  const startChoosingImage = useCallback(
    () => fileInput && fileInput.current?.click(),
    [fileInput]
  );

  const handleDrop = useCallback(
    (files: File[]) => chatIsActive && performUpload(files),
    [chatIsActive, performUpload]
  );

  const [{dndMouseIsOver}, connectDropTarget] = useDrop({
    accept: NativeTypes.FILE,
    drop: (item: {files: File[]}) => handleDrop(item?.files),
    collect: monitor => ({
      dndMouseIsOver: monitor.isOver()
    })
  });

  const renderDndMask = useCallback(() => {
    if (dndMouseIsOver && chatIsActive) {
      return (
        <div className="dnd-mask">
          <div>
            <h3>
              <Icon name="virc-upload" />
              <FormattedMessage id="Chat.DropFilesToSend" />
            </h3>
          </div>
        </div>
      );
    }
    return null;
  }, [dndMouseIsOver, chatIsActive]);

  const setStatus = useCallback(
    (position: number, status: UploadingPictureStatus) => {
      const newArr = [...listFiles];
      newArr[position] = {...listFiles[position], status};
      setListFiles(newArr);
    },
    [listFiles]
  );

  const setId = useCallback(
    (position: number, id: number) => {
      const newArr = [...listFiles];
      newArr[position] = {...listFiles[position], id};
      setListFiles(newArr);
    },
    [listFiles]
  );

  const renderUploadingImage = useCallback(
    (image: ChatUploadingImage, position: number) => {
      if (image.roomId === selectedRoomId) {
        return (
          <UploadingImage
            image={image}
            key={position}
            position={position}
            setStatusByPosition={setStatus}
            setIdByPosition={setId}
          />
        );
      }
      return null;
    },
    [selectedRoomId, setStatus, setId]
  );

  const onInputChange = useCallback(
    () => fileInput.current?.files && performUpload(fileInput.current?.files),
    [fileInput, performUpload]
  );

  const renderFooter = useCallback(() => {
    if (!chatIsActive) {
      return null;
    }
    return (
      <Footer
        editLastOwnMessage={editLastOwnMessage}
        isChatCompact={isChatCompact}
        startChoosingImage={startChoosingImage}
      />
    );
  }, [chatIsActive, editLastOwnMessage, isChatCompact, startChoosingImage]);

  return connectDropTarget(
    <div className="chat-image-upload">
      <div className="chat-messages">
        <input
          className="chat-images-input"
          type="file"
          ref={fileInput}
          onChange={onInputChange}
          accept={'.jpg,.jpeg,.png,.bmp'}
          multiple={true}
        />
        <Body isChatCompact={isChatCompact} chatIsActive={chatIsActive}>
          {listFiles.map(renderUploadingImage)}
        </Body>
      </div>
      {renderFooter()}
      {renderDndMask()}
    </div>
  );
};
