import {type Middleware} from 'redux';
import thunk from 'redux-thunk';

import notificationConfig from 'services/browser-notifications';
import {routerMiddleware} from 'store/redux-router';

import axiosMiddleware from './axios';
import wampReplaceableMiddleware from './autobahn';
import webRTCMiddleware from '../middleware/webRTC';
import browserNotificationsMiddleware from './browser-notifications';
import ravenLogMiddleware from './sentry-log';
import {config} from './sentry-log/config';
import xplayerMiddleware from './xplayer';
import {logger} from './logger';

const middlewares: Middleware[] = [
  routerMiddleware,
  thunk,
  ravenLogMiddleware(config),
  axiosMiddleware,
  webRTCMiddleware,
  wampReplaceableMiddleware,
  browserNotificationsMiddleware(notificationConfig),
  xplayerMiddleware({
    syncTimeout: 3000
  })
];

if (import.meta.env.REACT_APP_REDUX_LOGGER) {
  middlewares.push(logger);
}

export default middlewares;
