import {type Value, type ValueJSON} from '@englex/slate';
import {type List, type Map, type OrderedMap, type Set} from 'immutable';
import {type Action} from 'redux';
import {type Descendant} from 'slate';

import {
  type ExerciseCategory,
  type ExerciseInstanceComment,
  type LanguageLevel,
  type PartialCoursebook,
  type Role
} from 'store/interface';
import {type WampCallResponseAction} from 'services/wamp/actions/interface';
import {type DataMap} from 'components/Slate/interface';
import {type UserV2} from 'components/CoursebookLibrary/interface';
import {type ImmutableRecord} from 'immutable-record/Record';

import {type ExerciseProperties} from './Exercise/interface';
import {type WidgetValuesVersionChanged, type XPlayerSync} from './persistence/interface';
import {type SupplementaryExercisesModalState} from './Exercise/supplementary/interface';
import {type DisplayButtonJSON, type DisplayButtonProperties} from './DisplayButtonRecord';

export type Answer = string;

export interface DNDChoice {
  value: Answer;
  caseSensitive?: true;
  isExtra?: true;
}

export type ChoicesMap = OrderedMap<string, DNDChoice>;

export interface DNDInputValue {
  choiceId: string;
  text: string;
}

interface Layout {
  showSavingBadge?: true;
  playerTop?: number;
  scrollTop?: number;
  clientHeight?: number;
}

export interface DraftCommentJSON {
  updatedAt?: number;
  id: string | null;
  comment: ValueJSON;
  autoFocus?: boolean;
}

export interface DraftCommentProperties {
  updatedAt: number;
  id: string | null;
  comment: ValueJSON;
  autoFocus: boolean;

  value: Descendant[];
  contentChange(value: Descendant[], id: string | null): this;
  toJSON(): DraftCommentJSON;
}

export type DraftComments = Map<string, DraftCommentProperties>;

export type ExerciseRecordMap = OrderedMap<string | number, ExerciseProperties>;

export interface XPlayerState {
  exercises: ExerciseRecordMap;
  layout?: Layout;
  sync: XPlayerSync;
  draftComments?: DraftComments;
  supplementaryExercisesModal?: SupplementaryExercisesModalState;
  modalExercise?: string;
}

export enum WidgetType {
  AUDIO = 'Audio',
  ESSAY = 'Essay',
  GAP_FILL = 'GapFill',
  FORMATTED_TEXT = 'FormattedText',
  NOTE = 'Note',
  COMMENT = 'Comment',
  QUESTS = 'Quests',
  QUESTIONS = 'Questions',
  IMAGE_MATCHING = 'ImageMatching',
  IMAGE_LABELING = 'ImageLabeling',
  MULTIPLE_CHOICE = 'MultipleChoice',
  FREE_CHOICE = 'FreeChoice',
  ODD_WORD = 'OddWord',
  MATCHING = 'Matching',
  VIDEO = 'Video',
  VOCABULARY = 'Vocabulary',
  SCRAMBLED_SENTENCES = 'ScrambledSentences',
  GRAMMAR_RULES = 'GrammarRules',
  SPEAKING = 'Speaking',
  CARDS = 'Cards',
  Quiz = 'Quiz',
  PICTURE_CHOICE = 'PictureChoice',
  DIALOGUE = 'Dialogue',
  WORD_SET = 'WordSet',
  PICTURE_SET = 'PictureSet',
  WORD_PICTURE_SET = 'WordPictureSet',
  FLIP_CARDS = 'FlipCards',
  HORIZONTAL_STYLED_LIST = 'HorizontalStyledList',
  VERTICAL_STYLED_LIST = 'VerticalStyledList',
  SPELLING = 'Spelling',
  UNKNOWN = 'Unknown'
}

export const WidgetTitle: {[key in WidgetType]: string} = {
  [WidgetType.AUDIO]: 'Listening',
  [WidgetType.ESSAY]: 'Writing (Essay)',
  [WidgetType.GAP_FILL]: 'Complete',
  [WidgetType.FORMATTED_TEXT]: 'Reading',
  [WidgetType.NOTE]: 'Tips',
  [WidgetType.COMMENT]: "Teacher's Notes",
  [WidgetType.QUESTS]: 'Writing (Sentence)',
  [WidgetType.IMAGE_MATCHING]: 'Picture Matching',
  [WidgetType.IMAGE_LABELING]: 'Picture Labeling',
  [WidgetType.MULTIPLE_CHOICE]: 'Multiple Choice',
  [WidgetType.FREE_CHOICE]: 'Free Choice',
  [WidgetType.ODD_WORD]: 'Odd Word',
  [WidgetType.MATCHING]: 'Categorizing',
  [WidgetType.VOCABULARY]: 'Glossary',
  [WidgetType.SCRAMBLED_SENTENCES]: 'Scrambled Sentences',
  [WidgetType.VIDEO]: 'Video',
  [WidgetType.GRAMMAR_RULES]: 'Grammar Rules',
  [WidgetType.SPEAKING]: 'Speaking',
  [WidgetType.QUESTIONS]: 'Questions',
  [WidgetType.CARDS]: 'Cards',
  [WidgetType.Quiz]: 'Quiz',
  [WidgetType.PICTURE_CHOICE]: 'Picture Choice',
  [WidgetType.DIALOGUE]: 'Dialogue',
  [WidgetType.WORD_SET]: 'Word Set',
  [WidgetType.PICTURE_SET]: 'Picture Set',
  [WidgetType.WORD_PICTURE_SET]: 'Word / Picture Set',
  [WidgetType.FLIP_CARDS]: 'Flip Cards',
  [WidgetType.HORIZONTAL_STYLED_LIST]: 'H-List',
  [WidgetType.VERTICAL_STYLED_LIST]: 'V-List',
  [WidgetType.SPELLING]: 'Spelling',
  [WidgetType.UNKNOWN]: 'Unknown'
};

export interface ExerciseOwnership {
  author: UserV2;
  original?: {
    id: string;
    author: UserV2;
  };
}

interface GrammarExerciseItem {
  id: number;
  title: string;
}

export type GrammarExerciseOptions = Array<GrammarExerciseItem>;

export type GrammarExerciseIds = Array<Pick<GrammarExerciseItem, 'id'>>;

export interface ExerciseJSON {
  readonly id: string | null;
  readonly title: string | null;
  readonly grammarTitle?: ValueJSON | null;
  readonly isNew?: boolean;
  readonly widgets: WidgetJSON[];
  readonly excerpt?: string;
  readonly completedAt?: string | null;
  readonly meta?: ExerciseMetaJSON;
  readonly grammar?: GrammarExerciseIds;
  readonly coursebooks?: PartialCoursebook[];
  readonly ownership?: ExerciseOwnership;
  readonly mainId?: string | null;
  readonly homeworkId?: string | undefined;
  readonly location?: ExerciseLocation;
  readonly additional?: ExerciseJSON[];
  readonly additionalAvailable?: number;
  readonly comments?: ExerciseInstanceComment[];
  readonly mediaSources: ExerciseMediaSourceJSON[] | null;
}

export interface WidgetJSON {
  id: string;
  type: WidgetType;
  task: ValueJSON | null;
  version?: number | null;
  media?: MediaMeta;
  displayButton?: DisplayButtonJSON | ValueJSON;
}

export interface WidgetToJSONOptions {
  preserveAnswers?: boolean;
  generateIdentifiers?: boolean;
  withMedia?: boolean;
}

export interface XExerciseToJsonOptions extends WidgetToJSONOptions {}

export interface ExerciseMediaSourceJSON {
  readonly title: string;
  readonly url: string;
}

export type ExerciseMediaSource = DataMap<ExerciseMediaSourceJSON & {id: string}>;

export type ExerciseMediaSources = List<ExerciseMediaSource>;

export interface CountWordsOption {
  countWords?: true;
}

export interface ChoicesJSON {
  [id: string]: {
    value: string;
  };
}

export enum WidgetMediaType {
  IMAGES = 'images',
  PRONUNCIATIONS = 'pronunciations',
  AUDIOS = 'audios',
  VIDEOS = 'videos'
}

export type WidgetMediaData = {[key in WidgetMediaType]: Set<number>};
export type MediaMeta = DataMap<WidgetMediaData>;

export interface WidgetProperties extends ImmutableRecord {
  readonly id: string;
  readonly type: WidgetType;
  readonly pristineTask?: Value;
  readonly task: Value;
  readonly displayButton?: DisplayButtonProperties;
  readonly media?: MediaMeta;
  readonly values?: unknown;
  readonly answers?: unknown;
  readonly choices?: unknown;
  readonly version: number | null;
  readonly skipSync?: true;
  readonly createValues: () => this;
  readonly setValuesFromJSON: (json: unknown) => this;
  readonly setAnswersFromJSON: (answers: unknown) => this;
  readonly setLabel: (label: string) => this;
  readonly hasDisplayButton: (role?: Role) => boolean;
  readonly throttleSendValues?: number;
  readonly isAutoChecked: boolean;
  readonly isAvailableSelection?: boolean;
  toJSON(): WidgetJSON;
}

export interface WidgetComponentProps<W extends WidgetProperties = WidgetProperties>
  extends WidgetTypeComponentProps<W> {
  isExtra?: boolean;
  isHeadWidget?: boolean;
  isHomeworkPlayer?: boolean;
  isGrammarPlayer?: boolean;
  isWidgetPreviewPlayer?: boolean;
  isPointerAvailable?: boolean;
}

export interface WidgetTypeComponentProps<W extends WidgetProperties = WidgetProperties> {
  widget: W;
  exerciseId: string;
  homeworkId?: string;
  isHomework?: boolean;
  preview?: boolean;
  role: Role;
  showPreviewExerciseNumber?: boolean;
  closed?: boolean;
  isGrammarPlayer?: boolean;
  isPointerAvailable?: boolean;
  isWidgetPreviewPlayer?: boolean;
  isModal?: boolean;
}

interface XPlayerAction extends Action {
  preview?: boolean;
}

export interface WidgetAction extends XPlayerAction {
  widgetId: string;
}

export type WidgetInstanceSavedAction = WampCallResponseAction<
  [WidgetValuesVersionChanged],
  {exerciseId: string; widgetId: number},
  never
>;

export interface SetModalExerciseAction extends Action {
  id?: string;
}

export interface ExerciseLocation {
  coursebookInstanceId: string;
  unitInstanceId: number;
  pageNumber: number;
  preview?: boolean;
  hidden?: boolean;
}

export interface ExerciseMetaJSON {
  categories: ExerciseCategory[];
  levels: LanguageLevel[];
}

export interface PronunciationOption {
  id: number;
  url?: string;
  createdAt?: string;
  importedAt?: string;
}
