import {valueFromText} from 'components/Slate/utils';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {type WidgetToJSONOptions, WidgetType} from 'store/exercise/player/interface';
import {type VerticalStyledListJSON} from 'store/exercise/player/widgets/List/StyledList/interface';

import XStyledList from './XStyledListRecord';
import {type XVerticalStyledListProperties, type XVerticalStyledListProps} from './interface';

class XVerticalStyledListRecord extends XStyledList implements XVerticalStyledListProperties {
  public declare readonly twoColumns: boolean;

  constructor(raw: XVerticalStyledListProps) {
    super(raw);
    this.initValues({
      twoColumns: !!raw.twoColumns
    });
  }

  public toJSON(options?: WidgetToJSONOptions): VerticalStyledListJSON {
    return {
      ...super.toJSON(options),
      twoColumns: this.twoColumns || undefined
    };
  }

  public get type() {
    return WidgetType.VERTICAL_STYLED_LIST;
  }

  public changeTwoColumns(twoColumnsCheckbox: boolean) {
    return this.set('twoColumns', twoColumnsCheckbox);
  }
}

decorate(XVerticalStyledListRecord, {
  content: property(valueFromText()),
  styleName: property(''),
  twoColumns: property(false)
});
record()(XVerticalStyledListRecord);
export default XVerticalStyledListRecord;
