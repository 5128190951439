import {defineMessages, type MessageDescriptor} from 'react-intl';
import {PaintTool} from '@englex/paint-react';

import {type ThicknessPreset} from '../Painter/plugins/thicknessPreset';

export const thicknessTitles: {[key in keyof ThicknessPreset]: MessageDescriptor} = defineMessages({
  thin: {id: 'Thickness.Thin'},
  medium: {id: 'Thickness.Medium'},
  thick: {id: 'Thickness.Thick'},
  xthick: {id: 'Thickness.ExtraThick'}
});

export const buttonTitles = defineMessages({
  copy: {id: 'Common.Copy'},
  paste: {id: 'Common.Paste'},
  clone: {id: 'Common.Clone'},
  delete: {id: 'Common.Delete'},
  insertImage: {id: 'Paint.Toolbar.Button.Title.InsertImage'},
  scale: {id: 'Paint.Toolbar.Button.Title.Scale'},
  select: {id: 'Paint.Toolbar.Button.Title.Select'},
  rotatePageClockwise: {id: 'Common.Page.Rotate.Clockwise'},
  rotatePageCounterClockwise: {id: 'Common.Page.Rotate.CounterClockwise'}
});

export const toolButtonTitles = defineMessages<PaintTool>({
  [PaintTool.Pencil]: {id: 'Paint.Toolbar.Button.Title.Pencil'},
  [PaintTool.Highlighter]: {id: 'Paint.Toolbar.Button.Title.Highlighter'},
  [PaintTool.Line]: {id: 'Paint.Toolbar.Button.Title.Line'},
  [PaintTool.Arrow]: {id: 'Paint.Toolbar.Button.Title.Arrow'},
  [PaintTool.Rect]: {id: 'Paint.Toolbar.Button.Title.Rect'},
  [PaintTool.Ellipse]: {id: 'Paint.Toolbar.Button.Title.Ellipse'},
  [PaintTool.Text]: {id: 'Paint.Toolbar.Button.Title.Text'}
});
