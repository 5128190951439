import {WidgetType} from 'store/exercise/player/interface';

import {type XWidgetProperties} from './interface';
import XGapFillRecord from './XGapFill/XGapFillRecord';
import XFormattedTextRecord from './XFormattedText/XFormattedTextRecord';
import XVocabularyRecord from './XVocabulary/XVocabularyRecord';
import XCommentRecord from './XComment/XCommentRecord';
import XNoteRecord from './XNote/XNoteRecord';
import XEssayRecord from './XEssay/XEssayRecord';
import XQuestsRecord from './XQuests/XQuestsRecord';
import XImageMatchingRecord from './XImage/XImageMatching/XImageMatchingRecord';
import XMultipleChoiceRecord from './XMultipleChoice/XMultipleChoiceRecord';
import XFreeChoiceRecord from './XFreeChoice/XFreeChoiceRecord';
import XOddWordRecord from './XOddWord/XOddWordRecord';
import XAudioRecord from './XAudio/XAudioRecord';
import XVideoRecord from './XVideo/XVideoRecord';
import XGrammarRulesRecord from './XGrammarRules/XGrammarRulesRecord';
import XSpeakingRecord from './XSpeaking/XSpeakingRecord';
import XScrambledRecord from './XScrambled/XScrambledRecord';
import XQuestionsRecord from './XQuestions/XQuestionsRecord';
import XQuizRecord from './XQuiz/XQuizRecord';
import XUnknownRecord from './XUnknown/XUnknownRecord';
import XCardsRecord from './XCards/XCardsRecord';
import XImageLabelingRecord from './XImage/XImageLabeling/XImageLabelingRecord';
import {XPictureChoiceRecord} from './XPictureChoice/XPictureChoiceRecord';
import XDialogueRecord from './XDialogue/XDialogueRecord';
import XWordPictureSetRecord from './XWordPictureSet/XWordPictureSet/XWordPictureSetRecord';
import XWordSetRecord from './XWordPictureSet/XWordSet/XWordSetRecord';
import XPictureSetRecord from './XWordPictureSet/XPictureSet/XPictureSetRecord';
import XFlipCardsRecord from './XFlipCards/XFlipCardsRecord';
import XHorizontalStyledList from './XStyledList/XHorizontalStyledListRecord';
import XVerticalStyledList from './XStyledList/XVerticalStyledListRecord';
import {xmatchingFactory} from './XMatching/xmatchingFactory';
import XSpellingRecord from './XSpelling/XSpellingRecord';
import XSpellingWordsRecord from './XSpelling/XSpellingWordsRecord';
import {type SpellingJSON} from '../../player/widgets/Selling/interface';
import {SpellingType} from './XSpelling/interface';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function xwidgetFactory(raw: any): XWidgetProperties {
  switch (raw.type) {
    case WidgetType.FORMATTED_TEXT: {
      return new XFormattedTextRecord(raw);
    }
    case WidgetType.ESSAY: {
      return new XEssayRecord(raw);
    }
    case WidgetType.GAP_FILL: {
      return new XGapFillRecord(raw);
    }
    case WidgetType.VOCABULARY: {
      return new XVocabularyRecord(raw);
    }
    case WidgetType.COMMENT: {
      return new XCommentRecord(raw);
    }
    case WidgetType.NOTE: {
      return new XNoteRecord(raw);
    }
    case WidgetType.QUESTS: {
      return new XQuestsRecord(raw);
    }
    case WidgetType.QUESTIONS: {
      return new XQuestionsRecord(raw);
    }
    case WidgetType.IMAGE_MATCHING: {
      return new XImageMatchingRecord(raw);
    }
    case WidgetType.IMAGE_LABELING: {
      return new XImageLabelingRecord(raw);
    }
    case WidgetType.MULTIPLE_CHOICE: {
      return new XMultipleChoiceRecord(raw);
    }
    case WidgetType.FREE_CHOICE: {
      return new XFreeChoiceRecord(raw);
    }
    case WidgetType.ODD_WORD: {
      return new XOddWordRecord(raw);
    }
    case WidgetType.MATCHING: {
      return xmatchingFactory(raw);
    }
    case WidgetType.SCRAMBLED_SENTENCES: {
      return new XScrambledRecord(raw);
    }
    case WidgetType.AUDIO: {
      return new XAudioRecord(raw);
    }
    case WidgetType.VIDEO: {
      return new XVideoRecord(raw);
    }
    case WidgetType.GRAMMAR_RULES: {
      return new XGrammarRulesRecord(raw);
    }
    case WidgetType.SPEAKING: {
      return new XSpeakingRecord(raw);
    }
    case WidgetType.CARDS: {
      return new XCardsRecord(raw);
    }
    case WidgetType.Quiz: {
      return new XQuizRecord(raw);
    }
    case WidgetType.PICTURE_CHOICE: {
      return new XPictureChoiceRecord(raw);
    }
    case WidgetType.DIALOGUE: {
      return new XDialogueRecord(raw);
    }
    case WidgetType.WORD_PICTURE_SET: {
      return new XWordPictureSetRecord(raw);
    }
    case WidgetType.WORD_SET: {
      return new XWordSetRecord(raw);
    }
    case WidgetType.PICTURE_SET: {
      return new XPictureSetRecord(raw);
    }
    case WidgetType.FLIP_CARDS: {
      return new XFlipCardsRecord(raw);
    }
    case WidgetType.HORIZONTAL_STYLED_LIST: {
      return new XHorizontalStyledList(raw);
    }
    case WidgetType.VERTICAL_STYLED_LIST: {
      return new XVerticalStyledList(raw);
    }
    case WidgetType.SPELLING: {
      return (raw as SpellingJSON).spellingType === SpellingType.Words
        ? new XSpellingWordsRecord(raw)
        : new XSpellingRecord(raw);
    }
    default:
      return new XUnknownRecord(raw);
  }
}
