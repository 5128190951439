import React, {type ReactNode, type ReactElement} from 'react';
import {default as Tooltip} from 'rc-tooltip';
import {type TooltipProps} from 'rc-tooltip/lib/Tooltip';

interface ControlledTooltipProps
  extends Partial<Omit<TooltipProps, 'children' | 'visible'> & {children?: ReactNode}> {
  overlay: React.ReactNode;
  contentEditable?: false;
  children?: ReactNode;
  onVisibleChange?: (visible: boolean) => void;
  defaultVisible?: boolean;
}

interface State {
  visible: boolean;
}

class ControlledTooltip extends React.Component<ControlledTooltipProps, State> {
  public state: State = {
    visible: !!this.props.defaultVisible || false
  };
  public render() {
    const {children, ...tooltipProps} = this.props;
    return (
      <Tooltip
        trigger={['click']}
        placement="bottom"
        destroyTooltipOnHide={true}
        {...tooltipProps}
        onVisibleChange={this.onVisibleChange}
        visible={this.isVisible}
        onPopupAlign={this.onPopupAlign}
      >
        {children as ReactElement}
      </Tooltip>
    );
  }

  protected onVisibleChange = (visible?: boolean) => {
    this.setState({visible: !!visible}, () => this.props.onVisibleChange?.(!!visible));
  };

  public get isVisible() {
    return this.state.visible;
  }

  public show = (afterShow?: () => void) => {
    this.setState({visible: true}, afterShow);
  };

  public hide = (afterShow?: () => void) => {
    this.setState({visible: false}, afterShow);
  };

  public toggle = (afterToggle?: () => void) => {
    this.setState({visible: this.state.visible}, afterToggle);
  };

  private onPopupAlign = (popupDomNode: HTMLDivElement, align: object) => {
    if (this.props.contentEditable === false) {
      this.ensureDisableContentEditable(popupDomNode);
    }
    if (this.props.onPopupAlign) {
      this.props.onPopupAlign(popupDomNode, align);
    }
  };

  private ensureDisableContentEditable = (popupDomNode: HTMLDivElement) => {
    const tooltipRootNode = popupDomNode.parentNode!.parentNode! as HTMLDivElement;
    const contentEditable = tooltipRootNode.getAttribute('contenteditable');

    if (contentEditable !== 'false' || !contentEditable) {
      tooltipRootNode.setAttribute('contenteditable', 'false');
    }
  };
}

export default ControlledTooltip;
