export default function resolveIdFromUri(uri: string): {[key: string]: number} {
  const regexp: RegExp = /([A-Za-z]+)[.|:|/]_?([\d]+)/g;
  const toReturn: {[key: string]: number} = {};
  let result: string[] | null = [];

  while ((result = regexp.exec(uri))) {
    toReturn[result[1] + 'Id'] = Number(result[2]);
  }
  return toReturn;
}

export function resolveParamFromUri(param: string, uri: string): number | null {
  const params = resolveIdFromUri(uri);
  return params[param] ? params[param] : null;
}

export function resolveEventFromSubscriptionTopic(
  topic: string,
  matchTopicMarker: string = 'event'
): string {
  const parts = topic.split(`${matchTopicMarker}.`);
  if (parts.length !== 2) {
    throw new Error(
      `Unable resolve event name from subscription topic '${topic}' by match topic marker '${matchTopicMarker}'`
    );
  }

  return parts[1];
}
