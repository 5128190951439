import React from 'react';
import {FormattedMessage} from 'react-intl';
import Button from 'react-bootstrap/lib/Button';
import classNames from 'classnames';

import Icon from './Icon';

import './ErrorMask.scss';

interface Props {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  reloadTitle?: React.ReactNode;
  reload?: () => void;
}

export const ErrorMask: React.FC<Props> = ({
  className,
  title,
  description,
  reload,
  reloadTitle
}) => {
  return (
    <div className={classNames('error-mask', className)}>
      <div>
        <div>
          <Icon name="coffee" size="lg" />
        </div>
        <p>{title || <FormattedMessage id="Common.SomethingWrong" />}</p>
        {description || <FormattedMessage id="Common.WampError" />}
        <div>
          <Button bsSize="sm" className="btn-transparent" onClick={reload}>
            {reloadTitle || <FormattedMessage id="Common.Reload" />}
          </Button>
        </div>
      </div>
    </div>
  );
};
