import {type Action} from 'redux';
import {createSearchParams, matchPath} from 'react-router-dom';
import {type IEvent} from 'autobahn';
import {type Dispatch as AxiosDispatch} from 'redux-axios-middleware';
import {type ThunkAction} from 'redux-thunk';

import {replace} from 'store/router';
import {
  type DictionarySubscribeCallback,
  type WampSubscribeAction
} from 'services/wamp/actions/interface';
import {type AxiosResponseAction} from 'services/axios/interface';
import {resolveEventFromSubscriptionTopic, resolveParamFromUri} from 'services/wamp/uriIdResolver';
import {
  dictionaryAllEntriesPath,
  studentDictionaryAllEntriesPattern,
  dictionaryListPath,
  dictionaryListPattern,
  studentDictionaryPath,
  studentDictionaryPattern,
  teacherDictionaryAllEntriesPath,
  teacherDictionaryEntriesInListPattern,
  teacherDictionaryListPattern,
  teacherDictionaryPath,
  teacherDictionaryPattern
} from 'common/paths';
import {type DictionaryList, type DictionaryOverview} from 'components/Dictionary/shared/interface';
import {type DictionaryOwnerRole} from 'components/Dictionary/shared/contexts';

import {type AppState} from '../interface';
import {SUBSCRIBE_DICTIONARY} from './actionTypes';
import {
  deleteEntriesFromList,
  deleteEntriesFromStore,
  deleteEntryFromStore,
  deleteFromList,
  deleteListFromStore,
  refreshList,
  replaceList,
  setDictionaryOverview,
  unshiftList,
  updateAllEntriesPage,
  updateListMemberOf,
  updateOverviewAndLists,
  updateOverviews
} from './actions';
import {getDictionaryOverviewRequest} from './requests';

export const subscribeDictionary = (
  dictionaryOwnerId: number,
  dictionaryOwnerRole: DictionaryOwnerRole
): WampSubscribeAction<[], {}> => ({
  type: SUBSCRIBE_DICTIONARY,
  wamp: {
    method: 'subscribe',
    uri: `dictionary:instance._${dictionaryOwnerId}.event`,
    options: {match: 'prefix'},
    rejectOnError: true
  },
  callback: eventCallback.bind(null, dictionaryOwnerId, dictionaryOwnerRole)
});

interface EventListEntriesUpdatedKwargs {
  dictionaryEntryInstanceIds: string[];
  isDeleted?: boolean;
}

const eventCallback: DictionarySubscribeCallback<unknown, unknown> = (
  dictionaryOwnerId,
  dictionaryOwnerRole,
  args,
  kwargs,
  details,
  {dispatch}
) => {
  const event = resolveEventFromSubscriptionTopic(details.topic);
  switch (event) {
    case 'list.updated':
      return dispatch(handleListUpdated((args as [DictionaryList])[0]));
    case 'list.created':
      return dispatch(handleListCreated((args as [DictionaryList])[0]));
    case 'list.deleted':
      return dispatch(
        handleListDeleted((args as [DictionaryList])[0], dictionaryOwnerId, dictionaryOwnerRole)
      );
    case 'list.entries.updated':
      return dispatch(
        handleListEntriesUpdated(
          (args as [string])[0],
          kwargs as EventListEntriesUpdatedKwargs,
          dictionaryOwnerId,
          dictionaryOwnerRole
        )
      );
    case 'list.widget':
      return dispatch(
        handleWidgetEntriesUpdated(
          details,
          (args as [string])[0],
          dictionaryOwnerId,
          dictionaryOwnerRole
        )
      );
    case 'entry.saved':
    case 'entries.saved':
    case 'entries.widget':
      return dispatch(handleEntrySaved(dictionaryOwnerId, dictionaryOwnerRole));
    case 'entry.deleted':
      return dispatch(handleEntryDeleted(details, (args as [string])[0], dictionaryOwnerRole));
    case 'entries.deleted':
      return dispatch(
        handleEntriesDeleted(details, kwargs as EventListEntriesUpdatedKwargs, dictionaryOwnerRole)
      );
    default:
      if (import.meta.env.MODE === 'development') {
        // eslint-disable-next-line no-console
        console.error(`dictionaryEventCallback: unknown event '${event}'`);
      }
  }
};

const handleListUpdated =
  (dictionaryList: DictionaryList): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const pathname = getState().router.location!.pathname;

    if (
      matchPath({path: studentDictionaryPattern, end: false}, pathname) ||
      matchPath({path: teacherDictionaryPattern, end: false}, pathname)
    )
      dispatch(replaceList(dictionaryList));
  };

const handleListCreated =
  (dictionaryList: DictionaryList): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    if (getState().dictionary?.status === 'active') dispatch(unshiftList(dictionaryList));
  };

const handleListDeleted =
  (
    {id}: DictionaryList,
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const pathname = getState().router.location!.pathname;

    if (getState().dictionary?.status === 'active') {
      dispatch(deleteListFromStore(id));
      const match =
        matchPath({path: dictionaryListPattern, end: false}, pathname) ||
        matchPath({path: teacherDictionaryListPattern, end: false}, pathname);

      // if user is currently on deleted list page - redirect to dictionary homepage
      if (match?.params?.listId === id && dictionaryOwnerId)
        dispatch(
          replace(
            dictionaryOwnerRole === 'student'
              ? studentDictionaryPath(dictionaryOwnerId)
              : teacherDictionaryPath(dictionaryOwnerId)
          )
        );
    }
  };

const handleListEntriesUpdated =
  (
    id: string,
    kwargs: EventListEntriesUpdatedKwargs,
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();

    if (!dictionaryOwnerId) return;

    const {pathname, search} = state.router.location!;
    const searchParams = createSearchParams(search);

    if (
      searchParams.get('inLists') === 'false' &&
      (matchPath(studentDictionaryAllEntriesPattern, pathname) ||
        matchPath(teacherDictionaryEntriesInListPattern, pathname))
    ) {
      const {dictionaryEntryInstanceIds = []} = kwargs;
      dispatch(deleteEntriesFromStore(dictionaryEntryInstanceIds));
    }

    if (
      matchPath({path: studentDictionaryPattern, end: false}, pathname) ||
      matchPath({path: teacherDictionaryPattern, end: false}, pathname)
    ) {
      const match =
        matchPath({path: dictionaryListPattern, end: false}, pathname) ||
        matchPath({path: teacherDictionaryListPattern, end: false}, pathname);

      const listId = match?.params.listId;

      if (listId) dispatch(refreshList(listId));

      dispatch(updateOverviews(dictionaryOwnerId, dictionaryOwnerRole, id));
    }

    if (kwargs.dictionaryEntryInstanceIds.length) {
      dispatch(updateListMemberOf(kwargs.dictionaryEntryInstanceIds, kwargs.isDeleted));
    }
  };

const handleWidgetEntriesUpdated =
  (
    details: IEvent,
    listId: string,
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const pathname = getState().router.location!.pathname;

    const studentId = resolveParamFromUri('instanceId', details.topic);
    if (!studentId) return;

    if (
      pathname === studentDictionaryPath(dictionaryOwnerId) ||
      pathname === teacherDictionaryPath(dictionaryOwnerId)
    )
      return dispatch(updateOverviews(studentId, dictionaryOwnerRole, listId));

    if (pathname === dictionaryListPath(studentId, listId)) return dispatch(refreshList(listId));

    dispatch(updateAllEntriesPage(studentId, dictionaryOwnerRole));
  };

const handleEntrySaved =
  (
    dictionaryOwnerId: number,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch: AxiosDispatch<Action, AppState>, getState) => {
    const pathname = getState().router.location!.pathname;

    if (!dictionaryOwnerId) return;

    if (
      [studentDictionaryPath(dictionaryOwnerId), teacherDictionaryPath(dictionaryOwnerId)].includes(
        pathname
      )
    ) {
      dispatch(updateOverviewAndLists(dictionaryOwnerId, dictionaryOwnerRole));
      dispatch<AxiosResponseAction<DictionaryOverview>>(
        getDictionaryOverviewRequest(dictionaryOwnerId, dictionaryOwnerRole)
      ).then(({payload: {data}}) => {
        dispatch(setDictionaryOverview(data));
      });
      return;
    }

    const match =
      matchPath(dictionaryListPattern, pathname) ||
      matchPath(teacherDictionaryListPattern, pathname);
    const listId = match?.params.listId;

    if (listId) {
      dispatch(refreshList(listId));
      return;
    }

    dispatch(updateAllEntriesPage(dictionaryOwnerId, dictionaryOwnerRole));
  };

const handleEntryDeleted =
  (
    details: IEvent,
    entryId: string,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const pathname = getState().router.location!.pathname;

    const studentId = resolveParamFromUri('instanceId', details.topic);
    if (!studentId) return;

    if ([studentDictionaryPath(studentId), teacherDictionaryPath(studentId)].includes(pathname))
      return dispatch(updateOverviewAndLists(studentId, dictionaryOwnerRole));

    const match =
      matchPath(dictionaryListPattern, pathname) ||
      matchPath(teacherDictionaryListPattern, pathname);
    const listId = match?.params.listId;
    if (listId) return dispatch(deleteFromList(entryId));

    if (
      [dictionaryAllEntriesPath(studentId), teacherDictionaryAllEntriesPath(studentId)].includes(
        pathname
      )
    ) {
      dispatch(deleteEntryFromStore(entryId));
    } else {
      const dictionaryState = getState().dictionary;
      const selectedStudentId = dictionaryState?.studentId;

      if (!selectedStudentId || studentId !== selectedStudentId) return;
      dispatch(deleteEntryFromStore(entryId));
    }
  };

const handleEntriesDeleted =
  (
    details: IEvent,
    kwargs: EventListEntriesUpdatedKwargs,
    dictionaryOwnerRole: DictionaryOwnerRole
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const pathname = getState().router.location!.pathname;
    const studentId = resolveParamFromUri('instanceId', details.topic);

    if (!studentId) return;

    if (
      [dictionaryAllEntriesPath(studentId), teacherDictionaryAllEntriesPath(studentId)].includes(
        pathname
      )
    )
      return dispatch(deleteEntriesFromStore(kwargs.dictionaryEntryInstanceIds));

    const match = matchPath(dictionaryListPattern, pathname);
    const listId = match?.params.listId;

    if (listId) return dispatch(deleteEntriesFromList(kwargs.dictionaryEntryInstanceIds));

    if (pathname === studentDictionaryPath(studentId))
      return dispatch(updateOverviewAndLists(studentId, dictionaryOwnerRole));

    const dictionaryState = getState().dictionary;
    const selectedStudentId = dictionaryState?.studentId;

    if (!selectedStudentId || studentId !== selectedStudentId) return;
    return dispatch(deleteEntriesFromStore(kwargs.dictionaryEntryInstanceIds));
  };
