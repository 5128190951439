import {default as axios} from 'axios';
import {multiClientMiddleware} from 'redux-axios-middleware';

import vircClient, {middlewareOptions} from 'services/axios/vircClient';
import staticClient, {staticMiddlewareOptions} from 'services/axios/staticClient';

import {type AppState} from '../interface';

const axiosMiddleware = multiClientMiddleware<AppState>({
  default: {
    client: axios.create(vircClient),
    options: middlewareOptions
  },
  v2: {
    client: axios.create({
      ...vircClient,
      baseURL: import.meta.env.REACT_APP_REST_API_V2_URL
    }),
    options: middlewareOptions
  },
  static: {
    client: axios.create(staticClient),
    options: staticMiddlewareOptions
  }
});

export default axiosMiddleware;
