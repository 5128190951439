import React, {type FC, useCallback, useMemo, useState, memo} from 'react';
import {useMediaQuery} from '@englex/react-hooks/lib/useMediaQuery';
import {useSelector} from 'react-redux';

import {type AppState, type StudentTeachers} from 'store/interface';
import StudentTeacherBreadcrumb from 'components/Breadcrumbs/StudentTeacherBreadcrumb/StudentTeacherBreadcrumb';

import {useDictionaryContext} from './contexts';

interface Props {
  changeActiveStudentTeacher(id: number): void;
  popoverHeight?: number;
  popoverContainerClassName?: string;
  popoverLeftPosition?: string;
  popoverTopPosition?: string;
  studentTeachers: StudentTeachers | undefined;
  isSidebar?: boolean;
}

export const StudentSelector: FC<Props> = memo(
  ({
    changeActiveStudentTeacher,
    popoverHeight,
    popoverLeftPosition = '5px',
    popoverTopPosition = '40px',
    studentTeachers,
    popoverContainerClassName,
    isSidebar = false
  }) => {
    const [activeTab, setActiveTab] = useState('1');
    const [filter, setFilter] = useState('');
    const [popoverIsOpen, setPopoverIsOpen] = useState(false);
    const togglePopover = useCallback(() => setPopoverIsOpen(!popoverIsOpen), [popoverIsOpen]);
    const {dictionaryOwnerId} = useDictionaryContext();
    const currentStudent = useSelector((state: AppState) => state.dictionary?.studentId);

    const popoverPosition = useMediaQuery(480) ? '-45px' : popoverLeftPosition;

    const activeStudentTeacher = useMemo(
      () =>
        studentTeachers &&
        Object.values(studentTeachers).find(st => Number(st.student_id) === dictionaryOwnerId),
      [studentTeachers, dictionaryOwnerId]
    );

    return !studentTeachers ? null : (
      <StudentTeacherBreadcrumb
        activeStudentTeacher={activeStudentTeacher}
        toggleStudentTeachersPopover={togglePopover}
        studentTeacherPopoverOpen={popoverIsOpen}
        studentTeachers={studentTeachers}
        currentStudent={currentStudent}
        isSidebar={isSidebar}
        changeActiveStudentTeacher={changeActiveStudentTeacher}
        popoverId="dictionary-student-selector-popover"
        studentTeachersFilter={filter}
        popoverOpenTabId={activeTab}
        changeFilter={setFilter}
        changeActiveTab={setActiveTab}
        popoverHeight={popoverHeight}
        popoverTopPosition={popoverTopPosition}
        popoverLeftPosition={popoverPosition}
        popoverContainerClassName={popoverContainerClassName}
      />
    );
  }
);
