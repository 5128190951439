import React, {type FC} from 'react';
import {type FieldProps} from 'formik';
import Radio from 'react-bootstrap/lib/Radio';

interface Props extends FieldProps {
  id: string;
  label: string;
}

const RadioButton: FC<Props> = ({field: {name, value, onChange, onBlur}, id, label}) => (
  <Radio
    name={name}
    inline={true}
    onChange={onChange}
    checked={id === value}
    onBlur={onBlur}
    id={id}
    value={id}
    title={label}
  >
    <span>
      <span>{label}</span>
    </span>
  </Radio>
);

export default RadioButton;
