import {useMemo} from 'react';
import {
  PaintTool,
  SelectablePaint,
  ThicknessPaint,
  ToolsPaint,
  usePaint
} from '@englex/paint-react';
import {Paint, PaintNode, type PNode, type ShapeAttrs} from '@englex/paint';

import {type ThicknessName, ThicknessPresetPaint} from 'components/Paint/Painter/plugins';

export function useThickness() {
  const paint = usePaint<ToolsPaint & ThicknessPresetPaint>();
  const activeTool = ToolsPaint.getTool(paint);

  const [selectedNode] = Paint.selectedEntry<PNode<ShapeAttrs>>(paint);
  const isSelectable = SelectablePaint.isEnabled(paint);
  const isHighlighter =
    selectedNode && isSelectable
      ? PaintNode.isHighlight(selectedNode)
      : activeTool === PaintTool.Highlighter;

  const preset = isHighlighter
    ? paint.thickness.preset.highlightMap
    : paint.thickness.preset.defaultMap;
  const isDisabled =
    isSelectable && selectedNode
      ? ['Image', 'Text'].includes(selectedNode.className)
      : activeTool === PaintTool.Text;

  const selectedNodeThickness: ThicknessName | undefined = useMemo(() => {
    if (!selectedNode || !isSelectable || isDisabled) return;
    const thickness = ThicknessPaint.getNodeThickness(selectedNode);
    if (!thickness) return;
    return ThicknessPresetPaint.toName(thickness, preset);
  }, [isDisabled, isSelectable, preset, selectedNode]);

  const active =
    selectedNode && isSelectable ? selectedNodeThickness : paint.thickness.preset.active;

  return {active, selectedNode, isSelectable, preset, isDisabled};
}
