import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {type Action, type ActionCreator} from 'redux';
import {useParams} from 'react-router-dom';
import {useIsMounted} from '@englex/react-hooks/lib/useIsMounted';

import {type AppState, CONNECTED, type StudentTeacher} from 'store/interface';

import {MESSAGE_SEND_SUCCESS, MESSAGE_SEND_FAIL} from '../../components/Chat/actions/actionTypes';
import {messageSend} from '../../components/Chat/actions/action';

type Params = {
  studentId?: string;
};

export function useSentChatMessage() {
  const dispatch: ActionCreator<Promise<Action>> = useDispatch();

  const {studentId} = useParams<Params>();

  const isMounted = useIsMounted();
  const isOnline = useSelector((state: AppState) => state.wamp.status === CONNECTED);

  const room = useSelector<AppState, StudentTeacher | undefined>(state => {
    if (state.studentTeachers) {
      const studentTeachersList = state.studentTeachers.studentTeachers;

      if (studentId && studentTeachersList) {
        return Object.values(studentTeachersList).find(
          studentTeacher => Number(studentTeacher.student_id) === Number(studentId)
        );
      }

      const selectedStudentTeacher = state.studentTeachers.selectedStudentTeacher;
      return selectedStudentTeacher && studentTeachersList
        ? studentTeachersList[selectedStudentTeacher]
        : undefined;
    }

    return undefined;
  });

  const send = useCallback(
    (message: string) => {
      return new Promise((resolve, reject) => {
        if (isMounted.current && isOnline && room) {
          const {
            room_id: roomId,
            recipient: {profile}
          } = room;

          dispatch(messageSend(roomId, message))
            .then((action: Action) => {
              if (action.type === MESSAGE_SEND_SUCCESS && profile) {
                resolve(profile);
              }

              if (action.type === MESSAGE_SEND_FAIL && profile) {
                reject(profile);
              }
            })
            .catch(() => {
              reject(profile);
            });
        } else {
          reject('Wamp is not connected');
        }
      });
    },
    [dispatch, isMounted, isOnline, room]
  );

  return {send};
}
