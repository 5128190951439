import React, {type FC} from 'react';
import Spinner from 'react-spinkit';
import './simple-loading-overlay.scss';
import classNames from 'classnames';
interface Props {
  isLoading?: boolean;
  className?: string;
  spinnerClassName?: string;
}
const SimpleLoadingOverlay: FC<Props> = ({
  className,
  isLoading = true,
  spinnerClassName = 'brand-primary'
}) => {
  return (
    <div
      className={classNames('simple-loading-overlay', className, {
        loading: isLoading,
        animated: isLoading !== undefined
      })}
    >
      {isLoading && (
        <Spinner
          name="three-bounce"
          className={classNames('center-block', spinnerClassName)}
          fadeIn="none"
        />
      )}
    </div>
  );
};
export default SimpleLoadingOverlay;
