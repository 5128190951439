import {defineMessages, type IntlShape, type MessageDescriptor} from 'react-intl';
import * as yup from 'yup';
import {Value} from '@englex/slate';

import {type CommentJSON, CommentType} from 'store/exercise/player/widgets/Comment/interface';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';
import {widgetTaskMaxLength} from 'config/static';

import {WidgetTitle, WidgetType} from '../../../player/interface';
import XFormattedTextRecord from '../XFormattedText/XFormattedTextRecord';
import validationMessages from '../i18n';
import {documentNotEmpty, taskMaxLength} from '../validation';
import {commentDataByType, getCommentType} from '../../../player/widgets/Comment/commentDataByType';
import {type XCommentProperties} from './interface';

const messages = defineMessages({
  placeholder: {
    id: 'XEditor.XWidget.Task.XComment'
  }
});

class XCommentRecord extends XFormattedTextRecord implements XCommentProperties {
  public declare readonly commentType: CommentType;
  public declare readonly isCollapsed?: boolean;

  constructor(raw: CommentJSON) {
    super(raw);
    const commentType = getCommentType(raw);

    this.initValues({
      content: Value.fromJSON(raw.content),
      commentType,
      isCollapsed: this.getDefaultCollapsed(raw.isCollapsed, commentType)
    });
  }

  public toJSON(): CommentJSON {
    return {
      ...super.toJSON(),
      commentType: this.commentType,
      displayButton: this.displayButton?.toJSON(),
      isCollapsed: this.isCollapsed
    };
  }

  public get type() {
    return WidgetType.COMMENT;
  }

  public get taskPlaceholder(): MessageDescriptor {
    return messages.placeholder;
  }

  public get titleIconName(): string {
    return commentDataByType[this.commentType].iconName;
  }

  public get title(): string {
    return `${WidgetTitle[this.type]} (${commentDataByType[this.commentType].title})`;
  }

  public get hasDisplayButton() {
    return this.commentType === CommentType.TEACHING_TIPS;
  }

  public get customBodyClass() {
    return this.commentType;
  }

  public getDefaultCollapsed(isCollapsed: boolean | undefined, commentType: CommentType) {
    if (isCollapsed !== undefined) return isCollapsed;

    return commentType === CommentType.TEACHING_TIPS ? true : undefined;
  }

  public toggleCollapsedCheckbox() {
    return this.set('isCollapsed', !this.isCollapsed);
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Should not be empty',
          intl.formatMessage(validationMessages.ContentNonEmpty),
          (v: Value) => documentNotEmpty(v.document)
        ),
      task: yup.mixed().test(
        'Task max length',
        intl.formatMessage(validationMessages.TaskMaxLength, {
          maxCharCount: widgetTaskMaxLength
        }),
        (v: Value) => taskMaxLength(v.document, widgetTaskMaxLength)
      )
    });
  }
}

decorate(XCommentRecord, {
  commentType: property(CommentType.TEACHING_NOTES),
  isCollapsed: property()
});
record()(XCommentRecord);
export default XCommentRecord;
