import {ValidationError} from 'yup';

import type XWidgetRecord from './XWidgetRecord';
import {type ExerciseMediaSourceJSON} from '../../player/interface';

enum ValidationErrorTypes {
  ValidationTitleError = 'ValidationTitleError',
  ValidationGrammarTitleError = 'ValidationGrammarTitleError',
  ValidationWidgetError = 'ValidationWidgetError',
  ValidationMediaSourceError = 'ValidationMediaSourceError'
}

type MaybeError = object & {type?: string};

export class ValidationTitleError extends ValidationError {
  static isInstanceof(error?: MaybeError): error is ValidationTitleError {
    return Boolean(error && error.type === ValidationErrorTypes.ValidationTitleError);
  }

  constructor(error: string, value: string, path = 'title') {
    super(error, value, path, ValidationErrorTypes.ValidationTitleError);
  }
}

export class ValidationGrammarTitleError extends ValidationError {
  static isInstanceof(error?: MaybeError): error is ValidationGrammarTitleError {
    return Boolean(error && error.type === ValidationErrorTypes.ValidationGrammarTitleError);
  }

  constructor(error: string, value: string, path = 'grammarTitle') {
    super(error, value, path, ValidationErrorTypes.ValidationGrammarTitleError);
  }
}

export class ValidationWidgetError extends ValidationError {
  public widgetId: string;
  public widget?: XWidgetRecord;
  public meta?: Record<string, unknown>;

  static isInstanceof(error?: MaybeError | null): error is ValidationWidgetError {
    return Boolean(error && error.type === ValidationErrorTypes.ValidationWidgetError);
  }

  constructor(error: string, widget?: XWidgetRecord, props?: Record<string, unknown>) {
    super(error, widget, 'widgets', ValidationErrorTypes.ValidationWidgetError);

    this.widgetId = widget?.id || '';
    this.widget = widget;
    this.meta = props;
  }

  public get widgetError() {
    return {
      errors: this.errors,
      message: this.message,
      widgetId: this.widgetId,
      meta: this.meta
    };
  }

  public get widgetMapError() {
    return {[this.widgetId]: this.widgetError};
  }
}

export class ValidationMediaSourceError extends ValidationError {
  static isInstanceof(error?: MaybeError): error is ValidationMediaSourceError {
    return Boolean(error && error.type === ValidationErrorTypes.ValidationMediaSourceError);
  }

  public mediaSource: ExerciseMediaSourceJSON & {id: string};
  public meta?: Record<string, unknown>;

  constructor(
    error: string,
    mediaSource: ExerciseMediaSourceJSON & {id: string},
    meta?: Record<string, unknown>
  ) {
    super(error, mediaSource, 'mediaSources', ValidationErrorTypes.ValidationMediaSourceError);

    this.mediaSource = mediaSource;
    this.meta = meta;
  }

  public get mediaSourceError() {
    return {
      errors: this.errors,
      message: this.message,
      mediaSourceId: this.mediaSource.id,
      ...this.meta
    };
  }
}
