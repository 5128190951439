import {type IntlShape} from 'react-intl';
import {type Value} from '@englex/slate';
import * as yup from 'yup';

import {record} from 'immutable-record/decorator/record';

import {oddWordAnswersNumberMin} from '../constants';
import {WidgetTitle, WidgetType} from '../../../player/interface';
import XMultipleChoiceRecord from '../XMultipleChoice/XMultipleChoiceRecord';
import validationMessages from '../i18n';
import {
  answerBlocksNotEmpty,
  correctAnswerSelectedForEveryQuestion,
  minRequiredAnswers
} from '../validation';

class XOddWordRecord extends XMultipleChoiceRecord {
  public get type() {
    return WidgetType.ODD_WORD;
  }

  public get title(): string {
    return WidgetTitle.OddWord;
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Min required answers',
          intl.formatMessage(validationMessages.MinGroupRequiredAnswers),
          (v: Value) => minRequiredAnswers(v.document, oddWordAnswersNumberMin)
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => answerBlocksNotEmpty(v.document)
        )
        .test(
          'Correct answers selected',
          intl.formatMessage(validationMessages.CorrectAnswersSelected),
          (v: Value) => correctAnswerSelectedForEveryQuestion(v.document)
        )
    });
  }
}

record()(XOddWordRecord);
export default XOddWordRecord;
