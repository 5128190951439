const prefix = 'COURSEBOOK_INSTANCE/';

export const SET_UNIT_INSTANCES = prefix + 'SET_UNIT_INSTANCES';
export const CLEAR_UNIT_INSTANCES = prefix + 'CLEAR_UNIT_INSTANCES';

export const ACTIVATE_UNIT_INSTANCE = prefix + 'ACTIVATE_UNIT_INSTANCE';
export const DEACTIVATE_UNIT_INSTANCE = prefix + 'DEACTIVATE_UNIT_INSTANCE';
export const DEACTIVATE_UNIT_INSTANCE_EVENT = prefix + 'DEACTIVATE_UNIT_INSTANCE_EVENT';
export const ACTIVATE_UNIT_INSTANCE_EVENT = prefix + 'ACTIVATE_UNIT_INSTANCE_EVENT';
export const COMPLETE_UNIT_INSTANCE_EVENT = prefix + 'COMPLETE_UNIT_INSTANCE_EVENT';
export const UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES =
  prefix + 'UNIT_INSTANCE_INCREMENT_COMPLETED_EXERCISES';
export const UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES =
  prefix + 'UNIT_INSTANCE_DECREMENT_COMPLETED_EXERCISES';
export const REQUEST_COURSEBOOK_INSTANCE = prefix + 'REQUEST_COURSEBOOK_INSTANCE';
export const SET_SELECTED_COURSEBOOK_INSTANCE = prefix + 'SET_SELECTED_COURSEBOOK_INSTANCE';
export const TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK =
  prefix + 'TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK';
export const SELECTED_CBI_INCREMENT_COMPLETED_UNITS =
  prefix + 'SELECTED_CBI_INCREMENT_COMPLETED_UNITS';
export const CLEAR_SELECTED_COURSEBOOK_INSTANCE = prefix + 'CLEAR_SELECTED_COURSEBOOK_INSTANCE';
