import {
  type FormattedTextProperties,
  type WidgetFormattedTextJSON
} from '../FormattedText/interface';

export enum NoteType {
  SPEAKING_FOCUS = 'SpeakingFocus',
  WRITING_FOCUS = 'WritingFocus',
  VOCABULARY_FOCUS = 'VocabularyFocus',
  GRAMMAR_FOCUS = 'GrammarFocus',
  PRONUNCIATION_FOCUS = 'PronunciationFocus',
  SPELLING_FOCUS = 'SpellingFocus',
  HOW_TO_STRATEGIES = 'HowTo',
  CULTURAL_FOCUS = 'CulturalFocus'
}

export interface NoteDataByType {
  type: NoteType;
  iconName: string;
  title: string;
}

export interface NoteJSON extends WidgetFormattedTextJSON {
  noteType: NoteType;
  isCollapsed?: boolean;
}

export interface NoteProperties extends FormattedTextProperties {
  readonly noteType: NoteType;
  readonly isCollapsible: boolean;
  readonly noteLabel?: string;
  readonly isCollapsed?: boolean;
}
