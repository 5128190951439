import {connect} from 'react-redux';

import {type ChatClipboard, type Room} from 'store/interface';

import {
  type FooterDispatchProps,
  type FooterDispatchToProps,
  type FooterOwnProps,
  type FooterStateProps,
  type FooterStateToProps
} from './interface';
import {
  chatTyping,
  clearMessageToUpdate,
  messageSend,
  promoteMessageToUpdate,
  resetChatTyping,
  setChatClipboard,
  updateMessage
} from '../../actions/action';
import Footer from './Footer';

const mapStateToProps: FooterStateToProps = state => {
  const selectedRoom: Room | null =
    state.chat!.rooms && state.chat!.rooms[state.chat!.selectedRoomId!]
      ? state.chat!.rooms[state.chat!.selectedRoomId!]
      : null;
  const messageToUpdate =
    state.chat!.messageToUpdateId && selectedRoom
      ? selectedRoom.messages.find(message => message.id === state.chat!.messageToUpdateId)
      : undefined;
  return {
    isMobile: state.layout.isMobile,
    selectedRoomId: state.chat!.selectedRoomId || undefined,
    messageToUpdate: messageToUpdate && messageToUpdate.id,
    sendingMessageAwait: state.chat!.rooms![state.chat!.selectedRoomId!].sendingMessageAwait,
    key: messageToUpdate ? String(messageToUpdate.id) : 'new-message',
    defaultText: messageToUpdate ? messageToUpdate.text : ''
  };
};

const mapDispatchToProps: FooterDispatchToProps = dispatch => ({
  clearMessageToUpdate: (roomId: number) => dispatch(clearMessageToUpdate(roomId)),
  chatTyping: (roomId: number) => dispatch(chatTyping(roomId)),
  resetChatTyping: () => dispatch(resetChatTyping()),
  sendMessage: (roomId: number, message: string) => dispatch(messageSend(roomId, message)),
  updateMessage: (roomId: number, messageId: number, messageText: string) =>
    dispatch(updateMessage(roomId, messageId, messageText)),
  promoteMessageToUpdate: (roomId: number, id: number) =>
    dispatch(promoteMessageToUpdate(roomId, id)),
  setChatClipboard: (clipboard: ChatClipboard) => dispatch(setChatClipboard(clipboard))
});

const FooterContainer = connect<FooterStateProps, FooterDispatchProps, FooterOwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(Footer);

export default FooterContainer;
