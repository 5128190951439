import {type List} from 'immutable';

import {
  type WordPictureSetBaseCardId,
  type WordPictureSetBaseCardJSON,
  type WordPictureSetBaseMetaDataJSON,
  type WordPictureSetBaseMetaDataProperties,
  type WordPictureSetBaseProps,
  type WordPictureSetBaseValues
} from 'store/exercise/player/widgets/WordPictureSet/baseInterface';

import type XPictureSetCardRecord from './XPictureSetCardRecord';
import type XPictureSetRecord from './XPictureSetRecord';
import {
  type XWordPictureSetBaseCardProperties,
  type XWordPictureSetBaseCardProps,
  type XWordPictureSetBaseProperties
} from '../baseInterface';

export enum CardSizeType {
  SQUARE = 'Square',
  RECTANGLE = 'Rectangle'
}

export interface XPictureSetCardProps extends XWordPictureSetBaseCardProps {
  image?: number;
}

export interface XPictureSetCardProperties extends XWordPictureSetBaseCardProperties {
  image: number;
}

export interface XPictureSetProps
  extends WordPictureSetBaseProps<
    WordPictureSetBaseCardId,
    WordPictureSetBaseMetaDataJSON<WordPictureSetBaseCardId>,
    WordPictureSetBaseCardJSON
  > {}

export interface XPictureSetProperties
  extends XWordPictureSetBaseProperties<
    WordPictureSetBaseCardId,
    WordPictureSetBaseValues<
      WordPictureSetBaseCardId,
      WordPictureSetBaseMetaDataProperties<WordPictureSetBaseCardId>
    >,
    XPictureSetCardRecord,
    XPictureSetRecord
  > {
  changeCardImage(cardId: string, image: number): XPictureSetRecord;
  moveCard(moveCardIndex: number, targetCardIndex: number): XPictureSetRecord;
  rollBackCards(cardIds: List<WordPictureSetBaseCardId>): XPictureSetRecord;
}
