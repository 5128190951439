import {type AxiosError} from 'axios';
import {type Action, type Dispatch} from 'redux';

import {type AxiosRequestAction} from 'services/axios/interface';
import {
  type CoursebookInstance,
  type CourseInstanceOverview,
  type HomeworkWithContents
} from 'store/interface';

import {
  CLEAR_COURSE_INSTANCE_INFO,
  CREATE_COURSEBOOK_INSTANCE,
  COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES,
  REQUEST_COURSE_INSTANCE_INFO,
  CLEAR_HOMEWORK_DRAFT,
  LOAD_HOMEWORK_DRAFT,
  SET_COURSEBOOK_INSTANCES,
  SET_HOMEWORK_DRAFT,
  TOGGLE_BREADCRUMB_DROPDOWN,
  TOGGLE_PAGER_POPOVER,
  COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS,
  SET_COURSE_INSTANCE_OVERVIEW,
  DEACTIVATE_COURSEBOOK_INSTANCE,
  REACTIVATE_COURSEBOOK_INSTANCE,
  SET_COURSEBOOK_INSTANCE_DEACTIVATED,
  SET_COURSEBOOK_INSTANCE_ACTIVATED,
  COURSEBOOK_INSTANCE_DELETED,
  ASSIGN_DOCUMENT_INSTANCE_TO_DRAFT,
  DELETE_DOCUMENT_INSTANCE_FROM_DRAFT,
  EDIT_HW_DOCUMENT_COMMENT_REQUEST,
  COURSEBOOK_INSTANCE_DECREMENT_COMPLETED_EXERCISES,
  DELETE_DOCUMENT_INSTANCE_PAGE_FROM_DRAFT,
  ASSIGN_DOCUMENT_INSTANCE_PAGE_TO_DRAFT,
  EDIT_HW_DOCUMENT_PAGE_COMMENT_REQUEST,
  REQUEST_TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK,
  REQUEST_HIDE_COURSEBOOK_INSTANCE
} from './actionTypes';
import {type ToggleElementAction} from '../../../../common/interface';
import {type IncrementCBICompletedUnitsAction} from '../CoursebookInstancePage/actions';

interface RequestCourseInstanceInfoExpandParams {
  expandCoursebookInstances?: boolean;
  expandOverview?: boolean;
}

export const requestCourseInstanceInfo = (
  courseInstanceId: number,
  {expandCoursebookInstances, expandOverview}: RequestCourseInstanceInfoExpandParams
): AxiosRequestAction => ({
  type: REQUEST_COURSE_INSTANCE_INFO,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `/v2/course-instance/${courseInstanceId}`,
      params: {
        expand: `${expandOverview ? 'overview, ' : ''}${
          expandCoursebookInstances ? 'coursebookInstances, coursebookInstances.overview' : ''
        }`
      }
    }
  }
});

export interface RequestCourseInstanceInfoResponseData {
  coursebookInstances?: CoursebookInstance[];
  overview?: CourseInstanceOverview;
}

export interface SetCoursebookInstancesAction extends Action {
  instances: CoursebookInstance[];
}

export const setCoursebookInstances = (
  instances: CoursebookInstance[]
): SetCoursebookInstancesAction => ({
  type: SET_COURSEBOOK_INSTANCES,
  instances
});

export interface SetCourseInstanceOverviewAction extends Action {
  overview: CourseInstanceOverview;
}

export const setCourseInstanceOverview = (
  overview: CourseInstanceOverview
): SetCourseInstanceOverviewAction => ({
  type: SET_COURSE_INSTANCE_OVERVIEW,
  overview
});

export const toggleCoursebookInstanceDropdown = (show: boolean): ToggleElementAction => ({
  type: TOGGLE_BREADCRUMB_DROPDOWN,
  show
});

export const clearCourseInstanceInfo = (): Action => ({
  type: CLEAR_COURSE_INSTANCE_INFO
});

export const togglePagerPopover = (show: boolean): ToggleElementAction => ({
  type: TOGGLE_PAGER_POPOVER,
  show
});

export const createCoursebookInstance = (
  courseInstanceId: number,
  coursebookId: string
): AxiosRequestAction => ({
  type: CREATE_COURSEBOOK_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      url: `v2/coursebook-instance`,
      method: 'POST',
      data: {
        courseInstanceId,
        coursebookId
      }
    }
  }
});

export interface CoursebookInstanceAction extends Action {
  coursebookInstanceId: string;
}

export function isCoursebookInstanceAction(action: Action): action is CoursebookInstanceAction {
  return !!(action as CoursebookInstanceAction).coursebookInstanceId;
}

export const coursebookInstanceIncrementCompletedExercises = (
  coursebookInstanceId: string
): CoursebookInstanceAction => ({
  type: COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES,
  coursebookInstanceId
});

export const coursebookInstanceDecrementCompletedExercises = (
  coursebookInstanceId: string
): CoursebookInstanceAction => ({
  type: COURSEBOOK_INSTANCE_DECREMENT_COMPLETED_EXERCISES,
  coursebookInstanceId
});

export interface CoursebookInstanceIncrementCompletedUnitsAction
  extends CoursebookInstanceAction,
    IncrementCBICompletedUnitsAction {}

export const coursebookInstanceIncrementCompletedUnits = (
  coursebookInstanceId: string,
  isTest?: boolean
): CoursebookInstanceIncrementCompletedUnitsAction => ({
  type: COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS,
  coursebookInstanceId,
  isTest
});

export const deactivateCoursebookInstance = (id: string): AxiosRequestAction => ({
  type: DEACTIVATE_COURSEBOOK_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      url: `v2/coursebook-instance/${id}/deactivate`,
      method: 'patch'
    }
  }
});

export const reactivateCoursebookInstance = (id: string): AxiosRequestAction => ({
  type: REACTIVATE_COURSEBOOK_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      url: `v2/coursebook-instance/${id}/activate`,
      method: 'patch'
    }
  }
});

export const hideCoursebookInstance = (id: string): AxiosRequestAction => ({
  type: REQUEST_HIDE_COURSEBOOK_INSTANCE,
  payload: {
    client: 'v2',
    request: {
      url: `/v2/coursebook-instance/${id}/hide`,
      method: 'patch'
    }
  }
});

export interface CoursebookInstanceDeactivatedAction extends CoursebookInstanceAction {
  completedAt: string;
}

export const setCoursebookInstanceDeactivated = (
  coursebookInstanceId: string,
  completedAt: string
): CoursebookInstanceDeactivatedAction => ({
  type: SET_COURSEBOOK_INSTANCE_DEACTIVATED,
  coursebookInstanceId,
  completedAt
});

export const setCoursebookInstanceActivated = (
  coursebookInstanceId: string
): CoursebookInstanceAction => ({
  type: SET_COURSEBOOK_INSTANCE_ACTIVATED,
  coursebookInstanceId
});

export const coursebookInstanceDeleted = (
  coursebookInstanceId: string
): CoursebookInstanceAction => ({
  type: COURSEBOOK_INSTANCE_DELETED,
  coursebookInstanceId
});

export const loadHomeworkDraft = (courseInstanceId: number): AxiosRequestAction => ({
  type: LOAD_HOMEWORK_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'GET',
      url: `v2/course-instance/${courseInstanceId}/homework/draft`,
      validateStatus: status => status === 404 || (status >= 200 && status < 300)
    }
  }
});

export function isHomeworkDraft(
  draft: HomeworkWithContents | AxiosError
): draft is HomeworkWithContents {
  return !!(draft as HomeworkWithContents).id;
}

export interface SetHomeworkDraftAction extends Action {
  draft: HomeworkWithContents;
}

const setDraft = (draft: HomeworkWithContents): SetHomeworkDraftAction => ({
  type: SET_HOMEWORK_DRAFT,
  draft
});

export const setHomeworkDraft = (draft: HomeworkWithContents) => (dispatch: Dispatch<Action>) =>
  dispatch(setDraft(draft));

export const clearHomeworkDraft = (): Action => ({
  type: CLEAR_HOMEWORK_DRAFT
});

export const assignDocumentInstanceToDraft = (
  courseInstanceId: number,
  documentInstanceId: number,
  comment: string
): AxiosRequestAction => ({
  type: ASSIGN_DOCUMENT_INSTANCE_TO_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `v2/course-instance/${courseInstanceId}/homework/draft/document/${documentInstanceId}`,
      data: {
        comment
      }
    }
  }
});

export const assignDocumentInstancePageToDraft = (
  courseInstanceId: number,
  documentInstanceId: number,
  pageNumber: number,
  comment: string
): AxiosRequestAction => ({
  type: ASSIGN_DOCUMENT_INSTANCE_PAGE_TO_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'POST',
      url: `v2/course-instance/${courseInstanceId}/homework/draft/document/${documentInstanceId}/page/${pageNumber}`,
      data: {
        comment
      }
    }
  }
});

export const deleteDocumentInstanceFromDraft = (
  courseInstanceId: number,
  homeworkId: string,
  documentInstanceId: number
): AxiosRequestAction => ({
  type: DELETE_DOCUMENT_INSTANCE_FROM_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document/${documentInstanceId}`
    }
  }
});

export const deleteDocumentInstancePageFromDraft = (
  courseInstanceId: number,
  homeworkId: string,
  documentInstancePageId: number
): AxiosRequestAction => ({
  type: DELETE_DOCUMENT_INSTANCE_PAGE_FROM_DRAFT,
  payload: {
    client: 'v2',
    request: {
      method: 'DELETE',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document-page/${documentInstancePageId}`
    }
  }
});

export const editHWDocumentCommentRequest = (
  courseInstanceId: number,
  homeworkId: string,
  documentInstanceId: number,
  comment: string
): AxiosRequestAction => ({
  type: EDIT_HW_DOCUMENT_COMMENT_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document/${documentInstanceId}`,
      data: {
        comment
      }
    }
  }
});

export const editHWDocumentPageCommentRequest = (
  courseInstanceId: number,
  homeworkId: string,
  documentInstancePageId: number,
  comment: string
): AxiosRequestAction => ({
  type: EDIT_HW_DOCUMENT_PAGE_COMMENT_REQUEST,
  payload: {
    client: 'v2',
    request: {
      method: 'PUT',
      url: `v2/course-instance/${courseInstanceId}/homework/${homeworkId}/document-page/${documentInstancePageId}`,
      data: {
        comment
      }
    }
  }
});

export const requestToToggleCoursebookInstanceSelfCheck = (
  coursebookId: string,
  selfCheck: boolean
): AxiosRequestAction => ({
  type: REQUEST_TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK,
  payload: {
    client: 'v2',
    request: {
      method: 'PATCH',
      url: `v2/coursebook-instance/${coursebookId}/self-check`,
      data: {
        selfCheck: !selfCheck
      }
    }
  }
});
