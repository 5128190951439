import React from 'react';
import Popover from 'react-bootstrap/lib/Popover';
import Tab from 'react-bootstrap/lib/Tab';
import Tabs from 'react-bootstrap/lib/Tabs';
import {FormattedMessage} from 'react-intl';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';

import {type SelectCallback} from './interface';
import SearchBar from './SearchBar';
import './select-partner-popover.scss';

interface PartnersState {
  chatHeight: number; // used only in ChatSelectRoomPopover
  listElementHeightSet: boolean;
  searchBarHeight: number;
  listElementHeight: number;
  tabsHeight: number;
}

interface PartnersPopoverProps<E> {
  containerClassName?: string;
  isChatCompact?: boolean;
  partnerSelectedHandler: (id: number) => void;
  hidePopover: () => void;
  elements: {[key: string]: E};
  selectedElementId?: number;
  videoCall?: boolean;
  awaitingCallAnswer?: boolean;
  callPartnerId?: number;
  topPosition?: string | number;
  leftPosition?: string | number;
  arrowLeftOffset?: string;
  popoverHeight?: number;
  popoverId: string;
  filter: string;
  openTabId: string;
  changeActiveTab: (id: string) => void;
  changeFilter: (filter: string) => void;
  hideArrow?: boolean;
  currentStudentTeachersId?: string;
}

abstract class SelectPartnerPopover<E> extends React.Component<
  PartnersPopoverProps<E>,
  PartnersState
> {
  public static maxDisplayedRooms: number = 4;
  public static noElementsFoundHeight: number = 72.85;
  protected containerClassName: string = 'chat-popover-narrow';

  public state: PartnersState = {
    chatHeight: 0,
    listElementHeightSet: false,
    searchBarHeight: 0,
    listElementHeight: 60,
    tabsHeight: 0
  };

  public componentWillUnmount() {
    this.props.changeActiveTab('1');
  }

  protected abstract sortActiveRooms: (elements: E[]) => E[];

  protected abstract sortByAlphabet: (el1: E, el2: E) => 1 | -1;

  protected abstract isElementActive: (el: E) => boolean;

  protected abstract elementMatchesSearchFilter: (el: E) => boolean;

  protected getScrollBarHeight = () => {
    const {openTabId} = this.props;
    const activeElementsNumber: number = Object.keys(this.activeElements).length;
    const inactiveElementsNumber: number = Object.keys(this.inactiveElements).length;
    if (openTabId === '1') {
      return activeElementsNumber > 4
        ? this.getHeight(activeElementsNumber) + this.state.listElementHeight / 2
        : this.getHeight(activeElementsNumber);
    }
    return activeElementsNumber > 4
      ? this.getHeight(inactiveElementsNumber) + this.state.listElementHeight / 2
      : this.getHeight(inactiveElementsNumber);
  };

  public render() {
    const {
      openTabId,
      isChatCompact,
      popoverId,
      leftPosition,
      topPosition,
      filter,
      changeFilter,
      hideArrow,
      popoverHeight,
      containerClassName
    } = this.props;
    const elementsLength =
      openTabId === '1'
        ? Object.keys(this.activeElements).length
        : Object.keys(this.inactiveElements).length;
    const height = this.getScrollBarHeight() - (isChatCompact && elementsLength > 1 ? 6 : 0);
    const scrollBarStyle: {[key: string]: string | number} = {
      width: '100%',
      height: popoverHeight ? Math.min(popoverHeight - 104, height) : height
    };
    const className = classNames('contacts-popover', {'no-arrow': hideArrow});
    return (
      <Popover
        id={popoverId}
        arrowOffsetLeft={this.props.arrowLeftOffset}
        positionLeft={leftPosition}
        positionTop={isChatCompact ? 44 : topPosition}
        placement="bottom"
        className={className}
      >
        <div className={containerClassName || this.containerClassName} title="">
          <SearchBar
            getSize={this.getSearchBarSize}
            isSmall={true}
            filter={filter}
            changeFilter={changeFilter}
            isMobile={isChatCompact}
          />
          <Tabs
            id="chat-rooms-tabs"
            activeKey={openTabId}
            onSelect={this.onTabSelect as SelectCallback}
            className="chat-rooms-tabs"
          >
            <Tab
              eventKey="1"
              title={
                <div>
                  <FormattedMessage id="Chat.ActiveRooms" /> (
                  {Object.keys(this.activeElements).length})
                </div>
              }
            >
              <Scrollbars style={scrollBarStyle} autoHide={true} hideTracksWhenNotNeeded={true}>
                <ul ref={this.getActiveElementsRef} className="popover-ul">
                  {this.renderActiveElements()}
                </ul>
              </Scrollbars>
            </Tab>
            <Tab
              eventKey="2"
              title={
                <div ref={this.setTabHeight}>
                  <FormattedMessage id="Chat.InactiveRooms" /> (
                  {Object.keys(this.inactiveElements).length})
                </div>
              }
            >
              <Scrollbars style={scrollBarStyle} autoHide={true} hideTracksWhenNotNeeded={true}>
                <ul ref={this.getInactiveElementsRef} className="popover-ul">
                  {this.renderInactiveElements()}
                </ul>
              </Scrollbars>
            </Tab>
          </Tabs>
        </div>
      </Popover>
    );
  }

  protected getActiveElementsRef = (el: HTMLUListElement) => {
    if (this.props.openTabId === '1' && Object.keys(this.activeElements).length) {
      this.setRoomHeight(el, Object.keys(this.activeElements).length);
    }
  };

  protected getInactiveElementsRef = (el: HTMLUListElement) => {
    if (this.props.openTabId === '2') {
      this.setRoomHeight(el, Object.keys(this.inactiveElements).length);
    }
  };

  public abstract renderElement: (el: E) => JSX.Element;

  protected getSearchBarSize = (height: number, width: number) => {
    if (!this.state.searchBarHeight) {
      this.setState({searchBarHeight: height});
    }
  };

  private setRoomHeight = (el: HTMLUListElement, elementsNumber: number) => {
    if (el && !this.state.listElementHeightSet && el.clientHeight !== 0) {
      this.setState({
        listElementHeightSet: true,
        listElementHeight: el.clientHeight / elementsNumber
      });
    }
  };

  private setTabHeight = (el: HTMLDivElement) => {
    if (el && !this.state.tabsHeight) {
      this.setState({tabsHeight: el.clientHeight});
    }
  };

  private renderInactiveElements = () => {
    const inactiveElements: E[] = [];

    for (const id in this.inactiveElements) {
      inactiveElements.push(this.inactiveElements[id]);
    }
    inactiveElements.sort(this.sortByAlphabet);
    if (!inactiveElements.length) {
      return this.renderNothingFound();
    }
    return inactiveElements.map(this.renderElement);
  };

  private renderActiveElements = () => {
    let activeElements: E[] = [];

    for (const id in this.activeElements) {
      activeElements.push(this.activeElements[id]);
    }
    activeElements = this.sortActiveRooms(activeElements);
    if (!activeElements.length) {
      return this.renderNothingFound();
    }
    return activeElements.map(this.renderElement);
  };

  private renderNothingFound = () => {
    return (
      <div className="nothing-found">
        <FormattedMessage id="Common.NothingFound" />
      </div>
    );
  };

  private onTabSelect = (eventKey: string) => {
    if (this.props.openTabId !== eventKey) {
      this.props.changeActiveTab(eventKey);
    }
  };

  private getHeight = (elementsNumber: number) => {
    if (elementsNumber < SelectPartnerPopover.maxDisplayedRooms) {
      if (elementsNumber === 0) {
        return SelectPartnerPopover.noElementsFoundHeight;
      }
      return this.state.listElementHeight * elementsNumber;
    }
    return this.state.listElementHeight * SelectPartnerPopover.maxDisplayedRooms;
  };

  public get activeElements() {
    return Object.values(this.props.elements)
      .filter(this.elementMatchesSearchFilter)
      .filter(this.isElementActive);
  }

  public get inactiveElements() {
    return Object.values(this.props.elements)
      .filter(this.elementMatchesSearchFilter)
      .filter((el: E) => !this.isElementActive(el));
  }
}
export default SelectPartnerPopover;
