import {type Action, type ActionCreator, type Dispatch, type MiddlewareAPI} from 'redux';
import {type ThunkAction, type ThunkDispatch} from 'redux-thunk';
import {matchPath} from 'react-router-dom';
import {type IEvent} from 'autobahn';

import {
  type AppState,
  type CallEndedMeta,
  type ChatClipboard,
  type ChatMessage,
  type ChatState,
  type Room,
  type ServiceMessageMeta
} from 'store/interface';
import {CALL, SUBSCRIBE, UNSUBSCRIBE} from 'services/wamp/actions/types';
import {
  type Dispatch as WampDispatch,
  type WampCallResponseAction,
  type WampPublishAction,
  type WampUnsubscribeAction
} from 'services/wamp/actions/interface';
import {type ToggleElementCreator} from 'common/interface';
import {studentTeacherPattern} from 'common/paths';
import {chatTypingAdvanceTimeout, chatTypingAwaitTimeout} from 'config/static';

import {type ChangeFilterCreator} from '../../../routes/ClassRoom/actions/interface';
import {
  CHANGE_ACTIVE_TAB,
  CHANGE_ROOMS_FILTER,
  CHANGE_USER_SESSIONS,
  CLEAR_CHAT,
  CLEAR_CHAT_CLIPBOARD,
  CLEAR_MESSAGE_TO_UPDATE,
  CLEAR_ROOM_MESSAGES_EXEPT_SELECTED,
  DELETED_MESSAGE_RECEIVED,
  GET_PRIVATE_ROOMS,
  GET_PRIVATE_ROOMS_LIST,
  GET_PRIVATE_ROOMS_LIST_SUCCESS,
  GET_ROOM_MESSAGES,
  HIDE_START_PHRASES,
  MESSAGE_DELETE,
  MESSAGE_READ,
  MESSAGE_RECEIVED,
  MESSAGE_SEND,
  MESSAGE_UPDATE,
  PROMOTE_MESSAGE_TO_UPDATE,
  PUBLISH_TYPING_STARTED,
  RECIPIENT_TYPING_STARTED,
  RECIPIENT_TYPING_STOPPED,
  SELECT_ROOM,
  SET_CHAT_CLIPBOARD,
  SET_NOTIFICATION_TO_FALSE,
  SUBSCRIBE_TO_ROOM,
  TOGGLE_ROOMS_POPOVER,
  TOGGLE_SCROLLED_TO_BOTTOM,
  TOGGLE_START_PHRASES,
  TYPING_STARTED,
  TYPING_STOPPED,
  UNSUBSCRIBE_TO_ROOM,
  UPDATED_MESSAGE_RECEIVED
} from './actionTypes';
import {
  type ChangeActiveTabCreator,
  type ChangeUserStatusCreator,
  type ChatAction,
  type ChatTypingCreator,
  type ClearChatCreator,
  type ClearMessageToUpdateActionCreator,
  type DeletedMessageReceivedActionCreator,
  type GetPrivateRoomsCreator,
  type GetRoomMessagesCreator,
  type MessageDeleteActionCreator,
  type MessageReadCreator,
  type MessageReceivedCreator,
  type MessageSendCreator,
  messagesLoadingLimit,
  type MessageUpdateActionCreator,
  type PromoteMessageToUpdateActionCreator,
  type RecipientTypingActionCreator,
  type ResetChatTypingCreator,
  type SelectRoomCreator,
  type SubscribeToRoomAction,
  type UpdatedMessageReceivedActionCreator
} from './interface';
import {type RTCCall, type SpeedtestResult} from '../../../webRTC/interface';
import {
  callAcceptedEvent,
  callAnswered,
  callAnsweredEvent,
  callEnd,
  callOfferedEvent,
  callSignalingStableEvent,
  callUpgradedEvent,
  changeCallStatus,
  iceCandidateEvent,
  incomingCall,
  muteRemoteStream,
  otherSessionCall,
  outgoingCall,
  outgoingCallInterrupted,
  partnerDisconnected,
  partnerSessionIdUpgraded,
  saveQualityAndSetTimeout
} from '../../../webRTC/action/action';
import {type SetQualityKwargs} from '../../../webRTC/action/interface';
import resolveIdFromUri from '../../../services/wamp/uriIdResolver';
import * as toastr from '../../toastr';

type RoomEventCallbackArgs = Array<
  number | ChatMessage | RTCCall | boolean | string | RTCIceCandidate
>;

interface RoomEventCallbackKwargs extends SetQualityKwargs {
  offer?: string;
  answer?: string;
  reconnect?: boolean;
}

type SelectChatRoomRelatedToSelectedStudentTeacherAction = ThunkAction<
  void,
  AppState,
  void,
  Action
>;

const callCreated =
  (call: RTCCall, video: boolean, audio: boolean): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const appState = getState();
    if (call.caller_id === appState.user.id) {
      if (call.caller_session === appState.wamp.sessionId) {
        dispatch(outgoingCall(call.recipient_id));
      } else {
        dispatch(otherSessionCall(call));
      }
    } else {
      const dispatchIncomingCall = () =>
        dispatch(
          incomingCall(call, {
            video,
            audio
          })
        );
      dispatch(hideStartPhrases(call.room_id));
      if (appState.rtc.callStarting) {
        dispatch(outgoingCallInterrupted());
        setTimeout(dispatchIncomingCall); // timeout added so that outgoing call is cancelled before incoming call action is dispatched
      } else {
        dispatchIncomingCall();
      }
    }
  };

const callMissed =
  (message: ChatMessage): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    if ((message.meta as ServiceMessageMeta).callerId !== getState().user.id) {
      message.new = true;
    }
    dispatch(messageReceived(message.room_id, message));
    dispatch(callEnd());
  };

const callBusy =
  (message: ChatMessage): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    if (state.rtc.call && (message.meta as ServiceMessageMeta).callId === state.rtc.call.id) {
      dispatch(callEnd());
    }
    if ((message.meta as ServiceMessageMeta).recipientId !== state.user.id) {
      message.new = true;
    }
    dispatch(messageReceived(message.room_id, message));
  };

const gotOfferSdp =
  (call: RTCCall): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const rtc = getState().rtc;
    if (rtc.incomingCall) {
      dispatch(callOfferedEvent(call.offer!));
    }
  };

const gotAnswerSdp =
  (call: RTCCall): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const rtc = getState().rtc;
    if (rtc.outgoingCall) {
      dispatch(callAnsweredEvent(call.answer!));
    }
    if (Number(call.caller_id) === getState().user.id) {
      const options: MediaStreamConstraints = {
        audio: Boolean(call.recipient_audio),
        video: Boolean(call.recipient_video)
      };
      dispatch(callAnswered(options, String(call.recipient_session), call.answer));
    } else {
      if (Number(call.recipient_session) === getState().wamp.sessionId) {
        dispatch(callAnswered());
      } else {
        dispatch(otherSessionCall());
      }
    }
  };

const callAccepted =
  (message: ChatMessage, answererSession: number): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    if ((message.meta as ServiceMessageMeta).callerId === getState().user.id) {
      const options: MediaStreamConstraints = {
        audio: (message.meta as ServiceMessageMeta).audio,
        video: (message.meta as ServiceMessageMeta).video
      };
      dispatch(callAcceptedEvent(options, String(answererSession)));
    } else {
      if (Number(answererSession) === getState().wamp.sessionId) {
        dispatch(callAcceptedEvent());
      } else {
        dispatch(otherSessionCall());
      }
    }
    dispatch(messageReceived(message.room_id, message));
  };

const callEnded =
  (message: ChatMessage): ThunkAction<void, AppState, never, Action> =>
  dispatch => {
    if ((message.meta as CallEndedMeta).endedBy === null) {
      dispatch(partnerDisconnected());
    } else {
      dispatch(callEnd());
    }
    dispatch(messageReceived(message.room_id, message));
  };

const callMuted =
  (
    callId: string,
    userId: number,
    video: boolean,
    audio: boolean,
    shouldReconnect?: boolean
  ): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const state = getState();
    if (
      state.rtc.call &&
      state.rtc.call.id === callId &&
      !state.rtc.otherSessionCall &&
      state.user.id !== userId
    ) {
      dispatch(muteRemoteStream(video, audio, shouldReconnect));
    }
  };

const callUpgrade =
  (kwargs: {
    offer?: string;
    answer?: string;
    userUpdatedSessionId?: number;
  }): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    const rtcState = getState().rtc;
    if (rtcState && !rtcState.otherSessionCall && rtcState.call) {
      if (kwargs.offer) {
        dispatch(callUpgradedEvent(kwargs.offer, 'offer'));
      }
      if (kwargs.answer) {
        dispatch(callUpgradedEvent(kwargs.answer, 'answer'));
      }
      if (kwargs.userUpdatedSessionId) {
        dispatch(partnerSessionIdUpgraded(kwargs.userUpdatedSessionId));
      }
    }
  };

const textMessageUpdated =
  (message: ChatMessage): ThunkAction<void, AppState, never, Action> =>
  dispatch => {
    dispatch(updatedMessageReceived(message.room_id, message));
    dispatch(resetRecipientTyping(message.room_id!));
  };

const recipientTypingStarted = (roomId: number, timerId: NodeJS.Timeout) => ({
  type: RECIPIENT_TYPING_STARTED,
  roomId,
  timerId
});

const recipientTypingStopped = (roomId: number) => ({
  type: RECIPIENT_TYPING_STOPPED,
  roomId
});

const privateRoomsList: GetPrivateRoomsCreator = () => ({
  type: GET_PRIVATE_ROOMS_LIST,
  wamp: {
    method: CALL,
    uri: 'chatroom:private',
    kwargs: {fields: 'id, deleted_at'}
  }
});

const recipientTyping =
  (roomId: number) =>
  (dispatch: ThunkDispatch<AppState, void, Action>, getState: () => AppState) => {
    clearRecipientTimer(roomId, getState().chat);

    const typingTimerId = setTimeout(() => {
      dispatch(resetRecipientTyping(roomId));
    }, chatTypingAwaitTimeout);

    dispatch(recipientTypingStarted(roomId, typingTimerId));
  };

const publishTypingStarted: (roomId: number, excludeUserId: number) => WampPublishAction = (
  roomId,
  excludeUserId
) => ({
  type: PUBLISH_TYPING_STARTED,
  wamp: {
    method: 'publish',
    uri: `chatroom:_${roomId}.event.message.typing`,
    options: {
      exclude_authid: [excludeUserId.toString()]
    }
  }
});

const typingStarted = (timerId: NodeJS.Timeout) => ({
  type: TYPING_STARTED,
  timerId
});

const typingStopped = () => ({
  type: TYPING_STOPPED
});

function clearRecipientTimer(roomId: number, chat?: ChatState): void {
  if (chat && chat.rooms && chat.rooms[roomId]) {
    const recipient = chat.rooms[roomId].recipient;

    if (recipient && recipient.typingTimerId) {
      clearTimeout(recipient.typingTimerId);
    }
  }
}

export const changeRoomsFilter: ChangeFilterCreator = (filter: string) => ({
  type: CHANGE_ROOMS_FILTER,
  filter
});

export const selectRoom: SelectRoomCreator = (id: number) => ({
  type: SELECT_ROOM,
  id
});

export const roomsChangeActiveTab: ChangeActiveTabCreator = (id: string) => ({
  type: CHANGE_ACTIVE_TAB,
  id
});

export const messageReceived: MessageReceivedCreator = (
  roomId: number,
  message: ChatMessage,
  shouldNotify?: boolean
) => ({
  type: MESSAGE_RECEIVED,
  roomId,
  message,
  shouldNotify
});

export const messageRead: MessageReadCreator = (roomId: number, messageId: number) => ({
  type: MESSAGE_READ,
  roomId,
  wamp: {
    method: CALL,
    uri: 'chatroom:message._' + messageId + '.read'
  }
});

export const getPrivateRooms: GetPrivateRoomsCreator = () => ({
  type: GET_PRIVATE_ROOMS,
  wamp: {
    method: CALL,
    uri: 'chatroom:private',
    kwargs: {expand: 'recipient, newMessagesCount'}
  }
});

export const getPrivateRoomsList = async (
  dispatch: WampDispatch<Action, WampCallResponseAction<{}, {}, Action>>
) => {
  const response = await dispatch(privateRoomsList());
  if (response.type === GET_PRIVATE_ROOMS_LIST_SUCCESS) {
    // if no active room were found, onSubscribe callback of wampAutoSub won't fire, so we need to load private rooms list manually
    const roomsArr = response.wamp.callResult.args[0];
    if (!roomsArr.find((room: Room) => !room.deleted_at)) {
      dispatch(getPrivateRooms()).then(() =>
        dispatch(selectChatRoomRelatedToSelectedStudentTeacher())
      );
    }
  }
};

export const getRoomMessages: GetRoomMessagesCreator = (
  roomId: number,
  withoutLoader?: boolean,
  lastMessageId?: number
) => ({
  type: GET_ROOM_MESSAGES,
  withoutLoader,
  wamp: {
    method: CALL,
    uri: 'chatroom:_' + String(roomId) + '.history',
    args: lastMessageId ? [messagesLoadingLimit, lastMessageId] : [messagesLoadingLimit],
    kwargs: {expand: 'thumbnail'}
  }
});

export const messageSend: MessageSendCreator = (roomId: number, message: string) => ({
  type: MESSAGE_SEND,
  wamp: {
    method: CALL,
    uri: 'chatroom:_' + roomId + '.message',
    args: [message]
  }
});

export const updateMessage: MessageUpdateActionCreator = (
  roomId: number,
  messageId: number,
  messageText: string
) => ({
  type: MESSAGE_UPDATE,
  roomId,
  wamp: {
    method: CALL,
    uri: `chatroom:message._${messageId}.update`,
    args: [messageText]
  }
});

export const deleteMessage: MessageDeleteActionCreator = (roomId: number, messageId: number) => ({
  type: MESSAGE_DELETE,
  roomId,
  messageId,
  wamp: {
    method: CALL,
    uri: `chatroom:message._${messageId}.delete`
  }
});

export const changeUserStatus: ChangeUserStatusCreator = (
  userId: number,
  onlineState: number,
  callsNumber: number
) => ({
  type: CHANGE_USER_SESSIONS,
  userId,
  onlineState,
  callsNumber
});

export const unsubscribeToRoom = (roomId: number): WampUnsubscribeAction => ({
  type: UNSUBSCRIBE_TO_ROOM,
  wamp: {
    method: UNSUBSCRIBE,
    uri: `chatroom:_${roomId}.event`
  }
});

export const clearChat: ClearChatCreator = () => ({
  type: CLEAR_CHAT
});

export const resetRecipientTyping: RecipientTypingActionCreator =
  (roomId: number) => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    clearRecipientTimer(roomId, getState().chat);
    dispatch(recipientTypingStopped(roomId));
  };

export const subscribeToRoom: (roomId: number) => SubscribeToRoomAction = (roomId: number) => ({
  type: SUBSCRIBE_TO_ROOM,
  wamp: {
    method: SUBSCRIBE,
    uri: 'chatroom:_' + roomId + '.event',
    options: {match: 'prefix'}
  },
  callback: onChatEvent
});

export const toggleRoomsPopover: ToggleElementCreator = (show: boolean) => ({
  type: TOGGLE_ROOMS_POPOVER,
  show
});

export const chatTyping: ChatTypingCreator =
  (roomId: number) => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const chat = getState().chat;
    if (chat) {
      let timerId = chat.typingTimerId;

      if (!timerId) {
        timerId = setTimeout(() => {
          dispatch(typingStopped());
        }, chatTypingAwaitTimeout - chatTypingAdvanceTimeout);

        dispatch(typingStarted(timerId));
        dispatch(publishTypingStarted(roomId, getState().user.id!));
      }
    }
  };

export const resetChatTyping: ResetChatTypingCreator =
  () => (dispatch: Dispatch<Action>, getState: () => AppState) => {
    const chat = getState().chat;
    if (chat) {
      const timerId = chat.typingTimerId;

      if (timerId) {
        clearTimeout(timerId);
        dispatch(typingStopped());
      }
    }
  };

export const promoteMessageToUpdate: PromoteMessageToUpdateActionCreator = (
  roomId: number,
  id: number
) => ({
  type: PROMOTE_MESSAGE_TO_UPDATE,
  roomId,
  id
});

export const clearMessageToUpdate: ClearMessageToUpdateActionCreator = (roomId: number) => ({
  type: CLEAR_MESSAGE_TO_UPDATE,
  roomId
});

export const clearRoomMessagesExeptSelected: ActionCreator<ChatAction> = () => ({
  type: CLEAR_ROOM_MESSAGES_EXEPT_SELECTED
});

export const setNotificationToFalse: ActionCreator<ChatAction> = () => ({
  type: SET_NOTIFICATION_TO_FALSE
});

export const toggleScrolledToBottom: ToggleElementCreator = (atBottom: boolean) => ({
  type: TOGGLE_SCROLLED_TO_BOTTOM,
  show: atBottom
});

export const selectChatRoomRelatedToSelectedStudentTeacher =
  (): SelectChatRoomRelatedToSelectedStudentTeacherAction =>
  (dispatch: Dispatch, getState: () => AppState) => {
    const state = getState();
    const studentTeacherRouteMatch = matchPath(
      {path: studentTeacherPattern, end: false},
      state.router.location!.pathname
    );
    if (
      !state.classroom ||
      !state.studentTeachers?.studentTeachers ||
      !studentTeacherRouteMatch ||
      !state.chat ||
      !state.chat.rooms ||
      !state.layout.isFirstLoadedPage
    ) {
      return;
    }
    const selectedUserId =
      state.studentTeachers.studentTeachers[studentTeacherRouteMatch.params.studentTeacherId!]
        .recipient.id;
    const roomToSelect = Object.values(state.chat.rooms).find(
      room => room.recipient && room.recipient.id === selectedUserId
    );
    if (roomToSelect && state.chat.selectedRoomId !== roomToSelect.id) {
      dispatch(selectRoom(roomToSelect.id));
    }
  };

export const setChatClipboard = (clipboard: ChatClipboard) => ({
  type: SET_CHAT_CLIPBOARD,
  clipboard
});

export const clearChatClipboard = () => ({
  type: CLEAR_CHAT_CLIPBOARD
});

export const toggleStartPhrases = (roomId: number) => ({
  type: TOGGLE_START_PHRASES,
  roomId
});

export const hideStartPhrases = (roomId: number) => ({
  type: HIDE_START_PHRASES,
  roomId
});

export const textMessageReceived =
  (message: ChatMessage): ThunkAction<void, AppState, never, Action> =>
  (dispatch, getState) => {
    if (message.sender_id === getState().user.id) {
      message.own = true;
    } else {
      message.new = true;
    }
    dispatch(messageReceived(message.room_id, message, !getState().layout.pageHasFocus));
    dispatch(hideStartPhrases(message.room_id as number));
  };

export const updatedMessageReceived: UpdatedMessageReceivedActionCreator = (
  roomId: number,
  updatedMessage: ChatMessage
) => ({
  type: UPDATED_MESSAGE_RECEIVED,
  roomId,
  updatedMessage
});

export const deletedMessageReceived: DeletedMessageReceivedActionCreator = (
  roomId: number,
  deletedMessage: ChatMessage
) => ({
  type: DELETED_MESSAGE_RECEIVED,
  roomId,
  deletedMessage
});

export const onChatEvent = (
  args: RoomEventCallbackArgs,
  kwargs: RoomEventCallbackKwargs,
  details: IEvent,
  {dispatch, getState}: MiddlewareAPI<ThunkDispatch<AppState, void, Action>, AppState>
) => {
  const topicArr: string[] = details.topic.split(/\./);
  const roomId = resolveIdFromUri(details.topic).roomId;
  if (topicArr[topicArr.length - 1] === 'status' && topicArr[topicArr.length - 2] === 'user') {
    dispatch(changeUserStatus(args[0], args[1], args[2]));
  }

  if (topicArr[topicArr.length - 2] === 'message') {
    switch (topicArr[topicArr.length - 1]) {
      case 'text':
      case 'image':
        dispatch(textMessageReceived(args[0] as ChatMessage));
        break;
      case 'typing':
        dispatch(recipientTyping(roomId));
        break;
      case 'updated':
        dispatch(textMessageUpdated(args[0] as ChatMessage));
        break;
      case 'deleted':
        dispatch(deletedMessageReceived(roomId, args[0] as ChatMessage));
        break;
      default:
        if (import.meta.env.MODE === 'development') {
          toastr.error('Wamp error', `unknown chat message event: ${details.topic}`);
        }
        break;
    }
  }

  if (topicArr[topicArr.length - 2] === 'call') {
    if (import.meta.env.MODE === 'development') {
      // eslint-disable-next-line no-console
      console.log(`Call ${topicArr[topicArr.length - 1]} event.`, args);
    }

    switch (topicArr[topicArr.length - 1]) {
      case 'created':
        dispatch(callCreated(args[0] as RTCCall, args[1] as boolean, args[2] as boolean));
        break;
      case 'accepted':
        dispatch(callAccepted(args[0] as ChatMessage, args[1] as number));
        break;
      case 'offered':
        dispatch(gotOfferSdp(args[0] as RTCCall));
        break;
      case 'answered':
        dispatch(gotAnswerSdp(args[0] as RTCCall));
        break;
      case 'missed':
        dispatch(callMissed(args[0] as ChatMessage));
        break;
      case 'busy':
        dispatch(callBusy(args[0] as ChatMessage));
        break;
      case 'end':
        dispatch(callEnded(args[0] as ChatMessage));
        break;
      case 'mute':
        dispatch(
          callMuted(
            args[0] as string,
            args[1] as number,
            args[2] as boolean,
            args[3] as boolean,
            kwargs.reconnect
          )
        );
        break;
      case 'muted': {
        const {call, otherSessionCall} = getState().rtc;
        if (call && call.id === args[0] && call.status === 'reconnecting') {
          dispatch(changeCallStatus('connected'));
        }
        if (!otherSessionCall) {
          dispatch(callSignalingStableEvent());
        }
        break;
      }
      case 'upgraded':
        dispatch(callUpgrade(kwargs));
        break;
      case 'candidate':
        dispatch(iceCandidateEvent(args[2]));
        break;
      case 'speedtest':
        const state = getState();
        if (state.rtc.call && state.rtc.call.id === args[0] && state.user.id !== args[1]) {
          const kw = kwargs as Required<SetQualityKwargs>;
          const remoteSpeedtest: SpeedtestResult = {
            ping: kw.p,
            jitter: kw.j,
            ip: kw.ip,
            uploadingSpeed: kw.u,
            downloadingSpeed: kw.d
          };
          dispatch(saveQualityAndSetTimeout(remoteSpeedtest));
        }
        break;
      default:
        if (import.meta.env.MODE === 'development') {
          toastr.error('Wamp error', `unknown chat event: ${details.topic}`);
          // eslint-disable-next-line no-console
          console.error(`unknown chat event: ${details.topic}`, args, kwargs);
        }
    }
  }
};
