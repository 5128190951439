const prefix = 'COURSE_INSTANCE/';

export const REQUEST_COURSE_INSTANCE_INFO = prefix + 'REQUEST_COURSE_INSTANCE_INFO';
export const SET_COURSEBOOK_INSTANCES = prefix + 'SET_COURSEBOOK_INSTANCES';
export const SET_COURSE_INSTANCE_OVERVIEW = prefix + 'SET_COURSE_INSTANCE_OVERVIEW';
export const TOGGLE_BREADCRUMB_DROPDOWN = prefix + 'TOGGLE_BREADCRUMB_DROPDOWN';
export const CLEAR_COURSE_INSTANCE_INFO = prefix + 'CLEAR_COURSE_INSTANCE_INFO';
export const TOGGLE_PAGER_POPOVER = prefix + 'TOGGLE_PAGER_POPOVER';
export const LOAD_HOMEWORK_DRAFT = prefix + 'LOAD_HOMEWORK_DRAFT';
export const SET_HOMEWORK_DRAFT = prefix + 'SET_HOMEWORK_DRAFT';
export const CLEAR_HOMEWORK_DRAFT = prefix + 'CLEAR_HOMEWORK_DRAFT';
export const CREATE_COURSEBOOK_INSTANCE = prefix + 'CREATE_COURSEBOOK_INSTANCE';
export const COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES =
  prefix + 'COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_EXERCISES';
export const COURSEBOOK_INSTANCE_DECREMENT_COMPLETED_EXERCISES =
  prefix + 'COURSEBOOK_INSTANCE_DECREMENT_COMPLETED_EXERCISES';
export const COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS =
  prefix + 'COURSEBOOK_INSTANCE_INCREMENT_COMPLETED_UNITS';
export const DEACTIVATE_COURSEBOOK_INSTANCE = prefix + 'DEACTIVATE_COURSEBOOK_INSTANCE';
export const REACTIVATE_COURSEBOOK_INSTANCE = prefix + 'REACTIVATE_COURSEBOOK_INSTANCE';
export const SET_COURSEBOOK_INSTANCE_DEACTIVATED = prefix + 'SET_COURSEBOOK_INSTANCE_DEACTIVATED';
export const SET_COURSEBOOK_INSTANCE_ACTIVATED = prefix + 'SET_COURSEBOOK_INSTANCE_ACTIVATED';
export const COURSEBOOK_INSTANCE_DELETED = prefix + 'COURSEBOOK_INSTANCE_DELETED';
export const ASSIGN_DOCUMENT_INSTANCE_TO_DRAFT = prefix + 'ASSIGN_DOCUMENT_INSTANCE_TO_DRAFT';
export const ASSIGN_DOCUMENT_INSTANCE_PAGE_TO_DRAFT =
  prefix + 'ASSIGN_DOCUMENT_INSTANCE_PAGE_TO_DRAFT';
export const DELETE_DOCUMENT_INSTANCE_FROM_DRAFT = prefix + 'DELETE_DOCUMENT_INSTANCE_FROM_DRAFT';
export const DELETE_DOCUMENT_INSTANCE_PAGE_FROM_DRAFT =
  prefix + 'DELETE_DOCUMENT_INSTANCE_PAGE_FROM_DRAFT';
export const EDIT_HW_DOCUMENT_COMMENT_REQUEST = prefix + 'EDIT_HW_DOCUMENT_COMMENT_REQUEST';
export const EDIT_HW_DOCUMENT_PAGE_COMMENT_REQUEST =
  prefix + 'EDIT_HW_DOCUMENT_PAGE_COMMENT_REQUEST';
export const REQUEST_TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK =
  prefix + 'REQUEST_TOGGLE_COURSEBOOK_INSTANCE_SELF_CHECK';
export const REQUEST_HIDE_COURSEBOOK_INSTANCE = prefix + 'REQUEST_HIDE_COURSEBOOK_INSTANCE';
