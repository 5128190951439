import {Map} from 'immutable';
import {type IntlShape} from 'react-intl';
import {type Block, Value, type Document, type ValueJSON, Editor} from '@englex/slate';
import * as yup from 'yup';

import {SlateBlock} from 'components/Slate/interface';
import {decorate} from 'immutable-record/decorate.util';
import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {isBlockOfType, questsInitialValue, valueFromText} from 'components/Slate/utils';
import {type WidgetToJSONOptions} from 'store/exercise/player/interface';
import {type MatchingFreeChoiceJSON} from 'store/exercise/player/widgets/Matching/interface';
import {exerciseExcerptLength} from 'config/static';

import validationMessages from '../i18n';
import XMatchingRecord from './XMatchingRecord';
import {type XMatchingChoices, type XMatchingFreeChoiceRecordProperties} from './interface';
import {
  checkingForDuplicates,
  getAnswerBlocks,
  isList,
  labelsNotEmpty,
  valueToString
} from './utils';

const noMoreAnswersThanQuestions = (content: Document, extraContent: Document): boolean =>
  extraContent.filterDescendants(block => isBlockOfType(block, SlateBlock.LIST_ITEM)).size >=
  content.filterDescendants(block => isBlockOfType(block, SlateBlock.LIST_ITEM)).size;

class XMatchingFreeChoiceRecord
  extends XMatchingRecord
  implements XMatchingFreeChoiceRecordProperties
{
  public declare readonly extraContent: Value;

  constructor(raw: MatchingFreeChoiceJSON) {
    super(raw);
    const contentValue = Value.fromJSON(raw.content);
    this.initValues({
      content: isList(contentValue)
        ? contentValue
        : Value.fromJSON(questsInitialValue('Sample category', 3)),
      extraContent: raw.extraContent
        ? Value.fromJSON(raw.extraContent)
        : Value.fromJSON(questsInitialValue('Sample answer', 6))
    });
  }

  public toJSON(options: WidgetToJSONOptions): MatchingFreeChoiceJSON {
    const {answers, ...data} = super.toJSON(options);

    return {
      ...data,
      content: this.contentWithIdsToJSON(this.content),
      extraContent: this.extraContent.toJSON(),
      choices: this.choices.toJS()
    };
  }

  private contentWithIdsToJSON(content: Value): ValueJSON {
    const change = new Editor({value: content});

    change.command(change => {
      const lis = content.document.filterDescendants((d: Block) => d.type === SlateBlock.LIST_ITEM);

      return lis.reduce((c: Editor, li: Block) => {
        const newId = li.text;

        return c.setNodeByKey(li.key, {
          data: li.data.set('id', newId)
        });
      }, change);
    });

    return change.value.toJSON();
  }

  public get choices(): XMatchingChoices {
    const value = this.extraContent;
    const answers = getAnswerBlocks(value.document);
    return Map(
      answers
        .sort((b1: Block, b2: Block) => b1.text.localeCompare(b2.text))
        .map((block: Block) => [block.text, {value: block.text}])
    );
  }

  public schema(intl: IntlShape) {
    return yup.object({
      content: yup
        .mixed()
        .test(
          'Categories not empty',
          intl.formatMessage(validationMessages.CategoriesNonEmpty),
          (v: Value) => labelsNotEmpty(v.document)
        )
        .test(
          'Answers not empty',
          intl.formatMessage(validationMessages.AnswersNonEmpty),
          (v: Value) => labelsNotEmpty(this.extraContent.document)
        )
        .test(
          'No more answers than questions',
          intl.formatMessage(validationMessages.NoMoreAnswersThanQuestions),
          (v: Value) =>
            noMoreAnswersThanQuestions(this.content.document, this.extraContent.document)
        )
        .test(
          'No duplicate categories',
          intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Category.Unique'}),
          (v: Value) => checkingForDuplicates(v.document)
        )
        .test(
          'No duplicate answers',
          intl.formatMessage({id: 'XEditor.Form.EditGap.Validation.Answer.Unique'}),
          (v: Value) => checkingForDuplicates(this.extraContent.document)
        )
    });
  }

  public get excerpt(): string {
    const task = this.task.document.text;
    if (task.length >= exerciseExcerptLength) {
      return task;
    }

    const categories = valueToString(this.content);
    const answers = valueToString(this.extraContent);

    return `${task} ${categories} ${answers}`.trim();
  }
}

decorate(XMatchingFreeChoiceRecord, {
  extraContent: property(valueFromText())
});
record()(XMatchingFreeChoiceRecord);
export default XMatchingFreeChoiceRecord;
