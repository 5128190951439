import {type Dispatch, useEffect, useRef, useState} from 'react';
import {type Action} from 'redux';

import {type Id, type Type} from './interface';

export const animationSpeed = 300;

type RegisterArgs = [Id, Id, Type];

type UseRegistration = (
  dispatch: Dispatch<Action>,
  register: (...args: RegisterArgs) => Action,
  unregister: (...args: RegisterArgs) => Action,
  ...args: RegisterArgs
) => void;

export const useRegistration: UseRegistration = (
  dispatch,
  register,
  unregister,
  providerId,
  id,
  type
) => {
  const registered = useRef(false);
  useEffect(() => {
    if (!registered.current) {
      dispatch(register(providerId, id, type));
      registered.current = true;
    }
    return () => {
      dispatch(unregister(providerId, id, type));
      registered.current = false;
    };
  }, [dispatch, register, unregister, providerId, id, type]);
};

export const useNode = (selector: string, refresh?: boolean) => {
  const [node, setNode] = useState<Element | null>(null);

  useEffect(() => {
    if (!node || refresh) {
      setNode(document.querySelector(selector));
    }
  }, [selector, refresh, node]);

  return node;
};
