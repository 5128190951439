import {AXIOS_ERROR_POSTFIX, AXIOS_SUCCESS_POSTFIX} from 'services/axios/interface';
const PATH: string = 'SOUNDS/';
export const CHANGE_PLAY_STATUS: string = PATH + 'CHANGE_PLAY_STATUS';
export const CHANGE_TIMESTAMP: string = PATH + 'CHANGE_TIMESTAMP';
export const CHANGE_ACTIVE: string = PATH + 'CHANGE_ACTIVE';
export const EDIT_SOUND: string = PATH + 'EDIT_SOUND';
export const EDIT_SOUND_SUCCESS: string = EDIT_SOUND + AXIOS_SUCCESS_POSTFIX;
export const EDIT_SOUND_ERROR: string = EDIT_SOUND + AXIOS_ERROR_POSTFIX;
export const DELETE_SOUND: string = PATH + 'DELETE_SOUND';
export const DELETE_SOUND_SUCCESS: string = DELETE_SOUND + AXIOS_SUCCESS_POSTFIX;
export const EDIT_LENGTH: string = PATH + 'EDIT_LENGTH';
export const OPEN_EDITOR: string = PATH + 'OPEN_EDITOR';
export const REQUEST_SOUNDS: string = PATH + 'REQUEST_SOUNDS';
export const REQUEST_SOUNDS_SUCCESS: string = REQUEST_SOUNDS + AXIOS_SUCCESS_POSTFIX;
export const REQUEST_SOUNDS_FAIL: string = REQUEST_SOUNDS + AXIOS_ERROR_POSTFIX;
export const SOUND_VIEWED: string = PATH + 'SOUND_VIEWED';
export const SOUND_VIEWED_SUCCESS: string = SOUND_VIEWED + AXIOS_SUCCESS_POSTFIX;
export const SOUNDS_RESET: string = PATH + 'SOUNDS_RESET';
export const CLEAR_OPENED_TOGETHER: string = PATH + 'CLEAR_OPENED_TOGETHER';
export const CHANGE_VOLUME: string = PATH + 'CHANGE_VOLUME';
export const CHANGE_PLAYBACK_RATE = PATH + 'CHANGE_PLAYBACK_RATE';
export const TOGGLE_VOLUME_POPOVER: string = PATH + 'TOGGLE_VOLUME_POPOVER';
export const SET_UNIQUE_PLAYBACK_ID: string = PATH + 'SET_UNIQUE_PLAYBACK_ID';
export const SET_SOUND_DELETING: string = PATH + 'SET_SOUND_DELETING';
