import {type Block, type Inline, type Mark, type Node} from '@englex/slate';

import {
  type GapFillType,
  type ClassMark,
  type GapFillInline,
  type IconInline,
  type LinkInline,
  type ListBlock,
  type ListItemBlock,
  type ListType,
  SlateBlock,
  SlateInline,
  type SlateMark,
  SlateObject,
  type StyledBlock,
  type ListStyleType
} from '../interface';

export function isMark(mark: {object?: string}): mark is Mark {
  return mark.object === SlateObject.MARK;
}

export function isMarkOfType(mark: {object?: string}, type: SlateMark): mark is Mark {
  return isMark(mark) && mark.type === type;
}

export function isBlock(node: Node): node is Block {
  return (node as StyledBlock).object === SlateObject.BLOCK;
}

export function isBlockOfType(node: Node, type: SlateBlock): node is Block {
  return isBlock(node) && node.type === type;
}

export function isInline(node: Node): node is Inline {
  return (node as Inline).object === SlateObject.INLINE;
}

export function isInlineOfType(node: Node, type: SlateInline): node is Inline {
  return isInline(node) && node.type === type;
}

export function isGapFillInline(node: Node): node is GapFillInline {
  return isInline(node) && node.type === SlateInline.GAP;
}

export function isGapFillInlineOfType(node: Node, type: GapFillType): node is GapFillInline {
  return isGapFillInline(node) && node.data.get('type') === type;
}

export function isLinkInline(node: Node): node is LinkInline {
  return isInline(node) && node.type === SlateInline.LINK;
}

export function isIconInline(node: Node): node is IconInline {
  return isInlineOfType(node, SlateInline.ICON);
}

export function isDefaultBlock(node: Node): node is Block {
  return isBlockOfType(node, SlateBlock.DEFAULT);
}

export function isImageBlock(node: Node): node is Block {
  return isBlockOfType(node, SlateBlock.IMAGE);
}

export function isListBlock(node: Node | void | undefined | null): node is ListBlock {
  return !!node && isBlockOfType(node, SlateBlock.LIST);
}

export function isListItemBlock(node: Node | void): node is ListItemBlock {
  return !!node && isBlockOfType(node, SlateBlock.LIST_ITEM);
}

export function isListType(block: ListBlock, type: ListType): boolean {
  return block.data.get('list') === type;
}

export function isListStyleType(block: ListBlock, listStyleType?: ListStyleType): boolean {
  return block.data.get('listStyleType') === listStyleType;
}

export function isListBlockOfType(node: Node, type: ListType): node is ListBlock {
  return isListBlock(node) && isListType(node, type);
}

export function isClassMark(mark: Mark): mark is ClassMark {
  return isMark(mark) && !!mark.data.get('className');
}

export function isClassMarkOfType(mark: Mark, type: SlateMark): mark is ClassMark {
  return isClassMark(mark) && mark.type === type;
}
