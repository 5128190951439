import React, {type FC, useCallback, memo} from 'react';
import Button from 'react-bootstrap/lib/Button';
import {useSelector} from 'react-redux';

import Icon from 'components/Icon';
import {useSearchingInODContext} from 'components/Dictionary/Sidebar/DictionaryArticle/contexts/searchingInODContext';
import {type AppState} from 'store/interface';
import {useIsTeacherDictionary} from 'components/Dictionary/hooks/useIsTeacherDictionary';
import {DictionarySearchMode} from 'common/enums';

import {SwitchDictionaryDropdown} from '../SwitchDictionaryDropdown/SwitchDictionaryDropdown';
import {DictionarySearchField} from '../DictionarySearchField/DictionarySearchField';

import './DictionarySearchPanel.scss';

interface Props {
  isSidebar?: boolean;
  onSidebarDictionaryReset?: () => void;
}

export const DictionarySearchPanel: FC<Props> = memo(({isSidebar, onSidebarDictionaryReset}) => {
  const isTeacher = useSelector((state: AppState) => state.user.role === 'teacher');

  const isTeacherDictionary = useIsTeacherDictionary();

  const {
    searchMode,
    searchFieldValue,
    setSearchFieldValue,
    openResultOfSearchingInOD,
    sendApiRequestEnToRus,
    sendApiRequestEn
  } = useSearchingInODContext();

  const onEnter = useCallback(() => {
    if (searchMode === DictionarySearchMode.MY_DICTIONARY) return;
    openResultOfSearchingInOD();

    if (searchMode === DictionarySearchMode.EN_TO_RUS) sendApiRequestEnToRus();
    else sendApiRequestEn();
  }, [openResultOfSearchingInOD, searchMode, sendApiRequestEn, sendApiRequestEnToRus]);

  return (
    <div className="dictionary-search-panel">
      <SwitchDictionaryDropdown isTeacher={isTeacher} isTeacherDictionary={isTeacherDictionary} />

      <DictionarySearchField
        isTeacher={isTeacher}
        isTeacherDictionary={isTeacherDictionary}
        value={searchFieldValue}
        isSidebar={isSidebar}
        onSidebarDictionaryReset={onSidebarDictionaryReset}
        setValue={setSearchFieldValue}
        onEnter={onEnter}
      />
      <Button
        onClick={onEnter}
        disabled={
          searchMode === DictionarySearchMode.MY_DICTIONARY || searchFieldValue.length === 0
        }
        className="search-btn"
      >
        <Icon name="search" />
      </Button>
    </div>
  );
});
