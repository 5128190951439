import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import Scrollbars from 'react-custom-scrollbars';
import Button from 'react-bootstrap/lib/Button';
import Spinner from 'react-spinkit';

import {messageReadingTime} from 'config/static';
import {changeChatSize, emitter, requestChatSize} from 'services/event-emitter';
import {type ChatMessage, CONNECTED} from 'store/interface';
import {type WampCallResponseAction} from 'services/wamp/actions/interface';
import resolveIdFromUri from 'services/wamp/uriIdResolver';
import genKey from 'components/Slate/utils/genKey';

import {type BodyProps} from './interface';
import Icon from '../../../Icon';
import {type MessageReadAction} from '../../actions/interface';
import {MESSAGE_READ_FAIL} from '../../actions/actionTypes';
import {SoundNotification, soundUrl} from '../../../../helpers/sound';
import UnreadMessagesNotification from '../UnreadMessagesNotification/UnreadMessagesNotification';
import Messages from './Messages';
import {StartPhrases} from '../StartPhrases/StartPhrases';

const startPhrasesListMinHeight = 210;

interface UnreadMessage {
  element: HTMLDivElement;
  isVisible: boolean;
  readingTimeout?: NodeJS.Timeout | null;
}

interface State {
  key: string;
}

class ChatBody extends React.PureComponent<BodyProps, {}> {
  private scrollbarContentHeight: number = 0;
  private scrollbar: Scrollbars;
  private container: HTMLDivElement;
  private scrollbarMessages: HTMLDivElement;
  private unreadMessages: {[key: string]: UnreadMessage} = {};

  public state: State = {key: genKey()};

  public componentDidMount(): void {
    if (this.props.selectedRoomId && !this.props.messages.length) {
      this.props.getRoomMessages(this.props.selectedRoomId);
    }
    if (this.scrollbar) {
      this.scrollToBottom();
      emitter.emit(changeChatSize, this.scrollbar.getClientHeight());
    }
    emitter.on(requestChatSize, this.handleChatSizeRequest);
  }

  public componentWillUnmount(): void {
    emitter.removeListener(requestChatSize, this.handleChatSizeRequest);
  }

  public componentDidUpdate(prevProps: BodyProps) {
    this.checkUnreadMessagesVisibilityIfNeeded(prevProps);
    this.clearReadMessagesTimeoutsIfNeeded(prevProps);
    if (this.props.selectedRoomId !== prevProps.selectedRoomId && this.props.selectedRoomId) {
      this.props.hidePhrases(this.props.selectedRoomId);
      this.setState({key: genKey()});
    }
    if (this.props.showStartPhrases !== prevProps.showStartPhrases) {
      if (!this.props.showStartPhrases) {
        this.scrollbarMessages.style.minHeight = '100%';
      }
    }
    if (this.props.scrollbarAtBottom && this.scrollbar) {
      this.scrollToBottom();
    }
    if (this.scrollbar) {
      emitter.emit(changeChatSize, this.scrollbar.getClientHeight());
    }
    if (this.props.loadingNewMessages && !prevProps.loadingNewMessages && this.scrollbar) {
      this.scrollbarContentHeight = this.scrollbar.getScrollHeight();
    }
    if (!this.props.loadingNewMessages && prevProps.loadingNewMessages && this.scrollbar) {
      this.scrollbar.scrollTop(this.scrollbar.getScrollHeight() - this.scrollbarContentHeight);
    }
    if ((this.props.chatPopoverOpen && this.props.isChatCompact) || !this.props.isChatCompact) {
      const roomChanged: boolean = this.props.selectedRoomId !== prevProps.selectedRoomId;
      if (roomChanged) {
        this.handleRoomChange();
      } else {
        this.checkIfNewMessageReceived(prevProps);
      }
    }
  }

  public render() {
    const {chatPopoverOpen, isChatCompact, showStartPhrases, role, chatIsActive} = this.props;
    if (!chatPopoverOpen && isChatCompact) {
      return null;
    }

    const isTeacher = role === 'teacher';

    return (
      <div ref={this.componentRef} className="chat-body-scrollbar">
        {this.renderAudioNotification()}
        {this.renderLoader()}
        <ReactResizeDetector onResize={this.onResize} refreshRate={16} refreshMode="throttle">
          {() => (
            <Scrollbars
              ref={this.scrollbarRef}
              autoHide={true}
              onScrollStop={this.handleScrollStop}
            >
              <div ref={this.scrollbarMessagesRef} className="chat-body-scrollbar__messages">
                <Messages
                  allMessagesLoaded={this.props.allMessagesLoaded}
                  messageToUpdateId={this.props.messageToUpdateId}
                  selectedRoomId={this.props.selectedRoomId}
                  messages={this.props.messages}
                  currentCallId={this.props.currentCallId}
                  userTimezone={this.props.userTimezone}
                  partnerName={this.props.partnerName}
                  userId={this.props.userId}
                  deleteMessage={this.props.deleteMessage}
                  promoteMessageToUpdate={this.props.promoteMessageToUpdate}
                  getRef={this.getMessageRef}
                  chatPopoverOpen={this.props.chatPopoverOpen}
                  isMobile={this.props.isMobile}
                  imageLoadingFailed={this.props.imageLoadingFailed}
                />
                {this.props.children}
                {isTeacher && chatIsActive && (
                  <StartPhrases
                    closed={!showStartPhrases}
                    toggleClosed={this.toggleClosed}
                    key={this.state.key}
                  />
                )}
              </div>
            </Scrollbars>
          )}
        </ReactResizeDetector>
        {this.renderScrollDownButton()}
      </div>
    );
  }

  public toggleClosed = () => {
    const roomId = this.props.selectedRoomId;

    if (!roomId) return;

    const lastMessage = document.querySelector('.isLastMessage');
    const lastMessageMarginBottom = lastMessage
      ? parseInt(getComputedStyle(lastMessage).marginBottom)
      : 0;

    // the condition below will work when there is free space between the button and the last message
    // (element padding isn't taken into account)
    // because of this space, the block opens in the wrong direction

    // its execution adds the required height for the scrollbar
    // so that the block with phrases opens from the bottom up

    if (
      !this.props.showStartPhrases &&
      lastMessageMarginBottom <= startPhrasesListMinHeight &&
      lastMessageMarginBottom !== 0
    ) {
      const requiredHeight =
        this.scrollbarMessages.scrollHeight + (startPhrasesListMinHeight - lastMessageMarginBottom);

      this.scrollbarMessages.style.minHeight = `${requiredHeight}px`;
    }

    this.props.togglePhrases(roomId);
  };

  private checkIfNewMessageReceived(prevProps: BodyProps) {
    const thisPropsLastMessageId =
      (this.props.messages[this.props.messages.length - 1] &&
        this.props.messages[this.props.messages.length - 1].id) ||
      null;
    const prevPropsLastMessageId =
      (prevProps.messages[prevProps.messages.length - 1] &&
        prevProps.messages[prevProps.messages.length - 1].id) ||
      null;
    const lastMessageChanged: boolean = prevPropsLastMessageId !== thisPropsLastMessageId;
    if (lastMessageChanged && thisPropsLastMessageId) {
      this.handleIncomingMessage(this.props.messages[this.props.messages.length - 1]);
    }
  }

  private checkUnreadMessagesVisibilityIfNeeded(prevProps: BodyProps) {
    const pageWasFocused = this.props.pageHasFocus && !prevProps.pageHasFocus;
    const chatWasExpanded = !this.props.chatCollapsed;
    const hasConnected = this.props.wampStatus === CONNECTED && prevProps.wampStatus !== CONNECTED;
    const messagesLoaded = !this.props.loadingNewMessages && prevProps.loadingNewMessages;
    const tabChanged = !this.props.dictionaryTabOpened && prevProps.dictionaryTabOpened;
    const wasOpened =
      (!this.props.chatCollapsed && prevProps.chatCollapsed) ||
      (this.props.chatPopoverOpen && !prevProps.chatPopoverOpen);

    const isAvailableFocused = (wasOpened || chatWasExpanded) && pageWasFocused;

    if (isAvailableFocused || chatWasExpanded || hasConnected || messagesLoaded || tabChanged) {
      this.checkUnreadMessagesVisibility();
    }
  }

  private clearReadMessagesTimeoutsIfNeeded(prevProps: BodyProps) {
    const pageLostFocus = !this.props.pageHasFocus && prevProps.pageHasFocus;
    const lostWampConnection =
      this.props.wampStatus !== CONNECTED && prevProps.wampStatus === CONNECTED;
    const wasClosed =
      (this.props.chatCollapsed && !prevProps.chatCollapsed) ||
      (!this.props.chatPopoverOpen && prevProps.chatPopoverOpen);
    if (pageLostFocus || lostWampConnection || wasClosed) {
      this.clearReadMessagesTimeouts();
    }
  }

  private componentRef = (el: HTMLDivElement) => el && (this.container = el);

  private scrollbarRef = (el: Scrollbars) => el && (this.scrollbar = el);

  private scrollbarMessagesRef = (el: HTMLDivElement) => el && (this.scrollbarMessages = el);

  private onResize = (width: number, height: number) => {
    emitter.emit(changeChatSize, height);
    if (this.scrollbar && this.props.scrollbarAtBottom) {
      this.scrollToBottom();
    }
  };

  private renderAudioNotification = () =>
    this.props.shouldPlayNotification ? (
      <audio
        src={soundUrl(SoundNotification.chatMessageReceived)}
        loop={false}
        autoPlay={true}
        onEnded={this.props.setNotificationToFalse}
      />
    ) : null;

  private handleRoomChange = () => {
    const {getRoomMessages, selectedRoomId, messages, allMessagesLoaded, loadingNewMessages} =
      this.props;
    if (!messages.length && !allMessagesLoaded && !loadingNewMessages) {
      this.clearReadMessagesTimeouts();
      getRoomMessages(selectedRoomId!);
    } else {
      this.checkUnreadMessagesVisibility();
      this.toggleScrolledToBottom();
    }
  };

  private handleIncomingMessage = (message: ChatMessage) => {
    const {
      selectedRoomId,
      resetRecipientTyping,
      messages,
      messageRead,
      chatCollapsed,
      pageHasFocus,
      dictionaryTabOpened
    } = this.props;
    if (message.own) {
      this.toggleScrolledToBottom();
    } else {
      if (selectedRoomId) {
        resetRecipientTyping(selectedRoomId);
      }
      if (this.isScrollable) {
        if ((this.scrollbar && this.props.scrollbarAtBottom) || messages.length === 0) {
          if (!chatCollapsed && pageHasFocus && !dictionaryTabOpened) {
            messageRead(selectedRoomId!, message.id).then(this.handleMessageReadCompleted);
          }
          this.toggleScrolledToBottom();
        }
      } else {
        this.checkUnreadMessagesVisibility();
        if (this.scrollbar.getValues().scrollHeight !== this.scrollbar.getValues().clientHeight) {
          this.toggleScrolledToBottom();
        }
      }
    }
  };

  private scrollToBottom = () => {
    setTimeout(() => this.scrollbar?.scrollToBottom(), 100);
  };

  private toggleScrolledToBottom = () => {
    if (!this.props.scrollbarAtBottom) {
      this.props.toggleScrolledToBottom(true);
    }
  };

  private renderLoader = () => {
    if (this.props.loadingNewMessages) {
      return (
        <div className="messages-loader">
          <Spinner name="three-bounce" className="chat-time-color sm" fadeIn="none" />
        </div>
      );
    } else {
      return null;
    }
  };

  private get isScrollable() {
    const values = this.scrollbar.getValues();
    return values.scrollHeight !== values.clientHeight;
  }

  private handleScrollStop = () => {
    if (!this.isScrollable) {
      return;
    }
    const shouldScrollToBottom = this.scrollbar.getValues().top >= 0.999;
    if (shouldScrollToBottom !== this.props.scrollbarAtBottom) {
      this.props.toggleScrolledToBottom(shouldScrollToBottom);
    }
    this.shouldLoadNewMessages();
    if (!this.props.chatCollapsed) {
      this.checkUnreadMessagesVisibility();
    }
  };

  private shouldLoadNewMessages = () => {
    if (
      this.scrollbar.getValues().top === 0 &&
      !this.props.allMessagesLoaded &&
      !this.props.loadingNewMessages &&
      this.props.selectedRoomId
    ) {
      if (this.props.messages[0]) {
        this.props.getRoomMessages(this.props.selectedRoomId!, false, this.props.messages[0].id);
      }
    }
  };

  private renderScrollDownButton = () => {
    return (
      !this.props.scrollbarAtBottom && (
        <Button className="scroll-down-button" onClick={this.toggleScrolledToBottom}>
          <Icon name="angle-down" size="lg" />
          <UnreadMessagesNotification
            unreadMessagesNumber={this.props.newMessagesCount}
            className="new-messages-count"
          />
        </Button>
      )
    );
  };

  private handleChatSizeRequest = () => {
    if (this.scrollbar) {
      emitter.emit(changeChatSize, this.scrollbar.getClientHeight());
    }
  };

  private checkUnreadMessagesVisibility = (nextProps?: BodyProps) => {
    const connected = this.props.wampStatus === CONNECTED;
    if (!this.props.pageHasFocus || !this.container) {
      return;
    }
    const containerRect = this.container.getBoundingClientRect();

    for (const id in this.unreadMessages) {
      const message: UnreadMessage = this.unreadMessages[id];
      message.isVisible = this.checkIfMessageVisible(
        message.element.getBoundingClientRect(),
        containerRect
      );
      if (!this.props.loadingNewMessages && nextProps && nextProps.loadingNewMessages) {
        if (message.isVisible && connected) {
          this.props
            .messageRead(this.props.selectedRoomId!, Number(id.substring(1)))
            .then(this.handleMessageReadCompleted);
        }
      } else if (message.isVisible && !message.readingTimeout && connected) {
        const selectedRoomId = this.props.selectedRoomId!;
        message.readingTimeout = setTimeout(() => {
          this.props
            .messageRead(selectedRoomId, Number(id.substring(1)))
            .then(this.handleMessageReadCompleted);
        }, messageReadingTime);
      } else if (!message.isVisible && message.readingTimeout) {
        clearTimeout(message.readingTimeout);
        message.readingTimeout = null;
      }
    }
  };

  private handleMessageReadCompleted = (
    action: WampCallResponseAction<{}, {}, MessageReadAction>
  ) => {
    if (action.type !== MESSAGE_READ_FAIL) {
      const id: string = `_${resolveIdFromUri(action.wamp.meta.previousAction.wamp.uri).messageId}`;
      if (id && this.unreadMessages[id]) {
        delete this.unreadMessages[id];
      }
    } else if (action.type === MESSAGE_READ_FAIL) {
      this.clearReadMessagesTimeouts();
    }
  };

  private clearReadMessagesTimeouts = () => {
    for (const id in this.unreadMessages) {
      if (this.unreadMessages[id].readingTimeout) {
        clearTimeout(this.unreadMessages[id].readingTimeout!);
        this.unreadMessages[id].readingTimeout = null;
      }
    }
  };

  private checkIfMessageVisible = (messageRect: ClientRect, containerRect: ClientRect) => {
    const isBelowContainerTop: boolean = messageRect.top + messageRect.height > containerRect.top;
    const isAboveContainerBottom: boolean =
      messageRect.top < containerRect.top + containerRect.height;
    return isBelowContainerTop && isAboveContainerBottom;
  };

  private getMessageRef = (el: HTMLDivElement, message: ChatMessage) => {
    if (el && message.new && !message.own) {
      if (!this.unreadMessages['_' + message.id]) {
        this.unreadMessages['_' + message.id] = {
          element: el,
          isVisible: false
        };
      } else {
        this.unreadMessages['_' + message.id].element = el;
      }
    }
  };
}

export default ChatBody;
