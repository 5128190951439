import React, {type FC} from 'react';
import classNames from 'classnames';
import {MobilePaint, MobileViewport, usePaint} from '@englex/paint-react';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const PaintContainer: FC<Props> = ({children, className, ...props}) => {
  const paint = usePaint();
  const isMobile = MobilePaint.is(paint);

  if (isMobile) {
    return (
      <Container className={classNames(className, 'paint-mobile')} {...props}>
        <MobileViewport paint={paint}>{children}</MobileViewport>
      </Container>
    );
  }

  return (
    <Container className={className} {...props}>
      {children}
    </Container>
  );
};

const Container: FC<Props> = ({children, className, ...props}) => {
  return (
    <div {...props} className={classNames('paint-container', className)}>
      {children}
    </div>
  );
};
