// TODO: move it into shared package lib
export function throttle<R = unknown, A extends unknown[] = []>(
  fn: (...args: A) => R,
  delay: number
) {
  let lastCall = 0;
  return function (...args: A): R | void {
    const now = new Date().getTime();
    if (now - lastCall < delay) return;

    lastCall = now;
    return fn(...args);
  };
}
