import {type Action} from 'redux';

import {type WampErrorAction} from 'services/wamp/actions/interface';

type CandidateType = 'host' | 'relay' | 'srflx';

export interface CandidatePairStat {
  availableOutgoingBitrate: number;
  bytesReceived: number;
  bytesSent: number;
  consentRequestsSent: number;
  currentRoundTripTime: number;
  id: string;
  localCandidateId: string;
  nominated: boolean;
  priority: number;
  remoteCandidateId: string;
  requestsReceived: number;
  requestsSent: number;
  responsesReceived: number;
  responsesSent: number;
  state: string;
  timestamp: number;
  totalRoundTripTime: number;
  transportId: string;
  type: 'candidate-pair';
  writable: boolean;
}

export interface CandidateStat {
  candidateType: CandidateType;
  deleted: boolean;
  id: string;
  ip: string;
  isRemote: boolean;
  port: number;
  priority: number;
  protocol: string;
  timestamp: number;
  transportId: string;
  type: 'local-candidate' | 'remote-candidate';
}

export interface IceServer extends RTCIceServer {
  ip: string;
  urls: string;
}

export interface RTCConfig extends RTCConfiguration {
  sdpSemantics?: string;
  iceServers?: IceServer[];
  iceTypeAllowed: Record<RTCIceCandidateType, boolean>;
  speedTestEnabled: boolean;
}

export interface ConnectedIceInfo {
  localIp: string;
  localPort: string;
  remoteIp: string;
  remotePort: string;
  type: CandidateType;
  turn?: string;
}

export interface BitrateInfo {
  receiving: number;
  sending: number;
}

export type RTCErrorAction = WampErrorAction<{}, {errors: Error[]}, Action>;

export const unsupportedBrowserCallErrorMessage =
  'Recipient is using Browser which does not supported by Virtual Class for WebRTC calls';

export const recipientOfflineCallErrorMessage = 'Recipient offline';
