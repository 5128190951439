import {type MarkDef} from '../../definitions';

export const fontSizes = ['smallest', 'smaller', 'small', 'large', 'largest'].reduce<MarkDef[]>(
  (colors, value) => {
    colors.push({
      mark: 'fontSize',
      value,
      attrs: {
        className: `sfs-${value}`
      }
    });
    return colors;
  },
  []
);

export const [fontSizeSmallest, fontSizeSmall, fontSizeLarge, fontSizeLargest] = fontSizes;
