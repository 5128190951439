export const pronunciationPhoneticSpellingMaxLength = 255;

export const listTitleMaxLength = 255;

export const maxListEntries = 100;

export const additionalSymbols = [
  'ɔ',
  'ɜ',
  'ʊ',
  'ʌ',
  'ə',
  'æ',
  'ɑ',
  'ɒ',
  'ɪ',
  'ʃ',
  'ʒ',
  'ŋ',
  'ð',
  'ɵ',
  'ˈ',
  'ˌ'
];
