import {Value} from '@englex/slate';

import genKey from 'components/Slate/utils/genKey';
import {recordBase} from 'immutable-record/decorator/recordBase';
import {BaseRecord} from 'immutable-record/Record';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {decorate} from 'immutable-record/decorate.util';

import {type CardJSON, CardsType, type CardProperties} from './interface';

const Record = recordBase()(BaseRecord);

class CardRecord extends Record implements CardProperties {
  public declare readonly id: string;

  public declare readonly type: CardsType;

  public declare readonly title: Value;

  public declare readonly text: Value;

  public declare readonly image: number;

  constructor(raw: Partial<CardJSON> & {type: CardsType}) {
    super();
    this.initValues({
      id: raw.id || genKey(),
      type: raw.type,
      text: Value.fromJSON(raw.text!),
      title: raw.title ? Value.fromJSON(raw.title) : undefined,
      image: raw.image
    });
  }

  public toJSON(type: CardsType): CardJSON {
    switch (type) {
      case CardsType.TEXT:
        return {
          id: this.id,
          title: this.title.toJSON(),
          text: this.text.toJSON()
        };

      case CardsType.IMAGES:
        return {
          id: this.id,
          image: this.image,
          text: this.text.toJSON()
        };

      default:
        throw Error('Unknown card type');
    }
  }
}

decorate(CardRecord, {
  id: property(genKey()),
  type: property(),
  title: property(),
  text: property(),
  image: property()
});
record()(CardRecord);

export default CardRecord;
