import {type Action} from 'redux';

import {type ActionHandlersList} from 'store/reducers';

import {SET_TEACHER_SETTINGS} from '../actions/actionTypes';
import {type RequestTeacherSettings, type TeacherSettings} from '../actions/interface';

export interface SettingsState {
  teacher?: TeacherSettings;
}

const initialSettingsState: SettingsState = {};

const ACTION_HANDLERS: ActionHandlersList<SettingsState, Action> = {
  [SET_TEACHER_SETTINGS]: (state: SettingsState, action: RequestTeacherSettings) => ({
    ...state,
    teacher: action.teacherSettings
  })
};

export default function (
  state: SettingsState = initialSettingsState,
  action: Action
): SettingsState {
  const settingsReducer = ACTION_HANDLERS[action.type];
  return settingsReducer ? settingsReducer(state, action) : state;
}
