import {record} from 'immutable-record/decorator/record';

import {WidgetType} from '../../interface';
import WidgetRecord from '../../WidgetRecord';

class UnknownRecord extends WidgetRecord {
  public get type() {
    return WidgetType.UNKNOWN;
  }
}
record()(UnknownRecord);
export default UnknownRecord;
