import React from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';

import notFoundImage from 'assets/404_pic.png';
import notFoundImage2x from 'assets/404_pic@2x.png';

import './PageNotFound.scss';

export class PageNotFound extends React.Component {
  public render(): JSX.Element {
    return (
      <div className="page-not-found">
        <h1>
          <FormattedMessage id="Common.PageNotFound" />
        </h1>
        <div className="img-container">
          <img src={notFoundImage} srcSet={`${notFoundImage2x} 2x`} alt="" />
        </div>
        <FormattedMessage
          id="Common.PageNotFoundText"
          values={{
            goHome: (
              <Link to="/" className="lnk" replace={true}>
                <FormattedMessage id="Common.PageNotFound.BackLink" />
              </Link>
            )
          }}
        />
      </div>
    );
  }
}
