import React, {type FC} from 'react';
import Tooltip from 'rc-tooltip';
import classNames from 'classnames';

import PronunciationSoundPlayer from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import Spinner from 'components/Spinner';
import Icon from 'components/Icon';

export const PronunciationTooltip: FC<{
  phoneticSpelling?: string | null;
  soundId?: number | null;
  inError?: boolean;
}> = ({phoneticSpelling, soundId, inError}) => {
  if (!phoneticSpelling && !soundId) {
    return null;
  }
  if (phoneticSpelling) {
    return (
      <Tooltip
        destroyTooltipOnHide={true}
        overlay={`[${phoneticSpelling}]`}
        trigger={['hover']}
        placement="top"
      >
        <span className={classNames('sound-player', {error: inError})}>
          <SoundPlayer soundId={soundId} phoneticSpelling={phoneticSpelling} />
        </span>
      </Tooltip>
    );
  }
  return (
    <span className={classNames('sound-player', {error: inError})}>
      <SoundPlayer soundId={soundId} />
    </span>
  );
};

const SoundPlayer: FC<{soundId?: number | null; phoneticSpelling?: string | null}> = ({
  soundId,
  phoneticSpelling
}) => {
  return (
    <PronunciationSoundPlayer
      soundId={soundId || undefined}
      renderContent={({playSound, status}) =>
        status === 'loading' ? (
          <Spinner size={16} />
        ) : (
          <PronunciationIcon
            onClick={playSound}
            className={classNames({playing: status === 'playing'})}
            soundId={soundId}
            phoneticSpelling={phoneticSpelling}
          />
        )
      }
    />
  );
};

const PronunciationIcon: FC<{
  onClick?: () => void;
  className?: string;
  soundId?: number | null;
  phoneticSpelling?: string | null;
}> = ({soundId, phoneticSpelling, onClick, className}) => {
  if (soundId) {
    return <Icon name="volume-up" onClick={onClick} className={className} />;
  }
  if (phoneticSpelling) {
    return <Icon name="commenting" />;
  }
  return null;
};
