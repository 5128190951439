import React from 'react';
import Button from 'react-bootstrap/lib/Button';
import Checkbox from 'react-bootstrap/lib/Checkbox';
import DropdownButton from 'react-bootstrap/lib/DropdownButton';
import Form from 'react-bootstrap/lib/Form';
import {default as FormControl, type FormControlProps} from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import Modal from 'react-bootstrap/lib/Modal';
import {type FormControlFeedbackProps} from 'react-bootstrap/lib/FormControlFeedback';
import {defineMessages, FormattedMessage, type MessageDescriptor} from 'react-intl';
import Spinner from 'react-spinkit';

import * as toastr from 'components/toastr';
import Icon from 'components/Icon';

import {type IntlProps} from './MessageModal';
import SimpleLoadingOverlay from '../../components/loaders/overlay/SimpleLoadingOverlay';
import ModalError from '../../components/ModalError';
import {type SelectCallback} from '../../components/interface';
import './message-modal-content.scss';

interface ModalContentState {
  subject: string;
  text: string;
  name: string;
  email: string;
  sendEmail: boolean;
  subjectDirty: boolean;
  textDirty: boolean;
  nameDirty: boolean;
  emailDirty: boolean;
}

type ValidationState = 'success' | 'warning' | 'error' | undefined;

type FormatMessage = (messageDescriptor: MessageDescriptor, values?: object) => string;

const otherMessages = defineMessages({
  MessageSubject: {
    id: 'MessageModal.MessageSubject'
  },
  MessagePlaceHolder: {
    id: 'MessageModal.MessagePlaceholder'
  },
  SendMessageSuccess: {
    id: 'MessageModal.SendMessageSuccess'
  },
  SendMessageErrorHeader: {
    id: 'MessageModal.SendMessageErrorHeader'
  },
  SendMessageErrorContent: {
    id: 'MessageModal.SendMessageErrorContent'
  },
  NamePlaceHolder: {
    id: 'Common.Name'
  }
});

export default class MessageModalContent extends React.Component<IntlProps, ModalContentState> {
  public state: ModalContentState = {
    subject: '',
    text: '',
    name: '',
    email: '',
    sendEmail: false,
    subjectDirty: false,
    textDirty: false,
    nameDirty: false,
    emailDirty: false
  };

  protected formatMessage: FormatMessage = this.props.intl.formatMessage;
  private textareaRows: number = 8;

  public componentDidMount() {
    if (!this.props.subjects) {
      this.props.requestSubjects();
    }
  }

  public render(): JSX.Element {
    const sendButton: JSX.Element | null =
      this.props.networkError || !this.props.subjects ? null : (
        <Button
          bsStyle="primary"
          bsSize="sm"
          onClick={this.onSend}
          disabled={this.props.sendingMessageAwait}
        >
          <FormattedMessage id="Common.Send" />
        </Button>
      );
    return (
      <div className="sendmessage-modal-content" data-testid="test-smm-content">
        {this.renderLoadingOverlay()}
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="MessageModal.MessageForManager" />
            <a onClick={this.hideContactsModal}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>

        <Form>
          {this.renderFormBody()}
          <Modal.Footer>
            <Button
              bsStyle="default"
              className="btn-transparent"
              bsSize="sm"
              onClick={this.hideContactsModal}
            >
              <FormattedMessage id="Common.Cancel" />
            </Button>
            {sendButton}
          </Modal.Footer>
        </Form>
      </div>
    );
  }

  protected renderNameInput = (): null | JSX.Element => {
    return null;
  };

  protected renderEmailInput = (): null | JSX.Element => {
    return null;
  };

  protected onTextChange = (event: React.SyntheticEvent<FormControlProps>) => {
    switch (event.currentTarget.id) {
      case 'text-control':
        this.setState({text: event.currentTarget.value} as ModalContentState);
        break;
      default:
        break;
    }
  };

  protected onInputBlur = (event: React.FormEvent<FormControlFeedbackProps>) => {
    switch (event.currentTarget.id) {
      case 'text-control':
        this.setState({textDirty: true} as ModalContentState);
        break;
      case 'subject-dropdown':
        this.setState({subjectDirty: true} as ModalContentState);
        break;
      default:
        break;
    }
  };

  private hideContactsModal = () => {
    this.props.toggleContactsModal(false);
  };

  private getSubjectValidation(): ValidationState {
    if (!this.state.subject && this.state.subjectDirty) {
      return 'error';
    } else {
      return undefined;
    }
  }

  private renderFormBody = () => {
    if (this.props.networkError) {
      const errorText = <FormattedMessage id="MessageModal.GetSubjectsErrorText" />;
      const errorTitle = <FormattedMessage id="MessageModal.GetSubjectsErrorTitle" />;
      return (
        <Modal.Body>
          <ModalError
            reload={this.props.requestSubjects}
            errorTitle={errorTitle}
            errorText={errorText}
          />
        </Modal.Body>
      );
    }
    if (!this.props.subjects) {
      return (
        <Modal.Body>
          <Spinner name="three-bounce" className="center-block brand-primary" fadeIn="none" />
        </Modal.Body>
      );
    }

    return (
      <Modal.Body>
        {this.renderNameInput()}
        {this.renderEmailInput()}
        <FormGroup
          controlId="subject-control"
          validationState={this.getSubjectValidation()}
          className={`message-modal-dropdown-wrapper ${this.props.isMobile ? 'mobile' : ''}`}
        >
          {this.props.isMobile ? (
            <div>
              <select
                id="message-modal-select-dropdown"
                className="message-modal-select-dropdown"
                onChange={this.handleMobileSelect}
              >
                <option hidden={true}>{this.formatMessage(otherMessages.MessageSubject)}</option>
                {Object.keys(this.props.subjects).map(key => (
                  <option key={key} value={key}>
                    {this.props.subjects![key]}
                  </option>
                ))}
              </select>
              <div className="message-modal-select-dropdown-arrow" />
            </div>
          ) : (
            <DropdownButton
              bsSize="lg"
              id="subject-dropdown"
              title={this.messageSubjectPlaceholder()}
              onSelect={this.onSelect as SelectCallback}
              onBlur={this.onInputBlur}
              className={`dropdown-toggle ${this.state.subjectDirty ? 'dirty' : ''}`}
            >
              {Object.keys(this.props.subjects).map(this.renderOption.bind(this))}
            </DropdownButton>
          )}
          <div className="help-block error">
            <FormattedMessage id="MessageModal.NoSubjectError" />
          </div>
        </FormGroup>

        <FormGroup controlId="text-control" validationState={this.getTextValidation()}>
          <FormControl
            bsSize="lg"
            componentClass="textarea"
            placeholder={this.formatMessage(otherMessages.MessagePlaceHolder)}
            onChange={this.onTextChange}
            rows={this.textareaRows}
            onBlur={this.onInputBlur}
          />
          <div className="help-block error">
            <FormattedMessage id="MessageModal.NoMessageError" />
          </div>
        </FormGroup>

        <FormGroup controlId="send-email">
          <Checkbox onClick={this.onEmailCheck}>
            <FormattedMessage id="MessageModal.SendCopyToEmail" />
          </Checkbox>
        </FormGroup>
      </Modal.Body>
    );
  };

  private renderLoadingOverlay = () => {
    if (this.props.sendingMessageAwait) {
      return <SimpleLoadingOverlay />;
    }
    return null;
  };

  private getTextValidation(): ValidationState {
    if (!this.state.text && this.state.textDirty) {
      return 'error';
    } else {
      return undefined;
    }
  }

  private handleMobileSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();
    const subject = event.target.value;
    this.setState({subject});
  };

  private onSelect = (eventKey: string) => {
    this.setState({subject: eventKey} as ModalContentState);
  };

  private renderOption = (subject: string) => {
    return (
      <MenuItem key={subject} active={this.state.subject === subject} eventKey={subject}>
        {this.props.subjects![subject]}
      </MenuItem>
    );
  };

  private sendMessage = () => {
    if (this.state.text.length && this.state.subject.length) {
      this.props
        .requestMessageSend(this.state.subject, this.state.text, this.state.sendEmail || undefined)
        .then(() => {
          this.props.toggleContactsModal(false);
          toastr.success('', this.formatMessage(otherMessages.SendMessageSuccess));
        })
        .catch(() => {
          toastr.error(
            this.formatMessage(otherMessages.SendMessageErrorHeader),
            this.formatMessage(otherMessages.SendMessageErrorContent)
          );
        });
    }
  };

  private onEmailCheck = () =>
    this.setState({sendEmail: !this.state.sendEmail} as ModalContentState);

  private onSend = () => {
    this.setState(
      {
        subjectDirty: true,
        textDirty: true,
        nameDirty: true,
        emailDirty: true
      },
      this.sendMessage
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private messageSubjectPlaceholder(): any {
    if (this.state.subject) {
      return <span>{this.props.subjects![this.state.subject]}</span>;
    }

    return (
      // placeholder
      <b>{this.formatMessage(otherMessages.MessageSubject)}</b>
    );
  }
}
