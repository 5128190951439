import {letterMatcher, wordFilter} from 'config/static';

import {type CheckerFn} from './interface';

export const wordChecker: CheckerFn = (s: string[], i: number) =>
  wordFilter.test(s[i]) && letterMatcher.test(s[i]);
export const letterChecker: CheckerFn = (s: string[], i: number) => letterMatcher.test(s[i]);

export const getSurroundOffsets = (text: string, char = "'") => {
  let [atStart, atEnd] = [0, 0];

  for (let i = 0; i < text.length; i++) {
    if (text[i] === char) {
      atStart++;
    } else {
      break;
    }
  }

  for (let i = text.length - 1; i > atStart; i--) {
    if (text[i] === char) {
      atEnd++;
    } else {
      break;
    }
  }

  return [atStart, atEnd];
};

export const isSurroundedByChar = (text: string, char = "'") =>
  text[0] === char && text[text.length - 1] === char;
