import Bowser from 'bowser';

export const isSafari = () =>
  Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Safari';

export const isOpera = () =>
  Bowser.getParser(window.navigator.userAgent).getBrowser().name === 'Opera';

export const isMobileWebView = () => typeof window.webViewData === 'object';

export const isIOS =
  /iPad|iPhone|iPod/.test(navigator.platform) ||
  (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

export const isWindows = /Win/.test(navigator.platform);

export const isAndroid = typeof navigator !== 'undefined' && /Android/.test(navigator.userAgent);

export const isMobileDevice = isIOS || isAndroid;

export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const isTouchMobileDevice = isMobileDevice && isTouchDevice();

export const isIOSMobileWebView = isIOS && isMobileWebView();
