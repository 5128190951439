import {type Path} from 'react-router-dom';

/**
 * Parses a string URL path into its separate pathname, search, and hash components.
 */
export function parsePath(path: string): Partial<Path> {
  const parsedPath: Partial<Path> = {};

  if (path) {
    const hashIndex = path.indexOf('#');
    if (hashIndex >= 0) {
      parsedPath.hash = path.substring(hashIndex);
      path = path.substring(0, hashIndex);
    }

    const searchIndex = path.indexOf('?');
    if (searchIndex >= 0) {
      parsedPath.search = path.substring(searchIndex);
      path = path.substring(0, searchIndex);
    }

    if (path) {
      parsedPath.pathname = path;
    }
  }

  return parsedPath;
}
