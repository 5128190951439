function getConstructorsNames(obj: object, names: string[] = []): string[] {
  const constructorName = obj?.constructor?.name;

  if (!constructorName || constructorName === 'MRecord') return names;

  return getConstructorsNames(Object.getPrototypeOf(obj), [...names, obj.constructor.name]);
}

class DefaultValues {
  private values = {};

  public set(key: string, value: unknown) {
    this.values[key] = value;
    return value;
  }

  public get(key: string) {
    return this.values[key] || {};
  }

  public merge(proto: object, value: object = {}) {
    const constructorNames = getConstructorsNames(proto);

    const [current, ...parents] = constructorNames;

    const withParentsProps = parents.reduce(
      (acc, parent) => ({...this.get(parent), ...acc}),
      value
    );

    return this.set(current, withParentsProps);
  }
}

export const defaultValues = new DefaultValues();
