import {connect} from 'react-redux';
import {type Dispatch} from 'redux';

import {type AppState} from 'store/interface';

import {toggleContactsModal, toggleContactsPopover} from '../actions/action';
import {type ToggleElementAction, type ToggleElementCreator} from '../../common/interface';
import ContactsNavItem from '../components/ContactsNavItem';

export interface ContactsNavItemStateProps {
  contactsPopoverOpen?: boolean;
  isMobile?: boolean;
}

export interface DispatchContactsNavItemProps {
  toggleContactsModal: ToggleElementCreator;
  toggleContactsPopover: ToggleElementCreator;
}

type HeaderDispatchToProps = (
  dispatch: Dispatch<ToggleElementAction>
) => DispatchContactsNavItemProps;

type HeaderStateToProps = (state: AppState) => ContactsNavItemStateProps;

const mapStateToProps: HeaderStateToProps = state => ({
  contactsPopoverOpen: state.layout.contactsPopoverOpen,
  isMobile: state.layout.isMobile
});

const mapDispatchToProps: HeaderDispatchToProps = (dispatch): DispatchContactsNavItemProps =>
  ({
    toggleContactsModal(show: boolean): void {
      dispatch(toggleContactsModal(show));
    },
    toggleContactsPopover(show: boolean): void {
      dispatch(toggleContactsPopover(show));
    }
  }) as DispatchContactsNavItemProps;

export default connect<ContactsNavItemStateProps, DispatchContactsNavItemProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(ContactsNavItem);
