import {Provider as ReduxProvider} from 'react-redux';
import React, {type PropsWithChildren} from 'react';

import {type AsyncStore} from 'store';
import {type AppState} from 'store/interface';

import IntlProvider from './intl-provider';

interface Props {
  store: AsyncStore<AppState>;
}

const Provider: React.FC<PropsWithChildren<Props>> = ({store, children}) => (
  <ReduxProvider store={store}>
    <IntlProvider>{children}</IntlProvider>
  </ReduxProvider>
);

export default Provider;
