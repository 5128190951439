import {useIntl} from 'react-intl';

import Icon from 'components/Icon';

interface Props {
  isTeacherDictionary: boolean;
  isTeacher: boolean;
}

export const useDictionaryDropdownItems = ({isTeacherDictionary, isTeacher}: Props) => {
  const {formatMessage} = useIntl();

  const dictionary = {
    myDictionary: {
      value: formatMessage({id: 'Dictionary.MyDictionary'})
    },
    studentDictionary: {
      value: formatMessage({id: 'Dictionary.StudentDictionary'})
    }
  };

  return {
    myDictionary:
      isTeacher && !isTeacherDictionary ? dictionary.studentDictionary : dictionary.myDictionary,
    enToRus: {
      value: formatMessage(
        {
          id: 'Dictionary.Search.EnToRus'
        },
        {icon: <Icon name="exchange" />}
      ) as string
    },
    en: {
      value: formatMessage({id: 'Dictionary.Search.En'})
    }
  };
};
