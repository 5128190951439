import {type Action, type MiddlewareAPI} from 'redux';
import {defineMessages, type IntlShape} from 'react-intl';
import React from 'react';

import * as toastr from 'components/toastr';
import {type AppState} from 'store/interface';
import Icon from 'components/Icon';
import {requestBrowserNotificationPermissionsTimeout} from 'config/static';

import {type SetIsMobileAction} from '../../layouts/actions/interface';
import {type Dispatch, type WampAction} from '../wamp/actions/interface';
import PermissionsToastButton from '../../components/toastr/NotificationsPermissionsToastButton';

const messages = defineMessages({
  NotificationToastrTitle: {
    id: 'Notification.PermissionToastTitle'
  },
  NotificationToastrBody: {
    id: 'Notification.PermissionToastBody'
  }
});

const requestNotificationPermissions = (timeout: number, intl: IntlShape) => {
  if (
    'Notification' in window &&
    Notification.permission !== 'denied' &&
    Notification.permission !== 'granted'
  ) {
    const [title, body] = [
      intl.formatMessage(messages.NotificationToastrTitle),
      intl.formatMessage(messages.NotificationToastrBody)
    ];
    setTimeout(() => {
      toastr.warning(title, body, {
        component: <PermissionsToastButton />,
        icon: <Icon name="warning" />,
        removeOnHover: false
      });
    }, timeout);
  }
};

export default (
  {getState}: MiddlewareAPI<Dispatch<Action, WampAction>, AppState>,
  action: SetIsMobileAction
) => {
  const {
    layout: {enforceDesktop},
    user: {role},
    intl
  } = getState();

  if (action.isMobile === false && enforceDesktop === undefined && role !== 'methodist') {
    requestNotificationPermissions(requestBrowserNotificationPermissionsTimeout, intl);
  }
};
