import {type Action, type Reducer, type ReducersMapObject} from 'redux';

import ExerciseRecord from './ExerciseRecord';
import widgetsReducer from '../widgets/widgetsReducer';
import {type WidgetAction} from '../interface';
import {
  type ExtraExerciseAction,
  type PreviewExerciseCompletedAction,
  type UpdateUnitExercisesIsInHomeworkAction
} from '../actions';
import {
  INCREMENT_EXTRA_COUNT,
  PREVIEW_EXERCISE_COMPLETED,
  PREVIEW_EXERCISE_UNCOMPLETED,
  UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK
} from '../actionTypes';
import {
  EXERCISE_ALREADY_COMPLETED,
  EXERCISE_COMPLETED_EVENT,
  EXERCISE_UNCOMPLETED_EVENT
} from '../persistence/actionTypes';
import {
  type CompletedExerciseEventAction,
  type UncompletedExerciseEventAction
} from '../persistence/interface';
import type WidgetRecord from '../WidgetRecord';
import {type ExerciseProperties} from './interface';
import {LABEL_FIRST_TASK} from '../widgets/actionTypes';
import {type LabelFirstTaskAction} from '../widgets/actions';
import {commentsReducer} from '../../../../components/XPlayer/components/ExerciseFooter/ExerciseComments/commentsReducer';
import {type CommentAction} from '../../../../components/XPlayer/components/ExerciseFooter/ExerciseComments/actions';

const exerciseCompletedReducer = (
  state: ExerciseRecord,
  {exerciseId, completedWidgets, completedAt}: CompletedExerciseEventAction
) => {
  if (exerciseId !== state.id) {
    return state;
  }
  const widgets = state.widgets.map((w: WidgetRecord) => {
    const widgetCompletedValues = completedWidgets[w.id];
    if (widgetCompletedValues) {
      const {values, version, answers} = widgetCompletedValues;
      return w.withMutations(m => {
        if (version !== undefined) {
          m.set('version', version);
        }
        m.setValuesFromJSON(values);
        m.setAnswersFromJSON(answers);
      });
    }
    return w;
  });
  return state.withMutations(e => {
    e.set('widgets', widgets);
    e.set('completedAt', completedAt);
  });
};

const exerciseUncompletedReducer = (
  state: ExerciseRecord,
  {exerciseId, role}: UncompletedExerciseEventAction
) =>
  exerciseId !== state.id
    ? state
    : state.withMutations(e => {
        if (role === 'student')
          e.set(
            'widgets',
            state.widgets.map(w => w!.delete('answers'))
          );
        e.delete('completedAt');
      });

const REDUCERS: ReducersMapObject = {
  [EXERCISE_COMPLETED_EVENT]: exerciseCompletedReducer,
  [EXERCISE_ALREADY_COMPLETED]: exerciseCompletedReducer,
  [EXERCISE_UNCOMPLETED_EVENT]: exerciseUncompletedReducer,
  [PREVIEW_EXERCISE_COMPLETED]: (
    state: ExerciseRecord,
    {completedAt, exerciseId}: PreviewExerciseCompletedAction
  ) => (exerciseId !== state.id ? state : state.set('completedAt', completedAt)),
  [PREVIEW_EXERCISE_UNCOMPLETED]: (
    state: ExerciseRecord,
    {exerciseId}: PreviewExerciseCompletedAction
  ) => (exerciseId !== state.id ? state : state.delete('completedAt')),
  [UPDATE_UNIT_EXERCISES_IS_IN_HOMEWORK]: (
    state: ExerciseRecord,
    action: UpdateUnitExercisesIsInHomeworkAction
  ): ExerciseRecord => {
    const exerciseShouldBeInHomework = action.homeworkExercises.includes(state.id);
    const exerciseWasInHomework = state.homeworkId === action.homeworkId;
    if (exerciseWasInHomework && !exerciseShouldBeInHomework) {
      return state.delete('homeworkId');
    }
    if (!exerciseWasInHomework && exerciseShouldBeInHomework) {
      return state.set('homeworkId', action.homeworkId);
    }
    return state;
  },
  [INCREMENT_EXTRA_COUNT]: (state: ExerciseRecord, action: ExtraExerciseAction): ExerciseRecord => {
    if (state.id !== action.exerciseId) {
      return state;
    }
    return state.set('additionalAvailable', (state.additionalAvailable || 0) + 1);
  },
  [LABEL_FIRST_TASK]: (
    state: ExerciseRecord,
    {exerciseId, page, exerciseNumber}: LabelFirstTaskAction
  ): ExerciseRecord => {
    if (exerciseId !== state.id) {
      return state;
    }
    const widgets = state.widgets;
    return state.set(
      'widgets',
      widgets.set(
        state.headWidgetIndex,
        widgets
          .get(state.headWidgetIndex)
          .setLabel(page ? `${page}.${exerciseNumber}` : `Extra ${exerciseNumber}`)
      )
    );
  }
};

const initialState: ExerciseRecord = new ExerciseRecord({
  id: null,
  title: '',
  grammarTitle: null,
  widgets: [],
  mediaSources: [],
  meta: {
    categories: [],
    levels: []
  }
});

const exerciseReducerCreator = (preview?: boolean) => {
  return (state: ExerciseProperties = initialState, action: Action): ExerciseProperties => {
    const reducer: Reducer<ExerciseProperties> = REDUCERS[action.type];

    state = reducer ? reducer(state, action) : state;

    if (
      !preview &&
      state.comments &&
      (action as CommentAction).comment?.exerciseInstanceId === state.id
    ) {
      state = state.set('comments', commentsReducer(state.comments, action as CommentAction));
    }

    if ((action as WidgetAction).widgetId && preview === (action as WidgetAction).preview) {
      state = state.set('widgets', widgetsReducer(state.widgets, action as WidgetAction));
    }

    return state;
  };
};

export const xplayerExerciseReducer = exerciseReducerCreator();
export const xpreviewExerciseReducer = exerciseReducerCreator(true);
