import {type Action} from 'redux';
import {type ValueJSON} from '@englex/slate';

import {type ExerciseInstanceComment} from 'store/interface';
import {type AxiosRequestAction} from 'services/axios/interface';

import {
  DELETE_COMMENT,
  PUSH_COMMENT,
  REPLACE_COMMENT,
  REQUEST_DELETE_COMMENT,
  REQUEST_EDIT_COMMENT,
  REQUEST_POST_COMMENT
} from './actionTypes';

export interface CommentAction extends Action {
  comment: Omit<ExerciseInstanceComment, 'createdBy'>;
}

export interface CommentDescriptorAction extends Action {
  comment: Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>;
}

export const requestPostComment: (
  exerciseInstanceId: string,
  comment: ValueJSON
) => AxiosRequestAction = (exerciseInstanceId, comment) => ({
  type: REQUEST_POST_COMMENT,
  payload: {
    client: 'v2',
    request: {
      method: 'post',
      url: `/v2/exercise-instance/${exerciseInstanceId}/comment`,
      data: {comment}
    }
  }
});

export const requestEditComment: (
  exerciseInstanceId: string,
  commentId: string,
  comment: ValueJSON
) => AxiosRequestAction = (exerciseInstanceId, commentId, comment) => ({
  type: REQUEST_EDIT_COMMENT,
  payload: {
    client: 'v2',
    request: {
      method: 'patch',
      url: `/v2/exercise-instance/${exerciseInstanceId}/comment/${commentId}`,
      data: {comment}
    }
  }
});

export const requestDeleteComment: (
  exerciseInstanceId: string,
  commentId: string
) => AxiosRequestAction = (exerciseInstanceId, commentId) => ({
  type: REQUEST_DELETE_COMMENT,
  payload: {
    client: 'v2',
    request: {
      method: 'delete',
      url: `/v2/exercise-instance/${exerciseInstanceId}/comment/${commentId}`
    }
  }
});

export const pushComment = (comment: ExerciseInstanceComment): CommentAction => ({
  type: PUSH_COMMENT,
  comment
});

export const replaceComment = (
  comment: Omit<ExerciseInstanceComment, 'createdBy'>
): CommentAction => ({
  type: REPLACE_COMMENT,
  comment
});

export const deleteComment = (
  comment: Pick<ExerciseInstanceComment, 'id' | 'exerciseInstanceId'>
): CommentDescriptorAction => ({
  type: DELETE_COMMENT,
  comment
});
