import React, {type FC, type SyntheticEvent, useCallback, useContext} from 'react';
import Checkbox, {type CheckboxProps} from 'react-bootstrap/lib/Checkbox';

import {
  EditEntryActionsContext,
  EditEntryStateContext
} from '../../contexts/entry/EditEntryContext';
import {deselectEntries, selectEntries} from '../../contexts/entry/actions';

export const SelectionCheckbox: FC<{id: string}> = ({id}) => {
  const {dispatch} = useContext(EditEntryActionsContext);
  const {selectionMode} = useContext(EditEntryStateContext);

  const onChange = useCallback(
    (e: SyntheticEvent<CheckboxProps>) => {
      dispatch(e.currentTarget.checked ? selectEntries([id]) : deselectEntries([id]));
    },
    [dispatch, id]
  );

  return (
    <Checkbox onChange={onChange} checked={selectionMode?.entryIds.includes(id)}>
      <span />
    </Checkbox>
  );
};
