import {type FC, memo, useCallback, useContext, useEffect, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {Transition} from 'react-transition-group';

import {
  EditEntryActionsContext,
  EditEntryStateContext
} from 'components/Dictionary/shared/contexts/entry/EditEntryContext';
import {bottomSlideOutTransition} from 'components/Dictionary/shared/utils';
import {exitSelectionMode} from 'components/Dictionary/shared/contexts/entry/actions';

import {AddSelectedEntriesToListDropdown} from '../AddSelectedEntriesToListDropdown/AddSelectedEntriesToListDropdown';
import {UnselectButton} from '../UnselectButton/UnselectButton';

import './SelectEntriesPanel.scss';

interface Props {
  hasLists: boolean;
  reset(): void;
}

export const SelectEntriesPanel: FC<Props> = memo(({hasLists, reset}) => {
  const {formatMessage} = useIntl();
  const {selectionMode} = useContext(EditEntryStateContext);
  const {dispatch} = useContext(EditEntryActionsContext);

  const transitionObj = useMemo(() => bottomSlideOutTransition(300, 58), []);

  const onExited = useCallback(() => {
    dispatch(exitSelectionMode());
  }, [dispatch]);

  useEffect(() => () => reset(), [reset]);

  return (
    <Transition timeout={transitionObj.timeout} in={!!selectionMode} onExited={onExited}>
      {state => (
        <div
          className="select-entries-panel"
          style={{...transitionObj.defaultStyle, ...transitionObj.transitionStyles[state]}}
        >
          <AddSelectedEntriesToListDropdown hasLists={hasLists} />

          <div className="selected-entries-wrapper">
            <p className="items-counter">
              {formatMessage(
                {id: 'Dictionary.Selected'},
                {count: selectionMode?.entryIds.length || 0}
              )}
            </p>

            <UnselectButton numberOfSelectedEntries={selectionMode?.entryIds.length || 0} />
          </div>
        </div>
      )}
    </Transition>
  );
});
