import {type FC, useCallback, useEffect, useState} from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import {useIntl} from 'react-intl';

import {useAxiosDispatch} from 'hooks/redux/useAxiosDispatch';
import {useLoadable} from 'hooks/useLoadable';
import {gotToken} from 'authentication/actions/action';
import {replace} from 'store/router';
import {type AxiosRequestError, type AxiosResponseAction} from 'services/axios/interface';

import {loginRequest, type LoginResponse} from './actions';
import {LoginForm, type LoginFormValues} from './LoginForm';
import styles from './LoginRoute.module.scss';
import {isLoginAtDashboardWeb} from '../../authentication';
import {useUserId} from '../../hooks/user/useUserId';

export const LoginRoute: FC = () => {
  const isDisabled = isLoginAtDashboardWeb();
  const userId = useUserId();

  const to = useLocation().state?.returnBack || '/';

  if (isDisabled || userId) {
    return <Navigate to={to} replace={true} />;
  }

  return <Login />;
};

const Login: FC = () => {
  const dispatch = useAxiosDispatch();
  const intl = useIntl();

  const login = useCallback(
    (email: string, password: string) =>
      dispatch<AxiosResponseAction<LoginResponse>>(loginRequest(email, password)),
    [dispatch]
  );

  const returnBack = useLocation().state?.returnBack;

  const [request, , isSubmitting] = useLoadable(login, {
    onSuccess: useCallback(
      ({
        payload: {
          data: {token}
        }
      }: AxiosResponseAction<LoginResponse>) => {
        dispatch(gotToken(token));
        dispatch(replace(returnBack || '/'));
      },
      [dispatch, returnBack]
    ),
    onError: useCallback(
      (e: AxiosRequestError) => {
        if (e.error.response?.status === 401) {
          setLoginError(intl.formatMessage({id: 'Login.Form.LoginError.Unauthorized'}));
        } else {
          setLoginError(intl.formatMessage({id: 'Common.SomethingWentWrongPleaseTryAgainLater'}));
        }
      },
      [intl]
    )
  });
  const onSubmit = ({email, password}: LoginFormValues) => {
    request(email, password);
  };
  const [loginError, setLoginError] = useState<string | undefined>();

  useEffect(() => {
    if (loginError) {
      setLoginError(undefined);
    }
  }, [loginError]);
  return (
    <div className={styles.container}>
      <LoginForm onSubmit={onSubmit} loginError={loginError} isSubmitting={isSubmitting} />
    </div>
  );
};
