import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {type AppState} from 'store/interface';

import {pullSidebar, toggleCollapse} from '../action';
import reducer from '../reducer';
import {useInjectReducer} from '../../../hooks/redux/useInjectReducer';

export function useRedux() {
  useInjectReducer({floatSidebar: reducer});

  const dispatch = useDispatch();

  const layoutCollapsed = useSelector<AppState, boolean>(state => state.layout.collapsed);

  const sidebarCollapsed = useSelector<AppState, boolean>(
    state => state.layout.collapsed || !!state.floatSidebar?.collapsed
  );
  const sidebarPulledOut = useSelector<AppState, boolean>(
    state => !!state.floatSidebar?.sidebarPulledOut
  );

  const pullSidebarCb = useCallback(() => dispatch(pullSidebar(false)), [dispatch]);

  const toggleCollapseCb = useCallback(
    (collapse: boolean) => {
      requestAnimationFrame(() => dispatch(toggleCollapse(collapse)));
    },
    [dispatch]
  );

  const clickHandler = useCallback(
    () => dispatch(pullSidebar(!sidebarPulledOut)),
    [dispatch, sidebarPulledOut]
  );

  return {
    sidebarCollapsed,
    layoutCollapsed,
    pullSidebarCb,
    sidebarPulledOut,
    toggleCollapseCb,
    clickHandler
  };
}
