import {defineMessages} from 'react-intl';

const i18n = defineMessages({
  homeworkStatusNotAssigned: {
    id: 'HomeworkPage.Status.NotAssigned'
  },
  homeworkStatusAssigned: {
    id: 'HomeworkPage.Status.Assigned'
  },
  homeworkStatusToCheck: {
    id: 'HomeworkPage.Status.ToCheck'
  },
  homeworkStatusChecked: {
    id: 'HomeworkPage.Status.Checked'
  },
  homeworkStatusCompleted: {
    id: 'HomeworkPage.Status.Completed'
  },
  homeworkActionAssign: {
    id: 'Homework.Action.Assign'
  },
  homeworkActionComplete: {
    id: 'Homework.Action.Complete'
  },
  homeworkActionRestore: {
    id: 'Homework.Action.Restore'
  },
  homeworkActionSubmit: {
    id: 'Homework.Action.Submit'
  },
  homeworkDeleteConfirmation: {
    id: 'Homework.Action.DeleteConfirmationAction'
  },
  homeworkActivateConfirmation: {
    id: 'Homework.Action.ActivateConfirmationAction'
  },
  homeworkDeleteSuccessToast: {
    id: 'Homework.Action.DeleteSuccessToast'
  },
  homeworkDeleteFailToast: {
    id: 'Homework.Action.DeleteFailToast'
  },
  homeworkCompleteConfirm: {
    id: 'Homework.Action.CompleteConfirm'
  },
  homeworkCompleteSuccess: {
    id: 'Homework.Action.CompleteSuccess'
  },
  homeworkCompleteFail: {
    id: 'Homework.Action.CompleteFail'
  },
  homeworkReactivationConfirmation: {
    id: 'Homework.Action.ReactivateConfirmationAction'
  },
  homeworkReactivationSuccess: {
    id: 'Homework.Action.ReactivationSuccess'
  },
  homeworkReactivationFail: {
    id: 'Homework.Action.ReactivationFail'
  },
  statusFilterPlaceholder: {
    id: 'Homework.Filter.StatusesPlaceholder'
  }
});

export default i18n;
