import {List} from 'immutable';

import {record} from 'immutable-record/decorator/record';
import {property} from 'immutable-record/decorator/property';
import {decorate} from 'immutable-record/decorate.util';

import {WidgetType} from '../../interface';
import {
  type VocabularyJSON,
  type VocabularyProperties,
  type VocabularyWithGroupedWords,
  type WidgetVocabularyInfo
} from './interface';
import VocabularyWordRecord from './VocabularyWordRecord';
import VocabularyCategoryRecord from './VocabularyCategoryRecord';
import WidgetRecord from '../../WidgetRecord';
import {isVocabularyWordJSON} from './utils';

class VocabularyRecord extends WidgetRecord implements VocabularyProperties {
  public declare readonly vocabulary: List<VocabularyWordRecord | VocabularyCategoryRecord>;
  public declare readonly quizletURL?: string;
  public declare readonly dictionaryListId?: string | null;
  public declare readonly listTitle?: string;
  public declare readonly vocabularyInfoUpdatedTimestamp?: number;

  constructor(raw: VocabularyJSON) {
    super(raw);
    this.initValues({
      vocabulary: List(
        raw.vocabulary.map(entry =>
          isVocabularyWordJSON(entry)
            ? new VocabularyWordRecord(entry)
            : new VocabularyCategoryRecord(entry)
        )
      ),
      listTitle: raw.listTitle,
      quizletURL: raw.quizletURL
    });
  }

  public toJSON(): VocabularyJSON {
    return {
      id: this.id,
      type: this.type,
      task: this.task.toJSON(),
      version: this.version,
      vocabulary: this.vocabulary.toJS(),
      quizletURL: this.quizletURL,
      displayButton: this.displayButton?.toJSON()
    };
  }

  public get vocabularyGroupedWords(): VocabularyWithGroupedWords {
    return this.vocabulary.reduce<VocabularyWithGroupedWords>(
      (
        reduction: VocabularyWithGroupedWords,
        entry: VocabularyCategoryRecord | VocabularyWordRecord
      ) => {
        const lastElInReduction = reduction[reduction.length - 1];
        if (isVocabularyWordJSON(entry)) {
          if (lastElInReduction && lastElInReduction instanceof Array) {
            lastElInReduction.push(entry);
          } else {
            reduction.push([entry]);
          }
        } else {
          reduction.push(entry);
        }
        return reduction;
      },
      []
    );
  }

  public setWidgetVocabularyInfo(widgetVocabularyInfo: WidgetVocabularyInfo): this {
    const newThis = this.withMutations(t => {
      t.set('dictionaryListId', widgetVocabularyInfo.dictionaryListId);
      t.set('vocabularyInfoUpdatedTimestamp', new Date().getTime());
    });
    return newThis.set(
      'vocabulary',
      newThis.vocabulary.map((v: VocabularyWordRecord) => {
        if (!v.wordId) return v; // do not touch categories

        const dictionaryWordId = widgetVocabularyInfo.words?.[v.wordId];
        return dictionaryWordId
          ? v.set('dictionaryEntryInstance', dictionaryWordId)
          : v.delete('dictionaryEntryInstance');
      })
    );
  }

  public get type() {
    return WidgetType.VOCABULARY;
  }

  public get isAvailableSelection() {
    return true;
  }
}

decorate(VocabularyRecord, {
  dictionaryListId: property(),
  listTitle: property(),
  vocabulary: property(List([])),
  quizletURL: property(),
  vocabularyInfoUpdatedTimestamp: property()
});
record()(VocabularyRecord);
export default VocabularyRecord;
