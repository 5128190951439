import {defineMessages} from 'react-intl';

export const reactSelectMessages = defineMessages({
  placeholder: {
    id: 'Sandbox.Forms.MultiSelect.Placeholder'
  },
  noOptionsMessage: {
    id: 'Sandbox.Forms.MultiSelect.NoOptionsMessage'
  }
});
