import {type Block, Value} from '@englex/slate';
import {List} from 'immutable';

import {SlateBlock} from 'components/Slate/interface';
import {decorate} from 'immutable-record/decorate.util';
import {property} from 'immutable-record/decorator/property';
import {record} from 'immutable-record/decorator/record';
import {WidgetType} from 'store/exercise/player/interface';
import WidgetRecord from 'store/exercise/player/WidgetRecord';

import {type StyledListJSON, type StyledListProperties, type StyledListStyles} from './interface';

class StyledListRecord
  extends WidgetRecord<unknown, List<string>, unknown>
  implements StyledListProperties
{
  public declare readonly content: Value;
  public declare readonly styleName: StyledListStyles;

  constructor(raw: StyledListJSON) {
    super(raw);
    this.initValues({
      content: this.setItemData(Value.fromJSON(raw.content), List([])),
      styleName: raw.styleName,
      values: raw.values ? List(raw.values) : List<string>()
    });
  }

  public toJSON(): StyledListJSON {
    return {
      ...super.toJSON(),
      content: this.content.toJSON(),
      styleName: this.styleName,
      values: this.values.toArray()
    };
  }

  selectItem(id: string) {
    const afterChangeValues = this.changeValue(id);
    return afterChangeValues.set(
      'content',
      afterChangeValues.setItemData(afterChangeValues.content, afterChangeValues.values)
    );
  }

  public setValuesFromJSON(values: string[]): this {
    const afterChangeValues = this.set('values', List(values));
    return afterChangeValues.set(
      'content',
      afterChangeValues.setItemData(afterChangeValues.content, afterChangeValues.values)
    );
  }

  public get isAvailableSelection() {
    return true;
  }

  public get type() {
    return WidgetType.HORIZONTAL_STYLED_LIST;
  }

  private changeValue(id: string): this {
    if (this.values.includes(id)) {
      return this.set(
        'values',
        this.values.delete(this.values.findIndex((value: StyledListStyles) => value === id))
      );
    }

    return this.set('values', this.values.push(id));
  }

  private setItemData(value: Value, values?: List<string>): Value {
    return value.document.nodes.reduce((reduction: Value, block: Block, key: number) => {
      if (block.type !== SlateBlock.LIST) return reduction;

      return (reduction.document.nodes.get(key)?.get('nodes') as List<Block>).reduce(
        (reduction: Value, block: Block, key: number) => {
          const path = reduction.document.getPath(block.key);

          if (!path) return reduction;

          return reduction
            .setIn(
              ['document', 'nodes', path.get(0), 'nodes', path.get(1), 'data', 'isActive'],
              values && values.includes(block.data.get('id'))
            )
            .setIn(
              ['document', 'nodes', path.get(0), 'nodes', path.get(1), 'data', 'index'],
              path.get(1)
            );
        },
        reduction
      );
    }, value) as Value;
  }
}

decorate(StyledListRecord, {
  content: property(),
  styleName: property()
});
record()(StyledListRecord);
export default StyledListRecord;
