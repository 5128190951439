import React, {Component} from 'react';
import {FormattedMessage, injectIntl, type WrappedComponentProps} from 'react-intl';
import {Editor as SlateEditorReact, type Plugin} from '@englex/slate-react';
import {Editor, type Value} from '@englex/slate';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import ToggleButton from 'react-bootstrap/lib/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/lib/ToggleButtonGroup';
import Modal from 'react-bootstrap/lib/Modal';
import Button from 'react-bootstrap/lib/Button';

import {type LoadPronunciationsResponseAction} from 'store/dictionary/requests';
import {type AxiosRequestError} from 'services/axios/interface';
import {type CancellablePromise, makeCancellable} from 'helpers/cancellablePromise';
import * as toastr from 'components/toastr';
import Loader from 'components/Loader';
import SingleParagraphEditor from 'components/Slate/SlateEditor/plugins/SingleParagraphEditor';
import Icon from 'components/Icon';
import {valueFromText} from 'components/Slate/utils/value';
import PronunciationSoundPlayer from 'components/XPlayer/widgets/Vocabulary/PronunciationSoundPlayer';
import {type PronunciationOption} from 'store/exercise/player/interface';

import {additionalSymbols, pronunciationPhoneticSpellingMaxLength} from '../static';

import './PronunciationModal.scss';

interface Props {
  pronunciationOptions?: Array<PronunciationOption | null>;
  selectedOptionIndex: number;
  phoneticSpelling?: Value;
  validationStatus: 'success' | 'error';
  changePhoneticSpelling(value: Value): void;
  loadPronunciations(): Promise<LoadPronunciationsResponseAction>;
  pronunciationsLoaded(options: PronunciationOption[]): void;
  selectPronunciationOption(index: number): void;

  close(): void;
  submit(): void;
}

interface State {
  show: boolean;
}

class PronunciationModalView extends Component<Props & WrappedComponentProps, State> {
  public state: State = {show: true};

  private plugins: Plugin[] = [new SingleParagraphEditor()];
  private pronunciationsRequest: CancellablePromise;

  public componentDidMount() {
    this.pronunciationsRequest = makeCancellable(
      this.props.loadPronunciations(),
      this.handlePronunciationsLoaded,
      this.handleRequestFail
    );
  }

  public componentWillUnmount() {
    this.pronunciationsRequest.cancel();
  }

  public render() {
    const {
      pronunciationOptions,
      phoneticSpelling,
      validationStatus,
      selectedOptionIndex,
      selectPronunciationOption,

      close,
      submit
    } = this.props;
    const {show} = this.state;

    return (
      <Modal
        show={show}
        backdrop="static"
        onHide={this.close}
        className="x-vocabulary-pronunciation-modal modal-gray"
        onExited={() => {
          if (!show) close();
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <FormattedMessage id="XEditorXWidget.Vocabulary.Modal.Title" />
            <a onClick={this.close}>
              <Icon name="pc-close" tag="i" />
            </a>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!pronunciationOptions || !phoneticSpelling ? (
            <Loader />
          ) : (
            <div>
              <form>
                <FormattedMessage id="XEditorXWidget.Vocabulary.Modal.SelectRecording" />

                <ToggleButtonGroup
                  vertical={true}
                  type="radio"
                  name="vocabulary-pronunciation-options-selector"
                  value={selectedOptionIndex}
                  onChange={selectPronunciationOption}
                  className="vocabulary-pronunciation-options-selector"
                >
                  {pronunciationOptions.map(this.renderPronunciationOption)}
                </ToggleButtonGroup>

                <FormattedMessage id="XEditorXWidget.Vocabulary.Modal.EnterPhoneticSpelling" />
                <FormGroup controlId="text-control" validationState={validationStatus}>
                  <SlateEditorReact
                    value={phoneticSpelling}
                    onChange={this.onEditorChange}
                    plugins={this.plugins}
                  />
                  <div className="help-block error">
                    <FormattedMessage
                      id="XEditorXWidget.Vocabulary.Modal.PhoneticSpellingTooLong"
                      values={{length: pronunciationPhoneticSpellingMaxLength}}
                    />
                  </div>
                </FormGroup>

                <div className="add-symbol-buttons">
                  {additionalSymbols.map(this.renderAddSymbolBtn)}
                </div>
              </form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button bsStyle="default" className="btn-transparent" bsSize="sm" onClick={this.close}>
            <FormattedMessage id="Common.Cancel" />
          </Button>
          {!pronunciationOptions ? null : (
            <Button
              bsStyle="primary"
              type="submit"
              bsSize="sm"
              disabled={validationStatus === 'error'}
              onClick={submit}
            >
              <FormattedMessage id="Common.Save" />
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }

  private close = () => this.setState({show: false});

  private onEditorChange = (change: SlateEditorReact) =>
    this.props.changePhoneticSpelling(change.value);

  private renderAddSymbolBtn = (symbol: string, index: number) => (
    <button
      key={index}
      type="button"
      disabled={!this.props.phoneticSpelling?.selection?.isFocused}
      onMouseDown={this.addSymbol(symbol)}
    >
      {symbol}
    </button>
  );

  private addSymbol = (symbol: string) => (e: React.MouseEvent) => {
    const {phoneticSpelling, changePhoneticSpelling} = this.props;
    e.preventDefault();
    const change = new Editor({value: phoneticSpelling});
    const newChange = change.insertText(symbol);
    changePhoneticSpelling(newChange.value);
  };

  private renderPronunciationOption = (option: PronunciationOption, index: number) => {
    if (!option) {
      // render "no pronunciation" option
      return (
        <ToggleButton value={index} bsSize="sm" key={index} className="no-pronunciation-option">
          <Icon name="volume-off" />
          <FormattedMessage id="XEditorXWidget.Vocabulary.Modal.NoPronunciation" />
        </ToggleButton>
      );
    }
    return (
      <ToggleButton value={index} bsSize="sm" key={index} className="pronunciation-option">
        <PronunciationSoundPlayer
          soundId={option.id}
          soundUrl={option.url}
          renderContent={(props: {playSound: () => void}) => (
            <div onClick={props.playSound}>
              <Icon name="volume-up" />
              <FormattedMessage
                id="XEditorXWidget.Vocabulary.Modal.Option"
                values={{count: index}}
              />
            </div>
          )}
        />
      </ToggleButton>
    );
  };

  private handleRequestFail = (action: AxiosRequestError) => {
    const {pronunciationsLoaded, intl, phoneticSpelling, changePhoneticSpelling} = this.props;
    if (!action.error.response || action.error.response.data.statusCode !== 404) {
      toastr.error(
        '',
        intl.formatMessage({id: 'XEditorXWidget.Vocabulary.Modal.PronunciationRequestFailed'})
      );
    }
    pronunciationsLoaded([]);
    if (!phoneticSpelling) changePhoneticSpelling(valueFromText(''));
  };

  private handlePronunciationsLoaded = (action: LoadPronunciationsResponseAction) => {
    const {pronunciationsLoaded, changePhoneticSpelling, phoneticSpelling} = this.props;
    pronunciationsLoaded(action.payload.data.audio);
    if (!phoneticSpelling || !phoneticSpelling.document.text.length)
      changePhoneticSpelling(valueFromText(action.payload.data.phoneticSpelling));
  };
}

export const PronunciationModal = injectIntl(PronunciationModalView);
