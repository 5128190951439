import {type Search} from 'react-router-dom';
import {type MessageDescriptor} from 'react-intl';
import {useEffect, useMemo, useRef} from 'react';

import {HomeworkStatus} from 'common/enums';

import {type RequestOptions} from './interface';
import i18n from './i18n';
import {defaultSearchString} from './static';

export enum HomeworkPhase {
  COMPLETED = 'completed',
  ASSIGNED = 'assigned',
  NOT_ASSIGNED = 'not-assigned',
  TO_CHECK = 'to-check',
  CHECKED = 'checked'
}

export const resolveHomeworkPhase = (
  activatedAt: string | null,
  completedAt: string | null,
  status: HomeworkStatus
): HomeworkPhase => {
  if (completedAt) {
    return HomeworkPhase.COMPLETED;
  }
  if (!activatedAt) {
    return HomeworkPhase.NOT_ASSIGNED;
  }
  switch (status) {
    case HomeworkStatus.GIVEN:
      return HomeworkPhase.ASSIGNED;
    case HomeworkStatus.DONE:
      return HomeworkPhase.TO_CHECK;
    case HomeworkStatus.CHECKED:
      return HomeworkPhase.CHECKED;
  }
};

export const labelMap = {
  [HomeworkPhase.ASSIGNED]: i18n.homeworkStatusAssigned,
  [HomeworkPhase.NOT_ASSIGNED]: i18n.homeworkStatusNotAssigned,
  [HomeworkPhase.COMPLETED]: i18n.homeworkStatusCompleted,
  [HomeworkPhase.TO_CHECK]: i18n.homeworkStatusToCheck,
  [HomeworkPhase.CHECKED]: i18n.homeworkStatusChecked
};

export const buttonMap = {
  [HomeworkPhase.NOT_ASSIGNED]: i18n.homeworkActionAssign,
  [HomeworkPhase.COMPLETED]: i18n.homeworkActionRestore,
  [HomeworkPhase.TO_CHECK]: i18n.homeworkStatusChecked,
  [HomeworkPhase.CHECKED]: i18n.homeworkActionComplete,
  [HomeworkPhase.ASSIGNED]: i18n.homeworkStatusChecked
};

export const selectMap = {
  given: i18n.homeworkStatusAssigned,
  checked: i18n.homeworkStatusChecked,
  done: i18n.homeworkStatusToCheck
};

export const mapPhaseToDescriptor =
  (map: {}) =>
  (label: HomeworkPhase): MessageDescriptor => {
    return map[label];
  };

export const validStatuses = ['', '0', '1', '2'];

const validateSearch =
  (knownParams: {[key: string]: string[]}) =>
  (search: string): [string, boolean] => {
    const searchParams = new URLSearchParams(search);

    const keysToRemove: string[] = [];

    const knownParamsKeys = Object.keys(knownParams);
    searchParams.forEach((value, key) => {
      if (!knownParamsKeys.includes(key)) return;

      searchParams
        .get(key)!
        .split(',')
        .forEach(v => {
          if (knownParams[key] && !knownParams[key].includes(v)) {
            keysToRemove.push(key);
          }
        });
    });

    if (keysToRemove.length) {
      for (const key of keysToRemove) searchParams.delete(key);
      return [searchParams.toString(), true];
    }

    return [search, false];
  };

export const getHomeworkOptions = (search: Search): RequestOptions => {
  const searchParams = new URLSearchParams(search);
  const hideCompleted =
    searchParams.get('hideCompleted') === 'true'
      ? true
      : searchParams.get('hideCompleted') === 'false'
        ? false
        : undefined;

  const studentIdParam = searchParams.get('studentId');
  const studentId = studentIdParam ? Number(studentIdParam) : undefined;

  const statusesParam: string | null = searchParams.get('statuses');
  const statuses = statusesParam ? statusesParam.split(',').map(sp => Number(sp)) : undefined;
  return {statuses, hideCompleted, studentId};
};

export const useSanitizeSearch = (
  inboundSearch: string,
  knownParams: Record<string, string[]>
): [RequestOptions, string, boolean] => {
  const [outboundSearch, shouldRefresh] = useMemo<[string, boolean]>(
    () => validateSearch(knownParams)(inboundSearch),
    [inboundSearch, knownParams]
  );

  const defaultSearch = outboundSearch !== '' ? undefined : defaultSearchString;

  const prevRequestOptions = useRef<RequestOptions | undefined>();

  const requestOptions = useMemo(() => {
    const reqOpts = getHomeworkOptions(defaultSearch ? defaultSearch : outboundSearch);
    const knownKeys = Object.keys(knownParams);
    let equal = true;
    if (!prevRequestOptions.current) return reqOpts;
    for (const key of knownKeys) {
      const prevValue = prevRequestOptions.current[key];
      const currentValue = reqOpts[key];

      if (!!prevValue !== !!currentValue) {
        equal = false;
        break;
      }

      if (prevValue instanceof Array) {
        if (prevValue.length !== currentValue.length) {
          equal = false;
          break;
        }

        for (const entry of prevValue) {
          if (!currentValue?.includes(entry)) {
            equal = false;
            break;
          }
        }
      } else if (prevValue !== currentValue) {
        equal = false;
        break;
      }
    }
    return equal ? prevRequestOptions.current : reqOpts;
  }, [defaultSearch, knownParams, outboundSearch]);

  useEffect(() => {
    prevRequestOptions.current = requestOptions;
  });

  return [requestOptions, outboundSearch, shouldRefresh];
};
