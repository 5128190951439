import React from 'react';

import Icon from 'components/Icon';

import {type UploadingPictureStatus, type UploadingPictureValidationError} from './interface';
import {renderStatusText} from './functions';

interface Props {
  isError: boolean;
  status: UploadingPictureStatus | UploadingPictureValidationError;
  minResolution?: number | [number, number];
  serverValidationMessage?: string;
}

export default class PictureUploadingStatus extends React.Component<Props> {
  public render() {
    const {children, isError, minResolution, status, serverValidationMessage} = this.props;
    return (
      <div className={`status-component ${isError ? 'error' : ''}`}>
        <span>
          <Icon name={isError ? 'times-circle' : 'virc-upload'} size="lg" />
          {serverValidationMessage
            ? serverValidationMessage
            : renderStatusText(status, minResolution)}
        </span>
        {children}
      </div>
    );
  }
}
