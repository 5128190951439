import {type Action} from 'redux';

import {isMobileDevice} from 'helpers/browser';
import {type FloatSidebarState} from 'store/interface';
import {type ActionHandlersList} from 'store/reducers';

import {
  PULL_SIDEBAR,
  type PullSidebarAction,
  TOGGLE_COLLAPSE,
  type ToggleCollapseAction
} from './action';

const ACTION_HANDLERS: ActionHandlersList<FloatSidebarState, Action> = {
  [PULL_SIDEBAR]: (state: FloatSidebarState, action: PullSidebarAction): FloatSidebarState => ({
    ...state,
    sidebarPulledOut: action.pulledOut
  }),
  [TOGGLE_COLLAPSE]: (
    state: FloatSidebarState,
    {collapse}: ToggleCollapseAction
  ): FloatSidebarState => ({
    ...state,
    collapsed: collapse
  })
};

const initialDesktopFloatSidebarState: FloatSidebarState = {
  sidebarPulledOut: true,
  collapsed: false
};

const initialMobileFloatSidebarState: FloatSidebarState = {
  sidebarPulledOut: false,
  collapsed: true
};

const initialFloatSidebarState = isMobileDevice
  ? initialMobileFloatSidebarState
  : initialDesktopFloatSidebarState;

export default function (
  state: FloatSidebarState = initialFloatSidebarState,
  action: Action
): FloatSidebarState {
  const reducer = ACTION_HANDLERS[action.type];
  return reducer ? reducer(state, action) : state;
}
